import React, { FC, FormEvent } from "react";
import { CheckboxProps, Form, Message } from "semantic-ui-react";

import { IHistoryQuestion } from "model/organisation";
import { IQuestionaryError } from "model/questionaryError";

import "scss/Questionary.scss";

interface IExclusionForm {
    errors: IQuestionaryError;
    onFieldChange: (e: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
    question: IHistoryQuestion;
    answers: any[];
    isBack: boolean;
}

const ExclusionForm: FC<IExclusionForm> = ({ errors, onFieldChange, question, answers, isBack }) => {
    const { options, uuid } = question;
    const { showError, list } = errors;
    const isError = showError && list.some((formError) => formError.includes(uuid));

    return (
        <Form error={isError}>
            <div className="questionary-response-row">
                <div className="questionary-response-options">
                    {options.map((option: any | string) => {
                        const currentOption = answers[option.uuid];
                        const isChecked = !!currentOption?.value;

                        return (
                            <Form.Checkbox
                                key={option.uuid}
                                id={`${option.uuid}`}
                                label={option.exclusion}
                                onChange={onFieldChange}
                                exclusionuuid={option.uuid}
                                exclusion={option.exclusion}
                                error={isError}
                                className="questionary-item"
                                checked={isChecked}
                                disabled={isBack}
                            />
                        );
                    })}
                </div>
            </div>
            {isError && <Message error content="This question is required" />}
        </Form>
    );
};

export default ExclusionForm;
