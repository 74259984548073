import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getLinkToAssessment, getLinkToPatientAssessment } from "helpers/caseList";

import { IAssessmentBase } from "model/assessment";
import { UserRole } from "model/userRole";

import * as caseService from "services/caseService";
import { LocalStorageService } from "services/localStorageService";
import userService from "services/userService";

import { CASE_DESCRIPTION } from "navigation/routes";

import { getOrganisation } from "redux/selectors/data";

interface IUseRedirectToAssessment {
    redirectToAssessment: (caseUuid: string, isPatient: boolean) => void;
}

const useRedirectToAssessment = (): IUseRedirectToAssessment => {
    const dispatch = useDispatch();
    const history = useHistory();
    const organisation = useSelector(getOrganisation);

    const redirectToAssessment = async (caseUuid: string, isPatient: boolean): Promise<void> => {
        LocalStorageService.setCurrentCaseUuid(caseUuid);
        const currentAssessment: IAssessmentBase | void = await caseService.continueAssessment(caseUuid)(dispatch);
        if (currentAssessment) {
            const {
                case: { homeInitiated },
                lesion,
            } = currentAssessment;
            const isSuperadmin = userService.checkUserHasRole([UserRole.SA_ADMIN, UserRole.SUPERADMIN]);
            let redirectLink;
            if (isSuperadmin && homeInitiated && !(lesion && !lesion.result)) {
                redirectLink = `${CASE_DESCRIPTION}/${caseUuid}`;
            } else {
                redirectLink = isPatient
                    ? getLinkToPatientAssessment(currentAssessment)
                    : getLinkToAssessment(currentAssessment, organisation);
            }
            history.push(redirectLink);
        }
    };

    return { redirectToAssessment };
};

export default useRedirectToAssessment;
