import { FC, useContext } from "react";

import PatientSearchResultsTable from "components/PatientSearch/PatientSearchResultsTable";
import PatientSearchPagination from "components/PatientSearch/PatientSearchPagination";

import { PatientSearchContext } from "contextProviders/PatientSearchProvider";

import { RESULTS_LIMIT } from "model/patientSearch";

const PatientSearchResults: FC = () => {
    const {
        state: { patients, lastItemOnPageIndex, firstItemOnPageIndex, totalPages },
    } = useContext(PatientSearchContext);

    const { length: totalPatients } = patients;
    const isTooManyResults = totalPatients === RESULTS_LIMIT;
    const isNoResults = totalPatients === 0;
    const showMessage = isTooManyResults || isNoResults;
    const resultsCount = isTooManyResults ? `> ${RESULTS_LIMIT}` : totalPatients;
    const showPagination = totalPages > 1;

    return (
        <>
            <p className="patient-search-results__total-patients-text">
                <span data-testid="patient-search-results-count-label">{resultsCount}</span>
                <span>Result{totalPatients > 1 ? "s" : ""} found in UHB PAS</span>
            </p>
            <PatientSearchResultsTable isTooManyResults={isTooManyResults} showMessage={showMessage} />
            {!showMessage && (
                <div className="patient-search-results__footer" data-testid="patient-search-results-footer">
                    <div className="pages-info" data-testid="patient-search-results-footer-results-range-text">
                        {firstItemOnPageIndex + 1}-{lastItemOnPageIndex + 1} of {totalPatients} results
                    </div>
                    {showPagination && <PatientSearchPagination />}
                </div>
            )}
        </>
    );
};

export default PatientSearchResults;
