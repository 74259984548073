import types from "redux/actions/types";

const INITIAL_STATE = {
    consent: undefined,
    organisationUuid: undefined,
    consentDate: undefined,
    email: undefined,
    registrationUuid: undefined,
    isLoading: undefined,
    organisationCode: undefined,
    flowType: undefined,
};

const registrationReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case types.USER_CREATION:
            return {
                ...INITIAL_STATE,
                isLoading: true,
                organisationCode: action.payload,
            };

        case types.USER_CREATION_SUCCESS:
            return {
                ...state,
                consent: true,
                isLoading: false,
                email: undefined,
                ...action.payload,
            };

        case types.USER_CLEAR_DATA:
            return INITIAL_STATE;

        case types.USER_PERSONAL_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };

        case types.USER_SET_EMAIL:
            return {
                ...state,
                email: action.payload,
            };

        case types.SET_ORG_UUID:
            return {
                ...state,
                organisationUuid: action.payload,
            };

        case types.SET_ORG_FLOW_TYPE:
            return {
                ...state,
                flowType: action.payload,
            };

        default:
            return state;
    }
};

export default registrationReducer;
