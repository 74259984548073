import { useRef, useState, useEffect } from "react";

const useInterval = (callback: () => void, delay: number, finished: boolean) => {
    const savedCallback = useRef<() => void>();
    const [intervalId, setIntervalId] = useState<number>();

    // Remove interval on unmount
    useEffect(
        () => () => {
            clearInterval(intervalId);
        },
        []
    );

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (!finished) {
            const id = setInterval(tick, delay);
            setIntervalId(Number(id));
        }
        return undefined;
    }, [delay, finished]);

    useEffect(() => {
        if (finished) {
            clearInterval(intervalId);
        }
    }, [finished]);
};

export default useInterval;
