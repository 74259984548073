import React, { Component } from "react";
import RichTextEditor from "react-rte";

interface ICustomRichTextEditorProps {
    onChange: (value: string, name: string) => void;
    name: string;
    value?: string;
}

class CustomRichTextEditor extends Component<ICustomRichTextEditorProps, any> {
    private toolbarConfig: any = {
        BLOCK_TYPE_BUTTONS: [
            { label: "UL", style: "unordered-list-item" },
            { label: "OL", style: "ordered-list-item" },
        ],
        INLINE_STYLE_BUTTONS: [
            { label: "Bold", style: "BOLD", className: "custom-css-class" },
            { label: "Italic", style: "ITALIC" },
            { label: "Underline", style: "UNDERLINE" },
        ],
        display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "HISTORY_BUTTONS"],
    };

    constructor(props: any) {
        super(props);
        const { value } = props;
        this.state = {
            value: value ? RichTextEditor.createValueFromString(value, "html") : RichTextEditor.createEmptyValue(),
        };
    }

    public setValue(value: string) {
        this.setState({ value: RichTextEditor.createValueFromString(value, "html") });
    }

    private onChange = (value: any) => {
        this.setState({ value });
        const { onChange, name } = this.props;
        if (onChange) {
            onChange(value.toString("html"), name);
        }
    };

    public clearInput() {
        this.setState({ value: RichTextEditor.createEmptyValue() });
    }

    public render() {
        const { value } = this.state;

        return <RichTextEditor value={value} onChange={this.onChange} toolbarConfig={this.toolbarConfig} />;
    }
}

export default CustomRichTextEditor;
