import { FC, useContext } from "react";
import { Pagination, PaginationProps } from "semantic-ui-react";

import { PatientSearchContext } from "contextProviders/PatientSearchProvider";
import { PatientSearchActions } from "contextProviders/modules/patientSearch/actions";

const PatientSearchPagination: FC = () => {
    const {
        state: { currentPage, totalPages },
        dispatch,
    } = useContext(PatientSearchContext);

    const handlePaginationChange = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: PaginationProps) => {
        const { activePage } = data;
        dispatch({ type: PatientSearchActions.SET_RESULTS_PAGE, currentPage: Number(activePage) });
    };

    return (
        <Pagination
            data-testid="patient-search-results-pagination"
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={totalPages}
            nextItem={{
                "aria-label": "Next item",
                "content": "Next",
            }}
            prevItem={{
                "aria-label": "Next item",
                "content": "Prev",
            }}
            activePage={currentPage}
            onPageChange={handlePaginationChange}
        />
    );
};

export default PatientSearchPagination;
