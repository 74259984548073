import { FC } from "react";
import { Grid, Header } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";

import ViewEditMode from "model/viewEditMode";
import { IStructuredTemplateField } from "model/organisation";

export interface IDeleteFieldProperties {
    index: number;
    isChild: boolean;
    childIndex?: number;
}

interface IDeleteTemplateFieldConfirmationProps {
    fields: IStructuredTemplateField[];
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    setFields: (newFields) => void;
    deleteFieldProperties: IDeleteFieldProperties;
    mode: ViewEditMode;
}

const DeleteTemplateFieldConfirmation: FC<IDeleteTemplateFieldConfirmationProps> = ({
    fields,
    setFields,
    showModal,
    setShowModal,
    deleteFieldProperties,
    mode,
}) => {
    const onDelete = (properties: IDeleteFieldProperties): void => {
        const { index, isChild, childIndex } = properties;
        const updatedFields = JSON.parse(JSON.stringify(fields));
        const targetPath = isChild ? updatedFields[index].childStructuredFields[childIndex] : updatedFields[index];

        if (mode === ViewEditMode.EDIT) {
            targetPath.removed = true;
            delete targetPath.isEdited;
        } else if (isChild) {
            updatedFields[index].childStructuredFields.splice(childIndex, 1);
        } else {
            updatedFields.splice(index, 1);
        }

        setFields(updatedFields);
        setShowModal(false);
    };

    return (
        <ModalDialog
            open={showModal}
            iconSrc="/images/icons/icon-warning-red-bg.svg"
            onClose={() => setShowModal(false)}
            maxWidth="sm"
            disableBackdropClick
            buttons={[
                {
                    onClick: () => onDelete(deleteFieldProperties),
                    text: "Yes",
                },
                {
                    onClick: () => setShowModal(false),
                    text: "Cancel",
                },
            ]}
        >
            <Grid className="grid-dialog-body">
                <Header as="h3">Are you sure you want to delete this question?</Header>
            </Grid>
        </ModalDialog>
    );
};

export default DeleteTemplateFieldConfirmation;
