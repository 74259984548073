export enum ContactableOverWeekends {
    No = "No",
    Yes = "Yes",
}

export enum ReviewFields {
    name = "First Name",
    surname = "Last Name",
    addressLine1 = "Address Line 1",
    addressLine2 = "Address Line 2",
    addressLine3 = "Address Line 3",
    country = "Country",
    city = "Town / City",
    postCode = "Post Code",
    birth = "Date of birth",
    mobileNumber = "Phone number",
    gender = "Gender",
}

export enum RegistrationQuestionType {
    EXCLUSION = "EXCLUSION",
    REMOTE_OTHER = "REMOTE_OTHER",
}

export interface IRegistrationQuestionOption {
    blocking: boolean;
    option: string;
    optionOrder: number;
    removed: boolean;
    uuid: string;
}

export interface IRegistrationQuestion {
    copyOfUuid?: string | null;
    createdBy: string;
    creationDate: string;
    lastModificationDate: string;
    lastModifiedBy: string;
    options: IRegistrationQuestionOption[];
    organisationUuid: string;
    question: string;
    questionOrder: number;
    questionType: string;
    removed: boolean;
    required: boolean;
    uuid: string;
}

export interface IPersonalDataInput {
    identifier: boolean;
    displayName: string;
    lastModifiedBy: string;
    displayOrder: number;
    type: string;
    creationDate: string;
    uuid: string;
    required: boolean;
    organisationUuid: string;
    lastModificationDate: string;
    removed: boolean;
    propertyName: string;
    createdBy: string;
    registration: boolean;
    copyOfUuid: string | null;
}

export interface IRegistrationTexts {
    whatHappens: string;
    deliveryKit: string;
    consent: string;
    payment: string;
}

export interface IPersonalAddress {
    postcode: string;
    postcode_inward: string;
    postcode_outward: string;
    post_town: string;
    dependant_locality: string;
    double_dependant_locality: string;
    thoroughfare: string;
    dependant_thoroughfare: string;
    building_number: string;
    building_name: string;
    sub_building_name: string;
    po_box: string;
    department_name: string;
    organisation_name: string;
    udprn: number;
    umprn: string;
    postcode_type: string;
    su_organisation_indicator: string;
    delivery_point_suffix: string;
    line_1: string;
    line_2: string;
    line_3: string;
    premise: string;
    country: string;
    county: string;
    administrative_county: string;
    postal_county: string;
    traditional_county: string;
    district: string;
    ward: string;
    longitude: number;
    latitude: number;
    eastings: number;
    northings: number;
    uprn: string;
}

export interface IPersonalAddressOption {
    key: string;
    text: string;
    value: number;
}

export enum RegistrationPersonalFields {
    EMAIL = "email",
    EMAIL_REPEATED = "emailRepeated",
    PREAUTHORISATION_NUMBER = "preauthorisationNumber",
    MEMBER_NUMBER = "memberNumber",
}
