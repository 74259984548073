import { Component } from "react";
import { Grid, Table } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import QuestionaryFormElement from "components/templates/QuestionaryFormElement";

import { ICase, ILesion } from "model/case";
import { ILesionExclusion } from "model/lesionExclusion";
import { AllLesionLocation } from "model/lesionLocation";
import { IHistoryAnswerType, IHistoryQuestionType, IOrganisation } from "model/organisation";

import lesionService from "services/lesionService";

import "scss/ConfirmDialog.scss";
import "scss/Table.scss";

interface IAssessmentLesionRowProps {
    caseObject: ICase;
    lesion: ILesion;
    lesionNumber: number;
    organisation: IOrganisation;
    showExclusions: boolean;
    isManualUploadRemoteMode?: boolean;
    setExclusionAdded: (lesion: ILesionExclusion[]) => void;
}

class AssessmentLesionRow extends Component<IAssessmentLesionRowProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            addExclusion: false,
            caseObject: props.caseObject,
            edited: false,
            selectedExclusions: {
                value: {},
            },
        };
    }

    private getExclusions = () => {
        const { organisation } = this.props;
        const { lesionExclusions } = organisation;

        return lesionExclusions
            ?.filter((exclusion: any) => !exclusion.removed)
            .map((exclusion) => ({ ...exclusion, option: exclusion.exclusion }));
    };

    private getSelectedExclusions() {
        const { selectedExclusions } = this.state;
        const exclusions = this.getExclusions();

        return Object.keys(selectedExclusions.value)
            .filter((key) => selectedExclusions.value[key])
            .map((key) => ({
                exclusion: key,
                exclusionUuid: exclusions.find((exclusion) => exclusion.exclusion === key)?.uuid,
            }));
    }

    private updateExclusion = (e: any, data: any) => {
        const { selectedExclusions } = this.state;
        const { value } = selectedExclusions;

        value[data.label] = data.checked;

        this.setState({ selectedExclusions: { value } });
    };

    private addExclusion = () => {
        this.setState({ addExclusion: true });
    };

    private submitExclusion = (lesionUuid: string) => () => {
        const { caseObject } = this.state;
        const { setExclusionAdded } = this.props;
        const lesionExclusions = this.getSelectedExclusions();

        lesionService
            .updateExclusions(caseObject.uuid, caseObject.patient.uuid, lesionUuid, lesionExclusions)
            .then((response) => {
                const caseLesion = caseObject.lesions.find((lesion: ILesion) => lesion.uuid === lesionUuid);
                if (caseLesion) {
                    caseLesion.exclusions = lesionExclusions as any;
                    caseLesion.excluded = response.data.excluded;
                    this.setState({ caseObject, edited: true });
                }
            })
            .finally(() => {
                this.setState({ addExclusion: false });
            });
        setExclusionAdded(lesionExclusions);
    };

    private cancelSubmitExclusion = () => {
        this.setState({ addExclusion: false, selectedExclusions: { value: {} } });
    };

    private getExclusionQuestion = (lesionUuid: string) => {
        const exclusions = this.getExclusions();
        const question = {
            answerType: IHistoryAnswerType.CHECKBOX,
            options: exclusions as any,
            order: 1,
            question: "Do any of these exclusion criteria apply?",
            questionType: IHistoryQuestionType.EXCLUSION,
            removed: false,
            required: true,
            uuid: exclusions[0].uuid,
        };
        const { selectedExclusions } = this.state;
        const submitDisabled = !this.getSelectedExclusions().length;

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column className="update-exclusions">
                        <QuestionaryFormElement
                            question={question}
                            onChange={this.updateExclusion}
                            optionalNotes={false}
                            answer={selectedExclusions}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <CustomButton
                            variant="filled"
                            type="submit"
                            action={this.cancelSubmitExclusion}
                            text="Cancel"
                        />
                        <CustomButton
                            variant="filled"
                            type="submit"
                            disabled={submitDisabled}
                            action={this.submitExclusion(lesionUuid)}
                            text="Save"
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    public render() {
        const { caseObject, lesion, lesionNumber, showExclusions, isManualUploadRemoteMode } = this.props;
        const { addExclusion, edited } = this.state;

        const lesionExclusions = lesion.exclusions?.filter((exclusion) => !exclusion.removed);

        return (
            <Table.Row className="item">
                <Table.Cell className="assessment-main-cell" collapsing width={2}>
                    #{lesionNumber + 1}
                </Table.Cell>
                <Table.Cell width={4}>{`${AllLesionLocation[lesion.bodyPart]} ${
                    lesion.description ? `(${caseObject.lesions[lesionNumber].description})` : ""
                }`}</Table.Cell>
                {showExclusions && (
                    <Table.Cell className="assessment-main-cell" collapsing width={3}>
                        <Grid columns="1">
                            <Grid.Row>
                                <Grid.Column>
                                    {lesionExclusions
                                        ? lesionExclusions.map((exclusion) => (
                                              <Table.Row key={exclusion.uuid}>{exclusion.exclusion}</Table.Row>
                                          ))
                                        : "None"}
                                </Grid.Column>
                            </Grid.Row>

                            {!lesion.excluded && !addExclusion && !edited && !isManualUploadRemoteMode && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <CustomButton
                                            variant="filled"
                                            disabled={addExclusion}
                                            type="submit"
                                            action={this.addExclusion}
                                            text="+ Add Exclusion"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            {edited && !addExclusion && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <CustomButton
                                            variant="filled"
                                            disabled={addExclusion}
                                            type="submit"
                                            action={this.addExclusion}
                                            text="Edit"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            )}

                            {addExclusion && <>{this.getExclusionQuestion(lesion.uuid)}</>}
                        </Grid>
                    </Table.Cell>
                )}
            </Table.Row>
        );
    }
}

export default AssessmentLesionRow;
