import { noteExceedsLimit, noteLimit } from "helpers/noteValidation";

export default function NoteExceedsLimitWarning({ note, padding }: { note: string; padding: number }) {
    const noteExceedsLimitText = (text: string) =>
        noteExceedsLimit(text)
            ? `${text.length - noteLimit} characters over the limit`
            : `${noteLimit - text.length} characters remaining`;

    const noteExceedsLimitWarningTextStyle = (text: string) =>
        noteExceedsLimit(text) ? { color: "red", paddingBottom: padding } : { paddingBottom: padding };

    return <p style={noteExceedsLimitWarningTextStyle(note)}>{noteExceedsLimitText(note)}</p>;
}
