import React, { FC } from "react";
import { Divider, Header } from "semantic-ui-react";

import CaseAddAdminNotes from "components/CaseDescription/CaseAddAdminNotes";
import CaseNotesGroup from "components/CaseDescription/CaseNotesGroup";
import CaseInfoPanel from "components/CaseDescription/CaseInfoPanel";
import CaseSasLink from "components/CaseDescription/CaseSasLink";
import CaseKitTracking from "components/CaseDescription/CaseKitTracking";

import { ICase } from "model/case";
import { IOrganisation } from "model/organisation";
import { NoteType } from "model/reviewNotes";

interface ICaseAdminPanel {
    readonly caseObject: ICase;
    readonly caseSasLink: string;
    readonly patientOrganisation?: IOrganisation;

    updateCaseNotes: (type: NoteType, note: string) => void;
}

const CaseAdministrationPanel: FC<ICaseAdminPanel> = ({
    caseObject,
    caseSasLink,
    patientOrganisation,

    updateCaseNotes,
}) => {
    const { uuid: caseUuid, remote } = caseObject;
    return (
        <div className="section-separate">
            <div>
                <Header as="h2" className="without-bottom-margin">
                    Admin panel
                </Header>
                <Divider className="title-divider" />

                <CaseInfoPanel caseObject={caseObject} patientOrganisation={patientOrganisation} />

                {remote && <CaseKitTracking caseObject={caseObject} />}

                <CaseSasLink currentCaseUuid={caseUuid} currentCaseSasLink={caseSasLink} />

                <CaseAddAdminNotes currentCaseUuid={caseUuid} updateCaseNotes={updateCaseNotes} />
                <CaseNotesGroup
                    caseObject={caseObject}
                    renderAddNotesButton={false}
                    type={NoteType.ADMIN}
                    updateCaseNotes={updateCaseNotes}
                />
            </div>
        </div>
    );
};

CaseAdministrationPanel.defaultProps = {
    patientOrganisation: null,
};

export default CaseAdministrationPanel;
