import { AxiosResponse } from "axios";

import { Config } from "config/config";

import { HttpConnector } from "dao/http/httpConnector";

import store from "redux/store";
import * as actions from "redux/actions";

import * as ErrorService from "services/errorService";

const REFRESH_TOKEN_URL = `${Config.getUsersMsUrl()}/api/v1/auth/token`;

export class AuthorizedHttpConnector {
    public static async sendPost(url: string, body: any, isArrayBuffer?: boolean): Promise<AxiosResponse<any>> {
        try {
            return await HttpConnector.sendPost(
                url,
                body,
                AuthorizedHttpConnector.getAuthorizationHeaders(isArrayBuffer)
            );
        } catch (err: any) {
            if (err.response?.status === 401) {
                await AuthorizedHttpConnector.refreshToken(err);

                try {
                    return await HttpConnector.sendPost(
                        url,
                        body,
                        AuthorizedHttpConnector.getAuthorizationHeaders(isArrayBuffer)
                    );
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            }

            throw err;
        }
    }

    public static async sendGet(url: string, arrayBuffer?: boolean): Promise<AxiosResponse<any>> {
        try {
            return await HttpConnector.sendGet(url, AuthorizedHttpConnector.getAuthorizationHeaders(arrayBuffer));
        } catch (err: any) {
            if (err.response?.status === 401) {
                await AuthorizedHttpConnector.refreshToken(err);
                return await HttpConnector.sendGet(url, AuthorizedHttpConnector.getAuthorizationHeaders(arrayBuffer));
            }

            throw err;
        }
    }

    public static async sendPut(url: string, body: any): Promise<AxiosResponse<any>> {
        try {
            return await HttpConnector.sendPut(url, body, AuthorizedHttpConnector.getAuthorizationHeaders());
        } catch (err: any) {
            if (err.response?.status === 401) {
                await AuthorizedHttpConnector.refreshToken(err);
                return await HttpConnector.sendPut(url, body, AuthorizedHttpConnector.getAuthorizationHeaders());
            }

            throw err;
        }
    }

    public static async sendDelete(url: string): Promise<AxiosResponse<any>> {
        try {
            return await HttpConnector.sendDelete(url, AuthorizedHttpConnector.getAuthorizationHeaders());
        } catch (err: any) {
            if (err.response?.status === 401) {
                await AuthorizedHttpConnector.refreshToken(err);
                return await HttpConnector.sendDelete(url, AuthorizedHttpConnector.getAuthorizationHeaders());
            }

            throw err;
        }
    }

    public static async refreshToken(error: any) {
        if (store.store.getState().auth.refreshToken) {
            try {
                const { refreshToken } = store.store.getState().auth;
                const response = await HttpConnector.sendPost(
                    REFRESH_TOKEN_URL,
                    {},
                    AuthorizedHttpConnector.getRefreshTokenHeaders(refreshToken)
                );
                if (response.headers && response.status === 200) {
                    store.store.dispatch(actions.authActions.refreshAuthToken(response.headers.token));
                }

                return response.headers.token;
            } catch (err: any) {
                ErrorService.handleError(err);
            }
        } else {
            ErrorService.handleError(error);
        }

        return undefined;
    }

    private static getAuthorizationHeaders(arrayBuffer?: boolean) {
        const headers = {
            "Content-Type": "application/json",
            "authorization": `Bearer ${store.store.getState().auth.authToken}`,
        };

        if (arrayBuffer) {
            return {
                headers,
                responseType: "arraybuffer",
            };
        }
        return { headers };
    }

    private static getRefreshTokenHeaders(token: string) {
        const headers = {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`,
        };

        return { headers };
    }
}

export default new AuthorizedHttpConnector();
