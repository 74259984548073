import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { DropdownProps, Form, Icon, InputOnChangeData, Message } from "semantic-ui-react";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { Moment } from "moment";

import CustomButton from "components/templates/Button";
import { PatientDetailsValidation, MAX_AGE } from "components/PatientDetails/PatientDetailsValidation";
import InputDateField from "components/InputDateField";

import { PatientSearchContext } from "contextProviders/PatientSearchProvider";
import { PatientSearchActions } from "contextProviders/modules/patientSearch/actions";

import createDropdownOptions from "helpers/semanticUi";
import { getStringWithoutBackspaces } from "helpers/patientUtils";
import { doesStringContainBackspace, formatPostcode, checkFormForEmptyFields } from "helpers/patientSearch";
import { formatMomentDateForForm } from "helpers/datetime";

import {
    FieldsGroups,
    FormFields,
    Gender,
    IDisabledFields,
    IPatientSearchForm as IForm,
    IPatientSearchFormError,
} from "model/patientSearch";

import { NEW_ASSESSMENT } from "navigation/routes";

import { getOrganisation } from "redux/selectors/data";

interface IPatientSearchForm {
    form: IForm;
    isFetchingData: boolean;
    isRequestError: boolean;
    setForm: (value: IForm) => void;
    getPatients: () => void;
    resetForm: () => void;
}

const initialDisabledFields = {
    [FieldsGroups.single]: false,
    [FieldsGroups.advanced]: false,
};

const initialErrors: IPatientSearchFormError = {
    formError: "",
    showFieldErrors: false,
    requestError: "",
    [FormFields.nhsNumber]: undefined,
    [FormFields.hospitalNumber]: undefined,
    [FormFields.dateOfBirth]: undefined,
};

const fieldToGroup = {
    [FormFields.nhsNumber]: FieldsGroups.single,
    [FormFields.hospitalNumber]: FieldsGroups.single,
    [FormFields.dateOfBirth]: FieldsGroups.advanced,
    [FormFields.name]: FieldsGroups.advanced,
    [FormFields.surname]: FieldsGroups.advanced,
    [FormFields.gender]: FieldsGroups.advanced,
    [FormFields.postCode]: FieldsGroups.advanced,
};

const NHS_NUMBER_EMPTY_MASK = "___ ___ ____";
const EVENT_TYPE_BLUR = "blur";

const PatientSearchForm: FC<IPatientSearchForm> = ({
    form,
    isFetchingData,
    isRequestError,
    setForm,
    getPatients,
    resetForm,
}) => {
    const {
        dispatch,
        state: { showResults, expandedViewPatient, searchFormData, showAdvancedSearch },
    } = useContext(PatientSearchContext);
    const [errors, setErrors] = useState<IPatientSearchFormError>(initialErrors);
    const [disabledFields, setDisabledFields] = useState<IDisabledFields>(initialDisabledFields);
    const organisation = useSelector(getOrganisation);

    useEffect(() => {
        if (!expandedViewPatient && showResults) {
            const { areAdvancedAdditionalFieldsEmpty } = checkFormForEmptyFields(searchFormData);

            setDisabledFields({
                [FieldsGroups.single]: true,
                [FieldsGroups.advanced]: areAdvancedAdditionalFieldsEmpty,
            });
        }
    }, [expandedViewPatient, searchFormData, showResults]);

    const { minimumAge } = organisation;

    const handleClearClicked = (): void => {
        resetForm();
        setErrors(initialErrors);
        setDisabledFields(initialDisabledFields);
    };

    const getIsValid = (): boolean => {
        const isDateOfBirthValid = !errors[FormFields.dateOfBirth];
        const isNhsNumberValid = !errors[FormFields.nhsNumber];
        const isHospitalNumberValid = !errors[FormFields.hospitalNumber];
        const { isDateOfBirthEmpty, isHospitalNumberEmpty, isNhsNumberEmpty, areAdvancedAdditionalFieldsEmpty } =
            checkFormForEmptyFields(form);

        if (isHospitalNumberEmpty && isNhsNumberEmpty && !showAdvancedSearch) {
            setErrors({ ...errors, formError: "Please enter the NHS Number or Hospital Number." });
            return false;
        }
        if (
            isHospitalNumberEmpty &&
            isNhsNumberEmpty &&
            showAdvancedSearch &&
            areAdvancedAdditionalFieldsEmpty &&
            isDateOfBirthEmpty
        ) {
            setErrors({ ...errors, formError: "Please enter the Date of Birth, NHS Number or Hospital Number." });
            return false;
        }
        if (!isDateOfBirthEmpty && areAdvancedAdditionalFieldsEmpty) {
            setErrors({ ...errors, formError: "Please complete at least 1 other field." });
            return false;
        }
        if (isDateOfBirthEmpty && !areAdvancedAdditionalFieldsEmpty) {
            setErrors({ ...errors, formError: "Please enter the Date of Birth." });
            return false;
        }

        if (
            (!isDateOfBirthEmpty && isDateOfBirthValid) ||
            (!isNhsNumberEmpty && isNhsNumberValid) ||
            (!isHospitalNumberEmpty && isHospitalNumberValid)
        ) {
            setErrors(initialErrors);
            return true;
        }

        setErrors({ ...errors, showFieldErrors: true, formError: "" });
        return false;
    };

    const handleSubmitClicked = async (): Promise<void> => {
        const isValid = getIsValid();
        if (isValid) {
            await getPatients();
        }
    };

    const validateValue = (fieldName: FormFields, value: string): void => {
        let error;
        const isValueEmpty = Boolean(value);
        switch (fieldName) {
            case FormFields.nhsNumber: {
                const isNhsNumberInvalid = isValueEmpty && PatientDetailsValidation.isWrongNHSNumber(value);
                error = isNhsNumberInvalid ? "NHS number is invalid" : undefined;
                break;
            }
            case FormFields.hospitalNumber: {
                const isHospitalNumberInvalid = isValueEmpty && PatientDetailsValidation.isHospitalNumberInvalid(value);
                error = isHospitalNumberInvalid ? "Hospital number is invalid" : undefined;
                break;
            }
            case FormFields.dateOfBirth: {
                const { isBiggerThanMaxAge, isLowerThanMinAgeDefined } = PatientDetailsValidation.isAgeValid(
                    value,
                    minimumAge
                );
                if (isBiggerThanMaxAge) {
                    error = `The patient must be under ${MAX_AGE} years old`;
                }
                if (isLowerThanMinAgeDefined) {
                    error = `The patient must be aged ${minimumAge} or over to use this service.`;
                }
                if (!isBiggerThanMaxAge && !isLowerThanMinAgeDefined) {
                    error = undefined;
                }
                break;
            }
            default:
                break;
        }
        setErrors({ ...errors, [fieldName]: error });
    };

    const disableFields = (fieldName: FormFields, nextFormValue: IForm): void => {
        const value = nextFormValue[fieldName];
        const isNhsNumber = fieldName === FormFields.nhsNumber;
        const isGender = fieldName === FormFields.gender;
        let isValueEmpty = value === "";
        if (isNhsNumber) {
            const isNhsNumberEmptyMask = value === NHS_NUMBER_EMPTY_MASK;
            isValueEmpty = isValueEmpty || isNhsNumberEmptyMask;
        }
        if (isGender) {
            const isNull = value === null;
            isValueEmpty = isValueEmpty || isNull;
        }
        const group = fieldToGroup[fieldName];
        const isSingleSearchField = group === FieldsGroups.single;
        const { isDateOfBirthEmpty, areAdvancedAdditionalFieldsEmpty } = checkFormForEmptyFields(nextFormValue);
        if (isValueEmpty) {
            const areAllAdvancedFieldsEmpty = areAdvancedAdditionalFieldsEmpty && isDateOfBirthEmpty;
            if (areAllAdvancedFieldsEmpty || isSingleSearchField) {
                setDisabledFields(initialDisabledFields);
            }
        } else if (isSingleSearchField) {
            setDisabledFields({ [FieldsGroups.single]: true, [FieldsGroups.advanced]: true });
        } else {
            setDisabledFields({ [FieldsGroups.single]: true, [FieldsGroups.advanced]: false });
        }
    };

    const handleValueChanged = (
        event: ChangeEvent<HTMLInputElement> | DropdownProps,
        data: InputOnChangeData | DropdownProps
    ): void => {
        const { value, name } = data;
        const isHospitalNumber = name === FormFields.hospitalNumber;
        const isGender = name === FormFields.gender;
        let formattedValue: string = value.toString();
        if (isHospitalNumber) {
            formattedValue = getStringWithoutBackspaces(formattedValue);
        }
        if (isGender) {
            const isBlurEventType = event.type === EVENT_TYPE_BLUR;
            formattedValue = isBlurEventType ? null : formattedValue;
        }
        const nextFormValue = {
            ...form,
            [name]: formattedValue,
        };

        setForm(nextFormValue);
        setErrors({ ...errors, formError: "" });
        validateValue(name, formattedValue);
        disableFields(name, nextFormValue);
    };

    const handleMaskChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleValueChanged(e, {
            name: e.target.name,
            value: e.target.value,
        });
    };

    const handleShowAdvancedClicked = (): void => {
        dispatch({ type: PatientSearchActions.TOGGLE_ADVANCED_SEARCH_VISIBILITY });
    };

    const applyPostcodeFormatting = () => {
        const currentPostcodeValue = form[FormFields.postCode];
        const doesPostcodeHaveBackspace = doesStringContainBackspace(currentPostcodeValue);

        if (!doesPostcodeHaveBackspace) {
            const formattedPostcode = formatPostcode(currentPostcodeValue);
            handleValueChanged(null, {
                name: FormFields.postCode,
                value: formattedPostcode,
            });
        }
    };

    const { formError } = errors;
    const showError = isRequestError || Boolean(formError);
    const isClearButtonDisabled = !Object.keys(form).find((key) => {
        const value = form[key];
        return key !== FormFields.caseUuid && value !== "" && value !== null;
    });

    return (
        <Form>
            <p>
                Search by NHS Number <b>or</b> Hospital Number
            </p>
            <Form.Group widths="equal">
                <Form.Input
                    label="NHS Number"
                    placeholder="Enter NHS Number"
                    onChange={handleValueChanged}
                    error={errors.showFieldErrors && errors[FormFields.nhsNumber]}
                    disabled={disabledFields[FieldsGroups.single] && !form[FormFields.nhsNumber].toString()}
                    id={FormFields.nhsNumber}
                    input={
                        <InputMask
                            onChange={handleMaskChange}
                            mask="999 999 9999"
                            placeholder="Enter NHS Number"
                            name={FormFields.nhsNumber}
                            id={FormFields.nhsNumber}
                            value={form[FormFields.nhsNumber].toString()}
                        />
                    }
                />

                <Form.Input
                    label="Hospital Number"
                    placeholder="Enter Hospital Number"
                    onChange={handleValueChanged}
                    value={form[FormFields.hospitalNumber]}
                    name={FormFields.hospitalNumber}
                    error={errors.showFieldErrors && errors[FormFields.hospitalNumber]}
                    disabled={disabledFields[FieldsGroups.single] && !form[FormFields.hospitalNumber]}
                    id={FormFields.hospitalNumber}
                />
            </Form.Group>
            <button
                onClick={handleShowAdvancedClicked}
                onKeyDown={handleShowAdvancedClicked}
                type="button"
                tabIndex={0}
                className="advanced-search__button"
            >
                <span className="advanced-search__text">Advanced Search</span>{" "}
                <Icon name={`angle ${showAdvancedSearch ? "up" : "down"}`} size="large" />
            </button>
            {showAdvancedSearch && (
                <div className="advanced-search__form-wrapper">
                    <p>
                        Search by Date of Birth <b>and</b> another field.
                    </p>
                    <Form.Group widths="4">
                        <InputDateField
                            value={form[FormFields.dateOfBirth]}
                            label="Date of Birth"
                            onChange={(value: Moment) => {
                                handleValueChanged(undefined, {
                                    value: formatMomentDateForForm(value),
                                    name: FormFields.dateOfBirth,
                                });
                            }}
                            dateError={errors.showFieldErrors && errors[FormFields.dateOfBirth]}
                            disabled={disabledFields[FieldsGroups.advanced]}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Given Name(s)"
                            placeholder="Enter the given name(s)"
                            onChange={handleValueChanged}
                            value={form[FormFields.name]}
                            name={FormFields.name}
                            disabled={disabledFields[FieldsGroups.advanced]}
                            id={FormFields.name}
                        />
                        <Form.Input
                            label="Surname"
                            placeholder="Enter the family name"
                            onChange={handleValueChanged}
                            value={form[FormFields.surname]}
                            name={FormFields.surname}
                            disabled={disabledFields[FieldsGroups.advanced]}
                            id={FormFields.surname}
                        />
                        <Form.Select
                            data-testid="patient-search-form-gender-select"
                            placeholder="Select gender"
                            value={form[FormFields.gender]}
                            onChange={handleValueChanged}
                            options={createDropdownOptions(Gender)}
                            name={FormFields.gender}
                            disabled={disabledFields[FieldsGroups.advanced]}
                            id={FormFields.gender}
                            label={{ children: "Gender", htmlFor: FormFields.gender }}
                            clearable
                            selectOnBlur={false}
                        />
                        <Form.Input
                            label="Post Code"
                            placeholder="Enter the post code"
                            onChange={handleValueChanged}
                            value={form[FormFields.postCode]}
                            name={FormFields.postCode}
                            disabled={disabledFields[FieldsGroups.advanced]}
                            id={FormFields.postCode}
                            onBlur={applyPostcodeFormatting}
                            maxLength="8"
                        />
                    </Form.Group>
                </div>
            )}
            {showError && (
                <div className="patient-search__error-wrapper">
                    <Message negative>
                        {formError || "Unable to reach Oceano PAS. Please retry or create a case manually"}
                    </Message>
                </div>
            )}
            <div className="patient-search__footer">
                {!showResults && (
                    <CustomButton
                        variant="empty"
                        type="link"
                        to={NEW_ASSESSMENT}
                        text="< Back"
                        size="small"
                        disabled={isFetchingData}
                    />
                )}
                <div />
                <div className="patient-search__buttons-wrapper">
                    <CustomButton
                        dataTestId="patient-search-form-clear-button"
                        variant="empty-dark"
                        type="button"
                        action={handleClearClicked}
                        text="Clear"
                        size="small"
                        disabled={isClearButtonDisabled}
                    />
                    <CustomButton
                        dataTestId="patient-search-form-search-button"
                        variant="filled"
                        type="submit"
                        action={handleSubmitClicked}
                        text="Search"
                        size="small"
                        disabled={isFetchingData}
                        loading={isFetchingData}
                    />
                </div>
            </div>
        </Form>
    );
};

export default PatientSearchForm;
