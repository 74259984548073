import { Dispatch, useEffect, useState } from "react";

interface IUseNavigationPrompt {
    predicate?: boolean;
    callback?: () => void;
}

interface IUseNavigationPromptReturn {
    preventUserNavigation: boolean;
    setPreventUserNavigation: Dispatch<React.SetStateAction<boolean>>;
    setReadyToProgress: Dispatch<React.SetStateAction<boolean>>;
}

const useNavigationPrompt = ({ predicate, callback }: IUseNavigationPrompt): IUseNavigationPromptReturn => {
    const [preventUserNavigation, setPreventUserNavigation] = useState<boolean>(() => Boolean(predicate));
    const [readyToProgress, setReadyToProgress] = useState<boolean>(false);

    useEffect(() => {
        setPreventUserNavigation((prevState) => Boolean(predicate) && prevState);
    }, [predicate]);

    useEffect(() => {
        if (readyToProgress && callback) {
            callback();
        }
    }, [readyToProgress]);

    return {
        preventUserNavigation,
        setPreventUserNavigation,
        setReadyToProgress,
    };
};

export default useNavigationPrompt;
