import React, { Component } from "react";
import { Divider, Form, Grid, Icon, Label, Table } from "semantic-ui-react";

import ContextIconButton from "components/templates/ContextIconButton";
import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";

import { IMedicalHistoryQuestion, IMedicalHistoryQuestionOption, IOrganisation } from "model/organisation";
import ViewEditMode from "model/viewEditMode";

import questionService from "services/questionService";

import "scss/OrganisationDetails.scss";

interface IHistoryQuestionRowProps {
    onRemove: (event: any, obj: any) => void;
    historyQuestion: IMedicalHistoryQuestion;
    organisation: IOrganisation;
}

class HistoryQuestionRow extends Component<IHistoryQuestionRowProps, any> {
    constructor(props: IHistoryQuestionRowProps) {
        super(props);
        const { historyQuestion } = props;
        const {
            answerType,
            neutralValue,
            options,
            question,
            questionOrder,
            required,
            uuid,
            copyOfUuid,
            organisationUuid,
        } = historyQuestion;
        this.state = {
            historyQuestion,
            mode: ViewEditMode.VIEW,

            row: {
                answerType,
                neutralValue,
                options,
                question,
                questionOrder,
                required,
                uuid,
                copyOfUuid,
                organisationUuid,
            },
        };
    }

    private getQuestion() {
        const { row } = this.state;
        const { organisation } = this.props;
        const { question, copyOfUuid, organisationUuid } = row;
        const { parentUuid } = organisation;

        return (
            <SettingsPropertyWrapper
                copyOfUuid={copyOfUuid}
                parentOrgUuid={parentUuid}
                propertyOrganisationUuid={organisationUuid}
            >
                {question}
            </SettingsPropertyWrapper>
        );
    }

    private getQuestionOrder() {
        const { row } = this.state;

        return row.questionOrder;
    }

    private getAnswerType() {
        const { row } = this.state;

        return row.answerType;
    }

    private getOptions() {
        const { row } = this.state;
        const { options, neutralValue } = row;

        if (neutralValue) {
            return (
                <>
                    <Label size="mini" content="neutral value" color="blue" /> {neutralValue}
                </>
            );
        }
        return options
            ? options.map((item: IMedicalHistoryQuestionOption, index: string) =>
                  HistoryQuestionRow.getOption(item, index)
              )
            : "";
    }

    private static getOption(item: IMedicalHistoryQuestionOption, key: string) {
        return (
            <div key={key}>
                <Grid columns="2">
                    <Grid.Row>
                        {item.supplementaryText ? (
                            <Grid.Column width="12">
                                <b>{item.option}</b> {item.supplementaryText}
                            </Grid.Column>
                        ) : (
                            <Grid.Column width="12">{item.option}</Grid.Column>
                        )}
                        <Grid.Column width="2" floated="right">
                            <Grid.Row>
                                {item.additionalInformation ? (
                                    <Label className="medical-question-label" size="mini" content="optional text" />
                                ) : (
                                    ""
                                )}
                            </Grid.Row>
                            <Grid.Row>
                                {item.significant ? (
                                    <Label
                                        className="medical-question-label"
                                        size="mini"
                                        content="significant"
                                        color="red"
                                    />
                                ) : (
                                    ""
                                )}
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
            </div>
        );
    }

    private getRequired() {
        const { row } = this.state;

        return row.required ? <Icon name="check" /> : <Icon name="cancel" />;
    }

    private onRemoveRow = (event: any, obj: any) => {
        const { onRemove } = this.props;
        const { row } = this.state;

        questionService.deleteQuestion(row.uuid).then(() => {
            onRemove(event, obj);
        });
    };

    private onEditRow = () => {
        this.setState({ mode: ViewEditMode.EDIT });
    };

    private onCancel = () => {
        this.setState({ mode: ViewEditMode.VIEW });
    };

    public render() {
        const { organisation, historyQuestion } = this.props;
        const { historyQuestion: historyQuestionState, mode, row } = this.state;

        const canEditQuestion =
            !historyQuestion.organisationUuid || organisation.uuid === historyQuestion.organisationUuid;

        return (
            <Table.Row key={historyQuestionState.question}>
                <Table.Cell width={3}>
                    <Form>{this.getQuestion()}</Form>
                </Table.Cell>
                <Table.Cell width={3}>
                    <Form>{this.getOptions()}</Form>
                </Table.Cell>
                <Table.Cell width={1}>
                    <Form>{this.getQuestionOrder()}</Form>
                </Table.Cell>
                <Table.Cell width={2}>
                    <Form>{this.getAnswerType()}</Form>
                </Table.Cell>

                <Table.Cell width={1}>
                    <Form>{this.getRequired()}</Form>
                </Table.Cell>

                <Table.Cell width={1}>
                    {mode === ViewEditMode.EDIT && (
                        <>
                            <ContextIconButton iconName="trash" onClick={this.onRemoveRow} uuid={row.uuid} />
                            <ContextIconButton iconName="window close" onClick={this.onCancel} />
                        </>
                    )}
                    {mode === ViewEditMode.VIEW && canEditQuestion && (
                        <ContextIconButton iconName="edit" onClick={this.onEditRow} />
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default HistoryQuestionRow;
