import React, { useState, FormEvent } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { CheckboxProps, Form, Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import useAutoLogout from "hooks/useAutoLogout";

import { IUser } from "model/user";

import { ASSESSMENT_MODE } from "navigation/routes";

import { getUser } from "redux/selectors/data";

import userService from "services/userService";

import "scss/Dialog.scss";

interface IGeneralTeledermatologyExclusionsPopup {
    showDialog: boolean;
}

const GeneralTeledermatologyExclusionsPopup = (props: IGeneralTeledermatologyExclusionsPopup) => {
    useAutoLogout();
    const user: IUser = useSelector(getUser);
    const [showPopup, setShowPopup] = useState<boolean | undefined>(
        user.preferences?.hideGeneralDermatologyExceptions || false
    );
    const [next, setNext] = useState<boolean>(false);

    const { showDialog } = props;

    const onCheckboxChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setShowPopup(data.checked);
    };

    const onConfirm = async () => {
        if (showPopup) {
            await userService.setHideGeneralTeledermatologyExceptions();
        }
        setNext(true);
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    <span>
                        Teledermatology is not always appropriate. We would recommend considering alternative pathways
                        for cases where the skin problem is:
                        <ul>
                            <li>On intimate areas (e.g. on genitals or breasts)</li>
                            <li>Significantly obscured by hair (e.g on your scalp), or on tattoos or scars</li>
                            <li>Beneath the nails, on the eyelid or lip or inside the eyes, ears, nose or mouth</li>
                        </ul>
                    </span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Form>
                        <Form.Checkbox
                            label="Don't show this message again"
                            onChange={onCheckboxChange}
                            checked={showPopup}
                        />
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    if (!next) {
        return (
            (showDialog && (
                <AbstractConfirmDialog
                    title="General teledermatology exceptions"
                    titleDividerLine
                    open={showDialog}
                    centerActionButtons
                    yesButton={{
                        backgroundColor: "#3CB5AD",
                        fontColor: "#000000",
                        onClick: onConfirm,
                        text: "Continue",
                    }}
                >
                    {popupBody}
                </AbstractConfirmDialog>
            )) ||
            null
        );
    }

    return <Redirect to={ASSESSMENT_MODE} />;
};

export default GeneralTeledermatologyExclusionsPopup;
