import React, { FC } from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import { createCaseHistory, prepareHistoryCase } from "helpers/cases";

import { ICase } from "model/case";

import "scss/Dialog.scss";

interface ICaseHistoryDialog {
    showDialog: boolean;
    callback: (arg: boolean) => void;
    currentCase: ICase;
}

const CaseHistoryDialog: FC<ICaseHistoryDialog> = ({ showDialog, callback, currentCase }) => {
    const caseHistoryData = createCaseHistory(prepareHistoryCase(currentCase));
    const notificationsData = currentCase.notifications;

    const title = notificationsData ? "Case log" : "Case history";

    const popupBody = () => (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    {notificationsData ? <h2>Case history</h2> : null}

                    {caseHistoryData.map(({ date, action, by }) => (
                        <div key={`${action}-${date}`} className="case-history-row">
                            <div className="case-history-time">
                                <span className="case-history-date">{moment(date).format("D MMM")}</span>
                                <span className="case-history-hours">{moment(date).format("HH:mm:ss")}</span>
                            </div>

                            <div className="case-history-description">{action}</div>
                            <div className="case-history-description">{by}</div>
                        </div>
                    ))}
                </Grid.Column>
            </Grid.Row>

            {notificationsData?.length ? (
                <Grid.Row>
                    <Grid.Column>
                        <h2>Notification log</h2>
                        {notificationsData.map(({ uuid, notificationType, email, creationDate }) => (
                            <div key={uuid} className="case-history-row">
                                <div className="case-history-notification">
                                    Email for <b>{notificationType}</b> sent to{" "}
                                    <b>
                                        <i>{email}</i>
                                    </b>
                                    &nbsp;at {moment(creationDate).format("MMM,D HH:mm")}
                                </div>
                            </div>
                        ))}
                    </Grid.Column>
                </Grid.Row>
            ) : null}
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                callback={callback}
                maxWidth="md"
                centerActionButtons
            >
                {popupBody()}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default CaseHistoryDialog;
