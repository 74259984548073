import { FC } from "react";

import IntegrationPatientLabel from "components/templates/IntegrationPatientLabel";
import PatientCreatedFromIntegrationLabel from "components/templates/PatientCreatedFromIntegrationLabel";

import { IIntegration } from "model/assessment";
import { PatientLabelTypes } from "model/patientSearch";

interface IPatientBannerHeader {
    integrationData: IIntegration;
    isMergedRecord?: boolean;
    isDeceased?: boolean;
}

const PatientBannerHeader: FC<IPatientBannerHeader> = ({ integrationData, isMergedRecord, isDeceased }) => (
    <div className="patient-banner-header__wrapper">
        <PatientCreatedFromIntegrationLabel integrationData={integrationData} />
        <div className="patient-banner-header__labels-wrapper">
            {isMergedRecord && <IntegrationPatientLabel type={PatientLabelTypes.MERGED_RECORD} />}
            {isDeceased && <IntegrationPatientLabel type={PatientLabelTypes.DECEASED} />}
        </div>
    </div>
);

PatientBannerHeader.defaultProps = {
    isDeceased: false,
};

export default PatientBannerHeader;
