import React, { FC } from "react";
import { Header } from "semantic-ui-react";

import ImmediateResultLesionsAssesment from "components/ImmediateResult/Lesions/ImmediateResultLesionsAssessment";

import { getFieldFromTemplate, getTemplate } from "helpers/template";

import { ICase } from "model/case";
import { GuidanceValue, IOrganisation } from "model/organisation";
import { TemplateType } from "model/templateType";
import { CaseStatus } from "model/caseStatus";

interface ISafetyNetReview {
    currentCase: ICase;
    organisation: IOrganisation;
    isExcluded: boolean;
    isException: boolean;
    guidanceValue: GuidanceValue;
}

const SafetyNetReview: FC<ISafetyNetReview> = ({
    currentCase,
    organisation,
    isException,
    isExcluded,
    guidanceValue,
}) => {
    const descriptionText = getFieldFromTemplate(
        "case_in_review_text",
        currentCase,
        organisation,
        getTemplate(TemplateType.IMMEDIATE_RESULT_TEXT, organisation)
    );

    const caseInSaReview = [CaseStatus.CASE_IN_SA_REVIEW, CaseStatus.CASE_FOR_SA_REVIEW].includes(
        currentCase.caseStatus
    );

    const isCaseCompletedOrClosed = [
        CaseStatus.CASE_FAILED,
        CaseStatus.CASE_COMPLETED,
        CaseStatus.CASE_CLOSED,
        CaseStatus.CASE_ABANDONED,
    ].includes(currentCase.caseStatus);

    let headerText = "This case is being reviewed by a Dermatologist.";

    if (isExcluded) {
        headerText = "One or more lesions were excluded from DERM analysis.";
    }

    if (isException) {
        headerText = "One or more lesions were not analysed by DERM.";
    }

    return (
        <>
            <Header as="h2">Clinician review in progress</Header>
            {caseInSaReview && <Header as="h4">{headerText}</Header>}
            <p>{descriptionText}</p>
            {isCaseCompletedOrClosed && (
                <ImmediateResultLesionsAssesment caseObject={currentCase} guidanceValue={guidanceValue} />
            )}
        </>
    );
};

export default SafetyNetReview;
