import { connect } from "react-redux";

import AiResultsScreen from "components/AiResults/AiResultsScreen";

import * as caseService from "services/caseService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    currentLesion: state.assessment.currentLesion,
});

const mapDispatchToProps = {
    getCase: caseService.getCaseForAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AiResultsScreen);
