import { FC } from "react";

import RemoteModelContainer from "components/templates/RemoteModelContainer";
import RemoteCaseStatusTimeline from "components/templates/RemoteCaseStatusTimeline";

import "scss/RemoteModel.scss";

interface ICaseStatusContainer {
    activeStatus: string;
    isAwaitingCallback?: boolean;
}

const CaseStatusContainer: FC<ICaseStatusContainer> = ({ activeStatus, isAwaitingCallback }) => (
    <RemoteModelContainer header="Case Status">
        <RemoteCaseStatusTimeline activeStatus={activeStatus} isAwaitingCallback={isAwaitingCallback} />
    </RemoteModelContainer>
);

CaseStatusContainer.defaultProps = {
    isAwaitingCallback: false,
};

export default CaseStatusContainer;
