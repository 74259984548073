import { createActions } from "redux-actions";

import types from "redux/actions/types";

export const {
    getUserMeRequest,
    getUserMeSuccess,
    getUserMeFail,
    showOrganisationSelection,
    hideOrganisationSelection,
    getMyOrganisationRequest,
    getMyOrganisationSuccess,
    getMyOrganisationFail,
    displayCaseListSnackbar,
    hideCaseListSnackbar,
    clearData,
    setPendingRequest,
    updateUserPreferences,
    updateHideGtePopup,
    setUserData,
    hideUserModalPreference,
} = createActions(
    types.GET_USER_ME_REQUEST,
    types.GET_USER_ME_SUCCESS,
    types.GET_USER_ME_FAIL,
    types.SHOW_ORGANISATION_SELECTION,
    types.HIDE_ORGANISATION_SELECTION,
    types.GET_MY_ORGANISATION_REQUEST,
    types.GET_MY_ORGANISATION_SUCCESS,
    types.GET_MY_ORGANISATION_FAIL,
    types.HIDE_CASE_LIST_SNACKBAR,
    types.CLEAR_DATA,
    types.SET_PENDING_REQUEST,
    types.UPDATE_USER_PREFERENCES,
    types.UPDATE_HIDE_GTE_POPUP,
    types.SET_USER_DATA,
    types.HIDE_USER_MODAL_PREFERENCE
);
