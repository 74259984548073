import { IUser } from "model/user";

function getUserOrganisationUuids(user: IUser): string[] {
    const additionalOrgUuids =
        user.additionalOrganisationData?.length > 0
            ? user.additionalOrganisationData.map((org) => org.organisationUuid)
            : [];
    const userOrgUuids = [user.organisationUuid, ...additionalOrgUuids];
    return userOrgUuids;
}

export default getUserOrganisationUuids;
