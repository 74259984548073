import { connect } from "react-redux";

import CaseEditPatientDetails from "components/CaseEditPatient/CaseEditPatientDetails";

import * as patientService from "services/patientService";

const mapStateToProps = (state: any) => ({
    organisation: state.data.organisation,
    pendingRequest: state.data.pendingRequest,
    user: state.data.user,
});

const mapDispatchToProps = {
    updatePatient: patientService.updatePatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseEditPatientDetails);
