import React from "react";
import { UseFormClearErrors } from "react-hook-form";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import PatientDetailsIntegration from "model/integrations";
import { IPatientLookupForm } from "model/patientLookup";

import { getPatient } from "redux/selectors/assessment";

interface ICombinationControl {
    setCombination: (combination: number) => void;
    combination: number;
    formFields: IPatientLookupForm;
    clearErrors: UseFormClearErrors<IPatientLookupForm>;
    continueWithoutVerifying: () => void;
}

const PatientLookupFormCombinationControl = ({
    setCombination,
    combination,
    formFields,
    clearErrors,
    continueWithoutVerifying,
}: ICombinationControl) => {
    const changeCombination = (): void => {
        setCombination(combination === 1 ? 2 : 1);
        clearErrors();
    };
    const isFirstCombination = combination === 1;
    const patient = useSelector(getPatient);
    const allowContinueWithoutVerifying =
        patient?.uuid && patient?.integrationInfo?.integrationName !== PatientDetailsIntegration.PDS;

    const SkipPDSVerification = (
        <button onClick={continueWithoutVerifying} type="button" className="dialogue">
            proceed without verifying patient
        </button>
    );
    return (
        <div className="combination-control">
            <div>
                {isFirstCombination
                    ? "Don't know the NHS number? Click "
                    : "Go back to search for the patient using their "}
                <button type="button" onClick={changeCombination}>
                    {isFirstCombination ? "here" : "NHS number"}
                </button>
                {isFirstCombination ? " to use different details or " : " or "}
                {allowContinueWithoutVerifying ? (
                    SkipPDSVerification
                ) : (
                    <Link
                        to={{
                            pathname: "/patient-details",
                            state: {
                                from: "patient-lookup",
                                values: {
                                    ...formFields,
                                    birth: formFields.birth ? moment(formFields.birth).format("YYYY-MM-DD") : null,
                                },
                            },
                        }}
                    >
                        enter details manually
                    </Link>
                )}
            </div>
        </div>
    );
};
export default PatientLookupFormCombinationControl;
