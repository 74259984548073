import React, { FC, useState, ChangeEvent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Input, InputOnChangeData } from "semantic-ui-react";
import FocusTrap from "focus-trap-react";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "components/templates/Button";
import ErrorMessage from "components/ErrorMessage";

import useIsEmailValid from "hooks/useIsEmailValid";

import ResetPasswordModalType from "model/resetPassword";

import { getResetPasswordModalType } from "redux/selectors/auth";
import { authActions } from "redux/actions";

import * as authorizationService from "services/authorizationService";

import "scss/ResetPasswordModal.scss";

interface IResetPasswordModalProps {
    requestIsSent: (passwordResetEmailSent: boolean) => void;
}

const ResetPasswordModal: FC<IResetPasswordModalProps> = ({ requestIsSent }) => {
    const [email, setEmail] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const resetPasswordModalType: "" | ResetPasswordModalType = useSelector(getResetPasswordModalType);
    const isEmailValid = useIsEmailValid(email);
    const dispatch = useDispatch();

    const toggleModal = (): void => {
        const modalType = resetPasswordModalType === "" ? ResetPasswordModalType.RESET : "";
        dispatch(authActions.setResetPasswordModalType(modalType));
        setIsError(false);
        setEmail("");
    };

    const enterEmail = (e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
        const emailValue = data.value;
        setEmail(emailValue);
    };

    const sendEmailRequest = async (): Promise<void> => {
        try {
            setIsError(false);
            await authorizationService.requestEmailSend(email);
            requestIsSent(true);
            dispatch(authActions.setResetPasswordModalType(""));
        } catch (e) {
            setIsError(true);
            requestIsSent(false);
        }
    };

    interface DialogTitleProps {
        children?: React.ReactNode;
        onClose?: () => void;
    }

    const DialogTitle = ({ children, onClose }: DialogTitleProps) => (
        <h2>
            {children}
            {onClose ? (
                <button type="button" onClick={toggleModal} className="modal-close-icon">
                    <img src="/images/icons/icon-close.svg" alt="Icon close" />
                </button>
            ) : null}
        </h2>
    );

    const showResetPasswordModal = resetPasswordModalType !== "";

    return (
        <div>
            <div
                onKeyDown={toggleModal}
                role="button"
                tabIndex={0}
                className="forgotten-password"
                onClick={toggleModal}
            >
                FORGOT YOUR PASSWORD?
            </div>
            <Dialog open={showResetPasswordModal} onClose={toggleModal} className="reset-password-modal">
                <FocusTrap>
                    <div>
                        <DialogTitle onClose={toggleModal}>Reset your password</DialogTitle>
                        <DialogContent>
                            <p className="reset-password-modal__subtitle ">
                                Please enter your email address. We will send you a link to reset your password
                            </p>
                            {isError && <ErrorMessage errors="An error occurred. Please try again later." />}
                            <Input
                                autoFocus
                                className="field login forgot-email"
                                type="text"
                                placeholder="Enter your email"
                                onChange={enterEmail}
                                value={email}
                            />
                        </DialogContent>
                        <DialogActions>
                            <CustomButton
                                type="submit"
                                variant="filled"
                                action={sendEmailRequest}
                                text="Reset Password"
                                disabled={!isEmailValid}
                            />
                        </DialogActions>
                    </div>
                </FocusTrap>
            </Dialog>
        </div>
    );
};

export default ResetPasswordModal;
