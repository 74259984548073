import { FILTER_ALL } from "model/filters";

function addFiltersAndSearchToUrlWithFilters(
    url: string,
    filters?: Record<string, unknown>,
    search?: Record<string, unknown>
) {
    const filterArray: string[] = [];
    const searchArray: string[] = [];
    let newUrl: string = url;

    if (filters) {
        Object.keys(filters).forEach((key) => {
            const value = filters[key];

            if (value !== FILTER_ALL) {
                filterArray.push(`${key}:${value}`);
            }
        });
    }
    if (search) {
        Object.keys(search).forEach((key) => {
            searchArray.push(`${key}:${search[key]}`);
        });
    }
    if (filterArray.length) {
        newUrl += `;${filterArray.join(";")}`;
    }
    if (searchArray.length) {
        newUrl += `&search=${searchArray.join(";")}`;
    }

    return newUrl;
}

export default addFiltersAndSearchToUrlWithFilters;
