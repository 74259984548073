import React, { FC, FormEvent } from "react";
import { Grid, CheckboxProps } from "semantic-ui-react";

import DermatologistRow from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/DermatologistRow";

import { checkedNotAvailableDermatologist } from "helpers/common";

import { IDermatologistOption } from "model/allocationConfig";

interface IAutoAllocationDermatologistList {
    list: IDermatologistOption[];
    onChangeDermatologist: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

const AutoAllocationDermatologistList: FC<IAutoAllocationDermatologistList> = ({ list, onChangeDermatologist }) => (
    <>
        {list?.length ? (
            <Grid>
                <Grid.Row>
                    <Grid.Column tablet="16" computer="12" widescreen="8">
                        <p>
                            <b>Pick from a list of Safety Net Dermatologists: </b>
                        </p>
                        <p>Greyed out Safety Net Dermatologists are momentarily flagged as not Available.</p>

                        <div className="dermatologists-table">
                            <div className="dermatologists-table-header">
                                <div className="dermatologist-name">
                                    <p>Safety Net Dermatologists</p>
                                </div>
                                <div className="dermatologist-availability">
                                    <p>Availability</p>
                                </div>
                                <div className="dermatologist-checkbox" />
                                <div className="dermatologist-link" />
                            </div>
                            <div className="dermatologists-table-body">
                                {list.map((dermatologist: IDermatologistOption) => {
                                    const { key, text, value, availability } = dermatologist;

                                    return (
                                        <DermatologistRow
                                            id={key}
                                            name={text}
                                            key={key}
                                            value={value}
                                            onChangeDermatologist={onChangeDermatologist}
                                            disabled={checkedNotAvailableDermatologist(availability)}
                                            availability={availability}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        ) : (
            <p className="dermatologists-table-empty">Sorry, there are no dermatologists in your organization.</p>
        )}
    </>
);

export default AutoAllocationDermatologistList;
