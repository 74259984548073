import { connect } from "react-redux";

import ChangePassword from "components/ChangePassword/ChangePassword";

import * as authorizationService from "services/authorizationService";
import * as ErrorService from "services/errorService";

const mapStateToProps = (state: any) => ({
    changePasswordCompleted: state.auth.changePasswordCompleted,
    formErrors: state.formErrors,
});

const mapDispatchToProps = {
    changePassword: authorizationService.changePassword,
    changePasswordReset: authorizationService.changePasswordReset,
    clearFormErrors: ErrorService.cleanFormError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
