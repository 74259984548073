import React, { FC, useState, useRef, useEffect, createContext, ReactNode, MutableRefObject } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { Button, Icon, Modal } from "semantic-ui-react";

import { ModalTypes, WithModalParameters } from "model/modal";

import "scss/Modal.scss";

interface IModalContext {
    setModalParameters: (value: WithModalParameters) => void;
    closeModal: () => void;
}

export const ModalContext = createContext<IModalContext>({} as IModalContext);

interface IModalProvider {
    children: ReactNode;
}

const INITIAL_MODAL_PARAMETERS = {
    content: null,
    isVisible: false,
};

const ModalProvider: FC<IModalProvider> = ({ children }) => {
    const [modalParameters, setModalParameters] = useState<WithModalParameters>(INITIAL_MODAL_PARAMETERS);
    const modalRef = useRef() as MutableRefObject<HTMLInputElement>;
    const location = useLocation();

    const hideModal = () => {
        setModalParameters({ ...modalParameters, isVisible: false });
    };

    useEffect(() => {
        hideModal();
    }, [location.pathname]);

    const { isVisible, content, size, type, closeCallback, className = "", closeOnOverlay = true } = modalParameters;
    const isLesionType = type === ModalTypes.LESION_IMAGE;
    const showCloseButton = type === ModalTypes.SHOW_CLOSE_BUTTON;

    let closeIcon;

    if (isLesionType) {
        closeIcon = true;
    } else if (showCloseButton) {
        closeIcon = (
            <div className="close-modal">
                <Button icon size="big">
                    <Icon name="close" />
                    Close
                </Button>
                <p>or press esc</p>
            </div>
        );
    }

    const closeModal = () => {
        if (closeOnOverlay) {
            hideModal();
            closeCallback?.();
        }
    };

    const updaters = {
        closeModal,
        setModalParameters,
    };

    return (
        <ModalContext.Provider value={updaters}>
            {children}
            <div ref={modalRef} />
            {isVisible &&
                ReactDOM.createPortal(
                    <Modal
                        open={isVisible}
                        onClose={closeModal}
                        size={size || "mini"}
                        className={className}
                        closeIcon={closeIcon}
                    >
                        {content}
                    </Modal>,
                    modalRef.current
                )}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
