import { FC } from "react";
import { Grid } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";

interface IWhatAmIBeingAskedThisQuestionModal {
    showModal: boolean;
    handleClose: () => void;
}

const WhyAmIBeingAskedThisQuestionModal: FC<IWhatAmIBeingAskedThisQuestionModal> = ({ showModal, handleClose }) => (
    <ModalDialog
        title="Why am I being asked this question?"
        open={showModal}
        onClose={handleClose}
        maxWidth="md"
        disableBackdropClick
        buttons={[
            {
                onClick: handleClose,
                text: "Got it",
                colour: "primary",
            },
        ]}
    >
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    <p>
                        When assessing a skin lesion, the way your skin responds to the sun is indicative of the level
                        of melanin it has. Melanin is a pigment that plays a crucial role in protecting the skin from
                        the harmful effects of the sun.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </ModalDialog>
);

export default WhyAmIBeingAskedThisQuestionModal;
