import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import LesionDetails from "components/templates/LesionDetails";
import LoadingSpinner from "components/templates/LoadingSpinner";
import PatientBanner from "components/templates/PatientBanner";

import { IAssessment } from "model/assessment";
import { ICase, ILesion } from "model/case";

import assessmentService from "services/assessmentService";

import { LESION_LOCATOR } from "navigation/routes";

interface IStudyAssessmentSummaryProps {
    case: ICase;
    assessment: IAssessment;
    currentLesion: number;
    addNextLesionAction: (lesionNumber: number) => void;
    finishAssessment: (uuid: string) => void;
}

class StudyAssessmentSummary extends Component<IStudyAssessmentSummaryProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            caseUuid: props.assessment.case.uuid,
            createLesion: false,
            finished: false,
        };
    }

    public getLesions() {
        const elements: JSX.Element[] = [];
        const { case: caseObject } = this.props;
        const { lesions } = caseObject;

        lesions.forEach((lesion: ILesion, index: number) => {
            if (index !== 0) {
                elements.push(<Divider />);
            }
            elements.push(<LesionDetails key={lesion.uuid} case={caseObject} lesionNumber={index} />);
        });

        return elements;
    }

    public addLesion = () => {
        const { addNextLesionAction, currentLesion } = this.props;
        addNextLesionAction(currentLesion + 1);
        this.setState({ createLesion: true });
    };

    public submit = () => {
        const { assessment } = this.props;
        const { case: caseObject } = assessment;
        assessmentService.finishAssessment(caseObject.uuid).finally(() => {
            assessmentService.completeCase(caseObject.uuid).then(() => {
                this.setState({ finished: true });
            });
        });
    };

    public render() {
        const { assessment } = this.props;
        const { createLesion, finished, caseUuid } = this.state;

        if (createLesion) {
            return <Redirect to={`${LESION_LOCATOR}/${assessment.case.lesions.length + 1}`} />;
        }

        if (finished) {
            return <Redirect to={`/case-description/${caseUuid}`} />;
        }

        if (!assessment.case) {
            return <LoadingSpinner />;
        }

        return (
            <Container className="ui segment wizzard-container">
                <PatientBanner caseId={assessment.case.caseId} patient={assessment.patient} />
                <h1>Assessment Summary</h1>
                <Divider />
                <div>{this.getLesions()}</div>
                <Divider />
                <ButtonContainer
                    button1={<CustomButton variant="filled" type="submit" action={this.addLesion} text="Add Lesion" />}
                    button2={<CustomButton variant="filled" type="submit" action={this.submit} text="Finish" />}
                />
            </Container>
        );
    }
}

export default StudyAssessmentSummary;
