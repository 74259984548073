import React, { FC, useState, FormEvent } from "react";
import { Button, Form, Header, Message, TextAreaProps } from "semantic-ui-react";

import NoteExceedsLimitWarning from "components/Notes/NoteExceedsLimitWarning";

import { isUndefined } from "helpers/common";
import { onPasteWhitespaceRemover } from "helpers/field";
import { noteExceedsLimit, noteExceedsLimitTextfieldStyle } from "helpers/noteValidation";

import { INITIAL_REQUEST_STATUS, IRequestStatus } from "model/common";
import { NoteType, NoteTypeTitle } from "model/reviewNotes";

import * as caseService from "services/caseService";

interface ICaseAddAdminNotes {
    readonly currentCaseUuid: string;
    updateCaseNotes?: (type: NoteType, note: string) => void;
}

const CaseAddAdminNotes: FC<ICaseAddAdminNotes> = ({ currentCaseUuid, updateCaseNotes }) => {
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [requestStatus, setRequestStatus] = useState<IRequestStatus>(INITIAL_REQUEST_STATUS);
    const [adminNote, setAdminNote] = useState<string>("");

    const handleNoteChange = (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        const newAdminNote = data.value as string;
        setAdminNote(newAdminNote);
    };

    const submitNote = async () => {
        setShowMessage(false);
        await caseService.addAdminNoteToCase(currentCaseUuid, adminNote);
    };

    const handleSubmitAdminNote = async () => {
        setRequestStatus({ ...requestStatus, isPending: true });
        try {
            await submitNote();
            if (updateCaseNotes) {
                updateCaseNotes(NoteType.ADMIN, adminNote);
            }

            setRequestStatus({
                ...requestStatus,
                isSuccess: true,
                isPending: false,
            });
            setAdminNote("");
            setShowMessage(true);
        } catch (err: any) {
            const errorDetails = err.response?.data.errors || [{ message: err.message }];
            setRequestStatus({
                ...requestStatus,
                error: errorDetails[0].message,
                isPending: false,
            });
            setShowMessage(true);
        }
    };

    const handleOnDismissMessage = () => {
        setShowMessage(false);
    };

    const { isPending, isSuccess, error } = requestStatus;
    const sectionTitle = `Add ${NoteTypeTitle.ADMIN}`;
    const messageText = isSuccess ? "Note added successfully" : error;
    const disableSaveNoteButton = isUndefined(adminNote) || !adminNote || noteExceedsLimit(adminNote);
    const messageColor = isSuccess ? "green" : "red";

    return (
        <div className="section-case-add-admin-notes__container">
            <Header as="h3" className="without-bottom-margin">
                {sectionTitle}
                <div className="section-case-add-admin-notes__subheader">
                    <Header.Subheader>Admin notes can be added and viewed by: Superadmin, SA admin.</Header.Subheader>
                    <Header.Subheader>Admin notes are not displayed in the report.</Header.Subheader>
                </div>
            </Header>
            <div className="section-case-add-admin-notes__actions">
                <Form className="section-case-add-admin-notes__form" onSubmit={handleSubmitAdminNote}>
                    <Form.TextArea
                        style={noteExceedsLimitTextfieldStyle(adminNote)}
                        placeholder="Enter note here..."
                        onChange={handleNoteChange}
                        onPaste={onPasteWhitespaceRemover(handleNoteChange, true)}
                        value={adminNote}
                        disabled={isPending}
                    />
                    <Button loading={isPending} type="submit" disabled={disableSaveNoteButton}>
                        Save Note
                    </Button>
                </Form>
            </div>
            <NoteExceedsLimitWarning padding={8} note={adminNote} />
            {showMessage && (
                <div className="section-case-add-admin-notes__message">
                    <Message color={messageColor} onDismiss={handleOnDismissMessage}>
                        {messageText}
                    </Message>
                </div>
            )}
        </div>
    );
};

CaseAddAdminNotes.defaultProps = {
    updateCaseNotes: () => undefined,
};

export default CaseAddAdminNotes;
