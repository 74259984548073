import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Header } from "semantic-ui-react";

import Question from "components/CaseDescription/Questions/Question";
import SignificantQuestions from "components/CaseDescription/Questions/SignificantQuestions";

import { getCaseQuestionDefinition, matchAndSortQuestionsAndDefinition } from "helpers/questionDefinition";

import { AssessmentTypes } from "model/assessment";
import { ILesion } from "model/case";
import CaseQuestionDefinitionType from "model/caseQuestionDefinitionType";
import { IMedicalHistoryQuestion } from "model/organisation";

interface ILesionQuestionsProps {
    lesion: ILesion;
    isNonSkinCancer: boolean;
    lesionNumber: number;
    organisationUuid: string;
    assessmentType?: AssessmentTypes;
}

interface ILesionQuestionsState {
    lesionQuestionsDefinition: IMedicalHistoryQuestion[];
    showAll: boolean;
}

class LesionQuestions extends Component<ILesionQuestionsProps, ILesionQuestionsState> {
    constructor(props: ILesionQuestionsProps) {
        super(props);
        const isRemoteModel = props.assessmentType === AssessmentTypes.remoteModel;
        this.state = {
            lesionQuestionsDefinition: [],
            showAll: isRemoteModel,
        };
    }

    public componentDidMount() {
        const { organisationUuid, isNonSkinCancer } = this.props;
        const questionType = isNonSkinCancer
            ? CaseQuestionDefinitionType.SKIN_COMPLAINT
            : CaseQuestionDefinitionType.LESION;
        getCaseQuestionDefinition(questionType, organisationUuid).then((response) => {
            this.setState({ lesionQuestionsDefinition: response });
        });
    }

    private onShowHide = () => {
        const { showAll } = this.state;
        this.setState({ showAll: !showAll });
    };

    public render() {
        const { lesion, assessmentType } = this.props;
        const { lesionQuestionsDefinition, showAll } = this.state;
        const isLesionQuestions = lesion.lesionHistory?.length;
        const isRemoteModel = assessmentType === AssessmentTypes.remoteModel;

        if (!lesionQuestionsDefinition.length) {
            return null;
        }

        const lesionQuestionsWithDefinition = matchAndSortQuestionsAndDefinition(
            lesion.lesionHistory,
            lesionQuestionsDefinition
        );

        return (
            (isLesionQuestions && (
                <>
                    {!isRemoteModel && (
                        <>
                            <SignificantQuestions
                                questions={lesion.lesionHistory || []}
                                questionsDefinition={lesionQuestionsDefinition}
                            />
                            <Header as="h5" className="without-bottom-margin">
                                All answers:
                                <Link to="#/" onClick={this.onShowHide}>
                                    {showAll ? " hide" : " show"}
                                </Link>
                            </Header>
                        </>
                    )}
                    {showAll &&
                        lesionQuestionsWithDefinition.map((questionWithDefinition) => (
                            <Question
                                question={questionWithDefinition.question}
                                key={questionWithDefinition.question.uuid}
                                questionDefinition={questionWithDefinition.questionDefinition}
                                isRemoteModelSummaryPage={isRemoteModel}
                            />
                        ))}
                </>
            )) ||
            null
        );
    }
}

export default LesionQuestions;
