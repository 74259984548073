import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Form, Grid, Label, Menu, Message, Pagination, Segment, Table } from "semantic-ui-react";

import ConditionalRender from "components/templates/ConditionalRender";
import LoadingSpinner from "components/templates/LoadingSpinner";
import TableRow from "components/templates/TableRow";

import { AdministrationPages, StudyManagmentSubpages } from "model/administrationPages";
import { UserRole } from "model/userRole";

import { ADMINISTRATION } from "navigation/routes";

import studyService from "services/studyService";

import "scss/Table.scss";

class StudyList extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            activePage: 0,
            studyList: undefined,
            totalPages: 0,
            totalStudies: 0,
            studyNameFilter: undefined,
            organisationNameFilter: undefined,
            errors: [],
        };
    }

    public async componentDidMount() {
        await this.loadPage(0, undefined, undefined);
    }

    private getErrorMessages() {
        const { errors } = this.state;

        if (errors) {
            return errors.map((detail: any) => (
                <Message key={detail.message} negative>
                    <p>{detail.message}</p>
                </Message>
            ));
        }
        return undefined;
    }

    private getResults() {
        const { studyList, totalStudies, activePage, totalPages } = this.state;

        if (studyList) {
            return (
                <div>
                    <Divider />
                    <Table selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Study name</Table.HeaderCell>
                                <Table.HeaderCell>Creation date</Table.HeaderCell>
                                <Table.HeaderCell>Organisation</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{StudyList.getStudyListRows(studyList)}</Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={7} textAlign="left">
                                    <Label className="table-label">
                                        <div>
                                            <Label.Detail>Total Studies: {totalStudies}</Label.Detail>
                                        </div>
                                    </Label>
                                    <Menu floated="right" pagination>
                                        <Pagination
                                            activePage={activePage + 1}
                                            boundaryRange={1}
                                            onPageChange={this.handlePaginationChange}
                                            size="small"
                                            siblingRange={1}
                                            totalPages={totalPages}
                                            firstItem={null}
                                            lastItem={null}
                                        />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
            );
        }

        return <LoadingSpinner />;
    }

    private handlePaginationChange = (e: any, { activePage }: any) => {
        const { organisationNameFilter, organisationTypeFilter } = this.state;

        this.loadPage(activePage - 1, organisationNameFilter, organisationTypeFilter);
    };

    private static getStudyListRows(studyList: any) {
        return studyList.map((study: any) => {
            const { uuid } = study;
            return (
                <TableRow key={uuid} id={uuid} link={StudyList.getStudyLink(uuid)}>
                    <Table.Cell>{study.name}</Table.Cell>
                    <Table.Cell>{new Date(study.creationDate).toLocaleString()}</Table.Cell>
                    <Table.Cell>{study.organisationName}</Table.Cell>
                </TableRow>
            );
        });
    }

    private static getStudyLink(uuid: string) {
        return `${ADMINISTRATION}/${AdministrationPages.STUDY_MANAGEMENT}/${StudyManagmentSubpages.STUDY_DETAILS}/${uuid}`;
    }

    private updateOrganisationNameFilter = (event: any, obj: any) => {
        const { organisationTypeFilter } = this.state;

        this.loadPage(0, obj.value, organisationTypeFilter);
    };

    private async loadPage(
        actualPage: number,
        studyNameFilter: string | undefined,
        organisationNameFilter: string | undefined
    ): Promise<void> {
        const searchParameters: string[] = [];

        if (studyNameFilter) {
            searchParameters.push(`name:${studyNameFilter}`);
        }

        if (organisationNameFilter) {
            searchParameters.push(`organisation:${organisationNameFilter}`);
        }

        try {
            const result = await studyService.getAllStudies(actualPage * 10, searchParameters);

            this.setState({
                activePage: actualPage,
                studyList: result.data,
                totalPages: Math.ceil(result.total / 10),
                totalStudies: result.total,
            });
        } catch (err: any) {
            this.setState({ errors: err.response.errors });
        }
    }

    public render() {
        const { studyNameFilter, organisationNameFilter } = this.state;

        return (
            <ConditionalRender requiredRole={[UserRole.SUPERADMIN]}>
                <Segment>
                    <h2>Study List</h2>
                    <Form>
                        <Grid columns={4}>
                            <Grid.Row>
                                <Grid.Column width={3} floated="right">
                                    <Link
                                        to={`${ADMINISTRATION}/${AdministrationPages.STUDY_MANAGEMENT}/${StudyManagmentSubpages.CREATE_STUDY}`}
                                    >
                                        <Button>Create new study</Button>
                                    </Link>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <Form.Input
                                        type="text"
                                        value={studyNameFilter}
                                        label="Study Name"
                                        id="studyNameFilter"
                                        placeholder="Search by study name..."
                                        onChange={this.updateOrganisationNameFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Input
                                        type="text"
                                        value={organisationNameFilter}
                                        label="Organisation Name"
                                        id="organisationNameFilter"
                                        placeholder="Search by organisation name..."
                                        onChange={this.updateOrganisationNameFilter}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>{this.getErrorMessages()}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                    {this.getResults()}
                </Segment>
            </ConditionalRender>
        );
    }
}

export default StudyList;
