import React, { FC, MouseEvent as ReactMouseEvent } from "react";
import { Grid, Icon, Menu } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

import ConditionalRender from "components/templates/ConditionalRender";

import { UserRole } from "model/userRole";

interface ISingleHeaderItem {
    onClick: (e: ReactMouseEvent<HTMLDivElement, MouseEvent> | ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    allowedRoles: UserRole[];
    text: string;
    iconName: SemanticICONS;
}

const SingleHeaderItem: FC<ISingleHeaderItem> = ({ onClick, allowedRoles, text, iconName }) => (
    <ConditionalRender requiredRole={allowedRoles}>
        <Menu.Item name="about" className="header-single-menu-item" onClick={onClick}>
            <Grid columns="2">
                <Grid.Row>
                    <Grid.Column>
                        <Icon name={iconName} size="large" />
                    </Grid.Column>
                    <Grid.Column className="header-single-menu-item-text">{text}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Menu.Item>
    </ConditionalRender>
);

export default SingleHeaderItem;
