import React, { FC, useState, useEffect } from "react";

import RemoteCaseStatusTimelineItem from "components/templates/RemoteCaseStatusTimeline/RemoteCaseStatusTimelineItem";

import {
    RemoteTimeline,
    REMOTE_TIMELINE_ROW,
    RemoteTimelineDictionary,
    RemoteTimelineShortenedDictionary,
} from "model/remoteCaseTimeline";

import "scss/RemoteModel.scss";

const IMAGES_SRC = {
    [RemoteTimeline.CONSENT]: "/images/icons/status-case-consent.svg",
    [RemoteTimeline.PERSONAL_DETAILS]: "/images/icons/status-personal-details.svg",
    [RemoteTimeline.MEDICAL_HISTORY]: "/images/icons/status-medical.svg",
    [RemoteTimeline.KIT]: "/images/icons/status-kit.svg",
    [RemoteTimeline.LESION_DETAILS]: "/images/icons/status-lesion-details.svg",
    [RemoteTimeline.LESION_IMAGES]: "/images/icons/status-lesion-images.svg",
    [RemoteTimeline.SUMMARY]: "/images/icons/status-case-summary.svg",
    [RemoteTimeline.REVIEW]: "/images/icons/status-case-review.svg",
    [RemoteTimeline.REPORT_AVAILABLE]: "/images/icons/status-report-available.svg",
    [RemoteTimeline.CASE_CLOSED]: "/images/icons/status-case-closed.svg",
};

interface ICaseStatusContainer {
    activeStatus: string;
    isAwaitingCallback?: boolean;
}

const smallDesktopBreakpoint = 1200;

const RemoteCaseStatusTimeline: FC<ICaseStatusContainer> = ({ activeStatus, isAwaitingCallback }) => {
    const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= smallDesktopBreakpoint);

    const activeStatusIndex = isAwaitingCallback
        ? REMOTE_TIMELINE_ROW.length - 1
        : REMOTE_TIMELINE_ROW.findIndex((currentStatus) => currentStatus === activeStatus);

    const handleResize = (): void => {
        setIsDesktop(window.innerWidth >= smallDesktopBreakpoint);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getVisibleStatusesMobile = () => {
        if (activeStatusIndex === 0) {
            return [0, 1, 2];
        }
        const statusesRowLenght = REMOTE_TIMELINE_ROW.length;
        if (activeStatusIndex === statusesRowLenght - 1) {
            return [statusesRowLenght - 1, statusesRowLenght - 2, statusesRowLenght - 3];
        }
        return [activeStatusIndex - 1, activeStatusIndex, activeStatusIndex + 1];
    };

    return (
        <div className="remote-modal-case-status">
            {REMOTE_TIMELINE_ROW.map((status: string, index: number) => {
                const isClosed = status === RemoteTimeline.CASE_CLOSED;
                const imageSrc = IMAGES_SRC[status];

                const RemoteDictionary = isDesktop ? RemoteTimelineDictionary : RemoteTimelineShortenedDictionary;

                const text =
                    isClosed && isAwaitingCallback
                        ? RemoteDictionary[RemoteTimeline.AWAITING_CALLBACK]
                        : RemoteDictionary[status];

                return (
                    <RemoteCaseStatusTimelineItem
                        key={status}
                        visibleStatusesMobile={getVisibleStatusesMobile()}
                        activeStatusIndex={activeStatusIndex}
                        index={index}
                        imgSrc={imageSrc}
                        text={text}
                    />
                );
            })}
        </div>
    );
};

RemoteCaseStatusTimeline.defaultProps = {
    isAwaitingCallback: false,
};

export default RemoteCaseStatusTimeline;
