import React, { FC } from "react";
import { Icon, Popup } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

interface IUserAdditionalInfo {
    notes: string;
    additionalRoleInfo: string;
}

const UserAdditionalInfo: FC<IUserAdditionalInfo> = ({ additionalRoleInfo, notes }) => {
    const infoIcon = <Icon name="info circle" />;
    const inheritedInfo = (
        <p>
            <b>Notes</b>
            <span>{ReactHtmlParser(notes)}</span>
        </p>
    );

    return (
        <>
            {(additionalRoleInfo || notes) && (
                <>
                    {additionalRoleInfo && `${additionalRoleInfo} `}
                    {notes && <Popup content={inheritedInfo} trigger={infoIcon} />}
                </>
            )}
        </>
    );
};

export default UserAdditionalInfo;
