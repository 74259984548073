import { FC, useContext } from "react";
import { Grid } from "semantic-ui-react";

import AllocationConfigurationContext from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigProvider";
import ModalDialog from "components/templates/dialog/ModalDialog";

import "scss/Dialog.scss";

const AuditConfigurationAutomatedDecisionConsentModal: FC = () => {
    const {
        auditState: { showAutomatedDecisionConsentModal },
        auditDispatchers: { toggleAutomatedDecisionWarningModal },
    } = useContext(AllocationConfigurationContext);

    const handleModalClose = () => {
        toggleAutomatedDecisionWarningModal();
    };

    if (!showAutomatedDecisionConsentModal) {
        return null;
    }

    return (
        <ModalDialog
            className="warning-modal"
            title='The "Audit routing" cannot be activated'
            open={showAutomatedDecisionConsentModal}
            iconSrc="/images/icons/icon-warning-yellow-bg.svg"
            onClose={handleModalClose}
            maxWidth="md"
            disableBackdropClick
            buttons={[
                {
                    onClick: handleModalClose,
                    text: "Understood",
                    colour: "primary",
                },
            ]}
        >
            <Grid className="grid-dialog-body">
                <Grid.Row>
                    <Grid.Column>
                        This function is only available in autonomous pathways, so in order to activate it you will need
                        to enable the &quot;Automated decision making&quot; consent first.
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </ModalDialog>
    );
};

export default AuditConfigurationAutomatedDecisionConsentModal;
