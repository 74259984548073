import { FC, ReactNode, ReactNodeArray } from "react";
import { Image } from "semantic-ui-react";

interface ICopyBlock {
    title?: string;
    icon?: string;
    children: ReactNode | ReactNodeArray;
}

const CopyBlock: FC<ICopyBlock> = ({ title, icon, children }) => (
    <div className="custom__wrapper">
        {title && icon && (
            <div className="remote-model__copies-header">
                <Image alt={`${title} icon`} src={`/images/icons/${icon}.svg`} />
                <p>{title}</p>
            </div>
        )}
        {children}
    </div>
);

CopyBlock.defaultProps = {
    title: "",
    icon: "",
};

export default CopyBlock;
