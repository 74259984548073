import { FC, useState, useEffect, SyntheticEvent, FormEvent, useContext } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, InputOnChangeData, CheckboxProps, RadioProps, DropdownProps } from "semantic-ui-react";
import clsx from "clsx";

import { history } from "App";

import MobileNumberVerificationModal from "components/RemoteModel/modals/MobileNumberVerificationModal";
import ReviewList from "components/RemoteModel/RegistrationScreen/components/ReviewList";
import RegistrationForm from "components/RemoteModel/RegistrationScreen/components/RegistrationForm";
import PatientDetailsValidation from "components/PatientDetails/PatientDetailsValidation";
import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";

import AtHomeFlowContext from "contextProviders/AtHomeFlowProvider";
import { AtHomeFlowActionTypes } from "contextProviders/modules/atHomeFlow/actions";

import { scrollToError } from "helpers/assessment";

import useNavigationPrompt from "hooks/useNavigationPrompt";

import { IValidationErrorsRemoteFlow } from "model/assessment";
import { IPersonalAddress, IPersonalAddressOption } from "model/registration";
import QuestionType from "model/questionType";
import { IDefinedPersonalData } from "model/organisation";
import { IPatientEdit } from "model/patientEdit";
import { IPatientForm } from "model/remoteModel";
import { ContactPartsOfDay, ICase } from "model/case";
import { RemoteTimeline } from "model/remoteCaseTimeline";
import NavigationMessages from "model/navigation";

import { REMOTE_MODEL_CASES_MEDICAL_HISTORY } from "navigation/remoteModelRoutes";

import * as actions from "redux/actions";
import { getOrganisation } from "redux/selectors/data";
import { getAssessment } from "redux/selectors/assessment";
import { getRegistration } from "redux/selectors/registration";

import * as caseService from "services/caseService";
import registrationService from "services/registrationService";
import * as patientService from "services/patientService";

import { isAutomationTestVerifyMobileNumber } from "helpers/automationTestHelper";
import {
    ADDRESS_INPUT_TEXT,
    ADDRESS_LINE_1_INPUT_TEXT,
    ADDRESS_LINE_2_INPUT_TEXT,
    ADDRESS_LINE_3_INPUT_TEXT,
    CITY_INPUT_TEXT,
    CONTACT_ON_WEEKEND,
    CONTACT_TIME,
    PHONE_INPUT_TEXT,
    POSTCODE_INPUT_TEXT,
} from "helpers/definedPersonalData";
import { formatMobileNumber, getPatientData, validateMobileNumber } from "helpers/patientUtils";
import { capitalizeFirstLetter, isUndefined } from "helpers/common";
import { removeFocus, scrollToTop } from "helpers/page";

import "scss/RemoteModel.scss";

const initialForm = {
    name: "",
    surname: "",
    postCode: "",
    mobileNumber: "",
    verifiedMobileNumber: "",
    gender: "",
    addressInput: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    country: "",
    contactPreferences: {
        weekend: undefined,
        partsOfDay: [],
    },
};

const EXISTING_PATIENT_FIELDS_TO_IGNORE = [
    POSTCODE_INPUT_TEXT,
    ADDRESS_LINE_1_INPUT_TEXT,
    ADDRESS_LINE_2_INPUT_TEXT,
    ADDRESS_LINE_3_INPUT_TEXT,
    CITY_INPUT_TEXT,
];

const RegistrationScreen: FC = () => {
    const dispatch = useDispatch();

    const organisation = useSelector(getOrganisation);
    const assessment = useSelector(getAssessment);
    const registration = useSelector(getRegistration);

    const { dispatch: atHomeFlowDispatch } = useContext(AtHomeFlowContext);

    const [form, setForm] = useState<IPatientEdit>(initialForm);
    const [addressList, setAddressList] = useState<IPersonalAddress[] | null>(null);
    const [addressListError, setAddressListError] = useState<string>("");
    const [addressListOptions, setAddressListOptions] = useState<IPersonalAddressOption[]>();
    const [visibleMobileNumberVerificationModal, setVisibleMobileNumberVerificationModal] = useState<boolean>(false);
    const [reviewMode, setReviewMode] = useState<boolean>(false);
    const [verifyMobileNumberError, setVerifyMobileNumberError] = useState("");
    const [validationErrors, setValidationErrors] = useState<IValidationErrorsRemoteFlow>({
        isValid: true,
        errorsList: [],
        isVisible: false,
        contactPreferencesError: false,
    });

    const { case: currentCase } = assessment;
    const { mobileNumber, verifiedMobileNumber } = form;
    const { definedPersonalData, patientDetailsIntegrationType } = organisation;
    const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;

    const { preventUserNavigation, setPreventUserNavigation, setReadyToProgress } = useNavigationPrompt({
        predicate: isAtHomeFlow,
        callback: () => {
            history.push(REMOTE_MODEL_CASES_MEDICAL_HISTORY);
        },
    });

    const registerTitle = isAtHomeFlow ? "Patient Details" : "Register your details below";
    const title = reviewMode ? "Review details" : registerTitle;
    const subtitle = reviewMode
        ? "Please check your details are correct. This helps us get the skin check kit delivered to you as fast as possible."
        : "Enter your details so we know where to send your skin check kit.";

    const personalDataFields = definedPersonalData
        .filter(
            (field: IDefinedPersonalData) =>
                !field.removed &&
                !field.registration &&
                (!patientDetailsIntegrationType || (patientDetailsIntegrationType && field.isRequestedAtManualCreation))
        )
        .sort((obj1: IDefinedPersonalData, obj2: IDefinedPersonalData) => obj1.displayOrder - obj2.displayOrder);

    const getPreviousCase = async () => {
        const previousCases: ICase[] = await caseService.getCurrentAndPreviousCase();
        const prefilledData: IPatientForm = initialForm;
        const firstCase = previousCases[0];
        const secondCase = previousCases[1];
        const isFirstCaseWithPatient = Boolean(firstCase && firstCase.patient);
        const previousCase = isFirstCaseWithPatient ? firstCase : secondCase;
        const previousCasePatient = previousCase?.patient;

        if (previousCase && previousCasePatient) {
            const { patientData, contactPreferences } = previousCasePatient;
            patientData.forEach((patientDataItem) => {
                const allowToCopyFromPreviousCase = EXISTING_PATIENT_FIELDS_TO_IGNORE.includes(patientDataItem.name);
                if (!allowToCopyFromPreviousCase) {
                    const value = getPatientData({ patientDataItem });
                    prefilledData[patientDataItem.name] = value;
                }
            });
            const { weekend, partsOfDay } = contactPreferences;
            prefilledData.contactPreferences.weekend = weekend;
            prefilledData.contactPreferences.partsOfDay = partsOfDay.filter((part) => Boolean(part));
            setForm({ ...prefilledData });
        }
    };

    useEffect(() => {
        if (!isAtHomeFlow) {
            getPreviousCase();
        }
    }, []);

    useEffect(() => {
        setAddressListOptions(
            addressList?.map((item: IPersonalAddress, index: number) => {
                const { line_1: line1, line_2: line2, post_town: postTown, uprn } = item;
                const address = `${line1},${line2 ? ` ${line2}, ` : ""} ${capitalizeFirstLetter(postTown)}`;

                return {
                    key: `${uprn}${index}`,
                    text: address,
                    value: index,
                };
            })
        );
    }, [addressList]);

    useEffect(() => {
        setVerifyMobileNumberError("");
    }, [verifiedMobileNumber]);

    const onChange = (event: SyntheticEvent<HTMLElement>, data: InputOnChangeData | RadioProps | DropdownProps) => {
        const { id, value, type, name } = data;

        setAddressListError("");

        if (id === PHONE_INPUT_TEXT) {
            setVerifyMobileNumberError("");
        }

        let nextFormValue: IPatientEdit;

        if (id === ADDRESS_INPUT_TEXT) {
            const chosenAddress: IPersonalAddress = addressList ? addressList[value as string] : null;

            if (!chosenAddress) return;

            const { line_1: line1, line_2: line2, line_3: line3, post_town: postTown, country } = chosenAddress;
            nextFormValue = {
                ...form,
                [id]: value,
                addressLine1: line1,
                addressLine2: line2,
                addressLine3: line3,
                city: capitalizeFirstLetter(postTown),
                country,
            };
        } else {
            const isRadio = type === QuestionType.RADIO;
            if (isRadio) {
                removeFocus();
            }
            const key = isRadio ? String(name) : id;
            const valueFormatted = isRadio ? String(value) : value;
            nextFormValue = {
                ...form,
                [key]: valueFormatted,
            };
        }

        setForm(nextFormValue);

        setValidationErrors((prevState) => {
            const filteredErrors = prevState.errorsList.filter(({ propertyName }) => propertyName !== data.id);

            return { ...prevState, errorsList: filteredErrors };
        });
    };

    const onContactPreferencesChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        removeFocus();
        const { name, id, value } = data;
        const { contactPreferences } = form;
        const updatedContactPreferences = { ...contactPreferences };
        if (name === CONTACT_TIME) {
            const index = contactPreferences.partsOfDay.findIndex((part: ContactPartsOfDay) => part === id);
            if (index === -1) {
                updatedContactPreferences.partsOfDay.push(id as ContactPartsOfDay);
            } else {
                updatedContactPreferences.partsOfDay.splice(index, 1);
            }
        }
        if (name === CONTACT_ON_WEEKEND) {
            updatedContactPreferences.weekend = Boolean(value);
        }
        const { weekend } = updatedContactPreferences;
        setForm({ ...form, contactPreferences: updatedContactPreferences });
        if (validationErrors.contactPreferencesError) {
            const isContactPreferencesValid = !isUndefined(weekend);
            const showValidationErrors = validationErrors.isVisible || !isContactPreferencesValid;
            setValidationErrors({
                ...validationErrors,
                isVisible: showValidationErrors,
                contactPreferencesError: !isContactPreferencesValid,
            } as IValidationErrorsRemoteFlow);
        }
    };

    const toggleReviewMode = () => {
        setReviewMode(!reviewMode);
        scrollToTop();
    };

    const onContinue = () => {
        const validationResult = PatientDetailsValidation.validate(organisation, personalDataFields, form);

        if (isAtHomeFlow) {
            if (validationResult.isValid) {
                const filteredForm = Object.fromEntries(
                    Object.entries(form).filter(([key, value]) => value && key !== "contactPreferences")
                );
                atHomeFlowDispatch({
                    type: AtHomeFlowActionTypes.SET_PERSONAL_DETAILS,
                    payload: filteredForm,
                });

                setPreventUserNavigation(() => {
                    setReadyToProgress(true);

                    return false;
                });
            } else {
                setValidationErrors((prevState) => {
                    const { errorsList } = validationResult;
                    if (errorsList?.length) {
                        scrollToError({ errorUuid: errorsList[0].propertyName });
                    }

                    return {
                        ...prevState,
                        ...validationResult,
                        isVisible: true,
                    } as IValidationErrorsRemoteFlow;
                });
            }
        } else {
            const {
                contactPreferences: { weekend },
            } = form;
            const isContactPreferencesValid = !isUndefined(weekend);
            const isMobileNumberVerified = verifiedMobileNumber !== "";
            const isCurrentNumberVerified = mobileNumber === verifiedMobileNumber;
            const isMobileVerificationPassed = isMobileNumberVerified && isCurrentNumberVerified;

            setValidationErrors({
                ...validationResult,
                isVisible: true,
                contactPreferencesError: !isContactPreferencesValid,
            } as IValidationErrorsRemoteFlow);

            if (!isMobileVerificationPassed) {
                setVerifyMobileNumberError("Please verify your mobile number");
            }
            if (validationResult.isValid && isMobileNumberVerified && isContactPreferencesValid) {
                toggleReviewMode();
            }
        }
    };

    const toggleMobileNumberVerificationModal = () =>
        setVisibleMobileNumberVerificationModal(!visibleMobileNumberVerificationModal);

    const onSave = () => {
        dispatch(
            patientService.createPatient(currentCase.uuid, form, () => {
                history.push(REMOTE_MODEL_CASES_MEDICAL_HISTORY);
                const { name, surname } = form;
                const payload = { name, surname };
                dispatch(actions.dataActions.setUserData(payload));
            })
        );
    };

    const verifyMobileNumber = (): Promise<void> | undefined => {
        const isAutomationTest = isAutomationTestVerifyMobileNumber();
        const { isError, message } = validateMobileNumber(mobileNumber);
        if (isError) {
            setVerifyMobileNumberError(message);
            return undefined;
        }
        if (isAutomationTest) {
            toggleMobileNumberVerificationModal();
            return undefined;
        }
        const formattedNumber = formatMobileNumber(mobileNumber);
        return registrationService
            .verifyMobileNumber({ number: formattedNumber })
            .then(() => {
                if (!visibleMobileNumberVerificationModal) {
                    toggleMobileNumberVerificationModal();
                }
            })
            .catch((err) => {
                setVerifyMobileNumberError(err?.response?.data?.errors[0]?.message || err?.message);
            });
    };

    const handleVerify = () => {
        setForm((prevState) => ({
            ...prevState,
            verifiedMobileNumber: mobileNumber,
        }));
    };

    const onFindAddress = () => {
        registrationService
            .getAddresses(form.postCode)
            .then((result) => {
                const addresses = result.addressList;
                if (addresses.length) {
                    setAddressList(addresses);
                    setForm({
                        ...form,
                        addressInput: "",
                        addressLine1: "",
                        addressLine2: "",
                        addressLine3: "",
                        city: "",
                    });
                } else {
                    setAddressList(null);
                    setAddressListError("Please enter a valid postcode");
                }
            })
            .catch(({ message }) => setAddressListError(message));
    };

    const resetFieldValidationError = (fieldName: string): void => {
        const updatedErrorsList = validationErrors.errorsList.filter((error) => error.propertyName !== fieldName);
        setValidationErrors({ ...validationErrors, errorsList: updatedErrorsList });
    };

    return (
        <>
            <Prompt when={preventUserNavigation} message={NavigationMessages.WARN_BEFORE_NAVIGATION} />
            {!isAtHomeFlow && <CaseStatusContainer activeStatus={RemoteTimeline.PERSONAL_DETAILS} />}
            <Container className={clsx("custom__container", isAtHomeFlow && "at-home-flow")}>
                <div className="registration__wrapper">
                    <div className="is-heading">{title}</div>
                    <p>{!isAtHomeFlow && subtitle}</p>

                    {reviewMode ? (
                        <ReviewList form={form} onEdit={toggleReviewMode} onSave={onSave} />
                    ) : (
                        <RegistrationForm
                            form={form}
                            personalDataFields={personalDataFields}
                            onChange={onChange}
                            onContinue={onContinue}
                            onContactPreferencesChange={onContactPreferencesChange}
                            onFindAddress={onFindAddress}
                            onVerifyMobileNumber={verifyMobileNumber}
                            addressListOptions={addressListOptions}
                            addressListError={addressListError}
                            verifyMobileNumberError={verifyMobileNumberError}
                            validationErrors={validationErrors}
                            isAtHomeFlow={isAtHomeFlow}
                            resetFieldValidationError={resetFieldValidationError}
                        />
                    )}
                </div>
            </Container>

            {!isAtHomeFlow && (
                <MobileNumberVerificationModal
                    mobileNumber={mobileNumber}
                    onClose={toggleMobileNumberVerificationModal}
                    closeCallback={toggleMobileNumberVerificationModal}
                    show={visibleMobileNumberVerificationModal}
                    onVerify={handleVerify}
                    verifyMobileNumber={verifyMobileNumber}
                />
            )}
        </>
    );
};

export default RegistrationScreen;
