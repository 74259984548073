import { FC } from "react";
import { DialogContent } from "@mui/material";

import ModalDialog from "components/templates/dialog/ModalDialog";

interface IAddDisplayOrderDialogProps {
    showModal: boolean;
    handleClose: () => void;
}

const AddDisplayOrderDialog: FC<IAddDisplayOrderDialogProps> = ({ showModal, handleClose }) => (
    <ModalDialog
        title="Update the display order to save the changes"
        open={showModal}
        iconSrc="/images/icons/icon-warning-red-bg.svg"
        onClose={handleClose}
        maxWidth="sm"
        disableBackdropClick
        buttons={[
            {
                onClick: handleClose,
                text: "Close",
                colour: "primary",
            },
        ]}
    >
        <DialogContent>
            <p>You have left a Display Order field empty. Please enter a value and save again.</p>
        </DialogContent>
    </ModalDialog>
);

export default AddDisplayOrderDialog;
