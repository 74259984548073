import React, { FC, useState, ChangeEvent } from "react";
import { Image, Input, InputOnChangeData } from "semantic-ui-react";
import FocusTrap from "focus-trap-react";

import AnimatedDots from "components/templates/AnimatedDots";
import CustomButton from "components/templates/Button";
import SupportEmailLink from "components/templates/SupportEmailLink";

import { formatMobileNumber } from "helpers/patientUtils";

import withModal from "HOC/withModal";

import useInterval from "hooks/useInterval";

import { isAutomationTestVerifyMobileNumber } from "helpers/automationTestHelper";

import HttpStatus from "model/httpStatus";
import { ModalIconAltTexts } from "model/modal";

import registrationService from "services/registrationService";

interface IMobileVerificationModal {
    onClose: () => void;
    onVerify: () => void;
    verifyMobileNumber: () => void;
    mobileNumber: string;
}

const SHOW_SUCCESS_MODAL_TIMEOUT = 3000;
const COUNTDOWN_TIMEOUT = 1000;
const COUNTDOWN_INITIAL = 59;

const MobileNumberVerificationModal: FC<IMobileVerificationModal> = ({
    onClose,
    onVerify,
    verifyMobileNumber,
    mobileNumber,
}) => {
    const [code, setCode] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [countdownTime, setCountdownTime] = useState<number>(COUNTDOWN_INITIAL);
    const [verifiedMobileNumber, setVerifiedMobileNumber] = useState(false);

    const isCountdownFinished = countdownTime === 0;

    useInterval(
        () => {
            setCountdownTime(countdownTime - 1);
        },
        COUNTDOWN_TIMEOUT,
        isCountdownFinished
    );

    const onChange = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setCode(data.value);
        setError("");
    };

    const handleSuccessfulVerification = (): void => {
        setVerifiedMobileNumber(true);
        onVerify();
        setTimeout(() => {
            onClose();
        }, SHOW_SUCCESS_MODAL_TIMEOUT);
    };

    const handleVerify = (): void => {
        const isAutomationTest = isAutomationTestVerifyMobileNumber();
        if (isAutomationTest) {
            handleSuccessfulVerification();
        } else {
            const formattedNumber = formatMobileNumber(mobileNumber);
            registrationService
                .verifyCodeSMS({ number: formattedNumber, code })
                .then(() => {
                    handleSuccessfulVerification();
                })
                .catch((err) => {
                    setError(
                        err?.response?.status === HttpStatus.INTERNAL_ERROR
                            ? "Invalid PIN. Please try again"
                            : err?.message || "Sorry, something went wrong"
                    );
                });
        }
    };

    const resendValidationCode = () => {
        verifyMobileNumber();
        setCountdownTime(COUNTDOWN_INITIAL);
    };

    const formattedCountdownTime = String(countdownTime).padStart(2, "0");
    const resendButtonText = `Resend PIN${isCountdownFinished ? "" : ` (00:${formattedCountdownTime})`}`;
    const resendButtonStyle = isCountdownFinished ? "filled" : "dark";

    return (
        <div className="remote-model__modal">
            {!verifiedMobileNumber ? (
                <FocusTrap active>
                    <div>
                        <Image alt="Phone icon" src="/images/icons/icon-phone.svg" />
                        <p className="h2">Mobile number verification</p>
                        <div className="validate-mobile">
                            <p className="subtitle">We sent you a PIN to {mobileNumber}</p>
                            <div className={`custom-input ${error ? "with-error" : ""} `}>
                                <p>Enter your PIN</p>
                                <Input type="text" name="pin" value={code} onChange={onChange} />
                                <div className="is-error">{error}</div>
                            </div>
                            <p className="additional-info">
                                <i>
                                    If you didn’t receive the SMS, press Resend PIN after the time elapses.
                                    <br />
                                    <br />
                                    If the phone number above is wrong, you can{" "}
                                    <button type="button" className="is-button-link" onClick={onClose}>
                                        go back and edit it here
                                    </button>
                                    &nbsp;or if you’re having any other issues, please contact <SupportEmailLink />
                                </i>
                            </p>
                        </div>
                        <div className="buttons-wrapper">
                            <CustomButton
                                disabled={!isCountdownFinished}
                                type="button"
                                variant={resendButtonStyle}
                                action={resendValidationCode}
                                text={resendButtonText}
                            />
                            <CustomButton
                                disabled={!code}
                                type="button"
                                variant="filled"
                                action={handleVerify}
                                text="Verify"
                            />
                        </div>
                    </div>
                </FocusTrap>
            ) : (
                <>
                    <Image alt={ModalIconAltTexts.SUCCESS} src="/images/icons/icon-okay.svg" />
                    <p className="h2">Mobile number verified</p>
                    <p className="h4">
                        Redirecting you to register page
                        <AnimatedDots />
                    </p>
                </>
            )}
        </div>
    );
};

export default withModal(MobileNumberVerificationModal);
