import React, { useState } from "react";
import { Form, Grid, InputOnChangeData } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import { getFieldFromTemplate, getTemplate } from "helpers/template";

import useAutoLogout from "hooks/useAutoLogout";

import { ICase } from "model/case";
import { IOrganisation } from "model/organisation";
import { TemplateType } from "model/templateType";

import "scss/Dialog.scss";

interface IAbandonDialog {
    showDialog: boolean;
    callback: (arg: boolean) => void;
    caseObject: ICase;
    organisation: IOrganisation;
    abandonCase: (reason: string | undefined) => void;
}

const AbandonCaseDialog = (props: IAbandonDialog) => {
    useAutoLogout();
    const { abandonCase, showDialog, callback, caseObject, organisation } = props;
    const [abandonReason, setAbandonReason] = useState("");

    const template = organisation ? getTemplate(TemplateType.ABANDON_CASE_CONFIRMATION_POPUP, organisation) : "";
    const bodyText = organisation ? getFieldFromTemplate("message", caseObject, organisation, template) : "";
    const title = organisation ? getFieldFromTemplate("title", caseObject, organisation, template) : "";

    const onReasonChange = (event: any, data: InputOnChangeData) => {
        setAbandonReason(data.value);
    };

    const onConfirm = () => {
        abandonCase(abandonReason);
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>{bodyText}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Form>
                        <Form.Input label="Please provide reason" onChange={onReasonChange} />
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                callback={callback}
                yesButton={{
                    backgroundColor: "#2185D0",
                    fontColor: "#FFFFFF",
                    onClick: onConfirm,
                    text: "Abandon",
                }}
                noButton={{
                    backgroundColor: "#db2828",
                    fontColor: "#FFFFFF",
                    text: "Cancel",
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default AbandonCaseDialog;
