import { connect } from "react-redux";

import ClinicLocation from "components/ClinicLocation/ClinicLocation";

import { setCaseLocation } from "redux/actions/assessment";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    locationUuid: state.assessment.locationUuid,
    organisation: state.data.organisation,
    signedIn: state.auth.signedIn,
    user: state.data.user,
});

const mapDispatchToProps = {
    setCaseLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicLocation);
