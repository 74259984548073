import { FC, useRef, useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Dimmer, Header, Loader, Message } from "semantic-ui-react";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MuiGrid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import clsx from "clsx";

import LesionImagePreview from "components/templates/LesionImagePreview";
import SelectableImages from "components/CaseImgReassessment/SelectableImages";

import ImagePreviewProvider, { ImagePreviewContext } from "contextProviders/ImagePreviewProvider";

import { sortByOrder } from "helpers/common";
import convertImageFromArrayBufferToBase64 from "helpers/imageHandling";

import { CaptureImageType, CaptureImageTypeDictionary } from "model/captureImageType";
import { ILesion as ICaseLesion, IImage as ICaseImage, ICase, ISkinToneAnswer } from "model/case";
import { ILesion as IAssessmentLesion } from "model/assessment";
import { IImagePreview } from "model/imagePreview";
import { UserRole } from "model/userRole";

import * as ROUTES from "navigation/routes";

import * as caseService from "services/caseService";
import userService from "services/userService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        magnifyButton: {
            "backgroundColor": theme.palette.primary.main,
            "&:hover, &:active, &:focus": {
                backgroundColor: theme.palette.primary.main,
                boxShadow: theme.shadows[4],
            },
        },
        rotate: {
            position: "relative",
            top: "80%", // Positions the image under the "Dermoscopic Image" label
            left: 0,
            transformOrigin: "top left",
            transform: "rotate(-90deg)",
        },
        thumbnailButton: {
            "padding": 0,

            "&:hover, &:active, &:focus": {
                boxShadow: theme.shadows[8], // shadows[4] is too subtle for the image thumbnail
            },

            "&.spaced-image": {
                "margin-top": "24px",
            },

            "&.retake-image": {
                "&:before": {
                    "content": '""',
                    "display": "block",
                    "position": "absolute",
                    "background-color": "#ff7a7a",
                    "top": "-24px",
                    "left": "0",
                    "height": "24px",
                    "width": "100%",
                    "border-radius": "5px 5px 0 0",
                },
                "&$rotate": {
                    "&:before": {
                        "top": "0",
                        "left": "100%",
                        "height": "100%",
                        "width": "24px",
                        "border-radius": "0 5px 5px 0",
                    },
                },
            },
        },
        thumbnailImage: {
            width: "175px",
            height: "auto",
        },
    })
);

export enum LesionImagesType {
    RemoteFlow = "REMOTE_FLOW",
}

interface ILesionImageRow {
    images: IImagePreview[];
    containsImagesToRetake: boolean;
    onClick: (uuid: string) => void;
}

const LesionImageRow: FC<ILesionImageRow> = ({ images, containsImagesToRetake, onClick }) => {
    const { images: dermoImages, setNewImages } = useContext(ImagePreviewContext);

    const classes = useStyles();
    const [shouldRotate, setShouldRotate] = useState<boolean>(false);
    const dermoImageUuid = useRef(null);
    const gridContainerRef = useRef(null);

    if (!images?.length || images?.length === 0) {
        return null;
    }

    const handleImageLoad = (event: React.SyntheticEvent): void => {
        const imageEvent = event.target as HTMLImageElement;

        if (imageEvent.naturalHeight > imageEvent.naturalWidth) {
            setShouldRotate(true);
            setNewImages([
                ...dermoImages,
                {
                    uuid: dermoImageUuid.current,
                    shouldRotate: true,
                },
            ]);

            if (gridContainerRef?.current) {
                gridContainerRef.current.style.height = `${imageEvent.width + 50}px`; // + 50 allows height of Grid to include the image type <p>
            }
        }
    };

    return (
        <MuiGrid container item spacing={1} justifyContent="flex-start">
            {images.map((image: IImagePreview, idx: number) => {
                const { imageType, src, uuid, retake } = image;
                const isDermoscopicImage = imageType === CaptureImageType.DERMOSCOPIC;
                dermoImageUuid.current = isDermoscopicImage ? uuid : null;

                const buttonStyles: string = clsx(
                    "image-preview",
                    containsImagesToRetake && "spaced-image",
                    retake && "retake-image",
                    classes.thumbnailButton,
                    shouldRotate && classes.rotate
                );

                return (
                    <MuiGrid item key={uuid} xs={6} sm={4} md={3} ref={gridContainerRef}>
                        <p className="image-type">{CaptureImageTypeDictionary[imageType]}</p>

                        <Button onClick={() => onClick(uuid)} className={buttonStyles}>
                            <img
                                alt={`${CaptureImageTypeDictionary[imageType]} number ${idx + 1}`}
                                className={classes.thumbnailImage}
                                src={src}
                                onLoad={isDermoscopicImage ? handleImageLoad : null}
                            />
                        </Button>
                    </MuiGrid>
                );
            })}
        </MuiGrid>
    );
};

interface ILesionImagesProps {
    lesion: ICaseLesion | IAssessmentLesion;
    lesionNumber?: number;
    type?: LesionImagesType;
    selectable?: boolean;
    caseObject?: ICase;
    isCaseCompleted?: boolean;
    updateSkinToneClassification?: (result: ISkinToneAnswer[]) => void;
}

interface ILesionImagesState {
    contextImages: IImagePreview[];
    dermImages: IImagePreview[];
}

interface ILesionFullSizeImageState {
    fullSizeDermImages: IImagePreview[];
    fullSizeContextImages: IImagePreview[];
}

interface IParams {
    id: string;
}

const LesionImages: FC<ILesionImagesProps> = ({
    lesion,
    lesionNumber,
    type,
    selectable = false,
    caseObject,
    isCaseCompleted,
    updateSkinToneClassification,
}) => {
    const classes = useStyles();
    const [errorCount, setErrorCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [images, setImages] = useState<ILesionImagesState>({
        contextImages: [],
        dermImages: [],
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [activeImageUuid, setActiveImageUuid] = useState(null);
    const [fullSizeImages, setFullSizeImages] = useState<ILesionFullSizeImageState>({
        fullSizeDermImages: [],
        fullSizeContextImages: [],
    });

    const params: IParams = useParams();
    const caseId = params.id;
    const isRemoteCase = caseObject?.remote;

    const { contextImages, dermImages } = images;
    const isRemoteModel = type === LesionImagesType.RemoteFlow;

    useEffect(() => {
        if (!lesion?.images || lesion?.images?.length === 0) {
            return;
        }
        const retrievedDermImages: IImagePreview[] = [];
        const retrievedContextImages: IImagePreview[] = [];
        const retrievedFullSizeDermImages: IImagePreview[] = [];
        const retrievedFullSizeContextImages: IImagePreview[] = [];
        let retrievalErrorCount = 0;

        const notRemovedImages = (lesion.images as ICaseImage[]).filter((image) => !image.removed);

        const buildImageArrays = async (
            getImageFunc: (imageUuid: string) => Promise<string>,
            dermArray: IImagePreview[],
            contextArray: IImagePreview[],
            lesionImage: ICaseImage,
            index: number
        ): Promise<void> => {
            const { uuid, type: imageType, retake } = lesionImage;
            try {
                const presignedUrl = await getImageFunc(uuid);
                const response = await caseService.getImageFromPresignedUrl(presignedUrl);
                const result = convertImageFromArrayBufferToBase64(response);
                const resizedImage = {
                    imageType,
                    src: result,
                    order: index,
                    uuid,
                    retake: retake || false,
                };

                if (imageType === CaptureImageType.MACRO) {
                    contextArray.push(resizedImage);
                } else {
                    dermArray.push(resizedImage);
                }
            } catch (err) {
                retrievalErrorCount += 1;
            }
        };

        async function fetchImages(): Promise<void> {
            setLoading(true);
            notRemovedImages.forEach(async (lesionImage, index) => {
                await buildImageArrays(
                    caseService.getBase64Image,
                    retrievedDermImages,
                    retrievedContextImages,
                    lesionImage,
                    index
                );

                /**
                 * We will always hit this if statement as the length of retrievedContextImages and
                 * retrievedDermImages will be incremented given a retrieved image, or we increment
                 * retrievalErrorCount when image retrieval fails
                 */
                if (
                    notRemovedImages.length ===
                    retrievedContextImages.length + retrievedDermImages.length + retrievalErrorCount
                ) {
                    setErrorCount(retrievalErrorCount);
                    setImages({
                        dermImages: retrievedDermImages,
                        contextImages: retrievedContextImages,
                    });
                    setLoading(false); // loading = false to prevent infinite spinner following a thrown error
                }

                // Load full size images in the background - these should not block
                await buildImageArrays(
                    caseService.getFullSizeBase64Image,
                    retrievedFullSizeDermImages,
                    retrievedFullSizeContextImages,
                    lesionImage,
                    index
                );

                if (
                    notRemovedImages.length ===
                    retrievedFullSizeContextImages.length + retrievedFullSizeDermImages.length
                ) {
                    setFullSizeImages({
                        fullSizeDermImages: retrievedFullSizeDermImages,
                        fullSizeContextImages: retrievedFullSizeContextImages,
                    });
                }
            });
        }

        fetchImages();
    }, [lesion?.images]);

    const handleImageClick = (uuid: string) => {
        setActiveImageUuid(uuid);
        setShowModal(true);
    };

    const getLesionImageStyles = (retake: boolean): string =>
        clsx("remote-model-lesion-image", "case-summary-page", retake && "retake-image");
    const contextImagesSortedByOrder: IImagePreview[] = contextImages.sort(sortByOrder);
    const message = errorCount > 0 ? `Unable to download ${errorCount} image(s)` : "";

    if (isRemoteModel) {
        if (loading) {
            return (
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
            );
        }

        if (errorCount === lesion?.images?.length) {
            return <Message className="lesion-image-error" error content={message} />;
        }

        if (!dermImages.length && !contextImages.length) {
            return <Message className="lesion-image-error" error content="No image(s) recorded" />;
        }

        const orderedImages: IImagePreview[] = [...contextImagesSortedByOrder, ...dermImages];

        const fullSizeContextImagesSortedByOrder: IImagePreview[] =
            fullSizeImages.fullSizeContextImages.sort(sortByOrder);

        const orderedFullSizeImages: IImagePreview[] = [
            ...fullSizeContextImagesSortedByOrder,
            ...fullSizeImages.fullSizeDermImages,
        ];

        return (
            <ImagePreviewProvider>
                <LesionImagePreview
                    resizedImages={orderedImages}
                    fullSizeImages={orderedFullSizeImages}
                    imageUuid={activeImageUuid}
                    showModal={showModal}
                    setActiveImage={setActiveImageUuid}
                    onClose={() => setShowModal(false)}
                />
                <MuiGrid container className="remote-model-lesion-images">
                    <MuiGrid item xs={12} className="remote-model-lesion-image-row">
                        <Header as="h4">Dermoscopic image</Header>

                        <div className="remote-model-lesion-images">
                            {dermImages.map((contextImage) => {
                                const { src, uuid, retake } = contextImage;

                                return (
                                    <div key={uuid} className="img-preview-wrapper">
                                        <div
                                            className={getLesionImageStyles(retake)}
                                            style={{ backgroundImage: `url(${src})` }}
                                        >
                                            <IconButton
                                                aria-label="Open in image viewer"
                                                className={classes.magnifyButton}
                                                onClick={() => handleImageClick(uuid)}
                                            >
                                                <ZoomInIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </MuiGrid>

                    <MuiGrid item xs={12} className="remote-model-lesion-image-row without-bottom-padding">
                        <Header as="h4">Context images</Header>

                        <div className="remote-model-lesion-images">
                            {contextImagesSortedByOrder.map((contextImage) => {
                                const { src, uuid, retake } = contextImage;

                                return (
                                    <div key={uuid} className="img-preview-wrapper">
                                        <div
                                            className={getLesionImageStyles(retake)}
                                            style={{ backgroundImage: `url(${src})` }}
                                        >
                                            <IconButton
                                                aria-label="Open in image viewer"
                                                className={classes.magnifyButton}
                                                onClick={() => handleImageClick(uuid)}
                                            >
                                                <ZoomInIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </MuiGrid>
                    {errorCount > 0 && errorCount < lesion?.images?.length ? (
                        <MuiGrid item xs={12}>
                            <Message
                                className="lesion-image-error"
                                error
                                content={`Unable to download ${errorCount} image(s)`}
                            />
                        </MuiGrid>
                    ) : null}
                </MuiGrid>
            </ImagePreviewProvider>
        );
    }

    if (selectable && dermImages && contextImages) {
        return (
            <SelectableImages
                dermImages={dermImages}
                contextImages={contextImages}
                handleImageClick={handleImageClick}
            />
        );
    }

    const orderedImages: IImagePreview[] = [...contextImagesSortedByOrder, ...dermImages];
    const containsContextImagesToRetake: boolean = contextImagesSortedByOrder.some((image) => image.retake);
    const containsDermoscopicImagesToRetake: boolean = dermImages.some((image) => image.retake);

    const isDermatologistOrSuperAdmin = userService.checkUserHasRole([UserRole.DERMATOLOGIST, UserRole.SUPERADMIN]);

    const fullSizeContextImagesSortedByOrder: IImagePreview[] = fullSizeImages.fullSizeContextImages.sort(sortByOrder);

    const orderedFullSizeImages: IImagePreview[] = [
        ...fullSizeContextImagesSortedByOrder,
        ...fullSizeImages.fullSizeDermImages,
    ];

    return (
        <ImagePreviewProvider>
            <LesionImagePreview
                resizedImages={orderedImages}
                fullSizeImages={orderedFullSizeImages}
                imageUuid={activeImageUuid}
                showModal={showModal}
                caseObject={caseObject}
                setActiveImage={setActiveImageUuid}
                onClose={() => setShowModal(false)}
                updateSkinToneClassification={updateSkinToneClassification}
            />
            <MuiGrid container spacing={2} className="lesion-images" justifyContent="flex-start">
                <LesionImageRow
                    images={contextImagesSortedByOrder}
                    containsImagesToRetake={containsContextImagesToRetake}
                    onClick={handleImageClick}
                />
                <LesionImageRow
                    images={dermImages}
                    containsImagesToRetake={containsDermoscopicImagesToRetake}
                    onClick={handleImageClick}
                />
                {errorCount > 0 ? (
                    <MuiGrid item>
                        <p className="subtitle-gray-text">{message}</p>
                    </MuiGrid>
                ) : null}
                {isRemoteCase &&
                    (containsContextImagesToRetake ||
                        containsDermoscopicImagesToRetake ||
                        (!isCaseCompleted && isDermatologistOrSuperAdmin)) && (
                        <MuiGrid item xs={12}>
                            {containsContextImagesToRetake || containsDermoscopicImagesToRetake ? (
                                <Message warning className="image-preview retake-images-warning">
                                    <p>The request for new images has been sent to the patient.</p>
                                </Message>
                            ) : (
                                !isCaseCompleted &&
                                isDermatologistOrSuperAdmin && (
                                    <>
                                        <p className="reassess-img-question">Unable to assess these images?</p>

                                        <Link to={`${ROUTES.REQUEST_IMG_REASSESSMENT}/${caseId}/${lesionNumber}`}>
                                            Request for images to be retaken
                                        </Link>
                                    </>
                                )
                            )}
                        </MuiGrid>
                    )}
            </MuiGrid>
        </ImagePreviewProvider>
    );
};

LesionImages.defaultProps = {
    lesionNumber: 0,
    type: null,
    selectable: false,
    isCaseCompleted: false,
};

export default LesionImages;
