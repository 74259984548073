import { Moment } from "moment";

export enum Gender {
    Female = "Female",
    Male = "Male",
    NotKnown = "Not known",
    NotSpecified = "Not specified",
}

export enum PDSFields {
    itkResponseCode = "itkResponseCode",
    nhsNumber = "nhs",
    hospitalNumber = "hospitalNumber",
    dateOfBirth = "birth",
    name = "name",
    surname = "surname",
    gender = "gender",
    postcode = "postCode",
    gpIdentifier = "gpIdentifier",
    gpName = "gpName",
    gpPostcode = "gpPostcode",
    caseUuid = "caseUuid",
    dateOfDeath = "dateOfDeath",
}

export interface IPatientLookupForm {
    [PDSFields.dateOfBirth]?: Moment | string;
    [PDSFields.nhsNumber]?: string;
    [PDSFields.name]?: string;
    [PDSFields.surname]?: string;
    [PDSFields.gender]?: Gender | "";
    [PDSFields.postcode]?: string;
    [PDSFields.caseUuid]?: string;
}

export interface IPatientLookupMatched {
    [PDSFields.nhsNumber]?: string;
    [PDSFields.name]?: string;
    [PDSFields.surname]?: string;
    [PDSFields.postcode]?: string;
    [PDSFields.dateOfBirth]?: string;
    [PDSFields.gender]?: string;
    [PDSFields.gpName]?: string;
    [PDSFields.gpIdentifier]?: string;
    [PDSFields.gpPostcode]?: string;
    [PDSFields.dateOfDeath]?: string;
}

export interface IPDSPatientLookupResponse extends IPatientLookupMatched {
    [PDSFields.itkResponseCode]: string;
}

export const INITIAL_PATIENT_LOOKUP_PARAMS = {
    [PDSFields.nhsNumber]: "",
    [PDSFields.name]: "",
    [PDSFields.surname]: "",
    [PDSFields.dateOfBirth]: "",
    [PDSFields.postcode]: "",
    [PDSFields.gender]: "",
};

export const INITIAL_MATCHED_PATIENT = {
    ...INITIAL_PATIENT_LOOKUP_PARAMS,
    [PDSFields.gpName]: "",
    [PDSFields.gpIdentifier]: "",
    [PDSFields.gpPostcode]: "",
    [PDSFields.caseUuid]: "",
};

export enum PatientLookupRender {
    FORM = "lookupForm",
    MATCH = "patientMatched",
    ADDITIONAL_DETAILS = "additionalDetails",
    SAVED_DETAILS = "savedDetails",
}

export interface ITableRow {
    label: string;
    value: string;
}
