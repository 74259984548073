import { createActions } from "redux-actions";

import types from "redux/actions/types";

export const { clearCase, clearCases, getCase, getCases, getReport } = createActions(
    types.GET_CASE,
    types.GET_CASES,
    types.GET_REPORT,
    types.CLEAR_CASE,
    types.CLEAR_CASES
);
