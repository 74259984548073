import React, { FC } from "react";
import { Grid } from "semantic-ui-react";

import CopyIcon from "components/templates/CopyIcon";

import "scss/PatientDetails.scss";

interface IPatientDetail {
    readonly title: string;
    readonly personalData?: string;
    readonly showCopy?: boolean;
    readonly copyOverride?: string;
    readonly multiline?: boolean;
}

const PatientDetail: FC<IPatientDetail> = ({ title, personalData, showCopy, multiline, copyOverride }) => {
    let lines;

    if (multiline) {
        lines = personalData?.split(",")?.map((personalDataItem, index) => {
            const key = `${personalDataItem}-${index}`;
            return (
                <div className="patient-details__detail" key={key}>
                    {personalDataItem}
                </div>
            );
        });
    } else {
        lines = <span className="patient-details__detail">{personalData as string}</span>;
    }

    return (
        <Grid>
            <Grid.Row className="patient-details__details">
                <Grid.Column mobile={12} tablet={7} computer={7}>
                    {title}
                </Grid.Column>
                <Grid.Column className="patient-details__line-wrapper" mobile={12} tablet={7} computer={7}>
                    {lines}
                </Grid.Column>
                {showCopy && (
                    <Grid.Column
                        className="patient-details__copy-icon"
                        mobile={12}
                        tablet={2}
                        computer={2}
                        float="right"
                    >
                        <CopyIcon valueToCopy={personalData} title={title} copyOverride={copyOverride} />
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
};

PatientDetail.defaultProps = {
    personalData: "",
    showCopy: false,
    copyOverride: "",
    multiline: false,
};

export default PatientDetail;
