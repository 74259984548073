import { FC } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core";

import SkinTypeBurnAndTanProfile from "components/templates/SkinTypeBurnAndTanProfile";

import { mapAnswerToColour } from "helpers/fitzpatrick";

import { FitzpatrickAnswersType } from "model/fixedQuestion";

const useStyles = makeStyles(() =>
    createStyles({
        emphasis: {
            "color": "#000000",
            "fontWeight": 400,
            "marginBottom": "0px",

            "&.content": {
                fontWeight: 600,
                color: "#5D918B",
            },

            "&.tan-profile": {
                color: "#7a7a7a",
            },
        },
        skinColourIcon: {
            borderRadius: "50%",
            minHeight: "50px",
            minWidth: "50px",
            maxHeight: "50px",
            maxWidth: "50px",
            marginRight: "16px",
        },
        skinColourWrapper: {
            display: "flex",
            alignItems: "center",
        },
    })
);

interface SkinColourInterface {
    fitzpatrickAnswer: FitzpatrickAnswersType;
    text?: string;
}

const SkinColour: FC<SkinColourInterface> = ({ fitzpatrickAnswer, text }) => {
    const classes = useStyles();
    const colour = mapAnswerToColour(fitzpatrickAnswer);

    const body = text ? (
        <p className={clsx(classes.emphasis, "content")}>{text}</p>
    ) : (
        <SkinTypeBurnAndTanProfile fitzpatrickAnswer={fitzpatrickAnswer} />
    );

    return (
        <div className={classes.skinColourWrapper}>
            <div
                className={classes.skinColourIcon}
                style={{
                    backgroundColor: colour,
                }}
            />
            {body}
        </div>
    );
};

export default SkinColour;
