import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { CaseStatus } from "model/caseStatus";
import { INotificationConfiguration, NotificationType } from "model/organisation";

import URLs from "services/urls";

const { POST_NOTIFICATION_CONFIGURATION, DELETE_NOTIFICATION_CONFIGURATION, EDIT_NOTIFICATION_CONFIGURATION } = URLs;

interface INotification {
    organisationUuid: string;
    notificationType: NotificationType;
    targets?: string[];
    caseStatus?: CaseStatus | null;
    channel?: string;
    notificationTitle?: string;
    notificationContent?: string;
}

async function createNotification({
    organisationUuid,
    notificationType,
    targets,
    caseStatus,
    channel,
    notificationTitle,
    notificationContent,
}: INotification) {
    try {
        const payload: INotification = {
            notificationType,
            organisationUuid,
        };

        if (targets) payload.targets = targets;
        if (caseStatus) payload.caseStatus = caseStatus;
        if (channel) payload.channel = channel;
        if (notificationTitle) payload.notificationTitle = notificationTitle;
        if (notificationContent) payload.notificationContent = notificationContent;

        const response = await AuthorizedHttpConnector.sendPost(POST_NOTIFICATION_CONFIGURATION, payload);
        return response.data;
    } catch (err: any) {
        return err.response.data;
    }
}

async function deleteNotification(uuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(
        DELETE_NOTIFICATION_CONFIGURATION.replace("{{UUID}}", uuid)
    );
    return response.data;
}

type EditedNotification = Pick<
    INotificationConfiguration,
    "targets" | "notificationType" | "caseStatus" | "channel" | "notificationTitle" | "notificationContent"
>;

async function editNotification(
    uuid: string,
    { targets, notificationType, caseStatus, channel, notificationTitle, notificationContent }: EditedNotification
) {
    const payload: EditedNotification = {
        notificationType,
    };

    if (targets) payload.targets = targets;
    if (caseStatus) payload.caseStatus = caseStatus;
    if (channel) payload.channel = channel;
    if (notificationTitle) payload.notificationTitle = notificationTitle;
    if (notificationContent) payload.notificationContent = notificationContent;

    const response = await AuthorizedHttpConnector.sendPut(
        EDIT_NOTIFICATION_CONFIGURATION.replace("{{UUID}}", uuid),
        payload
    );
    return response.data;
}

export default {
    createNotification,
    deleteNotification,
    editNotification,
};
