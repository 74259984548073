import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { CheckboxProps, Container, Divider, Form, Message } from "semantic-ui-react";

import AssessmentFlowConfirmModal from "components/templates/AssessmentFlowConfirmModal";
import AssessmentFlowHeader from "components/templates/AssessmentFlowHeader";
import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";

import { IAssessment } from "model/assessment";
import { IOrganisation } from "model/organisation";
import { IUser } from "model/user";

import { LESION_LOCATOR, LOGIN, MEDICAL_HISTORY, SUN_EXPOSURE } from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";
import * as caseService from "services/caseService";

import { doesOrganisationHaveLocations } from "helpers/assessment";
import {
    mapFitzpatrickQuestionAnswer,
    wasCaseCreatedBeforeTheSkinQuestionWasAddedToTheOrganisation,
} from "helpers/fitzpatrick";

import "scss/MultilevelList.scss";

interface IClinicLocation {
    organisation: IOrganisation;
    assessment: IAssessment;
    signedIn: boolean;
    user: IUser;
    locationUuid: string;
    setCaseLocation: (locationUuid: string) => void;
}

interface IClinicLocationState {
    isBack: boolean;
    isEditMode: boolean;
    isEdited: boolean;
    error: string;
    locationUuid: string | undefined;
    next: boolean;
    showModal: boolean;
}

class ClinicLocation extends Component<IClinicLocation, IClinicLocationState> {
    constructor(props: IClinicLocation) {
        super(props);

        const { locationUuid } = this.props;

        this.state = {
            isBack: locationUuid !== undefined,
            isEditMode: false,
            isEdited: false,
            error: "",
            locationUuid: locationUuid || undefined,
            next: false,
            showModal: false,
        };
    }

    public componentDidMount() {
        logoutTimeoutService.stopCount();
    }

    private getLocations() {
        const { organisation } = this.props;
        const { isBack, isEditMode, locationUuid } = this.state;

        return organisation.locations
            .filter((location) => !location.removed)
            .map((location) => (
                <Form.Radio
                    key={location.uuid}
                    label={location.name}
                    name="radioGroup"
                    value={location.uuid}
                    checked={locationUuid === location.uuid}
                    onChange={this.changeLocation}
                    disabled={isBack && !isEditMode}
                />
            ));
    }

    private submit = async (withRedirect?: boolean) => {
        const {
            assessment: {
                case: { uuid },
            },
            setCaseLocation,
        } = this.props;
        const { isEditMode, isEdited, locationUuid } = this.state;

        if (isEditMode && isEdited && withRedirect) {
            this.setState({ showModal: true, isEditMode: false });
        } else {
            try {
                await caseService.setCaseLocation(uuid, locationUuid);
                setCaseLocation(locationUuid);
                if (withRedirect) {
                    this.moveForward();
                }
                this.setState({ isEditMode: false, isEdited: false, error: "" });
            } catch (e) {
                if (e instanceof Error) {
                    this.setState({ error: e.message });
                }
            }
        }
    };

    private changeLocation = (e: React.FormEvent<HTMLInputElement>, { value }: CheckboxProps) =>
        this.setState({ locationUuid: value.toString(), isEdited: true });

    private editConsent = () => {
        const { isEditMode } = this.state;

        if (isEditMode) {
            this.submit();
        } else {
            this.setState({ isEditMode: true });
        }
    };

    private closeModal = () => {
        this.setState({ showModal: false, isEditMode: true });
    };

    private moveForward = () => {
        this.setState({
            next: true,
        });
    };

    private resetEdit = () => {
        const { locationUuid, assessment } = this.props;

        this.setState({
            isEdited: false,
            locationUuid: locationUuid || assessment ? assessment.locationUuid : undefined,
        });
    };

    public render() {
        const { assessment, organisation, signedIn } = this.props;
        const { next, isBack, isEditMode, locationUuid, showModal, error } = this.state;
        const { case: currentCase } = assessment;
        const { lesions } = currentCase;

        const fitzpatrickQuestion = mapFitzpatrickQuestionAnswer({
            medicalHistoryAnswers: assessment?.patient?.medicalHistory,
            medicalHistoryQuestions: organisation?.medicalHistoryQuestions,
        })?.question;

        if (!this.props || !signedIn) {
            return <Redirect to={LOGIN} />;
        }

        if ((currentCase && next) || !doesOrganisationHaveLocations(organisation.locations)) {
            return <Redirect to={`${LESION_LOCATOR}${lesions?.length ? "/1" : ""}`} />;
        }

        const backButton = () => {
            const hasFitzpatrickAnswer = Boolean(
                mapFitzpatrickQuestionAnswer({
                    medicalHistoryAnswers: assessment?.patient?.medicalHistory,
                    medicalHistoryQuestions: organisation?.medicalHistoryQuestions,
                })?.questionAnswer
            );

            const isInflightCase = wasCaseCreatedBeforeTheSkinQuestionWasAddedToTheOrganisation({
                caseData: assessment.case,
                organisation,
            });

            if (fitzpatrickQuestion && assessment.case.homeInitiated && !hasFitzpatrickAnswer) {
                return MEDICAL_HISTORY;
            }

            if (fitzpatrickQuestion && !isInflightCase) {
                return SUN_EXPOSURE;
            }

            return MEDICAL_HISTORY;
        };

        return (
            <Container className="ui segment wizzard-container">
                <AssessmentFlowHeader
                    title="Select Clinic Location"
                    isEditMode={isEditMode}
                    disabled={!isBack}
                    edit={this.editConsent}
                    reset={this.resetEdit}
                />
                <Divider />
                <p>Please select the location this case should be filed against.</p>
                <Form>{this.getLocations()}</Form>
                <div className="clinic-location-button-container">
                    <ButtonContainer
                        button1={
                            <CustomButton
                                variant="empty"
                                to={backButton()}
                                text="< Back"
                                type="link"
                                size="small"
                                disabled={isEditMode}
                            />
                        }
                        button2={
                            <CustomButton
                                disabled={!locationUuid}
                                variant="filled"
                                type="submit"
                                action={this.submit}
                                text="Continue >"
                            />
                        }
                    />
                </div>
                {Boolean(error) && (
                    <Message negative>
                        <p>{error}</p>
                    </Message>
                )}
                <AssessmentFlowConfirmModal
                    show={showModal}
                    close={this.closeModal}
                    goAhead={this.moveForward}
                    save={this.submit}
                />
            </Container>
        );
    }
}

export default ClinicLocation;
