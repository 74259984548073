import { FC, ReactNode } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core//Paper";

import "scss/InterstitialPage.scss";

interface IInterstitialPage {
    children: ReactNode | ReactNode[];
}

const InterstitialPage: FC<IInterstitialPage> = ({ children }) => (
    <Container className="interstitial-container" fixed maxWidth="sm">
        <Paper className="interstitial-paper" elevation={20}>
            <Box className="interstitial-contents">{children}</Box>
        </Paper>
    </Container>
);

export default InterstitialPage;
