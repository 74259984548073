import moment from "moment";

import { IPatientLookupMatched, PDSFields } from "model/patientLookup";
import { IPatientData, IPatient } from "model/case";

export const mapSavedPatientToFormData = (savedPatientData: IPatientData[]): IPatientLookupMatched =>
    savedPatientData?.reduce((acc, curr) => {
        acc[curr.name] = curr.textValue || curr.dateValue || curr.numberValue || curr.booleanValue;
        return acc;
    }, {});

export const savedPatientDetailsMatchPDSResponse = (
    PDSPatient: IPatientLookupMatched,
    existingPatient: IPatient
): boolean => {
    const savedPatient = mapSavedPatientToFormData(existingPatient?.patientData);
    if (
        !savedPatient ||
        PDSPatient[PDSFields.name]?.toLowerCase() !== savedPatient[PDSFields.name]?.toLowerCase() ||
        PDSPatient[PDSFields.surname]?.toLowerCase() !== savedPatient[PDSFields.surname]?.toLowerCase() ||
        PDSPatient[PDSFields.nhsNumber] !== savedPatient[PDSFields.nhsNumber] ||
        !moment(PDSPatient[PDSFields.dateOfBirth]).isSame(savedPatient[PDSFields.dateOfBirth])
    ) {
        return false;
    }
    return true;
};
