import { FC } from "react";
import { Header, Divider } from "semantic-ui-react";

import LesionQuestions from "components/CaseDescription/Questions/LesionQuestions";
import LesionImages, { LesionImagesType } from "components/templates/LesionImages";

import { AssessmentTypes } from "model/assessment";
import { ILesion } from "model/case";
import { AllLesionLocation } from "model/lesionLocation";

interface ILesions {
    lesions: ILesion[];
    organisationUuid: string;
}

const Lesions: FC<ILesions> = ({ lesions, organisationUuid }) => (
    <>
        {lesions.map((lesion: ILesion, lesionNumber: number) => {
            const { bodyPart, description, uuid } = lesion;
            const location = description
                ? `${AllLesionLocation[bodyPart]} (${description})`
                : `${AllLesionLocation[bodyPart]}`;

            return (
                <div className="" key={uuid}>
                    <Header as="h4" className="lesion-title">
                        Lesion {lesionNumber + 1} - {location}
                    </Header>
                    <Divider />
                    <div className="remote-model-lesion-history">
                        <Header as="h4">History</Header>
                        <Divider />
                        <LesionQuestions
                            lesion={lesion}
                            lesionNumber={lesionNumber}
                            organisationUuid={organisationUuid}
                            isNonSkinCancer={false}
                            assessmentType={AssessmentTypes.remoteModel}
                        />
                    </div>
                    <LesionImages lesion={lesion} type={LesionImagesType.RemoteFlow} />
                </div>
            );
        })}
    </>
);

export default Lesions;
