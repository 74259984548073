import { FC, useState, useEffect, FormEvent, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Checkbox, CheckboxProps, Message } from "semantic-ui-react";
import parse from "html-react-parser";

import CustomButton from "components/templates/Button";
import ErrorMessage from "components/ErrorMessage";
import NewAssessment from "components/NewAssessment";
import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";

import AtHomeFlowContext from "contextProviders/AtHomeFlowProvider";
import { AtHomeFlowActionTypes } from "contextProviders/modules/atHomeFlow/actions";

import { RemoteTimeline } from "model/remoteCaseTimeline";

import { history } from "App";
import { REMOTE_MODEL_CASES_START, REMOTE_MODEL_REGISTRATION } from "navigation/remoteModelRoutes";

import getValidateError from "redux/selectors/validateError";
import { getOrganisation, getUser } from "redux/selectors/data";
import getFormErrors from "redux/selectors/formError";
import { getRegistration } from "redux/selectors/registration";

import * as caseService from "services/caseService";
import * as ErrorService from "services/errorService";

const INITIAL_FORM_STATE = {
    agreedTerms: false,
    agreedPurposes: false,
};

interface IInitialFormState {
    agreedTerms: boolean;
    agreedPurposes: boolean;
}

const ConsentScreen: FC = () => {
    const dispatch = useDispatch();

    const organisation = useSelector(getOrganisation);
    const registration = useSelector(getRegistration);
    const user = useSelector(getUser);

    const { dispatch: atHomeFlowDispatch } = useContext(AtHomeFlowContext);

    const [form, setForm] = useState<IInitialFormState>(INITIAL_FORM_STATE);

    const { agreedTerms, agreedPurposes } = form;
    const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;
    const isAtHomePatientFlow = isAtHomeFlow && !user;

    useEffect(() => {
        dispatch(ErrorService.cleanFormError());
    }, []);

    const validateErrors = useSelector(getValidateError);
    const formErrors = useSelector(getFormErrors);

    const onCheckboxChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        const { id, checked } = data;
        setForm((prevState) => ({ ...prevState, [String(id)]: checked }));
    };

    const onBack = () => history.push(REMOTE_MODEL_CASES_START);

    const onContinue = () => {
        if (registration?.flowType?.allowHomeInitiatedCases) {
            atHomeFlowDispatch({
                type: AtHomeFlowActionTypes.SET_CONSENT,
                payload: {
                    consent: agreedPurposes,
                    secondaryConsent: false,
                },
            });
            history.push(REMOTE_MODEL_REGISTRATION);
            dispatch(ErrorService.cleanErrors());
        } else {
            dispatch(
                caseService.createCase(agreedPurposes, false, false, undefined, undefined, () => {
                    history.push(REMOTE_MODEL_REGISTRATION);
                    dispatch(ErrorService.cleanErrors());
                })
            );
        }
    };

    const { assessment, research, assessmentPatient, researchPatient } = organisation;
    const { errors } = formErrors;
    const showRequestErrors = Boolean(errors);
    const assessmentText = parse(assessment || "");
    const researchText = parse(research || "");
    const assessmentPatientText = parse(assessmentPatient || "");
    const researchPatientText = parse(researchPatient || "");

    return (
        <>
            {!isAtHomeFlow && <CaseStatusContainer activeStatus={RemoteTimeline.CONSENT} />}
            {isAtHomeFlow ? (
                <NewAssessment />
            ) : (
                <Container className="ui segment remote-model consent">
                    <div className="is-heading">Consent</div>
                    <p>
                        I confirm that:
                        <br />
                        <br />
                        <span className="consent-text">
                            {isAtHomePatientFlow && assessmentPatient ? assessmentPatientText : assessmentText}
                        </span>
                    </p>
                    <div className="custom-checkbox">
                        <Checkbox
                            onChange={onCheckboxChange}
                            id="agreedTerms"
                            checked={agreedTerms}
                            label={{
                                children: (
                                    <span>
                                        I agree to the above and to the&nbsp;
                                        <a href="https://skin-analytics.com/terms/" target="_blank" rel="noreferrer">
                                            Terms and Conditions
                                        </a>
                                    </span>
                                ),
                            }}
                        />
                    </div>
                    <p>
                        We&apos;d like to ask your consent for Skin Analytics to use your information and images for
                        additional purposes valuable to the healthcare system. These purposes are optional and won’t
                        affect your service with us:
                        <br />
                        <br />
                        <span className="consent-text">
                            {isAtHomePatientFlow && researchPatient ? researchPatientText : researchText}
                        </span>
                    </p>
                    <div className="custom-checkbox">
                        <Checkbox
                            onChange={onCheckboxChange}
                            id="agreedPurposes"
                            checked={agreedPurposes}
                            label="I agree that my information and images may be used for these additional purposes."
                        />
                    </div>
                    <p>
                        Data will be processed in accordance with the Skin Analytics{" "}
                        <a href="https://skin-analytics.com/Privacy/" target="_blank" rel="noreferrer">
                            Privacy Policy
                        </a>
                        .
                    </p>
                    <div className="buttons-wrapper">
                        {!isAtHomeFlow && (
                            <CustomButton type="button" variant="empty-dark" action={onBack} text="Back" />
                        )}

                        <CustomButton
                            type="button"
                            variant="filled"
                            action={onContinue}
                            text="Continue"
                            disabled={!agreedTerms}
                        />
                    </div>
                    {validateErrors.load ? <ErrorMessage errors={validateErrors?.load?.message} /> : null}
                    {showRequestErrors && (
                        <Message title="Something went wrong" negative>
                            <ol>
                                {errors.map((err: { message: string; details: string }) => (
                                    <li key={err.message}>{err.message}</li>
                                ))}
                            </ol>
                        </Message>
                    )}
                </Container>
            )}
        </>
    );
};

export default ConsentScreen;
