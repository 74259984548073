import React, { FC } from "react";

import ErrorMessage from "components/ErrorMessage";
import ExceptionQuestion from "components/RemoteModel/modals/exceptionsQuestions/ExceptionQuestion";
import { IAnswers } from "components/RemoteModel/modals/QuestionsModal";

import { IRegistrationQuestion } from "model/registration";

interface IExceptionQuestions {
    preparedQuestions: IRegistrationQuestion[];
    onAnswerChange: (questionUuid: string, answerUuid: string) => void;
    answers: IAnswers;
    requestError: string;
    submit: boolean;
}

const ExceptionQuestions: FC<IExceptionQuestions> = ({
    preparedQuestions,
    onAnswerChange,
    answers,
    requestError,
    submit,
}) => {
    const isError = requestError.length > 0;

    return (
        <div className="content">
            {preparedQuestions?.map((question: IRegistrationQuestion) => {
                const { uuid } = question;

                const handleChange = (answerUuid: string) => {
                    onAnswerChange(uuid, answerUuid);
                };

                return (
                    <ExceptionQuestion
                        key={uuid}
                        question={question}
                        onChange={handleChange}
                        selectedAnswerUuid={answers[uuid]}
                        error={submit && question.required && !answers[uuid]}
                    />
                );
            })}
            {isError && <ErrorMessage errors={requestError} />}
        </div>
    );
};

export default ExceptionQuestions;
