import { FC, useState, useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Container, Header, Divider, Message } from "semantic-ui-react";

import StickyPatientInformation from "components/templates/StickyPatientInformation";
import LesionImages from "components/templates/LesionImages";

import { ICase, ILesion } from "model/case";
import { AllLesionLocation } from "model/lesionLocation";

import * as caseService from "services/caseService";

interface IRouteParams {
    caseId: string;
    lesionNumber: string;
}

enum Error {
    CASE_NOT_FOUND = "CASE_NOT_FOUND",
}

const CaseImgReassessment: FC<RouteComponentProps<IRouteParams>> = ({ match }) => {
    const { caseId, lesionNumber } = match.params;

    const [currentCase, setCurrentCase] = useState<ICase | null>(null);
    const [error, setError] = useState<Error | "">("");

    const selectedLesion = useRef<ILesion | null>(null);
    const rawBodyPart = selectedLesion.current?.bodyPart;
    const lesionLocation = rawBodyPart && AllLesionLocation[rawBodyPart];

    useEffect(() => {
        let mounted = true;

        caseService
            .getCaseAsync(caseId)
            .then((response: ICase) => {
                if (mounted) {
                    selectedLesion.current = response.lesions[Number(lesionNumber) - 1];
                    setCurrentCase(response);
                }
            })
            .catch(() => {
                setError(Error.CASE_NOT_FOUND);
            });

        return () => {
            mounted = false;
        };
    }, []);

    return (
        <Container className="ui segment wizzard-container case-img-reassessment">
            {currentCase ? (
                <>
                    <StickyPatientInformation
                        caseId={currentCase.caseId}
                        patient={currentCase.patient}
                        nonSkinCancerFlow={currentCase.nonSkinCancer}
                    />

                    <div className="section-separate section-case-header">
                        <Header as="h2" className="without-bottom-margin">
                            Request for images to be retaken
                        </Header>
                    </div>

                    <Divider className="title-divider" />

                    <p className="info-subtitle">
                        Please specify which images need to be resubmitted and record a reason
                    </p>

                    <div className="tab-div">
                        <Header className="without-bottom-margin capitalize" as="h3">
                            {`Lesion ${lesionNumber}. ${lesionLocation}`}
                        </Header>
                        <Divider className="title-divider" />

                        {selectedLesion.current && <LesionImages lesion={selectedLesion.current} selectable />}
                    </div>
                </>
            ) : (
                error && (
                    <Message
                        error
                        content="Sorry, something went wrong. Please try refreshing the page or try again later"
                    />
                )
            )}
        </Container>
    );
};

export default CaseImgReassessment;
