import { PATIENT_PARAMETERS_MAP } from "helpers/caseList";
import { formatTime } from "helpers/datetime";
import { getPatientData } from "helpers/patientUtils";

import { PatientFromIntegrationFields } from "model/assessment";
import { IPatientData } from "model/case";
import { IDefinedPersonalData } from "model/organisation";
import { DATA_IS_NOT_AVAILABLE_TEXT } from "model/common";

export const getDefinedPersonalDataWithoutName = (definedPersonalData: IDefinedPersonalData[]) =>
    definedPersonalData.filter((data) => !["name", "surname"].includes(data.propertyName.toLowerCase()));

export const sortPersonalDataByOrder = (definedPersonalData: IDefinedPersonalData[]) =>
    definedPersonalData.sort((a, b) => a.displayOrder - b.displayOrder);

export const getNameSurname = (patientData?: IPatientData[], isRemoteModel?: boolean, withTitle?: boolean): string => {
    const patientSurname = patientData?.find((data) => data.name.toLowerCase() === PATIENT_PARAMETERS_MAP.lastName);
    const patientName = patientData?.find((data) => data.name.toLowerCase() === PATIENT_PARAMETERS_MAP.name);
    const patientTitle = patientData?.find((data) => data.name.toLowerCase() === PatientFromIntegrationFields.TITLE);

    if (!patientName || !patientSurname) {
        return DATA_IS_NOT_AVAILABLE_TEXT;
    }

    if (isRemoteModel) {
        return `${patientName.textValue} ${patientSurname.textValue}`;
    }

    return `${patientSurname.textValue?.toUpperCase()}, ${patientName.textValue}${
        withTitle && patientTitle ? ` (${patientTitle.textValue})` : ""
    }`;
};

export const getIdentifier = (definedPersonalData: IDefinedPersonalData[], patientData?: IPatientData[]) => {
    const identifierDefinition = definedPersonalData.find((field) => field.identifier);
    const identifierField = patientData?.find((data) => data.name === identifierDefinition?.propertyName);

    return {
        identifierDefinition,
        identifierField: identifierField || { textValue: DATA_IS_NOT_AVAILABLE_TEXT },
    };
};

export const getPatientPropertyByName = (
    patientData: IPatientData[],
    fieldName: PatientFromIntegrationFields,
    dateOfDeath?: string
): string | number | boolean => {
    if (patientData) {
        const patientDataItem = patientData.find((field) => field.name === fieldName);
        const withAge = fieldName === PatientFromIntegrationFields.BIRTH;
        return getPatientData({ patientDataItem, dateFormatter: formatTime, withAge, dateOfDeath });
    }
    return "";
};

export function getIsMergedRecordField(patientData: IPatientData[]): boolean {
    return Boolean(getPatientPropertyByName(patientData, PatientFromIntegrationFields.MERGED_RECORD));
}

export function getIsDeceasedPatient(patientData: IPatientData[]): boolean {
    const dateOfDeath = getPatientPropertyByName(patientData, PatientFromIntegrationFields.DATE_OF_DEATH);
    return Boolean(dateOfDeath);
}

export const GENDER_INPUT_TEXT = "gender";
export const ADDRESS_INPUT_TEXT = "addressInput";
export const PHONE_INPUT_TEXT = "mobileNumber";
export const DOB_INPUT_TEXT = "birth";
export const POSTCODE_INPUT_TEXT = "postCode";
export const CONTACT_TIME = "contactTime";
export const CONTACT_ON_WEEKEND = "contactOnWeekend";
export const NAME_INPUT_TEXT = "name";
export const SURNAME_INPUT_TEXT = "surname";
export const ADDRESS_LINE_1_INPUT_TEXT = "addressLine1";
export const ADDRESS_LINE_2_INPUT_TEXT = "addressLine2";
export const ADDRESS_LINE_3_INPUT_TEXT = "addressLine3";
export const CITY_INPUT_TEXT = "city";
export const COUNTRY_INPUT_TEXT = "country";
