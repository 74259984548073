import { FC, useContext } from "react";
import { connect } from "react-redux";

import NewAssessment from "components/NewAssessment/NewAssessment";

import AtHomeFlowContext from "contextProviders/AtHomeFlowProvider";

import * as caseService from "services/caseService";
import studyService from "services/studyService";
import { INewAssessmentProps } from "model/assessment";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    organisation: state.data.organisation,
    registration: state.registration,
    user: state.data.user,
    pendingRequest: state.data.pendingRequest,
});

const mapDispatchToProps = {
    createCase: caseService.createCase,
    getDevices: studyService.getUserDevices,
    updateCase: caseService.updateCase,
};

const NewAssessmentHOC: FC<Omit<INewAssessmentProps, "atHomeFlowDispatch">> = (props) => {
    const { dispatch: atHomeFlowDispatch } = useContext(AtHomeFlowContext);

    return <NewAssessment {...props} atHomeFlowDispatch={atHomeFlowDispatch} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAssessmentHOC);
