import { connect } from "react-redux";

import AssessmentResults from "components/CaseDescription/CaseDescription";

import * as authorizationService from "services/authorizationService";
import * as caseService from "services/caseService";

const mapStateToProps = (state: any) => ({
    organisation: state.data.organisation,
    pendingRequest: state.data.pendingRequest,
    user: state.data.user,
});

const mapDispatchToProps = {
    getReport: caseService.downloadReport,
    viewReport: caseService.viewReport,
    switchUserOrganisation: authorizationService.switchUserOrganisation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentResults);
