import React, { FC } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

import { ILesion } from "model/case";
import { IReviewDiagnosis } from "model/organisation";

import { getOrganisation } from "redux/selectors/data";

interface IFindOutMoreInformation {
    lesions: ILesion[];
}

const FindOutMoreInformation: FC<IFindOutMoreInformation> = ({ lesions }) => {
    const organisation = useSelector(getOrganisation);
    const { reviewDiagnoses, reviewManagementOptions } = organisation;

    const diagnosisAndManagementOutcomeUuids = lesions.map((lesion: ILesion) => {
        const lesionReviews = lesion.reviews;
        const latestReviewIndex = lesionReviews.length - 1;
        const latestReview = lesionReviews[latestReviewIndex];
        const { reviewDiagnosisUuid: diagnosisUuid, reviewManagementUuid: managementOutcomeUuid } = latestReview;
        return { diagnosisUuid, managementOutcomeUuid };
    });

    const currentCaseFindOutMoreInfo: IReviewDiagnosis[] = [];

    diagnosisAndManagementOutcomeUuids.forEach(({ diagnosisUuid, managementOutcomeUuid }) => {
        const currentDiagnosis = reviewDiagnoses.find((reviewDiagnosis) => reviewDiagnosis.uuid === diagnosisUuid);
        if (currentDiagnosis) {
            const currentManagementOutcome = reviewManagementOptions.find(
                (reviewManagementOutcome) => reviewManagementOutcome.uuid === managementOutcomeUuid
            );

            if (currentManagementOutcome) {
                if (!currentManagementOutcome.refer && currentDiagnosis.additionalInformation) {
                    currentCaseFindOutMoreInfo.push(currentDiagnosis);
                }
            }
        }
    });
    const diagnoses = currentCaseFindOutMoreInfo.map((diagnose) => diagnose?.diagnosis).join(", ");
    const diagnosisAditionalInformationTexts = currentCaseFindOutMoreInfo.map((diagnose) =>
        parse(diagnose?.additionalInformation || "")
    );

    const showDiagnosesAdditionalInformation = currentCaseFindOutMoreInfo.length > 0;

    return (
        <>
            {showDiagnosesAdditionalInformation && (
                <>
                    <b>Patient Information Leaflet(s): {diagnoses}</b>
                    <br />
                    <br />
                    <p>You can find out more information about your diagnosis on the following link(s):</p>
                    {diagnosisAditionalInformationTexts}
                </>
            )}
            <div>
                <b>Preventing Skin Cancer</b>
                <p>
                    <br />
                    <br />
                    You can find out more about how to keep an eye on your skin lesions and reduce your future risk of
                    skin cancer by reading the information at the following links:
                    <br />
                    <br />
                    <a href="http://www.nhs.uk/conditions/moles" target="_blank" rel="noreferrer">
                        http://www.nhs.uk/conditions/moles
                    </a>
                    <br />
                    <br />
                    <a
                        href="https://www.bad.org.uk/for-the-public/skin-cancer/sunscreen-fact-sheet"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://www.bad.org.uk/for-the-public/skin-cancer/sunscreen-fact-sheet
                    </a>
                </p>
            </div>
        </>
    );
};

export default FindOutMoreInformation;
