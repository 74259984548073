import React, { useState, useMemo, useEffect, ChangeEvent, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Message, InputOnChangeData } from "semantic-ui-react";

import { isErrorOfTypePasswordOnDenyList } from "helpers/error";

import { IFormError } from "model/formError";
import { UserRole } from "model/userRole";

import * as actions from "redux/actions";
import getFormErrors from "redux/selectors/formError";

import * as authorizationService from "services/authorizationService";
import * as errorService from "services/errorService";

interface IUseSetPassword {
    form: IFormState;
    setFormValue: (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
    setPassword: () => Promise<boolean>;
    renderErrors: () => ReactNode;
    isInvalidToken: boolean;
    isButtonDisabled: boolean;
    isCheckTokenRequestPending: boolean;
    setIsButtonDisabled: (state: boolean) => void;
    userRoleFromToken: UserRole;
    passwordIsOnDenyList: () => boolean;
    formHasErrors: () => boolean;
    isAPIFailure: boolean;
}

export const PASSWORD_FIELD_NAME = "password";
export const CONFIRMED_PASSWORD_FIELD_NAME = "confirmedPassword";

const INITIAL_STATE = {
    [PASSWORD_FIELD_NAME]: "",
    [CONFIRMED_PASSWORD_FIELD_NAME]: "",
};

export interface IFormState {
    [PASSWORD_FIELD_NAME]: string;
    [CONFIRMED_PASSWORD_FIELD_NAME]: string;
}

const TOKEN_QUERY_KEY = "token";

const useSetPassword = (): IUseSetPassword => {
    const [form, setForm] = useState<IFormState>(INITIAL_STATE);
    const [isValidToken, setIsValidToken] = useState<boolean>(false);
    const [isAPIFailure, setIsAPIFailure] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [isCheckTokenRequestPending, setIsCheckTokenRequestPending] = useState<boolean>(true);
    const [userRoleFromToken, setUserRoleFromToken] = useState<UserRole>(undefined);
    const formErrors = useSelector(getFormErrors);
    const dispatch = useDispatch();

    const token = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(TOKEN_QUERY_KEY);
    }, [window.location.search]);

    const checkToken = async (): Promise<void> => {
        if (token) {
            const response = await authorizationService.checkToken(token);

            if (response === undefined) {
                setIsAPIFailure(true);
            } else if (response.status === 500) {
                setIsValidToken(false);
            } else {
                setIsValidToken(Boolean(response));
            }
        }
        setIsCheckTokenRequestPending(false);
    };

    const getUserRoleFromToken = (): void => {
        if (token) {
            const userRole = authorizationService.getUserRoleFromToken(token);
            setUserRoleFromToken(userRole);
        }
    };

    useEffect(() => {
        checkToken();
        getUserRoleFromToken();
        dispatch(errorService.cleanFormError());
    }, []);

    const setFormValue = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const { name, value } = data;
        setForm({ ...form, [name]: value });
    };

    const setPassword = async (): Promise<boolean> => {
        const { password, confirmedPassword } = form;
        if (password && confirmedPassword && token) {
            return authorizationService
                .setPassword(token, password, confirmedPassword)
                .then((response) => {
                    errorService.cleanFormError();
                    dispatch(actions.authActions.changePasswordSuccess(response));
                    return true;
                })
                .catch((err: any) => {
                    dispatch(actions.formErrorActions.formError(err.response.data.errors));
                    return false;
                });
        }
        return Promise.resolve(false);
    };

    const renderErrors = () => {
        if (formErrors.errors && !isErrorOfTypePasswordOnDenyList(formErrors.errors)) {
            return (
                <Message title="Something went wrong" negative>
                    <ol>
                        {formErrors.errors.map((err: IFormError) => (
                            <li key={err.message}>{err.message}</li>
                        ))}
                    </ol>
                </Message>
            );
        }
        return null;
    };

    const passwordIsOnDenyList = (): boolean => {
        if (formErrors.errors) {
            return isErrorOfTypePasswordOnDenyList(formErrors.errors);
        }
        return false;
    };

    const formHasErrors = (): boolean => formErrors.errors && !isErrorOfTypePasswordOnDenyList(formErrors.errors);

    return {
        form,
        setFormValue,
        setPassword,
        renderErrors,
        isInvalidToken: !isValidToken,
        isButtonDisabled,
        isCheckTokenRequestPending,
        setIsButtonDisabled,
        userRoleFromToken,
        passwordIsOnDenyList,
        formHasErrors,
        isAPIFailure,
    };
};

export default useSetPassword;
