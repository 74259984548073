import React from "react";
import { Select, SelectProps, MenuItem, InputLabel, FormHelperText } from "@material-ui/core";
import { DropdownItemProps } from "semantic-ui-react";

import "scss/Select.scss";

type CustomSelectProps = SelectProps & {
    options: DropdownItemProps[];
    onChange: (any) => void;
    placeholder?: string;
    dropdownMaxHeight?: number;
    required?: boolean;
    labelText?: string;
    inputError?: string;
};

export default function CustomSelect(props: CustomSelectProps) {
    const { options, placeholder, dropdownMaxHeight, inputError, labelText, required, ...muiSelectProps } = props;

    const { id, value, onChange, variant } = muiSelectProps;

    const isInputErrorClass = inputError ? "error" : "";

    const renderOptions = options.map((option) => (
        <MenuItem key={option.key} value={option.key}>
            {option.text}
        </MenuItem>
    ));

    if (placeholder && !value) {
        renderOptions.unshift(
            <MenuItem value="" key="placeholder">
                <em style={{ opacity: "30%" }}>{placeholder}</em>
            </MenuItem>
        );
    }

    return (
        <div className="custom-select-container">
            {labelText ? (
                <InputLabel id="lesion-location-select-label" className={isInputErrorClass} required={required}>
                    {labelText}
                </InputLabel>
            ) : null}
            <Select
                className={`custom-select ${isInputErrorClass}`}
                labelId="lesion-location-select-label"
                id={id}
                variant={variant || "outlined"}
                value={value || ""}
                onChange={onChange}
                displayEmpty
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: dropdownMaxHeight,
                        },
                    },
                }}
                {...muiSelectProps}
            >
                {renderOptions}
            </Select>
            <FormHelperText error={!!inputError}>{inputError}</FormHelperText>
        </div>
    );
}
