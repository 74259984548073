const downloadFile = (data: BlobPart, fileName: string, blobOptions?: BlobPropertyBag) => {
    const blob = new Blob([data], blobOptions);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
};

const getFilenameFromResponse = (headers: any) => {
    const header = headers["content-disposition"];
    const filename = header.split("filename=")[1];
    const result = JSON.parse(filename);
    return result;
};

export { downloadFile, getFilenameFromResponse };
