import { FC } from "react";
import { DialogContent } from "@mui/material";

import ModalDialog from "components/templates/dialog/ModalDialog";

interface ISomethingWentWrongDialogProps {
    showModal: boolean;
    handleClose: () => void;
    dialogText?: string;
}

const SomethingWentWrongDialog: FC<ISomethingWentWrongDialogProps> = ({ showModal, handleClose, dialogText }) => {
    const text = dialogText || "Unable to save. Try again shortly.";
    return (
        <ModalDialog
            title="Something went wrong"
            open={showModal}
            iconSrc="/images/icons/icon-alert.svg"
            className="warning-modal"
            onClose={handleClose}
            maxWidth="sm"
            disableBackdropClick
            buttons={[
                {
                    onClick: handleClose,
                    text: "Close",
                    colour: "primary",
                },
            ]}
        >
            <DialogContent>{text}</DialogContent>
        </ModalDialog>
    );
};

export default SomethingWentWrongDialog;
