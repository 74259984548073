import {
    AllocationConfigurationConsentType,
    AllocationConfigurationSettingType,
    IAllocationTableSettings,
} from "model/allocationConfig";
import { IFormError } from "model/formError";
import { IAllocation } from "model/organisation";

export enum AllocationConfigurationActionTypes {
    SET_LOADING = "SET_LOADING",
    SET_LOADED_ORGANISATION = "SET_LOADED_ORGANISATION",
    TOGGLE_URGENT_REFER = "TOGGLE_URGENT_REFER",
    CREATE_ALLOCATIONS = "CREATE_ALLOCATIONS",
    SET_NEW_ALLOCATION = "SET_NEW_ALLOCATION",
    SET_IMMEDIATE_RESULTS = "SET_IMMEDIATE_RESULTS",
    UPDATE_ORGANISATION = "UPDATE_ORGANISATION",
    TOGGLE_ALLOCATION_ORDER_MODAL = "TOGGLE_ALLOCATION_ORDER_MODAL",
    TOGGLE_URGENT_REFER_MODAL = "TOGGLE_URGENT_REFER_MODAL",
    ALLOCATION_CONFIG_ERROR = "ALLOCATION_CONFIG_ERROR",
}

export interface SetLoading {
    type: AllocationConfigurationActionTypes.SET_LOADING;
    payload: {
        loading: boolean;
        type: AllocationConfigurationConsentType;
    };
}

export interface SetLoadedOrganisation {
    type: AllocationConfigurationActionTypes.SET_LOADED_ORGANISATION;
    payload: {
        showAutomatedDecisionConsent: boolean;
        showUnconsentedCaseSettings: boolean;
        showDefaultLayout: boolean;
        consented: IAllocationTableSettings;
        unconsented: IAllocationTableSettings;
        enableUrgentRefer: boolean;
    };
}

export interface EnableUrgentRefer {
    type: AllocationConfigurationActionTypes.TOGGLE_URGENT_REFER;
}
export interface CreateAllocations {
    type: AllocationConfigurationActionTypes.CREATE_ALLOCATIONS;
    payload: {
        allocations?: IAllocation[];
        type: AllocationConfigurationConsentType;
    };
}

export interface SetNewAllocation {
    type: AllocationConfigurationActionTypes.SET_NEW_ALLOCATION;
    payload: {
        allocations?: IAllocation[];
        type: AllocationConfigurationSettingType;
        unconsentedCasesAllocations?: IAllocation[];
        hasAllocationOrderChanged?: boolean;
    };
}
export interface SetImmediateResults {
    type: AllocationConfigurationActionTypes.SET_IMMEDIATE_RESULTS;
    payload: {
        allocations?: IAllocation[];
        unconsentedCasesAllocations?: IAllocation[];
        type: AllocationConfigurationConsentType;
    };
}

export interface UpdateOrganisation {
    type: AllocationConfigurationActionTypes.UPDATE_ORGANISATION;
    payload: {
        allocations: IAllocation[];
    };
}

export interface ToggleAllocationOrderModal {
    type: AllocationConfigurationActionTypes.TOGGLE_ALLOCATION_ORDER_MODAL;
}

export interface ToggleUrgentReferModal {
    type: AllocationConfigurationActionTypes.TOGGLE_URGENT_REFER_MODAL;
}

export interface AllocationConfigurationError {
    type: AllocationConfigurationActionTypes.ALLOCATION_CONFIG_ERROR;
    payload: {
        type: AllocationConfigurationConsentType;
        error: Partial<IFormError>[];
    };
}

export type AllocationConfigurationAction =
    | SetLoadedOrganisation
    | EnableUrgentRefer
    | CreateAllocations
    | SetNewAllocation
    | SetImmediateResults
    | UpdateOrganisation
    | SetLoading
    | ToggleAllocationOrderModal
    | ToggleUrgentReferModal
    | AllocationConfigurationError;

export default AllocationConfigurationAction;
