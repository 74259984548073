import React, { FC } from "react";
import { Image } from "semantic-ui-react";

import AnimatedDots from "components/templates/AnimatedDots";

import withModal from "HOC/withModal";

const CallbackRequestedModal: FC = () => (
    <div className="remote-model__modal">
        <Image alt="Callback icon" src="/images/icons/icon-callback.svg" />
        <p className="h2">
            Your request for a callback has been recorded.
            <br />
            One of our clinicians will contact you within one working day
            <AnimatedDots />
        </p>
    </div>
);

export default withModal(CallbackRequestedModal);
