import React, { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

import { ModalIconAltTexts } from "model/modal";

interface IAccountCreatedModal {
    onContinue: () => void;
}

const AccountCreatedModal: FC<IAccountCreatedModal> = ({ onContinue }) => (
    <div className="remote-model__modal">
        <Image alt={ModalIconAltTexts.SUCCESS} src="/images/icons/icon-okay.svg" />
        <p className="h2">Your Skin Analytics account has been created successfully.</p>
        <div className="buttons-wrapper">
            <CustomButton type="button" variant="filled" action={onContinue} text="Continue" />
        </div>
    </div>
);

export default withModal(AccountCreatedModal);
