import { FC, useState, useEffect } from "react";
import { Divider, Header } from "semantic-ui-react";
import { useDispatch } from "react-redux";

import { IMAGE_MANUAL_UPLOAD } from "navigation/routes";
import { history } from "App";

import CustomButton from "components/templates/Button";
import LesionAssessment from "components/CaseDescription/Lesions/LesionAssessment";

import { ICase, IImage, ILesion, ISkinToneAnswer } from "model/case";
import { CaseViewMode } from "model/caseViewMode";
import { CaptureImageType } from "model/captureImageType";

import * as caseService from "services/caseService";

interface ILesionsAssessmentProps {
    caseObject: ICase;
    mode: CaseViewMode;
    downloadImages: (downloadDermImages: () => Promise<void>) => Promise<void>;
    updateSkinToneClassification: (result: ISkinToneAnswer[]) => void;
}

const LesionsAssesment: FC<ILesionsAssessmentProps> = ({
    caseObject,
    mode,
    downloadImages,
    updateSkinToneClassification,
}) => {
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const { uuid: caseUuid } = caseObject;
    const dispatch = useDispatch();

    const downloadDermImages = async (): Promise<void> => {
        setImageLoading(true);
        await caseService.getDermImages(caseUuid);
        setImageLoading(false);
    };

    useEffect(() => {
        dispatch(caseService.getCaseForAssessment(caseUuid));
        dispatch(caseService.continueAssessment(caseUuid));
    }, [caseUuid]);

    const handleDownloadImages = async (): Promise<void> => {
        await downloadImages(downloadDermImages);
    };

    const { lesions, nonSkinCancer } = caseObject;

    if (!lesions) {
        return null;
    }

    const isSuperAdmin = mode === CaseViewMode.SUPERADMINVIEW;
    const caseHasImages = lesions?.some((lesion: ILesion) => lesion.images);

    const lesionHasDermoscopicImage: boolean = lesions?.every((lesion: ILesion) =>
        lesion?.images?.some((image: IImage) => image.type === CaptureImageType.DERMOSCOPIC)
    );

    const shouldShowManualUpload = caseObject?.remote && isSuperAdmin && !lesionHasDermoscopicImage;
    const redirectToRemoteManualUpload = () => {
        dispatch(caseService.setManualUploadRemoteMode(true));
        history.push(IMAGE_MANUAL_UPLOAD);
    };

    const lesionAssessmentText = nonSkinCancer ? "Skin complaint" : "Lesion Assessment";

    return (
        <div>
            <div className="section-separate section-case-header">
                <div>
                    <Header as="h2" className="without-bottom-margin">
                        {lesionAssessmentText}
                    </Header>
                </div>

                <div>
                    {shouldShowManualUpload && (
                        <CustomButton
                            type="button"
                            variant="empty"
                            floated="right"
                            text="Upload images manually"
                            action={redirectToRemoteManualUpload}
                        />
                    )}
                    {isSuperAdmin && caseObject && caseHasImages && (
                        <CustomButton
                            type="submit"
                            variant="empty"
                            text="Download Images"
                            action={handleDownloadImages}
                            floated="right"
                            loading={imageLoading}
                        />
                    )}
                </div>
            </div>

            <Divider className="title-divider" />

            {lesions.map((lesion, index) => (
                <LesionAssessment
                    key={lesion.uuid}
                    lesion={lesion}
                    lesionNumber={index + 1}
                    caseObject={caseObject}
                    mode={mode}
                    updateSkinToneClassification={updateSkinToneClassification}
                />
            ))}
        </div>
    );
};

export default LesionsAssesment;
