import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import useRedirectToCaseAssessment from "components/CaseList/hooks/useRedirectToAssessment";

import { UserRole } from "model/userRole";

import userService from "services/userService";

interface IParams {
    id: string;
}

const RedirectPatientToAssessment: FC = () => {
    const { redirectToAssessment } = useRedirectToCaseAssessment();
    const params = useParams<IParams>();

    useEffect(() => {
        const isPatient = userService.checkUserHasRole([UserRole.PATIENT]);
        if (isPatient) {
            redirectToAssessment(params.id, isPatient);
        }
    }, []);

    return null;
};

export default RedirectPatientToAssessment;
