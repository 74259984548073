import React, { FC } from "react";
import { Image } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import CustomButton from "components/templates/Button";

import "scss/Modal.scss";

interface INoAutomatedDecisionModal {
    isOpen: boolean;
    handleClose: (event: any, reason: string) => void;
    isClinician: boolean;
    blockCasesWithoutAutomatedDecisionConsent: boolean;
}

const NoAutomatedDecisionModal: FC<INoAutomatedDecisionModal> = ({
    isOpen,
    handleClose,
    isClinician,
    blockCasesWithoutAutomatedDecisionConsent,
}) => {
    const allowNonConsentedCasesToProgressInfo = isClinician
        ? "Not consenting will add some delay in the decision making for the patient. Does the patient still want to continue without giving their consent?"
        : "Not consenting will add some delay in the decision for your case due to additional review by a clinician. Do you still want to continue without giving your consent?";
    const blockNonConsentedCasesProgressing = isClinician
        ? "It is required for the patient to consent to automated decision making in order to use this service. Does the patient still want to reject consent?"
        : "Your consent for automatic decision making is required for us to be able to offer you this service. Not consenting means that we cannot allow you to use this service and you will have to contact your healthcare provider for next steps. Do you still want to reject consent and not use the service?";

    const modalContentText = blockCasesWithoutAutomatedDecisionConsent
        ? blockNonConsentedCasesProgressing
        : allowNonConsentedCasesToProgressInfo;

    return (
        <Dialog open={isOpen} onClose={handleClose} disableEscapeKeyDown className="no-automated-decision">
            <div>
                <Image alt="decline or accept" src="/images/icons/icon-decline-accept.svg" />
                <DialogTitle>
                    {`Are you sure? You have chosen to not consent for automatic decision making ${
                        isClinician ? "for this patient" : ""
                    }`}
                </DialogTitle>
                <DialogContent>
                    <p className="p">{modalContentText}</p>
                </DialogContent>
                <DialogActions>
                    <CustomButton type="button" variant="empty" action={handleClose} text="Yes" />
                    <CustomButton type="button" variant="filled" action={handleClose} text="No, edit consent" />
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default NoAutomatedDecisionModal;
