export enum CaseStatus {
    CASE_CREATED = "CASE_CREATED",
    CASE_COMPLETED = "CASE_COMPLETED",
    CASE_FAILED = "CASE_FAILED",
    CASE_IN_PROGRESS = "CASE_IN_PROGRESS",
    CASE_FOR_SA_REVIEW = "CASE_FOR_SA_REVIEW",
    CASE_FOR_REVIEW = "CASE_FOR_REVIEW",
    CASE_IN_SA_REVIEW = "CASE_IN_SA_REVIEW",
    CASE_IN_REVIEW = "CASE_IN_REVIEW",
    CASE_CLOSED = "CASE_CLOSED",
    CASE_IN_CLIENT_REVIEW = "CASE_IN_CLIENT_REVIEW",
    CASE_ABANDONED = "CASE_ABANDONED",
}

export enum CaseStatusFaked {
    CASE_OVERDUE = "CASE_OVERDUE",
    CASE_CALLBACK_REQUESTED = "CASE_CALLBACK_REQUESTED",
}

export enum CaseStatusDictionary {
    CASE_CREATED = "Created",
    CASE_IN_PROGRESS = "In progress",
    CASE_COMPLETED = "Completed",
    CASE_FAILED = "Failed",
    ALL = "All",
    CASE_FOR_SA_REVIEW = "To Allocate (SA)",
    CASE_FOR_REVIEW = "To Allocate",
    CASE_IN_SA_REVIEW = "With Safety Net",
    CASE_IN_REVIEW = "With Dermatologist",
    CASE_CLOSED = "Closed",
    CASE_ABANDONED = "Case abandoned",
}

export enum CaseStatusDictionaryPatient {
    CASE_CREATED = "In progress",
    CASE_IN_PROGRESS = "In progress",
    CASE_COMPLETED = "Completed",
    CASE_FAILED = "Failed",
    ALL = "All",
    CASE_FOR_SA_REVIEW = "In review",
    CASE_FOR_REVIEW = "In review",
    CASE_IN_SA_REVIEW = "In review",
    CASE_IN_REVIEW = "In review",
    CASE_CLOSED = "Closed",
    CASE_ABANDONED = "Case abandoned",
}

export enum CaseOverdueStatusDictionary {
    CASE_CREATED = "Created",
    CASE_IN_PROGRESS = "In progress",
    CASE_FOR_SA_REVIEW = "To Allocate (SA)",
    CASE_FOR_REVIEW = "To Allocate",
    CASE_IN_SA_REVIEW = "With Safety Net",
    CASE_IN_REVIEW = "With Dermatologist",
    CASE_COMPLETED = "Completed",
}

export enum CaseStatusFilter {
    ALL = "ALL",
    CASE_CREATED = "CASE_CREATED",
    CASE_IN_PROGRESS = "CASE_IN_PROGRESS",
    CASE_COMPLETED = "CASE_COMPLETED",
    CASE_FAILED = "CASE_FAILED",
    CASE_FOR_SA_REVIEW = "CASE_FOR_SA_REVIEW",
    CASE_FOR_REVIEW = "CASE_FOR_REVIEW",
    CASE_IN_SA_REVIEW = "CASE_IN_SA_REVIEW",
    CASE_IN_REVIEW = "CASE_IN_REVIEW",
    CASE_CLOSED = "CASE_CLOSED",
    CASE_ABANDONED = "CASE_ABANDONED",
}
