import React, { FC, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Container, Divider, Grid, Header } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";

import { getPatientFields } from "helpers/patientUtils";
import {
    ADDRESS_LINE_1_INPUT_TEXT,
    ADDRESS_LINE_2_INPUT_TEXT,
    ADDRESS_LINE_3_INPUT_TEXT,
    CITY_INPUT_TEXT,
    NAME_INPUT_TEXT,
    POSTCODE_INPUT_TEXT,
    SURNAME_INPUT_TEXT,
} from "helpers/definedPersonalData";

import { KitDeliveryStatuses } from "model/remoteModel";
import { RemoteTimeline } from "model/remoteCaseTimeline";

import { REMOTE_MODEL_CASES_LESION_DETAILS, REMOTE_MODEL_CASES_MEDICAL_HISTORY } from "navigation/remoteModelRoutes";

import { getCurrentCase, getPatient } from "redux/selectors/assessment";

import * as caseService from "services/caseService";
import { COURIER_URL_BASE } from "navigation/external";

import "scss/RemoteModel.scss";

export interface ITracking {
    status: KitDeliveryStatuses;
    trackingNumber: string;
}

const INITIAL_TRACK = {
    status: KitDeliveryStatuses.TO_DISPATCH,
    trackingNumber: "",
};

const IMAGES_SRC = {
    TO_DISPATCH: <CheckOutlinedIcon />,
    DISPATCHED: <LocalShippingOutlinedIcon />,
    DELIVERED: <HomeOutlinedIcon />,
};

const DELIVERY_STATUS_MAPPING = {
    TO_DISPATCH: "Processed",
    DISPATCHED: "Shipped",
    DELIVERED: "Delivered",
};

const KitDelivery: FC = () => {
    const [tracking, setTracking] = useState<ITracking>(INITIAL_TRACK);

    const dispatch = useDispatch();
    const patient = useSelector(getPatient);
    const currentCase = useSelector(getCurrentCase);
    const { kitTracking, kitDeliveryStatus } = currentCase;

    const courierUrl = `${COURIER_URL_BASE}${tracking.trackingNumber}`;

    const fetchCase = async () => {
        const updatedCase = await caseService.continueAssessment(currentCase.uuid)(dispatch);
        return updatedCase;
    };

    useEffect(() => {
        if (currentCase?.uuid) {
            fetchCase();
        }
    }, []);

    useEffect(() => {
        if (kitTracking) {
            const { trackOutNo } = kitTracking;
            setTracking({
                status: kitDeliveryStatus as KitDeliveryStatuses,
                trackingNumber: trackOutNo || "",
            });
        }
    }, [kitTracking]);

    const patientFields = getPatientFields(patient, [
        NAME_INPUT_TEXT,
        SURNAME_INPUT_TEXT,
        ADDRESS_LINE_1_INPUT_TEXT,
        ADDRESS_LINE_2_INPUT_TEXT,
        ADDRESS_LINE_3_INPUT_TEXT,
        POSTCODE_INPUT_TEXT,
        CITY_INPUT_TEXT,
    ]);
    const [name, surname, addressLine1, addressLine2, addressLine3, postCode, city] = patientFields;

    const comify = (value?: string) => (value ? `${value},` : "");
    const KitDeliveryStatusArray = Object.values(KitDeliveryStatuses);
    const currentDeliveryStatusIndex = KitDeliveryStatusArray.findIndex((item) => item === tracking.status);

    const ToDispatchBody = (): JSX.Element => (
        <div className="kit-delivery-body">
            <p>
                <span>Your kit will be dispatched to the address below shortly.</span> <br />
                Once you receive the kit, you will be able to continue with the process.
            </p>
            <div>
                {patient ? (
                    <div className="remote-model-patient-details">
                        <p>{`${name} ${surname}`}</p>
                        <p>{`${comify(addressLine1)} ${comify(addressLine2)} ${comify(addressLine3)}`}</p>
                        <p>{`${postCode}`}</p>
                        <p>{`${city}`}</p>
                    </div>
                ) : null}
            </div>
        </div>
    );

    const trackingInfo = (trackingData: ITracking): JSX.Element => (
        <div className="tracking">
            <span>{`Tracking number: ${trackingData.trackingNumber}`}</span>
            <Button href={courierUrl} rel="noopener" target="_blank" variant="contained" color="primary">
                Track my kit
            </Button>
        </div>
    );

    const DispatchedBody = (): JSX.Element => (
        <div className="kit-delivery-body-dispatched">
            <p>
                Your Skin Check Kit is on its way and should arrive within the next 2 days*. Once you have received it,
                you can continue to the next step.
            </p>
            {trackingInfo(tracking)}
            <p>We recommend that you use the kit to upload your images within 3 days of receiving it.</p>
        </div>
    );

    const DeliveredBody = (): JSX.Element => (
        <div className="kit-delivery-body-dispatched">
            {trackingInfo(tracking)}
            <p>
                Your Skin Check Kit should have arrived. We recommend you use the kit to upload your images within 3
                days of receiving it.
            </p>
            <p>
                If you have not received it, please contact{" "}
                <a href="mailto:support@skinanalytics.co.uk">support@skinanalytics.co.uk</a> as soon as possible.
            </p>
        </div>
    );

    const getTrackingHeader = (status: KitDeliveryStatuses): string => {
        switch (status) {
            case KitDeliveryStatuses.TO_DISPATCH:
                return "Your Skin Check Kit will be with you shortly";
            case KitDeliveryStatuses.DISPATCHED:
                return "Your Skin Check Kit has been shipped";
            case KitDeliveryStatuses.DELIVERED:
                return "Your Skin Check Kit has been delivered";
            default:
                return "Your Skin Check Kit";
        }
    };

    return (
        <>
            <CaseStatusContainer activeStatus={RemoteTimeline.KIT} />
            <Container className="ui segment remote-model kit">
                <Header as="h3">{getTrackingHeader(tracking.status)}</Header>
                <Divider />
                <div className="kit-delivery-timeline">
                    <div className="timeline">
                        {KitDeliveryStatusArray.map((status: string, statusItemIndex: number) => {
                            const isEventActiveOrPassed = currentDeliveryStatusIndex >= statusItemIndex;
                            return (
                                <div className={`timeline-item${isEventActiveOrPassed ? "-passed" : ""}`}>
                                    <div className="icon-container">{IMAGES_SRC[status]}</div>
                                    <span>{DELIVERY_STATUS_MAPPING[status]}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Grid stackable>
                    <Grid.Column textAlign="center" className="kit-delivery-info">
                        {tracking.status === KitDeliveryStatuses.TO_DISPATCH && <ToDispatchBody />}
                        {tracking.status === KitDeliveryStatuses.DISPATCHED && <DispatchedBody />}
                        {tracking.status === KitDeliveryStatuses.DELIVERED && <DeliveredBody />}
                    </Grid.Column>
                </Grid>
                <div className="kit-delivery-buttons-wrapper">
                    <Button href={REMOTE_MODEL_CASES_MEDICAL_HISTORY} variant="contained" color="secondary">
                        Back
                    </Button>
                    {tracking.status !== KitDeliveryStatuses.TO_DISPATCH && (
                        <Button href={REMOTE_MODEL_CASES_LESION_DETAILS} variant="contained" color="primary">
                            Continue
                        </Button>
                    )}
                </div>
                {tracking.status === KitDeliveryStatuses.TO_DISPATCH && (
                    <p className="subtext">
                        We aim to ship kits ordered before 2pm on weekdays (excluding Bank Holidays) on the same day via
                        Royal Mail&apos;s tracked 24-hour delivery. While most kits arrive within 2 working days,
                        occasional Royal Mail delays can extend deliveries. If your address is wrong or your kit
                        hasn&apos;t arrived in 5 days, contact us at support@skinanalytics.co.uk.
                    </p>
                )}
                {tracking.status === KitDeliveryStatuses.DISPATCHED && (
                    <p className="subtext">
                        * While most kits arrive within 2 working days, occasional Royal Mail delays can extend
                        deliveries. If your address is wrong or your kit hasn&apos;t arrived in 5 days, contact us at
                        support@skinanalytics.co.uk.
                    </p>
                )}
                {tracking.status === KitDeliveryStatuses.DELIVERED && (
                    <p className="subtext">
                        Hold onto your kit until you have received your results via email or phone. Failure to return
                        the kit as instructed after receiving your assessment results may result in a charge.
                    </p>
                )}
            </Container>
        </>
    );
};

export default KitDelivery;
