import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { IUser, IUserModalPreference } from "model/user";

import { getUser } from "redux/selectors/data";

import userService from "services/userService";

interface IUseModalPreferenceHook {
    setUserPreference: () => Promise<void>;
    showModal: boolean;
}

const useModalPreference = (modalIdentifier: string): IUseModalPreferenceHook => {
    const currentUser: IUser = useSelector(getUser);
    const [showModal, setShowModal] = useState<boolean>(false);
    const setUserPreference = async () => {
        await userService.hideModal(modalIdentifier);
    };

    useEffect(() => {
        if (currentUser?.preferences?.modalPreferences) {
            const userPreferenceForModal: IUserModalPreference = currentUser.preferences.modalPreferences.find(
                (preference: IUserModalPreference) => preference.modalIdentifier === modalIdentifier
            );
            if (userPreferenceForModal) {
                const { hide } = userPreferenceForModal;
                setShowModal(!hide);
            }
        }
    }, [modalIdentifier, currentUser]);

    return { setUserPreference, showModal };
};

export default useModalPreference;
