import { DropdownItemProps } from "semantic-ui-react";

import { IMappedType } from "model/generics";

const createDropdownOptions = <Options extends IMappedType, Dictionary extends IMappedType>(
    options: Options,
    dictionary?: Dictionary
): DropdownItemProps[] =>
    Object.keys(options).map((key) => ({
        key,
        text: dictionary?.[key] || options[key],
        value: key,
    }));

export default createDropdownOptions;
