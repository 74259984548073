import React, { Component } from "react";

import StickyOrganisationTopPanel from "components/Administration/OrganisationManagment/ViewOrganisation/StickyOrganisationTopPanel";
import { ORGANISATION_INHERITANCE_PROPERTY } from "components/Administration/OrganisationManagment/ViewOrganisation/OrganisationDetailsScreen";
import CreateEscalationScreen from "components/Administration/OrganisationManagment/ViewOrganisation/ReviewNsc/CreateEscalationScreen";
import EscalationsTable from "components/Administration/OrganisationManagment/ViewOrganisation/ReviewNsc/EscalationsTable";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";

import { OrganisationManagementTabs, OrganisationMenuItems } from "model/administrationPages";
import { IOrganisation, IReviewEscalation } from "model/organisation";

interface IEscallationScreenProps {
    organisation: IOrganisation | undefined;
    showEditButton: boolean;
    updateInheritanceData: (property: string) => void;
}

interface IEscallationScreenState {
    mode: EscalationMode;
    organisation: IOrganisation;
}

enum EscalationMode {
    VIEW = "VIEW",
    CREATE = "CREATE",
}

class EscalationScreen extends Component<IEscallationScreenProps, IEscallationScreenState> {
    constructor(props: any) {
        super(props);

        const { organisation } = props;

        this.state = {
            mode: EscalationMode.VIEW,
            organisation,
        };
    }

    private onChangeMode = () => {
        const { mode, organisation } = this.state;

        if (organisation) {
            const isCreate = mode === EscalationMode.CREATE;
            this.setState({ mode: isCreate ? EscalationMode.VIEW : EscalationMode.CREATE });
        }
    };

    private updateOrganisation = (escalation: IReviewEscalation) => {
        const { updateInheritanceData } = this.props;
        const { organisation } = this.state;

        const updatedOrganisation = { ...organisation };

        if (updatedOrganisation.escalationPlans?.length) {
            updatedOrganisation.escalationPlans.push(escalation);
        } else {
            updatedOrganisation.escalationPlans = [escalation];
        }

        this.setState({
            organisation: updatedOrganisation,
            mode: EscalationMode.VIEW,
        });

        updateInheritanceData(ORGANISATION_INHERITANCE_PROPERTY[OrganisationMenuItems.REVIEW_SETTINGS]);
    };

    private removeCallback = (uuid: string) => {
        const { organisation } = this.state;

        const updatedOrganisation = { ...organisation };

        updatedOrganisation.escalationPlans = updatedOrganisation.escalationPlans.filter(
            (escalation) => escalation.uuid !== uuid
        );

        this.setState({
            organisation: updatedOrganisation,
        });
    };

    public render() {
        const { organisation, mode } = this.state;

        const isViewMode = mode === EscalationMode.VIEW;
        const isCreateMode = mode === EscalationMode.CREATE;

        return (
            <>
                <StickyOrganisationTopPanel title="Escalation Plan">
                    <OrganisationManagementTabHeader
                        organisationUuid={organisation ? organisation.uuid : ""}
                        type={OrganisationManagementTabs.REVIEW_SETTINGS}
                        showEditButton={false}
                        updateOrganisation={this.updateOrganisation}
                        createMode={!isViewMode}
                        changeMode={this.onChangeMode}
                    />
                </StickyOrganisationTopPanel>
                {isViewMode && (
                    <EscalationsTable
                        escalationPlans={organisation.escalationPlans || []}
                        organisation={organisation}
                        removeCallback={this.removeCallback}
                    />
                )}
                {isCreateMode && (
                    <CreateEscalationScreen onCreate={this.updateOrganisation} organisationUuid={organisation.uuid} />
                )}
            </>
        );
    }
}

export default EscalationScreen;
