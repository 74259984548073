import { ReactNode } from "react";

export enum ModalTypes {
    LESION_IMAGE = "LESION_IMAGE",
    SHOW_CLOSE_BUTTON = "SHOW_CLOSE_BUTTON",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export enum ModalIcons {
    SUCCESS = "icon-okay",
    ERROR = "icon-error",
}

export enum ModalIconAltTexts {
    SUCCESS = "Okay icon",
    ERROR = "Error icon",
    INFO = "Information icon",
    DOCUMENT = "Document icon",
    PDF = "PDF icon",
}

export interface WithModalParameters {
    isVisible: boolean;
    closeCallback?: () => void;
    content: ReactNode;
    size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
    type?: string;
    className?: string;
    closeOnOverlay?: boolean;
}

export interface ModalProps extends Omit<WithModalParameters, "content" | "isVisible"> {
    type: ModalTypes;
    children: ReactNode;
    show: boolean;
    cta?: () => void | null;
    ctaText?: string;
}
