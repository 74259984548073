import React, { FC, ChangeEvent } from "react";
import { Button, Form, Table, Grid, InputOnChangeData } from "semantic-ui-react";

interface IError {
    tempEmail?: boolean;
    isNotUnique?: boolean;
}

interface IEmailComponent {
    hideInput?: boolean;
    onEmailChange: (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
    addEmail: () => void;
    deleteEmail: (email: string) => () => void;
    error: { tempEmail?: boolean; isUnique?: boolean };
    email: string;
    emails?: string[];
    isRequired?: boolean;
}

const EmailComponent: FC<IEmailComponent> = ({
    hideInput,
    onEmailChange,
    addEmail,
    deleteEmail,
    error,
    email,
    emails,
    isRequired,
}) => {
    const renderError = (errors: IError) => {
        if (errors.isNotUnique) {
            return "Please enter unique email";
        }

        if (errors.tempEmail) {
            return "Please enter valid email";
        }

        return undefined;
    };

    return (
        <>
            <div className={`notification-input-block ${isRequired ? "is-required" : ""}`}>
                <div>
                    {!hideInput ? (
                        <>
                            <p className="notification-input-label">Email address</p>
                            <div className="notification-email">
                                <Form.Input
                                    className="long-text-field"
                                    type="email"
                                    id="userEmail"
                                    value={email || ""}
                                    onChange={onEmailChange}
                                    error={renderError(error)}
                                />
                                <Button
                                    className="notification-email-button"
                                    floated="left"
                                    onClick={addEmail}
                                    disabled={!email.trim().length}
                                    icon="plus"
                                />
                            </div>
                        </>
                    ) : null}
                </div>

                {emails?.length ? (
                    <div className="notification-email-list">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={12} computer={10} largeScreen={8} widescreen={6}>
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>#</Table.HeaderCell>
                                                <Table.HeaderCell>Email</Table.HeaderCell>
                                                <Table.HeaderCell>Action</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {emails.map((emailItem: string, index) => (
                                                <Table.Row key={emailItem}>
                                                    <Table.Cell collapsing>{index + 1}</Table.Cell>
                                                    <Table.Cell>
                                                        <a href={`mailto:${emailItem}`}>{emailItem}</a>
                                                    </Table.Cell>
                                                    <Table.Cell collapsing>
                                                        <Button
                                                            className="notification-email-button"
                                                            onClick={deleteEmail(emailItem)}
                                                            icon="close"
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                ) : null}
            </div>
        </>
    );
};

EmailComponent.defaultProps = {
    hideInput: false,
    emails: [],
    isRequired: false,
};

export default EmailComponent;
