import React, { createRef, MouseEvent as ReactMouseEvent, ChangeEvent, useState, useEffect } from "react";
import { Button, Form, Grid, Segment, DropdownProps, PaginationProps } from "semantic-ui-react";
import debounce from "lodash.debounce";

import CustomButton from "components/templates/Button";
import ConditionalRender from "components/templates/ConditionalRender";
import CaseReportCSVDialog from "components/templates/dialog/CaseReportCSVDialog";
import ImportUsersDialog from "components/templates/dialog/ImportUsersDialog";
import UserTable from "components/Administration/UserManagement/UserList/UserTable";

import { AdministrationPages, UserManagementSubpages } from "model/administrationPages";
import { IDictionaryItem } from "model/dictionaryItem";
import { FILTER_ALL } from "model/filters";
import { IUser } from "model/user";
import { UserRole, UserRoleFilter } from "model/userRole";
import { UserStatusFilter } from "model/userStatus";

import { ADMINISTRATION } from "navigation/routes";

import organizationService from "services/organizationService";
import reportsService from "services/reportsService";
import userService from "services/userService";

type InitialState = Omit<ILoadPage, "actualPage">;

const INITIAL_STATE: InitialState = {
    emailFilter: "",
    nameFilter: "",
    surnameFilter: "",
    organisationFilter: [],
    roleFilter: "",
    statusFilter: "",
    preauthorisationNumberFilter: "",
    memberNumberFilter: "",
};

interface ILoadPage {
    actualPage: number;
    emailFilter: string | undefined;
    nameFilter: string | undefined;
    surnameFilter: string | undefined;
    statusFilter: string | undefined;
    roleFilter: string | undefined;
    organisationFilter: string[] | undefined;
    preauthorisationNumberFilter: string | undefined;
    memberNumberFilter: string | undefined;
}

export default function UserList() {
    const [searchFilters, setSearchFilters] = useState<InitialState>(INITIAL_STATE);
    const [organisations, setOrganisations] = useState<IDictionaryItem[]>([]);
    const [caseReportCSVDialogOpened, setCaseReportCSVDialogOpened] = useState<boolean>(false);
    const [importUsersDialogOpened, setImportUsersDialogOpened] = useState<boolean>(false);
    const [uploadUserListError, setUploadUserListError] = useState<string>("");
    const [userList, setUserList] = useState<IUser[]>([]);
    const [activePage, setActivePage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalUsers, setTotalUsers] = useState<number>(0);

    const inputFileRef = createRef<HTMLInputElement>();

    const handleFiltersSearch = (
        emailFilter: string | undefined,
        nameFilter: string | undefined,
        surnameFilter: string | undefined,
        statusFilter: string | undefined,
        roleFilter: string | undefined,
        organisationFilter: string[] | undefined,
        preauthorisationNumberFilter: string | undefined,
        memberNumberFilter: string | undefined
    ) => {
        const filters: string[] = [];
        const search: string[] = [];
        const organisationSearch: string[] = [];

        if (emailFilter) {
            search.push(`email.text:${emailFilter}`);
        }
        if (nameFilter) {
            search.push(`name:${nameFilter}`);
        }
        if (surnameFilter) {
            search.push(`surname:${surnameFilter}`);
        }
        if (statusFilter && statusFilter !== UserStatusFilter.ALL) {
            filters.push(`status:${statusFilter}`);
        }
        if (roleFilter && roleFilter !== UserRoleFilter.ALL) {
            filters.push(`role:${roleFilter}`);
        }
        if (organisationFilter && organisationFilter[0] !== FILTER_ALL) {
            organisationSearch.push(`${organisationFilter}`);
        }
        if (preauthorisationNumberFilter) {
            search.push(`preauthorisationNumber:${preauthorisationNumberFilter}`);
        }
        if (memberNumberFilter) {
            search.push(`memberNumber:${memberNumberFilter}`);
        }

        return {
            filters,
            search,
            organisationSearch,
        };
    };

    const loadPage = ({
        actualPage,
        emailFilter,
        nameFilter,
        surnameFilter,
        statusFilter,
        roleFilter,
        organisationFilter,
        preauthorisationNumberFilter,
        memberNumberFilter,
    }: ILoadPage) => {
        try {
            const { filters, search, organisationSearch } = handleFiltersSearch(
                emailFilter,
                nameFilter,
                surnameFilter,
                statusFilter,
                roleFilter,
                organisationFilter,
                preauthorisationNumberFilter,
                memberNumberFilter
            );
            userService
                .getAllUsers({ offset: actualPage * 10, filters, search, organisations: organisationSearch })
                .then((result) => {
                    setActivePage(actualPage);
                    setTotalPages(Math.ceil(result.total / 10));
                    setTotalUsers(result.total);
                    setUserList(result.data);
                });
        } catch (e) {
            console.error(e);
        }
    };

    const loadPageAfterFilter = (): void => {
        const pageLoad: ILoadPage = {
            actualPage: 0,
            ...searchFilters,
        };
        loadPage(pageLoad);
    };

    const handlePaginationChange = (
        event: ReactMouseEvent<HTMLAnchorElement, MouseEvent> | ChangeEvent<HTMLAnchorElement>,
        data: PaginationProps
    ): void => {
        const actualPage = Number(data.activePage) - 1;
        loadPage({
            actualPage,
            ...searchFilters,
        });
    };

    const debouncedLoadPageAfterFilter = debounce(loadPageAfterFilter, 500);

    useEffect(() => {
        debouncedLoadPageAfterFilter();
    }, [searchFilters]);

    const toggleImportUsersDialog = (): void => setImportUsersDialogOpened(!importUsersDialogOpened);
    const toggleReportCSVDialog = (): void => setCaseReportCSVDialogOpened(!caseReportCSVDialogOpened);

    const downloadCSVUsersList = (): void => {
        const { filters, search, organisationSearch } = handleFiltersSearch(
            searchFilters.emailFilter,
            searchFilters.nameFilter,
            searchFilters.surnameFilter,
            searchFilters.statusFilter,
            searchFilters.roleFilter,
            searchFilters.organisationFilter,
            searchFilters.preauthorisationNumberFilter,
            searchFilters.memberNumberFilter
        );

        reportsService.requestGenerateUsersCsvReport(filters, search, organisationSearch);
        toggleReportCSVDialog();
    };

    const uploadCSVUsersList = (event: any): void => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.readAsBinaryString(file);

            const formData = new FormData();
            formData.append("users", file);

            reportsService
                .uploadUserList(formData)
                .then(() => {
                    toggleImportUsersDialog();
                    setUploadUserListError(null);
                })
                .catch((err) => {
                    setUploadUserListError(err.message);
                    toggleImportUsersDialog();
                    inputFileRef.current.value = "";
                });
        }
    };

    const initialFetch = async (): Promise<void> => {
        await loadPage({
            actualPage: 0,
            ...searchFilters,
        });
    };

    const clearFiltersSearch = (): void => {
        setSearchFilters({ ...INITIAL_STATE });
        initialFetch();
    };

    const useMountEffect = (effectFn): void => useEffect(effectFn, []);
    useMountEffect(() => {
        initialFetch().then(() => {
            organizationService.getAllOrganisationsDictionary().then((resp) => setOrganisations(resp));
        });
    });

    return (
        <div>
            <CaseReportCSVDialog
                onClose={toggleReportCSVDialog}
                callback={toggleReportCSVDialog}
                showDialog={caseReportCSVDialogOpened}
            />

            <ImportUsersDialog
                onClose={toggleImportUsersDialog}
                callback={toggleImportUsersDialog}
                showDialog={importUsersDialogOpened}
                errors={uploadUserListError}
            />

            <ConditionalRender requiredRole={[UserRole.SUPERADMIN]}>
                <Segment>
                    <h2>User List</h2>
                    <Form className="admin-user-list">
                        <Grid columns={4}>
                            <Grid.Row key="optionsRow">
                                <Grid.Column width={8} />
                                <Grid.Column width={7} textAlign="right">
                                    <CustomButton
                                        className="create-user-btn"
                                        to={`${ADMINISTRATION}/${AdministrationPages.USER_MANAGEMENT}/${
                                            UserManagementSubpages.CREATE_USER
                                        }${
                                            searchFilters.organisationFilter
                                                ? `/${searchFilters.organisationFilter}`
                                                : ""
                                        }`}
                                        text="Create new user"
                                        type="link"
                                        variant="dark"
                                    />

                                    <Button
                                        onKeyPress={() => inputFileRef.current.click()}
                                        className="btn-upload-csv-file"
                                    >
                                        <label htmlFor="fileCSVButton">
                                            Import user list .csv
                                            <input
                                                style={{ display: "none" }}
                                                role="button"
                                                id="fileCSVButton"
                                                type="file"
                                                accept=".csv"
                                                onChange={uploadCSVUsersList}
                                                ref={inputFileRef as any}
                                            />
                                        </label>
                                    </Button>
                                    <Button disabled={!userList?.length} onClick={downloadCSVUsersList}>
                                        Download list .csv
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row key="filterRow">
                                <Grid.Column width={3}>
                                    <Form.Input
                                        type="text"
                                        value={searchFilters.emailFilter}
                                        label="Email"
                                        id="emailFilter"
                                        placeholder="Search by email..."
                                        onChange={(e) => {
                                            setSearchFilters({
                                                ...searchFilters,
                                                emailFilter: e.target.value as string,
                                            });
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Form.Input
                                        type="text"
                                        value={searchFilters.nameFilter}
                                        label="Name"
                                        id="nameFilter"
                                        placeholder="Search by user name..."
                                        onChange={(e) => {
                                            setSearchFilters({
                                                ...searchFilters,
                                                nameFilter: e.target.value as string,
                                            });
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Form.Input
                                        type="text"
                                        value={searchFilters.surnameFilter}
                                        label="Surname"
                                        id="surnameFilter"
                                        placeholder="Search by user surname..."
                                        onChange={(e) => {
                                            setSearchFilters({
                                                ...searchFilters,
                                                surnameFilter: e.target.value as string,
                                            });
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Form.Select
                                        label="Role"
                                        value={searchFilters.roleFilter}
                                        id="roleFilter"
                                        placeholder="Search by user role..."
                                        onChange={(e: any, data: any) => {
                                            setSearchFilters({
                                                ...searchFilters,
                                                roleFilter: data.value,
                                            });
                                        }}
                                        options={Object.keys(UserRoleFilter).map((key) => ({
                                            key,
                                            text: UserRoleFilter[key],
                                            value: key,
                                        }))}
                                    />
                                </Grid.Column>

                                <Grid.Column width={2} floated="right">
                                    <Button tabIndex={0} className="clear-filter-button" onClick={clearFiltersSearch}>
                                        Clear
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row key="organisationsRow">
                                <Grid.Column width={12}>
                                    <Form.Dropdown
                                        className="organisation-select"
                                        search
                                        selection
                                        fluid
                                        multiple
                                        label="Organisation"
                                        value={searchFilters.organisationFilter}
                                        id="organisationFilter"
                                        placeholder="Search by organisation..."
                                        onChange={(e: any, data: DropdownProps) =>
                                            setSearchFilters({
                                                ...searchFilters,
                                                organisationFilter: data.value as string[],
                                            })
                                        }
                                        options={organisations.map((org, idx) => ({
                                            text: org.text,
                                            key: idx,
                                            value: org.value,
                                        }))}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <Form.Input
                                        type="text"
                                        value={searchFilters.preauthorisationNumberFilter}
                                        label="Pre-authorisation Number"
                                        id="preauthorisationNumberFilter"
                                        placeholder="Search by preauthorisation number..."
                                        onChange={(e: any) => {
                                            setSearchFilters({
                                                ...searchFilters,
                                                preauthorisationNumberFilter: e.target.value as string,
                                            });
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Input
                                        type="text"
                                        value={searchFilters.memberNumberFilter}
                                        label="Member Number"
                                        id="memberNumberFilter"
                                        placeholder="Search by member number..."
                                        onChange={(e: any) => {
                                            setSearchFilters({
                                                ...searchFilters,
                                                memberNumberFilter: e.target.value as string,
                                            });
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Select
                                        label="Status"
                                        value={searchFilters.statusFilter}
                                        id="statusFilter"
                                        placeholder="Search by user status..."
                                        onChange={(e: any, data: any) => {
                                            setSearchFilters({
                                                ...searchFilters,
                                                statusFilter: data.value,
                                            });
                                        }}
                                        options={Object.keys(UserStatusFilter).map((key) => ({
                                            key,
                                            text: UserStatusFilter[key],
                                            value: key,
                                        }))}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <UserTable
                            activePage={activePage}
                            userList={userList}
                            totalPages={totalPages}
                            totalUsers={totalUsers}
                            handlePaginationChange={handlePaginationChange}
                        />
                    </Form>
                </Segment>
            </ConditionalRender>
        </div>
    );
}
