import React, { FC, useContext } from "react";
import { useSelector } from "react-redux";

import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";

import { ModalContext } from "contextProviders/ModalProvider";

import { UserRole } from "model/userRole";

import getAuth from "redux/selectors/auth";

import "scss/Container.scss";

interface IAssessmentFlowHeaderProps {
    title: string;
    isEditMode: boolean;
    isRemoteModel?: boolean;
    disabled: boolean;
    edit?: () => void;
    reset?: () => void;
}

const AssessmentFlowHeader: FC<IAssessmentFlowHeaderProps> = ({
    title,
    isEditMode,
    isRemoteModel,
    disabled,
    edit,
    reset,
}) => {
    const auth = useSelector(getAuth);

    const { setModalParameters, closeModal } = useContext(ModalContext);

    const isClientAdmin: boolean = auth?.authTokenBody?.userRole === UserRole.ADMIN;
    const isEditingAllowed: boolean = ((isRemoteModel && !isClientAdmin) || !isRemoteModel) && Boolean(edit);

    const handleResetPressed = () => {
        if (reset) reset();
        closeModal();
    };

    const handleCancelPressed = () => {
        setModalParameters({
            content: (
                <div className="confirm-modal-wrapper">
                    <p>Discard changes?</p>
                    <ButtonContainer
                        button1={
                            <CustomButton variant="empty" type="submit" action={closeModal} text="No, keep editing" />
                        }
                        button2={
                            <CustomButton
                                variant="filled"
                                type="submit"
                                action={handleResetPressed}
                                text="Yes, discard changes"
                                size="small"
                            />
                        }
                    />
                </div>
            ),
            isVisible: true,
            size: "small",
        });
    };

    return (
        <div className="wizzard-container-title">
            <h1>{title}</h1>

            {isEditingAllowed && (
                <div>
                    <CustomButton
                        text={isEditMode ? "Save" : "Edit"}
                        variant="filled"
                        type="submit"
                        disabled={disabled}
                        action={edit}
                    />
                    {isEditMode && (
                        <CustomButton
                            text="Cancel"
                            variant="filled"
                            type="submit"
                            disabled={disabled}
                            action={handleCancelPressed}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

AssessmentFlowHeader.defaultProps = {
    isRemoteModel: false,
    edit: undefined,
    reset: () => undefined,
};

export default AssessmentFlowHeader;
