import React, { FC, useContext } from "react";
import { Button } from "semantic-ui-react";

import AddNotesModal from "components/CaseDescription/Review/AddNotesModal";

import { CaseListContext } from "contextProviders/CaseListProvider";
import { CaseReviewContext } from "contextProviders/CaseReviewProvider";
import { ModalContext } from "contextProviders/ModalProvider";

import { NoteType } from "model/reviewNotes";

interface IAddNotesButton {
    type: NoteType;
    caseUuid: string;
    patientUuid: string;
    text: string;
    updateCaseNotes: (type: NoteType, note: string) => void;
}

const AddNotesButton: FC<IAddNotesButton> = ({ type, caseUuid, patientUuid, text, updateCaseNotes }) => {
    const { setModalParameters } = useContext(ModalContext);
    const { updateCaseStatus } = useContext(CaseReviewContext);
    const { updateCaseStatus: updateCaseListItemStatus } = useContext(CaseListContext);

    const handleAddNotes = () => {
        setModalParameters({
            content: (
                <AddNotesModal
                    type={type}
                    caseUuid={caseUuid}
                    patientUuid={patientUuid}
                    updateCaseStatus={updateCaseStatus}
                    updateCaseListItemStatus={updateCaseListItemStatus}
                    updateCaseNotes={updateCaseNotes}
                />
            ),
            isVisible: true,
            size: "small",
        });
    };

    return <Button onClick={handleAddNotes}>{text}</Button>;
};

export default AddNotesButton;
