import { FormFields, IPatientSearchForm } from "model/patientSearch";

export interface IEmptyFields {
    isDateOfBirthEmpty: boolean;
    isHospitalNumberEmpty: boolean;
    isNhsNumberEmpty: boolean;
    areAdvancedAdditionalFieldsEmpty: boolean;
}

const MINIMUM_POSTCODE_LENGTH_TO_APPLY_FORMATTING = 3;
const SYMBOLS_BEFORE_THE_BACKSPACE_PASTING = 3;

const isFieldEmpty = (propertyName: FormFields, form: IPatientSearchForm): boolean => {
    const value: string = form[propertyName];
    return value === "" || value === null;
};

const checkFormForEmptyFields = (form: IPatientSearchForm): IEmptyFields => {
    const isDateOfBirthEmpty = isFieldEmpty(FormFields.dateOfBirth, form);
    const isNameEmpty = isFieldEmpty(FormFields.name, form);
    const isSurnameEmpty = isFieldEmpty(FormFields.surname, form);
    const isGenderEmpty = isFieldEmpty(FormFields.gender, form);
    const isPostCodeEmpty = isFieldEmpty(FormFields.postCode, form);
    const isHospitalNumberEmpty = isFieldEmpty(FormFields.hospitalNumber, form);
    const isNhsNumberEmpty = isFieldEmpty(FormFields.nhsNumber, form);
    const areAdvancedAdditionalFieldsEmpty = isSurnameEmpty && isNameEmpty && isGenderEmpty && isPostCodeEmpty;

    return {
        isDateOfBirthEmpty,
        isHospitalNumberEmpty,
        isNhsNumberEmpty,
        areAdvancedAdditionalFieldsEmpty,
    };
};

const doesStringContainBackspace = (postCodeValue: string): boolean => postCodeValue.indexOf(" ") !== -1;

const formatPostcode = (postCodeValue: string): string => {
    const postcodeLength = postCodeValue.length;
    if (postcodeLength > MINIMUM_POSTCODE_LENGTH_TO_APPLY_FORMATTING) {
        const sliceIndex = postcodeLength - SYMBOLS_BEFORE_THE_BACKSPACE_PASTING;
        return `${postCodeValue.slice(0, sliceIndex)} ${postCodeValue.slice(sliceIndex)}`;
    }
    return postCodeValue;
};

export { checkFormForEmptyFields, doesStringContainBackspace, formatPostcode };
