import { Config } from "config/config";

const GET_ALL_SUBOGRANISATIONS_LIMIT =
    "limit=10&sort=name.keyword:asc&offset={{OFFSET}}&filter=parentUuid:{{PARENTUUID}}";

// eslint-disable-next-line import/no-mutable-exports
let URLs: Record<string, any> = {
    // Assessment service
    LESIONS_DEFINED_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{uuid}/lesionsDefined`,
    COMPLETE_CASE_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{uuid}/complete`,

    // Auditlog service
    GET_AUDITLOG_BY_CASE_ID: `${Config.getReaderMsUrl()}/api/v1/reader/auditlog/admin/case/{0}`,

    // Authorization service
    LOGIN_URL: `${Config.getUsersMsUrl()}/api/v1/auth/login`,
    LOGOUT_URL: `${Config.getUsersMsUrl()}/api/v1/auth/logout`,
    SWITCH_USER_ORGANISATION_URL: `${Config.getUsersMsUrl()}/api/v1/auth/switchOrganisation`,
    CHANGE_PASSWORD_URL: `${Config.getUsersMsUrl()}/api/v1/password/change`,
    RESET_PASSWORD_URL: `${Config.getUsersMsUrl()}/api/v1/password/reset`,
    SET_PASSWORD_URL: `${Config.getUsersMsUrl()}/api/v1/password/set`,
    UPDATE_PASSWORD_URL: `${Config.getUsersMsUrl()}/api/v1/password/update`,
    INIT_RESET_PASSWORD_URL: `${Config.getUsersMsUrl()}/api/v1/password/update/reset`,
    SEND_PASSWORD_EMAIL_VERIFICATION: `${Config.getUsersMsUrl()}/api/v1/password/email/verification`,

    // MFA
    MFA_CREATE_GRACE_PERIOD_START_DATE: `${Config.getUsersMsUrl()}/api/v1/mfa/grace-period`,
    MFA_SETUP_CHECK: `${Config.getReaderMsUrl()}/api/v1/reader/users/mfa/check`,
    MFA_EMAIL_INITIATION: `${Config.getNotificationMsUrl()}/api/v1/verification/mfa/email`,
    MFA_SMS_INITIATION: `${Config.getNotificationMsUrl()}/api/v1/verification/mfa/sms`,
    MFA_VERIFY_CODE: `${Config.getNotificationMsUrl()}/api/v1/verification/mfa`,

    // Case service
    CREATE_CASE_URL: `${Config.getCasesMsUrl()}/api/v1/cases`,
    UPDATE_CASE_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{{UUID}}`,
    CLOSE_CASE_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{uuid}/close`,
    ABANDON_CASE_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{uuid}/abandon`,
    GET_REVIEWED_CASES: `${Config.getReaderMsUrl()}/api/v1/reader/cases/reviewed`,
    GET_CASE_URL: `${Config.getReaderMsUrl()}/api/v1/reader/cases`,
    GET_REPORT_URL: `${Config.getReportsMsUrl()}/api/v1/reports`,
    GET_IMAGE_URL: `${Config.getReportsMsUrl()}/api/v1/images/{{UUID}}`,
    DOWNLOAD_IMAGES_URL: {
        original_url: `${Config.getReportsMsUrl()}/api/v1/images/case`,
        proxy_url: `${Config.getServicesUrl()}/images/case`,
    },
    REOPEN_CASE_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{uuid}/reopen`,
    UPLOAD_MACRO_IMAGE: {
        original_url: `${Config.getImagesMsUrl()}/api/v1/image/macro`,
        proxy_url: `${Config.getServicesUrl()}/image/macro`,
    },
    UPLOAD_DERMOSCOPIC_IMAGE: {
        original_url: `${Config.getImagesMsUrl()}/api/v1/image/dermoscopic`,
        proxy_url: `${Config.getServicesUrl()}/image/dermoscopic`,
    },
    RETAKE_IMAGES_URL: `${Config.getImagesMsUrl()}/api/v1/image/retake/request`,
    ADD_ADMIN_NOTE: `${Config.getCasesMsUrl()}/api/v1/cases/{{UUID}}/admin/notes`,
    LESION_DEFINED_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{{UUID}}/lesionsDefined`,
    CASE_FEEDBACK_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{{UUID}}/feedbacks`,
    KIT_STATUS_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{{UUID}}/kit/deliveryStatus`,
    KIT_TRACKIN_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{{UUID}}/kit/trackin`,
    KIT_TRACKOUT_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{{UUID}}/kit/trackout`,
    REQUEST_CALLBACK_URL: `${Config.getCasesMsUrl()}/api/v1/cases/{{UUID}}/callbackIsNeeded`,

    // Diagnosis service
    // TODO when API-GATEWAY will work - replace with url from config.
    CREATE_DIAGNOSIS_URL: `${Config.getStudyMsUrl()}/api/v1/diagnoses`,
    UPDATE_DIAGNOSIS_URL: `${Config.getStudyMsUrl()}/api/v1/diagnoses/{uuid}`,

    // Lesion service
    CREATE_LESION_URL: `${Config.getLesionsMsUrl()}/api/v1/lesions`,
    UPDATE_LESION_URL: `${Config.getLesionsMsUrl()}/api/v1/lesions/{{UUID}}`,
    GET_LESION_URL: `${Config.getReaderMsUrl()}/api/v1/reader/lesions/{{UUID}}`,
    UPDATE_EXCLUSIONS_URL: `${Config.getLesionsMsUrl()}/api/v1/lesions/{{UUID}}/exclusions`,

    // Notification service
    POST_NOTIFICATION_CONFIGURATION: `${Config.getNotificationMsUrl()}/api/v1/configuration`,
    DELETE_NOTIFICATION_CONFIGURATION: `${Config.getNotificationMsUrl()}/api/v1/configuration/{{UUID}}`,
    EDIT_NOTIFICATION_CONFIGURATION: `${Config.getNotificationMsUrl()}/api/v1/configuration/{{UUID}}`,

    // Organisation service
    USER_ME_URL: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/my`,
    UNAUTH_USER_GET_ORG_URL: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/{{UUID}}`,
    GET_FIRST_500_ORGANISATIONS_DICT: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/admin/all?fields=uuid,name&sort=name.keyword:asc&limit=500`,
    GET_ALL_ORGANISATIONS_URL: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/admin/all?limit=10&sort=name.keyword:asc&offset={{OFFSET}}`,
    CREATE_ORGANISATION_URL: `${Config.getOrganisationMs()}/api/v1/organisations`,
    CREATE_SUBORGANISAION_URL: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/suborganisations`,
    MODIFY_ORGANISATION_URL: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}`,
    UPDATE_ORGANISATION_CONSENT_URL: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/consent`,
    GET_ORGANISATION_ADMIN_URL: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/admin/{{UUID}}`,
    GET_ORGANISATION_QUESTIONS: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/{{UUID}}/questions?type={{TYPE}}`,
    GET_ORGANISATION_FLOW: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/{{UUID}}/flow`,
    GET_ORGANISATION_WORKING_DAYS: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/{{UUID}}/workingdays`,
    UPDATE_ORGANISATION_WORKING_DAYS: `${Config.getNotificationMsUrl()}/api/v1/workingdays`,
    UPDATE_ORGANISATION_PATIENT_DETAILS_INTEGRATION: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/integration/switch`,

    SET_DERM_USER_URL: `${Config.getDermMs()}/api/v1/derm/credentials`,

    CREATE_NEW_LOCATION: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/locations`,
    DELETE_LOCATION: `${Config.getOrganisationMs()}/api/v1/locations/{{UUID}}`,

    UPDATE_PERSONAL_DATA: `${Config.getOrganisationMs()}/api/v1/personaldata/{{UUID}}`,
    DELETE_PERSONAL_DATA: `${Config.getOrganisationMs()}/api/v1/personaldata/{{UUID}}`,
    CREATE_PERSONAL_DATA: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/personaldata`,

    GET_ALL_SUBOGRANISATIONS_LIMIT,
    GET_ALL_SUBORGANISATIONS_URL: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/admin/all?${GET_ALL_SUBOGRANISATIONS_LIMIT}`,

    CREATE_ALLOCATION_CONFIG: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/allocations/list`,
    UPDATE_ALLOCATION_CONFIG: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/allocations/list`,

    DELETE_TEMPLATE: `${Config.getOrganisationMs()}/api/v1/templates/{{UUID}}`,
    CREATE_TEMPLATE: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/templates`,
    UPDATE_TEMPLATE: `${Config.getOrganisationMs()}/api/v1/templates/{{UUID}}`,

    GET_ORGANISATION_MANAGEMENT_OUTCOMES: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/managementoptions`,
    GET_ORGANISATION_BY_UUID_MANAGEMENT_OUTCOMES: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/{{UUID}}/managementoptions`,
    GET_ORGANISATION_BY_UUID_DIAGNOSES: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/{{UUID}}/diagnoses`,
    GET_ORGANISATION_BY_UUID_ESCALATIONS: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/{{UUID}}/escalationplans`,

    GET_ORGANISATION_BY_UUID_PATHWAYCONSENT: `${Config.getReaderMsUrl()}/api/v1/reader/organisations/{{UUID}}/pathwayconsent`,

    COPY_PARENT_ORGANISATION_SETTINGS: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/personaldata/copy`,
    COPY_PARENT_ORGANISATION_QUESTIONS: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/questions/copy`,
    COPY_PARENT_ORGANISATION_EXCLUSIONS: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/exclusions/copy`,
    COPY_PARENT_ORGANISATION_DIAGNOSES: `${Config.getReviewMs()}/api/v1/diagnoses/copy`,
    COPY_PARENT_ORGANISATION_TEMPLATES: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/templates/copy`,
    COPY_PARENT_ORGANISATION_ALLOCATIONS: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/allocations/copy`,
    COPY_PARENT_ORGANISATION_LOCATIONS: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/locations/copy`,
    COPY_PARENT_ORGANISATION_NOTIFICATIONS: `${Config.getNotificationMsUrl()}/api/v1/configuration/copy`,
    REMOVE_ORGANISATION_NOTIFICATIONS: `${Config.getNotificationMsUrl()}/api/v1/configuration/removeAll`,

    UPLOAD_ORGANISATION_LOGO: `${Config.getImagesMsUrl()}/api/v1/image/organisationlogo`,
    GET_ORGANISATION_LOGO: `${Config.getReportsMsUrl()}/api/v1/images/assets/{{UUID}}`,
    REMOVE_ORGANISATION_LOGO: `${Config.getImagesMsUrl()}/api/v1/image/organisationlogo/{{UUID}}`,
    UPDATE_ORGANISATION_OVERDUE_SETTING: `${Config.getNotificationMsUrl()}/api/v1/overdue/{{UUID}}`,
    UPDATE_AUTOALLOCATION: `${Config.getReviewMs()}/api/v1/autoallocation/config`,
    GET_ORGANISATION_UUID_BY_CODENAME: `${Config.getReaderMsUrl()}/api/v1/reader/registration/organisations/{{CODE_NAME}}`,
    GET_ORGANISATION_LOGO_PUBLIC: `${Config.getReportsMsUrl()}/api/v1/images/organisationLogo/{{UUID}}`,
    GET_ORGANISATION_REGISTRATION_TEXTS_PUBLIC: `${Config.getReaderMsUrl()}/api/v1/reader/registration/organisations/{{UUID}}/texts`,
    GET_PERSONAL_DATA_CORE_FIELDS: `${Config.getOrganisationMs()}/api/v1/personaldata/corefields?integrationType={{IS_INTEGRATION_ENABLED}}`,

    CREATE_AUDIT_CONFIG: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/auditConfiguration/list`,
    UPDATE_AUDIT_CONFIG: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/auditConfiguration/list`,

    // Patient service
    CREATE_PATIENT_URL: `${Config.getPatientsMsUrl()}/api/v1/patients`,
    UPDATE_PATIENT_URL: `${Config.getPatientsMsUrl()}/api/v1/patients/{uuid}`,
    SUBMIT_MEDICAL_ANSWERS_URL: `${Config.getPatientsMsUrl()}/api/v1/patients/{uuid}/answers`,
    SEARCH_INTEGRATION_PATIENT_URL: `${Config.getPatientsMsUrl()}/api/v1/integrations/patients`,
    CREATE_PATIENT_FROM_INTEGRATION_URL: `${Config.getPatientsMsUrl()}/api/v1/integrations/patients`,
    SUBMIT_SKIN_TONE_CLASSIFICATION: `${Config.getPatientsMsUrl()}/api/v1/patients/{{UUID}}/skinToneClassification?clickOrigin={{CLICKORIGIN}}`,

    // Question service
    CREATE_MEDICAL_QUESTIONS: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/questions/list`,
    CREATE_MEDICAL_QUESTION: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/questions`,
    MODIFY_MEDICAL_QUESTION: `${Config.getOrganisationMs()}/api/v1/questions/{{UUID}}`,
    DELETE_MEDICAL_QUESTION: `${Config.getOrganisationMs()}/api/v1/questions/{{UUID}}`,

    ADD_EXCLUSION: `${Config.getOrganisationMs()}/api/v1/organisations/{{UUID}}/exclusions`,
    MODIFY_EXCLUSION: `${Config.getOrganisationMs()}/api/v1/exclusions/{{UUID}}`,
    REMOVE_EXCLUSION: `${Config.getOrganisationMs()}/api/v1/exclusions/{{UUID}}`,

    // Registration service
    CREATE_CONSENT: `${Config.getRegistrationMsUrl()}/api/v1/registration/consent`,
    GET_QUESTIONS_LIST: `${Config.getReaderMsUrl()}/api/v1/reader/registration/organisations/{{UUID}}/questions`,
    SEND_ANSWERS: `${Config.getRegistrationMsUrl()}/api/v1/registration/answers`,
    SUBMIT_AT_HOME_FLOW_CASE: `${Config.getRegistrationMsUrl()}/api/v1/homeQuestionnaire`,
    GET_PERSONAL_DATA: `${Config.getReaderMsUrl()}/api/v1/reader/registration/organisations/{{UUID}}/personaldata`,
    SEND_PERSONAL_DATA: `${Config.getRegistrationMsUrl()}/api/v1/registration/accounts`,
    VERIFY_MOBILE_NUMBER: `${Config.getNotificationMsUrl()}/api/v1/verification/sms`,
    SEND_POST_CODE: `${Config.getPatientsMsUrl()}/api/v1/address?postcode={{POSTCODE}}`,

    // Report service
    GET_CASES: `${Config.getReaderMsUrl()}/api/v1/reader/cases?limit={{LIMIT}}&filter=creationDate:gt:{{DATEFROM}};creationDate:lt:{{DATETO}}`,

    GET_CASES_CSV: `${Config.getReportsMsUrl()}/api/v1/csvreports/cases?&filter=creationDate:gt:{{DATEFROM}};creationDate:lt:{{DATETO}}`,
    GET_NEXT_STEPS_CSV: `${Config.getReportsMsUrl()}/api/v1/csvreports/next-steps?&filter=creationDate:gt:{{DATEFROM}};creationDate:lt:{{DATETO}}`,
    GET_LESIONS_CSV: `${Config.getReportsMsUrl()}/api/v1/csvreports/cases?&lesions=true&filter=creationDate:gt:{{DATEFROM}};creationDate:lt:{{DATETO}}`,
    GET_USERS_CSV: `${Config.getReportsMsUrl()}/api/v1/csvreports/users`,

    GET_REPORT_CSV: `${Config.getReportsMsUrl()}/api/v1/csvreports/download/{{CASEID}}/{{FILENAME}}`,
    GET_CASE_LIST_REPORT_CSV: `${Config.getReportsMsUrl()}/api/v1/csvreports/cases/list`,
    GET_KIT_TO_DISPATCH_REPORT_CSV: `${Config.getReportsMsUrl()}/api/v1/csvreports/kits/dispatch`,
    UPLOAD_USER_LIST: `${Config.getUsersMsUrl()}/api/v1/users/import`,

    // Review management service
    CREATE_DIAGNOSES: `${Config.getReviewMs()}/api/v1/diagnoses`,
    UPDATE_ALL_DIAGNOSES: `${Config.getReviewMs()}/api/v1/diagnoses/list`,
    UPDATE_DIAGNOSES: `${Config.getReviewMs()}/api/v1/diagnoses/{{UUID}}`,
    DELETE_DIAGNOSES: `${Config.getReviewMs()}/api/v1/diagnoses/{{UUID}}`,
    CREATE_MANAGEMENT_OUTCOME: `${Config.getReviewMs()}/api/v1/management`,
    DELETE_MANAGEMENT_OUTCOME: `${Config.getReviewMs()}/api/v1/management/{{UUID}}`,
    CREATE_ESCALATION: `${Config.getReviewMs()}/api/v1/escalationplans`,
    DELETE_ESCALATION: `${Config.getReviewMs()}/api/v1/escalationplans/{{UUID}}`,

    // Review service
    CREATE_REVIEW: `${Config.getReviewMs()}/api/v1/reviews`,
    ALLOCATE_CASE: `${Config.getReviewMs()}/api/v1/assignments`,
    UNALLOCATE_CASE: `${Config.getReviewMs()}/api/v1/assignments/{{UUID}}`,
    CREATE_NOTE: `${Config.getReviewMs()}/api/v1/notes`,
    REMOVE_OR_UPDATE_NOTE: `${Config.getReviewMs()}/api/v1/notes/{{UUID}}`,
    SUBMIT_CALLBACK_OUTCOME: `${Config.getReviewMs()}/api/v1/callback/outcomes`,

    // Study service
    GET_STUDIES: `${Config.getReaderMsUrl()}/api/v1/reader/studies`,
    GET_STUDY_DEVICES: `${Config.getReaderMsUrl()}/api/v1/reader/studies/{0}/devices?limit={{LIMIT}}&sort=creationDate:desc&offset={{OFFSET}}&filter=removed:false`,
    GET_ALL_STUDIES: `${Config.getReaderMsUrl()}/api/v1/reader/studies/admin/all?limit=10&sort=creationDate:desc&offset={{OFFSET}}`,
    CREATE_STUDY: `${Config.getStudyMsUrl()}/api/v1/studies`,
    CREATE_DEVICE: `${Config.getStudyMsUrl()}/api/v1/devices`,
    DELETE_DEVICE: `${Config.getStudyMsUrl()}/api/v1/devices/{{UUID}}`,

    // User service
    USER_MY_USER_URL: `${Config.getReaderMsUrl()}/api/v1/reader/users/me`,
    GET_ALL_USERS: `${Config.getReaderMsUrl()}/api/v1/reader/users/admin/all?limit={{LIMIT}}&sort=surname.raw:asc&offset={{OFFSET}}`,
    CREATE_USER_URL: `${Config.getUsersMsUrl()}/api/v1/users`,
    UPDATE_USER_URL: `${Config.getUsersMsUrl()}/api/v1/users/{{UUID}}`,
    GET_USER_BY_UUID: `${Config.getReaderMsUrl()}/api/v1/reader/users/admin/{{UUID}}`,
    TOGGLE_USER_URL: `${Config.getUsersMsUrl()}/api/v1/users/{{UUID}}/status/toggle`,

    // User preferences
    GET_ORGANISATION_USERS_URL: `${Config.getReaderMsUrl()}/api/v1/reader/users?limit=10000&sort=surname.raw:asc&offset=0`,
    UPDATE_USER_PREFERENCES_URL: `${Config.getUsersMsUrl()}/api/v1/preferences/displaycolumns`,
    SET_HIDE_GENERAL_TELEDERMATOLOGY_EXCEPTIONS_URL: `${Config.getUsersMsUrl()}/api/v1/preferences/hidegte`,
    SET_HIDE_ORGANISATION_SWITCH_REMINDER_URL: `${Config.getUsersMsUrl()}/api/v1/preferences/hideorganisationswitch`,
    HIDE_MODAL_URL: `${Config.getUsersMsUrl()}/api/v1/preferences/modal/{{UUID}}/hide`,
};

URLs = Object.entries(URLs).reduce((acc, [key, value]) => {
    if (typeof value === "object" && value !== null && "original_url" in value) {
        if (process.env.REACT_APP_ENVIRONMENT !== "local" && process.env.REACT_APP_ENVIRONMENT !== "test") {
            acc[key] = value.proxy_url;
        } else {
            acc[key] = value.original_url;
        }
    } else {
        acc[key] = value;
    }
    return acc;
}, {});

export default URLs;
