import { FC, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Checkbox, Message, Dimmer, Loader } from "semantic-ui-react";
import parse from "html-react-parser";

import CustomHeader from "components/LandingPage/components/CustomHeader";
import CopyBlock from "components/LandingPage/components/CopyBlock";
import CustomButton from "components/templates/Button";
import ConfirmationModal from "components/RemoteModel/modals/ConfirmationModal";
import QuestionsModal, { IExceptionsModalErrors, IAnswers } from "components/RemoteModel/modals/QuestionsModal";
import ModalErrors from "components/RemoteModel/modals/ModalErrors";
import SuccessModal from "components/RemoteModel/modals/SuccessModal";

import { RegistrationQuestionType, IRegistrationTexts } from "model/registration";
import HttpStatus from "model/httpStatus";

import { history } from "App";
import { LOGIN, VALID_APPLICATION_ROUTES } from "navigation/routes";
import { REMOTE_MODEL_START, REMOTE_MODEL_CONSENT } from "navigation/remoteModelRoutes";

import * as actions from "redux/actions";
import getValidateError from "redux/selectors/validateError";
import { getRegistration } from "redux/selectors/registration";
import { getModalConfirmationVisible } from "redux/selectors/registrationUI";

import registrationService from "services/registrationService";
import organizationService from "services/organizationService";

import "scss/RemoteModel.scss";

interface ILandingPageParams {
    codeName: string;
}

interface IOrganisationRegistrationData {
    isLoading: boolean;
    logoUrl: string;
    registrationTexts: IRegistrationTexts;
}

const INITIAL_REGISTRATION_TEXTS: IRegistrationTexts = {
    whatHappens: "",
    consent: "",
    payment: "",
    deliveryKit: "",
};

const INITIAL_REGISTRATION_DATA: IOrganisationRegistrationData = {
    isLoading: false,
    logoUrl: "",
    registrationTexts: INITIAL_REGISTRATION_TEXTS,
};

const ExceptionsModalErrors: FC<IExceptionsModalErrors> = ({ onCancel, onConfirm, errors }) => (
    <ModalErrors
        title="It seems that this might not be the right service for you because we won’t be able to assess lesions that are:"
        onCancel={onCancel}
        onConfirm={onConfirm}
        reasons={errors}
    />
);

const RemoteQuestionsModalErrors: FC<IExceptionsModalErrors> = ({ onCancel, onConfirm, errors }) => (
    <ModalErrors title={errors[0]} onCancel={onCancel} onConfirm={onConfirm} />
);

const RemoteModelLandingPage: FC = () => {
    const { codeName } = useParams<ILandingPageParams>();

    const dispatch = useDispatch();
    const formErrors = useSelector(getValidateError);
    const registration = useSelector(getRegistration);
    const visibleConfirmationModal = useSelector(getModalConfirmationVisible);

    const [checked, setChecked] = useState(false);
    const [paymentConsentChecked, setPaymentConsentChecked] = useState(false);
    const [visibleExceptionsModal, setVisibleExceptionsModal] = useState(false);
    const [visibleRemoteQuestionsModal, setVisibleRemoteQuestionsModal] = useState(false);
    const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
    const [exceptionsAnswers, setExceptionsAnswers] = useState({});
    const [remoteQuestionsAnswers, setRemoteQuestionsAnswers] = useState({});
    const [organisationData, setOrganisationData] = useState<IOrganisationRegistrationData>(INITIAL_REGISTRATION_DATA);

    const { organisationUuid } = registration;
    const { isLoading, logoUrl, registrationTexts } = organisationData;
    const {
        whatHappens: whatHappensHtml,
        deliveryKit: deliveryKitHtml,
        payment: paymentHtml,
        consent: consentText,
    } = registrationTexts;
    const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;

    const whatHappensText = parse(whatHappensHtml || "");
    const deliveryKitText = parse(deliveryKitHtml || "");
    const paymentText = parse(paymentHtml || "");

    const fetchLogoAndTexts = async (newOrgUuid: string) => {
        setOrganisationData({ ...organisationData, isLoading: true });

        const presignedLogoUrl = await organizationService.getOrganisationLogoByUuidPublic(newOrgUuid);
        const texts = await organizationService.getOrganisationRegistrationTextsPublic(newOrgUuid);

        let registrationTextsData: IRegistrationTexts = INITIAL_REGISTRATION_TEXTS;

        if (texts.data) {
            const { whatHappens, consent, deliveryKit, payment } = texts.data;
            registrationTextsData = {
                whatHappens,
                consent,
                deliveryKit,
                payment,
            };
        }

        setOrganisationData({
            ...organisationData,
            registrationTexts: registrationTextsData,
            logoUrl: presignedLogoUrl,
            isLoading: false,
        });
    };

    const setOrgFlowType = async (orgUuid: string) => {
        const flowType = await organizationService.getOrganisationFlowType(orgUuid);
        dispatch(actions.registrationActions.setOrgFlowType(flowType));
        return flowType;
    };

    const fetchOrganisationData = async () => {
        try {
            setOrganisationData({ ...organisationData, isLoading: true });

            const result = await organizationService.getOrganisationUuidByCodenamePublic(codeName);
            const newOrgUuid = result.data.uuid;

            dispatch(actions.registrationActions.setOrgUuid(newOrgUuid));
            fetchLogoAndTexts(newOrgUuid);
            const flowType = await setOrgFlowType(newOrgUuid);

            if (flowType?.allowHomeInitiatedCases) {
                organizationService.getOrganisation(dispatch, newOrgUuid);
            } else {
                dispatch(actions.dataActions.clearData());
            }
        } catch (err: any) {
            const { status } = err.response || {};
            if (status === HttpStatus.NOT_FOUND) {
                history.push(LOGIN);
            }
        }
    };

    useEffect(() => {
        const isExistingRoute = VALID_APPLICATION_ROUTES.includes(`/${codeName}`);
        if (isExistingRoute) {
            history.push(LOGIN);
        } else {
            fetchOrganisationData();
        }
    }, [codeName]);

    const onClickAgree = () => {
        if (isAtHomeFlow) {
            history.push(REMOTE_MODEL_CONSENT);
        } else if (checked && codeName) {
            dispatch(registrationService.createUser(checked, codeName));
        }
    };

    const closeConfirmationModal = () => {
        dispatch(actions.registrationUIActions.setIsModalConfirmationVisible(false));
        setVisibleExceptionsModal(true);
    };

    const onSubmitExceptions = (answers: IAnswers) => {
        setVisibleExceptionsModal(false);
        setVisibleRemoteQuestionsModal(true);
        setExceptionsAnswers(answers);
    };

    const backToExceptionsModal = () => {
        setVisibleRemoteQuestionsModal(false);
        setTimeout(() => setVisibleExceptionsModal(true), 10);
    };

    const backToConfirmationModal = () => {
        setVisibleExceptionsModal(false);
        setTimeout(() => dispatch(actions.registrationUIActions.setIsModalConfirmationVisible(true)), 10);
    };

    const onResetFlow = () => {
        setChecked(false);
        dispatch(actions.registrationUIActions.setIsModalConfirmationVisible(false));
        setVisibleExceptionsModal(false);
        setVisibleRemoteQuestionsModal(false);
        setRemoteQuestionsAnswers({});
        setExceptionsAnswers({});
    };

    const onSubmitRemoteQuestions = (answers: IAnswers) => {
        setVisibleSuccessModal(true);
        setRemoteQuestionsAnswers(answers);
    };

    const onСonfirmRules = () => setChecked((prevstate) => !prevstate);

    const onCheckPaymentConsent = () => setPaymentConsentChecked((prevstate) => !prevstate);

    const onStartRegistration = () => {
        history.push(REMOTE_MODEL_START);
    };

    return (
        <>
            {!organisationUuid || isLoading || registration.isLoading ? (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            ) : (
                <>
                    <CustomHeader logoUrl={logoUrl} isAtHomeFlow={isAtHomeFlow} />
                    <Container>
                        {!isAtHomeFlow && (
                            <div className="remote-model__text remote-model__wrapper-transparent">
                                <p>
                                    Get a dermatologist assessment of up to three suspicious skin lesions within three
                                    working days. We send you an imaging kit and you take photos for assessment. You
                                    choose where and when - no need to travel to a face-to-face appointment for your
                                    assessment.
                                </p>
                                <p>
                                    <b>
                                        Please take a minute to review the information below, then we’ll ask for some
                                        details so we can send a kit to your preferred address.
                                    </b>
                                </p>
                                <p>
                                    If you&apos;ve recently started a skin check and would like to continue it, please{" "}
                                    <Link to={LOGIN}>click here</Link> to log in.
                                </p>
                            </div>
                        )}

                        {whatHappensHtml && (
                            <CopyBlock title="What happens next" icon="icon-arrow-in-circle">
                                <p>{whatHappensText}</p>
                            </CopyBlock>
                        )}

                        {deliveryKitHtml && (
                            <CopyBlock title="Kit Delivery" icon="icon-kit">
                                <p>{deliveryKitText}</p>
                            </CopyBlock>
                        )}

                        <CopyBlock title="Protecting your data" icon="icon-shield">
                            <p>The security of your data and personal information is really important to us.</p>
                            <p>
                                We store all your data - including contact details, medical history and photos -
                                securely in line with our&nbsp;
                                <a href="https://skin-analytics.com/Privacy/" target="_blank" rel="noreferrer">
                                    Privacy Policy
                                </a>
                                .
                            </p>
                            {!isAtHomeFlow && (
                                <p>
                                    We provide information back to your insurance provider to enable them to understand
                                    who has used our service and the outcome of the assessment, to support you with an
                                    onward referral if needed.
                                </p>
                            )}
                        </CopyBlock>

                        {paymentHtml && (
                            <CopyBlock title="Payment" icon="icon-card">
                                <p>{paymentText}</p>
                                {!isAtHomeFlow ? (
                                    <div>
                                        <p>
                                            Failure to return the kit as instructed after receiving your assessment
                                            results may result in a charge of £300.
                                        </p>
                                        <div className="remote-model__wrapper">
                                            <div className="custom-checkbox">
                                                <strong>
                                                    <Checkbox
                                                        onChange={onCheckPaymentConsent}
                                                        id="paymentAgreed"
                                                        checked={paymentConsentChecked}
                                                        label={{
                                                            children: (
                                                                <p>
                                                                    I acknowledge and accept the information provided
                                                                    above, including the&nbsp;
                                                                    <a
                                                                        target="_blank"
                                                                        href="https://skin-analytics.com/terms-and-conditions/"
                                                                        rel="noreferrer"
                                                                    >
                                                                        terms and conditions
                                                                    </a>
                                                                    . I understand that a charge of £300 may be applied
                                                                    if I fail to return the kit as instructed.
                                                                </p>
                                                            ),
                                                        }}
                                                    />
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </CopyBlock>
                        )}

                        {!isAtHomeFlow && consentText && (
                            <CopyBlock>
                                <div className="remote-model__wrapper">
                                    <div className="custom-checkbox">
                                        <strong>
                                            <Checkbox
                                                onChange={onСonfirmRules}
                                                id="agreed"
                                                checked={checked}
                                                label={consentText}
                                            />
                                        </strong>
                                    </div>
                                </div>
                            </CopyBlock>
                        )}

                        {formErrors.load && (
                            <Message negative>
                                <Message.Header>Ooops... {formErrors.load.message}</Message.Header>
                            </Message>
                        )}

                        <div className="buttons-wrapper">
                            <CustomButton
                                loading={registration.isLoading}
                                type="button"
                                disabled={
                                    (!isAtHomeFlow && (!checked || !paymentConsentChecked)) || registration.isLoading
                                }
                                variant="filled"
                                action={onClickAgree}
                                text={isAtHomeFlow ? "Continue" : "I agree"}
                            />
                        </div>
                    </Container>

                    {!isAtHomeFlow && (
                        <>
                            <ConfirmationModal
                                show={visibleConfirmationModal}
                                goAhead={closeConfirmationModal}
                                onClose={onResetFlow}
                                closeOnOverlay={false}
                            />

                            <QuestionsModal
                                answers={exceptionsAnswers}
                                className="modal-remote-model"
                                show={visibleExceptionsModal}
                                onSubmit={onSubmitExceptions}
                                onBack={backToConfirmationModal}
                                onResetFlow={onResetFlow}
                                closeOnOverlay={false}
                                modalTitle="Please confirm the following about the lesions you want assessed"
                                registrationQuestionType={RegistrationQuestionType.EXCLUSION}
                                errorComponent={ExceptionsModalErrors}
                            />

                            <QuestionsModal
                                answers={remoteQuestionsAnswers}
                                className="modal-remote-model"
                                show={visibleRemoteQuestionsModal}
                                onSubmit={onSubmitRemoteQuestions}
                                onResetFlow={onResetFlow}
                                closeOnOverlay={false}
                                modalTitle="Please answer the following questions"
                                registrationQuestionType={RegistrationQuestionType.REMOTE_OTHER}
                                errorComponent={RemoteQuestionsModalErrors}
                                onBack={backToExceptionsModal}
                            />

                            <SuccessModal
                                show={visibleSuccessModal}
                                onStart={onStartRegistration}
                                closeOnOverlay={false}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default RemoteModelLandingPage;
