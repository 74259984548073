import React, { useState } from "react";
import { Button, Divider, Icon, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { darken } from "@material-ui/core";

interface IButtonConfig {
    backgroundColor: string;
    fontColor: string;
    text: string;
    onClick?: () => void;
    border?: string;
    disabled?: boolean;
}

interface IConfirmDialogButton {
    title?: string | JSX.Element;
    titleIconColor?: SemanticCOLORS;
    titleIconName?: SemanticICONS;
    titleDividerLine?: boolean;
    boldTitle?: boolean;
    children: any;
    open: boolean;
    callback?: (open: boolean) => void;
    yesButton?: IButtonConfig;
    noButton?: IButtonConfig;
    centerActionButtons?: boolean;
    disableBackdropClick?: boolean;
    loading?: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

const AbstractConfirmDialog = (props: IConfirmDialogButton) => {
    const {
        title,
        titleIconColor,
        titleIconName,
        titleDividerLine,
        boldTitle,
        children,
        open,
        callback = () => undefined,
        noButton,
        yesButton,
        centerActionButtons,
        disableBackdropClick,
        loading,
        maxWidth,
    } = props;

    const yesStylingOriginal = {
        backgroundColor: `${yesButton?.backgroundColor}`,
        color: `${yesButton?.fontColor}`,
        ...(yesButton?.border ? { border: `1px solid ${yesButton?.border}` } : undefined),
    };

    const yesStylingFocused = {
        color: `${yesButton?.fontColor}`,
        ...(yesButton?.border ? { border: "1px solid #000000" } : undefined),
        ...(yesButton?.backgroundColor ? { backgroundColor: darken(`${yesButton?.backgroundColor}`, 0.3) } : undefined),
    };

    const noStylingOriginal = {
        backgroundColor: `${noButton?.backgroundColor}`,
        color: `${noButton?.fontColor}`,
        ...(noButton?.border ? { border: `1px solid ${noButton?.border}` } : undefined),
    };

    const noStylingFocused = {
        color: `${noButton?.fontColor}`,
        ...(noButton?.border ? { border: "1px solid #000000" } : undefined),
        ...(noButton?.backgroundColor ? { backgroundColor: darken(`${noButton?.backgroundColor}`, 0.3) } : undefined),
    };

    const [yesStyling, setYesStyling] = useState<Record<string, unknown>>(yesStylingOriginal);
    const [noStyling, setNoStyling] = useState<Record<string, unknown>>(noStylingOriginal);

    const onYesClick = () => {
        callback(false);
        if (yesButton?.onClick) {
            yesButton.onClick();
        }
    };

    const onNoClick = () => {
        callback(false);
        if (noButton?.onClick) {
            noButton.onClick();
        }
    };

    const onClose = (event: Event, reason: "backdropClick" | "escapeKeyDown") => {
        const isBackdropClick = reason === "backdropClick";
        if (!isBackdropClick || (isBackdropClick && !disableBackdropClick)) {
            callback(false);
        }
    };

    const titleWithIcon = (
        <div>
            {titleIconName && <Icon name={titleIconName} color={titleIconColor} />}
            <span>{title}</span>
        </div>
    );

    const handleYesFocus = () => {
        setYesStyling(yesStylingFocused);
    };

    const handleYesBlur = () => {
        setYesStyling(yesStylingOriginal);
    };

    const handleNoFocus = () => {
        setNoStyling(noStylingFocused);
    };

    const handleNoBlur = () => {
        setNoStyling(noStylingOriginal);
    };

    return (
        <Dialog
            maxWidth={maxWidth}
            open={Boolean(open)}
            onClose={onClose}
            aria-labelledby="confirm-dialog"
            role="dialog"
            tabIndex={-1}
        >
            <DialogTitle id="confirm-dialog">
                {boldTitle ? <b>{titleWithIcon}</b> : titleWithIcon}
                {titleDividerLine ? <Divider /> : <></>}
            </DialogTitle>
            <DialogContent>
                <div>{children}</div>
            </DialogContent>
            <DialogActions style={{ justifyContent: centerActionButtons ? "center" : "flex-end" }}>
                {noButton && (
                    <Button
                        tabIndex={0}
                        onClick={onNoClick}
                        disabled={noButton.disabled}
                        style={noStyling}
                        onFocus={handleNoFocus}
                        onBlur={handleNoBlur}
                    >
                        {noButton.text}
                    </Button>
                )}
                {yesButton && (
                    <Button
                        tabIndex={0}
                        loading={loading}
                        disabled={loading || yesButton.disabled}
                        onClick={onYesClick}
                        style={yesStyling}
                        onFocus={handleYesFocus}
                        onBlur={handleYesBlur}
                    >
                        {yesButton.text}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

AbstractConfirmDialog.defaultProps = {
    title: null,
    titleIconColor: "",
    titleIconName: "",
    titleDividerLine: false,
    boldTitle: false,
    callback: () => undefined,
    yesButton: null,
    noButton: null,
    centerActionButtons: false,
    disableBackdropClick: false,
    loading: false,
    maxWidth: undefined,
};

export default AbstractConfirmDialog;
