import React, { useState, FormEvent, useEffect } from "react";
import { Form, Grid, TextAreaProps } from "semantic-ui-react";
import Alert from "@mui/material/Alert";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import { getPatientData } from "helpers/patientUtils";
import { formatTime } from "helpers/datetime";
import { onPasteWhitespaceRemover } from "helpers/field";

import useAutoLogout from "hooks/useAutoLogout";

import { ICase } from "model/case";
import { DefinedPersonalDataType, IDefinedPersonalData, IOrganisation } from "model/organisation";
import PatientDetailsIntegration from "model/integrations";

import "scss/Dialog.scss";

interface IAddPatientNoteDialog {
    showDialog: boolean;
    callback: (arg: boolean) => void;
    caseObject: ICase;
    patientObject: any;
    organisation: IOrganisation;
    onSubmit: (patientNote: string, isPDSPatientFieldChanged: boolean) => void;
}

const AddPatientNoteDialog = (props: IAddPatientNoteDialog) => {
    useAutoLogout();
    const { showDialog, patientObject, caseObject, callback, onSubmit, organisation } = props;
    const [note, setNote] = useState<string>("");
    const [error, setError] = useState<string | undefined>();
    const [isPDSPatientFieldChanged, setIsPDSPatientFieldChanged] = useState<boolean>(false);

    const changedFields: string[] = Object.keys(patientObject);
    let isPatientModified = false;

    const isPDSCreatedPatient = caseObject?.patient?.integrationInfo?.integrationName === PatientDetailsIntegration.PDS;

    useEffect(() => {
        if (isPDSCreatedPatient) {
            const PDSPersonalDataProperties = organisation.definedPersonalData.filter(
                (field) =>
                    field?.integrationSourceSystem === PatientDetailsIntegration.PDS &&
                    field?.isRequestedAtManualCreation
            );

            const changedPersonalDataFields = [];
            changedFields.forEach((change: string) => {
                const previousPatientData = caseObject.patient.patientData.find((item) => item.name === change);
                const previousValue = getPatientData({
                    patientDataItem: previousPatientData,
                    dateFormatter: formatTime,
                });

                const fieldName = organisation.definedPersonalData.find(
                    (definedPersonalData) => definedPersonalData.propertyName === change
                )?.propertyName;

                const isData = previousPatientData?.type === DefinedPersonalDataType.DATE;
                const newValue = isData ? formatTime(patientObject[change]) : patientObject[change];

                if ((previousValue || newValue) && newValue !== previousValue) {
                    changedPersonalDataFields.push(fieldName);
                }
            });
            const hasPDSFieldChanged = !!PDSPersonalDataProperties.filter((personalData: IDefinedPersonalData) =>
                changedPersonalDataFields.includes(personalData.propertyName)
            ).length;

            if (hasPDSFieldChanged) {
                setIsPDSPatientFieldChanged(true);
            }
        }
    }, [patientObject]);

    const onReasonChange = (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        setNote(data.value as string);
    };

    const validateSubmit = () => {
        if (!note) {
            setError("Note is required");
            return false;
        }
        return true;
    };

    const onConfirm = () => {
        if (validateSubmit()) {
            onSubmit(note, isPDSPatientFieldChanged);
        }
    };

    const onCancel = () => {
        callback(false);
    };

    const getPDSChangeWarning = (): JSX.Element => {
        if (isPDSCreatedPatient) {
            return (
                <Grid.Row style={{ padding: "0 14px" }}>
                    <Alert className="dialog-alert pds" severity="warning">
                        <div>
                            {isPDSPatientFieldChanged ? (
                                <>
                                    <p>
                                        You are changing patient details that were gathered from the NHS Personal
                                        Demographics Service (PDS). Bear in mind:
                                    </p>
                                    <ul style={{ marginBottom: "0" }}>
                                        <li>We will not be able to verify the details you are editing</li>
                                        <li>
                                            Any changes you make will not be reflected outside of the Skin Analytics
                                            platform
                                        </li>
                                    </ul>
                                </>
                            ) : (
                                <>
                                    <p>
                                        Any changes you make will not be reflected outside of the Skin Analytics
                                        platform
                                    </p>
                                </>
                            )}
                        </div>{" "}
                    </Alert>
                </Grid.Row>
            );
        }
        return null;
    };

    const getChanges = () => {
        const changeGrid: any[] = [];
        changedFields.forEach((change) => {
            const previousPatientData = caseObject.patient.patientData.find((item) => item.name === change);
            const previousValue = getPatientData({ patientDataItem: previousPatientData, dateFormatter: formatTime });

            const fieldName = organisation.definedPersonalData.find(
                (definedPersonalData) => definedPersonalData.propertyName === change
            )?.displayName;

            const isData = previousPatientData?.type === DefinedPersonalDataType.DATE;
            const newValue = isData ? formatTime(patientObject[change]) : patientObject[change];

            if ((previousValue || newValue) && newValue !== previousValue) {
                isPatientModified = true;
                const fromToStatement = newValue ? ` From ${previousValue} to ${newValue}` : "-";
                changeGrid.push(
                    <Grid.Row key={change} style={{ padding: "2px 0" }}>
                        <Grid.Column width={16} style={{ paddingBottom: "10px" }}>
                            <b>{fieldName}:</b>
                            {fromToStatement}
                        </Grid.Column>
                    </Grid.Row>
                );
            }
        });

        return (
            <Grid columns={2} className="vertical-space">
                {changeGrid}
            </Grid>
        );
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            {getPDSChangeWarning()}
            <Grid.Row>
                <Grid.Column>
                    <Alert className="dialog-alert" severity="info">
                        {getChanges()}
                    </Alert>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Form>
                        <Form.TextArea
                            disabled={!isPatientModified}
                            rows={2}
                            error={error}
                            value={note}
                            required
                            label="Reason for edit"
                            onChange={onReasonChange}
                            onPaste={onPasteWhitespaceRemover(onReasonChange, true)}
                        />
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title="Confirm changes"
                open={showDialog}
                yesButton={{
                    backgroundColor: "#7CD8D1",
                    fontColor: "#000000",
                    onClick: onConfirm,
                    text: "Confirm",
                    disabled: !isPatientModified,
                }}
                noButton={{
                    backgroundColor: "#C2E7E3",
                    border: "#C2E7E3",
                    fontColor: "#000000",
                    onClick: onCancel,
                    text: "Cancel",
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default AddPatientNoteDialog;
