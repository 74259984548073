import { FC, useCallback, useContext } from "react";
import clsx from "clsx";
import { Table } from "semantic-ui-react";

import PatientSearchResultsTableRow from "components/PatientSearch/PatientSearchResultsTableRow";

import { PatientSearchContext } from "contextProviders/PatientSearchProvider";
import { PatientSearchActions } from "contextProviders/modules/patientSearch/actions";

import { IPatientSearchItem, RESULTS_LIMIT, SortableColumns } from "model/patientSearch";

interface IPatientSearchResultsTable {
    isTooManyResults: boolean;
    showMessage: boolean;
}

const PatientSearchResultsTable: FC<IPatientSearchResultsTable> = ({ isTooManyResults, showMessage }) => {
    const {
        dispatch,
        state: { patients, sortByColumn, direction, firstItemOnPageIndex, lastItemOnPageIndex },
    } = useContext(PatientSearchContext);

    const message = `${
        isTooManyResults ? `Your search returned more than ${RESULTS_LIMIT} results` : "No patients found"
    }, please refine your search criteria`;

    const handleViewPatientClicked = useCallback((patient: IPatientSearchItem) => {
        dispatch({ type: PatientSearchActions.SET_EXPANDED_VIEW_PATIENT, patient });
    }, []);

    const handleSortByColumnClicked = (e) => {
        const columnName = e.target.id;
        dispatch({ type: PatientSearchActions.SORT_RESULTS_BY_COLUMN, column: columnName });
    };

    const paginatedResults = patients.slice(firstItemOnPageIndex, lastItemOnPageIndex + 1);

    return (
        <Table celled sortable className={clsx("patient-search-results__table", showMessage && "message")}>
            <Table.Header>
                <Table.Row>
                    {showMessage ? (
                        <Table.HeaderCell />
                    ) : (
                        <>
                            <Table.HeaderCell
                                sorted={sortByColumn === SortableColumns.surname ? direction : null}
                                id={SortableColumns.surname}
                                onClick={handleSortByColumnClicked}
                            >
                                Surname
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortByColumn === SortableColumns.name ? direction : null}
                                id={SortableColumns.name}
                                onClick={handleSortByColumnClicked}
                            >
                                Given name (s)
                            </Table.HeaderCell>
                            <Table.HeaderCell>Hospital Number</Table.HeaderCell>
                            <Table.HeaderCell>NHS Number</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortByColumn === SortableColumns.dateOfBirth ? direction : null}
                                id={SortableColumns.dateOfBirth}
                                onClick={handleSortByColumnClicked}
                            >
                                DOB
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortByColumn === SortableColumns.gender ? direction : null}
                                id={SortableColumns.gender}
                                onClick={handleSortByColumnClicked}
                            >
                                Gender
                            </Table.HeaderCell>
                            <Table.HeaderCell>POST CODE</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </>
                    )}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {showMessage ? (
                    <Table.Row>
                        <Table.Cell>{message}</Table.Cell>
                    </Table.Row>
                ) : (
                    paginatedResults.map((patient) => (
                        <PatientSearchResultsTableRow
                            patient={patient}
                            key={patient.patientIdentifier}
                            onViewPatientClick={handleViewPatientClicked}
                        />
                    ))
                )}
            </Table.Body>
        </Table>
    );
};

export default PatientSearchResultsTable;
