import { FC } from "react";
import { Divider, Header } from "semantic-ui-react";

import ImmediateResultLesionAssessment from "components/ImmediateResult/Lesions/ImmediateResultLesionAssessment";

import { ICase } from "model/case";
import { GuidanceValue } from "model/organisation";

interface IImmediateResultLesionsAssessmentProps {
    caseObject: ICase;
    guidanceValue: GuidanceValue;
}

const ImmediateResultLesionsAssesment: FC<IImmediateResultLesionsAssessmentProps> = ({ caseObject, guidanceValue }) => {
    const { lesions } = caseObject;

    if (!lesions) {
        return null;
    }

    return (
        <>
            {lesions && (
                <>
                    <Header as="h3" className="without-bottom-margin">
                        Results of the AI assessment
                    </Header>
                    <Divider className="title-divider" />

                    {lesions.map((lesion, index) => (
                        <ImmediateResultLesionAssessment
                            key={lesion.uuid}
                            lesion={lesion}
                            lesionNumber={index + 1}
                            caseObject={caseObject}
                            guidanceValue={guidanceValue}
                        />
                    ))}
                </>
            )}
        </>
    );
};

export default ImmediateResultLesionsAssesment;
