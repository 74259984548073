export enum UserStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum UserStatusFilter {
    ALL = "ALL",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}
