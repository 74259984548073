export enum NoteType {
    CLINICAL = "CLINICAL",
    PATIENT = "PATIENT",
    ADMIN = "ADMIN",
}

export enum LesionReviewIds {
    DIAGNOSES = "selectedDiagnoses",
    MANAGEMENT = "selectedManagement",
    ESCALATION = "selectedEscalation",
    FREE_TEXT_SUFFIX = "FreeText",
}

export enum NoteTypeTitle {
    CLINICAL = "Notes for Clinical use",
    PATIENT = "Notes for the Patient",
    ADMIN = "Admin Notes",
    NEXT_STEPS = "Administrative next steps",
}

export interface ILesionItemReview {
    lesionUuid: string;
    selectedDiagnosesFreeText: string;
    selectedManagementFreeText: string;
    selectedDiagnoses: string;
    selectedManagement: string;
    selectedEscalation: string;
    selectedEscalationFreeText: string;
}

export interface ILesionReviews {
    [key: string]: ILesionItemReview;
}

export interface IReviewFormData {
    lesionReviews: ILesionReviews;
    caseManagementOutcome: string;
    caseManagementOutcomeFreeText: string;
    showCaseManagementOutcome: boolean;
    isValid: boolean;
}

interface ILesionReview {
    escalationPlanUuid: string;
    lesionUuid: string;
    reviewDiagnosisUuid: string;
    reviewManagementUuid: string;
}

export interface ICreateReviewResponse {
    caseUuid: string;
    patientUuid: string;
    reviewManagementUuid: string;
    uuid: string;
    reviewManagement: string;
    lesionReviews: ILesionReview[];
}
