import { FC, FormEvent, Fragment } from "react";
import { Grid, Image, Checkbox } from "semantic-ui-react";
import Button from "@material-ui/core/Button";

import ReasonsSelection from "components/CaseImgReassessment/ReasonsSelection";

import { sortByOrder } from "helpers/common";

import { CaptureImageTypeDictionary, CaptureImageType } from "model/captureImageType";
import { ISelectedImage, RetakeImagesError } from "model/case";
import { IImagePreview } from "model/imagePreview";

interface IProps {
    images: ISelectedImage[];
    dermImages: IImagePreview[];
    contextImages: IImagePreview[];
    error: RetakeImagesError | "";
    handleImageClick: (uuid: string) => void;
    handleImageSelection: (event: FormEvent<HTMLInputElement>) => void;
    handleReasonSelection: (event: FormEvent<HTMLInputElement>, imageUuid: string) => void;
}

const ImagesGrid: FC<IProps> = ({
    images,
    dermImages,
    contextImages,
    error,
    handleImageClick,
    handleImageSelection,
    handleReasonSelection,
}) => {
    const hasErrored = error === RetakeImagesError.MISSING_REASON;

    return (
        <Grid>
            {Object.keys(CaptureImageTypeDictionary)
                .sort()
                .map((imageType, index) => {
                    const imageTypeKey = `${imageType}-${index}`;
                    const isMacroImage = imageType === CaptureImageType.MACRO;
                    const currentImages: IImagePreview[] = isMacroImage ? contextImages : dermImages;
                    const imageTypeTitle = `${CaptureImageTypeDictionary[imageType]}${isMacroImage ? "s" : ""}`;

                    return (
                        <Fragment key={imageTypeKey}>
                            <Grid.Row className="no-bottom-padding">
                                <Grid.Column>
                                    <p className="image-type">{imageTypeTitle}</p>
                                </Grid.Column>
                            </Grid.Row>
                            {currentImages.sort(sortByOrder).map(({ src, uuid }, sortedImageIndex) => {
                                const currentImage: ISelectedImage = images.find(
                                    (img) => img.uuid === uuid
                                ) as ISelectedImage;
                                const { checked } = currentImage;

                                return (
                                    <Fragment key={uuid}>
                                        <Grid.Row>
                                            <Grid.Column width={3}>
                                                <Checkbox id={uuid} checked={checked} onChange={handleImageSelection} />
                                                <Button
                                                    onClick={() => handleImageClick(uuid)}
                                                    className="image-preview"
                                                >
                                                    <Image
                                                        alt={`Lesion image ${sortedImageIndex}`}
                                                        src={src}
                                                        size="medium"
                                                    />
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {checked && (
                                            <Grid.Row>
                                                <ReasonsSelection
                                                    currentImage={currentImage}
                                                    hasErrored={hasErrored}
                                                    handleReasonSelection={handleReasonSelection}
                                                    imageType={imageType}
                                                />
                                            </Grid.Row>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </Fragment>
                    );
                })}
        </Grid>
    );
};

export default ImagesGrid;
