import { createContext, Dispatch, FC, ReactNode, useCallback, useContext, useEffect, useReducer } from "react";

import PatientSearchExceptionModal from "components/PatientSearch/PatientSearchExceptionModal";

import { ModalContext } from "contextProviders/ModalProvider";
import { PatientSearchActions, TPatientSearchActions } from "contextProviders/modules/patientSearch/actions";
import reducer from "contextProviders/modules/patientSearch/reducer";

import {
    INITIAL_PATIENT_SEARCH_FORM,
    IPatientSearchForm,
    IPatientSearchItem,
    PatientSearchExceptionModalTypes,
    SortableColumns,
} from "model/patientSearch";
import { SortOrderUI } from "model/caseList";

export interface IState {
    patients: IPatientSearchItem[];
    sortByColumn: SortableColumns;
    direction: SortOrderUI;
    showResults: boolean;
    currentPage: number;
    totalPages: number;
    firstItemOnPageIndex: number;
    lastItemOnPageIndex: number;
    expandedViewPatient: IPatientSearchItem;
    searchFormData: IPatientSearchForm;
    showAdvancedSearch: boolean;
    showMinimumAgeExceptionModal: boolean;
    showDeceasedPatientModal: boolean;
    showNotEnoughDataModal: boolean;
}

const initialState: IState = {
    patients: [],
    sortByColumn: null,
    direction: null,
    showResults: false,
    currentPage: 0,
    totalPages: 0,
    firstItemOnPageIndex: 0,
    lastItemOnPageIndex: 0,
    expandedViewPatient: null,
    searchFormData: INITIAL_PATIENT_SEARCH_FORM,
    showAdvancedSearch: false,
    showMinimumAgeExceptionModal: false,
    showDeceasedPatientModal: false,
    showNotEnoughDataModal: false,
};

interface IPatientSearchProvider {
    children: ReactNode;
}

export const PatientSearchContext = createContext<{
    state: IState;
    dispatch: Dispatch<TPatientSearchActions>;
}>({
    state: initialState,
    dispatch: () => null,
});

const PatientSearchProvider: FC<IPatientSearchProvider> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { setModalParameters, closeModal } = useContext(ModalContext);
    const value = {
        state,
        dispatch,
    };

    const handleCloseModal = useCallback(() => {
        closeModal();
        dispatch({
            type: PatientSearchActions.SET_MODAL_VISIBILITY,
            show: false,
        });
    }, []);

    const {
        showDeceasedPatientModal,
        showMinimumAgeExceptionModal,
        showNotEnoughDataModal,
        expandedViewPatient,
        searchFormData,
    } = state;

    const { hospitalNumber: searchFormDataHospitalNumber } = searchFormData;
    const { patientIdentifier } = { ...expandedViewPatient };

    useEffect(() => {
        const isVisible = showDeceasedPatientModal || showMinimumAgeExceptionModal || showNotEnoughDataModal;
        if (isVisible) {
            let type: PatientSearchExceptionModalTypes;

            if (showDeceasedPatientModal) {
                type = PatientSearchExceptionModalTypes.DECEASED_PATIENT;
            }
            if (showMinimumAgeExceptionModal) {
                type = PatientSearchExceptionModalTypes.MINIMUM_AGE;
            }
            if (showNotEnoughDataModal) {
                type = PatientSearchExceptionModalTypes.NOT_ENOUGH_DATA;
            }

            setModalParameters({
                content: <PatientSearchExceptionModal onClose={handleCloseModal} type={type} />,
                isVisible,
                size: "large",
                closeCallback: handleCloseModal,
            });
        }
    }, [
        showDeceasedPatientModal,
        showMinimumAgeExceptionModal,
        showNotEnoughDataModal,
        expandedViewPatient,
        searchFormDataHospitalNumber,
        patientIdentifier,
        handleCloseModal,
    ]);

    return <PatientSearchContext.Provider value={value}>{children}</PatientSearchContext.Provider>;
};

export default PatientSearchProvider;
