import React, { FC } from "react";
import { Table } from "semantic-ui-react";

import { formatTimeWithHours } from "helpers/datetime";

import { ICallbackOutcome } from "model/case";
import { CallbackOutcomesText } from "model/remoteModel";

interface ICaseCallbackHistoryTable {
    callbackOutcomes: ICallbackOutcome[];
}

const CaseCallbackHistoryTable: FC<ICaseCallbackHistoryTable> = ({ callbackOutcomes }) => (
    <Table celled className="assessment-summary-table">
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width={4}>NAME OF CALLBACK AGENT</Table.HeaderCell>
                <Table.HeaderCell width={4}>DATE</Table.HeaderCell>
                <Table.HeaderCell width={6}>СALLBACK OUTCOME</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {callbackOutcomes.map(({ uuid, lastModifiedByName, lastModificationDate, outcome }) => (
                <Table.Row key={uuid}>
                    <Table.Cell>
                        <p>{lastModifiedByName}</p>
                    </Table.Cell>
                    <Table.Cell>{formatTimeWithHours(lastModificationDate)}</Table.Cell>
                    <Table.Cell>{CallbackOutcomesText[outcome]}</Table.Cell>
                </Table.Row>
            ))}
        </Table.Body>
    </Table>
);

export default CaseCallbackHistoryTable;
