import React, { FC, FormEvent } from "react";
import { CheckboxProps, Form, Icon } from "semantic-ui-react";

import { AdministrationPages, UserManagementSubpages } from "model/administrationPages";

import { ADMINISTRATION } from "navigation/routes";

interface IDermatologistRow {
    id: string;
    name: string;
    value: boolean;
    onChangeDermatologist: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
    disabled: boolean;
    availability?: string | number;
}

const DermatologistRow: FC<IDermatologistRow> = ({
    id,
    name,
    value,
    onChangeDermatologist,
    disabled,
    availability,
}) => {
    const onIconClick = (event: MouseEvent) => {
        event.stopPropagation();
        const userUrl = `${ADMINISTRATION}/${AdministrationPages.USER_MANAGEMENT}/${UserManagementSubpages.USER_DETAILS}/${id}`;
        window.open(userUrl, "_blank");
    };

    return (
        <div className="dermatologists-table-row">
            <div className={`${disabled ? "is-disabled" : ""} dermatologist-name`}>
                <p>{name}</p>
            </div>
            <div className={`${disabled ? "is-disabled" : ""} dermatologist-availability`}>
                <p>{Number(availability) === 0 ? "Not available" : "Available"}</p>
            </div>
            <div className={`${disabled ? "is-disabled" : ""} dermatologist-checkbox`}>
                <Form.Checkbox name={name} id={id} checked={value} onChange={onChangeDermatologist} />
            </div>
            <div className="dermatologist-link">
                <Icon name="share square" onClick={onIconClick} />
            </div>
        </div>
    );
};

DermatologistRow.defaultProps = {
    availability: "",
};

export default DermatologistRow;
