import React from "react";
import { Grid, Message } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";
import OrganisationWorkingDays from "components/templates/dialog/OrganisationWorkingDays";

import { IFormError } from "model/formError";

import "scss/Dialog.scss";

interface IOrganisationWorkingDaysDialog {
    showDialog: boolean;
    callback?: (isOpenPopup: boolean) => void;
    onSave: () => void;
    onCancel: () => void;
    workingDays: string[];
    setWorkingDays: (workingDays: string[]) => void;
    loading?: boolean;
    errors?: IFormError[];
}

const OrganisationWorkingDaysDialog = (props: IOrganisationWorkingDaysDialog) => {
    const { showDialog, callback, onSave, onCancel, workingDays, setWorkingDays, loading, errors } = props;

    const onConfirm = () => {
        onSave();
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    <p>
                        Please tick the standard working days for this organisation. Unticked days will not count
                        towards the Overdue case calculation.
                    </p>
                    <OrganisationWorkingDays workingDays={workingDays} setWorkingDays={setWorkingDays} />

                    {errors &&
                        errors.map((error: IFormError) => (
                            <Message key={error.errorCode} color="red">
                                {error.message}
                            </Message>
                        ))}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title="Working days"
                boldTitle
                open={showDialog}
                callback={callback}
                loading={loading}
                noButton={{
                    border: "#2185D0",
                    backgroundColor: "#fff",
                    fontColor: "#2185D0",
                    onClick: onCancel,
                    text: "Cancel",
                }}
                yesButton={{
                    backgroundColor: "#2185D0",
                    fontColor: "#FFFFFF",
                    onClick: onConfirm,
                    text: "Save",
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

OrganisationWorkingDaysDialog.defaultProps = {
    callback: () => undefined,
    loading: false,
    errors: [],
};

export default OrganisationWorkingDaysDialog;
