import { FC, useState, SyntheticEvent, FormEvent, Fragment } from "react";
import { Radio, Checkbox, Form, InputOnChangeData, CheckboxProps, DropdownProps } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import FormElement from "components/templates/FormElement";
import HospitalNumberTip from "components/templates/HospitalNumberTip";
import NHSNumberTip from "components/templates/NHSNumberTip";

import { IDefinedPersonalData, IDefinedPropertyName, IDefinedValidationType } from "model/organisation";
import { IPersonalAddressOption } from "model/registration";
import { IValidationErrorsRemoteFlow } from "model/assessment";
import { ContactPartsOfDay } from "model/case";
import { IPatientEdit } from "model/patientEdit";

import { VERIFY_MOBILE_NUMBER_BUTTON_ID } from "helpers/automationTestHelper";
import {
    ADDRESS_INPUT_TEXT,
    CONTACT_ON_WEEKEND,
    CONTACT_TIME,
    PHONE_INPUT_TEXT,
    POSTCODE_INPUT_TEXT,
} from "helpers/definedPersonalData";
import { isUndefined } from "helpers/common";

const POSTCODE_LENGTH = 3;

interface IRegistrationForm {
    form: IPatientEdit;
    personalDataFields: IDefinedPersonalData[];
    onFindAddress: () => void;
    onVerifyMobileNumber: () => Promise<void> | undefined;
    addressListOptions?: IPersonalAddressOption[];
    addressListError?: string;
    verifyMobileNumberError?: string;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement> | SyntheticEvent<HTMLElement, Event>,
        data: InputOnChangeData | CheckboxProps | DropdownProps
    ) => void;
    onContactPreferencesChange: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
    onContinue: () => void;
    validationErrors: IValidationErrorsRemoteFlow;
    isAtHomeFlow: boolean;
    resetFieldValidationError: (fieldName: string) => void;
}

const RegistrationForm: FC<IRegistrationForm> = ({
    form,
    personalDataFields,
    onFindAddress,
    onVerifyMobileNumber,
    onChange,
    addressListOptions,
    addressListError,
    verifyMobileNumberError,
    onContactPreferencesChange,
    onContinue,
    validationErrors,
    isAtHomeFlow,
    resetFieldValidationError,
}) => {
    const [isVerificationCodeSending, setIsVerificationCodeSending] = useState<boolean>(false);

    const { postCode, mobileNumber, verifiedMobileNumber, addressInput, contactPreferences } = form;

    const handleVerifyMobileNumber = () => {
        const verifyMobileNumberResult = onVerifyMobileNumber();
        if (verifyMobileNumberResult) {
            setIsVerificationCodeSending(true);
            verifyMobileNumberResult?.then(() => {
                setIsVerificationCodeSending(false);
            });
        }
    };

    const renderAction = (property: string) => {
        if (property === PHONE_INPUT_TEXT) {
            const verifiedNumber = mobileNumber && mobileNumber === verifiedMobileNumber;
            const disabled = !mobileNumber || isVerificationCodeSending || verifiedNumber;

            return {
                onClick: handleVerifyMobileNumber,
                disabled,
                loading: isVerificationCodeSending,
                labelPosition: "right",
                content: verifiedNumber ? "Verified" : "Verify",
                icon: verifiedNumber ? "check circle outline" : null,
                id: VERIFY_MOBILE_NUMBER_BUTTON_ID,
            };
        }

        if (property === POSTCODE_INPUT_TEXT) {
            return {
                disabled: postCode.length <= POSTCODE_LENGTH,
                onClick: onFindAddress,
                labelPosition: "right",
                content: "Find Address",
            };
        }

        return null;
    };

    const { isVisible, contactPreferencesError } = validationErrors;

    const renderAdditionals = (property: string) => {
        const postCodeInput = property === POSTCODE_INPUT_TEXT;
        const mobileNumberInput = property === PHONE_INPUT_TEXT;

        if (postCodeInput && addressListError?.length) {
            return <p className="remote-model-flow-registarion-error">{addressListError}</p>;
        }

        if (mobileNumberInput && verifyMobileNumberError?.length) {
            return <p className="remote-model-flow-registarion-error">{verifyMobileNumberError}</p>;
        }

        if (postCodeInput && addressListOptions?.length) {
            return (
                <div className="custom-input">
                    <Form.Select
                        id={ADDRESS_INPUT_TEXT}
                        placeholder="Please select your address"
                        required
                        onChange={onChange}
                        options={addressListOptions}
                        value={addressInput}
                        selectOnBlur={false}
                    />
                </div>
            );
        }

        const { isValid } = validationErrors;
        if (!isValid && isVisible) {
            const currentPropertyError = validationErrors.errorsList.find((error) => error.propertyName === property);
            return (
                currentPropertyError && (
                    <p className="remote-model-flow-registarion-error">{currentPropertyError.text}</p>
                )
            );
        }

        return null;
    };

    const { partsOfDay, weekend: allowWeekend } = contactPreferences;
    const isMorningContactChecked = partsOfDay.includes(ContactPartsOfDay.MORNING);
    const isAfternoonContactChecked = partsOfDay.includes(ContactPartsOfDay.AFTERNOON);
    const isEveningContactChecked = partsOfDay.includes(ContactPartsOfDay.EVENING);
    const allowWeekendContact = isUndefined(allowWeekend) || Boolean(allowWeekend);

    return (
        <>
            <div className="remote-model__form">
                <Form>
                    {personalDataFields.map((field: IDefinedPersonalData) => {
                        const { uuid, propertyName, required, validationProperty } = field;
                        const placeholder = propertyName === PHONE_INPUT_TEXT ? "07512345678" : undefined;
                        const beforeText =
                            propertyName === POSTCODE_INPUT_TEXT
                                ? "This is the address we will be sending the kit to so make sure it is correct."
                                : "";
                        const isNhsNumberField = validationProperty === IDefinedValidationType.nhsNumber;
                        const isHospitalNumberField = propertyName === IDefinedPropertyName.hospitalNumber;

                        const handleResetFieldValidationError = () => {
                            resetFieldValidationError(propertyName);
                        };

                        return (
                            <Fragment key={uuid}>
                                <div className="custom-input" key={propertyName} id={`${propertyName}.row`}>
                                    {beforeText && <p>{beforeText}</p>}
                                    <FormElement
                                        required={required}
                                        data={field}
                                        onChange={onChange}
                                        value={form[propertyName]}
                                        action={renderAction(propertyName)}
                                        placeholder={placeholder}
                                        validationProperty={validationProperty}
                                        resetFieldValidationError={handleResetFieldValidationError}
                                    />
                                    {renderAdditionals(propertyName)}
                                </div>
                                {isAtHomeFlow && isNhsNumberField && <NHSNumberTip />}
                                {isAtHomeFlow && isHospitalNumberField && <HospitalNumberTip />}
                            </Fragment>
                        );
                    })}
                </Form>
            </div>

            {!isAtHomeFlow && (
                <div>
                    <div className="is-heading__small">Contact Preferences</div>
                    <div>
                        <p>
                            If we would need to call you, select your preferred time of day from the options below. We
                            will try to contact you during your preferred slot but this may not always be possible
                        </p>
                    </div>

                    <div className="remote-model__form">
                        <div className="custom-checkbox">
                            <Checkbox
                                onChange={onContactPreferencesChange}
                                id={ContactPartsOfDay.MORNING}
                                name={CONTACT_TIME}
                                checked={isMorningContactChecked}
                                label={{
                                    children: (
                                        <span>
                                            <b>Morning</b> (8:00 - 12:00)
                                        </span>
                                    ),
                                }}
                            />
                        </div>
                        <div className="custom-checkbox">
                            <Checkbox
                                onChange={onContactPreferencesChange}
                                id={ContactPartsOfDay.AFTERNOON}
                                name={CONTACT_TIME}
                                checked={isAfternoonContactChecked}
                                label={{
                                    children: (
                                        <span>
                                            <b>Afternoon</b> (12:00 - 17:00)
                                        </span>
                                    ),
                                }}
                            />
                        </div>
                        <div className="custom-checkbox">
                            <Checkbox
                                onChange={onContactPreferencesChange}
                                id={ContactPartsOfDay.EVENING}
                                name={CONTACT_TIME}
                                checked={isEveningContactChecked}
                                label={{
                                    children: (
                                        <span>
                                            <b>Evening</b> (17:00 - 21:00)
                                        </span>
                                    ),
                                }}
                            />
                        </div>
                    </div>

                    <div className="is-required">
                        <p>Would you be happy to be contacted over a weekend?</p>
                        <div className="remote-model__form">
                            <div className="custom-radio">
                                <div className="options">
                                    <Radio
                                        name={CONTACT_ON_WEEKEND}
                                        label="Yes"
                                        onChange={onContactPreferencesChange}
                                        checked={allowWeekend}
                                        value={1}
                                    />
                                    <Radio
                                        name={CONTACT_ON_WEEKEND}
                                        label="No"
                                        onChange={onContactPreferencesChange}
                                        checked={!allowWeekendContact}
                                        value={0}
                                    />
                                </div>
                            </div>
                            {isVisible && contactPreferencesError && (
                                <span className="remote-model-flow-registarion-error">This field is required</span>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="buttons-wrapper">
                <CustomButton type="button" variant="filled" action={onContinue} text="Continue" />
            </div>
        </>
    );
};

RegistrationForm.defaultProps = {
    addressListOptions: [],
    addressListError: "",
    verifyMobileNumberError: "",
};

export default RegistrationForm;
