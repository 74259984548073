import { FC, useEffect, useMemo, useState, useRef, useContext } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import MUIIconButton from "@material-ui/core/IconButton";
import { Theme, createStyles, makeStyles, styled } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Grid from "@material-ui/core/Grid";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { TransformComponent, useControls, useTransformContext } from "react-zoom-pan-pinch";

import { ImagePreviewContext } from "contextProviders/ImagePreviewProvider";

import { CaptureImageType } from "model/captureImageType";
import { IImagePreview } from "model/imagePreview";

const ROTATED_SCALE = 1.32; // 1.32 scale factor required to compensate for rotation of a portrait image

/*
 * Define styles for square IconButtons
 * The IconButton style is not set globally on the MuiTheme object because we currently use round IconButtons
 * on the CaseList page
 */
const IconButton = styled(MUIIconButton)(({ theme }) => ({
    "borderRadius": "5px",
    "backgroundColor": theme.palette.primary.main,
    "width": "50px",
    "height": "50px",
    "margin": "8px",
    "zIndex": 1,
    "&:hover, &:active, &:focus": {
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[4],
        outline: "none",
        border: "none",
    },
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeIcon: {
            position: "absolute",
            top: "2.5%",
            right: "2.5%",
            height: "50px",
            width: "50px",
        },
        dialogContent: {
            "padding": "0px",
            "& #main-img": {
                "maxHeight": "calc(100vh - 64px)",
                // required to avoid going over modal bounds when rendered normally and
                // avoids weird positioning when rotated
                "maxWidth": "calc(100vw - 64px)",
                "width": "auto",
                "margin": "0 auto",
                "objectFit": "contain",
                "objectPosition": "center",
                "cursor": "grab",
                "backfaceVisibility": "hidden", // prevent anti-aliasing

                [theme.breakpoints.down("sm")]: {
                    maxHeight: "100vh",
                },

                // Switch maxWidth to height when rotated
                "&.rotated": {
                    maxWidth: "calc(100vh - 64px)",
                },
            },
        },
        directionControlWrapper: {
            position: "absolute",
            bottom: "1%",
            left: "50%",
            transform: "translateX(-50%)",
        },
        imageControlWrapper: {
            "display": "flex",
            "flex-direction": "column",
            "position": "absolute",
            "bottom": "2.5%",
            "right": "2.5%",
        },
        thumbnail: {
            maxWidth: "80px",
            height: "auto",
            verticalAlign: "top",
        },
        thumbnailButton: {
            "border": "4px solid #FFF",
            "borderRadius": "2px",
            "margin": "4px",
            "padding": 0,

            "&:hover, &:active, &:focus": {
                boxShadow: theme.shadows[8],
                outline: "none",
            },
        },
        thumbnailRotated: {
            transform: "rotate(-90deg)",
        },
        thumbnailWrapper: {
            position: "absolute",
            bottom: "1%",
            left: "1%",
            maxWidth: "188px",
        },
        selected: {
            "border": `4px solid ${theme.palette.primary.main}`,
            "&:hover, &:active, &:focus": {
                boxShadow: `${theme.shadows[4]}`,
            },
        },
        spinner: {
            position: "absolute",
            bottom: "10%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
        },
        transformContentClass: {
            "cursor": "grab",
            "width": "auto",
            "height": "auto",
            "objectFit": "contain",
            "objectPosition": "center",

            "&.dragging": {
                cursor: "grabbing",
            },
        },
        transformWrapperClass: {
            display: "flex",
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)", // position the wrapper centrally
            overflow: "visible", // override the library's styling that hides the overflow when increasing height/width
        },
    })
);

interface IThumbnailProps {
    activeImageUuid: string;
    images: IImagePreview[];
    onClick: (uuid: string) => void;
}

const Thumbnails: FC<IThumbnailProps> = ({ activeImageUuid, images, onClick }) => {
    const { images: dermoImageRotationInformation } = useContext(ImagePreviewContext);

    const classes = useStyles();
    const buttonRef = useRef(null);

    // Set focus when modal opens
    useEffect(() => {
        if (buttonRef?.current) {
            buttonRef.current.focus();
        }
    }, []);

    return (
        <Grid container item xs={12} justifyContent="center" alignContent="space-between">
            {images.map((img, idx) => {
                const isActiveImage = activeImageUuid === img.uuid;
                const rotateDermoImage = dermoImageRotationInformation.find(
                    (image) => image.uuid === img.uuid
                )?.shouldRotate;

                return (
                    <Grid item key={img.uuid} xs={6}>
                        <Button
                            className={clsx(
                                classes.thumbnailButton,
                                rotateDermoImage && classes.thumbnailRotated,
                                isActiveImage && classes.selected
                            )}
                            onClick={() => onClick(img.uuid)}
                            ref={isActiveImage ? buttonRef : null}
                        >
                            <img src={img.src} alt={`Thumbnail ${idx + 1}`} className={classes.thumbnail} />
                        </Button>
                    </Grid>
                );
            })}
        </Grid>
    );
};

interface ILesionImagePanZoom {
    resizedImages: IImagePreview[];
    fullSizeImages: IImagePreview[];
    imageUuid: string;
    isDragging: boolean;
    onClose: () => void;
    setActiveImage: React.Dispatch<React.SetStateAction<string>>;
    handleImageOnLoad: (image: HTMLImageElement) => void;
    setContainer: React.Dispatch<React.SetStateAction<HTMLDivElement>>;
}

const LesionImagePanZoom: FC<ILesionImagePanZoom> = ({
    resizedImages,
    fullSizeImages,
    imageUuid,
    isDragging,
    onClose,
    setActiveImage,
    handleImageOnLoad,
    setContainer,
}) => {
    const { images: dermoImageRotationInformation } = useContext(ImagePreviewContext);

    const classes = useStyles();

    // react-zoom-pan-pinch hooks
    const context = useTransformContext();
    const { centerView, resetTransform, zoomIn, zoomOut } = useControls();

    // Element refs
    const mainImageRef = useRef(null);
    /**
     * Initialise the image source array with the resized images in case fullSizeImages aren't loaded
     * The imageSourceArray is updated in a useEffect when the fullSizeImages are loaded and the array length changes
     */
    const imageSourceArray = useRef(resizedImages);

    // Store zoom and rotate
    const [rotateFactor, setRotateFactor] = useState<number>(0);

    const resizedImageArrayMemoised = useMemo(() => {
        // Use resized images for the thumbnails
        const contextImages = resizedImages?.filter((image) => image.imageType === CaptureImageType.MACRO) || [];
        const dermoscopicImages =
            resizedImages?.filter((image) => image.imageType === CaptureImageType.DERMOSCOPIC) || [];
        return {
            contextImages,
            dermoscopicImages,
        };
    }, [resizedImages]);

    useEffect(() => {
        imageSourceArray.current = fullSizeImages.length > 0 ? fullSizeImages : resizedImages;
    }, [fullSizeImages.length]);

    // Handle positioning image on resize event
    const handleResize = (): void => {
        centerView();
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const resetTranslation = (): void => {
        setRotateFactor(0);
        mainImageRef.current.style.transform = `rotate(0deg)`;
    };

    // Handle rotation
    const handleRotate = (): void => {
        const newRotation: number = rotateFactor + 90 >= 360 ? 0 : rotateFactor + 90;
        setRotateFactor(newRotation);
        let transform = `rotate(${newRotation}deg)`;
        if (rotateFactor % 180 === 0) {
            const isPortrait = mainImageRef.current.naturalHeight / mainImageRef.current.naturalWidth > 1;
            // Scale up rotated images as the height/width is dependent on their naturalHeight/naturalWidth
            // But only for portrait cases
            const newScale = isPortrait ? ROTATED_SCALE : context.setup.minScale;
            transform += ` scale(${newScale})`;
            context.contentComponent.style.width = "auto";
            if (isPortrait) {
                const height = mainImageRef.current.offsetHeight * newScale;
                context.contentComponent.style.width = `${height}px`;
            }
            context.wrapperComponent.style.height = "100%"; // add wrapper height so user can pan whole image in landscape mode
            context.wrapperComponent.style.width = "auto";
        } else {
            // Set these to reset widths set when rotated
            context.wrapperComponent.style.width = "auto";
            context.contentComponent.style.width = "auto";
        }

        const {
            transformState: { positionX, positionY },
        } = context;
        // Prevent image leaving screen
        if (positionX < mainImageRef.current.offsetWidth * -1 || positionY < mainImageRef.current.offsetHeight * -1) {
            centerView();
        }
        mainImageRef.current.style.transform = transform;
    };

    const progressImage = (direction: number): void => {
        const currentIndex = resizedImages.findIndex((image) => image.uuid === imageUuid);
        let newIndex: number;
        if (direction === 0) {
            newIndex = currentIndex === 0 ? resizedImages.length - 1 : currentIndex - 1;
        } else {
            newIndex = currentIndex === resizedImages.length - 1 ? 0 : currentIndex + 1;
        }
        const newImageUuid = resizedImages[newIndex].uuid;

        setActiveImage(newImageUuid);
        resetTranslation();
    };

    /**
     * Handle keyboard events
     * Note the positioning of `preventDefault` - this is to allow triggering of other keyboard events (e.g. refresh)
     */
    useEffect(() => {
        const handleKeyboardNavigation = (event: any) => {
            if (event.key === "ArrowLeft") {
                event.preventDefault();
                progressImage(0);
            }
            if (event.key === "ArrowRight") {
                event.preventDefault();
                progressImage(1);
            }
        };

        window.addEventListener("keydown", handleKeyboardNavigation);
        return () => {
            window.removeEventListener("keydown", handleKeyboardNavigation);
        };
    }, [imageUuid]);

    const selectImage = (uuid: string): void => {
        if (imageUuid !== uuid) {
            setActiveImage(uuid);
            if (rotateFactor !== 0) {
                resetTranslation();
                setRotateFactor(0);
            }
        }
    };

    const handleClose = (): void => {
        resetTranslation();
        onClose();
    };

    const handleImageLoad = (event: React.SyntheticEvent, rotateDermoImage: boolean): void => {
        const imageEvent = event.target as HTMLImageElement;
        handleImageOnLoad(imageEvent);

        if (mainImageRef?.current && rotateDermoImage && imageEvent.naturalHeight > imageEvent.naturalWidth) {
            const newRotation = 270;
            setRotateFactor(newRotation);
            mainImageRef.current.style.transform = `rotate(${newRotation}deg) scale(${ROTATED_SCALE})`;
        }
        // Reset the width style in case it was set during rotation
        context.wrapperComponent.style.width = "auto";
        context.contentComponent.style.width = "auto";
        resetTransform();
    };

    const imageArray = [...imageSourceArray.current];
    const activeImageIndex: number = imageArray.findIndex((image) => image.uuid === imageUuid);

    if (!imageArray || imageArray.length === 0 || !imageArray[activeImageIndex]) {
        return null;
    }

    const { contextImages, dermoscopicImages } = resizedImageArrayMemoised;

    const rotateDermoImage =
        imageArray[activeImageIndex].imageType === CaptureImageType.DERMOSCOPIC
            ? dermoImageRotationInformation.find((image) => image.uuid === imageUuid)?.shouldRotate
            : null;

    return (
        <>
            <IconButton aria-label="Close image viewer" className={classes.closeIcon} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent className={classes.dialogContent} ref={(el: HTMLDivElement | null) => setContainer(el)}>
                {fullSizeImages.length === 0 ? (
                    <div className={classes.spinner}>
                        <CircularProgress />
                    </div>
                ) : null}
                <TransformComponent
                    contentClass={clsx(classes.transformContentClass, isDragging && "dragging")}
                    wrapperClass={classes.transformWrapperClass}
                >
                    <img
                        id="main-img"
                        src={imageArray[activeImageIndex].src}
                        alt={`${imageArray[activeImageIndex].imageType}`}
                        ref={mainImageRef}
                        onLoad={(e) => handleImageLoad(e, rotateDermoImage)}
                        className={clsx(rotateFactor % 180 === 90 && "rotated")}
                    />
                </TransformComponent>

                <div className={classes.thumbnailWrapper}>
                    <Thumbnails activeImageUuid={imageUuid} images={contextImages} onClick={selectImage} />
                    <Thumbnails activeImageUuid={imageUuid} images={dermoscopicImages} onClick={selectImage} />
                </div>
                <div className={classes.directionControlWrapper}>
                    <IconButton aria-label="Previous image" onClick={() => progressImage(0)}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton aria-label="Next image" onClick={() => progressImage(1)}>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <div className={classes.imageControlWrapper}>
                    <IconButton aria-label="Rotate image" onClick={handleRotate}>
                        <RotateRightIcon />
                    </IconButton>
                    <IconButton aria-label="Increase zoom level" onClick={() => zoomIn()}>
                        <AddIcon />
                    </IconButton>
                    <IconButton aria-label="Reduce zoom level" onClick={() => zoomOut()}>
                        <RemoveIcon />
                    </IconButton>
                </div>
            </DialogContent>
        </>
    );
};

export default LesionImagePanZoom;
