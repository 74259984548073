import React, { Component, SyntheticEvent } from "react";
import { DropdownItemProps, Grid, Icon, InputOnChangeData, Segment } from "semantic-ui-react";

interface ISelectableAnswer {
    selected: boolean;
    value: string;
    option?: DropdownItemProps;
    id?: string;
    additionalInformation?: string;
    onClick?: (event: SyntheticEvent<HTMLElement> | undefined, obj: InputOnChangeData) => void;
    isRemoteModelHistory?: boolean;
}

class QuestionAnswer extends Component<ISelectableAnswer> {
    private onQuestionClick = () => {
        const { onClick, id, option } = this.props;
        const { value } = { ...option };
        if (onClick && value) {
            onClick(undefined, { id, value: `${value}` });
        }
    };

    private onQuestionKeyPress = (e) => {
        if (e.key === " ") {
            this.onQuestionClick();
        }
    };

    public render() {
        const { selected, value, additionalInformation, onClick, isRemoteModelHistory } = this.props;
        const isAction = !!onClick;

        const selectedStyle = selected ? "selected-answer answer" : "not-selected-answer answer";
        const actionStyle = isAction ? "action" : "";
        const selectedIcon = isRemoteModelHistory ? "dot circle outline" : "circle";

        return (
            <Grid.Row>
                <Grid.Column>
                    <Segment
                        tabIndex={0}
                        className={`${selectedStyle} ${actionStyle}`}
                        onKeyDown={this.onQuestionKeyPress}
                        onClick={this.onQuestionClick}
                    >
                        <Icon name={selected ? selectedIcon : "circle outline"} />
                        {value}
                    </Segment>
                </Grid.Column>

                {selected && (
                    <Grid.Column>
                        {additionalInformation && (
                            <Segment className="additional-information">
                                <p>{additionalInformation}</p>
                            </Segment>
                        )}
                    </Grid.Column>
                )}
            </Grid.Row>
        );
    }
}

export default QuestionAnswer;
