import { FC } from "react";
import { useSelector } from "react-redux";

import ModalProvider from "contextProviders/ModalProvider";

import PrivateRoutes from "navigation/PrivateRoutes";
import PublicRoutes from "navigation/PublicRoutes";

import getAuth from "redux/selectors/auth";

import * as authorizationService from "services/authorizationService";

const Routes: FC = () => {
    const auth = useSelector(getAuth);

    const isSignedIn = authorizationService.isSignedIn(auth.signedIn);

    return <ModalProvider>{isSignedIn ? <PrivateRoutes /> : <PublicRoutes />}</ModalProvider>;
};

export default Routes;
