import React, { PureComponent } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

interface IProps {
    loadingText?: string;
}

export default class LoadingSpinner extends PureComponent<IProps> {
    public render() {
        const { loadingText } = this.props;

        return (
            <Dimmer active style={{ opacity: 0.3 }}>
                <Loader active inline="centered" size="large" content={loadingText || "Loading"} />
            </Dimmer>
        );
    }
}
