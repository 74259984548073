import React, { FC, useState, useReducer, useEffect, createContext, ReactNode } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import caseListReducer from "contextProviders/modules/caseList/reducer";
import { CaseListActions } from "contextProviders/modules/caseList/actions";

import {
    INITIAL_CASE_LISTS,
    INITIAL_FILTERS,
    areRemoteLesionsCompleted,
    getIsReferCaseCallbackRequired,
} from "helpers/caseList";

import useDebounce from "hooks/useDebounce";

import { IAssignment, ICase } from "model/case";
import {
    CaseListColumns,
    CaseListFilters,
    CaseListTabs,
    COLUMNS_SETTINGS,
    DEFAULT_COLUMNS,
    ICaseLists,
    ICaseListsState,
    SortOrder,
    IKitDeliveryStatusFilter,
    ICaseListSettingItem,
    IFilter,
    IFilterReq,
    ISearch,
    IFilterReqParams,
    IPaginatedResponse,
} from "model/caseList";
import { RemoteTimeline, IRemoteTimelineFilterReq } from "model/remoteCaseTimeline";
import { CaseStatus, CaseStatusDictionary, CaseStatusFaked } from "model/caseStatus";
import { UserRole } from "model/userRole";
import { UserStatus } from "model/userStatus";
import { PathwayValues } from "model/assessmentPathway";
import { FILTER_ALL } from "model/filters";
import { KitDeliveryStatuses } from "model/remoteModel";
import { ESEmpty } from "model/common";

import { getOrganisation, getUser } from "redux/selectors/data";

import * as caseService from "services/caseService";
import userService from "services/userService";
import reportsService from "services/reportsService";
import logoutTimeoutService from "services/logoutTimeoutService";

interface ICaseListProvider {
    children: ReactNode;
}

const getInitialSorting = (chronogical: string) => ({
    [CaseListColumns.DATE_CREATED]: chronogical,
});

const CASE_LIST_COLUMN_SORT = {
    [CaseListColumns.ID]: "caseId",
    [CaseListColumns.DATE_CREATED]: "creationDate",
    [CaseListColumns.LOCATION]: "locationName.raw",
    [CaseListColumns.ORGANISATION]: "organisationName.raw",
    [CaseListColumns.STATUS]: "caseStatus",
    [CaseListColumns.ACTION]: "caseStatus",
    [CaseListColumns.MANAGEMENT_OUTCOME]: "recommendation.raw",
    [CaseListColumns.OVERDUE_FOR]: "overdueCreationDate",
    [CaseListColumns.DATE_ENTERED_LIST]: "kitDeliveryStatusLastModificationDate",
};

const CASE_LIST_KIT_TAB_ONLY_COLUMNS = [CaseListColumns.DATE_ENTERED_LIST];

const LIMIT = 10;
const limitConst = `&limit=${LIMIT}`;

const TABS = {
    [UserRole.SUPERADMIN]: [
        CaseListTabs.OVERDUE,
        CaseListTabs.TO_ALLOCATE,
        CaseListTabs.WITH_SAFETY_NET,
        CaseListTabs.TO_DISPATCH,
        CaseListTabs.DISPATCHED,
        CaseListTabs.DELIVERED,
        CaseListTabs.CALL_BACK,
        CaseListTabs.ALL,
    ],
    [UserRole.SA_ADMIN]: [
        CaseListTabs.OVERDUE,
        CaseListTabs.TO_ALLOCATE,
        CaseListTabs.WITH_SAFETY_NET,
        CaseListTabs.TO_DISPATCH,
        CaseListTabs.DISPATCHED,
        CaseListTabs.DELIVERED,
        CaseListTabs.CALL_BACK,
        CaseListTabs.ALL,
    ],
    [UserRole.ADMIN]: [
        CaseListTabs.TO_CLOSE,
        CaseListTabs.TO_ALLOCATE,
        CaseListTabs.WITH_DERMATOLOGIST,
        CaseListTabs.WITH_SAFETY_NET,
        CaseListTabs.OVERDUE,
        CaseListTabs.ALL,
    ],
    [UserRole.DERMATOLOGIST]: [CaseListTabs.TO_REVIEW, CaseListTabs.REVIEWED, CaseListTabs.OVERDUE, CaseListTabs.ALL],
    [UserRole.CLINICIAN]: [CaseListTabs.IN_PROGRESS, CaseListTabs.TO_CLOSE, CaseListTabs.OVERDUE, CaseListTabs.ALL],
    [UserRole.PATIENT]: [CaseListTabs.ALL],
    [UserRole.CALLBACK_AGENT]: [CaseListTabs.CALL_BACK],
    [UserRole.CLINICAL_VIEWER]: [CaseListTabs.ALL],
};

const FILTERS = {
    [UserRole.SUPERADMIN]: [
        CaseListFilters.CASE_ID,
        CaseListFilters.DATES,
        CaseListFilters.HOSPITAL_ID,
        CaseListFilters.NHS,
        CaseListFilters.LOCATION,
        CaseListFilters.ORGANISATION,
        CaseListFilters.BIRTH,
        CaseListFilters.REMOTE_TIMELINE,
        CaseListFilters.CASE_STATUS,
        CaseListFilters.MANAGEMENT_OUTCOME,
        CaseListFilters.DERMATOLOGIST,
        CaseListFilters.PATHWAY,
    ],
    [UserRole.SA_ADMIN]: [
        CaseListFilters.CASE_ID,
        CaseListFilters.DATES,
        CaseListFilters.HOSPITAL_ID,
        CaseListFilters.NHS,
        CaseListFilters.LOCATION,
        CaseListFilters.ORGANISATION,
        CaseListFilters.BIRTH,
        CaseListFilters.REMOTE_TIMELINE,
        CaseListFilters.CASE_STATUS,
        CaseListFilters.MANAGEMENT_OUTCOME,
        CaseListFilters.DERMATOLOGIST,
        CaseListFilters.PATHWAY,
    ],
    [UserRole.ADMIN]: [
        CaseListFilters.CASE_ID,
        CaseListFilters.DATES,
        CaseListFilters.HOSPITAL_ID,
        CaseListFilters.NHS,
        CaseListFilters.LOCATION,
        CaseListFilters.BIRTH,
        CaseListFilters.REMOTE_TIMELINE,
        CaseListFilters.CASE_STATUS,
        CaseListFilters.MANAGEMENT_OUTCOME,
        CaseListFilters.DERMATOLOGIST,
        CaseListFilters.PATHWAY,
    ],
    [UserRole.DERMATOLOGIST]: [
        CaseListFilters.CASE_ID,
        CaseListFilters.DATES,
        CaseListFilters.HOSPITAL_ID,
        CaseListFilters.NHS,
        CaseListFilters.LOCATION,
        CaseListFilters.BIRTH,
        CaseListFilters.MANAGEMENT_OUTCOME,
        CaseListFilters.PATHWAY,
    ],
    [UserRole.CLINICIAN]: [
        CaseListFilters.CASE_ID,
        CaseListFilters.DATES,
        CaseListFilters.HOSPITAL_ID,
        CaseListFilters.NHS,
        CaseListFilters.LOCATION,
        CaseListFilters.BIRTH,
        CaseListFilters.CASE_STATUS,
        CaseListFilters.PATHWAY,
    ],
    [UserRole.PATIENT]: [],
    [UserRole.CALLBACK_AGENT]: [],
    [UserRole.CLINICAL_VIEWER]: [
        CaseListFilters.CASE_ID,
        CaseListFilters.DATES,
        CaseListFilters.HOSPITAL_ID,
        CaseListFilters.NHS,
        CaseListFilters.LOCATION,
        CaseListFilters.BIRTH,
        CaseListFilters.MANAGEMENT_OUTCOME,
        CaseListFilters.PATHWAY,
    ],
};

export interface IConfiguration {
    tabs: string[];
    filters: string[];
}

export interface ICaseListContext {
    activePage: number;
    caseLists: ICaseLists;
    config: IConfiguration;
    currentTabCases: ICaseListsState;
    activeTab: string;
    dermatologists: any[];
    setActiveTab: (value: string) => void;
    fetchMore: () => void;
    sortList: (column: string) => void;
    setFilter: (updatedValue: any) => void;
    resetFilters: () => void;
    updateCaseStatus: (caseUuid: string, newStatus: CaseStatus, newAssignee?: IAssignment) => void;
    updateReviewedCasesTab: (sortByCreationDate?: string) => void;
    downloadCsvReport: () => void;
    downloadKitCsvReport: () => Promise<void>;
    updateCaseSasLink: (sasLink: string, caseUuid: string) => void;
    updateKitDeliveryStatus: (
        caseUuid: string,
        newKitDeliverStatus: KitDeliveryStatuses,
        currentKitDeliveryStatus: KitDeliveryStatuses
    ) => void;
    removeCaseAfterCallback: (caseUuid: string) => void;
    isKitDeliveryStatusTab: () => boolean;
    getDefaultColumns: (userRole: UserRole) => ICaseListSettingItem[];
}

export const TAB_STATE = {
    [CaseListTabs.ALL]: "all",
    [CaseListTabs.REVIEWED]: "reviewed",
    [CaseListTabs.WITH_SAFETY_NET]: "inSaReview",
    [CaseListTabs.TO_ALLOCATE]: "forReview",
    [CaseListTabs.IN_PROGRESS]: "inProgress",
    [CaseListTabs.TO_CLOSE]: "toClose",
    [CaseListTabs.WITH_DERMATOLOGIST]: "inReview",
    [CaseListTabs.TO_REVIEW]: "inReview",
    [CaseListTabs.OVERDUE]: "overdue",
    [CaseListTabs.TO_DISPATCH]: "toDispatch",
    [CaseListTabs.DISPATCHED]: "dispatched",
    [CaseListTabs.DELIVERED]: "delivered",
    [CaseListTabs.CALL_BACK]: "callback",
};

const STATUS_STATE = {
    [CaseStatus.CASE_IN_SA_REVIEW]: TAB_STATE[CaseListTabs.WITH_SAFETY_NET],
    [CaseStatus.CASE_FOR_REVIEW]: TAB_STATE[CaseListTabs.TO_ALLOCATE],
    [CaseStatus.CASE_IN_REVIEW]: TAB_STATE[CaseListTabs.WITH_DERMATOLOGIST],
    [CaseStatus.CASE_FOR_SA_REVIEW]: TAB_STATE[CaseListTabs.TO_ALLOCATE],
    [CaseStatus.CASE_COMPLETED]: TAB_STATE[CaseListTabs.TO_CLOSE],
    [`${CaseStatus.CASE_CREATED},${CaseStatus.CASE_IN_PROGRESS}`]: TAB_STATE[CaseListTabs.IN_PROGRESS],
    [`${CaseStatus.CASE_IN_REVIEW},${CaseStatus.CASE_IN_SA_REVIEW}`]: TAB_STATE[CaseListTabs.WITH_DERMATOLOGIST],
    [CaseStatusFaked.CASE_OVERDUE]: TAB_STATE[CaseListTabs.OVERDUE],
    [KitDeliveryStatuses.TO_DISPATCH]: TAB_STATE[CaseListTabs.TO_DISPATCH],
    [KitDeliveryStatuses.DISPATCHED]: TAB_STATE[CaseListTabs.DISPATCHED],
    [KitDeliveryStatuses.DELIVERED]: TAB_STATE[CaseListTabs.DELIVERED],
    [CaseStatusFaked.CASE_CALLBACK_REQUESTED]: TAB_STATE[CaseListTabs.CALL_BACK],
};

export const TAB_STATUS = {
    [CaseListTabs.WITH_SAFETY_NET]: CaseStatus.CASE_IN_SA_REVIEW,
    [`${CaseListTabs.TO_ALLOCATE}_${UserRole.ADMIN}`]: CaseStatus.CASE_FOR_REVIEW,
    [`${CaseListTabs.TO_ALLOCATE}_${UserRole.SUPERADMIN}`]: CaseStatus.CASE_FOR_SA_REVIEW,
    [`${CaseListTabs.TO_ALLOCATE}_${UserRole.SA_ADMIN}`]: CaseStatus.CASE_FOR_SA_REVIEW,
    [CaseListTabs.IN_PROGRESS]: `${CaseStatus.CASE_IN_PROGRESS},${CaseStatus.CASE_CREATED}`,
    [CaseListTabs.TO_CLOSE]: CaseStatus.CASE_COMPLETED,
    [CaseListTabs.WITH_DERMATOLOGIST]: CaseStatus.CASE_IN_REVIEW,
    [CaseListTabs.TO_REVIEW]: `${CaseStatus.CASE_IN_REVIEW},${CaseStatus.CASE_IN_SA_REVIEW}`,
    [`${CaseListTabs.OVERDUE}_${UserRole.DERMATOLOGIST}`]: `${CaseStatus.CASE_IN_REVIEW},${CaseStatus.CASE_IN_SA_REVIEW}`,
    [CaseListTabs.TO_DISPATCH]: KitDeliveryStatuses.TO_DISPATCH,
    [CaseListTabs.DISPATCHED]: KitDeliveryStatuses.DISPATCHED,
    [CaseListTabs.DELIVERED]: KitDeliveryStatuses.DELIVERED,
};

const USER_ROLE_NEXT_TAB_CURRENT_TAB = {
    [`${UserRole.CLINICIAN}_${CaseStatus.CASE_ABANDONED}`]: TAB_STATE[CaseListTabs.IN_PROGRESS],
    [`${UserRole.CLINICIAN}_${CaseStatus.CASE_CLOSED}`]: TAB_STATE[CaseListTabs.TO_CLOSE],
    [`${UserRole.SA_ADMIN}_${CaseStatus.CASE_IN_SA_REVIEW}`]: TAB_STATE[CaseListTabs.TO_ALLOCATE],
    [`${UserRole.SA_ADMIN}_${CaseStatus.CASE_FOR_SA_REVIEW}`]: TAB_STATE[CaseListTabs.WITH_SAFETY_NET],
    [`${UserRole.SUPERADMIN}_${CaseStatus.CASE_FOR_SA_REVIEW}`]: TAB_STATE[CaseListTabs.WITH_SAFETY_NET],
    [`${UserRole.ADMIN}_${CaseStatus.CASE_CLOSED}`]: TAB_STATE[CaseListTabs.TO_CLOSE],
    [`${UserRole.ADMIN}_${CaseStatus.CASE_FOR_REVIEW}`]: TAB_STATE[CaseListTabs.WITH_DERMATOLOGIST],
    [`${UserRole.ADMIN}_${CaseStatus.CASE_IN_REVIEW}`]: TAB_STATE[CaseListTabs.TO_ALLOCATE],
};

const KIT_DELIVERY_TAB_FILTERS = {
    [KitDeliveryStatuses.TO_DISPATCH]: {
        kitDeliveryStatus: KitDeliveryStatuses.TO_DISPATCH,
        caseStatus: [CaseStatus.CASE_CREATED, CaseStatus.CASE_IN_PROGRESS],
        remote: true,
    },
    [KitDeliveryStatuses.DISPATCHED]: {
        kitDeliveryStatus: KitDeliveryStatuses.DISPATCHED,
        caseStatus: [CaseStatus.CASE_CREATED, CaseStatus.CASE_IN_PROGRESS],
        remote: true,
    },
    [KitDeliveryStatuses.DELIVERED]: {
        kitDeliveryStatus: KitDeliveryStatuses.DELIVERED,
        caseStatus: [CaseStatus.CASE_CREATED, CaseStatus.CASE_IN_PROGRESS],
        remote: true,
    },
};

export const CaseListContext = createContext<ICaseListContext>({} as ICaseListContext);

const DEBOUNCE_TIME = 500;

const CaseListProvider: FC<ICaseListProvider> = ({ children }) => {
    const user = useSelector(getUser);

    const [config, setConfig] = useState<IConfiguration>({ tabs: [], filters: [] });
    const [activeTab, setActiveTab] = useState<string>("");
    const [caseLists, dispatch] = useReducer(caseListReducer, INITIAL_CASE_LISTS);
    const [dermatologists, setDermatologists] = useState<any[]>([]);
    const userOrganisation = useSelector(getOrganisation);

    const currentTabCases = caseLists[TAB_STATE[activeTab]];
    const {
        filters: {
            activeSearchField,
            id,
            hospitalId,
            nhs,
            location,
            recommendation,
            lastName,
            dateFrom,
            dateTo,
            organisation,
            birth,
            remoteTimeline,
            status,
            locationUuid,
            managementOutcome,
            dermatologist,
            pathway,
            name,
            createdByEmail,
            preauthorisationNumber,
            memberNumber,
            postCode,
        } = INITIAL_FILTERS,
    } = { ...currentTabCases };
    const { role } = user;
    const currentState = TAB_STATE[activeTab];

    const debouncedCaseId = useDebounce(id, DEBOUNCE_TIME);
    const debouncedHospitalId = useDebounce(hospitalId, DEBOUNCE_TIME);
    const debouncedHhs = useDebounce(nhs, DEBOUNCE_TIME);
    const debouncedLocation = useDebounce(location, DEBOUNCE_TIME);
    const debouncedRecommendation = useDebounce(recommendation, DEBOUNCE_TIME);
    const debouncedLastName = useDebounce(lastName, DEBOUNCE_TIME);
    const debouncedFirstName = useDebounce(name, DEBOUNCE_TIME);
    const debouncedPreauthorisationNumber = useDebounce(preauthorisationNumber, DEBOUNCE_TIME);
    const debouncedCreatorEmailAddress = useDebounce(createdByEmail, DEBOUNCE_TIME);
    const debouncedMemberNumber = useDebounce(memberNumber, DEBOUNCE_TIME);
    const debouncedPostcode = useDebounce(postCode, DEBOUNCE_TIME);

    const generateSortValue = (sortState: any) => {
        const value = Object.keys(sortState).reduce(
            (acc, current, index) =>
                `${acc}${index === 0 ? "" : ","}${CASE_LIST_COLUMN_SORT[current]}:${sortState[current]}`,
            "sort="
        );
        return value;
    };

    const generateSort = (column?: string) => {
        const currentValue = caseLists[currentState];
        const isNotDefinedOrDesc = !currentValue.sort[column] || currentValue.sort[column] === "desc";
        const sortState = column
            ? { [column]: isNotDefinedOrDesc ? SortOrder.ASC : SortOrder.DESC }
            : currentValue.sort;
        const sortValue = generateSortValue(sortState);

        return { sortValue, sortState };
    };

    const getTimelineFilter = (timelineStep: RemoteTimeline): IRemoteTimelineFilterReq => {
        const remote = { remote: true };

        switch (timelineStep) {
            case RemoteTimeline.PERSONAL_DETAILS: {
                return { ...remote, patient: ESEmpty.IS_EMPTY };
            }
            case RemoteTimeline.MEDICAL_HISTORY: {
                return {
                    ...remote,
                    patient: ESEmpty.NOT_EMPTY,
                    medicalHistoryCreatedBy: ESEmpty.IS_EMPTY,
                };
            }
            case RemoteTimeline.KIT: {
                return {
                    ...remote,
                    medicalHistoryCreatedBy: ESEmpty.NOT_EMPTY,
                    lesions: ESEmpty.LIST_IS_EMPTY,
                };
            }
            case RemoteTimeline.LESION_IMAGES: {
                return {
                    ...remote,
                    lesions: ESEmpty.LIST_NOT_EMPTY,
                    stepFilter: RemoteTimeline.LESION_IMAGES,
                };
            }
            case RemoteTimeline.SUMMARY: {
                return {
                    ...remote,
                    lesions: ESEmpty.LIST_NOT_EMPTY,
                    stepFilter: RemoteTimeline.SUMMARY,
                };
            }
            case RemoteTimeline.REVIEW: {
                return {
                    ...remote,
                    caseStatus: `${CaseStatus.CASE_FOR_SA_REVIEW},${CaseStatus.CASE_IN_SA_REVIEW}`,
                    stepFilter: RemoteTimeline.REVIEW,
                };
            }
            case RemoteTimeline.REPORT_AVAILABLE: {
                return { ...remote, caseStatus: CaseStatus.CASE_COMPLETED };
            }
            case RemoteTimeline.CASE_CLOSED: {
                return { ...remote, caseStatus: CaseStatus.CASE_CLOSED };
            }
            default: {
                return {};
            }
        }
    };

    const getStatusFilter = (currentStatus?: string) => {
        const isTabWithStatus = activeTab === CaseListTabs.ALL || activeTab === CaseListTabs.OVERDUE;
        const isToAllocateTab = activeTab === CaseListTabs.TO_ALLOCATE;
        const isDermatologistOverdue = activeTab === CaseListTabs.OVERDUE && user.role === UserRole.DERMATOLOGIST;
        if (isTabWithStatus && currentStatus) {
            if (currentStatus === CaseStatusDictionary.ALL.toUpperCase()) {
                return "";
            }
            return currentStatus;
        }
        return TAB_STATUS[isToAllocateTab || isDermatologistOverdue ? `${activeTab}_${user.role}` : activeTab];
    };

    const isKitDeliveryStatusTab = () =>
        [CaseListTabs.TO_DISPATCH, CaseListTabs.DISPATCHED, CaseListTabs.DELIVERED].includes(activeTab as CaseListTabs);

    const getKitDeliveryStatusFilters = () => {
        const isKitStatusTab = isKitDeliveryStatusTab();
        return isKitStatusTab ? KIT_DELIVERY_TAB_FILTERS[TAB_STATUS[activeTab]] : {};
    };

    const generateFilters = () => {
        const remoteTimelineFilter = getTimelineFilter(remoteTimeline);

        const statusFilter = getStatusFilter(status);
        const kitStatusTabFilters = getKitDeliveryStatusFilters();

        const filterOrganisation = organisation === FILTER_ALL ? "" : organisation;
        const filterDermatologist = dermatologist === FILTER_ALL ? "" : dermatologist;
        const filterLocation = locationUuid === FILTER_ALL ? "" : locationUuid;
        const filterManagementOutcome = managementOutcome === FILTER_ALL ? "" : managementOutcome;
        const isOverdueTab = activeTab === CaseListTabs.OVERDUE;
        const isCaseCallbackTab = activeTab === CaseListTabs.CALL_BACK;

        let pathwayFilter;

        switch (pathway) {
            case PathwayValues.SKIN_CANCER_FLOW: {
                pathwayFilter = {
                    nonSkinCancer: "false",
                    remote: "false",
                };
                break;
            }
            case PathwayValues.REMOTE_MODEL: {
                pathwayFilter = {
                    remote: "true",
                };
                break;
            }
            default: {
                pathwayFilter = {};
            }
        }

        const manipulateDate = (date: string, isFrom?: boolean) => {
            const momentDate = moment(date);

            const momentManipulated = isFrom ? momentDate.subtract(1, "days") : momentDate.add(1, "days");
            return momentManipulated.format("YYYY-MM-DD");
        };

        const requestFilters: IFilterReq = {
            ...(statusFilter ? { caseStatus: statusFilter } : {}),
            ...(dateFrom
                ? {
                      dateFrom: manipulateDate(dateFrom, true),
                  }
                : {}),
            ...(dateTo
                ? {
                      dateTo: manipulateDate(dateTo),
                  }
                : {}),
            ...(id ? { id } : {}),
            ...(filterOrganisation ? { organisation: filterOrganisation } : {}),
            ...(filterLocation ? { locationUuid: filterLocation } : {}),
            ...(filterManagementOutcome ? { managementOutcome: filterManagementOutcome } : {}),
            ...(filterDermatologist ? { dermatologist: filterDermatologist } : {}),
            ...(isOverdueTab ? { overdue: true } : {}),
            ...(isCaseCallbackTab ? { isCallbackNeeded: true } : {}),
            ...pathwayFilter,
            ...kitStatusTabFilters,
            ...remoteTimelineFilter,
        };
        const patientFilters: IFilter = {
            ...(hospitalId ? { hospitalId } : {}),
            ...(lastName ? { lastName } : {}),
            ...(name ? { name } : {}),
            ...(preauthorisationNumber ? { preauthorisationNumber } : {}),
            ...(memberNumber ? { memberNumber } : {}),
            ...(postCode ? { postCode } : {}),
            ...(nhs ? { nhs } : {}),
            ...(birth ? { birth } : {}),
        };
        const searchFilters: ISearch = {
            ...(location ? { location } : {}),
            ...(recommendation ? { recommendation } : {}),
            ...(createdByEmail ? { createdByEmail } : {}),
        };

        return {
            requestFilters,
            patientFilters,
            searchFilters,
        };
    };

    const filterCasesTimelineAfterFetch = async (
        cases: ICase[],
        step: RemoteTimeline,
        requestParams?: IFilterReqParams
    ): Promise<ICase[]> => {
        switch (step) {
            case RemoteTimeline.LESION_IMAGES: {
                return cases.filter((currentCase) => !areRemoteLesionsCompleted(currentCase.lesions));
            }
            case RemoteTimeline.SUMMARY: {
                return cases.filter(
                    (currentCase) =>
                        areRemoteLesionsCompleted(currentCase.lesions) &&
                        currentCase.caseStatus === CaseStatus.CASE_IN_PROGRESS
                );
            }
            case RemoteTimeline.REVIEW: {
                const { caseFilters, patientFilters, sortValue, searchFilters } = {
                    ...requestParams,
                };

                const completedCases = await caseService
                    .getCasesAsync(
                        activeTab,
                        limitConst,
                        { ...caseFilters, caseStatus: CaseStatus.CASE_COMPLETED },
                        patientFilters,
                        sortValue,
                        searchFilters
                    )
                    .then((result: IPaginatedResponse) => result.data);

                const completedCasesWithCallback = completedCases.filter((currentCase) =>
                    getIsReferCaseCallbackRequired(currentCase)
                );

                return [...cases, ...completedCasesWithCallback];
            }
            case RemoteTimeline.REPORT_AVAILABLE:
            case RemoteTimeline.CASE_CLOSED: {
                return cases.filter((currentCase) => !getIsReferCaseCallbackRequired(currentCase));
            }
            default: {
                return cases;
            }
        }
    };

    const updateCaseList = (column?: string) => {
        logoutTimeoutService.startCount();
        const { sortValue, sortState } = generateSort(column);
        const { requestFilters, patientFilters, searchFilters } = generateFilters();
        const { stepFilter, ...caseFilters } = requestFilters;

        if (activeTab === CaseListTabs.OVERDUE && userService.checkUserHasRole([UserRole.ADMIN])) {
            caseFilters.remote = false;
        }

        if (userService.checkUserHasRole([UserRole.DERMATOLOGIST])) {
            if (activeTab === CaseListTabs.ALL || !userOrganisation.isSecondReadOrganisation) {
                caseFilters.organisation = userOrganisation.uuid;
            }

            if (activeTab === CaseListTabs.TO_REVIEW || activeTab === CaseListTabs.OVERDUE) {
                caseFilters.dermatologist = user.uuid;
            }
        }

        caseService
            .getCasesAsync(activeTab, limitConst, caseFilters, patientFilters, sortValue, searchFilters)
            .then(async (result: IPaginatedResponse) => {
                const { data, total } = result;

                const newCases: ICase[] | Promise<ICase[]> = stepFilter
                    ? await filterCasesTimelineAfterFetch(data, stepFilter, {
                          caseFilters,
                          patientFilters,
                          sortValue,
                          searchFilters,
                      })
                    : data;

                const nFilteredCases = data.length - newCases.length;

                dispatch({
                    data: {
                        ...result,
                        data: newCases,
                        total: total - nFilteredCases,
                        isPending: false,
                        sort: sortState,
                    },
                    tab: currentState,
                    type: CaseListActions.SAVE_CASE_LIST_TAB,
                });
            });
    };

    useEffect(() => {
        if (currentTabCases) {
            updateCaseList();
        }
    }, [
        activeSearchField,
        debouncedCaseId,
        debouncedHospitalId,
        debouncedHhs,
        debouncedLocation,
        debouncedRecommendation,
        debouncedLastName,
        dateFrom,
        dateTo,
        organisation,
        birth,
        remoteTimeline,
        status,
        locationUuid,
        managementOutcome,
        dermatologist,
        pathway,
        debouncedFirstName,
        debouncedPreauthorisationNumber,
        debouncedCreatorEmailAddress,
        debouncedMemberNumber,
        debouncedPostcode,
    ]);

    const fetchDermatologists = () => {
        const filters = [`role:${UserRole.DERMATOLOGIST};status:${UserStatus.ACTIVE}`];
        if (user.role === UserRole.ADMIN) {
            userService.getOrganisationUsers(filters).then(({ data }) => setDermatologists(data));
        } else {
            userService.getAllUsers({ offset: 0, filters, limit: 10000 }).then(({ data }) => setDermatologists(data));
        }
    };

    const getInitialFilters = (caseStatus: string): Partial<IFilter> => {
        const isCaseOverdue = caseStatus === CaseStatusFaked.CASE_OVERDUE;
        const isCaseCallbackRequested = caseStatus === CaseStatusFaked.CASE_CALLBACK_REQUESTED;
        const isDermatologist = role === UserRole.DERMATOLOGIST;
        const isCaseCompleted = caseStatus === CaseStatus.CASE_COMPLETED;
        const isAdmin = role === UserRole.ADMIN;

        if (isCaseOverdue && isDermatologist) {
            return {
                overdue: true,
                caseStatus: `${CaseStatus.CASE_IN_REVIEW},${CaseStatus.CASE_IN_SA_REVIEW}`,
            };
        }
        if (isCaseOverdue) {
            return {
                overdue: true,
            };
        }
        if (isCaseCallbackRequested) {
            return {
                isCallbackNeeded: true,
            };
        }
        if (isCaseCompleted && isAdmin) {
            return {
                caseStatus,
                remote: false,
            };
        }
        return {
            caseStatus,
        };
    };

    const getInitialSortValue = (index: number, caseStatus: string, sortByCreationDate?: string) => {
        const isCaseOverdue = caseStatus === CaseStatusFaked.CASE_OVERDUE;
        if (isCaseOverdue) {
            return {
                initSortValue: `sort=overdueCreationDate:${SortOrder.DESC}`,
                initialSort: { [CaseListColumns.OVERDUE_FOR]: SortOrder.DESC },
            };
        }
        const isFirst = index === 0;
        const isCaseInSaReview = caseStatus === CaseStatus.CASE_IN_SA_REVIEW;
        const creationDateSort =
            (isFirst || isCaseInSaReview) && sortByCreationDate ? sortByCreationDate : SortOrder.ASC;
        const initSortValue = `sort=creationDate:${creationDateSort}`;
        const initialSort = getInitialSorting(creationDateSort);
        return { initSortValue, initialSort };
    };

    const fetchInitial = (
        caseStatuses: string[],
        kitDeliveryStatusFilters: IKitDeliveryStatusFilter[] = [],
        sortByCreationDate?: string
    ): void => {
        caseStatuses.forEach((caseStatus, index) => {
            const tab = STATUS_STATE[caseStatus];
            const initialFilters = getInitialFilters(caseStatus);
            const { initSortValue, initialSort } = getInitialSortValue(index, caseStatus, sortByCreationDate);

            if (tab === TAB_STATE[CaseListTabs.OVERDUE] && userService.checkUserHasRole([UserRole.ADMIN])) {
                initialFilters.remote = false;
            }

            if (userService.checkUserHasRole([UserRole.DERMATOLOGIST])) {
                if (!userOrganisation.isSecondReadOrganisation) {
                    initialFilters.organisation = userOrganisation.uuid;
                }

                if (tab === TAB_STATE[CaseListTabs.TO_REVIEW] || tab === TAB_STATE[CaseListTabs.OVERDUE]) {
                    initialFilters.dermatologist = user.uuid;
                }
            }

            caseService.getCasesAsync(tab, limitConst, initialFilters, undefined, initSortValue).then((result: any) => {
                dispatch({
                    data: {
                        ...result,
                        isPending: false,
                        sort: initialSort,
                    },
                    tab,
                    type: CaseListActions.SAVE_CASE_LIST_TAB,
                });
            });
        });

        kitDeliveryStatusFilters.forEach((kitDeliveryStatusFilter) => {
            const { kitDeliveryStatus } = kitDeliveryStatusFilter;
            const tab = STATUS_STATE[kitDeliveryStatus];
            const kitDeliveryStatusTabSortOrder = SortOrder.ASC;
            const initSortValue = `sort=kitDeliveryStatusLastModificationDate:${kitDeliveryStatusTabSortOrder}`;
            const initialFilter = { ...kitDeliveryStatusFilter };

            caseService.getCasesAsync(tab, limitConst, initialFilter, undefined, initSortValue).then((result: any) => {
                dispatch({
                    data: {
                        ...result,
                        isPending: false,
                        sort: {
                            [CaseListColumns.DATE_ENTERED_LIST]: kitDeliveryStatusTabSortOrder,
                        },
                    },
                    tab,
                    type: CaseListActions.SAVE_CASE_LIST_TAB,
                });
            });
        });

        const creationDateSortAll = sortByCreationDate || SortOrder.ASC;
        const isDermatologist = role === UserRole.DERMATOLOGIST;
        const isCallbackAgent = role === UserRole.CALLBACK_AGENT;
        const isClinicalViewer = role === UserRole.CLINICAL_VIEWER;
        const sortValueAll = `sort=creationDate:${creationDateSortAll}`;
        const initialSortAll = { [CaseListColumns.DATE_CREATED]: creationDateSortAll };

        if (isClinicalViewer || isDermatologist) {
            const organisationFilter = !userOrganisation.isSecondReadOrganisation
                ? { organisation: userOrganisation.uuid }
                : undefined;

            caseService
                .getCasesAsync(CaseListTabs.ALL, limitConst, organisationFilter, undefined, sortValueAll)
                .then((result: any) => {
                    dispatch({
                        data: { ...result, isPending: false, sort: initialSortAll },
                        tab: TAB_STATE[CaseListTabs.ALL],
                        type: CaseListActions.SAVE_CASE_LIST_TAB,
                    });
                });
            if (TABS[role].includes(CaseListTabs.REVIEWED)) {
                caseService
                    .getCasesAsync(CaseListTabs.REVIEWED, limitConst, organisationFilter, undefined, sortValueAll)
                    .then((result: any) => {
                        dispatch({
                            data: { ...result, isPending: false, sort: initialSortAll },
                            tab: TAB_STATE[CaseListTabs.REVIEWED],
                            type: CaseListActions.SAVE_CASE_LIST_TAB,
                        });
                    });
            }
        } else if (!isCallbackAgent) {
            caseService
                .getCasesAsync(CaseListTabs.ALL, limitConst, undefined, undefined, sortValueAll)
                .then((result: any) => {
                    dispatch({
                        data: { ...result, isPending: false, sort: initialSortAll },
                        tab: TAB_STATE[CaseListTabs.ALL],
                        type: CaseListActions.SAVE_CASE_LIST_TAB,
                    });
                });
        }
    };

    const fetchCases = () => {
        if (role === UserRole.SUPERADMIN || role === UserRole.SA_ADMIN) {
            fetchDermatologists();
            fetchInitial(
                [
                    CaseStatusFaked.CASE_OVERDUE,
                    CaseStatus.CASE_FOR_SA_REVIEW,
                    CaseStatus.CASE_IN_SA_REVIEW,
                    CaseStatusFaked.CASE_CALLBACK_REQUESTED,
                ],
                [
                    KIT_DELIVERY_TAB_FILTERS[KitDeliveryStatuses.TO_DISPATCH],
                    KIT_DELIVERY_TAB_FILTERS[KitDeliveryStatuses.DISPATCHED],
                    KIT_DELIVERY_TAB_FILTERS[KitDeliveryStatuses.DELIVERED],
                ],
                SortOrder.DESC
            );
        }
        if (role === UserRole.ADMIN) {
            fetchDermatologists();
            fetchInitial([
                CaseStatusFaked.CASE_OVERDUE,
                CaseStatus.CASE_FOR_REVIEW,
                CaseStatus.CASE_IN_SA_REVIEW,
                CaseStatus.CASE_IN_REVIEW,
                CaseStatus.CASE_COMPLETED,
            ]);
        }
        if (role === UserRole.CLINICIAN) {
            fetchInitial(
                [
                    `${CaseStatus.CASE_CREATED},${CaseStatus.CASE_IN_PROGRESS}`,
                    CaseStatus.CASE_COMPLETED,
                    CaseStatusFaked.CASE_OVERDUE,
                ],
                [],
                SortOrder.DESC
            );
        }
        if (role === UserRole.DERMATOLOGIST) {
            fetchInitial([
                `${CaseStatus.CASE_IN_REVIEW},${CaseStatus.CASE_IN_SA_REVIEW}`,
                CaseStatusFaked.CASE_OVERDUE,
            ]);
        }
        if (role === UserRole.PATIENT) {
            fetchInitial([]);
        }
        if (role === UserRole.CALLBACK_AGENT) {
            fetchInitial([CaseStatusFaked.CASE_CALLBACK_REQUESTED]);
        }
        if (role === UserRole.CLINICAL_VIEWER) {
            fetchInitial([]);
        }
    };

    useEffect(() => {
        if (!userOrganisation) {
            return;
        }

        dispatch({ type: CaseListActions.REMOVE_ALL_CASES });

        fetchCases();

        const { filters, tabs } = config;

        if (!tabs.length && !filters.length) {
            const initialTabs = TABS[user.role];
            const initialFilters = FILTERS[user.role];
            const allTabInitial = userService.checkUserHasRole([UserRole.SUPERADMIN, UserRole.PATIENT]);

            setConfig({ tabs: initialTabs, filters: initialFilters });
            setActiveTab(allTabInitial ? CaseListTabs.ALL : initialTabs[0]);
        }
    }, [role, userOrganisation]);

    const fetchMore = () => {
        const { next: nextPageUrl } = caseLists[currentState]._links;
        dispatch({
            data: { isPending: true },
            tab: currentState,
            type: CaseListActions.SET_LOADING,
        });
        caseService.getCasesNextPage(nextPageUrl).then((result: any) => {
            dispatch({
                data: { ...result, isPending: false },
                tab: currentState,
                type: CaseListActions.FETCH_MORE,
            });
        });
    };

    const sortList = (column: string) => {
        updateCaseList(column);
    };

    const setFilter = (updatedValue: any) => {
        const currentValue = caseLists[currentState];
        const { filters } = currentValue;
        const updatedFilters = { ...filters, ...updatedValue };

        dispatch({
            data: { filters: updatedFilters },
            tab: currentState,
            type: CaseListActions.SET_FILTERS,
        });
    };

    const resetFilters = () => {
        dispatch({
            data: { filters: INITIAL_FILTERS },
            tab: currentState,
            type: CaseListActions.SET_FILTERS,
        });
    };

    const updateCaseStatus = (caseUuid: string, newStatus: CaseStatus, newAssignee?: IAssignment) => {
        const nextTab = STATUS_STATE[newStatus];
        const currentTab =
            currentState === TAB_STATE[CaseListTabs.ALL]
                ? USER_ROLE_NEXT_TAB_CURRENT_TAB[`${role}_${newStatus}`]
                : currentState;
        dispatch({
            caseUuid,
            currentTab,
            newAssignee,
            newStatus,
            nextTab,
            type: CaseListActions.UPDATE_CASE_STATUS,
        });
    };

    const updateReviewedCasesTab = (sortByCreationDate?: string): void => {
        if (role === UserRole.DERMATOLOGIST) {
            const creationDateSortAll = sortByCreationDate || SortOrder.ASC;
            const sortValueAll = `sort=creationDate:${creationDateSortAll}`;
            const initialSortAll = { [CaseListColumns.DATE_CREATED]: creationDateSortAll };
            const organisationFilter = !userOrganisation.isSecondReadOrganisation
                ? { organisation: userOrganisation.uuid }
                : undefined;

            caseService
                .getCasesAsync(CaseListTabs.REVIEWED, limitConst, organisationFilter, undefined, sortValueAll)
                .then((result: any) => {
                    dispatch({
                        data: { ...result, isPending: false, sort: initialSortAll },
                        tab: TAB_STATE[CaseListTabs.REVIEWED],
                        type: CaseListActions.SAVE_CASE_LIST_TAB,
                    });
                });
        }
    };

    const downloadCsvReport = () => {
        const { sortValue } = generateSort();
        const { requestFilters, patientFilters, searchFilters } = generateFilters();

        reportsService.downloadCasesListCsvReport(activeTab, requestFilters, patientFilters, sortValue, searchFilters);
    };

    const downloadKitCsvReport = async (): Promise<void> => {
        const { sortValue } = generateSort();
        const { requestFilters, patientFilters, searchFilters } = generateFilters();

        await reportsService.downloadKitCsvReport(requestFilters, patientFilters, sortValue, searchFilters);
    };

    const updateCaseSasLink = (sasLink: string, caseUuid: string) => {
        dispatch({
            data: { sasLink, caseUuid },
            tab: currentState,
            type: CaseListActions.SET_SAS_LINK,
        });
    };

    const updateKitDeliveryStatus = (
        caseUuid: string,
        newKitDeliveryStatus: KitDeliveryStatuses,
        currentKitDeliveryStatus: KitDeliveryStatuses
    ) => {
        const nextTab = STATUS_STATE[newKitDeliveryStatus];
        const currentTab = STATUS_STATE[currentKitDeliveryStatus];
        dispatch({
            caseUuid,
            currentTab,
            newKitDeliveryStatus,
            nextTab,
            type: CaseListActions.UPDATE_KIT_DELIVERY_STATUS,
        });
    };

    const removeCaseAfterCallback = (caseUuid: string): void => {
        dispatch({
            caseUuid,
            type: CaseListActions.REMOVE_CASE_AFTER_CALLBACK,
        });
    };

    const getDefaultColumns = (userRole: UserRole): ICaseListSettingItem[] => {
        const currentColumns = DEFAULT_COLUMNS[userRole];
        const currentColumnSettings = COLUMNS_SETTINGS.map((setting, index) => {
            const isKitDeliveryTabColumn = CASE_LIST_KIT_TAB_ONLY_COLUMNS.includes(setting);

            const display = !isKitDeliveryTabColumn
                ? currentColumns.includes(setting)
                : currentColumns.includes(setting) && isKitDeliveryStatusTab();

            return {
                name: setting,
                display,
                order: index,
            };
        });
        const sortedColumns = currentColumnSettings.sort(
            (obj1: ICaseListSettingItem, obj2: ICaseListSettingItem) => obj1.order - obj2.order
        );
        return sortedColumns;
    };

    const values = {
        activePage: 1,
        activeTab,
        caseLists,
        config,
        currentTabCases,
        dermatologists,
        fetchMore,
        resetFilters,
        setActiveTab,
        setFilter,
        sortList,
        updateCaseStatus,
        updateReviewedCasesTab,
        downloadCsvReport,
        downloadKitCsvReport,
        updateCaseSasLink,
        updateKitDeliveryStatus,
        removeCaseAfterCallback,
        getDefaultColumns,
        isKitDeliveryStatusTab,
    };

    return <CaseListContext.Provider value={values}>{children}</CaseListContext.Provider>;
};

export default CaseListProvider;
