import { FC } from "react";
import { Grid, Segment } from "semantic-ui-react";

import CoreFields from "components/Administration/OrganisationManagment/ViewOrganisation/PersonalData/CoreFields";

interface IPersonalData {
    organisationUuid: string;
    showEditButton: boolean;
    parentOrganisationUuid: string;
    updateInheritanceData: (property: string) => void;
}

const PersonalData: FC<IPersonalData> = ({
    organisationUuid,
    parentOrganisationUuid,
    showEditButton,
    updateInheritanceData,
}) => (
    <Segment>
        <Grid columns={1}>
            <Grid.Row>
                <Grid.Column>
                    <CoreFields
                        organisationUuid={organisationUuid}
                        parentOrganisationUuid={parentOrganisationUuid}
                        showEditButton={showEditButton}
                        updateInheritanceData={updateInheritanceData}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
);

export default PersonalData;
