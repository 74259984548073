import React, { FC, useState, useEffect, SyntheticEvent } from "react";
import { DropdownProps, Message, InputOnChangeData } from "semantic-ui-react";
import { useSelector } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";

import QuestionAnswer from "components/templates/QuestionAnswer";
import ModalDialog from "components/templates/dialog/ModalDialog";

import { getPatientData } from "helpers/caseList";
import { getOrganisation } from "redux/selectors/data";

import useAutoLogout from "hooks/useAutoLogout";

import { IAssignment, ICase } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { INITIAL_REQUEST_STATUS, IRequestStatus } from "model/common";
import { UserRole } from "model/userRole";
import { UserStatus } from "model/userStatus";

import reviewService from "services/reviewService";
import userService from "services/userService";

interface IAllocateCaseModal {
    isSuperAdmin: boolean;
    isUnallocation: boolean;
    updateCaseStatus: (uuid: string, status: CaseStatus, newAssignee?: IAssignment) => void;
    currentCase?: ICase;
    showDialog: boolean;
    callback: (isOpenPopup: boolean) => void;
}

interface IDermatologist {
    name: string;
    surname: string;
    uuid: string;
    availability: number;
}

interface IDermatologistsResult {
    data: IDermatologist[];
    correlationId: string;
}

interface IFilterOption {
    key: string;
    text: string;
    value: string;
}

const AllocateCaseModal: FC<IAllocateCaseModal> = ({
    isSuperAdmin,
    isUnallocation,
    updateCaseStatus,
    currentCase,
    showDialog,
    callback,
}) => {
    useAutoLogout();

    const [dermatologists, setDermatologists] = useState<IFilterOption[]>([]);
    const [selectedDermatologist, setSelectedDermatologist] = useState<IAssignment>({
        assigneeName: "",
        assigneeUuid: "",
    });
    const [allocationStatusAndResult, setAllocationStatusAndResult] = useState<{
        result: { data: { data: { uuid: string } } } | null;
        newStatus: CaseStatus | undefined;
    }>({ result: null, newStatus: undefined });
    const [isActiveButton, setIsActiveButton] = useState<boolean>(false);
    const [requestStatus, setRequestStatus] = useState<IRequestStatus>(INITIAL_REQUEST_STATUS);

    const {
        uuid: caseUuid,
        caseStatus,
        caseId,
        patient,
        organisationUuid,
        assignmentDetails: actualDermatologist,
    } = currentCase;
    const { patientData = [] } = { ...patient };
    const { name: organisationName } = useSelector(getOrganisation);
    const firstName = getPatientData(patientData, "name");
    const secondName = getPatientData(patientData, "surname");
    const patientName = `${secondName.toUpperCase()}, ${firstName} `;

    const isReallocation = !!actualDermatologist?.assigneeUuid && !isUnallocation;
    const caseInOrgReview = caseStatus === CaseStatus.CASE_IN_REVIEW;

    const generateOptions = (dermatologistsResult: IDermatologist[]): IFilterOption[] => {
        const result = dermatologistsResult
            .filter((dermatologist: IDermatologist) => dermatologist.availability > 0)
            .map((dermatologist: IDermatologist) => {
                const { uuid, name, surname } = dermatologist;

                return {
                    key: uuid,
                    text: `${surname.toUpperCase()}, ${name}`,
                    value: uuid,
                };
            });

        return result;
    };

    const getDermatologistsList = async () => {
        let dermatologistsResult: IDermatologistsResult | null = null;

        if (isReallocation && isSuperAdmin && caseInOrgReview) {
            dermatologistsResult = await userService.getAllUsers({
                offset: 0,
                filters: [
                    `role:${UserRole.DERMATOLOGIST};status:${UserStatus.ACTIVE};organisationUuid:${organisationUuid}`,
                ],
                limit: 500,
            });
        } else {
            dermatologistsResult = await userService.getOrganisationUsers([
                `role:${UserRole.DERMATOLOGIST};status:${UserStatus.ACTIVE}`,
            ]);
        }

        const options = generateOptions(dermatologistsResult.data);

        setDermatologists(options);
    };

    useEffect(() => {
        getDermatologistsList();
    }, []);

    const handleDermatologistChange = (
        event: SyntheticEvent<HTMLElement> | undefined,
        data: InputOnChangeData | DropdownProps
    ) => {
        const { id } = data;
        const curentDermatologistName = dermatologists.find((dermatologist) => dermatologist.value === id).text;

        setSelectedDermatologist({ assigneeUuid: `${id}`, assigneeName: curentDermatologistName });

        if (!selectedDermatologist.assigneeUuid) {
            setIsActiveButton(true);
        }
    };

    const closeDialog = () => {
        callback(false);
    };

    const handleOnModalEsc = () => {
        const { newStatus, result } = allocationStatusAndResult;
        updateCaseStatus(caseUuid, newStatus, {
            ...selectedDermatologist,
            ...(result ? { uuid: result.data.data.uuid } : {}),
        });
    };

    const handleApplyClick = async () => {
        let newStatus;
        let result: { data: { data: { uuid: string } } } | null = null;

        if (requestStatus.isSuccess) {
            updateCaseStatus(caseUuid, newStatus, {
                ...selectedDermatologist,
                ...(result ? { uuid: result.data.data.uuid } : {}),
            });
            closeDialog();
        } else {
            setRequestStatus({ ...requestStatus, isPending: true });

            try {
                if (isUnallocation) {
                    const isInReview = caseStatus === CaseStatus.CASE_IN_REVIEW;
                    newStatus = isInReview ? CaseStatus.CASE_FOR_REVIEW : CaseStatus.CASE_FOR_SA_REVIEW;
                    await reviewService.unallocateCase(actualDermatologist.uuid);
                } else {
                    const isIForReview = caseStatus === CaseStatus.CASE_FOR_REVIEW;
                    newStatus = isIForReview ? CaseStatus.CASE_IN_REVIEW : CaseStatus.CASE_IN_SA_REVIEW;
                    result = await reviewService.allocateCase(caseUuid, selectedDermatologist.assigneeUuid);
                }

                setAllocationStatusAndResult({ result, newStatus });
                setRequestStatus({ ...requestStatus, isSuccess: true, isPending: false });
            } catch ({ message }) {
                setRequestStatus({
                    ...requestStatus,
                    isPending: false,
                    error: `Sorry, something went wrong. ${message}`,
                });
            }
        }
    };

    const { isPending, isSuccess, error } = requestStatus;
    const isButtonNotActive = (!isActiveButton || isPending) && !isUnallocation;
    const isAllocation = !actualDermatologist?.assigneeUuid && !isUnallocation;

    let buttonText = "";
    let modalTitle = "";

    if (isAllocation) {
        buttonText = "Allocate Case";
        modalTitle = "Allocate case to a dermatologist";
    }

    if (isReallocation) {
        buttonText = "Change Allocation";
        modalTitle = "Change dermatologist allocation";
    }

    if (isUnallocation) {
        buttonText = "Continue";
        modalTitle = `Are you sure you want to remove the allocation of this case?`;
    }

    return (
        <>
            {!isSuccess ? (
                <ModalDialog
                    title={modalTitle}
                    closeIcon={!isUnallocation}
                    subtitle={
                        <div>
                            <span>{`Case ID: ${caseId} > ${organisationName}`}</span>
                            {isReallocation && (
                                <span>{`Current dermatologist: ${actualDermatologist?.assigneeName}`}</span>
                            )}
                            <span>Patient: {patientName}</span>
                        </div>
                    }
                    open={showDialog}
                    onClose={closeDialog}
                    maxWidth={isUnallocation ? "sm" : "lg"}
                    buttons={[
                        {
                            onClick: handleApplyClick,
                            text: buttonText,
                            isLoading: isPending,
                            disabled: isButtonNotActive,
                            colour: "primary",
                        },
                        ...(isUnallocation
                            ? [
                                  {
                                      onClick: () => closeDialog(),
                                      text: "Close",
                                  },
                              ]
                            : [{}]),
                    ]}
                >
                    {(isReallocation || isAllocation) && (
                        <DialogContent>
                            <div className="select-dermatologist-wrapper">
                                {dermatologists &&
                                    dermatologists.map((dermatologist) => (
                                        <QuestionAnswer
                                            key={dermatologist.key}
                                            selected={
                                                dermatologist.key ===
                                                (selectedDermatologist.assigneeUuid ||
                                                    actualDermatologist?.assigneeUuid)
                                            }
                                            value={dermatologist.text}
                                            id={dermatologist.key}
                                            option={dermatologist}
                                            onClick={handleDermatologistChange}
                                        />
                                    ))}
                            </div>
                            {error && <Message negative>{error}</Message>}
                        </DialogContent>
                    )}
                    {isUnallocation &&
                        "The removal of the allocation means that the dermatologist can no longer review the case."}
                </ModalDialog>
            ) : (
                <ModalDialog
                    title={isUnallocation ? "Allocation removed" : "Allocation successful"}
                    open={showDialog}
                    onClose={closeDialog}
                    maxWidth="sm"
                    iconName="check"
                    disableBackdropClick
                    onEsc={handleOnModalEsc}
                    subtitle={
                        <div>
                            <span>{`Case ID: ${caseId} > ${organisationName} `}</span>
                        </div>
                    }
                    buttons={[
                        {
                            onClick: handleApplyClick,
                            text: "Close",
                            colour: "primary",
                        },
                    ]}
                >
                    {isUnallocation ? "" : `Case successfully allocated to ${selectedDermatologist.assigneeName}`}
                </ModalDialog>
            )}
        </>
    );
};

AllocateCaseModal.defaultProps = {
    currentCase: null,
};

export default AllocateCaseModal;
