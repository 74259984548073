import { FC } from "react";
import { Header, Image } from "semantic-ui-react";

interface IOrganisationHeaderItemProps {
    organisationName: string;
}

const OrganisationHeaderItem: FC<IOrganisationHeaderItemProps> = ({ organisationName }) => (
    <div className="organisation-menu-container">
        <Image src="/images/icons/icon-home.svg" />

        <div className="organisation-menu-details-container single-item">
            <div>
                <div className="organisation-menu-organisation-info">
                    <Header as="h5">Organisation</Header>
                    <span>{organisationName}</span>
                </div>
            </div>
        </div>
    </div>
);

export default OrganisationHeaderItem;
