import React, { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

interface IModalErrors {
    title: string;
    reasons?: string[];
    onCancel: () => void;
    onConfirm: () => void;
}

const ModalErrors: FC<IModalErrors> = ({ title, onCancel, onConfirm, reasons }) => (
    <div className="remote-model__modal">
        <Image alt="Alert icon" src="/images/icons/icon-alert.svg" />
        <p className="h3">{title}</p>
        {reasons ? (
            <ul>
                {reasons.map((reason: string) => (
                    <li key={reason} className="p">
                        <b>{reason}</b>
                    </li>
                ))}
            </ul>
        ) : null}

        <p className="p semibold">
            Please contact your insurance provider or GP to advise on an alternative course of action.
        </p>
        <div className="buttons-wrapper">
            <CustomButton type="button" variant="empty-dark" action={onCancel} text="Back" />
            <CustomButton type="button" variant="filled" action={onConfirm} text="OK" />
        </div>
    </div>
);

ModalErrors.defaultProps = {
    reasons: [],
};

export default ModalErrors;
