import React, { FC, useEffect, useState, useContext, FormEvent } from "react";
import { useHistory } from "react-router-dom";
import { Message } from "semantic-ui-react";

import ButtonContainer from "components/templates/ButtonContainer";
import CustomButton from "components/templates/Button";
import SuccessModal from "components/CaseImgReassessment/SuccessModal";
import ImagesGrid from "components/CaseImgReassessment/ImagesGrid";

import { ModalContext } from "contextProviders/ModalProvider";

import { ISelectedImage, BadImgReason, RetakeImagesError, IRetakeImageReq, IRetakeImagesReq } from "model/case";
import { IImagePreview } from "model/imagePreview";
import { ModalTypes } from "model/modal";

import * as caseService from "services/caseService";

interface IProps {
    dermImages: IImagePreview[];
    contextImages: IImagePreview[];
    handleImageClick: (uuid: string) => void;
}

const SelectableImages: FC<IProps> = ({ dermImages, contextImages, handleImageClick }) => {
    const history = useHistory();

    const { setModalParameters } = useContext(ModalContext);

    const [images, setImages] = useState<ISelectedImage[]>([]);
    const [error, setError] = useState<RetakeImagesError | "">("");

    useEffect(() => {
        if (!images.length && dermImages.length && contextImages.length) {
            setImages(
                [...dermImages, ...contextImages].map((image) => ({
                    checked: false,
                    uuid: image.uuid,
                    reasons: [],
                    optionalNotes: "",
                }))
            );
        }
    }, [dermImages, contextImages]);

    const handleImageSelection = (event: FormEvent<HTMLInputElement>): void => {
        const { id, checked } = event.target as HTMLInputElement;

        setImages(
            images.map((image) => {
                if (image.uuid === id) {
                    return {
                        ...image,
                        checked,
                    };
                }
                return image;
            })
        );

        if (error === RetakeImagesError.NO_IMAGES_SELECTED) {
            setError("");
        }
    };

    const handleReasonSelection = (event: FormEvent<HTMLInputElement>, imageUuid: string): void => {
        const { name, value, checked } = event.target as HTMLInputElement;

        let selectedImages: ISelectedImage[];

        if (name === "optionalNote") {
            selectedImages = images.map((image) => {
                if (image.uuid === imageUuid) {
                    return {
                        ...image,
                        optionalNotes: value,
                    };
                }
                return image;
            });
        } else {
            selectedImages = images.map((image) => {
                if (image.uuid === imageUuid) {
                    if (checked) {
                        return {
                            ...image,
                            reasons: [...image.reasons, value as BadImgReason],
                        };
                    }
                    return {
                        ...image,
                        reasons: image.reasons.filter((reason) => reason !== value),
                    };
                }
                return image;
            });
        }

        setImages(selectedImages);
    };

    const handleSubmit = (): void => {
        const selectedImages: ISelectedImage[] = images.filter((image) => image.checked);

        const imagesWithoutReason = selectedImages.find((image) => !image.reasons.length);

        if (!selectedImages.length) {
            setError(RetakeImagesError.NO_IMAGES_SELECTED);
            return;
        }

        if (imagesWithoutReason) {
            setError(RetakeImagesError.MISSING_REASON);
            return;
        }

        const retakeImageReq: IRetakeImageReq[] = selectedImages.map((image) => {
            const { uuid, reasons, optionalNotes } = image;

            if (image.optionalNotes) {
                return {
                    uuid,
                    retakeReasons: reasons,
                    retakeReasonOther: optionalNotes,
                };
            }
            return {
                uuid,
                retakeReasons: reasons,
            };
        });

        const retakeImagesReq: IRetakeImagesReq = {
            retakeImages: retakeImageReq,
        };

        caseService
            .retakeImagesRequest(retakeImagesReq)
            .then(() => {
                setModalParameters({
                    className: "success-modal",
                    content: <SuccessModal />,
                    isVisible: true,
                    size: "small",
                    type: ModalTypes.SHOW_CLOSE_BUTTON,
                    closeCallback: history.goBack,
                });
            })
            .catch(() => setError(RetakeImagesError.NO_SERVER_RESPONSE));
    };

    return (
        <>
            {images.length ? (
                <ImagesGrid
                    images={images}
                    dermImages={dermImages}
                    contextImages={contextImages}
                    error={error}
                    handleImageClick={handleImageClick}
                    handleImageSelection={handleImageSelection}
                    handleReasonSelection={handleReasonSelection}
                />
            ) : null}

            {error === RetakeImagesError.NO_IMAGES_SELECTED && (
                <Message error content="Please select at least one image" />
            )}

            {error === RetakeImagesError.NO_SERVER_RESPONSE && (
                <Message
                    error
                    content="Sorry, something went wrong. Please try refreshing the page or try again later"
                />
            )}

            <ButtonContainer
                button1={
                    <CustomButton variant="empty" type="button" action={history.goBack} text="Cancel" size="small" />
                }
                button2={
                    <CustomButton
                        variant="filled"
                        type="submit"
                        action={handleSubmit}
                        text="Send request for new images"
                    />
                }
            />
        </>
    );
};

export default SelectableImages;
