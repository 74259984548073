import { FC } from "react";
import { DialogContent } from "@mui/material";

import ModalDialog from "components/templates/dialog/ModalDialog";

interface IUpdateDiagnosisDialogProps {
    showModal: boolean;
    handleClose: () => void;
    handleContinue: () => void;
    isConfirmAction: boolean;
}

const CONFIRM = "Confirm";
const DISCARD = "Discard";

export const UpdateDiagnosisDialog: FC<IUpdateDiagnosisDialogProps> = ({
    showModal,
    handleClose,
    handleContinue,
    isConfirmAction,
}) => {
    const modalTitle = `${isConfirmAction ? CONFIRM : DISCARD} changes`;
    const actionButtonText = isConfirmAction ? CONFIRM : DISCARD;
    const dialogContent = `Do you want to ${
        isConfirmAction ? CONFIRM.toLowerCase() : DISCARD.toLowerCase()
    } your changes?`;
    const modalIconName = isConfirmAction ? "check" : "x";

    return (
        <ModalDialog
            title={modalTitle}
            open={showModal}
            onClose={handleClose}
            iconName={modalIconName}
            disableBackdropClick
            buttons={[
                {
                    onClick: handleContinue,
                    text: actionButtonText,
                    colour: "primary",
                },
                {
                    onClick: handleClose,
                    text: "Cancel",
                },
            ]}
        >
            <DialogContent>
                <p>{dialogContent}</p>
            </DialogContent>
        </ModalDialog>
    );
};

export default UpdateDiagnosisDialog;
