import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";

interface IFormHeader {
    isLoading: boolean;
    isTooManyAttempts: boolean;
    showSuccessMessage: boolean;
    isUnverifiedAndHomeInitiated: boolean;
}

const FormHeader = ({
    isLoading,
    isTooManyAttempts,
    showSuccessMessage,
    isUnverifiedAndHomeInitiated,
}: IFormHeader) => {
    if (isLoading) {
        return (
            <div className="pds-loading">
                <CircularProgress size={40} />
                <h3>{`${
                    isUnverifiedAndHomeInitiated ? "Verifying patient details against" : "Searching for the patient in"
                } the NHS database`}</h3>
            </div>
        );
    }
    if (showSuccessMessage) {
        return (
            <>
                <div className="icon">
                    <CheckIcon fontSize="large" />
                </div>
                <h3>Patient details verified</h3>
            </>
        );
    }
    return (
        <>
            <div className="icon">
                {!isTooManyAttempts ? <SearchIcon fontSize="large" /> : <ErrorOutlineIcon fontSize="large" />}
            </div>
            <h3>
                {!isTooManyAttempts
                    ? "Search for the patient in the NHS database"
                    : "Patient not found in the NHS database"}
            </h3>
        </>
    );
};
export default FormHeader;
