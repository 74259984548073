import { SyntheticEvent, FC, useEffect, ChangeEvent, useState } from "react";
import { Button, Divider, DropdownProps, Form, Grid, Header, InputProps, Label, Segment } from "semantic-ui-react";
import { Moment } from "moment";

import PreviewExport from "components/Administration/Reports/PreviewExport";
import ConditionalRender from "components/templates/ConditionalRender";
import CaseReportCSVDialog from "components/templates/dialog/CaseReportCSVDialog";
import InputDateField from "components/InputDateField";

import { formatMomentDateForForm } from "helpers/datetime";

import { UserRole } from "model/userRole";
import { UserStatus } from "model/userStatus";
import { FILTER_ALL } from "model/filters";

import organizationService from "services/organizationService";
import reportsService from "services/reportsService";
import userService, { IUserDict } from "services/userService";
import { IDictionaryItem } from "model/dictionaryItem";

interface IExportCasesObject {
    caseNumber: number;
    organisationsCases: Record<string, string | string[]>;
}

const ReportsExport: FC = () => {
    const [dateFrom, setDateFrom] = useState<string>(null);
    const [dateTo, setDateTo] = useState<string>(null);

    const [dateFromError, setDateFromError] = useState<boolean | string>();
    const [dateToError, setDateToError] = useState<boolean | string>();

    const [downloadCaseCSV, setDownloadCaseCSV] = useState<boolean>();
    const [downloadLesionsCSV, setDownloadLesionsCSV] = useState<boolean>();
    const [downloadNextStepsCSV, setDownloadNextStepsCSV] = useState<boolean>();
    const [submitting, setSubmitting] = useState<boolean>();
    const [caseReportCSVDialogOpened, setCaseReportCSVDialogOpened] = useState<boolean>();
    const [organisationDictionary, setOrganisationDictionary] = useState<IDictionaryItem[]>([]);
    const [dermatologistDictionary, setDermatologistDictionary] = useState<IUserDict[]>([]);
    const [dermatologistList, setDermatologistList] = useState<IUserDict[]>([]);
    const [exportCasesObject, setExportCasesObject] = useState<IExportCasesObject>();

    const [organizationUuidFilter, setOrganizationUuidFilter] = useState<string>();
    const [dermatologistFilter, setDermatologistFIlter] = useState<string>();
    const [managementOutcomeFilter, setManagementOutcomeFilter] = useState<string>();
    const [locationFilter, setLocationFilter] = useState<string>();

    useEffect(() => {
        organizationService.getAllOrganisationsDictionary().then((response) => {
            setOrganisationDictionary(response);
        });

        userService
            .getAllUsersDict(0, [`role:${UserRole.DERMATOLOGIST};status:${UserStatus.ACTIVE}`], undefined, 500)
            .then((result) => {
                setDermatologistDictionary(result);
                setDermatologistList(result);
            });
    }, []);

    const getFilters = () => {
        const filters: Record<string, string> = {};

        if (organizationUuidFilter) {
            filters.organisationUuid = organizationUuidFilter;
        }

        if (dermatologistFilter) {
            filters.assignment = dermatologistFilter;
        }

        return filters;
    };

    const getSearch = () => {
        const search: Record<string, string> = {};
        if (managementOutcomeFilter) search.recommendation = managementOutcomeFilter;
        if (locationFilter) search.locationName = locationFilter;
        return search;
    };

    const onChangeDermatologistFIlter = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const { value: fieldValue } = data;
        if (fieldValue !== FILTER_ALL) {
            const dermatologist = dermatologistDictionary.find((dictItem) => dictItem.value === fieldValue);
            const values = [fieldValue];
            if (dermatologist?.linkedUserUuid) {
                values.push(dermatologist.linkedUserUuid);
            }
            setDermatologistFIlter(`reviewedBy:${values.join(",")}`);
        } else {
            setDermatologistFIlter(fieldValue);
        }
    };

    const onChangeManagementOutcomeFilter = (event: ChangeEvent<HTMLInputElement>, data: InputProps) => {
        const { value: fieldValue } = data;
        setManagementOutcomeFilter(fieldValue);
    };

    const onChangeLocationFilter = (event: ChangeEvent<HTMLInputElement>, data: InputProps) => {
        const { value: fieldValue } = data;
        setLocationFilter(fieldValue);
    };

    const onOrganisationFilterChange = (event: SyntheticEvent<HTMLElement, Event>, obj: DropdownProps) => {
        const { value } = obj;
        setOrganizationUuidFilter(String(value));
        setDermatologistDictionary(
            dermatologistList.filter((item: IUserDict) =>
                value === FILTER_ALL
                    ? item
                    : item.organisationuuid === value ||
                      item.additionalorganisationuuids?.includes(value as string) ||
                      item.uuid === FILTER_ALL
            )
        );
    };

    const toggleDialog = () => {
        setCaseReportCSVDialogOpened(!caseReportCSVDialogOpened);
    };

    const onDownloadCasesCSV = () => {
        setDownloadCaseCSV(true);
        toggleDialog();

        reportsService
            .requestGenerateCaseCsvReport(dateFrom, dateTo, getFilters(), getSearch())
            .then(() => {
                setDownloadCaseCSV(false);
            })
            .catch(() => {
                setDownloadCaseCSV(false);
            });
    };

    const onDownloadLesionsCSV = () => {
        setDownloadLesionsCSV(true);
        toggleDialog();

        reportsService
            .requestGenerateLesionsCsvReport(dateFrom, dateTo, getFilters(), getSearch())
            .then(() => {
                setDownloadLesionsCSV(false);
            })
            .catch(() => {
                setDownloadLesionsCSV(false);
            });
    };

    const onDownloadNextStepsCSV = () => {
        setDownloadNextStepsCSV(true);
        toggleDialog();

        reportsService
            .requestGenerateNextStepsCsvReport(dateFrom, dateTo, getFilters(), getSearch())
            .then(() => {
                setDownloadNextStepsCSV(false);
            })
            .catch(() => {
                setDownloadNextStepsCSV(false);
            });
    };

    const validateDateNotEmpty = (givenDateFrom: number, givenDateTo: number) => {
        let correctDate = true;

        if (!givenDateFrom || Number.isNaN(givenDateFrom)) {
            setDateFromError("Date from can not be empty");
            correctDate = false;
        }

        if (!givenDateTo || Number.isNaN(givenDateTo)) {
            setDateToError("Date to can not be empty");
            correctDate = false;
        }

        return correctDate;
    };

    const validateDateRangeNotInFuture = (givenDateFrom: number, givenDateTo: number) => {
        let correctDate = true;
        const dateNow = new Date().getTime();

        if (givenDateFrom > dateNow) {
            setDateFromError("Date from can not be in future");
            correctDate = false;
        }

        if (givenDateTo > dateNow) {
            setDateToError("Date to can not be in future");
            correctDate = false;
        }

        return correctDate;
    };

    const validateDateRange = () => {
        setDateToError(false);
        setDateFromError(false);

        const dateFromMillis = dateFrom ? Date.parse(dateFrom) : undefined;
        const dateToMillis = dateTo ? Date.parse(dateTo) : undefined;

        if (!validateDateNotEmpty(dateFromMillis, dateToMillis)) {
            return false;
        }

        if (!validateDateRangeNotInFuture(dateFromMillis, dateToMillis)) {
            return false;
        }

        if (dateFromMillis > dateToMillis) {
            setDateFromError("Date from must be before Date To");
            return false;
        }

        setDateToError(false);
        setDateFromError(false);
        return true;
    };

    const submit = () => {
        if (validateDateRange()) {
            setSubmitting(true);
            reportsService
                .getPreviewExport(dateFrom, dateTo, 50, getFilters(), getSearch())
                .then((result) => {
                    setExportCasesObject(result);
                    setSubmitting(false);
                })
                .catch(() => {
                    setSubmitting(false);
                });
        }
    };

    const getResults = () =>
        exportCasesObject ? (
            <div>
                <Divider />
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Label>{`Cases: ${exportCasesObject.caseNumber}`}</Label>
                            <PreviewExport organisationsCases={exportCasesObject.organisationsCases} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button loading={downloadCaseCSV} style={{ marginTop: 22 }} onClick={onDownloadCasesCSV}>
                                Create Cases CSV
                            </Button>
                            <Button
                                loading={downloadLesionsCSV}
                                style={{ marginTop: 22 }}
                                onClick={onDownloadLesionsCSV}
                            >
                                Create Lesions CSV
                            </Button>
                            <Button
                                loading={downloadNextStepsCSV}
                                style={{ marginTop: 22 }}
                                onClick={() => {
                                    onDownloadNextStepsCSV();
                                }}
                            >
                                Create Next Steps CSV
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        ) : (
            <></>
        );

    return (
        <div>
            <CaseReportCSVDialog
                onClose={toggleDialog}
                callback={toggleDialog}
                showDialog={caseReportCSVDialogOpened}
            />

            <ConditionalRender requiredRole={[UserRole.SUPERADMIN, UserRole.ADMIN]}>
                <Segment>
                    <h2>Export cases and lesions</h2>
                    <Form>
                        <Grid columns={4}>
                            <Grid.Row className="export-csv-grid-bottom-padding export-csv-grid-top-padding">
                                <Grid.Column>
                                    <Header as="h3" className="without-bottom-margin">
                                        Filters & Search
                                    </Header>
                                    <Divider className="title-divider export-csv-no-bottom-padding" />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row className="export-csv-no-top-padding">
                                <ConditionalRender requiredRole={[UserRole.SUPERADMIN]}>
                                    <Grid.Column className="export-csv-column">
                                        <Form.Dropdown
                                            search
                                            selection
                                            fluid
                                            label="Organisation"
                                            id="organisationUuid"
                                            placeholder="Filter by organisation"
                                            onChange={onOrganisationFilterChange}
                                            options={organisationDictionary.map((org, idx) => ({
                                                text: org.text,
                                                key: idx,
                                                value: org.value,
                                            }))}
                                            clearable
                                        />
                                    </Grid.Column>
                                </ConditionalRender>
                                <Grid.Column className="export-csv-column">
                                    <Form.Select
                                        label="Dermatologist"
                                        id="assignment"
                                        placeholder="Filter by dermatologist"
                                        onChange={onChangeDermatologistFIlter}
                                        options={dermatologistDictionary}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        type="text"
                                        value={managementOutcomeFilter}
                                        label="Management Outcome"
                                        id="recommendation"
                                        placeholder="Search by Management Outcome"
                                        onChange={onChangeManagementOutcomeFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        type="text"
                                        value={locationFilter}
                                        label="Location"
                                        id="locationName"
                                        placeholder="Search by Location"
                                        onChange={onChangeLocationFilter}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row className="export-csv-grid-bottom-padding export-csv-grid-top-padding">
                                <Grid.Column>
                                    <Header as="h3" className="without-bottom-margin">
                                        Data range
                                    </Header>
                                    <Divider className="title-divider export-csv-grid-bottom-padding" />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="export-csv-no-top-padding">
                                <Grid.Column width={4}>
                                    <InputDateField
                                        value={dateFrom}
                                        label="Date from"
                                        boldLabel
                                        onChange={(value: Moment) => {
                                            if (value !== null) setDateFrom(formatMomentDateForForm(value));
                                        }}
                                        dateError={dateFromError}
                                        required
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <InputDateField
                                        value={dateTo}
                                        label="Date to"
                                        boldLabel
                                        onChange={(value: Moment) => {
                                            if (value !== null) setDateTo(formatMomentDateForForm(value));
                                        }}
                                        dateError={dateToError}
                                        required
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Button loading={submitting} className="export-csv-button" onClick={submit}>
                                        Submit
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>

                    {getResults()}
                </Segment>
            </ConditionalRender>
        </div>
    );
};

export default ReportsExport;
