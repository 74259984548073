import { mainSegmentId } from "components/templates/PageWrapper";

export const scrollBottom = () => {
    const objDiv = document.getElementById(mainSegmentId);
    objDiv.scrollTop = objDiv.scrollHeight;
};

export const scrollToTop = () => {
    const segmentElement = document.getElementById(mainSegmentId);
    if (segmentElement) {
        segmentElement.scroll(0, 0);
    }
};

export const removeFocus = () => {
    (document.activeElement as HTMLElement).blur();
};
