import moment from "moment";

import { IObjectWithDate, UnitOfTime } from "model/datetime";

const DATE_FORMAT = "DD-MMM-YYYY";
const DATE_FORMAT_DOB = "DD MMM YYYY";
const DATE_FORMAT_FORM = "YYYY-MM-DD";
const ANDROID_TEXT_INPUT_DATE_FORMAT = "DD/MM/YYYY";
const DATETIME_FORMAT_WITH_SECONDS = `${DATE_FORMAT}, h:mm:ss a`;
const DATETIME_FORMAT_WITHOUT_SECONDS = `${DATE_FORMAT}, h:mm a`;
const TIME_FORMAT_REMOTE_MODEL = "h:mma";
const HOURS_IN_DAY = 24;

export const MIN_DATE = "1970-01-01";

export const formatTime = (time: string): string => moment(time).format(DATE_FORMAT).toUpperCase();

export const formatTimeWithHours = (time: string): string =>
    moment(time).format(DATETIME_FORMAT_WITH_SECONDS).toUpperCase();

export const formatTimeWithHoursWithMinutes = (time: string): string =>
    moment(time).format(DATETIME_FORMAT_WITHOUT_SECONDS).toUpperCase();

export const formatDateForForm = (time: string): string => moment(time).format(DATE_FORMAT_FORM);

export const formatMomentDateForForm = (time: moment.Moment): string => (time ? time.format(DATE_FORMAT_FORM) : null);

export const formatDateForAndroidTextDateForm = (time: string): string =>
    moment(time).format(ANDROID_TEXT_INPUT_DATE_FORMAT);

export const formatCustomDateForForm = (time: string, pattern): string =>
    moment(time, pattern).format(DATE_FORMAT_FORM);

export const formatTimeDOB = (time: string) => moment(time).format(DATE_FORMAT_DOB);

export const formatTimeRemoteModel = (time: string): string =>
    moment(time).format(TIME_FORMAT_REMOTE_MODEL).toLowerCase();

export const getDatetimeOverdueOrSinceUpdate = (time: string | undefined): moment.Moment =>
    moment(moment().diff(time)).add(1, "hours").utc();

export const singledDays = (time: moment.Moment): string =>
    +time.format("D") !== 0 && +time.format("D") - 1 !== 0 ? `${+time.format("D") - 1}d ` : "";

export const singledHours = (time: moment.Moment): string =>
    +time.format("HH") - 1 >= 0 ? `${+time.format("HH") - 1}h ` : `${+time.format("HH")}h `;

export const singledMinutes = (time: moment.Moment): string => {
    if (+time.format("D") - 1 >= 0 && +time.format("HH") - 1 >= 1) {
        return `${time.format("mm")}m`;
    }

    return +time.format("mm") < 10 && +time.format("mm") !== 0 ? `${time.format("m")}m` : `${time.format("mm")}m`;
};

export function daysInMonth(month, year): number {
    return new Date(year, month, 0).getDate();
}

export const sortByDescendingDateTime = <T extends IObjectWithDate>(a: T, b: T): number => {
    const valueA = a.date;
    const valueB = b.date;

    if (valueA.isBefore(valueB)) {
        return 1;
    }

    if (valueA.isAfter(valueB)) {
        return -1;
    }

    return 0;
};

export const calculateTimeDifference = (
    firstDateTime: string | undefined,
    secondDateTime?: string,
    unitOfTime: UnitOfTime = "hours"
): number => {
    const isDDMMMYYYYFormat = moment(firstDateTime, "DD-MMM-YYYY", true).isValid();
    const firstDateTimeAndFormat = isDDMMMYYYYFormat ? moment(firstDateTime, "DD-MMM-YYYY") : moment(firstDateTime);

    return moment(secondDateTime).add(1, "hours").diff(firstDateTimeAndFormat, unitOfTime);
};

export const formatDatetimeDifference = (datetime: string): string => {
    const formattedDateTime = formatTime(datetime);
    const datetimeOverdueOrSinceUpdate = getDatetimeOverdueOrSinceUpdate(datetime);
    const timeDifferenceInHours = calculateTimeDifference(datetime);

    if (timeDifferenceInHours < HOURS_IN_DAY) {
        return `${singledDays(datetimeOverdueOrSinceUpdate)} ${singledHours(
            datetimeOverdueOrSinceUpdate
        )} ${singledMinutes(datetimeOverdueOrSinceUpdate)} ago (${formattedDateTime})`;
    }

    const timeSinceOverdueOrUpdate = moment(datetime).add(1, "hours").fromNow().replace(/^a/i, "1");

    return `${timeSinceOverdueOrUpdate} (${formattedDateTime})`;
};
