import React, { FC } from "react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

interface IDiscardLesionModal {
    onCancel: () => void;
    onConfirm: () => void;
    lesionNumber: number;
}

const DiscardLesionModal: FC<IDiscardLesionModal> = ({ onCancel, onConfirm, lesionNumber }) => (
    <div className="remote-model__modal">
        <p className="h2">Are you sure you want to discard Lesion #{lesionNumber}?</p>

        <div className="buttons-wrapper">
            <CustomButton type="button" variant="empty-dark" action={onCancel} text="Cancel" />
            <CustomButton type="button" variant="filled" action={onConfirm} text="Yes, discard lesion" />
        </div>
    </div>
);

export default withModal(DiscardLesionModal);
