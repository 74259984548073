enum AnalysisResult {
    PROCESSING_ANSWER_TYPE_SYSTEM_ERROR = "PROCESSING_ANSWER_TYPE_SYSTEM_ERROR",
    PROCESSING_ANSWER_TYPE_IMAGE_HASH_VALIDATION_FAILED = "PROCESSING_ANSWER_TYPE_IMAGE_HASH_VALIDATION_FAILED",
    PROCESSING_ANSWER_TYPE_IMAGE_QUALITY_FAILED_BLURRED = "PROCESSING_ANSWER_TYPE_IMAGE_QUALITY_FAILED_BLURRED",
    PROCESSING_ANSWER_TYPE_IMAGE_QUALITY_FAILED_TOO_DARK = "PROCESSING_ANSWER_TYPE_IMAGE_QUALITY_FAILED_TOO_DARK",
    PROCESSING_ANSWER_TYPE_NO_LESION_IMAGE = "PROCESSING_ANSWER_TYPE_NO_LESION_IMAGE",
    PROCESSING_ANSWER_TYPE_SUCCESSFUL_PROCESSING = "PROCESSING_ANSWER_TYPE_SUCCESSFUL_PROCESSING",
}

export default AnalysisResult;
