import { createActions } from "redux-actions";

import types from "redux/actions/types";

export const {
    createCaseSuccess,
    createCaseFail,
    createDiagnosisSuccess,
    createDiagnosisFail,
    createPatientSuccess,
    createPatientFail,
    createLesionSuccess,
    createLesionFail,
    setConsent,
    setStudies,
    setDevices,
    setCase,
    updateDiagnosisSuccess,
    captureImageNextStep,
    captureImageReset,
    clearData,
    clearAssessment,
    updateLesionNumber,
    savePatient,
    continueAssessment,
    setCaseLocation,
    setNonSkinCancer,
    updateLesionSuccess,
    setCurrentLesion,
    toggleShowIntegrationPatientCreatedModal,
    setManualUploadRemote,
} = createActions(
    types.CREATE_CASE_SUCCESS,
    types.CREATE_CASE_FAIL,
    types.CREATE_DIAGNOSIS_SUCCESS,
    types.CREATE_DIAGNOSIS_FAIL,
    types.CREATE_PATIENT_SUCCESS,
    types.CREATE_PATIENT_FAIL,
    types.CREATE_LESION_SUCCESS,
    types.CREATE_LESION_FAIL,
    types.SET_CONSENT,
    types.SET_STUDIES,
    types.SET_DEVICES,
    types.SET_CASE,
    types.UPDATE_DIAGNOSIS_SUCCESS,
    types.CAPTURE_IMAGE_NEXT_STEP,
    types.CAPTURE_IMAGE_RESET,
    types.CLEAR_ASSESSMENT,
    types.CLEAR_DATA,
    types.UPDATE_LESION_NUMBER,
    types.SAVE_PATIENT,
    types.CONTINUE_ASSESSMENT,
    types.SET_CASE_LOCATION,
    types.SET_NON_SKIN_CANCER,
    types.UPDATE_LESION_SUCCESS,
    types.SET_CURRENT_LESION,
    types.TOGGLE_SHOW_INTEGRATION_PATIENT_CREATED_MODAL,
    types.SET_MANUAL_UPLOAD_REMOTE
);
