export enum LesionClassification {
    SKIN_LESION_MELANOMA = "Melanoma",
    SKIN_LESION_BCC = "Basal Cell Carcinoma",
    SKIN_LESION_SCC = "Squamous Cell Carcinoma",
    SKIN_LESION_AK = "Actinic Keratosis",
    SKIN_LESION_IEC = "Intraepidermal Carcinoma",
    SKIN_LESION_LENTIGO = "Lentigo Maligna",
    SKIN_LESION_DYSPLASTIC_NEVUS = "Dysplastic nevus",
    SKIN_LESION_SEBK = "Seborrheic Keratosis",
    SKIN_LESION_BENIGN_SOLAR_LENTIGO = "Solar Lentigo",
    SKIN_LESION_ATYPICAL_NEVUS = "Atypical Nevus",
    SKIN_LESION_BENIGN_SEBK = "Seborrheic Keratosis",
    SKIN_LESION_BENIGN_MELANOCYTIC_NEVUS = "Benign Melanocytic Nevus",
    SKIN_LESION_MELANOCYTIC_BENIGN_NEVUS = "Benign Melanocytic Nevi",
    SKIN_LESION_VASCULAR_LESIONS = "Vascular Lesions",
    SKIN_LESION_BENIGN_VASCULAR_LESIONS = "Benign Vascular Lesion",
    SKIN_LESION_DERMATOFIBROMA = "Dermatofibroma",
    SKIN_LESION_BENIGN_DERMATOFIBROMA = "Dermatofibroma",
    SKIN_LESION_OTHER = "Other",
    SKIN_LESION_BENIGN = "Benign",
}

export enum InitialDiagnosLesionClassification {
    SKIN_LESION_MELANOMA = "Melanoma",
    SKIN_LESION_BCC = "Basal Cell Carcinoma",
    SKIN_LESION_SCC = "Squamous Cell Carcinoma",
    SKIN_LESION_AK = "Actinic Keratosis",
    SKIN_LESION_IEC = "Intraepidermal Carcinoma / Bowen’s Disease",
    SKIN_LESION_BENIGN_SOLAR_LENTIGO = "Solar Lentigo",
    SKIN_LESION_ATYPICAL_NEVUS = "Atypical Nevus",
    SKIN_LESION_BENIGN_SEBK = "Seborrheic Keratosis",
    SKIN_LESION_BENIGN_MELANOCYTIC_NEVUS = "Benign Melanocytic Nevus",
    SKIN_LESION_BENIGN_VASCULAR_LESIONS = "Benign Vascular Lesion",
    SKIN_LESION_BENIGN_DERMATOFIBROMA = "Dermatofibroma",
}
