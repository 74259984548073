import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";

import OrganisationInheritedWarning from "components/templates/OrganisationInheritedWarning";

import { getModifiedInheritedProperties } from "helpers/organisation";

import { IOrganisation } from "model/organisation";
import { AdministrationPages, OrganisationManagementSubpages } from "model/administrationPages";

import { ADMINISTRATION } from "navigation/routes";

interface ISuborganisationRowProps {
    suborganisation: IOrganisation;
}

const SuborganisationRow: FC<ISuborganisationRowProps> = ({ suborganisation }) => {
    const { uuid, name, type } = suborganisation;
    const organisationInheritedProperties = getModifiedInheritedProperties(suborganisation, false);

    return (
        <Table.Row key={suborganisation.uuid}>
            <Table.Cell width={2}>
                <Link
                    to={`${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}/${OrganisationManagementSubpages.ORGANISATION_DETAILS}/${uuid}`}
                >
                    {name}
                    <OrganisationInheritedWarning organisationInheritedProperties={organisationInheritedProperties} />
                </Link>
            </Table.Cell>
            <Table.Cell width={2}>{type}</Table.Cell>
        </Table.Row>
    );
};

export default SuborganisationRow;
