import { withStyles, Tooltip } from "@material-ui/core";

interface CustomTooltipProps {
    backgroundColour?: string;
    textColour?: string;
    title: any;
}

const CustomTooltip = withStyles(() => ({
    tooltip: (props: CustomTooltipProps) => ({
        "position": "relative",
        "color": props.textColour || "#fff",
        "borderRadius": "4px",
        "fontSize": "0.95rem",
        "padding": "0.4rem 0.8rem",
        "textAlign": "center",
        "font-weight": "inherit",
        "font-family":
            "-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto', 'Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;",
        "backgroundColor": props.backgroundColour || "#333",
    }),
}))(Tooltip);

export default CustomTooltip;
