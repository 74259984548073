import { FC, ReactNode, createContext, useState } from "react";

interface IImageInformation {
    uuid: string;
    shouldRotate: boolean;
}

interface IImagePreviewContext {
    images: IImageInformation[];
    setNewImages: (dermoImages: IImageInformation[]) => void;
}

export const ImagePreviewContext = createContext<IImagePreviewContext>({} as IImagePreviewContext);

interface IImagePreviewProvider {
    children: ReactNode;
}

const ImagePreviewProvider: FC<IImagePreviewProvider> = ({ children }) => {
    const [dermoscopicImages, setDermoscopicImages] = useState<IImageInformation[]>([]);

    const setNewImages = (newDermoscopicImages: IImageInformation[]): void => {
        setDermoscopicImages([...newDermoscopicImages]);
    };

    return (
        <ImagePreviewContext.Provider value={{ images: dermoscopicImages, setNewImages }}>
            {children}
        </ImagePreviewContext.Provider>
    );
};

export default ImagePreviewProvider;
