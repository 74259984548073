import React, { FC } from "react";
import { List, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";

import { UserRole } from "model/userRole";

import { formatTimeWithHours } from "helpers/datetime";

import { getUser } from "redux/selectors/data";

export interface IReviewNote {
    removed: boolean;
    note: string;
    uuid: string;
    createdByName?: string;
    creationDate?: string;
    createdBy?: string;
}

interface IAddNotesButton {
    notes: IReviewNote[];
    title: string;
    subtitle?: string;
}

const ReviewNote: FC<IAddNotesButton> = ({ title, notes, subtitle }) => {
    const notRemovedNotes = notes?.filter((note) => !note.removed);
    const isNote = notRemovedNotes?.length > 0;
    const currentUser = useSelector(getUser);

    const getNoteCreatedByName = (patientNote: IReviewNote, createdByName: string): string => {
        const isNewlyCreatedNote = !("removed" in patientNote);
        if (!isNewlyCreatedNote) return createdByName;

        const isCurrentUserDermatologist = currentUser.role === UserRole.DERMATOLOGIST;
        if (!isCurrentUserDermatologist) return createdByName;

        const userTitle = currentUser.title ? `${currentUser.title} ` : "";

        return `${userTitle}${createdByName} (GMC ${currentUser.gmcNumber})`;
    };

    return (
        <div className="clinical-notes">
            <h4>{title}</h4>
            <p style={{ textAlign: "center" }}>{subtitle}</p>
            <div className="notes-container">
                <List>
                    {notRemovedNotes?.map((patientNote: IReviewNote) => {
                        const { note, createdByName, creationDate, uuid } = patientNote;
                        const time = formatTimeWithHours(creationDate);
                        const noteCreatedByName = getNoteCreatedByName(patientNote, createdByName);
                        const noteTitle = `Assessed by ${noteCreatedByName} (${time})`;
                        return (
                            <List.Item key={uuid}>
                                <p className="subtitle-gray-text assessed-by-text">{noteTitle}</p>
                                <Segment className="note">
                                    <span>{note}</span>
                                </Segment>
                            </List.Item>
                        );
                    })}
                </List>
                {!isNote && <Segment className="note"> N/A - No note recorded</Segment>}
            </div>
        </div>
    );
};

ReviewNote.defaultProps = {
    subtitle: "",
};

export default ReviewNote;
