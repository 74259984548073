import { connect } from "react-redux";

import UpdateDiagnosis from "components/UpdateDiagnosis/UpdateDiagnosis";

import * as diagnosisService from "services/diagnosisService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    currentLesion: state.assessment.currentLesion,
    diagnosis: state.assessment.diagnosis,
});

const mapDispatchToProps = {
    updateDiagnosis: diagnosisService.updateDiagnosis,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDiagnosis);
