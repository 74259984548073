import { connect } from "react-redux";

import Header from "components/templates/header/Header";

import { getAssessment } from "redux/selectors/assessment";

import * as authorizationService from "services/authorizationService";

const mapStateToProps = (state: any) => ({
    data: state.data,
    signedIn: state.auth.signedIn,
    showOrganisationSelection: state.data.showOrganisationSelection,
    assessment: getAssessment(state),
});

const mapDispatchToProps = {
    logout: authorizationService.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
