import { NotificationRole } from "model/userRole";

const EMAIL_REGEXP = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

export const getEmailAddressesFromTarget = (
    targets: (string | NotificationRole)[],
    caseCreatorEmail?: string
): string[] => {
    const emails = targets.filter((item) => item.match(EMAIL_REGEXP));
    if (caseCreatorEmail) {
        const withCaseCreator = targets.find((item) => item === NotificationRole.CASE_CREATOR);
        if (withCaseCreator) {
            emails.push(caseCreatorEmail);
        }
    }
    return emails;
};

export default getEmailAddressesFromTarget;
