import { FC } from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "semantic-ui-react";

import { HOME } from "navigation/routes";

interface ICustomHeader {
    logoUrl: string;
    isAtHomeFlow?: boolean;
}

const CustomHeader: FC<ICustomHeader> = ({ logoUrl, isAtHomeFlow }) => (
    <div className="remote-model__header">
        <Container>
            <div className="remote-model__header-row">
                <Link to={HOME}>
                    <Image className="logo-sa" src="/images/logoWhite.svg" />
                </Link>
                {logoUrl && <Image alt="Organisation logo" className="logo-organisation" src={logoUrl} />}
            </div>
            {!isAtHomeFlow && (
                <>
                    <div className="h1">Welcome to Skin Analytics</div>
                    <p className="p">
                        Skin Analytics is a service that allows you to have skin lesions checked for signs of skin
                        cancer, from the comfort of your own home.
                    </p>
                </>
            )}
        </Container>
    </div>
);

CustomHeader.defaultProps = {
    isAtHomeFlow: false,
};

export default CustomHeader;
