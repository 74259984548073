import { CaseListActions, TCaseListActions } from "contextProviders/modules/caseList/actions";
import { TAB_STATE } from "contextProviders/CaseListProvider";

import { INITIAL_CASE_LISTS } from "helpers/caseList";

import { ICase } from "model/case";
import { CaseListTabs, ICaseLists } from "model/caseList";

export default (state: ICaseLists, action: TCaseListActions) => {
    switch (action.type) {
        case CaseListActions.SAVE_CASE_LIST_TAB: {
            const { tab, data } = action;
            const currentTabState = state[tab];

            return { ...state, [tab]: { ...currentTabState, ...data } };
        }
        case CaseListActions.SET_LOADING: {
            const { tab, data } = action;
            const currentData = state[tab];

            return {
                ...state,
                [tab]: {
                    ...currentData,
                    isPending: data.isPending,
                },
            };
        }
        case CaseListActions.FETCH_MORE: {
            const { tab, data } = action;
            const currentData = state[tab];

            return {
                ...state,
                [tab]: {
                    ...currentData,
                    ...data,
                    data: [...currentData.data, ...data.data],
                },
            };
        }
        case CaseListActions.SET_FILTERS: {
            const {
                tab,
                data: { filters },
            } = action;
            const currentTabState = state[tab];

            return { ...state, [tab]: { ...currentTabState, filters } };
        }
        case CaseListActions.UPDATE_CASE_STATUS: {
            const { currentTab, caseUuid, nextTab, newStatus, newAssignee } = action;
            const currentTabState = currentTab ? state[currentTab] : {};
            const nextTabState = nextTab ? state[nextTab] : {};
            const allTabState = state.all;
            const { data: currentTabData, total: currentTabTotal } = currentTab ? currentTabState : allTabState;

            const currentCase = currentTabData.find((caseItem: ICase) => caseItem.uuid === caseUuid);

            if (currentCase) {
                currentCase.caseStatus = newStatus;
                currentCase.assignmentDetails = newAssignee;
            }

            const updatedCurrentTabData = currentTabData.filter((caseItem: ICase) => caseItem.uuid !== caseUuid);

            const isInsideAll = allTabState.data?.find((caseItem: ICase) => caseItem.uuid === caseUuid);

            const isInsideNextTab = nextTabState.data?.find((caseItem: ICase) => caseItem?.uuid === caseUuid);

            let nextTabData: ICase[] = [];
            const nextTabTotal = nextTabState.total + 1;

            if (nextTab) {
                nextTabData = isInsideNextTab ? [...nextTabState.data] : [currentCase, ...nextTabState.data];
            }

            const updatedAllData = isInsideAll
                ? allTabState.data.map((caseItem: ICase) => {
                      if (caseItem.uuid === caseUuid) {
                          return {
                              ...caseItem,
                              assignmentDetails: currentCase?.assignmentDetails || newAssignee,
                              caseStatus: newStatus,
                          };
                      }
                      return caseItem;
                  })
                : allTabState.data;

            return {
                ...state,
                ...(currentTab
                    ? {
                          [currentTab]: {
                              ...currentTabState,
                              data: updatedCurrentTabData,
                              total: currentTabTotal - 1,
                          },
                      }
                    : {}),
                ...(nextTab ? { [nextTab]: { ...nextTabState, data: nextTabData, total: nextTabTotal } } : {}),
                ...(isInsideAll ? { all: { ...allTabState, data: updatedAllData } } : {}),
            };
        }
        case CaseListActions.SET_SAS_LINK: {
            const { tab, data } = action;
            const { sasLink, caseUuid } = data;
            const currentCaseList = state[tab];
            const currentCase = currentCaseList.data.find((caseItem: ICase) => caseItem.uuid === caseUuid);
            currentCase.sasLink = sasLink;

            return { ...state, [tab]: currentCaseList };
        }
        case CaseListActions.UPDATE_KIT_DELIVERY_STATUS: {
            const { currentTab, caseUuid, nextTab, newKitDeliveryStatus } = action;
            const currentTabState = currentTab ? state[currentTab] : {};
            const nextTabState = nextTab ? state[nextTab] : {};
            const { data: currentTabData, total: currentTabTotal } = currentTabState;

            const currentCase: ICase = currentTabData.find((caseItem: ICase) => caseItem.uuid === caseUuid);

            if (currentCase) {
                currentCase.kitDeliveryStatus = newKitDeliveryStatus;
                currentCase.kitDeliveryStatusLastModificationDate = new Date().toISOString();
            }

            const updatedCurrentTabData = currentTabData.filter((caseItem: ICase) => caseItem.uuid !== caseUuid);

            const isInsideNextTab = nextTabState.data?.find((caseItem: ICase) => caseItem?.uuid === caseUuid);

            let nextTabData: ICase[] = [];
            const nextTabTotal = nextTabState.total + 1;

            if (nextTab) {
                if (isInsideNextTab) {
                    nextTabData = [...nextTabState.data];
                } else if (currentCase) {
                    nextTabData = [currentCase, ...nextTabState.data];
                } else {
                    nextTabData = [...nextTabState.data];
                }
            }

            return {
                ...state,
                ...(currentTab
                    ? {
                          [currentTab]: {
                              ...currentTabState,
                              data: updatedCurrentTabData,
                              total: currentTabTotal - 1,
                          },
                      }
                    : {}),
                ...(nextTab ? { [nextTab]: { ...nextTabState, data: nextTabData, total: nextTabTotal } } : {}),
            };
        }
        case CaseListActions.REMOVE_CASE_AFTER_CALLBACK: {
            const { caseUuid } = action;
            const currentTab = TAB_STATE[CaseListTabs.CALL_BACK];
            const currentTabState = state[currentTab];
            const { data: currentTabData, total: currentTabTotal } = currentTabState;

            const updatedCurrentTabData = currentTabData.filter((caseItem: ICase) => caseItem.uuid !== caseUuid);

            return {
                ...state,
                [currentTab]: {
                    ...currentTabState,
                    data: updatedCurrentTabData,
                    total: currentTabTotal - 1,
                },
            };
        }
        case CaseListActions.REMOVE_ALL_CASES: {
            return {
                ...state,
                INITIAL_CASE_LISTS,
            };
        }
        default:
            return state;
    }
};
