import React, { Component } from "react";
import { Message } from "semantic-ui-react";

import ConditionalRender from "components/templates/ConditionalRender";

import { IOrganisation, OrganisationType } from "model/organisation";
import { UserRole } from "model/userRole";

import studyService from "services/studyService";

interface ISettingsMessages {
    organisation: IOrganisation;
    errorCallback: (isErrorBlockingAssessment: boolean) => void;
}

interface ISettingsMessagesState {
    errors: false | IError[];
}

interface IError {
    message: string;
    isWarning?: boolean;
    isError?: boolean;
    isPositive?: boolean;
}

class SettingsValidation extends Component<ISettingsMessages, ISettingsMessagesState> {
    constructor(props: any) {
        super(props);
        this.state = {
            errors: false,
        };
    }

    public componentDidMount() {
        this.validateStudySettings();
    }

    private getErrorMessages() {
        const { errors } = this.state;
        return (
            errors &&
            errors.map((error: IError) => (
                <Message key="error" warning={error.isWarning} negative={error.isError} positive={error.isPositive}>
                    {error.message}
                </Message>
            ))
        );
    }

    private validateStudySettings() {
        const { organisation } = this.props;
        if (organisation.type === OrganisationType.STUDY) {
            this.validateStudyConfigurationExists();
        }
    }

    private validateStudyConfigurationExists() {
        const { errors } = this.state;
        const { errorCallback } = this.props;
        studyService.getLoggedUserStudies().then((studies: any[]) => {
            if (!studies.length) {
                const errorMessage: IError = {
                    isWarning: true,
                    message: `Your organisation is STUDY and do not have any study configuration. Assessment will not work in this case. Please contact with Skin Analytics.`,
                };
                this.setState({ errors: errors ? [...errors, errorMessage] : [errorMessage] });
                errorCallback(true);
            } else {
                this.validateDevicesExistsInStudy(studies.pop().uuid);
            }
        });
    }

    private validateDevicesExistsInStudy(studyUuid: string) {
        const { errors } = this.state;
        const { errorCallback } = this.props;
        studyService.getStudyDevices(studyUuid).then((response) => {
            const hasDevices = response.total > 0 && response.data.length;
            if (!hasDevices) {
                const errorMessage: IError = {
                    isWarning: true,
                    message: `Your organisation is STUDY and do not have any device in configuration. Assessment will not work in this case. Please contact with Skin Analytics.`,
                };
                this.setState({
                    errors: errors ? [...errors, errorMessage] : [errorMessage],
                });
                errorCallback(true);
            }
        });
    }

    public render() {
        return (
            <ConditionalRender
                requiredRole={[
                    UserRole.ADMIN,
                    UserRole.SA_ADMIN,
                    UserRole.SUPERADMIN,
                    UserRole.PATIENT,
                    UserRole.CLINICIAN,
                ]}
            >
                {this.getErrorMessages()}
            </ConditionalRender>
        );
    }
}

export default SettingsValidation;
