import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import * as actions from "redux/actions";
import store, { Dispatch } from "redux/store";

import * as ErrorService from "services/errorService";
import { LocalStorageService } from "services/localStorageService";

import URLs from "services/urls";

const { LESIONS_DEFINED_URL, COMPLETE_CASE_URL } = URLs;

function updateLesionNumberAction(newLesionNumber: number) {
    return (dispatch: Dispatch) => {
        dispatch(actions.assessmentActions.updateLesionNumber(newLesionNumber));
    };
}

async function finishAssessment(caseId: string, isRemoteModel?: boolean) {
    try {
        await AuthorizedHttpConnector.sendPost(LESIONS_DEFINED_URL.replace("{uuid}", caseId), {});
        if (!isRemoteModel) {
            store.store.dispatch(actions.assessmentActions.clearAssessment());
            LocalStorageService.removeCurrentCaseUuid();
        }
    } catch (err: any) {
        console.error(err);
        ErrorService.handleError(err, store.store.dispatch);
        throw new Error(err.response.data.errors?.map((error: any) => error.message)?.join(","), {
            cause: { statusCode: err.response.status },
        });
    }
}

async function completeCase(caseUuid: string) {
    try {
        await AuthorizedHttpConnector.sendPost(COMPLETE_CASE_URL.replace("{uuid}", caseUuid), {});
    } catch (err) {
        console.error(err);
    }
}

function newAssessment() {
    return (dispatch: Dispatch) => {
        dispatch(actions.assessmentActions.clearAssessment());
        dispatch(actions.previousCasesActions.clearCase());
        dispatch(actions.previousCasesActions.clearCases());
        dispatch(actions.dataActions.setPendingRequest(false));
    };
}

function toggleShowIntegrationPatientCreatedModal() {
    return (dispatch: Dispatch) => {
        dispatch(actions.assessmentActions.toggleShowIntegrationPatientCreatedModal());
    };
}

export default {
    updateLesionNumberAction,
    finishAssessment,
    newAssessment,
    completeCase,
    toggleShowIntegrationPatientCreatedModal,
};
