import React, { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

import { ModalIconAltTexts } from "model/modal";

interface ISuccessModal {
    onStart?: () => void;
}

const SuccessModal: FC<ISuccessModal> = ({ onStart }) => (
    <div className="remote-model__modal">
        <Image alt={ModalIconAltTexts.SUCCESS} src="/images/icons/icon-okay.svg" />
        <p className="h2">
            Thank you!
            <br />
            It looks like this service is right for you.
        </p>
        {onStart && (
            <div className="buttons-wrapper">
                <CustomButton type="button" variant="filled" action={onStart} text="Continue" />
            </div>
        )}
    </div>
);

SuccessModal.defaultProps = {
    onStart: null,
};

export default withModal(SuccessModal);
