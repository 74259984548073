import { FC, FormEvent, SyntheticEvent, useContext } from "react";
import { CheckboxProps, DropdownProps, Form, Grid } from "semantic-ui-react";
import clsx from "clsx";

import AllocationConfigurationContext from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigProvider";
import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";

import { getAllAvailableAllocations, getDefaultAllocationConfig } from "helpers/allocations";

import { AllocationConfigurationConsentType, IAllocationTableSettings } from "model/allocationConfig";
import { GuidanceValue, IAllocation } from "model/organisation";

import "scss/AdminPanel.scss";

interface IAllocationOptions {
    allocation: IAllocation;
    canEditAllocation: boolean;
    isRowForUrgentRefer: boolean;
    isUrgentReferEnabled: boolean;
    setNewAllocation: (event: FormEvent<HTMLInputElement>, obj: CheckboxProps) => void;
}

const AllocationOptions: FC<IAllocationOptions> = ({
    allocation,
    canEditAllocation,
    isRowForUrgentRefer,
    isUrgentReferEnabled,
    setNewAllocation,
}) => {
    const { allocation: allocationValue, guidanceValue, isForUnconsentedAutomatedDecisionCases } = allocation;
    const disabler = (): boolean => {
        if (isRowForUrgentRefer && !isUrgentReferEnabled) {
            return true;
        }

        return !canEditAllocation;
    };

    return (
        <>
            {getAllAvailableAllocations().map((possibleAction) => (
                <Grid.Column key={`${guidanceValue}-${possibleAction}`}>
                    <Form.Radio
                        aria-labelledby={`${possibleAction}-${
                            isForUnconsentedAutomatedDecisionCases ? "unconsented" : "consented"
                        }`}
                        data-testid={`${guidanceValue}-${possibleAction}`}
                        checked={possibleAction === allocationValue}
                        onChange={setNewAllocation}
                        value={possibleAction}
                        allocation={allocation}
                        disabled={disabler()}
                        name={guidanceValue}
                    />
                </Grid.Column>
            ))}
        </>
    );
};

interface IAllocationConfigurationTable {
    allocationSettings: IAllocationTableSettings;
    organisationUuid: string;
    parentUuid: string;
    type: AllocationConfigurationConsentType;
    setImmediateResults: (event: FormEvent<HTMLInputElement>, obj: CheckboxProps) => void;
    setNewPriority: (data: DropdownProps) => void;
    setNewAllocation: (event: FormEvent<HTMLInputElement>, obj: CheckboxProps) => void;
}

const AllocationConfigurationTable = ({
    allocationSettings,
    organisationUuid,
    parentUuid,
    type,
    setImmediateResults,
    setNewPriority,
    setNewAllocation,
}: IAllocationConfigurationTable): JSX.Element => {
    const {
        allocationState,
        allocationDispatchers: { toggleUrgentReferModal, toggleUrgentRefer },
    } = useContext(AllocationConfigurationContext);
    const { enableUrgentRefer: isUrgentReferEnabled } = allocationState;
    const { allocations } = allocationSettings;

    if (!allocations || allocations?.length === 0) {
        return null;
    }

    const renderOrder: IAllocation[] = getDefaultAllocationConfig(
        type === AllocationConfigurationConsentType.UNCONSENTED
    );

    const handleUrgentReferToggle = (isUrgentReferEnabledArg: boolean): void => {
        if (isUrgentReferEnabledArg) {
            toggleUrgentReferModal();
        } else {
            toggleUrgentRefer();
        }
    };

    const updatePriority = (_event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        setNewPriority(data);
    };

    return (
        <>
            {renderOrder.map((orderingAllocation: IAllocation) => {
                const allocation: IAllocation =
                    allocations.find(
                        (allocationConfig) => allocationConfig.guidanceValue === orderingAllocation.guidanceValue
                    ) || orderingAllocation;
                const {
                    isForUnconsentedAutomatedDecisionCases,
                    organisationUuid: allocationOrgUuid,
                    guidanceValue,
                    quantifier,
                    copyOfUuid,
                    immediateResults,
                } = allocation;

                const { isNew } = allocationSettings;

                const canEditAllocation: boolean =
                    isNew || !allocationOrgUuid || allocationOrgUuid === organisationUuid;

                const isRowForUrgentRefer: boolean = allocation.guidanceValue === GuidanceValue.URGENT_REFER;

                return (
                    <Grid.Row
                        className="allocation-configuration-table-row no-padding"
                        key={allocation.guidanceValue}
                        stretched
                        verticalAlign="middle"
                    >
                        <Grid.Column
                            className={clsx({
                                "allocation-disabled": isRowForUrgentRefer && !isUrgentReferEnabled,
                            })}
                            width={4}
                        >
                            <SettingsPropertyWrapper
                                parentOrgUuid={parentUuid}
                                copyOfUuid={copyOfUuid}
                                propertyOrganisationUuid={organisationUuid}
                            >
                                <b>{`${guidanceValue} (${quantifier})`}</b>
                                {isRowForUrgentRefer && !isForUnconsentedAutomatedDecisionCases ? (
                                    <Form.Checkbox
                                        checked={isUrgentReferEnabled}
                                        toggle
                                        value={type}
                                        className="urgent-refer-toggle"
                                        onChange={() => handleUrgentReferToggle(isUrgentReferEnabled)}
                                        disabled={false}
                                        float="right"
                                    />
                                ) : null}
                            </SettingsPropertyWrapper>
                        </Grid.Column>
                        <AllocationOptions
                            allocation={allocation}
                            canEditAllocation={canEditAllocation}
                            isRowForUrgentRefer={isRowForUrgentRefer}
                            isUrgentReferEnabled={isUrgentReferEnabled}
                            setNewAllocation={setNewAllocation}
                        />
                        <Grid.Column>
                            <Form.Checkbox
                                checked={immediateResults}
                                onChange={setImmediateResults}
                                allocation={allocation}
                                disabled={(isRowForUrgentRefer && !isUrgentReferEnabled) || !canEditAllocation}
                            />
                        </Grid.Column>
                        {isUrgentReferEnabled ? (
                            <Grid.Column>
                                <Form.Dropdown
                                    aria-label={`Update priority for ${allocation.guidanceValue}`}
                                    allocation={allocation}
                                    className="allocation-dropdown"
                                    selection
                                    fluid
                                    type="number"
                                    required
                                    onChange={updatePriority}
                                    options={[1, 2, 3, 4, 5].map((key) => ({
                                        key,
                                        text: key,
                                        value: key,
                                    }))}
                                    value={allocation.allocationOrder}
                                />
                            </Grid.Column>
                        ) : null}
                    </Grid.Row>
                );
            })}
        </>
    );
};

export default AllocationConfigurationTable;
