import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { IHistoryQuestion, IHistoryQuestionType } from "model/organisation";

import URLs from "services/urls";

const {
    CREATE_MEDICAL_QUESTIONS,
    CREATE_MEDICAL_QUESTION,
    MODIFY_MEDICAL_QUESTION,
    DELETE_MEDICAL_QUESTION,
    ADD_EXCLUSION,
    MODIFY_EXCLUSION,
    REMOVE_EXCLUSION,
} = URLs;

async function createQuestions(organisationUuid: string, questionsType: IHistoryQuestionType, questions: []) {
    let requestBody = {};
    if (questionsType === IHistoryQuestionType.MEDICAL) {
        requestBody = { medicalHistoryQuestions: questions };
    }
    if (questionsType === IHistoryQuestionType.LESION) {
        requestBody = { lesionHistoryQuestions: questions };
    }
    if (questionsType === IHistoryQuestionType.BIOPSY) {
        requestBody = { biopsyQuestions: questions };
    }

    const response = await AuthorizedHttpConnector.sendPost(
        CREATE_MEDICAL_QUESTIONS.replace("{{UUID}}", organisationUuid),
        requestBody
    );
    return { status: response.status };
}

async function createQuestion(organisationUuid: string, question: IHistoryQuestion) {
    const response = await AuthorizedHttpConnector.sendPost(
        CREATE_MEDICAL_QUESTION.replace("{{UUID}}", organisationUuid),
        question
    );
    return { status: response.status, question: response.data.data };
}

async function modifyQuestion(questionUuid: string, question: IHistoryQuestion) {
    const response = await AuthorizedHttpConnector.sendPut(
        MODIFY_MEDICAL_QUESTION.replace("{{UUID}}", questionUuid),
        question
    );
    return { status: response.status, uuid: response.data.data.uuid };
}

async function deleteQuestion(questionUuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(
        DELETE_MEDICAL_QUESTION.replace("{{UUID}}", questionUuid)
    );
    return { status: response.status };
}

async function createExclusion(organisationUuid: string, exclusion: any) {
    const response = await AuthorizedHttpConnector.sendPost(
        ADD_EXCLUSION.replace("{{UUID}}", organisationUuid),
        exclusion
    );
    return { status: response.status, exclusion: response.data.data };
}

async function modifyExclusion(exclusionUuid: string, exclusion: any) {
    const response = await AuthorizedHttpConnector.sendPut(
        MODIFY_EXCLUSION.replace("{{UUID}}", exclusionUuid),
        exclusion
    );
    return { status: response.status, uuid: response.data.data.uuid };
}

async function deleteExclusion(exclusionUuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(REMOVE_EXCLUSION.replace("{{UUID}}", exclusionUuid));
    return { status: response.status };
}

export default {
    createExclusion,
    createQuestion,
    createQuestions,
    deleteExclusion,
    deleteQuestion,
    modifyExclusion,
    modifyQuestion,
};
