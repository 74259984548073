import moment from "moment";

import { Config } from "config/config";

import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { downloadFile } from "helpers/downloadFile";
import { FILTER_PARAMETERS, generateParameters } from "helpers/caseList";

import { ICase } from "model/case";

import addFiltersAndSearchToUrlWithFilters from "util/searchAndFilters";

import URLs from "services/urls";

const {
    GET_CASES,
    GET_CASES_CSV,
    GET_NEXT_STEPS_CSV,
    GET_LESIONS_CSV,
    GET_USERS_CSV,
    GET_REPORT_CSV,
    GET_CASE_LIST_REPORT_CSV,
    GET_KIT_TO_DISPATCH_REPORT_CSV,
    UPLOAD_USER_LIST,
} = URLs;

async function downloadAllPagesFromLesionsAndCases(result: any, data: ICase[]) {
    if (result.data._links?.next) {
        const response = await AuthorizedHttpConnector.sendGet(`${Config.getReaderMsUrl()}${result.data._links.next}`);
        data.push(...response.data.data);
        await downloadAllPagesFromLesionsAndCases(response, data);
    }
}

function mapCasesToOrganisationCases(cases: ICase[]) {
    const result = {};

    cases.forEach((item) => {
        if (result[item.organisationName] === undefined) {
            result[item.organisationName] = [item];
        } else {
            result[item.organisationName].push(item);
        }
    });

    return result;
}

async function getPreviewExport(
    dateFrom: string,
    dateTo: string,
    limit: number,
    filters?: Record<string, unknown>,
    search?: Record<string, unknown>
) {
    const dateFromTimestamp = `${new Date(dateFrom).getTime()}`;
    const dateToTimestamp = `${new Date(`${dateTo} 23:59:59`).getTime()}`;

    const getCasesUrl = GET_CASES.replace("{{DATEFROM}}", dateFromTimestamp)
        .replace("{{DATETO}}", dateToTimestamp)
        .replace("{{LIMIT}}", String(limit));

    const casesResult = await AuthorizedHttpConnector.sendGet(
        addFiltersAndSearchToUrlWithFilters(getCasesUrl, filters, search)
    );

    const cases: ICase[] = casesResult.data.data;
    await downloadAllPagesFromLesionsAndCases(casesResult, cases);

    return {
        caseNumber: casesResult.data.total,
        organisationsCases: mapCasesToOrganisationCases(cases),
    };
}

async function requestGenerateCaseCsvReport(
    dateFrom: string,
    dateTo: string,
    filters?: Record<string, unknown>,
    search?: Record<string, unknown>
) {
    const dateFromTimestamp = `${new Date(dateFrom).getTime()}`;
    const dateToTimestamp = `${new Date(`${dateTo} 23:59:59`).getTime()}`;
    const urlGetCases = GET_CASES_CSV.replace("{{DATEFROM}}", dateFromTimestamp).replace("{{DATETO}}", dateToTimestamp);
    await AuthorizedHttpConnector.sendGet(addFiltersAndSearchToUrlWithFilters(urlGetCases, filters, search));
}

async function requestGenerateLesionsCsvReport(
    dateFrom: string,
    dateTo: string,
    filters?: Record<string, unknown>,
    search?: Record<string, unknown>
) {
    const dateFromTimestamp = `${new Date(dateFrom).getTime()}`;
    const dateToTimestamp = `${new Date(`${dateTo} 23:59:59`).getTime()}`;
    const urlGetLesions = GET_LESIONS_CSV.replace("{{DATEFROM}}", dateFromTimestamp).replace(
        "{{DATETO}}",
        dateToTimestamp
    );

    await AuthorizedHttpConnector.sendGet(addFiltersAndSearchToUrlWithFilters(urlGetLesions, filters, search));
}

async function requestGenerateNextStepsCsvReport(
    dateFrom: string,
    dateTo: string,
    filters?: Record<string, unknown>,
    search?: Record<string, unknown>
): Promise<void> {
    const dateFromTimestamp = `${new Date(dateFrom).getTime()}`;
    const dateToTimestamp = `${new Date(`${dateTo} 23:59:59`).getTime()}`;
    const urlGetLesions = GET_NEXT_STEPS_CSV.replace("{{DATEFROM}}", dateFromTimestamp).replace(
        "{{DATETO}}",
        dateToTimestamp
    );

    await AuthorizedHttpConnector.sendGet(addFiltersAndSearchToUrlWithFilters(urlGetLesions, filters, search));
}

async function requestGenerateUsersCsvReport(filters?: string[], search?: string[], organisationSearch?: string[]) {
    const query = new URLSearchParams({
        ...(filters?.length ? { filter: `${filters.join(";")}` } : {}),
        ...(search?.length ? { search: `${search.join(";")}` } : {}),
        ...(organisationSearch?.length ? { organisationSearch: `${organisationSearch.join(";")}` } : {}),
    });

    const urlGetUsers = `${GET_USERS_CSV}?${query.toString()}`;

    await AuthorizedHttpConnector.sendGet(urlGetUsers);
}

async function downloadReport(caseId: string, reportName: string) {
    const reportResponse = await AuthorizedHttpConnector.sendGet(
        GET_REPORT_CSV.replace("{{CASEID}}", caseId).replace("{{FILENAME}}", reportName)
    );
    downloadFile(reportResponse.data, `${reportName}`);
}

async function downloadCasesListCsvReport(
    viewName: string,
    filters?: any,
    patientFilters?: any,
    sort?: string,
    searchFilter?: any
) {
    const reportName = `cases_${new Date().toLocaleString("en-GB")}`;
    const filterString = generateParameters(filters, FILTER_PARAMETERS.FILTER);
    const patientSearch = generateParameters(patientFilters, FILTER_PARAMETERS.PATIENT);
    const search = generateParameters(searchFilter, FILTER_PARAMETERS.SEARCH);
    const url = `${GET_CASE_LIST_REPORT_CSV}?viewName=${viewName}&${sort}${filterString}${patientSearch}${search}`;
    const reportResponse = await AuthorizedHttpConnector.sendGet(url);
    downloadFile(reportResponse.data, `${reportName}.csv`);
}

async function downloadKitCsvReport(
    filters?: any,
    patientFilters?: any,
    sort?: string,
    searchFilter?: any
): Promise<void> {
    const dateFormatted = moment(new Date()).format("YYYY.MM.DD");
    const reportName = `${dateFormatted}_kitsToDispatch`;
    const filterString = generateParameters(filters, FILTER_PARAMETERS.FILTER);
    const patientSearch = generateParameters(patientFilters, FILTER_PARAMETERS.PATIENT);
    const search = generateParameters(searchFilter, FILTER_PARAMETERS.SEARCH);
    const url = `${GET_KIT_TO_DISPATCH_REPORT_CSV}?${sort}${filterString}${patientSearch}${search}`;
    const reportResponse = await AuthorizedHttpConnector.sendGet(url);
    downloadFile(reportResponse.data, `${reportName}.csv`);
}

async function uploadUserList(data: any) {
    try {
        const response = await AuthorizedHttpConnector.sendPost(UPLOAD_USER_LIST, data);
        return response.data;
    } catch (err: any) {
        if (err.response.data) {
            throw new Error(
                `${err?.response?.data?.errors[0]?.message};${
                    err.response.status === 409 ? "Please, update the .csv file and try again." : ""
                }`
            );
        }
        throw new Error("Unhandled API error");
    }
}

export default {
    downloadCasesListCsvReport,
    downloadKitCsvReport,
    downloadReport,
    getPreviewExport,
    requestGenerateCaseCsvReport,
    requestGenerateLesionsCsvReport,
    requestGenerateUsersCsvReport,
    uploadUserList,
    requestGenerateNextStepsCsvReport,
};
