import React, { FC } from "react";
import { Button, ButtonProps, Icon } from "semantic-ui-react";

import "scss/Icons.scss";

interface IContextIconButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, obj: ButtonProps) => void;
    disabled?: boolean;
    iconName: any;
    uuid?: string;
    arg?: any;
    content?: string;
}

const ContextIconButton: FC<IContextIconButtonProps> = ({ onClick, iconName, uuid, arg, disabled, content }) => (
    <Button disabled={disabled} className="row-button-icon" onClick={onClick} uuid={uuid} arg={arg}>
        <Icon name={iconName} />
        {content}
    </Button>
);

ContextIconButton.defaultProps = {
    disabled: false,
    uuid: "",
    arg: null,
    content: "",
};

export default ContextIconButton;
