import React, { FC } from "react";

import CustomButton from "components/templates/Button";
import ButtonCloseModal from "components/RemoteModel/modals/ButtonCloseModal";
import SupportEmailLink from "components/templates/SupportEmailLink";

import withModal from "HOC/withModal";

import { history } from "App";
import { LOGIN } from "navigation/routes";

interface IPreathorisationNumberErrorModal {
    onBackClicked: () => void;
}

const PreathorisationNumberErrorModal: FC<IPreathorisationNumberErrorModal> = ({ onBackClicked }) => {
    const handleLoginClicked = () => {
        history.push(LOGIN);
    };

    return (
        <div className="remote-model__modal remote-model__modal-preauth-error">
            <ButtonCloseModal onClick={onBackClicked} />
            <p className="h2">This pre-authorisation number is already associated with a skin checkup</p>
            <p className="p">
                <b>
                    If you are trying to continue a case that you have not finished or check the report for a completed
                    skin check, click below to login.
                </b>
            </p>
            <div className="buttons-wrapper">
                <CustomButton type="button" variant="filled" action={handleLoginClicked} text="Login" />
            </div>
            <p className="p">
                <b>
                    If you want to create a new case to have additional lesions assessed, you will need to get a new
                    valid pre-authorisation number from your private insurer.
                </b>
                <br />
                <br />
                Please note our service is only intended to assess a maximum of 3 lesions. If you are concerned about
                more than 3 lesions, please contact your NHS GP or insurance provider to discuss what alternative
                options are available.
                <br />
                <br />
                Otherwise please contact <SupportEmailLink />
            </p>
            <div className="buttons-wrapper">
                <CustomButton type="button" variant="empty-dark" action={onBackClicked} text="Back" />
            </div>
        </div>
    );
};

export default withModal(PreathorisationNumberErrorModal);
