import React, { FC } from "react";
import { Grid, Header, Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import RemoteModelContainer from "components/templates/RemoteModelContainer";

import { REMOTE_MODEL_CONSENT } from "navigation/remoteModelRoutes";

const StartCaseCreation: FC = () => (
    <RemoteModelContainer header="Start">
        <p>
            This is the homepage for your case. Here, you can provide information when needed and check the status of
            your case as it progresses through each step.
        </p>
        <Grid className="case-creation-information">
            <Grid.Row>
                <Grid.Column textAlign="center">
                    <Image alt="Box icon" src="/images/icons/icon-box.svg" />
                    <Header as="h3">Order your kit</Header>
                    <p className="case-creation-description-text">
                        Fill in your personal details (so we know where to send your skin check kit) and then your
                        medical history.
                        <br />
                        <br />
                        The kit will include a phone and a dermoscopic lens and will come in the post through your
                        letterbox (no need to sign).
                    </p>
                </Grid.Column>
                <Grid.Column textAlign="center">
                    <Image alt="Picture icon" src="/images/icons/icon-picture.svg" />
                    <Header as="h3">Take your images</Header>
                    <p className="case-creation-description-text">
                        After receiving your skin check kit, add your lesion details and follow the instructions to
                        capture images for the lesion(s) you’re concerned about.
                        <br />
                        <br />
                        After you submit the case, your images will be assessed within one working day by one of our
                        dermatologists.
                    </p>
                </Grid.Column>
                <Grid.Column textAlign="center">
                    <Image alt="Check icon" src="/images/icons/icon-check.svg" />
                    <Header as="h3">Get the results</Header>
                    <p className="case-creation-description-text">
                        You will be notified by phone or email when your case has been assessed and a report will be
                        available in the platform and as a PDF.
                        <br />
                        <br />
                        Once you have your result, use the pre-paid envelope to return the kit to us within 3 days, just
                        pop it in any post box.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <div className="buttons-wrapper">
            <CustomButton type="link" variant="filled" to={REMOTE_MODEL_CONSENT} text="Continue" />
        </div>
    </RemoteModelContainer>
);

export default StartCaseCreation;
