import React, { FC } from "react";
import clsx from "clsx";

interface IPatientNotes {
    note: string;
    isLatest: boolean;
}

const PatientNotes: FC<IPatientNotes> = ({ note, isLatest }) => {
    const patientNoteContainerClassName = clsx("patient-note", isLatest && "patient-note__latest");

    return (
        <p className={patientNoteContainerClassName}>
            <span className="patient-note__title">Notes for the Patient:</span>
            <span className="patient-note__text">&nbsp;&quot;{note}&quot;</span>
        </p>
    );
};

export default PatientNotes;
