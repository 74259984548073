import React, { FC } from "react";
import { Image, Header } from "semantic-ui-react";

import CaseSummaryTable from "components/RemoteModel/ReportAvailable/CaseSummaryTable";
import CallbackAgentRecommnedation from "components/RemoteModel/ReportAvailable/CallbackAgentRecommendation";
import PatientNotes from "components/RemoteModel/ReportAvailable/PatientNotes";

import { formatTimeDOB, formatTimeRemoteModel } from "helpers/datetime";

import { ICase } from "model/case";

interface IReferralRecommendation {
    currentCase: ICase;
}

const ReferralRecommendation: FC<IReferralRecommendation> = ({ currentCase }) => {
    const { reviews, patientNotes } = currentCase;
    const dermatologistPatientNote = patientNotes[0];
    const dermatologistReview = reviews[0];
    const { createdByName, creationDate = "", reviewManagement } = { ...dermatologistReview };
    const recommendationDate = formatTimeDOB(creationDate);
    const recommendationTime = formatTimeRemoteModel(creationDate);

    const withCallbackAgentReview = reviews.length > 1;
    const latestReview = withCallbackAgentReview ? reviews[reviews.length - 1] : dermatologistReview;
    const { refer: latestReviewRefer } = { ...latestReview };

    const recommendation = latestReviewRefer ? "In-person assessment recommended" : "No referral required";
    const dermatologistAssessmentTextBeginning = withCallbackAgentReview ? "Previously, o" : "O";
    const dermatologistAssessmentText = `${dermatologistAssessmentTextBeginning}n
        ${recommendationDate} at ${recommendationTime}, ${createdByName} labelled
        your case outcome as ${reviewManagement} and left a note:`;

    return (
        <div className="referral-recommendation">
            <Header as="h2">
                <div className="status remote-modal-case-status-active">
                    <div>
                        <div className="remote-modal-case-status-circle">
                            <Image alt="Status report available icon" src="/images/icons/status-report-available.svg" />
                        </div>
                    </div>
                </div>
                Referral Recommendation: {recommendation}
            </Header>

            {withCallbackAgentReview && (
                <CallbackAgentRecommnedation
                    reviews={reviews}
                    patientNotes={patientNotes}
                    previousReviewManagement={reviewManagement}
                />
            )}

            {creationDate && (
                <>
                    <p>{dermatologistAssessmentText}</p>
                    <CaseSummaryTable currentCase={currentCase} />
                </>
            )}

            {dermatologistPatientNote && (
                <PatientNotes note={dermatologistPatientNote.note} isLatest={!withCallbackAgentReview} />
            )}
        </div>
    );
};

export default ReferralRecommendation;
