import React, { FC, useState } from "react";
import { Button, Form, Grid, Table } from "semantic-ui-react";

import { IReviewEscalation } from "model/organisation";

import reviewManagementService from "services/reviewManagementService";

interface ICreateEscalationScreen {
    organisationUuid: string;
    onCreate: (data: IReviewEscalation) => void;
}

const CreateEscalationScreen: FC<ICreateEscalationScreen> = ({ organisationUuid, onCreate }) => {
    const [newEscalation, setNewEscalation] = useState<string>("");
    const [isFreeText, setIsFreeText] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const createNewEscalation = () => {
        if (newEscalation) {
            setSubmitting(true);
            reviewManagementService
                .createEscalation({
                    escalationPlan: newEscalation,
                    freeText: isFreeText,
                    organisationUuid,
                })
                .then((response: IReviewEscalation) => {
                    onCreate(response);
                });
        }
    };

    const onEscalationChange = (event: any, obj: any) => {
        setNewEscalation(obj.value);
    };

    const onIsFreeTextChange = (event: any, obj: any) => {
        setIsFreeText(obj.checked);
    };

    return (
        <div>
            <Form>
                <Table padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={6}>Escalation</Table.HeaderCell>
                            <Table.HeaderCell width={1} textAlign="center">
                                Free text
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1} />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row key="newEscalation" id="newEscalation" verticalAlign="top" textAlign="center">
                            <Table.Cell>
                                <Form.Input
                                    type="text"
                                    id="newEscalation"
                                    value={newEscalation}
                                    onChange={onEscalationChange}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Form.Checkbox type="checkbox" id="isFreeText" onChange={onIsFreeTextChange} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={16} />
                        <Grid.Column floated="right" width={3}>
                            <Button loading={submitting} onClick={createNewEscalation}>
                                Create
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </div>
    );
};

export default CreateEscalationScreen;
