import { FC } from "react";

export interface IOrganisationSettingLabel {
    label: string;
    htmlFor?: string;
    helpText?: string;
}

const OrganisationSettingLabel: FC<IOrganisationSettingLabel> = ({ label, htmlFor, helpText }) => {
    const LabelComponent = htmlFor ? "label" : "div";
    return (
        <div className="field">
            <LabelComponent htmlFor={htmlFor || null}>{label}</LabelComponent>
            {helpText ? (
                <p>
                    <small className="organisation-setting-help-text">{helpText}</small>
                </p>
            ) : null}
        </div>
    );
};

export default OrganisationSettingLabel;
