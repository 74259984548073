export enum OrganisationType {
    CLINIC = "CLINIC",
    STUDY = "STUDY",
}

export enum OrganisationTypeFilter {
    ALL = "All",
    CLINIC = "CLINIC",
    STUDY = "STUDY",
}
