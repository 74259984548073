import { FC, createContext, useReducer, ReactNode } from "react";
import moment from "moment";

import { AllocationConfigurationActionTypes } from "contextProviders/modules/allocationConfiguration/actions";
import { AuditConfigurationActionTypes } from "contextProviders/modules/auditConfiguration/actions";
import allocationConfigReducer from "contextProviders/modules/allocationConfiguration/reducer";
import auditConfigReducer from "contextProviders/modules/auditConfiguration/reducer";

import {
    allocationOrderIncrementRemap,
    getDefaultAllocationConfig,
    prependUrgentReferAllocationConfig,
    removeUrgentReferAllocationConfig,
    resetAllocationOrder,
    validateAllocationConfigurationListOrder,
} from "helpers/allocations";

import { AllocationConfigurationConsentType, IAllocationSettings } from "model/allocationConfig";
import { IAuditConfiguration, IAuditConfigurationState, IExtendedAuditConfiguration } from "model/auditConfig";
import LookBackPeriod from "model/lookBackPeriod";
import { AllocationValue, GuidanceValue, IAllocation } from "model/organisation";
import { FixedQuestion } from "model/fixedQuestion";

import organizationService from "services/organizationService";

export const INITIAL_FITZPATRICK_AUDIT_IDENTIFIER = FixedQuestion.FITZPATRICK_SKIN_TYPE;

/**
 * Audit function interfaces
 */
interface ISetAuditPercentage {
    uuid: string;
    percentage: number;
}

interface ISetAuditAllocation {
    uuid: string;
    allocation: AllocationValue;
}

/**
 * Allocation function interfaces
 */
interface ISetNewAllocation {
    modifiedAllocation: IAllocation;
    value?: number | string;
}

interface ISetNewPriority {
    modifiedAllocation: IAllocation;
    value?: string | number | boolean | (string | number | boolean)[];
}

interface ISetImmediateResults {
    modifiedAllocation: IAllocation;
    checked?: boolean;
}

interface StoreProps {
    children?: ReactNode;
}

// For use when the organisation has no audit configuration
const initialAuditConfiguration: IAuditConfigurationState = {
    enableAuditConfiguration: false,
    loading: false,
    showAutomatedDecisionConsentModal: false,
    showDisableAuditRoutingModal: false,
    isNew: true,
    isChanged: false,
    configuration: [
        {
            uuid: FixedQuestion.FITZPATRICK_SKIN_TYPE,
            name: FixedQuestion.FITZPATRICK_SKIN_TYPE,
            removed: false,
            percentage: 100,
            allocation: AllocationValue.CLIENT,
            guidanceValue: GuidanceValue.DISCHARGE,
            lookBackPeriod: LookBackPeriod.QUARTER,
            isChanged: true,
        },
    ],
};

const initialState: IAllocationSettings = {
    showUnconsentedCaseSettings: false,
    showDefaultLayout: true,
    showAutomatedDecisionConsent: false,
    consented: {
        allocations: [],
        loading: false,
        isNew: true,
        isChanged: false,
        savedConfiguration: [],
    },
    unconsented: {
        allocations: [],
        loading: false,
        isNew: true,
        isChanged: false,
        savedConfiguration: [],
    },
    enableUrgentRefer: false,
    showAllocationOrderModal: false,
    showUrgentReferModal: false,
};

interface ISetLoadedOrganisation {
    consentedAllocations: IAllocation[];
    unconsentedAllocations: IAllocation[];
    showAutomatedDecisionConsent: boolean;
    blockCasesWithoutAutomatedDecisionConsent: boolean;
    isNewConsented: boolean;
    isNewUnconsented: boolean;
    isChangedConsented: boolean;
    isChangedUnconsented: boolean;
    // temporary
    auditConfiguration: IAuditConfiguration[];
    enableAuditConfiguration: boolean;
}

interface IAllocationDispatchers {
    setLoading: (type: AllocationConfigurationConsentType) => void;
    setLoadedOrganisation: (settings: ISetLoadedOrganisation) => void;
    createAllocations: (type: AllocationConfigurationConsentType, organisationUuid: string) => Promise<void>;
    setNewAllocation: (data: ISetNewAllocation) => void;
    setImmediateResults: (data: ISetImmediateResults) => void;
    setNewPriority: (data: ISetNewPriority) => void;
    toggleAllocationOrderModal: () => void;
    toggleUrgentRefer: () => void;
    toggleUrgentReferModal: () => void;
    updateOrganisation: (allocations: IAllocation[]) => void;
}

interface IAuditDispatchers {
    toggleAuditConfiguration: (showAutomatedDecisionConsent: boolean) => void;
    toggleAuditConfigurationRow: (uuid: string) => void;
    setAuditCriteria: (ISetAuditPercentage) => void;
    setAuditAllocation: (ISetAuditAllocation) => void;
    createAuditConfiguration: (organisationUuid: string) => void;
    toggleAutomatedDecisionWarningModal: () => void;
    toggleDisableAuditRoutingModal: () => void;
    disableAuditRoutingAndConfigurations: () => void;
}

const AllocationConfigurationContext = createContext<{
    allocationState: IAllocationSettings;
    auditState: IAuditConfigurationState;
    allocationDispatchers: IAllocationDispatchers;
    auditDispatchers: IAuditDispatchers;
}>({
    allocationState: { ...initialState },
    auditState: { ...initialAuditConfiguration },
    allocationDispatchers: {} as IAllocationDispatchers,
    auditDispatchers: {} as IAuditDispatchers,
});

export const AllocationConfigurationProvider: FC<StoreProps> = ({ children }) => {
    const [allocationState, allocationActionDispatchers] = useReducer(allocationConfigReducer, initialState);
    const [auditState, auditActionDispatchers] = useReducer(auditConfigReducer, initialAuditConfiguration);

    const setLoadedOrganisation = ({
        consentedAllocations,
        unconsentedAllocations,
        showAutomatedDecisionConsent,
        blockCasesWithoutAutomatedDecisionConsent,
        isNewConsented,
        isNewUnconsented,
        isChangedConsented,
        isChangedUnconsented,
        auditConfiguration,
        enableAuditConfiguration,
    }: ISetLoadedOrganisation) => {
        let organisationAuditConfiguration: IAuditConfiguration[];
        const isUrgentReferEnabled: boolean = !isNewConsented && consentedAllocations?.length === 5;

        /**
         * If user has switched on urgent refer and saved the consented configuration,
         * but has navigated away from the page before saving the unconsented configuration,
         * we need to prepend the urgent refer config to any existing unconsented configuration
         */

        const prependedUnconsentedAllocations =
            isUrgentReferEnabled && unconsentedAllocations.length === 4
                ? prependUrgentReferAllocationConfig(unconsentedAllocations, true)
                : unconsentedAllocations;

        const unconsentedCasesAllocations =
            isUrgentReferEnabled && isChangedUnconsented
                ? prependedUnconsentedAllocations.map(allocationOrderIncrementRemap)
                : prependedUnconsentedAllocations;

        const hasExistingAuditConfiguration = auditConfiguration?.length > 0;
        /**
         * Check if all existing audit configurations have been removed
         * If they are, show the most recently modified audit configuration per type
         */
        const areAllExistingAuditConfigurationsRemoved =
            auditConfiguration?.filter((auditConfig) => auditConfig?.removed)?.length === auditConfiguration?.length;

        if (hasExistingAuditConfiguration && areAllExistingAuditConfigurationsRemoved) {
            // Find the most recently updated for each audit configuration name
            const configuration = auditConfiguration?.reduce(
                (prev, curr) => ({
                    [curr.name]: auditConfiguration
                        ?.sort((a, b) => {
                            const configA = a as IExtendedAuditConfiguration;
                            const configB = b as IExtendedAuditConfiguration;
                            return moment(configA.lastModificationDate) > moment(configB.lastModificationDate) ? 1 : -1;
                        })
                        .pop(),
                }),
                {}
            );
            organisationAuditConfiguration = Object.keys(configuration)?.map((key) => configuration[key]);
        } else if (hasExistingAuditConfiguration) {
            // Show only live configurations
            organisationAuditConfiguration = auditConfiguration?.filter((auditConfig) => !auditConfig.removed);
        } else {
            // Use the initial configuration
            organisationAuditConfiguration = initialAuditConfiguration?.configuration;
        }

        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.SET_LOADED_ORGANISATION,
            payload: {
                showAutomatedDecisionConsent,
                showUnconsentedCaseSettings: showAutomatedDecisionConsent && !blockCasesWithoutAutomatedDecisionConsent,
                showDefaultLayout: !showAutomatedDecisionConsent,
                consented: {
                    allocations: consentedAllocations,
                    isNew: isNewConsented,
                    savedConfiguration: consentedAllocations,
                    isChanged: isChangedConsented,
                },
                unconsented: {
                    allocations: unconsentedCasesAllocations,
                    isNew: isNewUnconsented,
                    savedConfiguration: unconsentedCasesAllocations,
                    isChanged: isChangedUnconsented,
                },
                enableUrgentRefer: isUrgentReferEnabled,
            },
        });

        auditActionDispatchers({
            type: AuditConfigurationActionTypes.SET_LOADED_ORGANISATION,
            payload: {
                isNew: !hasExistingAuditConfiguration,
                isChanged: false,
                loading: false,
                enableAuditConfiguration,
                showAutomatedDecisionConsentModal: false,
                showDisableAuditRoutingModal: false,
                configuration: organisationAuditConfiguration,
            },
        });
    };

    const setLoading = (type: AllocationConfigurationConsentType): void => {
        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.SET_LOADING,
            payload: { type, loading: true },
        });
    };

    const toggleAllocationOrderModal = (): void => {
        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.TOGGLE_ALLOCATION_ORDER_MODAL,
        });
    };

    const createAllocations = async (
        type: AllocationConfigurationConsentType,
        organisationUuid: string
    ): Promise<void> => {
        const {
            enableUrgentRefer,
            [type]: { allocations, hasAllocationOrderChanged, isNew },
        } = allocationState;

        if (
            enableUrgentRefer &&
            hasAllocationOrderChanged &&
            allocations.some((allocation) => !allocation.allocationOrder)
        ) {
            toggleAllocationOrderModal();
        } else {
            try {
                setLoading(type);
                const allocationsToSave: IAllocation[] = enableUrgentRefer
                    ? [...allocations]
                    : removeUrgentReferAllocationConfig(allocations).map((allocation) =>
                          resetAllocationOrder(
                              allocation,
                              getDefaultAllocationConfig(type === AllocationConfigurationConsentType.UNCONSENTED)
                          )
                      );

                validateAllocationConfigurationListOrder(allocationsToSave);

                const saveConfigFunc = isNew
                    ? organizationService.createAllocationConfig
                    : organizationService.updateAllocationConfig;

                const savedAllocations = await saveConfigFunc(organisationUuid, allocationsToSave);
                const { configuration } = savedAllocations;
                allocationActionDispatchers({
                    type: AllocationConfigurationActionTypes.CREATE_ALLOCATIONS,
                    payload: {
                        allocations: configuration,
                        type,
                    },
                });
            } catch (err: any) {
                const error = err.response ? err.response.data.errors : err.message;
                allocationActionDispatchers({
                    type: AllocationConfigurationActionTypes.ALLOCATION_CONFIG_ERROR,
                    payload: {
                        type,
                        error,
                    },
                });
            }
        }
    };

    const setNewAllocation = ({ modifiedAllocation, value }: ISetNewAllocation): void => {
        const {
            consented: { allocations },
            unconsented: { allocations: unconsentedCasesAllocations },
        } = allocationState;

        const getNewAllocations = (existingAllocations: IAllocation[]): IAllocation[] => {
            const newAllocations: IAllocation[] = existingAllocations.map((existingAllocation) => {
                const newAllocation: IAllocation = existingAllocation;
                if (modifiedAllocation.guidanceValue === newAllocation.guidanceValue) {
                    newAllocation.allocation = value as AllocationValue;
                    newAllocation.modified = true;
                }
                return newAllocation;
            });
            return newAllocations;
        };

        if (!modifiedAllocation.isForUnconsentedAutomatedDecisionCases) {
            const newAllocations = getNewAllocations(allocations);
            allocationActionDispatchers({
                type: AllocationConfigurationActionTypes.SET_NEW_ALLOCATION,
                payload: {
                    allocations: newAllocations,
                    type: AllocationConfigurationConsentType.CONSENTED,
                },
            });
            return;
        }
        const newAllocations = getNewAllocations(unconsentedCasesAllocations);
        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.SET_NEW_ALLOCATION,
            payload: {
                allocations: newAllocations,
                type: AllocationConfigurationConsentType.UNCONSENTED,
            },
        });
    };

    const setNewPriority = ({ modifiedAllocation, value }: ISetNewPriority) => {
        const {
            consented: { allocations },
            unconsented: { allocations: unconsentedCasesAllocations },
        } = allocationState;
        const newAllocationOrder = value as number;
        const getNewAllocations = (existingAllocations: IAllocation[]): IAllocation[] => {
            const newAllocations: IAllocation[] = existingAllocations.map((existingAllocation) => {
                const newAllocation: IAllocation = existingAllocation;
                if (newAllocation.allocationOrder === newAllocationOrder) {
                    newAllocation.allocationOrder = null;
                    newAllocation.modified = true;
                }
                if (modifiedAllocation.guidanceValue === newAllocation.guidanceValue) {
                    newAllocation.allocationOrder = newAllocationOrder;
                    newAllocation.modified = true;
                }
                return newAllocation;
            });
            return newAllocations;
        };
        if (!modifiedAllocation.isForUnconsentedAutomatedDecisionCases) {
            const newAllocations = getNewAllocations(allocations);
            allocationActionDispatchers({
                type: AllocationConfigurationActionTypes.SET_NEW_ALLOCATION,
                payload: {
                    allocations: newAllocations,
                    type: AllocationConfigurationConsentType.CONSENTED,
                    hasAllocationOrderChanged: true,
                },
            });
            return;
        }
        const newAllocations = getNewAllocations(unconsentedCasesAllocations);
        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.SET_NEW_ALLOCATION,
            payload: {
                allocations: newAllocations,
                type: AllocationConfigurationConsentType.UNCONSENTED,
                hasAllocationOrderChanged: true,
            },
        });
    };

    const setImmediateResults = ({ modifiedAllocation, checked }: ISetImmediateResults): void => {
        const {
            consented: { allocations },
            unconsented: { allocations: unconsentedCasesAllocations },
        } = allocationState;

        const getNewAllocations = (existingAllocations: IAllocation[]): IAllocation[] => {
            const newAllocations: IAllocation[] = existingAllocations.map((allocation) => {
                const newAllocation: IAllocation = allocation;
                if (modifiedAllocation.guidanceValue === newAllocation.guidanceValue) {
                    newAllocation.immediateResults = checked;
                    newAllocation.modified = true;
                }
                return newAllocation;
            });
            return newAllocations;
        };

        if (!modifiedAllocation.isForUnconsentedAutomatedDecisionCases) {
            const newAllocations = getNewAllocations(allocations);
            allocationActionDispatchers({
                type: AllocationConfigurationActionTypes.SET_IMMEDIATE_RESULTS,
                payload: {
                    allocations: newAllocations,
                    type: AllocationConfigurationConsentType.CONSENTED,
                },
            });
            return;
        }
        const newAllocations = getNewAllocations(unconsentedCasesAllocations);
        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.SET_IMMEDIATE_RESULTS,
            payload: {
                allocations: newAllocations,
                type: AllocationConfigurationConsentType.UNCONSENTED,
            },
        });
    };

    const toggleUrgentRefer = (): void => {
        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.TOGGLE_URGENT_REFER,
        });
    };

    const toggleUrgentReferModal = (): void => {
        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.TOGGLE_URGENT_REFER_MODAL,
        });
    };

    const updateOrganisation = (allocations: IAllocation[]): void => {
        allocationActionDispatchers({
            type: AllocationConfigurationActionTypes.UPDATE_ORGANISATION,
            payload: {
                allocations,
            },
        });
    };

    /**
     * Audit configuration functions
     */

    const setAuditLoading = (): void => {
        auditActionDispatchers({
            type: AuditConfigurationActionTypes.SET_LOADING,
            payload: { loading: true },
        });
    };

    const toggleAuditConfiguration = (showAutomatedDecisionConsent: boolean): void => {
        auditActionDispatchers({
            type: AuditConfigurationActionTypes.TOGGLE_AUDIT_CONFIGURATION,
            payload: {
                showAutomatedDecisionConsent,
            },
        });
    };

    const toggleAuditConfigurationRow = (uuid: string): void => {
        auditActionDispatchers({
            type: AuditConfigurationActionTypes.TOGGLE_INDIVIDUAL_AUDIT,
            payload: { uuid },
        });
    };

    const setAuditCriteria = ({ uuid, percentage }: ISetAuditPercentage): void => {
        auditActionDispatchers({
            type: AuditConfigurationActionTypes.SET_AUDIT_CRITERIA,
            payload: {
                uuid,
                percentage,
            },
        });
    };

    const setAuditAllocation = ({ uuid, allocation }: ISetAuditAllocation): void => {
        auditActionDispatchers({
            type: AuditConfigurationActionTypes.SET_AUDIT_ALLOCATION,
            payload: {
                uuid,
                allocation,
            },
        });
    };

    const createAuditConfiguration = async (organisationUuid: string): Promise<void> => {
        const { configuration, isNew, enableAuditConfiguration } = auditState;

        const configurationToSave = {
            allowAuditRouting: enableAuditConfiguration,
            auditConfiguration: configuration.filter((auditConfig) => auditConfig.isChanged),
        };

        try {
            // Hard coded for now as the audit configuration panel sits within the consented allocation block
            setAuditLoading();

            // Not required for now - we only have a single audit configuration
            // validateAllocationConfigurationListOrder(auditConfiguration);

            const saveConfigFunc = isNew
                ? organizationService.createAuditConfiguration
                : organizationService.updateAuditConfiguration;

            const savedAllocations = await saveConfigFunc(organisationUuid, configurationToSave);

            const { configuration: savedConfiguration } = savedAllocations;
            auditActionDispatchers({
                type: AuditConfigurationActionTypes.CREATE_AUDIT_CONFIGURATION,
                payload: {
                    configuration: savedConfiguration,
                },
            });
        } catch (err: any) {
            const error = err.response ? err.response.data.errors : err.message;
            auditActionDispatchers({
                type: AuditConfigurationActionTypes.AUDIT_CONFIG_ERROR,
                payload: {
                    error,
                },
            });
        }
    };

    const disableAuditRoutingAndConfigurations = () => {
        auditActionDispatchers({
            type: AuditConfigurationActionTypes.DISABLE_AUDIT_ROUTING_AND_CONFIGURATIONS,
        });
    };

    const toggleAutomatedDecisionWarningModal = () => {
        auditActionDispatchers({
            type: AuditConfigurationActionTypes.TOGGLE_AUTOMATED_DECISION_WARNING_MODEL,
        });
    };

    const toggleDisableAuditRoutingModal = () => {
        auditActionDispatchers({
            type: AuditConfigurationActionTypes.TOGGLE_DISABLE_AUDIT_ROUTING_MODAL,
        });
    };

    const allocationDispatchers = {
        setLoading,
        setLoadedOrganisation,
        createAllocations,
        setNewAllocation,
        setImmediateResults,
        setNewPriority,
        toggleAllocationOrderModal,
        toggleUrgentRefer,
        toggleUrgentReferModal,
        updateOrganisation,
    };

    const auditDispatchers = {
        toggleAuditConfiguration,
        toggleAuditConfigurationRow,
        setAuditCriteria,
        setAuditAllocation,
        createAuditConfiguration,
        disableAuditRoutingAndConfigurations,
        toggleAutomatedDecisionWarningModal,
        toggleDisableAuditRoutingModal,
    };

    return (
        <AllocationConfigurationContext.Provider
            value={{ allocationState, auditState, allocationDispatchers, auditDispatchers }}
        >
            {children}
        </AllocationConfigurationContext.Provider>
    );
};

AllocationConfigurationProvider.defaultProps = {
    children: null,
};

export default AllocationConfigurationContext;
