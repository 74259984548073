import React, { PureComponent } from "react";
import { Container, Form, Header, Message, Segment } from "semantic-ui-react";

import ResetPasswordModal from "components/ResetPasswordModal";
import CustomButton from "components/templates/Button";
import MessageDismissable from "components/templates/MessageDismissable";
import SupportEmailLink from "components/templates/SupportEmailLink";

import { INACTIVE_USER_ERROR } from "services/errorService";

import "scss/InputField.scss";
import "scss/LoginPage.scss";

interface ILoginScreenProps {
    changePasswordCompleted: boolean;
    errorInfo: any;
    signedIn: boolean;
    userError: any;
    visible: boolean;
    clearPassword: () => void;
    initResetPassword: (email: string) => void;
    login: (login: string, password: string) => void;
}

interface ILoginScreenState {
    detail: any;
    error: boolean;
    isValidEmail: false;
    loading: boolean;
    password: string | null;
    passwordResetEmailSent: boolean;
    sendEmail: string;
    username: string | null;
    visible: boolean;
}

class LoginScreen extends PureComponent<ILoginScreenProps, ILoginScreenState> {
    constructor(props: any) {
        super(props);

        const { errorInfo, visible } = this.props;

        this.state = {
            detail: errorInfo,
            error: visible,
            isValidEmail: false,
            loading: false,
            password: null,
            passwordResetEmailSent: false,
            sendEmail: props.sendEmail,
            username: null,
            visible: false,
        };

        this.requestIsSent = this.requestIsSent.bind(this);
    }

    private getErrorMessage() {
        const { visible: visibleState } = this.state;
        const { errorInfo, visible } = this.props;

        if (visibleState && errorInfo.load.length) {
            this.setState({ loading: false });

            return errorInfo.load.map((err: { message: any; details: any }) => {
                const errorText =
                    err.message === INACTIVE_USER_ERROR ? (
                        <p>
                            This is not a valid account. If you have any questions, please contact <SupportEmailLink />.
                        </p>
                    ) : (
                        err.message
                    );

                return (
                    <MessageDismissable
                        type={this.errorExists(err.details)}
                        title="LoginMessage"
                        text={errorText}
                        key="loginErrorMessage"
                        dismiss={this.removeVisibility}
                        visible={visible}
                    />
                );
            });
        }

        return <></>;
    }

    private renderMessage = () => {
        const { passwordResetEmailSent } = this.state;
        const { changePasswordCompleted } = this.props;

        const showMessage = passwordResetEmailSent || changePasswordCompleted;

        if (showMessage) {
            const message = passwordResetEmailSent
                ? "To reset your password, check your email and follow the link we’ve sent you."
                : "Your password has been changed. Please use your new password to log in";

            return (
                <Message title="Success Password has been changed" positive>
                    {message}
                </Message>
            );
        }

        return <></>;
    };

    private errorExists = (item: any) => {
        try {
            if (typeof item === "undefined") {
                return `SUCCESS`;
            }

            return `ERROR`;
        } catch (error) {
            return `ERROR`;
        }
    };

    private removeVisibility = () => {
        const { visible } = this.state;

        this.setState({ visible: !visible });
        this.setState({ error: false });
    };

    private submit = () => {
        const { login, clearPassword } = this.props;
        const { loading, username, password } = this.state;

        if (!loading) {
            this.setState({ loading: true, visible: true });

            login(username, password);

            clearPassword();
        }
    };

    private setField = (e: any, data: any) => {
        const { name, value } = data;

        this.setState((prevState) => ({ ...prevState, [name]: value.trim() }));
    };

    private resetPassword = () => {
        const { username } = this.state;
        const { initResetPassword } = this.props;

        initResetPassword(username);
    };

    private requestIsSent(passwordResetEmailSent: boolean) {
        this.setState({ visible: true, passwordResetEmailSent });
    }

    public render() {
        const { loading } = this.state;

        return (
            <Container className="login-container">
                <Segment>
                    <Header as="h1">Login</Header>
                    <Header as="h5">Welcome back. Let&apos;s get you logged in</Header>

                    {this.getErrorMessage()}
                    {this.renderMessage()}

                    <Form>
                        <Form.Field>
                            <Form.Input
                                width="16"
                                label="Your email address"
                                className="field login"
                                type="text"
                                placeholder="eg: user@example.com"
                                name="username"
                                onChange={this.setField}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                width="16"
                                label="Your password"
                                className="field login"
                                type="password"
                                placeholder="eg: ********"
                                name="password"
                                onChange={this.setField}
                            />
                        </Form.Field>

                        <div className="actions-container">
                            <CustomButton
                                type="submit"
                                loading={loading}
                                disabled={loading}
                                variant="filled"
                                action={this.submit}
                                text="Login"
                            />

                            <ResetPasswordModal requestIsSent={this.requestIsSent} />
                        </div>
                    </Form>
                </Segment>
            </Container>
        );
    }
}

export default LoginScreen;
