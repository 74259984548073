import { Component, FormEvent, SyntheticEvent } from "react";
import { CheckboxProps, DropdownProps, Form, InputOnChangeData, Table } from "semantic-ui-react";

import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";
import ContextIconButton from "components/templates/ContextIconButton";
import OrganisationManagementCheckbox from "components/templates/OrganisationManagementCheckbox";

import {
    getValidationPropertyOptions,
    PERSONAL_DATA_DISPLAY_ORDER_MAX_LENGTH,
    PERSONAL_DATA_VALUE_MAX_LENGTH,
    removeNullProperties,
    validateDisplayNamePersonalField,
    validateDisplayOrderPersonalField,
    validateIdentifierPersonalField,
    validateRequiredPersonalField,
} from "helpers/organisation";

import {
    IDefinedPersonalData,
    DefinedPersonalDataType,
    IDefinedValidationType,
    DefinedPersonalDataValidationText,
    IDefinedPersonalDataErrors,
    PersonalDataFieldCoreProperties,
} from "model/organisation";
import ViewEditMode from "model/viewEditMode";

import organizationService from "services/organizationService";

import "scss/InputField.scss";

interface IPersonalDataRowProps {
    onRemove: (obj: any) => void;
    definedPersonalData: IDefinedPersonalData[];
    personalData: IDefinedPersonalData;
    organisationUuid: string;
    parentOrganisationUuid?: string;
    isIntegrationEnabled: boolean;
    isEditable: boolean;
}

interface IPersonalDataRowState {
    mode: ViewEditMode;
    row: IDefinedPersonalData;
    errors: IDefinedPersonalDataErrors;
}

const CLEAN_ERRORS = {
    displayNameError: "",
    displayOrderError: "",
    identifierError: "",
    requiredError: "",
};

class PersonalDataRow extends Component<IPersonalDataRowProps, IPersonalDataRowState> {
    constructor(props: IPersonalDataRowProps) {
        super(props);
        const { personalData } = this.props;
        const copyOfPersonalData = { ...personalData };

        removeNullProperties(copyOfPersonalData);
        this.state = {
            mode: ViewEditMode.VIEW,
            row: copyOfPersonalData,
            errors: CLEAN_ERRORS,
        };
    }

    public componentDidUpdate(prevProps: IPersonalDataRowProps) {
        if (prevProps.personalData !== this.props.personalData) {
            const copyOfPersonalDataUpdate = { ...this.props.personalData };
            removeNullProperties(copyOfPersonalDataUpdate);
            this.setState({
                row: copyOfPersonalDataUpdate,
                errors: CLEAN_ERRORS,
            });
        }
    }

    private getRequiredField() {
        const {
            mode,
            row: { required },
            errors,
        } = this.state;
        const { requiredError } = errors;
        if (mode === ViewEditMode.EDIT) {
            return (
                <Form.Checkbox
                    name={PersonalDataFieldCoreProperties.required}
                    checked={required}
                    onChange={this.onFieldChange}
                    error={requiredError || false}
                />
            );
        }
        return <OrganisationManagementCheckbox isSelected={required} />;
    }

    private getRequestAtManualCreation() {
        const {
            mode,
            row: { isRequestedAtManualCreation },
            errors,
        } = this.state;
        const { requiredError } = errors;
        if (mode === ViewEditMode.EDIT) {
            return (
                <Form.Checkbox
                    name={PersonalDataFieldCoreProperties.isRequestedAtManualCreation}
                    checked={isRequestedAtManualCreation}
                    onChange={this.onFieldChange}
                    error={requiredError || false}
                />
            );
        }
        return <OrganisationManagementCheckbox isSelected={isRequestedAtManualCreation} />;
    }

    private getIdentifierField() {
        const {
            mode,
            row: { identifier },
            errors,
        } = this.state;
        const { identifierError } = errors;
        if (mode === ViewEditMode.EDIT) {
            return (
                <Form.Checkbox
                    name={PersonalDataFieldCoreProperties.identifier}
                    checked={identifier}
                    onChange={this.onFieldChange}
                    error={identifierError || false}
                />
            );
        }
        return <OrganisationManagementCheckbox isSelected={identifier} />;
    }

    private getPropertyNameField() {
        const { parentOrganisationUuid } = this.props;
        const {
            row: { propertyName, copyOfUuid, organisationUuid },
        } = this.state;

        return (
            <SettingsPropertyWrapper
                parentOrgUuid={parentOrganisationUuid}
                copyOfUuid={copyOfUuid}
                propertyOrganisationUuid={organisationUuid}
            >
                {propertyName}
            </SettingsPropertyWrapper>
        );
    }

    private getDisplayNameField() {
        const { mode, row, errors } = this.state;
        const { displayNameError } = errors;
        if (mode === ViewEditMode.EDIT) {
            return (
                <Form.Input
                    name={PersonalDataFieldCoreProperties.displayName}
                    className="normal"
                    value={row.displayName}
                    onChange={this.onFieldChange}
                    error={displayNameError || false}
                    maxLength={PERSONAL_DATA_VALUE_MAX_LENGTH}
                />
            );
        }
        return row.displayName;
    }

    private getIntegrationSourceSystem(): string {
        const {
            row: { integrationSourceSystem },
        } = this.state;

        return integrationSourceSystem;
    }

    private getIntegrationSourceProperty(): string {
        const {
            row: { integrationSourcePropertyName },
        } = this.state;

        return integrationSourcePropertyName;
    }

    private getDisplayOrderField() {
        const {
            mode,
            row,
            errors: { displayOrderError },
        } = this.state;
        if (mode === ViewEditMode.EDIT) {
            return (
                <Form.Input
                    name={PersonalDataFieldCoreProperties.displayOrder}
                    type="text"
                    className="small"
                    value={row.displayOrder}
                    onChange={this.onFieldChange}
                    error={displayOrderError || false}
                    maxLength={PERSONAL_DATA_DISPLAY_ORDER_MAX_LENGTH}
                />
            );
        }
        return row.displayOrder;
    }

    private getTypeField() {
        const {
            row: { type, options },
        } = this.state;

        if (type === DefinedPersonalDataType.OPTIONS && options) {
            return (
                <>
                    Options:
                    <br />
                    {options?.map((option: string) => (
                        <span className="type-options" key={option}>
                            {option}
                        </span>
                    ))}
                </>
            );
        }
        return type;
    }

    private getValidationField() {
        const {
            mode,
            row: { validationProperty, type },
        } = this.state;
        const { isIntegrationEnabled } = this.props;

        if (mode === ViewEditMode.EDIT && isIntegrationEnabled) {
            const validationPropertyOptions = getValidationPropertyOptions(type);

            return (
                <Form.Select
                    name="validationProperty"
                    className="validation-type"
                    onChange={this.onFieldChange}
                    value={validationProperty}
                    options={validationPropertyOptions}
                />
            );
        }

        return validationProperty ? DefinedPersonalDataValidationText[validationProperty] : "";
    }

    private validateDisplayOrder = (value: string) => {
        const { definedPersonalData } = this.props;
        const { errors } = this.state;
        const displayOrderError = validateDisplayOrderPersonalField(value, definedPersonalData);

        this.setState({ errors: { ...errors, displayOrderError } });
    };

    private validateIdentifier = (value: boolean) => {
        const { definedPersonalData } = this.props;
        const { errors, row } = this.state;
        const identyfierErrors = validateIdentifierPersonalField(value, row, definedPersonalData);

        this.setState({ errors: { ...errors, ...identyfierErrors } });
    };

    private validateRequired = (value: boolean) => {
        const { errors, row } = this.state;
        const requiredError = validateRequiredPersonalField(value, row);

        this.setState({ errors: { ...errors, requiredError } });
    };

    private validateDisplayName = (value: string) => {
        const { definedPersonalData } = this.props;
        const { errors } = this.state;
        const displayNameError = validateDisplayNamePersonalField(value, definedPersonalData);

        this.setState({ errors: { ...errors, displayNameError } });
    };

    private onFieldChange = (
        event: FormEvent<HTMLInputElement> | SyntheticEvent<HTMLElement, Event>,
        data: CheckboxProps | InputOnChangeData | DropdownProps
    ) => {
        const { row } = this.state;
        const { name, type, checked, value } = data;
        const fieldValue = type === "checkbox" ? checked : value;
        if (name === PersonalDataFieldCoreProperties.displayOrder) {
            this.validateDisplayOrder(fieldValue);
        }
        if (name === PersonalDataFieldCoreProperties.identifier) {
            this.validateIdentifier(Boolean(fieldValue));
        }
        if (name === PersonalDataFieldCoreProperties.required) {
            this.validateRequired(Boolean(fieldValue));
        }
        if (name === PersonalDataFieldCoreProperties.displayName) {
            this.validateDisplayName(fieldValue);
        }
        row[name] = fieldValue;
        this.setState({ row });
    };

    private onRemoveRow = (event: any, obj: any) => {
        const { onRemove } = this.props;
        const { row } = this.state;

        organizationService.removePersonalData(row.uuid).then(() => {
            onRemove(obj.uuid);
        });
    };

    private onEditRow = () => {
        this.setState({ mode: ViewEditMode.EDIT });
    };

    private onCancel = () => {
        const { personalData } = this.props;
        this.setState({ mode: ViewEditMode.VIEW, row: { ...personalData }, errors: CLEAN_ERRORS });
    };

    private onSubmit = () => {
        const {
            row: { uuid },
            errors: { displayOrderError, identifierError, requiredError, displayNameError },
        } = this.state;
        const personalDataRow = this.getPersonalDataRow();
        if (!displayOrderError && !identifierError && !requiredError && !displayNameError) {
            organizationService.updatePersonalData(uuid, personalDataRow).then((newRow) => {
                this.setState({ mode: ViewEditMode.VIEW, row: newRow });
                const { personalData } = this.props;
                personalData.identifier = personalDataRow.identifier;
                personalData.displayOrder = personalDataRow.displayOrder;
                personalData.displayName = personalDataRow.displayName;
                personalData.required = personalDataRow.required;
                personalData.isRequestedAtManualCreation = personalDataRow.isRequestedAtManualCreation;
                if (personalDataRow.integrationSourcePropertyName && personalDataRow.integrationSourceSystem) {
                    personalData.integrationSourcePropertyName = personalDataRow.integrationSourcePropertyName;
                    personalData.integrationSourceSystem = personalDataRow.integrationSourceSystem;
                }
            });
        }
    };

    private getPersonalDataRow = () => {
        const { row } = this.state;
        const dataRow = { ...row };
        const { validationProperty } = row;

        if (!validationProperty || validationProperty === IDefinedValidationType.none) {
            dataRow.validationProperty = undefined;
        }
        dataRow.displayOrder = Number(row.displayOrder);

        return dataRow;
    };

    public render() {
        const { row, mode } = this.state;
        const { organisationUuid, personalData, isEditable, isIntegrationEnabled } = this.props;
        const canEditPersonalData =
            !personalData.organisationUuid || personalData.organisationUuid === organisationUuid || isEditable;

        return (
            <Table.Row key={row.uuid}>
                <Table.Cell width={2}>{this.getPropertyNameField()}</Table.Cell>
                <Table.Cell width={2}>{this.getDisplayNameField()}</Table.Cell>
                <Table.Cell width={2}>{this.getRequestAtManualCreation()}</Table.Cell>
                <Table.Cell width={1}>{this.getDisplayOrderField()}</Table.Cell>
                <Table.Cell width={1}>{this.getTypeField()}</Table.Cell>
                <Table.Cell width={1}>{this.getRequiredField()}</Table.Cell>
                <Table.Cell width={1}>{this.getIdentifierField()}</Table.Cell>
                <Table.Cell width={1}>{this.getValidationField()}</Table.Cell>
                {isIntegrationEnabled && (
                    <>
                        <Table.Cell width={1}>{this.getIntegrationSourceSystem()}</Table.Cell>
                        <Table.Cell width={1}>{this.getIntegrationSourceProperty()}</Table.Cell>
                    </>
                )}
                <Table.Cell width={1}>
                    {mode === ViewEditMode.EDIT && (
                        <>
                            <ContextIconButton iconName="check square" onClick={this.onSubmit} />
                            <ContextIconButton iconName="trash" onClick={this.onRemoveRow} uuid={row.uuid} />
                            <ContextIconButton iconName="window close" onClick={this.onCancel} />
                        </>
                    )}
                    {mode === ViewEditMode.VIEW && canEditPersonalData && (
                        <ContextIconButton iconName="edit" onClick={this.onEditRow} />
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default PersonalDataRow;
