import React, { FC } from "react";
import { Grid, Table, Loader } from "semantic-ui-react";

import OverdueTableRow from "components/Administration/OrganisationManagment/ViewOrganisation/Overdue/OverdueTableRow";

import { IOrganisation, IOverdue, IOverdueUpdate } from "model/organisation";

interface IOverdueSettings {
    onSave: (overdueUpdate: IOverdueUpdate & { uuid: string }) => void;
    organisation: IOrganisation;
}

const OverdueSettings: FC<IOverdueSettings> = ({ organisation, onSave }) => {
    const { overdueDefinitions, parentUuid, uuid } = organisation;

    return (
        <div className="overdue-wrapper">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Table padded>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Overdue time limit</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Action</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {overdueDefinitions?.length !== 1 ? (
                                    overdueDefinitions
                                        .filter((overdueItem: IOverdue) => !overdueItem.removed)
                                        .map((overdueItem: IOverdue) => {
                                            const {
                                                caseStatus,
                                                overdueHours,
                                                overdueMinutes,
                                                enabled,
                                                uuid: overdueUuid,
                                                organisationUuid,
                                            } = overdueItem;

                                            return (
                                                <OverdueTableRow
                                                    onSave={onSave}
                                                    overdueStatus={caseStatus}
                                                    overdueHours={overdueHours}
                                                    overdueMinutes={overdueMinutes}
                                                    overdueEnabled={enabled}
                                                    key={overdueUuid}
                                                    uuid={overdueUuid}
                                                    organisationUuid={uuid}
                                                    inheritedFromParent={organisationUuid === parentUuid}
                                                    hasParent={!!parentUuid}
                                                />
                                            );
                                        })
                                ) : (
                                    <Table.Row>
                                        <Table.Cell />
                                        <Table.Cell>
                                            <Loader active inline="centered" size="medium" />
                                        </Table.Cell>
                                        <Table.Cell />
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default OverdueSettings;
