import { AllocationConfigurationConsentType, IAllocationTableSettings } from "model/allocationConfig";
import { AllocationValue, GuidanceValue, IAllocation, QuantifierValue } from "model/organisation";

const getDefaultAllocationConfig = (isForUnconsentedAutomatedDecisionCases: boolean): IAllocation[] =>
    isForUnconsentedAutomatedDecisionCases
        ? [
              {
                  allocation: AllocationValue.CLIENT,
                  isForUnconsentedAutomatedDecisionCases: true,
                  allocationOrder: 0,
                  guidanceValue: GuidanceValue.URGENT_REFER,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ANY,
              },
              {
                  allocation: AllocationValue.CLIENT,
                  isForUnconsentedAutomatedDecisionCases: true,
                  allocationOrder: 1,
                  guidanceValue: GuidanceValue.REFER,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ANY,
              },
              {
                  allocation: AllocationValue.CLIENT,
                  isForUnconsentedAutomatedDecisionCases: true,
                  allocationOrder: 2,
                  guidanceValue: GuidanceValue.EXCLUSION,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ANY,
              },
              {
                  allocation: AllocationValue.CLIENT,
                  isForUnconsentedAutomatedDecisionCases: true,
                  allocationOrder: 3,
                  guidanceValue: GuidanceValue.EXCEPTION,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ANY,
              },
              {
                  allocation: AllocationValue.CLIENT,
                  isForUnconsentedAutomatedDecisionCases: true,
                  allocationOrder: 4,
                  guidanceValue: GuidanceValue.DISCHARGE,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ALL,
              },
          ]
        : [
              {
                  allocation: AllocationValue.NONE,
                  isForUnconsentedAutomatedDecisionCases: false,
                  allocationOrder: 0,
                  guidanceValue: GuidanceValue.URGENT_REFER,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ANY,
              },
              {
                  allocation: AllocationValue.NONE,
                  isForUnconsentedAutomatedDecisionCases: false,
                  allocationOrder: 1,
                  guidanceValue: GuidanceValue.REFER,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ANY,
              },
              {
                  allocation: AllocationValue.NONE,
                  isForUnconsentedAutomatedDecisionCases: false,
                  allocationOrder: 2,
                  guidanceValue: GuidanceValue.EXCLUSION,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ANY,
              },
              {
                  allocation: AllocationValue.NONE,
                  isForUnconsentedAutomatedDecisionCases: false,
                  allocationOrder: 3,
                  guidanceValue: GuidanceValue.EXCEPTION,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ANY,
              },
              {
                  allocation: AllocationValue.NONE,
                  isForUnconsentedAutomatedDecisionCases: false,
                  allocationOrder: 4,
                  guidanceValue: GuidanceValue.DISCHARGE,
                  immediateResults: false,
                  removed: false,
                  quantifier: QuantifierValue.ALL,
              },
          ];

const filterAndSortAllocations = (allocations: IAllocation[], consentedAllocations: boolean): IAllocation[] =>
    allocations
        .filter(
            (allocation) =>
                !allocation.removed && allocation.isForUnconsentedAutomatedDecisionCases !== consentedAllocations
        )
        .sort((a, b) => a.allocationOrder - b.allocationOrder);

const prependUrgentReferAllocationConfig = (
    allocations: IAllocation[],
    isForUnconsentedAutomatedDecisionCases: boolean
): IAllocation[] => {
    const hasUrgentReferAllocationConfig: IAllocation = allocations.find(
        (allocation) => allocation.guidanceValue === GuidanceValue.URGENT_REFER
    );
    if (hasUrgentReferAllocationConfig) {
        return allocations;
    }
    const defaultAllocationConfig: IAllocation[] = getDefaultAllocationConfig(isForUnconsentedAutomatedDecisionCases);
    const urgentReferAllocationConfig: IAllocation = defaultAllocationConfig.find(
        (allocation) => allocation.guidanceValue === GuidanceValue.URGENT_REFER
    );
    if (!urgentReferAllocationConfig) {
        return allocations;
    }

    return [urgentReferAllocationConfig, ...allocations];
};

const removeUrgentReferAllocationConfig = (allocations: IAllocation[]): IAllocation[] =>
    allocations.filter((allocation: IAllocation) => allocation.guidanceValue !== GuidanceValue.URGENT_REFER);

const resetAllocationOrder = (allocation: IAllocation, savedConfiguration: IAllocation[]): IAllocation => ({
    ...allocation,
    allocationOrder: savedConfiguration.find(
        (defaultAllocation) => defaultAllocation.guidanceValue === allocation.guidanceValue
    ).allocationOrder,
});

const allocationOrderIncrementRemap = (allocation: IAllocation): IAllocation => ({
    ...allocation,
    allocationOrder: allocation.allocationOrder + 1,
});

const getAllAvailableAllocations = (): string[] => Object.keys(AllocationValue);

const setAllocationOrder = (
    { allocations, savedConfiguration }: IAllocationTableSettings,
    type: AllocationConfigurationConsentType
): IAllocation[] => {
    let predicate = (allocation) => resetAllocationOrder(allocation, savedConfiguration);
    let modifiedAllocations = allocations;
    if (savedConfiguration.length === 4) {
        modifiedAllocations = prependUrgentReferAllocationConfig(
            allocations,
            type === AllocationConfigurationConsentType.UNCONSENTED
        );
        predicate = allocationOrderIncrementRemap;
    }
    return modifiedAllocations.map(predicate);
};

const validateAllocationConfigurationListOrder = (configList: IAllocation[]): void => {
    const orderedConfigList = configList.sort((a, b) => a.allocationOrder - b.allocationOrder);
    orderedConfigList.forEach((config, index) => {
        if (config.allocationOrder !== index + 1) {
            throw new Error("Allocation configuration order is invalid");
        }
    });
};

export {
    getAllAvailableAllocations,
    getDefaultAllocationConfig,
    filterAndSortAllocations,
    prependUrgentReferAllocationConfig,
    removeUrgentReferAllocationConfig,
    resetAllocationOrder,
    allocationOrderIncrementRemap,
    setAllocationOrder,
    validateAllocationConfigurationListOrder,
};
