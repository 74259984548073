import React, { Component } from "react";
import { Icon, Table, Button } from "semantic-ui-react";

import { IDevice } from "model/study";

interface IDeviceRowProps {
    device: IDevice;
    onDeviceDelete: () => void;
}

interface IDeviceRowState {
    row: IDevice;
}

class DeviceRow extends Component<IDeviceRowProps, IDeviceRowState> {
    constructor(props: any) {
        super(props);

        const { device } = this.props;

        this.state = {
            row: device,
        };
    }

    public render() {
        const { row } = this.state;
        const { onDeviceDelete } = this.props;

        return (
            <Table.Row key={row.uuid}>
                <Table.Cell width={2}>{row.manufacturer}</Table.Cell>
                <Table.Cell width={2}>{row.model}</Table.Cell>
                <Table.Cell width={2}>{row.version}</Table.Cell>
                <Table.Cell width={2}>{row.osVersion}</Table.Cell>
                <Table.Cell width={1}>{row.useForAi ? <Icon name="check" /> : <Icon name="cancel" />}</Table.Cell>
                <Table.Cell width={2}>{row.infoText}</Table.Cell>
                <Table.Cell width={2}>
                    <Button size="tiny" color="red" onClick={onDeviceDelete}>
                        Delete
                    </Button>
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default DeviceRow;
