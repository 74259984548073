import React, { Component } from "react";
import { Form, Message } from "semantic-ui-react";

import { onChangeWhitespaceRemover, onPasteWhitespaceRemover } from "helpers/field";

import { IHistoryAnswerType, IHistoryQuestion, IHistoryQuestionOptions } from "model/organisation";
import { IQuestionaryError } from "model/questionaryError";

import "scss/Questionary.scss";

interface IQuestionaryFormElement {
    question: IHistoryQuestion;
    onChange: (e: any, data: any) => void;
    required?: boolean;
    formErrors?: IQuestionaryError | undefined;
    id?: string;
    isBack?: boolean;
    answer?: any;
    optionalNotes?: boolean;
}
class QuestionaryFormElement extends Component<IQuestionaryFormElement> {
    public getIsError = () => {
        const { formErrors, question } = this.props;
        if (formErrors) {
            const { list, showError } = formErrors;
            if (list && showError) {
                const isItemError = list.find((itemError: string) => itemError.includes(question.uuid));
                return !!isItemError;
            }
        }
        return false;
    };

    public render() {
        const { onChange, question, isBack, answer, optionalNotes = true } = this.props;
        const isError = this.getIsError();

        if (question.answerType === IHistoryAnswerType.TEXT) {
            const value = answer?.value || "";
            return (
                <Form id={`${question.uuid}.form`} error={isError}>
                    <Form.TextArea
                        error={isError}
                        required
                        disabled={isBack}
                        type="text"
                        id={question.uuid}
                        onChange={onChange}
                        onPaste={onPasteWhitespaceRemover(onChange, true, {
                            type: "text",
                            id: question.uuid,
                        })}
                        value={value}
                        className="questionary-response-row"
                    />
                    {isError && <Message error content="This question is required" />}
                </Form>
            );
        }
        if (question.answerType === IHistoryAnswerType.CHECKBOX || question.answerType === IHistoryAnswerType.OPTIONS) {
            const { options = [] } = question;
            const keys = Object.keys(answer && answer.value ? answer.value : {});
            const selectedValues = keys.filter((key) => answer.value[key]);
            const { additionalInformation = "" } = { ...answer };

            return (
                <Form id={`${question.uuid}.form`} error={isError}>
                    <div className="questionary-response-row">
                        <div className="questionary-response-options">
                            {options.map((optionObj: IHistoryQuestionOptions) => {
                                const { option } = optionObj;
                                const key = `${question.uuid}.${option}`;

                                return (
                                    <Form.Checkbox
                                        disabled={isBack}
                                        id={key}
                                        key={key}
                                        label={option}
                                        onChange={onChangeWhitespaceRemover(onChange, true)}
                                        questionuuid={question.uuid}
                                        error={!!isError}
                                        checked={selectedValues.includes(option)}
                                        className="questionary-item"
                                    />
                                );
                            })}
                        </div>

                        {optionalNotes && (
                            <div className="notes-wrapper">
                                <div className="questions-optional-information">
                                    <Form.TextArea
                                        disabled={isBack}
                                        type="extra"
                                        id={question.uuid}
                                        onChange={onChange}
                                        onPaste={onPasteWhitespaceRemover(onChange, true, {
                                            type: "extra",
                                            questionuuid: question.uuid,
                                        })}
                                        questionuuid={question.uuid}
                                        value={answer ? additionalInformation : ""}
                                        placeholder="Optional notes"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {isError && <Message error content="This question is required" />}
                </Form>
            );
        }
        if (question.answerType === IHistoryAnswerType.OPTION) {
            const { options = [] } = question;
            const selected = answer ? `${question.uuid}.${answer.value}` : "";
            const { additionalInformation = "" } = { ...answer };

            return (
                <Form id={`${question.uuid}.form`} error={isError}>
                    <div className="questionary-response-row">
                        <div className="questionary-response-options">
                            {options.map((optionObj: IHistoryQuestionOptions) => {
                                const { option } = optionObj;
                                const keyOption = `${question.uuid}.${option}`;
                                const isChecked = selected === `${question.uuid}.${option}`;

                                return (
                                    <Form.Radio
                                        disabled={isBack}
                                        id={keyOption}
                                        key={keyOption}
                                        label={option}
                                        onChange={onChange}
                                        questionuuid={question.uuid}
                                        checked={isChecked}
                                        error={!!isError}
                                        className="questionary-item"
                                    />
                                );
                            })}
                        </div>
                        {optionalNotes && (
                            <div className="notes-wrapper">
                                <div className="questions-optional-information">
                                    <Form.TextArea
                                        disabled={isBack}
                                        type="extra"
                                        id={question.uuid}
                                        onChange={onChange}
                                        onPaste={onPasteWhitespaceRemover(onChange, true, {
                                            type: "extra",
                                            questionuuid: question.uuid,
                                        })}
                                        questionuuid={question.uuid}
                                        value={answer ? additionalInformation : ""}
                                        placeholder="Optional notes"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {isError && <Message error content="This question is required" />}
                </Form>
            );
        }

        return null;
    }
}

export default QuestionaryFormElement;
