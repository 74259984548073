function removeNullPropertiesFromRequest<T>(data: Record<string, unknown>): T {
    const keys = Object.keys(data);
    const updatedRequestData = {} as T;
    keys.forEach((key) => {
        const value = data[key];
        if (value !== null) {
            updatedRequestData[key] = value;
        }
    });
    return updatedRequestData;
}

export default removeNullPropertiesFromRequest;
