import { FC, useState, useContext, useEffect, ReactNode } from "react";
import { Button, Form, List, ListItem, Message, Modal, Segment } from "semantic-ui-react";

import CaseReviewList from "components/CaseDescription/Review/CaseReviewList";
import ReviewNote from "components/CaseDescription/Review/ReviewNote";
import useCaseNote from "components/CaseDescription/hooks/useCaseNote";
import NoteExceedsLimitWarning from "components/Notes/NoteExceedsLimitWarning";

import { ModalContext } from "contextProviders/ModalProvider";

import { noteExceedsLimitTextfieldStyle, noteLimit } from "helpers/noteValidation";
import { onPasteWhitespaceRemover } from "helpers/field";
import { buildQuestionsHierarchy } from "helpers/administrativeNextSteps";

import useAutoLogout from "hooks/useAutoLogout";

import { IAdminNextStepQuestion } from "model/administrativeNextSteps";
import { ILesion, IPatient, IReviewNote } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { CaseViewMode } from "model/caseViewMode";
import { INITIAL_REQUEST_STATUS, IRequestStatus } from "model/common";
import { NoteType, NoteTypeTitle } from "model/reviewNotes";

import { history } from "App";
import { HOME } from "navigation/routes";

import * as caseService from "services/caseService";

interface IAddNotesModal {
    type: NoteType;
    caseUuid: string;
    caseId?: string;
    patient?: IPatient;
    patientUuid: string;
    updateCaseStatus?: (data: any, type: string) => void;
    updateReviewedCasesTab?: (sortByCreationDate?: string) => void;
    callback?: () => void;
    updateCaseNotes?: (type: NoteType, note: string) => void;
    lesions?: ILesion[];
    clinicalNotes?: IReviewNote[];
    managementOutcome?: string;
    updateCaseListItemStatus: (uuid: string, newStatus: CaseStatus) => void;
    remote?: boolean;
    administrativeNextSteps?: IAdminNextStepQuestion[];
}

const REFETCH_CASE_INTERVAL = 5000;
const NO_ANSWER_TEXT = "Not answered";

const AddNotesModal: FC<IAddNotesModal> = ({
    type,
    caseId,
    caseUuid,
    patient,
    patientUuid,
    updateCaseStatus,
    updateReviewedCasesTab,
    callback,
    updateCaseNotes,
    lesions,
    managementOutcome,
    clinicalNotes,
    updateCaseListItemStatus,
    remote,
    administrativeNextSteps,
}) => {
    useAutoLogout();
    const [requestStatus, setRequestStatus] = useState<IRequestStatus>(INITIAL_REQUEST_STATUS);
    const [intervalId, setIntervalId] = useState<number>(0);
    const { closeModal } = useContext(ModalContext);
    const { caseNote, handleCaseNoteChange, submitCaseNote } = useCaseNote({
        caseUuid,
        patientUuid,
        noteType: type,
        updateCaseNotes,
    });
    const { caseNote: clinicalNote } = useCaseNote({
        caseUuid,
        patientUuid,
        noteType: NoteType.CLINICAL,
        updateCaseNotes,
    });

    useEffect(() => () => window.clearInterval(intervalId), [intervalId]);

    const withReviewSummary = type === NoteType.PATIENT;
    const withCaseNote = !withReviewSummary || remote;

    const handleAddNoteClick = async () => {
        if (requestStatus.isSuccess) {
            closeModal();
        } else {
            setRequestStatus({ ...requestStatus, isPending: true });
            try {
                if (withCaseNote) {
                    const { data: noteData } = await submitCaseNote();
                    updateCaseStatus?.(noteData.data, type);
                }

                if (callback) {
                    await callback();

                    const intervalUuid = window.setInterval(() => {
                        caseService
                            .getCaseAsync(caseUuid, ["caseStatus", "assignment", "reviews"])
                            .then((response: any) => {
                                const { caseStatus } = response;
                                if (
                                    caseStatus !== CaseStatus.CASE_IN_REVIEW &&
                                    caseStatus !== CaseStatus.CASE_IN_SA_REVIEW
                                ) {
                                    updateCaseListItemStatus(caseUuid, caseStatus);
                                    updateReviewedCasesTab();
                                    closeModal();
                                    history.push(HOME);
                                }
                            });
                    }, REFETCH_CASE_INTERVAL);
                    setIntervalId(intervalUuid);
                } else {
                    setRequestStatus({ ...requestStatus, isSuccess: true, isPending: false });
                }
            } catch (err: any) {
                const errorDetails = err.response?.data.errors || [{ message: err.message }];
                setRequestStatus({
                    ...requestStatus,
                    error: errorDetails[0].message,
                    isPending: false,
                });
            }
        }
    };

    const getAdminNextStepsAnswer = (answer: string | string[]): string => {
        if (typeof answer === "string" && !!answer) {
            return answer;
        }

        if (Array.isArray(answer) && answer.length) {
            return `${answer.join(", ")}`;
        }

        return NO_ANSWER_TEXT;
    };

    const { isPending, isSuccess, error } = requestStatus;
    const textareaLabel = NoteTypeTitle[type];
    const headerTitle = withReviewSummary ? "Summary" : `Add ${textareaLabel} for current case`;
    const showMessage = isSuccess || error;
    const messageText = isSuccess ? "Note added successfully" : error;
    const isButtonActive = !withCaseNote || (caseNote.length > 0 && !isPending);
    const noteOverLimit = caseNote.length > noteLimit || clinicalNote.length > noteLimit;
    const initialButtonText = withReviewSummary ? "Submit" : "Add notes";
    const buttonText = isSuccess ? "Done" : initialButtonText;
    const adminNextStepsMap: Map<IAdminNextStepQuestion, IAdminNextStepQuestion[]> =
        buildQuestionsHierarchy(administrativeNextSteps);

    const getStyledAnswer = (answer: string | string[]): ReactNode => {
        const answerValue = getAdminNextStepsAnswer(answer);
        return answerValue !== NO_ANSWER_TEXT ? (
            <span className="answer">{answerValue}</span>
        ) : (
            <span className="no-answer">{answerValue}</span>
        );
    };

    return (
        <>
            <Modal.Header>{headerTitle}</Modal.Header>
            <Modal.Content>
                <Form>
                    {withReviewSummary && (
                        <>
                            <CaseReviewList
                                caseId={caseId}
                                patient={patient}
                                mode={CaseViewMode.REVIEWVIEW}
                                lesions={lesions}
                                managementOutcome={managementOutcome}
                            />
                            {clinicalNotes && (
                                <ReviewNote
                                    title={NoteTypeTitle.CLINICAL}
                                    subtitle="These notes will be included in the clinical report and will be visible to any clinician involved in the assessment"
                                    notes={clinicalNotes}
                                />
                            )}
                        </>
                    )}
                    {withCaseNote && (
                        <>
                            <Form.TextArea
                                style={noteExceedsLimitTextfieldStyle(caseNote)}
                                label={textareaLabel}
                                placeholder="Enter note here..."
                                onChange={handleCaseNoteChange}
                                onPaste={onPasteWhitespaceRemover(handleCaseNoteChange, true)}
                                value={caseNote}
                                required
                                disabled={isSuccess}
                            />
                            <NoteExceedsLimitWarning padding={0} note={caseNote} />
                        </>
                    )}

                    {administrativeNextSteps?.length > 0 ? (
                        <>
                            <h4>Next steps for admins</h4>
                            <p>
                                Only the questions answered will be included in the clinical report and will be visible
                                to your team.
                            </p>
                            <div>
                                {[...adminNextStepsMap.keys()].map((parent) => (
                                    <Segment key={parent.questionUuid} className="admin-next-steps-item">
                                        <span className="question">
                                            {parent.question}: {getStyledAnswer(parent.answer)}
                                        </span>
                                        {adminNextStepsMap.get(parent)?.length ? (
                                            <List bulleted className="child-question-list">
                                                {adminNextStepsMap.get(parent).map((child) => (
                                                    <ListItem key={child.questionUuid}>
                                                        <span className="question">{child.question}:</span>
                                                        {getStyledAnswer(child.answer)}
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : null}
                                    </Segment>
                                ))}
                            </div>
                        </>
                    ) : null}

                    {showMessage && <Message color={isSuccess ? "green" : "red"}>{messageText}</Message>}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {withReviewSummary && (
                    <Button onClick={closeModal} negative>
                        Back
                    </Button>
                )}
                <Button
                    primary
                    disabled={!isButtonActive || noteOverLimit}
                    onClick={handleAddNoteClick}
                    loading={isPending}
                >
                    {buttonText}
                </Button>
            </Modal.Actions>
        </>
    );
};

AddNotesModal.defaultProps = {
    caseId: "",
    patient: null,
    updateCaseStatus: () => undefined,
    updateReviewedCasesTab: () => undefined,
    callback: undefined,
    updateCaseNotes: () => undefined,
    lesions: [],
    clinicalNotes: [],
    managementOutcome: "",
    remote: false,
    administrativeNextSteps: [],
};

export default AddNotesModal;
