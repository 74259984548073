import { FC } from "react";
import { Dropdown, Grid, StrictGridColumnProps } from "semantic-ui-react";

import CopyIcon from "components/templates/CopyIcon";

interface IPatientSearchExpandedViewItem {
    title: string;
    width?: StrictGridColumnProps["width"];
    value: string | string[];
    minorPatientIdentifier?: string;
}

const PatientSearchExpandedViewItem: FC<IPatientSearchExpandedViewItem> = ({
    title,
    value,
    width,
    minorPatientIdentifier,
}) => {
    const isValueArray = Array.isArray(value);

    const options = isValueArray
        ? value.map((item) => ({
              key: item,
              text: item,
              value: item,
              content: (
                  <div className="patient-search-expanded-view__select-item">
                      {item}
                      <CopyIcon valueToCopy={item} title="minor identifier" />
                  </div>
              ),
          }))
        : [];

    return (
        <Grid.Column className="patient-search-expanded-view__wrapper" width={width}>
            <div className="patient-search-expanded-view__title">{title}</div>
            {isValueArray ? (
                <div className="patient-search-expanded-view__select">
                    <Dropdown selection fluid options={options} value={minorPatientIdentifier} />
                </div>
            ) : (
                <div className="patient-search-expanded-view__content">{value || ""}</div>
            )}
        </Grid.Column>
    );
};

PatientSearchExpandedViewItem.defaultProps = {
    width: undefined,
    minorPatientIdentifier: "",
};

export default PatientSearchExpandedViewItem;
