import { IContactPreferences } from "model/case";
import { CaseStatus } from "model/caseStatus";

export enum KitDeliveryStatuses {
    TO_DISPATCH = "TO_DISPATCH",
    DISPATCHED = "DISPATCHED",
    DELIVERED = "DELIVERED",
}

export const COMPLETED_STATUSES = [CaseStatus.CASE_COMPLETED, CaseStatus.CASE_CLOSED];

export interface IMobileNumberValidation {
    isError: boolean;
    message?: string;
}

export interface IPatientForm {
    name: string;
    surname: string;
    postCode: string;
    mobileNumber: string;
    verifiedMobileNumber: string;
    gender: string;
    addressInput: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    country: string;
    contactPreferences: IContactPreferences;
}

export enum CallbackOutcomesText {
    PATIENT_CONTACTED = "Patient contacted",
    VOICEMAIL_LEFT = "Voicemail left",
    PHONE_BUSY = "Phone busy",
    NO_ANSWER = "No answer",
    CHANGE_MANAGEMENT_OUTCOME = "Change management outcome",
}

export enum CallbackOutcomesValues {
    PATIENT_CONTACTED = "PATIENT_CONTACTED",
    VOICEMAIL_LEFT = "VOICEMAIL_LEFT",
    PHONE_BUSY = "PHONE_BUSY",
    NO_ANSWER = "NO_ANSWER",
    CHANGE_MANAGEMENT_OUTCOME = "CHANGE_MANAGEMENT_OUTCOME",
}

export interface IKitTracking {
    uuid: string;
    caseUuid: string;
    trackOutNo: string | null;
    trackInNo?: string;
    shippedOutDate: Date | null;
    shippedInDate?: Date;
}

export enum KitTrackingDictionary {
    trackOutNo = "Track out number",
    trackInNo = "Track in number",
    trackOut = "Track out number",
    trackIn = "Track in number",
}
