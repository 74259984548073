import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import URLs from "services/urls";

const { GET_AUDITLOG_BY_CASE_ID } = URLs;

async function getAuditlogById(caseId: string) {
    try {
        const response = await AuthorizedHttpConnector.sendGet(GET_AUDITLOG_BY_CASE_ID.replace("{0}", caseId));
        return response.data;
    } catch (err: any) {
        return err.response.data;
    }
}

export default {
    getAuditlogById,
};
