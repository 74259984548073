import React, { FC, useState, useContext, useRef, useEffect, MouseEvent as ReactMouseEvent } from "react";
import { Button, Header, Icon, List, ListItemProps, Ref, Segment } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { isColumnAvailable } from "helpers/caseList";

import { CaseListColumns, ICaseListSettingItem } from "model/caseList";
import { UserRole } from "model/userRole";

import userService from "services/userService";

import "scss/CaseList.scss";

interface ICaseListSettingsProps {
    columns: ICaseListSettingItem[];
    onSaveColumns: (currentColumns: ICaseListSettingItem[]) => void;
    userRole: UserRole;
    settingsMenuOpened: boolean;
    onClickOutside: () => void;
    triggerRef: any;
}

const CaseListSettings: FC<ICaseListSettingsProps> = ({
    columns,
    onSaveColumns,
    userRole,
    settingsMenuOpened,
    onClickOutside,
    triggerRef,
}) => {
    const { getDefaultColumns } = useContext(CaseListContext);
    const ref = useRef<any>();
    const [currentColumns, setCurrentColumns] = useState<ICaseListSettingItem[]>([]);

    useEffect(() => {
        setCurrentColumns(columns);
    }, [columns]);

    useEffect(() => {
        const clickHandler = (event: any) => {
            if (!event.target || !ref.current) {
                return;
            }

            const findParent = (parent: any, element: any) => {
                const findElement = (el: any): boolean => {
                    if (el === parent) {
                        return true;
                    }
                    if (!el) {
                        return false;
                    }

                    return findElement(el.parentElement);
                };

                return findElement(element);
            };

            if (findParent(triggerRef.current, event.target)) {
                return;
            }

            if (!findParent(ref.current, event.target)) {
                onClickOutside();
            }
        };
        window.addEventListener("click", clickHandler);

        return () => {
            window.removeEventListener("click", clickHandler);
        };
    }, [ref.current, settingsMenuOpened, triggerRef.current]);

    const stopPropagation = (event: any) => event.stopPropagation();

    const isSuperAdmin = userService.checkUserHasRole([UserRole.SUPERADMIN, UserRole.SA_ADMIN]);

    const getUpdatedColumns = (name: string): ICaseListSettingItem[] => {
        const isUncheckableColumns =
            !isSuperAdmin &&
            (name === CaseListColumns.ID ||
                name === CaseListColumns.PATIENT ||
                name === CaseListColumns.STATUS ||
                name === CaseListColumns.ACTION);
        if (!isUncheckableColumns) {
            const updatedColumns = currentColumns.map((column) => {
                if (column.name === name) {
                    return { ...column, display: !column.display };
                }
                return column;
            });
            return updatedColumns;
        }
        return [];
    };

    const handleItemKeyPressed = (name: string): void => {
        onSaveColumns(getUpdatedColumns(name));
    };

    const handleItemClicked = (e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>, data?: ListItemProps): void => {
        const { name } = data;
        onSaveColumns(getUpdatedColumns(name));
    };

    const handleResetClick = () => {
        const sortedColumns = getDefaultColumns(userRole);
        setCurrentColumns(sortedColumns);
        onSaveColumns(sortedColumns);
    };

    const getUpdatedArray = (from: number, to: number) => {
        const arr = [...currentColumns];
        const cutOut = arr.splice(from, 1)[0];
        arr.splice(to, 0, cutOut);
        const result = arr.map((item, index) => ({ ...item, order: index }));
        return result;
    };

    const onDragEnd = (result: DropResult) => {
        const {
            destination,
            source: { index: sourceIndex },
        } = result;
        const { index: destIndex = 0 } = { ...destination };
        const updatedColumns = getUpdatedArray(sourceIndex, destIndex);
        setCurrentColumns(updatedColumns);
        onSaveColumns(updatedColumns);
    };

    const getItemClassName = (isDragging: boolean) => (isDragging ? "draggable" : "");

    if (!settingsMenuOpened) {
        return null;
    }

    return (
        <div ref={ref} role="button" onKeyPress={stopPropagation} onClick={stopPropagation} tabIndex={0}>
            <Segment className="settings-portal">
                <Header>Column settings</Header>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <Ref innerRef={provided.innerRef}>
                                <List divided relaxed {...provided.droppableProps}>
                                    {currentColumns.map((column: ICaseListSettingItem, index: number) => {
                                        const { name: columnName, display } = column;
                                        const icon =
                                            !isSuperAdmin &&
                                            (columnName === CaseListColumns.ID ||
                                                columnName === CaseListColumns.PATIENT ||
                                                columnName === CaseListColumns.STATUS ||
                                                columnName === CaseListColumns.ACTION)
                                                ? "lock"
                                                : "check";
                                        const iconName = display ? icon : undefined;
                                        const isAvailable = isColumnAvailable(columnName);

                                        if (
                                            (!isSuperAdmin && columnName === CaseListColumns.SAS_LINK) ||
                                            !isAvailable
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <Draggable key={columnName} draggableId={columnName} index={index}>
                                                {(providedItem, snapshot) => (
                                                    <Ref innerRef={providedItem.innerRef}>
                                                        <List.Item
                                                            {...providedItem.draggableProps}
                                                            {...providedItem.dragHandleProps}
                                                            onClick={handleItemClicked}
                                                            onKeyPress={(e) =>
                                                                handleItemKeyPressed(e.target.getAttribute("name"))
                                                            }
                                                            name={columnName}
                                                            className={getItemClassName(snapshot.isDragging)}
                                                        >
                                                            <Icon name={iconName} />
                                                            {columnName}
                                                        </List.Item>
                                                    </Ref>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </List>
                            </Ref>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button
                    onBlur={() => triggerRef.current.focus()}
                    onClick={handleResetClick}
                    className="case-settings-reset-to-default-button"
                >
                    Reset to default
                </Button>
            </Segment>
        </div>
    );
};

export default CaseListSettings;
