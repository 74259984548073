import { IManagementOptions, IOrganisation, IReviewDiagnosis } from "model/organisation";
import { ReviewSettingMode } from "model/reviewType";

export enum ReviewSettingsActionTypes {
    SET_LOADED_ORGANISATION = "SET_LOADED_ORGANISATION",
    CHANGE_REVIEW_SETTING_MODE = "CHANGE_REVIEW_SETTING_MODE",
    TOGGLE_ERROR_DIALOG = "TOGGLE_ERROR_DIALOG",
    START_EDIT_DIAGNOSIS = "START_EDIT_DIAGNOSIS",
    UPDATE_ORGANISATION = "UPDATE_ORGANISATION",
    UPDATE_REVIEW_DIAGNOSES = "UPDATE_REVIEW_DIAGNOSES",
    UPDATE_REVIEW_MANAGEMENT_OUTCOMES = "UPDATE_REVIEW_MANAGEMENT_OUTCOMES",
}

export interface ChangeReviewSettingMode {
    type: ReviewSettingsActionTypes.CHANGE_REVIEW_SETTING_MODE;
    payload: {
        reviewSettingMode: ReviewSettingMode;
    };
}

export interface SetLoadedOrganisation {
    type: ReviewSettingsActionTypes.SET_LOADED_ORGANISATION;
    payload: {
        organisation: IOrganisation;
        reviewDiagnoses: IReviewDiagnosis[];
        reviewManagementOptions: IManagementOptions[];
    };
}

export interface StartEditDiagnosis {
    type: ReviewSettingsActionTypes.START_EDIT_DIAGNOSIS;
    payload: {
        diagnosisUuid: string;
    };
}

export interface UpdateOrganisation {
    type: ReviewSettingsActionTypes.UPDATE_ORGANISATION;
    payload: {
        organisation: IOrganisation;
    };
}

export interface UpdateReviewDiagnoses {
    type: ReviewSettingsActionTypes.UPDATE_REVIEW_DIAGNOSES;
    payload: {
        reviewDiagnoses: IReviewDiagnosis[];
    };
}

export interface UpdateReviewManagmentOptions {
    type: ReviewSettingsActionTypes.UPDATE_REVIEW_MANAGEMENT_OUTCOMES;
    payload: {
        reviewManagementOptions: IManagementOptions[];
    };
}

export type ReviewSettingAction =
    | SetLoadedOrganisation
    | ChangeReviewSettingMode
    | StartEditDiagnosis
    | UpdateOrganisation
    | UpdateReviewDiagnoses
    | UpdateReviewManagmentOptions;

export default ReviewSettingAction;
