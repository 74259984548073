import { FC, useState } from "react";
import { Container, InputOnChangeData, Header, Form } from "semantic-ui-react";

import ErrorMessage from "components/ErrorMessage";
import CustomButton from "components/templates/Button";
import SupportEmailLink from "components/templates/SupportEmailLink";
import EmailConfirmationModal from "components/RemoteModel/modals/EmailConfirmationModal";

import useIsEmailValid from "hooks/useIsEmailValid";

import * as authorizationService from "services/authorizationService";

const ResendEmail: FC = () => {
    const [userEmail, setUserEmail] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [visibleEmailConfirmationModal, setVisibleEmailConfirmationModal] = useState<boolean>(false);
    const [isPending, setIsPending] = useState<boolean>(false);
    const isEmailValid = useIsEmailValid(userEmail);

    const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
        const fieldValue = data.value;
        setUserEmail(fieldValue);
    };
    const toggleEmailConfirmationModal = (): void => setVisibleEmailConfirmationModal((prevState) => !prevState);

    const resendEmail = async (): Promise<void> => {
        try {
            setIsPending(true);
            await authorizationService.sendPasswordEmailVerification(userEmail);
            setIsError(false);
            toggleEmailConfirmationModal();
        } catch {
            setIsError(true);
        } finally {
            setIsPending(false);
        }
    };

    return (
        <Container className="custom__container expired__token">
            <Form className="expired_token_message">
                <Header as="h2" dividing>
                    This link has expired
                </Header>
                <p>
                    Please type your email address in the box below and then click on Continue so we can send you a new
                    link.
                </p>
                {isError && <ErrorMessage errors="An error occurred. Please try again later." />}

                <Form.Field>
                    <Form.Input
                        label="Email"
                        className="is-required login"
                        type="email"
                        name="username"
                        onChange={onFieldChange}
                        data-testid="resend-link-email-field"
                        value={userEmail}
                    />
                </Form.Field>

                <p className="set-password-additional-info">
                    <i>
                        If you have any problems creating an account please contact <SupportEmailLink />.
                    </i>
                </p>

                <div className="buttons-wrapper">
                    <CustomButton
                        type="submit"
                        variant="filled"
                        action={resendEmail}
                        text="Continue"
                        disabled={!isEmailValid || isPending}
                        loading={isPending}
                        dataTestId="resend-link-email-submit"
                        ariaLabel="Resend new password reset link"
                    />
                </div>
            </Form>
            <EmailConfirmationModal
                show={visibleEmailConfirmationModal}
                email={userEmail}
                goBack={toggleEmailConfirmationModal}
                closeCallback={toggleEmailConfirmationModal}
                closeOnOverlay
            />
        </Container>
    );
};

export default ResendEmail;
