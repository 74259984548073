import { FC, Fragment } from "react";
import { Message } from "semantic-ui-react";

import { AllLesionLocation } from "model/lesionLocation";
import { BadImgReason, BadImgReasonText, ILesion } from "model/case";
import { CaptureImageType, CaptureImageTypeDictionary } from "model/captureImageType";

interface IProps {
    lesions: ILesion[];
}

const RetakeImagesWarning: FC<IProps> = ({ lesions }) => (
    <Message warning className="retake-images-warning">
        <p>The images marked with red below weren&apos;t suitable for the dermatologist to make a decision.</p>

        <div className="tab-div lesion-details">
            {lesions?.map((lesion, lesionIndex) =>
                lesion.images
                    ?.filter((image) => !image.removed && image.retake)
                    .map((badImage) => {
                        const { description, bodyPart } = lesion;
                        const { uuid, type, retakeReasons, retakeReasonOther } = badImage;

                        const isContextImage = type === CaptureImageType.MACRO;
                        const contextImgIndex = lesion.images
                            .filter((img) => img.type === CaptureImageType.MACRO)
                            .findIndex((img) => img.uuid === uuid);

                        const lesionType = `Lesion #${lesionIndex + 1} ${AllLesionLocation[bodyPart]}`;
                        const lesionDescription = description ? ` (${description})` : "";
                        const imageType = ` - ${isContextImage ? `#${contextImgIndex + 1} ` : ""} ${
                            CaptureImageTypeDictionary[type]
                        }`;

                        return (
                            <Fragment key={uuid}>
                                <p>
                                    <b>
                                        {lesionType}
                                        {lesionDescription}
                                        {imageType}
                                    </b>
                                </p>

                                <p>
                                    Reasons:
                                    <ul>
                                        {retakeReasons?.map((reason, index) => {
                                            const reasonId = `${reason}-${index}`;
                                            const reasonText =
                                                reason === BadImgReason.OTHER
                                                    ? `${BadImgReasonText[reason]} - ${retakeReasonOther}`
                                                    : BadImgReasonText[reason];

                                            return <li key={reasonId}>{reasonText}</li>;
                                        })}
                                    </ul>
                                </p>
                            </Fragment>
                        );
                    })
            )}
        </div>

        <p>Please retake the images on the app and submit your case again.</p>
    </Message>
);

export default RetakeImagesWarning;
