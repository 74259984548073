import { FC, useEffect } from "react";
import { Redirect, Route, Switch, useLocation, match, matchPath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PageContainer from "components/templates/PageContainer";
import ScrollToTop from "components/ScrollToTop";
import Login from "components/Login";
import ResetPassword from "components/ResetPassword";
import LandingPage from "components/LandingPage";
import RegisterScreen from "components/RemoteModel/RegisterScreen";
import PasswordScreen from "components/RemoteModel/PasswordScreen";

import { AtHomeFlowProvider } from "contextProviders/AtHomeFlowProvider";

import LocalStorageKeys from "model/localStorageKeys";

import * as ROUTES from "navigation/routes";
import * as REMOTE_MODEL_ROUTES from "navigation/remoteModelRoutes";
import PatientRoutes from "navigation/PatientRoutes";

import * as actions from "redux/actions";
import { getRegistration } from "redux/selectors/registration";

import { LocalStorageService } from "services/localStorageService";

const REDIRECT_AFTER_LOGIN_WHITELIST = [ROUTES.CASE_DESCRIPTION_REGEXP];

interface IPath {
    path?: string;
}

const PublicRoutes: FC = () => {
    const location = useLocation();

    const dispatch = useDispatch();

    const registration = useSelector(getRegistration);

    const { pathname } = location;
    const currentMatch: match | null = matchPath(pathname, { path: "/:path" });
    const params: IPath | undefined = currentMatch?.params;
    const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;

    if (LocalStorageService.getItem(LocalStorageKeys.HAS_LOGGED_OUT) === "true") {
        LocalStorageService.removeItem(LocalStorageKeys.HAS_LOGGED_OUT);
    } else if (REDIRECT_AFTER_LOGIN_WHITELIST.some((listItem) => listItem.test(pathname))) {
        LocalStorageService.setLastUrl();
    }

    useEffect(() => {
        dispatch(actions.registrationActions.userClearData());
    }, []);

    return (
        <PageContainer isPublic={!isAtHomeFlow || params?.path !== "patient"}>
            <ScrollToTop />
            <Switch>
                <Route path={ROUTES.LOGIN} component={Login} exact />
                <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_START} component={RegisterScreen} exact />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_PASSWORD} component={PasswordScreen} exact />
                <Route path="/:codeName" component={LandingPage} exact />
                {isAtHomeFlow && (
                    <Route
                        path={REMOTE_MODEL_ROUTES.PATIENT_SUBPATH}
                        render={() => (
                            <AtHomeFlowProvider>
                                <PatientRoutes />
                            </AtHomeFlowProvider>
                        )}
                    />
                )}
                <Redirect to={ROUTES.LOGIN} />
            </Switch>
        </PageContainer>
    );
};

export default PublicRoutes;
