import { Component, ReactNode } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Divider, Grid, Header, Label, Loader } from "semantic-ui-react";
import moment from "moment";

import CaseAdministrationPanel from "components/CaseDescription/CaseAdministrationPanel";
import CaseAdministrationButtons from "components/CaseDescription/CaseAdministrationButtons";
import CaseBottomPanel from "components/CaseDescription/CaseBottomPanel";
import CaseNotes from "components/CaseDescription/CaseNotes";
import CasePdfReport from "components/CaseDescription/CasePdfReport";
import CaseStatusPanel from "components/CaseDescription/CaseStatusPanel";
import LesionsAssesment from "components/CaseDescription/Lesions/LesionsAssessment";
import AdministrativeNextSteps from "components/CaseDescription/Review/AdministrativeNextSteps";
import ReviewPathwayContext from "components/CaseDescription/Review/ReviewPathwayContext";
import CaseSummaryTable from "components/CaseDescription/Summary/CaseSummaryTable";
import StickyPatientInformation from "components/templates/StickyPatientInformation";
import CaseQuestions from "components/CaseDescription/Questions/CaseQuestions";
import { CaseReviewComponent } from "components/CaseDescription/Review/CaseReview";
import LoadingSpinner from "components/templates/LoadingSpinner";
import PatientDetails from "components/CaseDescription/Review/PatientDetails";
import CaseCallback from "components/CaseDescription/CaseCallback";
import CaseInfoPanel from "components/CaseDescription/CaseInfoPanel";
import RedirectPatientToAssessment from "components/CaseDescription/RedirectPatientToAssessment";
import AutoSavingClinicalNotesAnnouncement from "components/NewFeaturesAnnouncements/AutoSavingClinicalNotesAnnouncement";
import ImprovementsToImageViewerAnnouncement from "components/NewFeaturesAnnouncements/ImprovementsToImageViewerAnnouncement";
import RemoteCaseStatusTimeline from "components/templates/RemoteCaseStatusTimeline";
import Modal from "components/Modal";

import CaseReviewProvider from "contextProviders/CaseReviewProvider";

import { formatTimeWithHours } from "helpers/datetime";
import { getCaseStep } from "helpers/caseList";
import getUserOrganisationUuids from "helpers/userUtils";

import { ICase, IReview, ISkinToneAnswer, ReportStatus } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { CaseViewMode, ReviewMode } from "model/caseViewMode";
import { ModalTypes } from "model/modal";
import { IManagementOptions, IOrganisation, IReviewDiagnosis, IReviewEscalation } from "model/organisation";
import { ReviewType } from "model/reviewType";
import { IUser } from "model/user";
import { UserRole } from "model/userRole";
import { ICreateReviewResponse, NoteType } from "model/reviewNotes";
import { CallbackOutcomesValues, COMPLETED_STATUSES } from "model/remoteModel";

import { history } from "App";
import { CASE_DESCRIPTION, HOME } from "navigation/routes";

import * as caseService from "services/caseService";
import organizationService from "services/organizationService";
import logoutTimeoutService from "services/logoutTimeoutService";
import userService from "services/userService";

import "scss/CaseDetails.scss";
import "scss/Container.scss";

const PULL_CASE_DELAY = 3000;

interface ICaseDescriptionProps extends RouteComponentProps<{ reviewMode: string; id: string }> {
    pendingRequest: boolean;
    user: IUser;
    organisation: IOrganisation;
    getReport: (uuid: string, caseId?: string, patientId?: string) => void;
    viewReport: (uuid: string, openInnewPage?: boolean) => void;
    switchUserOrganisation: (organisationUuid: string) => Promise<void>;
}
interface ICaseDescriptionState {
    currentCase: ICase | undefined;
    currentCaseUuid: string;
    pullLimit: number;
    pullNumber: number;
    refreshCaseIntervalId: NodeJS.Timeout | undefined;
    userOrganisation: IOrganisation;
    reviewDiagnoses: IReviewDiagnosis[];
    reviewOptions: IManagementOptions[];
    escalationPlan: IReviewEscalation[];
    caseSasLink: string;
    patientOrganisation?: IOrganisation;
    showDownloadImagesModal: boolean;
    adminNextStepsIsOpen?: boolean;
}

class CaseDescription extends Component<ICaseDescriptionProps, ICaseDescriptionState> {
    private static checkCaseOlderThan5minutes(indiCase: ICase): boolean {
        const dateNow = Date.now();
        const dateModification = new Date(indiCase.lastModificationDate).getTime();
        if (dateNow - dateModification > 5 * 60 * 1000) {
            return true;
        }
        return false;
    }

    public constructor(props: ICaseDescriptionProps) {
        super(props);

        const { match, organisation } = this.props;
        const currentCaseUuid = match.params.id;

        this.state = {
            currentCase: undefined,
            currentCaseUuid,
            pullLimit: 60,
            pullNumber: 0,
            refreshCaseIntervalId: undefined,
            reviewDiagnoses: [],
            reviewOptions: [],
            escalationPlan: [],
            userOrganisation: organisation,
            caseSasLink: "",
            patientOrganisation: undefined,
            showDownloadImagesModal: false,
            adminNextStepsIsOpen: true,
        };

        this.updateSkinToneClassification = this.updateSkinToneClassification.bind(this);
    }

    public componentDidMount() {
        this.fetchCase(true);
    }

    public componentWillUnmount() {
        const { refreshCaseIntervalId } = this.state;
        clearInterval(refreshCaseIntervalId);
    }

    private handleNewCaseResponse(
        response: ICase,
        refreshCaseIntervalId: NodeJS.Timeout | undefined,
        newPullNumber: number
    ): void {
        const { currentCase } = this.state;

        if (response && response.report && response.report.status === ReportStatus.GENERATED) {
            clearInterval(refreshCaseIntervalId);
            currentCase.generatedReport = response.generatedReport;
            currentCase.report = response.report;
            currentCase.caseStatus = response.caseStatus;
            this.setState({
                currentCase,
                pullNumber: newPullNumber,
                refreshCaseIntervalId: undefined,
            });
        } else {
            currentCase.caseStatus = response.caseStatus;
            this.setState({ currentCase, pullNumber: newPullNumber });
        }
    }

    private static handleClicked(): void {
        logoutTimeoutService.startCount();
    }

    private getPatientOrganisation(currentCase: ICase): void {
        const { organisationUuid } = currentCase;
        const isSuperAdminOrSAAdmin = userService.checkUserHasRole([UserRole.SA_ADMIN, UserRole.SUPERADMIN]);

        if (isSuperAdminOrSAAdmin) {
            organizationService.getOrganisationByUuid(organisationUuid).then((response: IOrganisation) => {
                this.setState({
                    patientOrganisation: response,
                });
            });
        }
    }

    private getOrganisationReviewOptions(currentCase: ICase): void {
        const { match } = this.props;
        const { reviewMode } = match.params;

        if (reviewMode === ReviewMode.REVIEW) {
            const isClientReview = [CaseStatus.CASE_IN_CLIENT_REVIEW, CaseStatus.CASE_IN_REVIEW].includes(
                currentCase.caseStatus
            );
            let reviewType: ReviewType | undefined;
            if (currentCase.nonSkinCancer) {
                reviewType = ReviewType.NON_SKIN_CANCER;
            } else if (isClientReview) {
                reviewType = ReviewType.CLIENT;
            } else {
                reviewType = ReviewType.SAFETY_NET;
            }

            organizationService
                .getOrganisationByUuidDiagnoses(currentCase.organisationUuid)
                .then((diagnoses: IReviewDiagnosis[]) => {
                    this.setState({
                        reviewDiagnoses: diagnoses.filter(
                            (diagnosis) => diagnosis.reviewType === reviewType && !diagnosis.removed
                        ),
                    });
                });
            organizationService
                .getOrganisationByUuidManagementOptions(currentCase.organisationUuid)
                .then((options: IManagementOptions[]) => {
                    this.setState({
                        reviewOptions: options.filter((option) => option.reviewType === reviewType && !option.removed),
                    });
                });

            if (currentCase.nonSkinCancer) {
                organizationService
                    .getOrganisationByUuidEscalations(currentCase.organisationUuid)
                    .then((escalations: IReviewEscalation[]) => {
                        this.setState({
                            escalationPlan: escalations.filter((escalation) => !escalation.removed),
                        });
                    });
            }
        }
    }

    private getMode(): CaseViewMode {
        const {
            user: { role: userRole },
            match,
        } = this.props;
        const { currentCase } = this.state;
        const isRemote = Boolean(currentCase?.remote);
        const { reviewMode } = match.params;
        const isSuperadmin: boolean = userRole === UserRole.SUPERADMIN;
        const isClientAdmin: boolean = userRole === UserRole.ADMIN;
        const isSAAdmin: boolean = userRole === UserRole.SA_ADMIN;
        const isAdmin: boolean = isSAAdmin || isClientAdmin;
        const isDermatologist: boolean = userRole === UserRole.DERMATOLOGIST;
        const isCallbackAgent: boolean = userRole === UserRole.CALLBACK_AGENT;
        const isClinicalViewer: boolean = userRole === UserRole.CLINICAL_VIEWER;

        if (isSuperadmin) {
            return CaseViewMode.SUPERADMINVIEW;
        }

        if (isAdmin && !(isClientAdmin && isRemote)) {
            return CaseViewMode.ADMINVIEW;
        }

        if (isClientAdmin && isRemote) {
            return CaseViewMode.CLIENT_ADMIN_REMOTE_VIEW;
        }

        if (isDermatologist && reviewMode === ReviewMode.REVIEW) {
            return CaseViewMode.REVIEW;
        }

        if (isDermatologist && (reviewMode === ReviewMode.VIEW || !reviewMode)) {
            return CaseViewMode.REVIEWVIEW;
        }

        if (isCallbackAgent) {
            return CaseViewMode.CALLBACK_AGENT_REVIEW;
        }

        if (isClinicalViewer) {
            return CaseViewMode.CLINICAL_VIEWER_VIEW;
        }

        return CaseViewMode.VIEW;
    }

    private getDownloadText = (): ReactNode => {
        const { currentCase } = this.state;
        const isInReview = [
            CaseStatus.CASE_IN_SA_REVIEW,
            CaseStatus.CASE_FOR_SA_REVIEW,
            CaseStatus.CASE_FOR_REVIEW,
            CaseStatus.CASE_IN_REVIEW,
        ].includes(currentCase.caseStatus);

        const isCaseCompletedOrClosed = [
            CaseStatus.CASE_FAILED,
            CaseStatus.CASE_COMPLETED,
            CaseStatus.CASE_CLOSED,
        ].includes(currentCase.caseStatus);

        if (isInReview) {
            return (
                <div>
                    <p>Report will be generated after case review.</p>
                </div>
            );
        }
        if (currentCase.report?.status === ReportStatus.GENERATED) {
            return (
                <div>
                    <p>
                        Full details are available in the downloadable assessment report, which also includes all
                        information and images captured for this case.
                    </p>
                </div>
            );
        }
        if (currentCase.report?.status === ReportStatus.FAILED) {
            return (
                <div>
                    <p>Report Generation Failed. Please contact support</p>
                </div>
            );
        }
        if (isCaseCompletedOrClosed && CaseDescription.checkCaseOlderThan5minutes(currentCase)) {
            return (
                <div>
                    <p>Report is not generated yet. Please finish assessment or abandon case to see report</p>
                </div>
            );
        }

        return (
            <Grid columns={2} style={{ height: "50px" }}>
                <Grid.Column width={1}>
                    <Loader active inline size="small" />
                </Grid.Column>
                <Grid.Column>Processing case. Please wait...</Grid.Column>
            </Grid>
        );
    };

    private getReportSection = (): ReactNode => {
        const { getReport, viewReport, pendingRequest, user } = this.props;
        const { currentCase } = this.state;
        const { remote: isRemote } = currentCase;
        const mode = this.getMode();
        const allowedRoles = [
            CaseViewMode.CLIENT_ADMIN_REMOTE_VIEW,
            CaseViewMode.ADMINVIEW,
            CaseViewMode.SUPERADMINVIEW,
            CaseViewMode.VIEW,
            CaseViewMode.CLINICAL_VIEWER_VIEW,
        ];
        const allowReportRender = !isRemote || this.isReportAvailableForRemoteCase(currentCase);
        const canRender = allowReportRender && (allowedRoles.includes(mode) || user.role === UserRole.DERMATOLOGIST);

        return (
            canRender && (
                <>
                    <Header as="h2">Assessment reports</Header>
                    {this.getDownloadText()}
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                <CasePdfReport
                                    getReport={getReport}
                                    viewReport={viewReport}
                                    pendingRequest={pendingRequest}
                                    caseObject={currentCase}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            )
        );
    };

    public fetchCase = (withRefreshReport?: boolean): void => {
        const { currentCaseUuid } = this.state;
        const { user, organisation, switchUserOrganisation } = this.props;

        caseService.getCaseAsync(currentCaseUuid).then((currentCase: ICase) => {
            if (currentCase) {
                const userOrgUuids = getUserOrganisationUuids(user);
                if (
                    currentCase.organisationUuid !== organisation.uuid &&
                    userOrgUuids.includes(currentCase.organisationUuid) &&
                    user.role !== UserRole.SUPERADMIN
                ) {
                    switchUserOrganisation(currentCase.organisationUuid).then(() => {
                        window.location.reload();
                    });
                } else {
                    this.setState({ currentCase, caseSasLink: currentCase.sasLink || "" });
                    this.handleDermatologistRedirectToReview(currentCase);
                    const { remote: isRemote } = currentCase;
                    const isReportAvailableForRemoteCase = this.isReportAvailableForRemoteCase(currentCase);
                    if ((withRefreshReport && !isRemote) || isReportAvailableForRemoteCase) {
                        const intervalId = setInterval(this.refreshCaseUntilReportReady.bind(this), PULL_CASE_DELAY);
                        this.setState({ refreshCaseIntervalId: intervalId });
                    }
                    this.getOrganisationReviewOptions(currentCase);
                    this.getPatientOrganisation(currentCase);
                }
            } else {
                history.push(HOME);
            }
        });
    };

    private handleDermatologistRedirectToReview = (currentCase: ICase): void => {
        const {
            user: { role: userRole, uuid: userUuid },
        } = this.props;

        const isCurrentUserDermatologist = userRole === UserRole.DERMATOLOGIST;
        if (!isCurrentUserDermatologist) {
            return;
        }

        if (!currentCase.assignmentDetails) {
            return;
        }

        const {
            uuid: caseUuid,
            caseStatus,
            assignmentDetails: { assigneeUuid },
        } = currentCase;
        const isCurrentCaseAssignedToCurrentUser = userUuid === assigneeUuid;
        const isCaseInReviewOrInSaAdminReview = [CaseStatus.CASE_IN_REVIEW, CaseStatus.CASE_IN_SA_REVIEW].includes(
            caseStatus
        );
        const shouldRedirectToReviewMode = isCurrentCaseAssignedToCurrentUser && isCaseInReviewOrInSaAdminReview;

        if (shouldRedirectToReviewMode) {
            history.push(`${CASE_DESCRIPTION}/${caseUuid}/${ReviewMode.REVIEW}`);
        }
    };

    private updateCaseNotes = (
        type: string,
        note: string,
        noteUuid?: string,
        organisationUuid?: string,
        supersedes?: string,
        supersededNoteOriginalCreationDate?: Date
    ): void => {
        const { userOrganisation, currentCase } = this.state;
        const updatedCase = { ...currentCase };
        const { clinicalNotes, adminNotes, patientNotes } = updatedCase;
        const { user } = this.props;
        const isAdminNotes = type === NoteType.ADMIN;
        const isClinicalNotes = type === NoteType.CLINICAL;
        const isPatientNotes = type === NoteType.PATIENT;
        const newNote = {
            removed: false,
            note,
            uuid: noteUuid || "uuid",
            createdByName: `${user.name} ${user.surname}`,
            type,
            lastModificationDate: formatTimeWithHours(new Date().toString()),
            creatorOrganisationName: userOrganisation.name,
            organisationUuid: organisationUuid || "organisationUUid",
            createdBy: user.uuid,
            supersedes: supersedes || "",
            creationDate: formatTimeWithHours(new Date().toString()),
            originalCreationDate: supersededNoteOriginalCreationDate
                ? formatTimeWithHours(new Date(supersededNoteOriginalCreationDate || "").toString())
                : undefined,
        };

        if (isClinicalNotes) {
            if (!supersedes) {
                const updatedNoteIndex = updatedCase.clinicalNotes.findIndex(
                    (clinicalNote) => clinicalNote.uuid === noteUuid
                );

                if (updatedNoteIndex < 0) {
                    updatedCase.clinicalNotes = [...(clinicalNotes || []), newNote];
                } else {
                    updatedCase.clinicalNotes[updatedNoteIndex].note = note;
                }
            } else {
                updatedCase.clinicalNotes = [...(clinicalNotes || []), newNote];

                const sortedNotes = updatedCase.clinicalNotes.sort((a, b) => {
                    const dateA = a.originalCreationDate || a.creationDate;
                    const dateB = b.originalCreationDate || b.creationDate;
                    return new Date(dateA).valueOf() - new Date(dateB).valueOf();
                });
                updatedCase.clinicalNotes = sortedNotes;
            }
        }

        if (isAdminNotes) {
            updatedCase.adminNotes = [...(adminNotes || []), newNote];
        }

        if (isPatientNotes) {
            updatedCase.patientNotes = [...(patientNotes || []), newNote];
        }

        this.setState({ currentCase: updatedCase });
    };

    private removeNote = (type: string, noteUuid): void => {
        const { currentCase } = this.state;
        const updatedCase = { ...currentCase };
        const isClinicalNotes = type === NoteType.CLINICAL;

        if (isClinicalNotes) {
            const removedNoteIndex = updatedCase.clinicalNotes.findIndex((note) => note.uuid === noteUuid);
            updatedCase.clinicalNotes[removedNoteIndex].removed = true;
        }

        this.setState({ currentCase: updatedCase });
    };

    private updateCallbackOutcome = (callback: CallbackOutcomesValues, isFinalOutcome: boolean): void => {
        const { currentCase } = this.state;
        const updatedCase = { ...currentCase };
        const { callbackOutcome } = updatedCase;
        const currentDate = moment().format();
        const currentUserFullname = userService.getCurrentUserFullName();
        const currentUserUuid = userService.getCurrentUserUuid();
        const newOutcome = {
            uuid: `${callbackOutcome?.length || 0}`,
            caseUuid: updatedCase.uuid,
            createdBy: currentUserFullname,
            creationDate: currentDate,
            lastModificationDate: currentDate,
            lastModifiedByName: currentUserFullname,
            lastModifiedBy: currentUserUuid,
            outcome: callback,
        };
        const callbackOutcomesUpdated = callbackOutcome ? [...callbackOutcome, newOutcome] : [newOutcome];

        if (isFinalOutcome) {
            updatedCase.caseStatus = CaseStatus.CASE_CLOSED;
        }

        updatedCase.callbackOutcome = callbackOutcomesUpdated;
        this.setState({ currentCase: updatedCase });
    };

    private isReportAvailableForRemoteCase = (currentCase: ICase): boolean => {
        const { remote: isRemote, caseStatus } = currentCase;
        return Boolean(isRemote) && COMPLETED_STATUSES.includes(caseStatus);
    };

    private updateCaseReview = (reviewResponseData: ICreateReviewResponse): void => {
        const { organisation } = this.props;
        const { currentCase } = this.state;

        const updatedCase = { ...currentCase };
        const { reviews, lesions } = updatedCase;
        const { reviewManagementOptions, reviewDiagnoses } = organisation;
        const { lesionReviews, reviewManagement: reviewManagementResponse } = reviewResponseData;
        const isMultilesionCase = lesionReviews.length > 1;

        lesionReviews?.forEach((lesionReview) => {
            const { lesionUuid, reviewDiagnosisUuid, reviewManagementUuid } = lesionReview;
            const currentLesion = lesions.find((lesion) => lesion.uuid === lesionUuid);
            const reviewDiagnosis = reviewDiagnoses.find(
                (diagnosis) => diagnosis.uuid === reviewDiagnosisUuid
            )?.diagnosis;
            const reviewManagement = isMultilesionCase
                ? reviewManagementOptions.find((managementOption) => managementOption.uuid === reviewManagementUuid)
                      ?.management
                : reviewManagementResponse;

            currentLesion?.reviews.push({
                ...lesionReview,
                reviewDiagnosis,
                reviewManagement,
            } as IReview);
        });

        reviews.push({ reviewManagement: reviewManagementResponse } as IReview);
        updatedCase.recommendation = reviewManagementResponse;
        this.setState({ currentCase: updatedCase });
    };

    private setShowDownloadImagesModal = (): void => {
        const { showDownloadImagesModal } = this.state;
        this.setState({
            showDownloadImagesModal: !showDownloadImagesModal,
        });
    };

    private downloadImages = async (downloadDermImages: () => Promise<void>): Promise<void> => {
        const { currentCase } = this.state;
        const { consentDate } = currentCase;

        if (!consentDate) {
            this.setShowDownloadImagesModal();
        } else {
            await downloadDermImages();
        }
    };

    private handleOpenAdminNextSteps = (): void => {
        const { adminNextStepsIsOpen } = this.state;
        this.setState({ adminNextStepsIsOpen: !adminNextStepsIsOpen });
    };

    private updateSkinToneClassification(result: ISkinToneAnswer[]): void {
        const updatedCase = { ...this.state.currentCase };
        updatedCase.patient = {
            ...updatedCase.patient,
            skinToneClassification: [...result],
        };

        this.setState((prevState) => ({
            ...prevState,
            currentCase: updatedCase,
        }));
    }

    private refreshCaseUntilReportReady(): void {
        const { refreshCaseIntervalId, currentCase, pullNumber, pullLimit } = this.state;
        const { pendingRequest } = this.props;
        const mode = this.getMode();
        const newPullNumber = pullNumber + 1;

        if (currentCase) {
            const noRefreshCondition = [CaseStatus.CASE_CLOSED, CaseStatus.CASE_FAILED].includes(
                currentCase.caseStatus
            );

            const caseCompletedCondition = Boolean(currentCase.report) && CaseStatus.CASE_COMPLETED;
            const isReviewMode = mode === CaseViewMode.REVIEW;

            if (noRefreshCondition || caseCompletedCondition || isReviewMode || pullNumber >= pullLimit) {
                clearInterval(refreshCaseIntervalId);
                this.setState({ refreshCaseIntervalId: undefined });
                return;
            }

            if (!pendingRequest) {
                caseService
                    .getCaseAsync(currentCase.uuid, ["generateReport", "report", "caseStatus"])
                    .then((response: ICase) => {
                        this.handleNewCaseResponse(response, refreshCaseIntervalId, newPullNumber);
                    });
            }
        }
    }

    public render() {
        const {
            userOrganisation,
            currentCase,
            reviewOptions,
            reviewDiagnoses,
            escalationPlan,
            caseSasLink,
            patientOrganisation,
            showDownloadImagesModal,
            adminNextStepsIsOpen,
        } = this.state;
        const { user } = this.props;
        const { role: userRole } = user;
        const mode = this.getMode();
        const isCallbackAgentReview = mode === CaseViewMode.CALLBACK_AGENT_REVIEW;
        const isDermatologistReview = mode === CaseViewMode.REVIEW;
        const isReviewMode = isDermatologistReview || isCallbackAgentReview;
        const isNonSkinCancer = currentCase?.nonSkinCancer;
        const isSuperAdminOrSAAdmin = userService.checkUserHasRole([UserRole.SA_ADMIN, UserRole.SUPERADMIN]);

        if (!currentCase || (!patientOrganisation && isSuperAdminOrSAAdmin)) {
            return <LoadingSpinner />;
        }

        const hasCallbackOutcome = currentCase?.callbackOutcome?.length > 0;
        const showCallbackSection = !isCallbackAgentReview && hasCallbackOutcome;

        const isCurrentViewModeAdminOrSuperAdmin: boolean =
            mode === CaseViewMode.ADMINVIEW || mode === CaseViewMode.SUPERADMINVIEW;
        const isCurrentUserSAAdminOrSuperAdmin: boolean =
            userRole === UserRole.SA_ADMIN || userRole === UserRole.SUPERADMIN;

        const isCurrentUserDermatologist = userService.checkUserHasRole([UserRole.DERMATOLOGIST]);

        const isCaseAssignedToCurrentUser =
            currentCase.assignmentDetails?.assigneeUuid === userService.getCurrentUserUuid();

        const assessmentDetailsHeaderBase = "Assessment Details";
        const assessmentDetailsHeader = isReviewMode
            ? `Case Review ${assessmentDetailsHeaderBase}`
            : assessmentDetailsHeaderBase;
        const { organisationEmail = "" } = userOrganisation;
        const {
            patient,
            caseId,
            remote: isRemote,
            isCallbackNeeded,
            administrativeNextSteps,
            caseStatus,
            reviews,
        } = currentCase;

        const { contactPreferences } = { ...patient };
        const showTimeline = isRemote && !isDermatologistReview;
        const showPatientDetails: boolean =
            isCurrentUserSAAdminOrSuperAdmin || mode === CaseViewMode.CLIENT_ADMIN_REMOTE_VIEW;
        const showCaseInfoPanel = !isCurrentUserSAAdminOrSuperAdmin;

        const displayAdminNextSteps =
            [CaseStatus.CASE_CLOSED, CaseStatus.CASE_COMPLETED].includes(caseStatus) &&
            administrativeNextSteps?.length > 0;

        return (
            <Container className="ui segment" onClick={CaseDescription.handleClicked}>
                <AutoSavingClinicalNotesAnnouncement />
                <ImprovementsToImageViewerAnnouncement />
                <Modal
                    cta={this.setShowDownloadImagesModal}
                    ctaText="OK"
                    closeCallback={this.setShowDownloadImagesModal}
                    closeOnOverlay
                    show={showDownloadImagesModal}
                    type={ModalTypes.ERROR}
                >
                    <h2 className="h2">User has not consented - cannot download the image(s) for this case</h2>
                </Modal>
                <StickyPatientInformation
                    caseId={caseId}
                    patient={patient}
                    nonSkinCancerFlow={currentCase.nonSkinCancer}
                    patientOrganisation={patientOrganisation}
                />

                {showTimeline && (
                    <>
                        <Header as="h2" className="without-bottom-margin">
                            Case status
                        </Header>
                        <RemoteCaseStatusTimeline
                            activeStatus={getCaseStep(currentCase)}
                            isAwaitingCallback={isCallbackNeeded}
                        />
                    </>
                )}

                {isCurrentViewModeAdminOrSuperAdmin && isCurrentUserSAAdminOrSuperAdmin && (
                    <CaseAdministrationPanel
                        caseSasLink={caseSasLink}
                        caseObject={currentCase}
                        patientOrganisation={patientOrganisation}
                        updateCaseNotes={this.updateCaseNotes}
                    />
                )}

                {showPatientDetails && (
                    <PatientDetails caseObject={currentCase} mode={mode} patientOrganisation={patientOrganisation} />
                )}

                {displayAdminNextSteps ? (
                    <>
                        <div className="section-separate section-case-header">
                            <div className="without-bottom-margin" />

                            <CaseAdministrationButtons
                                mode={mode}
                                caseObject={currentCase}
                                organisation={userOrganisation}
                                user={user}
                                caseRefetch={this.fetchCase}
                            />
                        </div>

                        <AdministrativeNextSteps
                            nextStepQuestions={administrativeNextSteps}
                            mode={ReviewMode.VIEW}
                            isOpen={adminNextStepsIsOpen}
                            handleOpen={this.handleOpenAdminNextSteps}
                            caseReviews={reviews}
                        />
                    </>
                ) : null}

                <div className="section-separate section-case-header">
                    <div>
                        <Header as="h2" className="without-bottom-margin">
                            {assessmentDetailsHeader}
                            {isNonSkinCancer && <Label color="yellow" content="other skin conditions" />}
                        </Header>
                    </div>

                    {!displayAdminNextSteps ? (
                        <CaseAdministrationButtons
                            mode={mode}
                            caseObject={currentCase}
                            organisation={userOrganisation}
                            user={user}
                            caseRefetch={this.fetchCase}
                        />
                    ) : null}
                </div>
                <div>
                    {!isReviewMode && isCurrentUserDermatologist && (
                        <p className="subtitle-gray-text assessed-by-text">
                            Please review the case details below and record your assessment
                        </p>
                    )}
                </div>
                <Divider className="title-divider" />

                {showCaseInfoPanel && <CaseInfoPanel caseObject={currentCase} />}

                {isCallbackAgentReview && (
                    <>
                        <PatientDetails caseObject={currentCase} mode={mode} patientOrganisation={userOrganisation} />
                        <CaseCallback
                            contactPreferences={contactPreferences}
                            currentCase={currentCase}
                            isCallbackAgentReview={isCallbackAgentReview}
                            updateCallbackOutcome={this.updateCallbackOutcome}
                            updateCaseNotes={this.updateCaseNotes}
                            removeCaseNote={this.removeNote}
                            updateCaseReview={this.updateCaseReview}
                        />
                        <Header as="h2" className="without-bottom-margin">
                            {assessmentDetailsHeaderBase}
                        </Header>
                        {isCurrentUserDermatologist && (
                            <p className="subtitle-gray-text assessed-by-text">
                                Please review the case details below and record your assessment
                            </p>
                        )}
                        <Divider className="title-divider" />
                    </>
                )}

                <ReviewPathwayContext
                    currentCase={currentCase}
                    userRole={userRole}
                    organisationEmail={organisationEmail}
                />

                <CaseStatusPanel caseObject={currentCase} mode={mode} />

                <CaseSummaryTable mode={mode} caseObject={currentCase} />
                <CaseNotes
                    caseObject={currentCase}
                    updateCaseNotes={this.updateCaseNotes}
                    organisation={userOrganisation}
                    mode={mode}
                    removeCaseNote={this.removeNote}
                />
                {this.getReportSection()}

                <LesionsAssesment
                    caseObject={currentCase}
                    mode={mode}
                    downloadImages={this.downloadImages}
                    updateSkinToneClassification={this.updateSkinToneClassification}
                />

                <CaseQuestions
                    mode={mode}
                    caseObject={currentCase}
                    updateSkinToneClassification={this.updateSkinToneClassification}
                />

                {isCurrentUserDermatologist && isCaseAssignedToCurrentUser && (
                    <CaseReviewProvider currentCase={currentCase}>
                        <CaseReviewComponent
                            mode={mode}
                            reviewDiagnoses={reviewDiagnoses}
                            escalationPlan={escalationPlan}
                            reviewManagementOptions={reviewOptions}
                            updateCaseNotes={this.updateCaseNotes}
                            removeCaseNote={this.removeNote}
                        />
                    </CaseReviewProvider>
                )}

                {showCallbackSection && (
                    <CaseCallback
                        contactPreferences={contactPreferences}
                        currentCase={currentCase}
                        isCallbackAgentReview={isCallbackAgentReview}
                        isSuperAdminOrSAAdmin={isSuperAdminOrSAAdmin}
                    />
                )}
                {!isReviewMode && <CaseBottomPanel />}
                <RedirectPatientToAssessment />
            </Container>
        );
    }
}

export default withRouter(CaseDescription);
