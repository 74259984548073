import { FC, useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import MUIAccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MUIRadio from "@material-ui/core/Radio";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createStyles, makeStyles, styled } from "@material-ui/core";
import { Form, Segment, TextAreaProps } from "semantic-ui-react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import NoteExceedsLimitWarning from "components/Notes/NoteExceedsLimitWarning";
import WhatIsFitzpatrickModal from "components/RemoteModel/FitzpatrickQuestion/WhatIsFitzpatrickModal";
import WhyAmIBeingAskedThisQuestionModal from "components/RemoteModel/FitzpatrickQuestion/WhyAmIBeingAskedThisQuestionModal";
import SkinColour from "components/templates/FitzpatrickSkinColour";
import SkinTypeBurnAndTanProfile from "components/templates/SkinTypeBurnAndTanProfile";

import { onPasteWhitespaceRemover } from "helpers/field";
import { getUserRoleFromFPNote, sortFitzpatrickQuestionAnswers } from "helpers/fitzpatrick";

import { FitzpatrickAnswersType } from "model/fixedQuestion";
import { noteLimit } from "helpers/noteValidation";
import { IHistoryAnswerType, IHistoryQuestion } from "model/organisation";
import IQuestionaryAnswerValidation from "model/questionaryAnswerValidation";

import * as caseService from "services/caseService";

import { getAssessment } from "redux/selectors/assessment";
import { getPendingRequest } from "redux/selectors/data";

import "scss/RemoteModel.scss";
import { SkinTypeNoteOriginator } from "model/userRole";
import { ICase } from "model/case";
import { IMedicalHistory } from "model/assessment";
import { IUser } from "model/user";

const AccordionDetails = styled(MUIAccordionDetails)(({ theme }) => ({
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    backgroundColor: "#F5F5F5",
    fontFamily: theme.typography.fontFamily,
    display: "block",
}));

const Radio = styled(MUIRadio)(({ theme }) => ({
    "&:hover, &:active, &.Mui-focusVisible": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5,
    },
    "&.Mui-checked": {
        color: theme.palette.common.black,
    },
    "&.Mui-disabled": {
        color: theme.palette.common.black,
        opacity: 0.5,
    },
}));

const useStyles = makeStyles(() =>
    createStyles({
        accordion: {
            "boxShadow": "none",

            "&.Mui-expanded": {
                borderBottom: "1px solid #dbdcdd",
                borderTop: "1px solid #dbdcdd",
                margin: "12px 0px 8px 0px",
                paddingTop: "8px",
                paddingBottom: "8px",
            },

            "&:last-of-type": {
                borderBottom: "1px solid #dbdcdd",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
            },
        },
        accordionBody: {
            padding: "8px 24px 16px 42px",
        },
        accordionSummary: {
            border: "none",
            borderRadius: "0px",
            paddingBottom: "8px",
            paddingTop: "8px",
        },
        active: {
            "backgroundColor": "#F5F5F5 !important",

            "&.Mui-expanded": {
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
            },
        },
        emphasis: { color: "#5D918B", fontWeight: 600 },
        form: {
            width: "100%",
        },
        moreInfo: {
            marginTop: "26px",
        },
        moreInfoButtonWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        moreInfoButton: {
            textAlign: "center",
            width: "350px",
            fontWeight: 600,
            cursor: "pointer",
        },
        question: {
            padding: "20px 0px",
        },
        tanning: {
            color: "#000",
            opacity: "60%",
            marginLeft: "26px",
        },
        textArea: {
            "& textarea": {
                minWidth: "100%",
            },
        },
        textAreaReadOnly: {
            "& textarea": {
                "minWidth": "100%",
                "&:focus": {
                    "border": "1px solid rgba(69, 69, 69, 0.15) !important",
                    "&::placeholder": {
                        color: "rgba(0,0,0,.25) !important",
                    },
                },
            },
        },
        textAreaWrapper: {
            marginTop: "26px",
            width: "100%",
        },
        title: {
            "display": "block",

            "& label": {
                "& span": {
                    fontSize: "1rem",
                    fontWeight: 600,
                },
            },
        },
        additionalInfo: {
            display: "flex",
            justifyContent: "center",
            marginTop: "26px",
        },
        additionalInfoButton: {
            cursor: "pointer",
            fontWeight: 500,
            textAlign: "center",
            textDecoration: "underline",
            width: "350px",
        },
    })
);

interface INotes {
    patientAnswer: IMedicalHistory;
    isBack: boolean;
    optionalAnswer: string;
    pendingRequest: boolean;
    onFieldChange: (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => void;
}

const Notes: FC<INotes> = ({ patientAnswer, optionalAnswer, isBack, pendingRequest, onFieldChange }) => {
    const classes = useStyles();

    const hasPatientNote =
        patientAnswer && patientAnswer?.additionalInformation && patientAnswer?.additionalInformation !== "";

    const readOnly = isBack || pendingRequest;

    const textArea = (
        <div>
            <Form.TextArea
                className={clsx(readOnly ? classes.textAreaReadOnly : classes.textArea, "questionary-item-at-home")}
                type="text"
                onChange={onFieldChange}
                onPaste={onPasteWhitespaceRemover(onFieldChange, true)}
                value={optionalAnswer || ""}
                placeholder="Type here"
                maxLength={noteLimit}
                readOnly={readOnly}
            />
            <NoteExceedsLimitWarning padding={8} note={optionalAnswer} />
        </div>
    );

    if (hasPatientNote) {
        return (
            <div className={classes.textAreaWrapper}>
                <p>
                    <strong>Patient note</strong>
                </p>
                <Segment className="note note-container">
                    <div className="actual-note-wrapper">
                        <p>{patientAnswer?.additionalInformation}</p>
                    </div>
                </Segment>
                <p style={{ marginTop: "16px" }}>
                    <strong>Optional notes</strong>
                </p>
                {textArea}
            </div>
        );
    }

    return (
        <div className={classes.textAreaWrapper}>
            <p>
                <strong>Not sure about your answer? Tell us why</strong>
            </p>
            {textArea}
        </div>
    );
};

interface IMoreInformation {
    setShowModal: (boolean) => void;
}

const MoreInformation: FC<IMoreInformation> = ({ setShowModal }) => {
    const classes = useStyles();
    const handleOnKeyDown = (e) => {
        if (e.key === "Enter") {
            setShowModal(true);
        }
    };

    return (
        <div className={classes.moreInfo}>
            <p className={classes.emphasis}>What if the skin colour in the picture does not represent mine?</p>
            <p>
                We highly recommend you make your choice based on your response to the sun and other typical features
                described above rather than on the colour itself.
            </p>
            <div className={classes.moreInfoButtonWrapper}>
                <div
                    className={classes.moreInfoButton}
                    tabIndex={0}
                    role="button"
                    onClick={setShowModal}
                    onKeyDown={handleOnKeyDown}
                >
                    <p>More about Fitzpatrick Skin Types</p>
                </div>
            </div>
        </div>
    );
};

interface IFitzpatrickQuestionaryItemQuestion {
    isBack: boolean;
    answers: any;
    updateAnswers: (data: any, isEdited?: boolean) => void;
    validateAnswers: ({ showError, question }: IQuestionaryAnswerValidation) => void;
    validateAdditionalInformationLength: (additionalInformation) => void;
    question: IHistoryQuestion;
    resetAnswer: boolean;
    user: IUser | null;
    isRemoteModel?: boolean;
}

const FitzpatrickQuestion: FC<IFitzpatrickQuestionaryItemQuestion> = ({
    isBack,
    answers,
    updateAnswers,
    validateAnswers,
    validateAdditionalInformationLength,
    question,
    resetAnswer,
    user,
    isRemoteModel,
}) => {
    const classes = useStyles();
    const [showFitzpatrickInformationModal, setShowFitzpatrickInformationModal] = useState<boolean>(false);
    const [showWhyModal, setShowWhyModal] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>("");
    const [expanded, setExpanded] = useState<string>("");
    const [optionalAnswer, setOptionalAnswer] = useState<string>("");
    const [caseObject, setCaseObject] = useState<ICase>(null);
    const [fitzpatrickAnswers, setFitzpatrickAnswers] = useState<IMedicalHistory[]>([]);

    const [patientAnswer, setPatientAnswer] = useState<IMedicalHistory>();

    const dispatch = useDispatch();
    const assessment = useSelector(getAssessment);
    const pendingRequest = useSelector(getPendingRequest);

    useEffect(() => {
        setSelected("");
        setExpanded("");
        setOptionalAnswer("");
        dispatch(caseService.getCaseForAssessment(assessment?.case?.uuid));
    }, [assessment?.case?.uuid]);

    useEffect(() => {
        const patient = assessment?.patient;
        setCaseObject(assessment?.case);
        if (patient) {
            const latestFitzpatrickAnswers = assessment?.patient?.medicalHistory
                ?.filter((answer) => answer.uuid === question.uuid && !answer.removed)
                .sort(sortFitzpatrickQuestionAnswers);
            setFitzpatrickAnswers(latestFitzpatrickAnswers);
        }
    }, [assessment]);

    useEffect(() => {
        let hasPatientAnswer = false;
        fitzpatrickAnswers?.forEach((answer) => {
            const role = getUserRoleFromFPNote(answer, caseObject);
            if (role === SkinTypeNoteOriginator.PATIENT) {
                // TODO - decide if this can be removed once business logic has been decided on https://skinanalytics.atlassian.net/browse/OV2-3629
                // An answer of "" means the user did not select a Fitzpatrick skin type answer
                hasPatientAnswer = answer.answers.every((selectedAnswer) => selectedAnswer?.length > 0);
                if (hasPatientAnswer) {
                    setPatientAnswer(answer);
                }
            }
            if (role === SkinTypeNoteOriginator.CLINICIAN) {
                setSelected(answer?.answers[0] || "");
                setOptionalAnswer(answer?.additionalInformation || "");
            }
        });
        if (!selected && hasPatientAnswer) {
            const [earliestAnswer] = fitzpatrickAnswers;
            setSelected(earliestAnswer?.answers[0] || "");
        }

        // We need to store an empty answer if the user has seen
        // this screen and did not select a FP skin type or added a note

        // (isRemoteModel && !user?.role) -> PIQ when the page is accessed by the patient
        // (Object.keys(answers).length > 0 && user?.role -> CIQ/PIQ when the page is accessed by CLINICIAN or other roles
        if ((isRemoteModel && !user?.role) || (Object.keys(answers).length >= 0 && user?.role)) {
            const historyAnswers = { ...answers };
            historyAnswers[question.uuid] = {
                additionalInformation: "",
                type: IHistoryAnswerType.OPTION,
                value: "",
            };
            updateAnswers(historyAnswers, false);
            validateAnswers({ question });
        }
    }, [fitzpatrickAnswers, question]);

    useEffect(() => {
        if (resetAnswer !== undefined) {
            if (fitzpatrickAnswers && caseObject) {
                fitzpatrickAnswers.forEach((answer) => {
                    const role = getUserRoleFromFPNote(answer, caseObject);
                    if (role === SkinTypeNoteOriginator.PATIENT) {
                        setSelected(answer?.answers[0] || "");
                        setOptionalAnswer("");
                    }
                    if (role === SkinTypeNoteOriginator.CLINICIAN) {
                        setSelected(answer?.answers[0] || "");
                        setOptionalAnswer(answer?.additionalInformation || "");
                    }
                });
            }
        }
    }, [resetAnswer]);

    const handleChange =
        (panel: string) => (event: React.ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : "");
        };

    const handleSelectType = (panel: string): void => {
        setSelected(panel);
        const historyAnswers = { ...answers };
        const currentAnswer = answers[question.uuid];
        const { additionalInformation = "" } = { ...currentAnswer };

        const answersWithRoles = fitzpatrickAnswers?.map((answer: IMedicalHistory) =>
            getUserRoleFromFPNote(answer, caseObject)
        );
        const hasClinicianAnswer = answersWithRoles.find(
            (answer: any) => answer.role === SkinTypeNoteOriginator.CLINICIAN
        );

        let note;

        if (answersWithRoles?.length > 0) {
            note = hasClinicianAnswer?.length > 0 ? additionalInformation : optionalAnswer;
        } else {
            note = additionalInformation;
        }

        const currentQuestionAnswerObject = {
            additionalInformation: note,
            type: IHistoryAnswerType.OPTION,
            value: panel,
        };

        historyAnswers[question.uuid] = currentQuestionAnswerObject;
        updateAnswers(historyAnswers);
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === "Enter") {
            setShowWhyModal(true);
        }
    };

    const changeAnswer = (data: string): void => {
        const historyAnswers = { ...answers };
        const currentAnswer = historyAnswers[question.uuid];
        historyAnswers[question.uuid] = {
            ...currentAnswer,
            additionalInformation: data,
        };
        updateAnswers(historyAnswers);
        validateAnswers({ question });
        validateAdditionalInformationLength(historyAnswers[question.uuid]?.additionalInformation);
    };

    const onFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps): void => {
        setOptionalAnswer(String(data.value));
        changeAnswer(String(data.value));

        const historyAnswers = { ...answers };
        historyAnswers[question.uuid] = {
            additionalInformation: String(data.value),
            type: IHistoryAnswerType.OPTION,
            value: selected || "",
        };

        updateAnswers(historyAnswers);
        validateAnswers({ question });
    };

    return (
        <>
            <Form className={classes.form}>
                <div>
                    <div className={classes.question}>
                        <p>How does your skin respond to the sun when not wearing sunscreen?</p>
                    </div>

                    {question.options
                        ?.sort((a, b) => a.option.localeCompare(b.option))
                        .map((option, index) => {
                            const skinTypeOption = option.option as FitzpatrickAnswersType;
                            return (
                                <Accordion
                                    className={classes.accordion}
                                    key={skinTypeOption}
                                    expanded={expanded === skinTypeOption}
                                    onChange={handleChange(skinTypeOption)}
                                >
                                    <AccordionSummary
                                        className={clsx(expanded === skinTypeOption && classes.active)}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${skinTypeOption}-content`}
                                        id={`${skinTypeOption}-header`}
                                    >
                                        <div className={classes.title}>
                                            <div>
                                                <FormControlLabel
                                                    checked={selected === skinTypeOption}
                                                    control={
                                                        <Radio
                                                            onClick={(e) => e.stopPropagation()}
                                                            onChange={() => handleSelectType(skinTypeOption)}
                                                            checked={selected === skinTypeOption}
                                                            disabled={isBack}
                                                        />
                                                    }
                                                    label={
                                                        <SkinTypeBurnAndTanProfile fitzpatrickAnswer={skinTypeOption} />
                                                    }
                                                    labelPlacement="end"
                                                    value={skinTypeOption}
                                                />
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionBody}>
                                        <div>
                                            <p className={classes.emphasis}>
                                                Typical features to look for if you&apos;re confused
                                            </p>
                                            <p>{option.supplementaryText}</p>
                                            <SkinColour
                                                fitzpatrickAnswer={skinTypeOption}
                                                text={`Typical skin colour in people with Fitzpatrick Skin Type ${
                                                    index + 1
                                                }`}
                                            />
                                            <MoreInformation
                                                setShowModal={() => setShowFitzpatrickInformationModal(true)}
                                            />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                </div>
                <Notes
                    patientAnswer={patientAnswer}
                    optionalAnswer={optionalAnswer}
                    isBack={isBack}
                    pendingRequest={pendingRequest}
                    onFieldChange={onFieldChange}
                />
                <div className={classes.additionalInfo}>
                    <div
                        className={classes.additionalInfoButton}
                        tabIndex={0}
                        role="button"
                        onClick={() => setShowWhyModal(true)}
                        onKeyDown={handleOnKeyDown}
                    >
                        <p>Why am I being asked this question?</p>
                    </div>
                </div>
            </Form>
            <WhyAmIBeingAskedThisQuestionModal showModal={showWhyModal} handleClose={() => setShowWhyModal(false)} />
            <WhatIsFitzpatrickModal
                showModal={showFitzpatrickInformationModal}
                handleClose={() => setShowFitzpatrickInformationModal(false)}
            />
        </>
    );
};

export default FitzpatrickQuestion;
