import { FC } from "react";
import { DialogContent } from "@material-ui/core";

import ModalDialog from "components/templates/dialog/ModalDialog";

import useModalPreference from "hooks/useModalPreference";

import "scss/Dialog.scss";

const MODAL_IDENTIFIER = "improvements-to-clinical-notes-ux";

const AutoSavingClinicalNotesAnnouncement: FC = () => {
    const { setUserPreference, showModal } = useModalPreference(MODAL_IDENTIFIER);

    if (!showModal) {
        return null;
    }

    return (
        <ModalDialog
            title="Introducing an enhanced experience when adding clinical notes"
            open={showModal}
            iconSrc="/images/icons/icon-message.svg"
            onClose={setUserPreference}
            maxWidth="sm"
            disableBackdropClick
            buttons={[
                {
                    onClick: setUserPreference,
                    text: "Got it",
                    colour: "primary",
                },
            ]}
        >
            <DialogContent>
                <p>
                    You no longer have to click multiple times to add a note, we will auto-save it for you, and also you
                    will be able to easily modify it.
                </p>
            </DialogContent>
        </ModalDialog>
    );
};

export default AutoSavingClinicalNotesAnnouncement;
