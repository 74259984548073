import { ILesionReview } from "components/CaseDescription/Review/CaseReview";

import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { IAdminNextStepQuestion } from "model/administrativeNextSteps";
import { CallbackOutcomesValues } from "model/remoteModel";
import { ICreateReviewResponse } from "model/reviewNotes";
import { IApiResponse } from "model/request";

import URLs from "services/urls";

const { CREATE_REVIEW, ALLOCATE_CASE, UNALLOCATE_CASE, CREATE_NOTE, REMOVE_OR_UPDATE_NOTE, SUBMIT_CALLBACK_OUTCOME } =
    URLs;

async function createReview({
    caseUuid,
    patientUuid,
    reviewManagementUuid,
    lesionReviews,
    managementAdditionalText,
    administrativeNextStepsQuestions,
}: {
    caseUuid: string;
    patientUuid: string;
    reviewManagementUuid: string;
    lesionReviews: ILesionReview[];
    managementAdditionalText?: string;
    administrativeNextStepsQuestions?: IAdminNextStepQuestion[];
}): Promise<ICreateReviewResponse> {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_REVIEW, {
        caseUuid,
        lesionReviews,
        managementAdditionalText,
        patientUuid,
        reviewManagementUuid,
        administrativeNextStepsQuestions,
    });
    return response.data.data;
}

async function allocateCase(caseUuid: string, dermatologistUuid?: string, assignToClient?: boolean) {
    try {
        const cases = await AuthorizedHttpConnector.sendPost(ALLOCATE_CASE, {
            caseUuid,
            ...(dermatologistUuid ? { assigneeUuid: dermatologistUuid } : {}),
            ...(assignToClient ? { assignToClient } : {}),
        });
        return cases;
    } catch (err: any) {
        throw new Error(err.response.data.errors[0].message);
    }
}

async function unallocateCase(allocationUuid: string) {
    try {
        const response = await AuthorizedHttpConnector.sendDelete(UNALLOCATE_CASE.replace("{{UUID}}", allocationUuid));
        return response.data.data;
    } catch (err: any) {
        throw new Error(err.response.data.errors[0].message);
    }
}

async function submitNote(caseUuid: string, patientUuid: string, type: string, note: string, supersedes?: string) {
    try {
        const cases = await AuthorizedHttpConnector.sendPost(CREATE_NOTE, {
            caseUuid,
            note,
            patientUuid,
            type,
            supersedes,
        });
        return cases;
    } catch (err: any) {
        throw new Error(err.response.data.errors[0].message);
    }
}

async function removeNote(noteUuid) {
    try {
        const deletedNote = await AuthorizedHttpConnector.sendDelete(
            REMOVE_OR_UPDATE_NOTE.replace("{{UUID}}", noteUuid)
        );
        return deletedNote;
    } catch (err: any) {
        throw new Error(err);
    }
}

async function updateNote(noteUuid: string, noteText: string, isSuperseding = false) {
    try {
        const updatedNote = await AuthorizedHttpConnector.sendPut(REMOVE_OR_UPDATE_NOTE.replace("{{UUID}}", noteUuid), {
            note: noteText,
            isSuperseding,
        });
        return updatedNote;
    } catch (err: any) {
        throw new Error(err);
    }
}

async function submitCallbackOutcome(caseUuid: string, outcome: CallbackOutcomesValues): Promise<IApiResponse> {
    try {
        const result = await AuthorizedHttpConnector.sendPost(SUBMIT_CALLBACK_OUTCOME, {
            caseUuid,
            outcome,
        });
        return result;
    } catch (err: any) {
        throw new Error(err.response.data.errors[0].message);
    }
}

export default {
    allocateCase,
    createReview,
    submitNote,
    unallocateCase,
    submitCallbackOutcome,
    removeNote,
    updateNote,
};
