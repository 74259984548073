import { FC } from "react";

import { UserRole } from "model/userRole";

import userService from "services/userService";

interface IConditionalRenderProps {
    requiredRole: UserRole[];
    children: any;
}

const ConditionalRender: FC<IConditionalRenderProps> = ({ requiredRole, children }) => {
    const userRole = userService.getLoggedUserRole();

    return <>{userRole && requiredRole.includes(userRole) && children}</>;
};

export default ConditionalRender;
