import React, { Component } from "react";

import { getLocation } from "helpers/caseDescription";

import { ICase, ILesion } from "model/case";
import ImageClassification from "model/imageClassification";
import { LesionClassification } from "model/lesionClassification";

interface ILesionDetails {
    case: ICase;
    lesionNumber: number;
}

class LesionDetails extends Component<ILesionDetails, any> {
    // TODO Handle case when we will detect many lesions in one image
    private static getClassifciation(lesionObject: ILesion) {
        if (lesionObject.result.error) {
            return "";
        }
        if (lesionObject.result.lesions?.length > 0) {
            // DERM in result.lesions can return more than one result. But Fin introduced lesion priority, and
            // since this, we presenting only first result from result.lesions array.
            return `Classification: ${LesionClassification[LesionDetails.getFirstDermDiagnosis(lesionObject)]}`;
        }
        return "Classification: No mole classification";
    }

    private static getFirstDermDiagnosis(lesionObject: ILesion) {
        return lesionObject.result.lesions[0].classification;
    }

    private getLesionResultObject(lesionNumber: number) {
        const { case: caseObj } = this.props;

        return caseObj.lesions[lesionNumber];
    }

    public render() {
        const { lesionNumber } = this.props;
        const lesionObject = this.getLesionResultObject(lesionNumber);
        const location = getLocation(lesionObject);

        return (
            <>
                <span>
                    <p style={{ fontWeight: "bold" }}>Lesion #{lesionNumber + 1}</p>
                    <p style={{ color: "grey" }}>Location: {location}</p>
                    <p style={{ color: "grey" }}>
                        Processing result:{" "}
                        {ImageClassification[lesionObject.result.analysisResult] || " Not assessed by DERM"}
                    </p>
                    <p style={{ color: "grey" }}>{` ${LesionDetails.getClassifciation(lesionObject)}`} </p>
                </span>
            </>
        );
    }
}

export default LesionDetails;
