import React, { FC, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Image, Segment } from "semantic-ui-react";

import PromtCaseReportDialog from "components/templates/dialog/PromtCaseReportDialog";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { ICase, ReportStatus } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { ModalIconAltTexts } from "model/modal";
import { UserRole } from "model/userRole";

import { history } from "App";
import { HOME } from "navigation/routes";

import * as caseService from "services/caseService";
import userService from "services/userService";

const FIVE_MINUTES_IN_MILLIS = 5 * 60 * 1000;

interface ICasePdfReportProps {
    getReport: (uuid: string, caseId?: string, patientId?: string) => void;
    viewReport: (uuid: string, openInnewPage?: boolean) => void;
    pendingRequest: boolean;
    caseObject: ICase;
}

const CasePdfReport: FC<ICasePdfReportProps> = ({ caseObject, pendingRequest, getReport, viewReport }) => {
    const { updateCaseStatus } = useContext(CaseListContext);
    const [promtCaseReportDialogOpened, setPromtCaseReportDialogOpened] = useState(false);

    const isReportGenerated = caseObject.report?.status === ReportStatus.GENERATED;

    const checkCaseOlderThan5minutes = (indiCase: ICase) => {
        const dateNow = Date.now();
        const dateModification = new Date(indiCase.lastModificationDate).getTime();
        const isOlderThanFiveMinutes = dateNow - dateModification > FIVE_MINUTES_IN_MILLIS;
        return isOlderThanFiveMinutes;
    };

    const toggleDialog = () => {
        setPromtCaseReportDialogOpened(!promtCaseReportDialogOpened);
    };

    /**
     *  | User Role  | Can close case after report downloaded: Clinical Flow? | Can close case after report downloaded: Remote Model? |
        | ---------- | ------------------------------------------------------ | ----------------------------------------------------- |
        | SUPERADMIN | YES                                                    | YES                                                   |
        | SA_ADMIN   | YES                                                    | YES                                                   |
        | ADMIN      | YES                                                    | NO                                                    |
        | CLINICIAN  | YES                                                    | YES                                                   |
     */
    const downloadReport = () => {
        const { caseId, uuid, caseStatus, remote } = caseObject;
        const rolesPermittedToCloseCaseInAllFlows: UserRole[] = [
            UserRole.SUPERADMIN,
            UserRole.SA_ADMIN,
            UserRole.CLINICIAN,
        ];
        const rolesPermittedToCloseCurrentCase: UserRole[] = remote
            ? rolesPermittedToCloseCaseInAllFlows
            : [UserRole.ADMIN, ...rolesPermittedToCloseCaseInAllFlows];
        const isAllowedToCloseCase: boolean = userService.checkUserHasRole(rolesPermittedToCloseCurrentCase);

        getReport(uuid, caseId);

        if (caseStatus !== CaseStatus.CASE_CLOSED && isAllowedToCloseCase) {
            toggleDialog();
        }
    };

    const handleViewReport = () => {
        viewReport(caseObject.uuid);
    };

    const closeCase = () => {
        const { uuid } = caseObject;

        caseService.closeCase(uuid).then(() => {
            updateCaseStatus(uuid, CaseStatus.CASE_CLOSED);
        });

        history.push(HOME);
    };

    const renderDownloadButton = () => {
        const isCurrentUserClinicalViewer = userService.checkUserHasRole([UserRole.CLINICAL_VIEWER]);

        if (isCurrentUserClinicalViewer) {
            return <></>;
        }

        if (caseObject?.caseStatus === CaseStatus.CASE_IN_SA_REVIEW) {
            return <></>;
        }

        if (caseObject.report?.status === ReportStatus.GENERATED) {
            return (
                <Button
                    as={Link}
                    to="#"
                    onClick={downloadReport}
                    color="teal"
                    floated="right"
                    className="ui button filled-button small"
                    disabled={pendingRequest}
                    loading={pendingRequest}
                >
                    Download
                </Button>
            );
        }

        if (
            caseObject.report?.status === ReportStatus.FAILED ||
            (caseObject.caseStatus === CaseStatus.CASE_FAILED && checkCaseOlderThan5minutes(caseObject))
        ) {
            return <></>;
        }

        return <></>;
    };

    const renderViewPdfButton = () => {
        if (caseObject?.caseStatus === CaseStatus.CASE_IN_SA_REVIEW) {
            return <></>;
        }
        if (caseObject.report?.status === ReportStatus.GENERATED) {
            return (
                <Button
                    as={Link}
                    to="#"
                    onClick={handleViewReport}
                    color="teal"
                    floated="right"
                    className="ui button filled-button small"
                    disabled={pendingRequest}
                    loading={pendingRequest}
                >
                    View
                </Button>
            );
        }
        if (
            caseObject.report?.status === ReportStatus.FAILED ||
            (caseObject.caseStatus === CaseStatus.CASE_FAILED && checkCaseOlderThan5minutes(caseObject))
        ) {
            return <></>;
        }
        return <></>;
    };

    return (
        (isReportGenerated && (
            <div>
                <PromtCaseReportDialog
                    onClose={toggleDialog}
                    callback={toggleDialog}
                    showDialog={promtCaseReportDialogOpened}
                    onCloseCase={closeCase}
                />
                <Segment className="report-segment">
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column width={1}>
                                <Image alt={ModalIconAltTexts.PDF} src="/images/pdfIcon.png" className="pdf-icon" />
                            </Grid.Column>
                            <Grid.Column className="pdf-name" width={8}>
                                <b>{caseObject.report.path.split("/")[1]}</b>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                {renderDownloadButton()}
                                {renderViewPdfButton()}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>
        )) || <></>
    );
};

export default CasePdfReport;
