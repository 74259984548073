import { Component } from "react";

import AssessmentLesionRow from "components/ReadyForAssessment/AssessmentLesionRow";

import { ICase } from "model/case";
import { ILesionExclusion } from "model/lesionExclusion";
import { IOrganisation } from "model/organisation";

import "scss/ConfirmDialog.scss";
import "scss/Table.scss";

interface IAssessmentLesions {
    caseObject: ICase;
    organisation: IOrganisation;
    showExclusions: boolean;
    isManualUploadRemoteMode?: boolean;
    setExclusionAdded: (lesions: ILesionExclusion[]) => void;
}

class AssessmentLesions extends Component<IAssessmentLesions, any> {
    public render() {
        const { caseObject, organisation, showExclusions, setExclusionAdded, isManualUploadRemoteMode } = this.props;

        if (caseObject.lesions) {
            return caseObject.lesions.map((lesion, index) => (
                <AssessmentLesionRow
                    key={lesion.uuid}
                    caseObject={caseObject}
                    lesionNumber={index}
                    lesion={lesion}
                    organisation={organisation}
                    showExclusions={showExclusions}
                    setExclusionAdded={setExclusionAdded}
                    isManualUploadRemoteMode={isManualUploadRemoteMode}
                />
            ));
        }
        return null;
    }
}
export default AssessmentLesions;
