import { Config } from "config/config";

import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";
import { HttpConnector } from "dao/http/httpConnector";

import { downloadFile, getFilenameFromResponse } from "helpers/downloadFile";
import { FILTER_PARAMETERS, generateParameters } from "helpers/caseList";

import { ICase, IRetakeImagesReq } from "model/case";
import { CaseListTabs, IFilter, IKitDeliveryStatusFilter, ISearch, SortOrder } from "model/caseList";
import { IUser } from "model/user";
import { KitDeliveryStatuses } from "model/remoteModel";
import { IAssessmentBase, ILesion } from "model/assessment";

import store, { Dispatch } from "redux/store";
import * as actions from "redux/actions";

import studyService from "services/studyService";
import * as ErrorService from "services/errorService";
import { LocalStorageService } from "services/localStorageService";

import URLs from "services/urls";

const {
    CREATE_CASE_URL,
    UPDATE_CASE_URL,
    CLOSE_CASE_URL,
    ABANDON_CASE_URL,
    GET_REVIEWED_CASES,
    GET_CASE_URL,
    GET_REPORT_URL,
    GET_IMAGE_URL,
    DOWNLOAD_IMAGES_URL,
    REOPEN_CASE_URL,
    UPLOAD_MACRO_IMAGE,
    UPLOAD_DERMOSCOPIC_IMAGE,
    RETAKE_IMAGES_URL,
    ADD_ADMIN_NOTE,
    LESION_DEFINED_URL,
    CASE_FEEDBACK_URL,
    KIT_STATUS_URL,
    KIT_TRACKIN_URL,
    KIT_TRACKOUT_URL,
    REQUEST_CALLBACK_URL,
} = URLs;

async function setCaseLocation(caseUuid: string, locationUuid: string) {
    try {
        const response = await AuthorizedHttpConnector.sendPut(UPDATE_CASE_URL.replace("{{UUID}}", caseUuid), {
            locationUuid,
        });
        return response.data;
    } catch (err: any) {
        ErrorService.handleError(err, store.store.dispatch);
        throw new Error(err.response.data.errors?.map((error: any) => error.message)?.join(","));
    }
}

function setNonSkinCancer(nonSkinCancer: boolean) {
    return (dispatch: any) => {
        dispatch(actions.assessmentActions.setNonSkinCancer(nonSkinCancer));
    };
}

function createCase(
    consent = false,
    secondaryConsent = false,
    automatedDecisionConsent = false,
    nonSkinCancer?: boolean,
    studyUuid?: string,
    onSuccess: () => void = () => undefined
) {
    return (dispatch: any) => {
        dispatch(actions.assessmentActions.setConsent({ consent, secondaryConsent, automatedDecisionConsent }));
        AuthorizedHttpConnector.sendPost(CREATE_CASE_URL, {
            consent,
            secondaryConsent,
            automatedDecisionConsent,
            nonSkinCancer,
            studyUuid,
        })
            .then((response) => {
                const { data } = response.data;
                LocalStorageService.setCurrentCaseUuid(data.uuid);
                dispatch(actions.assessmentActions.createCaseSuccess(data));
                onSuccess();
                dispatch(ErrorService.cleanErrors());
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
    };
}

function updateCase(
    caseUuid: string,
    consent: boolean,
    secondaryConsent: boolean,
    automatedDecisionConsent: boolean,
    locationUuid?: string
) {
    return (dispatch: any) => {
        dispatch(actions.assessmentActions.setConsent({ consent, secondaryConsent, automatedDecisionConsent }));
        AuthorizedHttpConnector.sendPut(UPDATE_CASE_URL.replace("{{UUID}}", caseUuid), {
            consent,
            secondaryConsent,
            automatedDecisionConsent,
            locationUuid,
        })
            .then((response) => {
                dispatch(actions.assessmentActions.createCaseSuccess(response.data.data));
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
    };
}

function updateCaseSasLink(caseUuid: string, sasLink: string) {
    return AuthorizedHttpConnector.sendPut(UPDATE_CASE_URL.replace("{{UUID}}", caseUuid), { sasLink })
        .then((response) => response)
        .catch((err) => {
            console.error(err);
        });
}

async function getCaseAsync(caseUuid: string, fields?: string[]) {
    try {
        const response = await AuthorizedHttpConnector.sendGet(
            `${GET_CASE_URL}/${caseUuid}${fields ? `?fields=${fields?.join(",")}` : ""}`
        );
        return response.data.data;
    } catch (err: any) {
        ErrorService.handleError(err, store.store.dispatch);
        return new Error(err);
    }
}

function continueAssessment(caseUuid: string): (dispatch: Dispatch) => Promise<void | IAssessmentBase> {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.formErrorActions.cleanFormError());
            const currentCase: ICase = await getCaseAsync(caseUuid);
            const { lesions: currentCaseLesions, patient } = currentCase;
            const currentLesion = currentCaseLesions ? currentCaseLesions.length - 1 : 0;
            const lesion = currentCaseLesions?.[currentLesion] || undefined;
            const isStudy = Boolean(currentCase.studyUuid);

            const currentAssessment: IAssessmentBase = {
                case: currentCase,
                currentLesion,
                lesion,
                patient,
            };

            if (isStudy) {
                currentAssessment.study = { uuid: currentCase.studyUuid };
                await studyService.getUserDevicesAsync(dispatch);
            }

            dispatch(actions.assessmentActions.continueAssessment(currentAssessment));

            return currentAssessment;
        } catch (err) {
            ErrorService.handleError(err, dispatch);
            return undefined;
        }
    };
}

function setManualUploadRemoteMode(manualUploadMode: boolean): (dispatch: Dispatch) => Promise<void> {
    return async (dispatch: Dispatch) => {
        dispatch(actions.assessmentActions.setManualUploadRemote(manualUploadMode));
    };
}

function getCase(caseUuid: string, actionFunction: any) {
    return (dispatch: any) => {
        AuthorizedHttpConnector.sendGet(`${GET_CASE_URL}/${caseUuid}`)
            .then((response) => {
                dispatch(actionFunction(response.data.data));
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
    };
}

function getCaseForAssessment(caseUuid: string) {
    return getCase(caseUuid, actions.assessmentActions.setCase);
}

function setLesion(lesion: ILesion) {
    return (dispatch: any) => {
        dispatch(actions.assessmentActions.setCurrentLesion(lesion));
    };
}

function clearCases() {
    return (dispatch: any) => {
        dispatch(actions.previousCasesActions.clearCases());
    };
}

function clearAssessment() {
    return (dispatch: any) => {
        dispatch(actions.assessmentActions.clearAssessment());
    };
}

async function getBase64Image(imageUuid: string): Promise<string> {
    const getResizedImagesURL = `${GET_IMAGE_URL}?resized=true`;
    const response = await AuthorizedHttpConnector.sendGet(getResizedImagesURL.replace("{{UUID}}", imageUuid));
    return response.data.data.presignedUrl;
}

async function getFullSizeBase64Image(imageUuid: string): Promise<string> {
    const response = await AuthorizedHttpConnector.sendGet(GET_IMAGE_URL.replace("{{UUID}}", imageUuid));
    return response.data.data.presignedUrl;
}

async function getImageFromPresignedUrl(presignedUrl: string): Promise<Blob> {
    const response = await HttpConnector.sendGet(presignedUrl, {}, true);
    return response.data;
}

function downloadReport(caseUuid?: string) {
    return (dispatch: any) => {
        AuthorizedHttpConnector.sendGet(`${GET_REPORT_URL}/${caseUuid}`, true)
            .then((response) => {
                const filename = getFilenameFromResponse(response.headers);
                downloadFile(response.data, filename, {
                    type: "application/pdf",
                });
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
    };
}

async function getDermImages(caseUuid: string) {
    try {
        await AuthorizedHttpConnector.sendPost(
            `${DOWNLOAD_IMAGES_URL}/${caseUuid}`,
            { reason: "Research purpose" },
            true
        ).then((response) => {
            const filename = getFilenameFromResponse(response.headers);
            const blobOptions = {
                type: "application/zip",
            };
            downloadFile(response.data, filename, blobOptions);
        });
    } catch (err) {
        ErrorService.handleError(err);
    }
}

function viewReport(caseUuid?: string, openInNewPage = true) {
    return (dispatch: any) => {
        AuthorizedHttpConnector.sendGet(`${GET_REPORT_URL}/${caseUuid}/view`, true)
            .then(() => {
                const url = `/case-description/${caseUuid}/pdf`;

                if (!openInNewPage) {
                    window.location.replace(url);
                } else {
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("target", openInNewPage ? "_blank" : "");

                    if (openInNewPage) {
                        link.rel = "noreferrer";
                    }

                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
    };
}

async function getReportUrl(caseUuid: string) {
    try {
        const response = await AuthorizedHttpConnector.sendGet(`${GET_REPORT_URL}/${caseUuid}/view`, true);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        return url;
    } catch (err) {
        ErrorService.handleError(err, store.store.dispatch);
    }
    return undefined;
}

async function getCasesAsync(
    tab: string,
    params?: string,
    filters?: IFilter | IKitDeliveryStatusFilter,
    patientFilters?: IFilter,
    sort?: string,
    searchFilter?: ISearch
) {
    const filterString = generateParameters(filters, FILTER_PARAMETERS.FILTER);
    const patientSearch = generateParameters(patientFilters, FILTER_PARAMETERS.PATIENT);
    const search = generateParameters(searchFilter, FILTER_PARAMETERS.SEARCH);
    const isReviewedTab = tab === CaseListTabs.REVIEWED;
    try {
        const url = `${isReviewedTab ? GET_REVIEWED_CASES : GET_CASE_URL}?${sort}${
            params || ""
        }${filterString}${patientSearch}${search}`;
        const cases = await AuthorizedHttpConnector.sendGet(url);
        return cases.data;
    } catch (err) {
        ErrorService.handleError(err, store.store.dispatch);
        return err;
    }
}

async function getCasesNextPage(nextPageUrl: string) {
    const BASE_URL = Config.getReaderMsUrl();
    const url = `${BASE_URL}${nextPageUrl}`;
    try {
        const cases = await AuthorizedHttpConnector.sendGet(url);
        return cases.data;
    } catch (err) {
        ErrorService.handleError(err, store.store.dispatch);
        return err;
    }
}

async function closeCase(caseId: string) {
    await AuthorizedHttpConnector.sendPost(CLOSE_CASE_URL.replace("{uuid}", caseId), {});
}

async function abandonCase(caseId: string, user: IUser, reason?: string) {
    const abandonText = `Abandoned by ${user.surname.toUpperCase()}, ${user.name}`;
    const adandonReason = reason ? ` with reason: '${reason}'` : "";

    await AuthorizedHttpConnector.sendPost(ABANDON_CASE_URL.replace("{uuid}", caseId), {
        abandonReason: abandonText + adandonReason,
    });
}

async function reopenCase(caseId: string) {
    const cases = await AuthorizedHttpConnector.sendPost(REOPEN_CASE_URL.replace("{uuid}", caseId), {});
    return cases.data;
}

async function uploadMacroImage(data: any) {
    try {
        const response = await AuthorizedHttpConnector.sendPost(UPLOAD_MACRO_IMAGE, data);
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.errors?.map((error: any) => error.message)?.join(","));
    }
}

async function uploadDermoscopicImage(data: any) {
    try {
        const response = await AuthorizedHttpConnector.sendPost(UPLOAD_DERMOSCOPIC_IMAGE, data);
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.errors?.map((error: any) => error.message)?.join(","));
    }
}

async function addAdminNoteToCase(caseId: string, note: string) {
    try {
        const url = ADD_ADMIN_NOTE.replace("{{UUID}}", caseId);
        const response = await AuthorizedHttpConnector.sendPost(url, {
            note,
        });
        return response.data;
    } catch (err: any) {
        throw new Error(err);
    }
}

async function lesionDefined(caseUuid: string) {
    const response = await AuthorizedHttpConnector.sendPost(LESION_DEFINED_URL.replace("{{UUID}}", caseUuid), {});
    return response.data;
}

async function createFeedback(caseUuid: string, rating: number, feedback?: string) {
    const feedbackBody = { rating, ...(feedback ? { feedback } : {}) };
    const response = await AuthorizedHttpConnector.sendPost(
        CASE_FEEDBACK_URL.replace("{{UUID}}", caseUuid),
        feedbackBody
    );
    return response.data;
}

async function updateKitDeliveryStatus(caseUuid: string, kitDeliveryStatus: KitDeliveryStatuses) {
    try {
        const url = KIT_STATUS_URL.replace("{{UUID}}", caseUuid);
        const response = await AuthorizedHttpConnector.sendPost(url, {
            kitDeliveryStatus,
        });
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.errors?.map((error: any) => error.message)?.join(","));
    }
}

async function createKitTrackIn(caseUuid: string, trackInNo: string, shippedInDate: Date) {
    try {
        const response = await AuthorizedHttpConnector.sendPost(KIT_TRACKIN_URL.replace("{{UUID}}", caseUuid), {
            trackInNo,
            shippedInDate,
        });
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.errors?.map((error: any) => error.message)?.join(","));
    }
}

async function createKitTrackOut(caseUuid: string, trackOutNo: string, shippedOutDate: Date) {
    try {
        const response = await AuthorizedHttpConnector.sendPost(KIT_TRACKOUT_URL.replace("{{UUID}}", caseUuid), {
            trackOutNo,
            shippedOutDate,
        });
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.errors?.map((error: any) => error.message)?.join(","));
    }
}

async function getCurrentAndPreviousCase() {
    try {
        const url = `${GET_CASE_URL}?sort=creationDate:${SortOrder.DESC}&limit=2`;
        const result = await AuthorizedHttpConnector.sendGet(url);
        const { data: cases } = result.data;
        return cases;
    } catch (err: any) {
        ErrorService.handleError(err, store.store.dispatch);
        return err;
    }
}

async function requestCallback(caseUuid: string) {
    try {
        const response = await AuthorizedHttpConnector.sendPut(REQUEST_CALLBACK_URL.replace("{{UUID}}", caseUuid), {});
        return response.data;
    } catch (err: any) {
        throw new Error(err.response.data.errors?.map((error: any) => error.message)?.join(","));
    }
}

async function retakeImagesRequest(data: IRetakeImagesReq) {
    try {
        const response = await AuthorizedHttpConnector.sendPost(RETAKE_IMAGES_URL, data);
        return response.data;
    } catch (err: any) {
        ErrorService.handleError(err, store.store.dispatch);
        return err;
    }
}

export {
    abandonCase,
    clearAssessment,
    clearCases,
    closeCase,
    continueAssessment,
    createCase,
    downloadReport,
    getBase64Image,
    getFullSizeBase64Image,
    getImageFromPresignedUrl,
    getCaseAsync,
    getCaseForAssessment,
    getCasesAsync,
    getCasesNextPage,
    getDermImages,
    getCurrentAndPreviousCase,
    getReportUrl,
    reopenCase,
    setCaseLocation,
    setNonSkinCancer,
    updateCase,
    updateCaseSasLink,
    viewReport,
    uploadMacroImage,
    uploadDermoscopicImage,
    addAdminNoteToCase,
    lesionDefined,
    createFeedback,
    updateKitDeliveryStatus,
    createKitTrackIn,
    createKitTrackOut,
    requestCallback,
    retakeImagesRequest,
    setManualUploadRemoteMode,
    setLesion,
};
