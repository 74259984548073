import React, { Component, ReactNode } from "react";
import { Message } from "semantic-ui-react";

enum MessageType {
    ERROR = "negative",
    SUCCESS = "positive",
    WARNING = "warning",
}
interface IValidator {
    type: string;
    text: string | ReactNode;
    title: string;
    user?: string;
    visible?: boolean;
    dismiss?: any;
}

class MessageDismissable extends Component<IValidator, any> {
    public messageMap = {
        ERROR: "Something went wrong...",
        WARNING: "Watch out!",
    };

    constructor(props: IValidator) {
        super(props);

        const { visible } = this.props;

        this.state = {
            isNegative: true,
            isPositive: false,
            isWarning: false,
            visible,
        };
    }

    public componentDidMount() {
        const { type } = this.props;

        if (MessageType[type] === "negative") {
            this.setState({ isNegative: true });
        } else if (MessageType[type] === "positive") {
            this.setState({ isPositive: true });
        } else if (MessageType[type] === "warning") {
            this.setState({ isWarning: true });
        }
    }

    public handleDismiss = () => {
        this.setState({
            visible: false,
        });
    };

    public makeVisible = () => {
        const { type } = this.props;

        if (MessageType[type] === "negative") {
            this.setState({ isNegative: true });
        }
        if (MessageType[type] === "positive") {
            this.setState({ isPositive: true });
        }
        if (MessageType[type] === "warning") {
            this.setState({ isWarning: true });
        }
        this.setState({ visible: true });
    };

    public render() {
        const { type, dismiss, text } = this.props;
        const { isPositive, isWarning, isNegative, visible } = this.state;

        if (visible) {
            return (
                <Message
                    positive={isPositive}
                    warning={isWarning}
                    negative={isNegative}
                    onDismiss={dismiss}
                    visible={visible}
                >
                    <Message.Header> {this.messageMap[type]} </Message.Header>
                    <Message.Content>{text}</Message.Content>
                </Message>
            );
        }

        return <br />;
    }
}

export default MessageDismissable;
