import types from "redux/actions/types";

const INITIAL_STATE = {
    authToken: undefined,
    authTokenBody: undefined,
    changePasswordCompleted: undefined,
    refreshToken: undefined,
    signInError: undefined,
    signInLoading: false,
    signOutLoading: false,
    signedIn: false,
    signedOut: false,
    resetPasswordModalType: "",
    showUpdatePasswordPrompt: false,
};

const authReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                authToken: undefined,
                signInError: undefined,
                signInLoading: true,
            };
        case types.SET_TOKEN:
            return {
                ...state,
                authToken: action.payload.token,
                authTokenBody: action.payload.authTokenBody,
                refreshToken: action.payload.refreshToken,
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                signInError: undefined,
                signInLoading: false,
                signedIn: true,
                signedOut: false,
            };
        case types.REFRESH_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.payload,
            };
        case types.LOGIN_FAIL:
            return {
                ...state,
                signInError: action.payload,
                signInLoading: false,
                signedIn: false,
            };
        case types.LOGOUT_REQUEST:
            return {
                ...state,
                signOutError: undefined,
                signOutLoading: true,
            };
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                authToken: undefined,
                authTokenBody: undefined,
                changePasswordCompleted: undefined,
                refreshToken: undefined,
                signInError: undefined,
                signInLoading: false,
                signOutLoading: false,
                signedIn: false,
                signedOut: true,
                showUpdatePasswordPrompt: false,
            };
        case types.LOGOUT_FAIL:
            return {
                ...state,
                authToken: undefined,
                refreshToken: undefined,
                signInError: undefined,
                signInLoading: false,
                signOutLoading: false,
                signedIn: false,
                signedOut: true,
                showUpdatePasswordPrompt: false,
            };
        case types.CLEAR_DATA:
            return {
                ...state,
                authToken: undefined,
                authTokenBody: undefined,
                changePasswordCompleted: undefined,
                refreshToken: undefined,
                signInError: undefined,
                signInLoading: false,
                signOutLoading: false,
                signedIn: false,
                signedOut: false,
                showUpdatePasswordPrompt: false,
            };
        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordCompleted: action.payload,
                changePasswordError: undefined,
            };
        case types.CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changePasswordCompleted: undefined,
                changePasswordError: action.payload,
            };
        case types.CHANGE_PASSWORD_RESET:
            return {
                ...state,
                changePasswordCompleted: undefined,
                changePasswordError: undefined,
            };
        case types.CLEAR_PASSWORD_RESPONSE:
            return INITIAL_STATE;
        case types.SET_RESET_PASSWORD_MODAL_TYPE:
            return {
                ...state,
                resetPasswordModalType: action.payload,
            };
        case types.USER_CLEAR_DATA:
            return {
                ...state,
                resetPasswordModalType: INITIAL_STATE.resetPasswordModalType,
            };
        case types.UPDATE_PASSWORD:
            return {
                ...state,
                signInLoading: false,
                signInError: undefined,
            };
        case types.UPDATE_PASSWORD_SHOW_PROMPT:
            return {
                ...state,
                showUpdatePasswordPrompt: true,
            };
        case types.UPDATE_PASSWORD_RESET:
            return {
                ...state,
                showUpdatePasswordPrompt: false,
            };
        default:
            return state;
    }
};

export default authReducer;
