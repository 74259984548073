import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Divider } from "semantic-ui-react";

import CreateOrganisationComponent from "components/Administration/OrganisationManagment/CreateOrganisation/CreateOrganisationComponent";
import OrganisationList from "components/Administration/OrganisationManagment/OrganisationList";
import OrganisationDetailsScreen from "components/Administration/OrganisationManagment/ViewOrganisation/OrganisationDetailsScreen";

import { OrganisationManagementSubpages } from "model/administrationPages";
import { UserRole } from "model/userRole";

import userService from "services/userService";

interface IUserManagmentScreenProps {
    params: any;
}

class OrganisationManagmentScreen extends Component<IUserManagmentScreenProps> {
    private getContext() {
        const { params } = this.props;
        const { subpage = null } = { ...params };
        if (subpage) {
            const isCreateOrganisation = subpage === OrganisationManagementSubpages.CREATE_ORGANISATION;
            const isCreateSuborganisation = subpage === OrganisationManagementSubpages.CREATE_SUBORGANISATION;
            if (isCreateOrganisation || isCreateSuborganisation) {
                return <CreateOrganisationComponent params={params} />;
            }
            const isOrganisationDetails = subpage === OrganisationManagementSubpages.ORGANISATION_DETAILS;
            if (isOrganisationDetails) {
                return <OrganisationDetailsScreen params={params} />;
            }
        }

        return <OrganisationList />;
    }

    public render() {
        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        return (
            <div>
                <h1>Organisation Management</h1>
                <Divider />
                {this.getContext()}
            </div>
        );
    }
}

export default OrganisationManagmentScreen;
