import { FC } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { InputLabel } from "@material-ui/core";
import moment, { Moment } from "moment";

import "scss/InputDateField.scss";

const SHOW_DATE_FORMAT = "DD/MM/YYYY";
const DATE_FIELD_INPUT_DATE_FORMAT = "YYYY-MM-DD";

interface IInputDateField {
    value: string | null;
    maxDate?: Moment;
    label?: string;
    boldLabel?: boolean;
    onChange: (value: Moment | null) => void;
    required?: boolean;
    dateError?: string | boolean;
    className?: string;
    disabled?: boolean;
    id?: string;
    minDate?: Moment;
    openTo?: "day" | "month" | "year" | "";
}

const InputDateField: FC<IInputDateField> = ({
    value,
    maxDate,
    onChange,
    required,
    dateError,
    className,
    label,
    disabled,
    boldLabel,
    id,
    minDate,
    openTo,
}) => (
    <DatePicker
        className={`input-date-field-date-picker ${className}`}
        value={value ? moment(value, DATE_FIELD_INPUT_DATE_FORMAT) : null}
        onChange={onChange}
        disabled={disabled}
        inputFormat={SHOW_DATE_FORMAT}
        maxDate={maxDate || null}
        minDate={minDate || null}
        openTo={openTo || "day"}
        renderInput={(params) => (
            <>
                {label ? (
                    <InputLabel
                        className={`input-date-field label ${boldLabel ? "bold-label" : ""}`}
                        required={required}
                        htmlFor={`${label}`}
                        disabled={disabled}
                    >
                        {label}
                    </InputLabel>
                ) : null}
                <TextField
                    id={`${label || id}`}
                    className="input-date-field"
                    {...params}
                    error={Boolean(dateError)}
                    helperText={(dateError as string)?.length ? dateError : ""}
                />
            </>
        )}
    />
);

export default InputDateField;
