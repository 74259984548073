import { FC, useContext, useEffect, useState } from "react";
import { Grid, Segment } from "semantic-ui-react";

import { ORGANISATION_INHERITANCE_PROPERTY } from "components/Administration/OrganisationManagment/ViewOrganisation/OrganisationDetailsScreen";
import ReviewSettingsContext from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ReviewSettingsProvider";
import CreateDiagnosesScreen from "components/Administration/OrganisationManagment/ViewOrganisation/Review/CreateDiagnosesScreen";
import DiagnosesTable from "components/Administration/OrganisationManagment/ViewOrganisation/Review/DiagnosesTable";
import StickyOrganisationTopPanel from "components/Administration/OrganisationManagment/ViewOrganisation/StickyOrganisationTopPanel";
import LoadingSpinner from "components/templates/LoadingSpinner";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";

import { scrollToTop } from "helpers/page";

import { OrganisationManagementTabs, OrganisationMenuItems } from "model/administrationPages";
import { IOrganisation } from "model/organisation";
import { ReviewSettingMode, ReviewType } from "model/reviewType";

import organizationService from "services/organizationService";

interface IReviewSettingsScreenProps {
    organisation: IOrganisation;
    showEditButton: boolean;
    updateInheritanceData: (property: string) => void;
}

const ReviewSettingsScreen: FC<IReviewSettingsScreenProps> = ({
    showEditButton,
    updateInheritanceData,
    organisation,
}) => {
    const {
        state: { reviewDiagnoses, reviewManagementOptions, reviewSettingMode, organisation: org },
        dispatchers: { changeReviewSettingMode, setLoadedOrganisation, startEditDiagnosis },
    } = useContext(ReviewSettingsContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        organizationService
            .getOrganisationByUuid(organisation.uuid)
            .then((loadedOrg) => {
                setLoadedOrganisation(loadedOrg);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);

    const isViewMode = reviewSettingMode === ReviewSettingMode.VIEW;
    const isCreateMode = reviewSettingMode === ReviewSettingMode.CREATE;
    const isEditMode = reviewSettingMode === ReviewSettingMode.EDIT;

    const undefinedReviewDiagnoses = org?.reviewDiagnoses?.filter((diagnosis) => diagnosis.reviewType === undefined);

    const isUndefinedReviewDiagnosis = undefinedReviewDiagnoses && !!undefinedReviewDiagnoses.length;

    const onChangeMode = (): void => {
        if (org) {
            switch (reviewSettingMode) {
                case ReviewSettingMode.CREATE: {
                    changeReviewSettingMode(ReviewSettingMode.VIEW);
                    break;
                }
                case ReviewSettingMode.VIEW: {
                    changeReviewSettingMode(ReviewSettingMode.CREATE);
                    break;
                }
                case ReviewSettingMode.EDIT: {
                    changeReviewSettingMode(ReviewSettingMode.VIEW);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    const handleEditClicked = (event: any, data: any): void => {
        const { uuid } = data;
        startEditDiagnosis(uuid);
        scrollToTop();
    };

    const updateOrganisation = (result: any): void => {
        const updatedOrganisation = { ...org };
        const { diagnoses, managementOptions } = result;

        updatedOrganisation.reviewManagementOptions = managementOptions;
        updatedOrganisation.reviewDiagnoses = diagnoses;

        updateInheritanceData(ORGANISATION_INHERITANCE_PROPERTY[OrganisationMenuItems.REVIEW_SETTINGS]);
    };

    if (isLoading && !org) {
        return <LoadingSpinner />;
    }

    return (
        <Segment>
            <StickyOrganisationTopPanel title="Review diagnoses">
                <OrganisationManagementTabHeader
                    organisationUuid={org ? org.uuid : ""}
                    type={OrganisationManagementTabs.REVIEW_SETTINGS}
                    showEditButton={showEditButton}
                    updateOrganisation={updateOrganisation}
                    createMode={!isViewMode}
                    changeMode={onChangeMode}
                />
            </StickyOrganisationTopPanel>

            {isViewMode && (
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Safety Net Assessment</h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <DiagnosesTable
                                diagnosisType={ReviewType.SAFETY_NET}
                                handleEdit={handleEditClicked}
                                reviewDiagnoses={reviewDiagnoses}
                                reviewManagementOptions={reviewManagementOptions}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h4>Client Assessment</h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <DiagnosesTable
                                diagnosisType={ReviewType.CLIENT}
                                handleEdit={handleEditClicked}
                                reviewDiagnoses={reviewDiagnoses}
                                reviewManagementOptions={reviewManagementOptions}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {isUndefinedReviewDiagnosis && (
                        <>
                            <Grid.Row>
                                <Grid.Column>
                                    <h4>Diagnosis without review type</h4>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <DiagnosesTable
                                        reviewDiagnoses={reviewDiagnoses}
                                        reviewManagementOptions={reviewManagementOptions}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                </Grid>
            )}

            {isCreateMode || isEditMode ? (
                <CreateDiagnosesScreen
                    mode={reviewSettingMode}
                    allowedTypes={[ReviewType.CLIENT, ReviewType.SAFETY_NET]}
                />
            ) : null}
        </Segment>
    );
};

export default ReviewSettingsScreen;
