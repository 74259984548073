import { useState, SyntheticEvent } from "react";
import { InputOnChangeData, TextAreaProps } from "semantic-ui-react";

import { IApiResponse } from "model/request";
import { NoteType } from "model/reviewNotes";

import * as caseService from "services/caseService";
import reviewService from "services/reviewService";

interface IUseCaseNoteProps {
    caseUuid: string;
    patientUuid: string;
    previousNoteText?: string;
    noteType: NoteType;
    updateCaseNotes?: (type: NoteType, note: string, noteUuid: string, organisationUuid: string) => void;
    removeNote?: (type: NoteType, noteUuid: string) => void;
}

interface IUseCaseNote {
    caseNote: string;
    handleCaseNoteChange: (event: SyntheticEvent<HTMLElement>, data: InputOnChangeData | TextAreaProps) => void;
    submitCaseNote: () => Promise<IApiResponse>;
    removeCaseNote: (noteUuid: string) => Promise<IApiResponse>;
    updateCaseNote?: (noteUuid: string, noteText: string, isSuperseding: boolean) => Promise<IApiResponse>;
    setCaseNote?: (note: string) => void;
}

const useCaseNote = ({
    caseUuid,
    patientUuid,
    previousNoteText,
    noteType,
    updateCaseNotes,
    removeNote,
}: IUseCaseNoteProps): IUseCaseNote => {
    const [caseNote, setCaseNote] = useState<string>(previousNoteText || "");
    const trimmedCaseNote = caseNote.trim();

    const handleCaseNoteChange = (
        event: SyntheticEvent<HTMLElement>,
        data: InputOnChangeData | TextAreaProps
    ): void => {
        const { value } = data;
        setCaseNote(value as string);
    };

    const submitCaseNote = async (supersedes?: string): Promise<IApiResponse> => {
        const isAdminNotes = noteType === NoteType.ADMIN;
        let submitNoteResult;
        if (isAdminNotes) {
            submitNoteResult = await caseService.addAdminNoteToCase(caseUuid, trimmedCaseNote);
        } else {
            submitNoteResult = await reviewService.submitNote(
                caseUuid,
                patientUuid,
                noteType,
                trimmedCaseNote,
                supersedes
            );
        }

        if (updateCaseNotes) {
            const { uuid, organisationUuid } = submitNoteResult?.data?.data;
            updateCaseNotes(noteType, trimmedCaseNote, uuid, organisationUuid);
        }
        return submitNoteResult;
    };

    const removeCaseNote = async (noteUuid): Promise<IApiResponse> => {
        const isClinicalNote = noteType === NoteType.CLINICAL;
        let removeNoteResult;

        if (isClinicalNote) {
            removeNoteResult = await reviewService.removeNote(noteUuid);
        }
        if (removeNote) {
            removeNote(noteType, noteUuid);
        }
        return removeNoteResult;
    };

    const updateCaseNote = async (
        noteUuid: string,
        noteText: string,
        isSuperseding: boolean
    ): Promise<IApiResponse> => {
        const isClinicalNote = noteType === NoteType.CLINICAL;
        let updateNoteResult;

        if (isClinicalNote) {
            updateNoteResult = await reviewService.updateNote(noteUuid, noteText.trim(), isSuperseding);
        }
        return updateNoteResult;
    };

    return {
        caseNote,
        handleCaseNoteChange,
        submitCaseNote,
        removeCaseNote,
        updateCaseNote,
        setCaseNote,
    };
};

export default useCaseNote;
