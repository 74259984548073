import { history } from "App";
import * as ROUTES from "navigation/routes";

import store from "redux/store";
import * as actions from "redux/actions";

const logoutTime = 1000 * 60 * Number(process.env.REACT_APP_LOGOUT_TIME);

// auto logout should be performed after ${logoutTime} on the following pages:
// - /home;
// - /administration;
// - /case-description;
// - /change-password;
// - /guidance;
// - /patient/in-review;
// - /patient/report;
// - /request-image-reassessment;

// auto logout should not be performed at all on the following pages:
// - /clinic-location;
// - /new-assessment;
// - /patient-details;
// - /patient-search;
// - /lesion-locator;
// - /medical-history;
// - /image-capture;
// - /image-manual-upload;
// - /ready-for-assessment;
// - /patient/user-details;
// - /patient/consent;
// - /patient/start-case-creation;
// - /patient/medical-history;
// - /patient/kit-delivery;
// - /patient/lesion-details;
// - /patient/lesion-images;
// - /patient/summary.

class LogoutTimeoutService {
    private timeoutIds: number[] = [];

    public startCount() {
        this.stopCount();
        const timeoutId = window.setTimeout(this.logoutAfterTimeout.bind(this), logoutTime);
        this.timeoutIds.push(timeoutId);
    }

    public stopCount() {
        this.timeoutIds.forEach((timeoutId) => {
            window.clearTimeout(timeoutId);
        });
        this.timeoutIds = [];
    }

    private logoutAfterTimeout() {
        if (store.store.getState().auth.authToken) {
            this.stopCount();
            store.store.dispatch(actions.authActions.clearData());
            history.push(ROUTES.LOGIN);
        }
    }
}

export default new LogoutTimeoutService();
