import { DropdownItemProps } from "semantic-ui-react";

import { validEmail } from "helpers/common";

import { OrganisationMenuItems } from "model/administrationPages";
import {
    IDefinedPersonalData,
    NotificationType,
    IOrganisation,
    IOverdue,
    NotificationTypeText,
    IIdentifierValidationErrors,
    INewDefinedPersonalData,
    IOwnProperties,
    IModifiedInheritedProperties,
    DefinedPersonalDataType,
    validationTypeNoneOption,
    validationTypeMinumumAgeOption,
    validationTypeNhsNumberOption,
    validationTypeEmailOption,
    validationTypeHospitalNumberOption,
} from "model/organisation";
import { CaseStatusDictionary } from "model/caseStatus";

export const PERSONAL_DATA_VALUE_MAX_LENGTH = 50;
export const PERSONAL_DATA_DISPLAY_ORDER_MAX_LENGTH = 3;
const NUMBER_REGEX = /^[0-9]+$/;

export const getOrganisationPdfReportReceivers = (organisation: IOrganisation) => {
    const { notificationConfiguration } = organisation;
    const pdfReportNotificationConfiguration = notificationConfiguration?.filter(
        (config) => config.notificationType === NotificationType.reportGenerated && !config.removed
    );

    return pdfReportNotificationConfiguration?.map((conf) => conf.organisationEmail).join(", ");
};

const hasOwnProperties = (settingsSet: any[], parentUuid: string) => {
    if (settingsSet) {
        const uniqueProperty = settingsSet.find((item) => item.organisationUuid !== parentUuid);
        return !!uniqueProperty;
    }
    return false;
};

const getOwnProperties = (settingsSet: any[]): IOwnProperties => {
    const displayNotificationChanges = (notificationType: string, caseStatus: string) =>
        `${notificationType ? `${NotificationTypeText[notificationType]}` : ""} ${
            caseStatus ? `${CaseStatusDictionary[caseStatus]}` : ""
        }`;
    const response: IOwnProperties = { removed: [], added: [] };

    if (settingsSet) {
        response.removed = settingsSet
            .filter(({ removed, copyOfUuid }) => removed && copyOfUuid)
            .map(
                ({ question, diagnosis, exclusion, allocation, name, propertyName, notificationType, caseStatus }) =>
                    question ||
                    diagnosis ||
                    exclusion ||
                    allocation ||
                    name ||
                    propertyName ||
                    (notificationType && displayNotificationChanges(notificationType, caseStatus))
            );
        response.added = settingsSet
            .filter(({ removed, copyOfUuid }) => !copyOfUuid && !removed)
            .map(
                ({ question, diagnosis, exclusion, allocation, name, propertyName, notificationType, caseStatus }) =>
                    question ||
                    diagnosis ||
                    exclusion ||
                    allocation ||
                    name ||
                    propertyName ||
                    (notificationType && displayNotificationChanges(notificationType, caseStatus))
            );
    }
    return response;
};

const getEditedProperties = (settingsSet: IOverdue[], parentUuid: string): IOwnProperties => {
    const response: IOwnProperties = { removed: [], added: [], edited: [] };
    if (settingsSet) {
        response.edited = settingsSet
            .filter(({ organisationUuid }) => organisationUuid !== parentUuid)
            .map(({ caseStatus }) => `Status ${CaseStatusDictionary[caseStatus]}`);
    }
    return response;
};

export const getModifiedInheritedProperties = (
    organisation: IOrganisation,
    withDetails: boolean
): IModifiedInheritedProperties => {
    const {
        parentName,
        definedPersonalData,
        templates,
        medicalHistoryQuestions,
        lesionHistoryQuestions,
        lesionExclusions,
        biopsyQuestions,
        reviewDiagnoses,
        allocationConfiguration,
        parentUuid,
        overdueDefinitions,
        notificationConfiguration,
    } = organisation;

    const modifiedInheritedProperties: IModifiedInheritedProperties = {};
    if (parentName) {
        if (hasOwnProperties(allocationConfiguration, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.CASE_ALLOCATION] = withDetails
                ? getOwnProperties(allocationConfiguration)
                : "Case Allocation";
        }

        if (hasOwnProperties(definedPersonalData, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.PERSONAL_DATA] = withDetails
                ? getOwnProperties(definedPersonalData)
                : "Personal Data";
        }

        if (hasOwnProperties(notificationConfiguration, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.NOTIFICATIONS] = withDetails
                ? getOwnProperties(notificationConfiguration)
                : "Notifications";
        }

        if (hasOwnProperties(templates, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.TEMPLATES] = withDetails
                ? getOwnProperties(templates)
                : "Templates";
        }

        if (hasOwnProperties(medicalHistoryQuestions, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.MEDICAL_HISTORY] = withDetails
                ? getOwnProperties(medicalHistoryQuestions)
                : "Medical History";
        }
        if (hasOwnProperties(lesionHistoryQuestions, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.LESION_HISTORY] = withDetails
                ? getOwnProperties(lesionHistoryQuestions)
                : "Lesion History";
        }
        if (hasOwnProperties(biopsyQuestions, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.BIOPSY] = withDetails
                ? getOwnProperties(biopsyQuestions)
                : "Biopsy History";
        }
        if (hasOwnProperties(lesionExclusions, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.EXCLUSIONS] = withDetails
                ? getOwnProperties(lesionExclusions)
                : "Exclusions";
        }
        if (hasOwnProperties(reviewDiagnoses, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.REVIEW_SETTINGS] = withDetails
                ? getOwnProperties(reviewDiagnoses)
                : "Review Settings";
        }
        if (hasOwnProperties(overdueDefinitions, parentUuid)) {
            modifiedInheritedProperties[OrganisationMenuItems.OVERDUE_SETTINGS] = withDetails
                ? getEditedProperties(overdueDefinitions, parentUuid)
                : "Overdue Settings";
        }
    }

    return modifiedInheritedProperties;
};

export const removeNullProperties = (object: IDefinedPersonalData) => {
    const obj: IDefinedPersonalData = object;

    Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
};

export const validOrganisationEmail = (email: string) => (email?.length ? validEmail(email) : true);

export const isStringFieldValueEmpty = (fieldValue: string): boolean => fieldValue === null || fieldValue === "";

export const emptyPersonalDataFieldValueErrorMessage = "This field cannot be empty";

const personalDataRequiredFieldErrorMessage = "This field must be true if property is identifier";

export const validateDisplayOrderPersonalField = (
    fieldValue: string,
    definedPersonalData: IDefinedPersonalData[]
): string => {
    if (isStringFieldValueEmpty(fieldValue)) {
        return emptyPersonalDataFieldValueErrorMessage;
    }
    if (!NUMBER_REGEX.test(fieldValue)) {
        return "Only digits allowed";
    }

    if (definedPersonalData.find((item: IDefinedPersonalData) => item.displayOrder === Number(fieldValue))) {
        return "Value must be unique";
    }

    return "";
};

export const validateIdentifierPersonalField = (
    fieldValue: boolean,
    personalData: IDefinedPersonalData | INewDefinedPersonalData,
    definedPersonalData: IDefinedPersonalData[]
): IIdentifierValidationErrors => {
    const error = { identifierError: "", requiredError: "" };
    const identifierDuplicate: IDefinedPersonalData = definedPersonalData.find(
        (item: IDefinedPersonalData) =>
            item.identifier && !item.removed && item.propertyName !== personalData.propertyName
    );
    error.identifierError = fieldValue && Boolean(identifierDuplicate) ? "Only one property can be the identifier" : "";

    if (!personalData.required && fieldValue) {
        error.requiredError = personalDataRequiredFieldErrorMessage;
    }

    return error;
};

export const validateRequiredPersonalField = (
    fieldValue: boolean,
    personalData: IDefinedPersonalData | INewDefinedPersonalData
): string => {
    if (personalData.identifier && !fieldValue) {
        return personalDataRequiredFieldErrorMessage;
    }
    return "";
};

export const validateDisplayNamePersonalField = (
    fieldValue: string,
    definedPersonalData: IDefinedPersonalData[]
): string => {
    if (isStringFieldValueEmpty(fieldValue)) {
        return emptyPersonalDataFieldValueErrorMessage;
    }
    const nameDuplicate: IDefinedPersonalData = definedPersonalData.find(
        (item: IDefinedPersonalData) => item.displayName === fieldValue
    );

    if (nameDuplicate) {
        return "Display name should be unique";
    }
    return "";
};

export const getValidationPropertyOptions = (type: DefinedPersonalDataType): DropdownItemProps[] => {
    const isDateType = type === DefinedPersonalDataType.DATE;
    const isTextType = type === DefinedPersonalDataType.TEXT;
    const validationPropertyOptions = [
        validationTypeNoneOption,
        ...(isDateType ? [validationTypeMinumumAgeOption] : []),
        ...(isTextType
            ? [validationTypeNhsNumberOption, validationTypeEmailOption, validationTypeHospitalNumberOption]
            : []),
    ];
    return validationPropertyOptions;
};
