export enum LesionLocation {
    SCALP = "Scalp",
    FOREHEAD = "Forehead",
    EYE_LEFT = "Eye - Left",
    EYE_RIGHT = "Eye - Right",
    TEMPLE_LEFT = "Temple - Left",
    TEMPLE_RIGHT = "Temple - Right",
    EAR_LEFT = "Ear - Left",
    EAR_RIGHT = "Ear - Right",
    NOSE = "Nose",
    CHEEK_LEFT = "Cheek - Left",
    CHEEK_RIGHT = "Cheek - Right",
    LIPS = "Lips",
    CHIN = "Chin",
    FACE = "Face",
    NECK = "Neck",
    CHEST = "Chest",
    SHOULDER_LEFT = "Shoulder - Left",
    SHOULDER_RIGHT = "Shoulder - Right",
    FLANK_SIDE_LEFT = "Flank/Side - Left",
    FLANK_SIDE_RIGHT = "Flank/Side - Right",
    ABDOMEN = "Abdomen",
    BACK = "Back",
    ARM_LEFT = "Arm - Left",
    ARM_RIGHT = "Arm - Right",
    HAND_LEFT = "Hand - Left",
    HAND_RIGHT = "Hand - Right",
    GROIN = "Groin",
    BUTTOCK_LEFT = "Buttock - Left",
    BUTTOCK_RIGHT = "Buttock - Right",
    LEG_LEFT = "Leg - Left",
    LEG_RIGHT = "Leg - Right",
    FOOT_LEFT = "Foot - Left",
    FOOT_RIGHT = "Foot - Right",
}

export enum LesionLocationNsc {
    SCALP = "Scalp",
    FOREHEAD = "Forehead",
    EYE_LEFT = "Eye - Left",
    EYE_RIGHT = "Eye - Right",
    TEMPLE_LEFT = "Temple - Left",
    TEMPLE_RIGHT = "Temple - Right",
    EAR_LEFT = "Ear - Left",
    EAR_RIGHT = "Ear - Right",
    NOSE = "Nose",
    CHEEK_LEFT = "Cheek - Left",
    CHEEK_RIGHT = "Cheek - Right",
    LIPS = "Lips",
    CHIN = "Chin",
    FACE = "Face",
    NECK = "Neck",
    CHEST = "Chest",
    SHOULDER_LEFT = "Shoulder - Left",
    SHOULDER_RIGHT = "Shoulder - Right",
    FLANK_SIDE_LEFT = "Flank/Side - Left",
    FLANK_SIDE_RIGHT = "Flank/Side - Right",
    ABDOMEN = "Abdomen",
    BACK = "Back",
    ARM_LEFT = "Arm - Left",
    ARM_RIGHT = "Arm - Right",
    HAND_LEFT = "Hand - Left",
    HAND_RIGHT = "Hand - Right",
    GROIN = "Groin",
    BUTTOCK_LEFT = "Buttock - Left",
    BUTTOCK_RIGHT = "Buttock - Right",
    LEG_LEFT = "Leg - Left",
    LEG_RIGHT = "Leg - Right",
    FOOT_LEFT = "Foot - Left",
    FOOT_RIGHT = "Foot - Right",
    MULTIPLE = "Multiple",
}

export enum AllLesionLocation {
    HEAD = "Head",
    NECK = "Neck",
    CHEST = "Chest",
    SHOULDER_LEFT = "Shoulder - Left",
    SHOULDER_RIGHT = "Shoulder - Right",
    FLANK_SIDE_LEFT = "Flank/Side - Left",
    FLANK_SIDE_RIGHT = "Flank/Side - Right",
    ABDOMEN = "Abdomen",
    BACK = "Back",
    ARM_LEFT = "Arm - Left",
    ARM_RIGHT = "Arm - Right",
    HAND_LEFT = "Hand - Left",
    HAND_RIGHT = "Hand - Right",
    GROIN = "Groin",
    BUTTOCK_LEFT = "Buttock - Left",
    BUTTOCK_RIGHT = "Buttock - Right",
    LEG_LEFT = "Leg - Left",
    LEG_RIGHT = "Leg - Right",
    FOOT_LEFT = "Foot - Left",
    FOOT_RIGHT = "Foot - Right",
    MULTIPLE = "Multiple",
    FOREHEAD = "Forehead",
    NOSE = "Nose",
    CHEEK_LEFT = "Cheek - Left",
    CHEEK_RIGHT = "Cheek - Right",
    CHIN = "Chin",
    SCALP = "Scalp",
    EAR_LEFT = "Ear - Left",
    EAR_RIGHT = "Ear - Right",
    EYE_LEFT = "Eye - Left",
    EYE_RIGHT = "Eye - Right",
    TEMPLE_LEFT = "Temple - Left",
    TEMPLE_RIGHT = "Temple - Right",
    LIPS = "Lips",
    FACE = "Face",
}
