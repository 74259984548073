import React, { Component } from "react";
import { Button, Divider, Form, Grid, Segment, Table } from "semantic-ui-react";

import StickyOrganisationTopPanel from "components/Administration/OrganisationManagment/ViewOrganisation/StickyOrganisationTopPanel";
import ExclusionRow from "components/Administration/OrganisationManagment/ViewOrganisation/Exclusions/ExclusionRow";
import { ORGANISATION_INHERITANCE_PROPERTY } from "components/Administration/OrganisationManagment/ViewOrganisation/OrganisationDetailsScreen";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";

import { scrollBottom } from "helpers/page";

import { OrganisationManagementTabs, OrganisationMenuItems } from "model/administrationPages";
import { ILesionExclusion, IOrganisation } from "model/organisation";

import questionService from "services/questionService";

interface IEditHistoryQuestionComponentProps {
    title: string;
    exclusions?: ILesionExclusion[];
    organisation: IOrganisation;
    error?: any;
    showEditButton: boolean;
    updateInheritanceData: (property: string) => void;
}

class EditExclusionsComponent extends Component<IEditHistoryQuestionComponentProps, any> {
    constructor(props: any) {
        super(props);
        const { exclusions } = this.props;
        this.state = {
            createMode: false,
            edit: {},
            errors: {
                newExclusion: false,
                newOrder: false,
                nonExcluding: false,
            },
            exclusions: exclusions
                ? exclusions
                      .filter((exclusion) => !exclusion.removed)
                      .sort((a, b) => a.exclusionOrder - b.exclusionOrder)
                : [],
        };
    }

    private getExclusionsTable() {
        return (
            <Table padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>Question</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Order</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Non excluding</Table.HeaderCell>
                        <Table.HeaderCell width={1} />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.getExclusionsTableBody()}</Table.Body>
            </Table>
        );
    }

    private getAddNewExclusionTable() {
        const { submitting } = this.state;

        return (
            <div>
                <Table padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={5}>Exclusion</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Order</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Non excluding</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.addNewExclusion()}</Table.Body>
                </Table>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={16} />
                        <Grid.Column floated="right" width={3}>
                            <Button loading={submitting} onClick={this.onCreateNewExclusion}>
                                Add new exclusion
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    private getExclusionsTableBody() {
        const { organisation } = this.props;
        const { exclusions } = this.state;

        if (exclusions && exclusions.length > 0) {
            return exclusions.map((exclusion: any) => (
                <ExclusionRow
                    key={exclusion.uuid}
                    exclusion={exclusion}
                    onRemove={this.onRemoveRow}
                    organisation={organisation}
                />
            ));
        }

        return (
            <Table.Row key="none">
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
            </Table.Row>
        );
    }

    private changeMode = () => {
        const { createMode } = this.state;
        this.setState({ createMode: !createMode });
        if (!createMode) {
            scrollBottom();
        }
    };

    private onCreateNewExclusion = () => {
        const { organisation } = this.props;
        const { exclusions, edit } = this.state;

        if (!this.validateSubmit()) {
            this.setState({ submitting: true });

            questionService
                .createExclusion(organisation.uuid, {
                    exclusion: edit.newExclusion,
                    exclusionOrder: Number(edit.newOrder),
                    nonExcluding: edit.newNonExcluding,
                })
                .then((response: any) => {
                    // workaround for non consistency in backend response
                    exclusions.push(response.exclusion);
                    this.setState({
                        edit: {
                            newExclusion: "",
                            newOrder: "",
                            nonExcluding: false,
                        },
                        exclusions,
                        submitting: false,
                    });
                })
                .catch(() => {
                    this.setState({ submitting: false });
                });
        }
    };

    private onNewExclusionChange = (event: any, obj: any) => {
        this.onChange(event, obj);
        this.validateNewExclusion();
    };

    private onNewOrderChange = (event: any, obj: any) => {
        this.onChange(event, obj);
        this.validateNewOrder(obj.value);
    };

    private onChange = (event: any, obj: any) => {
        const { edit } = this.state;

        const fieldName = obj.id;
        const fieldValue = obj.type === "checkbox" ? obj.checked : obj.value;
        edit[fieldName] = fieldValue;

        this.setState({ edit });
    };

    private updateOrganisation = (result: any) => {
        const { updateInheritanceData } = this.props;

        this.setState({ exclusions: result.exclusions });
        updateInheritanceData(ORGANISATION_INHERITANCE_PROPERTY[OrganisationMenuItems.EXCLUSIONS]);
    };

    private onRemoveRow = (event: any, obj: any) => {
        const { exclusions } = this.state;

        const filteredExclusions = exclusions.filter((item: any) => item.uuid !== obj.uuid);

        this.setState({ exclusions: filteredExclusions });
    };

    private validateNewOrder(fieldValue: string | number) {
        const { exclusions, errors } = this.state;

        if (fieldValue === "" || !fieldValue) {
            errors.newOrder = "Field can not be empty";
        } else if (exclusions.find((item: ILesionExclusion) => item.exclusionOrder === Number(fieldValue))) {
            errors.newOrder = "Order need to be unique";
        } else {
            errors.newOrder = false;
        }

        this.setState({ errors });
    }

    private validateNewExclusion() {
        const { edit, errors } = this.state;

        if (edit.newExclusion === "" || !edit.newExclusion) {
            errors.newExclusion = "Field can not be empty";
        } else {
            errors.newExclusion = false;
        }

        this.setState({ errors });
    }

    private validateSubmit() {
        const { edit, errors } = this.state;

        this.validateNewOrder(edit.newOrder);
        this.validateNewExclusion();
        return errors.newOrder || errors.newExclusion;
    }

    private addNewExclusion() {
        const { errors, edit } = this.state;

        return (
            <Table.Row key="newExclusion" id="newExclusion" verticalAlign="top">
                <Table.Cell>
                    <Form.Input
                        style={{ width: 400 }}
                        error={errors.newExclusion}
                        type="text"
                        id="newExclusion"
                        value={edit.newExclusion}
                        onChange={this.onNewExclusionChange}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Form.Input
                        style={{ width: 50 }}
                        error={errors.newOrder}
                        type="text"
                        id="newOrder"
                        onChange={this.onNewOrderChange}
                        value={edit.newOrder}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Form.Checkbox
                        error={errors.nonExcluding}
                        style={{ marginTop: 7, marginLeft: 20 }}
                        id="newNonExcluding"
                        onChange={this.onNewExclusionChange}
                        checked={edit.newNonExcluding}
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    public render() {
        const { createMode } = this.state;
        const { organisation, showEditButton } = this.props;

        return (
            <Segment>
                <StickyOrganisationTopPanel title="Exclusions">
                    <OrganisationManagementTabHeader
                        organisationUuid={organisation.uuid}
                        type={OrganisationManagementTabs.EXCLUSIONS}
                        showEditButton={showEditButton}
                        updateOrganisation={this.updateOrganisation}
                        createMode={createMode}
                        changeMode={this.changeMode}
                    />
                </StickyOrganisationTopPanel>
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>{this.getExclusionsTable()}</Grid.Column>
                    </Grid.Row>
                </Grid>
                {createMode && (
                    <>
                        <h3>Create new exclusion</h3>
                        <Divider />
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>{this.getAddNewExclusionTable()}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </>
                )}
            </Segment>
        );
    }
}

export default EditExclusionsComponent;
