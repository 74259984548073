import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { AtHomeFlowApiBody } from "model/atHomeFlow";
import { RegistrationQuestionType } from "model/registration";
import HttpStatus from "model/httpStatus";

import { registrationActions, registrationUIActions } from "redux/actions";

import * as ErrorService from "services/errorService";

import URLs from "services/urls";

const {
    CREATE_CONSENT,
    GET_QUESTIONS_LIST,
    SEND_ANSWERS,
    SUBMIT_AT_HOME_FLOW_CASE,
    GET_PERSONAL_DATA,
    SEND_PERSONAL_DATA,
    VERIFY_MOBILE_NUMBER,
    SEND_POST_CODE,
} = URLs;

function createUser(consent: boolean, organisationIdentifier: string) {
    return (dispatch: any) => {
        dispatch(registrationActions.userCreation(organisationIdentifier));

        AuthorizedHttpConnector.sendPost(CREATE_CONSENT, { consent, organisationIdentifier })
            .then((response) => {
                const { data } = response.data;
                dispatch(ErrorService.cleanErrors());
                dispatch(registrationActions.userCreationSuccess(data));
                dispatch(registrationUIActions.setIsModalConfirmationVisible(true));
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
                dispatch(registrationUIActions.setIsModalConfirmationVisible(false));
            });
    };
}

async function submitAtHomeQuestionnaireCase(data: AtHomeFlowApiBody) {
    const response = await AuthorizedHttpConnector.sendPost(SUBMIT_AT_HOME_FLOW_CASE, data);
    return response.data.data;
}

async function getRegistrationQuestions(uuid: string, type: RegistrationQuestionType) {
    const response = await AuthorizedHttpConnector.sendGet(
        GET_QUESTIONS_LIST.replace("{{UUID}}", uuid).concat(`?type=${type}`)
    );
    return response.data.data;
}

async function sendRegistrationAnswers(data: any) {
    const response = await AuthorizedHttpConnector.sendPost(SEND_ANSWERS, data);
    return response.data.data;
}

async function getRegistrationPersonalData(uuid: string) {
    const response = await AuthorizedHttpConnector.sendGet(GET_PERSONAL_DATA.replace("{{UUID}}", uuid));
    return response.data.data;
}

async function sendRegistrationPersonalData(data: any, dispatch: (action: any) => void) {
    return AuthorizedHttpConnector.sendPost(SEND_PERSONAL_DATA, data)
        .then((result) => {
            const { patientToCreateNewCase } = result.data.data;
            dispatch(ErrorService.cleanErrors());
            if (patientToCreateNewCase) {
                dispatch(registrationUIActions.setIsModalRedirectVisible(true));
            } else {
                dispatch(registrationUIActions.setIsModalEmailConfirmationVisible(true));
            }
        })
        .catch((err) => {
            if (err?.response) {
                const isPreauthCodeAlreadyUsed = err.response.status === HttpStatus.CONFLICT;
                if (isPreauthCodeAlreadyUsed) {
                    dispatch(registrationUIActions.setIsPreauthorisationNumberErrorModalVisible(true));
                }
                dispatch(ErrorService.cleanErrors());
            } else {
                ErrorService.handleError(err, dispatch);
                dispatch(registrationUIActions.setIsModalEmailConfirmationVisible(false));
            }
        });
}

async function verifyMobileNumber(data: { number: string }) {
    const response = await AuthorizedHttpConnector.sendPost(VERIFY_MOBILE_NUMBER, data);
    return response.data.data;
}

async function verifyCodeSMS(data: { number: string; code: string }) {
    const response = await AuthorizedHttpConnector.sendPut(VERIFY_MOBILE_NUMBER, data);
    return response.data.data;
}

async function getAddresses(postcode: string) {
    const response = await AuthorizedHttpConnector.sendGet(SEND_POST_CODE.replace("{{POSTCODE}}", postcode));
    return response.data;
}

export default {
    createUser,
    submitAtHomeQuestionnaireCase,
    getRegistrationQuestions,
    sendRegistrationAnswers,
    getRegistrationPersonalData,
    sendRegistrationPersonalData,
    verifyMobileNumber,
    verifyCodeSMS,
    getAddresses,
};
