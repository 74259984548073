import React, { FC, ReactNode } from "react";
import { Grid } from "semantic-ui-react";

import "scss/Button.scss";

interface IButtonContainer {
    button1: ReactNode;
    button2?: ReactNode;
}

const ButtonContainer: FC<IButtonContainer> = ({ button1, button2 }) => (
    <Grid>
        <Grid.Row columns={3}>
            <Grid.Column>{button1}</Grid.Column>
            <Grid.Column />
            <Grid.Column floated="right" textAlign="right">
                {button2}
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

ButtonContainer.defaultProps = {
    button2: null,
};

export default ButtonContainer;
