import React, { FC, useState, useContext, SyntheticEvent } from "react";
import { DropdownProps, Form, Grid, Header, Message } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ChangeManagementOutcomeModal from "components/CaseDescription/Review/ChangeManagementOutcomeModal";
import CaseCallbackHistoryTable from "components/CaseDescription/CaseCallbackHistoryTable";
import CaseNotesGroup from "components/CaseDescription/CaseNotesGroup";
import ClinicalNotes from "components/CaseDescription/Review/ClinicalNotes";

import { ModalContext } from "contextProviders/ModalProvider";
import { CaseListContext } from "contextProviders/CaseListProvider";

import createDropdownOptions from "helpers/semanticUi";
import { sortByOrder } from "helpers/common";
import { isReviewManagementUrgent } from "helpers/review";

import { ICase, IContactPreferences, IReview } from "model/case";
import { CallbackOutcomesText, CallbackOutcomesValues } from "model/remoteModel";
import HttpStatus from "model/httpStatus";
import { IApiResponse } from "model/request";
import { ICreateReviewResponse, NoteType } from "model/reviewNotes";
import { ContactableOverWeekends } from "model/registration";

import CustomSelect from "components/SelectDropdown/Select";

import reviewService from "services/reviewService";

interface ICaseCallback {
    contactPreferences: IContactPreferences;
    currentCase: ICase;
    isCallbackAgentReview: boolean;
    isSuperAdminOrSAAdmin?: boolean;
    updateCallbackOutcome?: (callback: CallbackOutcomesValues, isFinalOutcome: boolean) => void;
    updateCaseNotes?: (type: NoteType, note: string) => void;
    removeCaseNote?: (type: NoteType, noteUuid: string) => void;
    updateCaseReview?: (reviewFormData: ICreateReviewResponse) => void;
}

const CONTACT_TIME = {
    MORNING: { text: "Morning (8 - 12)", order: 1 },
    AFTERNOON: { text: "Afternoon (12 - 17)", order: 2 },
    EVENING: { text: "Evening (17 - 21)", order: 3 },
};

const FINAL_CALLBACK_OUTCOMES = [
    CallbackOutcomesValues.PATIENT_CONTACTED,
    CallbackOutcomesValues.CHANGE_MANAGEMENT_OUTCOME,
];

const CaseCallback: FC<ICaseCallback> = ({
    contactPreferences,
    currentCase,
    isCallbackAgentReview,
    updateCallbackOutcome,
    updateCaseNotes,
    updateCaseReview,
    removeCaseNote,
}) => {
    const { callbackOutcome, isCallbackNeeded, uuid, reviews, patientUuid, clinicalNotes, assignmentDetails } =
        currentCase;

    const [callback, setCallBack] = useState<CallbackOutcomesValues | undefined>();
    const [isPending, setIsPending] = useState<boolean>(false);
    const [isAllowedToAddOutcome, setIsAllowedToAddOutcome] = useState<boolean>(
        isCallbackAgentReview && isCallbackNeeded
    );
    const [isError, setIsError] = useState<boolean>(false);
    const [callbackOutcomeSubmitted, setCallbackOutcomeSubmitted] = useState<boolean>(false);
    const { setModalParameters } = useContext(ModalContext);
    const { removeCaseAfterCallback } = useContext(CaseListContext);

    const { weekend, partsOfDay } = { ...contactPreferences };
    const contactOverWeekendText = weekend ? ContactableOverWeekends.Yes : ContactableOverWeekends.No;

    const partsOfDayText =
        partsOfDay
            ?.map((partOfDay) => CONTACT_TIME[partOfDay])
            .sort(sortByOrder)
            .map((partOfDay) => partOfDay?.text)
            .join(", ") || "";

    const handleFieldChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
        const fieldValue = data.value as CallbackOutcomesValues;
        setCallBack(fieldValue);
    };

    const addCallbackOutcomeRecord = (): void => {
        const isFinalOutcome = FINAL_CALLBACK_OUTCOMES.includes(callback);
        if (updateCallbackOutcome && callback) {
            updateCallbackOutcome(callback, isFinalOutcome);
        }
        if (isFinalOutcome) {
            setIsAllowedToAddOutcome(false);
            removeCaseAfterCallback(uuid);
        }
    };

    const submitCallbackOutcome = async (): Promise<IApiResponse | null> => {
        if (callback) {
            try {
                return await reviewService.submitCallbackOutcome(currentCase.uuid, callback);
            } catch (err) {
                console.error(err);
            }
        }
        return null;
    };

    const handleSubmitOutcome = async (): Promise<void> => {
        if (callback) {
            setCallbackOutcomeSubmitted(true);
            if (callback === CallbackOutcomesValues.CHANGE_MANAGEMENT_OUTCOME) {
                setModalParameters({
                    content: (
                        <ChangeManagementOutcomeModal
                            currentCase={currentCase}
                            addCallbackOutcome={addCallbackOutcomeRecord}
                            submitCallbackOutcome={submitCallbackOutcome}
                            updateCaseNotes={updateCaseNotes}
                            updateCaseReview={updateCaseReview}
                        />
                    ),
                    isVisible: true,
                    size: "small",
                });
            } else {
                setIsPending(true);
                try {
                    const result = await submitCallbackOutcome();
                    if (result?.status === HttpStatus.ADDED) {
                        addCallbackOutcomeRecord();
                    }
                    setIsPending(false);
                } catch {
                    setIsError(true);
                }
            }
        }
    };

    const isCallbackSelected = Boolean(callback);
    let reviewOptions = createDropdownOptions(CallbackOutcomesText);
    const header = `Callback${isAllowedToAddOutcome ? "" : " history"}`;
    const showCallbackHistory = Boolean(callbackOutcome?.length);
    const callbackOutcomesCopy = callbackOutcome ? [...callbackOutcome] : [];
    const callbackOutcomesReversed = callbackOutcomesCopy.reverse();
    const latestReview: IReview = reviews[reviews.length - 1];
    const { refer, reviewManagement } = latestReview;
    const isUrgentReferral = refer && isReviewManagementUrgent(reviewManagement);

    if (isUrgentReferral) {
        reviewOptions = reviewOptions.filter(
            (option) => option.value !== CallbackOutcomesValues.CHANGE_MANAGEMENT_OUTCOME
        );
    }

    const showPartsOfDay = Boolean(partsOfDayText);

    return (
        <>
            <Header as="h2" className="without-bottom-margin">
                {header}
            </Header>
            {isAllowedToAddOutcome && (
                <>
                    {showPartsOfDay && (
                        <p>
                            <b>Preferred contact time:</b> {partsOfDayText}
                        </p>
                    )}
                    <p>
                        <b>Contactable over a weekend:</b> {contactOverWeekendText}
                    </p>
                    <Form>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <CustomSelect
                                        id="callbackOutcome"
                                        placeholder="Callback outcome"
                                        options={reviewOptions}
                                        onChange={(e) => handleFieldChange(e, e.target)}
                                        value={callback || ""}
                                        labelText="Callback outcome"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                    <div className="buttons-row-wrapper case-callback">
                        <CustomButton
                            type="button"
                            variant="filled"
                            action={handleSubmitOutcome}
                            loading={isPending}
                            disabled={!isCallbackSelected}
                            text="Submit outcome"
                        />
                    </div>
                    <ClinicalNotes
                        notes={clinicalNotes}
                        caseUuid={uuid}
                        patientUuid={patientUuid}
                        caseAssignee={assignmentDetails?.assigneeUuid}
                        updateCaseNotes={updateCaseNotes}
                        removeCaseNote={removeCaseNote}
                        callbackOutcomeSubmitted={callbackOutcomeSubmitted}
                    />
                    {showCallbackHistory && (
                        <Header as="h3" className="without-bottom-margin">
                            Callback history
                        </Header>
                    )}
                    {isError && <Message error content="Something went wrong, please try again later." />}
                </>
            )}
            {showCallbackHistory && (
                <>
                    <CaseCallbackHistoryTable callbackOutcomes={callbackOutcomesReversed} />

                    {!isAllowedToAddOutcome && (
                        <CaseNotesGroup
                            caseObject={currentCase}
                            renderAddNotesButton={false}
                            type={NoteType.CLINICAL}
                            updateCaseNotes={updateCaseNotes}
                        />
                    )}

                    {isError && <Message error content="Something went wrong, please try again later." />}
                </>
            )}
        </>
    );
};

CaseCallback.defaultProps = {
    isSuperAdminOrSAAdmin: false,
    updateCallbackOutcome: () => undefined,
    updateCaseNotes: () => undefined,
    updateCaseReview: () => undefined,
    removeCaseNote: undefined,
};

export default CaseCallback;
