import React, { Component } from "react";
import { Button, Divider, Form, Grid, Message, Segment, Table } from "semantic-ui-react";

import LocationRow from "components/Administration/OrganisationManagment/ViewOrganisation/Locations/LocationRow";
import StickyOrganisationTopPanel from "components/Administration/OrganisationManagment/ViewOrganisation/StickyOrganisationTopPanel";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";

import { scrollBottom } from "helpers/page";

import { OrganisationManagementTabs } from "model/administrationPages";
import { ILocation, IOrganisation } from "model/organisation";

import organizationService from "services/organizationService";

interface IEditHistoryQuestionComponentProps {
    locations: ILocation[];
    organisation: IOrganisation;
    error?: any;
}

class EditLocationsComponent extends Component<IEditHistoryQuestionComponentProps, any> {
    constructor(props: any) {
        super(props);
        const { locations } = this.props;
        this.state = {
            createMode: false,
            edit: {},
            errors: {
                newName: false,
                newOrder: false,
            },
            locations: locations ? locations.filter((location) => !location.removed) : [],
            responseError: [],
        };
    }

    private getLocationTable() {
        return (
            <Table padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>Location name</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Order</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                        <Table.HeaderCell width={1} />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.getLocationsTableBody()}</Table.Body>
            </Table>
        );
    }

    private getAddNewLocationTable() {
        const { submitting } = this.state;

        return (
            <div>
                <Table padded columns={3}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={4}>Location name</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Order</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Description</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.addNewLocationTableBody()}</Table.Body>
                </Table>
                <Grid columns={2}>
                    {this.getErrorMessages()}
                    <Grid.Row>
                        <Grid.Column width={16} />
                        <Grid.Column floated="right" width={3}>
                            <Button loading={submitting} onClick={this.onCreateNewLocation}>
                                Add new location
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    private getErrorMessages() {
        const { responseError } = this.state;

        return responseError.map((detail: any) => (
            <Grid.Row key={detail.message}>
                <Grid.Column>
                    <Message key={detail.message} negative>
                        <p>{detail.message}</p>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        ));
    }

    private getLocationsTableBody() {
        const { organisation } = this.props;
        const { locations } = this.state;

        if (locations?.length > 0) {
            return locations.map((location: any) => (
                <LocationRow
                    key={location.uuid}
                    location={location}
                    onRemove={this.onRemoveRow}
                    organisation={organisation}
                />
            ));
        }

        return (
            <Table.Row key="none">
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
            </Table.Row>
        );
    }

    private changeMode = () => {
        const { createMode } = this.state;

        this.setState({ createMode: !createMode });

        if (!createMode) {
            scrollBottom();
        }
    };

    private onCreateNewLocation = () => {
        const { organisation } = this.props;
        const { edit, locations } = this.state;

        if (!this.validateSubmit()) {
            this.setState({ submitting: true });
            organizationService
                .createNewLocation(organisation.uuid, edit.newName, edit.newOrder, edit.newDescription)
                .then((response) => {
                    locations.push(response);

                    this.setState({
                        edit: {
                            newDescription: "",
                            newName: "",
                            newOrder: "",
                        },
                        locations,
                        responseError: [],
                        submitting: false,
                    });
                })
                .catch((err) => {
                    this.setState({ submitting: false, responseError: err.response.data.errors });
                });
        }
    };

    private onNewLocationChange = (event: any, obj: any) => {
        this.onChange(event, obj);
        this.validateNewName(obj.value);
    };

    private onNewOrderChange = (event: any, obj: any) => {
        this.onChange(event, obj);
        this.validateNewOrder(obj.value);
    };

    private onChange = (event: any, obj: any) => {
        const { edit } = this.state;

        const fieldName = obj.id;
        const fieldValue = obj.type === "checkbox" ? obj.checked : obj.value;
        edit[fieldName] = fieldValue;

        this.setState({ edit });
    };

    private onRemoveRow = (event: any, obj: any) => {
        const { locations } = this.state;

        const filteredLocations = locations.filter((item: any) => item.uuid !== obj.uuid);
        this.setState({ locations: filteredLocations });
    };

    private updateOrganisation = (result: any) => {
        const locations = result.locations.filter((location: ILocation) => !location.removed);
        this.setState({ locations });
    };

    private addNewLocationTableBody() {
        const { errors, edit } = this.state;

        return (
            <Table.Row key="newLocation" id="newLocation" verticalAlign="top">
                <Table.Cell>
                    <Form.Input
                        style={{ width: 200 }}
                        error={errors.newName}
                        type="text"
                        id="newName"
                        value={edit.newName}
                        onChange={this.onNewLocationChange}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Form.Input
                        style={{ width: 80 }}
                        error={errors.newOrder}
                        type="number"
                        id="newOrder"
                        onChange={this.onNewOrderChange}
                        value={edit.newOrder}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Form.Input
                        style={{ width: 400 }}
                        error={errors.newDescription}
                        type="text"
                        id="newDescription"
                        value={edit.newDescription}
                        onChange={this.onNewLocationChange}
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    private validateNewOrder(fieldValue: string | number) {
        const { errors, locations } = this.state;

        if (fieldValue === "" || !fieldValue) {
            errors.newOrder = "Field can not be empty";
        } else if (locations.find((item: ILocation) => item.locationOrder === Number(fieldValue))) {
            errors.newOrder = "Order need to be unique";
        } else {
            errors.newOrder = false;
        }

        this.setState({ errors });
    }

    private validateNewName(value: string) {
        const { errors, edit, locations } = this.state;

        if (edit.newName === "" || !edit.newName) {
            errors.newName = "Field can not be empty";
        } else if (locations.find((item: ILocation) => item.name === value)) {
            errors.newName = "Name need to be unique";
        } else {
            errors.newName = false;
        }

        this.setState({ errors });
    }

    private validateSubmit() {
        const { edit, errors } = this.state;

        this.validateNewOrder(edit.newOrder);
        this.validateNewName(edit.newName);
        return errors.newOrder || errors.newName;
    }

    public render() {
        const { createMode, locations } = this.state;
        const { organisation } = this.props;
        const showEditButton = locations.length === 0 && !!organisation.parentUuid;

        return (
            <Segment>
                <StickyOrganisationTopPanel title="Locations" withDescription={showEditButton}>
                    <OrganisationManagementTabHeader
                        organisationUuid={organisation.uuid}
                        type={OrganisationManagementTabs.LOCATIONS}
                        showEditButton={showEditButton}
                        updateOrganisation={this.updateOrganisation}
                        createMode={createMode}
                        changeMode={this.changeMode}
                        buttonText="COPY FROM PARENT"
                    />
                </StickyOrganisationTopPanel>

                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>{this.getLocationTable()}</Grid.Column>
                    </Grid.Row>
                </Grid>

                {createMode && (
                    <>
                        <h3>Create new location</h3>
                        <Divider />
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>{this.getAddNewLocationTable()}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </>
                )}
            </Segment>
        );
    }
}

export default EditLocationsComponent;
