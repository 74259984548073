import { Dispatch } from "react";

import PresentingComplaint from "model/presentingComplaint";
import { BodyLocations } from "model/bodyLocations";
import { ICase, IPatientData, IResult, ISkinToneAnswer } from "model/case";
import { LesionClassification } from "model/lesionClassification";
import Pathway from "model/pathway";
import { IOrganisation, IHistoryAnswerType } from "model/organisation";
import { IUser } from "model/user";
import QuestionSubTypeEnum from "model/questionSubType";

import AtHomeFlowAction from "contextProviders/modules/atHomeFlow/actions";

export interface IAssessmentBase {
    case: ICase;
    currentLesion: number;
    lesion: ILesion;
    patient: IPatient;
    study?: IStudy;
}

export interface IAssessment extends IAssessmentBase {
    locationUuid: string;
    nonSkinCancer: boolean;
    consent: boolean;
    devices: IDevice[];
    diagnosis: IDiagnosis;
    study: IStudy;
    continueAssessment: boolean;
    showIntegrationPatientCreatedModal: boolean;
    isManualUploadRemoteMode?: boolean;
}

export interface IDevice {
    infoText: string;
    model: string;
    osVersion: string;
    useForAi: string;
    uuid: string;
    version: string;
    manufacturer: string;
}

export interface IDiagnosis {
    caseUuid: string;
    confidence: string;
    diagnosis: LesionClassification;
    lesionUuid: string;
    pathway: Pathway;
    studyUuid: string;
    uuid: string;
}

export interface ILesion {
    bodyPart: BodyLocations;
    description: string;
    result: IResult;
    uuid: string;
    images: ILesionImage[];
    createdByName: string;
    presentingComplaint?: PresentingComplaint;
    complaintDescription?: string;
}

export interface IIntegration {
    integrationName: string;
    lastSychronisationDate: string;
}

export interface IPatient {
    uuid: string;
    patientData: IPatientData[];
    medicalHistory?: IMedicalHistory[];
    createdByName: string;
    integrationInfo?: IIntegration;
    skinToneClassification?: ISkinToneAnswer[];
}

export interface IMedicalHistory {
    additionalInformation?: string;
    answer: string;
    answers: string[];
    uuid: string;
    question: string;
    medicalHistoryCreationDate: string;
    lastModificationDate: string;
    removed: boolean;
    createdBy?: string;
    creationDate?: string;
}

export interface IMedicalHistoryAnswers {
    answer?: string;
    questionUuid?: string;
    removed: boolean;
    answerType: IHistoryAnswerType;
    uuid: string;
    answers?: string[];
    exclusion?: any;
    exclusionUuid?: string;
    additionalInformation?: string;
    questionSubType?: QuestionSubTypeEnum.SKIN_TYPE;
}

export interface IStudy {
    uuid: string;
    organisationUuid?: string;
    name?: string;
}

export interface ILesionImage {
    type: string;
    status: string;
    uuid: string;
    creationDate: string;
}

export interface IValidationError {
    propertyName: string;
    text: string;
}

export interface IValidationErrorsRemoteFlow {
    isValid: boolean;
    errorsList: IValidationError[];
    isVisible: boolean;
    contactPreferencesError: boolean;
}

export enum AssessmentTypes {
    remoteModel = "remoteModel",
}

export enum RemoteModelStatuses {
    START = "START",
    MEDICAL = "MEDICAL",
    KIT = "KIT",
    LESION_DETAILS = "LESION_DETAILS",
    LESION_IMAGES = "LESION_IMAGES",
    SUMMARY = "SUMMARY",
    REVIEW = "REVIEW",
    REPORT_AVAILABLE = "REPORT_AVAILABLE",
    CASE_CLOSED = "CASE_CLOSED",
    AWAITING_CALLBACK = "AWAITING_CALLBACK",
}

export enum RemoteModelManagementOutcomes {
    DISCHARGE = "DISCHARGE",
    REFERRAL = "REFERRAL",
}

export interface IScrollToError {
    errorUuid: string;
    currentQuestionUuid?: string;
}

export interface INewAssessmentProps {
    assessment: IAssessment;
    organisation: IOrganisation;
    registration: any;
    user: IUser;
    pendingRequest: boolean;
    createCase: (
        consent?: boolean,
        secondaryConsent?: boolean,
        automatedDecisionConsent?: boolean,
        nonSkinCancer?: boolean,
        studyUuid?: string
    ) => void;
    updateCase: (
        uuid: string,
        consent: boolean,
        secondaryConsent: boolean,
        automatedDecisionConsent: boolean,
        locationUuid?: string
    ) => void;
    getDevices: () => void;
    atHomeFlowDispatch: Dispatch<AtHomeFlowAction>;
}

export enum PatientFromIntegrationFields {
    HOSPITAL_NUMBER = "hospitalNo",
    LAST_NAME = "surname",
    NAME = "name",
    NHS_NUMBER = "nhs",
    PREAUTHORISATION_NUMBER = "preauthorisationNumber",
    MEMBER_NUMBER = "memberNumber",
    POST_CODE = "postCode",
    BIRTH = "birth",
    TITLE = "title",
    GENDER = "gender",
    ETHNIC_GROUP = "ethnicGroup",
    OCCUPATION = "occupation",
    MERGED_RECORD = "mergedRecord",
    DATE_OF_DEATH = "dateOfDeath",
}
