import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider, Form } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import PatientBanner from "components/templates/PatientBanner";

import { IAssessment } from "model/assessment";
import Confidence from "model/confidence";
import { InitialDiagnosLesionClassification } from "model/lesionClassification";
import Pathway from "model/pathway";

import { STUDY_IMAGE_CAPTURE } from "navigation/routes";

import "scss/InputField.scss";

interface IDiagnosisProps {
    assessment: IAssessment;
    caseUuid: string;
    lesionUuid: string;
    studyUuid: string;

    createDiagnosis: (caseUuid: string, lesionUuid: string, studyUuid: string, state: any) => void;
    resetCaptureImageWizzard: () => void;
}

interface IDiagnosisState {
    confidence?: Confidence | undefined;
    diagnosis?: InitialDiagnosLesionClassification | undefined;
    pathway?: Pathway | undefined;
    isBack?: boolean;
    next?: boolean;
}

class Diagnosis extends Component<IDiagnosisProps, IDiagnosisState> {
    constructor(props: IDiagnosisProps) {
        super(props);

        const { assessment } = this.props;

        this.state = {
            confidence: undefined,
            diagnosis: undefined,
            isBack: assessment.diagnosis !== undefined,
            next: false,
            pathway: undefined,
        };
    }

    public changeFieldValue = (e: any, data: any) => {
        this.setState({
            [data.id]: data.value,
        } as IDiagnosisState);
    };

    public back = () => {
        const { resetCaptureImageWizzard } = this.props;

        resetCaptureImageWizzard();
    };

    public submit = () => {
        const { isBack } = this.state;
        if (!isBack) {
            const { caseUuid, lesionUuid, studyUuid, createDiagnosis } = this.props;
            createDiagnosis(caseUuid, lesionUuid, studyUuid, this.state);
        }
        this.setState({ next: true });
    };

    public render() {
        const { assessment } = this.props;
        const { next, diagnosis, isBack } = this.state;
        const { case: caseObject, currentLesion, lesion, patient } = assessment;

        if (assessment.diagnosis && next) {
            return <Redirect to="/ai-results" />;
        }

        return (
            <Container className="ui segment wizzard-container">
                <PatientBanner
                    caseId={caseObject.caseId}
                    patient={patient}
                    lesion={lesion}
                    lesionNumber={currentLesion}
                />
                <h1>Initial Diagnosis</h1>
                <Divider />
                <Form>
                    <Form.Select
                        disabled={isBack}
                        className="field patient"
                        type="options"
                        data={diagnosis}
                        id="diagnosis"
                        label="Select diagnosis"
                        placeholder="diagnosis"
                        onChange={this.changeFieldValue}
                        options={Object.keys(InitialDiagnosLesionClassification).map((key) => ({
                            key,
                            text: InitialDiagnosLesionClassification[key],
                            value: key,
                        }))}
                    />
                    <Form.Select
                        disabled={isBack}
                        className="field patient"
                        type="text"
                        data={diagnosis}
                        id="confidence"
                        label="Select confidence"
                        placeholder="confidence"
                        onChange={this.changeFieldValue}
                        options={Object.keys(Confidence).map((key) => ({
                            key,
                            text: Confidence[key],
                            value: key,
                        }))}
                    />
                    <Form.Select
                        disabled={isBack}
                        className="field patient"
                        type="text"
                        label="Pathway"
                        data={diagnosis}
                        id="pathway"
                        placeholder="pathway"
                        onChange={this.changeFieldValue}
                        options={Object.keys(Pathway).map((key) => ({
                            key,
                            text: Pathway[key],
                            value: key,
                        }))}
                    />

                    <div style={{ paddingTop: 10 }}>
                        <Divider />
                        <ButtonContainer
                            button1={
                                <CustomButton
                                    variant="empty"
                                    type="link"
                                    to={STUDY_IMAGE_CAPTURE}
                                    action={this.back}
                                    text="< Back"
                                    size="small"
                                />
                            }
                            button2={<CustomButton variant="filled" type="submit" action={this.submit} text="Next >" />}
                        />
                    </div>
                </Form>
            </Container>
        );
    }
}

export default Diagnosis;
