class HttpException extends Error {
    constructor(status: number, body: Record<string, unknown>, message?: string) {
        super(message);
        this.body = body;
        this.status = status;
    }

    private body: Record<string, unknown>;

    private status: number;

    public getBody() {
        return this.body;
    }

    public getStatus() {
        return this.status;
    }
}

export default HttpException;
