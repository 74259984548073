import { Component } from "react";
import { Form, Table } from "semantic-ui-react";

import EmailContentComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Notifications/EmailContentComponent";
import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";
import ContextIconButton from "components/templates/ContextIconButton";

import getEmailAddressesFromTarget from "helpers/notifications";

import {
    INotificationConfiguration,
    NotificationType,
    NotificationTypeText,
    NotificationChannelUpper,
} from "model/organisation";

import { NotificationRole, NotificationRoleDisplay } from "model/userRole";
import { CaseStatusDictionary } from "model/caseStatus";

interface IOrganisationNotificationRow {
    onRemove: (uuid: string) => void;
    onEdit: (event: any, obj: any) => void;

    notification: INotificationConfiguration;
    parentUuid: string;
    disabledEditing?: boolean;
}

class OrganisationNotificationsRow extends Component<IOrganisationNotificationRow> {
    private showTargets = (targets: string[] = []) => {
        const emailsTarget = getEmailAddressesFromTarget(targets);
        const usersTarget = targets.filter((target: string) => !emailsTarget.includes(target));

        return (
            <>
                {!targets?.length ? "-" : null}

                {emailsTarget.length
                    ? emailsTarget.map((email: string, index: number) => (
                          <p key={email}>
                              Target {index + 1}: {email}
                          </p>
                      ))
                    : null}

                {usersTarget.length ? (
                    <p>
                        Target usertype:{" "}
                        <span>
                            {usersTarget
                                .filter((item: string) => item.length)
                                .map((item: string) => NotificationRoleDisplay[item])
                                .join(", ")}
                            .
                        </span>
                    </p>
                ) : null}
            </>
        );
    };

    private showNotifyCaseCreator = (type: string, notified: boolean) => {
        if (type === NotificationType.caseOverdue) {
            return "-";
        }

        return notified ? "Yes" : "No";
    };

    private onRemoveRow = () => {
        const { onRemove, notification } = this.props;
        if (notification.uuid) {
            onRemove(notification.uuid);
        }
    };

    private onEditRow = (event: any) => {
        const { onEdit, notification } = this.props;
        onEdit(event, notification);
    };

    public render() {
        const { parentUuid, notification, disabledEditing } = this.props;
        const {
            uuid,
            targets,
            copyOfUuid,
            organisationUuid,
            channel,
            caseStatus,
            notificationType,
            notificationTitle,
            notificationContent,
        } = notification;

        return (
            <Table.Row key={uuid}>
                <Table.Cell width={1}>
                    <div className="notification-copied-from-parent">
                        <SettingsPropertyWrapper
                            copyOfUuid={copyOfUuid}
                            parentOrgUuid={parentUuid}
                            propertyOrganisationUuid={organisationUuid}
                        >
                            <Form>{NotificationTypeText[notificationType]}</Form>
                        </SettingsPropertyWrapper>
                    </div>
                </Table.Cell>
                <Table.Cell width={1}>
                    <Form>{(channel && NotificationChannelUpper[channel]) || NotificationChannelUpper.EMAIL}</Form>
                </Table.Cell>
                <Table.Cell width={1}>
                    <Form>{caseStatus ? CaseStatusDictionary[caseStatus] : "-"}</Form>
                </Table.Cell>
                <Table.Cell width={4}>
                    <Form>{this.showTargets(targets)}</Form>
                </Table.Cell>
                <Table.Cell width={4}>
                    <Form className="notification-title">
                        {NotificationTypeText[notificationType] === NotificationTypeText.reportGenerated
                            ? null
                            : `Title: ${notificationTitle || "-"}`}

                        {notificationContent ? (
                            <>
                                <br />
                                <EmailContentComponent content={notificationContent} />
                            </>
                        ) : null}
                    </Form>
                </Table.Cell>
                <Table.Cell width={2}>
                    <Form>
                        {this.showNotifyCaseCreator(
                            notificationType,
                            Boolean(targets?.includes(NotificationRole.CASE_CREATOR))
                        )}
                    </Form>
                </Table.Cell>

                <Table.Cell width={2}>
                    {disabledEditing ? null : (
                        <>
                            <ContextIconButton iconName="trash" onClick={this.onRemoveRow} uuid={uuid} />
                            <ContextIconButton iconName="pencil" onClick={this.onEditRow} uuid={uuid} />
                        </>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default OrganisationNotificationsRow;
