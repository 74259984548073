import React, { FC } from "react";

import CaseNotesGroup from "components/CaseDescription/CaseNotesGroup";
import ConditionalRender from "components/templates/ConditionalRender";
import ClinicalNotes from "components/CaseDescription/Review/ClinicalNotes";

import { ICase } from "model/case";
import { CaseViewMode } from "model/caseViewMode";
import { IOrganisation } from "model/organisation";
import { UserRole } from "model/userRole";
import { NoteType } from "model/reviewNotes";

interface ICaseNotesProps {
    caseObject: ICase;
    mode: CaseViewMode;
    organisation: IOrganisation;
    updateCaseNotes: (type: string, note: string) => void;
    removeCaseNote?: (type: NoteType, noteUuid: string) => void;
}

const CaseNotes: FC<ICaseNotesProps> = ({ mode, caseObject, organisation, updateCaseNotes, removeCaseNote }) => {
    const allowedRoles = [
        CaseViewMode.CLIENT_ADMIN_REMOTE_VIEW,
        CaseViewMode.ADMINVIEW,
        CaseViewMode.REVIEWVIEW,
        CaseViewMode.SUPERADMINVIEW,
        CaseViewMode.VIEW,
        CaseViewMode.CALLBACK_AGENT_REVIEW,
        CaseViewMode.CLINICAL_VIEWER_VIEW,
    ];
    const canRender: boolean = allowedRoles.includes(mode) && Boolean(caseObject.caseId);

    const allowedToViewClinicalNotes = [
        UserRole.SA_ADMIN,
        UserRole.SUPERADMIN,
        UserRole.DERMATOLOGIST,
        UserRole.CLINICIAN,
        UserRole.ADMIN,
        UserRole.CLINICAL_VIEWER,
    ];

    const commonProps = { caseObject, organisation, updateCaseNotes };
    const { patientUuid, uuid, clinicalNotes, assignmentDetails } = caseObject;

    return (
        canRender && (
            <div className="section-separate">
                {caseObject.remote && (
                    <CaseNotesGroup renderAddNotesButton={false} type={NoteType.PATIENT} {...commonProps} />
                )}

                <ConditionalRender requiredRole={allowedToViewClinicalNotes}>
                    <ClinicalNotes
                        notes={clinicalNotes}
                        caseUuid={uuid}
                        patientUuid={patientUuid}
                        caseAssignee={assignmentDetails?.assigneeUuid}
                        updateCaseNotes={updateCaseNotes}
                        removeCaseNote={removeCaseNote}
                    />
                </ConditionalRender>
            </div>
        )
    );
};

CaseNotes.defaultProps = {
    removeCaseNote: undefined,
};

export default CaseNotes;
