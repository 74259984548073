import { FC } from "react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

interface IRemoveNotificationModal {
    onClose: () => void;
    onConfirm: () => void;
}

const RemoveNotificationModal: FC<IRemoveNotificationModal> = ({ onClose, onConfirm }) => (
    <div className="remote-model__modal">
        <p className="h2">Do you really want to delete this notification?</p>

        <div className="buttons-row-wrapper">
            <CustomButton type="button" variant="empty-dark" action={onClose} text="Cancel" />
            <CustomButton type="button" variant="filled" action={onConfirm} text="Confirm" />
        </div>
    </div>
);

export default withModal(RemoveNotificationModal);
