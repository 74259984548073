import React, { FC } from "react";

import PatientNotes from "components/RemoteModel/ReportAvailable/PatientNotes";

import { formatTimeDOB, formatTimeRemoteModel } from "helpers/datetime";

import { IReview, IReviewNote } from "model/case";

interface ICallbackAgentRecommnedation {
    reviews: IReview[];
    patientNotes: IReviewNote[];
    previousReviewManagement: string;
}

const CallbackAgentRecommnedation: FC<ICallbackAgentRecommnedation> = ({
    reviews,
    patientNotes,
    previousReviewManagement,
}) => {
    const lastPatienNoteIndex = patientNotes.length - 1;
    const lastReviewIndex = reviews.length - 1;
    const { note: lastPatientNote } = patientNotes[lastPatienNoteIndex];
    const latestReview = reviews[lastReviewIndex];
    const {
        createdByName: latestReviewCreatedByName,
        creationDate: latestReviewCreationDate,
        reviewManagement: latestReviewManagement,
    } = { ...latestReview };
    const latestReviewRecommendationDate = formatTimeDOB(latestReviewCreationDate);
    const latestReviewRecommendationTime = formatTimeRemoteModel(latestReviewCreationDate);

    return (
        <p>
            On {latestReviewRecommendationDate} at {latestReviewRecommendationTime},&nbsp;
            {latestReviewCreatedByName} updated your case outcome from {previousReviewManagement} to&nbsp;
            <b>{latestReviewManagement}</b> and left the following note:
            <PatientNotes note={lastPatientNote} isLatest />
        </p>
    );
};

export default CallbackAgentRecommnedation;
