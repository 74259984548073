import { FC, MouseEvent as ReactMouseEvent, useState } from "react";
import clsx from "clsx";
import { Image, Header, Icon } from "semantic-ui-react";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import OrganisationSwitchModal from "components/templates/OrganisationSwitchModal";
import { IOrganisation, IOrganisationData } from "model/organisation";

interface IOrganisationSelectHeaderItemProps {
    organisations: IOrganisationData[];
    existingOrganisation: IOrganisation;
}

const OrganisationSelectHeaderItem: FC<IOrganisationSelectHeaderItemProps> = ({
    organisations,
    existingOrganisation,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [newOrganisation, setNewOrganisation] = useState<IOrganisationData>(undefined);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleModalClose = (): void => {
        setShowModal(false);
    };

    const handleOrganisationMenuClick = (e: ReactMouseEvent<HTMLElement>): void => {
        setAnchorEl(e.currentTarget);
    };

    const handleClickAway = (): void => {
        setAnchorEl(null);
    };

    const handleCloseContextMenu = (): void => {
        handleClickAway();
    };

    const handleMenuItemClick = (organisation: IOrganisationData): void => {
        if (organisation.organisationUuid === existingOrganisation.uuid) {
            return;
        }
        handleCloseContextMenu();
        setNewOrganisation(organisation);
        setShowModal(true);
    };

    return (
        <>
            <div className="organisation-menu-container">
                <Image src="/images/icons/icon-home.svg" />
                <Button className="organisation-menu-icon-button" onClick={handleOrganisationMenuClick} disableRipple>
                    <div className="organisation-menu-details-container">
                        <div className="organisation-menu-organisation-info">
                            <Header as="h5">Organisation</Header>
                            <span>{existingOrganisation.name}</span>
                        </div>
                        <Icon name="angle down" size="big" />
                    </div>
                </Button>
            </div>
            <MuiMenu
                className="organisation-menu-items"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseContextMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                getContentAnchorEl={null}
            >
                {organisations.map((org) => (
                    <MenuItem
                        key={org.organisationUuid}
                        onClick={() => handleMenuItemClick(org)}
                        className={clsx({
                            "organisation-select-item": true,
                            "selected": org.organisationUuid === existingOrganisation.uuid,
                        })}
                    >
                        <div>
                            <p>{org.organisationName}</p>
                        </div>
                        <Image alt="check-icon" src="/images/icons/icon-round-check.svg" />
                    </MenuItem>
                ))}
            </MuiMenu>
            <OrganisationSwitchModal
                showModal={showModal}
                newOrganisation={newOrganisation}
                handleModalClose={handleModalClose}
            />
        </>
    );
};

export default OrganisationSelectHeaderItem;
