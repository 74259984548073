import React, { FC, SyntheticEvent } from "react";
import { Table } from "semantic-ui-react";

import CopyIcon from "components/templates/CopyIcon";
import TableRow from "components/templates/TableRow";
import UserAdditionalInfo from "components/Administration/UserManagement/UserAdditionalInfo";

import { AdministrationPages, UserManagementSubpages } from "model/administrationPages";
import { IUser } from "model/user";

import { ADMINISTRATION } from "navigation/routes";

interface IUserTableBody {
    readonly userList: IUser[];
}

const UserTableBody: FC<IUserTableBody> = ({ userList }) => (
    <Table.Body>
        {userList.map((user: IUser) => {
            const {
                uuid,
                email,
                name,
                surname,
                organisationName,
                additionalOrganisationData,
                lastModificationDate,
                role,
                preauthorisationNumber,
                memberNumber,
                status,
            } = user;
            const additionalOrganisationNames = additionalOrganisationData
                ? additionalOrganisationData.map((org) => org.organisationName)
                : [];
            const organisationNames = [organisationName, ...additionalOrganisationNames];
            const { lastLogin, additionalRoleInfo, notes } = user;
            const userLink = `${ADMINISTRATION}/${AdministrationPages.USER_MANAGEMENT}/${UserManagementSubpages.USER_DETAILS}/${uuid}`;
            const lastLoginDateTime = lastLogin && new Date(lastLogin).toLocaleString();
            const lastModifiedDateTime = new Date(lastModificationDate).toLocaleString();

            const stopPropagation = (event: SyntheticEvent) => {
                event.stopPropagation();
            };

            return (
                <TableRow key={uuid} id={uuid} link={userLink}>
                    <Table.Cell className="organisation-cell">
                        {organisationNames.map((orgName) => (
                            <p key={orgName} className="organisation-name-chip">
                                {orgName}
                            </p>
                        ))}
                    </Table.Cell>
                    <Table.Cell>
                        <div className="copy-email">
                            <span onClick={stopPropagation} onKeyPress={stopPropagation} role="button" tabIndex={0}>
                                {email}
                            </span>
                            <CopyIcon valueToCopy={email} title="Email" />
                        </div>
                    </Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{surname}</Table.Cell>
                    <Table.Cell>{lastModifiedDateTime}</Table.Cell>
                    <Table.Cell>{role}</Table.Cell>
                    <Table.Cell>
                        {preauthorisationNumber && (
                            <>
                                <span>{preauthorisationNumber}</span>
                                <CopyIcon valueToCopy={preauthorisationNumber} title="Preauthorisation Number" />
                            </>
                        )}
                    </Table.Cell>
                    <Table.Cell>
                        {memberNumber && (
                            <>
                                <span>{memberNumber}</span>
                                <CopyIcon valueToCopy={memberNumber} title="Member Number" />
                            </>
                        )}
                    </Table.Cell>
                    <Table.Cell>
                        <UserAdditionalInfo additionalRoleInfo={additionalRoleInfo} notes={notes} />
                    </Table.Cell>
                    <Table.Cell>{status}</Table.Cell>
                    <Table.Cell>{lastLoginDateTime}</Table.Cell>
                </TableRow>
            );
        })}
    </Table.Body>
);

export default UserTableBody;
