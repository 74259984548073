import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

import { NEW_ASSESSMENT } from "navigation/routes";

interface IPatientFormControl {
    button1Name: string;
    button1OnClick?: () => void;
    button2Name: string;
    button2OnClick?: () => void;
    button1Type?: string;
    button2Type?: "button" | "submit" | "reset";
    disabled?: boolean;
}

function PatientFormControl({
    button1Name,
    button1Type,
    button1OnClick,
    button2Name,
    button2Type,
    button2OnClick,
    disabled,
}: IPatientFormControl) {
    const linkButtonProps = { component: Link, to: NEW_ASSESSMENT };
    return (
        <div className="button-wrapper">
            <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={button1Type === "link" ? undefined : button1OnClick}
                {...(button1Type === "link" ? linkButtonProps : {})}
                disabled={disabled}
            >
                {button1Name}
            </Button>
            <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                fullWidth
                onClick={button2OnClick || null}
                type={button2Type}
            >
                {button2Name}
            </Button>
        </div>
    );
}

export default PatientFormControl;
