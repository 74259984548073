import { FC, useEffect, useState } from "react";
import { Form, Grid, Header } from "semantic-ui-react";

import { ORGANISATION_INHERITANCE_PROPERTY } from "components/Administration/OrganisationManagment/ViewOrganisation/OrganisationDetailsScreen";
import CoreFieldsTable from "components/Administration/OrganisationManagment/ViewOrganisation/PersonalData/CoreFieldsTable";
import AddCoreField from "components/Administration/OrganisationManagment/ViewOrganisation/PersonalData/AddCoreField";
import ErrorMessage from "components/ErrorMessage";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";
import ModalDialog from "components/templates/dialog/ModalDialog";

import { OrganisationManagementTabs, OrganisationMenuItems } from "model/administrationPages";
import PatientDetailsIntegration from "model/integrations";
import {
    IDefinedPersonalData,
    INewDefinedPersonalData,
    IOrganisation,
    IOrganisationPersonalData,
} from "model/organisation";

import organizationService from "services/organizationService";

interface ICoreFields {
    organisationUuid: string;
    parentOrganisationUuid: string;
    showEditButton: boolean;
    updateInheritanceData: (property: string) => void;
}

const CoreFields: FC<ICoreFields> = ({
    organisationUuid,
    parentOrganisationUuid,
    showEditButton,
    updateInheritanceData,
}) => {
    const [newCustomCoreField, setNewCustomCoreField] = useState<boolean>(false);
    const [isPDSModalOpen, setIsPDSModalOpen] = useState<boolean>(false);
    const [organisationPersonalData, setOrganisationPersonalData] = useState<IOrganisationPersonalData>({
        definedPersonalData: [],
        patientDetailsIntegrationType: null,
        isOrganisationDataFetched: false,
    });
    const [error, setError] = useState<string>("");

    useEffect(() => {
        const fetchDefinedPersonalData = async () => {
            try {
                const organisation: IOrganisation = await organizationService.getOrganisationByUuid(organisationUuid);
                const { definedPersonalData: organisationDefinedPersonalData, patientDetailsIntegrationType } =
                    organisation;
                const definedPersonalData =
                    organisationDefinedPersonalData
                        ?.filter((data) => !data.removed)
                        .sort((a, b) => a.displayOrder - b.displayOrder) || [];
                setOrganisationPersonalData({
                    definedPersonalData,
                    patientDetailsIntegrationType,
                    isOrganisationDataFetched: true,
                });
            } catch (e) {
                setError("Error while fetching personal data fields");
            }
        };

        fetchDefinedPersonalData();
    }, []);

    const { definedPersonalData, isOrganisationDataFetched, patientDetailsIntegrationType } = organisationPersonalData;
    const isPDSEnabled = patientDetailsIntegrationType === PatientDetailsIntegration.PDS;

    const handleFieldRemoved = (uuid: string) => {
        const filteredDefinedPersonalData = definedPersonalData.filter(
            (item: IDefinedPersonalData) => item.uuid !== uuid
        );

        setOrganisationPersonalData({ ...organisationPersonalData, definedPersonalData: filteredDefinedPersonalData });
    };

    const hideNewCustomCoreField = () => {
        setNewCustomCoreField(false);
    };

    const handleFieldAdded = async (obj: INewDefinedPersonalData) => {
        try {
            const newPersonalData = await organizationService.createPersonalData(organisationUuid, obj);
            const updatedPersonalData = [...definedPersonalData];
            updatedPersonalData.push(newPersonalData);
            setOrganisationPersonalData({ ...organisationPersonalData, definedPersonalData: updatedPersonalData });
            hideNewCustomCoreField();
            setError("");
        } catch (e) {
            setError("Error while creating new personal data field");
        }
    };

    const handleUpdateInheritanceData = (result: IOrganisation) => {
        setOrganisationPersonalData({ ...organisationPersonalData, definedPersonalData: result.definedPersonalData });
        updateInheritanceData(ORGANISATION_INHERITANCE_PROPERTY[OrganisationMenuItems.PERSONAL_DATA]);
    };

    const isError = Boolean(error);
    const isEditable = !showEditButton;
    const title = `Personal Data${isOrganisationDataFetched && !patientDetailsIntegrationType ? " - Core fields" : ""}`;
    const subtitle = patientDetailsIntegrationType
        ? "See below a list of the property fields used in case creation."
        : "These are fields that will be displayed on manual case creation.";

    const popupModalBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    {isPDSEnabled
                        ? "PDS linked fields will be removed and patient details will have to be manually input during case creation. Do you want to continue?"
                        : "Activating the “Autofill patient details” will enable PDS for patient details and will update existing personal data settings. Do you want to continue?"}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    const handlePDSSwitchChange = async (): Promise<void> => {
        const newPersonalDataFields =
            (await organizationService.updateOrganisationPatientDetailsIntegration(
                organisationUuid,
                isPDSEnabled ? null : PatientDetailsIntegration.PDS
            )) ?? [];
        setOrganisationPersonalData({
            ...organisationPersonalData,
            definedPersonalData: newPersonalDataFields.sort((a, b) => a.displayOrder - b.displayOrder),
            patientDetailsIntegrationType: isPDSEnabled ? null : PatientDetailsIntegration.PDS,
        });
        setIsPDSModalOpen(false);
    };

    return (
        <div className="personal-data-wrapper">
            <div>
                <Header as="h3" floated="left" className="personal-data-header">
                    {title}
                    {isOrganisationDataFetched && patientDetailsIntegrationType !== PatientDetailsIntegration.PAS && (
                        <>
                            <div className="autofill-patient-details">
                                <Form.Checkbox
                                    checked={!!patientDetailsIntegrationType}
                                    toggle
                                    onChange={() => setIsPDSModalOpen(true)}
                                />
                                <div>
                                    <span>Autofill patient details at case creation</span>
                                    <span>Get patient details from PDS</span>
                                </div>
                            </div>
                        </>
                    )}

                    {isOrganisationDataFetched && (
                        <Header.Subheader size="medium" floated="left">
                            {subtitle}
                        </Header.Subheader>
                    )}
                </Header>
                <Header floated="right" className="edit-button-personal-data">
                    <OrganisationManagementTabHeader
                        organisationUuid={organisationUuid}
                        type={OrganisationManagementTabs.PERSONAL_DATA}
                        showEditButton={showEditButton}
                        updateOrganisation={handleUpdateInheritanceData}
                    />
                </Header>
            </div>
            <div className="horizontal-scroll">
                <CoreFieldsTable
                    organisationUuid={organisationUuid}
                    parentOrganisationUuid={parentOrganisationUuid}
                    newCustomCoreField={newCustomCoreField}
                    isEditable={isEditable}
                    organisationPersonalData={organisationPersonalData}
                    onFieldRemoved={handleFieldRemoved}
                    onFieldAdded={handleFieldAdded}
                    hideNewCustomCoreField={hideNewCustomCoreField}
                />
            </div>
            {isEditable && (
                <AddCoreField
                    organisationPersonalData={organisationPersonalData}
                    setNewCoreField={setNewCustomCoreField}
                    onFieldAdded={handleFieldAdded}
                />
            )}
            {isError && <ErrorMessage errors={error} />}
            {isPDSModalOpen && (
                <ModalDialog
                    title={
                        isPDSEnabled
                            ? "Disable PDS search for patient details"
                            : "Enable PDS search for patient details"
                    }
                    open={isPDSModalOpen}
                    iconSrc="/images/icons/icon-warning-yellow-bg.svg"
                    onClose={() => setIsPDSModalOpen(false)}
                    isWarning
                    buttons={[
                        {
                            onClick: () => handlePDSSwitchChange(),
                            text: "Continue",
                        },
                        {
                            onClick: () => setIsPDSModalOpen(false),
                            text: "Cancel",
                        },
                    ]}
                >
                    {popupModalBody}
                </ModalDialog>
            )}
        </div>
    );
};

export default CoreFields;
