import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";

import AiDiagnosisTable from "components/templates/AiDiagnosisTable";
import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import PatientBanner from "components/templates/PatientBanner";

import { IAssessment } from "model/assessment";
import { CaseStatus } from "model/caseStatus";

interface IAiResultProps {
    assessment: IAssessment;
    currentLesion: number;

    getCase: (caseUuid: string) => void;
}

class AiResults extends Component<IAiResultProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            aiResultReady: false,
            next: false,
            intervalId: null,
        };
    }

    public async componentDidMount() {
        const {
            assessment: { case: currentCase },
            currentLesion,
        } = this.props;
        if (!currentCase.caseStatus) {
            this.refreshCase();
        }
        if (currentLesion > 0) {
            this.refreshCase();
        }

        if (currentCase) {
            const intervalId = setInterval(this.refreshCase.bind(this), 3000);
            this.setState({ intervalId });
        }
    }

    public getCaseStaus() {
        const { assessment } = this.props;
        const { case: caseObject } = assessment;

        if (caseObject && caseObject.caseStatus) {
            return CaseStatus[caseObject.caseStatus];
        }

        return "Waiting for data";
    }

    public submit = () => {
        this.setState({ loading: true });
        this.setState({ next: true, loading: false });
    };

    private refreshCase() {
        const { assessment, currentLesion, getCase } = this.props;
        const { case: caseObject } = assessment;
        const { intervalId } = this.state;

        if (caseObject.uuid) {
            getCase(caseObject.uuid);
            if (caseObject.lesions && caseObject.lesions[currentLesion]) {
                if (caseObject.lesions[currentLesion].result) {
                    this.setState({ aiResultReady: true });
                    clearInterval(intervalId);
                }
            }
        }
    }

    public render() {
        const { next } = this.state;

        if (!this.props) {
            return <Redirect to="/" />;
        }

        if (next) {
            return <Redirect to="/update-diagnosis" />;
        }

        const { assessment, currentLesion } = this.props;
        const { loading, aiResultReady } = this.state;

        return (
            <Container className="ui segment wizzard-container">
                <PatientBanner
                    caseId={assessment.case.caseId}
                    patient={assessment.patient}
                    lesion={assessment.lesion}
                    lesionNumber={assessment.currentLesion}
                />

                <h1>Diagnosis from Artificial Intelligence</h1>
                <Divider />

                <AiDiagnosisTable case={assessment.case} lesionNumber={currentLesion} />

                <div style={{ paddingTop: 10 }}>
                    <Divider />

                    <ButtonContainer
                        button1={
                            <CustomButton variant="empty" type="link" to="/diagnosis" text="< Back" size="small" />
                        }
                        button2={
                            <CustomButton
                                variant="filled"
                                loading={loading}
                                disabled={!aiResultReady}
                                type="submit"
                                action={this.submit}
                                text="Next >"
                            />
                        }
                    />
                </div>
            </Container>
        );
    }
}

export default AiResults;
