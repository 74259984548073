import React, { FC } from "react";
import { Divider, Header } from "semantic-ui-react";

import MedicalQuestions from "components/CaseDescription/Questions/MedicalQuestions";

import { AssessmentTypes, IMedicalHistory } from "model/assessment";
import { IMedicalHistoryQuestion } from "model/organisation";

interface IQuestionsSection {
    questions: IMedicalHistory[];
    questionsDefinition: IMedicalHistoryQuestion[];
    caseQuestionsCreateDetails?: string;
    title?: string;
    assessmentType?: AssessmentTypes;
}

const QuestionsSection: FC<IQuestionsSection> = ({
    caseQuestionsCreateDetails,
    questions,
    questionsDefinition,
    title,
    assessmentType,
}) => {
    const isRemoteModel = assessmentType === AssessmentTypes.remoteModel;
    const headerClassName = isRemoteModel ? "" : "without-bottom-margin";

    return (
        <>
            {title && (
                <>
                    <Header as="h2" className={headerClassName}>
                        {title}
                    </Header>
                    {caseQuestionsCreateDetails && (
                        <p className="subtitle-gray-text assessed-by-text">{caseQuestionsCreateDetails}</p>
                    )}
                    <Divider className="title-divider" />
                </>
            )}
            <MedicalQuestions
                questions={questions}
                questionsDefinition={questionsDefinition}
                assessmentType={assessmentType}
            />
        </>
    );
};

QuestionsSection.defaultProps = {
    caseQuestionsCreateDetails: "",
    title: "",
    assessmentType: null,
};

export default QuestionsSection;
