import React, { FC } from "react";
import { Message } from "semantic-ui-react";

import "scss/ErrorMessage.scss";

interface IErrorMessage {
    errors: any;
}

const ErrorMessage: FC<IErrorMessage> = ({ errors }) => (
    <Message negative className="error-message">
        <Message.Header>Something went wrong</Message.Header>

        {errors?.errors?.length ? (
            <ul>
                {errors?.errors?.map((err: { message: string; details: string }) => (
                    <li key={err.message}>{err.message}</li>
                ))}
            </ul>
        ) : (
            <p>{errors}</p>
        )}
    </Message>
);

export default ErrorMessage;
