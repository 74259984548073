import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import PatientLookupForm from "components/PatientLookup/PatientLookupForm/PatientLookupForm";
import PatientMatch from "components/PatientLookup/PatientMatch";
import PatientAdditionalDetails from "components/PatientLookup/PatientAdditionalDetails";
import SavedPatientDetails from "components/PatientLookup/SavedPatientDetails";

import { IPatientLookupMatched, IPatientLookupForm, PatientLookupRender } from "model/patientLookup";
import { ICase } from "model/case";
import { IOrganisation } from "model/organisation";
import PatientDetailsIntegration from "model/integrations";

import { LESION_LOCATOR, MEDICAL_HISTORY, NEW_ASSESSMENT } from "navigation/routes";

import { getPatient } from "redux/selectors/assessment";

import "scss/PatientDetails.scss";

interface IPatientLookup {
    case: ICase;
    organisation: IOrganisation;
    pendingRequest: boolean;
    createPatient: (caseUuid: string, patientObj: any, onSuccess?: () => void) => Promise<any>;
    updatePatient: (
        patientUuid: string,
        patientObj: any,
        assessment?: boolean,
        callback?: (error?: any) => Promise<void>
    ) => any;
}

const PatientLookup = ({
    organisation,
    createPatient,
    case: stateCase,
    pendingRequest,
    updatePatient,
}: IPatientLookup) => {
    const patient = useSelector(getPatient);
    const history = useHistory();
    const location: any = useLocation();
    const { defaultPDSSearchCombo, values: passedPatientData } = location?.state || {};

    const [combination, setCombination] = useState<number>(defaultPDSSearchCombo || 1);
    const [matchedPatient, setMatchedPatient] = useState<IPatientLookupMatched>({});
    const [defaultSearchParams, setDefaultSearchParams] = useState<IPatientLookupForm>(passedPatientData || {});
    const [searchAttempts, setSearchAttempts] = useState<number>(0);
    const [isSupersededNhsNumber, setIsSupersededNhsNumber] = useState<boolean>(false);
    const [patientLookupRender, setPatientLookupRender] = useState<PatientLookupRender>(PatientLookupRender.FORM);

    const { definedPersonalData, medicalHistoryQuestions } = organisation;

    const additionalRequiredPatientDetails = definedPersonalData.filter(
        (field) =>
            field.integrationSourceSystem !== PatientDetailsIntegration.PDS &&
            !field.removed &&
            field.isRequestedAtManualCreation
    );

    useEffect(() => {
        if (patient?.patientData) {
            const patientDetails: IPatientLookupMatched = {};
            patient.patientData.forEach((dataPoint) => {
                patientDetails[dataPoint.name] =
                    dataPoint.textValue || dataPoint.dateValue || dataPoint.numberValue || dataPoint.booleanValue;
            });

            setMatchedPatient(patientDetails);
            if (
                !stateCase?.homeInitiated ||
                patient.integrationInfo?.integrationName === PatientDetailsIntegration.PDS
            ) {
                setPatientLookupRender(PatientLookupRender.SAVED_DETAILS);
            }
        }
    }, [patient]);

    const backToMatchedPatient = (matchedPatientDetails: IPatientLookupMatched): void => {
        setPatientLookupRender(PatientLookupRender.MATCH);
        setMatchedPatient({ ...matchedPatientDetails });
    };

    const handleAttemptedSearch = (): void => {
        setSearchAttempts(searchAttempts + 1);
    };

    const handlePatientDetailsCompleted = (): void => {
        if (medicalHistoryQuestions && medicalHistoryQuestions.filter((question) => !question.removed).length > 0) {
            history.push(MEDICAL_HISTORY);
        } else {
            history.push(LESION_LOCATOR);
        }
    };

    const savePatientDetails = async (newPatientDetails: IPatientLookupMatched): Promise<void> => {
        const patientDetailsToSave = { ...newPatientDetails, integrationInfo: { integrationName: "PDS" } };
        const { postCode, ...rest } = patientDetailsToSave;
        const patientToSave = { ...rest };
        patientToSave.birth = moment(patientToSave.birth).format("YYYY-MM-DD");
        const patientAlreadyExists = !!patient?.uuid;
        const patientData = patientAlreadyExists
            ? await updatePatient(patient.uuid, patientToSave)
            : await createPatient(stateCase.uuid, patientToSave);
        if (!pendingRequest && patientData?.uuid) {
            handlePatientDetailsCompleted();
        }
    };

    const handlePatientMatchContinue = async (patientDetails: IPatientLookupMatched): Promise<void> => {
        const patientDetailsToSave = { ...patientDetails, integrationInfo: { integrationName: "PDS" } };
        const patientAlreadySaved = patient?.uuid;
        const isAlreadyPDSVerified = patient?.integrationInfo?.integrationName === PatientDetailsIntegration.PDS;

        if (patientAlreadySaved) {
            savePatientDetails(patientDetailsToSave);

            if (isAlreadyPDSVerified) {
                setPatientLookupRender(PatientLookupRender.SAVED_DETAILS);
            } else {
                handlePatientDetailsCompleted();
            }
            return null;
        }
        return additionalRequiredPatientDetails.length
            ? setPatientLookupRender(PatientLookupRender.ADDITIONAL_DETAILS)
            : savePatientDetails(patientDetailsToSave);
    };

    return (
        <div className="patient-lookup">
            <Container className="ui segment wizzard-container">
                {patientLookupRender === PatientLookupRender.FORM && (
                    <PatientLookupForm
                        case={stateCase}
                        combination={combination}
                        setCombination={setCombination}
                        setMatchedPatient={setMatchedPatient}
                        defaultSearchParams={matchedPatient || defaultSearchParams}
                        setDefaultSearchParams={setDefaultSearchParams}
                        searchAttempts={searchAttempts}
                        handleSearchAttempt={handleAttemptedSearch}
                        setIsSupersededNhsNumber={setIsSupersededNhsNumber}
                        setPatientLookupRender={setPatientLookupRender}
                        handlePatientMatchContinue={handlePatientMatchContinue}
                        continueWithoutVerifying={handlePatientDetailsCompleted}
                    />
                )}
                {patientLookupRender === PatientLookupRender.MATCH && (
                    <PatientMatch
                        matchedPatient={matchedPatient}
                        combination={combination}
                        goBack={() => setPatientLookupRender(PatientLookupRender.FORM)}
                        next={handlePatientMatchContinue}
                        isSupersededNhsNumber={isSupersededNhsNumber}
                    />
                )}
                {patientLookupRender === PatientLookupRender.ADDITIONAL_DETAILS && (
                    <PatientAdditionalDetails
                        additionalDataFields={additionalRequiredPatientDetails}
                        patientDetails={matchedPatient}
                        savePatientDetails={savePatientDetails}
                        back={backToMatchedPatient}
                    />
                )}

                {patientLookupRender === PatientLookupRender.SAVED_DETAILS && (
                    <SavedPatientDetails
                        matchedPatient={matchedPatient}
                        goBack={() => history.push(NEW_ASSESSMENT)}
                        next={handlePatientDetailsCompleted}
                        additionalRequiredPatientDetails={additionalRequiredPatientDetails}
                        updatePatient={updatePatient}
                        setMatchedPatient={setMatchedPatient}
                        setPatientLookupRender={setPatientLookupRender}
                    />
                )}
            </Container>
        </div>
    );
};

export default PatientLookup;
