import React, { FC, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Header, Image } from "semantic-ui-react";
import YouTube from "react-youtube";

import RemoteModelContainer from "components/templates/RemoteModelContainer";
import CustomButton from "components/templates/Button";
import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";
import QRCodePopup from "components/templates/QRCodePopup";
import SupportEmailLink from "components/templates/SupportEmailLink";

import { getBodyForQrCode, doesLesionContainNeededImages } from "helpers/assessment";
import { checkArraysEquality } from "helpers/common";

import { ILesion } from "model/assessment";
import { RemoteTimeline } from "model/remoteCaseTimeline";
import { ICase, IImage } from "model/case";

import { history } from "App";
import { REMOTE_MODEL_CASES_LESION_DETAILS, REMOTE_MODEL_CASES_SUMMARY } from "navigation/remoteModelRoutes";

import { getAssessment } from "redux/selectors/assessment";
import getAuth from "redux/selectors/auth";

import lesionService from "services/lesionService";
import * as caseService from "services/caseService";

const POLL_INTERVAL = 3000;
const BACK_CASE_SYNC_INTERVAL = 500;

const LesionImages: FC = () => {
    const assessment = useSelector(getAssessment);
    const auth = useSelector(getAuth);
    const dispatch = useDispatch();
    const [backButtonLoading, setBackButtonLoading] = useState<boolean>(false);
    const caseSyncIntervalId = useRef<NodeJS.Timer>();
    const currentCaseRef = useRef<ICase>();

    const oldImages = useRef<IImage[] | null>(null);

    const { case: currentCase, currentLesion: currentLesionIndex, lesion: currentLesion } = assessment;
    const { uuid: currentCaseUuid } = currentCase;
    const { uuid: currentLesionUuid } = { ...currentLesion };
    currentCaseRef.current = currentCase;

    const fetchOldImages = async () => {
        const lesion = currentLesion && (await lesionService.getLesion(currentLesion.uuid));
        oldImages.current = lesion?.images;
    };

    const refreshLesion = async () => {
        const lesion: ILesion = currentLesion ? await lesionService.getLesion(currentLesion.uuid) : null;
        const hasImagesToRetake = (lesion?.images as IImage[])?.some((image) => !image.removed && image.retake);
        const areOldImages =
            oldImages?.current && lesion?.images && checkArraysEquality(oldImages?.current as IImage[], lesion?.images);

        if (lesion?.images && !areOldImages && !hasImagesToRetake && doesLesionContainNeededImages(lesion.images)) {
            history.push(REMOTE_MODEL_CASES_SUMMARY);
        }
    };

    useEffect(() => {
        fetchOldImages();
    }, []);

    useEffect(() => {
        dispatch(caseService.getCaseForAssessment(currentCaseUuid));
    }, [currentCaseUuid]);

    useEffect(() => {
        const intervalIdentifier = setInterval(refreshLesion, POLL_INTERVAL);

        return () => {
            clearInterval(intervalIdentifier);
        };
    }, [currentLesionUuid]);

    const handleGoBack = () => {
        if (currentCaseRef.current.lesions.length < currentLesionIndex + 1) {
            dispatch(caseService.getCaseForAssessment(currentCaseUuid));
        } else {
            setBackButtonLoading(false);
            clearInterval(caseSyncIntervalId.current);
            history.push(oldImages?.current ? REMOTE_MODEL_CASES_SUMMARY : REMOTE_MODEL_CASES_LESION_DETAILS);
        }
    };

    const goBack = (): void => {
        setBackButtonLoading(true);
        caseSyncIntervalId.current = setInterval(handleGoBack, BACK_CASE_SYNC_INTERVAL);
    };

    const containerHeader = `Lesion #${currentLesionIndex + 1} Details`;

    return (
        <>
            <CaseStatusContainer activeStatus={RemoteTimeline.LESION_IMAGES} />
            <RemoteModelContainer header={containerHeader}>
                <p>
                    To capture images of the lesion, scan the QR code using the Skin Analytics app.
                    <br />
                    <b>
                        If the lesion is on your face or in a hard-to-reach place, ask someone to help you take the
                        images.
                    </b>
                </p>
                <Grid className="remote-model-lesion-image qr-code-page">
                    <Grid.Column mobile={16} computer={6}>
                        {assessment.lesion && <QRCodePopup value={getBodyForQrCode(auth.refreshToken, assessment)} />}
                    </Grid.Column>
                    <Grid.Column computer={10} mobile={16} verticalAlign="middle">
                        <div className="remote-model-instructions">
                            <Image
                                alt="Instruction one - switch on your phone and connect to your WiFi network."
                                src="/images/instructions1.svg"
                            />
                            <Image
                                alt="Instruction two - click on the Skin Analytics app on the home screen."
                                src="/images/instructions2.svg"
                            />
                            <Image
                                alt="Instruction three - point the camera at the QR code to start."
                                src="/images/instructions3.svg"
                            />
                        </div>
                        <p>
                            Head to our{" "}
                            <a href="https://skin-analytics.com/faq/" target="_blank" rel="noreferrer">
                                Help Centre
                            </a>{" "}
                            if you have any questions or contact us on <SupportEmailLink />.
                        </p>
                    </Grid.Column>
                </Grid>
                <Header as="h2">How to</Header>
                <Divider />
                <p>Have a look at the video guide below on how to take good quality images of your lesion.</p>
                <div className="remote-model-youtube_wrapper">
                    <YouTube videoId="1qgXhjBe4z4" />
                </div>
                <div className="buttons-wrapper">
                    <CustomButton
                        type="button"
                        variant="empty-dark"
                        className="teal"
                        action={goBack}
                        text="Back"
                        disabled={!assessment.case.lesions}
                        loading={!assessment.case.lesions || backButtonLoading}
                    />
                </div>
            </RemoteModelContainer>
        </>
    );
};

export default LesionImages;
