import moment from "moment";

import { IMedicalHistoryAnswers } from "model/assessment";
import { PDSFields, IPatientLookupForm, IPDSPatientLookupResponse } from "model/patientLookup";
import { IPatient, ISkinToneAnswer } from "model/case";
import IClickOrigin from "model/clickOrigin";
import { FitzpatrickAnswers } from "model/fixedQuestion";
import { FormFields, IPatientSearchForm } from "model/patientSearch";

import * as ErrorService from "services/errorService";
import URLs from "services/urls";

import { formatMomentDateForForm } from "helpers/datetime";
import * as actions from "redux/actions";
import store, { Dispatch } from "redux/store";

import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

const {
    CREATE_PATIENT_URL,
    UPDATE_PATIENT_URL,
    SUBMIT_MEDICAL_ANSWERS_URL,
    SEARCH_INTEGRATION_PATIENT_URL,
    CREATE_PATIENT_FROM_INTEGRATION_URL,
    SUBMIT_SKIN_TONE_CLASSIFICATION,
} = URLs;

function createPatient(caseUuid: string, patientObj: any, onSuccess: () => void = () => undefined) {
    return (dispatch: Dispatch) =>
        AuthorizedHttpConnector.sendPost(CREATE_PATIENT_URL, { caseUuid, ...patientObj })
            .then((response) => {
                dispatch(actions.assessmentActions.createPatientSuccess(response.data.data));
                onSuccess();
                return response.data.data;
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
}

function updatePatient(patientUuid: string, patientObj: any, assessment = true, callback?: (error?: any) => void) {
    return (dispatch: Dispatch) => {
        AuthorizedHttpConnector.sendPut(UPDATE_PATIENT_URL.replace("{uuid}", patientUuid), patientObj)
            .then((response) => {
                if (assessment) {
                    const currentPatient = store.store.getState().assessment.patient;
                    Object.assign(currentPatient.patientData, response.data.data.patientData);
                    if (currentPatient?.integrationInfo && !response?.data?.data?.integrationInfo) {
                        delete currentPatient.integrationInfo;
                    }
                    dispatch(actions.assessmentActions.createPatientSuccess(response.data.data));
                }
                if (callback) {
                    callback();
                }
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
                if (callback) {
                    callback(err);
                }
            });
    };
}

function submitMedicalAnswers(answers?: IMedicalHistoryAnswers[]) {
    const patientObj = store.store.getState().assessment.patient;
    return async (dispatch: Dispatch) => {
        AuthorizedHttpConnector.sendPost(SUBMIT_MEDICAL_ANSWERS_URL.replace("{uuid}", patientObj.uuid), {
            medicalHistoryAnswers: answers,
        })
            .then((response) => {
                dispatch(actions.assessmentActions.createPatientSuccess(response.data.data));
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
    };
}

function generateSearchUrl(url: URL, searchForm: IPatientSearchForm | IPatientLookupForm): string {
    Object.keys(searchForm).forEach((property) => {
        const value = searchForm[property];
        if (value) {
            const isNhsNumber = property === FormFields.nhsNumber || property === PDSFields.nhsNumber;
            const formattedValue = isNhsNumber ? value.replaceAll(" ", "") : value;
            url.searchParams.append(property, formattedValue);
        }
    });
    return url.href;
}

async function searchIntegrationPatientPAS(searchForm: IPatientSearchForm) {
    const endpoint = new URL(SEARCH_INTEGRATION_PATIENT_URL);
    const searchUrl = generateSearchUrl(endpoint, searchForm);
    const searchResult = await AuthorizedHttpConnector.sendGet(searchUrl);
    return searchResult;
}

async function searchIntegrationPatientPDS(
    searchForm: IPatientLookupForm,
    combination: number
): Promise<IPDSPatientLookupResponse> {
    const { nhs, surname, birth, postCode, gender, caseUuid, name } = searchForm;

    const searchParams: IPatientLookupForm = { birth: formatMomentDateForForm(moment(birth)), caseUuid };

    if (combination === 1) {
        searchParams.nhs = nhs.replace(/ /g, "");
    } else {
        searchParams.gender = gender;
        searchParams.surname = surname;
        searchParams.postCode = postCode;
        if (name) {
            searchParams.name = name;
        }
    }

    const endpoint = new URL(SEARCH_INTEGRATION_PATIENT_URL);

    const searchUrl = generateSearchUrl(endpoint, searchParams);

    const searchResult = await AuthorizedHttpConnector.sendGet(searchUrl);

    return searchResult.data.data;
}

async function createPatientFromIntegration(caseUuid: string, hospitalNumber: string): Promise<IPatient> {
    const createPatientResponse = await AuthorizedHttpConnector.sendPost(CREATE_PATIENT_FROM_INTEGRATION_URL, {
        caseUuid,
        hospitalNumber,
    });
    return createPatientResponse.data.data;
}

async function updateSkinToneClassification(
    patientUuid: string,
    caseUuid: string,
    skinToneClassification: FitzpatrickAnswers,
    clickOrigin: IClickOrigin
): Promise<ISkinToneAnswer[]> {
    const response = await AuthorizedHttpConnector.sendPost(
        SUBMIT_SKIN_TONE_CLASSIFICATION.replace("{{UUID}}", patientUuid).replace("{{CLICKORIGIN}}", clickOrigin),
        {
            caseUuid,
            skinToneClassification,
        }
    );
    return response.data.data;
}

export {
    createPatient,
    submitMedicalAnswers,
    updatePatient,
    searchIntegrationPatientPAS,
    searchIntegrationPatientPDS,
    createPatientFromIntegration,
    updateSkinToneClassification,
};
