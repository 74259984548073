import React, { FC, useContext, ChangeEvent, SyntheticEvent } from "react";
import { Dropdown, DropdownProps, Grid, Input, InputProps } from "semantic-ui-react";

import { CaseListContext } from "contextProviders/CaseListProvider";

import createDropdownOptions from "helpers/semanticUi";

import { CaseListSearchFieldNames, CaseListSearchFieldMapping } from "model/caseList";

import "scss/CaseListSearchRow.scss";

const CaseListSearchRow: FC = () => {
    const { currentTabCases, setFilter } = useContext(CaseListContext);

    const { filters: filterValues } = currentTabCases;
    const { activeSearchField = "" } = { ...filterValues };
    const filterKey = CaseListSearchFieldMapping[activeSearchField];
    const searchValue = filterValues ? filterValues[filterKey] : "";

    const updateTextFilter = (event: ChangeEvent<HTMLInputElement>, data: InputProps) => {
        setFilter({ [filterKey]: data.value });
    };

    const handleDropdownChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        setFilter({
            [filterKey]: "",
            activeSearchField: data.value,
        });
    };

    return (
        <Grid className="search-full-row">
            <Grid.Column width={16}>
                <Input
                    action={
                        <Dropdown
                            className="search-full-row__dropdown"
                            button
                            basic
                            value={activeSearchField}
                            options={createDropdownOptions(CaseListSearchFieldNames)}
                            onChange={handleDropdownChange}
                        />
                    }
                    actionPosition="left"
                    placeholder="Search..."
                    icon="search"
                    onChange={updateTextFilter}
                    value={searchValue}
                />
            </Grid.Column>
        </Grid>
    );
};

export default CaseListSearchRow;
