import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";
import CheckIcon from "@material-ui/icons/Check";

import AssessmentFlowConfirmModal from "components/templates/AssessmentFlowConfirmModal";
import AssessmentFlowHeader from "components/templates/AssessmentFlowHeader";
import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import PatientDetailsEdit from "components/PatientDetails/PatientDetailsEdit";
import PatientDetailsValidation from "components/PatientDetails/PatientDetailsValidation";
import LoadingSpinner from "components/templates/LoadingSpinner";
import AddPatientNoteDialog from "components/templates/dialog/AddPatientNoteDialog";
import CancelEditPatientInfoDialog from "components/templates/dialog/CancelEditPatientInfoDialog";
import ModalDialog from "components/templates/dialog/ModalDialog";

import { getInitialPatientData } from "helpers/patientUtils";

import { ICase } from "model/case";
import { IDefinedPersonalData, IOrganisation } from "model/organisation";
import { IPatientEdit } from "model/patientEdit";
import PatientDetailsIntegration from "model/integrations";

import * as ROUTES from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";
import * as caseService from "services/caseService";
import organizationService from "services/organizationService";

import "scss/InputField.scss";

interface ICasePatientDetails {
    match: any;
    organisation: IOrganisation;
    pendingRequest: boolean;
    updatePatient: (
        patientUuid: string,
        patient: IPatientEdit,
        assessment: boolean,
        callback: (err?: any) => void
    ) => void;
}

interface ICasePatientDetailsState {
    caseUuid: string;
    case: ICase | undefined;
    caseOrganisation: IOrganisation | undefined;
    editMode: boolean;
    edited: boolean;
    stateErrors: any;
    errors: {
        list: any[];
        show: false;
    };
    next: boolean;
    patient: IPatientEdit;
    personalDataFields: IDefinedPersonalData[];
    showCancelModal: boolean;
    showAddPatientNoteModal: boolean;
    showCancelEditModal: boolean;
    showSuccessModal: boolean;
    showErrorDialog: boolean;
    formValidated: boolean;
    PDSBackgroundCollectedFields: IDefinedPersonalData[];
}

class CasePatientDetails extends Component<ICasePatientDetails, ICasePatientDetailsState> {
    constructor(props: any) {
        super(props);

        const { match } = this.props;
        const caseUuid = match.params.caseId;

        this.state = {
            caseUuid,
            case: undefined,
            caseOrganisation: undefined,
            editMode: false,
            edited: false,
            errors: {
                list: [],
                show: false,
            },
            stateErrors: {},
            next: false,
            patient: {},
            personalDataFields: [],
            showCancelModal: false,
            showAddPatientNoteModal: false,
            showCancelEditModal: false,
            showSuccessModal: false,
            showErrorDialog: false,
            formValidated: false,
            PDSBackgroundCollectedFields: [],
        };
    }

    public componentDidMount() {
        logoutTimeoutService.stopCount();

        this.fetchCase();
    }

    private setShowAddPatientNoteDialog = (show: boolean) => {
        this.setState({ showAddPatientNoteModal: show });
    };

    private setShowCancelEditPatientDialog = (show?: boolean) => {
        const { showCancelEditModal } = this.state;

        this.setState({ showCancelEditModal: show || !showCancelEditModal });
    };

    private onCloseSuccessDialog = () => {
        const { case: caseObject } = this.state;
        const path = `${ROUTES.CASE_DESCRIPTION}/${caseObject.uuid}`;

        window.location.href = path;
    };

    private getAddPatientNoteDialog = () => {
        const { showAddPatientNoteModal, case: caseObject, caseOrganisation, patient } = this.state;

        return (
            showAddPatientNoteModal && (
                <AddPatientNoteDialog
                    showDialog={showAddPatientNoteModal}
                    callback={this.setShowAddPatientNoteDialog}
                    caseObject={caseObject}
                    patientObject={patient}
                    organisation={caseOrganisation}
                    onSubmit={this.updatePatient}
                />
            )
        );
    };

    private getCancelEditPatientDialog = () => {
        const { showCancelEditModal, case: caseObject } = this.state;

        return (
            showCancelEditModal && (
                <CancelEditPatientInfoDialog
                    showDialog={showCancelEditModal}
                    callback={this.setShowCancelEditPatientDialog}
                    caseObject={caseObject}
                />
            )
        );
    };

    private getSuccessDialog = () => {
        const { showSuccessModal } = this.state;

        return (
            showSuccessModal && (
                <ModalDialog
                    title="Information edited successfully"
                    open={showSuccessModal}
                    onClose={this.onCloseSuccessDialog}
                    maxWidth="sm"
                    iconComponent={CheckIcon}
                    buttons={[
                        {
                            onClick: this.onCloseSuccessDialog,
                            text: "Back to case view",
                            colour: "primary",
                        },
                    ]}
                />
            )
        );
    };

    private changeFieldValue = (fieldName: string, fieldValue: string) => {
        const { organisation } = this.props;
        const { patient, personalDataFields, formValidated, stateErrors } = this.state;
        patient[fieldName] = fieldValue;

        if (formValidated) {
            const validationResult = PatientDetailsValidation.validate(organisation, personalDataFields, patient);
            if (validationResult.isValid) {
                this.setState({
                    patient,
                    edited: true,
                    errors: {
                        ...stateErrors,
                        list: validationResult.errorsList,
                        showModal: false,
                        show: true,
                    },
                });
            } else {
                this.setState({
                    errors: {
                        ...stateErrors,
                        list: validationResult.errorsList,
                        showModal: false,
                        show: true,
                    },
                });
            }
        } else {
            this.setState({ patient, edited: true });
        }
    };

    private trimAllPatientStringKeys = (patientEdit: IPatientEdit): void => {
        const patientEditClone = { ...patientEdit };
        Object.keys(patientEditClone).forEach((objectKey) => {
            const fieldValue = patientEditClone[objectKey];

            if (typeof fieldValue === "string") {
                patientEditClone[objectKey] = fieldValue.trim();
            }
        });

        this.setState({ patient: patientEditClone });
    };

    private pressEditSave = () => {
        const { personalDataFields, patient, stateErrors } = this.state;
        const { organisation } = this.props;
        const { editMode } = this.state;

        if (!editMode) {
            this.setState({ errors: { list: [], show: false } });
            this.setInitialPatientData();
        } else {
            this.trimAllPatientStringKeys(patient);
            const validationResult = PatientDetailsValidation.validate(organisation, personalDataFields, {
                ...patient,
            });

            if (validationResult.isValid) {
                this.setState({ errors: { list: [], show: false }, formValidated: true });
                this.setShowAddPatientNoteDialog(true);
            } else {
                this.setState({
                    formValidated: true,
                    errors: {
                        ...stateErrors,
                        list: validationResult.errorsList,
                        showModal: false,
                        show: true,
                    },
                    showAddPatientNoteModal: false,
                });
            }
        }
    };

    private updatePatientCallback = (error?: any) => {
        if (error) {
            this.setState({ showErrorDialog: true });
        } else {
            this.setState({ editMode: false, edited: false, showSuccessModal: true });
        }
    };

    private updatePatient = (patientNote?: string, hasPDSFieldChanged?: boolean): void => {
        const { showCancelModal, edited, patient, case: caseObject } = this.state;
        const { pendingRequest, updatePatient } = this.props;
        const isPDSCreatedPatient =
            caseObject?.patient?.integrationInfo?.integrationName === PatientDetailsIntegration.PDS;

        if (!pendingRequest) {
            const updatedPatient = {
                ...patient,
                patientNote,
                caseUuid: caseObject?.uuid,
            };

            if (edited) {
                if (isPDSCreatedPatient && !hasPDSFieldChanged) {
                    updatedPatient.integrationInfo = caseObject?.patient?.integrationInfo;
                }

                updatePatient(caseObject.patient.uuid, updatedPatient, false, this.updatePatientCallback);
                this.setShowAddPatientNoteDialog(false);
            }

            if (showCancelModal) {
                this.moveForward();
            }
        }
    };

    private closeModal = () => {
        this.setState({ showCancelModal: false });
    };

    private moveForward = () => {
        this.setState({
            next: true,
        });
    };

    private resetEdit = () => {
        this.setInitialPatientData();
    };

    private setInitialPatientData = () => {
        const {
            case: { patient },
            personalDataFields,
            editMode,
            PDSBackgroundCollectedFields,
        } = this.state;

        const requestAtManualCreationPatientData = getInitialPatientData(personalDataFields, patient);

        const pdsBackgroundCollectedData: IPatientEdit =
            patient.integrationInfo?.integrationName === PatientDetailsIntegration.PDS &&
            PDSBackgroundCollectedFields.length
                ? getInitialPatientData(PDSBackgroundCollectedFields, patient)
                : {};

        const initialPatientData = { ...requestAtManualCreationPatientData, ...pdsBackgroundCollectedData };

        this.setState({ editMode: !editMode, patient: initialPatientData, edited: false });
    };

    private fetchCase = () => {
        const { caseUuid } = this.state;
        caseService.getCaseAsync(caseUuid).then((response: ICase) => {
            this.setState({ case: response });
            this.fetchCaseOrganisation();
        });
    };

    private fetchCaseOrganisation = () => {
        const { case: caseObject } = this.state;

        if (caseObject?.organisationUuid) {
            organizationService.getOrganisationByUuid(caseObject.organisationUuid).then((response: IOrganisation) => {
                const { definedPersonalData: definedPersonalDataFromResponse, patientDetailsIntegrationType } =
                    response;

                const isPatientDetailsIntegrationOrg = Boolean(patientDetailsIntegrationType);
                const definedPersonalData = definedPersonalDataFromResponse || [];
                const personalDataFields = definedPersonalData
                    .filter(
                        (field: IDefinedPersonalData) =>
                            !field.removed &&
                            (!isPatientDetailsIntegrationOrg ||
                                (isPatientDetailsIntegrationOrg && field.isRequestedAtManualCreation))
                    )
                    .sort(
                        (obj1: IDefinedPersonalData, obj2: IDefinedPersonalData) =>
                            obj1.displayOrder - obj2.displayOrder
                    );

                if (isPatientDetailsIntegrationOrg) {
                    const PDSBackgroundFields = definedPersonalData.filter(
                        (field: IDefinedPersonalData) =>
                            !field.removed &&
                            !field.isRequestedAtManualCreation &&
                            field.integrationSourceSystem === PatientDetailsIntegration.PDS
                    );
                    this.setState({ PDSBackgroundCollectedFields: PDSBackgroundFields });
                }
                const initialPatientData = getInitialPatientData(personalDataFields, caseObject.patient);
                this.setState({ caseOrganisation: response, personalDataFields, patient: initialPatientData });
            });
        }
    };

    public render() {
        const {
            next,
            editMode,
            personalDataFields,
            case: caseObject,
            caseOrganisation,
            errors,
            showCancelModal,
            patient: patientState,
            showErrorDialog,
        } = this.state;
        const { pendingRequest } = this.props;
        const backToCaseUrl = `${ROUTES.CASE_DESCRIPTION}/${caseObject?.uuid}`;

        if (caseObject?.patient && next) {
            return <Redirect to={backToCaseUrl} />;
        }

        if (!caseObject?.patient && !caseOrganisation) {
            return <LoadingSpinner />;
        }

        return (
            <Container className="ui segment wizzard-container">
                {this.getAddPatientNoteDialog()}
                {this.getCancelEditPatientDialog()}
                {this.getSuccessDialog()}
                <AssessmentFlowHeader
                    title="Patient Details"
                    isEditMode={editMode}
                    isRemoteModel={caseObject?.remote}
                    disabled={!caseObject.patient}
                    edit={this.pressEditSave}
                    reset={this.resetEdit}
                />
                <Divider />
                <PatientDetailsEdit
                    patient={patientState}
                    editMode={editMode}
                    personalDataFields={personalDataFields}
                    errors={errors}
                    organisation={caseOrganisation}
                    changeFieldValue={this.changeFieldValue}
                    patientCreated
                />
                <div className="vertical-space">
                    <Divider />
                    <ButtonContainer
                        button1={
                            <CustomButton
                                variant="empty"
                                type="submit"
                                action={this.setShowCancelEditPatientDialog}
                                text="Back to Case View"
                                size="small"
                                disabled={pendingRequest}
                            />
                        }
                    />
                </div>
                <AssessmentFlowConfirmModal
                    show={showCancelModal}
                    close={this.closeModal}
                    goAhead={this.moveForward}
                    save={this.pressEditSave}
                />
                <ModalDialog
                    title="Save failed"
                    open={showErrorDialog}
                    iconSrc="/images/icons/icon-warning-red-bg.svg"
                    onClose={() => this.setState({ showErrorDialog: false })}
                    maxWidth="sm"
                    disableBackdropClick
                    buttons={[
                        {
                            onClick: () => this.setState({ showErrorDialog: false }),
                            text: "Close",
                            colour: "primary",
                        },
                    ]}
                >
                    <p>
                        There was an unexpected error while saving the changes, please try again and if the problem
                        persists contact support at{" "}
                        <a href="mailto:support@skinanalytics.co.uk">support@skinanalytics.co.uk</a>
                    </p>
                </ModalDialog>
            </Container>
        );
    }
}

export default CasePatientDetails;
