import React, { Component } from "react";
import { Divider, Grid, Segment } from "semantic-ui-react";

import SuborganisationTable from "components/Administration/OrganisationManagment/ViewOrganisation/Suborganisations/SuborganisationTable";

import { IOrganisation } from "model/organisation";

import organizationService from "services/organizationService";

interface ISuborganisationsComponentProps {
    organisation: IOrganisation;
}

class SuborganisationsComponent extends Component<ISuborganisationsComponentProps, any> {
    public componentDidMount() {
        const { organisation } = this.props;

        organizationService.getAllSuborganisations(organisation.uuid, 0);
    }

    public render() {
        const { organisation } = this.props;
        return (
            <Segment>
                <h3>Suborganisations</h3>
                <Divider />
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <SuborganisationTable organisation={organisation} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}

export default SuborganisationsComponent;
