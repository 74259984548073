import { FC, ReactNode, useState } from "react";
import { Grid, Label, Table } from "semantic-ui-react";

import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";
import ContextIconButton from "components/templates/ContextIconButton";
import ReviewEntityDetailsDialog from "components/templates/dialog/ReviewEntityDetailsDialog";

import { ReviewEntities } from "model/administrationPages";
import { IManagementOptions, IOrganisation, IReviewDiagnosis } from "model/organisation";

import "scss/AdminPanel.scss";
import "scss/Container.scss";

interface IPreviewDiagnosisTableProps {
    organisation?: IOrganisation;
    reviewDiagnoses: IReviewDiagnosis[];
    reviewManagementOptions: IManagementOptions[];
}

interface IDetailsDialogOptions {
    type?: ReviewEntities;
    uuid: string;
}

const DETAILS_DIALOG_OPTIONS_INITIAL = { uuid: "" };

const PreviewDiagnosisTable: FC<IPreviewDiagnosisTableProps> = ({
    organisation,
    reviewDiagnoses,
    reviewManagementOptions,
}) => {
    const [detailsDialogOptions, setDetailsDialogOptions] =
        useState<IDetailsDialogOptions>(DETAILS_DIALOG_OPTIONS_INITIAL);

    const showDetailsDialog = (uuid: string, type: ReviewEntities): void => {
        setDetailsDialogOptions({ uuid, type });
    };

    const closeDetailsDialog = (): void => {
        setDetailsDialogOptions(DETAILS_DIALOG_OPTIONS_INITIAL);
    };

    const findManagementOutcome = (uuid: string): ReactNode[] => {
        const { uuid: detailsDialogUuid, type: detailsDialogType } = detailsDialogOptions;
        const { parentUuid } = organisation;
        const managementOutcomes: ReactNode[] = [];

        if (reviewManagementOptions) {
            const currentManagementOutcome = reviewManagementOptions.find(
                (option) => option.uuid === uuid && !option.removed
            );
            const showDialog = detailsDialogType === ReviewEntities.MANAGEMENT_OUTCOME && detailsDialogUuid === uuid;

            if (!currentManagementOutcome) {
                return null;
            }

            const handleShowDialog = (): void => {
                showDetailsDialog(uuid, ReviewEntities.MANAGEMENT_OUTCOME);
            };

            const { copyOfUuid, management, organisationUuid, refer, freeText, keyMessage, safetyNetting, discharge } =
                currentManagementOutcome;

            const currentManagementOutcomesItem = (
                <Grid.Row key={uuid}>
                    <Grid.Column>
                        <li>
                            <SettingsPropertyWrapper
                                copyOfUuid={copyOfUuid}
                                parentOrgUuid={parentUuid}
                                propertyOrganisationUuid={organisationUuid}
                            >
                                {management}
                            </SettingsPropertyWrapper>
                        </li>
                    </Grid.Column>
                    <Grid.Column>
                        <ReviewEntityDetailsDialog
                            managementOption={currentManagementOutcome}
                            onClose={closeDetailsDialog}
                            showDialog={showDialog}
                        />
                        <ContextIconButton iconName="magnify" onClick={handleShowDialog} content="details" />
                    </Grid.Column>

                    <Grid.Column>
                        {refer && <Label size="mini" color="red" content="Refer according to pathway" />}
                        {freeText && <Label size="mini" color="blue" content="Free text" />}
                        {keyMessage && <Label size="mini" color="green" content="Key message" />}
                        {safetyNetting && <Label size="mini" color="brown" content="Safety netting" />}
                        {discharge && <Label size="mini" content="Discharge flag" className="discharge-label" />}
                    </Grid.Column>
                </Grid.Row>
            );

            managementOutcomes.push(currentManagementOutcomesItem);
        }
        return managementOutcomes;
    };

    const getReviewDiagnosesTableRows = (): ReactNode | ReactNode[] => {
        const { type: detailsDialogType, uuid: detailsDialogUuid } = detailsDialogOptions;

        const activeDiagnoses = reviewDiagnoses
            ? reviewDiagnoses.filter((review: IReviewDiagnosis) => !review.removed)
            : [];
        const { parentUuid } = organisation;

        if (activeDiagnoses && activeDiagnoses.length) {
            return activeDiagnoses.map((diagnosis: IReviewDiagnosis) => {
                const { diagnosis: diagnosisClassification, copyOfUuid, organisationUuid, uuid } = diagnosis;

                const handleShowDialog = () => {
                    showDetailsDialog(diagnosis.uuid, ReviewEntities.DIAGNOSIS);
                };

                const showDialog = detailsDialogType === ReviewEntities.DIAGNOSIS && detailsDialogUuid === uuid;

                return (
                    <Table.Row key={uuid}>
                        <Table.Cell>
                            <SettingsPropertyWrapper
                                parentOrgUuid={parentUuid}
                                copyOfUuid={copyOfUuid}
                                propertyOrganisationUuid={organisationUuid}
                            >
                                {diagnosisClassification}
                            </SettingsPropertyWrapper>
                            <ReviewEntityDetailsDialog
                                diagnose={diagnosis}
                                onClose={closeDetailsDialog}
                                showDialog={showDialog}
                            />
                            <ContextIconButton iconName="magnify" onClick={handleShowDialog} content="details" />
                        </Table.Cell>
                        <Table.Cell>
                            {diagnosis.managementOptions.map(({ managementUuid }) => (
                                <Grid key={managementUuid} columns="2">
                                    {findManagementOutcome(managementUuid)}
                                </Grid>
                            ))}
                        </Table.Cell>
                    </Table.Row>
                );
            });
        }

        return (
            <Table.Row>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>
        );
    };

    return (
        <Table selectable columns={2}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Diagnosis</Table.HeaderCell>
                    <Table.HeaderCell>Management options</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>{getReviewDiagnosesTableRows()}</Table.Body>
        </Table>
    );
};

export default PreviewDiagnosisTable;
