import { Component, SyntheticEvent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Divider, DropdownProps, TextAreaProps } from "semantic-ui-react";

import LesionLocation from "components/LesionLocation";
import Questionary from "components/Questionary";
import AssessmentFlowConfirmModal from "components/templates/AssessmentFlowConfirmModal";
import AssessmentFlowHeader from "components/templates/AssessmentFlowHeader";
import LoadingSpinner from "components/templates/LoadingSpinner";
import PatientBanner from "components/templates/PatientBanner";

import { doesOrganisationHaveLocations, scrollToError } from "helpers/assessment";
import { scrollToTop } from "helpers/page";
import { generateAnswers, mapStateToAnswers, validateAnswersCommon } from "helpers/questionary";

import { IAssessment, IMedicalHistoryAnswers } from "model/assessment";
import { BodyLocations } from "model/bodyLocations";
import { ICase, ILesion, IPatient } from "model/case";
import QuestionSubTypeEnum from "model/questionSubType";

import {
    IHistoryAnswerType,
    IHistoryQuestionType,
    IMedicalHistoryQuestion,
    IOrganisation,
    OrganisationType,
} from "model/organisation";
import { IQuestionaryError } from "model/questionaryError";
import PresentingComplaint from "model/presentingComplaint";
import { CaptureImageType } from "model/captureImageType";

import { history } from "App";
import {
    CLINIC_LOCATION,
    IMAGE_CAPTURE,
    LESION_LOCATOR,
    MEDICAL_HISTORY,
    STUDY_IMAGE_CAPTURE,
    UPLOADED_IMAGES,
    READY_FOR_ASSESSMENT,
    SUN_EXPOSURE,
} from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";

import "scss/Table.scss";
import { wasCaseCreatedBeforeTheSkinQuestionWasAddedToTheOrganisation } from "helpers/fitzpatrick";

interface IMatchParams {
    lesionNumber: string;
}

interface ILesionLocator extends RouteComponentProps<IMatchParams> {
    assessment: IAssessment;
    case: ICase;
    currentLesion: number;
    organisation: IOrganisation;
    patient: IPatient;
    pendingRequest: boolean;
    getCaseForAssessment: (caseUuid: string) => void;
    setLesion: (lesion: ILesion) => void;
    createLesion: (
        caseUuid: string,
        patientUuid: string,
        bodyPart: BodyLocations,
        description: string,
        lesionHistoryAnswers: IMedicalHistoryAnswers[],
        lesionExclusions?: IMedicalHistoryAnswers[],
        presentingComplaint?: PresentingComplaint,
        complaintDescription?: string,
        callback?: () => void,
        errorCallback?: () => void
    ) => void;
    updateLesion: (
        lesionUuid: string,
        caseUuid: string,
        patientUuid: string,
        bodyPart: BodyLocations,
        description: string,
        lesionHistoryAnswers: IMedicalHistoryAnswers[],
        lesionExclusions?: IMedicalHistoryAnswers[],
        presentingComplaint?: PresentingComplaint,
        complaintDescription?: string
    ) => void;
}

interface ILesionLocatorState {
    locationNotes: string;
    location: BodyLocations | string;
    lesionAnswers: { [uuid: string]: any };
    errors: IQuestionaryError;
    [key: string]: any;
    isEditMode: boolean;
    wasPageNotRefreshed: boolean;
    showModal: boolean;
}
class LesionLocator extends Component<ILesionLocator, ILesionLocatorState> {
    constructor(props: ILesionLocator) {
        super(props);
        const { assessment, match } = this.props;
        const { currentLesion: currentLesionNumber, case: currentCase } = assessment;
        const { lesions } = { ...currentCase };
        const { params = null } = { ...match };

        const defaultLesionNumber = lesions?.length || currentLesionNumber + 1;

        const { lesionNumber = defaultLesionNumber } = { ...params };
        const currentLesionIndex = lesionNumber ? Number(lesionNumber) - 1 : currentLesionNumber;
        const lesion = lesions?.[currentLesionIndex];
        this.state = {
            errors: {
                formError: false,
                isInitial: true,
                list: [],
                showError: false,
            },
            isBack: Boolean(lesion),
            isEditMode: false,
            wasPageNotRefreshed: true, // this needs to be true to render a spinner and prevent temp render of page
            lesionAnswers: {},
            location: lesion?.bodyPart || "",
            locationNotes: lesion?.description || "",
            presentingComplaint: lesion && assessment.nonSkinCancer ? lesion.presentingComplaint : undefined,
            complaintDescription: lesion && assessment.nonSkinCancer ? lesion.complaintDescription : undefined,
            showModal: false,
        };
    }

    public componentDidMount() {
        scrollToTop();
        logoutTimeoutService.stopCount();

        window.addEventListener("beforeunload", this.handlePageRefresh);
        const wasPageNotRefreshed = sessionStorage.getItem("wasPageNotRefreshed");
        if (wasPageNotRefreshed) {
            setTimeout(() => {
                this.fetchLesion();
                sessionStorage.removeItem("wasPageNotRefreshed");
                this.setState({ wasPageNotRefreshed: false });
            }, 3000);
        } else {
            this.fetchLesion();
            sessionStorage.removeItem("wasPageNotRefreshed");
            this.setState({ wasPageNotRefreshed: false });
        }

        const { assessment } = this.props;
        if (assessment.case.lesions) {
            this.initiateLesionAnswers(assessment);
        }
    }

    public componentDidUpdate(prevProps: ILesionLocator, prevState: ILesionLocatorState) {
        const { match, assessment, organisation, setLesion } = this.props;
        const { wasPageNotRefreshed } = this.state;
        if (match.params.lesionNumber !== prevProps.match.params.lesionNumber) {
            this.initiateLesionAnswers(assessment);
            this.resetErrors();
        }
        if (
            JSON.stringify(prevProps.assessment.case.lesions) !== JSON.stringify(assessment.case.lesions) &&
            !wasPageNotRefreshed &&
            organisation.type !== "STUDY"
        ) {
            this.initiateLesionAnswers(assessment);
            this.resetErrors();

            if (assessment.case.lesions) {
                setLesion(assessment.case.lesions[assessment.case.lesions.length - 1]);
            }
        }

        const bodySelectError = document.getElementById("location-error-message");
        const { errors } = this.state;

        if ((prevState.errors !== errors && errors.list.length && errors.showError) || bodySelectError) {
            if (bodySelectError) {
                bodySelectError?.scrollIntoView({ behavior: "smooth" });
            } else {
                scrollToError({ errorUuid: errors.list[0] });
            }
        }
    }

    public componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handlePageRefresh);
        sessionStorage.removeItem("wasPageNotRefreshed");
    }

    public handlePageRefresh = () => {
        sessionStorage.setItem("wasPageNotRefreshed", "true");
    };

    public fetchLesion = () => {
        const { assessment, getCaseForAssessment, organisation } = this.props;
        if (organisation.type !== "STUDY") {
            getCaseForAssessment(assessment.case.uuid);
        }
    };

    public updateAnswers = (answers: any) => {
        this.setState({ lesionAnswers: answers, isEdited: true });
    };

    public updateErrors = (errors: IQuestionaryError) => {
        this.setState({ errors });
    };

    public changeLocation = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps | TextAreaProps) => {
        this.setState({ [data.id]: data.value, isEdited: true });
    };

    public submit = ({ mainData, extraData }: { mainData: any; extraData: any }) => {
        const { assessment, pendingRequest, match } = this.props;
        const { params = null } = { ...match };
        const { currentLesion: currentLesionNumber, case: currentCase } = assessment;
        const { lesions } = currentCase;
        const { lesionNumber = currentLesionNumber + 1 } = { ...params };
        const currentLesionIndex = Number(lesionNumber) - 1;
        const {
            location,
            locationNotes,
            isEditMode,
            isEdited,
            presentingComplaint,
            complaintDescription,
            errors: { formError },
        } = this.state;
        const isLesionExists = lesions?.[currentLesionIndex];

        if (!isLesionExists && !pendingRequest && !formError) {
            const {
                case: { uuid: caseUuid },
                patient: { uuid: patientUuid },
                createLesion,
            } = this.props;

            createLesion(
                caseUuid,
                patientUuid,
                location as BodyLocations,
                locationNotes,
                mainData,
                extraData,
                presentingComplaint,
                complaintDescription,
                this.moveForward,
                () => {
                    window.location.reload();
                }
            );
        }
        if (!isEditMode && isLesionExists) {
            this.moveForward();
        }
        if (isEditMode && isEdited) {
            this.setState({ showModal: true });
        }
    };

    public getBackButtonConfiguration = () => {
        const { pendingRequest, organisation, match, assessment } = this.props;
        const { isEditMode } = this.state;
        const { case: currentCase } = assessment;
        const { lesions } = currentCase;
        const { params = null } = { ...match };

        const lesionNumber = Number(params?.lesionNumber) || 0;
        const prevLesionNumber = lesionNumber - 1;
        const organisationHasLocations = doesOrganisationHaveLocations(organisation.locations);
        const isNewAdditionalLesion = lesionNumber > 0 && lesions.length < lesionNumber;

        const organisationHasFitzpatrickQuestion =
            organisation?.medicalHistoryQuestions
                ?.filter(
                    (question: IMedicalHistoryQuestion) => question?.questionSubType === QuestionSubTypeEnum.SKIN_TYPE
                )
                ?.filter((question: IMedicalHistoryQuestion) => !question?.removed).length > 0;

        const getBackUrl = (): string => {
            const isInflightCase = wasCaseCreatedBeforeTheSkinQuestionWasAddedToTheOrganisation({
                caseData: assessment.case,
                organisation,
            });

            let prevMedicalHistoryPage;
            if (organisationHasFitzpatrickQuestion && !isInflightCase) {
                prevMedicalHistoryPage = SUN_EXPOSURE;
            } else {
                prevMedicalHistoryPage = MEDICAL_HISTORY;
            }

            const prevPage = organisationHasLocations ? CLINIC_LOCATION : prevMedicalHistoryPage;

            if (isNewAdditionalLesion) {
                return READY_FOR_ASSESSMENT;
            }

            if (lesionNumber && prevLesionNumber) {
                return `${lesionNumber - 1}`;
            }

            return prevPage;
        };

        const getButtonText = (): string => {
            if (isNewAdditionalLesion) {
                return "Cancel";
            }
            return "< Back";
        };

        const getIsDisabled = (): boolean => Boolean(pendingRequest) || Boolean(isEditMode);

        return {
            isDisabled: getIsDisabled(),
            link: getBackUrl(),
            text: getButtonText(),
        };
    };

    private initiateLesionAnswers = (assessment: IAssessment) => {
        const { case: currentCase } = assessment;
        if (currentCase.lesions) {
            const { organisation, match } = this.props;
            const { params = null } = { ...match };
            const lesionNumber = Number(params?.lesionNumber) || 0;
            const currentLesion: ILesion =
                currentCase.lesions[lesionNumber ? Number(lesionNumber) - 1 : assessment.currentLesion];
            const {
                lesionHistory = null,
                exclusions = null,
                bodyPart,
                description,
                presentingComplaint,
                complaintDescription,
            } = {
                ...currentLesion,
            };
            const questionDefinition = currentCase.nonSkinCancer
                ? organisation.skinComplaintQuestions
                : organisation.lesionHistoryQuestions;
            const answers =
                lesionHistory && exclusions ? generateAnswers(lesionHistory, questionDefinition, exclusions) : {};
            this.setState({
                isEdited: false,
                lesionAnswers: answers,
                location: bodyPart || "",
                locationNotes: description || "",
                presentingComplaint,
                complaintDescription,
                showModal: false,
            });
        }
    };

    private editLesion = () => {
        const { case: propCase, patient, updateLesion, match, assessment } = this.props;
        const {
            isEditMode,
            location,
            locationNotes,
            showModal,
            isEdited,
            presentingComplaint,
            complaintDescription,
            lesionAnswers,
        } = this.state;
        if (isEditMode) {
            const { pendingRequest } = this.props;
            const isValid = this.validateAnswers(true);

            if (!pendingRequest && isValid) {
                const { params = null } = { ...match };
                const lesionNumber = Number(params?.lesionNumber) || 0;
                const currentLesionNumber = lesionNumber ? lesionNumber - 1 : assessment.currentLesion;
                const { case: currentCase } = assessment;
                const { mainData, extraData } = { ...mapStateToAnswers(lesionAnswers) };
                const currentLesion: ILesion | null = currentCase.lesions
                    ? currentCase.lesions[currentLesionNumber]
                    : null;

                if (isEdited) {
                    updateLesion(
                        currentLesion.uuid,
                        propCase.uuid,
                        patient.uuid,
                        location as BodyLocations,
                        locationNotes,
                        mainData as IMedicalHistoryAnswers[],
                        extraData,
                        presentingComplaint,
                        complaintDescription
                    );
                }

                this.setState({ isEditMode: false, isEdited: false });

                if (showModal) {
                    this.moveForward();
                }
            }
        } else {
            this.setState({ isEditMode: true });
        }
    };

    private validateAnswers = (showError?: boolean) => {
        const { organisation, assessment } = this.props;
        const { lesionAnswers, errors } = this.state;
        const { lesionHistoryQuestions, lesionExclusions, skinComplaintQuestions } = organisation;
        const isNonSkinCancerFlow = assessment.nonSkinCancer;
        const questionSet = isNonSkinCancerFlow ? skinComplaintQuestions : lesionHistoryQuestions;
        const questions = questionSet.filter((question) => !question.removed);
        const questionsSets: IMedicalHistoryQuestion[] | any = [{ questions }];
        const getExclusions = () => lesionExclusions.filter((exclusion: any) => !exclusion.removed);
        const exclusions = lesionExclusions ? getExclusions() : [];
        const isExclusionQuestionVisible = exclusions.length > 0;
        if (isExclusionQuestionVisible && !isNonSkinCancerFlow) {
            const exclusionQuestionSet = {
                questions: [
                    {
                        answerType: IHistoryAnswerType.CHECKBOX,
                        options: exclusions,
                        question: "Do any of these exclusion criteria apply?",
                        questionType: IHistoryQuestionType.EXCLUSION,
                        removed: false,
                        required: true,
                        uuid: exclusions[0].uuid,
                    },
                ],
            };
            questionsSets.push(exclusionQuestionSet);
        }

        return validateAnswersCommon(lesionAnswers, questionsSets, errors, this.updateErrors, showError);
    };

    private closeModal = () => {
        this.setState({ showModal: false });
    };

    private moveForward = () => {
        const { assessment, match, organisation } = this.props;
        const { params = null } = { ...match };
        const { case: currentCase, currentLesion: currentLesionNumber } = assessment;
        const { lesions } = { ...currentCase };
        const defaultLesionNumber = lesions?.length || currentLesionNumber + 1;
        const { lesionNumber = defaultLesionNumber } = { ...params };
        const totalLesionsLength = lesions?.length;

        const isStudy = organisation.type === OrganisationType.STUDY;
        const lesionNumberNmbr = Number(lesionNumber);
        const lesionLocatorLink = `${LESION_LOCATOR}/${lesionNumberNmbr + 1}`;
        const currentLesionImages = lesions?.[lesionNumberNmbr - 1]?.images;
        const imageCaptureLink = isStudy ? STUDY_IMAGE_CAPTURE : IMAGE_CAPTURE;
        const hasCapturedImagesForCurrentLesion = Boolean(
            currentLesionImages?.find((image) => image.type === CaptureImageType.DERMOSCOPIC)
        );
        const followingPageLink = hasCapturedImagesForCurrentLesion && !isStudy ? UPLOADED_IMAGES : imageCaptureLink;
        const nextLink =
            (lesionNumber && totalLesionsLength > lesionNumber) || lesionNumber <= currentLesionNumber
                ? lesionLocatorLink
                : followingPageLink;
        history.push(nextLink);
    };

    private resetEdit = () => {
        const { assessment } = this.props;
        this.initiateLesionAnswers(assessment);
    };

    private resetErrors = () => {
        const { assessment, match } = this.props;
        const { currentLesion: currentLesionNumber, case: currentCase } = assessment;
        const { params = null } = { ...match };
        const { lesions } = { ...currentCase };
        const defaultLesionNumber = lesions?.length || currentLesionNumber + 1;
        const { lesionNumber = defaultLesionNumber } = { ...params };
        const currentLesionIndex = lesionNumber ? Number(lesionNumber) - 1 : currentLesionNumber;
        const lesion = lesions?.[currentLesionIndex];

        this.setState({
            isBack: Boolean(lesion),
            errors: {
                formError: false,
                isInitial: true,
                list: [],
                showError: false,
            },
        });
    };

    public render() {
        const { assessment, match, organisation, pendingRequest } = this.props;
        const { case: currentCase, currentLesion: currentLesionNumber } = assessment;
        const { lesions } = { ...currentCase };

        const { params = null } = { ...match };

        const defaultLesionNumber = currentCase.lesions?.length || currentLesionNumber + 1;

        const { lesionNumber = defaultLesionNumber } = { ...params };
        const { wasPageNotRefreshed } = this.state;

        if (!currentCase) {
            return <LoadingSpinner />;
        }

        if (wasPageNotRefreshed) {
            return <LoadingSpinner />;
        }

        const isNonSkinCancerFlow = assessment.nonSkinCancer;
        const { lesionExclusions, lesionHistoryQuestions, skinComplaintQuestions } = organisation;
        const assessmentLesionQuestions = isNonSkinCancerFlow ? skinComplaintQuestions : lesionHistoryQuestions;
        const lesionQuestions = assessmentLesionQuestions
            .filter((question) => !question.removed)
            .sort((a, b) => a.questionOrder - b.questionOrder);
        const getExclusions = () => lesionExclusions.filter((exclusion: any) => !exclusion.removed);
        const exclusions = lesionExclusions ? getExclusions() : [];
        const isExclusionQuestionVisible = exclusions.length > 0;
        const backButtonConfiguration = this.getBackButtonConfiguration();
        const questionsSets: IMedicalHistoryQuestion[] | any = [{ questions: lesionQuestions }];
        if (isExclusionQuestionVisible && !isNonSkinCancerFlow) {
            const exclusionQuestionSet = {
                questions: [
                    {
                        answerType: IHistoryAnswerType.CHECKBOX,
                        options: exclusions,
                        question: "Do any of these exclusion criteria apply?",
                        questionType: IHistoryQuestionType.EXCLUSION,
                        removed: false,
                        required: true,
                        uuid: exclusions[0].uuid,
                    },
                ],
            };
            questionsSets.push(exclusionQuestionSet);
        }
        const currentLesion: ILesion | null =
            lesions?.[lesionNumber ? Number(lesionNumber) - 1 : assessment.currentLesion];
        const { lesionHistory = null, exclusions: currentExclusions = null } = { ...currentLesion };
        const {
            isEditMode,
            location,
            locationNotes,
            errors,
            isBack,
            lesionAnswers,
            showModal,
            presentingComplaint,
            complaintDescription,
        } = this.state;
        const lesionDetailsTitle = `Lesion ${lesionNumber} Details`;
        const title = assessment.nonSkinCancer ? "Skin Complaint History" : lesionDetailsTitle;

        return (
            <Container className="ui segment wizzard-container">
                <PatientBanner caseId={assessment.case.caseId} patient={assessment.patient} />
                <AssessmentFlowHeader
                    title={title}
                    isEditMode={isEditMode}
                    disabled={!lesionHistory && !currentExclusions}
                    edit={this.editLesion}
                    reset={this.resetEdit}
                />
                <Divider />
                <LesionLocation
                    onChange={this.changeLocation}
                    values={{
                        location,
                        locationNotes,
                        presentingComplaint,
                        complaintDescription,
                    }}
                    errors={errors}
                    updateErrors={this.updateErrors}
                    disabled={isBack && !isEditMode}
                    isNonSkinCancerFlow={isNonSkinCancerFlow}
                />
                <Questionary
                    questionSets={questionsSets}
                    errors={errors}
                    updateAnswers={this.updateAnswers}
                    answers={lesionAnswers}
                    updateErrors={this.updateErrors}
                    backButton={backButtonConfiguration}
                    isBack={isBack && !isEditMode}
                    nextButtons={[
                        {
                            action: this.submit,
                            isLoading: pendingRequest,
                            isDisabled: pendingRequest,
                            text: "Next >",
                            type: "submit",
                        },
                    ]}
                />
                <AssessmentFlowConfirmModal
                    show={showModal}
                    close={this.closeModal}
                    goAhead={this.moveForward}
                    save={this.editLesion}
                />
            </Container>
        );
    }
}

export default withRouter(LesionLocator);
