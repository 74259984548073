import { ChangeEvent, FC, ReactNode, useState } from "react";
import { Container, Form, Header, InputOnChangeData, Message } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import SupportEmailLink from "components/templates/SupportEmailLink";
import PasswordMatching from "components/PasswordMatching";
import AccountCreatedModal from "components/RemoteModel/modals/AccountCreatedModal";

import { CONFIRMED_PASSWORD_FIELD_NAME, IFormState, PASSWORD_FIELD_NAME } from "hooks/useSetPassword";

import { history } from "App";
import { UserRole } from "model/userRole";
import { REMOTE_MODEL_CONSENT } from "navigation/remoteModelRoutes";
import { blacklistErrorMessage } from "helpers/password";

interface ISetPassword {
    form: IFormState;
    setFormValue: (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
    setPassword: () => Promise<boolean>;
    renderErrors: () => ReactNode;
    isButtonDisabled: boolean;
    setIsButtonDisabled: (state: boolean) => void;
    userRoleFromToken: UserRole;
    passwordIsOnDenyList: () => boolean;
}

const SetPassword: FC<ISetPassword> = ({
    form,
    setFormValue,
    setPassword,
    renderErrors,
    isButtonDisabled,
    setIsButtonDisabled,
    userRoleFromToken,
    passwordIsOnDenyList,
}) => {
    const [visibleAccountCreatedModal, setVisibleAccountCreatedModal] = useState<boolean>(false);

    const changePassword = async () => {
        const result = await setPassword();
        if (result) {
            setVisibleAccountCreatedModal(!visibleAccountCreatedModal);
        }
    };

    const goAhead = () => history.push(REMOTE_MODEL_CONSENT);

    const passwordFormValue = form[PASSWORD_FIELD_NAME];
    const confirmedPasswordFormValue = form[CONFIRMED_PASSWORD_FIELD_NAME];

    return (
        <>
            <Container className="custom__container">
                <Form className="password__wrapper">
                    <Header as="h2" dividing>
                        Create a password for your account
                    </Header>
                    {passwordIsOnDenyList() ? <Message size="large" content={blacklistErrorMessage} negative /> : null}
                    <Form.Input
                        label="Password"
                        className="is-required login"
                        type="password"
                        name={PASSWORD_FIELD_NAME}
                        onChange={setFormValue}
                        data-testid="set-password-field"
                        value={passwordFormValue}
                    />
                    <Form.Input
                        label="Confirm Password"
                        className="is-required login"
                        type="password"
                        name={CONFIRMED_PASSWORD_FIELD_NAME}
                        onChange={setFormValue}
                        data-testid="set-password-confirm-field"
                        value={confirmedPasswordFormValue}
                    />
                    <div className="password-matching">
                        <PasswordMatching
                            title="To make your password strong, it must:"
                            newPassword={passwordFormValue}
                            confirmNewPassword={confirmedPasswordFormValue}
                            makeButtonDisabled={setIsButtonDisabled}
                            userRole={userRoleFromToken}
                        />
                    </div>
                    <p>
                        <i>
                            If you have any problems creating an account please contact <SupportEmailLink />.
                        </i>
                    </p>
                    <div className="buttons-wrapper">
                        <CustomButton
                            type="submit"
                            variant="filled"
                            action={changePassword}
                            text="Continue"
                            disabled={isButtonDisabled}
                            dataTestId="set-password-submit-button"
                            ariaLabel="Submit new password"
                        />
                        {renderErrors()}
                    </div>
                </Form>
            </Container>
            <AccountCreatedModal show={visibleAccountCreatedModal} onContinue={goAhead} closeOnOverlay={false} />
        </>
    );
};

export default SetPassword;
