import React, { FC, useContext } from "react";
import { Loader } from "semantic-ui-react";

import CaseListHeader from "components/CaseList/CaseListHeader";
import CaseListTable from "components/CaseList/CaseListTable";

import { CaseListContext } from "contextProviders/CaseListProvider";

import "scss/CaseList.scss";

const CaseList: FC = () => {
    const { currentTabCases } = useContext(CaseListContext);

    return (
        <>
            <CaseListHeader />
            {currentTabCases?.data ? <CaseListTable /> : <Loader />}
        </>
    );
};

export default CaseList;
