import React from "react";
import { useForm, Controller } from "react-hook-form";

import FormElement from "components/templates/FormElement";
import PatientLookupFormControl from "components/PatientLookup/PatientLookupFormControl";

import { IDefinedPersonalData } from "model/organisation";
import { IPatientLookupMatched } from "model/patientLookup";

interface IAdditionalDetails {
    additionalDataFields: IDefinedPersonalData[];
    patientDetails: IPatientLookupMatched;
    savePatientDetails: (patientDetails: IPatientLookupMatched) => void;
    back: (additionalPatientDetails) => void;
}

function PatientAdditionalDetails({
    additionalDataFields,
    patientDetails,
    savePatientDetails,
    back,
}: IAdditionalDetails) {
    const additionalPatientDetails = {};
    additionalDataFields.forEach((field: IDefinedPersonalData) => {
        additionalPatientDetails[field.propertyName] = "";
    });

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm<any>({ defaultValues: { ...additionalPatientDetails, ...patientDetails } });

    const formFields = watch();

    return (
        <div className="patient-additional-details">
            <h3>A few more patient details</h3>
            <p>Complete the following details in order to proceed with the creation of the case</p>
            <form onSubmit={handleSubmit(savePatientDetails)}>
                {additionalDataFields.map((field: IDefinedPersonalData) => {
                    const { required, validationProperty, uuid } = field;

                    return (
                        <div key={uuid} className="patient-additional-details">
                            <Controller
                                control={control}
                                name={field.propertyName}
                                {...register(field.propertyName, {
                                    required: { value: required, message: `This field is required` },
                                })}
                                render={({ field: { onChange, value } }) => (
                                    <FormElement
                                        formErrors={errors}
                                        required={required}
                                        data={field}
                                        onChange={onChange}
                                        value={value}
                                        validationProperty={validationProperty}
                                        className={`field patient ${errors[field.propertyName] ? "input-error" : ""} `}
                                    />
                                )}
                            />
                            {errors[field.propertyName] && (
                                <p className="errors">{errors[field.propertyName].message}</p>
                            )}
                        </div>
                    );
                })}
                <PatientLookupFormControl
                    button1Name="Back"
                    button1OnClick={() => back(formFields)}
                    button2Name="Continue"
                    button2Type="submit"
                />
            </form>
        </div>
    );
}

export default PatientAdditionalDetails;
