import { FC } from "react";
import { List } from "semantic-ui-react";

interface IPasswordMatchingItem {
    text: string;
    isMatched: boolean;
}

const PasswordMatchingItem: FC<IPasswordMatchingItem> = ({ text, isMatched }) => (
    <List.Item>
        <List.Icon
            name={isMatched ? "checkmark" : "close"}
            color={isMatched ? "green" : "red"}
            className="password-matching-icon"
        />
        <List.Content>{text}</List.Content>
    </List.Item>
);

export default PasswordMatchingItem;
