import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import HttpException from "exceptions/httpException";

import store from "redux/store";
import * as actions from "redux/actions";

export class HttpConnector {
    public static async sendPost(
        url: string,
        body: Record<string, unknown>,
        headers?: Record<string, unknown>
    ): Promise<AxiosResponse<any>> {
        try {
            store.store.dispatch(actions.dataActions.setPendingRequest(true));
            const response: AxiosResponse<any> = await axios.post(url, body, headers);
            HttpConnector.verifyResponse(response);
            return response;
        } finally {
            store.store.dispatch(actions.dataActions.setPendingRequest(false));
        }
    }

    public static async sendGet(
        url: string,
        headers?: Record<string, unknown>,
        arrayBuffer?: boolean
    ): Promise<AxiosResponse<any>> {
        try {
            store.store.dispatch(actions.dataActions.setPendingRequest(true));
            const response: AxiosResponse<any> = await axios.get(url, this.getRequestConfig(headers, arrayBuffer));
            HttpConnector.verifyResponse(response);
            return response;
        } finally {
            store.store.dispatch(actions.dataActions.setPendingRequest(false));
        }
    }

    public static async sendPut(url: string, body: Record<string, unknown>, headers?: Record<string, unknown>) {
        try {
            store.store.dispatch(actions.dataActions.setPendingRequest(true));
            const response: AxiosResponse<any> = await axios.put(url, body, headers);
            HttpConnector.verifyResponse(response);
            return response;
        } finally {
            store.store.dispatch(actions.dataActions.setPendingRequest(false));
        }
    }

    public static async sendDelete(url: string, headers?: Record<string, unknown>) {
        try {
            store.store.dispatch(actions.dataActions.setPendingRequest(true));
            const response: AxiosResponse<any> = await axios.delete(url, headers);
            HttpConnector.verifyResponse(response);
            return response;
        } finally {
            store.store.dispatch(actions.dataActions.setPendingRequest(false));
        }
    }

    private static verifyResponse(response: any) {
        if (!(response.status === 200 || response.status === 201)) {
            throw new HttpException(response.status, response.data);
        }
    }

    private static getRequestConfig(headers: Record<string, unknown>, arrayBuffer?: boolean): AxiosRequestConfig {
        return {
            ...headers,
            ...(arrayBuffer ? { responseType: "arraybuffer" } : {}),
        };
    }
}

export default new HttpConnector();
