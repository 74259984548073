import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import RemoteModelRegistrationScreen from "components/RemoteModel/RegistrationScreen";
import RemoteModelConsentScreen from "components/RemoteModel/ConsentScreen";
import RemoteModelStartCaseCreation from "components/RemoteModel/StartCaseCreation";
import RemoteModelMedicalHistory from "components/RemoteModel/MedicalHistory";
import FitzpatrickQuestion from "components/RemoteModel/FitzpatrickQuestion";
import RemoteModelKitDelivery from "components/RemoteModel/KitDelivery";
import RemoteModelLesionDetails from "components/RemoteModel/LesionDetails";
import RemoteModelLesionImages from "components/RemoteModel/LesionImages";
import RemoteModelCaseInReview from "components/RemoteModel/CaseInReview";
import RemoteModelCaseSummary from "components/RemoteModel/CaseSummary";
import RemoteModelReportAvailable from "components/RemoteModel/ReportAvailable";

import ReportAvailableProvider from "contextProviders/ReportAvailableProvider";

import useAutoLogout from "hooks/useAutoLogout";

import * as REMOTE_MODEL_ROUTES from "navigation/remoteModelRoutes";
import * as ROUTES from "navigation/routes";

const PatientRoutes: FC = () => {
    useAutoLogout();

    return (
        <>
            <Switch>
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CONSENT} component={RemoteModelConsentScreen} />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_REGISTRATION} component={RemoteModelRegistrationScreen} />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_START} component={RemoteModelStartCaseCreation} />
                <Route
                    path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_MEDICAL_HISTORY}
                    component={RemoteModelMedicalHistory}
                />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_SUN_EXPOSURE} component={FitzpatrickQuestion} />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_KIT_DELIVERY} component={RemoteModelKitDelivery} />
                <Route
                    path={`${REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_LESION_DETAILS}/:lesionIndex?`}
                    component={RemoteModelLesionDetails}
                />
                <Route
                    path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_LESION_IMAGES}
                    component={RemoteModelLesionImages}
                />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_IN_REVIEW} component={RemoteModelCaseInReview} />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_SUMMARY} component={RemoteModelCaseSummary} />
                <Route path={REMOTE_MODEL_ROUTES.REMOTE_MODEL_CASES_REPORT}>
                    <ReportAvailableProvider>
                        <RemoteModelReportAvailable />
                    </ReportAvailableProvider>
                </Route>
                <Redirect to={ROUTES.HOME} />
            </Switch>
        </>
    );
};

export default PatientRoutes;
