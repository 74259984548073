enum PresentingComplaint {
    ACNE = "Acne",
    FLARE_ECZEMA_PSORIASIS = "Flare of known eczema/psoriasis",
    HAIR_NAIL_PROBLEMS = "Hair/Nail problems",
    RASH = "Rash - diagnosis unknown",
    ROSACEA = "Rosacea",
    SEBORRHOEIC = "Seborrhoeic dermatitis",
    OTHER = "Other - details added in description",
}

export default PresentingComplaint;
