import React, { FC } from "react";
import { Image, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

import CustomButton from "components/templates/Button";
import ButtonCloseModal from "components/RemoteModel/modals/ButtonCloseModal";

import withModal from "HOC/withModal";

import { ModalIconAltTexts } from "model/modal";

interface IBlockUnconsentedCaseModal {
    onClose: () => void;
}

const BlockUnconsentedCaseModal: FC<IBlockUnconsentedCaseModal> = ({ onClose }) => (
    <div className="remote-model__modal">
        <ButtonCloseModal onClick={onClose} />
        <Image alt={ModalIconAltTexts.DOCUMENT} src="/images/icons/icon-document.svg" />
        <Header as="h2">Consent for automated decision making</Header>

        <p className="p">
            Consent for automated decision making is required in order to proceed with this service. For more
            information see the patient information leaflet or the Skin Analytics{" "}
            <Link target="_blank" to={{ pathname: "https://skin-analytics.com/privacy/" }}>
                privacy policy
            </Link>
        </p>
        <div className="buttons-wrapper">
            <CustomButton type="button" variant="filled" action={onClose} text="OK" />
        </div>
    </div>
);

export default withModal(BlockUnconsentedCaseModal);
