import React, { FC, useState } from "react";
import { Grid, Header } from "semantic-ui-react";

import EditPatientInfoDialog from "components/templates/dialog/EditPatientInfoDialog";
import CustomButton from "components/templates/Button";
import PatientDetail from "components/CaseDescription/Review/PatientDetail";

import { isUndefined } from "helpers/common";
import {
    ADDRESS_LINE_1_INPUT_TEXT,
    ADDRESS_LINE_2_INPUT_TEXT,
    ADDRESS_LINE_3_INPUT_TEXT,
    CITY_INPUT_TEXT,
    DOB_INPUT_TEXT,
    GENDER_INPUT_TEXT,
    PHONE_INPUT_TEXT,
    POSTCODE_INPUT_TEXT,
    getNameSurname,
} from "helpers/definedPersonalData";
import { getPatientFields } from "helpers/patientUtils";
import { formatTimeDOB } from "helpers/datetime";

import { ICase } from "model/case";
import { CaseViewMode } from "model/caseViewMode";
import { IOrganisation } from "model/organisation";
import { RegistrationPersonalFields } from "model/registration";

import "scss/PatientDetails.scss";

interface IPatientDetails {
    readonly caseObject: ICase;
    readonly mode: CaseViewMode;
    readonly patientOrganisation?: IOrganisation;
}

const PatientDetails: FC<IPatientDetails> = ({ caseObject, mode, patientOrganisation }) => {
    const [showEditPatientDialog, setShowEditPatientDialog] = useState<boolean>(false);
    const { createdByEmail, patient, remote } = caseObject;

    if (!remote) {
        return null;
    }

    const onEditPatientClick = (show: boolean) => {
        setShowEditPatientDialog(show);
    };

    const getOrganisationInsuranceLabel = (personalDataProperty: RegistrationPersonalFields): string =>
        patientOrganisation?.definedPersonalData?.find(
            (personalDataSetting) => personalDataSetting.propertyName === personalDataProperty
        )?.displayName || "";

    const getEditPatientButton = () => {
        const permittedRoles: CaseViewMode[] = [CaseViewMode.SUPERADMINVIEW, CaseViewMode.ADMINVIEW];
        const hasViewPermission: boolean = permittedRoles.includes(mode);

        return (
            hasViewPermission && (
                <CustomButton
                    type="submit"
                    variant="empty"
                    text="Edit patient info"
                    action={onEditPatientClick}
                    floated="right"
                />
            )
        );
    };

    const getEditPatientDialog = () =>
        showEditPatientDialog && (
            <EditPatientInfoDialog
                showDialog={showEditPatientDialog}
                callback={setShowEditPatientDialog}
                caseObject={caseObject}
            />
        );

    const { patientData } = { ...patient };
    const [
        phoneNumber,
        addressLine1,
        addressLine2,
        addressLine3,
        postCode,
        city,
        birth,
        gender,
        memberNumber,
        preauthorisationNumber,
    ] = getPatientFields(patient, [
        PHONE_INPUT_TEXT,
        ADDRESS_LINE_1_INPUT_TEXT,
        ADDRESS_LINE_2_INPUT_TEXT,
        ADDRESS_LINE_3_INPUT_TEXT,
        POSTCODE_INPUT_TEXT,
        CITY_INPUT_TEXT,
        DOB_INPUT_TEXT,
        GENDER_INPUT_TEXT,
        RegistrationPersonalFields.MEMBER_NUMBER,
        RegistrationPersonalFields.PREAUTHORISATION_NUMBER,
    ]);

    const preauthorisationNumberLabel = getOrganisationInsuranceLabel(
        RegistrationPersonalFields.PREAUTHORISATION_NUMBER
    );
    const memberNumberLabel = getOrganisationInsuranceLabel(RegistrationPersonalFields.MEMBER_NUMBER);
    const patientName = patientData ? getNameSurname(patientData, remote) : "";
    const patientDob = birth ? formatTimeDOB(birth as string) : "";
    const address = [postCode, addressLine1, addressLine2, addressLine3, city].filter(
        (addressField) => !isUndefined(addressField) && addressField !== ""
    );

    const multilineAddress = address.reduce((prev, acc) => {
        if (prev === "") {
            return acc;
        }
        return `${prev},${acc}`;
    }, "");

    const copyOverride = [postCode, addressLine1].reduce((prev, acc) => {
        if (prev === "") {
            return acc;
        }
        return `${prev}\r\n${acc}`;
    }, "");

    return (
        <>
            {getEditPatientDialog()}
            <div className="section-separate">
                <div className="patient-details">
                    <div className="patient-details__header">
                        <Header as="h3">Patient Details</Header>
                        {getEditPatientButton()}
                    </div>
                    <Grid className="patient-details__grid">
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={7} computer={7}>
                                <PatientDetail title="Name" personalData={patientName} showCopy />
                                <PatientDetail title="Phone number" personalData={phoneNumber} showCopy />
                                <PatientDetail title="Email address" personalData={createdByEmail} showCopy />
                                <PatientDetail
                                    title="Address"
                                    personalData={multilineAddress}
                                    copyOverride={copyOverride}
                                    showCopy
                                    multiline
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={1} computer={1} />
                            <Grid.Column mobile={16} tablet={7} computer={7}>
                                <PatientDetail title="Gender" personalData={gender} />
                                <PatientDetail title="Date of birth" personalData={patientDob} />

                                <Header as="h4">Insurer info</Header>

                                <PatientDetail
                                    title={preauthorisationNumberLabel}
                                    personalData={preauthorisationNumber}
                                    showCopy
                                />
                                <PatientDetail title={memberNumberLabel} personalData={memberNumber} showCopy />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        </>
    );
};

PatientDetails.defaultProps = {
    patientOrganisation: null,
};

export default PatientDetails;
