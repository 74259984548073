import React, { Component } from "react";
import { Header } from "semantic-ui-react";

import Question from "components/CaseDescription/Questions/Question";

import { matchAndSortQuestionsAndDefinition } from "helpers/questionDefinition";

import { IMedicalHistory } from "model/assessment";
import { IHistoryAnswerType, IMedicalHistoryQuestion } from "model/organisation";
import QuestionSubTypeEnum from "model/questionSubType";

interface ISignificantQuestionsProps {
    questions: IMedicalHistory[];
    questionsDefinition: IMedicalHistoryQuestion[];
}

class SignificantQuestions extends Component<ISignificantQuestionsProps, any> {
    private checkIfResposeIsSignificant(question: IMedicalHistory) {
        const { questionsDefinition } = this.props;
        const questionDefinition = questionsDefinition?.filter((definition) => definition.uuid === question.uuid)[0];

        const options = questionDefinition?.options;

        if (question.additionalInformation && question.additionalInformation !== "") {
            return true;
        }

        if (questionDefinition && questionDefinition.answerType === IHistoryAnswerType.TEXT) {
            return question.answers.find((answer) => answer !== "");
        }

        const significantOptions = options?.filter((option) => option.significant).map((option) => option.option);

        if (significantOptions) {
            let isSignificant = false;

            question.answers?.forEach((answer) => {
                if (significantOptions.includes(answer)) {
                    isSignificant = true;
                }
            });
            return isSignificant;
        }

        return false;
    }

    public render() {
        const { questions, questionsDefinition } = this.props;
        const isQuestionDefinition = questionsDefinition?.length > 0;
        const significantQuestions = questions.filter((question) => this.checkIfResposeIsSignificant(question));

        const questionsWithDefinition = matchAndSortQuestionsAndDefinition(
            significantQuestions,
            questionsDefinition
        ).filter((qd) => qd.questionDefinition.questionSubType !== QuestionSubTypeEnum.SKIN_TYPE);
        return (
            (isQuestionDefinition && (
                <>
                    <Header as="h5" className="without-bottom-margin">
                        Significant Answers
                    </Header>

                    {questionsWithDefinition.length
                        ? questionsWithDefinition.map((questionWithDefinition) => (
                              <Question
                                  question={questionWithDefinition.question}
                                  key={questionWithDefinition.question.uuid}
                                  questionDefinition={questionWithDefinition.questionDefinition}
                                  showOnlyAnswer
                              />
                          ))
                        : "No significant answers"}
                </>
            )) || <></>
        );
    }
}

export default SignificantQuestions;
