import { IMedicalHistory } from "model/assessment";
import CaseQuestionDefinitionType from "model/caseQuestionDefinitionType";
import { IMedicalHistoryQuestion } from "model/organisation";
import { UserRole } from "model/userRole";

import store from "redux/store";

import organizationService from "services/organizationService";

export const getCaseQuestionDefinition = async (
    questionType: CaseQuestionDefinitionType,
    caseOrganisationUuid: string
) => {
    const { organisation, user } = store.store.getState().data;

    if ([UserRole.ADMIN, UserRole.CLINICIAN, UserRole.PATIENT].includes(user.role)) {
        switch (questionType) {
            case CaseQuestionDefinitionType.BIOPSY: {
                return organisation.biopsyQuestions;
            }
            case CaseQuestionDefinitionType.LESION: {
                return organisation.lesionHistoryQuestions;
            }
            case CaseQuestionDefinitionType.MEDICAL: {
                return organisation.medicalHistoryQuestions;
            }
            default: {
                return undefined;
            }
        }
    } else {
        try {
            return await organizationService.getOrganisationQuestionsByUuid(caseOrganisationUuid, questionType);
        } catch (err) {
            console.error(err);
            return err;
        }
    }
};

export const sortQuestionDefinitionByOrder = (questionsDefinition: IMedicalHistoryQuestion[]) =>
    questionsDefinition.sort((a, b) => a.questionOrder - b.questionOrder);

export const matchAndSortQuestionsAndDefinition = (
    questions: IMedicalHistory[] = [],
    questionsDefinition: IMedicalHistoryQuestion[]
) => {
    const matchedQuestionsWithDefinition = questions
        .filter((question) => !question.removed)
        .map((question) => ({
            question,
            questionDefinition: questionsDefinition.find(
                (questionDefinition) => questionDefinition.uuid === question.uuid
            ),
        }));
    const questionsWithDefinition = matchedQuestionsWithDefinition.filter((question) => question.questionDefinition);

    return questionsWithDefinition.sort(
        (a, b) => a.questionDefinition.questionOrder - b.questionDefinition.questionOrder
    );
};
