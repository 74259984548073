import { FC } from "react";
import { Grid } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";

import "scss/Dialog.scss";

interface IDisableAuditConfiguration {
    showModal: boolean;

    handleClose: () => void;
    handleContinue: (_event: unknown, value: unknown) => void;
}

const DisableAuditConfigurationModal: FC<IDisableAuditConfiguration> = ({ showModal, handleClose, handleContinue }) => {
    if (!showModal) {
        return null;
    }

    const handleContinueFunc = (): void => {
        const continuePromise = new Promise((resolve) => resolve(handleContinue(null, { checked: false })));
        continuePromise.then(() => handleClose());
    };

    return (
        <ModalDialog
            className="warning-modal"
            title="Are you sure you want to disable the “Automated decision making”?"
            open={showModal}
            iconSrc="/images/icons/icon-warning-yellow-bg.svg"
            onClose={handleClose}
            maxWidth="md"
            disableBackdropClick
            buttons={[
                {
                    onClick: handleContinueFunc,
                    text: "Yes, disable",
                    colour: "primary",
                },
                {
                    onClick: handleClose,
                    text: "Cancel",
                    colour: "secondary",
                },
            ]}
        >
            <Grid className="grid-dialog-body">
                <Grid.Row>
                    <Grid.Column>
                        This organisation has one or more criteria for auditing active. Disabling the &quot;Automated
                        decision making&quot; will deactivate the auditing feature.
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </ModalDialog>
    );
};

export default DisableAuditConfigurationModal;
