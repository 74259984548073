import { AtHomeFlowActionTypes, AtHomeFlowAction } from "contextProviders/modules/atHomeFlow/actions";

import { AtHomeFlowState } from "model/atHomeFlow";

export default (state: AtHomeFlowState, action: AtHomeFlowAction) => {
    switch (action.type) {
        case AtHomeFlowActionTypes.SET_CONSENT: {
            const { consent, secondaryConsent, automatedDecisionConsent } = action.payload;

            return { ...state, consent, secondaryConsent, automatedDecisionConsent };
        }
        case AtHomeFlowActionTypes.SET_PERSONAL_DETAILS: {
            return { ...state, personalDetails: action.payload };
        }
        case AtHomeFlowActionTypes.SET_MEDICAL_QUESTIONS: {
            return { ...state, medicalHistory: action.payload };
        }
        case AtHomeFlowActionTypes.SET_FITZPATRICK_QUESTION: {
            return { ...state, medicalHistory: [...state?.medicalHistory, ...action.payload] };
        }
        default:
            return state;
    }
};
