/* eslint-disable react/no-array-index-key */
import { FC } from "react";
import { Table } from "semantic-ui-react";

interface IEmptyRow {
    cellNumber: number;
}

const EmptyRow: FC<IEmptyRow> = ({ cellNumber }) => {
    const array = [...Array(cellNumber)];

    return (
        <Table.Row>
            {array.map((item, index) => (
                <Table.Cell key={index}> - </Table.Cell>
            ))}
        </Table.Row>
    );
};

export default EmptyRow;
