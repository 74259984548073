import React, { ComponentType, useContext, useEffect } from "react";

import { ModalContext } from "contextProviders/ModalProvider";

import { WithModalParameters } from "model/modal";

interface IModal extends Pick<WithModalParameters, "size"> {
    show: boolean;
    closeCallback?: () => void;
    className?: string;
    closeOnOverlay?: boolean;
}

const withModal =
    <P extends unknown>(WrappedComponent: ComponentType<P>): ComponentType<P & IModal> =>
    (props: P & IModal): JSX.Element | null => {
        const { show, size = "tiny", closeCallback, className, closeOnOverlay } = props;
        const { setModalParameters, closeModal } = useContext(ModalContext);

        useEffect(() => () => closeModal(), []);

        useEffect(() => {
            setModalParameters({
                closeCallback: closeCallback || closeModal,
                content: <WrappedComponent {...props} />,
                isVisible: show,
                size,
                className,
                closeOnOverlay,
            });
        }, [show]);

        return null;
    };

export default withModal;
