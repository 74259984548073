import React, { FC } from "react";
import { Modal } from "semantic-ui-react";
import QRCode from "qrcode.react";

import "scss/QrCodeDialog.scss";

const QRCodePopup: FC<any> = ({ value }) => (
    <Modal
        trigger={<QRCode aria-details="QR Code" value={value} className="qr-code" level="L" size={300} />}
        className="qr-code-dialog"
        centered={false}
        basic
        closeIcon
    >
        <Modal.Header className="header">Please scan the QR Code</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <QRCode aria-details="QR Code" value={value} className="qr-code" level="L" size={300} />
            </Modal.Description>
        </Modal.Content>
    </Modal>
);

export default QRCodePopup;
