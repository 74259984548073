import { FC } from "react";
import { Table } from "semantic-ui-react";
import { useSelector } from "react-redux";

import PatientBannerTableCell from "components/templates/PatientBanner/PatientBannerTableCell";
import PatientBannerHeader from "components/templates/PatientBannerHeader";
import PatientDataField from "components/templates/PatientBanner/PatientDataField";

import {
    getDefinedPersonalDataWithoutName,
    getIdentifier,
    getNameSurname,
    sortPersonalDataByOrder,
} from "helpers/definedPersonalData";
import { getUser } from "redux/selectors/data";

import { IPatient } from "model/assessment";
import { IOrganisation } from "model/organisation";
import PatientDetailsIntegration from "model/integrations";
import { UserRole } from "model/userRole";

import store from "redux/store";

import "scss/PatientBanner.scss";

interface IManualPatientInformationTable {
    caseId: string;
    patient: IPatient;
    patientOrganisation?: IOrganisation;
}

const ManualPatientInformationTable: FC<IManualPatientInformationTable> = ({
    caseId,
    patientOrganisation,
    patient,
}) => {
    const organisation: IOrganisation = patientOrganisation || store.store.getState().data.organisation;
    const user = useSelector(getUser);

    if (!organisation) {
        return <></>;
    }

    const { patientData } = { ...patient };
    const { definedPersonalData, patientDetailsIntegrationType } = organisation;
    const isPatientDetailsIntegrationOrg = !!patientDetailsIntegrationType;
    const patientNameSurname = getNameSurname(patientData);
    const personalData = definedPersonalData.filter(
        (data) => !data.removed && (isPatientDetailsIntegrationOrg ? data.isRequestedAtManualCreation : true)
    );
    const { identifierDefinition, identifierField } = getIdentifier(personalData, patientData);
    const personalDataWithoutName = sortPersonalDataByOrder(getDefinedPersonalDataWithoutName(personalData));
    const personalDataWithoutNameAndIdentifier = personalDataWithoutName.filter((data) => !data.identifier);
    const hasIdentifierField = Boolean(identifierField && identifierDefinition);

    const commonPatientDataFieldProps = {
        patientData,
        definedPersonalData: personalDataWithoutNameAndIdentifier,
    };
    const { integrationInfo } = { ...patient };
    const isPDSCreatedPatient = integrationInfo?.integrationName === PatientDetailsIntegration.PDS;
    const isAdminUserOrGreater = user.role === UserRole.ADMIN || user.role === UserRole.SUPERADMIN;

    return (
        <>
            {isPDSCreatedPatient && isAdminUserOrGreater && <PatientBannerHeader integrationData={integrationInfo} />}
            <Table>
                <Table.Body>
                    <Table.Row>
                        <PatientBannerTableCell width="3" title="Case" value={caseId} isMainCell />
                        <PatientBannerTableCell title="Patient" value={patientNameSurname} width="3" />
                        {hasIdentifierField && (
                            <PatientBannerTableCell
                                width="3"
                                title={identifierDefinition.displayName}
                                value={identifierField.textValue}
                            />
                        )}
                        <PatientDataField index={0} {...commonPatientDataFieldProps} />
                        <PatientDataField index={1} {...commonPatientDataFieldProps} />
                        {!hasIdentifierField && <PatientDataField index={2} {...commonPatientDataFieldProps} />}
                    </Table.Row>
                </Table.Body>
            </Table>
        </>
    );
};

ManualPatientInformationTable.defaultProps = {
    patientOrganisation: null,
};

export default ManualPatientInformationTable;
