import React, { FC, Dispatch, SetStateAction, useState, SyntheticEvent } from "react";
import { Divider, DropdownItemProps, Form, InputOnChangeData, DropdownProps } from "semantic-ui-react";

import LesionReview from "components/CaseDescription/Review/LesionReview";
import LesionReviewCheckbox from "components/CaseDescription/Review/LesionReviewCheckbox";

import { getUniqueManagementOutcomeLesionReviews, mapToOptions } from "helpers/review";

import { ICase } from "model/case";
import { IManagementOptions, IReviewDiagnosis, IReviewEscalation } from "model/organisation";
import { IReviewFormData, LesionReviewIds } from "model/reviewNotes";

interface ICaseReviewForm {
    currentCase: ICase;
    reviewDiagnoses: IReviewDiagnosis[];
    escalationPlan?: IReviewEscalation[];
    reviewManagementOptions: IManagementOptions[];
    formData: IReviewFormData;
    setFormData: Dispatch<SetStateAction<IReviewFormData>>;
    validateValues: (
        updatedFormData: IReviewFormData,
        nonSkinCancer?: boolean,
        escalationPlan?: IReviewEscalation[]
    ) => boolean;
}

const CaseReviewForm: FC<ICaseReviewForm> = ({
    currentCase,
    reviewDiagnoses,
    escalationPlan,
    reviewManagementOptions,
    formData,
    setFormData,
    validateValues,
}) => {
    const [caseManagementOutcomeOptions, setCaseManagementOutcomeOptions] = useState<DropdownItemProps[]>([]);

    const { lesionReviews, caseManagementOutcome, caseManagementOutcomeFreeText, showCaseManagementOutcome } = formData;
    const { nonSkinCancer, lesions } = currentCase;

    const getOrderedDiagnoses = (diagnoses: IReviewDiagnosis[]): IReviewDiagnosis[] => {
        const activeDiagnoses = diagnoses.filter((diagnosis) => !diagnosis.removed);
        if (activeDiagnoses.every((diag) => diag.displayOrder)) {
            return activeDiagnoses.sort((a, b) => a.displayOrder - b.displayOrder);
        }
        return activeDiagnoses;
    };

    const diagnoses = getOrderedDiagnoses(reviewDiagnoses);

    const diagnosesDictionary = mapToOptions(diagnoses, "uuid", "diagnosis");

    const getOptions = (uniqueLesionManagementOutcome: string[]) => {
        if (!reviewManagementOptions) {
            return [];
        }

        const result = reviewManagementOptions.filter((managementOutcome: IManagementOptions) =>
            uniqueLesionManagementOutcome.includes(managementOutcome.uuid)
        );

        if (!result.length || result.every((option) => option.discharge)) {
            return result;
        }

        return result.filter((value) => !value.discharge);
    };

    const handleFieldChange = (fieldName: string, fieldValue: string, uuid: string): void => {
        const updatedLesionReviews = { ...lesionReviews };
        const currentReview = { ...updatedLesionReviews[uuid] };
        currentReview[fieldName] = fieldValue;
        const isDiagnosesField = fieldName === LesionReviewIds.DIAGNOSES;

        if (isDiagnosesField) {
            currentReview[LesionReviewIds.MANAGEMENT] = "";
        }
        if (isDiagnosesField || fieldName === LesionReviewIds.MANAGEMENT) {
            currentReview[`${fieldName}${LesionReviewIds.FREE_TEXT_SUFFIX}`] = "";
        }

        updatedLesionReviews[uuid] = currentReview;
        let showCaseManagementOutcomeUpdated = false;
        let caseManagementOutcomeOptionsUpdated: DropdownItemProps[] = [];

        if (lesions.length > 0) {
            const uniqueManagementOutcomeLesionReviews = getUniqueManagementOutcomeLesionReviews(updatedLesionReviews);
            const uniqueManagementOutcomes = uniqueManagementOutcomeLesionReviews.map(
                (item) => item.selectedManagement
            );
            const options = getOptions(uniqueManagementOutcomes);
            caseManagementOutcomeOptionsUpdated = mapToOptions(options, "uuid", "management");
            showCaseManagementOutcomeUpdated = uniqueManagementOutcomeLesionReviews.length > 1;
        }

        const preselectManagmentOutcome =
            caseManagementOutcomeOptionsUpdated.length === 1 && showCaseManagementOutcomeUpdated;

        const updatedFormData = {
            ...formData,
            caseManagementOutcome: preselectManagmentOutcome
                ? (caseManagementOutcomeOptionsUpdated[0].value as string)
                : "",
            caseManagementOutcomeFreeText: "",
            lesionReviews: updatedLesionReviews,
            showCaseManagementOutcome: showCaseManagementOutcomeUpdated,
        };
        const isValid = validateValues(updatedFormData, nonSkinCancer, escalationPlan);

        setFormData({
            ...updatedFormData,
            lesionReviews: updatedLesionReviews,
            showCaseManagementOutcome: showCaseManagementOutcomeUpdated,
            isValid,
        });
        setCaseManagementOutcomeOptions(caseManagementOutcomeOptionsUpdated);
    };

    const handleCaseManagementOutcomeChange = (
        event: SyntheticEvent<HTMLElement, Event> | undefined,
        obj: DropdownProps | InputOnChangeData
    ): void => {
        const { value: newValue, id: propertyName } = obj;
        const updatedFormData = {
            ...formData,
            [propertyName]: newValue,
        };
        const isValid = validateValues(updatedFormData, nonSkinCancer, escalationPlan);
        setFormData({ ...updatedFormData, isValid });
    };

    const currentManagementOutcomeHasFreeText = caseManagementOutcome
        ? reviewManagementOptions.find((option) => option.uuid === caseManagementOutcome)?.freeText
        : false;

    return (
        <Form>
            {currentCase.lesions.map((lesion, index) => {
                const { uuid: lesionUuid } = lesion;
                const currentReview = lesionReviews[lesionUuid];
                return (
                    <LesionReview
                        key={lesionUuid}
                        lesion={lesion}
                        index={index}
                        reviewDiagnoses={reviewDiagnoses}
                        reviewManagementOptions={reviewManagementOptions}
                        diagnosesDictionary={diagnosesDictionary}
                        onFieldChange={handleFieldChange}
                        review={currentReview}
                        nonSkinCancer={currentCase.nonSkinCancer}
                        escalationPlan={escalationPlan}
                    />
                );
            })}
            <Divider />
            {showCaseManagementOutcome && (
                <div className="case-management-select">
                    {caseManagementOutcomeOptions.length > 1 ? (
                        <p>
                            Because there are 2 or more lesions with different management outcomes, please select the
                            highest priority outcome
                        </p>
                    ) : null}
                    <LesionReviewCheckbox
                        label="Case management outcome"
                        id="caseManagementOutcome"
                        options={caseManagementOutcomeOptions}
                        onFieldChange={handleCaseManagementOutcomeChange}
                        value={caseManagementOutcome}
                        freeTextValue={caseManagementOutcomeFreeText}
                        withFreeText={!!currentManagementOutcomeHasFreeText}
                    />
                </div>
            )}
        </Form>
    );
};

CaseReviewForm.defaultProps = {
    escalationPlan: [],
};

export default CaseReviewForm;
