import { connect } from "react-redux";

import TestPage from "components/TestPage/TestPage";

const mapStateToProps = (state: any) => ({
    signedIn: state.auth.signedIn,
    organisation: state.data.organisation,
});

export default connect(mapStateToProps, null)(TestPage);
