import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider, Form, Grid, Header, Segment } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";

import { isUndefined } from "helpers/common";

import { IAssessment } from "model/assessment";
import { IOrganisation } from "model/organisation";
import { UserRole } from "model/userRole";

import { HOME, LOGIN, NEW_ASSESSMENT } from "navigation/routes";
import { REMOTE_MODEL_CASES_START } from "navigation/remoteModelRoutes";

import logoutTimeoutService from "services/logoutTimeoutService";
import userService from "services/userService";

import "scss/MultilevelList.scss";
import "scss/Assessment.scss";

interface IAssessmentMode {
    organisation: IOrganisation;
    assessment: IAssessment;
    signedIn: boolean;
    nonSkinCancer: boolean;
    setNonSkinCancer: (nonSkinCancer: boolean) => void;
}

class AssessmentMode extends Component<IAssessmentMode, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            next: false,
        };
    }

    public componentDidMount() {
        logoutTimeoutService.stopCount();
    }

    private selectSkinCancerFlow = () => {
        const { setNonSkinCancer } = this.props;

        setNonSkinCancer(false);
        this.moveForward();
    };

    private selectNonSkinCancerFlow = () => {
        const { setNonSkinCancer } = this.props;

        setNonSkinCancer(true);
        this.moveForward();
    };

    private moveForward = () => {
        this.setState({
            next: true,
        });
    };

    public render() {
        const { signedIn } = this.props;

        if (!signedIn) {
            return <Redirect to={LOGIN} />;
        }

        const { assessment, organisation } = this.props;
        const { next } = this.state;

        const isCaseCreated = !!assessment.case?.uuid;
        const isBack = !isUndefined(assessment.nonSkinCancer);
        const nonSkinCancerSelected = assessment.nonSkinCancer === true;
        const skinCancerFlowSelected = isBack && assessment.nonSkinCancer === false;

        if ((!isUndefined(assessment.nonSkinCancer) && next) || !organisation.nonSkinCancerAllowed) {
            const isPatient = userService.checkUserHasRole([UserRole.PATIENT]);
            const nextPage = isPatient ? REMOTE_MODEL_CASES_START : NEW_ASSESSMENT;
            return <Redirect to={nextPage} />;
        }

        return (
            <Container className="ui segment wizzard-container">
                <div className="wizzard-container-title">
                    <h1>Select pathway</h1>
                </div>

                <Divider />
                <Form>
                    <Grid columns={2}>
                        <Grid.Row className="pathway-row">
                            <Grid.Column>
                                <Segment
                                    className={`pathway-segment ${skinCancerFlowSelected ? "selected" : ""}`}
                                    disabled={isCaseCreated}
                                >
                                    <Grid columns={1}>
                                        <Grid.Row className="header">
                                            <Grid.Column textAlign="center">
                                                <Header as="h2">Skin cancer</Header>
                                                <Divider />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className="description">
                                            <Grid.Column />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign="center">
                                                <CustomButton
                                                    disabled={isCaseCreated}
                                                    variant="filled"
                                                    type="submit"
                                                    action={this.selectSkinCancerFlow}
                                                    text="Select"
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment
                                    className={`pathway-segment ${nonSkinCancerSelected ? "selected" : ""}`}
                                    disabled={isCaseCreated}
                                >
                                    <Grid columns={1}>
                                        <Grid.Row className="header">
                                            <Grid.Column textAlign="center">
                                                <Header as="h2">Other skin complaint</Header>
                                                <Divider />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className="description">
                                            <Grid.Column />
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column textAlign="center">
                                                <CustomButton
                                                    disabled={isCaseCreated}
                                                    variant="filled"
                                                    type="submit"
                                                    action={this.selectNonSkinCancerFlow}
                                                    text="Select"
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <div className="clinic-location-button-container">
                    <ButtonContainer
                        button1={<CustomButton variant="empty" to={HOME} text="< Back" type="link" size="small" />}
                        button2={
                            isCaseCreated && (
                                <CustomButton
                                    variant="filled"
                                    type="submit"
                                    action={this.moveForward}
                                    text="Continue >"
                                />
                            )
                        }
                    />
                </div>
            </Container>
        );
    }
}

export default AssessmentMode;
