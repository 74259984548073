import moment from "moment";

import ConfirmDialog, { getDefaultButtons } from "components/templates/ConfirmDialog";

import { getFieldFromTemplate, getTemplate } from "helpers/template";
import { historyCaseConfig, pathIndex } from "helpers/historyCaseLabels";
import { isUndefined } from "helpers/common";
import { sortByDescendingDateTime } from "helpers/datetime";

import { ICallbackOutcome, ICase, ILesion } from "model/case";
import { ICaseHistoryItem, ICaseHistoryRow } from "model/caseHistory";
import { IOrganisation } from "model/organisation";
import { TemplateType } from "model/templateType";
import { CallbackOutcomesValues } from "model/remoteModel";

interface ICaseHistoryDateTimeActionKey {
    readonly created: string;
    readonly modified: string;
}

export const showAbandonDialog = (caseObject: ICase, organisation: IOrganisation, abandon: () => void) => {
    const template = getTemplate(TemplateType.ABANDON_CASE_CONFIRMATION_POPUP, organisation);
    const bodyText = getFieldFromTemplate("message", caseObject, organisation, template);
    const title = getFieldFromTemplate("title", caseObject, organisation, template);

    ConfirmDialog(bodyText, title, getDefaultButtons(abandon));
};

export const showCloseCaseDialog = (caseObject: ICase, organisation: IOrganisation, closeCase: () => void) => {
    const template = getTemplate(TemplateType.CLOSE_CASE_CONFIRMATION_POPUP, organisation);
    const bodyText = getFieldFromTemplate("message", caseObject, organisation, template);
    const title = getFieldFromTemplate("title", caseObject, organisation, template);

    ConfirmDialog(bodyText, title, getDefaultButtons(closeCase));
};

const flattenNestedArray = (itemArray: any[]): any[] => {
    if (!Array.isArray(itemArray)) {
        return itemArray;
    }

    let hasChanges = false;

    const result = itemArray.reduce((acc, prev) => {
        if (Array.isArray(prev)) {
            hasChanges = true;
            return [...acc, ...prev];
        }

        return [...acc, prev];
    }, []);

    return hasChanges ? flattenNestedArray(result) : result;
};

const getValuesByPath = (object: any, path: string): string[] => {
    if (path.length === 0 || !object) {
        return object;
    }

    const steps = path.split("/");

    if (steps[0] === pathIndex) {
        return object.map((value: any) => getValuesByPath(value, steps.slice(1).join("/")));
    }
    return getValuesByPath(object[steps[0]], steps.slice(1).join("/"));
};

const getCaseProperty = (object: any, path: string): string | string[] =>
    flattenNestedArray(getValuesByPath(object, path));

const indexArray = (array: any[], sideEffect?: (item: any) => any) =>
    array?.map((item, index) => ({
        ...item,
        index: index + 1,
        ...(sideEffect ? sideEffect(item) : {}),
    }));

export const prepareHistoryCase = (currentCase: ICase) => {
    const amendedCurrentCase = { ...currentCase };
    const originalNotes = [];
    const amendedClinicalNotes = amendedCurrentCase.clinicalNotes
        ?.map((note) => {
            const amendedNote = { ...note };
            if (!amendedNote?.supersedes && !amendedNote.removed) {
                amendedNote.noteCreatedDate = note.creationDate;
            }
            if (!amendedNote?.supersedes && amendedNote.removed) {
                amendedNote.noteCreatedDate = note.creationDate;
                amendedNote.deletedDate = note.lastModificationDate;
            }
            if (!!amendedNote.supersedes && !amendedNote.removed) {
                originalNotes.push(amendedNote.supersedes);
                amendedNote.noteCreatedDate = amendedNote.originalCreationDate;
            }
            if (!!amendedNote.supersedes && amendedNote.removed) {
                originalNotes.push(amendedNote.supersedes);
                amendedNote.noteCreatedDate = note.originalCreationDate;
                amendedNote.deletedDate = note.lastModificationDate;
            }

            return amendedNote;
        })
        .filter((note) => !originalNotes.includes(note.uuid));

    amendedCurrentCase.clinicalNotes = amendedClinicalNotes;

    return {
        ...amendedCurrentCase,
        lesions: indexArray(amendedCurrentCase.lesions, (item) => ({
            exclusions: indexArray(item.exclusions),
            images: indexArray(item.images),
        })),
        patientNotes: indexArray(amendedCurrentCase.patient?.patientNotes || [], (item) => ({
            patientDataUpdates: indexArray(item.patientDataUpdates),
        })),
        reviews: indexArray(amendedCurrentCase.reviews),
        clinicalNotes: indexArray(amendedCurrentCase.clinicalNotes),
        notifications: indexArray(amendedCurrentCase.notifications),
    };
};

const caseHistoryDateTimeActionKeys: ICaseHistoryDateTimeActionKey[] = [
    { created: "Medical history created", modified: "Medical history last modification" },
    { created: "Assignment created", modified: "Assignment last modification" },
    { created: "Review added", modified: "Review last modificated" },
    {
        created: "Notes for Clinical use added",
        modified: "Notes for Clinical use last modificated",
    },
    {
        created: "Medical history questionnaire completed",
        modified: "Medical history questionnaire last modification",
    },
    {
        created: "Medical history questionnaire completed",
        modified: "Patient details last modification",
    },
];

const filterWhereCreatedandModifiedDateTimeMatch = (
    item: ICaseHistoryItem,
    index: number,
    array: ICaseHistoryItem[]
): boolean => {
    const caseHistoryItemActionKeys = caseHistoryDateTimeActionKeys.find(({ modified }) => modified === item.action);

    if (!caseHistoryItemActionKeys || isUndefined(caseHistoryItemActionKeys)) {
        return true;
    }

    const createdItem = array.find((arrayItem) => arrayItem.action === caseHistoryItemActionKeys?.created);

    if (!createdItem || isUndefined(createdItem)) {
        return true;
    }

    return !item.date.isSame(createdItem?.date);
};

export const createCaseHistory = (currentCase: ICase): ICaseHistoryItem[] => {
    const historyArray: ICaseHistoryItem[] = [];

    historyCaseConfig.forEach((config): void => {
        const date = getCaseProperty(currentCase, config.path);

        if (!date || !date.length) {
            return;
        }

        const info = Object.keys(config.info)
            .map((key) => ({ [key]: getCaseProperty(currentCase, config.info[key]) }))
            .reduce((acc, prev) => ({ ...acc, ...prev }), {});

        if (config.show && !config?.show(info as ICaseHistoryRow)) {
            return;
        }

        if (Array.isArray(date)) {
            date.forEach((value, i) => {
                if (!value) {
                    return;
                }

                const currentInfo = {};
                Object.keys(info).forEach((key) => {
                    if (Array.isArray(info[key])) {
                        currentInfo[key] = info[key][i] !== undefined ? info[key][i] : null;
                    } else {
                        currentInfo[key] = info[key];
                    }
                });

                if (Object.keys(currentInfo)?.length === 0 || isUndefined(currentInfo)) {
                    return;
                }

                historyArray.push({
                    date: moment(value),
                    action: config.action(currentInfo),
                    by: config.by(currentInfo) as string,
                });
            });
        } else {
            historyArray.push({
                date: moment(date),
                action: config.action(info as ICaseHistoryRow),
                by: config.by(info as ICaseHistoryRow) as string,
            });
        }
    });
    return flattenNestedArray(historyArray)
        .sort(sortByDescendingDateTime)
        .filter(filterWhereCreatedandModifiedDateTimeMatch);
};

export const hasCallbackOutcomeCompleted = (callbackOutcomes: ICallbackOutcome[]): boolean => {
    if (callbackOutcomes) {
        const completedOutcome = callbackOutcomes.find((callbackOutcome) => {
            const { outcome } = callbackOutcome;
            return (
                outcome === CallbackOutcomesValues.CHANGE_MANAGEMENT_OUTCOME ||
                outcome === CallbackOutcomesValues.PATIENT_CONTACTED
            );
        });
        return Boolean(completedOutcome);
    }
    return false;
};

export const shouldRedirectToQRCodePage = (lesions: ILesion[]): boolean =>
    Boolean(lesions?.find((lesion) => !lesion.result));
