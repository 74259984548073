import { FC, SyntheticEvent } from "react";
import { DropdownItemProps, DropdownProps, Form, Grid, InputOnChangeData } from "semantic-ui-react";

import QuestionAnswer from "components/templates/QuestionAnswer";

import { LesionReviewIds } from "model/reviewNotes";

import { ERROR_FIELD_IS_REQUIRED } from "helpers/common";

interface ILesionReviewSelect {
    label: string;
    id: string;
    options: DropdownItemProps[];
    onFieldChange: (event: SyntheticEvent<HTMLElement> | undefined, obj: InputOnChangeData | DropdownProps) => void;
    value: string;
    freeTextValue: string;
    withFreeText: boolean;
}

const LesionReviewCheckbox: FC<ILesionReviewSelect> = ({
    label,
    id,
    options,
    onFieldChange,
    value,
    freeTextValue,
    withFreeText,
}) => {
    const showTextInput = withFreeText;
    const error =
        showTextInput && !freeTextValue
            ? {
                  content: ERROR_FIELD_IS_REQUIRED,
                  pointing: undefined,
              }
            : null;

    return (
        <Grid.Row>
            <Grid.Column>
                <p>
                    <b>{label}</b>
                </p>
                {options.map((option) => (
                    <QuestionAnswer
                        selected={option.value === value}
                        value={option.text as string}
                        id={id}
                        option={option}
                        onClick={onFieldChange}
                        key={option.value as string}
                    />
                ))}
                {showTextInput && (
                    <Form.Input
                        required
                        label="Please specify"
                        type="text"
                        value={freeTextValue}
                        id={`${id}${LesionReviewIds.FREE_TEXT_SUFFIX}`}
                        onChange={onFieldChange}
                        error={error}
                    />
                )}
            </Grid.Column>
        </Grid.Row>
    );
};

export default LesionReviewCheckbox;
