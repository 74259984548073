import { FC } from "react";
import { Button } from "semantic-ui-react";

import { OrganisationManagementTabs } from "model/administrationPages";
import { IOrganisation } from "model/organisation";

import organizationService from "services/organizationService";

import "scss/AdminPanel.scss";

interface IOrganisationManagementTabHeaderProps {
    organisationUuid: string;
    type: string;
    showEditButton: boolean;
    updateOrganisation: (result: any) => void;
    createMode?: boolean;
    changeMode?: () => void | null;
    buttonText?: string;
}

const OrganisationManagementTabHeader: FC<IOrganisationManagementTabHeaderProps> = ({
    changeMode,
    createMode,
    organisationUuid,
    type,
    showEditButton,
    updateOrganisation,
    buttonText,
}) => {
    const handleCopyFromParentClicked = () => {
        switch (type) {
            case OrganisationManagementTabs.PERSONAL_DATA: {
                organizationService.copyOrganisationSettings(organisationUuid).then((result: IOrganisation) => {
                    updateOrganisation(result);
                });
                break;
            }
            case OrganisationManagementTabs.QUESTIONS: {
                organizationService.copyOrganisationQuestions(organisationUuid).then((result: IOrganisation) => {
                    updateOrganisation(result);
                });
                break;
            }
            case OrganisationManagementTabs.EXCLUSIONS: {
                organizationService.copyOrganisationExclusions(organisationUuid).then((result: IOrganisation) => {
                    updateOrganisation(result);
                });
                break;
            }
            case OrganisationManagementTabs.REVIEW_SETTINGS: {
                organizationService.copyOrganisationDiagnoses(organisationUuid).then((result: IOrganisation) => {
                    updateOrganisation(result);
                });
                break;
            }
            case OrganisationManagementTabs.TEMPLATES: {
                organizationService.copyOrganisationTemplates(organisationUuid).then((result: IOrganisation) => {
                    updateOrganisation(result);
                });
                break;
            }
            case OrganisationManagementTabs.ALLOCATIONS: {
                organizationService.copyOrganisationAllocations(organisationUuid).then((result: IOrganisation) => {
                    updateOrganisation(result);
                });
                break;
            }
            case OrganisationManagementTabs.LOCATIONS: {
                organizationService.copyOrganisationLocations(organisationUuid).then((result: IOrganisation) => {
                    updateOrganisation(result);
                });
                break;
            }
            case OrganisationManagementTabs.NOTIFICATIONS: {
                if (buttonText) {
                    organizationService
                        .removeOrganisationNotifications(organisationUuid)
                        .then((result: IOrganisation) => {
                            updateOrganisation(result);
                        });
                } else {
                    organizationService
                        .copyOrganisationNotifications(organisationUuid)
                        .then((result: IOrganisation) => {
                            updateOrganisation(result);
                        });
                }
                break;
            }
            default: {
                break;
            }
        }
        document.location.reload();
    };

    const changeModeButton = (
        <Button
            floated="right"
            icon={createMode ? "times circle outline" : "plus square outline"}
            onClick={changeMode}
        />
    );

    if (showEditButton) {
        return (
            <>
                <div className="tab-button-wrapper">
                    <Button onClick={handleCopyFromParentClicked}>{buttonText || "EDIT"}</Button>
                </div>
                {(type === OrganisationManagementTabs.LOCATIONS ||
                    (type === OrganisationManagementTabs.NOTIFICATIONS && buttonText)) && (
                    <Button
                        floated="right"
                        icon={createMode ? "times circle outline" : "plus square outline"}
                        onClick={changeMode}
                    />
                )}
            </>
        );
    }

    if (changeMode) {
        return changeModeButton;
    }

    return null;
};

OrganisationManagementTabHeader.defaultProps = {
    createMode: false,
    changeMode: null,
    buttonText: "",
};

export default OrganisationManagementTabHeader;
