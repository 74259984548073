import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";

import ErrorPage from "components/ErrorPage";
import LoadingSpinner from "components/templates/LoadingSpinner";

import reportsService from "services/reportsService";

class CsvDownloadReportPage extends Component<any, any> {
    public constructor(props: any) {
        super(props);

        this.state = {
            downloadingReport: false,
            downloadedReport: false,
            error: false,
        };
    }

    public componentDidMount() {
        this.getReportFromUrl();
    }

    private getReportFromUrl() {
        const { match } = this.props;
        const { downloadingReport } = this.state;
        const { id, filename } = match.params;

        if (!downloadingReport) {
            this.setState({ downloadingReport: true });
            reportsService
                .downloadReport(id, filename)
                .then(() => {
                    this.setState({ downloadingReport: false, downloadedReport: true });
                })
                .catch((err) => {
                    this.setState({ error: err });
                });
        }
    }

    public render() {
        const { downloadedReport, error } = this.state;

        if (error) {
            return <ErrorPage errorText="Could not download report from given url" errorObject={error} />;
        }
        if (downloadedReport) {
            return <Redirect to="/home" />;
        }

        return <LoadingSpinner />;
    }
}

export default withRouter(CsvDownloadReportPage);
