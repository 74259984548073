import React, { Component } from "react";
import { Button, Divider, Form, Grid, Message, Segment } from "semantic-ui-react";

import ConditionalRender from "components/templates/ConditionalRender";
import LoadingSpinner from "components/templates/LoadingSpinner";

import { UserRole } from "model/userRole";

import auditlogServie from "services/auditlogService";

import "scss/Table.scss";

class CaseList extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activePage: 1,
            caseIdFilter: "",
            caseList: [],
            errors: [],
            submitting: false,
            totalCases: 0,
            totalPages: 0,
        };
    }

    private getErrorMessages() {
        const { errors } = this.state;
        if (errors) {
            return errors.map((detail: any) => (
                <Message key={detail.message} negative>
                    <p>{detail.message}</p>
                </Message>
            ));
        }
        return undefined;
    }

    private handleSubmitCaseId = () => {
        const { caseIdFilter } = this.state;

        this.loadPage(0, caseIdFilter);
    };

    private getResults() {
        const { caseList, submitting } = this.state;

        if (caseList && !submitting) {
            return (
                <div>
                    <Divider />
                    <textarea rows={10} defaultValue={caseList.length ? JSON.stringify(caseList) : ""} />
                </div>
            );
        }
        return <LoadingSpinner />;
    }

    private updateCaseIdFilter = (event: any, obj: any) => {
        this.setState({ caseIdFilter: obj.value });
    };

    private async loadPage(actualPage: number, caseIdFilter: string): Promise<void> {
        try {
            this.setState((prevState) => ({ ...prevState, submitting: true }));
            const result = await auditlogServie.getAuditlogById(caseIdFilter);
            this.setState({
                activePage: actualPage,
                caseList: result.data,
                submitting: false,
                totalCases: result.total,
                totalPages: Math.ceil(result.total / 10),
            });
        } catch (err: any) {
            this.setState({ errors: err?.response?.errors || [err] });
        }
    }

    public render() {
        const { caseIdFilter } = this.state;

        return (
            <ConditionalRender requiredRole={[UserRole.SUPERADMIN]}>
                <Segment>
                    <h2>Case List</h2>
                    <Form>
                        <Grid columns={4}>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <Form.Input
                                        type="text"
                                        value={caseIdFilter}
                                        label="Enter Case id"
                                        id="caseIdFilter"
                                        placeholder="Search by case id..."
                                        onChange={this.updateCaseIdFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column width={3} floated="right">
                                    <Button onClick={this.handleSubmitCaseId}>Submit</Button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>{this.getErrorMessages()}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                    {this.getResults()}
                </Segment>
            </ConditionalRender>
        );
    }
}

export default CaseList;
