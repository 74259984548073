import { FC, ClipboardEvent, memo, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";

import CustomErrorMessage from "components/ErrorMessage/CustomErrorMessage";

import useCountdownTimer from "hooks/useCountdownTimer";

import {
    IOTPConfig,
    MFAVerificationChannel,
    otpGenericError,
    otpValidationError,
    OTP_LENGTH,
    OTP_SINGLE_INPUT_REGEXP,
    MFAButtons,
} from "model/mfa";

import * as actions from "redux/actions";
import { getPendingRequest, getUser } from "redux/selectors/data";

import userService from "services/userService";

import delay from "util/delay";

import "scss/MFASetup.scss";

const generateFieldNames = (count: number): string[] => Array.from({ length: count }, (_, i) => `input-${i}`);

interface ICountdown {
    channel: MFAVerificationChannel;
    to: string;
    setHasErrored: (boolean) => void;
}

const Countdown: FC<ICountdown> = memo(({ channel, to, setHasErrored }) => {
    const loading = useSelector(getPendingRequest);
    const { countdownTime, isCountdownFinished, resetCountdown } = useCountdownTimer();

    const requestNewOTP = async (): Promise<void> => {
        try {
            resetCountdown();
            if (channel === MFAVerificationChannel.EMAIL) {
                await userService.initiateEmailVerification();
            } else {
                await userService.initiateSMSVerification(to);
            }
        } catch (err) {
            setHasErrored(true);
        }
    };

    const formattedCountdownTime = String(countdownTime).padStart(2, "0");
    const resendText = `${isCountdownFinished ? `Resend ${channel}` : `Resend in (00:${formattedCountdownTime})`}`;
    return (
        <Box className="flex-center">
            <p>
                Didn&apos;t get the code?&nbsp;
                <Box
                    component="span"
                    className={clsx(
                        "text-button",
                        "text-button__heavy",
                        "text-button__heavy--grey",
                        countdownTime > 0 && "text-button--button-inactive"
                    )}
                    role="button"
                    tabIndex={0}
                    onClick={countdownTime === 0 ? requestNewOTP : null}
                >
                    {loading ? null : resendText}
                </Box>
            </p>
        </Box>
    );
});

export interface IVerifyOTP {
    config: IOTPConfig;
}

const VerifyOTP: FC<IVerifyOTP> = memo(({ config }) => {
    const { instructions, title, channel } = config;
    const dispatch = useDispatch();
    const loading = useSelector(getPendingRequest);
    const user = useSelector(getUser);
    const inputRef = useRef(null);
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [hasVerifiedCodeSuccessfully, setHasVerifiedCodeSuccessfully] = useState<boolean>(false);

    useEffect(() => {
        const sendEmail = async (): Promise<void> => {
            try {
                setHasErrored(false);
                await userService.initiateEmailVerification();
            } catch (e: any) {
                setHasErrored(true);
            }
        };
        if (channel === MFAVerificationChannel.EMAIL) {
            sendEmail();
        }
    }, []);

    const fieldNames = useMemo(() => generateFieldNames(OTP_LENGTH), []);
    type Inputs = Record<(typeof fieldNames)[number], string>;
    const defaultValues: Inputs = useMemo(
        () =>
            fieldNames.reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {} as Inputs),
        []
    );
    const {
        clearErrors,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<Inputs>({ defaultValues });

    const validateOTP = useCallback((otp: string): void => {
        trigger();
        if (otp.length !== OTP_LENGTH) {
            throw new Error("Invalid OTP length");
        }
        if (otp.split("").some((value) => Number.isNaN(Number(value)))) {
            throw new Error("OTP contains non-numeric characters");
        }
    }, []);

    const handlePaste = useCallback((event: ClipboardEvent<HTMLElement>): void => {
        event.preventDefault();
        setHasErrored(false);
        const pastedValues = event.clipboardData.getData("text");

        try {
            validateOTP(pastedValues);
            for (let i = 0; i < pastedValues?.length; i += 1) {
                setValue(fieldNames[i], pastedValues[i]);
                trigger(fieldNames[i]);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const onSubmit: SubmitHandler<Inputs> = async (data): Promise<void> => {
        const otp = Object.keys(data)
            .map((key) => data[key])
            .join("");

        try {
            setHasErrored(false);
            clearErrors();
            validateOTP(otp);
            const response = await userService.verifyCode({ code: otp, channel });
            setHasVerifiedCodeSuccessfully(true);
            await delay(1000);
            dispatch(
                actions.mfaActions.mfaVerified({
                    channel,
                    verificationDate: response.data.verificationDate,
                })
            );
            if (channel === MFAVerificationChannel.SMS) {
                // TODO remove hack when Trusted Devices implementation is in place
                dispatch(actions.mfaActions.mfaCompleteSetup());
            }
        } catch (error: any) {
            setHasErrored(true);
        }
    };

    const getMap = useCallback((): Map<string, HTMLInputElement> => {
        if (!inputRef.current) {
            inputRef.current = new Map();
        }
        return inputRef.current;
    }, []);

    const setRef = useCallback((node: unknown, fieldName: string): void => {
        const element = node as HTMLInputElement;
        const map = getMap();
        if (node) {
            map.set(fieldName, element);
        } else {
            map.delete(fieldName);
        }
    }, []);

    const handleDigitChange = useCallback((event, field, index): void => {
        setHasErrored(false);
        const { value } = event.target;
        if (OTP_SINGLE_INPUT_REGEXP.test(value)) {
            field.onChange(value);
            const map = getMap();
            const currentInput = map.get(`input-${index}`);
            const nextInput = map.get(`input-${index + 1}`);
            currentInput.focus();
            if (nextInput) {
                nextInput.focus();
            }
        } else if (value === "") {
            field.onChange(value);
        }
    }, []);

    const errorConfig = Object.keys(errors)?.length > 0 ? otpValidationError : otpGenericError;

    const to = useMemo(() => (channel === MFAVerificationChannel.EMAIL ? user?.email : user?.mobileNumber), []);

    return (
        <Box>
            <h2>{title}</h2>
            <p className="otp-form-instructions">
                {instructions} <strong>{to}</strong>
            </p>
            <Box className="otp-form-wrapper">
                <Box className="full-width" component="form" onSubmit={handleSubmit(onSubmit)}>
                    {hasErrored || Object.keys(errors)?.length > 0 ? (
                        <CustomErrorMessage message={errorConfig.message} cta={errorConfig.cta} />
                    ) : null}
                    <Box className="otp-input-wrapper">
                        {fieldNames.map((fieldName, index) => {
                            const accessibleLabel = `Please enter digit ${index + 1} of your one time passcode`;
                            return (
                                <div key={fieldName}>
                                    <label htmlFor={fieldName} style={{ display: "none" }}>
                                        Digit {index + 1} of your one-time password
                                    </label>
                                    <Controller
                                        name={fieldName}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: true,
                                            minLength: 1,
                                            maxLength: 1,
                                            min: 0,
                                            max: 9,
                                            pattern: OTP_SINGLE_INPUT_REGEXP,
                                            onBlur: () => trigger(fieldName),
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="otp-input"
                                                data-testid={fieldName}
                                                error={!!errors[fieldName]}
                                                id={fieldName}
                                                name={fieldName}
                                                inputProps={{
                                                    "aria-atomic": "true",
                                                    "aria-label": accessibleLabel,
                                                    "aria-invalid": errors[fieldName] ? "true" : "false",
                                                    "maxLength": 1,
                                                    "inputMode": "numeric",
                                                    "pattern": OTP_SINGLE_INPUT_REGEXP.source,
                                                    "min": 0,
                                                    "max": 9,
                                                    "ref": (node) => setRef(node, fieldName),
                                                }}
                                                // inputProps and InputProps are valid properties for TextField
                                                // https://v4.mui.com/api/text-field/
                                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                                InputProps={{
                                                    className: hasVerifiedCodeSuccessfully ? "success" : "",
                                                }}
                                                onChange={(e) => handleDigitChange(e, field, index)}
                                                onPaste={handlePaste}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </div>
                            );
                        })}
                    </Box>
                    <Box className="mfa-button-wrapper">
                        <Button
                            className="full-width"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={loading || hasVerifiedCodeSuccessfully}
                        >
                            {loading ? <CircularProgress size="16px" /> : MFAButtons.CONTINUE}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Countdown channel={channel} to={to} setHasErrored={setHasErrored} />
        </Box>
    );
});

export default VerifyOTP;
