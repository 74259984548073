import { FC, useEffect } from "react";
import { List } from "semantic-ui-react";

import PasswordMatchingItem from "components/PasswordMatching/PasswordMatchingItem";

import { getPasswordMinLength } from "helpers/password";

import { UserRole } from "model/userRole";

interface IPasswordMatching {
    newPassword: string;
    confirmNewPassword: string;
    makeButtonDisabled?: (state: boolean) => void;
    userRole?: UserRole;
    title?: string;
}

const PasswordMatching: FC<IPasswordMatching> = ({
    newPassword,
    confirmNewPassword,
    makeButtonDisabled,
    userRole,
    title,
}) => {
    const passwordMinLength = getPasswordMinLength(userRole);
    const isContainEnoughSymbols = newPassword.length >= passwordMinLength;
    const isMatchWithConfirmPassword = !!newPassword.length && newPassword === confirmNewPassword;
    const isContainNumber = /\d/.test(newPassword);
    const isContainUppercaseLetter = /[A-Z]/.test(newPassword);
    const isContainSpecialCharacter = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(newPassword);

    const buttonState =
        isContainEnoughSymbols &&
        isMatchWithConfirmPassword &&
        isContainNumber &&
        isContainUppercaseLetter &&
        isContainSpecialCharacter;

    useEffect(() => {
        if (makeButtonDisabled) {
            makeButtonDisabled(!buttonState);
        }
    }, [buttonState]);

    const getTitle = (): string => {
        if (title) {
            return title;
        }
        return "To make your password strong, it must:";
    };

    return (
        <>
            <p className="password-matching-text">{getTitle()}</p>

            <List>
                <PasswordMatchingItem
                    isMatched={isContainEnoughSymbols}
                    text={`contains ${passwordMinLength} or more characters`}
                />
                <PasswordMatchingItem isMatched={isContainNumber} text="contains at least 1 number" />
                <PasswordMatchingItem
                    isMatched={isContainUppercaseLetter}
                    text="contains at least 1 UPPERCASE letter"
                />
                <PasswordMatchingItem
                    isMatched={isContainSpecialCharacter}
                    text="contains at least 1 special character e.g. !@£$%^&*()"
                />
                <PasswordMatchingItem isMatched={isMatchWithConfirmPassword} text="password and confirmation match" />
            </List>
        </>
    );
};

PasswordMatching.defaultProps = {
    makeButtonDisabled: () => undefined,
    userRole: undefined,
    title: "",
};

export default PasswordMatching;
