import React, { FC, useContext } from "react";
import { Divider, Header } from "semantic-ui-react";
import clsx from "clsx";

import ReferralRecommendation from "components/RemoteModel/ReportAvailable/ReferralRecommendation";
import CustomButton from "components/templates/Button";

import { ReportAvailableActions } from "contextProviders/modules/reportAvailable/actions";
import { ReportAvailableContext } from "contextProviders/ReportAvailableProvider";

import { getNameSurname } from "helpers/definedPersonalData";

import { IPatient, RemoteModelManagementOutcomes, RemoteModelStatuses } from "model/assessment";
import { ICase } from "model/case";

import * as caseService from "services/caseService";

const ASSESSMENT_REPORT_TEXT = {
    [RemoteModelManagementOutcomes.DISCHARGE]: `We're pleased to inform you that the lesions assessed
        were felt to be benign (harmless). As a result, no further action is necessary and you will
        not require a further appointment with a dermatologist.`,
    [RemoteModelManagementOutcomes.REFERRAL]: `On assessing the history and images in your case, it
        was felt that one or more lesions would benefit from having an in person assessment with
        a dermatologist so that they can take a closer look.`,
};

interface IAssessmentReport {
    patient: IPatient;
    currentCase: ICase;
    recommendationType: RemoteModelManagementOutcomes;
    isCallbackNeeded: boolean;
}

const AssessmentReport: FC<IAssessmentReport> = ({ patient, currentCase, recommendationType, isCallbackNeeded }) => {
    const {
        state: { isCaseClosingPending, isDownloadReportErrorVisible, caseStatus },
        dispatch,
    } = useContext(ReportAvailableContext);

    const { uuid: currentCaseUuid } = currentCase;
    const { patientData } = { ...patient };
    const patientNameSurname = getNameSurname(patientData, true);

    const handleCloseCaseClicked = async () => {
        try {
            dispatch({
                type: ReportAvailableActions.START_CLOSE_CASE_REQUEST,
            });
            await caseService.closeCase(currentCaseUuid);
            dispatch({
                type: ReportAvailableActions.SET_CASE_CLOSED,
            });
        } catch (err: any) {
            dispatch({
                type: ReportAvailableActions.SET_CASE_CLOSED_ERROR,
                payload: err.message,
            });
        }
    };

    const showUnderstandBlock = caseStatus === RemoteModelStatuses.REPORT_AVAILABLE;
    const classNameButton = clsx({ "remote-model-report-error": isDownloadReportErrorVisible });

    return (
        <>
            <p>
                Dear {patientNameSurname},
                <br />
                <br />
                You recently registered with Skin Analytics for a teledermatology assessment of one or more lesions that
                was causing you concern.
                <br />
                <br />
                {ASSESSMENT_REPORT_TEXT[recommendationType]}
                <br />
                <br />
                Below you will find the report from your assessment and suggested next steps.
            </p>
            <ReferralRecommendation currentCase={currentCase} />
            {showUnderstandBlock && (
                <>
                    <Divider />
                    <div className="buttons-wrapper">
                        <Header as="h4">
                            Please click the button below to certify that you understand your results.
                        </Header>
                        <CustomButton
                            action={handleCloseCaseClicked}
                            type="button"
                            variant="filled"
                            loading={isCaseClosingPending}
                            text="I understand my results"
                            className={classNameButton}
                            disabled={isCallbackNeeded}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default AssessmentReport;
