import { FC, ReactNode, useCallback } from "react";
import { useSelector } from "react-redux";
import { Container, Divider } from "semantic-ui-react";
import clsx from "clsx";

import CaseList from "components/CaseList";
import Setup from "components/MFA/Setup";
import OrganisationSelection from "components/OrganisationSelection/OrganisationSelection";
import ConditionalRender from "components/templates/ConditionalRender";

import { UserRole } from "model/userRole";

import { getShowOrganisationSelection } from "redux/selectors/data";
import { getShowMFAFlow } from "redux/selectors/mfa";

import logoutTimeoutService from "services/logoutTimeoutService";

import "scss/Page.scss";

interface IInterstitialPageList {
    component: ReactNode;
    order: number;
}

const HomeScreen: FC = () => {
    const showOrganisationSelection = useSelector(getShowOrganisationSelection);
    const showMFASetup = useSelector(getShowMFAFlow);

    const handleClick = (): void => {
        logoutTimeoutService.startCount();
    };

    const generateInterstitialPageList = useCallback(
        (): IInterstitialPageList[] =>
            [
                {
                    show: showMFASetup,
                    component: <Setup />,
                    order: 0,
                },
                {
                    show: showOrganisationSelection,
                    component: <OrganisationSelection />,
                    order: 1,
                },
            ]
                .filter((page) => page.show)
                .sort((a, b) => a.order - b.order),
        [showMFASetup, showOrganisationSelection]
    );

    const pages = generateInterstitialPageList();

    return (
        <Container
            className={clsx("home-screen-wrapper", pages?.length > 0 ? "full-height" : "")}
            onClick={handleClick}
        >
            {pages?.length > 0 ? (
                pages[0]?.component
            ) : (
                <>
                    <Divider />
                    <ConditionalRender
                        requiredRole={[
                            UserRole.SUPERADMIN,
                            UserRole.PATIENT,
                            UserRole.CLINICIAN,
                            UserRole.ADMIN,
                            UserRole.DERMATOLOGIST,
                            UserRole.SA_ADMIN,
                            UserRole.CALLBACK_AGENT,
                            UserRole.CLINICAL_VIEWER,
                        ]}
                    >
                        <CaseList />
                    </ConditionalRender>
                    <Divider />
                </>
            )}
        </Container>
    );
};

export default HomeScreen;
