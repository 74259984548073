import { createActions } from "redux-actions";

import types from "redux/actions/types";

export const { errorGeneral, successGeneral, warningGeneral, cleanValidationError, clearData } = createActions(
    types.ERROR_GENERAL,
    types.SUCCESS_GENERAL,
    types.WARNING_GENERAL,
    types.CLEAN_VALIDATION_ERROR,
    types.CLEAR_DATA
);
