import { AxiosResponse } from "axios";
import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { IUserPreferencesData } from "model/caseList";
import { ALL_ITEM } from "model/filters";
import HttpStatus from "model/httpStatus";
import { MFAVerificationChannel } from "model/mfa";
import { IUser, IUserData } from "model/user";
import { UserRole } from "model/userRole";

import * as actions from "redux/actions";
import store from "redux/store";

import organizationService from "services/organizationService";

import URLs from "services/urls";

const {
    USER_MY_USER_URL,
    GET_ALL_USERS,
    CREATE_USER_URL,
    UPDATE_USER_URL,
    GET_USER_BY_UUID,
    TOGGLE_USER_URL,
    MFA_CREATE_GRACE_PERIOD_START_DATE,
    MFA_SETUP_CHECK,
    MFA_EMAIL_INITIATION,
    MFA_SMS_INITIATION,
    MFA_VERIFY_CODE,
    GET_ORGANISATION_USERS_URL,
    UPDATE_USER_PREFERENCES_URL,
    SET_HIDE_GENERAL_TELEDERMATOLOGY_EXCEPTIONS_URL,
    SET_HIDE_ORGANISATION_SWITCH_REMINDER_URL,
    HIDE_MODAL_URL,
} = URLs;

interface IUserResponse {
    status: number;
    uuid: string;
}

export interface IUserDict {
    key: string;
    text: string;
    value: string;
    organisationuuid: string;
    additionalorganisationuuids: string;
    linkedUserUuid: string;
    uuid?: string;
}

/**
 * MFA
 */

async function createMFAGracePeriodStartDate(): Promise<{ status: number }> {
    const response = await AuthorizedHttpConnector.sendPost(MFA_CREATE_GRACE_PERIOD_START_DATE, {});
    return { status: response.status };
}

async function checkIfMFASetupRequired(dispatch: any): Promise<void> {
    try {
        dispatch(actions.mfaActions.mfaStatusRequest());
        const response = await AuthorizedHttpConnector.sendGet(MFA_SETUP_CHECK);
        dispatch(actions.mfaActions.mfaStatusRequestSuccess(response.data.data));
    } catch (err: any) {
        dispatch(actions.mfaActions.mfaStatusRequestError());
    }
}

async function initiateSMSVerification(mobileNumber: string): Promise<void> {
    try {
        const response = await AuthorizedHttpConnector.sendPost(MFA_SMS_INITIATION, {
            number: mobileNumber,
        });
        store.store.dispatch(
            actions.mfaActions.mfaVerificationSent({
                data: response.data.data,
                channel: MFAVerificationChannel.SMS,
                mobileNumber,
            })
        );
    } catch (err: any) {
        store.store.dispatch(actions.mfaActions.mfaVerificationSent());
        throw new Error(err);
    }
}

async function initiateEmailVerification(): Promise<void> {
    try {
        const response = await AuthorizedHttpConnector.sendPost(MFA_EMAIL_INITIATION, {});
        store.store.dispatch(
            actions.mfaActions.mfaVerificationSent({ data: response.data.data, channel: MFAVerificationChannel.EMAIL })
        );
    } catch (err: any) {
        store.store.dispatch(actions.mfaActions.mfaVerificationSent());
        throw new Error(err);
    }
}

async function verifyCode(data: { code: string; channel: MFAVerificationChannel }): Promise<AxiosResponse<any, any>> {
    try {
        store.store.dispatch(actions.dataActions.setPendingRequest(true));
        const response = await AuthorizedHttpConnector.sendPost(MFA_VERIFY_CODE, data);
        return response;
    } catch (err: any) {
        store.store.dispatch(actions.mfaActions.mfaStatusRequest());
        throw new Error(err);
    }
}

async function getLoggedUserDetailsAsync(dispatch: any): Promise<void> {
    dispatch(actions.dataActions.getUserMeRequest());
    const response = await AuthorizedHttpConnector.sendGet(USER_MY_USER_URL);
    dispatch(actions.dataActions.getUserMeSuccess(response.data.data));
    if (
        response.data.data.additionalOrganisationData?.length > 0 ||
        (response.data.data.linkedUser && !response.data.data.linkedUser.removed)
    ) {
        const showCaseListSnackbar = !response.data.data.preferences?.hideOrganisationSwitchReminder;
        dispatch(actions.dataActions.showOrganisationSelection(showCaseListSnackbar));
    }
    await checkIfMFASetupRequired(dispatch);
    await organizationService.getOrganisation(dispatch);
}

function getLoggedUserRole(): UserRole | undefined {
    const authStore = store.store.getState().auth;

    if (authStore.authTokenBody && authStore.authTokenBody.userRole) {
        return authStore.authTokenBody.userRole;
    }
    return undefined;
}

function checkUserHasRole(roles: UserRole[]): boolean {
    return roles.includes(store.store.getState().auth.authTokenBody.userRole);
}

async function getAllUsers({
    offset,
    filters,
    search,
    organisations,
    limit,
}: {
    offset: number;
    filters?: string[];
    search?: string[];
    organisations?: string[];
    limit?: number;
}) {
    const url = getLoggedUserRole() === UserRole.SUPERADMIN ? GET_ALL_USERS : GET_ORGANISATION_USERS_URL;
    const urlGetAllUsers =
        url.replace("{{OFFSET}}", String(offset)).replace("{{LIMIT}}", String(limit || 10)) +
        (filters ? `&filter=${filters.join(";")}` : "") +
        (search ? `&search=${search.join(";")}` : "") +
        (organisations ? `&organisationSearch=${organisations.join(";")}` : "");

    if (limit > 500) {
        const allUsers = { data: [], total: 0, correlationId: "" };
        const getUsers = async (firstBatchLink: string): Promise<void> => {
            if (firstBatchLink) {
                const batch = await AuthorizedHttpConnector.sendGet(firstBatchLink);
                allUsers.data.push(...batch.data.data);
                if (batch.data._links?.next) {
                    await getUsers(`${process.env.REACT_APP_READER_MS}${batch.data._links.next}`);
                }
            }
        };
        allUsers.total = allUsers.data.length;
        await getUsers(urlGetAllUsers);
        return allUsers;
    }
    const result = await AuthorizedHttpConnector.sendGet(urlGetAllUsers);
    return result.data;
}

async function getAllUsersDict(
    offset: number,
    filters?: string[],
    search?: string[],
    limit?: number
): Promise<IUserDict[]> {
    const result = await getAllUsers({ offset, filters, search, limit });

    const userDict = result.data.map((userItem: IUser) => {
        const {
            uuid,
            name,
            surname,
            organisationName,
            organisationUuid,
            linkedUser,
            additionalOrganisationData = [],
        } = userItem;
        const additionalOrganisationUuids = additionalOrganisationData?.map((data) => data.organisationUuid);
        const additionalOrganisationNames = additionalOrganisationData?.map((data) => data.organisationName);

        const allOrgNames = [organisationName, ...additionalOrganisationNames];

        const linkedUserUuid = linkedUser && !linkedUser.removed ? linkedUser.linkedUserUuid : undefined;

        return {
            key: uuid,
            text: `${surname ? surname.toUpperCase() : ""}, ${name} (${allOrgNames.join(", ")})`,
            value: uuid,
            organisationuuid: organisationUuid,
            additionalorganisationuuids: additionalOrganisationUuids,
            linkedUserUuid,
        };
    });

    return [ALL_ITEM, ...userDict];
}

async function getOrganisationUsers(filters?: string[], search?: string[]) {
    const filtersValue = filters ? `&filter=${filters.toString()}` : "";
    const searchValue = search ? `&search=${search.join(";")}` : "";

    const urlGetAllUsers = `${GET_ORGANISATION_USERS_URL}${filtersValue}${searchValue}`;

    const result = await AuthorizedHttpConnector.sendGet(urlGetAllUsers);
    return result.data;
}

async function createNewUser(data: IUserData): Promise<IUserResponse> {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_USER_URL, { ...data });

    return { status: response.status, uuid: response.data.data.uuid };
}

async function getUserByUuid(uuid: string): Promise<{ data: IUser }> {
    const getUserByUuidUrl = GET_USER_BY_UUID.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendGet(getUserByUuidUrl);

    return response.data;
}

async function updateUser(uuid: string, data: IUserData): Promise<IUserResponse> {
    const response = await AuthorizedHttpConnector.sendPut(UPDATE_USER_URL.replace("{{UUID}}", uuid), { ...data });

    return { status: response.status, uuid: response.data.data.uuid };
}

async function toggleUser(uuid: string): Promise<IUserResponse> {
    const response = await AuthorizedHttpConnector.sendPost(TOGGLE_USER_URL.replace("{{UUID}}", uuid), {});

    return { status: response.status, uuid: response.data.data.uuid };
}

function getCurrentUserFullName() {
    return store.store.getState().auth.authTokenBody.userFullName;
}

function getCurrentUserUuid() {
    return store.store.getState().auth.authTokenBody.userUuid;
}

async function updateUserPrefernces(data: IUserPreferencesData) {
    const response = await AuthorizedHttpConnector.sendPost(UPDATE_USER_PREFERENCES_URL, {
        ...data,
    });

    return { status: response.status };
}

async function setHideGeneralTeledermatologyExceptions() {
    const response = await AuthorizedHttpConnector.sendPost(SET_HIDE_GENERAL_TELEDERMATOLOGY_EXCEPTIONS_URL, {});

    if (response.status === HttpStatus.OK) {
        store.store.dispatch(actions.dataActions.updateHideGtePopup());
    }

    return { status: response.status };
}

async function hideModal(modalIdentifier: string): Promise<void> {
    const dispatchHideModalPreference = () =>
        store.store.dispatch(actions.dataActions.hideUserModalPreference(modalIdentifier));
    try {
        const getModalUrl = HIDE_MODAL_URL.replace("{{UUID}}", modalIdentifier);
        await AuthorizedHttpConnector.sendPut(getModalUrl, {});

        dispatchHideModalPreference();
    } catch (err) {
        // Update the modalPreference object in redux state to hide the modal even if there is an API error
        dispatchHideModalPreference();
    }
}

function getPatientToCreateNewCase() {
    const { patientToCreateNewCase } = store.store.getState().data.user;
    return patientToCreateNewCase;
}

function getCaseReviewerFullName(): string {
    const { title = "", role, name, surname, gmcNumber = "" } = store.store.getState().data.user;
    if (role === UserRole.DERMATOLOGIST) {
        const userTitle = title ? `${title} ` : "";
        return `${userTitle}${name} ${surname} (GMC ${gmcNumber})`;
    }
    return `${name} ${surname}`;
}

function checkUserHasAdditionalOrganisationData(): boolean {
    return Boolean(store.store.getState().data.user.additionalOrganisationData?.length);
}

function hideCaseListSnackbar() {
    store.store.dispatch(actions.dataActions.hideCaseListSnackbar());
}

async function setHideOrganisationSwitchPreference(): Promise<{ status: number }> {
    const response = await AuthorizedHttpConnector.sendPost(SET_HIDE_ORGANISATION_SWITCH_REMINDER_URL, {});
    if (response.status === HttpStatus.OK) {
        store.store.dispatch(actions.dataActions.hideCaseListSnackbar());
    }
    return { status: response.status };
}

export default {
    checkUserHasRole,
    createNewUser,
    getAllUsers,
    getAllUsersDict,
    getCaseReviewerFullName,
    getCurrentUserFullName,
    getCurrentUserUuid,
    getLoggedUserDetailsAsync,
    getLoggedUserRole,
    getOrganisationUsers,
    getUserByUuid,
    toggleUser,
    updateUser,
    createMFAGracePeriodStartDate,
    checkIfMFASetupRequired,
    initiateSMSVerification,
    initiateEmailVerification,
    verifyCode,
    updateUserPrefernces,
    hideModal,
    setHideGeneralTeledermatologyExceptions,
    getPatientToCreateNewCase,
    checkUserHasAdditionalOrganisationData,
    hideCaseListSnackbar,
    setHideOrganisationSwitchPreference,
};
