import { FC, Fragment, useState } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Divider, Header, Segment } from "semantic-ui-react";
import Accordion from "@material-ui/core/Accordion";
import MUIAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReportIncorrectClassificationModal from "components/ReportIncorrectClassificationModal";
import ReportIncorrectClassification from "components/CaseDescription/ReportIncorrectClassification";
import ConditionalRender from "components/templates/ConditionalRender";

import {
    getFitzpatrickQuestionAnswers,
    getFitzpatrickQuestionsCreateDetails,
    getUserRoleFromFPNote,
} from "helpers/fitzpatrick";
import { getSkinToneClassificationByAssigneeUuid } from "helpers/patientUtils";

import { AssessmentTypes, IMedicalHistory } from "model/assessment";
import { ICase, ISkinToneAnswer } from "model/case";
import IClickOrigin from "model/clickOrigin";
import { IMedicalHistoryQuestion } from "model/organisation";
import { SkinTypeNoteOriginator, UserRole } from "model/userRole";

import userService from "services/userService";

const AccordionSummary = withStyles({
    root: {
        "marginBottom": -1,
        "minHeight": 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MUIAccordionSummary);

const useStyles = makeStyles(() =>
    createStyles({
        accordion: {
            "boxShadow": "none",
            "&.expanded": {},
        },
        accordionDetails: {
            display: "block",
        },
        reportIncorrectClassificationGrid: {
            display: "grid",
            justifyItems: "right",
            alignItems: "flex-start",
        },
        reportIncorrectClassificationWrapper: {
            position: "relative",
        },
        boldParagraph: {
            fontWeight: 600,
        },
        marginTop: { marginTop: "8px" },
    })
);

interface IAdditionalInformation {
    additionalInformation: string;
}

const AdditionalInformation: FC<IAdditionalInformation> = ({ additionalInformation }) => {
    if (!additionalInformation) {
        return null;
    }
    return (
        <div style={{ marginTop: "8px" }}>
            <Segment className="additional-information">
                <p>{additionalInformation}</p>
            </Segment>
        </div>
    );
};

interface IFitzpatrickQuestionsSection {
    caseObject: ICase;
    questionDefinitions: IMedicalHistoryQuestion[];
    questions: IMedicalHistory[];
    title?: string;
    assessmentType?: AssessmentTypes;
    updateSkinToneClassification: (result: ISkinToneAnswer[]) => void;
}

const FitzpatrickQuestionsSection: FC<IFitzpatrickQuestionsSection> = ({
    caseObject,
    questionDefinitions,
    questions,
    title,
    assessmentType,
    updateSkinToneClassification,
}) => {
    const classes = useStyles();
    const [showReportClassificationModal, setShowReportClassificationModal] = useState<boolean>(false);
    const [expand, setExpand] = useState(true);
    let answerComponent = <p className="subtitle-gray-text">Not provided by the patient</p>;
    let additionalAnswer = null;
    let fitzpatrickAnswer;
    const isRemoteModel = assessmentType === AssessmentTypes.remoteModel;
    const headerClassName = isRemoteModel ? "" : "without-bottom-margin";
    const currentUserUuid = userService.getCurrentUserUuid();
    const skinToneClassification = getSkinToneClassificationByAssigneeUuid(caseObject?.patient, currentUserUuid);
    const isCaseAssignedToCurrentUser = caseObject?.assignmentDetails?.assigneeUuid === currentUserUuid;
    const showToCurrentUser = isCaseAssignedToCurrentUser || !!skinToneClassification;
    const { fitzpatrickQuestionAnswers, fitzpatrickQuestionAnswer } = getFitzpatrickQuestionAnswers(
        questions,
        questionDefinitions
    );
    let caseQuestionsCreateDetails = "";
    if (fitzpatrickQuestionAnswer) {
        fitzpatrickAnswer = fitzpatrickQuestionAnswer?.answers[0] || fitzpatrickQuestionAnswer?.additionalInformation;
        if (fitzpatrickAnswer) {
            caseQuestionsCreateDetails = getFitzpatrickQuestionsCreateDetails(caseObject, fitzpatrickQuestionAnswer);
            if (caseObject.homeInitiated) {
                const answerWithRole = {
                    role: getUserRoleFromFPNote(fitzpatrickQuestionAnswer, caseObject),
                    answer: fitzpatrickQuestionAnswers[0],
                };

                if (
                    fitzpatrickQuestionAnswer?.additionalInformation &&
                    fitzpatrickQuestionAnswer.additionalInformation !== ""
                ) {
                    additionalAnswer = (
                        <Accordion className={classes.accordion} expanded={expand} onChange={() => setExpand(!expand)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {answerWithRole.answer.answers[0] ? (
                                    <p className={classes.boldParagraph}>{answerWithRole.answer.answers[0]}</p>
                                ) : (
                                    answerComponent
                                )}
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                <>
                                    <p className={`${classes.boldParagraph} ${classes.marginTop}`}>
                                        {answerWithRole.role} note
                                    </p>
                                    <AdditionalInformation
                                        additionalInformation={answerWithRole.answer.additionalInformation}
                                    />
                                </>
                            </AccordionDetails>
                        </Accordion>
                    );
                } else {
                    additionalAnswer = <p className={classes.boldParagraph}>{fitzpatrickAnswer}</p>;
                }
            } else {
                additionalAnswer = <p className={classes.boldParagraph}>{fitzpatrickAnswer}</p>;
                if (
                    fitzpatrickQuestionAnswer?.additionalInformation &&
                    fitzpatrickQuestionAnswer.additionalInformation !== ""
                ) {
                    additionalAnswer = (
                        <Accordion className={classes.accordion} expanded={expand} onChange={() => setExpand(!expand)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <p className={classes.boldParagraph}>{fitzpatrickAnswer}</p>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                <p className={`${classes.boldParagraph} ${classes.marginTop}`}>Clinician note</p>
                                <AdditionalInformation
                                    additionalInformation={fitzpatrickQuestionAnswer.additionalInformation}
                                />
                            </AccordionDetails>
                        </Accordion>
                    );
                }
            }
        } else {
            additionalAnswer = <p className="subtitle-gray-text">Not provided by the patient</p>;
        }
    }

    const renderQuestionAnswers = () => {
        if (fitzpatrickQuestionAnswers?.length === 1) {
            return additionalAnswer;
        }

        if (fitzpatrickQuestionAnswers?.length >= 2) {
            const answersWithRoles = fitzpatrickQuestionAnswers.map((answer: IMedicalHistory) => ({
                role: getUserRoleFromFPNote(answer, caseObject),
                answer,
            }));

            const clinicianAnswer = answersWithRoles.find(
                (answer) => answer.role === SkinTypeNoteOriginator.CLINICIAN
            ).answer;
            const patientAnswer = answersWithRoles.find(
                (answer) => answer.role === SkinTypeNoteOriginator.PATIENT
            ).answer;

            fitzpatrickAnswer = clinicianAnswer?.answers[0];
            answerComponent = fitzpatrickAnswer ? (
                <p className={classes.boldParagraph}>{fitzpatrickAnswer}</p>
            ) : (
                <p className="subtitle-gray-text">Not provided by the patient</p>
            );

            return (
                <Accordion className={classes.accordion} expanded={expand} onChange={() => setExpand(!expand)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{answerComponent}</AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        {patientAnswer?.additionalInformation && (
                            <Fragment key={patientAnswer.createdBy}>
                                <p className={`${classes.boldParagraph} ${classes.marginTop}`}>Patient note</p>
                                <AdditionalInformation additionalInformation={patientAnswer.additionalInformation} />
                            </Fragment>
                        )}
                        {clinicianAnswer?.additionalInformation && (
                            <Fragment key={clinicianAnswer.createdBy}>
                                <p className={`${classes.boldParagraph} ${classes.marginTop}`}>Clinician note</p>
                                <AdditionalInformation additionalInformation={clinicianAnswer.additionalInformation} />
                            </Fragment>
                        )}
                    </AccordionDetails>
                </Accordion>
            );
        }

        return answerComponent;
    };

    return (
        <>
            {title && (
                <>
                    <Header as="h2" className={headerClassName}>
                        {title}
                    </Header>
                    {fitzpatrickQuestionAnswer && caseQuestionsCreateDetails && (
                        <p className="subtitle-gray-text assessed-by-text">{caseQuestionsCreateDetails}</p>
                    )}
                    <Divider className="title-divider" />
                    <div className="tab-div">
                        <Grid container className={classes.reportIncorrectClassificationWrapper}>
                            <Grid item xs={12} sm={6}>
                                {renderQuestionAnswers()}
                            </Grid>
                            {fitzpatrickAnswer ? (
                                <ConditionalRender requiredRole={[UserRole.DERMATOLOGIST]}>
                                    <Grid className={classes.reportIncorrectClassificationGrid} item xs={12} sm={6}>
                                        <ReportIncorrectClassification
                                            fitzpatrickAnswer={fitzpatrickAnswer}
                                            skinToneClassification={skinToneClassification}
                                            showModal={setShowReportClassificationModal}
                                            showToCurrentUser={showToCurrentUser}
                                        />
                                    </Grid>
                                </ConditionalRender>
                            ) : null}
                        </Grid>
                    </div>
                </>
            )}

            <ReportIncorrectClassificationModal
                caseObject={caseObject}
                fitzpatrickQuestionAnswers={fitzpatrickQuestionAnswers}
                showModal={showReportClassificationModal}
                closeModal={() => setShowReportClassificationModal(false)}
                clickOrigin={IClickOrigin.CASE_REVIEW}
                updateSkinToneClassification={updateSkinToneClassification}
            />
        </>
    );
};

FitzpatrickQuestionsSection.defaultProps = {
    title: "",
    assessmentType: null,
};

export default FitzpatrickQuestionsSection;
