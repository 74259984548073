import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Header, Message } from "semantic-ui-react";
import Button from "@material-ui/core/Button";

import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";
import ConfirmCaseSubmissionDialog from "components/RemoteModel/CaseSummary/ConfirmCaseSubmissionDialog";
import LesionOverview from "components/RemoteModel/CaseSummary/LesionOverview";
import QuestionsSection from "components/CaseDescription/Questions/QuestionsSection";
import EditLink from "components/RemoteModel/CaseSummary/EditLink";
import RemoteModelContainer from "components/templates/RemoteModelContainer";
import RetakeImagesWarning from "components/RemoteModel/CaseSummary/RetakeImagesWarning";

import { getCaseQuestionDefinition } from "helpers/questionDefinition";
import { multiplyEnding } from "helpers/common";
import { containsImagesToRetake, containsRetakenImages } from "helpers/assessment";

import { AssessmentTypes } from "model/assessment";
import { ILesion } from "model/case";
import CaseQuestionDefinitionType from "model/caseQuestionDefinitionType";
import { RemoteTimeline } from "model/remoteCaseTimeline";

import { history } from "App";
import {
    REMOTE_MODEL_CASES_LESION_DETAILS,
    REMOTE_MODEL_CASES_MEDICAL_HISTORY,
    REMOTE_MODEL_CASES_IN_REVIEW,
} from "navigation/remoteModelRoutes";

import { getAssessment } from "redux/selectors/assessment";

import * as caseService from "services/caseService";
import assessmentService from "services/assessmentService";

const MAX_ALLOWED_LESIONS_PER_CASE = 3;
const ADD_ANOTHER_LESION_BUTTON_COPY = "Add another lesion";

interface IAddNewLesionButton {
    wasCasePreviouslySubmitted: boolean;
    possibleAddNewLesion: boolean;
}

const AddNewLesionMessage: FC<IAddNewLesionButton> = ({ wasCasePreviouslySubmitted, possibleAddNewLesion }) => (
    <>
        {!wasCasePreviouslySubmitted ? (
            <>
                {!possibleAddNewLesion ? (
                    <Message className="add-new-lesion-message" info>
                        <p>
                            Our service allows a maximum assessment of 3 lesions. If you have concerns regarding more
                            than 3 lesions, please contact your insurance provider or NHS GP to discuss alternative
                            options available.
                        </p>
                        <p>
                            Click the &quot;<strong>Submit Case</strong>&quot; button to send your images for
                            assessment.
                        </p>
                    </Message>
                ) : null}
            </>
        ) : null}
    </>
);

const CaseSummary: FC = () => {
    const [medicalQuestionDefinition, setMedicalQuestionDefinition] = useState([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const assessment = useSelector(getAssessment);
    const dispatch = useDispatch();

    const { case: currentCase, currentLesion, patient } = assessment;
    const { lesions, uuid: currentCaseUuid } = { ...currentCase };
    const { medicalHistory } = { ...patient };

    const hasImagesToRetake: boolean = lesions?.some(
        (lesion) => lesion.images && containsImagesToRetake(lesion.images)
    );
    const hasRetakenImages: boolean = lesions?.some((lesion) => lesion.images && containsRetakenImages(lesion.images));

    useEffect(() => {
        // get the latest data before asserting the following if statement
        dispatch(caseService.continueAssessment(currentCase.uuid));
    }, []);

    useEffect(() => {
        if (currentCase) {
            dispatch(caseService.getCaseForAssessment(currentCaseUuid));
            getCaseQuestionDefinition(CaseQuestionDefinitionType.MEDICAL, currentCase.organisationUuid).then(
                (response) => {
                    setMedicalQuestionDefinition(response);
                }
            );
        }
    }, [currentCaseUuid]);

    const addLesion = (): void => {
        dispatch(assessmentService.updateLesionNumberAction(currentLesion + 1));
        history.push(REMOTE_MODEL_CASES_LESION_DETAILS);
    };

    const submitCase = () => {
        assessmentService.finishAssessment(currentCase.uuid, true).then(() => {
            history.push(REMOTE_MODEL_CASES_IN_REVIEW);
        });
    };

    const handleSubmitCaseClick = (): void => {
        const canAddNewLesion: boolean = currentLesion + 1 < MAX_ALLOWED_LESIONS_PER_CASE;
        setShowModal(canAddNewLesion);
        if (!canAddNewLesion) {
            submitCase();
        }
    };

    if (!lesions) {
        return <CaseStatusContainer activeStatus={RemoteTimeline.SUMMARY} />;
    }

    const wasCasePreviouslySubmitted: boolean = lesions.some((lesion) => lesion.images?.some((image) => image.retake));
    const possibleAddNewLesion: boolean = MAX_ALLOWED_LESIONS_PER_CASE > currentLesion + 1;

    return (
        <>
            <ConfirmCaseSubmissionDialog
                showModal={showModal}
                closeModal={() => setShowModal(false)}
                submitCase={submitCase}
            />
            <CaseStatusContainer activeStatus={RemoteTimeline.SUMMARY} />

            {!hasImagesToRetake && (
                <RemoteModelContainer header="Case Summary">
                    <Header as="h5">
                        Please review the lesion images and your medical history before submitting the case for review.
                    </Header>
                </RemoteModelContainer>
            )}

            <RemoteModelContainer header={`Lesion${multiplyEnding(currentLesion)} Overview`}>
                <div className="remote-model-summary-container">
                    {hasImagesToRetake && <RetakeImagesWarning lesions={lesions} />}

                    {lesions.map((lesion: ILesion, index: number) => (
                        <LesionOverview
                            key={lesion.uuid}
                            lesion={lesion}
                            index={index + 1}
                            editable={!wasCasePreviouslySubmitted}
                        />
                    ))}

                    <AddNewLesionMessage
                        wasCasePreviouslySubmitted={wasCasePreviouslySubmitted}
                        possibleAddNewLesion={possibleAddNewLesion}
                    />
                    {!hasImagesToRetake && !hasRetakenImages && (
                        <div className="buttons-wrapper">
                            <Button
                                disabled={!possibleAddNewLesion}
                                onClick={addLesion}
                                color="secondary"
                                variant="contained"
                            >
                                {ADD_ANOTHER_LESION_BUTTON_COPY}
                            </Button>
                        </div>
                    )}
                </div>
            </RemoteModelContainer>

            {medicalHistory && !hasImagesToRetake && (
                <RemoteModelContainer header="Medical History">
                    <div className="remote-model-summary-container">
                        <QuestionsSection
                            questionsDefinition={medicalQuestionDefinition}
                            questions={medicalHistory}
                            assessmentType={AssessmentTypes.remoteModel}
                        />

                        <EditLink link={REMOTE_MODEL_CASES_MEDICAL_HISTORY} text="Edit Medical History" />
                    </div>
                </RemoteModelContainer>
            )}

            <Container>
                <AddNewLesionMessage
                    wasCasePreviouslySubmitted={wasCasePreviouslySubmitted}
                    possibleAddNewLesion={possibleAddNewLesion}
                />
                <div className="buttons-wrapper buttons-wrapper__stacked">
                    <div className="buttons-wrapper__stacked">
                        {!hasImagesToRetake && !hasRetakenImages && (
                            <Button
                                disabled={!possibleAddNewLesion}
                                onClick={addLesion}
                                color="secondary"
                                variant="contained"
                            >
                                {ADD_ANOTHER_LESION_BUTTON_COPY}
                            </Button>
                        )}
                        <Button onClick={handleSubmitCaseClick} color="primary" variant="contained">
                            Submit case
                        </Button>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default CaseSummary;
