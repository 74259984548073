import React, { FC, useState, useContext, useEffect } from "react";
import { Button, Message } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import CallbackRequestedModal from "components/RemoteModel/modals/CallbackRequestedModal";

import { ReportAvailableContext } from "contextProviders/ReportAvailableProvider";
import { ReportAvailableActions } from "contextProviders/modules/reportAvailable/actions";

import * as caseService from "services/caseService";

const MODAL_VISIBLE_TIME = 5000;

interface IRequestCallback {
    readonly caseUuid: string;
    readonly isCallbackNeeded: boolean;
}

const RequestCallback: FC<IRequestCallback> = ({ caseUuid, isCallbackNeeded }) => {
    const {
        state: { isAwaitingCallback },
        dispatch: dispatchReportAvailable,
    } = useContext(ReportAvailableContext);
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (isCallbackNeeded) {
            dispatchReportAvailable({
                type: ReportAvailableActions.SET_AWAITING_CALLBACK,
            });
        }
    }, [isCallbackNeeded]);

    const [showModal, setShowModal] = useState(false);

    const handleCallbackClicked = async () => {
        try {
            await caseService.requestCallback(caseUuid);
            dispatchReportAvailable({
                type: ReportAvailableActions.SET_AWAITING_CALLBACK,
            });
            setShowModal(true);
            setTimeout(() => {
                setShowModal(false);
            }, MODAL_VISIBLE_TIME);
        } catch {
            setError("Something went wrong, please try again later.");
        }
    };

    const isError = Boolean(error);

    return (
        <>
            <p>
                If you would like further clarification on your case, please click the button below to have one of our
                GPs contact you directly.
            </p>

            {isAwaitingCallback ? (
                <>
                    <div className="buttons-wrapper">
                        <Button disabled>Callback requested</Button>
                    </div>
                    <p>
                        Your request for a callback has been recorded.
                        <b> One of our clinicians will contact you within one working day.</b>
                    </p>
                </>
            ) : (
                <div className="buttons-wrapper">
                    <CustomButton
                        type="button"
                        variant="filled"
                        text="Request callback"
                        action={handleCallbackClicked}
                    />
                </div>
            )}
            {isError && <Message negative>{error}</Message>}
            <CallbackRequestedModal show={showModal} />
        </>
    );
};

export default RequestCallback;
