import React, { FC, useState, useContext, SyntheticEvent } from "react";
import { useSelector } from "react-redux";
import { Message, Modal, Form, InputOnChangeData, DropdownProps } from "semantic-ui-react";

import useReviewForm from "components/CaseDescription/Review/hooks/useReviewForm";
import CustomButton from "components/templates/Button";
import LesionReviewCheckbox from "components/CaseDescription/Review/LesionReviewCheckbox";
import useCaseNote from "components/CaseDescription/hooks/useCaseNote";

import { ModalContext } from "contextProviders/ModalProvider";

import { isReviewManagementUrgent, mapToOptions } from "helpers/review";
import { onPasteWhitespaceRemover } from "helpers/field";

import { ICase, IReview } from "model/case";
import { CallbackOutcomesValues } from "model/remoteModel";
import { IApiResponse } from "model/request";
import { ICreateReviewResponse, NoteType } from "model/reviewNotes";
import { IOrganisation } from "model/organisation";
import { ReviewType } from "model/reviewType";

import { getOrganisation } from "redux/selectors/data";

interface IChangeManagementOutcomeModal {
    currentCase: ICase;
    addCallbackOutcome: (outcome: CallbackOutcomesValues) => void;
    submitCallbackOutcome: () => Promise<IApiResponse | null>;
    updateCaseNotes?: (type: NoteType, note: string) => void;
    updateCaseReview: (reviewFormData: ICreateReviewResponse) => void;
}

interface IError {
    message: string;
}

const ChangeManagementOutcomeModal: FC<IChangeManagementOutcomeModal> = ({
    currentCase,
    addCallbackOutcome,
    submitCallbackOutcome,
    updateCaseNotes,
    updateCaseReview,
}) => {
    const { uuid, patientUuid, reviews } = currentCase;
    const caseNoteCommonProps = {
        caseUuid: uuid,
        patientUuid,
        updateCaseNotes,
    };

    const { closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<IError[]>([]);
    const {
        caseNote: clinicalNote,
        handleCaseNoteChange: handleClinicalNoteChange,
        submitCaseNote: submitClinicalNote,
    } = useCaseNote({
        ...caseNoteCommonProps,
        noteType: NoteType.CLINICAL,
    });
    const {
        caseNote: patientNote,
        handleCaseNoteChange: handlePatientNoteChange,
        submitCaseNote: submitPatientNote,
    } = useCaseNote({
        ...caseNoteCommonProps,
        noteType: NoteType.PATIENT,
    });

    const organisation: IOrganisation = useSelector(getOrganisation);
    const { formData, setFormData, submitReview, validateValues } = useReviewForm({
        currentCase,
        isCaseManagementOutcome: true,
    });

    const { reviewDiagnoses, reviewManagementOptions } = organisation;
    const { caseManagementOutcome, caseManagementOutcomeFreeText } = formData;
    const { nonSkinCancer } = currentCase;

    const handleSubmitClicked = async (): Promise<void> => {
        try {
            setIsLoading(true);
            await submitPatientNote();
            await submitCallbackOutcome();
            await submitClinicalNote();
            const reviewResult = await submitReview({ reviewDiagnoses, reviewManagementOptions });
            setIsLoading(false);
            addCallbackOutcome(CallbackOutcomesValues.CHANGE_MANAGEMENT_OUTCOME);
            updateCaseReview(reviewResult);
            closeModal();
        } catch (err: any) {
            const errorDetails = err.response
                ? err.response.data.errors || [{ message: "Something went wrong" }]
                : [{ message: err.message }];
            setIsLoading(false);
            setErrors(errorDetails);
        }
    };

    const handleCaseManagementOutcomeChange = (
        event: SyntheticEvent<HTMLElement, Event> | undefined,
        obj: InputOnChangeData | DropdownProps
    ) => {
        const { value: newValue, id: propertyName } = obj;
        const updatedFormData = { ...formData, [propertyName]: newValue };
        const isValid = validateValues(updatedFormData, nonSkinCancer);
        setFormData({ ...updatedFormData, isValid });
    };

    const getPossibleManagementOutcomeOptions = () => {
        const latestReview: IReview = reviews[reviews.length - 1];
        const { refer, reviewManagement } = latestReview;
        const isRoutineRefer = refer && !isReviewManagementUrgent(reviewManagement);
        const managementOptions = reviewManagementOptions.filter(
            ({ removed, reviewType, refer: referOption, management }) =>
                !removed &&
                reviewType === ReviewType.SAFETY_NET &&
                referOption &&
                (isRoutineRefer ? isReviewManagementUrgent(management) : true)
        );
        const managementOutcomeOptions = mapToOptions(managementOptions, "uuid", "management");
        return managementOutcomeOptions;
    };

    const isError = Boolean(errors.length);
    const isCaseManagementOutcomeFilled = formData.isValid;
    const isClinicalNoteFilled = Boolean(clinicalNote);
    const isPatientNoteFilled = Boolean(patientNote);
    const isSubmitButtonDisabled = !isCaseManagementOutcomeFilled || !isClinicalNoteFilled || !isPatientNoteFilled;
    const caseManagementOutcomeOptions = getPossibleManagementOutcomeOptions();
    const currentManagementOutcomeFreeText = caseManagementOutcome
        ? reviewManagementOptions.find((option) => option.uuid === caseManagementOutcome)?.freeText
        : false;
    const currentManagementOutcomeHasFreeText = Boolean(currentManagementOutcomeFreeText);

    return (
        <>
            <Modal.Header>Change management outcome</Modal.Header>
            <Modal.Content>
                Are you sure you want to change the management outcome? The new outcome selected will overrule the
                current management outcome.
                <Form>
                    <LesionReviewCheckbox
                        label="Case management outcome"
                        id="caseManagementOutcome"
                        options={caseManagementOutcomeOptions}
                        onFieldChange={handleCaseManagementOutcomeChange}
                        value={caseManagementOutcome}
                        freeTextValue={caseManagementOutcomeFreeText}
                        withFreeText={currentManagementOutcomeHasFreeText}
                    />
                    <Form.TextArea
                        label="Notes for Clinical use"
                        placeholder="Enter note here..."
                        onChange={handleClinicalNoteChange}
                        onPaste={onPasteWhitespaceRemover(handleClinicalNoteChange, true)}
                        value={clinicalNote}
                        required
                    />
                    <Form.TextArea
                        label="Notes for the Patient"
                        placeholder="Enter note here..."
                        onChange={handlePatientNoteChange}
                        onPaste={onPasteWhitespaceRemover(handlePatientNoteChange, true)}
                        value={patientNote}
                        required
                    />
                </Form>
                {isError &&
                    errors.map((error) => (
                        <Message key={error.message} negative>
                            <p>{error.message}</p>
                        </Message>
                    ))}
                <div className="buttons-row-wrapper case-callback">
                    <CustomButton variant="dark" type="button" text="Back" action={closeModal} />
                    <CustomButton
                        variant="filled"
                        type="button"
                        text="Submit outcome"
                        action={handleSubmitClicked}
                        loading={isLoading}
                        disabled={isSubmitButtonDisabled}
                    />
                </div>
            </Modal.Content>
        </>
    );
};

ChangeManagementOutcomeModal.defaultProps = {
    updateCaseNotes: () => undefined,
};

export default ChangeManagementOutcomeModal;
