import { FC } from "react";
import { Grid, Message } from "semantic-ui-react";

import { IFormError } from "model/formError";

interface IAllocationError {
    error: Partial<IFormError>[];
}

const AllocationError: FC<IAllocationError> = ({ error }) => {
    if (!error) {
        return null;
    }

    return (
        <>
            <Grid.Column mobile={16} tablet={16} computer={14} style={{ paddingBottom: "8px" }}>
                {error.map((detail: any) => (
                    <Message key={detail.message} negative>
                        <p>{detail.message}</p>
                    </Message>
                ))}
            </Grid.Column>
        </>
    );
};

export default AllocationError;
