import { FC } from "react";
import { Divider, Grid, Sticky } from "semantic-ui-react";

import "scss/OrganisationDetails.scss";

interface IStickyOrganisationTopPanelProps {
    title: string;
    children: any;
    withDescription?: boolean;
}

const StickyOrganisationTopPanel: FC<IStickyOrganisationTopPanelProps> = ({ children, title, withDescription }) => (
    <Sticky offset={73}>
        <div className="organisation-sticky-area">
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column className="organisation-management-tab-title">
                        <h3>{title}</h3>
                    </Grid.Column>
                    <Grid.Column floated="right" className="organisation-management-tab-content">
                        {children}
                    </Grid.Column>
                </Grid.Row>
                {withDescription && (
                    <Grid.Row className="organisation-management-tab-description">
                        <p>
                            These settings are separate from the parent organisation. If you want to copy the parent
                            settings you can do so by pressing the button.
                        </p>
                    </Grid.Row>
                )}
            </Grid>
        </div>
        <Divider />
    </Sticky>
);

StickyOrganisationTopPanel.defaultProps = {
    withDescription: false,
};

export default StickyOrganisationTopPanel;
