export enum TemplateType {
    FINISH_ASSESSMENT_POPUP = "FINISH_ASSESSMENT_POPUP",
    CLOSE_CASE_CONFIRMATION_POPUP = "CLOSE_CASE_CONFIRMATION_POPUP",
    ABANDON_CASE_CONFIRMATION_POPUP = "ABANDON_CASE_CONFIRMATION_POPUP",
    IMMEDIATE_RESULT_TEXT = "IMMEDIATE_RESULT_TEXT",
    WHAT_HAPPENS_NEXT_REMOTE_MODEL = "WHAT_HAPPENS_NEXT_REMOTE_MODEL",
    ADMINISTRATIVE_NEXT_STEPS = "ADMINISTRATIVE_NEXT_STEPS",
}

export enum WhatHappensNextTemplateKeys {
    DISCHARGE_TEXT = "discharge_text",
    REFERRAL_TEXT = "referral_text",
}

export const TemplateSchema = {
    [TemplateType.ABANDON_CASE_CONFIRMATION_POPUP]: ["title", "message"],
    [TemplateType.CLOSE_CASE_CONFIRMATION_POPUP]: ["title", "message"],
    [TemplateType.FINISH_ASSESSMENT_POPUP]: ["title", "message"],
    [TemplateType.IMMEDIATE_RESULT_TEXT]: ["case_in_review_text", "case_completed_text"],
    [TemplateType.WHAT_HAPPENS_NEXT_REMOTE_MODEL]: [
        WhatHappensNextTemplateKeys.DISCHARGE_TEXT,
        WhatHappensNextTemplateKeys.REFERRAL_TEXT,
    ],
};
