import React from "react";
import { Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import "scss/Dialog.scss";

interface IImageManualUploadDialog {
    showDialog: boolean;
    callback: (isOpenPopup: boolean) => void;
    onContextImageClick: () => void;
    onDermoscopicImageClick: () => void;
    remoteFlowManualUploadNextAction?: () => void;
    disableBackdropClick?: boolean;
    dissableUploadDermoscopic?: boolean;
    isManualUpdateRemoteMode?: boolean;
    onFinishUpload?: () => void;
}

const ImageManualUploadDialog = (props: IImageManualUploadDialog) => {
    const {
        showDialog,
        callback,
        onFinishUpload,
        onContextImageClick,
        onDermoscopicImageClick,
        disableBackdropClick,
        dissableUploadDermoscopic,
        isManualUpdateRemoteMode,
        remoteFlowManualUploadNextAction,
    } = props;

    const title = "Manual Upload";
    const bodyText = `Context image(s) successfully uploaded. You can now upload more Context images ${
        dissableUploadDermoscopic ? "" : "or proceed to upload a Dermoscopic image"
    }. Use the buttons below to select.`;
    const remoteManualUploadBodyText = "Image uploaded successfully.";
    const nextButtonText =
        dissableUploadDermoscopic || isManualUpdateRemoteMode
            ? `Next ${isManualUpdateRemoteMode && "Image"}`
            : "UPLOAD A DERMOSCOPIC IMAGE";

    const onDiscard = () => {
        onContextImageClick();
    };

    const onConfirm = () => {
        if (isManualUpdateRemoteMode) {
            remoteFlowManualUploadNextAction();
        } else {
            onDermoscopicImageClick();
        }
    };

    const onFinishNonSkinCancer = () => {
        onFinishUpload();
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>{isManualUpdateRemoteMode ? remoteManualUploadBodyText : bodyText}</Grid.Column>
            </Grid.Row>
        </Grid>
    );

    const noButtonAction = isManualUpdateRemoteMode
        ? undefined
        : {
              border: "#7cd8d1",
              backgroundColor: "#fff",
              fontColor: "#7cd8d1",
              onClick: onDiscard,
              text: "UPLOAD ANOTHER CONTEXT IMAGE",
          };

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                callback={callback}
                disableBackdropClick={disableBackdropClick}
                centerActionButtons={!noButtonAction}
                noButton={noButtonAction}
                yesButton={{
                    border: "#7cd8d1",
                    backgroundColor: "#7cd8d1",
                    fontColor: "#000",
                    onClick: dissableUploadDermoscopic ? onFinishNonSkinCancer : onConfirm,
                    text: nextButtonText,
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

ImageManualUploadDialog.defaultProps = {
    disableBackdropClick: false,
    dissableUploadDermoscopic: false,
    isManualUpdateRemoteMode: false,
    onFinishUpload: () => undefined,
    remoteFlowManualUploadNextAction: () => undefined,
};

export default ImageManualUploadDialog;
