import { FC } from "react";
import { Divider, Header } from "semantic-ui-react";

import LesionAIAssessment from "components/CaseDescription/Lesions/LesionAIAssessment";
import LesionImages from "components/templates/LesionImages";
import LesionReviewerAssessment from "components/CaseDescription/Lesions/LesionReviewerAssessment";
import LesionQuestions from "components/CaseDescription/Questions/LesionQuestions";

import { formatTimeWithHours } from "helpers/datetime";

import { ICase, ILesion, ISkinToneAnswer } from "model/case";
import { CaseViewMode } from "model/caseViewMode";
import { AllLesionLocation } from "model/lesionLocation";
import { CaseStatus } from "model/caseStatus";
import { COMPLETED_STATUSES } from "model/remoteModel";

interface ILesionsAssessmentProps {
    lesion: ILesion;
    lesionNumber: number;
    caseObject: ICase;
    mode: CaseViewMode;
    updateSkinToneClassification: (result: ISkinToneAnswer[]) => void;
}

const LesionAssesment: FC<ILesionsAssessmentProps> = ({
    lesionNumber,
    lesion,
    mode,
    caseObject,
    updateSkinToneClassification,
}) => {
    const { organisationName, nonSkinCancer, remote, caseStatus } = caseObject;

    const isImage = lesion.images?.length;
    const lesionPaddingStyle = lesionNumber !== 1 ? "lesion-top-padding" : "";
    const creators = [...new Set(lesion.images?.map((image) => image.createdByName))].join(", ");
    const creatorsOrganisations = [...new Set(lesion.images?.map((image) => image.creatorOrganisationName))].join(", ");
    const lesionImageCaptureDetails = isImage
        ? `Captured by ${creators} from ${creatorsOrganisations} on ${formatTimeWithHours(
              lesion.images[lesion.images.length - 1].creationDate
          )}`
        : "No image recorded";
    const lesionQuetionsCaptureDetails = `Captured by ${
        lesion.createdByName
    } from ${organisationName} on ${formatTimeWithHours(lesion.creationDate)}`;
    const isNonSkinCancer = Boolean(nonSkinCancer);
    const isRemoteModel = Boolean(remote);
    const showAIAssessment = !isNonSkinCancer && !isRemoteModel;
    const lesionText = isNonSkinCancer ? "" : `Lesion ${lesionNumber}.`;
    const isCaseCompleted: boolean = [...COMPLETED_STATUSES, CaseStatus.CASE_ABANDONED].includes(caseStatus);

    return (
        <div className="tab-div">
            <Header className={`without-bottom-margin ${lesionPaddingStyle}`} as="h3">{`${lesionText} ${
                AllLesionLocation[lesion.bodyPart]
            } `}</Header>
            <p className="subtitle-gray-text">{lesion.description}</p>
            <Divider className="title-divider" />
            <div className="tab-div">
                <LesionReviewerAssessment lesion={lesion} />
                {showAIAssessment && <LesionAIAssessment lesion={lesion} mode={mode} />}

                <Header as="h4" className="without-bottom-margin">{`${lesionText} Images`}</Header>
                <p className="subtitle-gray-text assessed-by-text">{lesionImageCaptureDetails}</p>
                <Divider className="title-divider" />
                {isImage && (
                    <LesionImages
                        lesion={lesion}
                        lesionNumber={lesionNumber}
                        caseObject={caseObject}
                        isCaseCompleted={isCaseCompleted}
                        updateSkinToneClassification={updateSkinToneClassification}
                    />
                )}

                <Header as="h4" className="without-bottom-margin">{`${lesionText} History`}</Header>
                <p className="subtitle-gray-text assessed-by-text">{lesionQuetionsCaptureDetails}</p>
                <Divider className="title-divider" />
                <LesionQuestions
                    key={lesion.uuid}
                    lesion={lesion}
                    lesionNumber={lesionNumber}
                    organisationUuid={caseObject.organisationUuid}
                    isNonSkinCancer={isNonSkinCancer}
                />
            </div>
        </div>
    );
};

export default LesionAssesment;
