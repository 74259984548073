import React, { Component } from "react";
import { Link } from "react-router-dom";

import Question from "components/CaseDescription/Questions/Question";
import SignificantQuestions from "components/CaseDescription/Questions/SignificantQuestions";

import { matchAndSortQuestionsAndDefinition } from "helpers/questionDefinition";

import { AssessmentTypes, IMedicalHistory } from "model/assessment";
import { IMedicalHistoryQuestion } from "model/organisation";
import QuestionSubTypeEnum from "model/questionSubType";

interface IMedicalQuestions {
    questions: IMedicalHistory[];
    questionsDefinition: IMedicalHistoryQuestion[];
    assessmentType?: AssessmentTypes;
}

interface IState {
    showAll: boolean;
}

class MedicalQuestions extends Component<IMedicalQuestions, IState> {
    constructor(props: IMedicalQuestions) {
        super(props);
        const isRemoteModel = props.assessmentType === AssessmentTypes.remoteModel;
        this.state = {
            showAll: isRemoteModel,
        };
    }

    private onShowHide = () => {
        const { showAll } = this.state;
        this.setState({ showAll: !showAll });
    };

    public render() {
        const { questions, questionsDefinition, assessmentType } = this.props;
        const { showAll } = this.state;
        const isRemoteModel = assessmentType === AssessmentTypes.remoteModel;

        if (!questionsDefinition.length) {
            return null;
        }

        const questionsWithDefinition = matchAndSortQuestionsAndDefinition(questions, questionsDefinition).filter(
            (qd) => qd.questionDefinition.questionSubType !== QuestionSubTypeEnum.SKIN_TYPE
        );

        return (
            <div className="tab-div">
                {!isRemoteModel && (
                    <>
                        <SignificantQuestions questions={questions} questionsDefinition={questionsDefinition} />
                        <h5>
                            All answers:
                            <Link to="#/" onClick={this.onShowHide}>
                                {showAll ? " hide" : " show"}
                            </Link>
                        </h5>
                    </>
                )}
                {showAll &&
                    questionsWithDefinition.map((questionWithDefinition) => {
                        if (questionWithDefinition.question) {
                            return (
                                <Question
                                    key={questionWithDefinition.question.uuid}
                                    question={questionWithDefinition.question}
                                    questionDefinition={questionWithDefinition.questionDefinition}
                                    isRemoteModelSummaryPage={isRemoteModel}
                                />
                            );
                        }
                        return null;
                    })}
            </div>
        );
    }
}

export default MedicalQuestions;
