import React from "react";
import { Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import "scss/Dialog.scss";

interface IDeleteDeviceDialog {
    showDialog: boolean;
    callback: (isOpenPopup: boolean) => void;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteDeviceDialog = (props: IDeleteDeviceDialog) => {
    const { showDialog, callback, onClose, onDelete } = props;

    const title = "Delete device";
    const bodyText = "Do you confirm your action?";

    const onConfirm = () => {
        onDelete();
    };

    const onCancel = () => {
        onClose();
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>{bodyText}</Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                callback={callback}
                yesButton={{
                    backgroundColor: "#2185D0",
                    fontColor: "#FFFFFF",
                    onClick: onConfirm,
                    text: "OK",
                }}
                noButton={{
                    backgroundColor: "#db2828",
                    fontColor: "#FFFFFF",
                    text: "Cancel",
                    onClick: onCancel,
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default DeleteDeviceDialog;
