import React from "react";
import { Grid } from "semantic-ui-react";

import useAutoLogout from "hooks/useAutoLogout";

import { ICase } from "model/case";

import * as ROUTES from "navigation/routes";

import ModalDialog from "./ModalDialog";

import "scss/Dialog.scss";

interface IEditPatientInfoDialog {
    showDialog: boolean;
    callback: (arg: boolean) => void;
    caseObject: ICase;
}

const EditPatientInfoDialog = (props: IEditPatientInfoDialog) => {
    useAutoLogout();
    const { showDialog, callback, caseObject } = props;

    const onConfirm = () => {
        const path = `${ROUTES.EDIT_PATIENT_DETAILS}/${caseObject.uuid}`;
        window.location.href = path;
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    <p>
                        The changes you make will be visible to any clinician involved in the assessment. Do you want to
                        continue?
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <ModalDialog
                title="You are about to edit patient details"
                open={showDialog}
                iconSrc="/images/icons/icon-warning-yellow-bg.svg"
                onClose={callback}
                isWarning
                buttons={[
                    {
                        onClick: onConfirm,
                        text: "Continue",
                        colour: "primary",
                    },
                    {
                        onClick: () => callback(false),
                        text: "Cancel",
                        colour: "secondary",
                    },
                ]}
            >
                {popupBody}
            </ModalDialog>
        )) ||
        null
    );
};

export default EditPatientInfoDialog;
