import { createActions } from "redux-actions";

import types from "redux/actions/types";

export const {
    userCreation,
    userCreationSuccess,
    userClearData,
    userPersonalDataSuccess,
    userSetEmail,
    setOrgUuid,
    setOrgFlowType,
} = createActions(
    types.USER_CREATION,
    types.USER_CREATION_SUCCESS,
    types.USER_CLEAR_DATA,
    types.USER_PERSONAL_DATA_SUCCESS,
    types.USER_SET_EMAIL,
    types.SET_ORG_UUID,
    types.SET_ORG_FLOW_TYPE
);
