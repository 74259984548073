import React, { Component } from "react";
import { Container, Divider, Grid } from "semantic-ui-react";

import parseDermError from "helpers/error";

import { ICase } from "model/case";
import { LesionClassification } from "model/lesionClassification";
import ImageClassification from "model/imageClassification";

interface IAiDiagnosisTable {
    case: ICase;
    lesionNumber: number;
}

class AiDiagnosisTable extends Component<IAiDiagnosisTable> {
    private getClassification(lesionNumber: number) {
        const { case: caseObject } = this.props;
        const { result } = caseObject.lesions[lesionNumber];
        if (result && result.error) {
            return (
                <Grid.Row>
                    <Grid.Column>
                        <h3>
                            {" "}
                            <b>Error Details: </b>{" "}
                        </h3>
                    </Grid.Column>
                    <Grid.Column>{parseDermError(result.error)}</Grid.Column>
                </Grid.Row>
            );
        }
        if (result) {
            return (
                <Grid.Row>
                    <Grid.Column>
                        <h3>
                            {" "}
                            <b>Classification: </b>{" "}
                        </h3>
                    </Grid.Column>
                    <Grid.Column>{this.getLesionDetails(lesionNumber)}</Grid.Column>
                </Grid.Row>
            );
        }
        return undefined;
    }

    private getLesionResultObject(lesionNumber: number) {
        const { case: caseObject } = this.props;
        if (caseObject.lesions[lesionNumber]) {
            return caseObject.lesions[lesionNumber].result;
        }
        return undefined;
    }

    private getLesionResultText(lesionNumber: number) {
        const lesionObject = this.getLesionResultObject(lesionNumber);
        if (lesionObject) {
            if (lesionObject.analysisResult) {
                return ImageClassification[lesionObject.analysisResult];
            }
            return "Not assessed by DERM";
        }
        return "Waiting for results";
    }

    private getLesionDetails(lesionNumber: number) {
        const lesionObject = this.getLesionResultObject(lesionNumber);
        if (lesionObject) {
            const lesionElements: any = [];
            if (lesionObject.analysisResult === "PROCESSING_ANSWER_TYPE_SUCCESSFUL_PROCESSING") {
                lesionObject.lesions.forEach((item) => {
                    const lesionElement = (
                        <div key={item.classification}>
                            <p>
                                <b>Detected Lesion:</b> {LesionClassification[item.classification]}
                            </p>
                            <p>
                                <b>Suspected Diagnosis:</b> {item.suspectedDiagnosis}
                            </p>
                            <p>
                                <b>Referral Recommendation Guidance:</b> {item.referralRecommendationGuidance.plainText}
                            </p>
                            <Divider />
                        </div>
                    );
                    lesionElements.push(lesionElement);
                });
                return (
                    <div style={{ marginTop: "10px" }}>
                        <div style={{ marginLeft: "10px" }}>{lesionElements}</div>
                    </div>
                );
            }
            return (
                <>
                    <h4>No more details for this kind of diagnosis</h4>
                </>
            );
        }
        return "Waiting for results";
    }

    public render() {
        const { lesionNumber, case: caseObject } = this.props;
        const isResult = caseObject && caseObject.caseStatus && caseObject.lesions[lesionNumber];
        return (
            <Container>
                <Grid.Row style={{ marginBottom: "10px" }}>
                    <Grid.Column>
                        <h3>
                            {" "}
                            <b>Processing result: </b>{" "}
                        </h3>
                    </Grid.Column>
                    <Grid.Column>
                        <p>{(isResult && this.getLesionResultText(lesionNumber)) || "Waiting for results"}</p>
                    </Grid.Column>
                </Grid.Row>
                {isResult && this.getClassification(lesionNumber)}
            </Container>
        );
    }
}

export default AiDiagnosisTable;
