import { FC } from "react";
import { Label } from "semantic-ui-react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";

import { formatTimeWithHoursWithMinutes } from "helpers/datetime";

import { IIntegration } from "model/assessment";
import PatientDetailsIntegration from "model/integrations";

interface IPatientCreatedFromIntegrationLabel {
    integrationData: IIntegration;
}

const PatientCreatedFromIntegrationLabel: FC<IPatientCreatedFromIntegrationLabel> = ({ integrationData }) => {
    const { lastSychronisationDate, integrationName } = integrationData;
    const isPDSIntegrationPatient = integrationName === PatientDetailsIntegration.PDS;
    const date = formatTimeWithHoursWithMinutes(lastSychronisationDate);

    return (
        <p className="patient-from-integration__wrapper">
            <Label className="patient-from-integration__name">
                {isPDSIntegrationPatient ? (
                    <div>
                        <span>NHS Verified</span>{" "}
                        <Tooltip title="The personal details of this patient have been verified in NHS database">
                            <InfoOutlinedIcon fontSize="small" />
                        </Tooltip>
                    </div>
                ) : (
                    "UHB PAS"
                )}
            </Label>
            {!isPDSIntegrationPatient ? (
                <span className="patient-from-integration__sync-date">Last updated: {date}</span>
            ) : null}
        </p>
    );
};

export default PatientCreatedFromIntegrationLabel;
