import { FocusEvent, KeyboardEvent } from "react";

const preventWheelEvent = (event: FocusEvent<HTMLInputElement>): void =>
    event.target.addEventListener("wheel", (e) => e.preventDefault(), { passive: false });

export const handleKeyDown = (e: KeyboardEvent, handleFunction: () => void): void => {
    if (e.key === "Enter") {
        handleFunction();
    }
};

export default preventWheelEvent;
