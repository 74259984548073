import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Segment } from "semantic-ui-react";

import OrganisationTable, {
    IOrganisationTableMode,
} from "components/Administration/OrganisationManagment/OrganisationTable";
import ConditionalRender from "components/templates/ConditionalRender";

import { AdministrationPages, OrganisationManagementSubpages } from "model/administrationPages";
import { UserRole } from "model/userRole";

import { ADMINISTRATION } from "navigation/routes";

const OrganisationList: FC = () => (
    <ConditionalRender requiredRole={[UserRole.SUPERADMIN]}>
        <Segment>
            <h2>Organisation List</h2>
            <Grid columns={4}>
                <Grid.Row>
                    <Grid.Column width={3} floated="right">
                        <Link
                            to={`${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}/${OrganisationManagementSubpages.CREATE_ORGANISATION}`}
                        >
                            <Button>Create new organisation</Button>
                        </Link>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <OrganisationTable mode={IOrganisationTableMode.ORGANISATION_LIST} />
        </Segment>
    </ConditionalRender>
);

export default OrganisationList;
