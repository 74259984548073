import React from "react";
import { Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import "scss/Dialog.scss";

interface ICaseReportCSVDialog {
    showDialog: boolean;
    callback: (arg: boolean) => void;
    onClose: () => void;
}

const CaseReportCSVDialog = (props: ICaseReportCSVDialog) => {
    const { onClose, showDialog, callback } = props;

    const title = "Data export";
    const bodyText =
        "The system is processing your .csv export request. It might take a few minutes. You will receive an email with a download link in your inbox as soon as the report is ready.";

    const onConfirm = () => {
        onClose();
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>{bodyText}</Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                callback={callback}
                yesButton={{
                    backgroundColor: "#2185D0",
                    fontColor: "#3f3939",
                    onClick: onConfirm,
                    text: "OK",
                }}
                centerActionButtons
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default CaseReportCSVDialog;
