import { connect } from "react-redux";

import ImageCapture from "components/StudyImageCapture/StudyImageCapture";

import captureImageWizardService from "services/captureImageWizardService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    authToken: state.auth.authToken,
    organisation: state.data.organisation,
    refreshToken: state.auth.refreshToken,
    wizard: state.assessment.captureImageWizard,
});

const mapDispatchToProps = {
    setNextWizardStep: captureImageWizardService.setNextWizardStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCapture);
