import ReviewSettingsData from "model/reviewSettingsData";
import { ReviewSettingMode } from "model/reviewType";

import ReviewSettingsAction, { ReviewSettingsActionTypes } from "contextProviders/modules/reviewSettings/actions";

export default (state: ReviewSettingsData, action: ReviewSettingsAction) => {
    switch (action.type) {
        case ReviewSettingsActionTypes.SET_LOADED_ORGANISATION: {
            return {
                ...state,
                organisation: action.payload.organisation,
                reviewDiagnoses: action.payload.reviewDiagnoses,
                reviewManagementOptions: action.payload.reviewManagementOptions,
            };
        }
        case ReviewSettingsActionTypes.CHANGE_REVIEW_SETTING_MODE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ReviewSettingsActionTypes.START_EDIT_DIAGNOSIS: {
            return {
                ...state,
                editDiagnosisUuid: action.payload.diagnosisUuid,
                reviewSettingMode: ReviewSettingMode.EDIT,
            };
        }
        case ReviewSettingsActionTypes.UPDATE_ORGANISATION: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ReviewSettingsActionTypes.UPDATE_REVIEW_DIAGNOSES: {
            return {
                ...state,
                reviewSettingMode: ReviewSettingMode.VIEW,
                reviewDiagnoses: action.payload.reviewDiagnoses,
                organisation: { ...state.organisation, reviewDiagnoses: action.payload.reviewDiagnoses },
                editDiagnosisUuid: undefined,
            };
        }
        case ReviewSettingsActionTypes.UPDATE_REVIEW_MANAGEMENT_OUTCOMES: {
            return {
                ...state,
                reviewManagementOptions: action.payload.reviewManagementOptions,
                organisation: { ...state.organisation, reviewMangementOptions: action.payload.reviewManagementOptions },
            };
        }
        default:
            return state;
    }
};
