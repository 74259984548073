import React, { Component, createRef, SyntheticEvent, FormEvent } from "react";
import {
    Button,
    CheckboxProps,
    Divider,
    DropdownProps,
    Form,
    Grid,
    Message,
    Segment,
    Table,
    InputOnChangeData,
} from "semantic-ui-react";

import NotificationsRow from "components/Administration/OrganisationManagment/ViewOrganisation/Notifications/OrganisationNotificationsRow";
import EmailComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Notifications/EmailComponent";
import RemoveNotificationModal from "components/Administration/OrganisationManagment/ViewOrganisation/Notifications/RemoveNotificationModal";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";
import CustomRichTextEditor from "components/templates/Editor";
import SupportedMarkers from "components/templates/SupportedMarkers";

import { validEmail } from "helpers/common";
import getEmailAddressesFromTarget from "helpers/notifications";

import {
    INotificationConfiguration,
    NotificationChannel,
    NotificationType,
    NotificationTypeText,
    IOrganisation,
    NotificationChannelUpper,
} from "model/organisation";
import { NotificationRole, NotificationRoleDisplay } from "model/userRole";
import { OrganisationManagementTabs } from "model/administrationPages";
import { CaseOverdueStatusDictionary } from "model/caseStatus";

import notificationService from "services/notificationService";

import { markersDefinitionNotifications } from "util/markerFiller";

import "scss/OrganisationDetails.scss";

interface IOrganisationNotificationsComponentProps {
    organisation: IOrganisation;
    error?: any;
    showEditButton: boolean;
}

interface IOrganisationNotificationsComponentState {
    edit: Partial<INotificationConfiguration>;
    editMode: boolean;
    organisationNotification: INotificationConfiguration[];
    responseError: [];
    submitting: boolean;
    errors: { tempEmail?: boolean; isNotUnique?: boolean };
    addOrganisationNotification: boolean;
    tempEmail: string;
    notificationArray: any;
    isVisibleRemoveModal: boolean;
    notificationToRemove?: string;
}

interface IOption {
    key: string;
    value: string;
    text: string;
}

const NOTIFICATION_TYPE = "notificationType";

const INITIAL_EDIT = {
    channel: NotificationChannel.email,
    targets: [],
    caseStatus: null,
    notificationTitle: "",
    notificationContent: "",
};

const INITIAL_ERRORS = { tempEmail: false, isNotUnique: false };

class OrganisationNotificationsComponent extends Component<
    IOrganisationNotificationsComponentProps,
    IOrganisationNotificationsComponentState
> {
    private refCustomRichTextEditor: any;

    constructor(props: any) {
        super(props);
        this.refCustomRichTextEditor = createRef();

        const { organisation } = this.props;
        const { notificationConfiguration } = organisation;

        this.state = {
            addOrganisationNotification: false,
            edit: { ...INITIAL_EDIT },
            editMode: false,
            errors: {},
            organisationNotification: notificationConfiguration?.filter((nf) => !nf.removed) || [],
            responseError: [],
            submitting: false,
            tempEmail: "",
            notificationArray: [],
            isVisibleRemoveModal: false,
        };
    }

    private handleCreateNotificationResponse(response: any, notification: INotificationConfiguration) {
        if (response.errors) {
            this.setState({ submitting: false, responseError: response.errors });
            return;
        }
        const { organisationNotification } = this.state;
        const { organisation } = this.props;

        const newNotification = { ...notification, ...response.data };

        organisationNotification.push(newNotification);

        if (organisation.notificationConfiguration) {
            organisation.notificationConfiguration.push(newNotification);
        } else {
            organisation.notificationConfiguration = [newNotification];
        }

        this.setState({
            addOrganisationNotification: false,
            edit: {
                notificationType: undefined,
            },
            organisationNotification,
            responseError: [],
            submitting: false,
        });
    }

    private handleEditNotificationResponse(response: any, notification: INotificationConfiguration) {
        if (response.errors) {
            this.setState({ submitting: false, responseError: response.errors });
            return;
        }

        const { organisationNotification } = this.state;
        const { organisation } = this.props;

        const index = organisationNotification.findIndex(
            (item: INotificationConfiguration) => item.uuid === notification.uuid
        );

        const editedOrganisationNotification = [
            ...organisationNotification.slice(0, index),
            notification,
            ...organisationNotification.slice(index + 1),
        ];

        organisation.notificationConfiguration = [...editedOrganisationNotification];

        this.setState({
            addOrganisationNotification: false,
            edit: {
                notificationType: undefined,
            },
            organisationNotification: editedOrganisationNotification,
            responseError: [],
            submitting: false,
        });
    }

    private onSaveNotification = () => {
        const { edit } = this.state;
        const { organisation } = this.props;

        if (!this.validateSubmit() || !edit.notificationType) {
            return null;
        }

        this.setState({ submitting: true });

        if (!edit.uuid) {
            return notificationService
                .createNotification({
                    organisationUuid: organisation.uuid,
                    notificationType: NotificationType[edit.notificationType],
                    targets: edit.targets,
                    caseStatus: edit.caseStatus,
                    channel: edit.channel,
                    notificationTitle: edit.notificationTitle,
                    notificationContent: edit.notificationContent,
                })
                .then((response) => {
                    this.handleCreateNotificationResponse(response, edit as INotificationConfiguration);
                })
                .catch((err) => {
                    this.setState({ submitting: false, responseError: err.response.data.errors });
                });
        }

        return notificationService
            .editNotification(edit.uuid, {
                notificationType: NotificationType[edit.notificationType],
                targets: edit.targets,
                caseStatus: edit.caseStatus,
                channel: edit.channel,
                notificationTitle: edit.notificationTitle,
                notificationContent: edit.notificationContent,
            })
            .then((response) => {
                this.handleEditNotificationResponse(response, edit as INotificationConfiguration);
            })
            .catch((err) => {
                this.setState({ submitting: false, responseError: err.response?.data?.errors });
            });
    };

    private onSendToCreatorChange = (event: SyntheticEvent<HTMLElement, Event>, obj: DropdownProps | CheckboxProps) => {
        const { edit } = this.state;
        const { checked } = obj;

        const updatedTargets = [
            ...(edit.targets?.filter((target: string) => target !== NotificationRole.CASE_CREATOR) || []),
            ...(checked ? [NotificationRole.CASE_CREATOR] : []),
        ];

        this.setState({
            edit: {
                ...edit,
                targets: updatedTargets,
            },
        });
    };

    private onChange = (
        event: SyntheticEvent<HTMLElement>,
        data: DropdownProps | CheckboxProps | InputOnChangeData
    ) => {
        const { edit } = this.state;

        const fieldName = data.id;
        const fieldValue = data.type === "checkbox" ? data.checked : data.value;
        const stateObj = fieldName === NOTIFICATION_TYPE ? { ...INITIAL_EDIT } : edit;

        stateObj[fieldName] = fieldValue;

        this.setState({
            edit: stateObj,
        });
    };

    private onRichTextChange = (value: string, name: string) => {
        const { edit } = this.state;
        this.setState({ edit: { ...edit, [name]: value } });
    };

    private onRemoveRow = () => {
        const { organisationNotification, notificationToRemove: uuid } = this.state;
        const { organisation } = this.props;

        if (!uuid) return;

        const filteredOrganisationNotifications = organisationNotification.filter((item: any) => item.uuid !== uuid);

        organisation.notificationConfiguration = organisation.notificationConfiguration?.filter(
            (item: any) => item.uuid !== uuid
        );

        notificationService.deleteNotification(uuid).then(() => {
            this.setState({
                organisationNotification: filteredOrganisationNotifications,
            });
            this.closeRemoveModal();
        });
    };

    private static getNotificationStatusAvailableToCreate(
        notificationConfiguration: INotificationConfiguration[],
        enumObject: any
    ) {
        return Object.keys(enumObject);
    }

    private static getTargetUserGroup(notificationConfiguration: INotificationConfiguration[], enumObject: any) {
        const createdTargetUserGroup =
            notificationConfiguration?.map((nc) => {
                const emailsTarget = getEmailAddressesFromTarget(nc.targets || []);
                return nc.targets?.filter((target: string) => !emailsTarget.includes(target));
            }) || [];
        return Object.keys(enumObject).filter((type: any) => !createdTargetUserGroup.includes(type));
    }

    private static getNotificationTypeAvailableToCreate(
        notificationConfiguration: INotificationConfiguration[],
        enumObject: any,
        isEditing?: boolean
    ) {
        const createdNotificationTypes =
            notificationConfiguration
                ?.map((nc) => nc.notificationType)
                .filter((type: string) => type !== NotificationType.caseOverdue) || [];

        return Object.keys(enumObject).filter((type: any) => isEditing || !createdNotificationTypes.includes(type));
    }

    private static getNotificationChannelAvailableToCreate(
        notificationConfiguration: INotificationConfiguration[],
        enumObject: any
    ) {
        const createdNotificationChannels = notificationConfiguration?.map((nc) => nc.channel) || [];
        return Object.keys(enumObject).filter((type: any) => !createdNotificationChannels.includes(type));
    }

    private getOrganisationNotificationSettingsTable() {
        const { organisationNotification } = this.state;

        return (
            <Table padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1}>Category</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Type</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={4}>Target</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Title and content</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Notify case creator</Table.HeaderCell>
                        <Table.HeaderCell width={1} />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.getNotificationsTableBody(organisationNotification)}</Table.Body>
            </Table>
        );
    }

    private handleClearForm = () => {
        this.setState((prevState) => ({
            ...prevState,
            addOrganisationNotification: false,
            edit: { ...INITIAL_EDIT },
        }));
        this.refCustomRichTextEditor.current?.clearInput();
    };

    private getErrorMessages() {
        const { responseError } = this.state;

        return responseError.map((detail: any) => (
            <Message key={detail.message} negative>
                <p>{detail.message}</p>
            </Message>
        ));
    }

    private getNotificationsTableBody(notifications: INotificationConfiguration[]) {
        const { organisation, showEditButton } = this.props;

        if (notifications && notifications.length) {
            const filteredNotifications = notifications.filter(
                (notification: INotificationConfiguration) => !notification.removed
            );
            const handleEdit = (event: any, notification: INotificationConfiguration) => {
                this.refCustomRichTextEditor.current?.setValue(notification.notificationContent);
                this.setState((prevState) => ({
                    ...prevState,
                    addOrganisationNotification: true,
                    edit: { ...notification },
                }));
                this.scrollToNotification(true);
            };

            return filteredNotifications.map((notificationSetting: INotificationConfiguration) => (
                <NotificationsRow
                    key={notificationSetting.uuid}
                    notification={notificationSetting}
                    onRemove={this.showRemoveModal}
                    onEdit={handleEdit}
                    parentUuid={organisation.parentUuid}
                    disabledEditing={showEditButton}
                />
            ));
        }

        return (
            <Table.Row key="none">
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell />
            </Table.Row>
        );
    }

    private changeShowAddOrganisationNotification = () => {
        const { addOrganisationNotification } = this.state;
        this.setState({ addOrganisationNotification: !addOrganisationNotification });

        if (addOrganisationNotification) {
            this.handleClearForm();
        }

        this.scrollToNotification(!addOrganisationNotification);
    };

    private updateOrganisation = (result: any) => {
        const organisationNotification = result.configuration.filter(
            (notification: INotificationConfiguration) => !notification.removed
        );
        this.setState({ organisationNotification });
    };

    private onEmailChange = (event: any, obj: any) => {
        const { errors } = this.state;
        this.setState({
            tempEmail: obj.value,
            errors: { ...errors, ...INITIAL_ERRORS },
        });
    };

    private addEmail = () => {
        const { tempEmail, edit, errors } = this.state;

        const isUnique = !edit.targets?.includes(tempEmail);

        if (validEmail(tempEmail) && isUnique) {
            this.setState({
                edit: { ...edit, targets: [...(edit.targets || []), tempEmail] },
                tempEmail: "",
                errors: { ...errors, ...INITIAL_ERRORS },
            });
        } else {
            this.setState({ errors: { ...errors, isNotUnique: !isUnique, tempEmail: true } });
        }
    };

    private deleteEmail = (email: string) => () => {
        this.setState((prevState) => ({
            edit: {
                ...prevState.edit,
                targets: [...(prevState.edit.targets?.filter((item: string) => item !== email) || [])],
            },
        }));
    };

    private onChangeUserRole = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        const { edit } = this.state;
        const { checked, id } = data;

        const updatedTargets = [
            ...(edit.targets?.filter((role: string) => role !== id) || []),
            ...(checked ? [String(id)] : []),
        ];

        this.setState({
            edit: {
                ...edit,
                targets: updatedTargets,
            },
        });
    };

    private showRemoveModal = (uuid: string) => {
        this.setState({
            isVisibleRemoveModal: true,
            notificationToRemove: uuid,
        });
    };

    private closeRemoveModal = () => {
        this.setState({
            isVisibleRemoveModal: false,
            notificationToRemove: undefined,
        });
    };

    private scrollToNotification = (openedForm: boolean) => {
        const scrollAnchor = document.getElementById("scrollAnchor");

        setTimeout(() => {
            if (openedForm) {
                scrollAnchor.scrollIntoView({ behavior: "smooth", block: "end" });
            }
        }, 200);
    };

    private validateSubmit() {
        const { edit } = this.state;

        if (edit.notificationType && edit.targets?.length) {
            return true;
        }

        return false;
    }

    private addNewOrganisationNotification() {
        const { submitting, edit } = this.state;

        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>{this.addNewNotificationTableBody()}</Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.getErrorMessages()}

                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <div className="notification-buttons">
                                {edit.uuid ? <Button onClick={this.handleClearForm}>Cancel</Button> : null}
                                <Button
                                    loading={submitting}
                                    onClick={this.onSaveNotification}
                                    disabled={
                                        !edit.notificationType ||
                                        (edit.notificationType === NotificationType.caseOverdue &&
                                            !edit.caseStatus?.length) ||
                                        !edit.targets?.length
                                    }
                                >
                                    {edit.uuid ? "Update" : "Create"} organisation notification
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    private addNewNotificationTableBody() {
        const { edit, organisationNotification, tempEmail, errors } = this.state;
        const { notificationType, channel, targets, notificationTitle, notificationContent } = edit;

        const isOverdueType = notificationType === NotificationType.caseOverdue;
        const isReportGeneratedType = notificationType === NotificationType.reportGenerated;
        const notificationChannelEmail = channel === NotificationChannel.email;

        const notificationTypes: IOption[] = OrganisationNotificationsComponent.getNotificationTypeAvailableToCreate(
            organisationNotification,
            NotificationType,
            Boolean(edit.uuid)
        ).map((key) => ({
            key,
            text: NotificationTypeText[key],
            value: key,
        }));

        const notificationStatuses: IOption[] =
            OrganisationNotificationsComponent.getNotificationStatusAvailableToCreate(
                organisationNotification,
                CaseOverdueStatusDictionary
            ).map((key) => ({
                key,
                text: CaseOverdueStatusDictionary[key],
                value: key,
            }));

        const notificationUserRoles: IOption[] = OrganisationNotificationsComponent.getTargetUserGroup(
            organisationNotification,
            NotificationRoleDisplay
        ).map((key) => ({
            key,
            text: NotificationRoleDisplay[key],
            value: key,
        }));

        const notificationChannelEmails: IOption[] =
            OrganisationNotificationsComponent.getNotificationChannelAvailableToCreate(
                organisationNotification,
                NotificationChannel
            ).map((key) => ({
                key,
                text: NotificationChannelUpper[NotificationChannel[key]],
                value: NotificationChannel[key],
            }));

        const userEmails = getEmailAddressesFromTarget(targets || []);

        return (
            <div>
                <div className="notification-input-block is-required">
                    <p className="notification-input-label">Category</p>
                    <Form.Select
                        id={NOTIFICATION_TYPE}
                        placeholder="Select notification type"
                        value={edit.notificationType}
                        onChange={this.onChange}
                        options={notificationTypes}
                    />
                </div>
                {isOverdueType ? (
                    <div className="notification-input-block is-required">
                        <p className="notification-input-label">Applies to Status</p>
                        <Form.Select
                            id="caseStatus"
                            placeholder="Select status"
                            value={edit.caseStatus || ""}
                            onChange={this.onChange}
                            options={notificationStatuses}
                        />
                    </div>
                ) : null}

                <div className="notification-input-block is-required">
                    <p className="notification-input-label">Type</p>
                    <Form.Select
                        id="notificationChannel"
                        placeholder="Select notification channel"
                        value={channel}
                        onChange={this.onChange}
                        options={notificationChannelEmails}
                    />
                </div>

                {notificationChannelEmail ? (
                    <EmailComponent
                        email={tempEmail}
                        onEmailChange={this.onEmailChange}
                        addEmail={this.addEmail}
                        deleteEmail={this.deleteEmail}
                        hideInput={isReportGeneratedType && Boolean(userEmails.length)}
                        error={errors}
                        emails={userEmails}
                    />
                ) : null}

                {isOverdueType ? (
                    <>
                        <div className="notification-input-block">
                            <p className="notification-input-label">Target of notification</p>
                            <div className="notification-checkboxes">
                                {notificationUserRoles.map((userRole: IOption) => (
                                    <Form.Checkbox
                                        label={userRole.text}
                                        key={userRole.key}
                                        id={userRole.key}
                                        onChange={this.onChangeUserRole}
                                        checked={edit.targets?.includes(userRole.key)}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className="notification-input-block">
                            <p className="notification-input-label">Notification title</p>
                            <Form.Input
                                className="long-text-field"
                                type="email"
                                id="notificationTitle"
                                value={notificationTitle}
                                onChange={this.onChange}
                            />
                        </div>

                        <div className="notification-input-block">
                            <p className="notification-input-label">Notification content</p>
                            <CustomRichTextEditor
                                ref={this.refCustomRichTextEditor}
                                value={notificationContent}
                                onChange={this.onRichTextChange}
                                name="notificationContent"
                            />
                        </div>
                    </>
                ) : null}

                {isReportGeneratedType ? (
                    <div className="notification-row">
                        <p className="notification-input-label">Send notification to case creator</p>
                        <Form.Checkbox
                            id="sendToCreator"
                            checked={edit.targets?.includes(NotificationRole.CASE_CREATOR)}
                            onChange={this.onSendToCreatorChange}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    public render() {
        const { addOrganisationNotification, edit, isVisibleRemoveModal } = this.state;
        const { organisation, showEditButton } = this.props;

        return (
            <Segment>
                <h3>Case creator notification settings</h3>
                {showEditButton && (
                    <p>
                        These settings are separate from the parent organisation. If you want to copy the parent
                        settings you can do so by pressing the button.
                    </p>
                )}
                <Divider />
                <SupportedMarkers data={markersDefinitionNotifications} />
                <Divider />
                <Segment>
                    <Grid columns="2">
                        <Grid.Row>
                            <Grid.Column>
                                <h3>Notified user in the organisation</h3>
                            </Grid.Column>
                            <Grid.Column floated="right" className="organisation-management-tab-content">
                                <OrganisationManagementTabHeader
                                    organisationUuid={organisation.uuid}
                                    type={OrganisationManagementTabs.NOTIFICATIONS}
                                    showEditButton={!!organisation.parentUuid}
                                    updateOrganisation={this.updateOrganisation}
                                    createMode={addOrganisationNotification}
                                    changeMode={this.changeShowAddOrganisationNotification}
                                    buttonText={!showEditButton ? "COPY FROM PARENT" : undefined}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider />
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>{this.getOrganisationNotificationSettingsTable()}</Grid.Column>
                        </Grid.Row>
                    </Grid>

                    {addOrganisationNotification && (
                        <>
                            <Grid columns={1}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <h3>{edit.uuid ? "Edit" : "Create new"} notification</h3>
                                        <Divider />
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column>{this.addNewOrganisationNotification()}</Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </>
                    )}
                    <RemoveNotificationModal
                        closeCallback={this.closeRemoveModal}
                        show={isVisibleRemoveModal}
                        onClose={this.closeRemoveModal}
                        onConfirm={this.onRemoveRow}
                    />
                    <div id="scrollAnchor" />
                </Segment>
            </Segment>
        );
    }
}

export default OrganisationNotificationsComponent;
