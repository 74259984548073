import { FC, FormEvent, ReactNode, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Divider,
    Form,
    Grid,
    InputOnChangeData,
    Label,
    Message,
    Segment,
    Table,
} from "semantic-ui-react";
import clsx from "clsx";

import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";
import PreviewDiagnosisTable from "components/Administration/OrganisationManagment/ViewOrganisation/Review/PreviewDiagnosisTable";
import ConfirmDeleteOutcomeDialog from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ConfirmDeleteOutcomeDialog";
import ReviewSettingsContext from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ReviewSettingsProvider";
import UpdateDiagnosisDialog from "components/Administration/OrganisationManagment/ViewOrganisation/Review/UpdateDiagnosisDialog";
import getConfirmDialog, { getDefaultButtons } from "components/templates/ConfirmDialog";
import SomethingWentWrongDialog from "components/templates/dialog/SomethingWentWrongDialog";
import ContextIconButton from "components/templates/ContextIconButton";
import CustomRichTextEditor from "components/templates/Editor";

import isManagementOutcomeUsed from "helpers/managementOutcome";
import { scrollToTop } from "helpers/page";

import { IManagementOptions, INewManagementOption, INewManagementOptions } from "model/organisation";
import { ReviewSettingMode, ReviewType } from "model/reviewType";
import { UserRole } from "model/userRole";

import reviewManagementService from "services/reviewManagementService";
import userService from "services/userService";

import "scss/AdminPanel.scss";
import "scss/Container.scss";

const REVIEW_TYPE_ID = "reviewType";
const REFER_TEXT = "Refer according to pathway";

interface ICreateDiagnosesScreenProps {
    allowedTypes: string[];
    mode: ReviewSettingMode;
}

interface INewDiagnosis {
    diagnosis: string;
    freeText: boolean;
    managementOptions: INewManagementOptions[];
    organisationUuid: string;
    diagnoseFreeText: boolean;
    reviewType: ReviewType | undefined;
    additionalInformation: string | undefined;
    removed: boolean;
    uuid: string | undefined;
    displayOrder?: number;
}

const NEW_MANAGEMENT_INITIAL = {
    freeTextManagement: false,
    management: "",
    reviewType: undefined,
    refer: false,
    discharge: false,
    keyMessage: undefined,
    safetyNetting: undefined,
};

const NEW_DIAGNOSIS_INITIAL = {
    diagnoseFreeText: false,
    diagnosis: "",
    freeText: false,
    managementOptions: [],
    reviewType: undefined,
    additionalInformation: undefined,
    removed: false,
    uuid: undefined,
};

const CreateDiagnosesScreen: FC<ICreateDiagnosesScreenProps> = ({ allowedTypes, mode }) => {
    const {
        state: { editDiagnosisUuid, organisation, reviewDiagnoses, reviewManagementOptions },
        dispatchers: { changeReviewSettingMode, updateReviewDiagnoses, updateReviewManagmentOptions },
    } = useContext(ReviewSettingsContext);

    const existingDiagnosis = editDiagnosisUuid
        ? reviewDiagnoses.find((diagnosis) => diagnosis.uuid === editDiagnosisUuid)
        : null;

    const DIAGNOSIS = existingDiagnosis
        ? {
              diagnoseFreeText: Boolean(existingDiagnosis.freeText),
              diagnosis: existingDiagnosis.diagnosis,
              freeText: existingDiagnosis.freeText,
              managementOptions: existingDiagnosis.managementOptions,
              organisationUuid: existingDiagnosis.organisationUuid,
              reviewType: existingDiagnosis.reviewType,
              additionalInformation: existingDiagnosis.additionalInformation,
              removed: Boolean(existingDiagnosis.removed),
              uuid: existingDiagnosis.uuid,
          }
        : {
              ...NEW_DIAGNOSIS_INITIAL,
              organisationUuid: organisation.uuid,
          };

    const [errorDetails, setErrorDetails] = useState<any[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [newDiagnosis, setNewDiagnosis] = useState<INewDiagnosis>(DIAGNOSIS);
    const [newOption, setNewOption] = useState<INewManagementOption>({ ...NEW_MANAGEMENT_INITIAL });
    const [showCancelUpdateDiagnosisDialog, setShowCancelUpdateDiagnosis] = useState<boolean>(false);
    const [showConfirmUpdateDiagnosisDialog, setShowConfirmUpdateDiagnosisDialog] = useState<boolean>(false);
    const [managementOutcomeToDelete, setManagementOptionToDelete] = useState<string>("");
    const [showConfirmDeleteOutcomeDialog, setShowConfirmDeleteOutcomeDialog] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

    const handleCheckboxChanged = (event: FormEvent<HTMLInputElement>, data: CheckboxProps): void => {
        if (isError) {
            setIsError(false);
        }

        const { uuid } = data;

        let updatedManagement = [...newDiagnosis.managementOptions];
        const isPresent = !!updatedManagement.filter((option) => option.managementUuid === uuid)?.length;

        if (isPresent) {
            updatedManagement = updatedManagement.filter(
                (existingDiagnose) => existingDiagnose.managementUuid !== uuid
            );
        } else {
            updatedManagement.push({ managementUuid: uuid });
        }

        setNewDiagnosis({ ...newDiagnosis, managementOptions: updatedManagement });
    };

    const handleNewOptionChanged = (
        event: FormEvent<HTMLInputElement>,
        data: CheckboxProps | InputOnChangeData
    ): void => {
        const { id = "", checked, value } = data;
        const isCheckedUndefined = checked === undefined;
        const updatedOption = { ...newOption };
        updatedOption[id] = isCheckedUndefined ? value || "" : checked;

        setNewOption(updatedOption);
    };

    const onRichTextChange = (value: string, name: string): void => {
        if (name === "additionalInformation") {
            const diagnosis = { ...newDiagnosis };
            const updatedDiagnosis = { ...diagnosis, additionalInformation: value };
            setNewDiagnosis(updatedDiagnosis);
        } else {
            const option = { ...newOption };
            const updatedOption = { ...option, [name]: value };
            setNewOption(updatedOption);
        }
    };

    const removeOption = (uuid: string): void => {
        reviewManagementService
            .removeManagementOutcome(uuid)
            .then(() => {
                const updatedManagementOutcomes = reviewManagementOptions.filter(
                    (managementOutcome) => managementOutcome.uuid !== uuid
                );
                updateReviewManagmentOptions(updatedManagementOutcomes);
                setNewOption(NEW_MANAGEMENT_INITIAL);
                setShowConfirmDeleteOutcomeDialog(false);
                setManagementOptionToDelete("");
            })
            .catch((err) => {
                const errors = err.response ? err.response.data.errors : [{ message: err.message }];
                setErrorDetails(errors);
                setIsError(false);
                setSubmitting(false);
                setShowConfirmDeleteOutcomeDialog(false);
                setShowErrorDialog(true);
            });
    };

    const handleRemoveOptionClicked = (event: any, obj: any): void => {
        const { uuid, arg } = obj;
        if (isManagementOutcomeUsed(reviewDiagnoses, uuid)) {
            setManagementOptionToDelete(uuid);
            setShowConfirmDeleteOutcomeDialog(true);
        } else {
            getConfirmDialog(
                `Are you sure that you want to delete ${arg} management outcome item?`,
                "",
                getDefaultButtons(() => removeOption(uuid))
            );
        }
    };

    const getErrorMessages = (): ReactNode =>
        errorDetails.map((detail: any) => (
            <Message key={detail.message} negative>
                <p>{detail.message}</p>
            </Message>
        ));

    const addOption = (): void => {
        newOption.reviewType = newDiagnosis.reviewType;
        reviewManagementService
            .createManagementOutcome(organisation.uuid, newOption)
            .then((result) => {
                const updatedManagementOptions = [...reviewManagementOptions, result];
                updateReviewManagmentOptions(updatedManagementOptions);
                setNewOption(NEW_MANAGEMENT_INITIAL);
            })
            .catch((err) => {
                const errors = err.response ? err.response.data.errors : [{ message: err.message }];
                console.error(errors);
                setIsError(false);
                setSubmitting(false);
                setShowErrorDialog(true);
            });
    };

    const existedOptionsManagement = (): ReactNode => {
        const { parentUuid = "" } = organisation;
        const { managementOptions, reviewType } = newDiagnosis;

        const filteredReviewManagementOptions = reviewManagementOptions.filter(
            (option) => option.reviewType === reviewType && !option.removed
        );

        if (!filteredReviewManagementOptions.length) {
            return <>Management outcomes not found. Please create new one</>;
        }

        return (
            filteredReviewManagementOptions && (
                <Table columns={3}>
                    <Table.Header>
                        <Table.HeaderCell>Management option</Table.HeaderCell>
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                    </Table.Header>
                    <Table.Body>
                        {filteredReviewManagementOptions.map((option: IManagementOptions) => {
                            const { uuid, management, copyOfUuid, organisationUuid: orgUuid } = option;
                            const isChecked = !!managementOptions.filter((moption) => moption.managementUuid === uuid)
                                .length;
                            const canEdit = organisation?.uuid === option.organisationUuid;
                            const canSelectOption = !!reviewType;

                            return (
                                <Table.Row key={uuid}>
                                    <Table.Cell width={4} verticalAlign="middle">
                                        <SettingsPropertyWrapper
                                            parentOrgUuid={parentUuid}
                                            copyOfUuid={copyOfUuid}
                                            propertyOrganisationUuid={orgUuid}
                                        >
                                            {management}
                                        </SettingsPropertyWrapper>
                                    </Table.Cell>
                                    <Table.Cell width={5}>
                                        {option.refer && <Label size="mini" color="red" content={REFER_TEXT} />}
                                        {option?.freeText && <Label size="mini" color="blue" content="Free text" />}
                                        {option?.keyMessage && (
                                            <Label size="mini" color="green" content="Key message" />
                                        )}
                                        {option?.safetyNetting && (
                                            <Label size="mini" color="brown" content="Safety netting" />
                                        )}
                                        {option?.discharge && (
                                            <Label size="mini" content="Discharge flag" className="discharge-label" />
                                        )}
                                    </Table.Cell>
                                    <Table.Cell width={2} verticalAlign="middle">
                                        {canEdit && (
                                            <>
                                                <ContextIconButton
                                                    iconName="trash"
                                                    onClick={handleRemoveOptionClicked}
                                                    uuid={uuid}
                                                    arg={management}
                                                />
                                                <ConfirmDeleteOutcomeDialog
                                                    showModal={
                                                        showConfirmDeleteOutcomeDialog &&
                                                        uuid === managementOutcomeToDelete
                                                    }
                                                    handleClose={() => setShowConfirmDeleteOutcomeDialog(false)}
                                                    handleContinue={() => removeOption(uuid)}
                                                />
                                            </>
                                        )}
                                        {canSelectOption && (
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={(event, data) => handleCheckboxChanged(event, data)}
                                                uuid={uuid}
                                                className="review-management-outcome-checkbox"
                                            />
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )
        );
    };

    const handleFieldChange = (event: any, data: any): void => {
        const newDiagnosisObj = { ...newDiagnosis };
        const newOptionObj = { ...newOption };
        const fieldName = data.id;

        if (fieldName === REVIEW_TYPE_ID) {
            newDiagnosisObj.managementOptions = [];
            newOptionObj.refer = NEW_MANAGEMENT_INITIAL.refer;
        }

        const { value, checked } = data;
        const isCheckedUndefined = checked === undefined;
        newDiagnosisObj[fieldName] = isCheckedUndefined ? value || "" : checked;
        setNewDiagnosis(newDiagnosisObj);
        setNewOption(newOptionObj);
    };

    const submitNewDiagnosis = (): void => {
        const {
            diagnosis,
            managementOptions,
            organisationUuid: orgUuid,
            freeText,
            reviewType,
            additionalInformation,
        } = newDiagnosis;

        if (!reviewType) {
            setIsError(true);
            return;
        }

        const lastDiagnosis = reviewDiagnoses
            ?.filter((diag) => !diag.removed && diag.reviewType === newDiagnosis.reviewType)
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            .pop();

        const createDiagnosisObject = {
            diagnosis,
            freeText,
            managementOptions,
            organisationUuid: orgUuid,
            reviewType,
            additionalInformation,
            ...(lastDiagnosis?.displayOrder ? { displayOrder: lastDiagnosis.displayOrder + 1 } : {}),
        };

        if (diagnosis !== "" && orgUuid) {
            setSubmitting(true);
            reviewManagementService
                .createDiagnoses(createDiagnosisObject)
                .then((createdDiagnosis) => {
                    const updatedDiagnoses = [...reviewDiagnoses, createdDiagnosis];
                    updateReviewDiagnoses(updatedDiagnoses);
                })
                .catch((err) => {
                    const errors = err.response ? err.response.data.errors : [{ message: err.message }];
                    setErrorDetails(errors);
                    setIsError(false);
                    setSubmitting(false);
                    setShowErrorDialog(true);
                });
        } else {
            setIsError(true);
        }
    };

    const optionsManagement = (): ReactNode => {
        const { freeTextManagement, refer, management, keyMessage, safetyNetting, discharge } = newOption;
        const canCreateDiagnosis = newDiagnosis.reviewType !== undefined;
        const showReferOption = newDiagnosis.reviewType === ReviewType.SAFETY_NET;

        if (!canCreateDiagnosis) {
            return <>Please select review type.</>;
        }

        return (
            <>
                <Grid.Row key="newManagmentOption">
                    <Form>
                        <Form.Input
                            value={management}
                            id="management"
                            onChange={handleNewOptionChanged}
                            className="new-management-outcome-input"
                            label="Management option name"
                        />

                        <Form.Checkbox
                            toggle
                            checked={discharge}
                            id="discharge"
                            onChange={handleNewOptionChanged}
                            className="field new-management-outcome-discharge-toggle"
                            label="Discharge flag"
                        />
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    <Form>
                        <div>
                            <div className="field create-review-rich-text-label">
                                <span>Report Key message</span>
                            </div>
                            <CustomRichTextEditor value={keyMessage} onChange={onRichTextChange} name="keyMessage" />
                        </div>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    <Form>
                        <div>
                            <div className="field create-review-rich-text-label">
                                <span>Report Safety Netting/Additional</span>
                            </div>
                            <CustomRichTextEditor
                                value={safetyNetting}
                                onChange={onRichTextChange}
                                name="safetyNetting"
                            />
                        </div>
                    </Form>
                </Grid.Row>
                <Grid.Row className="create-diagnoses-free-text">
                    <Checkbox
                        label="Free text (for 'other' management outcome)"
                        checked={freeTextManagement}
                        id="freeTextManagement"
                        onChange={handleNewOptionChanged}
                    />
                </Grid.Row>
                <Grid.Row className="create-diagnoses-free-text">
                    {showReferOption && (
                        <Checkbox id="refer" label={REFER_TEXT} checked={refer} onChange={handleNewOptionChanged} />
                    )}
                </Grid.Row>
                <Grid.Row>
                    <Button floated="right" icon="add" content="Create Management Outcome" onClick={addOption} />
                </Grid.Row>
            </>
        );
    };

    const openConfirmUpdateDiagnosisDialog = (): void => {
        if (newDiagnosis.managementOptions?.length === 0) {
            setIsError(true);
            return;
        }
        setShowConfirmUpdateDiagnosisDialog(true);
    };

    const openCancelUpdateDiagnosisDialog = (): void => {
        setShowCancelUpdateDiagnosis(true);
    };

    const closeCancelUpdateDiagnosisDialog = (): void => {
        setShowCancelUpdateDiagnosis(false);
    };

    const closeConfirmUpdateDiagnosisDialog = (): void => {
        setShowConfirmUpdateDiagnosisDialog(false);
    };

    const submitCancelUpdateDiagnosis = (): void => {
        closeCancelUpdateDiagnosisDialog();
        changeReviewSettingMode(ReviewSettingMode.VIEW);
        scrollToTop();
    };

    const submitUpdateDiagnosis = (): void => {
        reviewManagementService
            .updateDiagnoses(newDiagnosis)
            .then((updatedDiagnosis) => {
                const updatedDiagnoses = [...reviewDiagnoses].map((diag) => {
                    if (diag.uuid === updatedDiagnosis.uuid) {
                        return updatedDiagnosis;
                    }
                    return diag;
                });
                updateReviewDiagnoses(updatedDiagnoses);
                setShowConfirmUpdateDiagnosisDialog(false);
            })
            .catch((err) => {
                const errors = err.response ? err.response.data.errors : [{ message: err.message }];
                setErrorDetails(errors);
                setIsError(false);
                setSubmitting(false);
                setShowConfirmUpdateDiagnosisDialog(false);
                setShowErrorDialog(true);
            });
    };

    const getSaveButton = (): ReactNode => {
        if (mode === ReviewSettingMode.CREATE) {
            return (
                <Button loading={submitting} floated="right" onClick={submitNewDiagnosis}>
                    Create
                </Button>
            );
        }

        return (
            <>
                <Button loading={submitting} floated="right" onClick={openConfirmUpdateDiagnosisDialog}>
                    Save
                </Button>
                <UpdateDiagnosisDialog
                    showModal={showConfirmUpdateDiagnosisDialog}
                    handleClose={closeConfirmUpdateDiagnosisDialog}
                    handleContinue={submitUpdateDiagnosis}
                    isConfirmAction
                />
            </>
        );
    };

    const getCancelButton = (): ReactNode => {
        if (mode === ReviewSettingMode.CREATE) {
            return (
                <Button
                    content="Cancel"
                    floated="right"
                    onClick={() => changeReviewSettingMode(ReviewSettingMode.VIEW)}
                />
            );
        }

        return (
            <>
                <Button content="Cancel" floated="right" onClick={openCancelUpdateDiagnosisDialog} />
                <UpdateDiagnosisDialog
                    showModal={showCancelUpdateDiagnosisDialog}
                    handleClose={closeCancelUpdateDiagnosisDialog}
                    handleContinue={submitCancelUpdateDiagnosis}
                    isConfirmAction={false}
                />
            </>
        );
    };

    if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
        return <Redirect to="/home" />;
    }

    if (mode === ReviewSettingMode.VIEW) {
        return null;
    }

    return (
        <>
            <Segment>
                <h3>{mode === ReviewSettingMode.EDIT ? "Edit a diagnosis" : "Create new diagnosis for review"}</h3>
                <Divider />

                <div>
                    <h5 hidden={!isError} className="error-message">
                        <p>Please fill all required fields</p>
                    </h5>
                    <div className={clsx({ container: true, error: isError })}>
                        <br />
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Grid columns={1}>
                                        <Form className="create-diagnosis-form">
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Form.Input
                                                        label={`${
                                                            mode === ReviewSettingMode.CREATE ? "New " : ""
                                                        }Diagnosis name`}
                                                        type="text"
                                                        id="diagnosis"
                                                        onChange={handleFieldChange}
                                                        value={newDiagnosis.diagnosis}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Form.Select
                                                        label="Type"
                                                        id={REVIEW_TYPE_ID}
                                                        placeholder="Select diagnosis type"
                                                        required
                                                        onChange={handleFieldChange}
                                                        value={newDiagnosis.reviewType}
                                                        options={Object.keys(ReviewType)
                                                            .filter((item) => allowedTypes.includes(item))
                                                            .map((key) => ({
                                                                key,
                                                                text: ReviewType[key],
                                                                value: key,
                                                            }))}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <div>
                                                    <div className="field create-review-rich-text-label">
                                                        <span>Find out more info</span>
                                                    </div>
                                                    <CustomRichTextEditor
                                                        value={newDiagnosis.additionalInformation}
                                                        onChange={onRichTextChange}
                                                        name="additionalInformation"
                                                    />
                                                </div>
                                            </Grid.Row>
                                            <Grid columns={1}>
                                                <Grid.Row>
                                                    <Grid.Column>{getErrorMessages()}</Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row className="diagnose-free-text">
                                                    <Checkbox
                                                        label="Free text (for 'other' diagnosis)"
                                                        checked={newDiagnosis.freeText}
                                                        id="freeText"
                                                        onChange={handleFieldChange}
                                                    />
                                                </Grid.Row>
                                            </Grid>
                                        </Form>
                                        {reviewManagementOptions?.length ? (
                                            <Grid.Row className="title">
                                                <Grid.Column>
                                                    <Divider />
                                                    <h4>Select Management Options for your diagnosis</h4>
                                                    {existedOptionsManagement()}
                                                </Grid.Column>
                                            </Grid.Row>
                                        ) : (
                                            ""
                                        )}
                                        <Grid.Row className="title">
                                            <Grid.Column>
                                                <Divider />
                                                <h4>Add New Management Options to your diagnosis</h4>
                                                {optionsManagement()}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <b>Diagnosis Preview</b>
                                    <PreviewDiagnosisTable
                                        reviewDiagnoses={[newDiagnosis] as any}
                                        reviewManagementOptions={reviewManagementOptions}
                                        organisation={organisation}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    {getSaveButton()}
                                    {getCancelButton()}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </Segment>
            <SomethingWentWrongDialog showModal={showErrorDialog} handleClose={() => setShowErrorDialog(false)} />
        </>
    );
};

export default CreateDiagnosesScreen;
