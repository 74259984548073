import { FC, useContext, useEffect } from "react";
import { Container, Divider } from "semantic-ui-react";
import clsx from "clsx";

import ManualCaseCreationModal from "components/PatientSearch/ManualCaseCreationModal";
import PatientSearchComponent from "components/PatientSearch/PatientSearch";
import PatientSearchResults from "components/PatientSearch/PatientSearchResults";
import PatientSearchExpandedView from "components/PatientSearch/PatientSearchExpandedView";

import CustomButton from "components/templates/Button";
import IntegrationPatientLabel from "components/templates/IntegrationPatientLabel";

import { ModalContext } from "contextProviders/ModalProvider";
import { PatientSearchContext } from "contextProviders/PatientSearchProvider";

import { PatientLabelTypes } from "model/patientSearch";

import { NEW_ASSESSMENT } from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";

const PatientSearch: FC = () => {
    const {
        state: { showResults, expandedViewPatient },
    } = useContext(PatientSearchContext);
    const { setModalParameters } = useContext(ModalContext);

    const handleManualPatientCreationClicked = () => {
        setModalParameters({
            content: <ManualCaseCreationModal />,
            isVisible: true,
            size: "mini",
        });
    };

    useEffect(() => {
        logoutTimeoutService.stopCount();
    }, []);

    const isSearchView = !expandedViewPatient;
    const { mergedRecord } = { ...expandedViewPatient };
    const title = isSearchView ? "UHB PAS Patient Search" : "Patient Details";

    return (
        <>
            <Container className="ui segment wizzard-container">
                <div
                    className={clsx(
                        "wizzard-container-title",
                        expandedViewPatient && "patient-search-expanded-view__header"
                    )}
                >
                    <div>
                        <h1>{title}</h1>
                    </div>
                    {isSearchView && (
                        <CustomButton
                            variant="dark"
                            type="button"
                            action={handleManualPatientCreationClicked}
                            text="Create case manually"
                            size="small"
                        />
                    )}
                    {mergedRecord && <IntegrationPatientLabel type={PatientLabelTypes.MERGED_RECORD} />}
                </div>
                <Divider />
                {isSearchView ? <PatientSearchComponent /> : <PatientSearchExpandedView />}
            </Container>
            {isSearchView && showResults && (
                <Container>
                    <PatientSearchResults />
                    <CustomButton variant="empty" type="link" to={NEW_ASSESSMENT} text="< Back" size="small" />
                </Container>
            )}
        </>
    );
};

export default PatientSearch;
