import { useState, SyntheticEvent } from "react";

import { isUndefined } from "helpers/common";

interface IUseCopy {
    readonly titleOfCopyField: string;
    readonly valueOfCopyField?: string;
    readonly titleOfUpdatedField?: string;
    readonly displayUpdateDuration?: number;
}

interface IUseCopyReturn {
    readonly fieldTitle: string;
    readonly handleCopyClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const useCopy = ({
    titleOfCopyField,
    valueOfCopyField,
    titleOfUpdatedField,
    displayUpdateDuration,
}: IUseCopy): IUseCopyReturn => {
    const originalTitle = `Copy ${titleOfCopyField}`;
    const updatedTitle = isUndefined(titleOfUpdatedField) ? "Copied!" : titleOfUpdatedField;
    const displayUpdateTimeout = isUndefined(displayUpdateDuration) ? 1000 : displayUpdateDuration;

    const [fieldTitle, setFieldTitle] = useState<string>(() => (isUndefined(valueOfCopyField) ? "" : originalTitle));

    const setFieldTitleAfterDelay = (titleText: string, delayInMs: number): void => {
        setTimeout(() => {
            setFieldTitle(titleText);
        }, delayInMs);
    };

    const handleCopyClick = (event: SyntheticEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        event.preventDefault();

        if (isUndefined(valueOfCopyField)) {
            return;
        }

        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(valueOfCopyField)
                .then(() => {
                    setFieldTitle(updatedTitle);
                    setFieldTitleAfterDelay(originalTitle, displayUpdateTimeout);
                })
                .catch(() => {
                    setFieldTitle("Failed to copy");
                    setFieldTitleAfterDelay(originalTitle, displayUpdateTimeout);
                });
        } else {
            const textField = document.createElement("textarea");
            textField.innerHTML = valueOfCopyField;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand("copy");

            textField.remove();
            setFieldTitle(updatedTitle);
            setFieldTitleAfterDelay(originalTitle, displayUpdateTimeout);
        }
    };

    return {
        handleCopyClick,
        fieldTitle,
    };
};

export default useCopy;
