import { decode } from "jsonwebtoken";

import { ITokenBody } from "model/tokenBody";

export class TokenService {
    public static decodeToken(token: string) {
        return decode(token) as ITokenBody;
    }
}

export default new TokenService();
