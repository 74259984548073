import { ICaseHistoryRow } from "model/caseHistory";
import PatientDetailsIntegration from "model/integrations";
import { CallbackOutcomesText } from "model/remoteModel";

import isPASIntegration from "helpers/PASintegration";

export const pathIndex = "INDEX_PROPERTY";

const SYSTEM_USER = "User System";
const CASE_OVERDUE = "caseOverdue";
const NEW_ASSIGNMENT = "newAssignment";
const REPORT_GENERATED = "reportGenerated";
const CANCEL_ASSIGNMENT = "cancelAssignment";

const renderNotificationCopies = (notificationType: string) => {
    switch (notificationType) {
        case NEW_ASSIGNMENT:
            return "Case allocated to dermatologist";
        case CASE_OVERDUE:
            return "Case has been marked as overdue";
        case REPORT_GENERATED:
            return "Report Generated";
        case CANCEL_ASSIGNMENT:
            return "Allocation has been cancelled";
        default:
            return "";
    }
};

const renderNotificationCreator = (notificationCreator: string, notificationType: string) => {
    if (notificationCreator === SYSTEM_USER || notificationType === CASE_OVERDUE) return "";
    return `By ${notificationCreator}`;
};

export const historyCaseConfig = [
    {
        path: `patient/medicalHistoryCreationDate`,
        info: { medicalHistoryCreatedByName: `patient/medicalHistoryCreatedByName` },
        by: (info: ICaseHistoryRow) => `By ${info.medicalHistoryCreatedByName}`,
        action: () => `Medical history questionnaire completed`,
    },
    {
        path: `patient/medicalHistoryLastModificationDate`,
        info: { medicalHistoryLastModifiedByName: `patient/medicalHistoryCreatedByName` },
        by: (info: ICaseHistoryRow) => `By ${info.medicalHistoryLastModifiedByName}`,
        action: () => `Medical history questionnaire last modification`,
    },
    {
        path: `patient/creationDate`,
        info: { createdByName: `patient/createdByName`, integrationName: `patient/integrationInfo/integrationName` },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: (info: ICaseHistoryRow) =>
            `Patient details  ${info.integrationName ? `received from ${info.integrationName}` : "created"}`,
    },
    {
        path: `patient/lastModificationDate`,
        info: {
            lastModifiedByName: `patient/lastModifiedByName`,
            integrationName: "patient/integrationInfo/integrationName",
            patientDataUpdates: `patientNotes/${pathIndex}/patientDataUpdates`,
        },
        by: (info: ICaseHistoryRow) => `By ${info.lastModifiedByName}`,
        action: () => `Patient details last modification`,
        show: (info: ICaseHistoryRow): boolean =>
            isPASIntegration(info.integrationName) ? Boolean(info.patientDataUpdates?.[0]) : true,
    },
    {
        path: `patientNotes/${pathIndex}/creationDate`,
        info: {
            index: `patientNotes/${pathIndex}/index`,
            createdByName: `patientNotes/${pathIndex}/createdByName`,
            patientDataUpdates: `patientNotes/${pathIndex}/patientDataUpdates`,
            integrationName: "patient/integrationInfo/integrationName",
        },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: (info: ICaseHistoryRow) => {
            switch (info.integrationName) {
                case PatientDetailsIntegration.PAS:
                case "UHB PAS":
                    return "Integration data sync check";
                case PatientDetailsIntegration.PDS:
                    return "Non-PDS Patient details edited";
                default:
                    return "Patient details edited";
            }
        },
        show: (info: ICaseHistoryRow): boolean => (info.integrationName ? Boolean(info.patientDataUpdates?.[0]) : true),
    },
    {
        path: `report/creationDate`,
        info: {},
        by: () => undefined,
        action: () => `Report generated`,
    },
    {
        path: `abandonDate`,
        info: { abandonReason: `abandonReason` },
        by: () => undefined,
        action: () => `Case abandoned`,
    },
    {
        path: `assessmentDate`,
        info: { abandonReason: `abandonReason` },
        by: () => undefined,
        action: () => `Case sent for assessment`,
    },
    {
        path: `closureDate`,
        info: {},
        by: () => undefined,
        action: () => `Case closed`,
    },
    {
        path: `consentDate`,
        info: {},
        by: () => undefined,
        action: () => `Consent was obtained`,
    },
    {
        path: `creationDate`,
        info: { createdByName: `createdByName` },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: () => `Case created`,
    },
    {
        path: `assignmentDetails/creationDate`,
        info: {
            createdByName: `assignmentDetails/createdByName`,
            assigneeName: `assignmentDetails/assigneeName`,
        },
        by: (info: ICaseHistoryRow) => (info.createdByName === SYSTEM_USER ? "" : `By ${info.createdByName}`),
        action: (info: ICaseHistoryRow) =>
            `Case allocated to dermatologist ${
                info.createdByName === SYSTEM_USER ? `${info.assigneeName} (Automated allocation)` : ""
            }`,
        show: (info: ICaseHistoryRow): boolean => Boolean(info.assigneeName),
    },
    {
        path: `lesions/${pathIndex}/creationDate`,
        info: {
            index: `lesions/${pathIndex}/index`,
            createdByName: `lesions/${pathIndex}/createdByName`,
        },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: (info: ICaseHistoryRow) => `Lesion #${info.index} created`,
    },
    {
        path: `lesions/${pathIndex}/images/${pathIndex}/creationDate`,
        info: {
            index: `lesions/${pathIndex}/images/${pathIndex}/index`,
            createdByName: `lesions/${pathIndex}/images/${pathIndex}/createdByName`,
            type: `lesions/${pathIndex}/images/${pathIndex}/type`,
        },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: (info: ICaseHistoryRow) => `Image #${info.index} (type: ${info.type}) added`,
    },
    {
        path: `clinicalNotes/${pathIndex}/noteCreatedDate`,
        info: {
            index: `clinicalNotes/${pathIndex}/index`,
            createdByName: `clinicalNotes/${pathIndex}/createdByName`,
        },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: () => `Notes for Clinical use added`,
    },
    {
        path: `clinicalNotes/${pathIndex}/deletedDate`,
        info: {
            index: `clinicalNotes/${pathIndex}/index`,
            createdByName: `clinicalNotes/${pathIndex}/createdByName`,
        },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: () => `Notes for Clinical use deleted`,
    },
    {
        path: `patientNotes/${pathIndex}/creationDate`,
        info: {
            index: `patientNotes/${pathIndex}/index`,
            createdByName: `patientNotes/${pathIndex}/createdByName`,
            integrationName: `patient/integrationInfo/integrationName`,
            patientDataUpdates: `patientNotes/${pathIndex}/patientDataUpdates`,
        },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: (info: ICaseHistoryRow) => `Notes for the Patient #${info.index} added`,
        show: (info: ICaseHistoryRow) => (info.integrationName ? Boolean(info.patientDataUpdates?.[0]) : true),
    },
    {
        path: `reviews/${pathIndex}/creationDate`,
        info: {
            index: `reviews/${pathIndex}/index`,
            createdByName: `reviews/${pathIndex}/createdByName`,
        },
        by: (info: ICaseHistoryRow) => `By ${info.createdByName}`,
        action: () => `Case review submitted`,
    },
    {
        path: `notifications/${pathIndex}/creationDate`,
        info: {
            index: `notifications/${pathIndex}/index`,
            createdByName: `notifications/${pathIndex}/createdByName`,
            notificationType: `notifications/${pathIndex}/notificationType`,
        },
        by: (info: ICaseHistoryRow) => renderNotificationCreator(info.createdByName, info.notificationType),
        action: (info: ICaseHistoryRow) =>
            `Notification #${info.index} created: ${renderNotificationCopies(info.notificationType)}`,
    },
    {
        path: `callbackOutcome/${pathIndex}/creationDate`,
        info: {
            lastModifiedByName: `callbackOutcome/${pathIndex}/lastModifiedByName`,
            outcome: `callbackOutcome/${pathIndex}/outcome`,
        },
        by: (info: ICaseHistoryRow) => `By ${info.lastModifiedByName}`,
        action: (info: ICaseHistoryRow) => `Clinician Callback: ${CallbackOutcomesText[info.outcome]} submitted`,
    },
];
