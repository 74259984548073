import { useEffect, useState } from "react";

const useIsEmailValid = (email: string): boolean => {
    const [isEmailValid, setIsEmailValid] = useState(false);

    useEffect(() => {
        const isValidEmailAddress = email.match(/^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/);
        setIsEmailValid(Boolean(isValidEmailAddress));
    }, [email]);

    return isEmailValid;
};

export default useIsEmailValid;
