import React, { FC, Fragment } from "react";
import { Table } from "semantic-ui-react";

import { getLocation } from "helpers/caseDescription";

import { ILesion, IReview } from "model/case";

interface IReviewDiagnosesTableRows {
    lesions: ILesion[];
    isEscalation: boolean;
}

const ReviewDiagnosesTableRows: FC<IReviewDiagnosesTableRows> = ({ lesions, isEscalation }) => {
    if (!lesions || !lesions.length) {
        return (
            <Table.Row>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>-</Table.Cell>
            </Table.Row>
        );
    }

    return (
        <>
            {lesions.map((lesion: ILesion, index: number) => {
                const location = getLocation(lesion);

                return lesion.reviews ? (
                    lesion.reviews.map((review: IReview) => {
                        const {
                            createdByName,
                            reviewDiagnosis,
                            reviewManagement,
                            diagnosisAdditionalText,
                            managementAdditionalText,
                            escalationPlan,
                            escalationPlanAdditionalText,
                        } = review;
                        const lesionReviewFreeText =
                            review &&
                            (diagnosisAdditionalText || managementAdditionalText || escalationPlanAdditionalText);

                        return (
                            <Fragment key={review.uuid}>
                                <Table.Row>
                                    <Table.Cell>{`Lesion ${index + 1}`}</Table.Cell>
                                    <Table.Cell className="location-cell">{location}</Table.Cell>
                                    <Table.Cell>{createdByName}</Table.Cell>
                                    <Table.Cell>{reviewDiagnosis}</Table.Cell>
                                    <Table.Cell>{reviewManagement}</Table.Cell>
                                    {isEscalation && <Table.Cell>{escalationPlan}</Table.Cell>}
                                </Table.Row>

                                {lesionReviewFreeText ? (
                                    <Table.Row>
                                        <Table.Cell className="review-free-text-cell" />
                                        <Table.Cell className="review-free-text-cell" />
                                        <Table.Cell className="review-free-text-cell" />
                                        <Table.Cell className="review-free-text-cell" width={9}>
                                            {diagnosisAdditionalText}
                                        </Table.Cell>
                                        <Table.Cell className="review-free-text-cell" width={9}>
                                            {managementAdditionalText}
                                        </Table.Cell>
                                        {isEscalation && (
                                            <Table.Cell className="review-free-text-cell" width={9}>
                                                {escalationPlanAdditionalText}
                                            </Table.Cell>
                                        )}
                                    </Table.Row>
                                ) : null}
                            </Fragment>
                        );
                    })
                ) : (
                    <Table.Row key={lesion.uuid}>
                        <Table.Cell>{`Lesion ${index + 1}`}</Table.Cell>
                        <Table.Cell>{location}</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                    </Table.Row>
                );
            })}
        </>
    );
};

export default ReviewDiagnosesTableRows;
