import React, { FC, useState } from "react";
import ReactHtmlParser from "react-html-parser";

interface IEmailContentComponent {
    content: string;
}

const EmailContentComponent: FC<IEmailContentComponent> = ({ content }) => {
    const [activeContent, setActiveContent] = useState<boolean>(false);
    const toggleInformation = () => setActiveContent(!activeContent);

    return (
        <div>
            {activeContent ? (
                <div>
                    <br />
                    <b>Content:</b>
                    <div>{ReactHtmlParser(content)}</div>
                </div>
            ) : null}
            <button type="button" className="toggle-email-content" onClick={toggleInformation}>
                Show {activeContent ? "less" : "more"} сontent
            </button>
        </div>
    );
};

export default EmailContentComponent;
