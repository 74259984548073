import { FC } from "react";
import { Grid } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";

interface IWhatIsFitzpatrickModal {
    showModal: boolean;
    handleClose: () => void;
}

const WhatIsFitzpatrickModal: FC<IWhatIsFitzpatrickModal> = ({ showModal, handleClose }) => (
    <ModalDialog
        title="Fitzpatrick Skin Types (phototypes)"
        open={showModal}
        onClose={handleClose}
        maxWidth="md"
        disableBackdropClick
        buttons={[
            {
                onClick: handleClose,
                text: "Got it",
                colour: "primary",
            },
        ]}
    >
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    <h3>What Is a Fitzpatrick Skin Type (Phototype)?</h3>
                    <p>
                        It is a widely used method to understand the risk to sun damage that an individual has (without
                        wearing a sunscreen). It includes a total of six skin phototypes, known as Fitzpatrick Skin
                        Types 1 to 6.
                    </p>
                    <h3>Why is it important?</h3>
                    <p>
                        Your Fitzpatrick Skin Type is relevant when assessing skin lesions because different Fitzpatrick
                        Skin Types have varying levels of melanin and therefore, varying risk to sun damage. Melanin is
                        the pigment responsible for skin colour that also plays a crucial role in protecting the skin
                        from the harmful effects of the sun.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </ModalDialog>
);

export default WhatIsFitzpatrickModal;
