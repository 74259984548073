enum Pathway {
    BIOPSY = "Biopsy",
    EXCISIONAL_BIOPSY = "Excisional biopsy",
    EXCISION = "Excision",
    TOPICAL_TREATMENT = "Topical treatment",
    REVIEW_THREE_MONTHS = "Review after 3 months",
    DISCHARGE = "Discharge",
}

export default Pathway;
