import React, { Component } from "react";
import { Grid, Segment } from "semantic-ui-react";

import { ICase } from "model/case";
import { CaseStatusDictionary } from "model/caseStatus";
import { CaseViewMode } from "model/caseViewMode";
import { DATA_IS_NOT_AVAILABLE_TEXT } from "model/common";
import { UserRole } from "model/userRole";

import userService from "services/userService";

interface ICaseStatusPanelProps {
    caseObject: ICase;
    mode: CaseViewMode;
}

class CaseStatusPanel extends Component<ICaseStatusPanelProps, any> {
    private getEscalationPlan() {
        const { caseObject } = this.props;
        const nscLesion = caseObject.lesions[0];

        if (nscLesion.reviews) {
            const nscReview = caseObject.lesions[0].reviews[0];
            return `${nscReview.escalationPlan}`;
        }

        return DATA_IS_NOT_AVAILABLE_TEXT;
    }

    private getAbandonReason() {
        const { caseObject } = this.props;
        return (
            caseObject.abandonReason && (
                <Grid.Column width={4}>
                    <p>Note</p>
                    <Segment>{caseObject.abandonReason}</Segment>
                </Grid.Column>
            )
        );
    }

    public render() {
        const { caseObject, mode } = this.props;
        const { caseStatus, recommendation, nonSkinCancer } = caseObject;
        const allowedRoles = [
            CaseViewMode.CLIENT_ADMIN_REMOTE_VIEW,
            CaseViewMode.ADMINVIEW,
            CaseViewMode.REVIEWVIEW,
            CaseViewMode.SUPERADMINVIEW,
            CaseViewMode.VIEW,
            CaseViewMode.CALLBACK_AGENT_REVIEW,
        ];
        const canRender = allowedRoles.includes(mode) || userService.checkUserHasRole([UserRole.CALLBACK_AGENT]);

        const isNonSkinCancerFlow = nonSkinCancer;

        const managementText = isNonSkinCancerFlow ? "Management Plan" : "Management Outcome";

        return (
            canRender && (
                <div>
                    <Grid className="case-status">
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <p>Case Status</p>
                                <Segment>{CaseStatusDictionary[caseStatus] || DATA_IS_NOT_AVAILABLE_TEXT}</Segment>
                            </Grid.Column>
                            {this.getAbandonReason()}
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <p>{managementText}</p>
                                <Segment>{recommendation || DATA_IS_NOT_AVAILABLE_TEXT}</Segment>
                            </Grid.Column>
                            {isNonSkinCancerFlow && (
                                <Grid.Column width={4}>
                                    <p>Escalation Plan</p>
                                    <Segment>{this.getEscalationPlan() || DATA_IS_NOT_AVAILABLE_TEXT}</Segment>
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </Grid>
                    <br />
                </div>
            )
        );
    }
}

export default CaseStatusPanel;
