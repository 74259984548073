import { FC } from "react";
import { Redirect } from "react-router-dom";
import { Divider } from "semantic-ui-react";

import CaseList from "components/Administration/CaseReport/CaseList";

import { UserRole } from "model/userRole";

import userService from "services/userService";

const CaseReportScreen: FC = () => (
    <>
        {!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN]) ? (
            <Redirect to="/home" />
        ) : (
            <div>
                <h1>Case Report</h1>
                <Divider />
                <CaseList />
            </div>
        )}
    </>
);

export default CaseReportScreen;
