import React, { FC, useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, Header, Icon, Modal } from "semantic-ui-react";
import FocusTrap from "focus-trap-react";

import CaseListFilters from "components/CaseList/CaseListFilters";
import CaseListSearchRow from "components/CaseList/CaseListSearchRow";
import CaseListTabs from "components/CaseList/CaseListTabs";
import SettingsValidation from "components/Home/SettingsValidation";
import CustomButton from "components/templates/Button";
import GeneralTeledermatologyExclusionsPopup from "components/templates/dialog/GeneralTeledermatologyExclusionsPopup";

import { CaseListContext, ICaseListContext, TAB_STATE } from "contextProviders/CaseListProvider";
import { ModalContext } from "contextProviders/ModalProvider";

import { UserRole } from "model/userRole";
import { IOrganisation } from "model/organisation";
import { CaseListTabs as CaseListTabsModel } from "model/caseList";

import { ASSESSMENT_MODE } from "navigation/routes";

import { getAssessment } from "redux/selectors/assessment";
import { getOrganisation, getUser } from "redux/selectors/data";

import assessmentService from "services/assessmentService";
import userService from "services/userService";

const CASE_LIST_MAX_ITEMS_CSV = 500;

const CaseListHeader: FC = () => {
    const [isErrorBlockingAssessment, setIsErrorBlockingAssessment] = useState<boolean>(false);
    const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
    const { activeTab, caseLists, config, currentTabCases, downloadCsvReport, downloadKitCsvReport } =
        useContext<ICaseListContext>(CaseListContext);
    const { setModalParameters, closeModal } = useContext(ModalContext);

    const { filters: filterValues, total: totalCases } = { ...currentTabCases };
    const isFiltersActive = filterValues && Object.keys(filterValues).find((value: string) => filterValues[value]);

    const isCurrentUserSuperAdmin = userService.checkUserHasRole([UserRole.SUPERADMIN]);
    const isCurrentUserPatient = userService.checkUserHasRole([UserRole.PATIENT]);
    const isCurrentUserCallbackAgent = userService.checkUserHasRole([UserRole.CALLBACK_AGENT]);
    const isCurrentUserDermatologist = userService.checkUserHasRole([UserRole.DERMATOLOGIST]);
    const isCurrentUserClientAdmin = userService.checkUserHasRole([UserRole.ADMIN]);
    const isCurrentUserClinicalViewer = userService.checkUserHasRole([UserRole.CLINICAL_VIEWER]);
    const isCurrentUserDermatologistOrPatient =
        isCurrentUserPatient || isCurrentUserDermatologist || isCurrentUserCallbackAgent;
    const showAssessmentButtons = !isCurrentUserDermatologistOrPatient;

    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(!!isFiltersActive);
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
    const [showExclusionsPopup, setShowExclusionsPopup] = useState<boolean>(false);

    useEffect(() => {
        if (isCurrentUserSuperAdmin) {
            setIsFilterVisible(true);
        }
    }, []);

    useEffect(() => {
        if (currentTabCases?.data) {
            setIsSearchVisible(true);
        }
    }, [currentTabCases]);

    const assessment = useSelector(getAssessment);
    const user = useSelector(getUser);
    const hideGeneralDermatologyPopup = user.preferences?.hideGeneralDermatologyExceptions || false;

    const organisation: IOrganisation = useSelector(getOrganisation);
    const dispatch = useDispatch();

    const isNonSkinCancerOrganisation = organisation?.nonSkinCancerAllowed;

    const errorCallback = (isErrorBlocksAssessment: boolean) => {
        setIsErrorBlockingAssessment(isErrorBlocksAssessment);
    };

    const handleNewAssessmentClicked = () => {
        if (isNonSkinCancerOrganisation && !hideGeneralDermatologyPopup) {
            setShowExclusionsPopup(true);
        }
        if (assessment.case) {
            dispatch(assessmentService.newAssessment());
        }
    };

    const handleFilterClicked = () => {
        setIsFilterVisible(!isFilterVisible);
    };

    const handleSearchClicked = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const executeDownloadCsv = async (getKitCsv: boolean): Promise<void> => {
        if (totalCases && totalCases <= CASE_LIST_MAX_ITEMS_CSV) {
            setIsLoadingReport(true);
            if (getKitCsv) {
                await downloadKitCsvReport();
            } else {
                await downloadCsvReport();
            }
            setIsLoadingReport(false);
        } else {
            setModalParameters({
                content: (
                    <FocusTrap>
                        <div className="confirm-modal-wrapper">
                            <Modal.Header className="confirm-modal-header">
                                Maximum number of Cases exceeded ({CASE_LIST_MAX_ITEMS_CSV}).
                            </Modal.Header>
                            <p>
                                Please consider shortening the date range or applying more filters to decrease the
                                number of Cases in your query.
                            </p>
                            <CustomButton variant="filled" type="submit" action={closeModal} text="Close" />
                        </div>
                    </FocusTrap>
                ),
                isVisible: true,
                size: "small",
            });
        }
    };

    const handleDownloadCsvClicked = async (): Promise<void> => {
        await executeDownloadCsv(false);
    };

    const handleDownloadKitToDispatchCsvClicked = async (): Promise<void> => {
        await executeDownloadCsv(true);
    };

    const { filters } = config;
    const withFilters = Boolean(filters.length);
    const header = isCurrentUserPatient ? "My Cases" : "Cases";
    const withTabs = !isCurrentUserPatient;
    const computerHeaderWidth = withTabs ? 1 : 16;

    return (
        <>
            <SettingsValidation organisation={organisation} errorCallback={errorCallback} />
            <Grid columns="3" className="case-list-actions">
                <Grid.Row>
                    <Grid.Column mobile={16} computer={computerHeaderWidth}>
                        <Header as="h2">{header}</Header>
                    </Grid.Column>
                    {withTabs && (
                        <Grid.Column mobile={16} computer={15}>
                            <CaseListTabs />
                        </Grid.Column>
                    )}
                </Grid.Row>
                <Grid.Row className="case-list-header__action-button-row">
                    <Grid.Column width={8} floated="right">
                        <div className="case-list-header__action-buttons">
                            {isCurrentUserSuperAdmin && activeTab === CaseListTabsModel.TO_DISPATCH && (
                                <Button
                                    onClick={handleDownloadKitToDispatchCsvClicked}
                                    disabled={isLoadingReport || !caseLists[TAB_STATE[activeTab]]?.sort}
                                    loading={isLoadingReport}
                                >
                                    Kits to dispatch .csv
                                </Button>
                            )}
                            {isCurrentUserSuperAdmin && (
                                <Button
                                    onClick={handleDownloadCsvClicked}
                                    disabled={isLoadingReport || !caseLists[TAB_STATE[activeTab]]?.sort}
                                    loading={isLoadingReport}
                                >
                                    Download .csv
                                </Button>
                            )}
                            <>
                                {withFilters && (
                                    <Button basic={!isFilterVisible} onClick={handleFilterClicked}>
                                        <Icon name="filter" />
                                        Filter
                                    </Button>
                                )}
                                <Button basic={!isSearchVisible} onClick={handleSearchClicked}>
                                    <Icon name="search" />
                                    Search
                                </Button>
                            </>
                            {showAssessmentButtons &&
                                (!isNonSkinCancerOrganisation || hideGeneralDermatologyPopup) &&
                                !isCurrentUserClientAdmin &&
                                !isCurrentUserClinicalViewer && (
                                    <CustomButton
                                        type="link"
                                        variant="filled"
                                        to={ASSESSMENT_MODE}
                                        text="New case"
                                        icon="plus"
                                        action={handleNewAssessmentClicked}
                                        disabled={isErrorBlockingAssessment}
                                    />
                                )}
                            {showAssessmentButtons &&
                                isNonSkinCancerOrganisation &&
                                !hideGeneralDermatologyPopup &&
                                !isCurrentUserClientAdmin &&
                                !isCurrentUserClinicalViewer && (
                                    <CustomButton
                                        type="submit"
                                        variant="filled"
                                        text="New case"
                                        icon="plus"
                                        action={handleNewAssessmentClicked}
                                        disabled={isErrorBlockingAssessment}
                                    />
                                )}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                {isCurrentUserPatient && <Divider />}
                {isFilterVisible && (
                    <div className="case-list-filters-row">
                        <CaseListFilters filters={filters} />
                    </div>
                )}
                {isSearchVisible && (
                    <div className="case-list-filters-row">
                        <CaseListSearchRow />
                    </div>
                )}
                {showExclusionsPopup && <GeneralTeledermatologyExclusionsPopup showDialog={showExclusionsPopup} />}
            </Grid>
        </>
    );
};

export default CaseListHeader;
