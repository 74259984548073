import React, { FC } from "react";
import { Form, Radio } from "semantic-ui-react";

import { ERROR_FIELD_IS_REQUIRED } from "helpers/common";

import { IRegistrationQuestion, IRegistrationQuestionOption } from "model/registration";

interface IExceptionQuestion {
    question: IRegistrationQuestion;
    onChange?: (answerId: string) => void;
    selectedAnswerUuid?: string;
    error?: boolean;
}

const ExceptionQuestion: FC<IExceptionQuestion> = ({
    question,
    onChange = () => undefined,
    selectedAnswerUuid,
    error,
}) => {
    const { options } = question;
    const optionsPrepared = options
        .filter((option: IRegistrationQuestionOption) => !option.removed)
        .sort((a: IRegistrationQuestionOption, b: IRegistrationQuestionOption) => a.optionOrder - b.optionOrder);

    const getChangeHandler = (uuid: string) => () => onChange(uuid);

    return (
        <div className="exception-question">
            <div className="custom-radio">
                <Form key={question.uuid} className={question.required ? "is-required" : ""}>
                    <p className="p">{question.question}</p>
                    <div className="options centered">
                        {optionsPrepared.map(({ option, uuid }: IRegistrationQuestionOption) => (
                            <Radio
                                key={uuid}
                                name={option}
                                label={option}
                                checked={selectedAnswerUuid === uuid}
                                onChange={getChangeHandler(uuid)}
                            />
                        ))}
                    </div>
                    {error && <div className="is-error">{ERROR_FIELD_IS_REQUIRED}</div>}
                </Form>
            </div>
        </div>
    );
};

ExceptionQuestion.defaultProps = {
    onChange: () => undefined,
    selectedAnswerUuid: "",
    error: false,
};

export default ExceptionQuestion;
