import { createActions } from "redux-actions";

import types from "redux/actions/types";

export const {
    loginRequest,
    setToken,
    loginSuccess,
    loginFail,
    refreshAuthToken,
    logoutRequest,
    logoutSuccess,
    logoutFail,
    clearData,
    changePasswordSuccess,
    changePasswordError,
    clearPasswordResponse,
    changePasswordReset,
    setResetPasswordModalType,
    updatePassword,
    updatePasswordShowPrompt,
    updatePasswordReset,
} = createActions(
    types.LOGIN_REQUEST,
    types.SET_TOKEN,
    types.LOGIN_SUCCESS,
    types.LOGIN_FAIL,
    types.REFRESH_AUTH_TOKEN,
    types.LOGOUT_REQUEST,
    types.LOGOUT_SUCCESS,
    types.LOGOUT_FAIL,
    types.CLEAR_DATA,
    types.CHANGE_PASSWORD_SUCCESS,
    types.CHANGE_PASSWORD_ERROR,
    types.CLEAR_PASSWORD_RESPONSE,
    types.CHANGE_PASSWORD_RESET,
    types.SET_RESET_PASSWORD_MODAL_TYPE,
    types.UPDATE_PASSWORD,
    types.UPDATE_PASSWORD_SHOW_PROMPT,
    types.UPDATE_PASSWORD_RESET
);
