import { FC } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";

import ConditionalRender from "components/templates/ConditionalRender";

import { UserRole } from "model/userRole";
import { AdministrationPages } from "model/administrationPages";

import { ADMINISTRATION, HOME } from "navigation/routes";

interface IAdministrationNavigation {
    activeItem: AdministrationPages;
}

const AdministrationNavigation: FC<IAdministrationNavigation> = ({ activeItem }) => (
    <Menu secondary vertical>
        <ConditionalRender requiredRole={[UserRole.SUPERADMIN, UserRole.ADMIN]}>
            <Menu.Item
                name="Reports and Analytics"
                as={Link}
                to={`${ADMINISTRATION}/${AdministrationPages.REPORTS}`}
                active={activeItem === AdministrationPages.REPORTS}
            />
        </ConditionalRender>
        <ConditionalRender requiredRole={[UserRole.SUPERADMIN]}>
            <>
                <Menu.Item
                    name="Case Report"
                    as={Link}
                    to={`${ADMINISTRATION}/${AdministrationPages.CASE_REPORT}`}
                    active={activeItem === AdministrationPages.CASE_REPORT}
                />
                <Menu.Item
                    name="User Management"
                    as={Link}
                    to={`${ADMINISTRATION}/${AdministrationPages.USER_MANAGEMENT}`}
                    active={activeItem === AdministrationPages.USER_MANAGEMENT}
                />
                <Menu.Item
                    name="Organisation Management"
                    as={Link}
                    to={`${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}`}
                    active={activeItem === AdministrationPages.ORGANISATION_MANAGEMENT}
                />
                <Menu.Item
                    name="Study Management"
                    as={Link}
                    to={`${ADMINISTRATION}/${AdministrationPages.STUDY_MANAGEMENT}`}
                    active={activeItem === AdministrationPages.STUDY_MANAGEMENT}
                />
            </>
        </ConditionalRender>
        <Menu.Item name="Home" as={Link} to={HOME} className="menu-item-home" />
    </Menu>
);

export default AdministrationNavigation;
