import React, { FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "components/templates/Button";

import { ReportAvailableActions } from "contextProviders/modules/reportAvailable/actions";
import { ReportAvailableContext } from "contextProviders/ReportAvailableProvider";

import { getFieldFromTemplate, getTemplate } from "helpers/template";

import { TemplateType, WhatHappensNextTemplateKeys } from "model/templateType";
import { RemoteModelManagementOutcomes } from "model/assessment";

import { getCurrentCase } from "redux/selectors/assessment";
import { getOrganisation } from "redux/selectors/data";

import * as caseService from "services/caseService";

interface IWhatHappensNext {
    recommendationType: RemoteModelManagementOutcomes;
    isCallbackNeeded: boolean;
}

const WhatHappensNext: FC<IWhatHappensNext> = ({ recommendationType, isCallbackNeeded }) => {
    const isRefferal = recommendationType === RemoteModelManagementOutcomes.REFERRAL;
    const {
        state: { isDownloadReportErrorVisible, isSaveReportButtonAvailable },
        dispatch: dispatchReportAvailable,
    } = useContext(ReportAvailableContext);
    const currentCase = useSelector(getCurrentCase);
    const organisation = useSelector(getOrganisation);
    const dispatch = useDispatch();

    const handleSaveReportClicked = () => {
        const { uuid } = currentCase;
        if (isSaveReportButtonAvailable) {
            dispatch(caseService.downloadReport(uuid));
        } else {
            dispatchReportAvailable({
                type: ReportAvailableActions.SET_DOWNLOAD_REPORT_ERROR_VISIBLE,
            });
        }
    };

    const whatHappensNextOrganisationTemplate = getTemplate(TemplateType.WHAT_HAPPENS_NEXT_REMOTE_MODEL, organisation);
    const templateKey = isRefferal
        ? WhatHappensNextTemplateKeys.REFERRAL_TEXT
        : WhatHappensNextTemplateKeys.DISCHARGE_TEXT;
    const whatHappensNextText = getFieldFromTemplate(
        templateKey,
        currentCase,
        organisation,
        whatHappensNextOrganisationTemplate
    );
    const saveReportButtonStyle = isSaveReportButtonAvailable ? "filled" : "dark";

    return (
        <>
            <p>{whatHappensNextText}</p>
            <div className="buttons-wrapper">
                <CustomButton
                    variant={saveReportButtonStyle}
                    type="button"
                    action={handleSaveReportClicked}
                    text="Save report as a PDF"
                    disabled={isCallbackNeeded}
                />
                {isDownloadReportErrorVisible && (
                    <p className="remote-model-report-error">
                        You must click the button above to certify that you understand your results before saving the
                        report.
                    </p>
                )}
            </div>
        </>
    );
};

export default WhatHappensNext;
