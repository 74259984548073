export const FITZPATRICK_QUESTION = "Response to sun exposure \\(Fitzpatrick\\)";

export enum FixedQuestion {
    FITZPATRICK_SKIN_TYPE = "FITZPATRICK_SKIN_TYPE",
}

export enum FitzpatrickAnswers {
    TYPE_1_OPTION = "FST 1: Always burns. Never tans.",
    TYPE_2_OPTION = "FST 2: Almost always burns. Tans rarely, if at all.",
    TYPE_3_OPTION = "FST 3: Sometimes burns after long or intense sun exposure. Tans gradually to olive.",
    TYPE_4_OPTION = "FST 4: Could possibly burn after lengthy exposure to intense sunshine. Tans with ease to moderate brown.",
    TYPE_5_OPTION = "FST 5: Rarely burns, if at all. Almost always tans.",
    TYPE_6_OPTION = "FST 6: Never burns. Always Tans.",
}

export type FitzpatrickAnswersType =
    | FitzpatrickAnswers.TYPE_1_OPTION
    | FitzpatrickAnswers.TYPE_2_OPTION
    | FitzpatrickAnswers.TYPE_3_OPTION
    | FitzpatrickAnswers.TYPE_4_OPTION
    | FitzpatrickAnswers.TYPE_5_OPTION
    | FitzpatrickAnswers.TYPE_6_OPTION;

// FUTURE STANDARDISED ANSWERS
export enum SkinTypeAnswerKeys {
    TYPE_1 = "FST 1: Always burns. Never tans.",
    TYPE_2 = "FST 2: Almost always burns. Tans rarely, if at all.",
    TYPE_3 = "FST 3: Sometimes burns after long or intense sun exposure. Tans gradually to olive.",
    TYPE_4 = "FST 4: Could possibly burn after lengthy exposure to intense sunshine. Tans with ease to moderate brown.",
    TYPE_5 = "FST 5: Rarely burns, if at all. Almost always tans.",
    TYPE_6 = "FST 6: Never burns. Always Tans.",
}

export enum SkinToneTypes {
    TYPE_1 = "FST 1",
    TYPE_2 = "FST 2",
    TYPE_3 = "FST 3",
    TYPE_4 = "FST 4",
    TYPE_5 = "FST 5",
    TYPE_6 = "FST 6",
}

export enum SkinToneDescriptor {
    TYPE_1 = "Always burns. Never tans.",
    TYPE_2 = "Almost always burns. Tans rarely, if at all.",
    TYPE_3 = "Sometimes burns after long or intense sun exposure. Tans gradually to olive",
    TYPE_4 = "Could possibly burn after lengthy exposure to intense sunshine. Tans with ease to moderate brown.",
    TYPE_5 = "Rarely burns, if at all. Almost always tans.",
    TYPE_6 = "Never burns. Always Tans.",
}

export type FixedQuestionType = FixedQuestion.FITZPATRICK_SKIN_TYPE;
