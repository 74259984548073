import { FC, Fragment, useState } from "react";
import { Divider, Grid, Message } from "semantic-ui-react";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import QuestionaryItemQuestion from "components/QuestionaryItemQuestion";
import FitzpatrickQuestion from "components/QuestionaryItemQuestion/FitzpatrickQuestionaryItemQuestion";
import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";

import { noteExceedsLimit } from "helpers/noteValidation";
import { mapStateToAnswers, validateAnswersCommon } from "helpers/questionary";

import { IQuestionaryError } from "model/questionaryError";
import { IHistoryQuestion, IMedicalHistoryQuestion, IQuestionaryAnswers } from "model/organisation";
import { IMedicalHistoryAnswers } from "model/assessment";
import { Recaptcha } from "model/atHomeFlow";
import IQuestionaryAnswerValidation from "model/questionaryAnswerValidation";

import { getUser } from "redux/selectors/data";
import { getRegistration } from "redux/selectors/registration";

interface IQuestionaryButtons {
    isDisabled?: boolean;
    isLoading?: boolean;
    text: string;
    action?: ({
        mainData,
        extraData,
    }: {
        mainData: IMedicalHistoryAnswers[];
        extraData: IMedicalHistoryAnswers[];
    }) => void;
    link?: string;
    type?: "link" | "submit" | "button";
}

interface IQuestionary {
    questionSets: {
        questions: any[];
        label?: string;
    }[];
    errors: IQuestionaryError;
    isBack?: boolean;
    answers: IQuestionaryAnswers;
    updateAnswers: (answers: IQuestionaryAnswers, isEdited?: boolean) => void;
    updateErrors: (errors: IQuestionaryError, question?: IHistoryQuestion) => void;
    backButton?: IQuestionaryButtons;
    nextButtons: IQuestionaryButtons[];
    isRemoteModel?: boolean;
    recaptcha?: Recaptcha;
    isFitzpatrick?: boolean;
    resetAnswer?: boolean;
}

const Questionary: FC<IQuestionary> = ({
    questionSets,
    errors,
    isBack = false,
    answers,
    updateAnswers,
    updateErrors,
    backButton,
    nextButtons,
    isRemoteModel,
    recaptcha,
    isFitzpatrick,
    resetAnswer,
}) => {
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    const validateAnswers = ({ showError, question }: IQuestionaryAnswerValidation) =>
        validateAnswersCommon(answers, questionSets, errors, updateErrors, showError, question);

    const validateAdditionalInformationLength = (additionalInformation: string): void => {
        const hasNoteExceededLimit = noteExceedsLimit(additionalInformation);
        setDisableSubmit(hasNoteExceededLimit);
    };

    const user = useSelector(getUser);
    const registration = useSelector(getRegistration);

    const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;
    const optionalNotes = !isRemoteModel || Boolean(isAtHomeFlow);
    const remoteFlowNextButton = nextButtons[0];

    const buttonHandler = (action: (data: any) => void) => {
        const isValid = validateAnswers({ showError: true });
        if (isValid) {
            const data = mapStateToAnswers(answers);
            action(data);
        }
    };

    const nextButtonHandler = () => {
        if (remoteFlowNextButton.action) {
            buttonHandler(remoteFlowNextButton.action);
        }
    };

    return (
        <>
            <Grid disabled={isBack}>
                {questionSets.map((questionSet, index) => {
                    const { label, questions } = questionSet;

                    if (isFitzpatrick && questions[0]) {
                        return (
                            <FitzpatrickQuestion
                                isBack={isBack}
                                key={questions[0].uuid}
                                question={questions[0]}
                                answers={answers}
                                updateAnswers={updateAnswers}
                                validateAnswers={validateAnswers}
                                validateAdditionalInformationLength={validateAdditionalInformationLength}
                                resetAnswer={resetAnswer}
                                isRemoteModel
                                user={user}
                            />
                        );
                    }

                    return (
                        <Fragment key={label || index}>
                            {label && <h2>{label}</h2>}

                            {questions
                                .sort(
                                    (a: IMedicalHistoryQuestion, b: IMedicalHistoryQuestion) =>
                                        a.questionOrder - b.questionOrder
                                )
                                .map((question) => {
                                    const { uuid } = question;
                                    return (
                                        <QuestionaryItemQuestion
                                            key={uuid}
                                            question={question}
                                            answers={answers}
                                            isBack={isBack}
                                            errors={errors}
                                            updateAnswers={updateAnswers}
                                            validateAnswers={validateAnswers}
                                            optionalNotes={optionalNotes}
                                            isRemoteModel={isRemoteModel}
                                            isAtHomeFlow={isAtHomeFlow}
                                        />
                                    );
                                })}
                        </Fragment>
                    );
                })}
            </Grid>
            {isAtHomeFlow && recaptcha && !user && (
                <div className="recaptcha-wrapper">
                    <ReCAPTCHA
                        ref={recaptcha.ref}
                        size="invisible"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    />
                </div>
            )}
            {isAtHomeFlow && recaptcha?.errored && errors?.showError && (
                <>
                    <Message error content="Please validate Google Recaptcha before proceeding" />
                </>
            )}

            {isRemoteModel ? (
                <div className="buttons-wrapper remote-model__review-row">
                    {Boolean(backButton) && (
                        <CustomButton
                            type={backButton.action ? "submit" : "link"}
                            variant="empty-dark"
                            to={backButton.link}
                            text={backButton.text}
                            action={backButton.action}
                        />
                    )}
                    <CustomButton
                        type="button"
                        variant="filled"
                        action={nextButtonHandler}
                        loading={remoteFlowNextButton.isLoading}
                        disabled={remoteFlowNextButton.isDisabled || disableSubmit}
                        text={remoteFlowNextButton.text}
                    />
                </div>
            ) : (
                <div className="questionary-wrapper">
                    <Divider />
                    <ButtonContainer
                        button1={
                            <CustomButton
                                variant="empty"
                                type="link"
                                to={backButton.link}
                                text={backButton.text}
                                size="small"
                                disabled={backButton.isDisabled}
                            />
                        }
                        button2={
                            <div className="two fields">
                                {nextButtons.map((nextButton, index) => {
                                    const handleButton = () =>
                                        nextButton.type === "submit" && buttonHandler(nextButton.action);

                                    return (
                                        <CustomButton
                                            key={nextButton.link || index}
                                            variant="filled"
                                            type={nextButton.type || "submit"}
                                            disabled={nextButton.isDisabled || disableSubmit}
                                            action={handleButton}
                                            text={nextButton.text}
                                            loading={nextButton.isLoading}
                                            to={nextButton.link}
                                        />
                                    );
                                })}
                            </div>
                        }
                    />
                </div>
            )}
        </>
    );
};

Questionary.defaultProps = {
    isBack: false,
    backButton: null,
    isRemoteModel: false,
    recaptcha: {
        token: "",
        errored: false,
        ref: null,
    },
    isFitzpatrick: false,
};

export default Questionary;
