import { FC } from "react";
import { Image } from "semantic-ui-react";
import FocusTrap from "focus-trap-react";

import withModal from "HOC/withModal";

import { ModalIconAltTexts } from "model/modal";

interface IEmailConfirmationModal {
    email: string;
    goBack: () => void;
    passwordPage?: boolean;
}

const EmailConfirmationModal: FC<IEmailConfirmationModal> = ({ email, goBack, passwordPage }) => (
    <FocusTrap>
        <div className="remote-model__modal">
            <Image alt={ModalIconAltTexts.SUCCESS} src="/images/icons/icon-okay.svg" />
            <p className="h2 check-email-text">
                We sent you a link to <br /> {email}
            </p>
            <p className="h2">Click the link in the email to continue creating your account.</p>

            <p className="additional-info">
                <i>
                    If you didn’t receive an email, or the email address above is wrong, you can{" "}
                    <button type="button" className="is-button-link" onClick={goBack}>
                        go back and {passwordPage ? "try again" : "edit it"}.
                    </button>
                </i>
            </p>
        </div>
    </FocusTrap>
);

EmailConfirmationModal.defaultProps = {
    passwordPage: false,
};

export default withModal(EmailConfirmationModal);
