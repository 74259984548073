import { connect } from "react-redux";

import LesionLocator from "components/LesionLocator/LesionLocator";

import lesionService from "services/lesionService";
import * as caseService from "services/caseService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    case: state.assessment.case,
    currentLesion: state.assessment.currentLesion,
    organisation: state.data.organisation,
    patient: state.assessment.patient,
    pendingRequest: state.data.pendingRequest,
});

const mapDispatchToProps = {
    createLesion: lesionService.createLesion,
    updateLesion: lesionService.updateLesion,
    getCaseForAssessment: caseService.getCaseForAssessment,
    setLesion: caseService.setLesion,
};

export default connect(mapStateToProps, mapDispatchToProps)(LesionLocator);
