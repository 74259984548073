import { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Divider, Grid } from "semantic-ui-react";
import Dropzone from "react-dropzone";

import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import ImageManualUploadDialog from "components/templates/dialog/ImageManualUpload";
import PatientBanner from "components/templates/PatientBanner";

import { IAssessment } from "model/assessment";
import { UserRole } from "model/userRole";
import { ILesion } from "model/case";
import { CaptureImageType } from "model/captureImageType";

import { CASE_DESCRIPTION, IMAGE_CAPTURE, READY_FOR_ASSESSMENT } from "navigation/routes";
import { history } from "App";

import { getAssessment } from "redux/selectors/assessment";

import * as caseService from "services/caseService";
import logoutTimeoutService from "services/logoutTimeoutService";
import userService from "services/userService";
import lesionService from "services/lesionService";

const CHECK_IMAGES_AVAILABLE_INTERVAL = 3000;

interface IImageManualUpload {
    assessment: IAssessment;
}

enum Mode {
    Context,
    Dermoscopic,
}

const ImageManualUpload: FC<IImageManualUpload> = () => {
    const [image, setImage] = useState<any>();
    const [imageManualUploadDialogOpened, setImageManualUploadDialogOpened] = useState(false);

    const assessment = useSelector(getAssessment);
    const { case: currentCase, lesion, isManualUploadRemoteMode } = assessment;

    const currentLesionObject = currentCase.lesions?.find((currentLesion) => currentLesion.uuid === lesion.uuid);
    const { uuid: caseUuid, caseId } = currentCase;

    const [mode, setMode] = useState<Mode>(
        isManualUploadRemoteMode &&
            currentLesionObject?.images?.filter((lesionImage) => lesionImage.imageType !== CaptureImageType.DERMOSCOPIC)
                .length > 1
            ? Mode.Dermoscopic
            : Mode.Context
    );
    const [imageCounter, setImageCounter] = useState(
        currentLesionObject?.images ? currentLesionObject.images.length + 1 : 1
    );
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [finishedUpload, setFinishedUpload] = useState<boolean>(false);
    const [intervalId, setIntervalId] = useState<number>();

    useEffect(() => {
        logoutTimeoutService.stopCount();
    }, []);

    useEffect(
        () => () => {
            clearInterval(intervalId);
        },
        [intervalId]
    );
    const { patient, nonSkinCancer: isNonSkinCancerFlow, currentLesion } = assessment;
    const { uuid: lesionUuid } = lesion;
    const { uuid: patientUuid } = patient;

    const isSuperAdmin = userService.checkUserHasRole([UserRole.SUPERADMIN]);

    if (!isSuperAdmin && !isNonSkinCancerFlow) {
        return null;
    }

    const onDropImage = (acceptedFiles: any) => {
        if (acceptedFiles && acceptedFiles[0] && !image) {
            setError("");
            setImage(
                Object.assign(acceptedFiles[0], {
                    preview: URL.createObjectURL(acceptedFiles[0]),
                })
            );
        }
    };

    const thumb = image ? <img key={image.name} src={URL.createObjectURL(image)} className="" alt="" /> : null;

    const toggleImageManualUploadDialog = () => setImageManualUploadDialogOpened(!imageManualUploadDialogOpened);

    const addNewImage = () => {
        setImageCounter(imageCounter + 1);
        toggleImageManualUploadDialog();
        setImage(undefined);
    };

    const switchDermoscopic = () => {
        setImageCounter(1);
        setMode(Mode.Dermoscopic);
        toggleImageManualUploadDialog();
        setImage(undefined);
    };

    const remoteFlowManualUploadNextAction = () => {
        if (imageCounter >= 2 && mode === Mode.Context) {
            switchDermoscopic();
        } else {
            addNewImage();
        }
    };

    const discardImage = () => setImage(undefined);

    const getFormData = () => {
        if (image) {
            const reader = new FileReader();
            reader.readAsDataURL(image);
            const formData = new FormData();
            formData.append("image", image);
            formData.append("caseUuid", caseUuid);
            formData.append("patientUuid", patientUuid);
            formData.append("lesionUuid", lesionUuid);
            formData.append("deviceUuid", "");
            setIsLoading(true);

            return formData;
        }
        return null;
    };

    const getError = (e: any) => {
        setImage(undefined);
        setIsLoading(false);
        setError(e.message);
    };

    const saveImage = () => {
        const formData = getFormData();

        caseService
            .uploadMacroImage(formData)
            .then(() => {
                toggleImageManualUploadDialog();
                setIsLoading(false);
            })
            .catch((e) => {
                getError(e);
            });
    };

    const checkIsImagesAvailable = async () => {
        if (currentCase && lesion) {
            const lesionTemp: ILesion = await lesionService.getLesion(lesionUuid);

            if (lesionTemp) {
                if (lesionTemp.images) {
                    const containsMacroImage = lesionTemp.images.find(
                        (imageLession: any) => imageLession.type === CaptureImageType.MACRO
                    );
                    const containsDermImage = lesionTemp.images.find(
                        (imageLession: any) => imageLession.type === CaptureImageType.DERMOSCOPIC
                    );
                    if ((containsMacroImage && !isNonSkinCancerFlow && containsDermImage) || isNonSkinCancerFlow) {
                        history.push(READY_FOR_ASSESSMENT);
                    }
                }
            }
        }
    };

    const finishUpload = async () => {
        const formData = getFormData();

        if (!isNonSkinCancerFlow) {
            await caseService.uploadDermoscopicImage(formData);
        }

        const interval = window.setInterval(checkIsImagesAvailable, CHECK_IMAGES_AVAILABLE_INTERVAL);
        setIntervalId(interval);
    };

    const onFinishUploadNonSkinCancer = () => {
        setImageManualUploadDialogOpened(false);
        setFinishedUpload(true);
    };

    const renderButtons = (open: any) => {
        if (image && !finishedUpload) {
            return (
                <>
                    <CustomButton variant="empty" type="button" action={discardImage} text="Discard" size="small" />

                    {mode === Mode.Context ? (
                        <CustomButton
                            variant="filled"
                            type="button"
                            action={saveImage}
                            text="Save image"
                            size="small"
                            loading={isLoading}
                        />
                    ) : null}
                </>
            );
        }

        return (
            !finishedUpload && (
                <CustomButton variant="filled" type="button" action={open} text="Find the file" size="small" />
            )
        );
    };

    const onBack = () => {
        if (isManualUploadRemoteMode) {
            history.push(`${CASE_DESCRIPTION}/${caseUuid}`);
        } else {
            history.push(IMAGE_CAPTURE);
        }
    };

    return (
        <Container className="ui segment wizzard-container">
            {imageManualUploadDialogOpened && (
                <ImageManualUploadDialog
                    onContextImageClick={addNewImage}
                    onDermoscopicImageClick={switchDermoscopic}
                    callback={toggleImageManualUploadDialog}
                    showDialog={imageManualUploadDialogOpened}
                    disableBackdropClick
                    dissableUploadDermoscopic={isNonSkinCancerFlow}
                    onFinishUpload={onFinishUploadNonSkinCancer}
                    isManualUpdateRemoteMode={isManualUploadRemoteMode}
                    remoteFlowManualUploadNextAction={remoteFlowManualUploadNextAction}
                />
            )}

            <PatientBanner
                caseId={caseId}
                patient={patient}
                lesion={lesion}
                lesionNumber={currentLesion}
                nonSkinCancerFlow={isNonSkinCancerFlow}
            />

            <h1>Manual Upload</h1>
            <h2>
                {!isNonSkinCancerFlow && `Lesion ${currentLesion + 1} -`} Upload{" "}
                {mode === Mode.Context ? "Context" : "Dermoscopic"} image {mode === Mode.Context && `#${imageCounter}`}
            </h2>
            <p>Use the drag and drop box or click on &quot;Find the file&quot; to select the file from your device.</p>

            <Divider />
            <Grid>
                <Grid.Column>
                    <Dropzone maxFiles={1} onDrop={onDropImage} accept="image/jpg, image/jpeg, image/png">
                        {({ getRootProps, getInputProps, open }) => (
                            <div>
                                <div {...getRootProps()} className="dropzone">
                                    <input {...getInputProps()} className="" />

                                    {thumb || <p>Drag &apos;n&apos; drop some files here, or click to select files</p>}
                                </div>

                                <div className="upload-image-buttons">{renderButtons(open)}</div>
                            </div>
                        )}
                    </Dropzone>
                </Grid.Column>
            </Grid>

            {error && (
                <div className="error-message-div">
                    <p className="case-error">{error}</p>
                </div>
            )}

            <div className="case-buttons">
                <Divider />
                <ButtonContainer
                    button1={<CustomButton variant="empty" type="button" action={onBack} text="< Back" size="small" />}
                    button2={
                        (mode === Mode.Dermoscopic || finishedUpload) && image ? (
                            <CustomButton
                                variant="filled"
                                type="button"
                                action={finishUpload}
                                text="Finish"
                                size="small"
                                loading={isLoading}
                            />
                        ) : (
                            <></>
                        )
                    }
                />
            </div>
        </Container>
    );
};

export default ImageManualUpload;
