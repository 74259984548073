import React, { FC } from "react";
import { Message } from "semantic-ui-react";

import { formatTime, formatTimeWithHours, formatTimeWithHoursWithMinutes } from "helpers/datetime";
import isPASIntegration from "helpers/PASintegration";

import { ICase, IPatientDataUpdate, IPatientNote, IReview } from "model/case";
import { DefinedPersonalDataType, IOrganisation } from "model/organisation";
import { CallbackOutcomesValues } from "model/remoteModel";
import { useSelector } from "react-redux";
import { getOrganisation } from "redux/selectors/data";

interface ICaseInfoPanelProps {
    caseObject: ICase;
    patientOrganisation?: IOrganisation;
}

interface IReviewChange {
    reason: string;
    caseManagementOutcomeChangeText?: string;
    firstCaseManagementOutcome?: string;
    latestCaseManagementOutcome?: string;
}

interface IReviewsData {
    firstManagementOutcome: string;
    latestManagementOutcome: string;
    firstDiagnosis: string;
    latestDiagnosis: string;
}

const CaseInfoPanel: FC<ICaseInfoPanelProps> = ({ caseObject, patientOrganisation }) => {
    const { patient, callbackOutcome } = caseObject;
    const { patientNotes, integrationInfo } = { ...patient };
    const showPatientInfoEditedBy = !isPASIntegration(integrationInfo?.integrationName);
    const callbackManagementOutcomeChanged = callbackOutcome?.find(
        (outcome) => outcome.outcome === CallbackOutcomesValues.CHANGE_MANAGEMENT_OUTCOME
    );
    const organisation = useSelector(getOrganisation);
    const currentPatientOrganisation: IOrganisation = patientOrganisation || organisation;

    const isPatientDataChanged = Boolean(
        patientNotes?.length && patientNotes.find((patientNote) => patientNote.patientDataUpdates)
    );
    const isManagementOutcomeChanged = Boolean(callbackManagementOutcomeChanged);

    if (!isPatientDataChanged && !isManagementOutcomeChanged) {
        return null;
    }

    const getChangeText = (change: IPatientDataUpdate): string => {
        const isDateType = change.type === DefinedPersonalDataType.DATE;
        const previousValue =
            isDateType && change.previousValue ? formatTime(change.previousValue) : change.previousValue;
        const value = isDateType ? formatTime(change.value) : change.value;

        return `changed from ${previousValue ? `"${previousValue}"` : "N/A"} to "${value}"`;
    };

    const getReviewsData = (reviews: IReview[]): IReviewsData => {
        const firstReview = reviews[0];
        const latestReviewIndex = reviews.length - 1;
        const latestReview = reviews[latestReviewIndex];

        return {
            firstManagementOutcome: firstReview.reviewManagement,
            latestManagementOutcome: latestReview.reviewManagement,
            firstDiagnosis: firstReview.reviewDiagnosis,
            latestDiagnosis: latestReview.reviewDiagnosis,
        };
    };

    const getReviewChange = (): IReviewChange | null => {
        if (isManagementOutcomeChanged) {
            const { clinicalNotes, reviews } = caseObject;
            const previousClinicalNote = clinicalNotes?.[clinicalNotes.length - 1];
            const { note, createdByName, lastModificationDate } = { ...previousClinicalNote };
            const reasonText = `${note} (by ${createdByName} on ${formatTimeWithHours(lastModificationDate)}`;
            const result: IReviewChange | null = {
                reason: reasonText,
            };
            const { firstManagementOutcome, latestManagementOutcome } = getReviewsData(reviews);
            result.caseManagementOutcomeChangeText = "Case management outcome";
            result.firstCaseManagementOutcome = firstManagementOutcome;
            result.latestCaseManagementOutcome = latestManagementOutcome;

            return result;
        }

        return null;
    };

    const reviewChange = getReviewChange();
    const { caseManagementOutcomeChangeText, firstCaseManagementOutcome, latestCaseManagementOutcome, reason } = {
        ...reviewChange,
    };
    const withCaseMenagementOutcomeChange = Boolean(caseManagementOutcomeChangeText);
    const managementOutcomeChangeText = getChangeText({
        previousValue: firstCaseManagementOutcome,
        value: latestCaseManagementOutcome,
    } as IPatientDataUpdate);

    return (
        <Message warning>
            <Message.Header>Case information edited</Message.Header>
            {isPatientDataChanged && (
                <>
                    {[...patientNotes].reverse().map((note: IPatientNote) => {
                        const { uuid, patientDataUpdates, createdByName, creationDate, note: textNote } = note;

                        if (!patientDataUpdates) {
                            return null;
                        }

                        return (
                            <Message key={uuid}>
                                {patientDataUpdates?.map((change: IPatientDataUpdate) => {
                                    const { fieldName } = change;

                                    const changedProperty = currentPatientOrganisation.definedPersonalData.find(
                                        (personalDataField) => personalDataField.propertyName === fieldName
                                    );

                                    return (
                                        <p key={`change-${fieldName}`}>
                                            <b>{changedProperty?.displayName || ""}</b>
                                            &nbsp;{getChangeText(change)}
                                        </p>
                                    );
                                })}
                                <p>
                                    <b>Reason for edit: </b>
                                    {textNote}
                                    {showPatientInfoEditedBy &&
                                        ` (by ${createdByName} on ${formatTimeWithHoursWithMinutes(creationDate)})`}
                                </p>
                            </Message>
                        );
                    })}
                </>
            )}

            {isManagementOutcomeChanged && (
                <>
                    {withCaseMenagementOutcomeChange && (
                        <p>
                            <b>{caseManagementOutcomeChangeText}</b> {managementOutcomeChangeText}
                        </p>
                    )}
                    <p>
                        <b>Notes for Clinical use: </b>
                        {reason})
                    </p>
                </>
            )}
        </Message>
    );
};

CaseInfoPanel.defaultProps = {
    patientOrganisation: null,
};

export default CaseInfoPanel;
