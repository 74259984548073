import { FC, KeyboardEvent, useState, useContext, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Icon, Table } from "semantic-ui-react";

import CaseListTableRow from "components/CaseList/CaseListTableRow";
import CaseListSettings from "components/CaseList/CaseListSettings";
import CaseListHeaderItem from "components/CaseList/CaseListTableHeaderItem";
import CaseListSnackbar from "components/CaseList/CaseListSnackbar";
import useColumnSort from "components/CaseList/hooks/useColumnSort";
import AdministrativeNextStepsAnnouncement from "components/NewFeaturesAnnouncements/AdministrativeNextStepsAnnouncement";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { isColumnAvailable } from "helpers/caseList";

import { ICaseListSettingItem, IUserPreferencesData } from "model/caseList";
import { UserRole } from "model/userRole";

import { getUser } from "redux/selectors/data";
import { dataActions } from "redux/actions";

import userService from "services/userService";

const CaseListTable: FC = () => {
    const [columns, setColumns] = useState<{ [key: string]: ICaseListSettingItem[] }>({});
    const [settingsMenuOpened, setSettingsMenuOpened] = useState(false);
    const { currentTabCases, activeTab, fetchMore, getDefaultColumns } = useContext(CaseListContext);
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const triggerRef = useRef<any>();
    const { handleSortColumnClicked } = useColumnSort();

    const closeConfigMenu = () => {
        setSettingsMenuOpened(false);
    };

    useEffect(() => {
        closeConfigMenu();
    }, [activeTab]);

    useEffect(() => {
        if (!columns[activeTab]) {
            const { displayedColumns = [] } = { ...user.preferences };
            const userColumns = {};
            displayedColumns.forEach((displayedColumn: any) => {
                userColumns[displayedColumn.viewName] = displayedColumn.columns;
            });
            const sortedColumns = getDefaultColumns(user.role);
            setColumns({ [activeTab]: userColumns[activeTab] || sortedColumns, ...userColumns });
        }
    }, [user, activeTab]);

    const handleLoadMoreClicked = () => {
        fetchMore();
    };

    const saveColumns = async (displayColumnsData: IUserPreferencesData) => {
        await userService.updateUserPrefernces(displayColumnsData);
        dispatch(dataActions.updateUserPreferences(displayColumnsData));
    };

    const handleSaveColumns = async (currentColumns: ICaseListSettingItem[]) => {
        setColumns({ ...columns, [activeTab]: currentColumns });
        const displayColumnsData: IUserPreferencesData = {
            viewName: activeTab,
            columns: currentColumns,
        };
        await saveColumns(displayColumnsData);
    };

    const toggleConfigMenu = () => {
        setSettingsMenuOpened(!settingsMenuOpened);
    };

    const handleKeyDown = (e: KeyboardEvent, handleFunction: () => void) => {
        if (e.key === "Enter") {
            handleFunction();
        }
    };

    if (!columns[activeTab]) {
        return null;
    }

    const { data: casesData, total: totalCases, isPending: isCasesPending } = currentTabCases;
    const isEmpty = columns[activeTab].filter((column) => column.display).length === 0;

    const currentColumns = columns[activeTab];
    const { length: casesLength = 0 } = casesData ? [...casesData] : [];
    const isCurrentUserPatient = userService.checkUserHasRole([UserRole.PATIENT]);
    const showSettingsColumn = !isCurrentUserPatient;
    const additionalClassName = isEmpty ? "empty-list" : "";
    const mainClassName = showSettingsColumn ? "case-list-table-wrapper" : "";
    const footerTotalInfo = `Showing ${casesLength} of ${totalCases} cases`;
    const withLoadMoreButton = Boolean(totalCases) && casesLength < totalCases;

    return (
        <>
            <div className="case-list-container">
                <div className={`${mainClassName} ${additionalClassName}`}>
                    <Table celled striped selectable>
                        <Table.Header className="case-list-header">
                            <Table.Row>
                                {currentColumns?.map((column: ICaseListSettingItem) => {
                                    const { name: columnName, display } = column;
                                    const isAvailable = isColumnAvailable(columnName);

                                    if (!display || !isAvailable) {
                                        return null;
                                    }

                                    const handleColumnClicked = (): void => {
                                        handleSortColumnClicked(columnName);
                                    };

                                    return (
                                        <Table.HeaderCell
                                            onClick={handleColumnClicked}
                                            className="case-list-header-cell"
                                            key={columnName}
                                        >
                                            <CaseListHeaderItem
                                                columnName={columnName}
                                                isCurrentUserPatient={isCurrentUserPatient}
                                            />
                                        </Table.HeaderCell>
                                    );
                                })}

                                {showSettingsColumn && (
                                    <Table.HeaderCell
                                        className={`case-list-menu-cell case-list-header-cell case-list-cog-td headcol ${additionalClassName}`}
                                        as="td"
                                    >
                                        <div
                                            ref={triggerRef}
                                            role="button"
                                            onKeyDown={(e) => handleKeyDown(e, toggleConfigMenu)}
                                            onClick={toggleConfigMenu}
                                            tabIndex={0}
                                            aria-label={settingsMenuOpened ? "Close settings" : "Open settings"}
                                        >
                                            <Icon
                                                circular
                                                name={settingsMenuOpened ? "close" : "settings"}
                                                color="grey"
                                                inverted
                                            />
                                        </div>
                                        <CaseListSettings
                                            triggerRef={triggerRef}
                                            columns={currentColumns || []}
                                            onSaveColumns={handleSaveColumns}
                                            userRole={user.role}
                                            settingsMenuOpened={settingsMenuOpened}
                                            onClickOutside={closeConfigMenu}
                                        />
                                    </Table.HeaderCell>
                                )}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {currentColumns &&
                                casesData.map((currentCase) => (
                                    <CaseListTableRow
                                        key={currentCase.uuid}
                                        currentCase={currentCase}
                                        columns={currentColumns}
                                        currentUserRole={user.role}
                                        additionalClassName={additionalClassName}
                                        showSettingsColumn={showSettingsColumn}
                                    />
                                ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
            <div className="case-list-footer-cell">
                <Header as="h4" className="case-list-total-info">
                    {footerTotalInfo}
                </Header>
                {withLoadMoreButton && (
                    <Button
                        className="case-list-load-more-button"
                        onClick={handleLoadMoreClicked}
                        loading={isCasesPending}
                    >
                        Load 10 More
                    </Button>
                )}
                <div className="ui header case-list-total-info" />
            </div>

            <CaseListSnackbar />

            <AdministrativeNextStepsAnnouncement />
        </>
    );
};

export default CaseListTable;
