import { IMedicalHistory } from "model/assessment";
import { ICase } from "model/case";
import { FitzpatrickAnswers, FitzpatrickAnswersType } from "model/fixedQuestion";
import { IMedicalHistoryQuestion, IOrganisation } from "model/organisation";
import QuestionSubTypeEnum from "model/questionSubType";
import { SkinTypeNoteOriginator } from "model/userRole";
import { formatTimeWithHours } from "./datetime";

const fitzpatrickAnswerMap = {
    [FitzpatrickAnswers.TYPE_1_OPTION]: "#EFDDDB",
    [FitzpatrickAnswers.TYPE_2_OPTION]: "#E5C4B3",
    [FitzpatrickAnswers.TYPE_3_OPTION]: "#E5B08E",
    [FitzpatrickAnswers.TYPE_4_OPTION]: "#C07B53",
    [FitzpatrickAnswers.TYPE_5_OPTION]: "#945F37",
    [FitzpatrickAnswers.TYPE_6_OPTION]: "#3C2D20",
};

const fitzpatrickAnswerToFSTMarkup = {
    [FitzpatrickAnswers.TYPE_1_OPTION]: "FST 1",
    [FitzpatrickAnswers.TYPE_2_OPTION]: "FST 2",
    [FitzpatrickAnswers.TYPE_3_OPTION]: "FST 3",
    [FitzpatrickAnswers.TYPE_4_OPTION]: "FST 4",
    [FitzpatrickAnswers.TYPE_5_OPTION]: "FST 5",
    [FitzpatrickAnswers.TYPE_6_OPTION]: "FST 6",
};

const fitzpatrickTypeMarkup = {
    [FitzpatrickAnswers.TYPE_1_OPTION]: {
        BURN_PROFILE: "Always burns",
        TAN_PROFILE: "Never tans",
        FULL: "Always burns, never tans",
    },
    [FitzpatrickAnswers.TYPE_2_OPTION]: {
        BURN_PROFILE: "Almost always burns",
        TAN_PROFILE: "Tans rarely, if at all",
        FULL: "Almost always burns, tans rarely, if at all",
    },
    [FitzpatrickAnswers.TYPE_3_OPTION]: {
        BURN_PROFILE: "Sometimes burns after long or intense sun exposure",
        TAN_PROFILE: "Tans gradually to olive",
        FULL: "Sometimes burns after long or intense sun exposure, tans gradually to olive",
    },
    [FitzpatrickAnswers.TYPE_4_OPTION]: {
        BURN_PROFILE: "Could possibly burn after lengthy exposure to intense sunshine",
        TAN_PROFILE: "Tans with ease to moderate brown",
        FULL: "Could possibly burn after lengthy exposure to intense sunshine, tans with ease to moderate brown",
    },
    [FitzpatrickAnswers.TYPE_5_OPTION]: {
        BURN_PROFILE: "Rarely burns, if at all",
        TAN_PROFILE: "Almost always tans",
        FULL: "Rarely burns, if at all, almost always tans",
    },
    [FitzpatrickAnswers.TYPE_6_OPTION]: {
        BURN_PROFILE: "Never burns",
        TAN_PROFILE: "Always tans",
        FULL: "Never burns, always tans",
    },
};

const fitzpatrickAdditionalInformation = {
    [FitzpatrickAnswers.TYPE_1_OPTION]: `People in this category usually have the palest skin and light blue, light grey or light
    green coloured eyes. They also typically have red or light blonde hair and many freckles
    on their body. In the clinical world, this category is known as Fitzpatrick Skin Type
    (Phototype) 1.`,
    [FitzpatrickAnswers.TYPE_2_OPTION]: `People in this category usually have naturally pale skin and blue, grey or green
    coloured eyes. They could have light or dark hair and have freckles on their body. In
    the clinical world, this category is known as Fitzpatrick Skin Type (Phototype) 2.`,
    [FitzpatrickAnswers.TYPE_3_OPTION]: `People in this category usually burn only after long or intense sun exposure but
    generally tan gradually to olive. They usually have medium white to olive skin, and
    blue, green, hazel or light brown eyes. They may have dark blonde or chestnut hair and
    have a few freckles. In the clinical world, this category is known as Fitzpatrick Skin
    Type (Phototype) 3.`,
    [FitzpatrickAnswers.TYPE_4_OPTION]: `People in this category usually tan easily and could possibly burn but only after
    lengthy exposure to intense sunshine. They tend to have olive skin and brown eyes. They
    usually have dark brown hair and have very few freckles. In the clinical world, this
    category is known as Fitzpatrick Skin Type (Phototype) 4.`,
    [FitzpatrickAnswers.TYPE_5_OPTION]: `People in this category will find that their skin can darken easily with sun exposure
    and doesn’t generally burn unless exposure to the sun is very intense. They tend to have
    naturally brown skin and dark brown eyes. They usually have dark hair and have no
    freckles. In the clinical world, this category is known as Fitzpatrick Skin Type
    (Phototype) 5.`,
    [FitzpatrickAnswers.TYPE_6_OPTION]: `People in this category do not burn and always tan. They have the most deeply pigmented
    skin. They tend to have dark brown or black coloured skin, and dark brown or brownish
    black eyes. They usually have black hair and no freckles. In the clinical world, this
    category is known as Fitzpatrick Skin Type (Phototype) 6.`,
};

export const mapAnswerToColour = (fitzpatrickAnswer: string) => fitzpatrickAnswerMap[fitzpatrickAnswer];

export const mapAnswerToMarkup = (fitzpatrickAnswer: string) => fitzpatrickTypeMarkup[fitzpatrickAnswer];

export const mapAnswerToFSTMarkup = (fitzpatrickAnswer: string) => fitzpatrickAnswerToFSTMarkup[fitzpatrickAnswer];

export const mapAnswerToAdditionalInformation = (fitzpatrickAnswer: string) =>
    fitzpatrickAdditionalInformation[fitzpatrickAnswer];

export const sortFitzpatrickQuestionAnswers = (a: IMedicalHistory, b: IMedicalHistory) =>
    new Date(a.medicalHistoryCreationDate).valueOf() - new Date(b.medicalHistoryCreationDate).valueOf();

export const sortFitzpatrickQuestionAnswersByLastModificationDate = (a: IMedicalHistory, b: IMedicalHistory): number =>
    new Date(b.lastModificationDate).valueOf() - new Date(a.lastModificationDate).valueOf();

/**
 * Both a patient and clinician can provided optional supportive text for their fitzpatrick answer
 *
 * When this occurs we will return both fitzpatrick medical history answers from reader-ms;
 */
export const getFitzpatrickQuestionAnswers = (
    questions: IMedicalHistory[],
    questionDefinitions: IMedicalHistoryQuestion[]
): {
    fitzpatrickQuestionAnswers: IMedicalHistory[];
    fitzpatrickQuestionAnswer: IMedicalHistory;
} => {
    const allFitzpatrickQuestionDefinitions = questionDefinitions?.filter(
        (question: IMedicalHistoryQuestion) => question?.questionSubType === QuestionSubTypeEnum.SKIN_TYPE
    );
    if (
        !allFitzpatrickQuestionDefinitions ||
        allFitzpatrickQuestionDefinitions?.length === 0 ||
        !questions ||
        questions?.length === 0
    ) {
        return {
            fitzpatrickQuestionAnswers: [],
            fitzpatrickQuestionAnswer: undefined,
        };
    }

    const fitzpatrickQuestionAnswers = questions
        ?.filter((question: IMedicalHistory) =>
            allFitzpatrickQuestionDefinitions?.find((questionDefinition) => question.uuid === questionDefinition.uuid)
        )
        ?.filter((question: IMedicalHistory) => !question.removed)
        ?.sort(sortFitzpatrickQuestionAnswersByLastModificationDate);

    const fitzpatrickQuestionAnswer = fitzpatrickQuestionAnswers[0];
    return {
        fitzpatrickQuestionAnswers,
        fitzpatrickQuestionAnswer,
    };
};

export const getUserRoleFromFPNote = (fpAnswer: IMedicalHistory, caseObject: ICase): SkinTypeNoteOriginator => {
    if (
        caseObject.homeInitiated &&
        caseObject.createdBy === caseObject.patient.createdBy &&
        fpAnswer.createdBy === caseObject.createdBy
    ) {
        return SkinTypeNoteOriginator.PATIENT;
    }
    return SkinTypeNoteOriginator.CLINICIAN;
};

export const getFitzpatrickQuestionsCreateDetails = (
    caseObject: ICase,
    fitzpatrickQuestionAnswer: IMedicalHistory
): string => {
    const fitzpatrickLastUpdatedByRole = getUserRoleFromFPNote(fitzpatrickQuestionAnswer, caseObject);
    if (fitzpatrickLastUpdatedByRole === SkinTypeNoteOriginator.PATIENT) {
        return `Captured by Patient from ${caseObject.organisationName} on ${formatTimeWithHours(
            fitzpatrickQuestionAnswer.creationDate
        )}`;
    }
    return `Captured by ${caseObject.patient?.medicalHistoryLastModifiedByName} from ${
        caseObject.organisationName
    } on ${formatTimeWithHours(fitzpatrickQuestionAnswer?.creationDate)}`;
};

/**
 * Retrieves the first Fitzpatrick question-answer pair from medical history.
 *
 * This function identifies Fitzpatrick questions based on the `SKIN_TYPE` subtype
 * within an array of medical history questions. It attempts to map these questions
 * to their corresponding answers in the medical history. If a matching answer is found,
 * it returns the first matching pair. If no corresponding answer is found for any
 * Fitzpatrick question, it returns the first Fitzpatrick question without an answer.
 *
 */
export const mapFitzpatrickQuestionAnswer = ({
    medicalHistoryQuestions,
    medicalHistoryAnswers,
}: {
    medicalHistoryQuestions: IMedicalHistoryQuestion[];
    medicalHistoryAnswers: IMedicalHistory[];
}): { question: IMedicalHistoryQuestion; questionAnswer: IMedicalHistory | null } => {
    const fitzpatrickQuestions = medicalHistoryQuestions.filter(
        (question) => question?.questionSubType === QuestionSubTypeEnum.SKIN_TYPE
    );

    if (!fitzpatrickQuestions || fitzpatrickQuestions.length === 0) {
        return null;
    }

    return (
        fitzpatrickQuestions
            .map((question) => {
                const questionAnswer = medicalHistoryAnswers?.find((answer) => answer.uuid === question.uuid);
                return questionAnswer ? { question, questionAnswer } : null;
            })
            .filter((mapping) => mapping !== null)[0] || {
            question: fitzpatrickQuestions.filter((question: IMedicalHistoryQuestion) => !question.removed)[0],
            questionAnswer: null,
        }
    );
};

// If there is only one answer -  return it (CLINICIAN/PATIENT)
// If there are 2 answers return only the FST answer from the clinician
export const getSkinTypeClassificationAnswer = (
    fitzpatrickQuestionAnswers: IMedicalHistory[],
    caseObject: ICase
): FitzpatrickAnswersType | string => {
    let fitzpatrickAnswer = "";

    if (fitzpatrickQuestionAnswers?.length === 1) {
        [fitzpatrickAnswer] = fitzpatrickQuestionAnswers[0]?.answers;
    }

    if (fitzpatrickQuestionAnswers?.length >= 2) {
        const answersWithRoles = fitzpatrickQuestionAnswers.map((answer: IMedicalHistory) => ({
            role: getUserRoleFromFPNote(answer, caseObject),
            answer,
        }));

        const clinicianAnswer = answersWithRoles?.find((answer) => answer.role === SkinTypeNoteOriginator.CLINICIAN);
        const patientAnswer = answersWithRoles?.find((answer) => answer.role === SkinTypeNoteOriginator.PATIENT);
        if (clinicianAnswer) {
            [fitzpatrickAnswer] = clinicianAnswer?.answer?.answers;
        } else {
            [fitzpatrickAnswer] = patientAnswer?.answer?.answers;
        }
    }

    return fitzpatrickAnswer;
};

export const wasCaseCreatedBeforeTheSkinQuestionWasAddedToTheOrganisation = ({
    caseData,
    organisation,
}: {
    caseData: ICase;
    organisation: IOrganisation;
}): boolean => {
    const caseCreaitonDate = new Date(caseData.creationDate).getTime();
    const fitzpatrickQuestionCreationDate = new Date(
        organisation.medicalHistoryQuestions
            .filter((question: IMedicalHistoryQuestion) => question?.questionSubType === QuestionSubTypeEnum.SKIN_TYPE)
            ?.find((question) => !question.removed)?.creationDate
    ).getTime();

    // handle the case where there is no active fitzpatrick skin type question
    if (!fitzpatrickQuestionCreationDate) {
        return false;
    }

    // caseCreationDate is older than fitzpatrickQuestionCreationDate
    if (caseCreaitonDate < fitzpatrickQuestionCreationDate) {
        return true;
    }

    // caseCreationDate is newer than fitzpatrickQuestionCreationDate or both were created at the exact time precisely
    return false;
};
