enum ImageClassification {
    PROCESSING_ANSWER_TYPE_SYSTEM_ERROR = "Processing error",
    PROCESSING_ANSWER_TYPE_IMAGE_HASH_VALIDATION_FAILED = "Image hash incorrect",
    PROCESSING_ANSWER_TYPE_IMAGE_QUALITY_FAILED_BLURRED = "Image too blurred",
    PROCESSING_ANSWER_TYPE_IMAGE_QUALITY_FAILED_TOO_DARK = "Image too dark",
    PROCESSING_ANSWER_TYPE_NO_LESION_IMAGE = "Image without lesion",
    PROCESSING_ANSWER_TYPE_SUCCESSFUL_PROCESSING = "Detected lesion",
}

export default ImageClassification;
