import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Divider, Form, Grid, Message, Segment } from "semantic-ui-react";

import { AdministrationPages, StudyManagmentSubpages } from "model/administrationPages";
import { IOrganisation } from "model/organisation";
import { UserRole } from "model/userRole";

import { history } from "App";
import { ADMINISTRATION } from "navigation/routes";

import organizationService from "services/organizationService";
import studyServie from "services/studyService";
import userService from "services/userService";

import "scss/Container.scss";

interface ICreateStudyComponentState {
    errorDetails: any[];
    isError: boolean;
    submitting: boolean;
    nameInput: string;
    organisationInput: string;
    organisationDict: [];
}

class CreateStudyScreen extends Component<any, ICreateStudyComponentState> {
    constructor(props: any) {
        super(props);

        this.state = {
            errorDetails: [],
            isError: false,
            submitting: false,
            nameInput: "",
            organisationDict: [],
            organisationInput: "",
        };
    }

    public componentDidMount() {
        organizationService.getAllOrganisations(0, ["type:STUDY"]).then((result) => {
            this.setState({ organisationDict: result?.data?.length ? result.data : [] });
        });
    }

    private getErrorMessages() {
        const { errorDetails } = this.state;

        return errorDetails.map((detail: any) => (
            <Message key={detail.message} negative>
                <p>{detail.message}</p>
            </Message>
        ));
    }

    private onFieldChange = (event: any, obj: any) => {
        const stateObj = {};
        const fieldName = obj.id;
        const fieldValue = obj.value;
        stateObj[fieldName] = fieldValue;
        this.setState(stateObj);
    };

    private submit = () => {
        const { nameInput, organisationInput } = this.state;

        this.setState({ submitting: true });

        if (nameInput !== "" && organisationInput) {
            studyServie
                .createStudy(nameInput, organisationInput)
                .then((result) => {
                    this.setState({ submitting: false });
                    const studyLocation = `${ADMINISTRATION}/${AdministrationPages.STUDY_MANAGEMENT}/${StudyManagmentSubpages.STUDY_DETAILS}/${result.data.uuid}`;
                    history.push(studyLocation);
                })
                .catch((err) => {
                    if (err.response) {
                        this.setState({ submitting: false, isError: false, errorDetails: err.response.data.errors });
                    } else {
                        this.setState({ submitting: false, isError: false, errorDetails: [{ message: err.message }] });
                    }
                });
        } else {
            this.setState({ submitting: false, isError: true });
        }
    };

    public render() {
        const { isError, organisationDict, submitting } = this.state;

        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        return (
            <Segment>
                <h2>Create new study</h2>
                <Divider />
                <div>
                    <h5 hidden={!isError} className="error-message">
                        <p>Please fill all required fields</p>
                    </h5>
                    <div className={isError ? "container error" : "contaner"}>
                        <Form>
                            <Segment>
                                <h4>Study details </h4>
                                <Divider />
                                <Grid columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label="Study name"
                                                type="text"
                                                id="nameInput"
                                                required
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Dropdown
                                                search
                                                selection
                                                fluid
                                                label="Organisation"
                                                type="text"
                                                id="organisationInput"
                                                placeholder="Select organisation"
                                                required
                                                onChange={this.onFieldChange}
                                                options={
                                                    organisationDict
                                                        ? organisationDict.map((org: IOrganisation, idx) => ({
                                                              text: org.name,
                                                              key: idx,
                                                              value: org.uuid,
                                                          }))
                                                        : []
                                                }
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>{this.getErrorMessages()}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={9}>
                                            <Button loading={submitting} floated="right" onClick={this.submit}>
                                                {" "}
                                                Create{" "}
                                            </Button>
                                            <Link to={`${ADMINISTRATION}/${AdministrationPages.STUDY_MANAGEMENT}`}>
                                                <Button floated="right">Back</Button>
                                            </Link>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form>
                    </div>
                </div>
            </Segment>
        );
    }
}

export default CreateStudyScreen;
