import React, { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

import { ModalIconAltTexts } from "model/modal";

interface IProps {
    onCancel: () => void;
    onConfirm: () => void;
    message: string;
}

const InfoModal: FC<IProps> = ({ onCancel, onConfirm, message }) => (
    <div className="remote-model__modal">
        <Image alt={ModalIconAltTexts.INFO} src="/images/icons/icon-info.svg" />

        <p className="h2">{message}</p>

        <div className="buttons-wrapper">
            <CustomButton type="button" variant="empty-dark" action={onCancel} text="Cancel" />
            <CustomButton type="button" variant="filled" action={onConfirm} text="OK" />
        </div>
    </div>
);

export default withModal(InfoModal);
