import { FC, createContext, useReducer, Dispatch, ReactNode } from "react";

import { AtHomeFlowAction } from "contextProviders/modules/atHomeFlow/actions";
import atHomeFlowReducer from "contextProviders/modules/atHomeFlow/reducer";
import { AtHomeFlowState } from "model/atHomeFlow";

interface StoreProps {
    children?: ReactNode;
}

const initialState = {
    consent: false,
    secondaryConsent: false,
    automatedDecisionConsent: null,
    personalDetails: null,
    medicalHistory: null,
};

const AtHomeFlowContext = createContext<AtHomeFlowState & { dispatch: Dispatch<AtHomeFlowAction> }>({
    ...initialState,
    dispatch: () => null,
});

export const AtHomeFlowProvider: FC<StoreProps> = ({ children }) => {
    const [state, dispatch] = useReducer(atHomeFlowReducer, initialState);

    return <AtHomeFlowContext.Provider value={{ ...state, dispatch }}>{children}</AtHomeFlowContext.Provider>;
};

AtHomeFlowProvider.defaultProps = {
    children: null,
};

export default AtHomeFlowContext;
