import {
    AllocationConfigurationAction,
    AllocationConfigurationActionTypes,
} from "contextProviders/modules/allocationConfiguration/actions";

import {
    allocationOrderIncrementRemap,
    getDefaultAllocationConfig,
    prependUrgentReferAllocationConfig,
    resetAllocationOrder,
    setAllocationOrder,
} from "helpers/allocations";

import { AllocationConfigurationConsentType, IAllocationSettings } from "model/allocationConfig";

export default (state: IAllocationSettings, action: AllocationConfigurationAction): IAllocationSettings => {
    switch (action.type) {
        case AllocationConfigurationActionTypes.SET_LOADING: {
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    loading: action.payload.loading,
                },
            };
        }
        case AllocationConfigurationActionTypes.SET_LOADED_ORGANISATION: {
            return {
                ...state,
                ...action.payload,
                consented: {
                    ...state.consented,
                    ...action.payload.consented,
                    isNew: action.payload.consented.isNew,
                    isChanged: action.payload.consented.isChanged,
                },
                unconsented: {
                    ...state.unconsented,
                    ...action.payload.unconsented,
                    isNew: action.payload.unconsented.isNew,
                    isChanged: action.payload.unconsented.isChanged,
                },
                showAutomatedDecisionConsent: action.payload.showAutomatedDecisionConsent,
            };
        }
        case AllocationConfigurationActionTypes.TOGGLE_URGENT_REFER: {
            let remappedAllocationOrders = [...state.consented.allocations];
            let remappedUnconsentedAllocationOrders = [...state.unconsented.allocations];

            /**
             * Initial / default state: urgent refer is disabled and is being enabled
             * At this point the allocation configuration array might not include the urgent refer config
             * a) If new config - prepend the urgent refer allocation to the allocations array and increment the
             *    zero-indexed allocation orders
             * b) If old config - set allocation order based on the saved allocations
             */
            if (!state.enableUrgentRefer) {
                if (state.consented.isNew) {
                    remappedAllocationOrders = prependUrgentReferAllocationConfig(
                        state.consented.allocations,
                        false
                    ).map(allocationOrderIncrementRemap);
                } else if (!state.consented.isNew) {
                    remappedAllocationOrders = setAllocationOrder(
                        state.consented,
                        AllocationConfigurationConsentType.CONSENTED
                    );
                }
                if (state.unconsented.isChanged && !state.unconsented.isNew) {
                    /**
                     * If a user enables urgent refer and the current unconsented config contains only four allocations
                     * prepend the urgent refer config
                     *
                     * Otherwise, use the save config
                     */
                    remappedUnconsentedAllocationOrders =
                        state.unconsented.allocations?.length === 4
                            ? prependUrgentReferAllocationConfig(state.unconsented.allocations, true).map(
                                  allocationOrderIncrementRemap
                              )
                            : setAllocationOrder(state.unconsented, AllocationConfigurationConsentType.UNCONSENTED);
                } else if (state.unconsented.isNew) {
                    remappedUnconsentedAllocationOrders = prependUrgentReferAllocationConfig(
                        state.unconsented.allocations,
                        true
                    ).map(allocationOrderIncrementRemap);
                } else if (!state.unconsented.isNew) {
                    remappedUnconsentedAllocationOrders = setAllocationOrder(
                        state.unconsented,
                        AllocationConfigurationConsentType.UNCONSENTED
                    );
                }
            }

            /**
             * Second state: urgent refer is enabled and is being disabled
             * a) reset allocation order to saved configuration
             */
            if (state.enableUrgentRefer) {
                remappedAllocationOrders = state.consented.allocations.map((allocation) =>
                    resetAllocationOrder(allocation, getDefaultAllocationConfig(false))
                );
                remappedUnconsentedAllocationOrders = [...state.unconsented.allocations].map((allocation) =>
                    resetAllocationOrder(allocation, getDefaultAllocationConfig(true))
                );
            }

            return {
                ...state,
                enableUrgentRefer: !state.enableUrgentRefer,
                showUrgentReferModal: false,
                consented: {
                    ...state.consented,
                    allocations: remappedAllocationOrders,
                    isChanged: true,
                },
                unconsented: {
                    ...state.unconsented,
                    allocations: remappedUnconsentedAllocationOrders,
                    isChanged: true,
                },
            };
        }
        case AllocationConfigurationActionTypes.CREATE_ALLOCATIONS: {
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    allocations: action.payload.allocations,
                    savedConfiguration: action.payload.allocations,
                    isNew: false,
                    isChanged: false,
                    loading: false,
                    hasAllocationOrderChanged: false,
                    error: null,
                },
            };
        }
        case AllocationConfigurationActionTypes.SET_NEW_ALLOCATION: {
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    allocations: action.payload.allocations,
                    isChanged: true,
                    hasAllocationOrderChanged: action.payload.hasAllocationOrderChanged,
                },
            };
        }
        case AllocationConfigurationActionTypes.SET_IMMEDIATE_RESULTS: {
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    allocations: action.payload.allocations,
                    isChanged: true,
                },
            };
        }
        case AllocationConfigurationActionTypes.UPDATE_ORGANISATION: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case AllocationConfigurationActionTypes.TOGGLE_ALLOCATION_ORDER_MODAL: {
            return {
                ...state,
                showAllocationOrderModal: !state.showAllocationOrderModal,
            };
        }
        case AllocationConfigurationActionTypes.TOGGLE_URGENT_REFER_MODAL: {
            return {
                ...state,
                showUrgentReferModal: !state.showUrgentReferModal,
            };
        }
        case AllocationConfigurationActionTypes.ALLOCATION_CONFIG_ERROR: {
            return {
                ...state,
                [action.payload.type]: {
                    ...state[action.payload.type],
                    loading: false,
                    error: action.payload.error,
                },
            };
        }
        default:
            return state;
    }
};
