export enum Gender {
    F = "Female",
    I = "Indeterminate",
    M = "Male",
    X = "Not Known",
}

export enum FormFields {
    nhsNumber = "nhs",
    hospitalNumber = "hospitalNumber",
    dateOfBirth = "birth",
    name = "name",
    surname = "surname",
    gender = "gender",
    postCode = "postCode",
    caseUuid = "caseUuid",
}

export enum FieldsGroups {
    advanced = "advanced",
    single = "single",
}

export interface IPatientSearchForm {
    [FormFields.nhsNumber]: string;
    [FormFields.hospitalNumber]: string;
    [FormFields.dateOfBirth]: string;
    [FormFields.name]: string;
    [FormFields.surname]: string;
    [FormFields.gender]: Gender;
    [FormFields.postCode]: string;
    [FormFields.caseUuid]: string;
}

export interface IPatientSearchItem {
    surname: string;
    forename1: string;
    nhsNumber: string;
    dateOfBirth: string;
    deceasedPatient: boolean;
    sex: string;
    patientIdentifier: string;
    postCode: string;
    title: string;
    ethnicGroup: string;
    occupation: string;
    dateOfDeath?: string;
    mergedRecord: boolean;
    minorPatientIdentifier?: string[];
}

export interface IPatientSearchFormError {
    formError: string;
    showFieldErrors: boolean;
    requestError: string;
    [FormFields.nhsNumber]?: string;
    [FormFields.hospitalNumber]?: string;
    [FormFields.dateOfBirth]?: string;
}

export interface IDisabledFields {
    [FieldsGroups.single]: boolean;
    [FieldsGroups.advanced]: boolean;
}

export const RESULTS_LIMIT = 150;

export const ITEMS_PER_PAGE = 20;

export enum SortableColumns {
    surname = "surname",
    name = "forename1",
    gender = "sex",
    dateOfBirth = "dateOfBirth",
}

export const INITIAL_PATIENT_SEARCH_FORM = {
    [FormFields.nhsNumber]: "",
    [FormFields.hospitalNumber]: "",
    [FormFields.dateOfBirth]: null,
    [FormFields.name]: "",
    [FormFields.surname]: "",
    [FormFields.gender]: null,
    [FormFields.postCode]: "",
    [FormFields.caseUuid]: "",
};

export enum PatientSearchExceptionModalTypes {
    MINIMUM_AGE = "MINIMUM_AGE",
    DECEASED_PATIENT = "DECEASED_PATIENT",
    NOT_ENOUGH_DATA = "NOT_ENOUGH_DATA",
}

export enum PatientLabelTypes {
    MERGED_RECORD = "mergedRecord",
    DECEASED = "deceased",
}
