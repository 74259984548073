import { DropdownItemProps } from "semantic-ui-react";

import { ALL_ITEM } from "model/filters";

export enum PathwayValues {
    SKIN_CANCER_FLOW = "SKIN_CANCER_FLOW",
    REMOTE_MODEL = "REMOTE_MODEL",
}

export const pathwayFilters: DropdownItemProps[] = [
    ALL_ITEM,
    { key: PathwayValues.REMOTE_MODEL, value: PathwayValues.REMOTE_MODEL, text: "Remote model" },
    {
        key: PathwayValues.SKIN_CANCER_FLOW,
        value: PathwayValues.SKIN_CANCER_FLOW,
        text: "Skin cancer",
    },
];
