import { FC } from "react";
import { Grid } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";

import { MFAButtons } from "model/mfa";

interface IWhyMFAModal {
    showModal: boolean;
    handleClose: () => void;
}

const WhyMFAModal: FC<IWhyMFAModal> = ({ showModal, handleClose }) => (
    <ModalDialog
        className="full-width-button"
        title="Why am I being asked to set up MFA?"
        open={showModal}
        onClose={handleClose}
        maxWidth="md"
        disableBackdropClick
        buttons={[
            {
                onClick: handleClose,
                text: MFAButtons.MODAL_OK,
                colour: "primary",
            },
        ]}
    >
        <Grid className="grid-dialog-body--left-align">
            <Grid.Row>
                <Grid.Column>
                    <h3>What Is a Multi factor authentication?</h3>
                    <p>
                        Multi-Factor Authentication (MFA) adds an extra layer of security by requiring multiple
                        verification methods to access your account. Our MFA solution includes three factors&#58; your
                        Skin Analytics password, email authentication using a PIN sent to your email, and phone number
                        authentication using a PIN sent to your validated phone number.
                    </p>
                    <h3>Why is it important?</h3>
                    <p>
                        MFA significantly reduces the risk of cyber attacks and security breaches, making it much harder
                        for unauthorized users to gain access. With MFA, you benefit from enhanced protection and peace
                        of mind, knowing your account is more secure against unauthorized access.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </ModalDialog>
);

export default WhyMFAModal;
