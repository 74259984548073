import { FC, FormEvent } from "react";
import { CheckboxProps, InputOnChangeData } from "semantic-ui-react";

import ExclusionForm from "components/ExclusionForm";
import QuestionaryFormElement from "components/templates/QuestionaryFormElement";

import { IHistoryAnswerType, IHistoryQuestion, IHistoryQuestionType } from "model/organisation";
import { IQuestionaryError } from "model/questionaryError";
import QuestionType from "model/questionType";
import IQuestionaryAnswerValidation from "model/questionaryAnswerValidation";

interface IQuestionaryItemResponse {
    answers: any;
    errors: IQuestionaryError;
    isBack: boolean;
    question: IHistoryQuestion;
    updateAnswers: (data: any) => void;
    validateAnswers: ({ showError, question }: IQuestionaryAnswerValidation) => void;
    optionalNotes: boolean;
}

const QuestionaryItemResponse: FC<IQuestionaryItemResponse> = ({
    question,
    errors,
    answers,
    isBack,
    updateAnswers,
    validateAnswers,
    optionalNotes,
}) => {
    const { questionType } = question;

    const changeExclusionAnswer = (data: CheckboxProps) => {
        const currentAnswers = answers;
        const currentLesionExclusionAnswerObject = {
            exclusion: data.exclusion,
            value: data.checked,
        };

        currentAnswers[data.exclusionuuid] = currentLesionExclusionAnswerObject;
        updateAnswers(currentAnswers);
        validateAnswers({ question });
    };

    const changeAnswer = (data: InputOnChangeData) => {
        if (data.type === QuestionType.TEXT) {
            const historyAnswers = answers;
            const textValue = {
                type: IHistoryAnswerType.TEXT,
                value: data.value,
            };
            historyAnswers[data.id] = textValue;
            updateAnswers(historyAnswers);
        }
        if (data.type === QuestionType.CHECKBOX) {
            const historyAnswers = answers;
            const currentAnswer = historyAnswers[data.questionuuid];
            const { value = false, additionalInformation = "" } = { ...currentAnswer };
            const answersForQuestion = value || {};
            const currentQuestionAnswerObject = {
                additionalInformation,
                type: IHistoryAnswerType.CHECKBOX,
                value: {
                    ...answersForQuestion,
                    [data.label as string]: data.checked,
                },
            };
            historyAnswers[data.questionuuid] = currentQuestionAnswerObject;
            updateAnswers(historyAnswers);
        }
        if (data.type === QuestionType.RADIO) {
            const historyAnswers = answers;
            const currentAnswer = historyAnswers[data.questionuuid];
            const { additionalInformation = "" } = { ...currentAnswer };
            const currentQuestionAnswerObject = {
                additionalInformation,
                type: IHistoryAnswerType.OPTION,
                value: data.label,
            };
            historyAnswers[data.questionuuid] = currentQuestionAnswerObject;
            updateAnswers(historyAnswers);
        }
        if (data.type === "extra") {
            const historyAnswers = answers;
            const curentAnswer = historyAnswers[data.questionuuid];
            historyAnswers[data.questionuuid] = {
                ...curentAnswer,
                additionalInformation: data.value,
            };
            updateAnswers(historyAnswers);
        }
        validateAnswers({ question });
    };

    const onFieldChange = (e: any, data: any) => {
        changeAnswer(data);
    };

    const onExclusionFormChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        changeExclusionAnswer(data);
    };

    if (questionType === IHistoryQuestionType.EXCLUSION) {
        return (
            <ExclusionForm
                question={question}
                onFieldChange={onExclusionFormChange}
                errors={errors}
                answers={answers}
                isBack={isBack}
            />
        );
    }
    const answer = answers[question.uuid];

    return (
        <QuestionaryFormElement
            onChange={onFieldChange}
            question={question}
            formErrors={errors}
            isBack={isBack}
            answer={answer}
            optionalNotes={optionalNotes}
        />
    );
};

export default QuestionaryItemResponse;
