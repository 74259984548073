import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider, Grid, Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import PatientBanner from "components/templates/PatientBanner";
import QRCodePopup from "components/templates/QRCodePopup";
import SupportEmailLink from "components/templates/SupportEmailLink";

import { IAssessment } from "model/assessment";
import { PHONE_NUMBER } from "model/common";
import imageCaptureInstructions from "model/imageCapture";
import { AllLesionLocation } from "model/lesionLocation";
import { IOrganisation } from "model/organisation";

import { DIAGNOSIS, LESION_LOCATOR } from "navigation/routes";

interface IImageCapture {
    assessment: IAssessment;
    authToken: string;
    refreshToken: string;
    wizard: any;
    organisation: IOrganisation;

    setNextWizardStep: (currentDevice: number, devices: number) => void;
}

class StudyImageCapture extends Component<IImageCapture, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            next: false,
        };
    }

    public submit = () => {
        const { setNextWizardStep, wizard, assessment } = this.props;
        setNextWizardStep(wizard.currentCapturDevice, assessment.devices.length);
        this.setState({ next: true });
    };

    public getBodyForQrCode = (refreshToken: string, assessment: any, wizard: any) => {
        const { organisation } = this.props;

        const detailsStartIndex =
            assessment.patient.patientData[0].name === "name" && assessment.patient.patientData[1].name === "surname"
                ? 2
                : 1;
        const patientName =
            assessment.patient.patientData[0].name === "name" && assessment.patient.patientData[1].name === "surname"
                ? `${assessment.patient.patientData[0].textValue} ${assessment.patient.patientData[1].textValue}`
                : assessment.patient.patientData[0].textValue;

        const customProperty = assessment.patient.patientData[detailsStartIndex];
        const customPropertyName = organisation.definedPersonalData.find(
            (definedData) => definedData.propertyName === customProperty.name
        )?.displayName;

        const qrCodeBody = {
            assessmentDetails: {
                bodyPart: AllLesionLocation[assessment.lesion.bodyPart],
                customPropertyName: `${customPropertyName}`,
                customPropertyValue: `${customProperty.textValue}`,
                lesionDescription: `${assessment.lesion.description}`,
                patient: patientName,
            },
            caseId: assessment.case.caseId,
            caseUuid: assessment.case.uuid,
            deviceUuid: assessment.devices[wizard.currentCapturDevice - 1].uuid,
            lesionNumber: assessment.currentLesion + 1,
            lesionUuid: assessment.lesion.uuid,
            patientUuid: assessment.patient.uuid,
            refreshToken,
        };

        return JSON.stringify(qrCodeBody);
    };

    public render() {
        const { refreshToken, assessment, wizard } = this.props;
        const { next } = this.state;
        const { finished, currentCapturDevice } = wizard;

        if (finished && next) {
            return <Redirect to={DIAGNOSIS} />;
        }

        const { devices, case: currentCase, patient, lesion, currentLesion } = assessment;
        const { infoText, manufacturer, model } = devices[currentCapturDevice - 1];
        const nOfAssessmentDevices = devices.length || 1;
        const deviceNameCopy = infoText || `${manufacturer} ${model}`;
        const qrCodeCopy = `"${deviceNameCopy}" (device ${currentCapturDevice} of ${nOfAssessmentDevices})`;

        if (wizard.finished && next) {
            return <Redirect to="/diagnosis" />;
        }

        return (
            <Container className="ui segment wizzard-container">
                <PatientBanner
                    caseId={currentCase.caseId}
                    patient={patient}
                    lesion={lesion}
                    lesionNumber={currentLesion}
                />
                <h1>Scan the code using Skin Analytics app</h1>
                <Divider />

                <Grid columns={2}>
                    <Grid.Column>
                        <QRCodePopup value={this.getBodyForQrCode(refreshToken, assessment, wizard)} />
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={1}>
                            <Grid.Row style={{ height: "70px", background: "#F6F7F9" }}>
                                <Grid.Column>
                                    <p>
                                        <b>
                                            Please scan QR code and continue process on device:
                                            <br />
                                            {qrCodeCopy}
                                        </b>
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row>
                                <h3>Can&apos;t scan the code?</h3>
                            </Grid.Row>
                            <Grid.Row>
                                <p>Check that the phone is connected to Wi-Fi or the mobile network.</p>
                            </Grid.Row>
                            <Grid.Row>
                                <p>
                                    If you have trouble, contact <SupportEmailLink /> or call&nbsp;
                                    {PHONE_NUMBER}
                                </p>
                            </Grid.Row>
                            <Grid.Row>
                                <Image
                                    alt={imageCaptureInstructions}
                                    src="/images/imageCaptureInstruction.png"
                                    size="big"
                                />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>

                <Divider />
                <ButtonContainer
                    button1={
                        <CustomButton
                            variant="empty"
                            type="link"
                            to={`${LESION_LOCATOR}/${currentLesion + 1}`}
                            text="< Back"
                            size="small"
                        />
                    }
                    button2={<CustomButton variant="filled" type="submit" action={this.submit} text="Next >" />}
                />
            </Container>
        );
    }
}

export default StudyImageCapture;
