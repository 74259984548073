import { FC } from "react";
import { ModalProps } from "semantic-ui-react";

import Modal from "components/Modal";
import SupportEmailLink from "components/templates/SupportEmailLink";

import { ModalTypes } from "model/modal";

interface IAtHomeSubmissionModal {
    modal: Omit<ModalProps, "children">;
    closeModal: () => void;
}

const AtHomeSubmissionModal: FC<IAtHomeSubmissionModal> = ({ modal, closeModal }) => (
    <Modal
        type={modal.type}
        show={modal.show}
        closeOnOverlay={modal.type === ModalTypes.ERROR}
        closeCallback={modal.type === ModalTypes.ERROR && closeModal}
        cta={modal.type === ModalTypes.ERROR && closeModal}
        ctaText={modal.type === ModalTypes.ERROR && "Ok"}
    >
        {modal.type === ModalTypes.SUCCESS ? (
            <p className="h2">
                Your answers have been saved.
                <br />
                Thank you for submitting these ahead of your appointment.
                <br />
                <br />
                You can now close this browser window.
            </p>
        ) : (
            <>
                <p className="h2">An error has occurred. Please try again.</p>

                <p>
                    If this problem persists contact <SupportEmailLink />
                </p>
            </>
        )}
    </Modal>
);

export default AtHomeSubmissionModal;
