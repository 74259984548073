import { FC } from "react";
import { Link } from "react-router-dom";
import { Header } from "semantic-ui-react";

interface IEditLink {
    link: string;
    text: string;
    action?: () => void;
}

const EditLink: FC<IEditLink> = ({ link, text, action }) => (
    <div className="remote-model-link">
        <Link to={link} onClick={action}>
            <Header as="h4">{text}</Header>
        </Link>
    </div>
);

EditLink.defaultProps = {
    action: () => undefined,
};

export default EditLink;
