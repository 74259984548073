import { FC } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";

import { mapAnswerToFSTMarkup } from "helpers/fitzpatrick";

import { ISkinToneAnswer } from "model/case";
import { FitzpatrickAnswersType } from "model/fixedQuestion";

const useStyles = makeStyles(() =>
    createStyles({
        reportIncorrectClassification: {
            display: "flex",
            alignItems: "center",
            color: "#4182C4",
            cursor: "pointer",
            fontWeight: 600,
            padding: "8px 0px",
            textDecoration: "underline",
        },
        reportIncorrectClassificationWrapper: {
            position: "relative",
        },
        skinToneClassification: {
            display: "flex",
            alignItems: "center",
            color: "#000",
            fontWeight: 600,
            padding: "8px 0px",
            textDecoration: "underline",
        },
        skinToneClassificationIcon: {
            paddingLeft: "4px",
        },
    })
);

interface IReportIncorrectClassification {
    fitzpatrickAnswer: FitzpatrickAnswersType | string;
    showToCurrentUser: boolean;
    skinToneClassification: ISkinToneAnswer;
    showModal: (boolean) => void;
}

const ReportIncorrectClassification: FC<IReportIncorrectClassification> = ({
    fitzpatrickAnswer,
    showToCurrentUser,
    skinToneClassification,
    showModal,
}) => {
    const classes = useStyles();

    if (!showToCurrentUser) {
        return null;
    }

    if (!fitzpatrickAnswer) {
        return null;
    }

    if (skinToneClassification) {
        return (
            <div className={classes.skinToneClassification}>
                Reported as {mapAnswerToFSTMarkup(skinToneClassification.skinToneClassification)}
                <CreateIcon className={classes.skinToneClassificationIcon} />
            </div>
        );
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === "Enter") {
            showModal(true);
        }
    };

    return (
        <div
            role="button"
            className={classes.reportIncorrectClassification}
            onClick={() => showModal(true)}
            onKeyDown={handleKeyDown}
            tabIndex={0}
        >
            Report incorrect classification
        </div>
    );
};

export default ReportIncorrectClassification;
