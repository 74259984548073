import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import reducers from "redux/reducers";

const initialState = {};
const persistConfig = {
    key: "root",
    blacklist: ["validate", "registrationUI"],
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));

const persistor = persistStore(store);

export type Dispatch = typeof store.dispatch;

export default { store, persistor };
