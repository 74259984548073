import { FC } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory, History } from "history";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import FatalError from "components/ErrorBoundary/errorBoundary";
import Routes from "navigation";
import store from "redux/store";
import MuiTheme from "Theme/MuiTheme";

import "scss/Page.scss";

export const history: History = createBrowserHistory();

const App: FC = () => (
    <ErrorBoundary FallbackComponent={FatalError}>
        <Provider store={store.store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <PersistGate persistor={store.persistor}>
                    <Router history={history}>
                        <MaterialThemeProvider theme={MuiTheme}>
                            <Routes />
                        </MaterialThemeProvider>
                    </Router>
                </PersistGate>
            </LocalizationProvider>
        </Provider>
    </ErrorBoundary>
);

export default App;
