import { createActions } from "redux-actions";

import types from "redux/actions/types";

export const {
    setIsModalConfirmationVisible,
    setIsModalEmailConfirmationVisible,
    setIsModalRedirectVisible,
    setIsPreauthorisationNumberErrorModalVisible,
} = createActions(
    types.SET_IS_MODAL_CONFIRMATION_VISIBLE,
    types.SET_IS_MODAL_EMAIL_CONFIRMATION_VISIBLE,
    types.SET_IS_MODAL_REDIRECT_VISIBLE,
    types.SET_IS_PREAUTHORISATION_NUMBER_ERROR_MODAL_VISIBLE
);
