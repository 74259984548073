import React, { FC, ReactNode, ReactNodeArray } from "react";
import { Header, Container, Divider } from "semantic-ui-react";

interface IRemoteModelContainer {
    children: ReactNode | ReactNodeArray;
    header?: string;
    withoutDivider?: boolean;
}

const RemoteModelContainer: FC<IRemoteModelContainer> = ({ header, children, withoutDivider }) => (
    <Container className="ui segment remote-model">
        {header && (
            <>
                <Header as="h2">{header}</Header>
                {!withoutDivider && <Divider />}
            </>
        )}
        {children}
    </Container>
);

RemoteModelContainer.defaultProps = {
    header: "",
    withoutDivider: false,
};

export default RemoteModelContainer;
