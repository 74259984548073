import { connect } from "react-redux";

import ReadyForAssessment from "components/ReadyForAssessment/ReadyForAssessment";

import assessmentService from "services/assessmentService";
import * as caseService from "services/caseService";

const mapStateToProps = (state: any) => ({
    user: state.data.user,
    assessment: state.assessment,
    case: state.assessment.case,
    currentLesion: state.assessment.currentLesion,
    diagnosis: state.assessment.diagnosis,
    organisation: state.data.organisation,
    pendingRequest: state.data.pendingRequest,
});

const mapDispatchToProps = {
    updateCurrentLesionNumber: assessmentService.updateLesionNumberAction,
    getCase: caseService.getCaseForAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadyForAssessment);
