import { FC, useEffect, useState } from "react";
import { DropdownProps, Header, Select } from "semantic-ui-react";

import ErrorMessage from "components/ErrorMessage";
import CustomButton from "components/templates/Button";

import { IDictionaryItem } from "model/dictionaryItem";
import { INewDefinedPersonalData, IOrganisationPersonalData, IPersonalDataCoreField } from "model/organisation";
import { IPersonalDataCoreFields } from "model/request";

import organizationService from "services/organizationService";

interface IAddCoreField {
    organisationPersonalData: IOrganisationPersonalData;
    setNewCoreField: (newField: boolean) => void;
    onFieldAdded: (obj: INewDefinedPersonalData) => Promise<void>;
}

const customCoreFieldValue = "Custom";

const customCoreField = { key: customCoreFieldValue, text: customCoreFieldValue, value: customCoreFieldValue };

const AddCoreField: FC<IAddCoreField> = ({ organisationPersonalData, setNewCoreField, onFieldAdded }) => {
    const [selectedCoreField, setSelectedCoreField] = useState<string>("");
    const [personalDataCoreFields, setPersonalDataCoreFields] = useState<IPersonalDataCoreField[]>([]);
    const [error, setError] = useState<string>("");

    const { isOrganisationDataFetched, definedPersonalData, patientDetailsIntegrationType } = organisationPersonalData;

    useEffect(() => {
        const fetchCoreFields = async (): Promise<void> => {
            if (isOrganisationDataFetched) {
                try {
                    const result: IPersonalDataCoreFields = await organizationService.getPersonalDataCoreFields(
                        patientDetailsIntegrationType
                    );
                    if (result) {
                        setPersonalDataCoreFields(result.coreFields);
                    }
                } catch (e) {
                    setError("Error while fetching core fields configuration");
                }
            }
        };

        fetchCoreFields();
    }, [isOrganisationDataFetched, patientDetailsIntegrationType]);

    const handleCoreFieldChanged = (event: React.SyntheticEvent<HTMLElement, Event>, obj: DropdownProps): void => {
        setError("");
        setSelectedCoreField(`${obj.value}`);
    };

    const submitNewField = async (): Promise<void> => {
        const newFieldConfig: IPersonalDataCoreField = personalDataCoreFields.find(
            (personalDataCoreField) => personalDataCoreField.propertyName === selectedCoreField
        );
        if (selectedCoreField === customCoreFieldValue) {
            setNewCoreField(true);
        } else {
            const lastPersonalData = definedPersonalData[definedPersonalData.length - 1];
            const displayOrder = Number(lastPersonalData?.displayOrder || 0) + 1;
            const isIdentifierFieldExists = Boolean(
                definedPersonalData.find((personalData) => personalData.identifier)
            );
            const identifier = isIdentifierFieldExists ? false : newFieldConfig.identifier;
            const config: INewDefinedPersonalData = {
                ...newFieldConfig,
                validationProperty: newFieldConfig.validationProperty || undefined,
                identifier,
                displayOrder,
            };
            await onFieldAdded(config);
        }
        setSelectedCoreField("");
        setError("");
    };

    const fields: IDictionaryItem[] = personalDataCoreFields
        .filter(
            (field) =>
                !definedPersonalData.find(
                    (definedPersonalDataField) => definedPersonalDataField.propertyName === field.propertyName
                )
        )
        .map((field) => ({
            key: field.propertyName,
            text: field.displayName,
            value: field.propertyName,
        }));

    const isCoreFieldSelected = Boolean(selectedCoreField);

    const isError = Boolean(error);

    return (
        <>
            <Header as="h4">Add another field</Header>
            <div className="new-personal-data-field-wrapper">
                <Select
                    label="Organisation Type"
                    placeholder="Select from the available fields below"
                    className="new-personal-data-field"
                    value={selectedCoreField}
                    onChange={handleCoreFieldChanged}
                    options={[...fields, customCoreField]}
                />
                {isCoreFieldSelected && !isError && (
                    <CustomButton variant="filled" type="submit" action={submitNewField} text="Add field" />
                )}
                {isError && <ErrorMessage errors={error} />}
            </div>
        </>
    );
};

export default AddCoreField;
