import { FC, ReactNode, createContext, useReducer } from "react";

import { ReviewSettingsActionTypes } from "contextProviders/modules/reviewSettings/actions";
import reviewSettingsReducer from "contextProviders/modules/reviewSettings/reducer";

import { IManagementOptions, IOrganisation, IReviewDiagnosis } from "model/organisation";
import { ReviewSettingMode } from "model/reviewType";
import ReviewSettingsData from "model/reviewSettingsData";

interface StoreProps {
    children?: ReactNode;
}

const initialState: ReviewSettingsData = {
    reviewSettingMode: ReviewSettingMode.VIEW,
    reviewDiagnoses: [],
    reviewManagementOptions: [],
    organisation: undefined,
    showErrorDialog: false,
    editDiagnosisUuid: undefined,
};

interface IDispatchers {
    setLoadedOrganisation: (organisation: IOrganisation) => void;
    changeReviewSettingMode: (mode: ReviewSettingMode) => void;
    startEditDiagnosis: (diagnosisUuid: string) => void;
    updateOrganisation: (organisation: IOrganisation) => void;
    updateReviewDiagnoses: (reviewDiagnoses: IReviewDiagnosis[]) => void;
    updateReviewManagmentOptions: (reviewManagementOptions: IManagementOptions[]) => void;
}

const ReviewSettingsContext = createContext<{ state: ReviewSettingsData; dispatchers: IDispatchers }>({
    state: { ...initialState },
    dispatchers: {} as IDispatchers,
});

export const ReviewSettingsContextProvider: FC<StoreProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reviewSettingsReducer, initialState);

    const setLoadedOrganisation = (organisation: IOrganisation): void => {
        const { reviewDiagnoses = [], reviewManagementOptions = [] } = organisation;
        dispatch({
            type: ReviewSettingsActionTypes.SET_LOADED_ORGANISATION,
            payload: { organisation, reviewDiagnoses, reviewManagementOptions },
        });
    };

    const changeReviewSettingMode = (mode: ReviewSettingMode): void => {
        const payload = {
            reviewSettingMode: mode,
            ...(mode === ReviewSettingMode.VIEW && { editDiagnosisUuid: undefined }),
        };

        dispatch({ type: ReviewSettingsActionTypes.CHANGE_REVIEW_SETTING_MODE, payload });
    };

    const startEditDiagnosis = (diagnosisUuid: string): void => {
        dispatch({ type: ReviewSettingsActionTypes.START_EDIT_DIAGNOSIS, payload: { diagnosisUuid } });
    };

    const updateOrganisation = (organisation: IOrganisation): void => {
        dispatch({ type: ReviewSettingsActionTypes.UPDATE_ORGANISATION, payload: { organisation } });
    };

    const updateReviewDiagnoses = (reviewDiagnoses: IReviewDiagnosis[]): void => {
        dispatch({ type: ReviewSettingsActionTypes.UPDATE_REVIEW_DIAGNOSES, payload: { reviewDiagnoses } });
    };

    const updateReviewManagmentOptions = (reviewManagementOptions: IManagementOptions[]): void => {
        dispatch({
            type: ReviewSettingsActionTypes.UPDATE_REVIEW_MANAGEMENT_OUTCOMES,
            payload: { reviewManagementOptions },
        });
    };

    const dispatchers = {
        setLoadedOrganisation,
        changeReviewSettingMode,
        startEditDiagnosis,
        updateOrganisation,
        updateReviewDiagnoses,
        updateReviewManagmentOptions,
    };

    return <ReviewSettingsContext.Provider value={{ state, dispatchers }}>{children}</ReviewSettingsContext.Provider>;
};

ReviewSettingsContextProvider.defaultProps = {
    children: null,
};

export default ReviewSettingsContext;
