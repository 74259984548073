import { FC, ReactNode, useContext, useState } from "react";
import { Button, Dropdown, DropdownProps, Grid, Label, Message, Table } from "semantic-ui-react";

import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";
import AddDisplayOrderDialog from "components/Administration/OrganisationManagment/ViewOrganisation/Review/AddDisplayOrderDialog";
import ReviewSettingsContext from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ReviewSettingsProvider";
import UpdateDiagnosisDialog from "components/Administration/OrganisationManagment/ViewOrganisation/Review/UpdateDiagnosisDialog";
import ConfirmDialog, { getDefaultButtons } from "components/templates/ConfirmDialog";
import ContextIconButton from "components/templates/ContextIconButton";
import ReviewEntityDetailsDialog from "components/templates/dialog/ReviewEntityDetailsDialog";
import SomethingWentWrongDialog from "components/templates/dialog/SomethingWentWrongDialog";

import { ReviewEntities } from "model/administrationPages";
import { ReviewType } from "model/reviewType";
import { IManagementOptions, IOrganisation, IReviewDiagnosis } from "model/organisation";

import reviewManagementService from "services/reviewManagementService";

interface IDiagnosesTableProps {
    diagnosisType?: ReviewType;
    organisation?: IOrganisation;
    reviewDiagnoses?: IReviewDiagnosis[];
    reviewManagementOptions?: IManagementOptions[];
    handleEdit?: (event: any, data: any) => void;
}

interface IDetailsDialogOptions {
    type?: ReviewEntities;
    uuid: string;
}

const DETAILS_DIALOG_OPTIONS_INITIAL = { uuid: "" };

const reviewDiagnosesSortFunc = (a: IReviewDiagnosis, b: IReviewDiagnosis) => a.displayOrder - b.displayOrder;

const addDisplayOrderFunc = (reviewDiagnosis: IReviewDiagnosis, index: number): IReviewDiagnosis => {
    if (reviewDiagnosis.displayOrder) {
        return reviewDiagnosis;
    }
    return { ...reviewDiagnosis, displayOrder: index + 1 };
};

const DiagnosesTable: FC<IDiagnosesTableProps> = ({
    diagnosisType,
    handleEdit,
    reviewDiagnoses,
    reviewManagementOptions,
}) => {
    const {
        state: { organisation },
        dispatchers: { updateReviewDiagnoses },
    } = useContext(ReviewSettingsContext);

    const [activeDiagnoses, setActiveDiagnoses] = useState<IReviewDiagnosis[]>(
        reviewDiagnoses
            ? [...reviewDiagnoses]
                  .filter((review: IReviewDiagnosis) => !review.removed && review.reviewType === diagnosisType)
                  .map(addDisplayOrderFunc)
                  .sort(reviewDiagnosesSortFunc)
            : []
    );
    const [detailsDialogOptions, setDetailsDialogOptions] =
        useState<IDetailsDialogOptions>(DETAILS_DIALOG_OPTIONS_INITIAL);
    const [displayOrderEditMode, setDisplayOrderEditMode] = useState<boolean>(false);
    const [showSubmitDisplayOrderDialog, setShowSubmitDisplayOrderDialog] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const [submittingDisplayOrder, setSubmittingDisplayOrder] = useState<boolean>(false);
    const [showDisplayOrderErrorDialog, setShowDisplayOrderErrorDialog] = useState<boolean>(false);

    const displayOrderOptions = Array.from({ length: activeDiagnoses.length }, (_, index) => ({
        key: index + 1,
        text: (index + 1).toString(),
        value: index + 1,
    }));

    const showDetailsDialog = (uuid: string, type: ReviewEntities): void => {
        setDetailsDialogOptions({ uuid, type });
    };

    const closeDetailsDialog = (): void => {
        setDetailsDialogOptions(DETAILS_DIALOG_OPTIONS_INITIAL);
    };

    const removeDiagnosis = (obj: any): (() => Promise<void>) => {
        const { uuid } = obj;
        const diagnosis = reviewDiagnoses.find((diag) => diag.uuid === uuid);

        if (!diagnosis.displayOrder) {
            return () =>
                reviewManagementService
                    .removeDiagnoses(uuid)
                    .then(() => {
                        const updatedDiagnoses = [...reviewDiagnoses].map((diag) => {
                            if (diag.uuid === uuid) {
                                return { ...diag, removed: true };
                            }
                            return diag;
                        });
                        updateReviewDiagnoses(updatedDiagnoses);
                        setActiveDiagnoses(
                            updatedDiagnoses
                                .filter((diag) => !diag.removed && diag.reviewType === diagnosisType)
                                .map(addDisplayOrderFunc)
                                .sort(reviewDiagnosesSortFunc)
                        );
                    })
                    .catch(() => {
                        setShowErrorDialog(true);
                    });
        }

        const removedDisplayOrder = diagnosis.displayOrder;

        const updatedActiveDiagnoses = activeDiagnoses.map((diag) => {
            if (diag.displayOrder < removedDisplayOrder) {
                return diag;
            }
            if (diag.displayOrder === removedDisplayOrder) {
                return { ...diag, removed: true };
            }
            const { displayOrder, ...rest } = diag;
            return { ...rest, displayOrder: displayOrder - 1 };
        });

        const updatedDiagnoses = reviewDiagnoses.map((diag) => {
            if (diag.reviewType !== diagnosisType || diag.removed) {
                return diag;
            }

            return updatedActiveDiagnoses.find((diagnose) => diagnose.uuid === diag.uuid);
        });

        return () =>
            reviewManagementService
                .updateAllDiagnoses(organisation?.uuid, updatedDiagnoses)
                .then(() => {
                    updateReviewDiagnoses(updatedDiagnoses);
                    setActiveDiagnoses(
                        updatedActiveDiagnoses
                            .filter((diag) => !diag.removed && diag.reviewType === diagnosisType)
                            .sort(reviewDiagnosesSortFunc)
                    );
                })
                .catch((err) => {
                    const errors = err.response ? err.response.data.errors : [{ message: err.message }];
                    console.error(errors);
                    setShowErrorDialog(true);
                });
    };

    const onRemoveDiagnosis = (event: any, obj: any): void => {
        ConfirmDialog(
            "Are you sure that you want to delete this diagnosis management item?",
            "",
            getDefaultButtons(removeDiagnosis(obj))
        );
    };

    const findManagementOutcome = (uuid: string): ReactNode[] => {
        const { uuid: detailsDialogUuid, type: detailsDialogType } = detailsDialogOptions;
        const { parentUuid = "" } = organisation;
        const managementOutcomes: ReactNode[] = [];

        if (reviewManagementOptions) {
            const currentManagementOutcome = reviewManagementOptions.find(
                (option) => option.uuid === uuid && !option.removed
            );
            const showDialog = detailsDialogType === ReviewEntities.MANAGEMENT_OUTCOME && detailsDialogUuid === uuid;

            if (!currentManagementOutcome) {
                return null;
            }

            const handleShowDialog = (): void => {
                showDetailsDialog(uuid, ReviewEntities.MANAGEMENT_OUTCOME);
            };

            const { copyOfUuid, management, organisationUuid, refer, freeText, keyMessage, safetyNetting, discharge } =
                currentManagementOutcome;

            const currentManagementOutcomesItem = (
                <Grid.Row key={uuid}>
                    <Grid.Column>
                        <li>
                            <SettingsPropertyWrapper
                                copyOfUuid={copyOfUuid}
                                parentOrgUuid={parentUuid}
                                propertyOrganisationUuid={organisationUuid}
                            >
                                {management}
                            </SettingsPropertyWrapper>
                        </li>
                    </Grid.Column>
                    <Grid.Column>
                        <ReviewEntityDetailsDialog
                            managementOption={currentManagementOutcome}
                            onClose={closeDetailsDialog}
                            showDialog={showDialog}
                        />
                        <ContextIconButton iconName="magnify" onClick={handleShowDialog} content="details" />
                    </Grid.Column>

                    <Grid.Column>
                        {refer && <Label size="mini" color="red" content="Refer according to pathway" />}
                        {freeText && <Label size="mini" color="blue" content="Free text" />}
                        {keyMessage && <Label size="mini" color="green" content="Key message" />}
                        {safetyNetting && <Label size="mini" color="brown" content="Safety netting" />}
                        {discharge && <Label size="mini" content="Discharge flag" className="discharge-label" />}
                    </Grid.Column>
                </Grid.Row>
            );

            managementOutcomes.push(currentManagementOutcomesItem);
        }
        return managementOutcomes;
    };

    const renderDisplayOrder = (reviewDiagnosis: IReviewDiagnosis): ReactNode => {
        const onChangeDisplayOrder = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
            const updatedDiagnoses = activeDiagnoses.map((diag) => {
                if (diag.displayOrder === data.value) {
                    return { ...diag, displayOrder: null };
                }

                if (diag.uuid === reviewDiagnosis.uuid) {
                    return { ...diag, displayOrder: Number(data.value) };
                }

                return diag;
            });
            setActiveDiagnoses([...updatedDiagnoses]);
        };

        if (displayOrderEditMode) {
            return (
                <Dropdown
                    placeholder="Select order"
                    fluid
                    selection
                    options={displayOrderOptions}
                    value={reviewDiagnosis.displayOrder}
                    onChange={onChangeDisplayOrder}
                />
            );
        }

        return <p>{reviewDiagnosis.displayOrder}</p>;
    };

    const getReviewDiagnosesTableRows = (): ReactNode | ReactNode[] => {
        const { type: detailsDialogType, uuid: detailsDialogUuid } = detailsDialogOptions;

        const { parentUuid = "", uuid: orgUuid } = organisation;

        if (activeDiagnoses && activeDiagnoses.length) {
            return activeDiagnoses.map((diagnosis: IReviewDiagnosis) => {
                const { diagnosis: diagnosisClassification, copyOfUuid, organisationUuid, uuid } = diagnosis;
                const canEditDiagnoses = orgUuid === diagnosis.organisationUuid;

                const handleShowDialog = () => {
                    showDetailsDialog(diagnosis.uuid, ReviewEntities.DIAGNOSIS);
                };

                const showDialog = detailsDialogType === ReviewEntities.DIAGNOSIS && detailsDialogUuid === uuid;

                return (
                    <Table.Row key={uuid}>
                        <Table.Cell width={3}>{renderDisplayOrder(diagnosis)}</Table.Cell>
                        <Table.Cell width={4}>
                            <SettingsPropertyWrapper
                                parentOrgUuid={parentUuid}
                                copyOfUuid={copyOfUuid}
                                propertyOrganisationUuid={organisationUuid}
                            >
                                {diagnosisClassification}
                            </SettingsPropertyWrapper>
                            <ReviewEntityDetailsDialog
                                diagnose={diagnosis}
                                onClose={closeDetailsDialog}
                                showDialog={showDialog}
                            />
                            <ContextIconButton iconName="magnify" onClick={handleShowDialog} content="details" />
                        </Table.Cell>
                        <Table.Cell width={7}>
                            {diagnosis.managementOptions.map(({ managementUuid }) => (
                                <Grid key={managementUuid} columns="3">
                                    {findManagementOutcome(managementUuid)}
                                </Grid>
                            ))}
                        </Table.Cell>
                        <Table.Cell>
                            {canEditDiagnoses && <ContextIconButton iconName="edit" uuid={uuid} onClick={handleEdit} />}
                            {canEditDiagnoses && (
                                <ContextIconButton iconName="trash" uuid={uuid} onClick={onRemoveDiagnosis} />
                            )}
                        </Table.Cell>
                    </Table.Row>
                );
            });
        }

        return (
            <Table.Row>
                <Table.Cell width={3}>-</Table.Cell>
                <Table.Cell width={4}>-</Table.Cell>
                <Table.Cell width={7}>-</Table.Cell>
                <Table.Cell />
            </Table.Row>
        );
    };

    const onDisplayOrderEditClick = (): void => {
        setDisplayOrderEditMode(true);
    };

    const showConfirmDisplayOrderDialog = (): void => {
        setShowSubmitDisplayOrderDialog(true);
    };

    const saveDisplayOrder = (): void => {
        reviewManagementService
            .updateAllDiagnoses(organisation.uuid, activeDiagnoses)
            .then(() => {
                setShowSubmitDisplayOrderDialog(false);
                setSubmittingDisplayOrder(false);
                setDisplayOrderEditMode(false);
            })
            .catch((err) => {
                const errors = err.response ? err.response.data.errors : [{ message: err.message }];
                console.error(errors);
                setSubmittingDisplayOrder(false);
                setShowSubmitDisplayOrderDialog(false);
                setShowErrorDialog(true);
            });
        setActiveDiagnoses(activeDiagnoses.sort(reviewDiagnosesSortFunc));
    };

    const onCancelDisplayOrderClick = (): void => {
        const originalDiagnoses = [...reviewDiagnoses]
            ?.filter((review: IReviewDiagnosis) => !review.removed && review.reviewType === diagnosisType)
            ?.map(addDisplayOrderFunc)
            ?.sort(reviewDiagnosesSortFunc);
        setActiveDiagnoses(originalDiagnoses);
        setDisplayOrderEditMode(false);
    };

    const onSaveDisplayOrderClick = (): void => {
        setSubmittingDisplayOrder(true);
        if (activeDiagnoses.find((diagnosis) => !diagnosis.displayOrder)) {
            setShowDisplayOrderErrorDialog(true);
            setSubmittingDisplayOrder(false);
        } else {
            showConfirmDisplayOrderDialog();
        }
    };

    if (!organisation) {
        return null;
    }

    return (
        <>
            <Table selectable columns={2}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>Display order</Grid.Column>
                                    <Grid.Column className="diagnosis-edit-display-order">
                                        {!displayOrderEditMode && activeDiagnoses?.length > 1 ? (
                                            <ContextIconButton iconName="edit" onClick={onDisplayOrderEditClick} />
                                        ) : null}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Table.HeaderCell>
                        <Table.HeaderCell>Diagnosis</Table.HeaderCell>
                        <Table.HeaderCell>Management options</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>{getReviewDiagnosesTableRows()}</Table.Body>
            </Table>
            {displayOrderEditMode ? (
                <Grid>
                    <Grid.Column computer={12}>
                        <Message size="mini" negative>
                            The displayed configuration has not been saved yet, please make sure the correct order is
                            set and saved before moving to another tab.
                        </Message>
                    </Grid.Column>
                    <Grid.Column computer={4}>
                        <Button loading={submittingDisplayOrder} onClick={onSaveDisplayOrderClick} floated="right">
                            Save
                        </Button>

                        <Button onClick={onCancelDisplayOrderClick} floated="right">
                            Cancel
                        </Button>
                    </Grid.Column>
                </Grid>
            ) : null}

            <AddDisplayOrderDialog
                showModal={showDisplayOrderErrorDialog}
                handleClose={() => setShowDisplayOrderErrorDialog(false)}
            />

            <UpdateDiagnosisDialog
                showModal={showSubmitDisplayOrderDialog}
                handleClose={() => {
                    setShowSubmitDisplayOrderDialog(false);
                    setSubmittingDisplayOrder(false);
                }}
                handleContinue={saveDisplayOrder}
                isConfirmAction
            />

            <SomethingWentWrongDialog showModal={showErrorDialog} handleClose={() => setShowErrorDialog(false)} />
        </>
    );
};

export default DiagnosesTable;
