import { FC } from "react";

import { UserRole } from "model/userRole";

import userService from "services/userService";

const HospitalNumberTip: FC = () => {
    const currentUserRole = userService.getLoggedUserRole();
    const isUnauthenticatedOrPatient = !currentUserRole || currentUserRole === UserRole.PATIENT;

    if (!isUnauthenticatedOrPatient) {
        return null;
    }

    return (
        <p className="form-field-tip">
            You may be able to find your Hospital Number in your appointment information. If not, just leave this blank.
        </p>
    );
};

export default HospitalNumberTip;
