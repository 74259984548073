import { SUPPORT_EMAIL } from "model/common";
import UrlParts from "util/url";
import useClickOutside from "hooks/useClickOutside";

import "scss/ErrorFatal.scss";

interface IFatalError {
    resetErrorBoundary: () => void;
}

const FatalError = ({ resetErrorBoundary }: IFatalError) => {
    const { protocol, host } = UrlParts();
    const homeScreenUrl = `${protocol}//${host}/home`;

    const handleClickAway = () => {
        resetErrorBoundary();
    };

    const errorBoundaryRef = useClickOutside(handleClickAway);

    return (
        <div role="alert" className="fatal-error-page" ref={errorBoundaryRef}>
            <img alt="error" src="/images/error.svg" />
            <span>Something went wrong:</span>
            <p>
                Return <a href={homeScreenUrl}>Home</a> and try again.
            </p>
            <p>
                If you continue having trouble completing the process, contact our support team at:
                <a href={`mailto:${SUPPORT_EMAIL}`}> {SUPPORT_EMAIL}.</a> Please include details such as error messages
                and what you were trying to do.
            </p>
        </div>
    );
};

export default FatalError;
