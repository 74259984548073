import { FC } from "react";

import ResendEmail from "components/RemoteModel/PasswordScreen/ResendEmail";
import SetPassword from "components/RemoteModel/PasswordScreen/SetPassword";
import ServicesBlockedMessage from "components/ServicesBlockedMessage";

import useSetPassword from "hooks/useSetPassword";

const PasswordScreen: FC = () => {
    const {
        isInvalidToken,
        form,
        isButtonDisabled,
        isAPIFailure,
        isCheckTokenRequestPending,
        setFormValue,
        setPassword,
        renderErrors,
        setIsButtonDisabled,
        userRoleFromToken,
        passwordIsOnDenyList,
    } = useSetPassword();

    if (isCheckTokenRequestPending) {
        return null;
    }

    if (isAPIFailure) {
        return <ServicesBlockedMessage />;
    }

    if (isInvalidToken) {
        return <ResendEmail />;
    }

    return (
        <SetPassword
            isButtonDisabled={isButtonDisabled}
            form={form}
            setFormValue={setFormValue}
            setPassword={setPassword}
            renderErrors={renderErrors}
            setIsButtonDisabled={setIsButtonDisabled}
            userRoleFromToken={userRoleFromToken}
            passwordIsOnDenyList={passwordIsOnDenyList}
        />
    );
};

export default PasswordScreen;
