import { FC, FormEvent, ReactNode, useContext, useEffect } from "react";
import { CheckboxProps, Divider, DropdownProps, Form, Grid, Segment, SemanticWIDTHS } from "semantic-ui-react";

import AuditConfiguration from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AuditConfiguration";
import AuditConfigurationAutomatedDecisionConsentModal from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AuditConfigurationAutomatedDecisionConsentModal";
import AuditConfigurationDisableRoutingModal from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AuditConfigurationDisableRoutingModal";
import { ORGANISATION_INHERITANCE_PROPERTY } from "components/Administration/OrganisationManagment/ViewOrganisation/OrganisationDetailsScreen";
import AllocationError from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationError";
import AllocationPriorityChangeModal from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationPriorityChangeModal";
import AllocationConfigurationTableInformation from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigurationTableInformation";
import AllocationConfigurationTable from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigurationTable";
import SaveAllocation from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/SaveAllocation";
import AllocationConfigurationTableHeader from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigurationTableHeader";
import AllocationConfigurationContext from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigProvider";
import UrgentReferModal from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/UrgentReferModal";
import LoadingSpinner from "components/templates/LoadingSpinner";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";

import { getAllAvailableAllocations, getDefaultAllocationConfig } from "helpers/allocations";

import { OrganisationManagementTabs, OrganisationMenuItems } from "model/administrationPages";
import { AllocationConfigurationConsentType, IAllocationComponentProps } from "model/allocationConfig";
import { GuidanceValue, IAllocation } from "model/organisation";

import organizationService from "services/organizationService";

const FULL_ALLOCATION_CONFIGURATION_LENGTH = 5;
const ALLOCATION_CONFIGURATION_LENGTH = 4;
const ADDITIONAL_COLUMNS = 4;

interface ILoadedConfig {
    allocations: IAllocation[];
    isNew: boolean;
}

const AllocationComponent: FC<IAllocationComponentProps> = ({
    organisationUuid,
    showEditButton,
    updateInheritanceData,
    parentUuid = undefined,
}) => {
    const {
        allocationState,
        allocationDispatchers: {
            createAllocations,
            setImmediateResults,
            setLoadedOrganisation,
            setNewAllocation,
            setNewPriority,
            updateOrganisation,
        },
        auditState,
        auditDispatchers: { createAuditConfiguration },
    } = useContext(AllocationConfigurationContext);

    useEffect(() => {
        const fetchOrganisation = async (orgUuid: string): Promise<void> => {
            const org = await organizationService.getOrganisationByUuid(orgUuid);
            const {
                showAutomatedDecisionConsent,
                blockCasesWithoutAutomatedDecisionConsent,
                allocationConfiguration = [],
                allowAuditRouting,
                auditConfiguration,
            } = org;

            /**
             *  OV2-3359: A number of existing allocation configurations in the database have a value of 'null' for
             *  immediateResults so OpenAPI validation of the update fails if immediateResults is not explicitly
             *  updated. We therefore need to cast existing immediateResults to a boolean value to prevent allocation
             *  updates from failing.
             */

            const allocationsForConsentedCases: IAllocation[] = allocationConfiguration
                .filter((allocation) => !allocation.removed && !allocation.isForUnconsentedAutomatedDecisionCases)
                ?.map((allocation) => {
                    const newAllocation = { ...allocation };
                    newAllocation.immediateResults = Boolean(newAllocation.immediateResults);
                    return newAllocation;
                });

            const consentedAllocations: ILoadedConfig = allocationsForConsentedCases?.length
                ? { allocations: allocationsForConsentedCases, isNew: false }
                : { allocations: getDefaultAllocationConfig(false), isNew: true };

            const allocationsForUnconsentedCases: IAllocation[] = allocationConfiguration
                .filter((allocation) => !allocation.removed && allocation.isForUnconsentedAutomatedDecisionCases)
                ?.map((allocation) => {
                    const newAllocation = { ...allocation };
                    newAllocation.immediateResults = Boolean(newAllocation.immediateResults);
                    return newAllocation;
                });

            const unconsentedAllocations: ILoadedConfig = allocationsForUnconsentedCases?.length
                ? { allocations: allocationsForUnconsentedCases, isNew: false }
                : { allocations: getDefaultAllocationConfig(true), isNew: true };

            const configLength = allocationsForConsentedCases?.find(
                (allocation) => allocation.guidanceValue === GuidanceValue.URGENT_REFER
            )
                ? FULL_ALLOCATION_CONFIGURATION_LENGTH
                : ALLOCATION_CONFIGURATION_LENGTH;

            setLoadedOrganisation({
                consentedAllocations: consentedAllocations.allocations,
                unconsentedAllocations: unconsentedAllocations.allocations,
                showAutomatedDecisionConsent,
                blockCasesWithoutAutomatedDecisionConsent,
                isNewConsented: consentedAllocations.isNew,
                isNewUnconsented: unconsentedAllocations.isNew,
                isChangedConsented: allocationsForConsentedCases?.length !== configLength,
                isChangedUnconsented: allocationsForUnconsentedCases?.length !== configLength,
                auditConfiguration,
                enableAuditConfiguration: allowAuditRouting || false,
            });
        };

        fetchOrganisation(organisationUuid);
    }, []);

    const handleSetNewPriority = (data: DropdownProps): void => {
        const { allocation: modifiedAllocation, value } = data;
        setNewPriority({ modifiedAllocation, value });
    };

    const handleSetNewAllocation = (_event: FormEvent<HTMLInputElement>, obj: CheckboxProps): void => {
        const { allocation: modifiedAllocation, value } = obj;
        setNewAllocation({
            modifiedAllocation,
            value,
        });
    };

    const handleSetImmediateResults = (_event: FormEvent<HTMLInputElement>, obj: CheckboxProps): void => {
        const { allocation: modifiedAllocation, checked } = obj;
        setImmediateResults({
            modifiedAllocation,
            checked,
        });
    };

    const handleUpdateOrganisation = (result: any): void => {
        const { configuration } = result;
        updateOrganisation(configuration);
        updateInheritanceData(ORGANISATION_INHERITANCE_PROPERTY[OrganisationMenuItems.CASE_ALLOCATION]);
    };

    const saveAllocationAndAuditConfiguration = async () => {
        const {
            consented: { isNew, isChanged },
        } = allocationState;
        const { isChanged: isChangedAuditConfiguration } = auditState;
        if (isNew || isChanged) {
            await createAllocations(AllocationConfigurationConsentType.CONSENTED, organisationUuid);
        }
        if (isChangedAuditConfiguration) {
            await createAuditConfiguration(organisationUuid);
        }
    };

    const getAllocationSettingsTableTitle = (defaultLayout: boolean): string =>
        defaultLayout ? "Case allocation configuration" : "Cases WITH CONSENT to automated decision making";

    const getSubheaderCopy = (defaultLayout: boolean): string =>
        `${
            defaultLayout
                ? "After AI assessment, cases will be allocated for human assessment according to this configuration."
                : "Configure who the cases should be allocated to if the patients have given their consent to automated decision making."
        } If Immediate Results is selected, case creator will be taken to AI
            results screen immediately - before any human assessment. If Immediate Results is not selected, user
            will see a pop-up message explaining next steps. Refer to Templates settings for configuration of
            messaging.`;

    /**
     * Consented allocation configuration tables
     */
    const getAllocationSettingsTable = (): ReactNode => {
        const {
            consented: { isNew, isChanged, loading, error },
            showDefaultLayout,
            enableUrgentRefer,
        } = allocationState;
        const {
            isChanged: isChangedAuditConfiguration,
            loading: isLoadingAuditConfiguration,
            error: auditConfigurationError,
        } = auditState;
        const allPossibleAllocations: string[] = getAllAvailableAllocations();
        const columnAmount: SemanticWIDTHS = (allPossibleAllocations.length + ADDITIONAL_COLUMNS) as SemanticWIDTHS;

        return (
            <>
                <Segment>
                    <AllocationConfigurationTableInformation title={getAllocationSettingsTableTitle(showDefaultLayout)}>
                        <OrganisationManagementTabHeader
                            organisationUuid={organisationUuid}
                            type={OrganisationManagementTabs.ALLOCATIONS}
                            showEditButton={showEditButton}
                            updateOrganisation={handleUpdateOrganisation}
                        />
                    </AllocationConfigurationTableInformation>
                    <p>{getSubheaderCopy(showDefaultLayout)}</p>
                    <Divider />
                    <Form>
                        <AllocationConfigurationTableHeader
                            columnAmount={columnAmount}
                            enableUrgentRefer={enableUrgentRefer}
                        >
                            <AllocationConfigurationTable
                                allocationSettings={allocationState[AllocationConfigurationConsentType.CONSENTED]}
                                organisationUuid={organisationUuid}
                                parentUuid={parentUuid}
                                type={AllocationConfigurationConsentType.CONSENTED}
                                setImmediateResults={handleSetImmediateResults}
                                setNewPriority={handleSetNewPriority}
                                setNewAllocation={handleSetNewAllocation}
                            />
                        </AllocationConfigurationTableHeader>
                        <Divider />
                        <AuditConfiguration />
                        <Grid>
                            <Grid.Row verticalAlign="middle">
                                <AllocationError error={error} />
                                <AllocationError error={auditConfigurationError} />
                                <SaveAllocation
                                    loading={loading || isLoadingAuditConfiguration}
                                    isNew={isNew}
                                    isChanged={isChanged || isChangedAuditConfiguration}
                                    callback={saveAllocationAndAuditConfiguration}
                                />
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
            </>
        );
    };

    const getUnconsentedAllocationSettingsTable = (): ReactNode => {
        const {
            unconsented: { isNew, isChanged, loading, error },
            enableUrgentRefer,
        } = allocationState;
        const allPossibleAllocations: string[] = getAllAvailableAllocations();
        const columnAmount: SemanticWIDTHS = (allPossibleAllocations.length + ADDITIONAL_COLUMNS) as SemanticWIDTHS;

        return (
            <Segment>
                <AllocationConfigurationTableInformation title="Cases WITHOUT CONSENT to automated decision making">
                    <OrganisationManagementTabHeader
                        organisationUuid={organisationUuid}
                        type={OrganisationManagementTabs.ALLOCATIONS}
                        showEditButton={showEditButton}
                        updateOrganisation={handleUpdateOrganisation}
                    />
                </AllocationConfigurationTableInformation>
                <p>
                    Configure who the cases should be allocated to if the patients have NOT given their consent to
                    automated decision making. If Immediate Results is selected, case creator will be taken to AI
                    results screen immediately - before any human assessment. If Immediate Results is not selected, user
                    will see a pop-up message explaining next steps. Refer to Templates settings for configuration of
                    messaging.
                </p>
                <Divider />
                <Form>
                    <AllocationConfigurationTableHeader
                        columnAmount={columnAmount}
                        enableUrgentRefer={enableUrgentRefer}
                        isUnconsented
                    >
                        <AllocationConfigurationTable
                            allocationSettings={allocationState[AllocationConfigurationConsentType.UNCONSENTED]}
                            organisationUuid={organisationUuid}
                            parentUuid={parentUuid}
                            type={AllocationConfigurationConsentType.UNCONSENTED}
                            setImmediateResults={handleSetImmediateResults}
                            setNewPriority={handleSetNewPriority}
                            setNewAllocation={handleSetNewAllocation}
                        />
                    </AllocationConfigurationTableHeader>
                    <Divider />
                    <Grid>
                        <Grid.Row verticalAlign="middle">
                            <AllocationError error={error} />
                            <SaveAllocation
                                loading={loading}
                                isNew={isNew}
                                isChanged={isChanged}
                                callback={() =>
                                    createAllocations(AllocationConfigurationConsentType.UNCONSENTED, organisationUuid)
                                }
                            />
                        </Grid.Row>
                    </Grid>
                </Form>
            </Segment>
        );
    };

    return !allocationState.consented.allocations.length ? (
        <LoadingSpinner />
    ) : (
        <>
            <UrgentReferModal />
            <AllocationPriorityChangeModal />
            <AuditConfigurationAutomatedDecisionConsentModal />
            <AuditConfigurationDisableRoutingModal />
            {getAllocationSettingsTable()}
            {allocationState.showUnconsentedCaseSettings ? getUnconsentedAllocationSettingsTable() : null}
        </>
    );
};

export default AllocationComponent;
