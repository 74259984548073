import { Component } from "react";
import { Container, Divider, Grid, Loader, Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import PatientBanner from "components/templates/PatientBanner";
import QRCodePopup from "components/templates/QRCodePopup";
import SupportEmailLink from "components/templates/SupportEmailLink";

import { doesLesionContainNeededImages, getLegacyBodyForQrCode } from "helpers/assessment";

import { IAssessment, ILesion } from "model/assessment";
import { IOrganisation } from "model/organisation";
import { UserRole } from "model/userRole";
import { ICase, IImage } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { PHONE_NUMBER } from "model/common";
import imageCaptureInstructions from "model/imageCapture";

import { history } from "App";
import { IMAGE_MANUAL_UPLOAD, LESION_LOCATOR, READY_FOR_ASSESSMENT } from "navigation/routes";

import lesionService from "services/lesionService";
import userService from "services/userService";
import logoutTimeoutService from "services/logoutTimeoutService";
import * as caseService from "services/caseService";

interface IImageCapture {
    assessment: IAssessment;
    authToken: string;
    refreshToken: string;
    currentLesion: number;
    wizard: any;
    organisation: IOrganisation;

    getCase: (caseUuid: string) => void;
}

interface IImageCaptureState {
    imageCaptured: boolean;
    intervalId: NodeJS.Timer;
}

const REFETCH_INTERVAL = 3000;

class ImageCapture extends Component<IImageCapture, IImageCaptureState> {
    constructor(props: IImageCapture) {
        super(props);
        this.state = {
            imageCaptured: false,
            intervalId: null,
        };
    }

    public componentDidMount() {
        const { assessment, getCase } = this.props;
        const { case: currentCase } = assessment;
        const { lesions, uuid } = currentCase;
        logoutTimeoutService.stopCount();

        if (lesions) {
            this.startCheckIfImagesUploaded();
        } else {
            setTimeout(() => getCase(uuid), REFETCH_INTERVAL);
        }
    }

    public componentDidUpdate(prevProps: Readonly<IImageCapture>): void {
        const {
            assessment: {
                case: { lesions, uuid },
                currentLesion,
            },
            getCase,
        } = this.props;

        // If current lesion cannot be detected, get the latest case data
        if (!lesions[currentLesion]) {
            getCase(uuid);
        }

        if (!prevProps.assessment.case.lesions && lesions) {
            this.startCheckIfImagesUploaded();
        }
    }

    public componentWillUnmount() {
        this.stopWatchIfReportAvailable();
    }

    private startCheckIfImagesUploaded = () => {
        const intervalId = setInterval(this.hasReceivedImagesFromMobile, REFETCH_INTERVAL);
        this.setState({ intervalId });
    };

    private stopWatchIfReportAvailable = () => {
        const { intervalId } = this.state;
        clearInterval(intervalId);
    };

    private hasReceivedImagesFromMobile = async () => {
        const { assessment, currentLesion } = this.props;
        const { case: currentCase, nonSkinCancer } = assessment;
        const { lesions, uuid } = currentCase;
        const { intervalId } = this.state;
        if (lesions[currentLesion]) {
            if (!nonSkinCancer) {
                const lesion: ILesion = await lesionService.getLesion(lesions[currentLesion].uuid);
                const hasImagesToRetake = (lesion?.images as IImage[])?.some((image) => !image.removed && image.retake);

                if (lesion?.images && !hasImagesToRetake) {
                    if (doesLesionContainNeededImages(lesion.images)) {
                        this.setState({ imageCaptured: true });
                        clearInterval(intervalId);
                        history.push(READY_FOR_ASSESSMENT);
                    }
                }
            } else {
                const caseObject: ICase = await caseService.getCaseAsync(uuid);
                if (
                    caseObject &&
                    ![CaseStatus.CASE_IN_PROGRESS, CaseStatus.CASE_CREATED].includes(caseObject.caseStatus)
                ) {
                    this.setState({ imageCaptured: true });
                    clearInterval(intervalId);
                    history.push(READY_FOR_ASSESSMENT);
                }
            }
        }
    };

    public render() {
        const { authToken, assessment, refreshToken, organisation } = this.props;
        const { imageCaptured } = this.state;

        const { case: currentCase, currentLesion, patient, lesion, nonSkinCancer } = assessment;
        const { lesions, caseId, homeInitiated } = currentCase;

        const isClinician = userService.checkUserHasRole([UserRole.CLINICIAN]);
        const isSuperAdmin = userService.checkUserHasRole([UserRole.SUPERADMIN]);
        const isBackButtonDisabled = !currentCase || !lesions || lesions.length === 0;
        const showBackButton = !(homeInitiated && (isSuperAdmin || isClinician));

        return (
            <Container className="ui segment wizzard-container">
                <PatientBanner
                    caseId={caseId}
                    patient={patient}
                    lesion={lesion}
                    lesionNumber={currentLesion}
                    nonSkinCancerFlow={nonSkinCancer}
                />

                <div className="image-capture-header">
                    <h1>Scan the code using Skin Analytics app</h1>
                    {(isSuperAdmin || nonSkinCancer) && (
                        <CustomButton
                            variant="filled"
                            type="link"
                            to={IMAGE_MANUAL_UPLOAD}
                            text="Manual Upload"
                            size="small"
                        />
                    )}
                </div>
                <Divider />
                <Grid>
                    <Grid.Column mobile={16} computer={8}>
                        <QRCodePopup
                            value={getLegacyBodyForQrCode(authToken, refreshToken, assessment, organisation)}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={8}>
                        <Grid columns={2}>
                            <Grid.Row style={{ height: "50px", background: "#F6F7F9" }}>
                                <Grid.Column width={1}>
                                    <Loader active={!imageCaptured} inline size="small" />
                                </Grid.Column>
                                <Grid.Column>
                                    <p>Waiting for images from mobile device...</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid>
                            <Grid.Row>
                                <h3>Can&apos;t scan the code?</h3>
                            </Grid.Row>
                            <Grid.Row>
                                <p>Check that the phone is connected to Wi-Fi or the mobile network.</p>
                            </Grid.Row>
                            <Grid.Row>
                                <p>
                                    If you have trouble, contact <SupportEmailLink /> or call&nbsp;
                                    {PHONE_NUMBER}
                                </p>
                            </Grid.Row>
                            <Grid.Row>
                                <Image
                                    alt={imageCaptureInstructions}
                                    src="/images/imageCaptureInstruction.png"
                                    size="big"
                                />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>

                {showBackButton && (
                    <div className="case-buttons">
                        <Divider />
                        <ButtonContainer
                            button1={
                                <CustomButton
                                    variant="empty"
                                    type="link"
                                    to={`${LESION_LOCATOR}/${currentLesion + 1}`}
                                    action={this.stopWatchIfReportAvailable}
                                    text="< Back"
                                    size="small"
                                    disabled={isBackButtonDisabled}
                                    loading={isBackButtonDisabled}
                                />
                            }
                            button2={<></>}
                        />
                    </div>
                )}
            </Container>
        );
    }
}

export default ImageCapture;
