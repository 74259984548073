import React, { FC, useState, useContext, useEffect } from "react";
import { Form, Header, Button } from "semantic-ui-react";

import ConditionalRender from "components/templates/ConditionalRender";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { UserRole } from "model/userRole";

import * as caseService from "services/caseService";

interface ICaseSasLink {
    currentCaseUuid: string;
    currentCaseSasLink: string;
}

const CaseSasLink: FC<ICaseSasLink> = ({ currentCaseUuid, currentCaseSasLink }) => {
    const [sasLink, setSasLink] = useState("");
    const [sasLinkEditMode, setsasLinkEditMode] = useState(false);
    const { updateCaseSasLink } = useContext(CaseListContext);

    useEffect(() => {
        setSasLink(currentCaseSasLink);
    }, [currentCaseSasLink]);

    const onSASLinkChange = (event: any, obj: any) => {
        setSasLink(obj.value);
        setsasLinkEditMode(true);
    };

    const onSASLinkToggleMode = () => {
        setsasLinkEditMode(!sasLinkEditMode);
    };

    const onSASLinkSave = async () => {
        onSASLinkToggleMode();
        await caseService.updateCaseSasLink(currentCaseUuid, sasLink);
        updateCaseSasLink(sasLink, currentCaseUuid);
    };

    const onSASLinkDelete = async () => {
        setSasLink("");
        onSASLinkToggleMode();
        await caseService.updateCaseSasLink(currentCaseUuid, "");
        updateCaseSasLink("", currentCaseUuid);
    };

    return (
        <ConditionalRender requiredRole={[UserRole.SA_ADMIN, UserRole.SUPERADMIN]}>
            <div className="section-case-sas-link__container">
                <Header as="h3">Add SAS Link</Header>

                <div className="section-case-sas-link__actions">
                    {sasLink && !sasLinkEditMode ? (
                        <div className="section-case-sas-link__case-sas-wrapper">
                            <a href={sasLink} target="_blank" rel="noreferrer">
                                {sasLink}
                            </a>
                            <Button onClick={onSASLinkToggleMode}>Edit</Button>
                            {Boolean(sasLink.length) && <Button onClick={onSASLinkDelete}>Delete</Button>}
                        </div>
                    ) : (
                        <div className="section-case-sas-link__case-sas-wrapper">
                            <Form.Input value={sasLink} type="text" id="SASLink" onChange={onSASLinkChange} />
                            {!sasLinkEditMode && Boolean(sasLink.length) && (
                                <Button onClick={onSASLinkDelete}>Delete</Button>
                            )}
                            <Button onClick={onSASLinkSave} disabled={!sasLink.length}>
                                Save
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </ConditionalRender>
    );
};

export default CaseSasLink;
