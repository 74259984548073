import { FC, useContext } from "react";
import { Grid } from "semantic-ui-react";

import AllocationConfigurationContext from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigProvider";
import ModalDialog from "components/templates/dialog/ModalDialog";

import "scss/Dialog.scss";

const AuditConfigurationDisableRoutingModal: FC = () => {
    const {
        auditState: { showDisableAuditRoutingModal },
        auditDispatchers: { toggleDisableAuditRoutingModal, disableAuditRoutingAndConfigurations },
    } = useContext(AllocationConfigurationContext);

    const handleModalClose = () => {
        toggleDisableAuditRoutingModal();
    };

    const handleAuditRoutingDisabling = () => {
        disableAuditRoutingAndConfigurations();
    };

    if (!showDisableAuditRoutingModal) {
        return null;
    }

    return (
        <ModalDialog
            className="warning-modal"
            title="Are you sure you want to deactivate the “Audit routing”?"
            open={showDisableAuditRoutingModal}
            iconSrc="/images/icons/icon-warning-yellow-bg.svg"
            onClose={handleModalClose}
            maxWidth="md"
            disableBackdropClick
            buttons={[
                {
                    onClick: handleAuditRoutingDisabling,
                    text: "Yes, deactivate",
                    colour: "primary",
                },
                {
                    onClick: handleModalClose,
                    text: "Cancel",
                    colour: "secondary",
                },
            ]}
        >
            <Grid className="grid-dialog-body">
                <Grid.Row>
                    <Grid.Column>
                        By doing so, you agree to deactivate the routing for the different criteria selected
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </ModalDialog>
    );
};

export default AuditConfigurationDisableRoutingModal;
