import React, { FC, useContext } from "react";
import clsx from "clsx";

import useColumnSort from "components/CaseList/hooks/useColumnSort";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { SortOrder, SortOrderUI, CaseListPatientColumns, CaseListColumns } from "model/caseList";

const CUSTOM_COLUMN_NAME_PATIENT = [CaseListColumns.ID, CaseListColumns.ORGANISATION];

interface ICaseListHeaderItem {
    columnName: string;
    isCurrentUserPatient: boolean;
}

const CaseListHeaderItem: FC<ICaseListHeaderItem> = ({ columnName, isCurrentUserPatient }) => {
    const { currentTabCases } = useContext(CaseListContext);
    const { handleSortColumnClicked } = useColumnSort();

    const { sort: sorting } = currentTabCases;

    const currentColumnSorting = sorting?.[columnName];
    const isColumnSorted = Boolean(currentColumnSorting);
    const currentColumnOrderUI = currentColumnSorting === SortOrder.ASC ? SortOrderUI.ASC : SortOrderUI.DESC;

    const customColumnName = isCurrentUserPatient && CUSTOM_COLUMN_NAME_PATIENT.includes(columnName as CaseListColumns);

    const displayName = customColumnName ? CaseListPatientColumns[columnName] : columnName;
    const sorted = isColumnSorted ? { sorted: currentColumnOrderUI } : undefined;
    const classNames = clsx({
        "case-list__header-cell-mobile": true,
        sorted,
        ...(isColumnSorted
            ? {
                  ascending: currentColumnOrderUI === SortOrderUI.ASC,
                  descending: currentColumnOrderUI === SortOrderUI.DESC,
              }
            : {}),
    });

    const handleSortClicked = (): void => {
        handleSortColumnClicked(columnName);
    };

    return (
        <div
            onClick={handleSortClicked}
            onKeyPress={handleSortClicked}
            role="button"
            className={classNames}
            tabIndex={0}
            {...sorted}
        >
            <p>{displayName}</p>
        </div>
    );
};

export default CaseListHeaderItem;
