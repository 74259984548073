import { createActions } from "redux-actions";

import types from "redux/actions/types";

export const {
    mfaStatusRequest,
    mfaStatusRequestSuccess,
    mfaStatusRequestError,
    mfaVerificationSent,
    mfaVerified,
    mfaCompleteSetup,
    clearData,
} = createActions(
    types.MFA_STATUS_REQUEST,
    types.MFA_STATUS_REQUEST_SUCCESS,
    types.MFA_STATUS_REQUEST_ERROR,
    types.MFA_VERIFIED,
    types.MFA_VERIFICATION_SENT,
    types.MFA_COMPLETE_SETUP,
    types.CLEAR_DATA
);

// TODO add skip action
