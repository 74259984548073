import React from "react";
import { Grid } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";

import { getFieldFromTemplate, getTemplate } from "helpers/template";

import { ICase } from "model/case";
import { IOrganisation } from "model/organisation";
import { TemplateType } from "model/templateType";

import "scss/Dialog.scss";

interface ICloseCaseDialog {
    showDialog: boolean;
    callback: (isOpenPopup: boolean) => void;
    caseObject: ICase;
    organisation: IOrganisation;
    closeCase: () => void;
}

const CloseCaseDialog = (props: ICloseCaseDialog) => {
    const { closeCase, showDialog, callback, caseObject, organisation } = props;

    const template = getTemplate(TemplateType.CLOSE_CASE_CONFIRMATION_POPUP, organisation);
    const bodyText = getFieldFromTemplate("message", caseObject, organisation, template);
    const title = getFieldFromTemplate("title", caseObject, organisation, template);

    const closeDialog = () => {
        callback(false);
    };

    const onConfirm = () => {
        closeCase();
        closeDialog();
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>{bodyText}</Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <ModalDialog
                title={title}
                open={showDialog}
                iconSrc="/images/icons/icon-note.svg"
                onClose={closeDialog}
                maxWidth="sm"
                disableBackdropClick
                buttons={[
                    {
                        onClick: onConfirm,
                        text: "Close",
                    },
                    {
                        text: "Cancel",
                        onClick: closeDialog,
                    },
                ]}
            >
                {popupBody}
            </ModalDialog>
        )) ||
        null
    );
};

export default CloseCaseDialog;
