import { FC } from "react";
import { Grid } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

import ModalDialog from "components/templates/dialog/ModalDialog";
import SupportEmailLink from "components/templates/SupportEmailLink";

import { IOrganisation } from "model/organisation";

import "scss/Dialog.scss";

interface IUnableToSendForAssessmentModal {
    caseId: string;
    organisation: IOrganisation;
    showModal: boolean;
    message: string;
    buttonText: string;
    closeModal: () => void;
}

const UnableToSendForAssessmentModal: FC<IUnableToSendForAssessmentModal> = ({
    caseId,
    organisation,
    showModal,
    message,
    buttonText,
    closeModal,
}) => {
    const { name: organisationName } = organisation;

    if (!showModal) {
        return null;
    }

    const handleModalClose = () => {
        closeModal();
    };

    return (
        <ModalDialog
            title="Unable to send for assessment"
            subtitle={
                <div>
                    <span>{`Case ID: ${caseId} > ${organisationName}`}</span>
                </div>
            }
            open={showModal}
            iconSrc="/images/icons/icon-warning-red-bg.svg"
            onClose={handleModalClose}
            maxWidth="sm"
            disableBackdropClick
            buttons={[
                {
                    onClick: closeModal,
                    text: buttonText,
                    colour: "primary",
                },
            ]}
        >
            <Grid className="grid-dialog-body">
                <Grid.Row>
                    <Grid.Column>
                        <p>{ReactHtmlParser(message)}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p>
                            If this does not work, please contact <SupportEmailLink /> in order to manage this case
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </ModalDialog>
    );
};

export default UnableToSendForAssessmentModal;
