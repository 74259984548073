import { FC } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Divider, Form, Grid, Input, Message } from "semantic-ui-react";

import ExpiredTokenMessage from "components/ExpiredTokenMessage";
import PasswordMatching from "components/PasswordMatching";
import UpdatePassword from "components/ResetPassword/UpdatePassword";
import ServicesBlockedMessage from "components/ServicesBlockedMessage";
import CustomButton from "components/templates/Button";

import { blacklistErrorMessage } from "helpers/password";

import useSetPassword, { CONFIRMED_PASSWORD_FIELD_NAME, PASSWORD_FIELD_NAME } from "hooks/useSetPassword";

import ResetPasswordModalType from "model/resetPassword";

import { history } from "App";

import { LOGIN } from "navigation/routes";

import getAuth from "redux/selectors/auth";

import "scss/ResetPasswordScreen.scss";

const ResetPassword: FC = () => {
    const { showUpdatePasswordPrompt } = useSelector(getAuth);

    const {
        form,
        setFormValue,
        setPassword,
        renderErrors,
        isInvalidToken,
        isAPIFailure,
        isButtonDisabled,
        isCheckTokenRequestPending,
        setIsButtonDisabled,
        userRoleFromToken,
        passwordIsOnDenyList,
        formHasErrors,
    } = useSetPassword();

    const changePassword = async () => {
        const result = await setPassword();
        if (result) {
            history.push(LOGIN);
        }
    };

    const passwordFormValue = form[PASSWORD_FIELD_NAME];
    const confirmedPasswordFormValue = form[CONFIRMED_PASSWORD_FIELD_NAME];

    if (isCheckTokenRequestPending) {
        return null;
    }

    if (isAPIFailure) {
        return <ServicesBlockedMessage />;
    }

    if (isInvalidToken) {
        return <ExpiredTokenMessage type={ResetPasswordModalType.RESET} />;
    }

    if (showUpdatePasswordPrompt) {
        return (
            <UpdatePassword
                passwordFormValue={passwordFormValue}
                confirmedPasswordFormValue={confirmedPasswordFormValue}
                userRoleFromToken={userRoleFromToken}
                isButtonDisabled={isButtonDisabled}
                formHasErrors={formHasErrors}
                setFormValue={setFormValue}
                passwordIsOnDenyList={passwordIsOnDenyList}
                setIsButtonDisabled={setIsButtonDisabled}
                changePassword={changePassword}
            />
        );
    }

    return (
        <Container className="custom__container">
            <div className="custom__wrapper">
                <h1>Reset Password </h1>
                <Divider />
                <Grid.Row columns={2}>
                    <Grid.Column>
                        {renderErrors()}

                        {passwordIsOnDenyList() ? (
                            <Message size="large" content={blacklistErrorMessage} negative />
                        ) : null}

                        <Form>
                            <h5> Enter your new password </h5>
                            <Form.Field>
                                <Input
                                    className="field login"
                                    type="password"
                                    placeholder="New password"
                                    name={PASSWORD_FIELD_NAME}
                                    onChange={setFormValue}
                                    value={passwordFormValue}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    className="field login"
                                    type="password"
                                    placeholder="Confirm password"
                                    name={CONFIRMED_PASSWORD_FIELD_NAME}
                                    onChange={setFormValue}
                                    value={confirmedPasswordFormValue}
                                />
                            </Form.Field>

                            <CustomButton
                                type="submit"
                                variant="filled"
                                text="Reset Password"
                                action={changePassword}
                                disabled={isButtonDisabled}
                            />
                        </Form>
                    </Grid.Column>
                    <Grid.Column>
                        <PasswordMatching
                            newPassword={passwordFormValue}
                            confirmNewPassword={confirmedPasswordFormValue}
                            makeButtonDisabled={setIsButtonDisabled}
                            userRole={userRoleFromToken}
                        />
                    </Grid.Column>
                </Grid.Row>
            </div>
        </Container>
    );
};

export default withRouter(ResetPassword);
