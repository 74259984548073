import { FC, SyntheticEvent, useState } from "react";
import { ButtonProps, CheckboxProps, DropdownProps, Form, Grid, InputOnChangeData, Table } from "semantic-ui-react";

import ContextIconButton from "components/templates/ContextIconButton";

import {
    emptyPersonalDataFieldValueErrorMessage,
    getValidationPropertyOptions,
    isStringFieldValueEmpty,
    PERSONAL_DATA_DISPLAY_ORDER_MAX_LENGTH,
    PERSONAL_DATA_VALUE_MAX_LENGTH,
    validateDisplayNamePersonalField,
    validateDisplayOrderPersonalField,
    validateIdentifierPersonalField,
    validateRequiredPersonalField,
} from "helpers/organisation";

import {
    PersonalDataFieldCoreProperties,
    DefinedPersonalDataType,
    IDefinedPersonalData,
    IDefinedPersonalDataErrors,
    IDefinedValidationType,
    INewDefinedPersonalData,
    IIdentifierValidationErrors,
} from "model/organisation";

interface ICreateFieldRow {
    definedPersonalData: IDefinedPersonalData[];
    isIntegrationEnabled: boolean;
    onRowAdded: (obj: INewDefinedPersonalData) => Promise<void>;
    hideNewCustomCoreField: () => void;
}

const INITIAL_NEW_PERSONAL_DATA = {
    displayName: "",
    displayOrder: 0,
    identifier: false,
    propertyName: "",
    required: false,
    type: null,
    validationProperty: undefined,
    options: undefined,
    newOption: "",
};

const CreateFieldRow: FC<ICreateFieldRow> = ({
    definedPersonalData,
    isIntegrationEnabled,
    onRowAdded,
    hideNewCustomCoreField,
}) => {
    const [errors, setErrors] = useState<IDefinedPersonalDataErrors>({
        displayNameError: "",
        displayOrderError: "",
        identifierError: "",
        propertyNameError: "",
        requiredError: "",
        typeError: "",
        newOption: "",
        options: "",
    });
    const [newPersonalData, setNewPersonalData] = useState<INewDefinedPersonalData>(INITIAL_NEW_PERSONAL_DATA);

    const validateDisplayOrder = (fieldValue: string): string =>
        validateDisplayOrderPersonalField(fieldValue, definedPersonalData);

    const validateIdentifier = (fieldValue: boolean): IIdentifierValidationErrors =>
        validateIdentifierPersonalField(fieldValue, newPersonalData, definedPersonalData);

    const validateRequired = (fieldValue: boolean): string =>
        validateRequiredPersonalField(fieldValue, newPersonalData);

    const validateDisplayName = (fieldValue: string): string =>
        validateDisplayNamePersonalField(fieldValue, definedPersonalData);

    const validatePropertyName = (fieldValue: string) => {
        if (isStringFieldValueEmpty(fieldValue)) {
            return emptyPersonalDataFieldValueErrorMessage;
        }
        const propertyNameDuplicate: IDefinedPersonalData = definedPersonalData.find(
            (item: IDefinedPersonalData) => item.propertyName === fieldValue
        );
        if (propertyNameDuplicate) {
            return "Property name should be unique";
        }
        return "";
    };

    const validateType = (fieldValue: string) => {
        if (isStringFieldValueEmpty(fieldValue)) {
            return emptyPersonalDataFieldValueErrorMessage;
        }
        return "";
    };

    const validate = (fieldName: string, fieldValue: string | boolean) => {
        const updatedErrors = { ...errors };
        if (fieldName === PersonalDataFieldCoreProperties.propertyName) {
            updatedErrors.propertyNameError = validatePropertyName(`${fieldValue}`);
        }

        if (fieldName === PersonalDataFieldCoreProperties.required) {
            updatedErrors.requiredError = validateRequired(Boolean(fieldValue));
        }

        if (fieldName === PersonalDataFieldCoreProperties.displayName) {
            updatedErrors.displayNameError = validateDisplayName(`${fieldValue}`);
        }

        if (fieldName === PersonalDataFieldCoreProperties.displayOrder) {
            updatedErrors.displayOrderError = validateDisplayOrder(`${fieldValue}`);
        }

        if (fieldName === PersonalDataFieldCoreProperties.type) {
            updatedErrors.typeError = validateType(`${fieldValue}`);
        }

        if (fieldName === PersonalDataFieldCoreProperties.identifier) {
            const { identifierError, requiredError } = validateIdentifier(Boolean(fieldValue));
            updatedErrors.requiredError = requiredError;
            updatedErrors.identifierError = identifierError;
        }
        setErrors({ ...errors, ...updatedErrors });
    };

    const onNewPersonalDataChange = (
        event: SyntheticEvent<HTMLElement, Event>,
        data: InputOnChangeData | DropdownProps | CheckboxProps
    ) => {
        const updatedPersonalData = { ...newPersonalData };
        const { name, type, checked, value } = data;
        const fieldValue = type === "checkbox" ? checked : value;
        updatedPersonalData[name] = fieldValue;
        if (name === PersonalDataFieldCoreProperties.type) {
            updatedPersonalData.validationProperty = IDefinedValidationType.none;
        }
        validate(name, fieldValue);
        setNewPersonalData(updatedPersonalData);
    };

    const validateNewOption = (newOption: string) => {
        const updatedErrors = { ...errors };
        let newOptionError = "";
        if (!newOption.trim().length) {
            newOptionError = "Field can not be empty";
        }
        const { options } = newPersonalData;
        if (options && options.some((option: string) => option === newOption)) {
            newOptionError = "Option need to be unique";
        }
        setErrors({ ...updatedErrors, newOption: newOptionError });
        return newOptionError;
    };

    const onNewOptionChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const updatedPersonalData = { ...newPersonalData };
        const { name, value } = data;
        updatedPersonalData[name] = value;
        validateNewOption(value);
        setNewPersonalData(updatedPersonalData);
    };

    const addOption = () => {
        const updatedPersonalData = { ...newPersonalData };
        const options = newPersonalData.options || [];
        if (!validateNewOption(updatedPersonalData.newOption)) {
            options.push(newPersonalData.newOption);
            updatedPersonalData.newOption = "";
            updatedPersonalData.options = options;
            setNewPersonalData(updatedPersonalData);
        }
    };

    const removeOption = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
        const updatedPersonalData = { ...newPersonalData };
        const options = updatedPersonalData.options.filter((option: string) => option !== data.arg);
        updatedPersonalData.options = options;

        setNewPersonalData(updatedPersonalData);
    };

    const resetNewPersonalData = () => {
        setNewPersonalData(INITIAL_NEW_PERSONAL_DATA);
    };

    const getPersonalData = (): INewDefinedPersonalData => {
        const personalData = { ...newPersonalData };
        if (personalData.validationProperty === IDefinedValidationType.none) {
            personalData.validationProperty = undefined;
        }
        personalData.displayOrder = Number(personalData.displayOrder);
        return personalData;
    };

    const createCoreField = async () => {
        const propertyNameError = errors.propertyNameError || validatePropertyName(newPersonalData.propertyName);
        const displayNameError = errors.displayNameError || validateDisplayName(newPersonalData.displayName);
        const displayOrderError = errors.displayOrderError || validateDisplayOrder(`${newPersonalData.displayOrder}`);
        const typeError = errors.typeError || validateType(newPersonalData.type);
        const areValuesValid = !(
            propertyNameError ||
            displayNameError ||
            displayOrderError ||
            errors.identifierError ||
            errors.requiredError ||
            typeError
        );
        if (areValuesValid) {
            const personalData = getPersonalData();
            await onRowAdded(personalData);
            resetNewPersonalData();
        } else {
            setErrors({ ...errors, propertyNameError, displayNameError, displayOrderError, typeError });
        }
    };

    const handleCancelPressed = () => {
        resetNewPersonalData();
        hideNewCustomCoreField();
    };

    const { type, options, newOption } = newPersonalData;
    const isOptionsType = type === DefinedPersonalDataType.OPTIONS;
    const { propertyNameError, displayNameError, displayOrderError, typeError, requiredError, identifierError } =
        errors;
    const validationPropertyOptions = getValidationPropertyOptions(type);

    return (
        <Table.Row>
            <Table.Cell width={2}>
                <Form.Input
                    error={propertyNameError || false}
                    type="text"
                    name={PersonalDataFieldCoreProperties.propertyName}
                    value={newPersonalData.propertyName}
                    onChange={onNewPersonalDataChange}
                    maxLength={PERSONAL_DATA_VALUE_MAX_LENGTH}
                />
            </Table.Cell>
            <Table.Cell width={2}>
                <Form.Input
                    error={displayNameError || false}
                    type="text"
                    name={PersonalDataFieldCoreProperties.displayName}
                    onChange={onNewPersonalDataChange}
                    value={newPersonalData.displayName}
                    maxLength={PERSONAL_DATA_VALUE_MAX_LENGTH}
                />
            </Table.Cell>
            <Table.Cell width={2}>
                <Form.Checkbox
                    error={requiredError || false}
                    className="checkbox"
                    name={PersonalDataFieldCoreProperties.isRequestedAtManualCreation}
                    onChange={onNewPersonalDataChange}
                    checked={newPersonalData.manualPatientСreation}
                />
            </Table.Cell>
            <Table.Cell width={1}>
                <Form.Input
                    error={displayOrderError || false}
                    type="text"
                    name={PersonalDataFieldCoreProperties.displayOrder}
                    className="small"
                    onChange={onNewPersonalDataChange}
                    value={!Number.isNaN(newPersonalData.displayOrder) ? newPersonalData.displayOrder : ""}
                    maxLength={PERSONAL_DATA_DISPLAY_ORDER_MAX_LENGTH}
                />
            </Table.Cell>
            <Table.Cell width={2}>
                <Form.Select
                    error={typeError || false}
                    name={PersonalDataFieldCoreProperties.type}
                    className="personal-type"
                    placeholder="Select type"
                    onChange={onNewPersonalDataChange}
                    value={newPersonalData.type}
                    options={Object.keys(DefinedPersonalDataType).map((key) => ({
                        key,
                        text: DefinedPersonalDataType[key],
                        value: DefinedPersonalDataType[key],
                    }))}
                />
                {isOptionsType && (
                    <Grid columns={2}>
                        {options?.map((option: string) => (
                            <Grid.Row key={option} className="option-row option-row__mapped">
                                <Grid.Column width={13}>
                                    <Grid.Row>{option}</Grid.Row>
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    <div className="option-row__icon">
                                        <ContextIconButton onClick={removeOption} iconName="trash" arg={option} />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        )) || ""}
                        <Grid.Row className="option-row">
                            <Grid.Column width={13}>
                                <Grid.Row className="option">
                                    <Form.Input
                                        error={errors.newOption || false}
                                        className="input-fixed-width"
                                        value={newOption || ""}
                                        name="newOption"
                                        onChange={onNewOptionChange}
                                    />
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column width={1}>
                                <div className="option-row__icon">
                                    <ContextIconButton onClick={addOption} iconName="add" />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </Table.Cell>
            <Table.Cell width={1}>
                <Form.Checkbox
                    error={requiredError || false}
                    className="checkbox"
                    name={PersonalDataFieldCoreProperties.required}
                    onChange={onNewPersonalDataChange}
                    checked={newPersonalData.required}
                />
            </Table.Cell>
            <Table.Cell width={1}>
                <Form.Checkbox
                    error={identifierError || false}
                    className="checkbox"
                    verticalalign="top"
                    name={PersonalDataFieldCoreProperties.identifier}
                    onChange={onNewPersonalDataChange}
                    checked={newPersonalData.identifier}
                />
            </Table.Cell>
            <Table.Cell width={1}>
                <Form.Select
                    name={PersonalDataFieldCoreProperties.validationProperty}
                    className="validation-type"
                    placeholder="Select type"
                    onChange={onNewPersonalDataChange}
                    value={newPersonalData.validationProperty}
                    options={validationPropertyOptions}
                />
            </Table.Cell>
            {isIntegrationEnabled && (
                <>
                    <Table.Cell width={1} />
                    <Table.Cell width={1} />
                </>
            )}
            <Table.Cell width={1}>
                <ContextIconButton iconName="check square" onClick={createCoreField} />
                <ContextIconButton iconName="trash" onClick={handleCancelPressed} />
            </Table.Cell>
        </Table.Row>
    );
};

export default CreateFieldRow;
