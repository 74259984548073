import React, { FC } from "react";
import { Segment } from "semantic-ui-react";
import clsx from "clsx";

import QuestionAnswer from "components/templates/QuestionAnswer";

import { IMedicalHistory } from "model/assessment";
import { IHistoryAnswerType, IMedicalHistoryQuestion } from "model/organisation";

interface IQuestion {
    question: IMedicalHistory;
    questionDefinition: IMedicalHistoryQuestion | undefined;
    showOnlyAnswer?: boolean;
    isRemoteModelSummaryPage?: boolean;
}

const Question: FC<IQuestion> = ({ question, questionDefinition, showOnlyAnswer, isRemoteModelSummaryPage }) => {
    const isTextAnswer = questionDefinition?.answerType === IHistoryAnswerType.TEXT;

    return (
        <>
            {question && questionDefinition && (
                <div className={clsx("history-question", isRemoteModelSummaryPage && "remote-model-summary-question")}>
                    <p>
                        <b>{question.question}</b>
                    </p>

                    {isTextAnswer ? (
                        <Segment className="answer">{question.answers.join(", ")}</Segment>
                    ) : (
                        questionDefinition?.options
                            ?.filter((option) => !option.removed)
                            .map((option) => {
                                const selectedQuestion = question.answers?.includes(option.option);

                                if (!selectedQuestion && (showOnlyAnswer || isRemoteModelSummaryPage)) {
                                    return null;
                                }

                                return (
                                    <QuestionAnswer
                                        key={option.option}
                                        selected={selectedQuestion}
                                        value={option.option}
                                        additionalInformation={question.additionalInformation}
                                        isRemoteModelHistory={isRemoteModelSummaryPage}
                                    />
                                );
                            })
                    )}
                </div>
            )}
        </>
    );
};

Question.defaultProps = {
    showOnlyAnswer: false,
    isRemoteModelSummaryPage: false,
};

export default Question;
