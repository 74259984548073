import { FC } from "react";
import { Table } from "semantic-ui-react";

import PatientBannerTableCell from "components/templates/PatientBanner/PatientBannerTableCell";

import { ILesion } from "model/assessment";
import { AllLesionLocation } from "model/lesionLocation";

import { isUndefined } from "helpers/common";

interface ILesionInformationTable {
    lesionNumber: number;
    lesion: ILesion;
}

const LesionInformationTable: FC<ILesionInformationTable> = ({ lesionNumber, lesion }) => {
    if (!lesion || isUndefined(lesionNumber)) {
        return null;
    }

    const { bodyPart, description } = lesion;

    return (
        <Table className="patient-banner__table" celled>
            <Table.Body>
                <Table.Row>
                    <PatientBannerTableCell title="Lesion" value={`#${lesionNumber + 1}`} width="2" isMainCell />
                    <PatientBannerTableCell title="Location" value={AllLesionLocation[bodyPart]} width="3" />
                    <PatientBannerTableCell title="Location notes" value={description} width="9" />
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

export default LesionInformationTable;
