import { FC } from "react";
import { DialogContent } from "@material-ui/core";

import ModalDialog from "components/templates/dialog/ModalDialog";

import "scss/Dialog.scss";

interface IConfirmCaseSubmissionDialog {
    showModal: boolean;
    closeModal: () => void;
    submitCase: () => void;
}

const ConfirmCaseSubmissionDialog: FC<IConfirmCaseSubmissionDialog> = ({ showModal, closeModal, submitCase }) => {
    if (!showModal) {
        return null;
    }

    return (
        <ModalDialog
            title="Are you sure you want to submit the case?"
            open={showModal}
            iconSrc="/images/icons/icon-info-filled.svg"
            onClose={closeModal}
            maxWidth="sm"
            disableBackdropClick
            buttons={[
                {
                    onClick: submitCase,
                    text: "Continue",
                    colour: "primary",
                },
                {
                    onClick: closeModal,
                    text: "Cancel",
                },
            ]}
        >
            <DialogContent>
                <p>
                    You will not be able to add any additional lesions once you submit your case. Do you want to
                    continue?
                </p>
            </DialogContent>
        </ModalDialog>
    );
};

export default ConfirmCaseSubmissionDialog;
