import { CaseStatus } from "model/caseStatus";
import { ESExistenceCheck, ESArrayExistenceCheck } from "model/common";

export enum RemoteTimeline {
    CONSENT = "CONSENT",
    PERSONAL_DETAILS = "PERSONAL_DETAILS",
    MEDICAL_HISTORY = "MEDICAL_HISTORY",
    KIT = "KIT",
    LESION_DETAILS = "LESION_DETAILS",
    LESION_IMAGES = "LESION_IMAGES",
    SUMMARY = "SUMMARY",
    REVIEW = "REVIEW",
    REPORT_AVAILABLE = "REPORT_AVAILABLE",
    CASE_CLOSED = "CASE_CLOSED",
    AWAITING_CALLBACK = "AWAITING_CALLBACK",
}

export enum RemoteTimelineDictionary {
    ALL = "All",
    CONSENT = "Consent",
    PERSONAL_DETAILS = "Personal details",
    MEDICAL_HISTORY = "Medical history",
    KIT = "Kit delivery",
    LESION_DETAILS = "Lesion details",
    LESION_IMAGES = "Lesion images",
    SUMMARY = "Case summary",
    REVIEW = "Case in review",
    REPORT_AVAILABLE = "Report available",
    CASE_CLOSED = "Case closed",
    AWAITING_CALLBACK = "Awaiting callback",
}

export enum RemoteTimelineShortenedDictionary {
    ALL = "All",
    CONSENT = "Consent",
    PERSONAL_DETAILS = "Details",
    MEDICAL_HISTORY = "History",
    KIT = "Kit",
    LESION_DETAILS = "Lesion",
    LESION_IMAGES = "Images",
    SUMMARY = "Summary",
    REVIEW = "Review",
    REPORT_AVAILABLE = "Report",
    CASE_CLOSED = "Closed",
    AWAITING_CALLBACK = "Callback",
}

export enum RemoteTimelineFilterOptions {
    ALL = "ALL",
    PERSONAL_DETAILS = "PERSONAL_DETAILS",
    MEDICAL_HISTORY = "MEDICAL_HISTORY",
    KIT = "KIT",
    LESION_IMAGES = "LESION_IMAGES",
    SUMMARY = "SUMMARY",
    REVIEW = "REVIEW",
    REPORT_AVAILABLE = "REPORT_AVAILABLE",
    CASE_CLOSED = "CASE_CLOSED",
}

export interface IRemoteTimelineFilterReq {
    patient?: ESExistenceCheck;
    medicalHistoryCreatedBy?: ESExistenceCheck;
    lesions?: ESArrayExistenceCheck;
    caseStatus?: CaseStatus | string;
    stepFilter?: RemoteTimeline.LESION_IMAGES | RemoteTimeline.SUMMARY | RemoteTimeline.REVIEW;
}

export const REMOTE_TIMELINE_ROW = [
    RemoteTimeline.CONSENT,
    RemoteTimeline.PERSONAL_DETAILS,
    RemoteTimeline.MEDICAL_HISTORY,
    RemoteTimeline.KIT,
    RemoteTimeline.LESION_DETAILS,
    RemoteTimeline.LESION_IMAGES,
    RemoteTimeline.SUMMARY,
    RemoteTimeline.REVIEW,
    RemoteTimeline.REPORT_AVAILABLE,
    RemoteTimeline.CASE_CLOSED,
];
