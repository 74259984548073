import { FC } from "react";
import { Label } from "semantic-ui-react";
import clsx from "clsx";

import { PatientLabelTypes } from "model/patientSearch";

interface IIntegrationPatientLabel {
    type: PatientLabelTypes;
}

const TITLE = {
    [PatientLabelTypes.DECEASED]: "Deceased",
    [PatientLabelTypes.MERGED_RECORD]: "Merged Record",
};

const IntegrationPatientLabel: FC<IIntegrationPatientLabel> = ({ type }) => {
    const isMergedRecord = type === PatientLabelTypes.MERGED_RECORD;
    const isDeceased = type === PatientLabelTypes.DECEASED;
    const title = TITLE[type];

    return (
        <Label
            className={clsx(
                "patient-search-results__item-label",
                isMergedRecord && "merged-record",
                isDeceased && "deceased"
            )}
        >
            {title}
        </Label>
    );
};

export default IntegrationPatientLabel;
