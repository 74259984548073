import { FC } from "react";
import { useSelector } from "react-redux";

import { UserRole } from "model/userRole";
import PatientDetailsIntegration from "model/integrations";

import { FIND_NHS_NUMBER_URL } from "navigation/external";

import { getOrganisation, getUser } from "redux/selectors/data";

import userService from "services/userService";

const NHSNumberTip: FC = () => {
    const currentUserRole = userService.getLoggedUserRole();
    const isUnauthenticatedOrPatient = !currentUserRole || currentUserRole === UserRole.PATIENT;
    const additionalText = isUnauthenticatedOrPatient ? " in your appointment information" : "";
    const organisation = useSelector(getOrganisation);
    const user = useSelector(getUser);
    const isPDSOrgAndClinician =
        organisation?.patientDetailsIntegrationType === PatientDetailsIntegration.PDS &&
        user.role === UserRole.CLINICIAN;
    return (
        <p className="form-field-tip">
            {!isPDSOrgAndClinician ? (
                <>
                    If you can&apos;t find the NHS Number{additionalText}, please look it up&nbsp;
                    <a href={FIND_NHS_NUMBER_URL} target="_blank" rel="noreferrer">
                        here
                    </a>
                    . If you&apos;re having trouble, please enter 0000000000 (ten zeros).
                </>
            ) : null}
        </p>
    );
};

export default NHSNumberTip;
