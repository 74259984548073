import { connect } from "react-redux";

import PatientDetails from "components/PatientDetails/PatientDetails";

import * as patientService from "services/patientService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    case: state.assessment.case,
    organisation: state.data.organisation,
    pendingRequest: state.data.pendingRequest,
    user: state.data.user,
});

const mapDispatchToProps = {
    createPatient: patientService.createPatient,
    updatePatient: patientService.updatePatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
