import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";
import { HttpConnector } from "dao/http/httpConnector";

import { IAuditConfigurationRequestDto } from "model/auditConfig";
import CaseQuestionDefinitionType from "model/caseQuestionDefinitionType";
import { IDictionaryItem } from "model/dictionaryItem";
import { INewOrganisation } from "model/newOrganisation";
import {
    IAllocation,
    IAutoAllocation,
    IConsentData,
    IDefinedPersonalData,
    INewDefinedPersonalData,
    IOrganisation,
    IOverdueUpdate,
    ITemplate,
} from "model/organisation";
import { FILTER_ALL } from "model/filters";
import { IOrganisationApiResponse, IPersonalDataCoreFields } from "model/request";
import { UserRole } from "model/userRole";
import PatientDetailsIntegration from "model/integrations";

import { Dispatch } from "redux/store";
import * as actions from "redux/actions";

import userService from "services/userService";
import URLs from "services/urls";

const {
    USER_ME_URL,
    UNAUTH_USER_GET_ORG_URL,
    GET_FIRST_500_ORGANISATIONS_DICT,
    GET_ALL_ORGANISATIONS_URL,
    CREATE_ORGANISATION_URL,
    CREATE_SUBORGANISAION_URL,
    MODIFY_ORGANISATION_URL,
    UPDATE_ORGANISATION_CONSENT_URL,
    GET_ORGANISATION_ADMIN_URL,
    GET_ORGANISATION_QUESTIONS,
    GET_ORGANISATION_FLOW,
    GET_ORGANISATION_WORKING_DAYS,
    UPDATE_ORGANISATION_WORKING_DAYS,
    SET_DERM_USER_URL,
    CREATE_NEW_LOCATION,
    DELETE_LOCATION,
    UPDATE_PERSONAL_DATA,
    DELETE_PERSONAL_DATA,
    CREATE_PERSONAL_DATA,
    GET_ALL_SUBORGANISATIONS_URL,
    CREATE_ALLOCATION_CONFIG,
    UPDATE_ALLOCATION_CONFIG,
    DELETE_TEMPLATE,
    CREATE_TEMPLATE,
    UPDATE_TEMPLATE,
    GET_ORGANISATION_MANAGEMENT_OUTCOMES,
    GET_ORGANISATION_BY_UUID_MANAGEMENT_OUTCOMES,
    GET_ORGANISATION_BY_UUID_DIAGNOSES,
    GET_ORGANISATION_BY_UUID_ESCALATIONS,
    GET_ORGANISATION_BY_UUID_PATHWAYCONSENT,
    COPY_PARENT_ORGANISATION_SETTINGS,
    COPY_PARENT_ORGANISATION_QUESTIONS,
    COPY_PARENT_ORGANISATION_EXCLUSIONS,
    COPY_PARENT_ORGANISATION_DIAGNOSES,
    COPY_PARENT_ORGANISATION_TEMPLATES,
    COPY_PARENT_ORGANISATION_ALLOCATIONS,
    COPY_PARENT_ORGANISATION_LOCATIONS,
    COPY_PARENT_ORGANISATION_NOTIFICATIONS,
    REMOVE_ORGANISATION_NOTIFICATIONS,
    UPLOAD_ORGANISATION_LOGO,
    GET_ORGANISATION_LOGO,
    REMOVE_ORGANISATION_LOGO,
    UPDATE_ORGANISATION_OVERDUE_SETTING,
    UPDATE_AUTOALLOCATION,
    GET_ORGANISATION_UUID_BY_CODENAME,
    GET_ORGANISATION_LOGO_PUBLIC,
    GET_ORGANISATION_REGISTRATION_TEXTS_PUBLIC,
    GET_PERSONAL_DATA_CORE_FIELDS,
    CREATE_AUDIT_CONFIG,
    UPDATE_AUDIT_CONFIG,
    UPDATE_ORGANISATION_PATIENT_DETAILS_INTEGRATION,
} = URLs;

async function getOrganisation(dispatch: Dispatch, orgUuid?: string) {
    try {
        dispatch(actions.dataActions.getMyOrganisationRequest());
        const response = await AuthorizedHttpConnector.sendGet(
            orgUuid ? UNAUTH_USER_GET_ORG_URL.replace("{{UUID}}", orgUuid) : USER_ME_URL
        );
        dispatch(actions.dataActions.getMyOrganisationSuccess(response.data.data));
    } catch (err: any) {
        dispatch(actions.dataActions.getMyOrganisationFail(err.response.data.errors));
    }
}

async function getAllOrganisationsDictionary(): Promise<IDictionaryItem[]> {
    const allOrganisations: IDictionaryItem[] = [];

    const getOrganisationsDictionary = async (firstBatchLink: string): Promise<void> => {
        if (firstBatchLink) {
            const batch = await AuthorizedHttpConnector.sendGet(firstBatchLink);
            allOrganisations.push(...batch.data.data);

            if (batch.data._links?.next) {
                await getOrganisationsDictionary(`${process.env.REACT_APP_READER_MS}${batch.data._links.next}`);
            }
        }
    };

    await getOrganisationsDictionary(GET_FIRST_500_ORGANISATIONS_DICT);

    const finalResult = allOrganisations.map((organisation: any) => ({
        key: organisation.uuid,
        text: organisation.name,
        value: organisation.uuid,
    }));

    const allOrg = {
        key: FILTER_ALL,
        text: "All",
        value: FILTER_ALL,
    };

    return [allOrg, ...finalResult];
}

async function getAllOrganisations(offset: number, filters?: string[], nameSearch?: string) {
    const urlGetAllUsers =
        GET_ALL_ORGANISATIONS_URL.replace("{{OFFSET}}", String(offset)) +
        (filters && filters.length > 0 ? `&filter=${filters.toString()}` : "") +
        (nameSearch ? `&search=name:${nameSearch}` : "");
    const result = await AuthorizedHttpConnector.sendGet(urlGetAllUsers);
    return result.data;
}

async function getAllSuborganisations(parentOrganisationUuid: string, offset: number, nameSearch?: string) {
    const urlGetAllUsers =
        GET_ALL_SUBORGANISATIONS_URL.replace("{{OFFSET}}", String(offset)).replace(
            "{{PARENTUUID}}",
            parentOrganisationUuid
        ) + (nameSearch ? `&search=name:${nameSearch}` : " ");
    const result = await AuthorizedHttpConnector.sendGet(urlGetAllUsers);
    return result.data;
}

async function createNewOrganisation(newOrganisationDTO: INewOrganisation) {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_ORGANISATION_URL, newOrganisationDTO);

    return { status: response.status, uuid: response.data.data.uuid };
}

async function createNewSuborganisation(organisationUuid: string, newOrganisationDTO: INewOrganisation) {
    const response = await AuthorizedHttpConnector.sendPost(
        CREATE_SUBORGANISAION_URL.replace("{{UUID}}", organisationUuid),
        newOrganisationDTO
    );

    return { status: response.status, uuid: response.data.data.uuid };
}

async function modifyOrganisation(organisationUuid: string, modifyData: any) {
    const response = await AuthorizedHttpConnector.sendPut(
        MODIFY_ORGANISATION_URL.replace("{{UUID}}", organisationUuid),
        modifyData
    );
    return {
        status: response.status,
        uuid: response.data.data.uuid,
        organisation: response.data.data,
    };
}

async function updateOrganisationPatientDetailsIntegration(
    organisationUuid: string,
    integrationName?: PatientDetailsIntegration
): Promise<IDefinedPersonalData[]> {
    const response = await AuthorizedHttpConnector.sendPut(
        UPDATE_ORGANISATION_PATIENT_DETAILS_INTEGRATION.replace("{{UUID}}", organisationUuid),
        {
            name: integrationName || null,
        }
    );

    return response.data.data;
}

async function updateOrganisationConsent(
    organisationUuid: string,
    modifyData: IConsentData
): Promise<IOrganisationApiResponse> {
    const response = await AuthorizedHttpConnector.sendPost(
        UPDATE_ORGANISATION_CONSENT_URL.replace("{{UUID}}", organisationUuid),
        modifyData
    );
    return {
        status: response.status,
        uuid: response.data.data.uuid,
        organisation: response.data.data,
    };
}

async function getOrganisationByUuid(uuid: string): Promise<IOrganisation> {
    const getOrganisationUrl = userService.checkUserHasRole([UserRole.ADMIN])
        ? USER_ME_URL
        : GET_ORGANISATION_ADMIN_URL.replace("{{UUID}}", uuid);

    const response = await AuthorizedHttpConnector.sendGet(getOrganisationUrl);

    return response.data.data;
}

async function getOrganisationPathway(uuid: string) {
    const getOrganisationUrl = GET_ORGANISATION_BY_UUID_PATHWAYCONSENT.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendGet(getOrganisationUrl);
    return response.data.data;
}

async function getOrganisationQuestionsByUuid(organisationUuid: string, questionType: CaseQuestionDefinitionType) {
    const getOrganisationQuestionsUrl = GET_ORGANISATION_QUESTIONS.replace("{{UUID}}", organisationUuid).replace(
        "{{TYPE}}",
        questionType
    );
    const response = await AuthorizedHttpConnector.sendGet(getOrganisationQuestionsUrl);
    return response.data.data;
}

async function getOrganisationFlowType(organisationUuid: string) {
    const getOrganisationFlowTypeUrl = GET_ORGANISATION_FLOW.replace("{{UUID}}", organisationUuid);
    const response = await AuthorizedHttpConnector.sendGet(getOrganisationFlowTypeUrl);

    return response.data.data;
}

async function setDermUser(organisationUuid: string, dermLogin: string, dermPassword: string, dermHost: string) {
    const response = await AuthorizedHttpConnector.sendPost(SET_DERM_USER_URL, {
        host: dermHost,
        organisationUuid,
        password: dermPassword,
        user: dermLogin,
    });
    return response.data.data;
}

async function createNewLocation(organisationUuid: string, name: string, locationOrder: number, description: string) {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_NEW_LOCATION.replace("{{UUID}}", organisationUuid), {
        description,
        locationOrder: Number(locationOrder),
        name,
    });
    return response.data.data;
}

async function deleteLocation(locationUuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(DELETE_LOCATION.replace("{{UUID}}", locationUuid));
    return { status: response.status };
}

async function createPersonalData(organisationUuid: string, personalData: INewDefinedPersonalData) {
    const response = await AuthorizedHttpConnector.sendPost(
        CREATE_PERSONAL_DATA.replace("{{UUID}}", organisationUuid),
        personalData
    );
    return response.data.data;
}

async function updatePersonalData(personalDataUuid: string, personalData: IDefinedPersonalData) {
    try {
        const response = await AuthorizedHttpConnector.sendPut(
            UPDATE_PERSONAL_DATA.replace("{{UUID}}", personalDataUuid),
            personalData
        );
        return response.data.data;
    } catch (err: any) {
        throw new Error(err);
    }
}

async function removePersonalData(personalDataUuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(
        DELETE_PERSONAL_DATA.replace("{{UUID}}", personalDataUuid)
    );
    return { status: response.status };
}

async function createTemplate(template: ITemplate, organisationUuid: string) {
    const response = await AuthorizedHttpConnector.sendPost(
        CREATE_TEMPLATE.replace("{{UUID}}", organisationUuid),
        template
    );
    return response.data.data;
}

async function updateTemplate(template: ITemplate, templateUUid: string): Promise<ITemplate> {
    const response = await AuthorizedHttpConnector.sendPut(UPDATE_TEMPLATE.replace("{{UUID}}", templateUUid), template);
    return response.data.data;
}

/**
 * Allocation configuration
 */

async function createAllocationConfig(organisationUuid: string, allocationConfig: IAllocation[]) {
    const response = await AuthorizedHttpConnector.sendPost(
        CREATE_ALLOCATION_CONFIG.replace("{{UUID}}", organisationUuid),
        allocationConfig
    );
    return response.data.data;
}

async function updateAllocationConfig(organisationUuid: string, allocationConfig: IAllocation[]) {
    const response = await AuthorizedHttpConnector.sendPut(
        UPDATE_ALLOCATION_CONFIG.replace("{{UUID}}", organisationUuid),
        allocationConfig
    );
    return response.data.data;
}

async function updateAutoAllocationConfig(updatedAutoAllocation: IAutoAllocation) {
    const response = await AuthorizedHttpConnector.sendPut(UPDATE_AUTOALLOCATION, updatedAutoAllocation);
    return response.data.data;
}

/**
 * Audit configuration
 */

async function createAuditConfiguration(organisationUuid: string, configuration: IAuditConfigurationRequestDto) {
    const response = await AuthorizedHttpConnector.sendPost(
        CREATE_AUDIT_CONFIG.replace("{{UUID}}", organisationUuid),
        configuration
    );
    return response.data.data;
}

async function updateAuditConfiguration(organisationUuid: string, configuration: IAuditConfigurationRequestDto) {
    const response = await AuthorizedHttpConnector.sendPut(
        UPDATE_AUDIT_CONFIG.replace("{{UUID}}", organisationUuid),
        configuration
    );
    return response.data.data;
}

async function deleteTemplate(templateUuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(DELETE_TEMPLATE.replace("{{UUID}}", templateUuid));
    return { status: response.status };
}

async function getManagementOutcomes() {
    const response = await AuthorizedHttpConnector.sendGet(GET_ORGANISATION_MANAGEMENT_OUTCOMES);
    return response.data.data;
}

async function getOrganisationByUuidManagementOptions(uuid: string) {
    const getOrganisationUrl = GET_ORGANISATION_BY_UUID_MANAGEMENT_OUTCOMES.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendGet(getOrganisationUrl);
    return response.data.data;
}

async function getOrganisationByUuidDiagnoses(uuid: string) {
    const getOrganisationUrl = GET_ORGANISATION_BY_UUID_DIAGNOSES.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendGet(getOrganisationUrl);
    return response.data.data;
}

async function getOrganisationByUuidEscalations(uuid: string) {
    const getOrganisationUrl = GET_ORGANISATION_BY_UUID_ESCALATIONS.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendGet(getOrganisationUrl);
    return response.data.data;
}

async function copyOrganisationSettings(uuid: string) {
    const copyOrganisationSettingsUrl = COPY_PARENT_ORGANISATION_SETTINGS.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendPost(copyOrganisationSettingsUrl, {});

    return response.data.data;
}

async function copyOrganisationQuestions(uuid: string) {
    const copyOrganisationSettingsUrl = COPY_PARENT_ORGANISATION_QUESTIONS.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendPost(copyOrganisationSettingsUrl, {});

    return response.data.data;
}

async function copyOrganisationExclusions(uuid: string) {
    const copyOrganisationSettingsUrl = COPY_PARENT_ORGANISATION_EXCLUSIONS.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendPost(copyOrganisationSettingsUrl, {});

    return response.data.data;
}

async function copyOrganisationDiagnoses(uuid: string) {
    const response = await AuthorizedHttpConnector.sendPost(COPY_PARENT_ORGANISATION_DIAGNOSES, {
        organisationUuid: uuid,
    });

    return response.data.data;
}

async function copyOrganisationTemplates(uuid: string) {
    const copyOrganisationSettingsUrl = COPY_PARENT_ORGANISATION_TEMPLATES.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendPost(copyOrganisationSettingsUrl, {});

    return response.data.data;
}

async function copyOrganisationAllocations(uuid: string) {
    const copyOrganisationSettingsUrl = COPY_PARENT_ORGANISATION_ALLOCATIONS.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendPost(copyOrganisationSettingsUrl, {});

    return response.data.data;
}

async function copyOrganisationLocations(uuid: string) {
    const copyOrganisationSettingsUrl = COPY_PARENT_ORGANISATION_LOCATIONS.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendPost(copyOrganisationSettingsUrl, {});

    return response.data.data;
}

async function copyOrganisationNotifications(uuid: string) {
    const response = await AuthorizedHttpConnector.sendPost(COPY_PARENT_ORGANISATION_NOTIFICATIONS, {
        organisationUuid: uuid,
    });

    return response.data.data;
}

async function removeOrganisationNotifications(uuid: string) {
    const response = await AuthorizedHttpConnector.sendPost(REMOVE_ORGANISATION_NOTIFICATIONS, {
        organisationUuid: uuid,
    });

    return response.data.data;
}

/**
 * Organisation logo logic
 */

/**
 * Download functionality
 */

async function getOrganisationLogo(organisationUuid: string): Promise<string> {
    const response = await AuthorizedHttpConnector.sendGet(GET_ORGANISATION_LOGO.replace("{{UUID}}", organisationUuid));
    return response.data.data.presignedUrl;
}

async function getOrganisationLogoByUuidPublic(uuid: string): Promise<string> {
    try {
        const response = await HttpConnector.sendGet(GET_ORGANISATION_LOGO_PUBLIC.replace("{{UUID}}", uuid));
        return response.data.data.presignedUrl;
    } catch (err: any) {
        console.error(err);
        return err;
    }
}

/**
 * Upload functionality
 */

interface IOrganisationResponseData {
    uuid: string;
}

interface IUploadOrganisationResponse {
    correlationId: string;
    data: IOrganisationResponseData;
}

async function uploadOrganisationLogo(data: any): Promise<IUploadOrganisationResponse> {
    const response = await AuthorizedHttpConnector.sendPost(UPLOAD_ORGANISATION_LOGO, data);
    return response.data;
}

async function removeOrganisationLogo(organisationUuid: any) {
    const response = await AuthorizedHttpConnector.sendDelete(
        REMOVE_ORGANISATION_LOGO.replace("{{UUID}}", organisationUuid)
    );

    return response.data;
}

async function updateOverdueSetting(overdueUuid: string, data: IOverdueUpdate) {
    const response = await AuthorizedHttpConnector.sendPut(
        UPDATE_ORGANISATION_OVERDUE_SETTING.replace("{{UUID}}", overdueUuid),
        data
    );

    return response.data;
}

async function getOrganisationWorkingDays(uuid: string) {
    const getWorkingDays = GET_ORGANISATION_WORKING_DAYS.replace("{{UUID}}", uuid);
    const response = await AuthorizedHttpConnector.sendGet(getWorkingDays);
    return response.data.data;
}

async function updateOrganisationWorkingDays(organisationUuid: string, workingDayList: string[]) {
    const response = await AuthorizedHttpConnector.sendPut(UPDATE_ORGANISATION_WORKING_DAYS, {
        workingDayList,
        organisationUuid,
    });
    return response.data.data;
}

async function getOrganisationUuidByCodenamePublic(codeName: string) {
    const response = await HttpConnector.sendGet(GET_ORGANISATION_UUID_BY_CODENAME.replace("{{CODE_NAME}}", codeName));
    return response.data;
}

async function getOrganisationRegistrationTextsPublic(uuid: string) {
    try {
        const response = await HttpConnector.sendGet(
            GET_ORGANISATION_REGISTRATION_TEXTS_PUBLIC.replace("{{UUID}}", uuid)
        );
        return response.data;
    } catch (err) {
        console.error(err);
        return err;
    }
}

async function getPersonalDataCoreFields(
    patientDetailsIntegrationType?: PatientDetailsIntegration
): Promise<IPersonalDataCoreFields> {
    const requestUrl = !patientDetailsIntegrationType
        ? GET_PERSONAL_DATA_CORE_FIELDS.split("?")[0]
        : GET_PERSONAL_DATA_CORE_FIELDS.replace("{{INTEGRATION_TYPE}}", patientDetailsIntegrationType?.toString());
    try {
        const response = await AuthorizedHttpConnector.sendGet(requestUrl);

        return {
            status: response.status,
            coreFields: response.data.data,
        };
    } catch (err) {
        console.error(err);
        throw new Error((err as Error).message);
    }
}

export default {
    copyOrganisationAllocations,
    copyOrganisationDiagnoses,
    copyOrganisationExclusions,
    copyOrganisationLocations,
    copyOrganisationNotifications,
    copyOrganisationQuestions,
    copyOrganisationSettings,
    copyOrganisationTemplates,
    createAllocationConfig,
    createAuditConfiguration,
    createNewLocation,
    createNewOrganisation,
    createNewSuborganisation,
    createPersonalData,
    createTemplate,
    deleteLocation,
    deleteTemplate,
    getAllOrganisations,
    getAllOrganisationsDictionary,
    getAllSuborganisations,
    getOrganisation,
    getManagementOutcomes,
    getOrganisationByUuid,
    getOrganisationByUuidDiagnoses,
    getOrganisationByUuidManagementOptions,
    getOrganisationLogo,
    getOrganisationPathway,
    getOrganisationQuestionsByUuid,
    getOrganisationFlowType,
    modifyOrganisation,
    updateOrganisationConsent,
    removePersonalData,
    setDermUser,
    updateAuditConfiguration,
    updateAutoAllocationConfig,
    updatePersonalData,
    uploadOrganisationLogo,
    removeOrganisationLogo,
    updateOverdueSetting,
    getOrganisationWorkingDays,
    updateOrganisationWorkingDays,
    removeOrganisationNotifications,
    getOrganisationUuidByCodenamePublic,
    getOrganisationLogoByUuidPublic,
    getOrganisationRegistrationTextsPublic,
    getOrganisationByUuidEscalations,
    getPersonalDataCoreFields,
    updateAllocationConfig,
    updateTemplate,
    updateOrganisationPatientDetailsIntegration,
};
