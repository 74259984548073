import { FC, useContext } from "react";

import CustomButton from "components/templates/Button";

import { ModalContext } from "contextProviders/ModalProvider";

import { PATIENT_DETAILS } from "navigation/routes";

const ManualCaseCreationModal: FC = () => {
    const { closeModal } = useContext(ModalContext);

    return (
        <div className="manual-case-creation-modal__wrapper">
            <p>Are you sure you want to create a new case manually?</p>
            <div className="manual-case-creation-modal__buttons">
                <CustomButton variant="empty" type="button" action={closeModal} text="No" size="small" />
                <CustomButton variant="filled" type="link" to={PATIENT_DETAILS} text="Yes" size="small" />
            </div>
        </div>
    );
};

export default ManualCaseCreationModal;
