import { FC } from "react";
import { DialogContent } from "@material-ui/core";

import ModalDialog from "components/templates/dialog/ModalDialog";

interface IConfirmDeleteOutcomeDialogProps {
    showModal: boolean;
    handleClose: () => void;
    handleContinue: () => void;
}

const ConfirmDeleteOutcomeDialog: FC<IConfirmDeleteOutcomeDialogProps> = ({
    showModal,
    handleContinue,
    handleClose,
}) => (
    <ModalDialog
        title="This Management Outcome is in use for a diagnosis in this organisation"
        open={showModal}
        onClose={handleClose}
        iconSrc="/images/icons/icon-alert.svg"
        disableBackdropClick
        className="warning-modal"
        maxWidth="sm"
        buttons={[
            {
                onClick: handleContinue,
                text: "Yes, continue",
                colour: "primary",
            },
            {
                onClick: handleClose,
                text: "Cancel",
                variant: "outlined",
                colour: "secondary",
            },
        ]}
    >
        <DialogContent>
            <p>
                This management outcome is currently being used for one or more diagnoses. Deleting it now will remove
                it everywhere for this organisation.
            </p>
            <p>Do you want to continue?</p>
        </DialogContent>
    </ModalDialog>
);

export default ConfirmDeleteOutcomeDialog;
