import { useContext } from "react";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { SORT_COLUMNS } from "model/caseList";

interface IUseColumnSort {
    handleSortColumnClicked: (columnName: string) => void;
}

const useColumnSort = (): IUseColumnSort => {
    const { sortList } = useContext(CaseListContext);

    const handleSortColumnClicked = (columnName: string): void => {
        if (SORT_COLUMNS.includes(columnName)) {
            sortList(columnName);
        }
    };

    return {
        handleSortColumnClicked,
    };
};

export default useColumnSort;
