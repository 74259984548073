import { RemoteModelStatuses } from "model/assessment";

export enum ReportAvailableActions {
    SET_CASE_CLOSED = "SET_CASE_CLOSED",
    START_CLOSE_CASE_REQUEST = "START_CLOSE_CASE_REQUEST",
    SET_CASE_CLOSED_ERROR = "SET_CASE_CLOSED_ERROR",
    SET_CASE_STATUS = "SET_CASE_STATUS",
    SET_DOWNLOAD_REPORT_ERROR_VISIBLE = "SET_DOWNLOAD_REPORT_ERROR_VISIBLE",
    SET_AWAITING_CALLBACK = "SET_AWAITING_CALLBACK",
}

interface ISetCaseStatusPayload {
    caseStatus: RemoteModelStatuses;
    isSaveReportButtonAvailable: boolean;
    isAwaitingCallback: boolean;
}

export interface ISetCaseClosed {
    type: ReportAvailableActions.SET_CASE_CLOSED;
}

export interface IStartCloseCaseRequest {
    type: ReportAvailableActions.START_CLOSE_CASE_REQUEST;
}

export interface ISetCaseClosedError {
    type: ReportAvailableActions.SET_CASE_CLOSED_ERROR;
    payload: string;
}

export interface ISetCaseStatus {
    type: ReportAvailableActions.SET_CASE_STATUS;
    payload: ISetCaseStatusPayload;
}

export interface ISetDownloadReportErrorVisible {
    type: ReportAvailableActions.SET_DOWNLOAD_REPORT_ERROR_VISIBLE;
}

export interface ISetAwaitingCallback {
    type: ReportAvailableActions.SET_AWAITING_CALLBACK;
}

export type TReportAvailableActions =
    | ISetCaseClosed
    | IStartCloseCaseRequest
    | ISetCaseClosedError
    | ISetCaseStatus
    | ISetDownloadReportErrorVisible
    | ISetAwaitingCallback;
