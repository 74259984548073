import React, { FC, useContext, MouseEvent as ReactMouseEvent } from "react";
import { Header, Label, Loader, Menu, MenuItemProps } from "semantic-ui-react";

import { CaseListContext, ICaseListContext, IConfiguration, TAB_STATE } from "contextProviders/CaseListProvider";

import { CaseListTabs as CaseListTabsType, ICaseLists } from "model/caseList";

interface ICaseTabs {
    readonly activeTab: string;
    readonly config: IConfiguration;
    readonly caseLists: ICaseLists;

    setActiveTab: (value: string) => void;
}

const CaseTabs: FC<ICaseTabs> = ({ config, activeTab, caseLists, setActiveTab }) => {
    const handleMenuItemClick = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
        const { name = "" } = data;
        setActiveTab(name);
    };

    const handleMenuItemKeyDown = (event: KeyboardEvent, tab: string): void => {
        if (event.key === "Enter") {
            setActiveTab(tab);
        }
    };

    const tabs = config.tabs.map((tab) => {
        const isActive = activeTab === tab;
        const labelColor = isActive ? "red" : "grey";
        const showLabel = tab !== CaseListTabsType.ALL;
        const stateItem = TAB_STATE[tab];
        const { total = 0, isPending = false } = caseLists[stateItem];

        return (
            <Menu.Item
                tabIndex={0}
                key={tab}
                active={isActive}
                onKeyDown={(e) => handleMenuItemKeyDown(e, tab)}
                onClick={handleMenuItemClick}
                name={tab}
                fitted="vertically"
            >
                <Header as="h4">
                    {tab}
                    {showLabel &&
                        (isPending ? (
                            <Loader active inline size="small" className="case-list-loader" />
                        ) : (
                            <Label circular color={labelColor}>
                                {total}
                            </Label>
                        ))}
                </Header>
            </Menu.Item>
        );
    });

    return <>{tabs}</>;
};

const CaseListTabs: FC = () => {
    const { activeTab, setActiveTab, caseLists, config } = useContext<ICaseListContext>(CaseListContext);

    return (
        <Menu pointing secondary className="case-list-tabs">
            <CaseTabs activeTab={activeTab} caseLists={caseLists} config={config} setActiveTab={setActiveTab} />
        </Menu>
    );
};

export default CaseListTabs;
