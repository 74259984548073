import types from "redux/actions/types";

const INITIAL_STATE = {
    error: false,
    load: false,
    state: "",
    success: false,
    visible: false,
    warning: false,
};

const validateReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case types.ERROR_GENERAL:
            return {
                ...state,
                error: true,
                load: action.payload,
                state: "ERROR",
                visible: true,
            };
        case types.SUCCESS_GENERAL:
            return {
                ...state,
                error: false,
                load: action.payload,
                state: "SUCCESS",
                visibile: true,
            };
        case types.WARNING_GENERAL:
            return {
                ...state,
                load: action.payload,
                state: "WARNING",
            };
        case types.CLEAN_VALIDATION_ERROR:
            return {
                ...state,
                error: false,
                load: false,
                state: "",
                success: false,
                visible: false,
                warning: false,
            };
        case types.CLEAN_ERRORS:
        case types.CLEAR_DATA:
            return {
                ...state,
                error: false,
                load: false,
                state: "",
                success: false,
                visible: false,
                warning: false,
            };
        default:
            return state;
    }
};

export default validateReducer;
