import React, { useState, ReactElement } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import CheckIcon from "@material-ui/icons/Check";
import { Button, IconButton, TextField, Tooltip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { IPatientLookupMatched, ITableRow, PatientLookupRender } from "model/patientLookup";
import { IDefinedPersonalData } from "model/organisation";

import PatientLookupFormControl from "components/PatientLookup/PatientLookupFormControl";
import { getPatient } from "redux/selectors/assessment";

interface ISavedPatientDetails {
    matchedPatient: IPatientLookupMatched;
    goBack: () => void;
    next: (patientDetails: IPatientLookupMatched | undefined) => void;
    additionalRequiredPatientDetails: IDefinedPersonalData[];
    updatePatient: (patientUuid, patientDetails) => void;
    setMatchedPatient: (patient: IPatientLookupMatched) => void;
    setPatientLookupRender: (state: PatientLookupRender) => void;
}

export default function SavedPatientDetails({
    matchedPatient,
    goBack,
    next,
    additionalRequiredPatientDetails,
    updatePatient,
    setMatchedPatient,
    setPatientLookupRender,
}: ISavedPatientDetails) {
    const [editAdditionalDetails, setEditAdditionalDetails] = useState<IPatientLookupMatched>(null);
    const patient = useSelector(getPatient);

    const { name, surname, birth, nhs, ...rest } = matchedPatient;

    const TableRow = ({ label, value }: ITableRow): ReactElement => (
        <tr>
            <td>{label}</td>
            <td>{value}</td>
        </tr>
    );

    const onAdditionalDetailsChange = (value: string, field: string): void => {
        setEditAdditionalDetails({ ...editAdditionalDetails, [field]: value });
    };

    const updatePatientDetails = (): void => {
        const newPatientDetailsToSave = { ...editAdditionalDetails, integrationInfo: { integrationName: "PDS" } };
        newPatientDetailsToSave.birth = moment(newPatientDetailsToSave.birth).format("YYYY-MM-DD");
        updatePatient(patient.uuid, newPatientDetailsToSave);
        setMatchedPatient(editAdditionalDetails);
        setEditAdditionalDetails(null);
    };

    return (
        <div className="patient-match">
            <div className="icon">
                <PersonIcon fontSize="large" />
            </div>
            <h3>Saved patient details</h3>
            <div className="table-container">
                <div className="table-title">
                    NHS verified details{" "}
                    <Button endIcon={<SearchIcon />} onClick={() => setPatientLookupRender(PatientLookupRender.FORM)}>
                        Search again
                    </Button>
                </div>
                <table className="patient-table saved">
                    <tbody>
                        <TableRow label="First name" value={name} />
                        <TableRow label="Surname" value={surname} />
                        <TableRow label="Date of birth" value={moment(birth).format("DD/MM/YYYY")} />
                        <TableRow label="NHS number" value={nhs} />
                    </tbody>
                </table>
                {!!additionalRequiredPatientDetails.length && (
                    <>
                        <div className="table-title">
                            Other patient details{" "}
                            {!editAdditionalDetails ? (
                                <Button endIcon={<EditIcon />} onClick={() => setEditAdditionalDetails(matchedPatient)}>
                                    Edit
                                </Button>
                            ) : (
                                <div>
                                    <Tooltip title="Discard changes" placement="top-start">
                                        <IconButton onClick={() => setEditAdditionalDetails(null)}>
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Save changes" placement="top-start">
                                        <IconButton onClick={updatePatientDetails}>
                                            <CheckIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        <table className="patient-table saved">
                            <tbody>
                                {additionalRequiredPatientDetails.map((patientDetail) => (
                                    <tr key={patientDetail.uuid}>
                                        <td>{patientDetail.displayName}</td>
                                        <td>
                                            {editAdditionalDetails ? (
                                                <TextField
                                                    variant="outlined"
                                                    value={editAdditionalDetails[patientDetail.propertyName]}
                                                    onChange={(e) =>
                                                        onAdditionalDetailsChange(
                                                            e.target.value,
                                                            patientDetail.propertyName
                                                        )
                                                    }
                                                />
                                            ) : (
                                                rest[patientDetail.propertyName] || "Not provided"
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            <PatientLookupFormControl
                button1Name="Back"
                button1OnClick={goBack}
                button2Name="Continue"
                button2OnClick={() => next(matchedPatient)}
                disabled={!!editAdditionalDetails}
            />
        </div>
    );
}
