import { FC } from "react";
import { Table } from "semantic-ui-react";

import PatientBannerHeader from "components/templates/PatientBannerHeader";
import PatientBannerTableCell from "components/templates/PatientBanner/PatientBannerTableCell";

import {
    getIsDeceasedPatient,
    getIsMergedRecordField,
    getNameSurname,
    getPatientPropertyByName,
} from "helpers/definedPersonalData";

import { IPatient, PatientFromIntegrationFields } from "model/assessment";

interface IIntegrationPatientInformationTable {
    caseId: string;
    patient: IPatient;
}

const IntegrationPatientInformationTable: FC<IIntegrationPatientInformationTable> = ({ caseId, patient }) => {
    const { patientData, integrationInfo } = { ...patient };
    const patientName = getNameSurname(patientData, false, true);
    const hospitalNumber = getPatientPropertyByName(patientData, PatientFromIntegrationFields.HOSPITAL_NUMBER);
    const nhsNumber = getPatientPropertyByName(patientData, PatientFromIntegrationFields.NHS_NUMBER);
    const dateOfDeath = getPatientPropertyByName(patientData, PatientFromIntegrationFields.DATE_OF_DEATH) as string;
    const isDeceased = getIsDeceasedPatient(patientData);
    const dateOfBirth = getPatientPropertyByName(patientData, PatientFromIntegrationFields.BIRTH, dateOfDeath);
    const gender = getPatientPropertyByName(patientData, PatientFromIntegrationFields.GENDER);
    const ethnicGroup = getPatientPropertyByName(patientData, PatientFromIntegrationFields.ETHNIC_GROUP);
    const occupation = getPatientPropertyByName(patientData, PatientFromIntegrationFields.OCCUPATION);
    const isMergedRecord = getIsMergedRecordField(patientData);

    return (
        <>
            <PatientBannerHeader
                integrationData={integrationInfo}
                isMergedRecord={isMergedRecord}
                isDeceased={isDeceased}
            />
            <Table className="patient-banner__table">
                <Table.Body>
                    <Table.Row>
                        <PatientBannerTableCell isMainCell title="Case" value={caseId} width="2" />
                        <PatientBannerTableCell title="Patient" value={patientName} width="4" />
                        <PatientBannerTableCell title="Hospital Number" value={hospitalNumber} width="3" />
                        <PatientBannerTableCell title="NHS Number" value={nhsNumber} width="3" />
                        <PatientBannerTableCell title="Date of birth" value={dateOfBirth} width="4" />
                    </Table.Row>
                    <Table.Row>
                        <PatientBannerTableCell isMainCell width="2" />
                        <PatientBannerTableCell title="Gender" value={gender} width="4" />
                        <PatientBannerTableCell title="Ethnic group" value={ethnicGroup} width="3" />
                        <PatientBannerTableCell title="Occupation" value={occupation} width="3" />
                        {dateOfDeath && <PatientBannerTableCell title="Date of death" value={dateOfDeath} width="4" />}
                    </Table.Row>
                </Table.Body>
            </Table>
        </>
    );
};

export default IntegrationPatientInformationTable;
