import React from "react";
import { Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import "scss/Dialog.scss";

interface ICloseCaseDialog {
    showDialog: boolean;
    callback: (isOpenPopup: boolean) => void;
    onClose: () => void;
    onCloseCase: () => void;
}

const PromtCaseReportDialog = (props: ICloseCaseDialog) => {
    const { showDialog, callback, onClose, onCloseCase } = props;

    const title = "Report downloaded";
    const bodyText =
        "The report has been downloaded to your device. If no further actions are to be taken on this Case in this platform, you can Close the Case.";

    const onConfirm = () => {
        onCloseCase();
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>{bodyText}</Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                callback={callback}
                noButton={{
                    backgroundColor: "#db2828",
                    fontColor: "#FFFFFF",
                    onClick: onClose,
                    text: "Close case later manually",
                }}
                yesButton={{
                    backgroundColor: "#2185D0",
                    fontColor: "#FFFFFF",
                    onClick: onConfirm,
                    text: "Close case now",
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default PromtCaseReportDialog;
