import { IOrganisation } from "model/organisation";
import { IUser } from "model/user";

export const getOrganisation = (state: any): IOrganisation => state.data.organisation;

export const getUser = (state: any): IUser => state.data.user;

export const getPendingRequest = (state: any): boolean => state.data.pendingRequest;

export const getShowOrganisationSelection = (state: any): boolean => state.data.showOrganisationSelection;

export const getDisplayCaseListSnackbar = (state: any): boolean => state.data.displayCaseListSnackbar;
