import { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import AdministrationNavigation from "components/Administration/AdministrationNavigation";
import CaseReportScreen from "components/Administration/CaseReport/CaseReportScreen";
import OrganisationManagmentScreen from "components/Administration/OrganisationManagment/OrganisationManagmentScreen";
import ReportsScreen from "components/Administration/Reports/ReportsScreen";
import StudyManagmentScreen from "components/Administration/StudyManagment/StudyManagmentScreen";
import UserManagmentScreen from "components/Administration/UserManagement/UserManagmentScreen";

import { UserRole } from "model/userRole";
import { AdministrationPages } from "model/administrationPages";

import { HOME } from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";
import userService from "services/userService";

interface IAdministrationProps {
    match: any;
}

class Administration extends Component<any, IAdministrationProps> {
    public componentDidMount() {
        logoutTimeoutService.startCount();
    }

    private getContent(action: AdministrationPages) {
        const { match } = this.props;
        const { params } = match;

        if (action === AdministrationPages.REPORTS) {
            return <ReportsScreen />;
        }

        if (action === AdministrationPages.CASE_REPORT) {
            return <CaseReportScreen />;
        }

        if (action === AdministrationPages.USER_MANAGEMENT) {
            return <UserManagmentScreen params={params} />;
        }

        if (action === AdministrationPages.ORGANISATION_MANAGEMENT) {
            return <OrganisationManagmentScreen params={params} />;
        }

        if (action === AdministrationPages.STUDY_MANAGEMENT) {
            return <StudyManagmentScreen params={params} />;
        }

        return <></>;
    }

    private handleClick = () => {
        logoutTimeoutService.startCount();
    };

    public render() {
        const { match } = this.props;

        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to={HOME} />;
        }

        const selectedPage = match.params.page || AdministrationPages.REPORTS;

        return (
            <Grid className="admin-page__layout" columns={2} divided onClick={this.handleClick}>
                <Grid.Row>
                    <Grid.Column width={2} style={{ minWidth: 230 }}>
                        <AdministrationNavigation activeItem={selectedPage} />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={12}>
                        {this.getContent(selectedPage)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withRouter(Administration);
