import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";

import { parseAdminNextStepsTemplate } from "helpers/administrativeNextSteps";
import {
    generateLesionReviews,
    getInitialLesionReviews,
    getUniqueManagementOutcomeLesionReviews,
    hasFreeText,
} from "helpers/review";

import { IAdminNextStepQuestion } from "model/administrativeNextSteps";
import { ICase } from "model/case";
import { IManagementOptions, IOrganisation, IReviewDiagnosis, IReviewEscalation } from "model/organisation";
import { ICreateReviewResponse, IReviewFormData } from "model/reviewNotes";

import { getOrganisation } from "redux/selectors/data";

import reviewService from "services/reviewService";

interface IUseReviewFormProps {
    currentCase: ICase;
    isCaseManagementOutcome: boolean;
}

interface IUseReviewForm {
    formData: IReviewFormData;
    setFormData: Dispatch<SetStateAction<IReviewFormData>>;
    submitReview: ({
        reviewDiagnoses,
        reviewManagementOptions,
        escalationPlan,
    }: {
        reviewDiagnoses: IReviewDiagnosis[];
        reviewManagementOptions: IManagementOptions[];
        escalationPlan?: IReviewEscalation[];
    }) => Promise<ICreateReviewResponse>;
    validateValues: (
        updatedFormData: IReviewFormData,
        nonSkinCancer?: boolean,
        escalationPlan?: IReviewEscalation[]
    ) => boolean;
    adminNextSteps: IAdminNextStepQuestion[] | undefined;
    setAdminNextSteps: Dispatch<SetStateAction<IAdminNextStepQuestion[]>>;
}

const useReviewForm = ({ currentCase, isCaseManagementOutcome }: IUseReviewFormProps): IUseReviewForm => {
    const organisation: IOrganisation = useSelector(getOrganisation);

    const adminNextStepsTemplate = organisation.templates?.find(
        (template) => template.name === "ADMINISTRATIVE_NEXT_STEPS" && !template.removed
    );
    const adminNextStepsQuestions = parseAdminNextStepsTemplate(adminNextStepsTemplate);

    const [formData, setFormData] = useState<IReviewFormData>({
        lesionReviews: getInitialLesionReviews(isCaseManagementOutcome ? [] : currentCase.lesions),
        caseManagementOutcome: "",
        caseManagementOutcomeFreeText: "",
        showCaseManagementOutcome: isCaseManagementOutcome,
        isValid: false,
    });
    const [adminNextSteps, setAdminNextSteps] = useState<IAdminNextStepQuestion[] | undefined>(adminNextStepsQuestions);

    const validateValues = (
        updatedFormData: IReviewFormData,
        nonSkinCancer?: boolean,
        escalationPlan?: IReviewEscalation[]
    ): boolean => {
        const { reviewDiagnoses, reviewManagementOptions } = organisation;
        const { lesionReviews, showCaseManagementOutcome, caseManagementOutcome, caseManagementOutcomeFreeText } =
            updatedFormData;
        const lesionReviewKeys = Object.keys(lesionReviews);
        const isDiagnosesManagementAndTextsFilled =
            lesionReviewKeys.filter((lesionReviewKey) => {
                const currentReview = lesionReviews[lesionReviewKey];
                const { selectedDiagnoses, selectedManagement, selectedDiagnosesFreeText, selectedManagementFreeText } =
                    currentReview;
                const withFreeTextManagementOutcome = hasFreeText(reviewManagementOptions, selectedManagement);
                const withFreeTextDiagnosis = hasFreeText(reviewDiagnoses, selectedDiagnoses);
                const isSelectedDiagnoses = Boolean(selectedDiagnoses);
                const isSelectedDiagnosisFreeText = Boolean(selectedDiagnosesFreeText);
                const isSelectedManagementFreeText = Boolean(selectedManagementFreeText);
                const isSelectedManagement = Boolean(selectedManagement);

                return (
                    !isSelectedDiagnoses ||
                    !isSelectedManagement ||
                    (withFreeTextManagementOutcome && !isSelectedManagementFreeText) ||
                    (withFreeTextDiagnosis && !isSelectedDiagnosisFreeText)
                );
            }).length === 0;

        const isCaseManagementOutcomeFilled = showCaseManagementOutcome
            ? Boolean(caseManagementOutcome) &&
              !(hasFreeText(reviewManagementOptions, caseManagementOutcome) && !caseManagementOutcomeFreeText)
            : true;

        const isEscalationFilled =
            lesionReviewKeys.filter((lesionReviewKey) => {
                const { selectedEscalation, selectedEscalationFreeText } = lesionReviews[lesionReviewKey];

                const withFreeTextEscalation = hasFreeText(escalationPlan, selectedEscalation);

                return !selectedEscalation || (withFreeTextEscalation && !selectedEscalationFreeText);
            }).length === 0;

        const isEscalationValid = nonSkinCancer ? isEscalationFilled : true;

        return isDiagnosesManagementAndTextsFilled && isCaseManagementOutcomeFilled && isEscalationValid;
    };

    const submitReview = ({
        reviewDiagnoses,
        reviewManagementOptions,
        escalationPlan,
    }: {
        reviewDiagnoses: IReviewDiagnosis[];
        reviewManagementOptions: IManagementOptions[];
        escalationPlan?: IReviewEscalation[];
    }): Promise<ICreateReviewResponse> => {
        const { caseManagementOutcome, caseManagementOutcomeFreeText, lesionReviews, showCaseManagementOutcome } =
            formData;
        const {
            uuid: currentCaseUuid,
            patient: { uuid: patientUuid },
        } = currentCase;
        const lesionReviewsData = generateLesionReviews(lesionReviews, {
            reviewDiagnoses,
            reviewManagementOptions,
            escalationPlan,
        });
        const uniqueManagementOutcomeLesionReviews = getUniqueManagementOutcomeLesionReviews(lesionReviews);
        const { selectedManagement, selectedManagementFreeText } = {
            ...uniqueManagementOutcomeLesionReviews[0],
        };

        const managementOutcomeUuid = showCaseManagementOutcome ? caseManagementOutcome : selectedManagement;
        const managementOutcomeFreeText = hasFreeText(reviewManagementOptions, managementOutcomeUuid);

        const administrativeNextStepsQuestions = adminNextSteps
            ? adminNextSteps.map((step) => {
                  const isQuestionAnswered =
                      (typeof step.answer === "string" && step.answer) || step.answer?.length > 0;
                  if (!isQuestionAnswered) {
                      const updatedStep = { ...step };
                      updatedStep.answer = "Not answered";
                      return updatedStep;
                  }
                  return step;
              })
            : undefined;

        return reviewService.createReview({
            caseUuid: currentCaseUuid,
            patientUuid,
            reviewManagementUuid: managementOutcomeUuid,
            lesionReviews: lesionReviewsData,
            managementAdditionalText: managementOutcomeFreeText
                ? caseManagementOutcomeFreeText || selectedManagementFreeText
                : undefined,
            administrativeNextStepsQuestions,
        });
    };

    return {
        formData,
        setFormData,
        submitReview,
        validateValues,
        adminNextSteps,
        setAdminNextSteps,
    };
};

export default useReviewForm;
