import { FC } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Divider } from "semantic-ui-react";

import LesionImages from "components/templates/LesionImages";
import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import PatientBanner from "components/templates/PatientBanner";

import { getLinkToAssessment } from "helpers/caseList";
import { formatTimeWithHours } from "helpers/datetime";

import { ILesion } from "model/case";
import { AllLesionLocation } from "model/lesionLocation";

import { history } from "App";
import { CASE_DESCRIPTION, LESION_LOCATOR, READY_FOR_ASSESSMENT } from "navigation/routes";

import { getAssessment } from "redux/selectors/assessment";
import { getOrganisation } from "redux/selectors/data";

const UploadedImages: FC = () => {
    const assessment = useSelector(getAssessment);
    const organisation = useSelector(getOrganisation);
    const { case: currentCase } = assessment;

    if (!currentCase) {
        return <Redirect to="/home" />;
    }

    const { lesions } = currentCase;

    const moveBack = () => {
        const {
            isManualUploadRemoteMode,
            case: { uuid },
        } = assessment;

        if (isManualUploadRemoteMode) {
            history.push(`${CASE_DESCRIPTION}/${uuid}`);
        } else {
            const lesionNumber = lesions.length;
            const prevUrl = `${LESION_LOCATOR}/${lesionNumber}`;
            history.push(prevUrl);
        }
    };

    const moveForward = () => {
        history.push(READY_FOR_ASSESSMENT);
    };

    return (
        <Container className="ui segment wizzard-container">
            <PatientBanner caseId={assessment.case.caseId} patient={assessment.patient} />
            <h1>Images Uploaded</h1>
            <Divider />
            {lesions.map((lesion: ILesion, index: number) => {
                if (!lesion.images) {
                    const redirectLink = getLinkToAssessment(assessment, organisation);
                    return <Redirect to={redirectLink} />;
                }
                const { uuid, bodyPart } = lesion;
                const creators = [...new Set(lesion.images.map((image) => image.createdByName))].join(", ");
                const creatorsOrganisations = [
                    ...new Set(lesion.images.map((image) => image.creatorOrganisationName)),
                ].join(", ");
                const lesionImageCaptureDetails = `Captured by ${creators} from ${creatorsOrganisations} on ${formatTimeWithHours(
                    lesion.images[lesion.images.length - 1].creationDate
                )}`;

                return (
                    <div key={uuid}>
                        <h4>
                            Lesion #{index + 1}. Images ({AllLesionLocation[bodyPart]}):
                        </h4>
                        <p className="subtitle-gray-text assessed-by-text">{lesionImageCaptureDetails}</p>
                        <LesionImages lesion={lesion} />
                        <Divider />
                    </div>
                );
            })}
            <ButtonContainer
                button1={<CustomButton variant="empty" type="submit" action={moveBack} text="< Back" />}
                button2={<CustomButton variant="filled" type="submit" action={moveForward} text="Next >" />}
            />
        </Container>
    );
};

export default UploadedImages;
