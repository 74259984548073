import React, { memo } from "react";
import MUIAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import { IPatientLookupForm } from "model/patientLookup";
import PatientDetailsIntegration from "model/integrations";

import { getPatient } from "redux/selectors/assessment";

import "scss/PatientDetails.scss";

const linkToProps = (formFields) => ({
    pathname: "/patient-details",
    state: {
        from: "patient-lookup",
        values: {
            ...formFields,
            birth: formFields.birth ? moment(formFields.birth).format("YYYY-MM-DD") : null,
        },
    },
});

interface IPatientLookupDialogue {
    needsRefinement: boolean;
    PDSFailedMatch: boolean;
    isPatientDeceased: boolean;
    isTooManyAttempts: boolean;
    formFields: IPatientLookupForm;
    isNetworkError: boolean;
    PDSRecordIncomplete: boolean;
    searchAttempts: number;
    continueWithoutVerifying: () => void;
}

export default function PatientLookupDialogue({
    needsRefinement,
    PDSFailedMatch,
    isPatientDeceased,
    isTooManyAttempts,
    formFields,
    isNetworkError,
    PDSRecordIncomplete,
    continueWithoutVerifying,
}: IPatientLookupDialogue) {
    const patient = useSelector(getPatient);
    const allowContinueWithoutVerifying =
        patient?.uuid && patient?.integrationInfo?.integrationName !== PatientDetailsIntegration.PDS;

    const ContinueWithoutVerifyingButton = memo(({ children }) => (
        <button onClick={continueWithoutVerifying} type="button" className="dialogue">
            {children}
        </button>
    ));

    if (isTooManyAttempts) {
        const proceedText = allowContinueWithoutVerifying ? (
            <ContinueWithoutVerifyingButton>continue without verifying patient details</ContinueWithoutVerifyingButton>
        ) : (
            <>
                <Link to={linkToProps(formFields)}>enter patient details manually</Link>
            </>
        );
        return (
            <MUIAlert severity="error">
                <p>
                    {PDSFailedMatch ? (
                        <b>
                            We could not find any patient matching your search in the database
                            <br />
                        </b>
                    ) : null}
                    Looks like you have reached the maximum number of search attempts. However, you can still{" "}
                    {proceedText} and proceed with the case creation.
                </p>
            </MUIAlert>
        );
    }
    if (PDSFailedMatch) {
        const proceedText = allowContinueWithoutVerifying ? (
            <ContinueWithoutVerifyingButton>without verifying patient details</ContinueWithoutVerifyingButton>
        ) : (
            <>
                to <Link to={linkToProps(formFields)}>create a case manually</Link> if all the details are correct
            </>
        );
        return (
            <MUIAlert severity="error">
                <span>
                    <b>We could not find any patient matching your search in the database</b>
                    <br />
                    Amend your search and try again or proceed {proceedText}
                </span>
            </MUIAlert>
        );
    }
    if (needsRefinement) {
        return (
            <MUIAlert severity="warning">
                <span>
                    <b>We found more than one patient with the following details</b>
                    <br />
                    Provide the patient’s first name so we can match the correct patient in the database
                </span>
            </MUIAlert>
        );
    }
    if (isPatientDeceased) {
        const proceedText = allowContinueWithoutVerifying ? (
            <>
                <ContinueWithoutVerifyingButton>without verifying patient details</ContinueWithoutVerifyingButton> if
                all details are correct
            </>
        ) : (
            <>
                to <Link to={linkToProps(formFields)}>create a case manually</Link> if all the details are correct
            </>
        );
        return (
            <MUIAlert severity="error">
                <p>
                    <b>This patient is recorded as deceased in the NHS database. </b>
                    <br />
                    Amend your search and try again or proceed {proceedText}. In which case, inform the relevant staff
                    to make the necessary changes.
                </p>
            </MUIAlert>
        );
    }

    if (isNetworkError) {
        const proceedText = allowContinueWithoutVerifying ? (
            <ContinueWithoutVerifyingButton>without verifying patient details</ContinueWithoutVerifyingButton>
        ) : (
            <>
                to <Link to={linkToProps(formFields)}>enter details manually</Link>
            </>
        );
        return (
            <MUIAlert severity="error">
                <span>
                    <b>Something went wrong on our end</b>
                    <br />{" "}
                    <>
                        Try again and if the problem persists proceed <br />
                        {proceedText}
                    </>
                </span>
            </MUIAlert>
        );
    }

    if (PDSRecordIncomplete) {
        const proceedText = allowContinueWithoutVerifying ? (
            <ContinueWithoutVerifyingButton>without verifying patient details</ContinueWithoutVerifyingButton>
        ) : (
            <>
                to <Link to={linkToProps(formFields)}>enter details manually</Link>
            </>
        );
        return (
            <MUIAlert severity="warning">
                <span>
                    <b>Some of the details on the NHS database for this patient are missing</b>
                    <br /> <>Check the entered details and try again or proceed {proceedText}</>
                </span>
            </MUIAlert>
        );
    }
    return null;
}
