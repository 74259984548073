import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Home from "components/Home";
import CaseList from "components/CaseList";
import CaseViewReport from "components/CaseViewReport";
import CaseDescription from "components/CaseDescription";
import CaseEditPatient from "components/CaseEditPatient";
import CaseImgReassessment from "components/CaseImgReassessment";

import CaseListProvider from "contextProviders/CaseListProvider";

import useAutoLogout from "hooks/useAutoLogout";

import * as ROUTES from "navigation/routes";

const CaseListRoutes: FC = () => {
    useAutoLogout();

    return (
        <CaseListProvider>
            <Switch>
                <Route path={ROUTES.HOME} component={Home} exact />
                <Route path={ROUTES.CASE_LIST} component={CaseList} />
                <Route path={`${ROUTES.CASE_DESCRIPTION}/:id/pdf`} component={CaseViewReport} />
                <Route path={`${ROUTES.CASE_DESCRIPTION}/:id/:reviewMode?`} component={CaseDescription} />
                <Route path={`${ROUTES.EDIT_PATIENT_DETAILS}/:caseId`} component={CaseEditPatient} />
                <Route
                    path={`${ROUTES.REQUEST_IMG_REASSESSMENT}/:caseId/:lesionNumber`}
                    component={CaseImgReassessment}
                />
                <Redirect to={ROUTES.HOME} />
            </Switch>
        </CaseListProvider>
    );
};

export default CaseListRoutes;
