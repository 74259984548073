import { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import clsx from "clsx";
import { CheckboxProps, Container, Divider, Form, Header, Message } from "semantic-ui-react";

import { history } from "App";

import AssessmentFlowConfirmModal from "components/templates/AssessmentFlowConfirmModal";
import BlockUnconsentedCaseModal from "components/templates/BlockUnconsentedCaseModal";
import NoAutomatedDecisionModal from "components/templates/NoAutomatedDecisionModal";
import AssessmentFlowHeader from "components/templates/AssessmentFlowHeader";
import CustomButton from "components/templates/Button";

import { AtHomeFlowActionTypes } from "contextProviders/modules/atHomeFlow/actions";

import { IAssessment, INewAssessmentProps } from "model/assessment";
import { OrganisationType } from "model/organisation";
import { UserRole } from "model/userRole";
import PatientDetailsIntegration from "model/integrations";

import { REMOTE_MODEL_REGISTRATION } from "navigation/remoteModelRoutes";
import { ASSESSMENT_MODE, HOME, PATIENT_DETAILS, PATIENT_SEARCH, PATIENT_LOOK_UP } from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";

import "scss/MultilevelList.scss";

interface INewAssessmentState {
    consent?: boolean;
    secondaryConsent?: boolean;
    automatedDecisionConsent?: boolean;
    isBack: boolean;
    isEditMode: boolean;
    isEdited: boolean;
    showValidationError: boolean;
    isStudy: boolean;
    next: boolean;
    showModal: boolean;
    showBlockCaseModal: boolean;
    showNoAutomatedDecisionModal: boolean;
}

type UserConsentValidation = Array<{ text: string; hasConsent?: boolean }>;

class NewAssessment extends Component<INewAssessmentProps, INewAssessmentState> {
    constructor(props: any) {
        super(props);

        const { assessment, organisation } = this.props;

        this.state = {
            consent: assessment.case ? Boolean(assessment.case.consentDate) : undefined,
            secondaryConsent: assessment.case ? Boolean(assessment.case.secondaryConsentDate) : undefined,
            isBack: assessment.case !== undefined,
            isEditMode: false,
            isEdited: false,
            showValidationError: false,
            isStudy: organisation ? organisation.type === OrganisationType.STUDY : false,
            next: false,
            showModal: false,
            showBlockCaseModal: false,
            automatedDecisionConsent: assessment.case
                ? Boolean(assessment.case.automatedDecisionConsentDate)
                : undefined,
            showNoAutomatedDecisionModal: false,
        };
    }

    public componentDidMount() {
        const { isStudy } = this.state;
        const { getDevices } = this.props;

        if (isStudy) {
            getDevices();
        }

        logoutTimeoutService.stopCount();
    }

    private getBackLink() {
        const {
            organisation: { nonSkinCancerAllowed },
        } = this.props;

        if (nonSkinCancerAllowed) {
            return ASSESSMENT_MODE;
        }

        return HOME;
    }

    private submit = (create?: boolean) => {
        const { assessment, registration, pendingRequest, createCase, updateCase, atHomeFlowDispatch, organisation } =
            this.props;
        const { consent, secondaryConsent, isEdited, isEditMode, automatedDecisionConsent } = this.state;

        const { blockCasesWithoutAutomatedDecisionConsent, showAutomatedDecisionConsent } = organisation;

        const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;
        const consentValidationError: boolean = this.validateConsentForm();

        if (consentValidationError) {
            this.setState({ showValidationError: true });
            return;
        }

        if (blockCasesWithoutAutomatedDecisionConsent && !automatedDecisionConsent) {
            this.setState({ showBlockCaseModal: true });
            return;
        }

        if (isAtHomeFlow) {
            atHomeFlowDispatch({
                type: AtHomeFlowActionTypes.SET_CONSENT,
                payload: {
                    consent: Boolean(consent),
                    secondaryConsent: Boolean(secondaryConsent),
                    ...(showAutomatedDecisionConsent
                        ? { automatedDecisionConsent: Boolean(automatedDecisionConsent) }
                        : {}),
                },
            });
            history.push(REMOTE_MODEL_REGISTRATION);
        } else {
            const { case: assesmentCase, study, nonSkinCancer } = assessment;

            if (!assesmentCase && !pendingRequest) {
                createCase(consent, secondaryConsent, automatedDecisionConsent, nonSkinCancer, study?.uuid);
            } else if (create && isEditMode && isEdited) {
                this.setState({ showModal: true, isEditMode: false });
            } else {
                updateCase(assesmentCase.uuid, consent, secondaryConsent, automatedDecisionConsent);
            }

            if (!isEdited || (isEdited && create && !isEditMode)) {
                this.moveForward();
            }
        }
    };

    private changeConsentRadio = (e: React.FormEvent<HTMLInputElement>, { name, value }: CheckboxProps) => {
        this.setState({ [name]: Boolean(value === "true"), isEdited: true } as Pick<
            INewAssessmentState,
            keyof INewAssessmentState
        >);

        if (name === "automatedDecisionConsent" && value === "false") {
            this.setState({ showNoAutomatedDecisionModal: true });
        }
    };

    private editConsent = () => {
        const { isEditMode } = this.state;

        if (isEditMode) {
            this.submit();
        }

        this.setState((prevState) => ({ isEditMode: !prevState.isEditMode }));
    };

    private closeModal = () => {
        this.setState({ showModal: false, isEditMode: false });
    };

    private closeShowBlockCaseModal = () => {
        this.setState({ showBlockCaseModal: false });
    };

    private closeShowNoAutomatedDecisionModal = (event: any, reason: string) => {
        if (reason !== "backdropClick") {
            this.setState({ showNoAutomatedDecisionModal: false });
        }
    };

    private moveForward = () => {
        this.setState({
            next: true,
        });
    };

    private resetEdit = () => {
        const { assessment } = this.props;

        this.setState({
            consent: assessment.case ? Boolean(assessment.case.consentDate) : undefined,
            secondaryConsent: assessment.case ? Boolean(assessment.case.secondaryConsentDate) : undefined,
            isEdited: false,
            isEditMode: false,
        });
    };

    private getConsentText = (consentType: "assessment" | "research") => {
        const { organisation, assessment, registration, user } = this.props;

        const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;
        const isAtHomePatientFlow = isAtHomeFlow && !user;
        const isNonSkinCancerFlow = assessment.nonSkinCancer;

        let organisationConsent;

        if (isAtHomePatientFlow) {
            organisationConsent = organisation[`${consentType}Patient`]
                ? organisation[`${consentType}Patient`]
                : organisation[consentType];
        } else if (isNonSkinCancerFlow) {
            organisationConsent = organisation[`${consentType}Nsc`];
        } else {
            organisationConsent = organisation[consentType];
        }

        return ReactHtmlParser(organisationConsent);
    };

    private validateConsentForm = (): boolean => {
        const { organisation, registration } = this.props;
        const { consent, secondaryConsent, automatedDecisionConsent } = this.state;
        const {
            primaryConsent: primaryConsentText,
            secondaryConsent: secondaryConsentText,
            automatedDecisionConsentPatient,
            automatedDecisionConsentClinician,
            showAutomatedDecisionConsent,
        } = organisation;

        const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;

        const consentAndUserSelection: UserConsentValidation = [
            { text: primaryConsentText, hasConsent: consent },
            { text: secondaryConsentText, hasConsent: secondaryConsent },
        ];

        if (showAutomatedDecisionConsent) {
            const automatedDecisionText = isAtHomeFlow
                ? automatedDecisionConsentPatient
                : automatedDecisionConsentClinician;
            consentAndUserSelection.push({ text: automatedDecisionText, hasConsent: automatedDecisionConsent });
        }
        const undefinedConsents: UserConsentValidation = consentAndUserSelection.filter(
            (field) => field.text && typeof field.hasConsent === "undefined"
        );

        return undefinedConsents.length > 0;
    };

    private showConsentValidationError = (type: string): boolean => {
        const { consent, secondaryConsent, automatedDecisionConsent, showValidationError } = this.state;

        switch (type) {
            case "consent": {
                return showValidationError && typeof consent === "undefined";
            }
            case "secondaryConsent": {
                return showValidationError && typeof secondaryConsent === "undefined";
            }
            case "automatedDecisionConsent": {
                return showValidationError && typeof automatedDecisionConsent === "undefined";
            }
            default: {
                return false;
            }
        }
    };

    private getPatientDetailsPage = (
        patientDetailsIntegration: PatientDetailsIntegration,
        caseAssessment: IAssessment
    ): string => {
        let page = PATIENT_DETAILS;
        if (
            patientDetailsIntegration === PatientDetailsIntegration.PDS &&
            (!caseAssessment?.patient || caseAssessment?.patient?.integrationInfo)
        ) {
            page = PATIENT_LOOK_UP;
        }
        if (patientDetailsIntegration === PatientDetailsIntegration.PAS && !caseAssessment.patient) {
            page = PATIENT_SEARCH;
        }
        return page;
    };

    public render() {
        const { pendingRequest, organisation, assessment, registration, user } = this.props;
        const { isBack, isEditMode, showModal, next } = this.state;
        const { showBlockCaseModal, automatedDecisionConsent, showNoAutomatedDecisionModal } = this.state;

        const {
            primaryConsent: primaryConsentText,
            secondaryConsent: secondaryConsentText,
            automatedDecisionConsentClinician,
            automatedDecisionConsentPatient,
            showAutomatedDecisionConsent,
            blockCasesWithoutAutomatedDecisionConsent,
            patientDetailsIntegrationType,
        } = organisation;

        const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;

        const automatedDecisionText = isAtHomeFlow
            ? automatedDecisionConsentPatient
            : automatedDecisionConsentClinician;

        const title = isAtHomeFlow
            ? "Consent"
            : `Consent for skin ${assessment.nonSkinCancer ? "complaint" : "lesion"} assessment`;

        if (assessment.case && next) {
            const nextLink = this.getPatientDetailsPage(patientDetailsIntegrationType, assessment);
            return <Redirect to={nextLink} />;
        }

        const consents = [
            { type: "consent", text: primaryConsentText },
            { type: "secondaryConsent", text: secondaryConsentText },
        ].filter((field) => field.text);

        const buttonWrapperClassname = clsx("buttons-wrapper__remote-consent", isAtHomeFlow && "at-home-flow");

        return (
            <Container className="ui segment wizzard-container">
                <AssessmentFlowHeader
                    title={title}
                    isEditMode={isEditMode}
                    disabled={!isBack}
                    edit={!isAtHomeFlow && this.editConsent}
                    reset={this.resetEdit}
                />
                <Divider />
                <p>Please ensure you are comfortable with the information below before proceeding.</p>
                <Header as="h3">Medical consent for assessment</Header>
                <div>{this.getConsentText("assessment")}</div>

                {showAutomatedDecisionConsent && automatedDecisionText && (
                    <>
                        <Header as="h3">Consent for automated decision making</Header>
                        <p>{ReactHtmlParser(automatedDecisionText)}</p>
                        <Form className="margin-bottom-2">
                            <Form.Radio
                                label="Yes"
                                name="automatedDecisionConsent"
                                value="true"
                                checked={automatedDecisionConsent === true}
                                disabled={isBack && !isEditMode}
                                onChange={this.changeConsentRadio}
                            />
                            <Form.Radio
                                label="No"
                                name="automatedDecisionConsent"
                                value="false"
                                checked={automatedDecisionConsent === false}
                                disabled={isBack && !isEditMode}
                                onChange={this.changeConsentRadio}
                            />
                        </Form>
                        {this.showConsentValidationError("automatedDecisionConsent") && (
                            <Message error content="This question is required" />
                        )}
                    </>
                )}

                <Header as="h3">Consent for additional use of data</Header>
                <div>{this.getConsentText("research")}</div>
                <br />
                {consents.map(({ type, text }) => (
                    <Fragment key={type}>
                        <p>{ReactHtmlParser(text)}</p>
                        <Form className="margin-bottom-2">
                            <Form.Radio
                                label="Yes"
                                name={type}
                                value="true"
                                // eslint-disable-next-line react/destructuring-assignment
                                checked={this.state[type] === true}
                                disabled={isBack && !isEditMode}
                                onChange={this.changeConsentRadio}
                            />
                            <Form.Radio
                                label="No"
                                name={type}
                                value="false"
                                // eslint-disable-next-line react/destructuring-assignment
                                checked={this.state[type] === false}
                                disabled={isBack && !isEditMode}
                                onChange={this.changeConsentRadio}
                            />
                        </Form>
                        {this.showConsentValidationError(type) && <Message error content="This question is required" />}
                    </Fragment>
                ))}
                <div className={buttonWrapperClassname}>
                    {!isAtHomeFlow && (
                        <CustomButton
                            className="remote-consent-navigation__back fixed-width"
                            variant="empty"
                            to={this.getBackLink()}
                            text="< Back"
                            type="link"
                            disabled={pendingRequest || isEditMode}
                        />
                    )}
                    <CustomButton
                        className="remote-consent-navigation__continue fixed-width"
                        loading={pendingRequest}
                        disabled={pendingRequest}
                        variant="filled"
                        type="submit"
                        action={this.submit}
                        text="I Agree - Continue >"
                    />
                </div>
                <AssessmentFlowConfirmModal
                    show={showModal}
                    close={this.closeModal}
                    goAhead={this.moveForward}
                    save={this.submit}
                />
                <BlockUnconsentedCaseModal
                    show={blockCasesWithoutAutomatedDecisionConsent && showBlockCaseModal}
                    onClose={this.closeShowBlockCaseModal}
                    closeOnOverlay={false}
                />
                <NoAutomatedDecisionModal
                    isOpen={showNoAutomatedDecisionModal}
                    handleClose={(event, reason) => this.closeShowNoAutomatedDecisionModal(event, reason)}
                    isClinician={user?.role === UserRole.CLINICIAN}
                    blockCasesWithoutAutomatedDecisionConsent={blockCasesWithoutAutomatedDecisionConsent}
                />
            </Container>
        );
    }
}

export default NewAssessment;
