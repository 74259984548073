import { FC, Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MUIRadio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Message, Segment } from "semantic-ui-react";

import SkinColour from "components/templates/FitzpatrickSkinColour";
import CustomTooltip from "components/templates/CustomTooltip";

import {
    mapAnswerToFSTMarkup,
    mapAnswerToAdditionalInformation,
    getUserRoleFromFPNote,
    sortFitzpatrickQuestionAnswers,
    getSkinTypeClassificationAnswer,
} from "helpers/fitzpatrick";

import { IMedicalHistory } from "model/assessment";
import { ICase, ISkinToneAnswer } from "model/case";
import IClickOrigin from "model/clickOrigin";
import { FitzpatrickAnswers, FitzpatrickAnswersType } from "model/fixedQuestion";

import * as patientService from "services/patientService";

import "scss/Dialog.scss";
import "scss/Modal.scss";

const Radio = styled(MUIRadio)(({ theme }) => ({
    "&:hover, &:active, &.Mui-focusVisible": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5,
    },
    "&.Mui-checked": {
        color: theme.palette.common.black,
    },
    "&.Mui-disabled": {
        color: theme.palette.common.black,
        opacity: 0.5,
    },
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            "& .MuiDialog-paper": {
                minWidth: "calc(90vw - 64px)",
                maxWidth: "calc(90vw - 64px)",
                minHeight: "calc(90vh - 64px)",
                maxHeight: "calc(90vh - 64px)",
                [theme.breakpoints.down("sm")]: {
                    width: "100vw",
                    height: "100vh",
                },
                overflow: "hidden",
            },
        },
        dialogActions: {
            padding: "24px",
        },
        dialogContent: {
            paddingTop: "0px",
        },
        existingSkinToneClassification: {
            paddingBottom: "16px",
        },
        fitzpatrickAnswer: {
            marginBottom: "0px",
        },
        fstRadio: {
            position: "relative",
            display: "flex",
            justifyContents: "center",
            alignItems: "center",
            padding: "24px 16px",
            height: "120px",
        },
        fstLabel: {
            position: "absolute",
            left: "110px",
            top: "10%",
        },
        gridContainer: {
            "&.MuiGrid-item": {
                paddingRight: "16px",
            },
        },
        gridItem: {
            "borderTop": "1px solid #dbdcdd",
            "padding": "8px",

            "&:last-child": {
                borderBottom: "1px solid #dbdcdd",
            },
        },
        notes: {
            paddingTop: "16px",
        },
        skinToneClassificationPanel: {
            padding: "8px 0px",
        },
        subtitle: {
            color: "#7a7a7a",
            marginBottom: "4px",
        },
        tooltip: {
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            fill: "#808080",
            marginLeft: "8px",
        },
    })
);

interface INotes {
    fitzpatrickQuestionAnswers: IMedicalHistory[];
    caseObject: ICase;
}

interface IError {
    hasErrored: boolean;
    errorMessage: { message: string }[];
}

interface IErrorMessages {
    errors: IError;
}

const ErrorMessages: FC<IErrorMessages> = ({ errors }) => {
    const { errorMessage } = errors;
    if (!errorMessage) {
        return null;
    }
    return (
        <>
            {errorMessage.map((detail: { message: string }) => (
                <Message key={detail.message} negative>
                    <p>{detail.message}</p>
                </Message>
            ))}
        </>
    );
};

interface ISkinToneFormLabel {
    fitzpatrick: FitzpatrickAnswers;
}
const SkinToneFormLabel: FC<ISkinToneFormLabel> = ({ fitzpatrick }) => {
    const classes = useStyles();

    const fstMarkup = mapAnswerToFSTMarkup(fitzpatrick);
    const tooltipText = mapAnswerToAdditionalInformation(fitzpatrick);

    return (
        <div>
            <SkinColour fitzpatrickAnswer={fitzpatrick} />
            <div className={classes.fstLabel}>{fstMarkup}</div>
            <CustomTooltip className={classes.tooltip} placement="bottom-end" title={tooltipText}>
                <InfoOutlinedIcon />
            </CustomTooltip>
        </div>
    );
};

interface IReportIncorrectClassificationModal {
    caseObject: ICase;
    fitzpatrickQuestionAnswers: IMedicalHistory[];
    showModal: boolean;
    clickOrigin: IClickOrigin;
    closeModal: () => void;
    updateSkinToneClassification: (result: ISkinToneAnswer[]) => void;
}

const Notes: FC<INotes> = ({ fitzpatrickQuestionAnswers: notesFitzpatrickQA, caseObject }) => (
    <>
        {notesFitzpatrickQA.map(
            (fitzpatrickQuestionAnswer) =>
                fitzpatrickQuestionAnswer?.additionalInformation && (
                    <div key={fitzpatrickQuestionAnswer.createdBy}>
                        <h3>{getUserRoleFromFPNote(fitzpatrickQuestionAnswer, caseObject)} Note</h3>
                        <Segment className="note note-container">
                            <div className="actual-note-wrapper">
                                <p>{fitzpatrickQuestionAnswer?.additionalInformation}</p>
                            </div>
                        </Segment>
                    </div>
                )
        )}
    </>
);

const ReportIncorrectClassificationModal: FC<IReportIncorrectClassificationModal> = ({
    caseObject,
    fitzpatrickQuestionAnswers,
    showModal,
    clickOrigin,
    closeModal,
    updateSkinToneClassification,
}) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<FitzpatrickAnswersType>(null);
    const [errors, setErrors] = useState<IError>({
        hasErrored: false,
        errorMessage: [],
    });
    const [loading, setLoading] = useState<boolean>(false);

    // To ensure that the patient answer will always be displayed at the top
    const sortedFitzpatrickQuestionAnswers = fitzpatrickQuestionAnswers.sort(sortFitzpatrickQuestionAnswers);

    const fitzpatrickAnswer = getSkinTypeClassificationAnswer(fitzpatrickQuestionAnswers, caseObject);

    if (!showModal) {
        return null;
    }

    const handleClose = (): void => {
        setSelected(null);
        closeModal();
    };

    const handleChange = (event): void => {
        setSelected(event.target.value);
    };

    const handleSubmit = async (): Promise<void> => {
        try {
            setErrors({ hasErrored: false, errorMessage: [] });
            setLoading(true);
            const result: ISkinToneAnswer[] = await patientService.updateSkinToneClassification(
                caseObject.patientUuid,
                caseObject.uuid,
                selected as FitzpatrickAnswers,
                clickOrigin
            );
            updateSkinToneClassification(result);
            setErrors({ hasErrored: false, errorMessage: [] });
            handleClose();
        } catch (e: any) {
            setErrors({ hasErrored: true, errorMessage: e?.response?.data?.errors });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog className={classes.dialog} onClose={handleClose} open={showModal} fullWidth={false}>
            <DialogTitle>Report incorrect classification</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {fitzpatrickAnswer ? (
                    <div className={classes.existingSkinToneClassification}>
                        <p className={classes.subtitle}>Response recorded by the clinician:</p>
                        <p className={classes.fitzpatrickAnswer}>{fitzpatrickAnswer}</p>
                        <p>{mapAnswerToAdditionalInformation(fitzpatrickAnswer)}</p>
                    </div>
                ) : null}
                <FormControl component="fieldset">
                    <div>
                        <p>Select the Fitzpatrick skin type that you think is more accurate for this patient:</p>
                    </div>
                    <RadioGroup aria-label="Fitzpatrick type" value={selected} onChange={handleChange}>
                        <Grid className={classes.skinToneClassificationPanel} container spacing={1}>
                            <Grid className={classes.gridContainer} container item spacing={1} xs={12} md={6}>
                                {[
                                    FitzpatrickAnswers.TYPE_1_OPTION,
                                    FitzpatrickAnswers.TYPE_2_OPTION,
                                    FitzpatrickAnswers.TYPE_3_OPTION,
                                ].map((fp) => {
                                    const fstMarkup = mapAnswerToFSTMarkup(fp);

                                    return (
                                        <Fragment key={fstMarkup}>
                                            <Grid className={classes.gridItem} item xs={12}>
                                                <div className={classes.fstRadio}>
                                                    <FormControlLabel
                                                        checked={selected === fp}
                                                        control={<Radio />}
                                                        label={<SkinToneFormLabel fitzpatrick={fp} />}
                                                        labelPlacement="end"
                                                        value={fp}
                                                    />
                                                </div>
                                            </Grid>
                                        </Fragment>
                                    );
                                })}
                            </Grid>
                            <Grid className={classes.gridContainer} container item spacing={1} xs={12} md={6}>
                                {[
                                    FitzpatrickAnswers.TYPE_4_OPTION,
                                    FitzpatrickAnswers.TYPE_5_OPTION,
                                    FitzpatrickAnswers.TYPE_6_OPTION,
                                ].map((fp) => {
                                    const fstMarkup = mapAnswerToFSTMarkup(fp);
                                    return (
                                        <Fragment key={fstMarkup}>
                                            <Grid className={classes.gridItem} item xs={12}>
                                                <div className={classes.fstRadio}>
                                                    <FormControlLabel
                                                        checked={selected === fp}
                                                        control={<Radio />}
                                                        label={<SkinToneFormLabel fitzpatrick={fp} />}
                                                        labelPlacement="end"
                                                        value={fp}
                                                    />
                                                </div>
                                            </Grid>
                                        </Fragment>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
                <Notes fitzpatrickQuestionAnswers={sortedFitzpatrickQuestionAnswers} caseObject={caseObject} />
                <ErrorMessages errors={errors} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleClose} variant="contained" color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={!selected || loading} variant="contained" color="primary">
                    {loading ? <CircularProgress size="16px" /> : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReportIncorrectClassificationModal;
