import React, { FC } from "react";
import ReactHtmlParser from "react-html-parser";

interface IReviewSafetyNettingMessage {
    keyMessage: string | undefined;
    safetyNettingMessage: string | undefined;
}

const ReviewSafetyNettingMessage: FC<IReviewSafetyNettingMessage> = ({ keyMessage, safetyNettingMessage }) => {
    const shouldShow = keyMessage || safetyNettingMessage;

    return (
        (shouldShow && (
            <div>
                <h4>Preview of patient facing text correlated to this Diagnosis/Management outcome:</h4>
                <p>
                    <div>{ReactHtmlParser(keyMessage || "")}</div>
                </p>
                <p>
                    <div>{ReactHtmlParser(safetyNettingMessage || "")}</div>
                </p>
            </div>
        )) ||
        null
    );
};

export default ReviewSafetyNettingMessage;
