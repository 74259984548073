import { FC } from "react";
import { Image } from "semantic-ui-react";

import { PatientSearchExceptionModalTypes } from "model/patientSearch";

interface IPatientSearchExceptionModal {
    type: PatientSearchExceptionModalTypes;
    onClose: () => void;
}

const TITLES = {
    [PatientSearchExceptionModalTypes.MINIMUM_AGE]:
        "Patient’s age is lower than the minimum allowed age for this service.",
    [PatientSearchExceptionModalTypes.NOT_ENOUGH_DATA]:
        "Source record does not contain enough information to create a case.",
    [PatientSearchExceptionModalTypes.DECEASED_PATIENT]:
        "Patient is deceased: you will not be able to create a case for this Patient.",
};

const TEXTS = {
    [PatientSearchExceptionModalTypes.NOT_ENOUGH_DATA]:
        "Required fields are Hospital number, Date of Birth, Surname and First Name. Please check/update the source record or create a new case and enter the required information manually",
};

const PatientSearchExceptionModal: FC<IPatientSearchExceptionModal> = ({ type, onClose }) => {
    const text = TEXTS[type];
    const title = TITLES[type];

    const handleCloseClicked = () => {
        onClose();
    };

    return (
        <div className="patient-search-exception-modal__wrapper">
            <button type="button" className="patient-search-exception-modal__close-button" onClick={handleCloseClicked}>
                <Image src="/images/icons/close-icon.svg" alt="close icon" />
            </button>
            <div className="patient-search-exception-modal__image-wrapper">
                <Image src="/images/icons/warning-icon.svg" alt="warning icon" />
            </div>
            <p className="patient-search-exception-modal__text-wrapper">
                <span className="patient-search-exception-modal__title">{title}</span>
                {text && <span className="patient-search-exception-modal__text">{text}</span>}
            </p>
        </div>
    );
};

export default PatientSearchExceptionModal;
