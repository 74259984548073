export enum ReviewType {
    CLIENT = "CLIENT",
    SAFETY_NET = "SAFETY_NET",
    NON_SKIN_CANCER = "NON_SKIN_CANCER",
}

export enum ReviewTypePrefixText {
    CLIENT = "CLIENT",
    SAFETY_NET = "SAFETY NET",
    NON_SKIN_CANCER = "NON_SKIN_CANCER",
}

export enum ReviewSettingMode {
    VIEW = "VIEW",
    CREATE = "CREATE",
    EDIT = "EDIT",
}
