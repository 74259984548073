import { FC, useReducer, createContext, Dispatch, ReactNode } from "react";

import { TReportAvailableActions } from "contextProviders/modules/reportAvailable/actions";
import reducer from "contextProviders/modules/reportAvailable/reducer";

import { RemoteModelStatuses } from "model/assessment";

export interface IState {
    caseStatus: RemoteModelStatuses;
    isCaseClosingPending: boolean;
    isSaveReportButtonAvailable: boolean;
    isDownloadReportErrorVisible: boolean;
    isAwaitingCallback: boolean;
    caseCloseError: string;
}

interface IReportAvailableProviderProps {
    children: ReactNode;
}

const initialState = {
    caseStatus: RemoteModelStatuses.REPORT_AVAILABLE,
    isCaseClosingPending: false,
    isSaveReportButtonAvailable: true,
    isDownloadReportErrorVisible: false,
    isAwaitingCallback: false,
    caseCloseError: "",
};

export const ReportAvailableContext = createContext<{
    state: IState;
    dispatch: Dispatch<TReportAvailableActions>;
}>({
    state: initialState,
    dispatch: () => null,
});

const ReportAvailableProvider: FC<IReportAvailableProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {
        state,
        dispatch,
    };

    return <ReportAvailableContext.Provider value={value}>{children}</ReportAvailableContext.Provider>;
};

export default ReportAvailableProvider;
