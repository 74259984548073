import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Image } from "semantic-ui-react";

import AnimatedDots from "components/templates/AnimatedDots";

import withModal from "HOC/withModal";

import { history } from "App";
import { LOGIN } from "navigation/routes";

import * as actions from "redux/actions";

const TIMEOUT = 3000;

const RedirectModal: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(actions.registrationUIActions.setIsModalRedirectVisible(false));
            history.push(LOGIN);
        }, TIMEOUT);
    }, []);

    return (
        <div className="remote-model__modal">
            <Image alt="User already has an account icon" src="/images/icons/icon-user-checked.svg" />
            <p className="h2">Looks like you already have an account</p>
            <p className="h4">
                redirecting you to log in
                <AnimatedDots />
            </p>
        </div>
    );
};

export default withModal(RedirectModal);
