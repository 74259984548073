import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Divider,
    Grid,
    Label,
    Menu,
    Message,
    Pagination,
    PaginationProps,
    Segment,
    Table,
} from "semantic-ui-react";

import DeviceRow from "components/Administration/StudyManagment/EditStudy/DeviceRow";
import DeleteDeviceDialog from "components/templates/dialog/DeleteDeviceDialog";

import { AdministrationPages, StudyManagmentSubpages } from "model/administrationPages";
import { IDevice } from "model/study";

import { ADMINISTRATION } from "navigation/routes";

import studyService from "services/studyService";

const PAGINATION_QUERY_LIMIT = 10;

interface IStudyDeviceList {
    studyUuid: string;
}

interface IDeviceListStatus {
    activePage: number;
    totalPages: number;
    totalDevices: number;
    errors: string[];
    studyDevices: IDevice[];
    deleteDeviceDialogOpened: boolean;
}

class DeviceList extends Component<IStudyDeviceList, IDeviceListStatus> {
    constructor(props: any) {
        super(props);

        this.state = {
            activePage: 0,
            totalPages: 0,
            totalDevices: 0,
            errors: [],
            studyDevices: [],
            deleteDeviceDialogOpened: false,
        };
    }

    public componentDidMount() {
        const { activePage } = this.state;

        this.loadPage(activePage);
    }

    private getErrorMessages() {
        const { errors } = this.state;

        if (errors) {
            return errors.map((detail: any) => (
                <Message key={detail.message} negative>
                    <p>{detail.message}</p>
                </Message>
            ));
        }

        return undefined;
    }

    private handlePaginationChange = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.SyntheticEvent<HTMLElement, Event>,
        data: PaginationProps
    ) => {
        const { activePage } = data;
        this.loadPage(Number(activePage) - 1);
    };

    private getDevicesTable() {
        const { activePage, totalDevices, totalPages } = this.state;

        return (
            <Table padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={2}>Manufacturer</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Model</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Version</Table.HeaderCell>
                        <Table.HeaderCell width={1}>OS version</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Used for AI</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Info</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{this.getDeviceTableBody()}</Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={7} textAlign="left">
                            <Label className="table-label">
                                <div>
                                    <Label.Detail>Total Devices: {totalDevices}</Label.Detail>
                                </div>
                            </Label>
                            <Menu floated="right" pagination>
                                <Pagination
                                    disabled={totalPages === 0}
                                    activePage={activePage + 1}
                                    onPageChange={this.handlePaginationChange}
                                    size="small"
                                    totalPages={totalPages}
                                />
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );
    }

    private getDeviceTableBody() {
        const { studyDevices, deleteDeviceDialogOpened } = this.state;

        if (studyDevices && studyDevices.length > 0) {
            return studyDevices.map((device: IDevice) => {
                const handleDeviceDelete = () => {
                    this.deleteDevice(device.uuid);
                };

                return (
                    <Fragment key={device.uuid}>
                        <DeviceRow device={device} onDeviceDelete={this.toggleDeleteDeviceDialog} />

                        <DeleteDeviceDialog
                            onClose={this.toggleDeleteDeviceDialog}
                            callback={this.toggleDeleteDeviceDialog}
                            showDialog={deleteDeviceDialogOpened}
                            onDelete={handleDeviceDelete}
                        />
                    </Fragment>
                );
            });
        }
        return (
            <Table.Row key="none">
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
            </Table.Row>
        );
    }

    private toggleDeleteDeviceDialog = () => {
        const { deleteDeviceDialogOpened } = this.state;
        this.setState({ deleteDeviceDialogOpened: !deleteDeviceDialogOpened });
    };

    private deleteDevice = (deviceUuid: string) => {
        const { studyDevices } = this.state;
        studyService
            .deleteDevice(deviceUuid)
            .then(() => {
                this.setState({
                    studyDevices: studyDevices.filter((device: IDevice) => device.uuid !== deviceUuid),
                });
            })
            .catch((err) => {
                console.error("error", err);
            });
    };

    private async loadPage(actualPage: number): Promise<void> {
        const { studyUuid } = this.props;

        studyService
            .getStudyDevices(studyUuid, PAGINATION_QUERY_LIMIT, actualPage * PAGINATION_QUERY_LIMIT)
            .then((response) => {
                this.setState({
                    activePage: actualPage,
                    totalPages: Math.ceil(response.total / PAGINATION_QUERY_LIMIT),
                    totalDevices: response.total,
                    studyDevices: response.data,
                    errors: response.errors,
                });
            });
    }

    public render() {
        const { studyUuid } = this.props;

        return (
            <Segment>
                <h4>Device list in study</h4>
                <Divider />
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column width={3} floated="right">
                            <Link
                                to={`${ADMINISTRATION}/${AdministrationPages.STUDY_MANAGEMENT}/${StudyManagmentSubpages.CREATE_DEVICE}/${studyUuid}`}
                            >
                                <Button>Create new device</Button>
                            </Link>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>{this.getDevicesTable()}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>{this.getErrorMessages()}</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}

export default DeviceList;
