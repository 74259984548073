import { Component, ChangeEvent, FormEvent } from "react";
import { Redirect } from "react-router-dom";
import { Divider, Form, Grid, Image, Label, Message, Segment, CheckboxProps } from "semantic-ui-react";

import OrganisationSettingLabel from "components/Administration/OrganisationManagment/OrganisationSettingLabel";
import { MAX_LOGOFILE_SIZE } from "components/Administration/OrganisationManagment/ViewOrganisation/EditOrganisationDetailsComponent";
import CustomButton from "components/templates/Button";
import CustomRichTextEditor from "components/templates/Editor";
import SkinConditionsPathwayPopup from "components/templates/dialog/skinConditionsPathwayPopup";

import { validOrganisationEmail } from "helpers/organisation";
import { isNumber, NOT_VALID_EMAIL_ERROR } from "helpers/common";
import { urlToFile } from "helpers/file";
import { scrollBottom } from "helpers/page";

import {
    AdministrationPages,
    OrganisationManagementSubpages,
    OrganisationPropertyLabels,
    OrganisationPropertyHelpText,
    OrganisationPropertyIds,
} from "model/administrationPages";
import { IDictionaryItem } from "model/dictionaryItem";
import HttpStatus from "model/httpStatus";
import { INewOrganisation } from "model/newOrganisation";
import { OrganisationType } from "model/organisationType";
import { NotificationRole, UserRole } from "model/userRole";

import { history } from "App";
import { ADMINISTRATION } from "navigation/routes";

import organizationService from "services/organizationService";
import userService from "services/userService";

import "scss/Container.scss";

const NONE_ORGANISATION_OPTION = "None";
const CHECK_OVERDUE_SETTINGS_AVAILABLE_INTERVAL = 3000;
const ALLOW_HOME_INITIATED_CASES_ID = "allowHomeInitiatedCases";
const NON_SKIN_CANCER_ALLOWED_ID = "nonSkinCancerAllowed";

interface ILogoInput {
    src: string | ArrayBuffer | null;
}

interface ICreateOrganisationComponentState {
    errorDetails: any[];
    isError: boolean;
    organisationDict: IDictionaryItem[];
    submitting: boolean;
    guidanceInput: string;
    nameInput: string;
    typeInput: OrganisationType | null;
    assessmentInput: string;
    researchInput: string;
    lesionsLimitInput: number | undefined;
    safetyNetPathwayContextInput: string;
    minimumAgeInput: number | undefined;
    codeName: string | undefined;
    organisationEmail: string | undefined;
    emailInputError?: boolean;
    logoInput: ILogoInput;
    file: File | undefined;
    nonSkinCancerAllowed: boolean;
    assessmentNscInput: string;
    researchNscInput: string;
    allowHomeInitiatedCases: boolean;
    parentOrganisationUuid: string;
    logoUploadError: boolean;
    logoField: string;
    intervalId: number;
    isCheckboxDialogVisible: boolean;
    checkboxDialogOrganisationProperty: string;
    mfaEnabled: boolean;
}

const MIN_POSITIVE_NUMBER = 1;

interface ICreateNewOrganisation {
    newOrganisation: INewOrganisation;
    parentOrganisationUuid: string;
}

class CreateOrganisationComponent extends Component<any, ICreateOrganisationComponentState> {
    private static async createNewOrganisation({
        newOrganisation,
        parentOrganisationUuid: parentUuid,
    }: ICreateNewOrganisation): Promise<any> {
        if (parentUuid && parentUuid !== NONE_ORGANISATION_OPTION) {
            const newOrg = await organizationService.createNewSuborganisation(parentUuid, newOrganisation);

            return newOrg;
        }
        const newOrg = await organizationService.createNewOrganisation(newOrganisation);

        return newOrg;
    }

    private static addNoneDictOption(options: IDictionaryItem[]) {
        return [
            {
                key: NONE_ORGANISATION_OPTION,
                text: NONE_ORGANISATION_OPTION,
                value: NONE_ORGANISATION_OPTION,
            },
        ].concat(options);
    }

    constructor(props: any) {
        super(props);

        const { params } = this.props;

        this.state = {
            errorDetails: [],
            isError: false,
            organisationDict: [],
            submitting: false,
            assessmentInput: "",
            file: undefined,
            guidanceInput: "",
            lesionsLimitInput: undefined,
            logoInput: { src: "" },
            minimumAgeInput: undefined,
            nameInput: "",
            researchInput: "",
            safetyNetPathwayContextInput: "",
            typeInput: null,
            codeName: undefined,
            nonSkinCancerAllowed: false,
            assessmentNscInput: "",
            researchNscInput: "",
            allowHomeInitiatedCases: false,
            parentOrganisationUuid: params.uuid || NONE_ORGANISATION_OPTION,
            logoUploadError: false,
            logoField: "",
            intervalId: 0,
            organisationEmail: undefined,
            emailInputError: false,
            isCheckboxDialogVisible: false,
            checkboxDialogOrganisationProperty: "",
            mfaEnabled: false,
        };
    }

    public componentDidMount() {
        const { params } = this.props;
        const { uuid } = params;

        organizationService.getAllOrganisationsDictionary().then((result) => {
            const withAllRemoved = result?.filter((res) => res.key !== "ALL");
            this.setState({ organisationDict: CreateOrganisationComponent.addNoneDictOption(withAllRemoved) });
        });

        if (uuid) {
            this.fetchParentLogo(uuid);
        }
    }

    public componentWillUnmount() {
        const { intervalId } = this.state;

        window.clearInterval(intervalId);
    }

    private getParentOrganisationInput() {
        const { params } = this.props;
        const { parentOrganisationUuid, organisationDict } = this.state;

        if (organisationDict.length === 0) {
            return (
                <>
                    <p>
                        <b>Parent Organisation</b>
                    </p>
                    <p>Loading organisation list...</p>
                </>
            );
        }
        if (params.uuid) {
            return (
                <>
                    <p>
                        <b>Parent Organisation</b>
                    </p>
                    <p>
                        {CreateOrganisationComponent.getParentOrganisationName(
                            parentOrganisationUuid,
                            organisationDict
                        )}
                    </p>
                </>
            );
        }
        return (
            <Form.Select
                label="Parent organisation"
                type="text"
                id="parentOrganisationUuid"
                placeholder="Select organisation"
                onChange={this.onParentOrgFieldChange}
                options={organisationDict}
            />
        );
    }

    private static getParentOrganisationName(parentOrganisationUuid: string, organisationDict: IDictionaryItem[]) {
        const parentOrganisation = organisationDict.find(
            (organisation: any) => organisation.key === parentOrganisationUuid
        );
        if (parentOrganisation) {
            return parentOrganisation.text;
        }
        return parentOrganisationUuid ? "loading..." : "";
    }

    private getBackButton() {
        const { parentOrganisationUuid } = this.state;
        if (parentOrganisationUuid) {
            return <CustomButton variant="dark" type="button" floated="right" action={this.onBackButton} text="Back" />;
        }
        return <></>;
    }

    private onBackButton = () => {
        const { parentOrganisationUuid } = this.state;
        const location = `${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}/${OrganisationManagementSubpages.ORGANISATION_DETAILS}/${parentOrganisationUuid}`;
        history.push(location);
    };

    private getErrorMessages() {
        const { errorDetails } = this.state;

        return errorDetails.map((detail: any) => (
            <Message key={detail.message} negative>
                <p>{detail.message}</p>
            </Message>
        ));
    }

    private onFieldChange = (event: any, obj: any) => {
        const stateObj = {};
        const fieldName = obj.id;
        const fieldValue = obj.type === "checkbox" ? obj.checked : obj.value;

        if (fieldName === NotificationRole.ORGANISATION_EMAIL) {
            this.setState({ emailInputError: false });
        }

        stateObj[fieldName] = fieldValue;
        this.setState(stateObj);
    };

    private onRichTextEditorChanged = (
        value: ICreateOrganisationComponentState[keyof ICreateOrganisationComponentState],
        name: string
    ) => {
        this.setState({ [name]: value } as Pick<
            ICreateOrganisationComponentState,
            keyof ICreateOrganisationComponentState
        >);
    };

    private onParentOrgFieldChange = (event: any, obj: any) => {
        this.onFieldChange(event, obj);
        const parentUuid = obj.value;
        if (parentUuid && parentUuid !== NONE_ORGANISATION_OPTION) {
            this.fetchParentLogo(parentUuid);
        }
    };

    private submit = () => {
        this.setState(() => ({
            submitting: true,
        }));

        const {
            guidanceInput,
            parentOrganisationUuid,
            nameInput,
            typeInput,
            assessmentInput,
            researchInput,
            lesionsLimitInput,
            file,
            safetyNetPathwayContextInput,
            minimumAgeInput,
            organisationEmail,
            codeName,
            nonSkinCancerAllowed,
            assessmentNscInput,
            researchNscInput,
            allowHomeInitiatedCases,
            mfaEnabled,
        } = this.state;

        const lesionsLimit = isNumber(lesionsLimitInput) ? Number(lesionsLimitInput) : undefined;
        const minimumAge = isNumber(minimumAgeInput) ? Number(minimumAgeInput) : undefined;

        const newOrganisation: INewOrganisation = {
            name: nameInput,
            type: typeInput,
            assessment: assessmentInput,
            research: researchInput,
            pathwayConsent: safetyNetPathwayContextInput,
            lesionsLimit,
            minimumAge,
            guidance: guidanceInput,
            codeName,
            organisationEmail,
            nonSkinCancerAllowed,
            assessmentNsc: assessmentNscInput,
            researchNsc: researchNscInput,
            allowHomeInitiatedCases,
            mfaEnabled,
        };

        const requiredStrings = [nameInput];

        if (!requiredStrings.includes("") && typeInput !== null && validOrganisationEmail(organisationEmail || "")) {
            CreateOrganisationComponent.createNewOrganisation({
                newOrganisation,
                parentOrganisationUuid,
            })
                .then((result) => {
                    const { uuid } = result;
                    if (file) {
                        const formData = new FormData();
                        formData.append("image", file as Blob);
                        formData.append("organisationUuid", uuid);
                        organizationService.uploadOrganisationLogo(formData).then(() => {
                            this.setState({ submitting: false });
                            if (result.status === HttpStatus.ADDED) {
                                this.checkOverdueSettings(uuid);
                            }
                        });
                    } else {
                        this.checkOverdueSettings(uuid);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        this.setState({
                            errorDetails: err.response.data.errors,
                            isError: false,
                            emailInputError: false,
                            submitting: false,
                        });
                    } else {
                        this.setState({
                            errorDetails: [{ message: err.message }],
                            isError: false,
                            emailInputError: false,
                            submitting: false,
                        });
                    }
                });
        } else {
            this.setState({
                submitting: false,
                isError: true,
                emailInputError: !validOrganisationEmail,
            });
        }

        this.setState({ submitting: false });
    };

    private onLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        const fileSize = file.size;
        if (fileSize < MAX_LOGOFILE_SIZE) {
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    this.setState({ logoInput: { src: reader.result }, file });
                };
            }
        } else {
            this.setState({ logoUploadError: true, logoField: "" });
        }
    };

    private handleLogoRemoveClick = () => {
        this.setState({ logoInput: { src: "" }, file: undefined });
    };

    private onEnableDisableNonSkinCancer = (changeValue: boolean) => {
        const { nonSkinCancerAllowed, allowHomeInitiatedCases, checkboxDialogOrganisationProperty } = this.state;
        if (changeValue) {
            if (checkboxDialogOrganisationProperty === ALLOW_HOME_INITIATED_CASES_ID) {
                this.setState({ allowHomeInitiatedCases: !allowHomeInitiatedCases });
            }
            if (checkboxDialogOrganisationProperty === NON_SKIN_CANCER_ALLOWED_ID) {
                this.setState({ nonSkinCancerAllowed: !nonSkinCancerAllowed });
            }
        }
        this.setState({ isCheckboxDialogVisible: false });
        scrollBottom();
    };

    private redirectToNewOrganisation = (uuid: string) => {
        const locationUrl = `${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}/${OrganisationManagementSubpages.ORGANISATION_DETAILS}/${uuid}`;
        history.push(locationUrl);
    };

    private fetchParentLogo = async (parentUuid: string) => {
        const { logo } = await organizationService.getOrganisationByUuid(parentUuid);
        if (logo) {
            const organisationLogo = await organizationService.getOrganisationLogo(parentUuid);
            const file = await urlToFile(organisationLogo, "parentLogo.jpg", "image/png");
            this.setState({
                logoInput: { src: organisationLogo },
                file,
            });
        }
    };

    private checkOverdueSettings = async (uuid: string) => {
        const intervalId = setInterval(async () => {
            const organisation = await organizationService.getOrganisationByUuid(uuid);
            const { overdueDefinitions } = organisation;

            if (overdueDefinitions?.length > 1) {
                this.redirectToNewOrganisation(uuid);
            }
        }, CHECK_OVERDUE_SETTINGS_AVAILABLE_INTERVAL);
        this.setState({ intervalId: +intervalId });
    };

    private showCheckboxDialog = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        const fieldId = data.id;
        this.setState({ isCheckboxDialogVisible: true, checkboxDialogOrganisationProperty: fieldId.toString() });
    };

    private onMFAChange = (): void => {
        this.setState((prevState) => ({
            ...prevState,
            mfaEnabled: !prevState.mfaEnabled,
        }));
    };

    public render() {
        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        const {
            errorDetails,
            parentOrganisationUuid,
            isError,
            logoInput,
            emailInputError,
            nonSkinCancerAllowed,
            submitting,
            allowHomeInitiatedCases,
            logoUploadError,
            logoField,
            isCheckboxDialogVisible,
            checkboxDialogOrganisationProperty,
            [checkboxDialogOrganisationProperty as keyof typeof this.state]: doesCheckboxDialogOrgPropertyExist,
            mfaEnabled,
        } = this.state;

        // Workaround until backend will return uniform error form
        const nameError: any = errorDetails.find((err: any) => err.path === "name" || err.message.includes("Name"));
        const isSuborganisation = parentOrganisationUuid !== NONE_ORGANISATION_OPTION;
        const isLogoAdded = logoInput?.src;
        const popupFieldName =
            checkboxDialogOrganisationProperty === ALLOW_HOME_INITIATED_CASES_ID
                ? OrganisationPropertyLabels.ALLOW_HOME_INITIATED_CASES_LABEL
                : OrganisationPropertyLabels.NON_SKIN_CANCER_ALLOWED_LABEL;

        return (
            <Segment>
                <h2>Create new {isSuborganisation ? "suborganisation" : "organisation"}</h2>
                <Divider />
                <div>
                    <h5 hidden={!isError} className="error-message">
                        <p>Please fill all required fields</p>
                    </h5>
                    <div className={isError ? "container error" : "contaner"}>
                        <Form>
                            <Segment>
                                <h4>Organisation details </h4>
                                <Divider />
                                <Grid columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>{this.getParentOrganisationInput()}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div className="field">
                                                <span>Logo</span>
                                                {isLogoAdded && (
                                                    <>
                                                        <div className="organisation-logo-wrapper">
                                                            <Image
                                                                alt="Organisation logo"
                                                                src={logoInput.src}
                                                                size="small"
                                                            />
                                                            <CustomButton
                                                                type="button"
                                                                variant="dark"
                                                                action={this.handleLogoRemoveClick}
                                                                className="remove-organisation-logo"
                                                                text="Remove"
                                                            />
                                                        </div>
                                                        <p className="upload-logo-text">Select another logo image</p>
                                                    </>
                                                )}
                                                <Form.Input
                                                    type="file"
                                                    accept=".jpg, .jpeg, .png"
                                                    onChange={this.onLogoChange}
                                                    value={logoField}
                                                />
                                                {logoUploadError && (
                                                    <Label basic color="red" pointing="above">
                                                        Submitted image size is too big (&gt; 2MB)
                                                    </Label>
                                                )}
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                error={nameError ? nameError.message : false}
                                                label="Name"
                                                type="text"
                                                id="nameInput"
                                                required
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Select
                                                label="Type"
                                                id="typeInput"
                                                placeholder="Select organisation type"
                                                required
                                                onChange={this.onFieldChange}
                                                options={Object.keys(OrganisationType).map((key) => ({
                                                    key,
                                                    text: OrganisationType[key],
                                                    value: key,
                                                }))}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label={OrganisationPropertyLabels.LESION_LIMIT}
                                                type="number"
                                                id={OrganisationPropertyIds.LESION_LIMIT}
                                                required={!isSuborganisation}
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column id="assessment">
                                            <div className={`${!isSuborganisation ? "required field" : "field"}`}>
                                                <span>Assessment Consent</span>
                                            </div>
                                            <CustomRichTextEditor
                                                onChange={this.onRichTextEditorChanged}
                                                name="assessmentInput"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column id="research">
                                            <div className={`${!isSuborganisation ? "required field" : "field"}`}>
                                                <span>Research Consent</span>
                                            </div>
                                            <CustomRichTextEditor
                                                onChange={this.onRichTextEditorChanged}
                                                name="researchInput"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column id="guidance">
                                            <div className={`${!isSuborganisation ? "required field" : "field"}`}>
                                                <span>Guidance</span>
                                            </div>
                                            <CustomRichTextEditor
                                                onChange={this.onRichTextEditorChanged}
                                                name="guidanceInput"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column id="pathwayConsent">
                                            <div className={`${!isSuborganisation ? "required field" : "field"}`}>
                                                <span>Safety Net pathway Context</span>
                                            </div>
                                            <CustomRichTextEditor
                                                onChange={this.onRichTextEditorChanged}
                                                name="safetyNetPathwayContextInput"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label={OrganisationPropertyLabels.MINIMUM_AGE}
                                                type="number"
                                                id={OrganisationPropertyIds.MINIMUM_AGE}
                                                onChange={this.onFieldChange}
                                                min={MIN_POSITIVE_NUMBER}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                error={emailInputError ? NOT_VALID_EMAIL_ERROR : null}
                                                label="Organisation Email"
                                                type="email"
                                                id="organisationEmail"
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <OrganisationSettingLabel
                                                label={OrganisationPropertyLabels.MFA}
                                                helpText={OrganisationPropertyHelpText.MFA}
                                                htmlFor={OrganisationPropertyIds.ORGANISATION_MFA_CREATE}
                                            />
                                            <Form.Checkbox
                                                readonly={false}
                                                checked={mfaEnabled}
                                                toggle
                                                id={OrganisationPropertyIds.ORGANISATION_MFA_CREATE}
                                                onChange={this.onMFAChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {!isSuborganisation && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <OrganisationSettingLabel
                                                    label={OrganisationPropertyLabels.ALLOW_HOME_INITIATED_CASES_LABEL}
                                                />
                                                <Form.Checkbox
                                                    checked={allowHomeInitiatedCases}
                                                    toggle
                                                    id={ALLOW_HOME_INITIATED_CASES_ID}
                                                    onChange={this.showCheckboxDialog}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                error={nameError ? nameError.message : false}
                                                label="Code Name"
                                                type="text"
                                                id="codeName"
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {!isSuborganisation && (
                                        <Grid.Row>
                                            <Grid.Column>
                                                <OrganisationSettingLabel
                                                    label={OrganisationPropertyLabels.NON_SKIN_CANCER_ALLOWED_LABEL}
                                                />
                                                <Form.Checkbox
                                                    checked={nonSkinCancerAllowed}
                                                    toggle
                                                    id={NON_SKIN_CANCER_ALLOWED_ID}
                                                    onChange={this.showCheckboxDialog}
                                                    disabled={false}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    <Grid.Row>
                                        <Grid.Column id="assessmentNsc">
                                            <div className="field">
                                                <span>Assessment Consent for other skin conditions</span>
                                            </div>
                                            <CustomRichTextEditor
                                                onChange={this.onRichTextEditorChanged}
                                                name="assessmentNscInput"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column id="researchNsc">
                                            <div className="field">
                                                <span>Research Consent for other skin conditions</span>
                                            </div>
                                            <CustomRichTextEditor
                                                onChange={this.onRichTextEditorChanged}
                                                name="researchNscInput"
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form>

                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>{this.getErrorMessages()}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={9}>
                                    <CustomButton
                                        type="submit"
                                        variant="filled"
                                        loading={submitting}
                                        floated="right"
                                        action={this.submit}
                                        text="Create"
                                    />
                                    {this.getBackButton()}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
                <SkinConditionsPathwayPopup
                    showDialog={isCheckboxDialogVisible}
                    fieldName={popupFieldName}
                    isPropertyEnabling={!doesCheckboxDialogOrgPropertyExist}
                    callback={this.onEnableDisableNonSkinCancer}
                />
            </Segment>
        );
    }
}

export default CreateOrganisationComponent;
