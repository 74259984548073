import { createTheme } from "@material-ui/core/styles";
import shadows from "@material-ui/core/styles/shadows";

import theme from "scss/baseTheme.module.scss";

/**
 * Required to make tests work as theme returns empty object when mui palette is defined.
 * Not required for theme variables in overrides
 */
const baseTheme: any = { primaryColour: "#7cd8d1", secondaryColour: "#e0f3f2", warningColor: "#ffb020", ...theme };

const MuiTheme = createTheme({
    palette: {
        primary: {
            main: baseTheme.primaryColour,
        },
        secondary: {
            main: baseTheme.secondaryColour,
        },
        warning: {
            main: baseTheme.warningColor,
        },
    },
    props: {
        MuiButton: {
            disableRipple: true,
        },
        MuiIconButton: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                "fontSize": "16px",
                "minHeight": "40px",
                "padding": `12px ${baseTheme.spacerL}`,
                "lineHeight": "16px",
                "borderRadius": "6px",
                "fontWeight": 600,
                "&.Mui-focusVisible": {
                    backgroundColor: "unset",
                },
                "textTransform": "capitalize",
                "letterSpacing": "4%",
            },
            contained: {
                boxShadow: "unset",
            },
            containedPrimary: {
                "&:hover, &:active, &:focus": {
                    backgroundColor: `${baseTheme.primaryColour}`,
                },
            },
            containedSecondary: {
                "&:hover, &:active, &:focus": {
                    boxShadow: `${baseTheme.secondaryColour}`,
                },
            },
            outlinedSecondary: {
                "&:hover, &:active, &:focus": {
                    boxShadow: shadows[4],
                },
            },
        },
        MuiTableCell: {
            root: {
                fontSize: "14px",
                fontWeight: 400,
            },
            head: {
                border: "0",
                fontWeight: 800,
            },
            body: {
                border: "0",
            },
        },
    },
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif",
        ].join(","),
    },
});

export default MuiTheme;
