import { FC } from "react";
import { Button, Grid, Message } from "semantic-ui-react";

interface ISaveAllocation {
    loading: boolean;
    isNew: boolean;
    isChanged: boolean;
    callback: () => Promise<void>;
}

const SaveAllocation: FC<ISaveAllocation> = ({ loading, isNew, isChanged, callback }) => {
    if (!isNew && !isChanged) {
        return null;
    }

    return (
        <>
            <Grid.Column mobile={16} tablet={16} computer={14}>
                <Message negative>
                    The displayed configuration has not been saved yet, please make sure the correct allocation and
                    priority are set and save it before moving to another tab.
                </Message>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
                <Button floated="right" content="Save" onClick={callback} loading={loading} />
            </Grid.Column>
        </>
    );
};

export default SaveAllocation;
