import { DermatologistAvailabilityEntities, DermatologistAvailabilityMapping } from "model/administrationPages";

const getAvailability = (availability: number): string => {
    /**
     * OV2 currently allows a user to set availability above 0 - however, there are no users
     * with an availability above 1 in PROD
     */
    if (availability > 1) {
        return DermatologistAvailabilityEntities.AVAILABLE_FOR_CASE_ALLOCATION;
    }
    const availabilityKey = Object.keys(DermatologistAvailabilityMapping).find(
        (key) => DermatologistAvailabilityMapping[key] === availability
    );
    return availabilityKey;
};

export default getAvailability;
