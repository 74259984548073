import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";

import { HOME } from "navigation/routes";

const CaseBottomPanel: FC = () => (
    <Grid className="case-description-bottom-panel">
        <Grid.Row centered>
            <Grid.Column width="2">
                <Button as={Link} to={HOME} color="teal" floated="right" className="ui button empty-button small">
                    Home
                </Button>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default CaseBottomPanel;
