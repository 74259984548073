import React, { FC } from "react";
import { Segment } from "semantic-ui-react";
import clsx from "clsx";

export const mainSegmentId = "mainSegment";

interface IPageWrapper {
    isRemoteModel: boolean;
    isInitialRemoteModelPage: boolean;
    isTestPage?: boolean;
}

const PageWrapper: FC<IPageWrapper> = ({ children, isRemoteModel, isInitialRemoteModelPage, isTestPage }) => (
    <>
        {isRemoteModel ? (
            <div className="main-segment remote-model">{children}</div>
        ) : (
            <Segment
                id={mainSegmentId}
                className={clsx(
                    "main-segment",
                    isInitialRemoteModelPage && "main-segment__empty",
                    isTestPage ? "test-page" : ""
                )}
            >
                {children}
            </Segment>
        )}
    </>
);

export default PageWrapper;
