import React, { Component, ReactNode } from "react";
import { Divider, Form, Grid, Segment } from "semantic-ui-react";
import InfoIcon from "@material-ui/icons/Info";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Button } from "@material-ui/core";

import { ORGANISATION_INHERITANCE_PROPERTY } from "components/Administration/OrganisationManagment/ViewOrganisation/OrganisationDetailsScreen";
import StickyOrganisationTopPanel from "components/Administration/OrganisationManagment/ViewOrganisation/StickyOrganisationTopPanel";
import TemplateRow from "components/Administration/OrganisationManagment/ViewOrganisation/Templates/TemplateRow";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";
import SupportedMarkers from "components/templates/SupportedMarkers";
import AdminNextStepsFields from "components/Administration/OrganisationManagment/ViewOrganisation/Templates/AdminNextStepsFields";
import CustomTooltip from "components/templates/CustomTooltip";
import ModalDialog from "components/templates/dialog/ModalDialog";

import { scrollBottom } from "helpers/page";

import { OrganisationManagementTabs, OrganisationMenuItems } from "model/administrationPages";
import { IOrganisation, IStructuredTemplateField, ITemplate, ITemplateField } from "model/organisation";
import { TemplateSchema, TemplateType, WhatHappensNextTemplateKeys } from "model/templateType";

import organizationService from "services/organizationService";

import { markersDefinitionTemplates } from "util/markerFiller";

import MuiTheme from "Theme/MuiTheme";
import "scss/AdminPanel.scss";

interface IEditHistoryQuestionComponentProps {
    organisation: IOrganisation;
    showEditButton: boolean;
    updateInheritanceData: (property: string) => void;
}

interface ITemplateComponentState {
    createMode: boolean;
    newTemplate: any;
    organisation: IOrganisation;
    templates: ITemplate[];
    templateType: TemplateType | undefined;
    showConfirmationModal: boolean;
    isFormValid: boolean;
}

class TemplatesComponent extends Component<IEditHistoryQuestionComponentProps, ITemplateComponentState> {
    constructor(props: any) {
        super(props);

        const { organisation } = this.props;

        const activeTemplates = organisation?.templates?.filter((template) => !template.removed) || [];

        this.state = {
            createMode: false,
            newTemplate: {},
            organisation: props.organisation,
            templateType: undefined,
            templates: activeTemplates,
            showConfirmationModal: false,
            isFormValid: true,
        };
    }

    private getTemplates() {
        const { templates, organisation } = this.state;
        const setTemplate = (templateRow: ITemplate): void => {
            const otherTemplates = templates.filter((template) => template.uuid !== templateRow.uuid);
            this.setState({ templates: [...otherTemplates, templateRow] });
        };

        return templates?.map((template: ITemplate) => (
            <TemplateRow
                key={template.uuid}
                organisation={organisation}
                template={template}
                onRemove={this.onRemoveRow}
                setTemplate={(templateRow) => setTemplate(templateRow)}
            />
        ));
    }

    private getTemplatesAvailableToCreate() {
        const { templates } = this.state;
        const createdTemplates = templates?.map((template) => template.name) || [];
        return Object.keys(TemplateType).filter((type) => !createdTemplates.includes(type));
    }

    private getNewTemplateField(fieldName: string | WhatHappensNextTemplateKeys): ReactNode {
        const { newTemplate } = this.state;

        return (
            <Grid.Row>
                <Grid.Column columns={2} width={16}>
                    <Form>
                        <Form.Input
                            label={fieldName}
                            id={fieldName}
                            value={newTemplate[fieldName]}
                            onChange={this.onNewTemplateFieldChange}
                        />
                    </Form>
                </Grid.Column>
            </Grid.Row>
        );
    }

    private changeMode = () => {
        const { createMode } = this.state;

        this.setState({ createMode: !createMode });

        if (!createMode) {
            scrollBottom();
        }
    };

    private onNewTemplateFieldChange = (event: any, obj: any) => {
        const { newTemplate } = this.state;
        newTemplate[obj.id] = obj.value;
        this.setState({ newTemplate });
    };

    private onTemplateChange = (event: any, obj: any) => {
        if (obj.value === TemplateType.ADMINISTRATIVE_NEXT_STEPS) {
            const selectedNode = document.getElementById("templateInput");
            this.setState({ showConfirmationModal: true });
            if (selectedNode) {
                selectedNode.blur();
            }
            this.setState({ templateType: obj.value });
        } else {
            this.setState({ templateType: obj.value });
        }
    };

    private onCreateNewTemplate = (): void => {
        const { templates, organisation } = this.state;
        organizationService
            .createTemplate(this.prepareNewTemplateObject(), organisation.uuid)
            .then((response) => {
                templates.push(response);
                this.setState(() => ({
                    newTemplate: { fields: [] },
                    templateType: undefined,
                    templates,
                }));
                this.changeMode();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    private onRemoveRow = (templateUuid) => {
        const { templates } = this.state;
        const filteredTemplates = templates.filter((item: any) => item.uuid !== templateUuid);
        this.setState({
            templates: filteredTemplates,
            templateType: undefined,
        });
    };

    private updateOrganisation = (result: any) => {
        const { updateInheritanceData } = this.props;

        const { templates } = result;
        const updatedTemplates = templates.filter((template: any) => !template.removed);

        this.setState({ templates: updatedTemplates });
        updateInheritanceData(ORGANISATION_INHERITANCE_PROPERTY[OrganisationMenuItems.TEMPLATES]);
    };

    private newTemplate() {
        const { templateType, newTemplate, isFormValid } = this.state;

        let TemplateFields: ReactNode[] = [];
        const isAdministrativeNextSteps = templateType === TemplateType.ADMINISTRATIVE_NEXT_STEPS;

        if (templateType && !isAdministrativeNextSteps) {
            TemplateFields = TemplateSchema[templateType].map((field: string) => this.getNewTemplateField(field));
        }

        return (
            templateType && (
                <>
                    <Divider />
                    <Segment>
                        <Grid>
                            {!isAdministrativeNextSteps && TemplateFields}
                            {isAdministrativeNextSteps && (
                                <Grid.Row width={16}>
                                    <Grid.Column width={6}>
                                        <Form.Field label="Question" style={{ fontWeight: "bold" }} />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Form.Field label="Question type" style={{ fontWeight: "bold" }} />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            {isAdministrativeNextSteps && (
                                <AdminNextStepsFields
                                    fields={newTemplate.fields?.length ? newTemplate.fields : undefined}
                                    setFields={(newFields, callBack) =>
                                        this.setState({ newTemplate: { ...newTemplate, fields: newFields } }, callBack)
                                    }
                                    validateParentForm={(boolean) => this.setState({ isFormValid: boolean })}
                                    createNewTemplate={this.onCreateNewTemplate}
                                />
                            )}
                            {templateType !== TemplateType.ADMINISTRATIVE_NEXT_STEPS && (
                                <Grid.Row style={{ margin: "10px 0 20px" }}>
                                    <Grid.Column width={8} textAlign="right" style={{ paddingRight: "5px" }}>
                                        <Button
                                            onClick={() => {
                                                this.setState({ templateType: undefined });
                                                this.changeMode();
                                            }}
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Cancel
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column width={8} textAlign="left" style={{ paddingLeft: "5px" }}>
                                        <Button
                                            variant="contained"
                                            onClick={this.onCreateNewTemplate}
                                            color="primary"
                                            disabled={isAdministrativeNextSteps && !isFormValid}
                                        >
                                            Create
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                    </Segment>
                </>
            )
        );
    }

    private addNewTemplateForm() {
        const { templateType, showConfirmationModal } = this.state;

        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Form>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Form.Select
                                        width={8}
                                        label="Template type"
                                        id="templateInput"
                                        placeholder="Select template type"
                                        defaultValue={undefined}
                                        value={templateType}
                                        onChange={this.onTemplateChange}
                                        options={this.getTemplatesAvailableToCreate().map((key) => ({
                                            key,
                                            text: TemplateType[key],
                                            value: key,
                                        }))}
                                    />
                                    {templateType === TemplateType.ADMINISTRATIVE_NEXT_STEPS && (
                                        <CustomTooltip
                                            style={{ marginLeft: "8px" }}
                                            placement="top"
                                            backgroundColour={MuiTheme.palette.secondary.main}
                                            textColour="#000"
                                            title="With the creation of this template all dermatologists in this organisation will be able to add in a structured way the next steps for each individual patient. Please make sure to agree with the customer which fields they need before setting them up."
                                        >
                                            <InfoIcon />
                                        </CustomTooltip>
                                    )}
                                </div>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.newTemplate()}
                <ModalDialog
                    title="Check the structure of this template with the customer
"
                    open={showConfirmationModal}
                    iconComponent={ErrorOutlineOutlinedIcon}
                    onClose={() => this.setState({ showConfirmationModal: false })}
                    maxWidth="sm"
                    disableBackdropClick
                    buttons={[
                        {
                            onClick: () => this.setState({ showConfirmationModal: false }),
                            text: "Understood",
                            colour: "secondary",
                        },
                    ]}
                >
                    <Grid className="grid-dialog-body">
                        <Grid.Row>
                            <Grid.Column>
                                Before creating this template, make sure you have agreed with the customer the fields
                                they need as once created, the changes will be reflected automatically.
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </ModalDialog>
            </div>
        );
    }

    private prepareNewTemplateObject(): ITemplate {
        const { templateType, newTemplate } = this.state;

        let templateFields: (IStructuredTemplateField | ITemplateField)[] = [];
        const isStructured = templateType === TemplateType.ADMINISTRATIVE_NEXT_STEPS;

        if (isStructured) {
            templateFields = newTemplate.fields;
        } else {
            delete newTemplate.fields;
            templateFields = Object.entries(newTemplate).map(([key, value]) => ({
                content: value as string,
                name: key as string,
            }));
        }

        return {
            fields: templateFields,
            name: templateType,
            copyOfUuid: "",
            isStructured,
        };
    }

    public render() {
        const { createMode } = this.state;

        const { organisation, showEditButton } = this.props;

        return (
            <Segment>
                <StickyOrganisationTopPanel title="Templates in organisation">
                    <OrganisationManagementTabHeader
                        organisationUuid={organisation ? organisation.uuid : ""}
                        type={OrganisationManagementTabs.TEMPLATES}
                        showEditButton={showEditButton}
                        updateOrganisation={this.updateOrganisation}
                        createMode={createMode}
                        changeMode={this.changeMode}
                    />
                </StickyOrganisationTopPanel>
                <SupportedMarkers data={markersDefinitionTemplates} />
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>{this.getTemplates()}</Grid.Column>
                    </Grid.Row>
                </Grid>
                {createMode && (
                    <>
                        <h3>Create new template</h3>
                        <Divider />
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>{this.addNewTemplateForm()}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </>
                )}
            </Segment>
        );
    }
}

export default TemplatesComponent;
