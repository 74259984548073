import { FC } from "react";
import { Sticky } from "semantic-ui-react";

import PatientBanner from "components/templates/PatientBanner";

import { IPatient } from "model/assessment";
import { IOrganisation } from "model/organisation";

import "scss/PatientBanner.scss";

interface IStickyPatientInformation {
    caseId: string;
    patient: IPatient;
    aboveChilds?: any;
    nonSkinCancerFlow?: boolean;
    patientOrganisation?: IOrganisation;
}

const StickyPatientInformation: FC<IStickyPatientInformation> = ({
    patient,
    caseId,
    aboveChilds,
    nonSkinCancerFlow,
    patientOrganisation,
}) => (
    <Sticky offset={59}>
        <div className="background-white">
            <div className="banner-padding">
                {aboveChilds}
                <PatientBanner
                    caseId={caseId}
                    patient={patient}
                    nonSkinCancerFlow={nonSkinCancerFlow}
                    patientOrganisation={patientOrganisation}
                />
            </div>
        </div>
    </Sticky>
);

StickyPatientInformation.defaultProps = {
    aboveChilds: null,
    nonSkinCancerFlow: false,
    patientOrganisation: null,
};

export default StickyPatientInformation;
