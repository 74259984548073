import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import LoadingSpinner from "components/templates/LoadingSpinner";

import * as caseService from "services/caseService";

class CaseViewReport extends Component<RouteComponentProps<{ id: string }>, any> {
    public constructor(props: any) {
        super(props);
        this.state = {
            reportUrl: "",
        };
    }

    public componentDidMount() {
        this.getReportUrl();
    }

    public getReportUrl() {
        const { match } = this.props;
        const { reportUrl } = this.state;
        const { id } = match.params;

        if (!reportUrl) {
            caseService.getReportUrl(id).then((url) => {
                this.setState({ reportUrl: `${url}#toolbar=0&navpanes=0&scrollbar=0` });
            });
        }
    }

    public render() {
        const { reportUrl } = this.state;

        if (reportUrl) {
            return (
                <object
                    data={reportUrl}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                    name="report"
                    aria-label="report pdf"
                />
            );
        }

        return <LoadingSpinner />;
    }
}

export default withRouter(CaseViewReport);
