import React, { FC, useState, FormEvent } from "react";
import { Form, Table, Button, InputOnChangeData, CheckboxProps } from "semantic-ui-react";

import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";

import { CaseStatusDictionary } from "model/caseStatus";
import { IOverdueUpdate } from "model/organisation";

import organizationService from "services/organizationService";

interface IOverdueSettings {
    overdueStatus: string;
    overdueHours: number;
    overdueMinutes: number;
    overdueEnabled: boolean;
    uuid: string;
    onSave: (overdueUpdate: IOverdueUpdate & { uuid: string }) => void;
    inheritedFromParent?: boolean;
    hasParent?: boolean;
    organisationUuid: string;
}

const DECIMAL = 10;
const MINUTES_IN_HOUR = 60;

const OverdueTableRow: FC<IOverdueSettings> = ({
    overdueStatus,
    overdueHours,
    overdueMinutes,
    overdueEnabled,
    uuid,
    onSave,
    inheritedFromParent,
    hasParent,
    organisationUuid,
}) => {
    const [editMode, setEditMode] = useState(false);

    const [overdue, setOverdue] = useState({
        status: overdueStatus,
        hours: overdueHours,
        minutes: overdueMinutes,
        enabled: overdueEnabled,
    });

    const [submitting, setSubmitting] = useState(false);

    const { status, hours, minutes, enabled } = overdue;

    const handleEditMode = () => setEditMode(!editMode);

    const updateSetting = (overdueSetting: Omit<IOverdueUpdate, "organisationUuid">) =>
        organizationService.updateOverdueSetting(uuid, {
            ...overdueSetting,
            organisationUuid,
        });

    const onFieldChange = async (event: FormEvent<HTMLInputElement>, data: InputOnChangeData | CheckboxProps) => {
        const { name, value, type, checked } = data;
        const trimmedNumber = (numb: any) => parseInt(String(numb), DECIMAL);

        const inputNumber =
            name !== "hours" && trimmedNumber(value) > MINUTES_IN_HOUR ? MINUTES_IN_HOUR : trimmedNumber(value);

        const updatedValue = type === "checkbox" ? checked : inputNumber;

        setOverdue({ ...overdue, [name]: updatedValue });

        if (type === "checkbox") {
            const updatedData = {
                overdueHours: hours,
                overdueMinutes: minutes,
                enabled: updatedValue,
            };
            await updateSetting(updatedData);
            onSave({
                ...updatedData,
                organisationUuid,
                uuid,
            });
        }
    };

    const onTimeSave = () => {
        setSubmitting(true);
        updateSetting({
            overdueHours: hours,
            overdueMinutes: minutes,
            enabled,
        }).then(() => {
            handleEditMode();
            onSave({
                overdueHours: hours,
                overdueMinutes: minutes,
                enabled,
                organisationUuid,
                uuid,
            });
            setSubmitting(false);
        });
    };

    return (
        <Table.Row className="overdue-row">
            <Table.Cell width={2}>
                {hasParent ? (
                    <SettingsPropertyWrapper inheritedFromParent={inheritedFromParent}>
                        {CaseStatusDictionary[status]}
                    </SettingsPropertyWrapper>
                ) : (
                    `${CaseStatusDictionary[status]}`
                )}
            </Table.Cell>
            <Table.Cell width={3}>
                {editMode ? (
                    <>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                    name="hours"
                                    className="normal"
                                    value={hours}
                                    onChange={onFieldChange}
                                    size="small"
                                    width={6}
                                    type="number"
                                    step={1}
                                    min={1}
                                    max={60}
                                />
                                h :
                                <Form.Input
                                    name="minutes"
                                    className="normal"
                                    value={minutes}
                                    onChange={onFieldChange}
                                    size="small"
                                    width={6}
                                    type="number"
                                    step={1}
                                    min={1}
                                    max={60}
                                />
                                m
                            </Form.Group>
                        </Form>

                        <Button
                            loading={submitting}
                            size="mini"
                            className="overdue-button"
                            onClick={onTimeSave}
                            disabled={!String(hours).length || !String(minutes).length}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <>
                        <div>
                            {hours === 0 ? `0${hours}` : hours}h : {minutes < 10 ? `0${minutes}` : minutes}m
                        </div>
                        <Button size="mini" className="overdue-button" onClick={handleEditMode}>
                            Edit
                        </Button>
                    </>
                )}
            </Table.Cell>
            <Table.Cell width={2}>
                <div className="overdue-checkbox">
                    <Form.Checkbox
                        name="enabled"
                        checked={enabled}
                        onChange={onFieldChange}
                        label="Overdue enabled"
                        disabled={String(hours) === "0" && String(minutes) === "0"}
                    />
                </div>
            </Table.Cell>
        </Table.Row>
    );
};

OverdueTableRow.defaultProps = {
    inheritedFromParent: false,
    hasParent: false,
};

export default OverdueTableRow;
