import { connect } from "react-redux";

import LoginScreen from "components/Login/LoginScreen";

import * as authorizationService from "services/authorizationService";

const mapStateToProps = (state: any) => ({
    changePasswordCompleted: state.auth.changePasswordCompleted,
    errorInfo: state.validate,
    signedIn: state.auth.signedIn,
    userError: state.data.userError,
    visible: state.validate.visible,
});

const mapDispatchToProps = {
    clearPassword: authorizationService.clearPasswordChange,
    initResetPassword: authorizationService.initPasswordReset,
    login: authorizationService.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
