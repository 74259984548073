import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { TransformWrapper } from "react-zoom-pan-pinch";

import ReportIncorrectClassification from "components/CaseDescription/ReportIncorrectClassification";
import ReportIncorrectClassificationModal from "components/ReportIncorrectClassificationModal";
import ConditionalRender from "components/templates/ConditionalRender";
import LesionImagePanZoom from "components/templates/LesionImagePanZoom";

import {
    mapAnswerToFSTMarkup,
    getFitzpatrickQuestionAnswers,
    getSkinTypeClassificationAnswer,
} from "helpers/fitzpatrick";
import { getSkinToneClassificationByAssigneeUuid } from "helpers/patientUtils";
import { getCaseQuestionDefinition } from "helpers/questionDefinition";

import { ICase, ISkinToneAnswer } from "model/case";
import CaseQuestionDefinitionType from "model/caseQuestionDefinitionType";
import IClickOrigin from "model/clickOrigin";
import { IImagePreview } from "model/imagePreview";
import { IMedicalHistoryQuestion } from "model/organisation";
import { UserRole } from "model/userRole";

import userService from "services/userService";

const MAX_SCALE = 8; // Matches the default scale set by react-zoom-pan-pinch
const SMOOTH_STEP = 0.002; // Double the default of 0.001 set by react-zoom-pan-pinch

export interface Size {
    width: number;
    height: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipAnswer: {
            padding: "8px 0px",
        },
        dialog: {
            "& .MuiDialog-paper": {
                // Ensure the modal fills the viewport
                minWidth: "calc(100vw - 64px)",
                maxWidth: "calc(100vw - 64px)",
                minHeight: "calc(100vh - 64px)",
                maxHeight: "calc(100vh - 64px)",
                // full screen at heights other above "sm"
                [theme.breakpoints.down("sm")]: {
                    width: "100vw",
                    height: "100vh",
                },
            },
        },
        expandIcon: {
            display: "inline",
            height: "50px",
            width: "50px",
        },
        skinColourChip: {
            "display": "inline-flex",
            "alignItems": "center",
            "position": "absolute",
            "top": "2.5%",
            "left": "2.5%",
            "zIndex": 100,
            "borderRadius": "10px",
            "boxShadow": theme.shadows[4],
            "color": "black",
            "margin": "8px",
            "padding": "4px 12px",

            "&::before": {
                position: "absolute",
                top: "0",
                left: "0",
                content: '""',
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                padding: "4px 12px",
                backgroundColor: theme.palette.primary.main,
                opacity: 0.3,
                zIndex: -1,
            },
        },
        skinToneClassificationCollapsed: {
            display: "flex",
            alignItems: "center",
        },
    })
);

interface ILesionImagePreviewProps {
    resizedImages: IImagePreview[];
    fullSizeImages: IImagePreview[];
    imageUuid: string;
    showModal: boolean;
    caseObject?: ICase;
    onClose: () => void;
    setActiveImage: React.Dispatch<React.SetStateAction<string>>;
    updateSkinToneClassification?: (result: ISkinToneAnswer[]) => void;
}

const LesionImagePreview: FC<ILesionImagePreviewProps> = ({
    resizedImages,
    fullSizeImages,
    imageUuid,
    showModal,
    caseObject,
    onClose,
    setActiveImage,
    updateSkinToneClassification,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    // Fitzpatrick state
    const [reportClassificationChipExpanded, setReportClassificationChipExpanded] = useState<boolean>(false);
    const [showReportClassificationModal, setShowReportClassificationModal] = useState<boolean>(false);
    const [questionDefinitions, setQuestionDefinitions] = useState<IMedicalHistoryQuestion[]>(undefined);

    // Responsiveness - internal state
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const [containerSize, setContainerSize] = useState<Size>({ width: 0, height: 0 });

    const [imageNaturalSize, setImageNaturalSize] = useState<Size>({ width: 0, height: 0 });

    // Store variables pertinent to panning
    const [isDragging, setIsDragging] = useState<boolean>(false);

    // Responsiveness - handlers
    const handleImageOnLoad = (image: HTMLImageElement): void => {
        setImageNaturalSize({
            width: image.naturalWidth,
            height: image.naturalHeight,
        });
    };

    const imageScale = useMemo(() => {
        const { width: containerWidth, height: containerHeight } = containerSize;
        const { width: imageNaturalWidth, height: imageNaturalHeight } = imageNaturalSize;
        if (containerWidth === 0 || containerHeight === 0) {
            return 0;
        }

        const scale = Math.min(containerWidth / imageNaturalWidth, containerHeight / imageNaturalHeight);
        return Math.max(scale, 1);
    }, [containerSize, imageNaturalSize]);

    const handleResize = useCallback(() => {
        if (container !== null) {
            const rect = container.getBoundingClientRect();
            setContainerSize({
                width: rect.width,
                height: rect.height,
            });
        } else {
            setContainerSize({
                width: 0,
                height: 0,
            });
        }
    }, [container]);

    useEffect(() => {
        getCaseQuestionDefinition(CaseQuestionDefinitionType.MEDICAL, caseObject?.organisationUuid).then(
            (response: IMedicalHistoryQuestion[]) => setQuestionDefinitions(response)
        );
    }, []);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (!showModal) {
        return null;
    }

    const { fitzpatrickQuestionAnswers } = getFitzpatrickQuestionAnswers(
        caseObject?.patient?.medicalHistory,
        questionDefinitions
    );

    const fitzpatrickAnswer = getSkinTypeClassificationAnswer(fitzpatrickQuestionAnswers, caseObject);
    const currentUserUuid = userService.getCurrentUserUuid();
    const skinToneClassification = getSkinToneClassificationByAssigneeUuid(caseObject?.patient, currentUserUuid);
    const isCaseAssignedToCurrentUser = caseObject?.assignmentDetails?.assigneeUuid === currentUserUuid;
    const showToCurrentUser = isCaseAssignedToCurrentUser || !!skinToneClassification;

    const closeImagePreview = (): void => {
        onClose();
        setReportClassificationChipExpanded(false);
    };

    return (
        <>
            <Dialog
                className={classes.dialog}
                open={showModal}
                fullScreen={fullScreen}
                fullWidth={false}
                onClose={closeImagePreview}
            >
                {fitzpatrickAnswer ? (
                    <ConditionalRender requiredRole={[UserRole.DERMATOLOGIST, UserRole.SUPERADMIN]}>
                        <div className={classes.skinColourChip}>
                            {reportClassificationChipExpanded ? (
                                <div>
                                    <div>
                                        <div className={classes.chipAnswer}>
                                            <p>{fitzpatrickAnswer}</p>
                                        </div>

                                        <ReportIncorrectClassification
                                            fitzpatrickAnswer={fitzpatrickAnswer}
                                            skinToneClassification={skinToneClassification}
                                            showModal={setShowReportClassificationModal}
                                            showToCurrentUser={showToCurrentUser}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={classes.skinToneClassificationCollapsed}>
                                    <p>{mapAnswerToFSTMarkup(fitzpatrickAnswer)}</p>
                                </div>
                            )}

                            <IconButton
                                className={classes.expandIcon}
                                aria-label="Expand skin tone classification chip"
                                onClick={() => setReportClassificationChipExpanded(!reportClassificationChipExpanded)}
                            >
                                {reportClassificationChipExpanded ? (
                                    <KeyboardArrowLeftIcon />
                                ) : (
                                    <KeyboardArrowRightIcon />
                                )}
                            </IconButton>
                        </div>
                    </ConditionalRender>
                ) : null}
                <TransformWrapper
                    centerOnInit
                    disablePadding
                    onPanningStart={() => setIsDragging(true)}
                    onPanningStop={() => setIsDragging(false)}
                    panning={{ velocityDisabled: true }}
                    wheel={{ smoothStep: SMOOTH_STEP }}
                    initialScale={imageScale || 1}
                    minScale={imageScale || 1}
                    maxScale={imageScale * MAX_SCALE || 8}
                >
                    <LesionImagePanZoom
                        resizedImages={resizedImages}
                        fullSizeImages={fullSizeImages}
                        imageUuid={imageUuid}
                        isDragging={isDragging}
                        onClose={closeImagePreview}
                        setActiveImage={setActiveImage}
                        handleImageOnLoad={handleImageOnLoad}
                        setContainer={setContainer}
                    />
                </TransformWrapper>
            </Dialog>

            <ReportIncorrectClassificationModal
                caseObject={caseObject}
                fitzpatrickQuestionAnswers={fitzpatrickQuestionAnswers}
                showModal={showReportClassificationModal}
                closeModal={() => setShowReportClassificationModal(false)}
                clickOrigin={IClickOrigin.IMAGE_VIEWER}
                updateSkinToneClassification={updateSkinToneClassification}
            />
        </>
    );
};

export default LesionImagePreview;
