import { FC } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core";

import { mapAnswerToMarkup } from "helpers/fitzpatrick";

import { FitzpatrickAnswersType } from "model/fixedQuestion";

const useStyles = makeStyles(() =>
    createStyles({
        emphasis: {
            "color": "#394150",
            "fontWeight": 500,
            "marginBottom": "0px",

            "&.tan-profile": {
                color: "#000000",
                opacity: "0.6",
                fontWeight: 400,
            },
        },
    })
);

interface ISkinTypeBurnAndTanProfile {
    fitzpatrickAnswer: FitzpatrickAnswersType;
    text?: string;
}

const SkinTypeBurnAndTanProfile: FC<ISkinTypeBurnAndTanProfile> = ({ fitzpatrickAnswer }) => {
    const classes = useStyles();
    const { BURN_PROFILE, TAN_PROFILE } = mapAnswerToMarkup(fitzpatrickAnswer);

    return (
        <div>
            <p className={classes.emphasis}>{BURN_PROFILE}</p>
            <p className={clsx(classes.emphasis, "tan-profile")}>{TAN_PROFILE}</p>
        </div>
    );
};

export default SkinTypeBurnAndTanProfile;
