import { connect } from "react-redux";

import InitialDiagnosis from "components/InitialDiagnosis/InitialDiagnosis";

import captureImageWizardService from "services/captureImageWizardService";
import * as diagnosisService from "services/diagnosisService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    caseUuid: state.assessment.case.uuid,
    lesionUuid: state.assessment.lesion.uuid,
    studyUuid: state.assessment.study.uuid,
});

const mapDispatchToProps = {
    createDiagnosis: diagnosisService.createDiagnosis,
    resetCaptureImageWizzard: captureImageWizardService.resetWizard,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialDiagnosis);
