import React, { ReactNode, useState, useEffect } from "react";
import { Form, Grid } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { Button } from "@material-ui/core";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

import ModalDialog from "components/templates/dialog/ModalDialog";
import DeleteTemplateFieldConfirmation, {
    IDeleteFieldProperties,
} from "components/Administration/OrganisationManagment/ViewOrganisation/Templates/DeleteTemplateFieldConfirmation";

import { IStructuredTemplateField, TemplateFieldQuestionType } from "model/organisation";
import { TemplateType } from "model/templateType";
import ViewEditMode from "model/viewEditMode";

import organizationService from "services/organizationService";

const DEFAULT_STRUCTURED_FIELD: IStructuredTemplateField = {
    question: "",
    questionType: "",
    parentFieldUuid: "",
    responseOptions: [],
    childStructuredFields: [],
    required: false,
};

const QUESTION_TYPE_OPTIONS = Object.values(TemplateFieldQuestionType).map((type) => ({
    text: type,
    value: type,
}));

interface IAdminNextStepsFields {
    setFields: (newFields, callback?) => void;
    setToViewMode?: () => void;
    mode?: ViewEditMode;
    fields?: IStructuredTemplateField[];
    validateParentForm?: (boolean) => void;
    createNewTemplate?: () => void;
}
export default function AdminNextStepsFields({
    fields = [DEFAULT_STRUCTURED_FIELD],
    setFields,
    setToViewMode,
    validateParentForm,
    mode,
    createNewTemplate,
}: IAdminNextStepsFields) {
    const [localFormFields, setLocalFormFields] = useState<IStructuredTemplateField[]>(fields);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
    const [deleteFieldProperties, setDeleteFieldProperties] = useState<IDeleteFieldProperties>();
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const location = useLocation();
    const pathSegments = location.pathname.split("/");
    const organisationUuid = pathSegments[pathSegments.length - 1];

    const onTagsChange = (
        event: React.SyntheticEvent<Element, Event>,
        values: string[],
        index: number,
        isChild: boolean,
        childIndex: number,
        fallBackId?: string
    ) => {
        const eventTarget = event.target as HTMLInputElement;
        const amendedFields = JSON.parse(JSON.stringify(localFormFields));
        const targetPath = isChild ? amendedFields[index].childStructuredFields[childIndex] : amendedFields[index];

        targetPath[eventTarget.id || fallBackId] = values;

        if (mode === ViewEditMode.EDIT) {
            targetPath.isEdited = true;
        }

        setLocalFormFields(amendedFields);
    };

    const onAddSubQuestion = (index: number): void => {
        const { childStructuredFields, ...newField } = DEFAULT_STRUCTURED_FIELD;

        const newFields = JSON.parse(JSON.stringify(localFormFields));
        if (!newFields[index].childStructuredFields) {
            newFields[index].childStructuredFields = [];
        }
        newField.parentFieldUuid = newFields[index].uuid;
        newFields[index].childStructuredFields.push(newField);

        setLocalFormFields(newFields);
    };

    const onNewStructuredTemplateFieldChange = (
        event: React.FormEvent<HTMLInputElement>,
        obj: any,
        index: number,
        isChild: boolean,
        childIndex: number
    ): void => {
        const amendedFields = JSON.parse(JSON.stringify(localFormFields));
        const isCheckbox = obj.type === "checkbox";
        const strippedId = obj.id.split("-")[0];

        const targetPath = isChild ? amendedFields[index].childStructuredFields[childIndex] : amendedFields[index];

        targetPath[strippedId] = isCheckbox ? obj.checked : obj.value;

        if (strippedId === "questionType") {
            targetPath.responseOptions = [];
        }

        if (mode === ViewEditMode.EDIT && !targetPath.isEdited) {
            targetPath.isEdited = true;
        }

        setLocalFormFields(amendedFields);
    };

    const handleCreateNewStructuredTemplate = (): void => {
        const fieldsToCreate = localFormFields.filter((field) => !field.removed);
        setFields(fieldsToCreate, createNewTemplate);
    };

    const onUpdateTemplate = async (): Promise<void> => {
        const { templateUuid } = localFormFields[0];
        const fieldsToUpdate = localFormFields.filter((field) => field.uuid || (!field.uuid && !field.removed));

        const template = {
            fields: fieldsToUpdate,
            name: TemplateType.ADMINISTRATIVE_NEXT_STEPS,
            isStructured: true,
            copyOfUuid: "",
            organisationUuid,
        };

        try {
            const updatedTemplate = await organizationService.updateTemplate(template, templateUuid);
            setFields(updatedTemplate.fields);
            setToViewMode();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClickDelete = (index: number, isChild: boolean, childIndex: number): void => {
        setDeleteFieldProperties({ index, isChild, childIndex });
        setShowDeleteConfirmationModal(true);
    };

    const hasEmptyFieldInputs = (inputs): boolean =>
        inputs.some((input) => !input.removed && (!input.question.length || !input.questionType.length));

    const hasEmptyFieldResponseOptions = (inputs): boolean =>
        inputs.some(
            (input) =>
                (input.questionType === TemplateFieldQuestionType.DROPDOWN ||
                    input.questionType === TemplateFieldQuestionType.MULTIPLE_CHOICE) &&
                input.responseOptions.length < 1
        );

    const areStructuredFieldsValid = (structuredFields: IStructuredTemplateField[]): boolean => {
        const hasFields = !!structuredFields?.length;
        const hasEmptyParentInputs = hasEmptyFieldInputs(structuredFields);
        const hasEmptyChildInputs = structuredFields.some(
            (field) => field.childStructuredFields?.length && hasEmptyFieldInputs(field.childStructuredFields)
        );
        const hasEmptyInputs = hasEmptyParentInputs || hasEmptyChildInputs;
        const hasEmptyParentResponseOptions = hasEmptyFieldResponseOptions(structuredFields);
        const hasEmptyChildResponseOptions = structuredFields.some(
            (field) => field.childStructuredFields?.length && hasEmptyFieldResponseOptions(field.childStructuredFields)
        );
        const hasEmptyResponseOptions = hasEmptyParentResponseOptions || hasEmptyChildResponseOptions;

        const isValid = hasFields && !hasEmptyInputs && !hasEmptyResponseOptions;

        if (validateParentForm) {
            validateParentForm(isValid);
        }

        return isValid;
    };

    useEffect(() => {
        setIsFormValid(areStructuredFieldsValid(localFormFields));
    }, [localFormFields]);

    const structuredForm = (
        field: IStructuredTemplateField,
        index: number,
        isChild?: boolean,
        childIndex?: number
    ): ReactNode => {
        const valueTarget = isChild ? localFormFields[index].childStructuredFields[childIndex] : localFormFields[index];
        const key = `${field.templateUuid}${index}`;
        if (field.removed) {
            return null;
        }
        return (
            <div key={key}>
                <Form.Group>
                    <Form.Field
                        width={6}
                        className={isChild ? "sub-question" : "primary-question"}
                        control={Form.Input}
                        id="question"
                        value={valueTarget.question || ""}
                        placeholder="Enter your question"
                        onChange={(e: React.FormEvent<HTMLInputElement>, obj) =>
                            onNewStructuredTemplateFieldChange(e, obj, index, isChild, childIndex)
                        }
                    />
                    <Form.Field
                        width={4}
                        control={Form.Select}
                        options={QUESTION_TYPE_OPTIONS}
                        id="questionType"
                        value={valueTarget.questionType || ""}
                        onChange={(e: React.FormEvent<HTMLInputElement>, obj) =>
                            onNewStructuredTemplateFieldChange(e, obj, index, isChild, childIndex)
                        }
                    />
                    <Form.Field className="buttons-container" width={6}>
                        <Form.Checkbox
                            className="checkbox"
                            label="Required"
                            id={`required-${isChild ? childIndex : index}-${isChild ? "child" : "parent"}`}
                            onChange={(e: React.FormEvent<HTMLInputElement>, obj) =>
                                onNewStructuredTemplateFieldChange(e, obj, index, isChild, childIndex)
                            }
                            checked={valueTarget.required || false}
                        />
                        {!isChild && (
                            <Button
                                variant="outlined"
                                startIcon={<InsertLinkIcon />}
                                size="small"
                                id="addSubQuestionButton"
                                onClick={() => onAddSubQuestion(index)}
                            >
                                Add sub question
                            </Button>
                        )}
                        {(localFormFields.filter((row) => !row.removed).length > 1 || isChild) && (
                            <Button
                                variant="outlined"
                                startIcon={<DeleteOutlineIcon />}
                                size="small"
                                onClick={() => handleClickDelete(index, isChild, childIndex)}
                            >
                                Delete
                            </Button>
                        )}
                    </Form.Field>
                </Form.Group>
                {["Dropdown", "Multiple choice"].includes(valueTarget?.questionType) && (
                    <Form.Group widths="10">
                        <Autocomplete
                            className={`options-tag-input ${isChild ? "options-tag-input--child" : ""}`}
                            multiple
                            freeSolo
                            id="responseOptions"
                            options={[]}
                            disablePortal
                            defaultValue={valueTarget.responseOptions?.map((option) => option.option) || []}
                            onChange={(e: React.SyntheticEvent<Element, Event>, values: string[]) =>
                                onTagsChange(e, values, index, isChild, childIndex, "responseOptions")
                            }
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={
                                        valueTarget.responseOptions?.length ? "" : "Type and press enter to add options"
                                    }
                                />
                            )}
                        />
                    </Form.Group>
                )}
            </div>
        );
    };

    return (
        <>
            <Form className="structured-template-creation">
                {localFormFields.map((field, parentIndex) => {
                    const key = `${field.templateUuid}${parentIndex}`;
                    if (!field.removed && field.childStructuredFields?.length) {
                        return (
                            <div key={key}>
                                {structuredForm(field, parentIndex, false)}
                                {field.childStructuredFields.map((childField, childIndex) => {
                                    if (!childField.removed) {
                                        const childKey = `${childField.templateUuid}${childIndex}`;
                                        return (
                                            <div key={childKey}>
                                                {structuredForm(field, parentIndex, true, childIndex)}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        );
                    }

                    return <div key={key}>{structuredForm(field, parentIndex, false)}</div>;
                })}
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setLocalFormFields([...localFormFields, DEFAULT_STRUCTURED_FIELD]);
                            }}
                        >
                            Add question
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign="center" width={16}>
                        <Button
                            variant="contained"
                            className="centred-button"
                            color="primary"
                            onClick={
                                mode === ViewEditMode.EDIT
                                    ? () => setShowConfirmationModal(true)
                                    : handleCreateNewStructuredTemplate
                            }
                            disabled={!isFormValid}
                        >
                            {mode === ViewEditMode.EDIT ? "Save changes" : "Create"}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Form>
            <ModalDialog
                title="Are you sure you want to save the changes?"
                open={showConfirmationModal}
                iconComponent={HelpOutlineOutlinedIcon}
                onClose={() => setShowConfirmationModal(false)}
                maxWidth="sm"
                disableBackdropClick
                buttons={[
                    {
                        onClick: onUpdateTemplate,
                        text: "Yes",
                    },
                    {
                        onClick: () => setShowConfirmationModal(false),
                        text: "Cancel",
                    },
                ]}
            >
                <Grid className="grid-dialog-body">
                    <Grid.Row>
                        <Grid.Column>
                            Make sure you have agreed with the customer the changes as once saved, the changes will be
                            reflected automatically.
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </ModalDialog>

            <DeleteTemplateFieldConfirmation
                fields={localFormFields}
                setFields={setLocalFormFields}
                showModal={showDeleteConfirmationModal}
                setShowModal={setShowDeleteConfirmationModal}
                mode={mode}
                deleteFieldProperties={deleteFieldProperties}
            />
        </>
    );
}

AdminNextStepsFields.defaultProps = {
    setToViewMode: undefined,
    mode: ViewEditMode.VIEW,
    fields: [DEFAULT_STRUCTURED_FIELD],
    validateParentForm: undefined,
};
