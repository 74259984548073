import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { Button, IconButton, Snackbar } from "@material-ui/core";

import { getDisplayCaseListSnackbar } from "redux/selectors/data";

import userService from "services/userService";

const CaseListSnackbar: FC = () => {
    const showCaseListSnackbar = useSelector(getDisplayCaseListSnackbar);

    const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        userService.hideCaseListSnackbar();
    };

    const handleDoNotShowAgain = async () => {
        await userService.setHideOrganisationSwitchPreference();
    };

    const action = (
        <>
            <Button color="primary" size="small" onClick={handleDoNotShowAgain}>
                DO NOT SHOW AGAIN
            </Button>
            <IconButton size="small" aria-label="close" onClick={handleClose}>
                <Icon size="small" name="close" inverted />
            </IconButton>
        </>
    );

    return (
        <Snackbar
            open={showCaseListSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            action={action}
            onClose={handleClose}
            message="From now on, remember that you can switch between organisations from your menu"
        />
    );
};

export default CaseListSnackbar;
