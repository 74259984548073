import { FC, ReactElement, useContext } from "react";
import { CheckboxProps, Checkbox, Divider, Form, Grid, InputOnChangeData } from "semantic-ui-react";
import { createStyles, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Config } from "config/config";

import { IAuditConfigurationState } from "model/auditConfig";
import AllocationConfigurationContext from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigProvider";
import { AllocationValue } from "model/organisation";
import { FixedQuestionType } from "model/fixedQuestion";

const featureFlags = Config.getFeatureFlags();

const useStyles = makeStyles(() =>
    createStyles({
        auditRoutingCheckbox: {
            fontSize: "1.3em",
            fontWeight: 500,
        },
        column: {
            paddingBottom: "8px",
            width: "100%",
        },
        row: {
            display: "flex",
            padding: "16px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "8px",
            borderRadius: "8px",
            background: "#F5F5F5",
            marginBottom: "16px",
            borderBottom: "unset",
        },
        auditConfigurationName: {
            opacity: 0.38,
        },
        auditIntro: {
            paddingTop: "8px",
        },
    })
);

interface IAuditConfigurationTableRow {
    auditState: IAuditConfigurationState;
    handleAuditToggle: (uuid: string) => void;
    handlePercentageChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData, uuid: string) => void;
    handleAllocationChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps, uuid: string) => void;
    auditUuid: string;
}

const disableConfigurationByDefault = (configurationName: string | FixedQuestionType): boolean =>
    featureFlags[configurationName];

const AuditConfigurationTableRow = ({
    auditState,
    handleAuditToggle,
    handlePercentageChange,
    handleAllocationChange,
    auditUuid,
}: IAuditConfigurationTableRow): ReactElement => {
    const classes = useStyles();
    const { configuration, enableAuditConfiguration } = auditState;
    const foundConfig = configuration?.find((config) => config.uuid === auditUuid);
    if (!foundConfig) {
        return null;
    }

    const { allocation, name, percentage, removed } = foundConfig;
    const disableElement = !enableAuditConfiguration || removed;
    const disableFormFields = disableConfigurationByDefault(name) || disableElement;

    return (
        <TableRow key={auditUuid}>
            <TableCell>
                <Checkbox
                    checked={!removed}
                    id={auditUuid}
                    onChange={() => handleAuditToggle(auditUuid)}
                    disabled={disableFormFields}
                />
            </TableCell>
            <TableCell className={classes.auditConfigurationName}>Fitzpatrick 5 and 6 cases</TableCell>
            <TableCell>
                <Form.Input
                    type="number"
                    fluid
                    onChange={(event, data) => handlePercentageChange(event, data, auditUuid)}
                    disabled={disableFormFields}
                    value={percentage}
                    min={0}
                    max={100}
                />
            </TableCell>
            <TableCell>
                <Form.Radio
                    checked={allocation === AllocationValue.CLIENT}
                    onChange={(event, data) => handleAllocationChange(event, data, auditUuid)}
                    disabled={disableFormFields}
                    value={AllocationValue.CLIENT}
                />
            </TableCell>
            <TableCell>
                <Form.Radio
                    checked={allocation === AllocationValue.SA}
                    onChange={(event, data) => handleAllocationChange(event, data, auditUuid)}
                    disabled={disableFormFields}
                    value={AllocationValue.SA}
                />
            </TableCell>
        </TableRow>
    );
};

const AuditConfiguration: FC = () => {
    const classes = useStyles();
    const {
        allocationState,
        auditState,
        auditDispatchers: {
            toggleAuditConfiguration,
            toggleAuditConfigurationRow,
            toggleDisableAuditRoutingModal,
            setAuditCriteria,
            setAuditAllocation,
        },
    } = useContext(AllocationConfigurationContext);

    const { showAutomatedDecisionConsent } = allocationState;
    const { configuration, enableAuditConfiguration } = auditState;

    // Hide the audit configuration block if automated decision making is disabled
    if (!showAutomatedDecisionConsent) {
        return null;
    }

    if (!configuration || configuration.length === 0) {
        return null;
    }

    const handleChange = (): void => {
        if (enableAuditConfiguration) {
            toggleDisableAuditRoutingModal();
        } else {
            toggleAuditConfiguration(showAutomatedDecisionConsent);
        }
    };

    const handleAuditToggle = (uuid: string): void => {
        toggleAuditConfigurationRow(uuid);
    };

    const handlePercentageChange = (
        _event: React.ChangeEvent<HTMLInputElement>,
        data: InputOnChangeData,
        uuid: string
    ): void => {
        setAuditCriteria({ uuid, percentage: Number(data.value) });
    };

    const handleAllocationChange = (
        _event: React.FormEvent<HTMLInputElement>,
        data: CheckboxProps,
        uuid: string
    ): void => {
        setAuditAllocation({ uuid, allocation: data.value });
    };

    return (
        <Grid.Row className={classes.row}>
            <Grid.Column className={classes.column}>
                <Checkbox
                    data-testid="audit-configuration-toggle"
                    className={classes.auditRoutingCheckbox}
                    checked={enableAuditConfiguration}
                    id="auditConfiguration"
                    onChange={handleChange}
                    disabled={false}
                    label="Audit routing"
                />
                <div className={classes.auditIntro}>
                    The audit mode allows us to reroute all the Discharge cases that should have been discharged
                    autonomously so that customers can perform an audit on them to assess the efficacy of our service.
                </div>
                {enableAuditConfiguration ? (
                    <>
                        <Divider />
                        <TableContainer>
                            <Table aria-label="Audit configuration table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>Criteria for auditing</TableCell>
                                        <TableCell>Audit percentage (%)</TableCell>
                                        <TableCell>Client dermatologists</TableCell>
                                        <TableCell>Skin Analytics dermatologists</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {configuration?.map((config) => (
                                        <AuditConfigurationTableRow
                                            key={config.uuid}
                                            auditState={auditState}
                                            handleAuditToggle={handleAuditToggle}
                                            handlePercentageChange={handlePercentageChange}
                                            handleAllocationChange={handleAllocationChange}
                                            auditUuid={config.uuid}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : null}
            </Grid.Column>
        </Grid.Row>
    );
};

export default AuditConfiguration;
