import React from "react";
import { Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import useAutoLogout from "hooks/useAutoLogout";

import { ICase } from "model/case";

import * as ROUTES from "navigation/routes";

import "scss/Dialog.scss";

interface ICancelEditPatientInfoDialog {
    showDialog: boolean;
    callback: (arg: boolean) => void;
    caseObject: ICase;
}

const CancelEditPatientInfoDialog = (props: ICancelEditPatientInfoDialog) => {
    useAutoLogout();

    const { showDialog, callback, caseObject } = props;

    const onConfirm = () => {
        const path = `${ROUTES.CASE_DESCRIPTION}/${caseObject.uuid}`;
        window.location.href = path;
    };

    const onCancel = () => {
        callback(false);
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    <p>Unsaved changes will be discarded.</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title="Back to Case view"
                open={showDialog}
                callback={callback}
                yesButton={{
                    backgroundColor: "#3CB5AD",
                    fontColor: "#000000",
                    onClick: onConfirm,
                    text: "Continue",
                }}
                noButton={{
                    backgroundColor: "#FFFFFF",
                    border: "#3CB5AD",
                    fontColor: "#3CB5AD",
                    onClick: onCancel,
                    text: "Cancel",
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default CancelEditPatientInfoDialog;
