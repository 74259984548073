import { FC } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import CustomButton from "components/templates/Button";

import ResetPasswordModalType from "model/resetPassword";

import { LOGIN } from "navigation/routes";

import * as actions from "redux/actions";

interface IExpiredTokenMessage {
    type: ResetPasswordModalType;
}

const ExpiredTokenMessage: FC<IExpiredTokenMessage> = ({ type }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const resendLink = () => {
        history.push(LOGIN);
        dispatch(actions.authActions.setResetPasswordModalType(type));
    };

    return (
        <div className="expired_token_message">
            <h2>Your link has expired</h2>
            <p>The link to set your password expires after 60 minutes and can only be used once.</p>
            <CustomButton
                type="button"
                variant="filled"
                action={resendLink}
                text="Resend link"
                dataTestId="resend-link-button"
            />
        </div>
    );
};

export default ExpiredTokenMessage;
