import { connect } from "react-redux";

import FitzpatrickHistoryQuestion from "components/FitzpatrickHistoryQuestion/FitzpatrickHistoryQuestion";

import assessmentService from "services/assessmentService";
import * as patientService from "services/patientService";
import * as caseService from "services/caseService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    currentLesion: state.assessment.currentLesion,
    organisation: state.data.organisation,
    pendingRequest: state.data.pendingRequest,
});

const mapDispatchToProps = {
    createMedicalHistory: patientService.submitMedicalAnswers,
    toggleShowIntegrationPatientCreatedModal: assessmentService.toggleShowIntegrationPatientCreatedModal,
    getCase: caseService.getCaseForAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(FitzpatrickHistoryQuestion);
