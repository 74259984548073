import { ILesionReview } from "components/CaseDescription/Review/CaseReview";

import { ILesion } from "model/case";
import { IManagementOptions, IReviewDiagnosis, IReviewEscalation } from "model/organisation";
import { ILesionItemReview, ILesionReviews } from "model/reviewNotes";

const URGENT_CODE = "urgent";

export const mapToOptions = (data: any[], keyProperty: string, valueProperty: string) => {
    const apiOptions = data.map((item) => {
        const key = item[keyProperty];
        const value = item[valueProperty];

        return {
            key,
            text: value,
            value: key,
        };
    });
    return [...apiOptions];
};

interface IFreeTextBase {
    readonly uuid: string;
    readonly freeText: boolean;
}

export const hasFreeText = <T extends IFreeTextBase>(options: T[], selectedManagement: string): boolean => {
    const currentReview = options ? options.find((option) => option.uuid === selectedManagement) : null;
    const freeTextReview = currentReview ? currentReview.freeText : false;
    return freeTextReview;
};

export const generateLesionReviews = (
    lesionReviews: ILesionReviews,
    organisationProps: {
        reviewManagementOptions: IManagementOptions[];
        reviewDiagnoses: IReviewDiagnosis[];
        escalationPlan?: IReviewEscalation[];
    }
): ILesionReview[] => {
    const { reviewManagementOptions, reviewDiagnoses, escalationPlan } = organisationProps;
    const lesionUuids = Object.keys(lesionReviews);
    const result = lesionUuids.map((lesionUuid) => {
        const currentLesion = lesionReviews[lesionUuid];
        const {
            selectedDiagnoses,
            selectedDiagnosesFreeText,
            selectedManagement,
            selectedManagementFreeText,
            selectedEscalation,
            selectedEscalationFreeText,
        } = currentLesion;

        const freeTextReview = hasFreeText(reviewManagementOptions, selectedManagement);
        const freeTextDiagnosis = hasFreeText(reviewDiagnoses, selectedDiagnoses);
        const freeTextEscalations = escalationPlan ? hasFreeText(escalationPlan, selectedEscalation) : false;

        return {
            ...(freeTextDiagnosis ? { diagnosisAdditionalText: selectedDiagnosesFreeText } : {}),
            ...(freeTextReview ? { managementAdditionalText: selectedManagementFreeText } : {}),
            lesionUuid,
            reviewDiagnosisUuid: selectedDiagnoses,
            reviewManagementUuid: selectedManagement,
            escalationPlanUuid: selectedEscalation,
            ...(freeTextEscalations ? { escalationPlanAdditionalText: selectedEscalationFreeText } : {}),
        };
    });
    return result;
};

export const getUniqueManagementOutcomeLesionReviews = (lesionReviews: ILesionReviews): ILesionItemReview[] => {
    const keys = Object.keys(lesionReviews);
    const managementOutcomes: Map<string, ILesionItemReview> = new Map();

    keys.forEach((key) => {
        if (!managementOutcomes.has(lesionReviews[key].selectedManagement)) {
            managementOutcomes.set(lesionReviews[key].selectedManagement, lesionReviews[key]);
        }
    });

    return Array.from(managementOutcomes, ([, value]) => ({
        ...value,
    }));
};

export const getInitialLesionReviews = (lesions: ILesion[]): ILesionReviews => {
    const initialLesionReviews = {};
    lesions.forEach((lesion) => {
        const { uuid } = lesion;
        initialLesionReviews[uuid] = {
            selectedDiagnoses: "",
            selectedDiagnosesFreeText: "",
            selectedManagement: "",
            selectedManagementFreeText: "",
            selectedEscalation: "",
            selectedEscalationFreeText: "",
        };
    });
    return initialLesionReviews;
};

export const isReviewManagementUrgent = (reviewManagement: string): boolean =>
    reviewManagement.toLowerCase().includes(URGENT_CODE);
