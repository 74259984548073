import React, { FC, useContext, useEffect } from "react";
import { Modal } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";

import { ModalContext } from "contextProviders/ModalProvider";

interface IAssessmentFlowConfirmModal {
    show: boolean;
    close: () => void;
    goAhead: () => void;
    save: () => void;
}

const AssessmentFlowConfirmModal: FC<IAssessmentFlowConfirmModal> = ({ show, close, goAhead, save }) => {
    const { setModalParameters, closeModal } = useContext(ModalContext);

    useEffect(() => () => closeModal(), []);

    useEffect(() => {
        setModalParameters({
            closeCallback: close,
            content: (
                <div className="confirm-modal-wrapper">
                    <Modal.Header className="confirm-modal-header">Save changes?</Modal.Header>
                    <p>You have made changes to the information on this page. Do you want to save?</p>
                    <ButtonContainer
                        button1={<CustomButton variant="empty" type="submit" action={goAhead} text="No - don’t save" />}
                        button2={
                            <CustomButton
                                variant="filled"
                                type="submit"
                                action={save}
                                text="Yes - save now"
                                size="small"
                            />
                        }
                    />
                </div>
            ),
            isVisible: show,
            size: "small",
        });
    }, [show]);

    return null;
};

export default AssessmentFlowConfirmModal;
