import React, { Component, ReactNode } from "react";
import { Table } from "semantic-ui-react";

import { ICase, ILesion } from "model/case";

interface IPreviewExportProps {
    organisationsCases: { string: ICase[] };
}

class PreviewExport extends Component<any, IPreviewExportProps> {
    private getPreviewExportRows() {
        const { organisationsCases } = this.props;

        const tableBody: ReactNode[] = [];
        Object.keys(organisationsCases).forEach((key) => {
            const value = organisationsCases[key];
            const allOrganisationLesions = value.map((item) => item.lesions).reduce((a, b) => this.reduceLesions(a, b));

            tableBody.push(
                <Table.Row key={key} id={key}>
                    <Table.Cell>{key}</Table.Cell>
                    <Table.Cell>{value.length}</Table.Cell>
                    <Table.Cell>{allOrganisationLesions?.length || 0}</Table.Cell>
                </Table.Row>
            );
        });
        return tableBody;
    }

    private reduceLesions = (caseALesions: ILesion[], caseBLesions: ILesion[]) => {
        if (caseALesions && caseBLesions) {
            return caseALesions.concat(caseBLesions);
        }
        if (caseALesions) {
            return caseALesions;
        }

        if (caseBLesions) {
            return caseBLesions;
        }

        return [];
    };

    public render() {
        const { organisationsCases } = this.props;
        return (
            organisationsCases && (
                <Table selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Organisation Name</Table.HeaderCell>
                            <Table.HeaderCell>Cases</Table.HeaderCell>
                            <Table.HeaderCell>Lesions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.getPreviewExportRows()}</Table.Body>
                </Table>
            )
        );
    }
}

export default PreviewExport;
