import React, { FC, useState, useEffect, createContext } from "react";

import { ICase } from "model/case";
import { NoteType } from "model/reviewNotes";

import userService from "services/userService";

export interface ICasesReviewContext {
    currentCase: ICase;
    updateCaseStatus: (data: any, type: string) => void;
}

interface ICasesReviewProvider {
    currentCase: ICase;
}

export const CaseReviewContext = createContext<ICasesReviewContext>({} as ICasesReviewContext);

const CaseReviewProvider: FC<ICasesReviewProvider> = ({ children, currentCase }) => {
    const [streamCase, setStreamCase] = useState(currentCase);

    useEffect(() => {
        setStreamCase(currentCase);
    }, [currentCase]);

    const updateCaseStatus = (data: any, type: string) => {
        const updatedCase = { ...streamCase };
        const propertyName = type === NoteType.CLINICAL ? "clinicalNotes" : "patientNotes";
        const currentUserFullName = userService.getCurrentUserFullName();
        const newComment = { ...data, createdByName: currentUserFullName };
        if (!updatedCase[propertyName]) {
            updatedCase[propertyName] = [newComment];
        } else {
            updatedCase[propertyName].push(newComment);
        }
        setStreamCase(updatedCase);
    };

    const values = {
        currentCase: streamCase,
        updateCaseStatus,
    };

    return (
        <CaseReviewContext.Provider
            value={{
                ...values,
            }}
        >
            {children}
        </CaseReviewContext.Provider>
    );
};

export default CaseReviewProvider;
