import React, { FC } from "react";
import { Divider, Grid, Header, Message } from "semantic-ui-react";

interface ISupportedMarker {
    description: string;
    pattern: string;
}

interface Props {
    data: any;
}

const SupportedMarkers: FC<Props> = ({ data }) => (
    <Message info>
        <Header as="h3">Supported markers</Header>
        <Divider />
        <Grid columns={2}>
            {Object.values(data as { [key: string]: ISupportedMarker }).map((marker: ISupportedMarker) => (
                <Grid.Row key={marker.pattern}>
                    <Grid.Column largeScreen={5} computer={8} tablet={8} mobile={16}>
                        <b>{marker.pattern}</b>
                    </Grid.Column>
                    <Grid.Column largeScreen={11} computer={8} tablet={8} mobile={16}>
                        {marker.description}
                    </Grid.Column>
                </Grid.Row>
            ))}
        </Grid>
    </Message>
);

export default SupportedMarkers;
