import FocusTrap from "focus-trap-react";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";

interface IConfirmDialogButton {
    label: string;
    onClick: () => void;
}

const ConfirmDialog = (message: string, title: string, buttons: IConfirmDialogButton[]) => {
    confirmAlert({
        customUI: ({ onClose }) => (
            <FocusTrap active>
                <div
                    role="dialog"
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                    className="custom-alert-dialog"
                >
                    <h2 id="dialog-title">{title}</h2>
                    <p id="dialog-description">{message}</p>
                    {buttons.map((button, index) => (
                        <button
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            tabIndex={0}
                            aria-label={button.label}
                            onClick={() => {
                                button.onClick();
                                onClose();
                            }}
                            type="button"
                        >
                            {button.label}
                        </button>
                    ))}
                </div>
            </FocusTrap>
        ),
    });
};

export const getDefaultButtons = (onYesAction: () => void, onNoAction?: () => void) => [
    {
        label: "Yes",
        onClick: () => onYesAction(),
    },
    {
        label: "No",
        onClick: () => (onNoAction ? onNoAction() : () => undefined),
    },
];

export default ConfirmDialog;
