import { IMFASetupState, MFASetupStatus, MFAVerificationChannel, VerificationStatus } from "model/mfa";

import types from "redux/actions/types";

const checkChannelVerificationStatus = (status: MFASetupStatus): boolean =>
    [MFASetupStatus.NOT_VERIFIED, MFASetupStatus.RE_VERIFICATION_REQUIRED].includes(status);

const initialState: IMFASetupState = {
    showMfaSetup: false,
    hasSeenMFAScreen: false,
    gracePeriodCountdownInDays: null,
    email: {
        status: VerificationStatus.UNINITIATED,
        verificationDate: null,
    },
    sms: {
        status: VerificationStatus.UNINITIATED,
        verificationDate: null,
    },
};

export default (state: IMFASetupState = initialState, action: any): IMFASetupState => {
    switch (action.type) {
        case types.MFA_STATUS_REQUEST_SUCCESS: {
            /**
             * Show MFA setup screen if:
             *
             *      1) One or more organisations has mfaEnabled true
             *      AND
             *      2) Either email or SMS channels has NOT been verified
             *      3) Either email or SMS channels have not been verified in last 180 days
             */
            let showMfaSetup = false;
            const { enabled, hasSeenMFAScreen, gracePeriodCountdownInDays, email, sms } = action.payload;

            if (enabled) {
                showMfaSetup =
                    checkChannelVerificationStatus(email.status) || checkChannelVerificationStatus(sms.status);
            }

            return {
                ...state,
                showMfaSetup,
                hasSeenMFAScreen,
                gracePeriodCountdownInDays,
                [MFAVerificationChannel.EMAIL]: { ...email },
                [MFAVerificationChannel.SMS]: { ...sms },
            };
        }
        case types.MFA_STATUS_REQUEST_ERROR: {
            return {
                ...state,
                showMfaSetup: false,
            };
        }
        case types.MFA_VERIFICATION_SENT: {
            return {
                ...state,
                [action.payload.channel]: {
                    ...state[action.payload.channel],
                    status: VerificationStatus.PENDING,
                },
            };
        }
        case types.MFA_VERIFIED: {
            return {
                ...state,
                [action.payload.channel]: {
                    ...state[action.payload.channel],
                    status: VerificationStatus.APPROVED,
                    verificationDate: action.payload.verificationDate,
                },
            };
        }
        case types.MFA_COMPLETE_SETUP: {
            return {
                ...state,
                showMfaSetup: false,
            };
        }
        case types.CLEAR_DATA: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
};
