import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { scrollToTop } from "helpers/page";

const ScrollToTop = () => {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        scrollToTop();
    }, [pathname]);

    return null;
};

export default ScrollToTop;
