import types from "redux/actions/types";

const INITIAL_STATE = {
    case: null,
    cases: null,
};

const previousCaseReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case types.CLEAR_CASE:
            return {
                ...state,
                case: null,
            };

        case types.CLEAR_CASES:
            return {
                ...state,
                cases: null,
            };

        case types.GET_CASE:
            return {
                ...state,
                case: action.payload,
            };
        case types.GET_CASES:
            return {
                ...state,
                cases: action.payload,
            };
        case types.GET_REPORT:
            return {
                ...state,
                report: action.payload,
            };
        default:
            return state;
    }
};

export default previousCaseReducer;
