import moment from "moment";

import { ERROR_FIELD_IS_REQUIRED, validEmail } from "helpers/common";
import { getStringWithoutBackspaces } from "helpers/patientUtils";

import { IPatientEdit } from "model/patientEdit";
import {
    IDefinedPersonalData,
    IOrganisation,
    DefinedPersonalDataType,
    IDefinedValidationType,
} from "model/organisation";

export const MAX_AGE = 120;
const INVALID_DATE = "Invalid date";
const HOSPITAL_NO_REGEXP = /^(\w\d{6}|\d{7}|\d{6})$/;

interface IError {
    propertyName: string;
    text: string;
}

interface IIsAgeValid {
    isLowerThanMinAgeDefined: boolean;
    isBiggerThanMaxAge: boolean;
}

export class PatientDetailsValidation {
    public validate = (
        organisation: IOrganisation,
        personalDataFields: IDefinedPersonalData[],
        patient: IPatientEdit
    ) => {
        const { minimumAge } = organisation;
        const emptyFieldErrors = personalDataFields
            .filter(
                (item: IDefinedPersonalData) =>
                    (item.required && !patient[item.propertyName]) || patient[item.propertyName] === INVALID_DATE
            )
            .map((item: IDefinedPersonalData) => ({
                propertyName: item.propertyName,
                text: ERROR_FIELD_IS_REQUIRED,
            }));
        const validationErrors = personalDataFields.reduce((acc: IError[], item: IDefinedPersonalData) => {
            let error: IError | false | null = null;
            if (item.type === DefinedPersonalDataType.DATE) {
                error = this.validateAge(item, minimumAge, patient);
            }
            if (item.type === DefinedPersonalDataType.TEXT) {
                error = this.validateTextField(item, patient);
            }
            return error ? [...acc, error] : acc;
        }, []);
        const errorsList = [...emptyFieldErrors, ...validationErrors];
        const isValid = errorsList.length === 0;
        return { isValid, errorsList };
    };

    private validateAge = (
        item: IDefinedPersonalData,
        minimumAge: number,
        patient: IPatientEdit
    ): IError | false | null => {
        const currentField = patient[item.propertyName] as string;
        const hasMinAgeValidation = item.validationProperty === IDefinedValidationType.minimumAge;
        if (currentField) {
            const isIncludesUnderscore = currentField.includes("_");
            const { isLowerThanMinAgeDefined, isBiggerThanMaxAge } = PatientDetailsValidation.isAgeValid(
                currentField,
                hasMinAgeValidation ? minimumAge : undefined
            );
            const isInvalid = isLowerThanMinAgeDefined || isBiggerThanMaxAge || isIncludesUnderscore;

            return isInvalid
                ? {
                      propertyName: item.propertyName,
                      text: isLowerThanMinAgeDefined
                          ? `The patient must be aged ${minimumAge} or over to use this service.`
                          : "Please enter a valid date",
                  }
                : null;
        }
        return false;
    };

    private static multiplyByPosition(digit: string, index: number): number {
        return +digit * (11 - (index + 1));
    }

    private static addTogether(previousValue: number, currentValue: number): number {
        return previousValue + currentValue;
    }

    public static isWrongNHSNumber(nhsNumber: string): boolean {
        let newNhsNumber = nhsNumber;

        newNhsNumber = getStringWithoutBackspaces(newNhsNumber);
        const nhsNumberAsArray = newNhsNumber.split("");
        const remainder =
            nhsNumberAsArray
                .slice(0, 9)
                .map(PatientDetailsValidation.multiplyByPosition)
                .reduce(PatientDetailsValidation.addTogether, 0) % 11;
        let checkDigit = 11 - remainder;
        const providedCheckDigit = nhsNumberAsArray[9];

        if (checkDigit === 11) {
            checkDigit = 0;
        }

        return checkDigit !== Number(providedCheckDigit);
    }

    public static isHospitalNumberInvalid(hospitalNumber: string): boolean {
        return !HOSPITAL_NO_REGEXP.test(hospitalNumber);
    }

    public static isAgeValid(currentField: string, minimumAge?: number): IIsAgeValid {
        const years = moment().diff(currentField, "years");
        const isLowerThanMinAgeDefined = minimumAge ? years < minimumAge : false;
        const isBiggerThanMaxAge = years > MAX_AGE || years < 0;

        return { isLowerThanMinAgeDefined, isBiggerThanMaxAge };
    }

    private validateTextField = (item: IDefinedPersonalData, patient: IPatientEdit): IError | false | null => {
        const currentField = patient[item.propertyName] as string;
        const hasNHSValidation = item.validationProperty === IDefinedValidationType.nhsNumber;
        const hasEmailValidation = item.validationProperty === IDefinedValidationType.email;
        const hasHospitalNumberValidation = item.validationProperty === IDefinedValidationType.hospitalNumber;

        if (currentField && hasNHSValidation) {
            const isInvalid = PatientDetailsValidation.isWrongNHSNumber(currentField);

            return isInvalid
                ? {
                      propertyName: item.propertyName,
                      text: "NHS# is invalid",
                  }
                : null;
        }

        if (currentField && hasEmailValidation) {
            const isInvalid = !validEmail(currentField);

            return isInvalid
                ? {
                      propertyName: item.propertyName,
                      text: "Email is invalid",
                  }
                : null;
        }

        if (currentField && hasHospitalNumberValidation) {
            const isInvalid = PatientDetailsValidation.isHospitalNumberInvalid(currentField);

            return isInvalid
                ? {
                      propertyName: item.propertyName,
                      text: "Hospital Number is invalid",
                  }
                : null;
        }
        return false;
    };
}

export default new PatientDetailsValidation();
