import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";
import removeNullPropertiesFromRequest from "helpers/requestUtils";

import { INewManagementOption, INewReviewDiagnoses, INewReviewEscalation } from "model/organisation";

import URLs from "services/urls";

const {
    CREATE_DIAGNOSES,
    UPDATE_ALL_DIAGNOSES,
    UPDATE_DIAGNOSES,
    DELETE_DIAGNOSES,
    CREATE_MANAGEMENT_OUTCOME,
    DELETE_MANAGEMENT_OUTCOME,
    CREATE_ESCALATION,
    DELETE_ESCALATION,
} = URLs;

async function createDiagnoses(reviewDiagnoses: INewReviewDiagnoses) {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_DIAGNOSES, reviewDiagnoses);
    return response.data.data;
}

async function updateAllDiagnoses(organisationUuid: string, reviewDiagnoses: INewReviewDiagnoses[]) {
    const updatedDiagnoses = reviewDiagnoses.map((diag) => removeNullPropertiesFromRequest(diag));
    const response = await AuthorizedHttpConnector.sendPut(UPDATE_ALL_DIAGNOSES, {
        organisationUuid,
        diagnoses: updatedDiagnoses,
    });
    return response.data.data;
}

async function updateDiagnoses(reviewDiagnoses: INewReviewDiagnoses) {
    const updatedDiagnoses = removeNullPropertiesFromRequest<INewReviewDiagnoses>(reviewDiagnoses);

    const response = await AuthorizedHttpConnector.sendPut(
        UPDATE_DIAGNOSES.replace("{{UUID}}", updatedDiagnoses.uuid),
        reviewDiagnoses
    );
    return response.data.data;
}

async function removeDiagnoses(diagnosesUuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(DELETE_DIAGNOSES.replace("{{UUID}}", diagnosesUuid));
    return { status: response.status };
}

async function createEscalation(escalation: INewReviewEscalation) {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_ESCALATION, escalation);
    return response.data.data;
}

async function createManagementOutcome(organisationUuid: string, newOption: INewManagementOption) {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_MANAGEMENT_OUTCOME, {
        freeText: newOption.freeTextManagement,
        keyMessage: newOption.keyMessage,
        management: newOption.management,
        organisationUuid,
        reviewType: newOption.reviewType,
        safetyNetting: newOption.safetyNetting,
        refer: newOption.refer,
        discharge: newOption.discharge,
    });
    return response.data.data;
}

async function removeManagementOutcome(managementOutcomeUuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(
        DELETE_MANAGEMENT_OUTCOME.replace("{{UUID}}", managementOutcomeUuid)
    );
    return { status: response.status };
}

async function removeEscalationPlan(escalationPlanUuid: string): Promise<{ status: number }> {
    const response = await AuthorizedHttpConnector.sendDelete(
        DELETE_ESCALATION.replace("{{UUID}}", escalationPlanUuid)
    );
    return { status: response.status };
}

export default {
    createDiagnoses,
    createManagementOutcome,
    updateDiagnoses,
    updateAllDiagnoses,
    removeDiagnoses,
    removeManagementOutcome,
    createEscalation,
    removeEscalationPlan,
};
