import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider, Form, Grid } from "semantic-ui-react";

import { IOrganisation } from "model/organisation";

import CustomButton from "components/templates/Button";
import { history } from "App";

import { Config } from "config/config";
import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { HOME, LOGIN } from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";
import * as caseService from "services/caseService";

import "scss/MultilevelList.scss";
import "scss/Assessment.scss";
import "scss/TestPage.scss";

interface IImageTest {
    signedIn: boolean;
    organisation: IOrganisation;
}

const TEST_ORGANISATION_UUID = process.env.REACT_APP_IMAGE_ORGANISATION_LESION_UUID;
const TEST_CASE_UUID = process.env.REACT_APP_IMAGE_TESTING_CASE_UUID;
const TEST_LESION_UUID = process.env.REACT_APP_IMAGE_TESTING_LESION_UUID;

const ImageTest: React.FC<IImageTest> = ({ signedIn, organisation }) => {
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState("❔");

    useEffect(() => {
        logoutTimeoutService.stopCount();
    }, []);

    // Allow only the users from the test org to access the test page
    useEffect(() => {
        if (organisation.uuid !== TEST_ORGANISATION_UUID) {
            history.push(HOME);
        }
    }, []);

    const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

    const getImageFromPresignedUrl = async (imageUuid: string) => {
        const response = await AuthorizedHttpConnector.sendGet(
            `${Config.getReportsMsUrl()}/api/v1/images/image-test/${imageUuid}`
        );
        return response.data;
    };

    const checkImageHasBeenUploaded = async (uuid: string) => {
        const response = await AuthorizedHttpConnector.sendGet(
            `${Config.getImagesMsUrl()}/api/v1/image/check?uuid=${uuid}`
        );
        return response.data;
    };

    const createFormData = async (patientUuid: string): Promise<FormData> => {
        const formData = new FormData();

        const response = await fetch(`${process.env.PUBLIC_URL}/images/imageCaptureInstruction.png`);
        const blobImage = await response.blob();
        const file = new File([blobImage], "imageCaptureInstruction.png", { type: "image/png" });

        formData.append("image", file);
        formData.append("caseUuid", TEST_CASE_UUID);
        formData.append("patientUuid", patientUuid);
        formData.append("lesionUuid", TEST_LESION_UUID);
        formData.append("deviceUuid", "");

        return formData;
    };

    const waitForImageUpload = async (uuid: string, callback: () => void) => {
        try {
            const result = await checkImageHasBeenUploaded(uuid);
            const uploadStatus = result.data.status;

            switch (uploadStatus) {
                case "IN_PROGRESS":
                    await sleep(1000);
                    return waitForImageUpload(uuid, callback); // Recursively call to check again
                case "NOT_REGISTERED":
                    throw new Error("Image upload: image not registered");
                case "UPLOADED":
                    await callback();
                    return true;
                default:
                    throw new Error("Image upload: unknown statusfor");
            }
        } catch (e) {
            throw new Error(`Error checking image upload status: ${JSON.stringify(e)}`);
        }
    };

    const testPresignedURL = async (): Promise<void> => {
        setError("");
        setLoading(true);
        setStatus("❔");

        try {
            let patientUuid;
            try {
                const caseData = await caseService.getCaseAsync(TEST_CASE_UUID);
                patientUuid = caseData.patient.uuid;
            } catch (e) {
                throw new Error("Could not fetch case uuid");
            }

            const formData = await createFormData(patientUuid);

            let uploadImage;
            try {
                uploadImage = await caseService.uploadMacroImage(formData);
            } catch (e) {
                throw new Error("Uploading image failed.");
            }

            await waitForImageUpload(uploadImage.data.uuid, async () => {
                let presignedURL;
                try {
                    const fetchURL = await getImageFromPresignedUrl(uploadImage.data.uuid);
                    presignedURL = fetchURL.data.presignedUrl;
                } catch (e) {
                    throw new Error("Fetching presigned URL link failed");
                }

                try {
                    await fetch(presignedURL);
                } catch (e) {
                    throw new Error("Fetching image from the presigned URL failed");
                }
            });

            setStatus("✅");
        } catch (e) {
            setError(`Failed test, see error details ${e || JSON.stringify(e)}`);
            setStatus("🚫");
        } finally {
            setLoading(false);
        }
    };

    if (!signedIn) {
        return <Redirect to={LOGIN} />;
    }

    return (
        <Container>
            <div className="wizzard-container-title">
                <h1>Testing</h1>
            </div>

            <Divider />
            <Form>
                <Grid columns={2}>
                    <Grid.Row className="pathway-row">
                        {error ? (
                            <div style={{ marginBottom: "50px" }}>
                                <h3>Errors</h3>
                                <textarea
                                    readOnly
                                    name="error"
                                    id=""
                                    cols={30}
                                    rows={10}
                                    defaultValue={JSON.stringify(error)}
                                />
                            </div>
                        ) : null}
                    </Grid.Row>
                    <Grid.Row className="pathway-row">
                        <div className="list-container">
                            <div className="flex-container">
                                <span className="bullet-point">&#8226;</span>
                                <div className="content">
                                    <p>Image test, new networking</p>
                                    <CustomButton
                                        type="button"
                                        variant="filled"
                                        size="small"
                                        text="Run test"
                                        loading={loading}
                                        action={testPresignedURL}
                                        disabled={loading}
                                    />
                                    <p>{status}</p>
                                </div>
                            </div>
                        </div>
                    </Grid.Row>
                </Grid>
            </Form>
        </Container>
    );
};

export default ImageTest;
