import { FC, ReactNode } from "react";
import { Grid } from "semantic-ui-react";

interface IAllocationConfigurationTableInformation {
    title: string;
    children: ReactNode;
}

const AllocationConfigurationTableInformation: FC<IAllocationConfigurationTableInformation> = ({ title, children }) => (
    <Grid>
        <Grid.Row>
            <Grid.Column width="10">
                <h3>{title}</h3>
            </Grid.Column>
            <Grid.Column width="2" floated="right">
                {children}
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default AllocationConfigurationTableInformation;
