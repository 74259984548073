import {
    IPatientSearchForm,
    IPatientSearchItem,
    PatientSearchExceptionModalTypes,
    SortableColumns,
} from "model/patientSearch";

export enum PatientSearchActions {
    SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS",
    SORT_RESULTS_BY_COLUMN = "SORT_RESULTS_BY_COLUMN",
    HIDE_SEARCH_RESULTS = "HIDE_SEARCH_RESULTS",
    SET_RESULTS_PAGE = "SET_RESULTS_PAGE",
    SET_EXPANDED_VIEW_PATIENT = "SET_EXPANDED_VIEW_PATIENT",
    RESET_EXPANDED_VIEW_PATIENT = "RESET_EXPANDED_VIEW_PATIENT",
    SET_SEARCH_FORM_DATA = "SET_SEARCH_FORM_DATA",
    TOGGLE_ADVANCED_SEARCH_VISIBILITY = "TOGGLE_ADVANCED_SEARCH_VISIBILITY",
    SET_MODAL_VISIBILITY = "SET_MODAL_VISIBILITY",
}

export interface ISetSearchResults {
    type: PatientSearchActions.SET_SEARCH_RESULTS;
    patients: IPatientSearchItem[];
    form: IPatientSearchForm;
}

export interface ISortResultsByColumn {
    type: PatientSearchActions.SORT_RESULTS_BY_COLUMN;
    column: SortableColumns;
}

export interface IHideSearchResults {
    type: PatientSearchActions.HIDE_SEARCH_RESULTS;
}

export interface ISetResultsPage {
    type: PatientSearchActions.SET_RESULTS_PAGE;
    currentPage: number;
}

export interface ISetExpandedViewPatient {
    type: PatientSearchActions.SET_EXPANDED_VIEW_PATIENT;
    patient: IPatientSearchItem;
}

export interface IResetCurrentPatient {
    type: PatientSearchActions.RESET_EXPANDED_VIEW_PATIENT;
}

export interface IToggleAdvancedSearchVisibility {
    type: PatientSearchActions.TOGGLE_ADVANCED_SEARCH_VISIBILITY;
}

export interface ISetModalVisibility {
    type: PatientSearchActions.SET_MODAL_VISIBILITY;
    modalType?: PatientSearchExceptionModalTypes;
    show: boolean;
}

export type TPatientSearchActions =
    | ISetSearchResults
    | ISortResultsByColumn
    | IHideSearchResults
    | ISetResultsPage
    | ISetExpandedViewPatient
    | IResetCurrentPatient
    | IToggleAdvancedSearchVisibility
    | ISetModalVisibility;
