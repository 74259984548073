export enum CaseViewMode {
    VIEW = "VIEW",
    REVIEW = "REVIEW",
    REVIEWVIEW = "REVIEWVIEW",
    SUPERADMINVIEW = "SUPERADMINVIEW",
    ADMINVIEW = "ADMINVIEW",
    CALLBACK_AGENT_REVIEW = "CALLBACK_AGENT_REVIEW",
    CLIENT_ADMIN_REMOTE_VIEW = "CLIENT_ADMIN_REMOTE_VIEW",
    CLINICAL_VIEWER_VIEW = "CLINICAL_VIEWER_VIEW",
}

export enum ReviewMode {
    REVIEW = "REVIEW",
    VIEW = "VIEW",
}
