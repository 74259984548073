import { FC } from "react";
import { Table } from "semantic-ui-react";

import { ICase, ILesion } from "model/case";
import { AllLesionLocation } from "model/lesionLocation";

interface ICaseSummaryTable {
    currentCase: ICase;
}

const CaseSummaryTable: FC<ICaseSummaryTable> = ({ currentCase }) => {
    const { lesions } = currentCase;
    if (!currentCase) {
        return null;
    }
    return (
        <Table celled className="assessment-summary-table">
            <Table.Header className="report-header">
                <Table.Row>
                    <Table.HeaderCell width={2}>Lesion</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Suspected diagnosis</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Referral recommendation</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {lesions.map((lesion: ILesion, index: number) => {
                    const { reviews, bodyPart, description, uuid } = lesion;
                    const lastReviewIndex = reviews.length - 1;
                    const review = reviews[lastReviewIndex];
                    const { reviewDiagnosis, reviewManagement } = { ...review };

                    return (
                        <>
                            <Table.Row className="item desktop-row" key={uuid}>
                                <Table.Cell>
                                    <div>
                                        <b>Lesion #{index + 1}</b>
                                        <p>
                                            <span>{AllLesionLocation[bodyPart]}</span>
                                            {description && <span>{` (${description})`}</span>}
                                        </p>
                                    </div>
                                </Table.Cell>
                                <Table.Cell width={3}>{reviewDiagnosis}</Table.Cell>
                                <Table.Cell width={3}>{reviewManagement}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="item mobile-row">
                                <Table.Cell className="row header">Lesion</Table.Cell>
                                <Table.Cell className="row">
                                    <div>
                                        <b>Lesion #{index + 1}</b>
                                        <p>
                                            <span>{AllLesionLocation[bodyPart]}</span>
                                            {description && <span>{` (${description})`}</span>}
                                        </p>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row className="item mobile-row">
                                <Table.Cell className="row header">
                                    <span>Suspected diagnosis</span>
                                </Table.Cell>
                                <Table.Cell className="row">{reviewDiagnosis}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="item mobile-row">
                                <Table.Cell className="row header">
                                    <span>Referral recommendation</span>
                                </Table.Cell>
                                <Table.Cell className="row">{reviewManagement}</Table.Cell>
                            </Table.Row>
                        </>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export default CaseSummaryTable;
