import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Divider, Form, Grid, Segment } from "semantic-ui-react";

import CustomRichTextEditor from "components/templates/Editor";

import { getPasswordMinLength } from "helpers/password";
import createDropdownOptions from "helpers/semanticUi";

import {
    AdministrationPages,
    DermatologistAvailabilityMapping,
    DermatologistAvailabilityLabel,
    UserManagementSubpages,
    DermatologistAvailabilityEntities,
} from "model/administrationPages";
import { IDictionaryItem } from "model/dictionaryItem";
import { IFormError } from "model/formError";
import HttpStatus from "model/httpStatus";
import { UserRole } from "model/userRole";
import UserTitle from "model/userTitle";

import { history } from "App";
import { ADMINISTRATION } from "navigation/routes";

import organisationService from "services/organizationService";
import userService from "services/userService";

import "scss/CreateUserScreen.scss";

const AVAILABILITY_FIELD_TEXT = "availabilityInput";
const ROLE_INPUT_ID = "roleInput";

const CREATEABLE_USER_ROLES = [
    UserRole.ADMIN,
    UserRole.SA_ADMIN,
    UserRole.SUPERADMIN,
    UserRole.CLINICIAN,
    UserRole.DERMATOLOGIST,
    UserRole.CALLBACK_AGENT,
    UserRole.CLINICAL_VIEWER,
];

interface ICreateUserScreenProps {
    params: any;
}

interface ICreateUserScreenState {
    errorDetails: Pick<IFormError, "path" | "message">[];
    isError: boolean;
    organisationDict: IDictionaryItem[];
    submitting: boolean;
    emailInput: string;
    passwordInput: string;
    repeatPasswordInput: string;
    nameInput: string;
    surnameInput: string;
    roleInput: UserRole | undefined;
    organisationInput: string[];
    titleInput?: string;
    phoneNumberInput?: string;
    availabilityInput?: DermatologistAvailabilityEntities;
    gmcNumberInput?: string;
    additionalRoleInfoInput?: string;
    notesInput?: string;
}

class CreateUserScreen extends Component<ICreateUserScreenProps, ICreateUserScreenState> {
    constructor(props: any) {
        super(props);
        this.state = {
            errorDetails: [],
            isError: false,
            organisationDict: [],
            submitting: false,
            emailInput: "",
            passwordInput: "",
            repeatPasswordInput: "",
            nameInput: "",
            surnameInput: "",
            roleInput: undefined,
            organisationInput: [],
            titleInput: undefined,
            phoneNumberInput: undefined,
            availabilityInput: DermatologistAvailabilityEntities.AVAILABLE_FOR_CASE_ALLOCATION,
            gmcNumberInput: undefined,
            additionalRoleInfoInput: undefined,
            notesInput: undefined,
        };
    }

    public componentDidMount() {
        const { params } = this.props;
        const { uuid } = params;

        organisationService.getAllOrganisationsDictionary().then((result) => {
            const withAllRemoved = result?.filter((res) => res.key !== "ALL");
            this.setState({ organisationDict: withAllRemoved, organisationInput: uuid });
        });
    }

    private onRichTextEditorChanged = (value: string, name: string) => {
        const previousState = this.state;
        this.setState({ ...previousState, [name]: value });
    };

    private onFieldChange = (event: any, obj: any) => {
        const stateObj = {};
        const fieldName = obj.id;
        const fieldValue = obj.value;
        stateObj[fieldName] = fieldValue;

        this.setState(stateObj);
    };

    private submit = () => {
        if (this.validateSubmit()) {
            this.setState({ submitting: true });
            const {
                emailInput,
                passwordInput,
                nameInput,
                surnameInput,
                roleInput,
                organisationInput,
                titleInput,
                phoneNumberInput,
                availabilityInput,
                gmcNumberInput,
                additionalRoleInfoInput,
                notesInput,
            } = this.state;

            const withAdditionalData = this.showCallbackAgentOrDermatologistFields();

            userService
                .createNewUser({
                    email: emailInput,
                    password: passwordInput,
                    name: nameInput,
                    surname: surnameInput,
                    role: roleInput,
                    organisationUuids: organisationInput,
                    title: titleInput,
                    ...(withAdditionalData
                        ? {
                              availability: DermatologistAvailabilityMapping[availabilityInput],
                              gmcNumber: gmcNumberInput,
                              mobileNumber: phoneNumberInput,
                          }
                        : {}),
                    additionalRoleInfo: additionalRoleInfoInput,
                    notes: notesInput,
                })
                .then((result) => {
                    this.setState({ submitting: false });
                    if (result.status === HttpStatus.OK) {
                        const userUrl = `${ADMINISTRATION}/${AdministrationPages.USER_MANAGEMENT}/${UserManagementSubpages.USER_DETAILS}/${result.uuid}`;
                        history.push(userUrl);
                    }
                })
                .catch((err) => {
                    this.setState({
                        errorDetails: err.response.data.errors,
                        isError: true,
                        submitting: false,
                    });
                });
        }
    };

    private showCallbackAgentOrDermatologistFields(): boolean {
        const { roleInput } = this.state;

        const isCallbackAgentUser = roleInput === UserRole.CALLBACK_AGENT;
        const isDermatologist = roleInput === UserRole.DERMATOLOGIST;

        return isCallbackAgentUser || isDermatologist;
    }

    private validatePassword() {
        const { passwordInput, repeatPasswordInput, roleInput } = this.state;

        const PASSWORD_HAS_NUMBER_REGEXP = /\d/;
        const PASSWORD_HAS_UPPERCASE_REGEXP = /[A-Z]/;
        const PASSWORD_HAS_SPECIAL_CHARACTER_REGEXP = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        const passwordMinLength = getPasswordMinLength(roleInput);

        if (passwordInput.length < passwordMinLength || repeatPasswordInput.length < passwordMinLength) {
            this.setState({
                errorDetails: [
                    {
                        path: "password",
                        message: `Password must contain a minimum of ${passwordMinLength} characters`,
                    },
                ],
                isError: true,
            });

            return false;
        }

        if (!PASSWORD_HAS_UPPERCASE_REGEXP.test(passwordInput)) {
            this.setState({
                errorDetails: [{ path: "password", message: "Password must contain at least one uppercase letter" }],
                isError: true,
            });

            return false;
        }

        if (!PASSWORD_HAS_NUMBER_REGEXP.test(passwordInput)) {
            this.setState({
                errorDetails: [{ path: "password", message: "Password must contain at least one number" }],
                isError: true,
            });

            return false;
        }

        if (!PASSWORD_HAS_SPECIAL_CHARACTER_REGEXP.test(passwordInput)) {
            this.setState({
                errorDetails: [{ path: "password", message: "Password must contain at least one special character" }],
                isError: true,
            });

            return false;
        }

        if (repeatPasswordInput !== passwordInput) {
            this.setState({
                errorDetails: [{ path: "password", message: "Passwords must be equal" }],
                isError: true,
            });

            return false;
        }

        return true;
    }

    /**
     * Inline validation for the surname field.
     * The OpenAPI definition marks surname as a required field, but with a minLength of 0.
     * This is because we have two use cases where surname can be empty:
     *  1) Creating a system user (for developer purposes)
     *  2) Registration flow - can create an account with an email address, surname is not required
     * @returns boolean
     */
    private validateSurnameLength(): boolean {
        const { surnameInput } = this.state;

        if (!surnameInput || surnameInput?.trim()?.length === 0) {
            return false;
        }
        return true;
    }

    private validateSubmit(): boolean {
        const { organisationInput } = this.state;
        if (organisationInput?.length === 0) {
            return false;
        }

        return [this.validatePassword(), this.validateSurnameLength()].every((validationResult) =>
            Boolean(validationResult)
        );
    }

    public render() {
        const { isError, errorDetails, availabilityInput, organisationDict, submitting } = this.state;

        const createUserFormContainerClassName = isError
            ? "create-user-container__form-container--error"
            : "create-user-container__form-container";

        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        // Workaround until backend will return uniform error form
        const passwordError = errorDetails.find(
            (err: any) => err.path === "password" || err.message.includes("Password")
        );
        const emailError = errorDetails.find((err: any) => err.path === ".body.email" || err.message.includes("email"));

        const showAdditionalFields = this.showCallbackAgentOrDermatologistFields();

        return (
            <Segment>
                <h2>Create new user</h2>
                <Divider />
                <div className="create-user-container">
                    <h5 className="create-user-container__form-validation" hidden={!isError}>
                        <p>Please fill all required fields</p>
                    </h5>
                    <div className={createUserFormContainerClassName}>
                        <Form onSubmit={this.submit}>
                            <Grid columns={1}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            error={emailError ? emailError.message : false}
                                            label="Email"
                                            type="text"
                                            id="emailInput"
                                            required
                                            onChange={this.onFieldChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            error={passwordError ? passwordError.message : false}
                                            label="Password"
                                            type="password"
                                            id="passwordInput"
                                            required
                                            onChange={this.onFieldChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            error={passwordError ? passwordError.message : false}
                                            label="Repeat password"
                                            type="password"
                                            id="repeatPasswordInput"
                                            required
                                            onChange={this.onFieldChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Select
                                            label="Title"
                                            id="titleInput"
                                            placeholder="Select user title"
                                            onChange={this.onFieldChange}
                                            options={Object.keys(UserTitle).map((key) => ({
                                                key,
                                                text: UserTitle[key],
                                                value: UserTitle[key],
                                            }))}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            label="Name"
                                            type="text"
                                            id="nameInput"
                                            required
                                            onChange={this.onFieldChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            label="Surname"
                                            type="text"
                                            id="surnameInput"
                                            required
                                            onChange={this.onFieldChange}
                                            minLength={1}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Select
                                            label="Role"
                                            id={ROLE_INPUT_ID}
                                            placeholder="Select user role"
                                            required
                                            onChange={this.onFieldChange}
                                            className="user-details-select"
                                            options={CREATEABLE_USER_ROLES.map((key) => ({
                                                key,
                                                text: UserRole[key],
                                                value: key,
                                            }))}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            label="Additional role info"
                                            type="text"
                                            id="additionalRoleInfoInput"
                                            required={false}
                                            onChange={this.onFieldChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                {showAdditionalFields && (
                                    <>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Form.Input
                                                    label="Phone number"
                                                    type="text"
                                                    id="phoneNumberInput"
                                                    required
                                                    onChange={this.onFieldChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Form.Dropdown
                                                    aria-label="Availability for case allocation"
                                                    label="Availability for case allocation"
                                                    id={AVAILABILITY_FIELD_TEXT}
                                                    fluid
                                                    onChange={this.onFieldChange}
                                                    selection
                                                    required
                                                    options={createDropdownOptions(DermatologistAvailabilityLabel)}
                                                    value={
                                                        availabilityInput ||
                                                        DermatologistAvailabilityEntities.AVAILABLE_FOR_CASE_ALLOCATION
                                                    }
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Form.Input
                                                    label="GMC number"
                                                    type="text"
                                                    id="gmcNumberInput"
                                                    required
                                                    onChange={this.onFieldChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </>
                                )}
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Dropdown
                                            className="organisation-select"
                                            search
                                            selection
                                            fluid
                                            multiple
                                            label="Organisation"
                                            id="organisationInput"
                                            placeholder="Select organisation"
                                            onChange={this.onFieldChange}
                                            options={organisationDict.map((org: IDictionaryItem, idx: number) => ({
                                                text: org.text,
                                                key: idx,
                                                value: org.value,
                                            }))}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="field">
                                            <span>Notes</span>
                                        </div>
                                        <CustomRichTextEditor
                                            onChange={this.onRichTextEditorChanged}
                                            name="notesInput"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={9}>
                                        <Button type="submit" loading={submitting} floated="right">
                                            Create
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </div>
                </div>
            </Segment>
        );
    }
}

export default CreateUserScreen;
