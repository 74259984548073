import React, { ComponentType, FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Placeholder } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ExceptionQuestions from "components/RemoteModel/modals/exceptionsQuestions/ExceptionQuestions";
import ButtonCloseModal from "components/RemoteModel/modals/ButtonCloseModal";

import withModal from "HOC/withModal";

import { IRegistrationQuestion, RegistrationQuestionType } from "model/registration";

import { getRegistration } from "redux/selectors/registration";
import { getPendingRequest } from "redux/selectors/data";

import registrationService from "services/registrationService";

export interface IExceptionsModalErrors {
    errors: string[];
    onCancel: () => void;
    onConfirm: () => void;
}

interface IExceptionsModal {
    answers?: IAnswers;
    modalTitle: string;
    registrationQuestionType: RegistrationQuestionType;
    onSubmit: (answers: IAnswers) => void;
    onResetFlow: () => void;
    onBack?: () => void;
    errorComponent: ComponentType<IExceptionsModalErrors>;
}

export interface IAnswers {
    [questionUuid: string]: string;
}

const QuestionsModal: FC<IExceptionsModal> = ({
    answers: prefilledAnswers = {},
    modalTitle,
    registrationQuestionType,
    onSubmit,
    onResetFlow,
    onBack,
    errorComponent: ErrorComponent,
}) => {
    const [questions, setQuestions] = useState<IRegistrationQuestion[]>([]);
    const registration = useSelector(getRegistration);
    const [answers, setAnswers] = useState<IAnswers>(prefilledAnswers);
    const [submit, setSubmit] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [requestError, setRequestError] = useState<string>("");

    const isPendingQuestionsFetchingRequest = useSelector(getPendingRequest);

    useEffect(() => {
        registrationService
            .getRegistrationQuestions(registration.organisationUuid, registrationQuestionType)
            .then((result) => {
                setQuestions(result);
            })
            .catch(({ message }) => setRequestError(message));
    }, []);

    const preparedQuestions = questions
        ?.filter((question: IRegistrationQuestion) => !question.removed)
        .sort((a: IRegistrationQuestion, b: IRegistrationQuestion) => a.questionOrder - b.questionOrder);

    const onAnswerChange = (uuid: string, answerUuid: string) =>
        setAnswers((prev) => ({ ...prev, [uuid]: answerUuid }));

    const validateAnswers = () => {
        let isValid = true;

        questions.forEach((question: IRegistrationQuestion) => {
            if (!question.required || question.removed) {
                return;
            }

            if (!answers[question.uuid]) {
                isValid = false;
            }
        });

        return isValid;
    };

    const handleSumbit = () => {
        setSubmit(true);

        if (!validateAnswers()) {
            return;
        }

        const preparedAnswers = Object.keys(answers).map((i) => ({
            questionUuid: i,
            answerUuid: answers[i],
        }));

        const finalAnswer = {
            registrationUuid: registration.registrationUuid,
            registrationAnswers: [...preparedAnswers],
            questionType: registrationQuestionType,
        };

        registrationService
            .sendRegistrationAnswers(finalAnswer)
            .then((result) => {
                if (result.blockingMessages.length) {
                    setErrors(result.blockingMessages);
                } else {
                    onSubmit(answers);
                }
            })
            .catch(({ message }) => setRequestError(message || "Something went wrong, try "));
    };

    const handleCancel = () => {
        setErrors([]);
    };

    if (errors?.length) {
        return <ErrorComponent errors={errors} onCancel={handleCancel} onConfirm={onResetFlow} />;
    }

    const disabled = !questions?.length;
    const nextButtonText = onBack ? "OK" : "Continue";

    return (
        <div className="remote-model__modal remote-model__modal-questions">
            <ButtonCloseModal onClick={onResetFlow} />
            <p className="h2">{modalTitle}</p>

            {isPendingQuestionsFetchingRequest ? (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            ) : (
                <>
                    <ExceptionQuestions
                        preparedQuestions={preparedQuestions}
                        onAnswerChange={onAnswerChange}
                        answers={answers}
                        requestError={requestError}
                        submit={submit}
                    />

                    <div className="buttons-wrapper">
                        {onBack && (
                            <CustomButton
                                disabled={disabled}
                                type="button"
                                variant="empty-dark"
                                action={onBack}
                                text="Back"
                            />
                        )}
                        <CustomButton
                            disabled={disabled}
                            type="button"
                            variant="filled"
                            action={handleSumbit}
                            text={nextButtonText}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

QuestionsModal.defaultProps = {
    answers: null,
    onBack: () => undefined,
};

export default withModal(QuestionsModal);
