import { IFormError } from "model/formError";

import types from "redux/actions/types";

const INITIAL_STATE = {
    errors: null,
};

const formErrorReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case types.FORM_ERROR:
            return {
                ...state,
                errors: action.payload as IFormError,
            };
        case types.CLEAN_ERRORS:
        case types.CLEAR_DATA:
        case types.CLEAN_FORM_ERROR:
            return {
                ...state,
                errors: null,
            };
        default:
            return state;
    }
};

export default formErrorReducer;
