import { Component } from "react";
import { Table } from "semantic-ui-react";

import CaseSummaryRow from "components/CaseDescription/Summary/CaseSummaryRow";
import NonSkinCancerLesionTable from "components/templates/NonSkinCancerLesionTable";

import { ICase } from "model/case";
import { CaseViewMode } from "model/caseViewMode";

import "scss/Table.scss";

interface ICaseSummaryTableProps {
    caseObject: ICase;
    mode: CaseViewMode;
}

class CaseSummaryTable extends Component<ICaseSummaryTableProps> {
    private getCaseLesionsTableBody(caseObject: ICase) {
        const { mode } = this.props;

        if (caseObject.lesions.length) {
            return caseObject.lesions.map((lesion, index) => (
                <CaseSummaryRow
                    key={lesion.uuid}
                    lesion={lesion}
                    lesionNumber={index}
                    mode={mode}
                    nonSkinCancer={caseObject.nonSkinCancer}
                />
            ));
        }

        return (
            <div className="case-summary-no-lesions">
                <h1> No Lesions found </h1>
            </div>
        );
    }

    public render() {
        const { caseObject, mode } = this.props;
        const allowedRoles = [
            CaseViewMode.CLIENT_ADMIN_REMOTE_VIEW,
            CaseViewMode.ADMINVIEW,
            CaseViewMode.REVIEWVIEW,
            CaseViewMode.REVIEW,
            CaseViewMode.SUPERADMINVIEW,
            CaseViewMode.VIEW,
            CaseViewMode.CALLBACK_AGENT_REVIEW,
        ];
        const canRender = allowedRoles.includes(mode);
        const isNonSkinCancer = caseObject.nonSkinCancer;

        const lesionTitle = isNonSkinCancer ? "SKIN COMPLAINT" : "LESION";
        const managementTitle = isNonSkinCancer ? "MANAGEMENT PLAN" : "MANAGEMENT OUTCOME";

        if (caseObject.lesions && mode !== CaseViewMode.REVIEW) {
            return (
                canRender && (
                    <Table celled className="assessment-summary-table">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}>{lesionTitle}</Table.HeaderCell>
                                <Table.HeaderCell width={2}>ASSESSED BY</Table.HeaderCell>
                                <Table.HeaderCell width={2}>ANALYSIS</Table.HeaderCell>
                                <Table.HeaderCell width={5}>{managementTitle}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{this.getCaseLesionsTableBody(caseObject)}</Table.Body>
                    </Table>
                )
            );
        }

        if (mode === CaseViewMode.REVIEW && isNonSkinCancer) {
            return <NonSkinCancerLesionTable lesion={caseObject.lesions[0]} />;
        }

        if (mode !== CaseViewMode.REVIEW) {
            return <h2> No Lesions </h2>;
        }

        return null;
    }
}

export default CaseSummaryTable;
