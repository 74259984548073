import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";

import CustomErrorMessage from "components/ErrorMessage/CustomErrorMessage";

import { formatMobileNumber, UK_NUMBER_MAX_LENGTH, validateMobileNumber } from "helpers/patientUtils";

import { MFAButtons, OTP_LENGTH, otpGenericError } from "model/mfa";

import { getPendingRequest } from "redux/selectors/data";

import userService from "services/userService";

import "scss/PhoneNumberRegistration.scss";

interface Input {
    countryCode: string;
    mobileNumber: string;
}

const PhoneNumberRegistration: FC = () => {
    const [hasAPIError, setHasAPIError] = useState<boolean>(false);
    const loading = useSelector(getPendingRequest);
    const defaultValues = useMemo(() => ({ defaultValues: { mobileNumber: "", countryCode: "+44" } }), []);

    const {
        clearErrors,
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        setError,
        setValue,
    } = useForm<Input>(defaultValues);

    const handleMobileNumberValidation = (): boolean => {
        clearErrors();
        const mobileNumber = getValues("mobileNumber");
        const { isError, message } = validateMobileNumber(mobileNumber, UK_NUMBER_MAX_LENGTH);
        if (!isError) {
            return true;
        }
        setError("mobileNumber", { message });
        return false;
    };

    /**
     * Hack to prevent user entering full number in mui-tel-input
     */
    const handleCountryCodeChange = (value: string, info: MuiTelInputInfo): void => {
        if (info.numberValue === `+${info.countryCallingCode}`) {
            setValue("countryCode", `+${info.countryCallingCode}`);
        }
    };

    const onSubmitInvalid: SubmitErrorHandler<Input> = (): void => {
        handleMobileNumberValidation();
    };

    const onSubmit: SubmitHandler<Input> = async (data: Input): Promise<void> => {
        const { countryCode, mobileNumber } = data;
        const concatenatedMobileNumber = formatMobileNumber(mobileNumber, countryCode);

        try {
            setHasAPIError(false);
            clearErrors();
            await userService.initiateSMSVerification(concatenatedMobileNumber);
        } catch (error: any) {
            if (!errors?.mobileNumber) {
                setHasAPIError(true);
            }
        }
    };

    return (
        <Box>
            <h2>Now, enter your mobile number</h2>
            <p>We will send you a {OTP_LENGTH}-digit code to verify your mobile number</p>
            <Box
                className="full-width phone-number-registration-container"
                component="form"
                onSubmit={handleSubmit(onSubmit, onSubmitInvalid)}
            >
                <Box className="phone-number-registration-form">
                    <Controller
                        name="countryCode"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <FormControl className="country-code-control">
                                <MuiTelInput
                                    {...field}
                                    className="country-code-selector"
                                    onChange={(value, info) => handleCountryCodeChange(value, info)}
                                    defaultCountry="GB"
                                    label="Country code"
                                    focusOnSelectCountry
                                    color="primary"
                                    contentEditable={false}
                                />
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="mobileNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: true,
                            onBlur: handleMobileNumberValidation,
                            validate: handleMobileNumberValidation,
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mobile-number-input"
                                id="mobile-number"
                                name="mobileNumber"
                                value={getValues("mobileNumber")}
                                label="Mobile number"
                                error={!!errors?.mobileNumber?.message}
                                helperText={errors?.mobileNumber?.message}
                                inputProps={{
                                    "aria-atomic": "true",
                                    "aria-label": "Enter your mobile number",
                                    "aria-invalid": errors?.mobileNumber ? "true" : "false",
                                    "inputMode": "numeric",
                                }}
                                variant="outlined"
                            />
                        )}
                    />
                </Box>
                <Box className="mobile-number-error">
                    {hasAPIError ? <CustomErrorMessage message={otpGenericError.message} /> : null}
                </Box>
                <Box className="mfa-button-wrapper float-bottom">
                    <Button className="full-width" type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress size="16px" /> : MFAButtons.CONTINUE}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default PhoneNumberRegistration;
