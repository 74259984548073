import { IAuditConfiguration, IAuditConfigurationState } from "model/auditConfig";
import { IFormError } from "model/formError";
import { AllocationValue } from "model/organisation";

/**
 * Audit configuration
 */

export enum AuditConfigurationActionTypes {
    SET_LOADING = "SET_LOADING",
    SET_LOADED_ORGANISATION = "SET_LOADED_ORGANISATION",
    TOGGLE_AUDIT_CONFIGURATION = "TOGGLE_AUDIT_CONFIGURATION",
    TOGGLE_INDIVIDUAL_AUDIT = "TOGGLE_INDIVIDUAL_AUDIT",
    SET_AUDIT_CRITERIA = "SET_AUDIT_CRITERIA",
    SET_AUDIT_ALLOCATION = "SET_AUDIT_ALLOCATION",
    CREATE_AUDIT_CONFIGURATION = "CREATE_AUDIT_CONFIGURATION",
    TOGGLE_AUTOMATED_DECISION_WARNING_MODEL = "TOGGLE_AUTOMATED_DECISION_WARNING_MODEL",
    TOGGLE_DISABLE_AUDIT_ROUTING_MODAL = "TOGGLE_DISABLE_AUDIT_ROUTING_MODAL",
    DISABLE_AUDIT_ROUTING_AND_CONFIGURATIONS = "DISABLE_AUDIT_ROUTING_AND_CONFIGURATIONS",
    AUDIT_CONFIG_ERROR = "AUDIT_CONFIG_ERROR",
}

/**
 * Actions
 */

export interface SetLoading {
    type: AuditConfigurationActionTypes.SET_LOADING;
    payload: {
        loading: boolean;
    };
}

export interface SetLoadedOrganisation {
    type: AuditConfigurationActionTypes.SET_LOADED_ORGANISATION;
    payload: IAuditConfigurationState;
}

export interface ToggleAuditConfiguration {
    type: AuditConfigurationActionTypes.TOGGLE_AUDIT_CONFIGURATION;
    payload: {
        showAutomatedDecisionConsent: boolean;
    };
}

export interface ToggleAuditConfigurationRow {
    type: AuditConfigurationActionTypes.TOGGLE_INDIVIDUAL_AUDIT;
    payload: {
        uuid: string;
    };
}

export interface SetAuditCriteria {
    type: AuditConfigurationActionTypes.SET_AUDIT_CRITERIA;
    payload: {
        uuid: string;
        percentage: number;
    };
}

export interface SetAuditAllocation {
    type: AuditConfigurationActionTypes.SET_AUDIT_ALLOCATION;
    payload: {
        uuid: string;
        allocation: AllocationValue;
    };
}

export interface CreateAuditConfiguration {
    type: AuditConfigurationActionTypes.CREATE_AUDIT_CONFIGURATION;
    payload: {
        configuration: IAuditConfiguration[];
    };
}

export interface DisableAuditRoutingAndConfigurations {
    type: AuditConfigurationActionTypes.DISABLE_AUDIT_ROUTING_AND_CONFIGURATIONS;
}

export interface AuditConfigurationError {
    type: AuditConfigurationActionTypes.AUDIT_CONFIG_ERROR;
    payload: {
        error: Partial<IFormError>[];
    };
}

/**
 * Audit modals
 */

export interface ToggleAutomatedDecisionWarningModal {
    type: AuditConfigurationActionTypes.TOGGLE_AUTOMATED_DECISION_WARNING_MODEL;
}

export interface ToggleDisablingAuditRoutingModal {
    type: AuditConfigurationActionTypes.TOGGLE_DISABLE_AUDIT_ROUTING_MODAL;
}

export type AuditConfigurationAction =
    | SetLoading
    | SetLoadedOrganisation
    | ToggleAuditConfiguration
    | ToggleAuditConfigurationRow
    | SetAuditCriteria
    | SetAuditAllocation
    | CreateAuditConfiguration
    | ToggleAutomatedDecisionWarningModal
    | ToggleDisablingAuditRoutingModal
    | DisableAuditRoutingAndConfigurations
    | AuditConfigurationError;
