import LocalStorageKeys from "model/localStorageKeys";

import {
    IMAGE_CAPTURE,
    LESION_LOCATOR,
    LOGIN,
    MEDICAL_HISTORY,
    NEW_ASSESSMENT,
    PATIENT_DETAILS,
    READY_FOR_ASSESSMENT,
    RESET_PASSWORD,
    UPLOADED_IMAGES,
} from "navigation/routes";
import {
    REMOTE_MODEL_REGISTRATION,
    REMOTE_MODEL_CASES_IN_REVIEW,
    REMOTE_MODEL_CASES_KIT_DELIVERY,
    REMOTE_MODEL_CASES_LESION_DETAILS,
    REMOTE_MODEL_CASES_LESION_IMAGES,
    REMOTE_MODEL_CASES_MEDICAL_HISTORY,
    REMOTE_MODEL_CASES_START,
    REMOTE_MODEL_CASES_SUMMARY,
    REMOTE_MODEL_CONSENT,
    REMOTE_MODEL_CASES_REPORT,
} from "navigation/remoteModelRoutes";
import { history } from "App";

import store from "redux/store";

import * as caseService from "services/caseService";
import logoutTimeoutService from "services/logoutTimeoutService";

const LAST_PAGES_BLACKLIST = [LOGIN, RESET_PASSWORD];

export class LocalStorageService {
    private static halfHour = 30 * 60 * 1000;

    public static addItem = (key: string, value: any) => {
        localStorage.setItem(key, value);
    };

    public static getItem = (key: string) => localStorage.getItem(key);

    public static setLastUrl = () => {
        const { pathname } = window.location;
        if (!LAST_PAGES_BLACKLIST.includes(pathname)) {
            this.addItem(LocalStorageKeys.LAST_URL_KEY, pathname);
            this.addItem(LocalStorageKeys.LAST_URL_DATE_KEY, String(new Date().getTime()));
        }
    };

    public static getLastUrl = () => {
        const lastUrlDate = localStorage.getItem(LocalStorageKeys.LAST_URL_DATE_KEY);
        if (lastUrlDate) {
            const urlDate = Number(lastUrlDate);
            const dateNow = new Date().getTime();

            if (dateNow - urlDate < this.halfHour) {
                const lastUrl = this.getItem(LocalStorageKeys.LAST_URL_KEY) || "";
                const handled = this.handleAssessmentFlow(store.store.dispatch, lastUrl);
                this.cleanLastUrl();
                return handled ? "" : lastUrl;
            }
            this.cleanLastUrl();
            return undefined;
        }
        return undefined;
    };

    public static removeItem = (key: string) => {
        localStorage.removeItem(key);
    };

    public static cleanLastUrl = () => {
        this.removeItem(LocalStorageKeys.LAST_URL_KEY);
        this.removeItem(LocalStorageKeys.LAST_URL_DATE_KEY);
    };

    public static handleAssessmentFlow = (dispatch: any, lastUrl: string) => {
        if (lastUrl) {
            const assessmentFlowRoutes = [
                NEW_ASSESSMENT,
                PATIENT_DETAILS,
                MEDICAL_HISTORY,
                LESION_LOCATOR,
                READY_FOR_ASSESSMENT,
                IMAGE_CAPTURE,
                UPLOADED_IMAGES,
                REMOTE_MODEL_CONSENT,
                REMOTE_MODEL_REGISTRATION,
                REMOTE_MODEL_CASES_START,
                REMOTE_MODEL_CASES_MEDICAL_HISTORY,
                REMOTE_MODEL_CASES_KIT_DELIVERY,
                REMOTE_MODEL_CASES_LESION_DETAILS,
                REMOTE_MODEL_CASES_LESION_IMAGES,
                REMOTE_MODEL_CASES_SUMMARY,
                REMOTE_MODEL_CASES_IN_REVIEW,
                REMOTE_MODEL_CASES_REPORT,
            ];
            const isAssessmentFlow = assessmentFlowRoutes.includes(lastUrl);
            if (isAssessmentFlow) {
                const newCaseUuid = this.getItem(LocalStorageKeys.CURRENT_CASE_UUID_KEY);
                if (newCaseUuid) {
                    dispatch(caseService.continueAssessment(newCaseUuid)).then(() => {
                        logoutTimeoutService.stopCount();
                        history.push(lastUrl);
                    });
                }
                return true;
            }
        }
        return false;
    };

    public static setCurrentCaseUuid = (uuid: string) => {
        this.addItem(LocalStorageKeys.CURRENT_CASE_UUID_KEY, uuid);
    };

    public static removeCurrentCaseUuid = () => {
        this.removeItem(LocalStorageKeys.CURRENT_CASE_UUID_KEY);
    };
}

export default new LocalStorageService();
