import React, { FC } from "react";
import { Form } from "semantic-ui-react";

const workingDaysLabel = {
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
    SAT: "Saturday",
    SUN: "Sunday",
};

const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

interface IOrganisationWorkingDaysProps {
    workingDays: string[];
    setWorkingDays: (workingDays: string[]) => void;
}

const OrganisationWorkingDays: FC<IOrganisationWorkingDaysProps> = ({ workingDays, setWorkingDays }) => {
    const onChange = (event: any, data: any) => {
        const { checked, id } = data;

        if (checked) {
            setWorkingDays([...workingDays, id]);
        } else {
            setWorkingDays(workingDays.filter((item: string) => item !== id));
        }
    };

    return (
        <Form>
            {days.map((workingDay: string) => (
                <Form.Checkbox
                    id={workingDay}
                    key={workingDay}
                    label={workingDaysLabel[workingDay]}
                    checked={workingDays.includes(workingDay)}
                    onChange={onChange}
                />
            ))}
        </Form>
    );
};

export default OrganisationWorkingDays;
