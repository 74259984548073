import store from "redux/store";
import * as actions from "redux/actions";

function setNextWizardStep() {
    return (dispatch: any) => {
        if (store.store.getState().data.organisation.type === "STUDY") {
            const { currentCapturDevice } = store.store.getState().assessment.captureImageWizard;
            const devicesCount = store.store.getState().assessment.devices.length;
            if (currentCapturDevice < devicesCount) {
                dispatch(
                    actions.assessmentActions.captureImageNextStep({
                        currentCapturDevice: currentCapturDevice + 1,
                        finished: false,
                    })
                );
                return undefined;
            }
            return dispatch(
                actions.assessmentActions.captureImageNextStep({
                    finished: true,
                })
            );
        }
        return dispatch(
            actions.assessmentActions.captureImageNextStep({
                finished: true,
            })
        );
    };
}

function resetWizard() {
    return (dispatch: any) => {
        dispatch(actions.assessmentActions.captureImageReset());
    };
}

export default {
    resetWizard,
    setNextWizardStep,
};
