import React, { Component } from "react";
import {
    Button,
    Checkbox,
    Divider,
    DropdownProps,
    Form,
    Grid,
    Header,
    Segment,
    Select,
    Table,
} from "semantic-ui-react";

import StickyOrganisationTopPanel from "components/Administration/OrganisationManagment/ViewOrganisation/StickyOrganisationTopPanel";
import HistoryQuestionRow from "components/Administration/OrganisationManagment/ViewOrganisation/HistoryQuestions/HistoryQuestionRow";
import ContextIconButton from "components/templates/ContextIconButton";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";
import CustomButton from "components/templates/Button";

import { scrollBottom } from "helpers/page";

import { OrganisationManagementTabs } from "model/administrationPages";
import QuestionSubTypeEnum from "model/questionSubType";
import {
    IHistoryAnswerType,
    IHistoryQuestionType,
    IMedicalHistoryQuestion,
    IOrganisation,
    SkinToneAnswers,
    SkinToneSupplementaryInformation,
} from "model/organisation";

import questionService from "services/questionService";

import "scss/OrganisationDetails.scss";

interface IEditHistoryQuestionComponentProps {
    title: string;
    historyQuestions?: IMedicalHistoryQuestion[];
    questionType: IHistoryQuestionType;
    error?: any;
    organisation: IOrganisation;
    updateOrganisation: (result: any) => void;
    showEditButton: boolean;
    addQuestion?: (edit: any) => void;
    deleteQuestion?: (uuid: string) => void;
}

interface INewOption {
    option: string;
    additionalInformation: boolean;
}

class EditHistoryQuestionComponent extends Component<IEditHistoryQuestionComponentProps, any> {
    private static isOptionField(selectedAnswerType: IHistoryAnswerType) {
        const isOptionField = [
            IHistoryAnswerType.CHECKBOX,
            IHistoryAnswerType.OPTION,
            IHistoryAnswerType.OPTIONS,
        ].includes(selectedAnswerType);
        return isOptionField;
    }

    private dropdownOptions = [
        { key: 1, value: "custom", text: "Custom" },
        { key: 2, value: QuestionSubTypeEnum.SKIN_TYPE, text: "Fitzpatrick Skin Type question" },
    ];

    constructor(props: any) {
        super(props);
        const { historyQuestions, questionType } = props;
        this.state = {
            createMode: false,
            questionSubtype: "",
            dropdownSelectedValue: "",
            edit: {},
            editMode: true,
            errors: {
                newAnswerType: false,
                newOption: false,
                newOptions: false,
                newOrder: false,
                newQuestion: false,
            },
            historyQuestions: historyQuestions
                ? historyQuestions
                      ?.filter((question: IMedicalHistoryQuestion) => !question.removed)
                      .sort(
                          (a: IMedicalHistoryQuestion, b: IMedicalHistoryQuestion) => a.questionOrder - b.questionOrder
                      )
                : [],
            isMedicalHistoryPage: questionType === IHistoryQuestionType.MEDICAL,
        };
    }

    private getHistoryQuestionsTable() {
        return (
            <div className="horizontal-scroll">
                <Table padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={3}>Question</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Options</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Order</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Answer type</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Required</Table.HeaderCell>
                            <Table.HeaderCell width={1} />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.getHistoryQustionsTableBody()}</Table.Body>
                </Table>
            </div>
        );
    }

    private getAddNewQuestionTable() {
        const { edit, submitting } = this.state;
        const { newAnswerType } = edit;
        const isTextAnswerType = newAnswerType === IHistoryAnswerType.TEXT;

        return (
            <div>
                <Table padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={5}>Question</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Order</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Answer type</Table.HeaderCell>
                            <Table.HeaderCell width={3}>
                                {isTextAnswerType ? "Neutral value" : "Options"}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}>Required</Table.HeaderCell>
                            <Table.HeaderCell width={1} />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.addNewHistoryQuestion()}</Table.Body>
                </Table>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={16} />
                        <Grid.Column floated="right" width={3}>
                            <Button loading={submitting} onClick={this.createNewHistoryQuestion}>
                                Add new question
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    private getHistoryQustionsTableBody() {
        const { organisation } = this.props;
        const { historyQuestions } = this.state;

        if (historyQuestions && historyQuestions.length > 0) {
            return historyQuestions
                .sort((a: IMedicalHistoryQuestion, b: IMedicalHistoryQuestion) => a.questionOrder - b.questionOrder)
                .map((historyQuestion: any) => (
                    <HistoryQuestionRow
                        key={historyQuestion.uuid}
                        historyQuestion={historyQuestion}
                        onRemove={this.onRemoveRow}
                        organisation={organisation}
                    />
                ));
        }
        return (
            <Table.Row key="none">
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
            </Table.Row>
        );
    }

    private getNewOptionsField() {
        const { edit, errors } = this.state;
        const { newAnswerType } = edit;

        if (EditHistoryQuestionComponent.isOptionField(newAnswerType)) {
            return (
                <Grid columns={2}>
                    {edit.newOptions
                        ? edit.newOptions.map((option: any) => (
                              <Grid.Row key={option.option}>
                                  <Grid.Column width={13}>
                                      <Grid.Row>{option.option}</Grid.Row>
                                      <Grid.Row className="row-margin">
                                          <Checkbox
                                              disabled
                                              type="checkbox"
                                              checked={option.significant}
                                              label="is significant"
                                          />
                                      </Grid.Row>
                                  </Grid.Column>
                                  <Grid.Column width={1}>
                                      <ContextIconButton
                                          onClick={this.removeOption}
                                          iconName="trash"
                                          arg={option.option}
                                      />
                                  </Grid.Column>
                              </Grid.Row>
                          ))
                        : ""}
                    <Grid.Row key="newOption">
                        <Grid.Column width={13}>
                            <Grid.Row className="option">
                                <Form.Input
                                    error={errors.newOption}
                                    style={{ width: 130 }}
                                    value={edit.newOption ? edit.newOption.option : ""}
                                    id="newOption"
                                    onChange={this.onNewHistoryQuestionChange}
                                />
                            </Grid.Row>
                            <Grid.Row className="row-margin">
                                <Checkbox
                                    onChange={this.onNewHistoryQuestionChange}
                                    id="isSignificant"
                                    type="checkbox"
                                    checked={edit.newOption?.significant || false}
                                    label="is significant?"
                                />
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column width={1}>
                            <ContextIconButton onClick={this.addOption} iconName="add" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }
        if (newAnswerType === IHistoryAnswerType.TEXT) {
            return (
                <Form.Input
                    error={errors.newNeutralValue}
                    type="text"
                    id="newNeutralValue"
                    onChange={this.onNewHistoryQuestionChange}
                    value={edit.newNeutralValue}
                    className="small-input-text"
                />
            );
        }
        return "-";
    }

    private changeMode = () => {
        const { createMode } = this.state;

        this.setState({ createMode: !createMode });
        this.setState({ questionSubtype: "" });

        if (!createMode) {
            scrollBottom();
        }
    };

    private handleDropdownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
        this.setState({ dropdownSelectedValue: data.value });
    };

    // TODO: do we want to include ms shared as a dependency?
    private submitNewField = (): void => {
        const { dropdownSelectedValue } = this.state;

        // Use callback in setState
        this.setState((prevState) => ({
            questionSubtype: prevState.dropdownSelectedValue,
        }));

        if (dropdownSelectedValue && dropdownSelectedValue === QuestionSubTypeEnum.SKIN_TYPE) {
            this.setState({
                edit: {
                    newAnswerType: "OPTION",
                    newQuestion: "Response to sun exposure (Fitzpatrick)",
                    questionSubType: QuestionSubTypeEnum.SKIN_TYPE,
                    newOptions: Object.keys(SkinToneAnswers).map((key, index) => ({
                        optionOrder: index + 1,
                        option: SkinToneAnswers[key],
                        supplementaryText: SkinToneSupplementaryInformation[key],
                    })),
                },
                submitting: false,
                dropdownSelectedValue: "",
            });
        } else {
            this.setState({
                edit: {},
                submitting: false,
                dropdownSelectedValue: "",
            });
        }
    };

    private createNewHistoryQuestion = () => {
        const { addQuestion, questionType } = this.props;
        const { historyQuestions, edit } = this.state;
        const { newNeutralValue, newRequired, newQuestion, newOrder, newOptions, newAnswerType, questionSubType } =
            edit;

        if (!this.validateSubmit()) {
            this.setState({ submitting: true });
            const { organisation } = this.props;
            const { uuid, parentUuid } = organisation;
            questionService
                .createQuestion(uuid, {
                    answerType: newAnswerType,
                    neutralValue: newNeutralValue,
                    options: newOptions,
                    order: Number(newOrder),
                    question: newQuestion,
                    questionType,
                    questionSubType,
                    required: newRequired || false,
                })
                .then((response) => {
                    response.question.questionOrder = response.question.order;
                    if (parentUuid) {
                        response.question.copyOfUuid = null;
                    }
                    historyQuestions.push(response.question);
                    this.setState({
                        edit: {
                            newAnswerType: "",
                            newOrder: "",
                            newQuestion: "",
                            newRequired: false,
                        },
                        historyQuestions,
                        submitting: false,
                    });

                    if (addQuestion) {
                        addQuestion(response.question);
                    }
                    this.setState({ createMode: false });
                })

                .catch(() => {
                    this.setState({ submitting: false });
                });
        }
    };

    private removeOption = (event: any, obj: any) => {
        const { edit } = this.state;

        const newOptions = edit.newOptions?.filter(({ option }: { option: string }) => option !== obj.arg);
        const newEdit = edit;
        newEdit.newOptions = newOptions;
        this.setState({ edit: newEdit });
    };

    private addOption = () => {
        const { edit } = this.state;

        const newOptions = edit.newOptions || [];
        const withAdditionalInformation = edit.newAnswerType !== IHistoryAnswerType.TEXT;
        if (!this.validateNewOption(edit.newOption)) {
            newOptions.push({
                ...edit.newOption,
                additionalInformation: withAdditionalInformation,
            });
            edit.newOption = { option: "" };
            edit.newOptions = newOptions;
            this.setState({ edit });
        }
    };

    private onNewOrderChange = (event: any, obj: any) => {
        this.onNewHistoryQuestionChange(event, obj);
        this.validateNewOrder(obj.value);
    };

    private onNewQuestionChange = (event: any, obj: any) => {
        this.onNewHistoryQuestionChange(event, obj);
        this.validateNewQuestion();
    };

    private onNewAnswerTypeChange = (event: any, obj: any) => {
        this.onNewHistoryQuestionChange(event, obj);
        this.validateNewAnswerType(obj.value);
    };

    private onNewHistoryQuestionChange = (event: any, obj: any) => {
        const { edit } = this.state;

        const newOptionName = "newOption";
        const fieldName = obj.id;
        const isNewOption = ["newOption", "additionalInformation", "isSignificant"].includes(fieldName);
        const fieldValue = obj.type === "checkbox" ? obj.checked : obj.value;
        const stateObj = edit;
        const stateObjectName = isNewOption ? newOptionName : fieldName;
        stateObj[stateObjectName] = isNewOption
            ? {
                  optionOrder: 1,
                  ...stateObj[newOptionName],
                  ...(obj.type === "checkbox" && obj.id === "isSignificant" ? { significant: obj.checked } : {}),
                  ...(obj.type === "text" ? { option: fieldValue } : {}),
              }
            : fieldValue;

        this.setState({ edit: stateObj });
    };

    private onRemoveRow = (event: any, obj: any) => {
        const { deleteQuestion } = this.props;
        const { historyQuestions } = this.state;
        const filteredHistoryQuestion = historyQuestions?.filter((item: any) => item.uuid !== obj.uuid);

        this.setState({ historyQuestions: filteredHistoryQuestion });

        if (deleteQuestion) {
            deleteQuestion(obj.uuid);
        }
    };

    private addNewHistoryQuestion() {
        const { editMode, saving, errors, edit, questionSubtype } = this.state;
        const isFPQuestion = questionSubtype && questionSubtype === QuestionSubTypeEnum.SKIN_TYPE;

        if (editMode && !saving) {
            return (
                <>
                    <Table.Row key="newHistoryQuestion" id="newHistoryQuestion" verticalAlign="top">
                        <Table.Cell>
                            <Form.Input
                                error={errors.newQuestion}
                                type="text"
                                id="newQuestion"
                                disabled={isFPQuestion}
                                value={edit.newQuestion}
                                onChange={this.onNewQuestionChange}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Form.Input
                                error={errors.newOrder}
                                type="number"
                                id="newOrder"
                                onChange={this.onNewOrderChange}
                                value={edit.newOrder}
                                className="small-input-text"
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Form.Select
                                error={errors.newAnswerType}
                                id="newAnswerType"
                                placeholder="Select type"
                                onChange={this.onNewAnswerTypeChange}
                                disabled={isFPQuestion}
                                value={edit.newAnswerType}
                                options={Object.keys(IHistoryAnswerType).map((key) => ({
                                    key,
                                    text: IHistoryAnswerType[key],
                                    value: key,
                                }))}
                            />
                        </Table.Cell>
                        <Table.Cell>{isFPQuestion ? "-" : this.getNewOptionsField()}</Table.Cell>
                        <Table.Cell>
                            <Form.Checkbox
                                error={errors.newRequired}
                                style={{ marginTop: 7, marginLeft: 20 }}
                                id="newRequired"
                                onChange={this.onNewHistoryQuestionChange}
                                checked={edit.newRequired}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan="5">
                            <b>Notes:</b> Use the TEXT answer type for a purely free text response. All other answer
                            types allow the user to record free text notes in a box alongside the response options. Mark
                            a response option as significant if it should appear in the summary of significant responses
                            shown to reviewers and in the report.
                        </Table.Cell>
                    </Table.Row>
                </>
            );
        }
        return <></>;
    }

    private validateNewOption(newOption: INewOption) {
        const { errors, edit } = this.state;

        if (!newOption?.option?.trim().length) {
            errors.newOption = "Field can not be empty";
        } else if (
            edit.newOptions &&
            edit.newOptions.some((option: INewOption) => option.option === newOption.option)
        ) {
            errors.newOption = "Option need to be unique";
        } else {
            errors.newOption = false;
        }

        this.setState({ errors });

        return errors.newOption;
    }

    private validateNewOrder(fieldValue: string | number) {
        const { errors, historyQuestions } = this.state;

        if (fieldValue === "" || !fieldValue) {
            errors.newOrder = "Field can not be empty";
        } else if (
            historyQuestions.find((item: IMedicalHistoryQuestion) => item.questionOrder === Number(fieldValue))
        ) {
            errors.newOrder = "Order need to be unique";
        } else {
            errors.newOrder = false;
        }

        this.setState({ errors });
    }

    private validateNewQuestion() {
        const { errors, edit } = this.state;

        if (!edit.newQuestion || edit.newQuestion.option === "") {
            errors.newQuestion = "Field can not be empty";
        } else {
            errors.newQuestion = false;
        }

        this.setState({ errors });
    }

    private validateNewOptions(answerType: IHistoryAnswerType, values: INewOption[]) {
        const { errors } = this.state;

        if (EditHistoryQuestionComponent.isOptionField(answerType) && values === undefined) {
            errors.newOption = "Question require at least one option";
            this.setState({ errors });
        }
    }

    private validateNewAnswerType(value: string) {
        const { errors } = this.state;

        if (value === "" || !value) {
            errors.newAnswerType = "Field can not be empty";
        } else {
            errors.newAnswerType = false;
        }

        this.setState({ errors });
    }

    private validateSubmit() {
        const { edit, errors } = this.state;

        this.validateNewOrder(edit.newOrder);
        this.validateNewQuestion();
        this.validateNewAnswerType(edit.newAnswerType);
        this.validateNewOptions(edit.newAnswerType, edit.newOptions);

        return errors.newOrder || errors.newQuestion || errors.newAnswerType || errors.newOption;
    }

    private addNewQuestion() {
        const { createMode, isMedicalHistoryPage, questionSubtype, dropdownSelectedValue } = this.state;
        const { organisation } = this.props;

        const doesOrganisationHaveFPQuestion = organisation.medicalHistoryQuestions
            ?.filter((question) => !question.removed)
            .find((question) => question?.questionSubType === QuestionSubTypeEnum.SKIN_TYPE);

        if (!createMode) {
            return null;
        }

        if (isMedicalHistoryPage) {
            if (questionSubtype === "" && !doesOrganisationHaveFPQuestion) {
                return (
                    <>
                        <h3>Create new question</h3>
                        <Grid.Row>
                            <div className="new-personal-data-field-wrapper">
                                <Select
                                    label="Question type"
                                    placeholder="Select from the available fields below"
                                    className="new-personal-data-field"
                                    value={dropdownSelectedValue}
                                    options={this.dropdownOptions}
                                    onChange={this.handleDropdownChange}
                                />
                                <CustomButton
                                    variant="filled"
                                    type="submit"
                                    action={this.submitNewField}
                                    text="Add field"
                                />
                            </div>
                        </Grid.Row>
                    </>
                );
            }
            return (
                <>
                    <div style={{ marginTop: "1.2pt" }}>
                        <Header as="h4">Add another field</Header>
                    </div>
                    <Divider />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>{this.getAddNewQuestionTable()}</Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            );
        }

        return (
            <>
                <div style={{ marginTop: "1.2pt" }}>
                    <Header as="h4">Add another field</Header>
                </div>
                <Divider />
                <Grid>
                    <Grid.Row>
                        <Grid.Column>{this.getAddNewQuestionTable()}</Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }

    private renderErrorMessage() {
        const { error } = this.props;

        if (error) {
            return (
                <h5 hidden={!error} className="error-message">
                    <p>{error.message}</p>
                </h5>
            );
        }
        return <></>;
    }

    public render() {
        const { organisation, title, updateOrganisation, showEditButton } = this.props;
        const { createMode } = this.state;

        return (
            <Segment>
                <StickyOrganisationTopPanel title={title}>
                    <OrganisationManagementTabHeader
                        organisationUuid={organisation.uuid}
                        type={OrganisationManagementTabs.QUESTIONS}
                        showEditButton={showEditButton}
                        updateOrganisation={updateOrganisation}
                        createMode={createMode}
                        changeMode={this.changeMode}
                    />
                </StickyOrganisationTopPanel>

                {this.renderErrorMessage()}

                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>{this.getHistoryQuestionsTable()}</Grid.Column>
                    </Grid.Row>
                </Grid>

                {this.addNewQuestion()}
            </Segment>
        );
    }
}

export default EditHistoryQuestionComponent;
