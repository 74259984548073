import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Grid, Header, Segment } from "semantic-ui-react";

import { NoteType, NoteTypeTitle } from "model/reviewNotes";
import { ICase, IReview, IReviewNote } from "model/case";
import { IOrganisation } from "model/organisation";

import AddNotesButton from "components/CaseDescription/Review/AddNotesButton";

import { formatTimeWithHours } from "helpers/datetime";

import { getOrganisation } from "redux/selectors/data";

interface ICaseNotesGroup {
    renderAddNotesButton: boolean;
    updateCaseNotes: (type: NoteType, note: string) => void;
    type: NoteType;
    caseObject: ICase;
}

const CaseNotesGroup: FC<ICaseNotesGroup> = ({ renderAddNotesButton, updateCaseNotes, type, caseObject }) => {
    const organisation: IOrganisation = useSelector(getOrganisation);

    const { uuid: caseUuid, patientUuid } = caseObject;

    const findOrganisation = (organisationUuid: string) => {
        const currentReview = caseObject?.reviews?.find(
            (review: IReview) => review.organisationUuid === organisationUuid
        );
        return currentReview?.creatorOrganisationName || organisation.name;
    };

    const getCreatorOrganisation = (note: IReviewNote, organisationUuid: string) =>
        note.creatorOrganisationName || findOrganisation(organisationUuid);

    const getNotes = (notes: IReviewNote[]) =>
        notes?.map((note) => {
            const creatorOrganisation = getCreatorOrganisation(note, note.organisationUuid);
            const patientNotesCreateDetails = `Captured by ${
                note.createdByName
            } from ${creatorOrganisation} on ${formatTimeWithHours(note.lastModificationDate)}`;

            return (
                <Grid.Row key={note.uuid}>
                    <Grid.Column width={16}>
                        <p className="subtitle-gray-text assessed-by-text">{patientNotesCreateDetails}</p>
                        <Segment className="note">
                            <span>{note.note}</span>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            );
        });

    const getCaseNotes = () => {
        let notes: IReviewNote[] = [];

        switch (type) {
            case NoteType.CLINICAL: {
                notes = caseObject.clinicalNotes;
                break;
            }
            case NoteType.PATIENT: {
                notes = caseObject.patientNotes;
                break;
            }
            case NoteType.ADMIN: {
                notes = caseObject.adminNotes;
                break;
            }
            default: {
                break;
            }
        }

        const nonRemovedNotes = notes?.filter((note) => !note.removed);

        return getNotes(nonRemovedNotes);
    };

    return (
        <>
            <div className="case-notes">
                <Grid columns={2} verticalAlign="middle">
                    <Grid.Column width={8}>
                        <Header as="h4" className="without-bottom-margin">
                            {NoteTypeTitle[type]}
                        </Header>
                    </Grid.Column>
                    {renderAddNotesButton && (
                        <Grid.Column width={8} textAlign="right">
                            <AddNotesButton
                                text={`Add ${NoteTypeTitle[type]}`}
                                type={type}
                                patientUuid={patientUuid}
                                caseUuid={caseUuid}
                                updateCaseNotes={updateCaseNotes}
                            />
                        </Grid.Column>
                    )}
                </Grid>
                {getCaseNotes() || (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment>No note recorded</Segment>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </div>
        </>
    );
};

export default CaseNotesGroup;
