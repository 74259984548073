import React from "react";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import moment from "moment";

import { IPatientLookupForm, IPatientLookupMatched, PDSFields } from "model/patientLookup";

import PatientLookupFormControl from "components/PatientLookup/PatientLookupFormControl";

import { mapSavedPatientToFormData } from "services/patientLookupService";

import { getPatient } from "redux/selectors/assessment";

interface IPatientMatch {
    matchedPatient: IPatientLookupMatched;
    combination: number;
    goBack: () => void;
    next: (patientDetails: IPatientLookupMatched | undefined) => void;
    isSupersededNhsNumber: boolean;
}

interface ITableRow {
    label: string;
    value: string;
    highlightValue?: boolean;
}

export default function PatientMatch({
    matchedPatient,
    combination,
    goBack,
    next,
    isSupersededNhsNumber,
}: IPatientMatch) {
    const { name, surname, birth, nhs, postCode, gender } = matchedPatient;
    const patient = useSelector(getPatient);

    const isPDSVerified = patient?.integrationInfo?.integrationName;
    const savedPatientDetails = mapSavedPatientToFormData(patient?.patientData);

    const TableRow = ({ label, value, highlightValue }: ITableRow): JSX.Element => (
        <tr>
            <td>{label}</td>
            <td>{highlightValue ? <b>{value}</b> : value}</td>
        </tr>
    );
    const shouldHighlightDifference = (PDSPatientDatumType: keyof IPatientLookupForm): boolean => {
        const savedPatientDatum = savedPatientDetails?.[PDSFields[PDSPatientDatumType]];
        const PDSPatientDatum = matchedPatient?.[PDSFields[PDSPatientDatumType]];

        if (isPDSVerified || !savedPatientDatum) {
            return false;
        }

        let highlightDifference = false;

        switch (PDSPatientDatumType) {
            case PDSFields.name:
            case PDSFields.surname:
                highlightDifference = savedPatientDatum.toLowerCase() !== PDSPatientDatum.toLowerCase();
                break;
            case PDSFields.dateOfBirth:
                highlightDifference = moment(savedPatientDatum).isSame(PDSPatientDatum);
                break;
            case PDSFields.nhsNumber:
                highlightDifference = savedPatientDatum === PDSPatientDatum;
                break;
            default:
                break;
        }

        return highlightDifference;
    };

    const highlightName = shouldHighlightDifference(PDSFields.name);
    const highlightSurname = shouldHighlightDifference(PDSFields.surname);
    const highlightDOB = shouldHighlightDifference(PDSFields.dateOfBirth);
    const highlightNHSNumber = shouldHighlightDifference(PDSFields.nhsNumber);

    const showDataDifferenceMessage =
        !isPDSVerified && (highlightName || highlightSurname || highlightDOB || highlightNHSNumber);

    return (
        <div className="patient-match">
            <div className="icon">
                <CheckOutlinedIcon fontSize="large" />
            </div>
            <h3>Patient found in the NHS database</h3>
            <p>
                {`${showDataDifferenceMessage ? "Some details differ from those already saved." : ""} Make sure the
                details correspond to the patient you are attending. Otherwise go back to amend your search.`}
            </p>
            {isSupersededNhsNumber ? (
                <>
                    <br />
                    <Alert severity="info">
                        <p>
                            The NHS number you provided has been superseded. We will use the patient&apos;s superseding
                            NHS number for creating this case.
                        </p>
                    </Alert>
                </>
            ) : null}
            <div className="table-container">
                <table className="patient-table">
                    <tbody>
                        <TableRow label="First name" value={name} highlightValue={highlightName} />
                        <TableRow label="Surname" value={surname} highlightValue={highlightSurname} />
                        <TableRow
                            label="Date of birth"
                            value={moment(birth).format("DD/MM/YYYY")}
                            highlightValue={highlightDOB}
                        />
                        {combination === 2 && <TableRow label="Postcode" value={postCode} />}
                        {combination === 2 && <TableRow label="Gender" value={gender} />}
                        <TableRow label="NHS number" value={nhs} highlightValue={highlightNHSNumber} />
                    </tbody>
                </table>
            </div>
            <PatientLookupFormControl
                button1Name="Back"
                button1OnClick={goBack}
                button2Name="Continue"
                button2OnClick={() => next(matchedPatient)}
            />
        </div>
    );
}
