import React from "react";
import { Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import "scss/Dialog.scss";

interface IReallocatedCaseDialogProps {
    showDialog: boolean;
    onClose: () => void;
}

const ReallocatedCaseDialog = (props: IReallocatedCaseDialogProps) => {
    const { onClose, showDialog } = props;

    const bodyText = "This case is no longer allocated to you for review. Please click on Home to return to Case list.";
    const title = "Allocation changed";

    const onConfirm = () => {
        onClose();
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>{bodyText}</Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                yesButton={{
                    backgroundColor: "#2185D0",
                    fontColor: "#FFFFFF",
                    onClick: onConfirm,
                    text: "Home",
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default ReallocatedCaseDialog;
