import React, { FC, useState, useEffect, SyntheticEvent } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DropdownProps, TextAreaProps } from "semantic-ui-react";

import LesionLocation from "components/LesionLocation";
import Questionary from "components/Questionary";
import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";
import RemoteModelContainer from "components/templates/RemoteModelContainer";
import DiscardLesionModal from "components/RemoteModel/modals/DiscardLesionModal";

import { doesLesionContainNeededImages } from "helpers/assessment";
import { noteExceedsLimit } from "helpers/noteValidation";
import { generateAnswers } from "helpers/questionary";

import { IMedicalHistoryQuestion, IQuestionaryAnswers } from "model/organisation";
import { IQuestionaryError } from "model/questionaryError";
import { ILesion } from "model/case";
import { IMedicalHistoryAnswers } from "model/assessment";
import { BodyLocations } from "model/bodyLocations";
import { RemoteTimeline } from "model/remoteCaseTimeline";

import { history } from "App";
import {
    REMOTE_MODEL_CASES_LESION_IMAGES,
    REMOTE_MODEL_CASES_SUMMARY,
    REMOTE_MODEL_CASES_KIT_DELIVERY,
} from "navigation/remoteModelRoutes";

import { getAssessment } from "redux/selectors/assessment";
import { getOrganisation, getPendingRequest } from "redux/selectors/data";

import lesionService from "services/lesionService";
import assessmentService from "services/assessmentService";

const READER_UPDATE_WAITING_TIME = 2000;

interface ILocation {
    location: BodyLocations | null;
    locationNotes: string;
}

interface IParams {
    lesionIndex: string;
}

const LesionDetails: FC = () => {
    const [location, setLocation] = useState<ILocation>({
        location: null,
        locationNotes: "",
    });
    const [errors, setErrors] = useState<IQuestionaryError>({
        formError: true,
        isInitial: true,
        list: [],
        showError: false,
    });
    const [answers, setAnswers] = useState<IQuestionaryAnswers>({});
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const assessment = useSelector(getAssessment);
    const organisation = useSelector(getOrganisation);
    const { lesionIndex: lesionIndexParameter } = useParams<IParams>();
    const lesionIndex = Number(lesionIndexParameter) || assessment.currentLesion + 1;
    const { lesionHistoryQuestions = [] } = organisation;
    const { case: currentCase, currentLesion } = assessment;

    useEffect(() => {
        if (currentCase && currentCase.lesions) {
            const lesion: ILesion = currentCase.lesions[lesionIndex ? +lesionIndex - 1 : currentLesion];
            if (lesion) {
                const { lesionHistory = null, exclusions = null, bodyPart, description } = lesion;
                if (lesionHistory && bodyPart) {
                    const currentAnswers = generateAnswers(lesionHistory, lesionHistoryQuestions, exclusions);
                    setLocation({ location: bodyPart, locationNotes: description });
                    setAnswers(currentAnswers);
                }
            }
        }
    }, []);

    const lesionUuid = assessment.lesion?.uuid;
    const currentIndexLesion = lesionIndex ? +lesionIndex - 1 : currentLesion;
    const editedLesion: ILesion = currentCase?.lesions?.[currentIndexLesion];

    useEffect(() => {
        if (lesionUuid && !currentCase.lesions) {
            history.push(REMOTE_MODEL_CASES_LESION_IMAGES);
        }
    }, [lesionUuid]);

    const isRequestPending = useSelector(getPendingRequest);
    const dispatch = useDispatch();

    const changeLocation = (e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps | TextAreaProps) => {
        setLocation({ ...location, [data.id]: data.value });
        if (!isEdited) {
            setIsEdited(true);
        }
    };

    const lesionQuestions = lesionHistoryQuestions
        .filter((question: IMedicalHistoryQuestion) => !question.removed)
        .sort((a, b) => a.questionOrder - b.questionOrder);

    const questionsSets: { questions: IMedicalHistoryQuestion[] }[] = [{ questions: lesionQuestions }];

    const updateAnswers = (updatedAnswers: IQuestionaryAnswers) => {
        setAnswers(updatedAnswers);
        if (!isEdited) {
            setIsEdited(true);
        }
    };

    const submit = ({ mainData }: { mainData: IMedicalHistoryAnswers[] }) => {
        const { lesion, patient } = assessment;
        const { uuid: patientUuid } = patient;
        const { uuid: currentCaseUuid, lesions } = currentCase;
        if (!lesion || !lesions || !editedLesion) {
            if (currentCaseUuid && patientUuid && !errors.formError) {
                dispatch(
                    lesionService.createLesion(
                        currentCaseUuid,
                        patientUuid,
                        location.location,
                        location.locationNotes,
                        mainData
                    )
                );
            }
        } else if (isEdited) {
            dispatch(
                lesionService.updateLesion(
                    editedLesion.uuid,
                    currentCaseUuid,
                    patientUuid,
                    location.location,
                    location.locationNotes,
                    mainData
                )
            );
        }

        if (lesions) {
            setIsPending(true);
            const isContainsNeededImages = editedLesion ? doesLesionContainNeededImages(editedLesion.images) : false;
            if (isContainsNeededImages) {
                setTimeout(() => {
                    history.push(REMOTE_MODEL_CASES_SUMMARY);
                    setIsPending(false);
                }, READER_UPDATE_WAITING_TIME);
            } else {
                history.push(REMOTE_MODEL_CASES_LESION_IMAGES);
            }
        }
    };

    const onBackClicked = () => {
        if (lesionIndex === 1) {
            history.push(REMOTE_MODEL_CASES_KIT_DELIVERY);
        } else if (editedLesion) {
            history.push(REMOTE_MODEL_CASES_SUMMARY);
        } else {
            setShowModal(true);
        }
    };

    const handleModalCancel = () => {
        setShowModal(false);
    };

    const handleModalConfirm = () => {
        const descreasedLesionNumber = assessment.currentLesion - 1;
        dispatch(assessmentService.updateLesionNumberAction(descreasedLesionNumber));
        history.push(REMOTE_MODEL_CASES_SUMMARY);
    };

    return (
        <>
            <CaseStatusContainer activeStatus={RemoteTimeline.LESION_DETAILS} />
            <RemoteModelContainer header={`Lesion #${lesionIndex || 1} Details`}>
                <LesionLocation
                    onChange={changeLocation}
                    values={location}
                    errors={errors}
                    updateErrors={setErrors}
                    disabled={false}
                    isNonSkinCancerFlow={false}
                    isRemoteModel
                />
                <Questionary
                    questionSets={questionsSets}
                    errors={errors}
                    updateAnswers={updateAnswers}
                    answers={answers}
                    updateErrors={setErrors}
                    backButton={{
                        text: "Back",
                        action: onBackClicked,
                    }}
                    nextButtons={[
                        {
                            action: submit,
                            isLoading: isRequestPending || isPending,
                            isDisabled: isRequestPending || isPending || noteExceedsLimit(location.locationNotes),
                            text: "Continue",
                            type: "submit",
                        },
                    ]}
                    isRemoteModel
                />
            </RemoteModelContainer>
            <DiscardLesionModal
                show={showModal}
                onCancel={handleModalCancel}
                onConfirm={handleModalConfirm}
                lesionNumber={lesionIndex}
            />
        </>
    );
};

export default LesionDetails;
