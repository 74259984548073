export enum UserRole {
    ADMIN = "ADMIN",
    SA_ADMIN = "SA_ADMIN",
    SUPERADMIN = "SUPERADMIN",
    PATIENT = "PATIENT",
    CLINICIAN = "CLINICIAN",
    DERMATOLOGIST = "DERMATOLOGIST",
    CALLBACK_AGENT = "CALLBACK_AGENT",
    CLINICAL_VIEWER = "CLINICAL_VIEWER",
}

export enum UserRoleFilter {
    ALL = "ALL",
    ADMIN = "ADMIN",
    SA_ADMIN = "SA ADMIN",
    SUPERADMIN = "SUPERADMIN",
    PATIENT = "PATIENT",
    CLINICIAN = "CLINICIAN",
    DERMATOLOGIST = "DERMATOLOGIST",
    CALLBACK_AGENT = "CALLBACK_AGENT",
    CLINICAL_VIEWER = "CLINICAL_VIEWER",
}

export enum TargetUserGroup {
    ADMIN = "ADMIN",
    SA_ADMIN = "SA ADMIN",
    SUPERADMIN = "SUPERADMIN",
    CLINICIAN = "CLINICIAN",
    DERMATOLOGIST = "ALLOCATED DERMATOLOGIST",
    CASE_CREATOR = "CASE CREATOR",
    SUPPORT = "SUPPORT",
}

export enum NotificationRole {
    CASE_CREATOR = "caseCreator",
    LESION_CREATOR = "lesionCreator",
    SAFETY_NET_DERMATOLOGIST = "safetyNetDermatologist",
    CLIENT_DERMATOLOGIST = "clientDeramtologist",
    ADMIN = "admin",
    ORGANISATION_EMAIL = "organisationEmail",
    SA_SUPPORT = "saSupport",
}

export enum NotificationRoleDisplay {
    caseCreator = "Case creator",
    lesionCreator = "Lesion creator",
    safetyNetDermatologist = "Safety NetDermatologist",
    clientDermatologist = "Client Dermatologist",
    admin = "Client Org Admin",
    organisationEmail = "Organisation Email",
    saSupport = "SA Support",
}

export enum SkinTypeNoteOriginator {
    CLINICIAN = "Clinician",
    PATIENT = "Patient",
    SYSTEM = "Patient",
}
