import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { IDevice } from "model/assessment";

import * as actions from "redux/actions";

import URLs from "services/urls";

const { GET_STUDIES, GET_STUDY_DEVICES, GET_ALL_STUDIES, CREATE_STUDY, CREATE_DEVICE, DELETE_DEVICE } = URLs;

const MAX_LIMIT = 500; // elasticsearch limit of single query size

async function getLoggedUserStudies() {
    const response: any = await AuthorizedHttpConnector.sendGet(GET_STUDIES);
    return response.data.data;
}

async function getStudyDevices(studyUuid: string, limit = 10, offset = 0) {
    try {
        const getStudyDevicesUrl = GET_STUDY_DEVICES.replace("{{OFFSET}}", String(offset))
            .replace("{0}", studyUuid)
            .replace("{{LIMIT}}", String(limit));
        const response = await AuthorizedHttpConnector.sendGet(getStudyDevicesUrl);
        return response.data;
    } catch (err: any) {
        return err.response.data;
    }
}

function getUserDevices() {
    return (dispatch: any) => {
        getLoggedUserStudies()
            .then((studies) => {
                const selectedStudyCase = studies.pop();
                dispatch(actions.assessmentActions.setStudies(selectedStudyCase));
                return getStudyDevices(selectedStudyCase.uuid, MAX_LIMIT);
            })
            .then((response) => {
                const devices = response.data;
                dispatch(actions.assessmentActions.setDevices(devices));
            })
            .catch((err: any) => {
                console.error(err);
            });
    };
}

async function getUserDevicesAsync(dispatch: any) {
    try {
        const studies = await getLoggedUserStudies();
        const selectedStudyCase = studies.pop();
        dispatch(actions.assessmentActions.setStudies(selectedStudyCase));

        if (selectedStudyCase) {
            const response = await getStudyDevices(selectedStudyCase.uuid, MAX_LIMIT);
            const devices = response.data;
            dispatch(actions.assessmentActions.setDevices(devices));
        }
    } catch (err: any) {
        console.error(err);
    }
}

async function getAllStudies(offset: number, searchParameters?: string[]) {
    const searchQuery = searchParameters.length ? `&search=${searchParameters?.join(";")}` : "";

    try {
        const response = await AuthorizedHttpConnector.sendGet(
            GET_ALL_STUDIES.replace("{{OFFSET}}", String(offset) + searchQuery)
        );
        return response.data;
    } catch (err: any) {
        return err.response.data;
    }
}

async function createStudy(name: string, organisationUuid: string) {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_STUDY, {
        name,
        organisationUuid,
    });
    return response.data;
}

async function createDevice(device: IDevice) {
    const response = await AuthorizedHttpConnector.sendPost(CREATE_DEVICE, device);
    return response.data;
}

async function deleteDevice(deviceUuid: string) {
    const response = await AuthorizedHttpConnector.sendDelete(DELETE_DEVICE.replace("{{UUID}}", deviceUuid));
    return response.data;
}

export default {
    createDevice,
    createStudy,
    getAllStudies,
    getLoggedUserStudies,
    getStudyDevices,
    getUserDevices,
    getUserDevicesAsync,
    deleteDevice,
};
