import { FC, useContext } from "react";
import { Grid, Segment } from "semantic-ui-react";

import { ORGANISATION_INHERITANCE_PROPERTY } from "components/Administration/OrganisationManagment/ViewOrganisation/OrganisationDetailsScreen";
import CreateDiagnosesScreen from "components/Administration/OrganisationManagment/ViewOrganisation/Review/CreateDiagnosesScreen";
import DiagnosesTable from "components/Administration/OrganisationManagment/ViewOrganisation/Review/DiagnosesTable";
import ReviewSettingsContext from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ReviewSettingsProvider";
import StickyOrganisationTopPanel from "components/Administration/OrganisationManagment/ViewOrganisation/StickyOrganisationTopPanel";
import EscalationScreen from "components/Administration/OrganisationManagment/ViewOrganisation/ReviewNsc/EscalationScreen";
import OrganisationManagementTabHeader from "components/templates/OrganisationManagementTabHeader";

import { OrganisationManagementTabs, OrganisationMenuItems } from "model/administrationPages";
import { IOrganisation } from "model/organisation";
import { ReviewSettingMode, ReviewType } from "model/reviewType";

interface IReviewSettingsNscScreenProps {
    organisation: IOrganisation | undefined;
    showEditButton: boolean;
    updateInheritanceData: (property: string) => void;
}

const ReviewSettingsNscScreen: FC<IReviewSettingsNscScreenProps> = ({
    organisation,
    showEditButton,
    updateInheritanceData,
}) => {
    const { state: reviewSettingsData, dispatchers } = useContext(ReviewSettingsContext);

    const isNonSkinCancerAllowed = reviewSettingsData.organisation.nonSkinCancerAllowed;

    const isViewMode = reviewSettingsData.reviewSettingMode === ReviewSettingMode.VIEW;
    const isCreateMode = reviewSettingsData.reviewSettingMode === ReviewSettingMode.CREATE;

    const updateOrganisation = (result: any): void => {
        const updatedOrganisation = { ...organisation };
        const { diagnoses, managementOptions } = result;

        updatedOrganisation.reviewManagementOptions = managementOptions;
        updatedOrganisation.reviewDiagnoses = diagnoses;

        dispatchers.setLoadedOrganisation(organisation);
        updateInheritanceData(ORGANISATION_INHERITANCE_PROPERTY[OrganisationMenuItems.REVIEW_SETTINGS]);
    };

    const onChangeMode = (): void => {
        if (reviewSettingsData.organisation) {
            switch (reviewSettingsData.reviewSettingMode) {
                case ReviewSettingMode.CREATE: {
                    dispatchers.changeReviewSettingMode(ReviewSettingMode.VIEW);
                    break;
                }
                case ReviewSettingMode.VIEW: {
                    dispatchers.changeReviewSettingMode(ReviewSettingMode.CREATE);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };

    return (
        <Segment>
            <StickyOrganisationTopPanel title="Review diagnoses for other skin conditions">
                <OrganisationManagementTabHeader
                    organisationUuid={organisation ? organisation.uuid : ""}
                    type={OrganisationManagementTabs.REVIEW_SETTINGS}
                    showEditButton={showEditButton}
                    updateOrganisation={updateOrganisation}
                    createMode={!isViewMode}
                    changeMode={onChangeMode}
                />
            </StickyOrganisationTopPanel>

            {isViewMode && (
                <Grid>
                    {isNonSkinCancerAllowed && (
                        <>
                            <Grid.Row>
                                <Grid.Column>
                                    <h4>Other skin conditions pathway Assessment</h4>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <DiagnosesTable
                                        diagnosisType={ReviewType.NON_SKIN_CANCER}
                                        reviewDiagnoses={reviewSettingsData.reviewDiagnoses}
                                        reviewManagementOptions={reviewSettingsData.reviewManagementOptions}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                </Grid>
            )}

            {isCreateMode && (
                <CreateDiagnosesScreen
                    allowedTypes={[ReviewType.NON_SKIN_CANCER]}
                    mode={reviewSettingsData.reviewSettingMode}
                />
            )}

            <EscalationScreen
                organisation={organisation}
                showEditButton
                updateInheritanceData={updateInheritanceData}
            />
        </Segment>
    );
};

export default ReviewSettingsNscScreen;
