import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Divider, Segment } from "semantic-ui-react";

import DeviceList from "components/Administration/StudyManagment/EditStudy/DeviceList";
import LoadingSpinner from "components/templates/LoadingSpinner";

import { UserRole } from "model/userRole";

import userService from "services/userService";

class EditStudyScreen extends Component<any, any> {
    constructor(props: any) {
        super(props);

        const { params } = this.props;

        this.state = {
            studyUuid: params.uuid,
        };
    }

    private getDeviceList() {
        const { studyUuid } = this.state;

        return <DeviceList studyUuid={studyUuid} />;
    }

    public render() {
        const { organisation } = this.state;

        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        if (organisation === null) {
            return <LoadingSpinner />;
        }

        return (
            <Segment>
                <h2>Study Details</h2>
                <Divider />
                {this.getDeviceList()}
            </Segment>
        );
    }
}

export default EditStudyScreen;
