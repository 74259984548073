import { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

import { ModalIconAltTexts, ModalIcons, ModalProps } from "model/modal";

const Modal: FC<ModalProps> = ({ type, cta, ctaText, children }) => (
    <div className="remote-model__modal">
        <Image alt={ModalIconAltTexts[type]} src={`/images/icons/${ModalIcons[type]}.svg`} />
        {children}
        {cta && (
            <div className="buttons-wrapper">
                <CustomButton type="button" variant="filled" action={cta} text={ctaText || "Confirm"} />
            </div>
        )}
    </div>
);

export default withModal(Modal);
