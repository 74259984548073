import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Form, Header, Image, Segment } from "semantic-ui-react";

import getUserOrganisationUuids from "helpers/userUtils";

import { IOrganisationData } from "model/organisation";
import { IUser } from "model/user";

import { getUser } from "redux/selectors/data";

import * as authorizationService from "services/authorizationService";

import "scss/OrganisationSelection.scss";

const OrganisationSelection: FC = () => {
    const [selectedOrg, setSelectedOrg] = useState<string>(undefined);
    const user: IUser = useSelector(getUser);
    const dispatch = useDispatch();

    const getOrganisations = (): IOrganisationData[] => {
        const { organisationName, organisationUuid, additionalOrganisationData = [], linkedUser } = user;
        const primaryOrganisation: IOrganisationData = { organisationName, organisationUuid };
        const organisations = [primaryOrganisation, ...additionalOrganisationData];

        if (linkedUser && !linkedUser.removed) {
            const { linkedUserOrganisationUuid, linkedUserOrganisationName } = linkedUser;
            const userOrgUuids = getUserOrganisationUuids(user);

            if (!userOrgUuids.includes(linkedUserOrganisationUuid)) {
                organisations.push({
                    organisationUuid: linkedUserOrganisationUuid,
                    organisationName: linkedUserOrganisationName,
                });
            }
        }

        return organisations;
    };

    const organisations = getOrganisations();

    const handleContinue = (): void => {
        dispatch(authorizationService.switchUserOrganisation(selectedOrg));
    };

    return (
        <Container className="organisation-selection-container">
            <Segment>
                <Header as="h3">
                    Welcome back {user.name} {user.surname}
                </Header>
                <p>Select where you will be working today</p>
                <div className="organisation-list">
                    <Form>
                        {organisations.map((org) => (
                            <Segment
                                as={Button}
                                onClick={() => setSelectedOrg(org.organisationUuid)}
                                className={org.organisationUuid === selectedOrg ? "selected" : ""}
                                key={org.organisationUuid}
                                aria-label={`Select and login under ${org.organisationName}`}
                            >
                                <div>
                                    <p>{org.organisationName}</p>
                                </div>
                                <Image alt="check-icon" src="/images/icons/icon-round-check.svg" />
                            </Segment>
                        ))}
                    </Form>
                </div>
                <div className="continue-button-container">
                    <Button tabIndex={0} disabled={!selectedOrg} onClick={handleContinue}>
                        Continue
                    </Button>
                </div>
            </Segment>
        </Container>
    );
};

export default OrganisationSelection;
