import { FC } from "react";
import { Table } from "semantic-ui-react";
import clsx from "clsx";

import PersonalDataRow from "components/Administration/OrganisationManagment/ViewOrganisation/PersonalData/PersonalDataRow";
import CreateFieldRow from "components/Administration/OrganisationManagment/ViewOrganisation/PersonalData/CreateFieldRow";
import EmptyRow from "components/templates/EmptyRow";

import { IDefinedPersonalData, INewDefinedPersonalData, IOrganisationPersonalData } from "model/organisation";

interface ICoreFieldsTable {
    organisationUuid: string;
    parentOrganisationUuid: string;
    newCustomCoreField: boolean;
    isEditable: boolean;
    organisationPersonalData: IOrganisationPersonalData;
    onFieldRemoved: (uuid: string) => void;
    onFieldAdded: (obj: INewDefinedPersonalData) => Promise<void>;
    hideNewCustomCoreField: () => void;
}

const CoreFieldsTable: FC<ICoreFieldsTable> = ({
    organisationUuid,
    parentOrganisationUuid,
    newCustomCoreField,
    isEditable,
    organisationPersonalData,
    onFieldRemoved,
    onFieldAdded,
    hideNewCustomCoreField,
}) => {
    const { definedPersonalData } = organisationPersonalData;
    const organisationHasPersonalDataDefined = definedPersonalData.length > 0;
    const showEmptyRow = !organisationHasPersonalDataDefined && !newCustomCoreField;
    const showCreateFieldRow = Boolean(newCustomCoreField);
    const { isOrganisationDataFetched, patientDetailsIntegrationType } = organisationPersonalData;
    const withIntegrationEnabled = isOrganisationDataFetched && !!patientDetailsIntegrationType;

    return (
        <Table padded className={clsx(showCreateFieldRow && "additional-bottom-margin")}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={2}>Property Name</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Display Name</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Request at manual patient creation</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Display Order</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Type</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Required</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Identifier</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Validation</Table.HeaderCell>
                    {withIntegrationEnabled && (
                        <>
                            <Table.HeaderCell width={1}>Integration source system</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Integration source property</Table.HeaderCell>
                        </>
                    )}

                    <Table.HeaderCell width={1} />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {showEmptyRow ? (
                    <EmptyRow cellNumber={7} />
                ) : (
                    definedPersonalData.map((data: IDefinedPersonalData) => (
                        <PersonalDataRow
                            key={data.uuid}
                            personalData={data}
                            organisationUuid={organisationUuid}
                            parentOrganisationUuid={parentOrganisationUuid}
                            definedPersonalData={definedPersonalData}
                            isEditable={isEditable}
                            isIntegrationEnabled={withIntegrationEnabled}
                            onRemove={onFieldRemoved}
                        />
                    ))
                )}
                {showCreateFieldRow && (
                    <CreateFieldRow
                        definedPersonalData={definedPersonalData}
                        isIntegrationEnabled={withIntegrationEnabled}
                        onRowAdded={onFieldAdded}
                        hideNewCustomCoreField={hideNewCustomCoreField}
                    />
                )}
            </Table.Body>
        </Table>
    );
};

export default CoreFieldsTable;
