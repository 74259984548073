import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import * as actions from "redux/actions";

import URLs from "services/urls";

const { CREATE_DIAGNOSIS_URL, UPDATE_DIAGNOSIS_URL } = URLs;

function createDiagnosis(caseUuid: string, lesionUuid: string, studyUuid: string, diagnosisObj: any) {
    return (dispatch: any) => {
        AuthorizedHttpConnector.sendPost(CREATE_DIAGNOSIS_URL, { caseUuid, lesionUuid, studyUuid, ...diagnosisObj })
            .then((response) => {
                dispatch(actions.assessmentActions.createDiagnosisSuccess(response.data.data));
            })
            .catch(() => {
                // dispatch(actions.authActions.loginFail(err.response.data.errors))
            });
    };
}

function updateDiagnosis(diagnosisUuid: string, pathway: string) {
    return (dispatch: any) => {
        const url = UPDATE_DIAGNOSIS_URL.replace("{uuid}", diagnosisUuid);
        AuthorizedHttpConnector.sendPut(url, { updatedPathway: pathway })
            .then((response) => {
                dispatch(actions.assessmentActions.updateDiagnosisSuccess(response.data.data));
            })
            .catch(() => {
                // dispatch(actions.authActions.loginFail(err.response.data.errors))
            });
    };
}

export { createDiagnosis, updateDiagnosis };
