import getEmailAddressesFromTarget from "helpers/notifications";

import { ICase } from "model/case";
import { NotificationType, IOrganisation } from "model/organisation";

export const markersDefinitionTemplates = {
    caseId: {
        description: "The application will insert the caseId of currently displayed case in this place",
        pattern: "{{CASE_ID}}",
    },
    caseOrganisationName: {
        description: "The application will insert the case creator's organisation name in this place",
        pattern: "{{CASE_ORGANISATION_NAME}}",
    },
    organisationName: {
        description: "The application will insert the organisation name of logged user in this place",
        pattern: "{{ORGANISATION_NAME}}",
    },
    pdfReportReceivers: {
        description: "The application will insert the emails of users that will be notified about pdf report",
        pattern: "{{PDF_REPORT_RECEIVERS}}",
    },
};

export const markersDefinitionNotifications = {
    caseId: {
        description: "The application will insert the caseId of currently displayed case in this place",
        pattern: "{{CASE_ID}}",
    },
    caseOrganisationName: {
        description: "The application will insert the case creator's organisation name in this place",
        pattern: "{{ORGANISATION_NAME}}",
    },
    caseLink: {
        description: "The application will insert the link to the case in this place",
        pattern: "{{CASE_LINK}}",
    },
    location: {
        description: "The application will insert the location in this place",
        pattern: "{{LOCATION}}",
    },
    dermatologist: {
        description: "The application will insert assigned dermatologist in this place",
        pattern: "{{DERMATOLOGIST}}",
    },
    admin: {
        description: "The application will insert admin who allocated the case in this place",
        pattern: "{{ADMIN}}",
    },
};

function fillTextWithPdfReportReveivers(text: string, organisation: IOrganisation, creatorEmail: string) {
    let newText: string = text;

    if (newText.includes(markersDefinitionTemplates.pdfReportReceivers.pattern)) {
        const pdfReportNoficicationConfiguration = organisation.notificationConfiguration?.find(
            (notification) =>
                notification.notificationType === NotificationType.reportGenerated && !notification.removed
        );
        const pdfReportReceiversEmails = pdfReportNoficicationConfiguration
            ? getEmailAddressesFromTarget(pdfReportNoficicationConfiguration.targets, creatorEmail).join(", ")
            : "nobody";
        newText = newText.replace(markersDefinitionTemplates.pdfReportReceivers.pattern, pdfReportReceiversEmails);
        return newText;
    }
    return newText;
}

export default function fillTextMarkers(text: string, caseObject: ICase, organisation: IOrganisation) {
    const { createdByEmail, caseId } = caseObject;
    let newText: string = text;

    newText = newText.replace(markersDefinitionTemplates.caseId.pattern, caseId);
    newText = newText.replace(markersDefinitionTemplates.organisationName.pattern, organisation.name);
    newText = newText.replace(markersDefinitionTemplates.caseOrganisationName.pattern, caseObject.organisationName);

    newText = fillTextWithPdfReportReveivers(newText, organisation, createdByEmail);

    return newText;
}

export const supportedMarkersTemplates = Object.values(markersDefinitionTemplates);
export const supportedMarkersNotifications = Object.values(markersDefinitionNotifications);
