import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Button, Container, Divider, Icon } from "semantic-ui-react";

import "scss/ErrorPage.scss";

interface IErrorPage {
    errorText: string;
    errorObject?: any;
}

const ErrorPage: FC<IErrorPage> = ({ errorText, errorObject }) => {
    const [isActive, setActive] = useState(false);

    const handleShowDebugInfo = (e: any, titleProps: any) => {
        const { active } = titleProps;
        setActive(!active);
    };

    const parseDebugObject = (error?: any) => {
        const newError = error;

        if (newError) {
            if (newError.response) {
                return JSON.stringify(newError.response.data);
            }

            delete newError.stack;
            delete newError.config;
            return JSON.stringify(newError);
        }
        return undefined;
    };

    return (
        <Container className="ui segment wizzard-container">
            <div className="error-message-div">
                <Icon name="bug" size="massive" />
            </div>
            <div className="error-message-div">
                <h1>We are sorry to inform... but an error has occurred</h1>
            </div>
            <Divider />
            <div className="error-message-div">
                <p>{errorText}</p>
            </div>

            <Divider />
            {errorObject && (
                <Accordion>
                    <Accordion.Title
                        active={isActive}
                        index={0}
                        onClick={handleShowDebugInfo}
                        content="Debug information"
                    />
                    <Accordion.Content active={isActive}>
                        <textarea className="debug-text-area" rows={5}>
                            {parseDebugObject(errorObject)}
                        </textarea>
                    </Accordion.Content>
                </Accordion>
            )}

            <div className="error-message-div">
                <Button as={Link} to="/home" color="teal" className="ui button empty-button small">
                    Home
                </Button>
            </div>
        </Container>
    );
};

ErrorPage.defaultProps = {
    errorObject: null,
};

export default ErrorPage;
