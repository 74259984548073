import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { Divider } from "semantic-ui-react";

import ReportsExport from "components/Administration/Reports/ReportsExport";

import { UserRole } from "model/userRole";

import userService from "services/userService";

const ReportsScreen: FC = () => {
    if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
        return <Redirect to="/home" />;
    }

    return (
        <div>
            <h1>Reports & Analytics</h1>
            <Divider />
            <ReportsExport />
        </div>
    );
};

export default ReportsScreen;
