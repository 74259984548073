import { FC } from "react";
import { Icon } from "semantic-ui-react";

interface IOrganisationManagementCheckbox {
    isSelected: boolean;
}

const OrganisationManagementCheckbox: FC<IOrganisationManagementCheckbox> = ({ isSelected }) => (
    <Icon name={isSelected ? "check" : "cancel"} />
);

export default OrganisationManagementCheckbox;
