import React, { FC, useState, useEffect, FormEvent } from "react";
import { Divider, Grid, Segment, Button, CheckboxProps, Radio, RadioProps } from "semantic-ui-react";

import AutoAllocationDermatologistList from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AutoAllocationDermatologistList";

import { checkedNotAvailableDermatologist } from "helpers/common";

import { UserRole } from "model/userRole";
import { UserStatus } from "model/userStatus";
import { IAutoAllocation, IOrganisation } from "model/organisation";
import { IDermatologistOption } from "model/allocationConfig";

import userService from "services/userService";
import organizationService from "services/organizationService";

interface IAutomatedAllocationComponent {
    organisation: IOrganisation;
    onSave: (autoAssignConfig: IAutoAllocation) => void;
}

interface IDermatologist {
    key: string;
    uuid: string;
    name: string;
    surname: string;
    value: boolean;
    availability?: string | number;
}

interface IRadioItem {
    id: number;
    label: string;
    value: string;
}

const initialState = {
    organisationUuid: "",
    isAutoAllocation: false,
    isAllAvailableDermatologists: false,
    availableDermatologistUuidList: [],
};

enum RadioValues {
    ALL_AVAILABLE = "ALL_AVAILABLE",
    PICK_FROM_LIST = "PICK_FROM_LIST",
    DISABLE = "DISABLE",
}

const radioData = [
    { id: 1, label: "All Available Safety Net Dermatologists", value: RadioValues.ALL_AVAILABLE },
    { id: 2, label: "Pick from a list (tick to display list)", value: RadioValues.PICK_FROM_LIST },
    {
        id: 3,
        label: "Disable Automated Allocation to Safety Net Dermatologist",
        value: RadioValues.DISABLE,
    },
];

const AutomatedAllocationComponent: FC<IAutomatedAllocationComponent> = ({ organisation, onSave }) => {
    const { uuid: organisationUuid, autoAssignConfig = [] } = organisation;
    const [isDirty, setIsDirty] = useState(false);

    const [autoAllocationConfig, setAutoAllocationConfig] = useState<IAutoAllocation>({
        ...initialState,
        ...(autoAssignConfig[0] || {}),
    });
    const [loading, setLoading] = useState(false);
    const [dermatologistsList, setDermatologistsList] = useState<IDermatologistOption[]>([]);
    const [autoAllocation, setAutoAllocation] = useState<string>(RadioValues.PICK_FROM_LIST);

    const onRadioChange = (event: FormEvent<HTMLInputElement>, data: RadioProps) => {
        const { value } = data;
        setAutoAllocation(String(value));
    };

    const handleAllDermatologist = (value: boolean) => {
        setIsDirty(true);
        setDermatologistsList((list: IDermatologistOption[]) =>
            list.map((option: IDermatologistOption) => ({
                ...option,
                value: checkedNotAvailableDermatologist(option.availability) ? false : value,
            }))
        );
    };

    const setAutomatedAllocation = (value: boolean) => {
        setIsDirty(true);
        setAutoAllocationConfig({
            ...autoAllocationConfig,
            isAutoAllocation: value,
        });
    };

    const generateDermatologistsOptions = (dermatologists: IDermatologist[]) => {
        const optionsDerm = dermatologists.map((dermatologistItem: IDermatologist) => {
            const { uuid, name, surname, availability } = dermatologistItem;
            return {
                key: uuid,
                text: `${surname.toUpperCase()}, ${name}`,
                value: false,
                availability,
            };
        });
        return [...optionsDerm];
    };

    useEffect(() => {
        switch (autoAllocation) {
            case RadioValues.ALL_AVAILABLE:
                handleAllDermatologist(true);
                setAutomatedAllocation(true);
                break;
            case RadioValues.PICK_FROM_LIST:
                handleAllDermatologist(false);
                setAutomatedAllocation(true);
                break;
            case RadioValues.DISABLE:
                handleAllDermatologist(false);
                setAutomatedAllocation(false);
                break;
            default:
        }
    }, [autoAllocation]);

    useEffect(() => {
        userService
            .getOrganisationUsers([`role:${UserRole.DERMATOLOGIST};status:${UserStatus.ACTIVE}`])
            .then((result) => {
                const options = generateDermatologistsOptions(result.data).map((item: IDermatologistOption) => ({
                    ...item,
                    value: autoAllocationConfig.availableDermatologistUuidList.includes(item.key),
                }));

                setDermatologistsList(options);

                if (!autoAllocationConfig?.isAutoAllocation) {
                    setAutoAllocation(RadioValues.DISABLE);
                    return;
                }
                if (autoAllocationConfig.isAllAvailableDermatologists) {
                    setAutoAllocation(RadioValues.ALL_AVAILABLE);
                }
            });
    }, []);

    const handleSave = () => {
        setLoading(true);

        const chosenDermatologists = dermatologistsList
            .filter((item: IDermatologistOption) => item.value)
            .map((item: IDermatologistOption) => item.key);

        const updatedConfig = {
            organisationUuid,
            isAutoAllocation: autoAllocationConfig.isAutoAllocation,
            isAllAvailableDermatologists: autoAllocation === RadioValues.ALL_AVAILABLE,
            availableDermatologistUuidList: chosenDermatologists,
        };

        organizationService
            .updateAutoAllocationConfig(updatedConfig)
            .then(() => {
                onSave(updatedConfig);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onChangeDermatologist = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setIsDirty(true);
        setDermatologistsList((list: IDermatologistOption[]) => {
            const index = list.findIndex((option: IDermatologistOption) => String(option.key) === String(data.id));

            if (index < 0) {
                return list;
            }

            return [
                ...list.slice(0, index),
                {
                    ...list[index],
                    key: String(data.id),
                    value: Boolean(data.checked),
                    text: String(data.name),
                },
                ...list.slice(index + 1),
            ];
        });
    };

    return (
        <Segment>
            <h3>Automated allocation configuration</h3>
            <p>Select the Automated Allocation criteria using the options below:</p>
            <Divider />
            <div className="auto-allocation-row">
                <p>
                    <b>Automated Allocation to Safety Net Dermatologist:</b>
                </p>
                <div className="auto-allocation-radios">
                    {radioData.map((item: IRadioItem) => {
                        const { id, label, value } = item;

                        return (
                            <Radio
                                key={id}
                                label={label}
                                name="radioGroup"
                                value={value}
                                checked={autoAllocation === value}
                                onChange={onRadioChange}
                            />
                        );
                    })}
                </div>
            </div>
            <Divider />
            {autoAllocation === RadioValues.ALL_AVAILABLE && (
                <p className="auto-allocation-text">
                    With this configuration the allocation will be performed automatically, targeting a random Available
                    Safety Net Dermatologist.
                </p>
            )}
            {autoAllocation === RadioValues.PICK_FROM_LIST && (
                <AutoAllocationDermatologistList
                    list={dermatologistsList}
                    onChangeDermatologist={onChangeDermatologist}
                />
            )}

            {autoAllocation === RadioValues.DISABLE && (
                <p className="auto-allocation-text">Automated Allocation is disabled.</p>
            )}
            <Divider />
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            floated="right"
                            content="Save"
                            onClick={handleSave}
                            disabled={!isDirty}
                            loading={loading}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default AutomatedAllocationComponent;
