import { IState } from "contextProviders/ReportAvailableProvider";
import { TReportAvailableActions, ReportAvailableActions } from "contextProviders/modules/reportAvailable/actions";

import { RemoteModelStatuses } from "model/assessment";

export default (state: IState, action: TReportAvailableActions): IState => {
    switch (action.type) {
        case ReportAvailableActions.SET_CASE_CLOSED: {
            return {
                ...state,
                caseStatus: RemoteModelStatuses.CASE_CLOSED,
                isCaseClosingPending: false,
                isSaveReportButtonAvailable: true,
                isDownloadReportErrorVisible: false,
            };
        }
        case ReportAvailableActions.START_CLOSE_CASE_REQUEST: {
            return {
                ...state,
                isCaseClosingPending: true,
            };
        }
        case ReportAvailableActions.SET_CASE_CLOSED_ERROR: {
            const { payload } = action;
            return {
                ...state,
                isCaseClosingPending: false,
                caseCloseError: payload,
            };
        }
        case ReportAvailableActions.SET_CASE_STATUS: {
            const { payload } = action;

            return {
                ...state,
                ...payload,
            };
        }
        case ReportAvailableActions.SET_DOWNLOAD_REPORT_ERROR_VISIBLE: {
            return {
                ...state,
                isDownloadReportErrorVisible: true,
            };
        }
        case ReportAvailableActions.SET_AWAITING_CALLBACK: {
            return {
                ...state,
                isAwaitingCallback: true,
            };
        }
        default: {
            return state;
        }
    }
};
