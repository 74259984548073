import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { UserRole } from "model/userRole";

import { REMOTE_MODEL_CASES_IN_REVIEW, REMOTE_MODEL_CASES_REPORT } from "navigation/remoteModelRoutes";
import { HOME } from "navigation/routes";

import getAuth from "redux/selectors/auth";

import * as authorizationService from "services/authorizationService";
import logoutTimeoutService from "services/logoutTimeoutService";
import userService from "services/userService";

const PAGES_WITH_PATIENT_AUTOLOGOUT = [HOME, REMOTE_MODEL_CASES_IN_REVIEW, REMOTE_MODEL_CASES_REPORT];

const useAutoLogout = () => {
    const location = useLocation();
    const { pathname } = location;
    const auth = useSelector(getAuth);

    useEffect(() => {
        const isSignedIn = authorizationService.isSignedIn(auth.signedIn);

        const startCount = (): void => {
            logoutTimeoutService.startCount();
        };

        if (isSignedIn) {
            const isCurrentUserPatient = userService.checkUserHasRole([UserRole.PATIENT]);
            const isPageWithPatientAutoLogout = PAGES_WITH_PATIENT_AUTOLOGOUT.includes(pathname);

            if (!isCurrentUserPatient || (isCurrentUserPatient && isPageWithPatientAutoLogout)) {
                startCount();

                window.addEventListener("scroll", startCount, true);
            }

            if (isCurrentUserPatient && !isPageWithPatientAutoLogout) {
                logoutTimeoutService.stopCount();
            }
        }

        return () => {
            window.removeEventListener("scroll", startCount, true);
        };
    }, [pathname]);
};

export default useAutoLogout;
