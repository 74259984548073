import React, { FC, MouseEvent as ReactMouseEvent, ChangeEvent } from "react";
import { Divider, Label, Menu, Pagination, PaginationProps, Table } from "semantic-ui-react";

import LoadingSpinner from "components/templates/LoadingSpinner";
import UserTableBody from "components/Administration/UserManagement/UserList/UserTableBody";

import { IUser } from "model/user";

import "scss/Label.scss";

interface IUserTable {
    readonly activePage: number;
    readonly userList: IUser[];
    readonly totalPages: number;
    readonly totalUsers: number;

    handlePaginationChange: (
        event: ReactMouseEvent<HTMLAnchorElement, MouseEvent> | ChangeEvent<HTMLAnchorElement>,
        data: PaginationProps
    ) => void;
}

const UserTable: FC<IUserTable> = ({
    activePage,
    userList,
    totalPages,
    totalUsers,

    handlePaginationChange,
}) => {
    if (!userList) {
        return <LoadingSpinner />;
    }

    return (
        <div className="horizontal-scroll">
            <Divider />
            <Table selectable>
                <Table.Header>
                    <Table.Row id="headerRow">
                        <Table.HeaderCell>Organisation</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>First&nbsp;Name</Table.HeaderCell>
                        <Table.HeaderCell>Surname</Table.HeaderCell>
                        <Table.HeaderCell>Last&nbsp;modification</Table.HeaderCell>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                        <Table.HeaderCell>Pre&#8209;authorisation&nbsp;Number</Table.HeaderCell>
                        <Table.HeaderCell>Member&nbsp;Number</Table.HeaderCell>
                        <Table.HeaderCell>Additional&nbsp;user&nbsp;role&nbsp;info</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Last&nbsp;Login</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <UserTableBody userList={userList} />

                <Table.Footer>
                    <Table.Row id="footerRow">
                        <Table.HeaderCell colSpan={12} textAlign="left">
                            <Label className="user-table-label">
                                <Label.Detail>Total Users: {totalUsers}</Label.Detail>
                            </Label>
                            <Menu floated="right" pagination>
                                <Pagination
                                    activePage={activePage + 1}
                                    boundaryRange={1}
                                    onPageChange={handlePaginationChange}
                                    size="small"
                                    siblingRange={1}
                                    totalPages={totalPages}
                                />
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};

export default UserTable;
