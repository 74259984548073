import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "semantic-ui-react";

import CaseListContextMenu from "components/CaseList/CaseListCellContextMenu";
import CaseListTableCell from "components/CaseList/CaseListTableCell";
import useRedirectToCaseAssessment from "components/CaseList/hooks/useRedirectToAssessment";

import { ICase } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { UserRole } from "model/userRole";
import { ICaseListSettingItem } from "model/caseList";
import { ReviewMode } from "model/caseViewMode";

import { CASE_DESCRIPTION } from "navigation/routes";

interface ICaseListTableRow {
    currentCase: ICase;
    columns: ICaseListSettingItem[];
    currentUserRole: string;
    additionalClassName: string;
    showSettingsColumn: boolean;
}

const CaseListTableRow: FC<ICaseListTableRow> = ({
    currentCase,
    columns,
    currentUserRole,
    additionalClassName,
    showSettingsColumn,
}) => {
    const history = useHistory();
    const { redirectToAssessment } = useRedirectToCaseAssessment();

    const { patient, uuid, caseStatus, remote } = currentCase;
    const { patientData = [] } = { ...patient };

    const isClinician = currentUserRole === UserRole.CLINICIAN;
    const isDermatologist = currentUserRole === UserRole.DERMATOLOGIST;
    const isSuperadmin = currentUserRole === UserRole.SUPERADMIN;
    const isPatient = currentUserRole === UserRole.PATIENT;
    const caseInSaReviewStatus = caseStatus === CaseStatus.CASE_IN_SA_REVIEW;
    const caseInReviewStatus = caseStatus === CaseStatus.CASE_IN_REVIEW;
    const caseCreatedStatus = caseStatus === CaseStatus.CASE_CREATED;
    const caseInProgressStatus = caseStatus === CaseStatus.CASE_IN_PROGRESS;
    const isInReview = caseInSaReviewStatus || caseInReviewStatus;

    const redirectAppend = isInReview && isDermatologist ? `/${ReviewMode.REVIEW}` : "";
    const caseLink = `${CASE_DESCRIPTION}/${uuid}${redirectAppend}`;

    const onRowClicked = async () => {
        const isCreatedClinician =
            ((isSuperadmin && !remote) || isClinician) && (caseCreatedStatus || caseInProgressStatus);
        const toAssessment = isCreatedClinician || isPatient;
        if (toAssessment) {
            redirectToAssessment(uuid, isPatient);
        } else {
            history.push(caseLink);
        }
    };

    const onIconClicked = (event: MouseEvent) => {
        event.stopPropagation();
        window.open(caseLink, "_blank");
    };

    return (
        <Table.Row className="case-list-table-row">
            {columns.map(({ name: columnName, display }) => {
                if (!display) {
                    return null;
                }

                return (
                    <CaseListTableCell
                        key={columnName}
                        currentCase={currentCase}
                        column={columnName}
                        patientData={patientData}
                        currentUserRole={currentUserRole}
                        redirectToCaseDetails={onRowClicked}
                        openCaseInNewTab={onIconClicked}
                    />
                );
            })}
            {showSettingsColumn && (
                <CaseListContextMenu
                    currentCase={currentCase}
                    currentUserRole={currentUserRole}
                    additionalClassName={additionalClassName}
                />
            )}
        </Table.Row>
    );
};

export default CaseListTableRow;
