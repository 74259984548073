import React, { FC, ReactNode } from "react";
import { Icon, Popup } from "semantic-ui-react";

interface ISettingsPropertyWrapperProps {
    children: string | ReactNode;
    copyOfUuid?: string | null;
    parentOrgUuid?: string;
    propertyOrganisationUuid?: string;
    inheritedFromParent?: boolean;
}

const SettingsPropertyWrapper: FC<ISettingsPropertyWrapperProps> = ({
    children,
    copyOfUuid,
    parentOrgUuid,
    propertyOrganisationUuid,
    inheritedFromParent,
}) => {
    const modifiedWarning = (
        <Icon className="inherited-warning icon details organisation" name="exclamation triangle" />
    );
    const isDifferentFromParent =
        parentOrgUuid && propertyOrganisationUuid !== parentOrgUuid && copyOfUuid !== undefined;

    const hasNotInheritedFromParent = inheritedFromParent !== null && inheritedFromParent === false;

    return (
        <div style={{ display: "flex" }}>
            {children}
            {(isDifferentFromParent && !copyOfUuid) || hasNotInheritedFromParent ? (
                <Popup
                    content={<div>This property is different from parent organisation</div>}
                    trigger={modifiedWarning}
                />
            ) : null}
        </div>
    );
};

SettingsPropertyWrapper.defaultProps = {
    copyOfUuid: "",
    parentOrgUuid: "",
    propertyOrganisationUuid: "",
    inheritedFromParent: undefined,
};

export default SettingsPropertyWrapper;
