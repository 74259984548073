import { FC } from "react";
import { Grid } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";

import useAutoLogout from "hooks/useAutoLogout";

interface ISkinConditionsPathwayPopup {
    showDialog: boolean;
    isPropertyEnabling: boolean;
    fieldName: string;
    callback: (changeValue: boolean) => void;
}

const SkinConditionsPathwayPopup: FC<ISkinConditionsPathwayPopup> = ({
    showDialog,
    callback,
    isPropertyEnabling,
    fieldName,
}) => {
    useAutoLogout();

    const onConfirm = () => {
        callback(true);
    };

    const onCancel = () => {
        callback(false);
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    <span> Are you sure you want to {isPropertyEnabling ? "enable" : "disable"} it?</span>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    const continueButtonText = isPropertyEnabling ? "Enable" : "Disable";

    return (
        (showDialog && (
            <ModalDialog
                title={`${fieldName} is currently ${isPropertyEnabling ? "disabled" : "enabled"}`}
                open={showDialog}
                maxWidth="sm"
                onClose={onCancel}
                buttons={[
                    {
                        onClick: onConfirm,
                        text: continueButtonText,
                        colour: "primary",
                    },
                    {
                        onClick: onCancel,
                        text: "Cancel",
                    },
                ]}
            >
                {popupBody}
            </ModalDialog>
        )) ||
        null
    );
};

export default SkinConditionsPathwayPopup;
