import React, { Component, ReactNode } from "react";
import { Grid, GridColumn, Header, Segment, Form } from "semantic-ui-react";
import Chip from "@mui/material/Chip";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";
import ContextIconButton from "components/templates/ContextIconButton";
import AdminNextStepsFields from "components/Administration/OrganisationManagment/ViewOrganisation/Templates/AdminNextStepsFields";
import ModalDialog from "components/templates/dialog/ModalDialog";

import organizationService from "services/organizationService";

import {
    IOrganisation,
    IStructuredTemplateField,
    ITemplate,
    ITemplateField,
    ITemplateFieldOption,
} from "model/organisation";
import { TemplateType } from "model/templateType";
import ViewEditMode from "model/viewEditMode";

import "scss/AdminPanel.scss";

interface IHistoryQuestionRowProps {
    onRemove: (templateUuid) => void;
    template: ITemplate;
    organisation: IOrganisation;
    setTemplate: (template: ITemplate) => void;
}

class TemplateRow extends Component<IHistoryQuestionRowProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            mode: ViewEditMode.VIEW,
            showDeleteModal: false,
        };
    }

    private static getFields(fields: ITemplateField[]) {
        return fields?.map((field) => (
            <Grid.Row key={field.name}>
                <Grid.Column width={3}>
                    <b>{field.name}</b>
                </Grid.Column>
                <Grid.Column width={6}>{field.content}</Grid.Column>
            </Grid.Row>
        ));
    }

    private getStructuredFields = (fields: IStructuredTemplateField[]): ReactNode => {
        const { template, setTemplate } = this.props;
        const { mode } = this.state;

        const templateFields = template.fields as IStructuredTemplateField[];

        if (mode === ViewEditMode.EDIT) {
            return (
                <>
                    <Grid.Row width={16}>
                        <Grid.Column width={6}>
                            <Form.Field label="Question" style={{ fontWeight: "bold" }} />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field label="Question type" style={{ fontWeight: "bold" }} />
                        </Grid.Column>
                    </Grid.Row>
                    <AdminNextStepsFields
                        fields={templateFields.filter((field: IStructuredTemplateField) => !field.parentFieldUuid)}
                        setFields={(newFields) => setTemplate({ ...template, fields: newFields })}
                        setToViewMode={() => this.setState({ mode: ViewEditMode.VIEW })}
                        mode={mode}
                    />
                </>
            );
        }

        const responseOptions = (options: ITemplateFieldOption[] | string[]) =>
            options.map((option) => <Chip key={option.option} label={option.option} style={{ margin: "0 5px" }} />);

        const createStructuredFieldViewMode = (structuredField: IStructuredTemplateField) => (
            <Grid.Row
                key={structuredField.uuid || structuredField.question}
                className={structuredField.parentFieldUuid ? "field-sub-question-row" : ""}
            >
                <Grid.Column width={8} className={structuredField.parentFieldUuid ? "field-sub-question" : ""}>
                    <b>{structuredField.question}</b>
                    {structuredField.required && <sup>*</sup>}
                </Grid.Column>
                <Grid.Column width={6}>
                    <div className="row-response-options">
                        {structuredField.questionType}
                        {structuredField.responseOptions?.length && (
                            <GridColumn>{responseOptions(structuredField.responseOptions)}</GridColumn>
                        )}
                    </div>
                </Grid.Column>
            </Grid.Row>
        );

        const structuredFields = [];
        fields?.forEach((structuredField) => {
            structuredFields.push(createStructuredFieldViewMode(structuredField));

            if (structuredField.childStructuredFields?.length) {
                structuredField.childStructuredFields.forEach((childField) => {
                    structuredFields.push(createStructuredFieldViewMode(childField));
                });
            }
        });

        return structuredFields;
    };

    private onRemoveRow = (templateUuid) => {
        const { template, onRemove } = this.props;
        organizationService.deleteTemplate(template.uuid).then(() => {
            onRemove(templateUuid);
        });
    };

    private onEditRow = () => {
        this.setState({ mode: ViewEditMode.EDIT });
    };

    private onCancel = (): void => {
        this.setState({ mode: ViewEditMode.VIEW });
    };

    public render() {
        const { template, organisation } = this.props;
        const { mode, showDeleteModal } = this.state;
        const { parentUuid } = organisation;
        const canEditTemplate = !template.organisationUuid || template.organisationUuid === organisation.uuid;
        const { name, copyOfUuid, uuid, fields, organisationUuid, isStructured } = template;

        return (
            <>
                <Segment raised>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="template-header-wrapper">
                                    <Header as="h4">
                                        <SettingsPropertyWrapper
                                            parentOrgUuid={parentUuid}
                                            copyOfUuid={copyOfUuid}
                                            propertyOrganisationUuid={organisationUuid}
                                        >
                                            {TemplateType[name]}
                                        </SettingsPropertyWrapper>
                                    </Header>
                                    {mode === ViewEditMode.EDIT && (
                                        <div style={{ display: "flex" }}>
                                            <Button
                                                startIcon={<DeleteOutlineIcon />}
                                                onClick={() => this.setState({ showDeleteModal: true })}
                                            >
                                                Delete
                                            </Button>
                                            <Button startIcon={<CloseIcon />} onClick={this.onCancel}>
                                                Cancel
                                            </Button>
                                        </div>
                                    )}
                                    {mode === ViewEditMode.VIEW && canEditTemplate && (
                                        <ContextIconButton iconName="edit" onClick={this.onEditRow} />
                                    )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        {isStructured
                            ? this.getStructuredFields(fields as IStructuredTemplateField[])
                            : TemplateRow.getFields(fields as ITemplateField[])}
                    </Grid>
                </Segment>
                <ModalDialog
                    title="Are you sure you want to delete this template?"
                    open={showDeleteModal}
                    iconSrc="/images/icons/icon-warning-red-bg.svg"
                    onClose={() => this.setState({ showDeleteModal: false })}
                    maxWidth="sm"
                    disableBackdropClick
                    buttons={[
                        {
                            onClick: () => this.onRemoveRow(uuid),
                            text: "Yes",
                        },
                        {
                            onClick: () => this.setState({ showDeleteModal: false }),
                            text: "No",
                        },
                    ]}
                >
                    <Grid className="grid-dialog-body">
                        <Grid.Row>
                            <Grid.Column>
                                All users within the organisation will no longer see the information provided in this
                                template
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </ModalDialog>
            </>
        );
    }
}

export default TemplateRow;
