import { connect } from "react-redux";

import StudyAssessmentSummary from "components/StudyAssessmentSummary/studyAssessmentSummary";

import assessmentService from "services/assessmentService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    case: state.assessment.case,
    currentLesion: state.assessment.currentLesion,
});

const mapDispatchToProps = {
    addNextLesionAction: assessmentService.updateLesionNumberAction,
    finishAssessment: assessmentService.finishAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyAssessmentSummary);
