import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Information from "components/MFA/Information";
import PhoneNumberRegistration from "components/MFA/PhoneNumberRegistration";
import VerifyOTP from "components/MFA/VerifyOTP";
import StepIndicator from "components/MFA/StepIndicator";
import InterstitialPage from "components/templates/InterstitialPage";

import { MFAKeys, OTPConfig, VerificationStatus } from "model/mfa";

import getMFASetupState from "redux/selectors/mfa";

const NUMBER_OF_STEPS = 3;

const Setup: FC = () => {
    const [step, setStep] = useState<number>(0);
    // TODO Use redux state to handle back/forward
    const mfaSetupState = useSelector(getMFASetupState);
    const { gracePeriodCountdownInDays, email, sms } = mfaSetupState;
    const allowSkip = useMemo(() => gracePeriodCountdownInDays > 0, []);

    useEffect(() => {
        const calculateSetupState = (): void => {
            if (email?.status !== VerificationStatus.APPROVED && !email?.verificationDate) {
                setStep(0);
            }

            if (email?.status === VerificationStatus.PENDING) {
                setStep(1);
            }

            if (email?.status === VerificationStatus.APPROVED && !!email?.verificationDate) {
                setStep(2);
            }

            if (sms?.status === VerificationStatus.PENDING) {
                setStep(3);
            }
        };
        calculateSetupState();
    }, [mfaSetupState]);

    const handleNext = (): void => {
        setStep(step + 1);
    };

    const handleBack = (): void => {
        setStep(step - 1);
    };

    return (
        <InterstitialPage>
            <StepIndicator
                step={step}
                stepCount={NUMBER_OF_STEPS}
                allowBack={step === 3}
                allowSkip={allowSkip}
                handleBack={handleBack}
            />
            {step === 0 ? <Information handleNext={handleNext} /> : null}
            {step === 1 ? <VerifyOTP config={OTPConfig[MFAKeys.EMAIL_VERIFICATION]} /> : null}
            {step === 2 ? <PhoneNumberRegistration /> : null}
            {step === 3 ? <VerifyOTP config={OTPConfig[MFAKeys.SMS_VERIFICATION]} /> : null}
            {/* 
                https://skinanalytics.atlassian.net/browse/OV2-3725
                https://skinanalytics.atlassian.net/browse/OV2-3712
                Trusted devices && complete setup
            */}
        </InterstitialPage>
    );
};

export default Setup;
