import React, { FC, KeyboardEvent, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AllocateCaseModal from "components/CaseList/AllocateCaseModal";
import CloseCaseDialog from "components/templates/dialog/CloseCaseDialog";
import CaseListLabel from "components/CaseList/CaseListLabel";
import useRedirectToCaseAssessment from "components/CaseList/hooks/useRedirectToAssessment";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { shouldRedirectToQRCodePage } from "helpers/cases";

import { ICase } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { ReviewMode } from "model/caseViewMode";
import { UserRole } from "model/userRole";

import { history } from "App";
import { CASE_DESCRIPTION } from "navigation/routes";
import { OrganisationType } from "model/organisation";

import { getOrganisation } from "redux/selectors/data";

import * as caseService from "services/caseService";
import userService from "services/userService";

interface ICaseListCellAction {
    currentCase: ICase;
    userRole: string;
}

const CaseListCellAction: FC<ICaseListCellAction> = ({ currentCase, userRole }) => {
    const organisation = useSelector(getOrganisation);
    const { updateCaseStatus } = useContext(CaseListContext);
    const [showAllocationModal, setShowAllocationModal] = useState<boolean>(false);

    const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { redirectToAssessment } = useRedirectToCaseAssessment();

    const isCurrentUserPatient = userRole === UserRole.PATIENT;
    const isCurrentUserSuperAdmin = userService.checkUserHasRole([UserRole.SUPERADMIN, UserRole.SA_ADMIN]);

    const { uuid, caseStatus, remote: isRemote, lesions } = currentCase;

    const closeCase = () => {
        caseService.closeCase(uuid).then(() => {
            updateCaseStatus(uuid, CaseStatus.CASE_CLOSED);
        });
    };

    const isStudyOrganisation = organisation && organisation.type === OrganisationType.STUDY;
    const isStudyCase = Boolean(currentCase.studyUuid);
    const canContinueCase = (isStudyOrganisation && isStudyCase) || (!isStudyOrganisation && !isStudyCase);

    const handleDownloadReportClicked = () => {
        dispatch(caseService.downloadReport(uuid));
    };

    const handleContinueClicked = async () => {
        redirectToAssessment(uuid, isCurrentUserPatient);
    };

    const handleReviewClicked = () => {
        const reviewUrl = `${CASE_DESCRIPTION}/${uuid}/${ReviewMode.REVIEW}`;
        history.push(reviewUrl);
    };

    const handleViewClicked = () => {
        history.push(`${CASE_DESCRIPTION}/${uuid}`);
    };

    const handleKeyDown = (e: KeyboardEvent, handleFunction: () => void) => {
        if (e.key === "Enter") {
            handleFunction();
        }
    };

    if (userRole === UserRole.ADMIN) {
        switch (caseStatus) {
            case CaseStatus.CASE_IN_CLIENT_REVIEW:
            case CaseStatus.CASE_IN_SA_REVIEW:
            case CaseStatus.CASE_IN_REVIEW: {
                return <CaseListLabel icon="eye" text="View Case" />;
            }
            case CaseStatus.CASE_FOR_REVIEW: {
                return (
                    <>
                        <CaseListLabel
                            icon="user md"
                            text="Allocate"
                            onClick={() => setShowAllocationModal(true)}
                            onKeyDown={(e) => handleKeyDown(e, () => setShowAllocationModal(true))}
                        />
                        {showAllocationModal && (
                            <AllocateCaseModal
                                isUnallocation={false}
                                updateCaseStatus={updateCaseStatus}
                                isSuperAdmin={false}
                                currentCase={currentCase}
                                showDialog={showAllocationModal}
                                callback={setShowAllocationModal}
                            />
                        )}
                    </>
                );
            }
            case CaseStatus.CASE_COMPLETED: {
                if (isRemote) {
                    return null;
                }

                return (
                    <>
                        <CaseListLabel
                            icon="window close outline"
                            text="Close Case"
                            onClick={() => setShowCloseDialog(true)}
                            onKeyDown={(e) => handleKeyDown(e, () => setShowCloseDialog(true))}
                        />
                        {showCloseDialog && (
                            <CloseCaseDialog
                                showDialog={showCloseDialog}
                                callback={setShowCloseDialog}
                                caseObject={currentCase}
                                organisation={organisation}
                                closeCase={closeCase}
                            />
                        )}
                    </>
                );
            }
            case CaseStatus.CASE_CLOSED: {
                return (
                    <CaseListLabel
                        icon="file pdf"
                        text="Download Report"
                        onClick={handleDownloadReportClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleDownloadReportClicked)}
                    />
                );
            }
            default: {
                return null;
            }
        }
    }
    const isCurrentUserClinician = userRole === UserRole.CLINICIAN;
    if (isCurrentUserClinician || isCurrentUserPatient) {
        switch (caseStatus) {
            case CaseStatus.CASE_IN_PROGRESS:
            case CaseStatus.CASE_CREATED: {
                return canContinueCase ? (
                    <CaseListLabel
                        icon="arrow alternate circle right outline"
                        text="Continue Case"
                        onClick={handleContinueClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleContinueClicked)}
                    />
                ) : (
                    <></>
                );
            }
            case CaseStatus.CASE_COMPLETED: {
                if (isCurrentUserClinician) {
                    return (
                        <>
                            <CaseListLabel
                                icon="window close outline"
                                text="Close Case"
                                onClick={() => setShowCloseDialog(true)}
                                onKeyDown={(e) => handleKeyDown(e, () => setShowCloseDialog(true))}
                            />
                            {showCloseDialog && (
                                <CloseCaseDialog
                                    showDialog={showCloseDialog}
                                    callback={setShowCloseDialog}
                                    caseObject={currentCase}
                                    organisation={organisation}
                                    closeCase={closeCase}
                                />
                            )}
                        </>
                    );
                }
                return canContinueCase ? (
                    <CaseListLabel
                        icon="arrow alternate circle right outline"
                        text="Continue Case"
                        onClick={handleContinueClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleContinueClicked)}
                    />
                ) : (
                    <></>
                );
            }
            case CaseStatus.CASE_CLOSED: {
                if (isCurrentUserPatient) {
                    return (
                        <CaseListLabel
                            text="View report"
                            icon="clipboard outline"
                            onClick={handleContinueClicked}
                            onKeyDown={(e) => handleKeyDown(e, handleContinueClicked)}
                        />
                    );
                }
                return null;
            }
            case CaseStatus.CASE_FOR_SA_REVIEW:
            case CaseStatus.CASE_IN_SA_REVIEW:
            case CaseStatus.CASE_FOR_REVIEW:
            case CaseStatus.CASE_IN_REVIEW: {
                if (isCurrentUserPatient) {
                    return canContinueCase ? (
                        <CaseListLabel
                            icon="arrow alternate circle right outline"
                            text="Continue Case"
                            onClick={handleContinueClicked}
                            onKeyDown={(e) => handleKeyDown(e, handleContinueClicked)}
                        />
                    ) : (
                        <></>
                    );
                }

                break;
            }
            default: {
                return null;
            }
        }
    }
    if (userRole === UserRole.DERMATOLOGIST) {
        switch (caseStatus) {
            case CaseStatus.CASE_IN_REVIEW:
            case CaseStatus.CASE_IN_SA_REVIEW: {
                const isCaseAssignedToCurrentUser =
                    currentCase.assignmentDetails?.assigneeUuid === userService.getCurrentUserUuid();
                return isCaseAssignedToCurrentUser ? (
                    <CaseListLabel
                        icon="zoom"
                        text="Review"
                        onClick={handleReviewClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleReviewClicked)}
                    />
                ) : (
                    <CaseListLabel
                        icon="eye"
                        text="View Case"
                        onClick={handleViewClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleViewClicked)}
                    />
                );
            }
            default: {
                return (
                    <CaseListLabel
                        icon="eye"
                        text="View Case"
                        onClick={handleViewClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleViewClicked)}
                    />
                );
            }
        }
    }
    if (userRole === UserRole.CLINICAL_VIEWER) {
        return (
            <CaseListLabel
                icon="eye"
                text="View Case"
                onClick={handleViewClicked}
                onKeyDown={(e) => handleKeyDown(e, handleViewClicked)}
            />
        );
    }
    if (isCurrentUserSuperAdmin) {
        switch (caseStatus) {
            case CaseStatus.CASE_IN_PROGRESS:
            case CaseStatus.CASE_CREATED: {
                const redirectToQrCodePage = shouldRedirectToQRCodePage(lesions);
                if (isRemote || !redirectToQrCodePage) {
                    return (
                        <CaseListLabel
                            icon="eye"
                            text="View case"
                            onClick={handleViewClicked}
                            onKeyDown={(e) => handleKeyDown(e, handleViewClicked)}
                        />
                    );
                }
                return canContinueCase || redirectToQrCodePage ? (
                    <CaseListLabel
                        icon="arrow alternate circle right outline"
                        text="Continue Case"
                        onClick={handleContinueClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleContinueClicked)}
                    />
                ) : (
                    <></>
                );
            }
            case CaseStatus.CASE_FOR_SA_REVIEW: {
                return (
                    <>
                        <CaseListLabel
                            icon="user md"
                            text="Allocate"
                            onClick={() => setShowAllocationModal(true)}
                            onKeyDown={(e) => handleKeyDown(e, () => setShowAllocationModal(true))}
                        />
                        {showAllocationModal && (
                            <AllocateCaseModal
                                isUnallocation={false}
                                updateCaseStatus={updateCaseStatus}
                                isSuperAdmin
                                currentCase={currentCase}
                                showDialog={showAllocationModal}
                                callback={setShowAllocationModal}
                            />
                        )}
                    </>
                );
            }
            case CaseStatus.CASE_CLOSED:
            case CaseStatus.CASE_IN_SA_REVIEW:
            case CaseStatus.CASE_COMPLETED:
            case CaseStatus.CASE_IN_CLIENT_REVIEW:
            case CaseStatus.CASE_FOR_REVIEW:
            case CaseStatus.CASE_ABANDONED:
            case CaseStatus.CASE_FAILED: {
                return (
                    <CaseListLabel
                        icon="eye"
                        text="View case"
                        onClick={handleViewClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleViewClicked)}
                    />
                );
            }
            default: {
                return null;
            }
        }
    }
    if (userRole === UserRole.CALLBACK_AGENT) {
        switch (caseStatus) {
            case CaseStatus.CASE_COMPLETED: {
                return (
                    <CaseListLabel
                        icon="arrow alternate circle right outline"
                        text="Callback"
                        onClick={handleViewClicked}
                        onKeyDown={(e) => handleKeyDown(e, handleViewClicked)}
                    />
                );
            }
            default: {
                return null;
            }
        }
    }

    return null;
};

export default CaseListCellAction;
