import { Component } from "react";
import { Table } from "semantic-ui-react";

import { history } from "App";

interface ICustomTableRow {
    link?: string | undefined;
    id: string;
}

class TableRow extends Component<ICustomTableRow, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            active: false,
            redirect: false,
        };
    }

    public onMouseOut = () => this.setState({ active: false });

    public onMouseOver = () => this.setState({ active: true });

    public onClick = () => this.setState({ redirect: true });

    public onKeyPress = (e: KeyboardEvent): void => {
        if (e.key === "Enter") {
            this.onClick();
        }
    };

    public render() {
        const { link, children } = this.props;
        const { redirect, active } = this.state;

        if (redirect) {
            if (link) {
                history.push(link);
            }
        }
        const { id } = this.props;

        return (
            <Table.Row
                tabIndex={0}
                id={id}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                active={active}
                onClick={this.onClick}
                onKeyPress={this.onKeyPress}
            >
                {children}
            </Table.Row>
        );
    }
}
export default TableRow;
