import React, { Component } from "react";
import { Form, Label, Table } from "semantic-ui-react";

import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";
import ConfirmDialog, { getDefaultButtons } from "components/templates/ConfirmDialog";
import ContextIconButton from "components/templates/ContextIconButton";

import { IOrganisation, IReviewEscalation } from "model/organisation";

import reviewManagementService from "services/reviewManagementService";

interface IEscalationsTableProps {
    organisation?: IOrganisation;
    escalationPlans: IReviewEscalation[];
    disableDelete?: boolean;
    removeCallback: (uuid: string) => void;
}

interface IEscalationsTableState {
    disableDelete: boolean;
}

class EscalationsTable extends Component<IEscalationsTableProps, IEscalationsTableState> {
    constructor(props: any) {
        super(props);

        const { disableDelete } = props;

        this.state = {
            disableDelete: disableDelete || false,
        };
    }

    private getEscalationsTableRows() {
        const { disableDelete } = this.state;
        const { organisation, escalationPlans } = this.props;
        const activeEscalations = escalationPlans ? escalationPlans.filter((escalation) => !escalation.removed) : [];
        const { parentUuid, uuid: orgUuid } = organisation;

        if (activeEscalations && activeEscalations.length) {
            return activeEscalations.map((reviewEscalation: IReviewEscalation) => {
                const { escalationPlan, uuid, organisationUuid, copyOfUuid, freeText } = reviewEscalation;
                const canEditDiagnoses = orgUuid === organisationUuid;

                return (
                    <Table.Row key={uuid}>
                        <Table.Cell width="6">
                            <SettingsPropertyWrapper
                                parentOrgUuid={parentUuid}
                                copyOfUuid={copyOfUuid}
                                propertyOrganisationUuid={organisationUuid}
                            >
                                {escalationPlan}
                            </SettingsPropertyWrapper>
                        </Table.Cell>

                        <Table.Cell width="1">
                            {freeText && <Label size="mini" color="blue" content="Free text" />}
                        </Table.Cell>

                        <Table.Cell width="1">
                            {!disableDelete && canEditDiagnoses && (
                                <ContextIconButton iconName="trash" uuid={uuid} onClick={this.onRemoveEscalationPlan} />
                            )}
                        </Table.Cell>
                    </Table.Row>
                );
            });
        }

        return (
            <Table.Row>
                <Table.Cell>-</Table.Cell>
                <Table.Cell />
                <Table.Cell />
            </Table.Row>
        );
    }

    private onRemoveEscalationPlan = (event: any, obj: any) => {
        ConfirmDialog(
            "Are you sure that you want to delete this escalation plan item?",
            "",
            getDefaultButtons(this.removeEscalationPlan(obj))
        );
    };

    private removeEscalationPlan = (obj: any) => {
        const { removeCallback } = this.props;
        const { uuid } = obj;

        return () =>
            reviewManagementService.removeEscalationPlan(uuid).then(() => {
                if (removeCallback) {
                    removeCallback(uuid);
                }
            });
    };

    public render() {
        return (
            <Form>
                <Table selectable columns={3}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width="8">Escalation plan</Table.HeaderCell>
                            <Table.HeaderCell width="2" />
                            <Table.HeaderCell width="1" />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.getEscalationsTableRows()}</Table.Body>
                </Table>
            </Form>
        );
    }
}

export default EscalationsTable;
