import React, { FC } from "react";
import { Grid, Icon } from "semantic-ui-react";
import parse from "html-react-parser";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import { IManagementOptions, IReviewDiagnosis } from "model/organisation";

import "scss/Dialog.scss";

interface IReviewEntityDetailsDialog {
    showDialog: boolean;
    managementOption?: IManagementOptions;
    diagnose?: IReviewDiagnosis;
    onClose: () => void;
}

const ReviewEntityDetailsDialog: FC<IReviewEntityDetailsDialog> = ({
    onClose,
    showDialog,
    managementOption,
    diagnose,
}) => {
    const title = managementOption ? "Management Option Details" : "Diagnosis Details";
    const isManagementOption = Boolean(managementOption);

    const onConfirm = () => {
        onClose();
    };

    const name = isManagementOption ? managementOption?.management : diagnose?.diagnosis;
    const keyMessageText = parse(managementOption?.keyMessage || "-");
    const safetyNettingText = parse(managementOption?.safetyNetting || "-");
    const diagnoseAditionalInformationText = parse(diagnose?.additionalInformation || "-");

    const popupBody = (
        <Grid className="grid-dialog-body show-management-option-row" columns="2">
            <Grid.Row>
                <Grid.Column>
                    <b>Name:</b>
                </Grid.Column>
                <Grid.Column>{name}</Grid.Column>
            </Grid.Row>
            {isManagementOption ? (
                <>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Review type:</b>
                        </Grid.Column>
                        <Grid.Column>{managementOption?.reviewType}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Free text:</b>
                        </Grid.Column>
                        <Grid.Column>
                            {managementOption?.freeText ? <Icon name="check" /> : <Icon name="cancel" />}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Refer according to pathway:</b>
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name={managementOption?.refer ? "check" : "cancel"} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Key Message Text:</b>
                        </Grid.Column>
                        <Grid.Column>
                            <div>{keyMessageText}</div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Safety Netting Text:</b>
                        </Grid.Column>
                        <Grid.Column>
                            <div>{safetyNettingText}</div>
                        </Grid.Column>
                    </Grid.Row>
                </>
            ) : (
                <Grid.Row>
                    <Grid.Column>
                        <b>Find out more information:</b>
                    </Grid.Column>
                    <Grid.Column>
                        <div>{diagnoseAditionalInformationText}</div>
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                yesButton={{
                    backgroundColor: "#2185D0",
                    fontColor: "#FFFFFF",
                    onClick: onConfirm,
                    text: "Close",
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

ReviewEntityDetailsDialog.defaultProps = {
    managementOption: null,
    diagnose: null,
};

export default ReviewEntityDetailsDialog;
