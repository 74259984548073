import { FC } from "react";
import Box from "@material-ui/core/Box";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { SUPPORT_EMAIL } from "model/common";

import "scss/CustomErrorMessage.scss";

export interface ICustomErrorMessage {
    message: string;
    cta?: string;
}

const CustomErrorMessage: FC<ICustomErrorMessage> = ({ cta, message }) => (
    <Box className="custom-error-message">
        <ErrorOutlineIcon fontSize="large" />

        <Box marginLeft={2}>
            <Box className="message-title" role="alert" aria-live="polite" aria-label={`${message} ${cta || ""}`}>
                <p>
                    <strong>{message}</strong>
                </p>
            </Box>
            <Box>
                {cta ? (
                    <p>{cta}</p>
                ) : (
                    <p>
                        If you continue having trouble completing the process, contact our support team at:&nbsp;
                        <a href={`mailto:${SUPPORT_EMAIL}`} aria-label={`Send email to ${SUPPORT_EMAIL}`}>
                            {SUPPORT_EMAIL}.
                        </a>
                    </p>
                )}
            </Box>
        </Box>
    </Box>
);

export default CustomErrorMessage;
