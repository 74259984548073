import { FC, useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { ReCAPTCHA } from "react-google-recaptcha";

import RemoteModelContainer from "components/templates/RemoteModelContainer";
import AtHomeSubmissionModal from "components/RemoteModel/AtHomeSubmissionModal";
import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";
import Questionary from "components/Questionary";

import AtHomeFlowContext from "contextProviders/AtHomeFlowProvider";
import { AtHomeFlowActionTypes } from "contextProviders/modules/atHomeFlow/actions";

import { scrollToError } from "helpers/assessment";
import { generateAnswers } from "helpers/questionary";

import useNavigationPrompt from "hooks/useNavigationPrompt";

import { IHistoryAnswerType, IHistoryQuestion, IMedicalHistoryQuestion, IQuestionaryAnswers } from "model/organisation";
import { IMedicalHistoryAnswers } from "model/assessment";
import { RemoteTimeline } from "model/remoteCaseTimeline";
import { AtHomeFlowApiBody, MedicalHistoryApiBodyField, Recaptcha } from "model/atHomeFlow";
import { ModalProps, ModalTypes } from "model/modal";
import NavigationMessages from "model/navigation";
import { IQuestionaryError } from "model/questionaryError";
import IQuestionSet from "model/questionSet";
import QuestionSubTypeEnum from "model/questionSubType";

import { getOrganisation, getPendingRequest } from "redux/selectors/data";
import { getAssessment } from "redux/selectors/assessment";
import { getRegistration } from "redux/selectors/registration";

import registrationService from "services/registrationService";

import "scss/RemoteModel.scss";

const MedicalHistoryQuestions: FC = () => {
    const {
        consent,
        secondaryConsent,
        automatedDecisionConsent,
        personalDetails,
        medicalHistory: stateMedicalHistory,
        dispatch: atHomeFlowDispatch,
    } = useContext(AtHomeFlowContext);

    const [errors, setErrors] = useState<IQuestionaryError>({
        formError: false,
        isInitial: true,
        list: [],
        showError: false,
    });
    const [answers, setAnswers] = useState<IQuestionaryAnswers>({});
    const [isEdited, setIsEdited] = useState<boolean>(false);

    const recaptchaRef: React.MutableRefObject<ReCAPTCHA> = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<Recaptcha>({
        token: "",
        errored: false,
        ref: recaptchaRef,
    });

    const [modal, setModal] = useState<Omit<ModalProps, "children">>({
        show: false,
        type: ModalTypes.SUCCESS,
    });

    const organisation = useSelector(getOrganisation);
    const pendingRequest = useSelector(getPendingRequest);
    const assessment = useSelector(getAssessment);
    const registration = useSelector(getRegistration);

    let fitzpatrickQuestion;
    const allMedicalHistoryQuestions = organisation.medicalHistoryQuestions.filter(
        (question: IMedicalHistoryQuestion) => !question.removed
    );
    const biopsyQuestions = organisation.biopsyQuestions
        ? organisation.biopsyQuestions.filter((question: IMedicalHistoryQuestion) => !question.removed)
        : [];
    const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;

    if (isAtHomeFlow) {
        fitzpatrickQuestion = allMedicalHistoryQuestions
            .filter((question: IMedicalHistoryQuestion) => question?.questionSubType === QuestionSubTypeEnum.SKIN_TYPE)
            .filter((question: IMedicalHistoryQuestion) => !question?.removed);
    }

    const { preventUserNavigation, setPreventUserNavigation, setReadyToProgress } = useNavigationPrompt({
        predicate: isAtHomeFlow,
    });

    useEffect(() => {
        if (assessment?.patient) {
            const { medicalHistory } = assessment.patient;

            if (medicalHistory) {
                const questions = [...biopsyQuestions, ...allMedicalHistoryQuestions].sort(
                    (a, b) => a.questionOrder - b.questionOrder
                );
                const medicalHistoryAnswers = generateAnswers(medicalHistory, questions);

                setErrors({
                    formError: false,
                    isInitial: false,
                    list: [],
                    showError: true,
                });
                setAnswers(medicalHistoryAnswers);
            }
        }
    }, []);

    const fitzpatrickQuestionSet: IQuestionSet[] = [{ questions: fitzpatrickQuestion }];

    const handleRecaptchaChange = (token: string): void => {
        setRecaptcha({
            token,
            errored: false,
            ref: recaptchaRef,
        });
    };

    const submit = async ({ mainData }: { mainData: IMedicalHistoryAnswers[] }): Promise<void> => {
        setErrors({ ...errors, showError: true });

        if (isAtHomeFlow) {
            const token: string = await recaptchaRef.current.executeAsync();
            handleRecaptchaChange(token);

            if (!token) {
                setRecaptcha({ ...recaptcha, errored: true });
                recaptchaRef.current?.reset();
                return;
            }

            setPreventUserNavigation(() => {
                setReadyToProgress(true);

                return false;
            });

            // Add current form data to the previously recorded answers
            const medicalHistoryAnswers: MedicalHistoryApiBodyField = [...stateMedicalHistory, ...mainData].map(
                ({ questionUuid, answers: submittedAnswers, additionalInformation }) => ({
                    questionUuid,
                    answers: submittedAnswers,
                    additionalInformation,
                })
            );

            try {
                if (registration?.organisationUuid) {
                    const apiBody: AtHomeFlowApiBody = {
                        organisationUuid: registration?.organisationUuid,
                        consent,
                        secondaryConsent,
                        automatedDecisionConsent,
                        patientData: personalDetails,
                        medicalHistoryAnswers,
                        recaptchaResponse: token,
                    };
                    await registrationService.submitAtHomeQuestionnaireCase(apiBody);

                    atHomeFlowDispatch({
                        type: AtHomeFlowActionTypes.SET_FITZPATRICK_QUESTION,
                        payload: mainData,
                    });

                    setModal({
                        show: true,
                        type: ModalTypes.SUCCESS,
                    });
                } else {
                    throw new Error();
                }
            } catch (err) {
                setRecaptcha({ ...recaptcha, errored: true });
                recaptchaRef.current?.reset();
                setModal({
                    show: true,
                    type: ModalTypes.ERROR,
                });
            }
        }
    };

    const handleAnswersChange = (updatedAnswers: IQuestionaryAnswers): void => {
        setAnswers(updatedAnswers);
        if (!isEdited) {
            setIsEdited(true);
        }
    };

    const closeModal = (): void => {
        setModal((prevState) => ({
            ...prevState,
            show: false,
        }));
    };

    const handleErrors = (questionaryErrors: IQuestionaryError, question: IHistoryQuestion): void => {
        setErrors((prevState) => {
            if (isAtHomeFlow) {
                const { list: errorList, showError } = questionaryErrors;

                if (showError && errorList?.length && question?.answerType !== IHistoryAnswerType.TEXT) {
                    scrollToError({ errorUuid: errorList[0], currentQuestionUuid: question?.uuid });
                }
            }
            return {
                ...prevState,
                ...questionaryErrors,
            };
        });
    };

    return (
        <>
            <Prompt when={preventUserNavigation} message={NavigationMessages.WARN_BEFORE_NAVIGATION} />
            {!isAtHomeFlow && <CaseStatusContainer activeStatus={RemoteTimeline.MEDICAL_HISTORY} />}
            <RemoteModelContainer header="Response to sun exposure">
                <Questionary
                    questionSets={fitzpatrickQuestionSet}
                    errors={errors}
                    updateAnswers={handleAnswersChange}
                    answers={answers}
                    updateErrors={handleErrors}
                    isRemoteModel
                    isFitzpatrick
                    nextButtons={[
                        {
                            action: submit,
                            isLoading: pendingRequest,
                            isDisabled: pendingRequest,
                            text: "Submit",
                            type: "submit",
                        },
                    ]}
                    recaptcha={recaptcha}
                />
            </RemoteModelContainer>

            <AtHomeSubmissionModal modal={modal} closeModal={closeModal} />
        </>
    );
};

export default MedicalHistoryQuestions;
