import { FC } from "react";
import { DialogContent } from "@material-ui/core";

import ModalDialog from "components/templates/dialog/ModalDialog";

import useModalPreference from "hooks/useModalPreference";

import "scss/Dialog.scss";

const MODAL_IDENTIFIER = "improvements-to-image-viewer-ux";

// Introduced in v2.9.0
const ImprovementsToImageViewerAnnouncement: FC = () => {
    const { setUserPreference, showModal } = useModalPreference(MODAL_IDENTIFIER);

    if (!showModal) {
        return null;
    }

    return (
        <ModalDialog
            title="Introducing an improved image viewer"
            open={showModal}
            iconSrc="/images/icons/icon-image-viewer.svg"
            onClose={setUserPreference}
            maxWidth="sm"
            disableBackdropClick
            buttons={[
                {
                    onClick: setUserPreference,
                    text: "Got it",
                    colour: "primary",
                },
            ]}
        >
            <DialogContent>
                <p>Now you can zoom by scrolling the mouse wheel and click and drag to pan around the image</p>
            </DialogContent>
        </ModalDialog>
    );
};

export default ImprovementsToImageViewerAnnouncement;
