import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

import { ModalContext } from "contextProviders/ModalProvider";

import { ModalTypes } from "model/modal";

const SuccessModal: FC = () => {
    const history = useHistory();

    const { setModalParameters } = useContext(ModalContext);

    const handleClose = () => {
        setModalParameters({
            className: "success-modal",
            content: <></>,
            isVisible: false,
            size: "small",
            type: ModalTypes.SHOW_CLOSE_BUTTON,
        });
        history.goBack();
    };

    return (
        <Modal.Content className="modal-success-screen">
            <div className="modal-success-wrapper">
                <Icon name="check circle" color="green" size="big" />
                <Header as="h3">Request sent.</Header>
                <Button onClick={handleClose}>Close</Button>
            </div>
        </Modal.Content>
    );
};

export default SuccessModal;
