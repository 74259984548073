const UUID_REGEXP = /[\w]{8}(-[\w]{4}){3}-[\w]{12}/;
const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/;

export const validEmail = (email: string) => EMAIL_REGEXP.test(email);

export const checkedNotAvailableDermatologist = (value: string | number | undefined) => !Number(value);

export const ERROR_FIELD_IS_REQUIRED = "This field is required";
export const NOT_VALID_EMAIL_ERROR = "Please enter valid email";
export const NOT_SAME_EMAILS_ERRORS = "Sorry, emails are not the same";
export const NOT_VALID_PREAUTHORISATION_NUMBER =
    "This pre-authorisation number does not appear to be valid, please check and try again.";
export const NOT_VALID_MEMBER_NUMBER = "Membership number should be 10 digits long (e.g. 1234567890)";

export const areStringsSimilar = (a: string, b: string) => (a === b ? 1 : 0);

export const isUndefined = (value: any) => value === undefined;

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const validPreauthorisationNumber = (value = ""): boolean => {
    const isEightCharactersLength = value.length === 8;
    if (isEightCharactersLength) {
        return true;
    }
    return UUID_REGEXP.test(value);
};

export const validMemberNumber = (value = "") => Boolean(value.length === 10);

export const multiplyEnding = (numberItems: number): string => (numberItems > 0 ? "s" : "");

interface IOrderableItem {
    order: number;
}
export const sortByOrder = <T extends IOrderableItem>(a: T, b: T) => a.order - b.order;

export const checkObjectsEquality = (obj1: unknown, obj2: unknown): boolean =>
    typeof obj1 === "object" && typeof obj2 === "object" && obj1 && obj2
        ? Object.keys(obj1).length === Object.keys(obj2).length &&
          Object.keys(obj1).every((property) => checkObjectsEquality(obj1[property], obj2[property]))
        : obj1 === obj2;

export const checkArraysEquality = (arr1: unknown[], arr2: unknown[]): boolean =>
    arr1.length === arr2.length && arr1.every((element, index) => checkObjectsEquality(element, arr2[index]));

export const isNumber = (value: unknown): boolean => Number.isFinite(parseInt(value as string, 10));
