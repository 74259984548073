import { FC, useContext, useState } from "react";
import { useSelector } from "react-redux";

import PatientSearchForm from "components/PatientSearch/PatientSearchForm";

import { PatientSearchContext } from "contextProviders/PatientSearchProvider";
import { PatientSearchActions } from "contextProviders/modules/patientSearch/actions";

import { doesStringContainBackspace, formatPostcode } from "helpers/patientSearch";

import { FormFields, INITIAL_PATIENT_SEARCH_FORM, IPatientSearchForm } from "model/patientSearch";

import { getCurrentCase } from "redux/selectors/assessment";

import * as patientService from "services/patientService";

const PatientSearch: FC = () => {
    const {
        dispatch,
        state: { searchFormData },
    } = useContext(PatientSearchContext);
    const { uuid: caseUuid } = useSelector(getCurrentCase);
    const [form, setForm] = useState<IPatientSearchForm>({ ...searchFormData, caseUuid });
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [isRequestError, setIsRequestError] = useState<boolean>(false);

    const resetForm = () => {
        setForm({ ...INITIAL_PATIENT_SEARCH_FORM, caseUuid });
        setIsRequestError(false);
        dispatch({ type: PatientSearchActions.HIDE_SEARCH_RESULTS });
    };

    const getPostcodeFormatted = (): string => {
        const currentPostcodeValue = form[FormFields.postCode];
        const doesPostcodeHaveBackspace = doesStringContainBackspace(currentPostcodeValue);

        if (!doesPostcodeHaveBackspace) {
            return formatPostcode(currentPostcodeValue);
        }

        return currentPostcodeValue;
    };

    const fetchPatients = async () => {
        try {
            setIsFetchingData(true);
            const formattedForm = { ...form, [FormFields.postCode]: getPostcodeFormatted() };
            setForm(formattedForm);
            const result = await patientService.searchIntegrationPatientPAS(formattedForm);
            dispatch({
                type: PatientSearchActions.SET_SEARCH_RESULTS,
                patients: result.data.data,
                form: formattedForm,
            });
            setIsFetchingData(false);
            setIsRequestError(false);
        } catch (e) {
            setIsRequestError(true);
            dispatch({ type: PatientSearchActions.HIDE_SEARCH_RESULTS });
            setIsFetchingData(false);
        }
    };

    return (
        <PatientSearchForm
            form={form}
            isFetchingData={isFetchingData}
            isRequestError={isRequestError}
            setForm={setForm}
            getPatients={fetchPatients}
            resetForm={resetForm}
        />
    );
};

export default PatientSearch;
