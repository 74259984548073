import { FC } from "react";

import NonSkinCancerLesionTable from "components/templates/NonSkinCancerLesionTable";
import IntegrationPatientInformationTable from "components/templates/PatientBanner/IntegrationPatientInformationTable";
import ManualPatientInformationTable from "components/templates/PatientBanner/ManualPatientInformationTable";
import LesionInformationTable from "components/templates/PatientBanner/LesionInformationTable";

import { ILesion, IPatient } from "model/assessment";
import { IOrganisation } from "model/organisation";

import isPASIntegration from "helpers/PASintegration";

import "scss/PatientBanner.scss";

interface IPatientBanner {
    caseId: string;
    lesion?: ILesion;
    lesionNumber?: number;
    patient: IPatient;
    nonSkinCancerFlow?: boolean;
    patientOrganisation?: IOrganisation;
}

const PatientBanner: FC<IPatientBanner> = ({
    caseId,
    lesion,
    lesionNumber,
    patient,
    nonSkinCancerFlow,
    patientOrganisation,
}) => {
    const isPatientCreatedWithPASIntegration = isPASIntegration(patient?.integrationInfo?.integrationName);

    return (
        <>
            {isPatientCreatedWithPASIntegration ? (
                <IntegrationPatientInformationTable caseId={caseId} patient={patient} />
            ) : (
                <ManualPatientInformationTable
                    caseId={caseId}
                    patient={patient}
                    patientOrganisation={patientOrganisation}
                />
            )}
            {!nonSkinCancerFlow && <LesionInformationTable lesion={lesion} lesionNumber={lesionNumber} />}
            {nonSkinCancerFlow && <NonSkinCancerLesionTable lesion={lesion} />}
        </>
    );
};

PatientBanner.defaultProps = {
    lesion: null,
    lesionNumber: 0,
    nonSkinCancerFlow: false,
    patientOrganisation: null,
};

export default PatientBanner;
