import { FC } from "react";
import clsx from "clsx";
import { SemanticWIDTHS, Table } from "semantic-ui-react";

interface IPatientBannerTableCell {
    width?: SemanticWIDTHS;
    isMainCell?: boolean;
    title?: string;
    value?: string | number | boolean;
}

const PatientBannerTableCell: FC<IPatientBannerTableCell> = ({ title, value, width, isMainCell }) => {
    const isEmpty = !title && !value;
    const className = clsx({
        "patient-banner-table-cell__main": isMainCell,
        "patient-banner-table-cell__empty": isEmpty,
    });

    return (
        <Table.Cell width={width} className={className}>
            {!isEmpty && (
                <p className="patient-banner-table-cell__wrapper">
                    <span>{title}</span>
                    <span className="patient-banner-table-cell__value">{value}</span>
                </p>
            )}
        </Table.Cell>
    );
};

PatientBannerTableCell.defaultProps = {
    width: undefined,
    isMainCell: false,
    title: "",
    value: "",
};

export default PatientBannerTableCell;
