import React, { FC, ChangeEvent, MouseEvent as ReactMouseEvent } from "react";
import { Button, ButtonProps, Form, InputOnChangeData } from "semantic-ui-react";

import CopyIcon from "components/templates/CopyIcon";

import { KitTrackingDictionary } from "model/remoteModel";
import ViewEditMode from "model/viewEditMode";

import "scss/Table.scss";

const MAX_INPUT_LENGTH = 27;

interface ITrackingStatusViewMode {
    trackIn?: ViewEditMode;
    trackOut?: ViewEditMode;
}

interface IEditableField {
    readonly name: string;
    readonly value?: string;
    readonly viewMode: ITrackingStatusViewMode;
    readonly urlBase?: string;

    onFieldChange: (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
    onHandleSetField: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => void;
}

const EditableField: FC<IEditableField> = ({ name, value, viewMode, urlBase, onFieldChange, onHandleSetField }) => {
    const buttonLabel = viewMode[name] === ViewEditMode.EDIT ? "Save" : "Edit";
    const tooltipTitle = KitTrackingDictionary[name].toLowerCase();
    const courierUrl = urlBase ? encodeURI(`${urlBase}${value}`) : undefined;
    const fieldRender = urlBase ? (
        <a className="case-review__tracking-table-render" href={courierUrl} target="_blank" rel="noreferrer">
            {value}
        </a>
    ) : (
        <span className="case-review__tracking-table-render">{value}</span>
    );

    const trackingData =
        viewMode[name] === ViewEditMode.EDIT ? (
            <Form.Input
                name={name}
                className="case-review__tracking-table-input"
                value={value}
                onChange={onFieldChange}
                maxLength={MAX_INPUT_LENGTH}
            />
        ) : (
            fieldRender
        );

    return (
        <Form className="case-review__tracking-table-editable-field">
            {trackingData}
            <span className="case-review__tracking-table-editable-field-action-wrapper">
                <CopyIcon valueToCopy={value} title={tooltipTitle} />
                <Button onClick={onHandleSetField} name={name}>
                    {buttonLabel}
                </Button>
            </span>
        </Form>
    );
};

EditableField.defaultProps = {
    value: "",
    urlBase: "",
};

export default EditableField;
