import { FC } from "react";
import { Divider, Header, Table } from "semantic-ui-react";

import PatientBanner from "components/templates/PatientBanner";
import ReviewDiagnosesTableRows from "components/CaseDescription/Review/ReviewDiagnosesTableRows";

import { ILesion } from "model/case";
import { CaseViewMode } from "model/caseViewMode";
import { IPatient } from "model/assessment";

import "scss/Table.scss";

interface ICaseReviewListProps {
    lesions: ILesion[];
    mode: CaseViewMode;
    managementOutcome: string;
    caseId?: string;
    patient?: IPatient;
}

const CaseReviewList: FC<ICaseReviewListProps> = ({ lesions, mode, managementOutcome, caseId, patient }) => {
    const isSuperAdminView = mode === CaseViewMode.SUPERADMINVIEW;
    const isReviewView = mode === CaseViewMode.REVIEWVIEW;
    const escLesion = lesions.find((lesion) =>
        lesion.reviews.find((review) => review.escalationPlan && review.escalationPlan !== "")
    );
    const isEscalation = Boolean(escLesion);
    const isNonSkinCancer = Boolean(escLesion);

    const managementColumnHeader = isNonSkinCancer ? "Management plan" : "Management outcome";

    return (
        <>
            {(isSuperAdminView || isReviewView) && (
                <>
                    <PatientBanner caseId={caseId} patient={patient} />
                    <Header as="h2" className="without-bottom-margin">
                        Case Review
                    </Header>
                    <Divider className="title-divider" />
                    <Table selectable={false}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width="3">#</Table.HeaderCell>
                                <Table.HeaderCell width="3">Location</Table.HeaderCell>
                                <Table.HeaderCell width="4">Reviewer</Table.HeaderCell>
                                <Table.HeaderCell width="4">Diagnosis</Table.HeaderCell>
                                <Table.HeaderCell width="4"> {managementColumnHeader} </Table.HeaderCell>
                                {isEscalation && <Table.HeaderCell width="4">Escalation plan</Table.HeaderCell>}
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <ReviewDiagnosesTableRows lesions={lesions} isEscalation={isEscalation} />
                        </Table.Body>
                    </Table>
                    {managementOutcome && `Management outcome: ${managementOutcome}`}
                </>
            )}
        </>
    );
};

CaseReviewList.defaultProps = {
    caseId: "",
    patient: null,
};

export default CaseReviewList;
