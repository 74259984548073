import { IAssignment } from "model/case";
import { CaseListTabs, ICaseListsState } from "model/caseList";
import { CaseStatus } from "model/caseStatus";
import { KitDeliveryStatuses } from "model/remoteModel";

export enum CaseListActions {
    SAVE_CASE_LIST_TAB = "SAVE_CASE_LIST_TAB",
    SET_LOADING = "SET_LOADING",
    FETCH_MORE = "FETCH_MORE",
    SET_FILTERS = "SET_FILTERS",
    UPDATE_CASE_STATUS = "UPDATE_CASE_STATUS",
    SET_SAS_LINK = "SET_SAS_LINK",
    UPDATE_KIT_DELIVERY_STATUS = "UPDATE_KIT_DELIVERY_STATUS",
    REMOVE_CASE_AFTER_CALLBACK = "REMOVE_CASE_AFTER_CALLBACK",
    REMOVE_ALL_CASES = "REMOVE_ALL_CASES",
}
export interface ISaveCaseListTab {
    tab: string;
    data: ICaseListsState;
    type: CaseListActions.SAVE_CASE_LIST_TAB;
}

export interface ISetLoading {
    tab: string;
    data: ICaseListsState;
    type: CaseListActions.SET_LOADING;
}

export interface IFetchMore {
    tab: string;
    data: ICaseListsState;
    type: CaseListActions.FETCH_MORE;
}

export interface ISetFilters {
    tab: string;
    data: ICaseListsState;
    type: CaseListActions.SET_FILTERS;
}

export interface IUpdateCaseStatus {
    currentTab: CaseListTabs;
    caseUuid: string;
    nextTab: string;
    newStatus: CaseStatus;
    newAssignee?: IAssignment;
    type: CaseListActions.UPDATE_CASE_STATUS;
}

export interface ISetSasLink {
    tab: CaseListTabs;
    data: { caseUuid: string; sasLink: string };
    type: CaseListActions.SET_SAS_LINK;
}

export interface IUpdateKitDeliveryStatus {
    caseUuid: string;
    currentTab: string;
    newKitDeliveryStatus: KitDeliveryStatuses;
    nextTab: string;
    type: CaseListActions.UPDATE_KIT_DELIVERY_STATUS;
}

export interface IRemoveCaseAfterCallback {
    caseUuid: string;
    type: CaseListActions.REMOVE_CASE_AFTER_CALLBACK;
}

export interface IRemoveAllCases {
    type: CaseListActions.REMOVE_ALL_CASES;
}

export type TCaseListActions =
    | ISaveCaseListTab
    | ISetLoading
    | IFetchMore
    | ISetFilters
    | IUpdateCaseStatus
    | ISetSasLink
    | IUpdateKitDeliveryStatus
    | IRemoveCaseAfterCallback
    | IRemoveAllCases;
