import React, { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import ButtonCloseModal from "components/RemoteModel/modals/ButtonCloseModal";

import withModal from "HOC/withModal";

import { ModalIconAltTexts } from "model/modal";

interface IConfirmationModal {
    goAhead: () => void;
    onClose: () => void;
}

const ConfirmationModal: FC<IConfirmationModal> = ({ goAhead, onClose }) => (
    <div className="remote-model__modal">
        <ButtonCloseModal onClick={onClose} />
        <Image alt={ModalIconAltTexts.DOCUMENT} src="/images/icons/icon-document.svg" />
        <p className="h2">Let’s double check if this is the right service for you</p>
        <p className="p">
            <b>Our service is appropriate for assessing a maximum of 3 lesions.</b>
        </p>
        <p className="p">
            To make sure our service is appropriate for your skin concern, we will ask you a few questions before
            starting.
        </p>
        <div className="buttons-wrapper">
            <CustomButton type="button" variant="filled" action={goAhead} text="OK" />
        </div>
    </div>
);

export default withModal(ConfirmationModal);
