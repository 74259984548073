import { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Header, Divider } from "semantic-ui-react";

import EditLink from "components/RemoteModel/CaseSummary/EditLink";
import CustomButton from "components/templates/Button";
import LesionImages, { LesionImagesType } from "components/templates/LesionImages";
import InfoModal from "components/RemoteModel/modals/InfoModal";

import { containsImagesToRetake } from "helpers/assessment";

import { ILesion } from "model/case";
import { AllLesionLocation } from "model/lesionLocation";

import { REMOTE_MODEL_CASES_LESION_DETAILS, REMOTE_MODEL_CASES_LESION_IMAGES } from "navigation/remoteModelRoutes";

import * as actions from "redux/actions";

interface ILesionOverview {
    lesion: ILesion;
    index: number;
    editable: boolean;
}

const LesionOverview: FC<ILesionOverview> = ({ lesion, index, editable }) => {
    const { bodyPart, description } = lesion;

    const dispatch = useDispatch();
    const history = useHistory();

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    const lesionHeader = `Lesion #${index} ${AllLesionLocation[bodyPart]}`;
    const lesionDescription: string = description ? ` (${description})` : "";

    const imagesToRetake: boolean = lesion.images && containsImagesToRetake(lesion.images);

    const setCurrentLesion = (): void => {
        dispatch(actions.assessmentActions.updateLesionNumber(index - 1));
        dispatch(actions.assessmentActions.setCurrentLesion({ uuid: lesion.uuid }));
    };

    const openInfoModal = (): void => {
        setShowInfoModal(true);
    };

    const closeModal = (): void => {
        setShowInfoModal(false);
    };

    const redirectToLesionImages = (): void => {
        setCurrentLesion();
        history.push(REMOTE_MODEL_CASES_LESION_IMAGES);
    };

    return (
        <>
            <div className="remote-model lesion-images">
                <div className="lesion-header">
                    <Header as="h3">
                        {lesionHeader}
                        {lesionDescription && <span>{lesionDescription}</span>}
                    </Header>
                </div>
                {editable && (
                    <Link className="change-lesion-location-link" to={`${REMOTE_MODEL_CASES_LESION_DETAILS}/${index}`}>
                        <Header as="h5">Change lesion location</Header>
                    </Link>
                )}
                <Divider />

                {lesion.images ? <LesionImages lesion={lesion} type={LesionImagesType.RemoteFlow} /> : null}

                {imagesToRetake ? (
                    <CustomButton
                        type="button"
                        variant="warning"
                        text="Retake images on the app"
                        action={openInfoModal}
                        className="margin-top-30"
                    />
                ) : (
                    editable && (
                        <EditLink
                            link={REMOTE_MODEL_CASES_LESION_IMAGES}
                            text="Retake images on the app"
                            action={setCurrentLesion}
                        />
                    )
                )}
            </div>

            <InfoModal
                show={showInfoModal}
                closeOnOverlay={false}
                onCancel={closeModal}
                onConfirm={redirectToLesionImages}
                message="You will need to scan the QR code again and replace images on the mobile app"
            />
        </>
    );
};

export default LesionOverview;
