import { FC, ReactNode } from "react";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";

import { getAllAvailableAllocations } from "helpers/allocations";

import { AllocationText } from "model/organisation";

interface IAllocationTexts {
    isUnconsented: boolean;
}

const AllocationTexts: FC<IAllocationTexts> = ({ isUnconsented }) => (
    <>
        {getAllAvailableAllocations().map((possibleAllocation) => {
            const key = `${possibleAllocation}-${isUnconsented ? "unconsented" : "consented"}`;
            return (
                <Grid.Column key={key} id={key}>
                    <b>{AllocationText[possibleAllocation]}</b>
                </Grid.Column>
            );
        })}
    </>
);

interface IAllocationConfigurationTableHeader {
    columnAmount: SemanticWIDTHS;
    enableUrgentRefer: boolean;
    isUnconsented?: boolean;
    children: ReactNode;
}

const AllocationConfigurationTableHeader: FC<IAllocationConfigurationTableHeader> = ({
    columnAmount,
    enableUrgentRefer,
    isUnconsented,
    children,
}) => (
    <Grid className="allocation-configuration-table" columns={columnAmount}>
        <Grid.Row>
            <Grid.Column width={4} />
            <AllocationTexts isUnconsented={isUnconsented} />
            <Grid.Column>
                <b>Immediate Results</b>
            </Grid.Column>
            {enableUrgentRefer ? (
                <Grid.Column>
                    <b>Priority Order</b>
                </Grid.Column>
            ) : null}
        </Grid.Row>
        {children}
    </Grid>
);

AllocationConfigurationTableHeader.defaultProps = {
    isUnconsented: false,
};

export default AllocationConfigurationTableHeader;
