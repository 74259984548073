import React, { FC, useState, useContext, useEffect, ReactNode } from "react";
import { Grid, Message } from "semantic-ui-react";

import CustomButton from "components/templates/Button";
import AbandonDialog from "components/templates/dialog/AbandonCaseDialog";
import CloseDialog from "components/templates/dialog/CloseCaseDialog";
import ReopenCaseDialog from "components/templates/dialog/ReopenCaseDialog";
import CaseHistoryDialog from "components/CaseDescription/CaseHistoryDialog";
import EditPatientInfoDialog from "components/templates/dialog/EditPatientInfoDialog";

import { CaseListContext } from "contextProviders/CaseListProvider";

import useCopy from "hooks/useCopy";

import { ICase, ReportStatus } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { CaseViewMode } from "model/caseViewMode";
import { IFormError } from "model/formError";
import { IOrganisation } from "model/organisation";
import { IUser } from "model/user";
import { UserRole } from "model/userRole";

import { CASE_DESCRIPTION } from "navigation/routes";

import * as caseService from "services/caseService";
import logoutTimeoutService from "services/logoutTimeoutService";
import isPASIntegration from "helpers/PASintegration";

interface ICaseAdministrationButtons {
    mode: CaseViewMode;
    caseObject: ICase;
    organisation: IOrganisation;
    user: IUser;
    caseRefetch: () => void;
}

const COPY_CASE_LINK_BUTTON_TITLE = "link to case";
const UPDATED_COPY_CASE_LINK_BUTTON_TITLE = "Copied to clipboard";
const COPY_CASE_LINK_BUTTON_ICON = "copy outline";

const CaseAdministrationButtons: FC<ICaseAdministrationButtons> = ({
    caseObject,
    mode,
    organisation,
    caseRefetch,
    user,
}) => {
    const [errors, setErrors] = useState<IFormError[] | false>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAbandonCaseDialog, setShowAbandonCaseDialog] = useState<boolean>(false);
    const [showReopenDialog, setShowReopenDialog] = useState<boolean>(false);
    const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);
    const [showCaseHistoryDialog, setShowCaseHistoryDialog] = useState<boolean>(false);
    const [showEditPatientDialog, setShowEditPatientDialog] = useState<boolean>(false);
    const [copyCaseLinkButtonIcon, setCopyCaseLinkButtonIcon] = useState<string | undefined>(
        COPY_CASE_LINK_BUTTON_ICON
    );

    const { updateCaseStatus } = useContext(CaseListContext);

    const { uuid: caseUuid, remote, patient } = caseObject;
    const { integrationInfo } = { ...patient };
    const isPatientCreatedWithPASIntegrationData = isPASIntegration(integrationInfo?.integrationName);

    const { origin } = window.location;

    const caseLink = `${origin}${CASE_DESCRIPTION}/${caseUuid}`;

    const { fieldTitle, handleCopyClick } = useCopy({
        titleOfCopyField: COPY_CASE_LINK_BUTTON_TITLE,
        valueOfCopyField: caseLink,
        titleOfUpdatedField: UPDATED_COPY_CASE_LINK_BUTTON_TITLE,
        displayUpdateDuration: 3000,
    });

    useEffect(() => {
        logoutTimeoutService.startCount();
    }, [showAbandonCaseDialog, showCloseDialog, showReopenDialog, showCaseHistoryDialog]);

    useEffect(() => {
        if (fieldTitle === UPDATED_COPY_CASE_LINK_BUTTON_TITLE) {
            setCopyCaseLinkButtonIcon(undefined);
        } else {
            setCopyCaseLinkButtonIcon(COPY_CASE_LINK_BUTTON_ICON);
        }
    }, [fieldTitle]);

    const toggleCaseHistoryDialog = () => {
        setShowCaseHistoryDialog(!showCaseHistoryDialog);
    };

    const toggleReopenDialog = () => {
        setShowReopenDialog(!showReopenDialog);
    };

    const onAbandonCaseClick = (show: boolean) => {
        setShowAbandonCaseDialog(show);
    };

    const onCloseCaseClick = (show: boolean) => {
        setShowCloseDialog(show);
    };

    const onEditPatientClick = (show: boolean) => {
        setShowEditPatientDialog(show);
    };

    const getCaseHistoryButton = (): ReactNode => (
        <CustomButton
            type="submit"
            action={toggleCaseHistoryDialog}
            variant="empty"
            text="View Case History"
            floated="right"
        />
    );

    const getAbandonCaseButton = (): ReactNode => {
        const permittedRoles: CaseViewMode[] = [CaseViewMode.ADMINVIEW, CaseViewMode.SUPERADMINVIEW];
        const hasViewPermission: boolean = permittedRoles.includes(mode);

        const permittedCaseStatusAdmin: CaseStatus[] = [
            CaseStatus.CASE_FAILED,
            CaseStatus.CASE_IN_PROGRESS,
            CaseStatus.CASE_CREATED,
        ];
        const permittedCaseStatusSuperAdmin: CaseStatus[] = [
            ...permittedCaseStatusAdmin,
            CaseStatus.CASE_FOR_REVIEW,
            CaseStatus.CASE_IN_REVIEW,
            CaseStatus.CASE_FOR_SA_REVIEW,
            CaseStatus.CASE_IN_SA_REVIEW,
            CaseStatus.CASE_IN_CLIENT_REVIEW,
            CaseStatus.CASE_COMPLETED,
        ];

        const isPermittedCaseStatus: boolean =
            mode === CaseViewMode.SUPERADMINVIEW
                ? permittedCaseStatusSuperAdmin.includes(caseObject.caseStatus)
                : permittedCaseStatusAdmin.includes(caseObject.caseStatus);

        const isReportToBeGenerated: boolean = caseObject?.report?.status !== ReportStatus.GENERATED;

        const shouldRenderAbandonCaseButton: boolean =
            mode === CaseViewMode.SUPERADMINVIEW
                ? isPermittedCaseStatus && hasViewPermission
                : isReportToBeGenerated && isPermittedCaseStatus && hasViewPermission;

        return (
            shouldRenderAbandonCaseButton && (
                <CustomButton
                    type="submit"
                    variant="empty"
                    text="Abandon case"
                    action={onAbandonCaseClick}
                    floated="right"
                    loading={loading}
                />
            )
        );
    };

    const getCloseCaseButton = (): ReactNode => {
        const permittedRoles: CaseViewMode[] = [CaseViewMode.ADMINVIEW, CaseViewMode.SUPERADMINVIEW, CaseViewMode.VIEW];
        const hasViewPermission: boolean = permittedRoles.includes(mode);
        const shouldRenderCloseCaseButton: boolean =
            caseObject.caseStatus === CaseStatus.CASE_COMPLETED && hasViewPermission;

        return (
            shouldRenderCloseCaseButton && (
                <CustomButton
                    type="submit"
                    variant="filled"
                    text="Close case"
                    action={onCloseCaseClick}
                    floated="right"
                    loading={loading}
                />
            )
        );
    };

    const getReopenCaseButton = (): ReactNode => {
        const permittedRoles: CaseViewMode[] = [CaseViewMode.ADMINVIEW, CaseViewMode.SUPERADMINVIEW, CaseViewMode.VIEW];

        const hasViewPermission: boolean = permittedRoles.includes(mode);
        const shouldRenderCloseCaseButton: boolean =
            caseObject.caseStatus === CaseStatus.CASE_CLOSED && hasViewPermission;

        return (
            shouldRenderCloseCaseButton && (
                <CustomButton
                    type="submit"
                    variant="filled"
                    text="Reopen case"
                    action={toggleReopenDialog}
                    floated="right"
                    loading={loading}
                />
            )
        );
    };

    const getEditPatientButton = (): ReactNode => {
        const permittedRoles: CaseViewMode[] = [CaseViewMode.SUPERADMINVIEW, CaseViewMode.ADMINVIEW];
        const hasViewPermission: boolean = permittedRoles.includes(mode);
        const shouldRenderEditPatientButton: boolean =
            hasViewPermission && !remote && !isPatientCreatedWithPASIntegrationData;

        return (
            shouldRenderEditPatientButton && (
                <CustomButton
                    type="submit"
                    variant="empty"
                    text="Edit patient info"
                    action={onEditPatientClick}
                    floated="right"
                    loading={loading}
                />
            )
        );
    };

    const getCopyCaseLinkButton = (): ReactNode => {
        const permittedUserRoles: UserRole[] = [UserRole.DERMATOLOGIST, UserRole.CLINICAL_VIEWER];
        const shouldRenderCopyCaseLinkButton: boolean =
            permittedUserRoles.includes(user.role) && Boolean(caseObject.caseId);

        return (
            shouldRenderCopyCaseLinkButton && (
                <CustomButton
                    type="button"
                    variant="filled"
                    text={fieldTitle}
                    icon={copyCaseLinkButtonIcon}
                    action={handleCopyClick}
                    className="copy-case-button"
                    floated="right"
                />
            )
        );
    };

    const getErrorMessages = () =>
        errors &&
        errors.map((error: any) => (
            <Message key="errorMessage" negative>
                {error.message}
            </Message>
        ));

    const abandonCase = (reason?: string) => {
        setLoading(true);
        caseService
            .abandonCase(caseUuid, user, reason)
            .then(() => {
                caseRefetch();
                updateCaseStatus(caseUuid, CaseStatus.CASE_ABANDONED);
            })
            .catch((err) => {
                setErrors(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeCase = () => {
        setLoading(true);
        caseService
            .closeCase(caseUuid)
            .then(() => {
                caseRefetch();
                updateCaseStatus(caseUuid, CaseStatus.CASE_CLOSED);
            })
            .catch((err) => {
                setErrors(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const reopenCase = () => {
        setLoading(true);
        caseService
            .reopenCase(caseUuid)
            .then(() => {
                caseRefetch();
                updateCaseStatus(caseUuid, CaseStatus.CASE_COMPLETED);
            })
            .catch((err) => {
                setErrors(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getAbandonCaseDialog = () =>
        showAbandonCaseDialog && (
            <AbandonDialog
                showDialog={showAbandonCaseDialog}
                callback={setShowAbandonCaseDialog}
                caseObject={caseObject}
                organisation={organisation}
                abandonCase={abandonCase}
            />
        );

    const getCloseCaseDialog = () =>
        showCloseDialog && (
            <CloseDialog
                showDialog={showCloseDialog}
                callback={setShowCloseDialog}
                caseObject={caseObject}
                organisation={organisation}
                closeCase={closeCase}
            />
        );

    const getReopenCaseDialog = () =>
        showReopenDialog && (
            <ReopenCaseDialog
                showDialog={showReopenDialog}
                reopenCase={reopenCase}
                currentCase={caseObject}
                closeDialog={toggleReopenDialog}
            />
        );

    const getCaseHistoryDialog = () =>
        showCaseHistoryDialog && (
            <CaseHistoryDialog
                showDialog={showCaseHistoryDialog}
                callback={toggleCaseHistoryDialog}
                currentCase={caseObject}
            />
        );

    const getEditPatientDialog = () =>
        showEditPatientDialog && (
            <EditPatientInfoDialog
                showDialog={showEditPatientDialog}
                callback={setShowEditPatientDialog}
                caseObject={caseObject}
            />
        );

    return (
        <div>
            {getAbandonCaseDialog()}
            {getCloseCaseDialog()}
            {getReopenCaseDialog()}
            {getCaseHistoryDialog()}
            {getEditPatientDialog()}

            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        {getErrorMessages()}
                        {getAbandonCaseButton()}
                        {getCloseCaseButton()}
                        {getReopenCaseButton()}
                        {getCaseHistoryButton()}
                        {getCopyCaseLinkButton()}
                        {getEditPatientButton()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default CaseAdministrationButtons;
