import { UserRole } from "model/userRole";

const PASSWORD_MIN_LENGTH_STANDARD = 10;
const PASSWORD_MIN_LENGTH_ELEVATED = 15;

export const blacklistErrorMessage =
    "Sorry, your password contains a word, phrase or pattern that makes it easily guessable. Please try again with a different one";

export const getPasswordMinLength = (role: UserRole): number => {
    if (role === UserRole.SUPERADMIN) {
        return PASSWORD_MIN_LENGTH_ELEVATED;
    }
    return PASSWORD_MIN_LENGTH_STANDARD;
};
