import { ChangeEvent, FC } from "react";
import { useDispatch } from "react-redux";
import { Container, Form, Header, Image, Input, InputOnChangeData, Segment } from "semantic-ui-react";

import { history } from "App";

import PasswordMatching from "components/PasswordMatching";
import CustomButton from "components/templates/Button";

import { blacklistErrorMessage } from "helpers/password";

import { CONFIRMED_PASSWORD_FIELD_NAME, PASSWORD_FIELD_NAME } from "hooks/useSetPassword";

import { SUPPORT_EMAIL } from "model/common";
import { UserRole } from "model/userRole";

import { authActions } from "redux/actions";

interface IUpdatePassword {
    passwordFormValue: string;
    confirmedPasswordFormValue: string;
    userRoleFromToken: UserRole;
    isButtonDisabled: boolean;
    formHasErrors: () => boolean;
    setFormValue: (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
    passwordIsOnDenyList: () => boolean;
    setIsButtonDisabled: (state: boolean) => void;
    changePassword: () => Promise<void>;
}

const UpdatePassword: FC<IUpdatePassword> = ({
    passwordFormValue,
    confirmedPasswordFormValue,
    userRoleFromToken,
    isButtonDisabled,
    formHasErrors,
    setFormValue,
    passwordIsOnDenyList,
    setIsButtonDisabled,
    changePassword,
}) => {
    const dispatch = useDispatch();

    const handleNavigateToHome = (): void => {
        dispatch(authActions.updatePasswordReset());
        history.push("/");
    };

    return (
        <Container className="reset-password-container">
            <Segment className="reset-password">
                <Image className="reset-password-icon" alt="Update password icon" src="/images/updatePassword.svg" />

                {formHasErrors() ? (
                    <>
                        <Header as="h3" className="reset-password-header">
                            Sorry!
                        </Header>

                        <p className="sorry-message">
                            Sorry something went wrong, please try again. If the problem persists, please contact{" "}
                            <a href={`mailto:${SUPPORT_EMAIL}`}>support@skinanalytics.co.uk</a> for further assistance.
                        </p>

                        <div className="reset-button-container">
                            <CustomButton
                                type="button"
                                variant="filled"
                                text="Try again"
                                action={handleNavigateToHome}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Header as="h3" className="reset-password-header">
                            Please reset your password
                        </Header>
                        <p className="reset-password-text">
                            Before you continue we need you to change your password to meet our new strengthened
                            security policy.
                        </p>

                        <Form>
                            <Form.Field>
                                <Input
                                    type="password"
                                    placeholder="New password"
                                    name={PASSWORD_FIELD_NAME}
                                    onChange={setFormValue}
                                    value={passwordFormValue}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    type="password"
                                    placeholder="Repeat new password"
                                    name={CONFIRMED_PASSWORD_FIELD_NAME}
                                    onChange={setFormValue}
                                    value={confirmedPasswordFormValue}
                                />
                            </Form.Field>

                            <div className="deny-list-error-container">
                                {passwordIsOnDenyList() ? (
                                    <p className="deny-list-error-text">{blacklistErrorMessage}</p>
                                ) : null}
                            </div>

                            <PasswordMatching
                                newPassword={passwordFormValue}
                                confirmNewPassword={confirmedPasswordFormValue}
                                makeButtonDisabled={setIsButtonDisabled}
                                userRole={userRoleFromToken}
                            />

                            <div className="reset-button-container">
                                <CustomButton
                                    type="submit"
                                    variant="filled"
                                    text="Reset Password"
                                    action={changePassword}
                                    disabled={isButtonDisabled}
                                />
                            </div>
                        </Form>
                    </>
                )}
            </Segment>
        </Container>
    );
};

export default UpdatePassword;
