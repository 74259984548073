import React, { FC, FormEvent, Fragment } from "react";
import { Grid, Checkbox, Input, Message } from "semantic-ui-react";

import { CaptureImageType } from "model/captureImageType";
import { BadImgReason, BadImgReasonText, ISelectedImage } from "model/case";

interface IProps {
    currentImage: ISelectedImage;
    hasErrored?: boolean;
    handleReasonSelection: (event: FormEvent<HTMLInputElement>, imageUuid: string) => void;
    imageType: string;
}

const ReasonsSelection: FC<IProps> = ({ currentImage, hasErrored, handleReasonSelection, imageType }) => {
    const handleChange = (event: FormEvent<HTMLInputElement>) => {
        handleReasonSelection(event, currentImage.uuid);
    };

    return (
        <Grid.Column width={10} className="reasons-selection-wrapper">
            <span>Select a reason</span>

            {Object.keys(BadImgReasonText).map((key) => {
                if (key === BadImgReason.NOT_PRESSED && imageType === CaptureImageType.MACRO) {
                    return null;
                }

                const optionText = BadImgReasonText[key];
                const currentReason = Boolean(currentImage.reasons.find((reason) => reason === key));

                const checkboxId = `${currentImage.uuid}-${key}`;
                const noteInputId = `${currentImage.uuid}-${key}-optionalNote`;

                return (
                    <Fragment key={checkboxId}>
                        <Checkbox
                            id={checkboxId}
                            label={optionText}
                            value={key}
                            checked={currentReason}
                            onChange={handleChange}
                        />
                        {key === BadImgReason.OTHER && (
                            <Input
                                type="text"
                                name="optionalNote"
                                id={noteInputId}
                                value={currentImage.optionalNotes}
                                placeholder="Type in reason"
                                onChange={handleChange}
                                disabled={!currentReason}
                            />
                        )}
                    </Fragment>
                );
            })}

            {hasErrored && !currentImage.reasons.length && <Message error content="Please select a reason" />}
        </Grid.Column>
    );
};

ReasonsSelection.defaultProps = {
    hasErrored: false,
};

export default ReasonsSelection;
