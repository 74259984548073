import { ICase } from "model/case";
import { IOrganisation, ITemplate, ITemplateField } from "model/organisation";
import { TemplateType } from "model/templateType";

import store from "redux/store";

import fillTextMarkers from "util/markerFiller";

const TEXT_NOT_DEFINED_MESSAGE = "Text not defined in organisation settings";

export const getTemplate = (templateType: TemplateType, organisation?: IOrganisation) => {
    const templates = organisation ? organisation.templates : store.store.getState().data.organisation.templates;
    return templates?.find((template: ITemplate) => template.name === templateType && !template.removed);
};

export const getFieldFromTemplate = (
    fieldName: string,
    caseObject: ICase,
    organisation: IOrganisation,
    template: ITemplate
) => {
    if (template && !template.isStructured) {
        template.fields as ITemplateField[];
        const fieldContent =
            (template.fields as ITemplateField[]).find((field) => field.name === fieldName)?.content ||
            TEXT_NOT_DEFINED_MESSAGE;
        return fillTextMarkers(fieldContent, caseObject, organisation);
    }
    return TEXT_NOT_DEFINED_MESSAGE;
};
