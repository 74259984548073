import { FC } from "react";
import { Table } from "semantic-ui-react";

import { ILesion } from "model/assessment";
import { LesionLocationNsc } from "model/lesionLocation";
import PresentingComplaint from "model/presentingComplaint";

import "scss/PatientBanner.scss";

interface INonSkinCancerLesionTable {
    lesion: ILesion | undefined;
}

const NonSkinCancerLesionTable: FC<INonSkinCancerLesionTable> = ({ lesion }) => {
    if (lesion) {
        const { presentingComplaint, complaintDescription, bodyPart, description } = lesion;
        return (
            <Table celled>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell width="2" className="main-cell">
                            <p>Presenting complaint</p>
                            <h4>{PresentingComplaint[presentingComplaint]}</h4>
                        </Table.Cell>
                        <Table.Cell width="8" className="lesion-table-max-width">
                            <p>Complaint description</p>
                            <h4>{complaintDescription}</h4>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width="2" className="main-cell">
                            <p>Location on body</p>
                            <h4>{LesionLocationNsc[bodyPart]}</h4>
                        </Table.Cell>
                        <Table.Cell width="8" className="lesion-table-max-width">
                            <p>Location notes</p>
                            <h4>{description}</h4>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    }
    return <></>;
};

export default NonSkinCancerLesionTable;
