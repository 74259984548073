import { IState } from "contextProviders/PatientSearchProvider";
import { TPatientSearchActions, PatientSearchActions } from "contextProviders/modules/patientSearch/actions";
import moment from "moment";

import { SortOrderUI } from "model/caseList";
import {
    IPatientSearchItem,
    ITEMS_PER_PAGE,
    PatientSearchExceptionModalTypes,
    SortableColumns,
} from "model/patientSearch";

interface IFirstLastItemsIndexes {
    firstItemOnPageIndex: number;
    lastItemOnPageIndex: number;
}

const getFirstLastItemIndexes = (
    patientsLength: number,
    currentPage: number,
    totalPages: number
): IFirstLastItemsIndexes => {
    const firstItemOnPageIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const isLastPage = currentPage === totalPages;
    const lastItemOnPageIndex = (isLastPage ? patientsLength : currentPage * ITEMS_PER_PAGE) - 1;
    return { firstItemOnPageIndex, lastItemOnPageIndex };
};

const MODAL_TYPE_STORE_PROPERTY_MAP = {
    [PatientSearchExceptionModalTypes.MINIMUM_AGE]: "showMinimumAgeExceptionModal",
    [PatientSearchExceptionModalTypes.DECEASED_PATIENT]: "showDeceasedPatientModal",
    [PatientSearchExceptionModalTypes.NOT_ENOUGH_DATA]: "showNotEnoughDataModal",
};

export default (state: IState, action: TPatientSearchActions): IState => {
    switch (action.type) {
        case PatientSearchActions.SET_SEARCH_RESULTS: {
            const { patients, form } = action;
            const totalPages = Math.ceil(patients.length / ITEMS_PER_PAGE);
            const currentPage = 1;
            const { firstItemOnPageIndex, lastItemOnPageIndex } = getFirstLastItemIndexes(
                patients.length,
                currentPage,
                totalPages
            );

            return {
                ...state,
                patients,
                showResults: true,
                currentPage,
                totalPages,
                lastItemOnPageIndex,
                firstItemOnPageIndex,
                sortByColumn: null,
                searchFormData: form,
            };
        }
        case PatientSearchActions.SORT_RESULTS_BY_COLUMN: {
            const { column } = action;
            const { sortByColumn, direction, patients, totalPages } = state;
            const isCurrentColumn = sortByColumn === column;
            const currentPage = 1;
            const { firstItemOnPageIndex, lastItemOnPageIndex } = getFirstLastItemIndexes(
                patients.length,
                currentPage,
                totalPages
            );

            if (isCurrentColumn) {
                const isAsc = direction === SortOrderUI.ASC;
                return {
                    ...state,
                    direction: isAsc ? SortOrderUI.DESC : SortOrderUI.ASC,
                    patients: patients.slice().reverse(),
                    currentPage,
                    firstItemOnPageIndex,
                    lastItemOnPageIndex,
                };
            }

            let sortResult: IPatientSearchItem[];
            const isDateOfBirth = column === SortableColumns.dateOfBirth;
            if (isDateOfBirth) {
                sortResult = patients.sort((a, b) => (moment(a[column]) > moment(b[column]) ? 1 : -1));
            } else {
                sortResult = patients.sort((a, b) => {
                    const propertyA = a[column].toLowerCase();
                    const propertyB = b[column].toLowerCase();
                    if (propertyA < propertyB) return -1;
                    if (propertyA > propertyB) return 1;
                    return 0;
                });
            }

            return {
                ...state,
                sortByColumn: column,
                direction: SortOrderUI.ASC,
                patients: sortResult,
                currentPage: 1,
                firstItemOnPageIndex,
                lastItemOnPageIndex,
            };
        }
        case PatientSearchActions.HIDE_SEARCH_RESULTS: {
            return {
                ...state,
                showResults: false,
            };
        }
        case PatientSearchActions.SET_RESULTS_PAGE: {
            const { currentPage } = action;
            const { totalPages, patients } = state;
            const { firstItemOnPageIndex, lastItemOnPageIndex } = getFirstLastItemIndexes(
                patients.length,
                currentPage,
                totalPages
            );

            return {
                ...state,
                currentPage,
                firstItemOnPageIndex,
                lastItemOnPageIndex,
            };
        }
        case PatientSearchActions.SET_EXPANDED_VIEW_PATIENT: {
            const { patient } = action;
            return {
                ...state,
                expandedViewPatient: patient,
            };
        }
        case PatientSearchActions.RESET_EXPANDED_VIEW_PATIENT: {
            return {
                ...state,
                expandedViewPatient: null,
            };
        }
        case PatientSearchActions.TOGGLE_ADVANCED_SEARCH_VISIBILITY: {
            return {
                ...state,
                showAdvancedSearch: !state.showAdvancedSearch,
            };
        }
        case PatientSearchActions.SET_MODAL_VISIBILITY: {
            const { show, modalType } = action;
            if (show) {
                const propetyName = MODAL_TYPE_STORE_PROPERTY_MAP[modalType];
                return {
                    ...state,
                    [propetyName]: show,
                };
            }

            return {
                ...state,
                showDeceasedPatientModal: false,
                showMinimumAgeExceptionModal: false,
                showNotEnoughDataModal: false,
            };
        }
        default: {
            return state;
        }
    }
};
