import { FC } from "react";
import { DropdownItemProps, Form, Grid } from "semantic-ui-react";

import { LesionReviewIds } from "model/reviewNotes";

import CustomSelect from "components/SelectDropdown/Select";

import { ERROR_FIELD_IS_REQUIRED } from "helpers/common";

interface ILesionReviewSelect {
    label: string;
    id: string;
    placeholder: string;
    options: DropdownItemProps[];
    onFieldChange: (event: any, obj: any) => void;
    value: string;
    freeTextValue?: string;
    withFreeText: boolean;
}

const LesionReviewSelect: FC<ILesionReviewSelect> = ({
    label,
    id,
    placeholder,
    options,
    onFieldChange,
    value,
    freeTextValue,
    withFreeText,
}) => {
    const showTextInput = withFreeText;
    const error =
        showTextInput && !freeTextValue
            ? {
                  content: ERROR_FIELD_IS_REQUIRED,
                  pointing: undefined,
              }
            : null;
    return (
        <Grid.Row>
            <Grid.Column>
                <p>
                    <b>{label}</b>
                </p>
                <CustomSelect
                    id={id}
                    placeholder={placeholder}
                    variant="outlined"
                    value={value || ""}
                    onChange={(e) => onFieldChange(e, { id, value: (e.target as HTMLInputElement).value })}
                    options={options}
                />
                {showTextInput && (
                    <Form.Input
                        required
                        label="Please specify"
                        type="text"
                        value={freeTextValue}
                        id={`${id}${LesionReviewIds.FREE_TEXT_SUFFIX}`}
                        onChange={onFieldChange}
                        error={error}
                    />
                )}
            </Grid.Column>
        </Grid.Row>
    );
};

LesionReviewSelect.defaultProps = {
    freeTextValue: "",
};

export default LesionReviewSelect;
