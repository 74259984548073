export const PATIENT_SUBPATH = "/patient";

export const REMOTE_MODEL_START = "/register";
export const REMOTE_MODEL_PASSWORD = "/create-password";
export const REMOTE_MODEL_REGISTRATION = `${PATIENT_SUBPATH}/user-details`;
export const REMOTE_MODEL_CONSENT = `${PATIENT_SUBPATH}/consent`;
export const REMOTE_MODEL_CASES_START = `${PATIENT_SUBPATH}/start-case-creation`;
export const REMOTE_MODEL_CASES_SUN_EXPOSURE = `${PATIENT_SUBPATH}/sun-exposure`;
export const REMOTE_MODEL_CASES_MEDICAL_HISTORY = `${PATIENT_SUBPATH}/medical-history`;
export const REMOTE_MODEL_CASES_KIT_DELIVERY = `${PATIENT_SUBPATH}/kit-delivery`;
export const REMOTE_MODEL_CASES_LESION_DETAILS = `${PATIENT_SUBPATH}/lesion-details`;
export const REMOTE_MODEL_CASES_LESION_IMAGES = `${PATIENT_SUBPATH}/lesion-images`;
export const REMOTE_MODEL_CASES_SUMMARY = `${PATIENT_SUBPATH}/summary`;
export const REMOTE_MODEL_CASES_IN_REVIEW = `${PATIENT_SUBPATH}/in-review`;
export const REMOTE_MODEL_CASES_REPORT = `${PATIENT_SUBPATH}/report`;
