import React, { FC } from "react";
import { Icon } from "semantic-ui-react";
import clsx from "clsx";

import { isUndefined } from "helpers/common";

import useCopy from "hooks/useCopy";

import CustomTooltip from "./CustomTooltip";

interface ICopyIcon {
    readonly title: string;
    readonly copyOverride?: string;
    readonly valueToCopy?: string;
}

const CopyIcon: FC<ICopyIcon> = ({ valueToCopy, title, copyOverride }) => {
    const { handleCopyClick, fieldTitle } = useCopy({
        titleOfCopyField: title,
        valueOfCopyField: copyOverride || valueToCopy,
    });

    const disabled = isUndefined(valueToCopy) || valueToCopy === "";
    const copyButtonClassNames = clsx("btn-copy-email", { disabled });

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            handleCopyClick(e);
        }
    };

    return (
        <CustomTooltip title={fieldTitle} leaveDelay={500}>
            <button
                tabIndex={0}
                onKeyDown={handleKeyDown}
                className={copyButtonClassNames}
                type="button"
                onClick={handleCopyClick}
                aria-label={fieldTitle}
            >
                <Icon role="button" name="copy outline" />
            </button>
        </CustomTooltip>
    );
};

CopyIcon.defaultProps = {
    copyOverride: "",
    valueToCopy: "",
};

export default CopyIcon;
