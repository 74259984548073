import { FC, useContext } from "react";
import { Table } from "semantic-ui-react";
import { useSelector } from "react-redux";

import CustomButton from "components/templates/Button";
import IntegrationPatientLabel from "components/templates/IntegrationPatientLabel";

import { PatientDetailsValidation } from "components/PatientDetails/PatientDetailsValidation";

import { PatientSearchContext } from "contextProviders/PatientSearchProvider";
import { PatientSearchActions } from "contextProviders/modules/patientSearch/actions";

import { formatTime } from "helpers/datetime";
import { getHospitalNumberClassName } from "helpers/patientUtils";

import { IPatientSearchItem, PatientLabelTypes, PatientSearchExceptionModalTypes } from "model/patientSearch";

import { getOrganisation } from "redux/selectors/data";

interface IPatientSearchResultsTableRow {
    patient: IPatientSearchItem;
    onViewPatientClick: (patient: IPatientSearchItem) => void;
}

const PatientSearchResultsTableRow: FC<IPatientSearchResultsTableRow> = ({ patient, onViewPatientClick }) => {
    const { dispatch } = useContext(PatientSearchContext);
    const organisation = useSelector(getOrganisation);

    const { forename1, surname, nhsNumber, dateOfBirth, sex, patientIdentifier, postCode, mergedRecord, dateOfDeath } =
        patient;
    const formattedDate = formatTime(dateOfBirth);
    const isDeceased = Boolean(dateOfDeath);
    const { minimumAge } = organisation;

    const { isLowerThanMinAgeDefined } = PatientDetailsValidation.isAgeValid(dateOfBirth, minimumAge);

    const handleViewPatientClicked = (): void => {
        let modalType: PatientSearchExceptionModalTypes;
        if (isDeceased) {
            modalType = PatientSearchExceptionModalTypes.DECEASED_PATIENT;
        }
        if (!isDeceased && isLowerThanMinAgeDefined) {
            modalType = PatientSearchExceptionModalTypes.MINIMUM_AGE;
        }
        if (modalType) {
            dispatch({
                type: PatientSearchActions.SET_MODAL_VISIBILITY,
                modalType,
                show: true,
            });
        } else {
            onViewPatientClick(patient);
        }
    };

    return (
        <Table.Row key={patientIdentifier} data-testid="patient-search-results-row">
            <Table.Cell>{surname}</Table.Cell>
            <Table.Cell>{forename1}</Table.Cell>
            <Table.Cell className={getHospitalNumberClassName(isDeceased, mergedRecord)}>
                {patientIdentifier}
                <div>
                    {isDeceased && <IntegrationPatientLabel type={PatientLabelTypes.DECEASED} />}
                    {mergedRecord && <IntegrationPatientLabel type={PatientLabelTypes.MERGED_RECORD} />}
                </div>
            </Table.Cell>
            <Table.Cell>{nhsNumber}</Table.Cell>
            <Table.Cell>{formattedDate}</Table.Cell>
            <Table.Cell>{sex}</Table.Cell>
            <Table.Cell>{postCode}</Table.Cell>
            <Table.Cell>
                <CustomButton variant="filled" text="View" type="button" action={handleViewPatientClicked} />
            </Table.Cell>
        </Table.Row>
    );
};

export default PatientSearchResultsTableRow;
