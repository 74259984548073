import { FC } from "react";

import PatientBannerTableCell from "components/templates/PatientBanner/PatientBannerTableCell";

import { formatTime } from "helpers/datetime";
import { getPatientData } from "helpers/patientUtils";

import { IPatientData } from "model/case";
import { IDefinedPersonalData } from "model/organisation";

interface IPatientDataField {
    definedPersonalData: IDefinedPersonalData[];
    patientData: IPatientData[];
    index: number;
}

const PatientDataField: FC<IPatientDataField> = ({ definedPersonalData, patientData, index }) => {
    if (patientData) {
        if (index >= definedPersonalData.length) {
            return <PatientBannerTableCell width="3" />;
        }

        const currentPersonalData: IDefinedPersonalData = definedPersonalData[index];

        const currentPatientPersonalData = patientData.find(
            (personalData) => personalData.name.toLowerCase() === currentPersonalData.propertyName.toLowerCase()
        );

        if (currentPatientPersonalData) {
            const value = getPatientData({
                patientDataItem: currentPatientPersonalData,
                dateFormatter: formatTime,
                withAge: true,
            });

            return value && <PatientBannerTableCell width="3" title={currentPersonalData.displayName} value={value} />;
        }
    }
    return <PatientBannerTableCell width="3" />;
};

export default PatientDataField;
