import { FC } from "react";
import { Grid } from "semantic-ui-react";
import clsx from "clsx";

import QuestionaryItemResponse from "components/QuestionaryItemResponse";

import { IHistoryQuestion } from "model/organisation";
import { IQuestionaryError } from "model/questionaryError";
import IQuestionaryAnswerValidation from "model/questionaryAnswerValidation";

import "scss/Questionary.scss";

interface IQuestionaryItemQuestion {
    answers: any;
    errors: IQuestionaryError;
    isBack: boolean;
    updateAnswers: (data: any) => void;
    validateAnswers: ({ showError, question }: IQuestionaryAnswerValidation) => void;
    question: IHistoryQuestion;
    optionalNotes: boolean;
    isRemoteModel?: boolean;
    isAtHomeFlow?: boolean;
}

const QuestionaryItemQuestion: FC<IQuestionaryItemQuestion> = ({
    question,
    answers,
    isBack,
    errors,
    updateAnswers,
    validateAnswers,
    optionalNotes,
    isRemoteModel,
    isAtHomeFlow,
}) => {
    const { uuid, question: questionText, required } = question;
    const columnClassNames = clsx(
        isRemoteModel && "questionary-item-remote",
        isAtHomeFlow && "questionary-item-at-home"
    );

    return (
        <Grid.Row id={`${uuid}.row`}>
            <Grid.Column width={10} className={`questionary-item ${columnClassNames}`}>
                {questionText}
                {required && <span className="asterisk">*</span>}
                <QuestionaryItemResponse
                    question={question}
                    errors={errors}
                    isBack={isBack}
                    answers={answers}
                    updateAnswers={updateAnswers}
                    validateAnswers={validateAnswers}
                    optionalNotes={optionalNotes}
                />
            </Grid.Column>
        </Grid.Row>
    );
};

QuestionaryItemQuestion.defaultProps = {
    isRemoteModel: false,
    isAtHomeFlow: false,
};

export default QuestionaryItemQuestion;
