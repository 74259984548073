import React, { Component } from "react";
import { Form, Icon, Table } from "semantic-ui-react";

import SettingsPropertyWrapper from "components/Administration/OrganisationManagment/ViewOrganisation/SettingsPropertyWrapper/SettingsPropertyWrapper";
import ContextIconButton from "components/templates/ContextIconButton";

import { ILesionExclusion, IOrganisation } from "model/organisation";
import ViewEditMode from "model/viewEditMode";

import questionService from "services/questionService";

interface IHistoryQuestionRowProps {
    onRemove: (event: any, obj: any) => void;
    exclusion: ILesionExclusion;
    organisation: IOrganisation;
}

class ExclusionRow extends Component<IHistoryQuestionRowProps, any> {
    constructor(props: any) {
        super(props);
        const { exclusion } = this.props;
        const {
            exclusion: exclusionExclusion,
            exclusionOrder,
            nonExcluding,
            uuid,
            copyOfUuid,
            organisationUuid,
        } = exclusion;
        this.state = {
            exclusion,
            mode: ViewEditMode.VIEW,

            row: {
                exclusion: exclusionExclusion,
                exclusionOrder,
                nonExcluding,
                uuid,
                copyOfUuid,
                organisationUuid,
            },
        };
    }

    private getExclusion() {
        const { organisation } = this.props;
        const { row } = this.state;
        const { exclusion, copyOfUuid, organisationUuid } = row;
        const { parentUuid } = organisation;

        return (
            <SettingsPropertyWrapper
                copyOfUuid={copyOfUuid}
                parentOrgUuid={parentUuid}
                propertyOrganisationUuid={organisationUuid}
            >
                {exclusion}
            </SettingsPropertyWrapper>
        );
    }

    private getQuestionOrder() {
        const { row } = this.state;

        return row.exclusionOrder;
    }

    private getNonExcluding() {
        const { row } = this.state;

        return row.nonExcluding ? <Icon name="check" /> : <Icon name="cancel" />;
    }

    private onRemoveRow = (event: any, obj: any) => {
        const { onRemove } = this.props;
        const { row } = this.state;

        questionService.deleteExclusion(row.uuid).then(() => {
            onRemove(event, obj);
        });
    };

    private onEditRow = () => {
        this.setState({ mode: ViewEditMode.EDIT });
    };

    private onCancel = () => {
        this.setState({ mode: ViewEditMode.VIEW });
    };

    public render() {
        const { organisation, exclusion } = this.props;
        const { exclusion: exclusionState, mode, row } = this.state;
        const canEditExclusion = !exclusion.organisationUuid || organisation.uuid === exclusion.organisationUuid;

        return (
            <Table.Row key={exclusionState.question}>
                <Table.Cell width={3}>
                    <Form>{this.getExclusion()}</Form>
                </Table.Cell>
                <Table.Cell width={2}>
                    <Form>{this.getQuestionOrder()}</Form>
                </Table.Cell>
                <Table.Cell width={2}>
                    <Form>{this.getNonExcluding()}</Form>
                </Table.Cell>
                <Table.Cell width={1}>
                    {mode === ViewEditMode.EDIT && (
                        <>
                            <ContextIconButton iconName="trash" onClick={this.onRemoveRow} uuid={row.uuid} />
                            <ContextIconButton iconName="window close" onClick={this.onCancel} />
                            &nbsp;
                        </>
                    )}
                    {mode === ViewEditMode.VIEW && canEditExclusion && (
                        <ContextIconButton iconName="edit" onClick={this.onEditRow} />
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default ExclusionRow;
