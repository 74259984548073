import { ILesion, InterfaceVersion } from "model/case";
import { DATA_IS_NOT_AVAILABLE_TEXT } from "model/common";
import { AllLesionLocation } from "model/lesionLocation";

export const getLocation = (lesion: ILesion) => {
    const { description, bodyPart } = lesion;
    const lesionDescription = description ? ` (${description})` : "";
    const lesionLocation = AllLesionLocation[bodyPart]?.toUpperCase();

    return `${lesionLocation}${lesionDescription}`;
};

/**
 * Since DERM 3.0 the default logic is to use the `referralFlag`. DERM 2 uses `conditionGuidance`
 *
 * OV2 will retain the DERM 2 version check to render the correct condition guidance for old cases
 */
export const getLesionManagementOutcome = (lesion: ILesion, isCaseSummary?: boolean): string => {
    const { interfaceNumber, lesions } = lesion.result;
    return (
        lesions
            ?.map((lesionResult) => {
                const { referralRecommendation, interpretationGuidance, conditionGuidance } = lesionResult;
                let recommendation = { plainText: "" };

                if (isCaseSummary) {
                    recommendation = referralRecommendation;
                } else if (interfaceNumber === InterfaceVersion.VERSION_2 && conditionGuidance) {
                    recommendation = conditionGuidance;
                } else {
                    recommendation = interpretationGuidance;
                }

                return recommendation.plainText;
            })
            .join(", ") || DATA_IS_NOT_AVAILABLE_TEXT
    );
};
