import { FC, useState, useEffect, useCallback, useRef, SyntheticEvent } from "react";
import clsx from "clsx";
import { List, Segment, Icon, FormProps } from "semantic-ui-react";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import { IAdminNextStepQuestion } from "model/administrativeNextSteps";
import { CaseStatus } from "model/caseStatus";
import { ReviewMode } from "model/caseViewMode";
import { INITIAL_REQUEST_STATUS, IRequestStatus } from "model/common";
import { NoteType, NoteTypeTitle } from "model/reviewNotes";
import { UserRole } from "model/userRole";

import AdministrativeNextSteps from "components/CaseDescription/Review/AdministrativeNextSteps";
import useCaseNote from "components/CaseDescription/hooks/useCaseNote";
import CustomButton from "components/templates/Button";
import ModalDialog from "components/templates/dialog/ModalDialog";

import userService from "services/userService";

import { formatTimeWithHours } from "helpers/datetime";

import { getUser } from "redux/selectors/data";
import { getCurrentCase } from "redux/selectors/assessment";

export interface IClinicalNote {
    removed: boolean;
    note: string;
    uuid: string;
    createdByName?: string;
    creationDate?: string;
    createdBy?: string;
    supersedes?: string;
    originalCreationDate?: string;
    lastModificationDate?: string;
}

interface IClinicalNotes {
    notes: IClinicalNote[];
    caseUuid?: string;
    patientUuid?: string;
    updateCaseNotes?: (
        type: NoteType,
        note: string,
        noteUuid?: string,
        organisationUuid?: string,
        supersedes?: boolean,
        supersededNoteOriginalCreationDate?: Date
    ) => void;
    removeCaseNote?: (type: NoteType, noteUuid: string) => void;
    submitForAssessment?: any;
    isValid?: boolean;
    caseAssignee?: string;
    administrativeNextStepQuestions?: IAdminNextStepQuestion[];
    setAdministrativeNextSteps?: (e: SyntheticEvent<HTMLElement>, data: FormProps) => void;
    validateAdministrativeNextSteps?: () => boolean;
    callbackOutcomeSubmitted?: boolean;
}

interface IError {
    message: string;
    callback: () => void;
    type: string;
}

const ClinicalNotes: FC<IClinicalNotes> = ({
    notes,
    caseUuid,
    patientUuid,
    updateCaseNotes,
    removeCaseNote,
    submitForAssessment,
    isValid,
    caseAssignee,
    administrativeNextStepQuestions,
    setAdministrativeNextSteps,
    validateAdministrativeNextSteps,
    callbackOutcomeSubmitted,
}) => {
    const {
        caseNote: clinicalNote,
        handleCaseNoteChange: handleClinicalNoteChange,
        submitCaseNote: submitClinicalNote,
        setCaseNote: setClinicalNote,
    } = useCaseNote({
        caseUuid,
        patientUuid,
        noteType: NoteType.CLINICAL,
    });

    const {
        caseNote: existingNote,
        removeCaseNote: removeClinicalNote,
        updateCaseNote: updateExistingNote,
        handleCaseNoteChange: handleExistingCaseNoteChange,
        setCaseNote: setExistingCaseNote,
    } = useCaseNote({
        caseUuid,
        patientUuid,
        noteType: NoteType.CLINICAL,
        previousNoteText: clinicalNote,
        removeNote: removeCaseNote,
    });

    const MAX_NOTE_LENGTH = 1000;

    const inputRef = useRef(null);
    const currentUser = useSelector(getUser);
    const currentUserUuid = currentUser.uuid;
    const notRemovedNotes = notes?.filter((note) => !note.removed);
    const notesByCurrentUser = notRemovedNotes?.filter((note) => note.createdBy === currentUserUuid);
    const currentCase = useSelector(getCurrentCase);
    const isCaseCompletedOrClosed = [CaseStatus.CASE_CLOSED, CaseStatus.CASE_COMPLETED].includes(
        currentCase?.caseStatus
    );
    const organisationHasAdminNextSteps = !!administrativeNextStepQuestions?.length;

    const [showModal, setShowModal] = useState<boolean>(false);
    const [deleteNoteUuid, setDeleteNoteUuid] = useState<string>("");
    const [requestStatus, setRequestStatus] = useState<IRequestStatus>(INITIAL_REQUEST_STATUS);
    const [existingNoteUuid, setExistingNoteUuid] = useState<string>("");
    const [isUserTyping, setIsUserTyping] = useState<boolean>(false);
    const [shouldSupersede, setShouldSupersede] = useState<boolean>(false);
    const [showNoteInput, setShowNoteInput] = useState<boolean>(!notesByCurrentUser?.length);
    const [supersededNoteOriginalCreationDate, setSupersededNoteOriginalCreationDate] = useState<Date>(null);
    const [error, setError] = useState<IError>({ message: "", callback: undefined, type: "" });
    const [isAdminNextStepsOpen, setIsAdminNextStepsOpen] = useState<boolean>(false);

    const isSuperAdmin = currentUser.role === UserRole.SUPERADMIN;
    const isCaseAssignedToCurrentUser = caseAssignee === userService.getCurrentUserUuid();
    const isCurrentUserAllowedToAddNotes =
        userService.checkUserHasRole([UserRole.SUPERADMIN, UserRole.CALLBACK_AGENT]) || isCaseAssignedToCurrentUser;

    const allChangesSaved = !isUserTyping && !requestStatus.isPending && requestStatus.isSuccess;
    const savingChanges = isUserTyping && requestStatus.isPending && !requestStatus.isSuccess;

    const disableAddAdditionalNote =
        (showNoteInput && !clinicalNote.length && !existingNote.length) ||
        (clinicalNote.length && !allChangesSaved) ||
        (existingNote.length && requestStatus.isPending);

    const getNoteCreatedByName = (patientNote: IClinicalNote, createdByName: string): string => {
        const isNewlyCreatedNote = !("removed" in patientNote);

        if (!isNewlyCreatedNote) {
            return createdByName;
        }

        const isCurrentUserDermatologist = currentUser.role === UserRole.DERMATOLOGIST;
        if (!isCurrentUserDermatologist) {
            return createdByName;
        }

        const userTitle = currentUser.title ? `${currentUser.title} ` : "";

        return `${userTitle}${createdByName} (GMC ${currentUser.gmcNumber})`;
    };

    const handleDeleteNoteClick = () => {
        try {
            const countOfNotesByCurrentUser = notesByCurrentUser.length;
            removeClinicalNote(deleteNoteUuid);
            setShowModal(false);
            if (countOfNotesByCurrentUser === 1) {
                setShowNoteInput(true);
            }
        } catch (e) {
            setError({
                message: "We couldn't delete your note. Click here to save the note manually",
                callback: handleDeleteNoteClick,
                type: "network",
            });
        }
    };

    const handleEditNote = (uuid, note): void => {
        if (allChangesSaved) {
            updateCaseNotes(
                NoteType.CLINICAL,
                existingNote,
                existingNoteUuid,
                currentUser.organisationUuid,
                true,
                supersededNoteOriginalCreationDate
            );
        }
        setRequestStatus({ ...requestStatus, isSuccess: false, isPending: false });
        setExistingCaseNote(note);
        setExistingNoteUuid(uuid);
        setShowNoteInput(true);
        inputRef?.current?.focus();
    };

    // TODO: Look into why custom useDebounce hook not working with useCallback. Try to fix and remove lodash.debounce package
    const handleTyping = useCallback(
        debounce(() => {
            setIsUserTyping(false);
        }, 2000),
        []
    );

    const handleRequestStatus = (isSuccess: boolean): void => {
        if (isSuccess) {
            setRequestStatus({ ...requestStatus, isSuccess: true, isPending: false });
        } else {
            setRequestStatus({ ...requestStatus, isPending: true, isSuccess: false });
        }
    };

    const handleAddAnotherNoteClick = (): void => {
        if (existingNote) {
            if (allChangesSaved) {
                updateCaseNotes(
                    NoteType.CLINICAL,
                    existingNote,
                    existingNoteUuid,
                    currentUser.organisationUuid,
                    true,
                    supersededNoteOriginalCreationDate
                );
            }
            handleEditNote("", "");
            setClinicalNote("");
            setSupersededNoteOriginalCreationDate(null);
        }
        setShowNoteInput(true);
    };

    const handleCreateNewNote = async () => {
        try {
            const newNote: any = await submitClinicalNote();
            const { uuid, note } = newNote?.data?.data;
            handleEditNote(uuid, note);
            handleRequestStatus(true);
        } catch (e) {
            setError({
                message: "We couldn't save your note. Click here to save the note manually",
                callback: handleCreateNewNote,
                type: "network",
            });
        }
    };

    const handleSupersedeNote = async () => {
        try {
            const supersedingNoteData: any = await updateExistingNote(existingNoteUuid, existingNote, true);
            const supersedingNote = supersedingNoteData?.data.data;
            const { uuid, note, originalCreationDate } = supersedingNote;
            setSupersededNoteOriginalCreationDate(originalCreationDate);
            handleEditNote(uuid, note);
            handleRequestStatus(true);
        } catch (e) {
            setError({
                message: "We couldn't save your changes. Click here to save the note manually",
                callback: handleSupersedeNote,
                type: "network",
            });
        }
    };

    const handleOverwriteExistingNote = async () => {
        try {
            await updateExistingNote(existingNoteUuid, existingNote, false);
            handleRequestStatus(true);
        } catch (e) {
            setError({
                message: "We couldn't save your changes. Click here to save the note manually",
                callback: handleOverwriteExistingNote,
                type: "network",
            });
        }
    };

    useEffect(() => {
        handleRequestStatus(false);
        if (clinicalNote && !existingNoteUuid && !isUserTyping) {
            handleCreateNewNote();
        }
        if (existingNoteUuid && existingNote && !isUserTyping && shouldSupersede) {
            removeClinicalNote(existingNoteUuid);
            handleSupersedeNote();
            setShouldSupersede(false);
        }
        if (existingNoteUuid && existingNote && !isUserTyping && !shouldSupersede) {
            handleOverwriteExistingNote();
        }
    }, [isUserTyping]);

    useEffect(() => {
        if (callbackOutcomeSubmitted === true && existingNote.length) {
            updateCaseNotes(NoteType.CLINICAL, existingNote, existingNoteUuid, currentUser.organisationUuid, true);
            handleEditNote("", "");
            setClinicalNote("");
            setShowNoteInput(false);
        }
    }, [callbackOutcomeSubmitted]);

    const handleNoteInputChange = (e): void => {
        const removeInputError =
            (error.type === "paste" && e.target.value.length < MAX_NOTE_LENGTH) ||
            (error.type === "no-note" && e.target.value.length > 0);
        if (removeInputError) {
            setError({ callback: undefined, type: "", message: "" });
        }

        if (existingNoteUuid) {
            handleExistingCaseNoteChange(e, e.target);
        } else {
            handleClinicalNoteChange(e, e.target);
        }
        if (isUserTyping === false) {
            setIsUserTyping(true);
        }

        handleTyping();
    };

    const handleAdminNextStepsOpen = (): void => {
        setIsAdminNextStepsOpen(!isAdminNextStepsOpen);
    };

    const now = new Date();
    const twoMinutesAgo = new Date(now.getTime() - 2 * 60 * 1000);
    const notesToRender = notRemovedNotes
        ?.map((note) => {
            if (
                new Date(note.lastModificationDate) > twoMinutesAgo &&
                note.createdBy !== currentUserUuid &&
                !isCaseCompletedOrClosed
            ) {
                const supersededNoteUuid = note.supersedes;
                return notes.find((x) => x.uuid === supersededNoteUuid);
            }
            return note;
        })
        .filter((note) => note !== undefined);

    const notesSortedByOriginalCreationDate = notesToRender?.sort((a, b) => {
        const dateA = a.originalCreationDate || a.creationDate;
        const dateB = b.originalCreationDate || b.creationDate;
        return new Date(dateA).valueOf() - new Date(dateB).valueOf();
    });

    const handleSubmitAssessment = (): void => {
        if (organisationHasAdminNextSteps) {
            const isAdminNextStepsError = validateAdministrativeNextSteps();
            if (isAdminNextStepsError) {
                setIsAdminNextStepsOpen(true);
                return;
            }
        }

        const hasWrittenClinicalNote = notesByCurrentUser?.length || !!existingNote;

        if (currentCase?.remote && !hasWrittenClinicalNote) {
            setError({ message: "Please provide a clinical note", callback: undefined, type: "no-note" });
            return;
        }
        if (existingNote.length) {
            updateCaseNotes(NoteType.CLINICAL, existingNote, existingNoteUuid, currentUser.organisationUuid, true);
            handleEditNote("", "");
            setClinicalNote("");
            setShowNoteInput(false);
            global.setTimeout(() => {
                submitForAssessment();
            }, 500);
        } else {
            submitForAssessment();
        }
    };

    const onInputPaste = (event): void => {
        const pastedTextLength = event.clipboardData.getData("text").length;
        const exceedingCharacters = pastedTextLength - MAX_NOTE_LENGTH;
        if (exceedingCharacters > 0) {
            setError({
                message: "",
                callback: undefined,
                type: "paste",
            });
        }
    };

    const retryFailedRequest = (): void => {
        const errorClone = { ...error };
        setError({ message: "", callback: undefined, type: "" });
        errorClone.callback();
    };

    const canModifyOwnNote = (note: IClinicalNote): boolean => {
        const isOwnNote = note.createdBy === currentUserUuid;
        const isAssessmentComplete = isCaseCompletedOrClosed && !currentCase.isCallbackNeeded;
        const canUserRoleEditCase =
            isCaseAssignedToCurrentUser ||
            isSuperAdmin ||
            (currentCase?.isCallbackNeeded && currentUser.role === UserRole.CALLBACK_AGENT);

        return isOwnNote && !isAssessmentComplete && canUserRoleEditCase;
    };

    return (
        <>
            <div className="clinical-notes">
                <h4>
                    {NoteTypeTitle.CLINICAL}
                    <Tooltip
                        title={
                            <p style={{ fontSize: "14px" }}>
                                Your notes are automatically saved and will be visible to any clinician involved in the
                                assessment
                            </p>
                        }
                    >
                        <Icon name="info" size="tiny" bordered />
                    </Tooltip>
                </h4>
                <div className="notes-container">
                    {!notesSortedByOriginalCreationDate?.length && !isCurrentUserAllowedToAddNotes && (
                        <div className="no-notes">No notes recorded</div>
                    )}
                    <List>
                        {notesSortedByOriginalCreationDate?.map((patientNote: IClinicalNote) => {
                            const { note, createdByName, creationDate, originalCreationDate, uuid } = patientNote;
                            const time = formatTimeWithHours(originalCreationDate || creationDate);
                            const noteCreatedByName = getNoteCreatedByName(patientNote, createdByName);
                            const noteTitle = `Captured by ${noteCreatedByName} (${time})`;

                            if (existingNoteUuid !== patientNote.uuid) {
                                return (
                                    <List.Item key={uuid}>
                                        <p className="subtitle-gray-text assessed-by-text">{noteTitle}</p>
                                        <Segment className="note note-container">
                                            <div className="actual-note-wrapper">
                                                <p>{note}</p>
                                            </div>
                                            {canModifyOwnNote(patientNote) && (
                                                <div className="notes-buttons">
                                                    <Button
                                                        onClick={() => {
                                                            handleEditNote(uuid, note);
                                                            setShouldSupersede(true);
                                                        }}
                                                        startIcon={<Icon name="pencil" size="small" />}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            setDeleteNoteUuid(uuid);
                                                            setShowModal(true);
                                                        }}
                                                        startIcon={<Icon name="trash" size="small" />}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            )}
                                        </Segment>
                                    </List.Item>
                                );
                            }
                            return null;
                        })}
                    </List>
                    <>
                        {isCurrentUserAllowedToAddNotes && (
                            <div>
                                {showNoteInput && (
                                    <form>
                                        <TextField
                                            id="standard-multiline-flexible"
                                            multiline
                                            minRows={3}
                                            InputProps={{ disableUnderline: true, inputProps: { maxLength: 1000 } }}
                                            placeholder="Type your clinical note here"
                                            value={existingNoteUuid ? existingNote : clinicalNote}
                                            onChange={(e) => handleNoteInputChange(e)}
                                            inputRef={inputRef}
                                            style={{
                                                border:
                                                    error.type === "paste" || error.type === "no-note"
                                                        ? "solid 1px #c90002"
                                                        : "",
                                            }}
                                            onFocus={(e) =>
                                                e.currentTarget.setSelectionRange(
                                                    e.currentTarget.value.length,
                                                    e.currentTarget.value.length
                                                )
                                            }
                                            autoFocus={!!notesByCurrentUser?.length}
                                            onPaste={(e) => {
                                                onInputPaste(e);
                                            }}
                                        />
                                        {!error.callback && (
                                            <div className="edit-note-input-info">
                                                {(allChangesSaved || savingChanges) && (
                                                    <small>
                                                        <Icon
                                                            className={clsx(savingChanges && "icon-spinning")}
                                                            name={allChangesSaved ? "checkmark" : "refresh"}
                                                            size="small"
                                                        />
                                                        {allChangesSaved ? "All changes saved" : "Saving changes..."}
                                                    </small>
                                                )}
                                                <span
                                                    className="character-count"
                                                    style={{ color: error.type === "paste" ? "#c90002" : "inherit" }}
                                                >{`${
                                                    MAX_NOTE_LENGTH -
                                                    (existingNoteUuid ? existingNote.length : clinicalNote.length)
                                                } characters remaining`}</span>
                                            </div>
                                        )}
                                        {error.callback && (
                                            <div className="error-message">
                                                <Icon name="close" />
                                                <Button variant="text" onClick={retryFailedRequest}>
                                                    {error.message}
                                                </Button>
                                            </div>
                                        )}
                                    </form>
                                )}
                                <div className="add-additional-note">
                                    <Button
                                        onClick={handleAddAnotherNoteClick}
                                        startIcon={<Icon name="plus" size="small" />}
                                        disabled={!!disableAddAdditionalNote}
                                    >
                                        Add additional note
                                    </Button>
                                </div>
                            </div>
                        )}
                    </>
                </div>
                <ModalDialog
                    title="Are you sure you want to delete this clinical note?"
                    open={showModal}
                    iconName="warning sign"
                    onClose={setShowModal}
                    buttons={[
                        { text: "Delete", onClick: () => handleDeleteNoteClick() },
                        { text: "Cancel", onClick: () => setShowModal(false) },
                    ]}
                    maxWidth="sm"
                >
                    <p>Deleting a clinical note will permanently remove it from the case</p>
                </ModalDialog>
            </div>

            {organisationHasAdminNextSteps ? (
                <AdministrativeNextSteps
                    mode={ReviewMode.REVIEW}
                    nextStepQuestions={administrativeNextStepQuestions}
                    handleChange={setAdministrativeNextSteps}
                    isOpen={isAdminNextStepsOpen}
                    handleOpen={handleAdminNextStepsOpen}
                />
            ) : null}

            {submitForAssessment && (
                <div className="submit-for-assessment-container">
                    <CustomButton
                        action={handleSubmitAssessment}
                        variant="empty"
                        disabled={!isValid || savingChanges}
                        type="submit"
                        text="Submit assessment"
                    />
                </div>
            )}
        </>
    );
};

ClinicalNotes.defaultProps = {
    caseUuid: "",
    patientUuid: "",
    updateCaseNotes: undefined,
    removeCaseNote: undefined,
    submitForAssessment: undefined,
    isValid: undefined,
    caseAssignee: "",
    administrativeNextStepQuestions: [],
    setAdministrativeNextSteps: undefined,
    validateAdministrativeNextSteps: undefined,
    callbackOutcomeSubmitted: false,
};

export default ClinicalNotes;
