import { FC } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-material-ui-carousel";
import { DialogContent } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

import ModalDialog from "components/templates/dialog/ModalDialog";

import useModalPreference from "hooks/useModalPreference";

import { UserRole } from "model/userRole";

import { getOrganisation } from "redux/selectors/data";

import userService from "services/userService";

import "scss/AdministrativeNextStepsDialog.scss";

interface INextStepAnnouncementContent {
    headerText: string;
    bodyText: string;
}

interface INextStepAnnouncementProps {
    header: string;
    body: string;
}

const NextStepAnnouncement: FC<INextStepAnnouncementProps> = ({ header, body }) => (
    <div className="carousel-content">
        <h3>{header}</h3>
        <p>{body}</p>
    </div>
);

const MODAL_IDENTIFIER = "administrative-next-steps";
const TEMPLATE_NAME = "ADMINISTRATIVE_NEXT_STEPS";

const AdministrativeNextStepsAnnouncement: FC = () => {
    const { setUserPreference, showModal } = useModalPreference(MODAL_IDENTIFIER);

    const organisation = useSelector(getOrganisation);
    const adminNextStepsTemplate = organisation?.templates?.find(
        (template) => template.name === TEMPLATE_NAME && !template.removed
    );
    const isUserDermatologist = userService.checkUserHasRole([UserRole.DERMATOLOGIST]);

    const carouselContent: INextStepAnnouncementContent[] = [
        { headerText: "New functionality", bodyText: "Write and share with your team the next steps for patients" },
        {
            headerText: "Helping streamlining workflows",
            bodyText: "Reducing the use of additional systems for communicating the next steps for the patient",
        },
        {
            headerText: "A consistent way of working",
            bodyText: "The use of a structured form across the organisation.",
        },
    ];

    return (
        <div className="admin-next-steps-modal">
            <ModalDialog
                open={showModal && isUserDermatologist && !!adminNextStepsTemplate}
                iconSrc="/images/icons/icon-chat.svg"
                onClose={setUserPreference}
                maxWidth="sm"
                disableBackdropClick
                buttons={[
                    {
                        onClick: setUserPreference,
                        text: "Got it",
                        colour: "primary",
                    },
                ]}
            >
                <DialogContent className="admin-next-steps-modal-content">
                    <Carousel
                        index={0}
                        autoPlay={false}
                        animation="slide"
                        navButtonsAlwaysVisible
                        fullHeightHover={false}
                        PrevIcon={<KeyboardArrowLeft className="carousel-left-button" />}
                        NextIcon={<KeyboardArrowRight className="carousel-right-button" />}
                        navButtonsWrapperProps={{ className: "carousel-nav-button-container" }}
                        navButtonsProps={{
                            className: "carousel-nav-buttons",
                        }}
                        indicatorIconButtonProps={{
                            className: "carousel-indicator-icons",
                        }}
                        activeIndicatorIconButtonProps={{
                            className: "carousel-active-indicator-icons",
                        }}
                        indicatorContainerProps={{
                            className: "carousel-indicator-container",
                        }}
                    >
                        {carouselContent.map(({ headerText, bodyText }) => (
                            <NextStepAnnouncement key={headerText} header={headerText} body={bodyText} />
                        ))}
                    </Carousel>
                </DialogContent>
            </ModalDialog>
        </div>
    );
};

export default AdministrativeNextStepsAnnouncement;
