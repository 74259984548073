import React from "react";
import { Grid } from "semantic-ui-react";

import AbstractConfirmDialog from "components/templates/dialog/AbstractConfirmDialog";

import { ICase } from "model/case";

import "scss/Dialog.scss";

interface IReallocatedCaseDialogProps {
    showDialog: boolean;
    reopenCase: (uuid: string) => void;
    currentCase: ICase;
    closeDialog: () => void;
}

const ReopenCaseDialog = (props: IReallocatedCaseDialogProps) => {
    const { reopenCase, showDialog, currentCase, closeDialog } = props;
    const { caseId, uuid } = currentCase;

    const bodyText = `Are you sure you want to reopen the case?
        Reopened cases will return to the "To Close" list.
        An administrator can Close the case again when ready.`;
    const title = `Reopen Case ${caseId}`;

    const onConfirm = () => {
        reopenCase(uuid);
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>{bodyText}</Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        (showDialog && (
            <AbstractConfirmDialog
                title={title}
                open={showDialog}
                callback={closeDialog}
                yesButton={{
                    backgroundColor: "#2185D0",
                    fontColor: "#FFFFFF",
                    onClick: onConfirm,
                    text: "Yes",
                }}
                noButton={{
                    backgroundColor: "#db2828",
                    fontColor: "#FFFFFF",
                    onClick: closeDialog,
                    text: "No",
                }}
            >
                {popupBody}
            </AbstractConfirmDialog>
        )) ||
        null
    );
};

export default ReopenCaseDialog;
