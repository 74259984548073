import { AuthorizedHttpConnector } from "dao/http/authorizedHttpConnector";

import { IMedicalHistoryAnswers } from "model/assessment";
import { BodyLocations } from "model/bodyLocations";
import PresentingComplaint from "model/presentingComplaint";

import store from "redux/store";
import * as actions from "redux/actions";

import * as ErrorService from "services/errorService";

import URLs from "services/urls";

const { CREATE_LESION_URL, UPDATE_LESION_URL, GET_LESION_URL, UPDATE_EXCLUSIONS_URL } = URLs;

function createLesion(
    caseUuid: string,
    patientUuid: string,
    bodyPart: BodyLocations,
    description: string,
    lesionHistoryAnswers: IMedicalHistoryAnswers[],
    lesionExclusions?: IMedicalHistoryAnswers[],
    presentingComplaint?: PresentingComplaint,
    complaintDescription?: string,
    callback?: () => void,
    errorCallback?: () => void
) {
    return (dispatch: any) => {
        AuthorizedHttpConnector.sendPost(CREATE_LESION_URL, {
            bodyPart,
            caseUuid,
            description,
            lesionExclusions,
            lesionHistoryAnswers,
            patientUuid,
            presentingComplaint,
            complaintDescription,
        })
            .then((response) => {
                dispatch(actions.assessmentActions.createLesionSuccess(response.data.data));
                if (callback) {
                    callback();
                }
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
                if (errorCallback) {
                    errorCallback();
                }
            });
    };
}

function updateLesion(
    lesionUuid: string,
    caseUuid: string,
    patientUuid: string,
    bodyPart: BodyLocations,
    description: string,
    lesionHistoryAnswers: IMedicalHistoryAnswers[],
    lesionExclusions?: IMedicalHistoryAnswers[],
    presentingComplaint?: PresentingComplaint,
    complaintDescription?: string
) {
    return (dispatch: any) => {
        AuthorizedHttpConnector.sendPut(UPDATE_LESION_URL.replace("{{UUID}}", lesionUuid), {
            bodyPart,
            caseUuid,
            description,
            lesionExclusions,
            lesionHistoryAnswers,
            patientUuid,
            presentingComplaint,
            complaintDescription,
        })
            .then((response) => {
                dispatch(
                    actions.assessmentActions.updateLesionSuccess({
                        lesionUuid,
                        lesionHistoryAnswers,
                        lesionExclusions,
                        response: response.data.data,
                    })
                );
            })
            .catch((err) => {
                ErrorService.handleError(err, dispatch);
            });
    };
}

async function getLesion(uuid: string) {
    try {
        const response = await AuthorizedHttpConnector.sendGet(GET_LESION_URL.replace("{{UUID}}", uuid));
        return response.data.data;
    } catch (err) {
        ErrorService.handleError(err, store.store.dispatch);
        return err;
    }
}

async function updateExclusions(caseUuid: string, patientUuid: string, lesionUuid: string, lesionExclusions: any[]) {
    const response = await AuthorizedHttpConnector.sendPost(UPDATE_EXCLUSIONS_URL.replace("{{UUID}}", lesionUuid), {
        caseUuid,
        lesionExclusions,
        patientUuid,
    });

    return response.data;
}

export default {
    createLesion,
    getLesion,
    updateExclusions,
    updateLesion,
};
