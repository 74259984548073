import React, { FC, MouseEventHandler } from "react";

const ButtonCloseModal: FC<{
    onClick: MouseEventHandler<HTMLButtonElement>;
}> = ({ onClick }) => (
    <button type="button" onClick={onClick} className="remote-model__modal-close">
        <img className="is-icon" src="/images/icons/icon-close.svg" alt="Icon close" />
    </button>
);

export default ButtonCloseModal;
