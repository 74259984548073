import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Divider } from "semantic-ui-react";

import CreateDeviceScreen from "components/Administration/StudyManagment/CreateDevice/CreateDeviceScreen";
import CreateStudyScreen from "components/Administration/StudyManagment/CreateStudy/CreateStudyScreen";
import EditStudyScreen from "components/Administration/StudyManagment/EditStudy/EditStudyScreen";
import StudyList from "components/Administration/StudyManagment/StudyList";

import { StudyManagmentSubpages } from "model/administrationPages";
import { UserRole } from "model/userRole";

import userService from "services/userService";

interface IStudyManagmentScreenProps {
    params: any;
}
class StudyManagmentScreen extends Component<IStudyManagmentScreenProps> {
    private getContext() {
        const { params } = this.props;
        const { subpage = null } = { ...params };
        if (subpage) {
            if (subpage === StudyManagmentSubpages.STUDY_DETAILS) {
                return <EditStudyScreen params={params} />;
            }
            if (subpage === StudyManagmentSubpages.CREATE_DEVICE) {
                return <CreateDeviceScreen params={params} />;
            }
            if (subpage === StudyManagmentSubpages.CREATE_STUDY) {
                return <CreateStudyScreen params={params} />;
            }
        }
        return <StudyList />;
    }

    public render() {
        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        return (
            <div>
                <h1>Study Management</h1>
                <Divider />
                {this.getContext()}
            </div>
        );
    }
}

export default StudyManagmentScreen;
