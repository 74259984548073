import { FC } from "react";
import { DropdownItemProps, Grid, Header } from "semantic-ui-react";

import { ILesionItemReview } from "components/CaseDescription/Review/CaseReview";
import LesionReviewCheckbox from "components/CaseDescription/Review/LesionReviewCheckbox";
import LesionReviewSelect from "components/CaseDescription/Review/LesionReviewSelect";
import ReviewSafetyNettingMessage from "components/CaseDescription/Review/ReviewSafetyNettingMessage";

import { hasFreeText, mapToOptions } from "helpers/review";

import { ILesion } from "model/case";
import { IManagementOptions, IReviewEscalation } from "model/organisation";
import { AllLesionLocation } from "model/lesionLocation";
import { LesionReviewIds } from "model/reviewNotes";

interface ILesionReview {
    lesion: ILesion;
    index: number;
    diagnosesDictionary: DropdownItemProps[];
    onFieldChange: (fieldName: string, fieldValue: string, uuid: string) => void;
    reviewDiagnoses: any[];
    review: ILesionItemReview;
    reviewManagementOptions: IManagementOptions[];
    escalationPlan?: IReviewEscalation[];
    nonSkinCancer?: boolean;
}

const LesionReview: FC<ILesionReview> = ({
    lesion,
    index,
    diagnosesDictionary,
    onFieldChange,
    reviewDiagnoses,
    review,
    reviewManagementOptions,
    escalationPlan,
    nonSkinCancer,
}) => {
    const {
        selectedDiagnoses,
        selectedManagement,
        selectedDiagnosesFreeText,
        selectedManagementFreeText,
        selectedEscalation,
        selectedEscalationFreeText,
    } = review;
    const currentDiagnosis = reviewDiagnoses.find((diagnoses) => diagnoses.uuid === selectedDiagnoses);
    const isFreeTextDiagnosis = currentDiagnosis ? currentDiagnosis.freeText : false;
    const managementOptionsUuids = currentDiagnosis
        ? [...currentDiagnosis.managementOptions.map((option: IManagementOptions) => option.managementUuid)]
        : [];

    const management = reviewManagementOptions
        ? managementOptionsUuids.map((managementOptionUuid) =>
              reviewManagementOptions.filter((option) => !option.removed && managementOptionUuid === option.uuid).pop()
          )
        : [];
    const activeManagement = management.filter((value) => !!value);
    const managementDictionary = mapToOptions(activeManagement, "uuid", "management");
    const freeTextReview = hasFreeText(reviewManagementOptions, selectedManagement);

    const handleFieldChange = (event: any, obj: any) => {
        const fieldName = obj.id;
        const fieldValue = obj.value;
        const { uuid } = lesion;
        onFieldChange(fieldName, fieldValue, uuid);
    };

    const actualEscalationOptions = escalationPlan?.filter((esscalation) => !esscalation.removed) || [];
    const escalationDictionary = mapToOptions(actualEscalationOptions, "uuid", "escalationPlan");
    const lesionNumber = index + 1;
    const lesionNumberText = `of lesion ${lesionNumber}`;
    const selectDiagnosesLabel = `What is your diagnosis ${nonSkinCancer ? "" : lesionNumberText}`;
    const selectManagementLabel = `What is your management ${nonSkinCancer ? "plan" : `outcome ${lesionNumberText}`}`;
    const escalationLabel = `What is your escalation plan`;

    const selectedManagementOption = selectedManagement
        ? reviewManagementOptions.find((option) => option.uuid === selectedManagement)
        : undefined;
    const isEscalationFreeText = !!escalationPlan?.find((escalation) => escalation.uuid === selectedEscalation)
        ?.freeText;
    return (
        <div>
            <Header as="h2" className="without-bottom-margin">
                Lesion {index + 1}. {AllLesionLocation[lesion.bodyPart]}
            </Header>
            <p className="subtitle-gray-text assessed-by-text">{lesion.description}</p>
            <br />

            <Grid columns={1}>
                <LesionReviewSelect
                    label={selectDiagnosesLabel}
                    id={LesionReviewIds.DIAGNOSES}
                    placeholder="Select diagnosis"
                    options={diagnosesDictionary}
                    onFieldChange={handleFieldChange}
                    value={selectedDiagnoses}
                    freeTextValue={selectedDiagnosesFreeText}
                    withFreeText={isFreeTextDiagnosis}
                />
                <LesionReviewCheckbox
                    label={selectManagementLabel}
                    id={LesionReviewIds.MANAGEMENT}
                    options={managementDictionary}
                    onFieldChange={handleFieldChange}
                    value={selectedManagement}
                    freeTextValue={selectedManagementFreeText}
                    withFreeText={freeTextReview}
                />
                {nonSkinCancer && (
                    <LesionReviewCheckbox
                        label={escalationLabel}
                        id={LesionReviewIds.ESCALATION}
                        options={escalationDictionary}
                        onFieldChange={handleFieldChange}
                        value={selectedEscalation}
                        freeTextValue={selectedEscalationFreeText}
                        withFreeText={isEscalationFreeText}
                    />
                )}
                {selectedManagementOption && (
                    <ReviewSafetyNettingMessage
                        keyMessage={selectedManagementOption.keyMessage}
                        safetyNettingMessage={selectedManagementOption.safetyNetting}
                    />
                )}
            </Grid>
            <br />
        </div>
    );
};

LesionReview.defaultProps = {
    escalationPlan: [],
    nonSkinCancer: false,
};

export default LesionReview;
