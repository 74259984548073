import React, { Component } from "react";
import { Grid, Form, Table, Label, Menu, Pagination } from "semantic-ui-react";

import SuborganisationRow from "components/Administration/OrganisationManagment/ViewOrganisation/Suborganisations/SuborganisationRow";

import { IOrganisation } from "model/organisation";

import organisationService from "services/organizationService";

interface ISuborganisationTableProps {
    organisation: IOrganisation;
}

class SuborganisationTable extends Component<ISuborganisationTableProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activePage: 0,
            nameFilter: "",
            organisationList: [],
            totalOrganisations: 0,
            totalPages: 0,
        };
    }

    public async componentDidMount() {
        await this.loadPage(0, undefined);
    }

    private getSuborganisationTableBody() {
        const { organisationList } = this.state;
        if (organisationList && organisationList.length > 0) {
            return organisationList.map((device: IOrganisation) => (
                <SuborganisationRow key={device.uuid} suborganisation={device} />
            ));
        }

        return (
            <Table.Row key="none">
                <Table.Cell> - </Table.Cell>
                <Table.Cell> - </Table.Cell>
            </Table.Row>
        );
    }

    private handlePaginationChange = (e: any, { activePage }: any) => {
        const { nameFilter } = this.state;
        this.loadPage(activePage - 1, nameFilter);
    };

    private updateSubOrganisationNameFilter = (event: any, obj: any) => {
        const { value } = obj;

        this.setState({ nameFilter: value });
        this.loadPage(0, value);
    };

    private async loadPage(actualPage: number, organisationNameFilter: string | undefined): Promise<void> {
        const { organisation } = this.props;

        try {
            const result = await organisationService.getAllSuborganisations(
                organisation.uuid,
                actualPage * 10,
                organisationNameFilter
            );
            this.setState({
                activePage: actualPage,
                organisationList: result.data,
                totalOrganisations: result.total,
                totalPages: Math.ceil(result.total / 10),
            });
        } catch (e) {
            console.error(e);
        }
    }

    public render() {
        const { nameFilter, totalOrganisations, activePage, totalPages } = this.state;

        return (
            <Form>
                <Grid columns={4}>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Form.Input
                                type="text"
                                value={nameFilter}
                                label="Organisation Name"
                                id="organisationNameFilter"
                                placeholder="Search by organisation name..."
                                onChange={this.updateSubOrganisationNameFilter}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Table padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Type</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.getSuborganisationTableBody()}</Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={7} textAlign="left">
                                <Label className="organisation-table-label">
                                    <Label.Detail>Total Organisations: {totalOrganisations}</Label.Detail>
                                </Label>
                                <Menu floated="right" pagination>
                                    <Pagination
                                        activePage={activePage + 1}
                                        boundaryRange={1}
                                        onPageChange={this.handlePaginationChange}
                                        size="small"
                                        siblingRange={1}
                                        totalPages={totalPages}
                                        firstItem={null}
                                        lastItem={null}
                                    />
                                </Menu>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Form>
        );
    }
}

export default SuborganisationTable;
