import { Component, ReactNode } from "react";
import { Button, Form, Grid, Message, Segment } from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

import DisableAuditConfigurationModal from "components/Administration/OrganisationManagment/ViewOrganisation/Consent/DisableAuditConfigurationModal";
import CustomRichTextEditor from "components/templates/Editor";

import { validOrganisationEmail } from "helpers/organisation";

import { IOrganisation } from "model/organisation";
import ViewEditMode from "model/viewEditMode";

import organizationService from "services/organizationService";

import "scss/OrganisationManagement.scss";

interface IEditDetailsComponentProps {
    organisation: IOrganisation;
    updateOrganisation: (organisation: IOrganisation) => void;
}

interface ConsentField {
    name?: string;
    label?: string;
    blockLabel?: string;
    blockName?: string;
    block?: Array<{ name: string; label: string }>;
}

export const MAX_LOGOFILE_SIZE = 2097152;

const consentFields: ConsentField[] = [
    {
        name: "organisationAssessment",
        label: "Assessment Consent <b>for Clinic flow (Clinician view) & Remote Model (Patient View)</b>",
    },
    {
        name: "organisationAssessmentPatient",
        label: "Assessment Consent <b>for At home patient questionnaire (Patient View)</b>",
    },
    {
        blockLabel: "Automated decision making",
        blockName: "automatedDecisionMaking",
        block: [
            {
                name: "organisationAutomatedDecisionConsentClinician",
                label: "Clinician view",
            },
            {
                name: "organisationAutomatedDecisionConsentPatient",
                label: "Patient view",
            },
        ],
    },
    {
        name: "organisationResearch",
        label: "Research Consent <b>for Clinic flow (Clinician view) & Remote Model (Patient View)</b>",
    },

    {
        name: "organisationResearchPatient",
        label: "Research Consent <b>for At home patient questionnaire (Patient View)</b>",
    },
    {
        name: "organisationPrimaryConsent",
        label: "<b>Consent Request - One</b>",
    },
    {
        name: "organisationSecondaryConsent",
        label: "<b>Consent Request - Two</b>",
    },
    {
        name: "organisationAssessmentNsc",
        label: "Assessment Consent for other skin conditions",
    },
    {
        name: "organisationResearchNsc",
        label: "Research Consent for other skin conditions",
    },
];

class Consent extends Component<IEditDetailsComponentProps, any> {
    private static checkPropertySubmitConfirmationInBackendResponse(
        propertyName: string,
        previousValue: any,
        currentValue: any,
        backendResponse: any,
        errors: any[]
    ) {
        const propertyValueChanged = previousValue !== currentValue;

        if (propertyValueChanged && currentValue && backendResponse[propertyName] !== currentValue) {
            errors.push({ message: `Incorrect ${propertyName} in backend response` });
        }
    }

    constructor(props: any) {
        super(props);
        const { organisation } = this.props;

        this.state = {
            errorDetails: [],
            mode: ViewEditMode.VIEW,
            organisation,
            organisationAssessment: organisation.assessment,
            organisationResearch: organisation.research,
            organisationAssessmentPatient: organisation.assessmentPatient,
            organisationResearchPatient: organisation.researchPatient,
            organisationPrimaryConsent: organisation.primaryConsent,
            organisationSecondaryConsent: organisation.secondaryConsent,
            organisationAssessmentNsc: organisation.assessmentNsc,
            organisationResearchNsc: organisation.researchNsc,
            organisationShowAutomatedDecisionConsent: organisation.showAutomatedDecisionConsent || false,
            organisationAutomatedDecisionConsentClinician: organisation.automatedDecisionConsentClinician,
            organisationAutomatedDecisionConsentPatient: organisation.automatedDecisionConsentPatient,
            organisationBlockCasesWithoutAutomatedDecisionConsent:
                organisation.blockCasesWithoutAutomatedDecisionConsent || false,
            showAuditConfigurationWarningModal: false,
        };

        this.handleModalClose = this.handleModalClose.bind(this);
        this.showAutomatedConsentChange = this.showAutomatedConsentChange.bind(this);
    }

    public componentDidUpdate(prevProps: IEditDetailsComponentProps) {
        this.setInitialState(prevProps);
    }

    private handleModalClose(): void {
        this.setState((prevState) => ({
            showAuditConfigurationWarningModal: !prevState.showAuditConfigurationWarningModal,
        }));
    }

    private static getLabel(labelText: string) {
        return (
            <div className="field">
                <span>{ReactHtmlParser(labelText)}</span>
            </div>
        );
    }

    private getConsentField({ name, label }: ConsentField) {
        const { [name]: value, mode } = this.state;
        if (mode === ViewEditMode.VIEW) {
            return (
                <div>
                    {Consent.getLabel(label)}
                    <Segment>
                        <div>{ReactHtmlParser(value)}</div>
                    </Segment>
                </div>
            );
        }
        return (
            <div>
                <div className="field">
                    <span>{ReactHtmlParser(label)}</span>
                </div>
                <CustomRichTextEditor value={value} onChange={this.onRichTextChange} name={name} />
            </div>
        );
    }

    private getSubmitButton() {
        const { mode, submitting } = this.state;

        if (mode === ViewEditMode.EDIT) {
            return (
                <Grid.Column width={9}>
                    <Button loading={submitting} floated="right" onClick={this.submit}>
                        Update
                    </Button>
                    <Button loading={submitting} floated="right" onClick={this.cancel}>
                        Cancel
                    </Button>
                </Grid.Column>
            );
        }
        return <></>;
    }

    private onModeChangeClick = () => {
        const { mode } = this.state;
        const isCurrentViewMode = mode === ViewEditMode.VIEW;
        const updatedMode = isCurrentViewMode ? ViewEditMode.EDIT : ViewEditMode.VIEW;
        this.setState({ mode: updatedMode, errorDetails: [] });
    };

    private onRichTextChange = (value: string, name: string) => {
        const emptyHtmlField = "<p><br></p>";
        this.setState({ [name]: value === emptyHtmlField ? "" : value });
    };

    private getErrorMessages() {
        const { errorDetails } = this.state;
        return errorDetails.map((detail: any) => (
            <Grid.Row key={detail.message}>
                <Grid.Column>
                    <Message key={detail.message} negative>
                        <p>{detail.message}</p>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        ));
    }

    private cancel = () => {
        const { organisation } = this.props;

        this.setState({
            organisationAssessment: organisation.assessment,
            organisationResearch: organisation.research,
            organisationAssessmentPatient: organisation.assessmentPatient,
            organisationResearchPatient: organisation.researchPatient,
            organisationPrimaryConsent: organisation.primaryConsent,
            organisationSecondaryConsent: organisation.secondaryConsent,
            organisationAssessmentNsc: organisation.assessmentNsc,
            organisationResearchNsc: organisation.researchNsc,
            organisationShowAutomatedDecisionConsent: organisation.showAutomatedDecisionConsent || false,
            organisationAutomatedDecisionConsentClinician: organisation.automatedDecisionConsentClinician,
            organisationAutomatedDecisionConsentPatient: organisation.automatedDecisionConsentPatient,
            organisationBlockCasesWithoutAutomatedDecisionConsent:
                organisation.blockCasesWithoutAutomatedDecisionConsent || false,
        });
        this.onModeChangeClick();
    };

    private submit = () => {
        this.setState({ submitting: true });
        const { updateOrganisation, organisation } = this.props;
        const {
            organisationAssessment,
            organisationResearch,
            organisationAssessmentPatient,
            organisationResearchPatient,
            organisationPrimaryConsent,
            organisationSecondaryConsent,
            organisationAssessmentNsc,
            organisationResearchNsc,
            organisationShowAutomatedDecisionConsent,
            organisationAutomatedDecisionConsentClinician,
            organisationAutomatedDecisionConsentPatient,
            organisationBlockCasesWithoutAutomatedDecisionConsent,
            organisationEmail,
        } = this.state;

        if (
            organisationShowAutomatedDecisionConsent &&
            (!organisationAutomatedDecisionConsentClinician || !organisationAutomatedDecisionConsentPatient)
        ) {
            const isClinicianAndPatientTextMissing =
                !organisationAutomatedDecisionConsentClinician && !organisationAutomatedDecisionConsentPatient;
            const missingConsentTextFor = !organisationAutomatedDecisionConsentClinician ? "clinicians" : "patient";
            this.setState({
                errorDetails: [
                    {
                        message: `Please provide the automated decision consent text for the ${
                            isClinicianAndPatientTextMissing ? "clinician and the patient" : missingConsentTextFor
                        }. Consent text cannot be missing for any users of this org when show consent is switched on.`,
                    },
                ],
                submitting: false,
            });
            return;
        }

        if (validOrganisationEmail(organisationEmail || "")) {
            organizationService
                .updateOrganisationConsent(organisation.uuid, {
                    assessment: organisationAssessment,
                    research: organisationResearch,
                    assessmentPatient: organisationAssessmentPatient,
                    researchPatient: organisationResearchPatient,
                    primaryConsent: organisationPrimaryConsent,
                    secondaryConsent: organisationSecondaryConsent,
                    assessmentNsc: organisationAssessmentNsc,
                    researchNsc: organisationResearchNsc,
                    showAutomatedDecisionConsent: organisationShowAutomatedDecisionConsent,
                    automatedDecisionConsentClinician: organisationAutomatedDecisionConsentClinician,
                    automatedDecisionConsentPatient: organisationAutomatedDecisionConsentPatient,
                    blockCasesWithoutAutomatedDecisionConsent: organisationBlockCasesWithoutAutomatedDecisionConsent,
                })
                .then((response) => {
                    const validationResult = this.validateResponse(response.organisation);
                    if (validationResult) {
                        this.setState({
                            errorDetails: validationResult,
                            submitting: false,
                        });
                    } else {
                        updateOrganisation(response.organisation);
                        this.setState({
                            errorDetails: [],
                            mode: ViewEditMode.VIEW,
                            submitting: false,
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        errorDetails: err.response?.data.errors,
                        submitting: false,
                    });
                });
        } else {
            this.setState({ submitting: false });
        }
    };

    private validateResponse = (response: any) => {
        const {
            organisationAssessment,
            organisationResearch,
            organisationAssessmentPatient,
            organisationResearchPatient,
            organisationPrimaryConsent,
            organisationSecondaryConsent,
            organisationAssessmentNsc,
            organisationResearchNsc,
            organisationShowAutomatedDecisionConsent,
            organisationAutomatedDecisionConsentClinician,
            organisationAutomatedDecisionConsentPatient,
            organisationBlockCasesWithoutAutomatedDecisionConsent,
            organisation,
        } = this.state;

        const errors: any[] = [];

        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "assessment",
            organisation.assessmentConsent,
            organisationAssessment,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "research",
            organisation.researchConsent,
            organisationResearch,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "assessmentPatient",
            organisation.assessmentConsentPatient,
            organisationAssessmentPatient,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "researchPatient",
            organisation.researchConsentPatient,
            organisationResearchPatient,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "primaryConsent",
            organisation.primaryConsent,
            organisationPrimaryConsent,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "secondaryConsent",
            organisation.secondaryConsent,
            organisationSecondaryConsent,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "assessmentNsc",
            organisation.assessmentConsentNsc,
            organisationAssessmentNsc,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "researchNsc",
            organisation.researchConsentNsc,
            organisationResearchNsc,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "showAutomatedDecisionConsent",
            organisation.showAutomatedDecisionConsent,
            organisationShowAutomatedDecisionConsent,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "automatedDecisionConsentClinician",
            organisation.automatedDecisionConsentClinician,
            organisationAutomatedDecisionConsentClinician,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "automatedDecisionConsentPatient",
            organisation.automatedDecisionConsentPatient,
            organisationAutomatedDecisionConsentPatient,
            response,
            errors
        );
        Consent.checkPropertySubmitConfirmationInBackendResponse(
            "blockCasesWithoutAutomatedDecisionConsent",
            organisation.blockCasesWithoutAutomatedDecisionConsent,
            organisationBlockCasesWithoutAutomatedDecisionConsent,
            response,
            errors
        );

        return errors.length ? errors : false;
    };

    private setInitialState(prevProps: IEditDetailsComponentProps) {
        const { organisation } = this.props;
        const {
            uuid,
            assessment,
            research,
            assessmentPatient,
            researchPatient,
            primaryConsent,
            secondaryConsent,
            assessmentNsc,
            researchNsc,
            showAutomatedDecisionConsent,
            automatedDecisionConsentClinician,
            automatedDecisionConsentPatient,
            blockCasesWithoutAutomatedDecisionConsent,
        } = organisation;

        if (prevProps.organisation.uuid !== uuid) {
            this.setState({
                organisation,
                organisationAssessment: assessment,
                organisationResearch: research,
                organisationAssessmentPatient: assessmentPatient,
                organisationResearchPatient: researchPatient,
                organisationPrimaryConsent: primaryConsent,
                organisationSecondaryConsent: secondaryConsent,
                organisationAssessmentNsc: assessmentNsc,
                organisationResearchNsc: researchNsc,
                organisationShowAutomatedDecisionConsent: showAutomatedDecisionConsent || false,
                organisationAutomatedDecisionConsentClinician: automatedDecisionConsentClinician,
                organisationAutomatedDecisionConsentPatient: automatedDecisionConsentPatient,
                organisationBlockCasesWithoutAutomatedDecisionConsent:
                    blockCasesWithoutAutomatedDecisionConsent || false,
            });
        }
    }

    private showAutomatedConsentChange(name, value) {
        this.setState({
            organisationShowAutomatedDecisionConsent: value.checked,
        });
        if (value.checked === false) {
            this.setState({ organisationBlockCasesWithoutAutomatedDecisionConsent: false });
        }
    }

    private automatedDecisionMakingBlock(consentBlock): ReactNode {
        const {
            mode,
            organisationShowAutomatedDecisionConsent,
            organisationBlockCasesWithoutAutomatedDecisionConsent,
        } = this.state;
        const {
            organisation: { auditConfiguration },
        } = this.props;

        const blockCasesWithoutAutomatedDecisionConsentChange = (name, value) => {
            this.setState({ organisationBlockCasesWithoutAutomatedDecisionConsent: value.checked });
        };

        const showAuditConfigurationWarningModal =
            auditConfiguration?.length > 0 && organisationShowAutomatedDecisionConsent;

        const handleToggle = showAuditConfigurationWarningModal
            ? () => {
                  this.setState({
                      showAuditConfigurationWarningModal,
                  });
              }
            : this.showAutomatedConsentChange;

        return (
            <Grid.Row
                key={consentBlock.blockName}
                className="consentBlock"
                data-testid="automated-decision-making-block"
            >
                <Grid.Column>
                    <Grid.Row className="field">
                        <Grid.Column floated="left" width={6}>
                            <h4>{consentBlock.blockLabel}</h4>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="field">
                        <Grid.Column className="indentedCheckbox">
                            <div>
                                <Form.Checkbox
                                    checked={organisationShowAutomatedDecisionConsent}
                                    toggle
                                    id="showAutomatedConsent"
                                    onChange={handleToggle}
                                    label="Show the consent for automated decision making to all users of this organisation"
                                    disabled={mode === ViewEditMode.VIEW}
                                    data-testid="automated-decision-making-toggle"
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    {!!organisationShowAutomatedDecisionConsent && (
                        <>
                            {consentBlock.block.map((blockField) => (
                                <Grid.Row key={blockField.name} className="field">
                                    <Grid.Column>{this.getConsentField(blockField)}</Grid.Column>
                                </Grid.Row>
                            ))}
                            <Grid.Row className="field">
                                <Grid.Column className="indentedCheckbox">
                                    <div>
                                        <Form.Checkbox
                                            checked={organisationBlockCasesWithoutAutomatedDecisionConsent}
                                            toggle
                                            id="blockCasesWithoutAutomatedDecisionConsent"
                                            onChange={blockCasesWithoutAutomatedDecisionConsentChange}
                                            label="Block case progression (switching this on will block patients from using Skin Analytics service if they have not consented to automated decision making)"
                                            disabled={mode === ViewEditMode.VIEW}
                                            data-testid="block-unconsented-cases-toggle"
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                </Grid.Column>
            </Grid.Row>
        );
    }

    private mapConsentFields(fields: ConsentField[]): ReactNode[] {
        return fields.map((field) => {
            if (field.blockName === "automatedDecisionMaking") {
                return this.automatedDecisionMakingBlock(field);
            }
            return (
                <Grid.Row key={field.name}>
                    <Grid.Column>{this.getConsentField(field)}</Grid.Column>
                </Grid.Row>
            );
        });
    }

    public render() {
        const { mode, showAuditConfigurationWarningModal } = this.state;
        const isEditMode = mode === ViewEditMode.VIEW;

        return (
            <Segment>
                <DisableAuditConfigurationModal
                    showModal={showAuditConfigurationWarningModal}
                    handleClose={this.handleModalClose}
                    handleContinue={this.showAutomatedConsentChange}
                />
                <Form>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column floated="left" width={6}>
                                <h3>Consent</h3>
                            </Grid.Column>
                            <Grid.Column floated="right" width={4}>
                                <Button floated="right" onClick={isEditMode ? this.onModeChangeClick : this.cancel}>
                                    {isEditMode ? "Edit" : "Cancel"}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        {this.mapConsentFields(consentFields)}
                        {this.getErrorMessages()}
                        {this.getSubmitButton()}
                    </Grid>
                </Form>
            </Segment>
        );
    }
}

export default Consent;
