import { DermErrorResponse } from "model/errors";
import { IFormError } from "model/formError";

export function isErrorOfTypePasswordOnDenyList(errors: IFormError[]): boolean {
    return errors.some((err) => {
        if (err.details) {
            return err.details[0].errorName === "passwordOnDenyList";
        }
        return false;
    });
}

const parseDermError = (dermErrorText: string) => {
    try {
        if (dermErrorText) {
            let reason = JSON.parse(dermErrorText.split("Reason:")[1]);
            reason = JSON.parse(reason)
                .errorDetails.map((error: any) => error.errorDescription)
                .join(" ");
            return reason;
        }
        return "DERM AI was unable to assess the image";
    } catch (err) {
        return DermErrorResponse.SOMETHING_WENT_WRONG;
    }
};

export default parseDermError;
