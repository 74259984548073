import { ICustomErrorMessage } from "components/ErrorMessage/CustomErrorMessage";

export const OTP_LENGTH = 6;
export const OTP_SINGLE_INPUT_REGEXP = new RegExp(/^\d$/);
export const OTP_VALIDATION_REGEXP = new RegExp(`^\\d{${OTP_LENGTH}}$`);

export enum MFASetupStepTitle {
    EMAIL_VERIFICATION = "Let's start by verifying your email",
}

export enum MFAButtons {
    START_SETUP = "Start set up",
    CONTINUE = "Continue",
    BACK = "< Back",
    SKIP = "Skip",
    MODAL_OK = "Ok",
}

export enum MFAKeys {
    EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
    SMS_VERIFICATION = "SMS_VERIFICATION",
}

export enum MFAVerificationChannel {
    EMAIL = "email",
    SMS = "sms",
}

export interface IOTPConfig {
    instructions: string;
    title: string;
    channel: MFAVerificationChannel;
}

export type OTPConfigType = Record<string, IOTPConfig>;

export const OTPConfig: OTPConfigType = {
    EMAIL_VERIFICATION: {
        title: "Email Verification",
        instructions: `Check your inbox and enter the ${OTP_LENGTH}-digit code we sent to:`,
        channel: MFAVerificationChannel.EMAIL,
    },
    SMS_VERIFICATION: {
        title: "Phone Verification",
        instructions: `Check your inbox and enter the ${OTP_LENGTH}-digit code we sent to:`,
        channel: MFAVerificationChannel.SMS,
    },
};

export enum MFASetupStatus {
    VERIFIED = "VERIFIED",
    NOT_VERIFIED = "NOT_VERIFIED",
    RE_VERIFICATION_REQUIRED = "RE_VERIFICATION_REQUIRED",
}

export enum VerificationStatus {
    UNINITIATED = "uninitiated",
    PENDING = "pending",
    APPROVED = "approved",
    CANCELLED = "canceled", // US English spelling as per Twilio response
}

export enum MFASetupStep {
    EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
    SMS_VERIFICATION = "SMS_VERIFICATION",
}

export interface IMFAVerification {
    status: VerificationStatus;
    verificationDate: Date;
}

export interface IMFASetupState {
    showMfaSetup: boolean;
    hasSeenMFAScreen: boolean;
    gracePeriodCountdownInDays: number;
    email: IMFAVerification;
    sms: IMFAVerification;
}

export const otpValidationError: ICustomErrorMessage = {
    message: "The code you entered is not valid",
    cta: "Double check and make sure to enter it exactly as it appears. If the problem persists, you can try to resend a new one.",
};

export const otpGenericError: ICustomErrorMessage = {
    message: "Something went wrong",
};
