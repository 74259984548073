import React, { FC } from "react";
import { Image } from "semantic-ui-react";
import clsx from "clsx";

import { REMOTE_TIMELINE_ROW } from "model/remoteCaseTimeline";

import "scss/RemoteModel.scss";

interface ICaseStatusPanelItem {
    activeStatusIndex: number;
    visibleStatusesMobile: number[];
    index: number;
    imgSrc: string;
    text: string;
}

const RemoteCaseStatusTimelineItem: FC<ICaseStatusPanelItem> = ({
    activeStatusIndex,
    visibleStatusesMobile,
    index,
    imgSrc,
    text,
}) => {
    const isActive = activeStatusIndex === index;
    const isPassed = activeStatusIndex > index;
    const isNotVisibleMobile = !visibleStatusesMobile.includes(index);
    const wrapperClassName = clsx(
        "status",
        isActive && "remote-modal-case-status-active",
        isPassed && "passed",
        isNotVisibleMobile && "mobile-hidden"
    );

    return (
        <div className={wrapperClassName}>
            {index !== 0 && <div className="remote-modal-case-status-line" />}

            <div className="step">
                <p>{text}</p>

                <div className="remote-modal-case-status-circle">
                    <Image alt="Circular case status icon" src={imgSrc} />
                </div>
            </div>

            {index !== REMOTE_TIMELINE_ROW.length - 1 && <div className="remote-modal-case-status-line" />}
        </div>
    );
};

export default RemoteCaseStatusTimelineItem;
