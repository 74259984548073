import React, { Component, ReactNode } from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { Button, Container, Divider, Grid, Header, Segment } from "semantic-ui-react";

import SafetyNetReview from "components/ImmediateResult/SafetyNetReview";
import ReferImmediateResult from "components/ImmediateResult/ReferImmediateResult";
import CasePdfReport from "components/CaseDescription/CasePdfReport";
import ImmediateResultLesionsAssesment from "components/ImmediateResult/Lesions/ImmediateResultLesionsAssessment";
import StickyPatientInformation from "components/templates/StickyPatientInformation";
import LoadingSpinner from "components/templates/LoadingSpinner";

import { getOrganisationPdfReportReceivers } from "helpers/organisation";
import { getFieldFromTemplate, getTemplate } from "helpers/template";
import { getCaseAllocation } from "helpers/assessment";

import { AnalysisStatus, ICase, ReportStatus } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { AllocationValue, IOrganisation } from "model/organisation";
import { TemplateType } from "model/templateType";
import { IUser } from "model/user";

import * as caseService from "services/caseService";

import "scss/CaseDetails.scss";
import "scss/CaseResults.scss";
import "scss/Container.scss";

const INTERVAL_TIMEOUT_MS = 3000;

interface IImmediateResultProps extends RouteComponentProps<{ id: string }> {
    pendingRequest: boolean;
    getReport: (uuid: string, caseId?: string, patientId?: string) => void;
    viewReport: (uuid: string, openInnewPage?: boolean) => void;
    user: IUser;
    organisation: IOrganisation;
}
interface IImmediateResultState {
    currentCase: ICase | undefined;
    currentCaseUuid: string;
    userOrganisation: IOrganisation;
    intervalId: number;
}

class ImmediateResult extends Component<IImmediateResultProps, IImmediateResultState> {
    public constructor(props: any) {
        super(props);
        const { match, organisation } = this.props;
        const currentCaseUuid = match.params.id;
        this.state = {
            currentCase: undefined,
            currentCaseUuid,
            intervalId: 0,
            userOrganisation: organisation,
        };
    }

    public componentDidMount(): void {
        const { currentCaseUuid } = this.state;
        caseService.getCaseAsync(currentCaseUuid).then((response: ICase) => {
            this.setState({ currentCase: response });
            if (!response.report && !this.isCaseInReview()) {
                this.setIntervalMonitorForReport();
            }
        });
    }

    public componentWillUnmount(): void {
        const { intervalId } = this.state;
        clearInterval(intervalId);
    }

    private setIntervalMonitorForReport = (): void => {
        const { currentCase } = this.state;
        const intervalId = window.setInterval(() => {
            caseService.getCaseAsync(currentCase.uuid).then((res: ICase) => {
                if (res) {
                    window.clearInterval(intervalId);
                    this.setState({ currentCase: res });
                }
            });
        }, INTERVAL_TIMEOUT_MS);
    };

    private isCaseInReview = (): boolean => {
        const { currentCase } = this.state;

        return [
            CaseStatus.CASE_IN_SA_REVIEW,
            CaseStatus.CASE_IN_REVIEW,
            CaseStatus.CASE_IN_CLIENT_REVIEW,
            CaseStatus.CASE_FOR_REVIEW,
            CaseStatus.CASE_FOR_SA_REVIEW,
        ].includes(currentCase.caseStatus);
    };

    private getImmediateResultInfoText = (): string => {
        const { organisation } = this.props;
        const { currentCase } = this.state;

        return getFieldFromTemplate(
            "case_completed_text",
            currentCase,
            organisation,
            getTemplate(TemplateType.IMMEDIATE_RESULT_TEXT, organisation)
        );
    };

    private isExcluded = (): boolean => {
        const { currentCase } = this.state;
        return currentCase.lesions.some((lesion) => lesion.excluded);
    };

    private isException = (): boolean => {
        const { currentCase } = this.state;
        return currentCase.lesions.some((lesion) => {
            const { result } = lesion;
            return !result || !!result.error || result.analysisStatus === AnalysisStatus.STATUS_ERROR;
        });
    };

    private isCaseInProgress = (): boolean => {
        const { currentCase } = this.state;
        return currentCase.caseStatus === CaseStatus.CASE_IN_PROGRESS;
    };

    private useUnconsentedAllocationConfig = (): boolean => {
        const { organisation } = this.props;
        const { showAutomatedDecisionConsent } = organisation;

        const { currentCase } = this.state;
        const { automatedDecisionConsentDate } = currentCase;

        return showAutomatedDecisionConsent && !automatedDecisionConsentDate;
    };

    private getReportSection = (): ReactNode => {
        const { getReport, viewReport, pendingRequest } = this.props;
        const { currentCase, userOrganisation } = this.state;

        const isReport = currentCase?.report && currentCase.report.status === ReportStatus.GENERATED;

        const pdfReportReceivers = getOrganisationPdfReportReceivers(userOrganisation);

        return (
            (isReport && !this.isCaseInReview() && (
                <>
                    <p>For full details of the assessment, please see the assessment report.</p>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                <CasePdfReport
                                    getReport={getReport}
                                    viewReport={viewReport}
                                    pendingRequest={pendingRequest}
                                    caseObject={currentCase}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <p className="top-padding">
                        {pdfReportReceivers && `We have also sent a download link by email to ${pdfReportReceivers}`}
                    </p>
                </>
            )) ||
            null
        );
    };

    public render() {
        const { currentCase } = this.state;
        if (!currentCase) {
            return <LoadingSpinner />;
        }

        const { organisation } = this.props;
        const useUnconsentedAllocationConfig: boolean = this.useUnconsentedAllocationConfig();

        const { guidanceValue, allocation } = getCaseAllocation(
            currentCase.lesions,
            organisation,
            useUnconsentedAllocationConfig
        );

        const isExcluded: boolean = this.isExcluded();
        const isException: boolean = this.isException();
        const isCaseInReview: boolean = this.isCaseInReview();
        const isCaseInProgress: boolean = this.isCaseInProgress();

        const showClientReview: boolean = isCaseInReview && allocation === AllocationValue.CLIENT;
        const showSafetyNetReview: boolean = isCaseInReview && allocation === AllocationValue.SA;
        const showCompleted: boolean = !showSafetyNetReview && !showClientReview && !isCaseInProgress;

        return (
            <Container>
                <Segment>
                    <StickyPatientInformation caseId={currentCase.caseId} patient={currentCase.patient} />

                    <div className="section-separate">
                        {isCaseInProgress ? (
                            <LoadingSpinner />
                        ) : (
                            <>
                                <Header as="h1" className="without-bottom-margin">
                                    Skin lesion assessment results
                                </Header>
                                <Divider className="title-divider" />
                                <p>Thank you for requesting this skin assessment by Skin Analytics.</p>

                                <Divider className="title-divider" />

                                {showSafetyNetReview && (
                                    <SafetyNetReview
                                        isExcluded={isExcluded}
                                        isException={isException}
                                        currentCase={currentCase}
                                        organisation={organisation}
                                        guidanceValue={guidanceValue}
                                    />
                                )}

                                {showClientReview && (
                                    <ReferImmediateResult
                                        currentCase={currentCase}
                                        organisation={organisation}
                                        guidanceValue={guidanceValue}
                                    />
                                )}

                                {showCompleted && (
                                    <>
                                        <p>{this.getImmediateResultInfoText()}</p>
                                        <ImmediateResultLesionsAssesment
                                            caseObject={currentCase}
                                            guidanceValue={guidanceValue}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <Divider />
                    {this.getReportSection()}
                    <br />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={15}>
                                <Button
                                    as={Link}
                                    to="/home"
                                    color="teal"
                                    floated="right"
                                    className="ui button filled-button small"
                                >
                                    Done
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        );
    }
}

export default withRouter(ImmediateResult);
