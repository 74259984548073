import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";

import Questionary from "components/Questionary";
import AssessmentFlowConfirmModal from "components/templates/AssessmentFlowConfirmModal";
import AssessmentFlowHeader from "components/templates/AssessmentFlowHeader";
import PatientBanner from "components/templates/PatientBanner";

import { doesOrganisationHaveLocations, scrollToError } from "helpers/assessment";
import { generateAnswers, mapStateToAnswers } from "helpers/questionary";

import { IAssessment, IMedicalHistory, IMedicalHistoryAnswers } from "model/assessment";
import { IOrganisation } from "model/organisation";
import { IQuestionaryError } from "model/questionaryError";
import IQuestionSet from "model/questionSet";

import { CLINIC_LOCATION, HOME, LESION_LOCATOR, MEDICAL_HISTORY } from "navigation/routes";

import logoutTimeoutService from "services/logoutTimeoutService";
import { mapFitzpatrickQuestionAnswer } from "helpers/fitzpatrick";
import QuestionSubTypeEnum from "model/questionSubType";

interface IFitzpatrickHistoryQuestion {
    assessment: IAssessment;
    currentLesion: number;
    organisation: IOrganisation;
    pendingRequest: boolean;
    createMedicalHistory: (medicalHistoryAnswers?: IMedicalHistoryAnswers[]) => Promise<void>;
    getCase?: (caseUuid: string) => void;
}

interface IFitzpatrickHistoryState {
    isBack: boolean;
    saveAssessment: boolean;
    medicalHistoryAnswers: { [uuid: string]: any };
    next: boolean;
    [x: number]: any;
    errors: IQuestionaryError;
    showModal: boolean;
    isEditMode: boolean;
    isEdited: boolean;
    hasFitzpatrickQuestion: boolean;
    getCase?: (caseUuid: string) => void;
    resetSelectedQuestion: boolean;
}

class FitzpatrickHistoryQuestion extends Component<IFitzpatrickHistoryQuestion, IFitzpatrickHistoryState> {
    constructor(props: any) {
        super(props);

        const { assessment, organisation } = this.props;

        const fitzpatrickQuestion = mapFitzpatrickQuestionAnswer({
            medicalHistoryAnswers: assessment?.patient?.medicalHistory,
            medicalHistoryQuestions: organisation?.medicalHistoryQuestions,
        }).question;

        this.state = {
            errors: {
                formError: false,
                isInitial: true,
                list: [],
                showError: false,
            },
            isBack: Boolean(
                assessment?.patient?.medicalHistory?.filter(
                    (question: IMedicalHistory) => question?.uuid === fitzpatrickQuestion?.uuid
                ).length > 0
            ),
            isEditMode: false,
            isEdited: false,
            medicalHistoryAnswers: {},
            next: false,
            saveAssessment: false,
            showModal: false,
            hasFitzpatrickQuestion: !!fitzpatrickQuestion,
            resetSelectedQuestion: false,
        };
    }

    public componentDidMount(): void {
        logoutTimeoutService.stopCount();
        this.initializeAnswers();
    }

    public componentDidUpdate(prevProps: IFitzpatrickHistoryQuestion, prevState: IFitzpatrickHistoryState): void {
        const { errors } = this.state;

        if (prevState.errors !== errors && errors.list.length && errors.showError) {
            scrollToError({ errorUuid: errors.list[0] });
        }
    }

    public updateAnswers = (answers: any, isEdited?: boolean): void => {
        this.setState((prevState) => ({
            ...prevState,
            medicalHistoryAnswers: answers,
            isEdited: isEdited === undefined ? true : isEdited,
        }));
    };

    public updateErrors = (error: IQuestionaryError): void => {
        this.setState({ errors: error });
    };

    public submit = async ({
        mainData,
    }: {
        mainData: IMedicalHistoryAnswers[];
        extraData: IMedicalHistoryAnswers[];
    }): Promise<void> => {
        const { errors, isBack, isEditMode, isEdited } = this.state;
        const { pendingRequest, createMedicalHistory } = this.props;
        const isValid = !errors.list.length && !errors.isInitial;
        this.setState({ errors: { ...errors, showError: true } });
        if (!isBack && !pendingRequest && isValid && !isEditMode) {
            await createMedicalHistory(mainData);
            this.moveForward();
        }
        if (isEditMode && isEdited) {
            this.setState({
                showModal: true,
            });
        }
        if (!isEdited) {
            this.moveForward();
        }
    };

    public saveCase = async ({ mainData }: { mainData: any }): Promise<void> => {
        const { errors, isBack, isEditMode, isEdited } = this.state;
        const { pendingRequest, createMedicalHistory } = this.props;
        const isValid = !errors.list.length && !errors.isInitial;
        if (!isBack && !pendingRequest && isValid) {
            await createMedicalHistory(mainData);
            this.setState({
                saveAssessment: true,
            });
        }
        if (isEditMode && isEdited) {
            this.setState({ showModal: true });
        }
    };

    private editMedicalHistory = async (): Promise<void> => {
        const { isEditMode, errors, showModal, isEdited } = this.state;
        const { createMedicalHistory, pendingRequest } = this.props;
        if (isEditMode) {
            const isValid = !errors.list.length && !errors.isInitial;
            this.setState({ errors: { ...errors, showError: true } });
            if (!pendingRequest && isValid) {
                const { medicalHistoryAnswers } = this.state;
                const { mainData } = { ...mapStateToAnswers(medicalHistoryAnswers) };
                if (isEdited) {
                    await createMedicalHistory(mainData);
                }
                this.setState({ isEditMode: false, isEdited: false });
                if (showModal) {
                    this.moveForward();
                }
            }
        } else {
            this.setState({ isEditMode: true });
        }
    };

    private closeModal = (): void => {
        this.setState({ showModal: false });
    };

    private moveForward = (): void => {
        this.setState({
            next: true,
        });
    };

    private resetEdit = (): void => {
        const { assessment, getCase } = this.props;
        const { resetSelectedQuestion } = this.state;
        getCase(assessment.case?.uuid);
        this.setState({ isEditMode: false, isEdited: false, resetSelectedQuestion: !resetSelectedQuestion });
    };

    private initializeAnswers = (): void => {
        const { organisation, assessment } = this.props;
        const { medicalHistory } = assessment.patient;
        const { medicalHistoryQuestions } = organisation;
        const isNonSkinCancer = assessment.case.nonSkinCancer;
        const questionSubTypesToFilter = [QuestionSubTypeEnum.SKIN_TYPE];
        const fitzpatrickMedicalHistoryQuestion = medicalHistoryQuestions?.filter((question) =>
            questionSubTypesToFilter?.includes(question?.questionSubType)
        );
        if (medicalHistory) {
            const questions = [...((!isNonSkinCancer && fitzpatrickMedicalHistoryQuestion) || [])].sort(
                (a, b) => a.questionOrder - b.questionOrder
            );
            const answers = generateAnswers(medicalHistory, questions);
            this.setState({
                errors: {
                    formError: false,
                    isInitial: false,
                    list: [],
                    showError: true,
                },
                isEdited: false,
                medicalHistoryAnswers: answers,
            });
        }
    };

    public render() {
        const { assessment, organisation } = this.props;
        const { showModal, saveAssessment, next, hasFitzpatrickQuestion } = this.state;
        const {
            patient: { medicalHistory },
        } = assessment;

        if (saveAssessment && medicalHistory) {
            return <Redirect to={HOME} />;
        }

        const { case: currentCase, nonSkinCancer: isNonSkinCancerFlow, patient } = assessment;
        const { lesions, caseId } = currentCase;
        const organisationHasLocations = doesOrganisationHaveLocations(organisation.locations);
        const lesionLocatorUrl = `${LESION_LOCATOR}${lesions?.length ? "/1" : ""}`;

        if (medicalHistory && next) {
            return <Redirect to={organisationHasLocations ? CLINIC_LOCATION : lesionLocatorUrl} />;
        }

        const assessmentHistoryQuestions = isNonSkinCancerFlow
            ? organisation.nscMedicalHistoryQuestions
            : organisation.medicalHistoryQuestions;

        const fitzpatrickQuestion = [
            mapFitzpatrickQuestionAnswer({
                medicalHistoryAnswers: assessment?.patient?.medicalHistory,
                medicalHistoryQuestions: assessmentHistoryQuestions,
            }).question,
        ];

        const fitzpatrickQuestionSet: IQuestionSet[] = [{ questions: fitzpatrickQuestion }];

        const { pendingRequest } = this.props;
        const { errors, isBack, medicalHistoryAnswers, isEditMode, resetSelectedQuestion } = this.state;

        const nextButtons = [
            ...(isBack
                ? []
                : [
                      {
                          action: this.saveCase,
                          isLoading: pendingRequest,
                          isDisabled: pendingRequest,
                          text: "Save and Close",
                          type: "submit" as const,
                      },
                  ]),
            {
                action: this.submit,
                isLoading: pendingRequest,
                isDisabled: pendingRequest,
                text: isBack ? "Next >" : "Save and Continue",
                type: "submit" as const,
            },
        ];

        return (
            <Container className="ui segment wizzard-container">
                <PatientBanner caseId={caseId} patient={patient} />
                <AssessmentFlowHeader
                    title="Response to Sun Exposure"
                    isEditMode={isEditMode}
                    disabled={!medicalHistory}
                    edit={this.editMedicalHistory}
                    reset={this.resetEdit}
                />
                <Divider />
                <Questionary
                    questionSets={fitzpatrickQuestionSet}
                    errors={errors}
                    isBack={isBack && !isEditMode}
                    updateAnswers={this.updateAnswers}
                    answers={medicalHistoryAnswers}
                    updateErrors={this.updateErrors}
                    backButton={{
                        isDisabled: isEditMode,
                        link: MEDICAL_HISTORY,
                        text: "< Back",
                    }}
                    nextButtons={nextButtons}
                    isFitzpatrick={hasFitzpatrickQuestion}
                    resetAnswer={resetSelectedQuestion}
                />
                <AssessmentFlowConfirmModal
                    show={showModal}
                    close={this.closeModal}
                    goAhead={this.moveForward}
                    save={this.editMedicalHistory}
                />
            </Container>
        );
    }
}

export default FitzpatrickHistoryQuestion;
