const lesionImageTypeRegExp = new RegExp(/\.(png|jpg|jpeg)$/);

/**
 * RFC 2046 and RFC-7231 state that unknown image types should be treated as
 * application/octet- stream
 * https://www.rfc-editor.org/rfc/rfc2046.txt
 * https://www.rfc-editor.org/rfc/rfc7231
 */
export enum LesionImageMimeType {
    png = "image/png",
    jpg = "image/jpg",
    jpeg = "image/jpeg",
    unknown = "application/octet-stream",
}

export type MimeType =
    | LesionImageMimeType.png
    | LesionImageMimeType.jpg
    | LesionImageMimeType.jpeg
    | LesionImageMimeType.unknown;

export function getMimeTypeFromExtension(key: string): MimeType {
    const foundImageType = LesionImageMimeType[key];
    return foundImageType || LesionImageMimeType.unknown;
}

export const urlToFile = (url: string, filename: string, mimeType: string) =>
    fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }));

/**
 * Returns the mime type for the lesion image. As of December 2023, this should be png, jpg or jpeg
 * @param fileName
 * @returns MimeType
 */
export const getLesionImageMimeType = (fileName: string): MimeType => {
    if (!fileName || fileName === "" || fileName === null) {
        throw new Error("Unable to mimetype from provided file");
    }
    if (!fileName.match(lesionImageTypeRegExp)) {
        return LesionImageMimeType.unknown;
    }
    const regexpResults = [...fileName.match(lesionImageTypeRegExp)];
    return getMimeTypeFromExtension(regexpResults[1]);
};
