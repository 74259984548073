import React, { FC } from "react";
import { Grid, Segment } from "semantic-ui-react";

import { formatTimeWithHours } from "helpers/datetime";

import { ILesion } from "model/case";

interface ILesionReviewerAssessmentProps {
    lesion: ILesion;
}

const LesionReviewerAssessment: FC<ILesionReviewerAssessmentProps> = ({ lesion }) => {
    const reviewsCopy = lesion.reviews ? [...lesion.reviews] : [];
    const showAssessment = Boolean(reviewsCopy?.length);

    return (
        <>
            {showAssessment &&
                reviewsCopy
                    .reverse()
                    .filter((review) => review.creatorOrganisationName)
                    .map((review) => {
                        const {
                            reviewDiagnosis,
                            reviewManagement,
                            diagnosisAdditionalText,
                            managementAdditionalText,
                            uuid,
                            createdByName,
                            creatorOrganisationName,
                            creationDate,
                            escalationPlan,
                            escalationPlanAdditionalText,
                            callbackReview,
                        } = review;
                        const formattedDateTime = formatTimeWithHours(creationDate);
                        const reviewCreateDetails = `Assessed by ${createdByName} from ${creatorOrganisationName} on ${formattedDateTime}${
                            callbackReview ? " (Callback)" : ""
                        }`;
                        const isNonSkinCancer = !!escalationPlan;
                        const managementText = isNonSkinCancer ? "Plan" : "Outcome";

                        return (
                            <div key={uuid}>
                                <Grid className="case-status">
                                    <p className="subtitle-gray-text assessed-by-text">{reviewCreateDetails}</p>
                                    <Grid.Row>
                                        <Grid.Column width={4}>
                                            <p>
                                                Suspected <br />
                                                Diagnosis
                                            </p>
                                            <Segment>{`${reviewDiagnosis}${
                                                diagnosisAdditionalText ? ` (${diagnosisAdditionalText})` : ""
                                            }`}</Segment>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <p>
                                                Management <br />
                                                {managementText}&nbsp;
                                            </p>
                                            <Segment>{`${reviewManagement}${
                                                managementAdditionalText ? ` (${managementAdditionalText})` : ""
                                            }`}</Segment>
                                        </Grid.Column>
                                        {escalationPlan && (
                                            <Grid.Column width={4}>
                                                <p>
                                                    Escalation <br />
                                                    Plan&nbsp;
                                                </p>
                                                <Segment>{`${escalationPlan}${
                                                    escalationPlanAdditionalText
                                                        ? ` (${escalationPlanAdditionalText})`
                                                        : ""
                                                }`}</Segment>
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </div>
                        );
                    })}{" "}
        </>
    );
};

export default LesionReviewerAssessment;
