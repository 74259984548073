const INITIAL_STATE = {};

const navigatorReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default navigatorReducer;
