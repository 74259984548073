import { FC, KeyboardEvent } from "react";
import { Icon, Label } from "semantic-ui-react";
import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

import { getDatetimeOverdueOrSinceUpdate, singledDays, singledHours, singledMinutes } from "helpers/datetime";

import CustomTooltip from "components/templates/CustomTooltip";

import "scss/Tippy.scss";

interface ICaseListActionLabel {
    icon?: SemanticICONS;
    text: string;
    color?: SemanticCOLORS;
    basic?: boolean;
    onClick?: () => void;
    onKeyDown?: (e: KeyboardEvent) => void;
    overdue?: boolean;
    overdueCreationDate?: string;
}

const CaseListLabel: FC<ICaseListActionLabel> = ({
    basic,
    icon,
    text,
    color,
    overdue,
    overdueCreationDate,
    onClick,
    onKeyDown,
    ...rest
}) => {
    const forAction = !!icon;

    const tippyContent = () => {
        const inOverdueTime = getDatetimeOverdueOrSinceUpdate(overdueCreationDate);

        const showDiffFromNow = (time: any) => (
            <b>
                {singledDays(time)}
                {singledHours(time)}
                {singledMinutes(time)}
            </b>
        );

        return (
            <div className="tippy-text">
                {overdueCreationDate ? (
                    <div>
                        Overdue for:
                        <br />
                        <b>{showDiffFromNow(inOverdueTime)}</b>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <CustomTooltip title={overdue ? tippyContent() : ""}>
            <Label
                aria-label={text}
                className={`case-list-label ${forAction ? "case-list-action-label" : ""} ${
                    overdue ? "case-list-label-overdue" : ""
                }`}
                color={color}
                basic={basic}
                onClick={onClick}
                onKeyDown={onKeyDown}
                tabIndex={0}
                role="button"
                {...rest}
            >
                {forAction ? <Icon name={icon} /> : null}
                {text}
            </Label>
        </CustomTooltip>
    );
};

CaseListLabel.defaultProps = {
    icon: null,
    color: null,
    basic: false,
    onClick: () => undefined,
    onKeyDown: () => undefined,
    overdue: false,
    overdueCreationDate: "",
};

export default CaseListLabel;
