import {
    AuditConfigurationAction,
    AuditConfigurationActionTypes,
} from "contextProviders/modules/auditConfiguration/actions";

import { IAuditConfigurationState } from "model/auditConfig";

type ReducerActions = AuditConfigurationAction;

export default (state: IAuditConfigurationState, action: ReducerActions): IAuditConfigurationState => {
    switch (action.type) {
        /**
         *
         */
        case AuditConfigurationActionTypes.SET_LOADING: {
            return {
                ...state,
                loading: action.payload.loading,
            };
        }
        case AuditConfigurationActionTypes.SET_LOADED_ORGANISATION: {
            return {
                ...state,
                ...action.payload,
            };
        }
        /**
         * Toggle audit routing at the organisation level
         */
        case AuditConfigurationActionTypes.TOGGLE_AUDIT_CONFIGURATION: {
            const { showAutomatedDecisionConsent } = action.payload;
            const enableAuditConfiguration = showAutomatedDecisionConsent
                ? !state.enableAuditConfiguration
                : state.enableAuditConfiguration;
            const updatedAuditConfiguration = state?.configuration?.map((auditConfig) => ({
                ...auditConfig,
                removed: !enableAuditConfiguration, // if audit routing is allowed, enable audit configurations
                isChanged: true,
            }));
            return {
                ...state,
                configuration: [...updatedAuditConfiguration],
                enableAuditConfiguration,
                isChanged: showAutomatedDecisionConsent,
                showAutomatedDecisionConsentModal: !showAutomatedDecisionConsent,
            };
        }
        case AuditConfigurationActionTypes.DISABLE_AUDIT_ROUTING_AND_CONFIGURATIONS: {
            const disabledAuditConfiguration = state?.configuration?.map((auditConfig) => ({
                ...auditConfig,
                removed: true,
                isChanged: true,
            }));
            return {
                ...state,
                enableAuditConfiguration: false,
                showDisableAuditRoutingModal: false,
                isChanged: true,
                configuration: [...disabledAuditConfiguration],
            };
        }
        /**
         * Individual audit configuration
         */
        case AuditConfigurationActionTypes.TOGGLE_INDIVIDUAL_AUDIT: {
            const auditConfigurationIndex = state?.configuration?.findIndex(
                (config) => config.uuid === action.payload.uuid
            );
            const newAuditConfiguration = [...state?.configuration];
            newAuditConfiguration[auditConfigurationIndex].removed =
                !newAuditConfiguration[auditConfigurationIndex].removed;
            newAuditConfiguration[auditConfigurationIndex].isChanged = true;
            return {
                ...state,
                isChanged: true,
                configuration: [...newAuditConfiguration],
            };
        }
        case AuditConfigurationActionTypes.SET_AUDIT_CRITERIA: {
            const auditConfigurationIndex = state?.configuration?.findIndex(
                (config) => config.uuid === action.payload.uuid
            );
            const newAuditConfiguration = [...state?.configuration];
            newAuditConfiguration[auditConfigurationIndex].percentage = action.payload.percentage;
            newAuditConfiguration[auditConfigurationIndex].isChanged = true;
            return {
                ...state,
                isChanged: true,
                configuration: [...newAuditConfiguration],
            };
        }
        case AuditConfigurationActionTypes.SET_AUDIT_ALLOCATION: {
            const auditConfigurationIndex = state?.configuration?.findIndex(
                (config) => config.uuid === action.payload.uuid
            );
            const newAuditConfiguration = [...state?.configuration];
            newAuditConfiguration[auditConfigurationIndex].allocation = action.payload.allocation;
            newAuditConfiguration[auditConfigurationIndex].isChanged = true;

            return {
                ...state,
                isChanged: true,
                configuration: [...newAuditConfiguration],
            };
        }
        case AuditConfigurationActionTypes.CREATE_AUDIT_CONFIGURATION: {
            return {
                ...state,
                isChanged: false,
                isNew: false,
                loading: false,
                configuration: [...action.payload.configuration],
                error: null,
            };
        }
        case AuditConfigurationActionTypes.AUDIT_CONFIG_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }
        /**
         * Modals
         */
        case AuditConfigurationActionTypes.TOGGLE_AUTOMATED_DECISION_WARNING_MODEL: {
            return {
                ...state,
                showAutomatedDecisionConsentModal: !state.showAutomatedDecisionConsentModal,
            };
        }
        case AuditConfigurationActionTypes.TOGGLE_DISABLE_AUDIT_ROUTING_MODAL: {
            return {
                ...state,
                showDisableAuditRoutingModal: !state.showDisableAuditRoutingModal,
            };
        }
        default:
            return state;
    }
};
