import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider, Form, Grid, GridColumn } from "semantic-ui-react";

import AiDiagnosisTable from "components/templates/AiDiagnosisTable";
import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import PatientBanner from "components/templates/PatientBanner";

import { IAssessment, IDiagnosis } from "model/assessment";
import Confidence from "model/confidence";
import { LesionClassification } from "model/lesionClassification";
import Pathway from "model/pathway";

import "scss/InputField.scss";
import "scss/StudyModel.scss";

interface ISummaryDiagnosisProps {
    assessment: IAssessment;
    currentLesion: number;
    diagnosis: IDiagnosis;

    updateDiagnosis: (uuid: string, pathway: Pathway) => void;
}

interface ISummaryDiagnosisState {
    finished?: boolean;
    pathway?: Pathway;
    pathwayChanged: boolean;
}

class SummaryDiagnosis extends Component<ISummaryDiagnosisProps, ISummaryDiagnosisState> {
    constructor(props: any) {
        super(props);

        const { diagnosis } = this.props;

        this.state = {
            finished: false,
            pathway: diagnosis.pathway,
            pathwayChanged: false,
        };
    }

    public changeFieldValue = (e: any, data: any) => {
        this.setState({
            [data.id]: data.value,
            pathwayChanged: true,
        } as ISummaryDiagnosisState);
    };

    public submit = () => {
        const { pathwayChanged, pathway } = this.state;
        const { updateDiagnosis, diagnosis } = this.props;
        if (pathwayChanged) {
            updateDiagnosis(diagnosis.uuid, pathway);
        }
        this.setState({ finished: true });
    };

    public render() {
        const { finished, pathway } = this.state;
        if (finished) {
            return <Redirect to="/study-assessment-summary" />;
        }

        const { assessment, diagnosis, currentLesion } = this.props;

        return (
            <Container className="ui segment wizzard-container">
                <PatientBanner
                    caseId={assessment.case.caseId}
                    patient={assessment.patient}
                    lesion={assessment.lesion}
                    lesionNumber={assessment.currentLesion}
                />

                <h1>Diagnosis Summary</h1>
                <Divider />
                <Grid>
                    <Grid.Row>
                        <GridColumn className="diagnosis-summary-row">
                            <h2>Initial Diagnosis</h2>
                            <h3>Diagnosis</h3>
                            <p>{LesionClassification[diagnosis.diagnosis]}</p>
                            <h3>Confidence</h3>
                            <p>{Confidence[diagnosis.confidence]}</p>
                            <h3>Pathway</h3>
                            <p>{Pathway[diagnosis.pathway]}</p>
                        </GridColumn>
                        <GridColumn className="diagnosis-summary-row">
                            <h2>Artificial Intelligence Diagnosis</h2>
                            <AiDiagnosisTable case={assessment.case} lesionNumber={currentLesion} />
                        </GridColumn>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row>
                        <GridColumn className="diagnosis-summary-row">
                            <h2>Change pathway</h2>
                            <Form.Select
                                className="medium-select-box"
                                type="text"
                                label="New pathway "
                                value={pathway}
                                id="pathway"
                                placeholder={pathway}
                                onChange={this.changeFieldValue}
                                options={Object.keys(Pathway).map((key) => ({
                                    key,
                                    text: Pathway[key],
                                    value: key,
                                }))}
                            />
                        </GridColumn>
                    </Grid.Row>
                </Grid>
                <div className="small-padding-top">
                    <Divider />
                    <ButtonContainer
                        button1={
                            <CustomButton variant="empty" type="link" to="/ai-results" text="< Back" size="small" />
                        }
                        button2={<CustomButton variant="filled" type="submit" action={this.submit} text="Next >" />}
                    />
                </div>
            </Container>
        );
    }
}

export default SummaryDiagnosis;
