import React, { Component } from "react";
import { Form, Table } from "semantic-ui-react";

import ContextIconButton from "components/templates/ContextIconButton";

import { ILocation, IOrganisation } from "model/organisation";
import ViewEditMode from "model/viewEditMode";

import organizationService from "services/organizationService";

interface IHistoryQuestionRowProps {
    onRemove: (event: any, obj: any) => void;
    location: ILocation;
    organisation: IOrganisation;
}

class LocationRow extends Component<IHistoryQuestionRowProps, any> {
    constructor(props: any) {
        super(props);

        const { location } = this.props;

        this.state = {
            location,
            mode: ViewEditMode.VIEW,

            row: {
                description: location.description,
                locationOrder: location.locationOrder,
                name: location.name,
                uuid: location.uuid,
            },
        };
    }

    private getLocationName() {
        const { row } = this.state;

        return row.name;
    }

    private getLocationOrder() {
        const { row } = this.state;

        return row.locationOrder;
    }

    private getLocationDescription() {
        const { row } = this.state;

        return row.description;
    }

    private onRemoveRow = (event: any, obj: any) => {
        const { onRemove } = this.props;
        const { row } = this.state;

        organizationService.deleteLocation(row.uuid).then(() => {
            onRemove(event, obj);
        });
    };

    private onEditRow = () => {
        this.setState({ mode: ViewEditMode.EDIT });
    };

    private onCancel = () => {
        this.setState({ mode: ViewEditMode.VIEW });
    };

    public render() {
        const { organisation, location } = this.props;
        const { location: locationState, mode, row } = this.state;

        const canEditLocation = !location.organisationUuid || location.organisationUuid === organisation.uuid;

        return (
            <Table.Row key={locationState.uuid}>
                <Table.Cell width={3}>
                    <Form>{this.getLocationName()}</Form>
                </Table.Cell>
                <Table.Cell width={2}>
                    <Form>{this.getLocationOrder()}</Form>
                </Table.Cell>
                <Table.Cell width={2}>
                    <Form>{this.getLocationDescription()}</Form>
                </Table.Cell>

                <Table.Cell width={1}>
                    {mode === ViewEditMode.EDIT && (
                        <>
                            <ContextIconButton iconName="trash" onClick={this.onRemoveRow} uuid={row.uuid} />
                            <ContextIconButton iconName="window close" onClick={this.onCancel} />
                        </>
                    )}
                    {mode === ViewEditMode.VIEW && canEditLocation && (
                        <ContextIconButton iconName="edit" onClick={this.onEditRow} />
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default LocationRow;
