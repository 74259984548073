import React, { FC, useState, ReactNode } from "react";
import { Container, Header, Divider, Image } from "semantic-ui-react";
import clsx from "clsx";

interface IFoldableContainer {
    title: string;
    children: ReactNode;
}

const FoldableContainer: FC<IFoldableContainer> = ({ title, children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleCollapseClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Container
            className={clsx("ui segment remote-model foldable", isCollapsed && "remote-model-container-collapsed")}
        >
            <Header as="h2">{title}</Header>
            {!isCollapsed && <Divider />}
            <div
                className="remote-model-collapse-div"
                onClick={handleCollapseClick}
                onKeyPress={handleCollapseClick}
                role="button"
                tabIndex={0}
            >
                <Image alt="Collapse section icon" src="/images/icons/arrow.svg" />
            </div>
            {!isCollapsed && <div>{children}</div>}
        </Container>
    );
};

export default FoldableContainer;
