import React, { FC } from "react";

import { TERMS_URL, PRIVACY_URL, CONTACT_US_URL } from "navigation/external";

import "scss/Footer.scss";

const Footer: FC = () => (
    <footer className="footer">
        <div className="footer-row">
            <span className="copyright">© Copyright {new Date().getFullYear()} Skin Analytics Ltd.</span>
            <span className="version">
                {`Version ${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_ENVIRONMENT}`}
            </span>
        </div>

        <nav>
            <ul className="navigation">
                <li>
                    <a className="link" href={TERMS_URL} target="_blank" rel="noopener noreferrer">
                        Terms and conditions
                    </a>
                </li>
                <li>
                    <a className="link" href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                </li>
                <li>
                    <a className="link" href={CONTACT_US_URL} target="_blank" rel="noopener noreferrer">
                        Contact Us
                    </a>
                </li>
            </ul>
        </nav>
    </footer>
);

export default Footer;
