import { IFormError } from "model/formError";
import { IAllocation } from "model/organisation";

export interface ICreateAllocation {
    configuration: IAllocation[];
}

export interface IDermatologistOption {
    key: string;
    value: boolean;
    text: string;
    availability?: string | number;
}

export interface IAllocationComponentProps {
    organisationUuid: string;
    showEditButton: boolean;
    updateInheritanceData: (property: string) => void;
    parentUuid?: string;
}

export interface IAllocationTableSettings {
    allocations: IAllocation[];
    isNew?: boolean;
    isChanged?: boolean;
    hasAllocationOrderChanged?: boolean;
    savedConfiguration: IAllocation[];
    loading?: boolean;
    error?: Partial<IFormError>[];
}

export interface IAllocationSettings {
    showAutomatedDecisionConsent: boolean;
    showUnconsentedCaseSettings: boolean;
    showDefaultLayout: boolean;
    consented: IAllocationTableSettings;
    unconsented: IAllocationTableSettings;
    enableUrgentRefer: boolean;
    showAllocationOrderModal: boolean;
    showUrgentReferModal: boolean;
}

export enum AllocationConfigurationConsentType {
    CONSENTED = "consented",
    UNCONSENTED = "unconsented",
}

export type AllocationConfigurationSettingType =
    | AllocationConfigurationConsentType.CONSENTED
    | AllocationConfigurationConsentType.UNCONSENTED;
