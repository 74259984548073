import { UserRole } from "model/userRole";
import { ICase } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { KitDeliveryStatuses } from "model/remoteModel";
import { RemoteTimeline } from "model/remoteCaseTimeline";

export enum CaseListTabs {
    TO_ALLOCATE = "TO ALLOCATE",
    WITH_SAFETY_NET = "WITH SAFETY NET",
    ALL = "ALL",
    TO_CLOSE = "TO CLOSE",
    TO_REVIEW = "TO REVIEW",
    IN_PROGRESS = "IN PROGRESS",
    WITH_DERMATOLOGIST = "WITH DERMATOLOGIST",
    REVIEWED = "REVIEWED",
    TO_DISPATCH = "KIT TO DISPATCH",
    DISPATCHED = "KIT DISPATCHED",
    DELIVERED = "KIT DELIVERED",
    OVERDUE = "OVERDUE",
    CALL_BACK = "CALLBACK",
}

export enum CaseListColumns {
    ID = "ID",
    DATE_CREATED = "DATE CREATED",
    PATIENT = "PATIENT",
    HOSPITAL_NUMBER = "HOSPITAL NUMBER",
    NHS = "NHS#",
    LOCATION = "LOCATION",
    BIRTH = "DATE OF BIRTH",
    MANAGEMENT_OUTCOME = "MANAGEMENT OUTCOME",
    REMOTE_TIMELINE = "REMOTE TIMELINE",
    STATUS = "STATUS",
    SAS_LINK = "SAS LINK",
    OVERDUE_FOR = "OVERDUE FOR",
    DATE_ENTERED_STATUS = "DATE ENTERED STATUS",
    ACTION = "ACTION",
    ORGANISATION = "ORGANISATION",
    DERMATOLOGIST = "DERMATOLOGIST",
    CREATOR = "CREATOR",
    MANAGEMENT_OUTCOME_SA = "MANAGEMENT OUTCOME (SA)",
    MANAGEMENT_OUTCOME_CLIENT = "MANAGEMENT OUTCOME (CLIENT)",
    ORGANISATION_EMAIL = "ORGANISATION EMAIL",
    CREATOR_EMAIL = "CREATOR EMAIL",
    PATHWAY = "PATHWAY",
    DATE_ENTERED_LIST = "DATE ENTERED LIST",
    DEMOGRAPHIC_SOURCE = "DEMOGRAPHIC SOURCE",
}

export enum CaseListPatientColumns {
    "ID" = "Case #",
    "ORGANISATION" = "INSURER",
}

export enum CaseListFilters {
    CASE_ID = "CASE ID",
    DATES = "DATES",
    HOSPITAL_ID = "HOSPITAL ID",
    LOCATION = "LOCATION",
    BIRTH = "DATE OF BIRTH",
    ORGANISATION = "ORGANISATION",
    REMOTE_TIMELINE = "REMOTE_TIMELINE",
    CASE_STATUS = "CASE STATUS",
    NHS = "NHS",
    MANAGEMENT_OUTCOME = "MANAGEMENT_OUTCOME",
    DERMATOLOGIST = "DERMATOLOGIST",
    PATHWAY = "PATHWAY",
}

export enum SortOrder {
    ASC = "asc",
    DESC = "desc",
}

export enum SortOrderUI {
    ASC = "ascending",
    DESC = "descending",
}

export const DEFAULT_COLUMNS = {
    [UserRole.ADMIN]: [
        CaseListColumns.ID,
        CaseListColumns.DATE_CREATED,
        CaseListColumns.PATIENT,
        CaseListColumns.HOSPITAL_NUMBER,
        CaseListColumns.NHS,
        CaseListColumns.LOCATION,
        CaseListColumns.BIRTH,
        CaseListColumns.DERMATOLOGIST,
        CaseListColumns.MANAGEMENT_OUTCOME,
        CaseListColumns.REMOTE_TIMELINE,
        CaseListColumns.STATUS,
        CaseListColumns.ACTION,
    ],
    [UserRole.CLINICIAN]: [
        CaseListColumns.ID,
        CaseListColumns.DATE_CREATED,
        CaseListColumns.PATIENT,
        CaseListColumns.HOSPITAL_NUMBER,
        CaseListColumns.NHS,
        CaseListColumns.LOCATION,
        CaseListColumns.BIRTH,
        CaseListColumns.STATUS,
        CaseListColumns.ACTION,
    ],
    [UserRole.DERMATOLOGIST]: [
        CaseListColumns.ID,
        CaseListColumns.DATE_CREATED,
        CaseListColumns.PATIENT,
        CaseListColumns.NHS,
        CaseListColumns.LOCATION,
        CaseListColumns.BIRTH,
        CaseListColumns.DERMATOLOGIST,
        CaseListColumns.MANAGEMENT_OUTCOME,
        CaseListColumns.STATUS,
        CaseListColumns.ACTION,
    ],
    [UserRole.SUPERADMIN]: [
        CaseListColumns.ID,
        CaseListColumns.DATE_CREATED,
        CaseListColumns.PATIENT,
        CaseListColumns.HOSPITAL_NUMBER,
        CaseListColumns.NHS,
        CaseListColumns.ORGANISATION,
        CaseListColumns.LOCATION,
        CaseListColumns.BIRTH,
        CaseListColumns.DERMATOLOGIST,
        CaseListColumns.MANAGEMENT_OUTCOME,
        CaseListColumns.SAS_LINK,
        CaseListColumns.OVERDUE_FOR,
        CaseListColumns.DATE_ENTERED_STATUS,
        CaseListColumns.REMOTE_TIMELINE,
        CaseListColumns.STATUS,
        CaseListColumns.ACTION,
        CaseListColumns.DATE_ENTERED_LIST,
    ],
    [UserRole.SA_ADMIN]: [
        CaseListColumns.ID,
        CaseListColumns.DATE_CREATED,
        CaseListColumns.PATIENT,
        CaseListColumns.HOSPITAL_NUMBER,
        CaseListColumns.NHS,
        CaseListColumns.ORGANISATION,
        CaseListColumns.LOCATION,
        CaseListColumns.BIRTH,
        CaseListColumns.DERMATOLOGIST,
        CaseListColumns.MANAGEMENT_OUTCOME,
        CaseListColumns.SAS_LINK,
        CaseListColumns.OVERDUE_FOR,
        CaseListColumns.DATE_ENTERED_STATUS,
        CaseListColumns.REMOTE_TIMELINE,
        CaseListColumns.STATUS,
        CaseListColumns.ACTION,
        CaseListColumns.DATE_ENTERED_LIST,
    ],
    [UserRole.PATIENT]: [
        CaseListColumns.ID,
        CaseListColumns.DATE_CREATED,
        CaseListColumns.PATIENT,
        CaseListColumns.STATUS,
        CaseListColumns.ORGANISATION,
        CaseListColumns.BIRTH,
        CaseListColumns.ACTION,
    ],
    [UserRole.CALLBACK_AGENT]: [
        CaseListColumns.ID,
        CaseListColumns.DATE_CREATED,
        CaseListColumns.PATIENT,
        CaseListColumns.STATUS,
        CaseListColumns.ORGANISATION,
        CaseListColumns.BIRTH,
        CaseListColumns.MANAGEMENT_OUTCOME,
        CaseListColumns.ACTION,
    ],
    [UserRole.CLINICAL_VIEWER]: [
        CaseListColumns.ID,
        CaseListColumns.DATE_CREATED,
        CaseListColumns.PATIENT,
        CaseListColumns.NHS,
        CaseListColumns.LOCATION,
        CaseListColumns.BIRTH,
        CaseListColumns.DERMATOLOGIST,
        CaseListColumns.MANAGEMENT_OUTCOME,
        CaseListColumns.STATUS,
        CaseListColumns.ACTION,
    ],
};

export const COLUMNS_SETTINGS = [
    CaseListColumns.ID,
    CaseListColumns.DATE_CREATED,
    CaseListColumns.PATIENT,
    CaseListColumns.NHS,
    CaseListColumns.ORGANISATION,
    CaseListColumns.LOCATION,
    CaseListColumns.BIRTH,
    CaseListColumns.HOSPITAL_NUMBER,
    CaseListColumns.CREATOR,
    CaseListColumns.DATE_ENTERED_LIST,
    CaseListColumns.DERMATOLOGIST,
    CaseListColumns.MANAGEMENT_OUTCOME,
    CaseListColumns.MANAGEMENT_OUTCOME_SA,
    CaseListColumns.MANAGEMENT_OUTCOME_CLIENT,
    CaseListColumns.SAS_LINK,
    CaseListColumns.OVERDUE_FOR,
    CaseListColumns.DATE_ENTERED_STATUS,
    CaseListColumns.REMOTE_TIMELINE,
    CaseListColumns.STATUS,
    CaseListColumns.ACTION,
    CaseListColumns.ORGANISATION_EMAIL,
    CaseListColumns.CREATOR_EMAIL,
    CaseListColumns.PATHWAY,
    CaseListColumns.DEMOGRAPHIC_SOURCE,
];

export enum CaseListSearchFieldKeys {
    LAST_NAME = "LAST_NAME",
    FIRST_NAME = "FIRST_NAME",
    EMAIL_ADDRESS = "EMAIL_ADDRESS",
    PREAUTHORIZATION_NUMBER = "AUTHORIZATION_NUMBER",
    MEMBERSHIP_NUMBER = "MEMBERSHIP_NUMBER",
    POSTCODE = "POSTCODE",
}

export enum CaseListSearchFieldMapping {
    LAST_NAME = "lastName",
    FIRST_NAME = "name",
    EMAIL_ADDRESS = "createdByEmail",
    PREAUTHORIZATION_NUMBER = "preauthorisationNumber",
    MEMBERSHIP_NUMBER = "memberNumber",
    POSTCODE = "postCode",
}

export enum CaseListSearchFieldNames {
    LAST_NAME = "Last name",
    FIRST_NAME = "First name",
    EMAIL_ADDRESS = "Email address",
    PREAUTHORIZATION_NUMBER = "Pre-authorisation number",
    MEMBERSHIP_NUMBER = "Member number",
    POSTCODE = "Postcode",
}

export interface ICaseListSettingItem {
    name: CaseListColumns;
    order: number;
    display?: boolean;
}

export interface IUserPreferencesData {
    viewName: string;
    columns: ICaseListSettingItem[];
}

export interface ICaseLists {
    forReview: ICaseListsState;
    inSaReview: ICaseListsState;
    all: ICaseListsState;
    inProgress: ICaseListsState;
    toClose: ICaseListsState;
    toReview: ICaseListsState;
    inReview: ICaseListsState;
    overdue: ICaseListsState;
}

export interface IFilter {
    id?: string;
    remoteTimeline?: string;
    status?: string;
    caseStatus?: string;
    dateFrom?: string;
    dateTo?: string;
    organisation?: string;
    name?: string;
    lastName?: string;
    birth?: string;
    hospitalId?: string;
    locationUuid?: string;
    nhs?: string;
    managementOutcome?: string;
    recommendation?: string;
    dermatologist?: string;
    overdue?: boolean;
    nonSkinCancer?: string;
    remote?: boolean;
    preauthorisationNumber?: string;
    memberNumber?: string;
    postcode?: string;
    isCallbackNeeded?: boolean;
}

export interface IFilterReq extends IFilter {
    stepFilter: RemoteTimeline;
}

export interface ISearch {
    location?: string;
    recommendation?: string;
    createdByEmail?: string;
}

export interface IFilterReqParams {
    caseFilters?: IFilter;
    patientFilters?: IFilter;
    sortValue?: string;
    searchFilters?: ISearch;
}

export interface ICaseListsState {
    isPending?: boolean;
    data?: ICase[];
    total?: number;
    sort?: any;
    filters?: IFilter;
}

export interface IKitDeliveryStatusFilter {
    readonly kitDeliveryStatus: KitDeliveryStatuses;
    readonly caseStatus?: CaseStatus[];
}

export interface IPaginatedResponse {
    correlationId: string;
    total: number;
    data: ICase[];
    _links: ILinks;
}

export interface ILinks {
    first: string;
    prev: string;
    next: string;
    last: string;
}

export const SORT_COLUMNS: string[] = [
    CaseListColumns.ID,
    CaseListColumns.DATE_CREATED,
    CaseListColumns.STATUS,
    CaseListColumns.LOCATION,
    CaseListColumns.ORGANISATION,
    CaseListColumns.MANAGEMENT_OUTCOME,
    CaseListColumns.ACTION,
    CaseListColumns.OVERDUE_FOR,
    CaseListColumns.DATE_ENTERED_LIST,
];
