import React, { FC, useState, useEffect } from "react";
import { Grid, Image } from "semantic-ui-react";
import parse from "html-react-parser";

import { ICase } from "model/case";
import { CaseStatus } from "model/caseStatus";
import { UserRole } from "model/userRole";

import organizationService from "services/organizationService";

interface ICaseDescriptionProps {
    currentCase: ICase;
    userRole: string;
    organisationEmail: string;
}

const ReviewPathwayContext: FC<ICaseDescriptionProps> = ({ currentCase, userRole, organisationEmail }) => {
    const [pathwayConsent, setPathwayConsent] = useState<string>("");
    const [organisationLogo, setOrganisationLogo] = useState<string>("");
    const { organisationName, organisationUuid } = currentCase;
    const { caseStatus } = currentCase;
    const isInSaReview = caseStatus === CaseStatus.CASE_IN_SA_REVIEW;
    const isDermatologist = userRole === UserRole.DERMATOLOGIST;
    const isCallbackAgent = userRole === UserRole.CALLBACK_AGENT;
    const render = (isInSaReview && isDermatologist) || isCallbackAgent;

    useEffect(() => {
        if (organisationUuid && render) {
            organizationService.getOrganisationPathway(organisationUuid).then((result) => {
                setPathwayConsent(result);
            });
            organizationService
                .getOrganisationLogo(organisationUuid)
                .then((result) => {
                    setOrganisationLogo(result);
                })
                .catch((err) => {
                    console.error("err", err);
                });
        }
    }, [organisationUuid, render]);

    if (!render) {
        return null;
    }

    const pathwayConsentText = parse(pathwayConsent || "");

    return (
        <Grid>
            <Grid.Row columns="2">
                <Grid.Column width="8">
                    <p className="pathway-consent-label">Requested by:</p>
                    <p>{organisationName}</p>
                </Grid.Column>
                <Grid.Column width="8" floated="right" className="pathway-consemnt-logo-wrapper">
                    {organisationLogo && (
                        <Image
                            alt={`Organisation logo for ${organisationName}`}
                            src={organisationLogo}
                            className="pathway-consent-logo"
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            {pathwayConsentText && (
                <Grid.Row>
                    <Grid.Column>
                        {isCallbackAgent && (
                            <p className="pathway-consent-label">
                                <span>Partner organisation contact details:</span>
                                <span> {organisationEmail}</span>
                            </p>
                        )}

                        <p className="pathway-consent-label">Pathway context:</p>
                        <div>{pathwayConsentText}</div>
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );
};

export default ReviewPathwayContext;
