import React, { FC, useState, useContext, useEffect, ChangeEvent, MouseEvent as ReactMouseEvent } from "react";
import { ButtonProps, Checkbox, Header, InputOnChangeData, Table } from "semantic-ui-react";

import EditableField from "components/CaseDescription/EditableField";

import { CaseListContext } from "contextProviders/CaseListProvider";

import { formatTime } from "helpers/datetime";

import { ICase } from "model/case";
import { KitDeliveryStatuses } from "model/remoteModel";
import ViewEditMode from "model/viewEditMode";

import { COURIER_URL_BASE } from "navigation/external";

import * as caseService from "services/caseService";

import "scss/Table.scss";

const TRACK_OUT_LABEL = "trackOut";
const TRACK_IN_LABEL = "trackIn";

interface ITrackingStatus {
    trackIn: string;
    trackOut: string;
    shippedOutDate?: Date;
}

interface ITrackingStatusViewMode {
    trackIn?: ViewEditMode;
    trackOut?: ViewEditMode;
}

interface ICaseKitTracking {
    readonly caseObject: ICase;
}

const CaseKitTracking: FC<ICaseKitTracking> = ({ caseObject }) => {
    const [trackingStatus, setTrackingStatus] = useState<ITrackingStatus>({
        trackIn: "",
        trackOut: "",
        shippedOutDate: undefined,
    });
    const [viewEditMode, setViewEditMode] = useState<ITrackingStatusViewMode>({
        trackIn: ViewEditMode.VIEW,
        trackOut: ViewEditMode.VIEW,
    });
    const [currentKitDeliveryStatus, setCurrentKitDeliveryStatus] = useState(() => caseObject.kitDeliveryStatus);
    const [isKitDelivered, setIsKitDelivered] = useState<boolean>(false);
    const [enableDeliveredCheckbox, setEnableDeliveredCheckbox] = useState<boolean>(false);

    const { updateKitDeliveryStatus } = useContext(CaseListContext);
    const { uuid: caseUuid, kitTracking } = caseObject;

    useEffect(() => {
        if (kitTracking) {
            const {
                trackInNo: kitTrackingDtoTrackInNo,
                trackOutNo: kitTrackingDtotrackOutNo,
                shippedOutDate: kitTrackingDtoShippedOutDate,
            } = kitTracking;
            setTrackingStatus({
                trackIn: kitTrackingDtoTrackInNo || "",
                trackOut: kitTrackingDtotrackOutNo || "",
                shippedOutDate: (kitTrackingDtotrackOutNo && kitTrackingDtoShippedOutDate) || undefined,
            });
        }

        if (currentKitDeliveryStatus && kitTracking) {
            const isDeliverableStatus = [KitDeliveryStatuses.DELIVERED, KitDeliveryStatuses.DISPATCHED].includes(
                currentKitDeliveryStatus
            );

            setIsKitDelivered(currentKitDeliveryStatus === KitDeliveryStatuses.DELIVERED);
            setEnableDeliveredCheckbox(isDeliverableStatus && Boolean(kitTracking.trackOutNo));
        }
    }, []);

    const handleMarkKitAsDelivered = () => {
        const newKitDeliveryStatus =
            currentKitDeliveryStatus === KitDeliveryStatuses.DELIVERED
                ? KitDeliveryStatuses.DISPATCHED
                : KitDeliveryStatuses.DELIVERED;
        caseService.updateKitDeliveryStatus(caseUuid, newKitDeliveryStatus).then(() => {
            setCurrentKitDeliveryStatus(newKitDeliveryStatus);
            setIsKitDelivered(newKitDeliveryStatus === KitDeliveryStatuses.DELIVERED);

            updateKitDeliveryStatus(caseUuid, newKitDeliveryStatus, currentKitDeliveryStatus as KitDeliveryStatuses);
        });
    };

    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const { name: trackingDictionaryName, value: newTrackingValue } = data;
        const newTrackingStatuses = {
            ...trackingStatus,
            ...{
                [trackingDictionaryName]: newTrackingValue,
            },
        };
        setTrackingStatus(newTrackingStatuses);

        if (trackingDictionaryName === TRACK_OUT_LABEL && newTrackingValue === "") {
            setEnableDeliveredCheckbox(false);
        }
    };

    const handleSetField = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
        const { name: trackingDictionaryName } = data;
        const { trackIn: kitTrackInNo, trackOut: kitTrackOutNo } = trackingStatus;
        const newShippedDate = new Date();

        const trimmedKitTrackInNo = kitTrackInNo.trim();
        const trimmedKitTrackOutNo = kitTrackOutNo.trim();

        const newViewEditMode =
            viewEditMode[trackingDictionaryName] === ViewEditMode.EDIT ? ViewEditMode.VIEW : ViewEditMode.EDIT;
        const newViewEditModes = {
            ...viewEditMode,
            ...{
                [trackingDictionaryName]: newViewEditMode,
            },
        };

        setViewEditMode(newViewEditModes);

        if (
            viewEditMode[trackingDictionaryName] === ViewEditMode.EDIT &&
            (trimmedKitTrackOutNo !== kitTracking?.trackOutNo || trimmedKitTrackInNo !== kitTracking?.trackInNo)
        ) {
            const removeShippedDate = trimmedKitTrackOutNo ? newShippedDate : undefined;
            setTrackingStatus({
                ...trackingStatus,
                trackIn: trimmedKitTrackInNo,
                trackOut: trimmedKitTrackOutNo,
                shippedOutDate: removeShippedDate,
            });

            const isKitTrackOut = trackingDictionaryName === TRACK_OUT_LABEL;
            const createKitFunction = isKitTrackOut ? caseService.createKitTrackOut : caseService.createKitTrackIn;
            const kitTrackingNumber = isKitTrackOut ? trimmedKitTrackOutNo : trimmedKitTrackInNo;
            const createKitCallback = isKitTrackOut
                ? () => {
                      const isTrackOutEmpty = trimmedKitTrackOutNo === "";
                      const newKitDeliveryStatus = isTrackOutEmpty
                          ? KitDeliveryStatuses.TO_DISPATCH
                          : KitDeliveryStatuses.DISPATCHED;

                      if (isTrackOutEmpty) {
                          setIsKitDelivered(false);
                      }

                      if (currentKitDeliveryStatus !== newKitDeliveryStatus) {
                          updateKitDeliveryStatus(
                              caseUuid,
                              newKitDeliveryStatus,
                              currentKitDeliveryStatus as KitDeliveryStatuses
                          );
                      }

                      setCurrentKitDeliveryStatus(newKitDeliveryStatus);
                      setEnableDeliveredCheckbox(!isTrackOutEmpty);
                  }
                : () => null;

            createKitFunction(caseUuid, kitTrackingNumber, newShippedDate).then(createKitCallback);
        }
    };

    const { trackIn, trackOut, shippedOutDate } = trackingStatus;

    let dateShipped = "";

    if (shippedOutDate) {
        const dateShippedRaw = typeof shippedOutDate === "string" ? shippedOutDate : shippedOutDate.toISOString();
        dateShipped = formatTime(dateShippedRaw);
    }

    return (
        <>
            <Header as="h3">Device Info</Header>
            <Table celled className="case-review__tracking-table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>TRACKING OUT</Table.HeaderCell>
                        <Table.HeaderCell>TRACKING IN</Table.HeaderCell>
                        <Table.HeaderCell>DATE SHIPPED</Table.HeaderCell>
                        <Table.HeaderCell>DEVICE DELIVERED</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell width={4}>
                            <EditableField
                                urlBase={COURIER_URL_BASE}
                                name={TRACK_OUT_LABEL}
                                value={trackOut}
                                viewMode={viewEditMode}
                                onFieldChange={handleFieldChange}
                                onHandleSetField={handleSetField}
                            />
                        </Table.Cell>
                        <Table.Cell width={4}>
                            <EditableField
                                urlBase={COURIER_URL_BASE}
                                name={TRACK_IN_LABEL}
                                value={trackIn}
                                viewMode={viewEditMode}
                                onFieldChange={handleFieldChange}
                                onHandleSetField={handleSetField}
                            />
                        </Table.Cell>
                        <Table.Cell width={2}>{dateShipped}</Table.Cell>
                        <Table.Cell width={2}>
                            <Checkbox
                                label="Yes"
                                onChange={handleMarkKitAsDelivered}
                                checked={isKitDelivered}
                                disabled={!enableDeliveredCheckbox}
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </>
    );
};
export default CaseKitTracking;
