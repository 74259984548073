import { useState } from "react";

import useInterval from "hooks/useInterval";

const COUNTDOWN_INITIAL = 60;
const COUNTDOWN_TIMEOUT = 1000;

interface IUseCountdownTimer {
    countdownTime: number;
    isCountdownFinished: boolean;
    resetCountdown: () => void;
}

const useCountdownTimer = (): IUseCountdownTimer => {
    const [countdownTime, setCountdownTime] = useState<number>(COUNTDOWN_INITIAL);

    const isCountdownFinished = countdownTime <= 0;

    useInterval(
        () => {
            setCountdownTime(countdownTime - 1);
        },
        COUNTDOWN_TIMEOUT,
        isCountdownFinished
    );

    const resetCountdown = (): void => {
        setCountdownTime(COUNTDOWN_INITIAL);
    };

    return {
        countdownTime,
        isCountdownFinished,
        resetCountdown,
    };
};

export default useCountdownTimer;
