import { FC } from "react";
import { Grid } from "semantic-ui-react";

import { formatTime } from "helpers/datetime";

import PatientSearchExpandedViewItem from "components/PatientSearch/PatientSearchExpandedViewItem";

import { IPatientEdit } from "model/patientEdit";

interface IPatientDetailsIntegration {
    patient: IPatientEdit;
    searchHospitalNumber?: string;
}

const PatientDetailsIntegrationGrid: FC<IPatientDetailsIntegration> = ({ patient, searchHospitalNumber }) => {
    const {
        birth,
        ethnicGroup,
        gender,
        hospitalNo,
        name,
        occupation,
        surname,
        title,
        nhs,
        mergedRecord,
        minorPatientIdentifier,
    } = patient;

    const formattedDateOfBirth = birth ? formatTime(birth.toString()) : "";

    return (
        <Grid columns="equal" className="patient-search-expanded-view__fields">
            <Grid.Row>
                <PatientSearchExpandedViewItem title="Title" value={title} />
                <PatientSearchExpandedViewItem title="Given Name(s)" value={name} />
                <PatientSearchExpandedViewItem title="Surname" value={surname} />
                <PatientSearchExpandedViewItem title="Date of birth" value={formattedDateOfBirth} />
            </Grid.Row>
            <Grid.Row>
                <PatientSearchExpandedViewItem title="Gender" value={gender} />
                <PatientSearchExpandedViewItem title="NHS number" value={nhs} />
                <PatientSearchExpandedViewItem title="Hospital Number" value={hospitalNo} />
                <PatientSearchExpandedViewItem title="Ethnicity" value={ethnicGroup} />
            </Grid.Row>
            <Grid.Row>
                <PatientSearchExpandedViewItem title="Occupation" value={occupation} width={4} />
                {mergedRecord && minorPatientIdentifier && (
                    <PatientSearchExpandedViewItem
                        title="Minor patient identifier(s)"
                        value={minorPatientIdentifier}
                        minorPatientIdentifier={searchHospitalNumber}
                        width={4}
                    />
                )}
            </Grid.Row>
        </Grid>
    );
};

PatientDetailsIntegrationGrid.defaultProps = {
    searchHospitalNumber: "",
};

export default PatientDetailsIntegrationGrid;
