import HttpStatus from "model/httpStatus";

import { history } from "App";
import { LOGIN } from "navigation/routes";

import * as actions from "redux/actions";
import store from "redux/store";

export const INACTIVE_USER_ERROR = "Not an active user account";

interface IError {
    message: string;
}

function dispatchAction(action: any, dispatch?: any) {
    if (dispatch) {
        dispatch(action);
    }
}

function handleError(err: any, dispatch?: any): any[] {
    if (err.response && err.response.status) {
        if (err.response.status === HttpStatus.UNAUTHORIZED) {
            const authObject = store.store.getState().auth;
            if (authObject.authToken && authObject.authToken !== "") {
                history.push(LOGIN);
                dispatchAction(actions.authActions.clearData(), store.store.dispatch);
            }
            return undefined;
        }
        if (err.response.status === HttpStatus.BAD_REQUEST && err.response.data) {
            dispatchAction(actions.formErrorActions.formError(err.response.data.errors), dispatch);
            return err.response.data.errors;
        }
        dispatchAction(actions.validateActions.errorGeneral(err), dispatch);
        return [err];
    }
    dispatchAction(actions.validateActions.errorGeneral(err), dispatch);
    return [err];
}

function handleLoginScreenError(err: any, dispatch: any) {
    let errors: IError[] = [];
    if (err.response && err.response.status === HttpStatus.UNAUTHORIZED) {
        if (err.response.data.errors.find((error) => error.message === INACTIVE_USER_ERROR)) {
            errors = [
                {
                    message: INACTIVE_USER_ERROR,
                },
            ];
        } else {
            errors = [{ message: "Email/password incorrect, please try again." }];
        }
    } else if (err.response && err.response.data && err.response.data.errors) {
        errors = err.response.data.errors;
    } else if (err.response.status === HttpStatus.FORBIDDEN) {
        errors = [{ message: "Unable to log in to this user." }];
    } else if (err.response.status === HttpStatus.TOO_MANY_REQUESTS) {
        errors = [{ message: "Too many incorrect login attempts. You can try again in 15 minutes." }];
    } else {
        errors = [{ message: "Unable to log in - check network connection" }];
    }
    dispatchAction(actions.validateActions.errorGeneral(errors), dispatch);
}

function cleanFormError() {
    return (dispatch: any) => {
        dispatch(actions.formErrorActions.cleanFormError(), dispatch);
    };
}

function cleanValidationError() {
    return (dispatch: any) => {
        dispatch(actions.validateActions.cleanValidationError(), dispatch);
    };
}

function cleanErrors() {
    return (dispatch: any) => {
        dispatch(actions.formErrorActions.cleanErrors(), dispatch);
    };
}

export { handleError, handleLoginScreenError, cleanFormError, cleanValidationError, cleanErrors };
