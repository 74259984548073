import React, { ReactNode } from "react";
import clsx from "clsx";

import { Icon, SemanticICONS } from "semantic-ui-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import "scss/Modal.scss";

interface IButtonConfig {
    text?: string;
    onClick?: () => void;
    border?: string;
    disabled?: boolean;
    variant?: "outlined" | "contained";
    colour?: "primary" | "secondary";
    isLoading?: boolean;
}

interface IModalDialog {
    title?: string | JSX.Element;
    subtitle?: string | JSX.Element;
    iconName?: SemanticICONS;
    iconComponent?: React.ComponentType<SvgIconProps>;
    iconSrc?: string;
    isWarning?: boolean;
    children?: any;
    open: boolean;
    onClose: (open: boolean) => void;
    disableBackdropClick?: boolean;
    maxWidth?: "sm" | "md" | "lg" | false;
    buttons: IButtonConfig[];
    closeIcon?: boolean;
    onEsc?: () => void;
    transitionDuration?: number | { appear?: number; enter?: number; exit?: number };
    className?: string;
}

const ModalDialog = (props: IModalDialog) => {
    const {
        title,
        subtitle,
        iconName,
        iconSrc,
        iconComponent,
        children,
        open,
        closeIcon,
        onClose = () => undefined,
        disableBackdropClick,
        maxWidth,
        buttons,
        transitionDuration,
        onEsc = () => undefined,
        className,
        isWarning,
    } = props;

    const handleOnClose = (event: Event, reason?: "backdropClick" | "escapeKeyDown") => {
        const isBackdropClick = reason === "backdropClick";

        if (onEsc) {
            onEsc();
            onClose(false);
        } else if (!isBackdropClick || (isBackdropClick && !disableBackdropClick)) {
            onClose(false);
        }
    };

    const getIcon = (): ReactNode => {
        if (iconSrc) {
            return <img alt={iconSrc.split("icons/")[1].split(".svg")[0]} src={iconSrc} />;
        }

        if (iconName) {
            return <Icon name={iconName} size="big" circular bordered={false} alt={iconName} />;
        }

        if (iconComponent) {
            const IconComponent = iconComponent;
            return (
                <div className="icon-component">
                    <IconComponent />
                </div>
            );
        }
        return null;
    };

    return (
        <Dialog
            transitionDuration={transitionDuration}
            className="modal-dialog-container"
            maxWidth={maxWidth}
            open={open}
            onClose={handleOnClose}
            aria-labelledby="confirm-dialog"
            role="dialog"
        >
            <div className={clsx({ "modal-dialog": true, ...(className && { [className]: true }) })}>
                <div className="icon-container">{getIcon()}</div>
                <DialogTitle>
                    <b>{title}</b>
                    {closeIcon && (
                        <Button onClick={() => onClose(false)}>
                            <img alt="close" src="/images/icons/icon-close-simple.svg" />
                        </Button>
                    )}
                </DialogTitle>
                {subtitle ? <div className="modal-subtitle">{subtitle}</div> : null}
                <DialogContent>
                    <div className="dialog-content">{children}</div>
                </DialogContent>
                <DialogActions>
                    {buttons
                        .filter((value) => Object.keys(value).length !== 0)
                        .reverse()
                        .map((button, index) => (
                            <Button
                                onClick={button.onClick}
                                disabled={button.disabled}
                                variant={button.variant || "contained"}
                                color={button.colour || (index ? "primary" : "secondary")}
                                key={button.text || index}
                                style={isWarning ? { backgroundColor: `${index ? "#FFB020" : "#FFF4E5"}` } : {}}
                            >
                                {button.isLoading ? (
                                    <CircularProgress size="16px" key={button.text || index} />
                                ) : (
                                    `${button.text}`
                                )}
                            </Button>
                        ))}
                </DialogActions>
            </div>
        </Dialog>
    );
};

ModalDialog.defaultProps = {
    title: null,
    subtitle: null,
    iconName: "",
    iconSrc: "",
    disableBackdropClick: false,
    maxWidth: undefined,
    onEsc: undefined,
    closeIcon: false,
    transitionDuration: {
        appear: 500,
        enter: 300,
        exit: 500,
    },
    className: undefined,
};

export default ModalDialog;
