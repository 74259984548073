import { DefinedPersonalDataType } from "model/organisation";
import { ChangeEvent } from "react";
import { TextAreaProps } from "semantic-ui-react";

const TEXT_AREA_WHITESPACE_REGEXP = /\s[^\S\r\n]|[\t]/gm;
const TEXT_WHITESPACE_REGEXP = /\s|[\t\r\n\n]/gm;

type ChangeDataWithValue = {
    value: string | number;
};

type ChangeDataKeyValue = {
    [key: string]: any;
};

type ChangeData = ChangeDataKeyValue | ChangeDataWithValue;

const whitespaceRemover = (
    data: ChangeData,
    isTextArea = false,
    fieldType: DefinedPersonalDataType = DefinedPersonalDataType.TEXT
): ChangeData => {
    const fieldValue = data.value;
    const regexp = isTextArea ? TEXT_AREA_WHITESPACE_REGEXP : TEXT_WHITESPACE_REGEXP;
    if (typeof fieldValue === "string" && fieldType === DefinedPersonalDataType.TEXT && regexp.test(fieldValue)) {
        return { ...data, value: fieldValue.replace(regexp, " ") };
    }
    return data;
};

export const onChangeWhitespaceRemover =
    <T>(onChange: (event: T, data: ChangeData) => void, isTextArea = false): ((event: T, data: ChangeData) => void) =>
    (event: T, data: ChangeData) => {
        onChange(event, whitespaceRemover(data, isTextArea));
    };

export const onPasteWhitespaceRemover =
    <T>(
        callback: (event: T, data: TextAreaProps) => void,
        isTextArea = false,
        data?: ChangeData
    ): ((event: T) => void) =>
    (event: T) => {
        const pasteEvent = event as unknown as React.ClipboardEvent;
        const inputEvent = event as unknown as ChangeEvent<HTMLInputElement>;
        pasteEvent.preventDefault();
        const pastedText = pasteEvent.clipboardData.getData("text/plain");
        const { value: originalValue, selectionStart, selectionEnd } = inputEvent.target;
        const newText =
            originalValue.substring(0, selectionStart) +
            pastedText +
            originalValue.substring(selectionEnd, originalValue.length);
        const newData = whitespaceRemover({ value: newText, ...data }, isTextArea);
        callback(event, newData);
    };

export default onChangeWhitespaceRemover;
