import { connect } from "react-redux";

import AssessmentMode from "components/AssessmentMode/AssessmentMode";

import * as caseService from "services/caseService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    nonSkinCancer: state.assessment.nonSkinCancer,
    organisation: state.data.organisation,
    signedIn: state.auth.signedIn,
});

const mapDispatchToProps = {
    setNonSkinCancer: caseService.setNonSkinCancer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentMode);
