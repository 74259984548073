import React, { FC, ChangeEvent, FormEvent } from "react";
import { CheckboxProps, Form, InputOnChangeData } from "semantic-ui-react";
import InputMask from "react-input-mask";

import isAndroidDevice from "helpers/device";
import {
    daysInMonth,
    formatCustomDateForForm,
    formatDateForAndroidTextDateForm,
    formatMomentDateForForm,
} from "helpers/datetime";
import { onChangeWhitespaceRemover } from "helpers/field";

import { IContactPreferences } from "model/case";
import { DefinedPersonalDataType, IDefinedValidationType } from "model/organisation";
import QuestionType from "model/questionType";
import InputDateField from "components/InputDateField";
import { Moment } from "moment";

export enum AvailableMask {
    nhsNumber = "nhsNumber",
    date = "date",
}

interface IFormElement {
    data: {
        type: any;
        displayName: string;
        propertyName: string;
        options?: string[];
    };
    disabled?: boolean;
    onChange: (
        event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>,
        data: InputOnChangeData | CheckboxProps
    ) => void;
    className?: string;
    required?: boolean;
    formErrors?: any | undefined;
    type?: any;
    id?: any;
    value: string | number | IContactPreferences | boolean | undefined;
    validationProperty?: string | null;
    displayName?: string;
    action?: any;
    placeholder?: string;
    resetFieldValidationError?: () => void;
}

const FormElement: FC<IFormElement> = ({
    data: { type, displayName, propertyName, options },
    onChange,
    className,
    required,
    formErrors,
    value,
    validationProperty,
    action,
    disabled,
    placeholder,
    resetFieldValidationError,
}) => {
    const [dateValidationErrors, setDateValidationErrors] = React.useState<string>();
    const [customTextDate, setCustomTextDate] = React.useState<string>();

    const isTextDateNotCorrect = (date: any, isError: boolean): boolean =>
        date === undefined || String(date)?.includes("_") || isError;

    const handleMaskChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e, {
            id: propertyName,
            type,
            value: e.target.value,
        });
    };

    const validateDate = (date: string): boolean => {
        const splitedDate = date.split("/");
        const day = Number(splitedDate[0]);
        const month = Number(splitedDate[1]);
        const year = Number(splitedDate[2]);

        const allowedDaysInMonth = daysInMonth(month, year);

        if (
            date !== "" &&
            (month > 12 ||
                month < 1 ||
                year > 2100 ||
                year < 1900 ||
                day < 1 ||
                day > allowedDaysInMonth ||
                date.includes("_"))
        ) {
            setDateValidationErrors("Please enter a valid date");
            return false;
        }

        setDateValidationErrors(undefined);
        return true;
    };

    const handleTextDateValueChange = (e: React.ChangeEvent<HTMLInputElement>, inputValue: string) => {
        onChange(e, {
            id: propertyName,
            type,
            value: inputValue,
        });
    };

    const handleTextDateChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const inputValue = e.target.value;
        setCustomTextDate(inputValue);

        if (!isTextDateNotCorrect(inputValue, false)) {
            if (validateDate(inputValue)) {
                const dateIsoFormat = formatCustomDateForForm(inputValue, "DD/MM/YYYY");
                setCustomTextDate(dateIsoFormat);

                handleTextDateValueChange(e, dateIsoFormat);
            } else {
                resetFieldValidationError();
            }
        } else {
            handleTextDateValueChange(e, inputValue);
            setDateValidationErrors(undefined);
        }
    };

    const label = (
        <p>
            {displayName}
            {required ? <span className="asterisk">*</span> : ""}
        </p>
    );

    let isError: boolean | any;

    if (
        formErrors &&
        formErrors.show &&
        formErrors.list.length > 0 &&
        formErrors.list.find((error: any) => error.propertyName === propertyName)
    ) {
        const errorText = formErrors.list.find((error: any) => error.propertyName === propertyName).text;
        isError = {
            content: errorText,
            pointing: undefined,
        };
    } else if (dateValidationErrors) {
        isError = {
            content: dateValidationErrors,
            pointing: undefined,
        };
    } else {
        isError = false;
    }

    const getMask = (mask: AvailableMask): JSX.Element => {
        if (mask === AvailableMask.nhsNumber) {
            return (
                <InputMask
                    onChange={handleMaskChange}
                    mask="999 999 9999"
                    maskChar={null}
                    value={value?.toString() || ""}
                />
            );
        }
        if (mask === AvailableMask.date) {
            const customTextValue = customTextDate || value;

            return (
                <InputMask
                    onChange={handleTextDateChange}
                    mask="99/99/9999"
                    placeholder="DD/MM/YYYY"
                    value={
                        isTextDateNotCorrect(customTextValue, isError)
                            ? customTextValue?.toString()
                            : formatDateForAndroidTextDateForm(customTextValue?.toString())
                    }
                />
            );
        }
        return null;
    };

    if ([DefinedPersonalDataType.TEXT, DefinedPersonalDataType.NUMBER, DefinedPersonalDataType.DATE].includes(type)) {
        const isAndroid = isAndroidDevice();
        const shouldUseTextForDate = isAndroid && type === DefinedPersonalDataType.DATE;

        let mask;
        if (validationProperty === IDefinedValidationType.nhsNumber) {
            mask = getMask(AvailableMask.nhsNumber);
        }
        if (shouldUseTextForDate) {
            mask = getMask(AvailableMask.date);
        }

        const inputType = shouldUseTextForDate ? DefinedPersonalDataType.TEXT : type;

        if (type === DefinedPersonalDataType.DATE) {
            return (
                <InputDateField
                    value={value as string}
                    label={label as any}
                    className={className}
                    onChange={(newValue: Moment) => {
                        onChange(undefined, {
                            value: formatMomentDateForForm(newValue),
                            id: propertyName,
                        });
                    }}
                    dateError={isError?.content}
                    disabled={disabled}
                />
            );
        }

        return (
            <Form.Input
                action={action}
                error={isError}
                disabled={disabled}
                className={className}
                type={inputType}
                label={label}
                id={propertyName}
                placeholder={placeholder || displayName}
                onChange={onChangeWhitespaceRemover(onChange)}
                value={value || ""}
                validation={validationProperty}
            >
                {mask}
            </Form.Input>
        );
    }

    if (type.includes(DefinedPersonalDataType.OPTIONS)) {
        return (
            <div className="custom-radio">
                <div>{label}</div>
                <div className="options columned">
                    {options?.map((option: string) => (
                        <Form.Radio
                            disabled={disabled}
                            id={option}
                            key={option}
                            name={propertyName}
                            label={option}
                            checked={value === option}
                            onChange={onChange}
                            value={option}
                            type={QuestionType.RADIO}
                        />
                    ))}
                </div>
            </div>
        );
    }

    if (type === DefinedPersonalDataType.BOOLEAN) {
        return (
            <Form.Checkbox
                disabled={disabled}
                id={propertyName}
                checked={Boolean(value)}
                label={displayName}
                onChange={onChange}
                error={isError}
                className={className}
            />
        );
    }

    return (
        <Form.Input
            error={isError}
            disabled={disabled}
            type="text"
            className={className}
            label={label}
            id={propertyName}
            placeholder={displayName}
            onChange={onChange}
            value={value}
        />
    );
};

FormElement.defaultProps = {
    disabled: false,
    className: "",
    required: false,
    formErrors: undefined,
    type: undefined,
    id: undefined,
    validationProperty: "",
    displayName: "",
    action: undefined,
    placeholder: "",
    resetFieldValidationError: () => {},
};

export default FormElement;
