export const noteLimit = 1000;
const erroredBackground = "#FDF6F6";
const erroredOutline = "#D9B6B5";

export const noteExceedsLimit = (text: string): boolean => text.length > noteLimit;

export const noteExceedsLimitTextfieldStyle = (text: string): Record<string, string> =>
    noteExceedsLimit(text) ? { background: erroredBackground, borderColor: erroredOutline } : {};

export const medicalHistoryAnswersExceedLimit = (medHistAnswers: Record<string, Record<string, string>>): boolean =>
    Object.values(medHistAnswers).length > 0 &&
    Object.values(medHistAnswers).filter(
        (answer) =>
            (Object.keys(answer).includes("additionalInformation") && noteExceedsLimit(answer.additionalInformation)) ||
            (Object.keys(answer).includes("value") && noteExceedsLimit(answer.value))
    ).length > 0;
