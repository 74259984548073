import React, { FC } from "react";
import { Header } from "semantic-ui-react";

import ImmediateResultLesionsAssesment from "components/ImmediateResult/Lesions/ImmediateResultLesionsAssessment";

import { getFieldFromTemplate, getTemplate } from "helpers/template";

import { ICase } from "model/case";
import { GuidanceValue, IOrganisation } from "model/organisation";
import { TemplateType } from "model/templateType";
import { CaseStatus } from "model/caseStatus";

interface IReferImmediateResult {
    currentCase: ICase;
    organisation: IOrganisation;
    guidanceValue: GuidanceValue;
}

const ReferImmediateResult: FC<IReferImmediateResult> = ({ currentCase, organisation, guidanceValue }) => {
    const descriptionText = getFieldFromTemplate(
        "case_in_review_text",
        currentCase,
        organisation,
        getTemplate(TemplateType.IMMEDIATE_RESULT_TEXT, organisation)
    );

    const isCaseCompletedOrClosed = [
        CaseStatus.CASE_FAILED,
        CaseStatus.CASE_COMPLETED,
        CaseStatus.CASE_CLOSED,
        CaseStatus.CASE_ABANDONED,
    ].includes(currentCase.caseStatus);

    return (
        <>
            <Header as="h2">Clinician review in progress</Header>
            <p>{descriptionText}</p>
            {isCaseCompletedOrClosed && (
                <ImmediateResultLesionsAssesment caseObject={currentCase} guidanceValue={guidanceValue} />
            )}
        </>
    );
};

export default ReferImmediateResult;
