import { combineReducers } from "redux";

import assessmentReducer from "redux/reducers/assessmentReducer";
import authReducer from "redux/reducers/authReducer";
import dataReducer from "redux/reducers/dataReducer";
import mfaReducer from "redux/reducers/mfaReducer";
import navigationReducer from "redux/reducers/navigationReducer";
import previousCaseReducer from "redux/reducers/previousCaseReducer";
import registrationReducer from "redux/reducers/registrationReducer";
import registrationUIReducer from "redux/reducers/registrationUIReducer";

import formErrorReducer from "redux/reducers/formErrorReducer";
import validateReducer from "redux/reducers/validateReducer";

export default combineReducers({
    assessment: assessmentReducer,
    auth: authReducer,
    data: dataReducer,
    formErrors: formErrorReducer,
    mfa: mfaReducer,
    navigation: navigationReducer,
    previousCase: previousCaseReducer,
    validate: validateReducer,
    registration: registrationReducer,
    registrationUI: registrationUIReducer,
});
