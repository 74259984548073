import { Component, ChangeEvent, FormEvent, SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import {
    Button,
    Divider,
    DropdownProps,
    CheckboxProps,
    Form,
    Grid,
    Image,
    InputOnChangeData,
    Label,
    Message,
    Segment,
} from "semantic-ui-react";
import ReactHtmlParser from "react-html-parser";

import OrganisationSettingLabel from "components/Administration/OrganisationManagment/OrganisationSettingLabel";
import OrganisationWorkingDaysDialog from "components/templates/dialog/OrganisationWorkingDaysDialog";
import SkinConditionsPathwayPopup from "components/templates/dialog/skinConditionsPathwayPopup";
import CustomRichTextEditor from "components/templates/Editor";

import { validOrganisationEmail } from "helpers/organisation";
import { NOT_VALID_EMAIL_ERROR } from "helpers/common";
import { scrollBottom } from "helpers/page";

import {
    AdministrationPages,
    OrganisationManagementSubpages,
    OrganisationPropertyIds,
    OrganisationPropertyHelpText,
    OrganisationPropertyLabels,
    UserManagementSubpages,
} from "model/administrationPages";
import { IOrganisation, OrganisationType } from "model/organisation";
import ViewEditMode from "model/viewEditMode";
import { NotificationRole } from "model/userRole";

import { history } from "App";
import { ADMINISTRATION } from "navigation/routes";

import organizationService from "services/organizationService";

interface IEditDetailsComponentProps {
    organisation: IOrganisation;
    updateOrganisation: (organisation: IOrganisation) => void;
    setOrganisatonWorkingDays: (workingDays: string[]) => void;
}

const MIN_POSITIVE_NUMBER = 1;
export const MAX_LOGOFILE_SIZE = 2097152;
const ALLOW_HOME_INITIATED_CASES_NAME = "organisationAllowHomeInitiatedCases";
const NON_SKIN_CANCER_ALLOWED_NAME = "organisationNonSkinCancerAllowed";

class EditDetailsComponent extends Component<IEditDetailsComponentProps, any> {
    private static checkPropertySubmitConfirmationInBackendResponse(
        propertyName: string,
        previousValue: any,
        currentValue: any,
        backendResponse: any,
        errors: any[]
    ) {
        const propertyValueChanged = previousValue !== currentValue;

        if (propertyValueChanged && currentValue && backendResponse[propertyName] !== currentValue) {
            errors.push({ message: `Incorrect ${propertyName} in backend response` });
        }
    }

    constructor(props: any) {
        super(props);
        const { organisation } = this.props;
        const { dermCredentials } = organisation;
        this.state = {
            errorDetails: [],
            mode: ViewEditMode.VIEW,
            organisation,
            organisationGuidance: organisation.guidance,
            organisationLesionsLimit: organisation.lesionsLimit,
            organisationMinimumAge: organisation.minimumAge || "-",
            organisationName: organisation.name,
            organisationParentName: organisation.parentName,
            organisationParentUuid: organisation.parentUuid,
            organisationPathway: organisation.pathway,
            organisationType: organisation.type,
            organisationWorkingDays: organisation.workingDays,
            organisationCodeName: organisation.codeName || "",
            organisationEmail: organisation.organisationEmail || "",
            emailInputError: false,
            organisationNonSkinCancerAllowed: organisation.nonSkinCancerAllowed || false,
            organisationAllowHomeInitiatedCases: organisation.allowHomeInitiatedCases || false,
            dermHost: dermCredentials ? dermCredentials.host : "-",
            dermLogin: dermCredentials ? dermCredentials.user : "-",
            logo: organisation.logo,
            logoUploadError: false,
            logoField: "",
            openWorkingDaysModal: false,
            changeCheckboxDialogVisible: false,
            changeCheckboxPropertyName: "",
            organisationMfaEnabled: organisation.mfaEnabled,
        };
    }

    public componentDidMount() {
        const { logo } = this.state;
        if (logo && !logo.removed) {
            this.fetchLogo();
        }
    }

    public componentDidUpdate(prevProps: IEditDetailsComponentProps) {
        this.setInitialState(prevProps);
    }

    private getLogoField() {
        const { logo, mode, logoUploadError, logoField, organisationName } = this.state;
        const isEditMode = mode === ViewEditMode.EDIT;
        return (
            <>
                <OrganisationSettingLabel label="Logo" />
                {logo?.src && (
                    <>
                        <Image alt={`Organisation logo for ${organisationName}`} src={logo.src} size="small" />
                        <Button onClick={this.onLogoRemove} className="remove-organisation-logo">
                            Remove
                        </Button>
                    </>
                )}

                {isEditMode && (
                    <>
                        <p className="upload-logo-text">Upload logo image</p>
                        <Form.Input
                            type="file"
                            value={logoField}
                            onChange={this.onLogoChange}
                            accept=".jpg, .jpeg, .png"
                        />
                        {logoUploadError && (
                            <Label basic color="red" pointing="above">
                                Submitted image size is too big (&gt; 2MB)
                            </Label>
                        )}
                    </>
                )}
            </>
        );
    }

    private getNameField() {
        const { mode, organisationName, errorDetails } = this.state;
        const error = errorDetails.find((err: any) => err.path === ".body.name");
        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <OrganisationSettingLabel label="Name" />
                    <p>{organisationName}</p>
                </>
            );
        }
        return (
            <Form.Input
                error={error}
                label="Name"
                type="text"
                name="organisationName"
                value={organisationName}
                onChange={this.onInputChange}
            />
        );
    }

    private getParentOrganisationField() {
        const { organisationParentUuid, organisationParentName } = this.state;
        return (
            <>
                <OrganisationSettingLabel label="Parent" />
                <p>
                    {organisationParentName
                        ? EditDetailsComponent.getOrganisationLink(organisationParentName, organisationParentUuid)
                        : "-"}
                </p>
            </>
        );
    }

    private static getOrganisationLink(organisationName: string, organisationUuid: string) {
        return (
            <Link
                to={`${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}/${OrganisationManagementSubpages.ORGANISATION_DETAILS}/${organisationUuid}`}
            >
                {organisationName}
            </Link>
        );
    }

    private getDermUserField() {
        const { dermLogin } = this.state;

        return (
            <>
                <OrganisationSettingLabel label="DERM user" />
                <p>{dermLogin}</p>
            </>
        );
    }

    private getDermHostField() {
        const { dermHost } = this.state;

        return (
            <>
                <OrganisationSettingLabel label="DERM host" />
                <p>{dermHost}</p>
            </>
        );
    }

    private getLesionsLimitField() {
        const { organisationLesionsLimit, mode, errorDetails } = this.state;
        const error = errorDetails.find((err: any) => err.path === ".body.lesionsLimit");
        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <OrganisationSettingLabel label={OrganisationPropertyLabels.LESION_LIMIT} />
                    <p>{organisationLesionsLimit}</p>
                </>
            );
        }
        return (
            <Form.Input
                id={OrganisationPropertyIds.LESION_LIMIT}
                error={error}
                label={OrganisationPropertyLabels.LESION_LIMIT}
                type="number"
                name="organisationLesionsLimit"
                value={organisationLesionsLimit}
                onChange={this.onInputChange}
            />
        );
    }

    private getTypeField() {
        const { organisationType, mode } = this.state;
        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <OrganisationSettingLabel label="Type" />
                    <p>{organisationType}</p>
                </>
            );
        }
        return (
            <Form.Select
                label="Type"
                placeholder="Select organisation type"
                value={organisationType}
                name="organisationType"
                onChange={this.onInputChange}
                options={Object.keys(OrganisationType).map((key) => ({
                    key,
                    text: OrganisationType[key],
                    value: key,
                }))}
            />
        );
    }

    private getPathwayConsentField() {
        const { mode, organisationPathway } = this.state;

        if (mode === ViewEditMode.VIEW) {
            return (
                <div>
                    <OrganisationSettingLabel label="Safety Net pathway context" />
                    <Segment>
                        <div>{ReactHtmlParser(organisationPathway)}</div>
                    </Segment>
                </div>
            );
        }
        return (
            <div>
                <div className="field">
                    <span>Safety Net pathway context</span>
                </div>
                <CustomRichTextEditor
                    value={organisationPathway}
                    onChange={this.onRichTextChange}
                    name="organisationPathway"
                />
            </div>
        );
    }

    private getAboutPageField() {
        const { mode, organisationGuidance } = this.state;
        if (mode === ViewEditMode.VIEW) {
            return (
                <div>
                    <OrganisationSettingLabel label="Guidance" />
                    <Segment>
                        <div>{ReactHtmlParser(organisationGuidance)}</div>
                    </Segment>
                </div>
            );
        }
        return (
            <div>
                <div className="field">
                    <span>Guidance</span>
                </div>
                <CustomRichTextEditor
                    value={organisationGuidance}
                    onChange={this.onRichTextChange}
                    name="organisationGuidance"
                />
            </div>
        );
    }

    private getMinimumAgeField() {
        const { errorDetails, mode, organisationMinimumAge } = this.state;
        const error = errorDetails.find((err: any) => err.path === ".body.minimumAge");
        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <OrganisationSettingLabel label={OrganisationPropertyLabels.MINIMUM_AGE} />
                    <p>{organisationMinimumAge}</p>
                </>
            );
        }
        return (
            <Form.Input
                id={OrganisationPropertyIds.MINIMUM_AGE}
                error={error}
                label={OrganisationPropertyLabels.MINIMUM_AGE}
                type="number"
                value={organisationMinimumAge}
                name="organisationMinimumAge"
                onChange={this.onInputChange}
                min={MIN_POSITIVE_NUMBER}
            />
        );
    }

    private getOrganisationEmailField() {
        const { errorDetails, mode, organisationEmail, emailInputError } = this.state;
        const error = errorDetails.find((err: any) => err.path === ".body.organisationEmail");

        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <OrganisationSettingLabel label="Organisation Email" />
                    <p>{organisationEmail || "-"}</p>
                </>
            );
        }
        return (
            <Form.Input
                error={error || emailInputError ? NOT_VALID_EMAIL_ERROR : null}
                label="Organisation Email"
                type="email"
                value={organisationEmail || ""}
                name="organisationEmail"
                onChange={this.onInputChange}
            />
        );
    }

    private getMFAField() {
        const { organisationMfaEnabled, mode } = this.state;

        return (
            <>
                <OrganisationSettingLabel
                    label={OrganisationPropertyLabels.MFA}
                    helpText={OrganisationPropertyHelpText.MFA}
                    htmlFor={OrganisationPropertyIds.ORGANISATION_MFA_EDIT}
                />
                <Form.Checkbox
                    disabled={mode === ViewEditMode.VIEW}
                    checked={organisationMfaEnabled}
                    toggle
                    id={OrganisationPropertyIds.ORGANISATION_MFA_EDIT}
                    onChange={this.onMFAChange}
                />
            </>
        );
    }

    private getCodeNameField() {
        const { errorDetails, mode, organisationCodeName } = this.state;
        const error = errorDetails.find((err: any) => err.path === ".body.codeName");
        if (mode === ViewEditMode.VIEW) {
            return (
                <>
                    <OrganisationSettingLabel label="Code Name" />
                    <p>{organisationCodeName || "-"}</p>
                </>
            );
        }

        return (
            <Form.Input
                error={error}
                label="Code Name"
                type="text"
                value={organisationCodeName || ""}
                name="organisationCodeName"
                onChange={this.onInputChange}
            />
        );
    }

    private getNonSkinCancerSwitch() {
        const { errorDetails, mode, organisationNonSkinCancerAllowed } = this.state;
        const { organisation } = this.props;
        const { parentUuid } = organisation;
        const isSuborganisation = Boolean(parentUuid);
        const error = errorDetails.find((err: any) => err.path === ".body.nonSkinCancer");

        if (isSuborganisation || mode === ViewEditMode.VIEW) {
            return (
                <>
                    <OrganisationSettingLabel label={OrganisationPropertyLabels.NON_SKIN_CANCER_ALLOWED_LABEL} />
                    <Form.Checkbox checked={organisationNonSkinCancerAllowed} disabled toggle />
                </>
            );
        }

        return (
            <>
                <OrganisationSettingLabel label={OrganisationPropertyLabels.NON_SKIN_CANCER_ALLOWED_LABEL} />
                <Form.Checkbox
                    error={error}
                    checked={organisationNonSkinCancerAllowed}
                    toggle
                    name={NON_SKIN_CANCER_ALLOWED_NAME}
                    onChange={this.showCheckboxDialog}
                />
            </>
        );
    }

    private getAllowHomeInitiatedCasesSwitch() {
        const { errorDetails, mode, organisationAllowHomeInitiatedCases } = this.state;
        const { organisation } = this.props;
        const { parentUuid } = organisation;
        const isSuborganisation = Boolean(parentUuid);
        const error = errorDetails.find((err: any) => err.path === ".body.allowHomeInitiatedCases");

        if (isSuborganisation || mode === ViewEditMode.VIEW) {
            return (
                <>
                    <OrganisationSettingLabel label={OrganisationPropertyLabels.ALLOW_HOME_INITIATED_CASES_LABEL} />
                    <Form.Checkbox checked={organisationAllowHomeInitiatedCases} disabled toggle />
                </>
            );
        }

        return (
            <>
                <OrganisationSettingLabel label={OrganisationPropertyLabels.ALLOW_HOME_INITIATED_CASES_LABEL} />
                <Form.Checkbox
                    error={error}
                    checked={organisationAllowHomeInitiatedCases}
                    toggle
                    name={ALLOW_HOME_INITIATED_CASES_NAME}
                    onChange={this.showCheckboxDialog}
                    disabled={false}
                />
            </>
        );
    }

    private getSubmitButton() {
        const { mode, submitting } = this.state;

        if (mode === ViewEditMode.EDIT) {
            return (
                <Grid.Column width={9}>
                    <Button loading={submitting} floated="right" onClick={this.submit}>
                        Update
                    </Button>
                    <Button loading={submitting} floated="right" onClick={this.cancel}>
                        Cancel
                    </Button>
                </Grid.Column>
            );
        }
        return <></>;
    }

    private onModeChangeClick = () => {
        const { mode } = this.state;
        const isCurrentViewMode = mode === ViewEditMode.VIEW;
        const updatedMode = isCurrentViewMode ? ViewEditMode.EDIT : ViewEditMode.VIEW;
        this.setState({ mode: updatedMode, errorDetails: [] });
    };

    private onLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { organisation } = this.state;

        const file = event.target.files[0];
        const fileSize = file.size;
        if (fileSize < MAX_LOGOFILE_SIZE) {
            if (file) {
                const { uuid } = organisation;
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    this.setState({ logo: { src: reader.result } });
                };

                const formData = new FormData();
                formData.append("image", file);
                formData.append("organisationUuid", uuid);
                organizationService.uploadOrganisationLogo(formData);
                this.setState({ logoUploadError: false });
            }
        } else {
            this.setState({ logoUploadError: true, logoField: "" });
        }
    };

    private onLogoRemove = () => {
        const { organisation } = this.state;
        const { uuid } = organisation.logo;

        organizationService.removeOrganisationLogo(uuid).then(() => this.setState({ logo: null }));
    };

    private onEnableDisableNonSkinCancer = (changeValue: boolean) => {
        const { organisationNonSkinCancerAllowed, organisationAllowHomeInitiatedCases, changeCheckboxPropertyName } =
            this.state;
        if (changeValue) {
            if (changeCheckboxPropertyName === ALLOW_HOME_INITIATED_CASES_NAME) {
                this.setState({ organisationAllowHomeInitiatedCases: !organisationAllowHomeInitiatedCases });
            }
            if (changeCheckboxPropertyName === NON_SKIN_CANCER_ALLOWED_NAME) {
                this.setState({ organisationNonSkinCancerAllowed: !organisationNonSkinCancerAllowed });
            }
        }
        this.setState({ changeCheckboxDialogVisible: false });
        scrollBottom();
    };

    private onInputChange = (
        event: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement> | SyntheticEvent<HTMLElement>,
        data: InputOnChangeData | DropdownProps
    ) => {
        const { name, value, type } = data;
        const { emailInputError } = this.state;
        let inputValue: string | number | boolean | (string | number | boolean)[];

        if (type === "number") {
            const inputValueNumber = parseInt(value as string, 10);
            inputValue = Number.isNaN(inputValueNumber) ? value : inputValueNumber;
        } else {
            inputValue = value;
        }

        this.setState({
            [name]: inputValue,
            emailInputError: name === NotificationRole.ORGANISATION_EMAIL ? false : emailInputError,
        });
    };

    private onRichTextChange = (value: string, name: string) => {
        this.setState({ [name]: value });
    };

    private onMFAChange = (): void => {
        this.setState((prevState) => ({ ...prevState, organisationMfaEnabled: !prevState.organisationMfaEnabled }));
    };

    private getErrorMessages() {
        const { errorDetails } = this.state;
        return errorDetails.map((detail: any) => (
            <Grid.Row key={detail.message}>
                <Grid.Column>
                    <Message key={detail.message} negative>
                        <p>{detail.message}</p>
                    </Message>
                </Grid.Column>
            </Grid.Row>
        ));
    }

    private getSetDermUser() {
        const { dermLogin, dermHost, submitDermError } = this.state;
        return (
            <Form>
                <span>
                    <Segment>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required
                                        type="text"
                                        label="user"
                                        value={dermLogin}
                                        placeholder="please fill login"
                                        name="dermLogin"
                                        onChange={this.onInputChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required
                                        type="password"
                                        label="password"
                                        placeholder="please fill password"
                                        name="dermPassword"
                                        onChange={this.onInputChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        required
                                        type="text"
                                        label="host"
                                        value={dermHost}
                                        placeholder="https://"
                                        name="dermHost"
                                        onChange={this.onInputChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {submitDermError
                                        ? submitDermError.map((error: any) => (
                                              <Message key={error.path} negative>
                                                  {error.message}
                                              </Message>
                                          ))
                                        : ""}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button onClick={this.submitSetDermUser}>Save</Button>
                                    <Button onClick={this.closeSetDermUserModal}>Cancel</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </span>
            </Form>
        );
    }

    private showCheckboxDialog = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        const fieldName = data.name;
        this.setState({ changeCheckboxDialogVisible: true, changeCheckboxPropertyName: fieldName });
    };

    private cancel = () => {
        const { organisation } = this.props;

        this.setState({
            organisationAllowHomeInitiatedCases: organisation.allowHomeInitiatedCases || false,
            organisationCodeName: organisation.codeName || "",
            organisationGuidance: organisation.guidance,
            organisationLesionsLimit: organisation.lesionsLimit,
            logo: organisation.logo,
            organisationMinimumAge: organisation.minimumAge || "-",
            organisationName: organisation.name,
            organisationNonSkinCancerAllowed: organisation.nonSkinCancerAllowed || false,
            organisationEmail: organisation.organisationEmail || "",
            organisationPathway: organisation.pathway,
            organisationType: organisation.type,
            organisationMfaEnabled: organisation.mfaEnabled || false,
        });
        this.onModeChangeClick();
    };

    private submit = () => {
        this.setState({ submitting: true });
        const { updateOrganisation, organisation } = this.props;
        const {
            organisationGuidance,
            organisationLesionsLimit,
            organisationName,
            organisationPathway,
            organisationType,
            organisationMinimumAge,
            organisationEmail,
            organisationCodeName,
            organisationNonSkinCancerAllowed,
            organisationAllowHomeInitiatedCases,
            organisationMfaEnabled,
        } = this.state;

        if (validOrganisationEmail(organisationEmail || "")) {
            organizationService
                .modifyOrganisation(organisation.uuid, {
                    guidance: organisationGuidance,
                    lesionsLimit: organisationLesionsLimit,
                    name: organisationName,
                    pathwayConsent: organisationPathway,
                    type: organisationType,
                    ...(organisationMinimumAge !== "-" ? { minimumAge: organisationMinimumAge || 0 } : {}),
                    organisationEmail,
                    codeName: organisationCodeName,
                    nonSkinCancerAllowed: organisationNonSkinCancerAllowed,
                    allowHomeInitiatedCases: organisationAllowHomeInitiatedCases,
                    mfaEnabled: organisationMfaEnabled,
                })
                .then((response) => {
                    const validationResult = this.validateResponse(response.organisation);
                    if (validationResult) {
                        this.setState({
                            errorDetails: validationResult,
                            submitting: false,
                        });
                    } else {
                        updateOrganisation(response.organisation);
                        this.setState({
                            errorDetails: [],
                            mode: ViewEditMode.VIEW,
                            submitting: false,
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        errorDetails: err.response?.data.errors,
                        submitting: false,
                    });
                });
        } else {
            this.setState({ submitting: false, emailInputError: true });
        }
    };

    private submitSetDermUser = () => {
        const { organisation, dermLogin, dermPassword, dermHost } = this.state;

        organizationService
            .setDermUser(organisation.uuid, dermLogin, dermPassword, dermHost)
            .then(() => {
                const locationUrl = `${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}/${OrganisationManagementSubpages.ORGANISATION_DETAILS}/${organisation.uuid}`;

                history.push(locationUrl);
            })
            .catch((err) => {
                this.setState({ submitDermError: err.response.data.errors });
            });
    };

    private closeSetDermUserModal = () => {
        this.setState({ openDermModal: false });
    };

    private openSetDermUserModal = () => {
        this.setState({ openDermModal: true });
    };

    private createSuborganisation = () => {
        const { organisation } = this.state;

        const suborganisationUrl = `${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}/${OrganisationManagementSubpages.CREATE_SUBORGANISATION}/${organisation.uuid}`;

        history.push(suborganisationUrl);
    };

    private handleCreateNewUserClicked = () => {
        const { organisation } = this.state;
        const { uuid } = organisation;
        const suborganisationUrl = `${ADMINISTRATION}/${AdministrationPages.USER_MANAGEMENT}/${UserManagementSubpages.CREATE_USER}/${uuid}`;

        history.push(suborganisationUrl);
    };

    private fetchLogo = () => {
        const { logo } = this.state;
        const { organisation } = this.props;
        const { uuid } = organisation;

        organizationService.getOrganisationLogo(uuid).then((result) => {
            this.setState({ logo: { ...logo, src: result } });
        });
    };

    private toggleOrganisationWorkingDaysDialog = () => {
        const { organisation } = this.props;
        const { openWorkingDaysModal } = this.state;

        this.setState({
            openWorkingDaysModal: !openWorkingDaysModal,
            ...(!openWorkingDaysModal ? { organisationWorkingDays: organisation.workingDays } : {}),
        });
    };

    private onSaveWorkingDays = () => {
        const { organisation, setOrganisatonWorkingDays } = this.props;
        const { organisationWorkingDays } = this.state;

        this.setState({ submitting: true });

        setOrganisatonWorkingDays(organisationWorkingDays);

        organizationService
            .updateOrganisationWorkingDays(organisation.uuid, organisationWorkingDays)
            .then(() => {
                this.setState({ submitting: false });
                this.toggleOrganisationWorkingDaysDialog();
            })
            .catch((err) => {
                this.setState({ errorDetails: err.response.data.errors });
            });
    };

    private setWorkingDays = (workingDays: string[]) => {
        this.setState({ organisationWorkingDays: workingDays });
    };

    private getOrganisationWorkingDaysDialog = () => {
        const { openWorkingDaysModal, errorDetails, organisationWorkingDays, submitting } = this.state;

        return (
            openWorkingDaysModal && (
                <OrganisationWorkingDaysDialog
                    showDialog={openWorkingDaysModal}
                    onSave={this.onSaveWorkingDays}
                    onCancel={this.toggleOrganisationWorkingDaysDialog}
                    workingDays={organisationWorkingDays}
                    setWorkingDays={this.setWorkingDays}
                    loading={submitting}
                    errors={errorDetails}
                />
            )
        );
    };

    private validateResponse = (response: any) => {
        const {
            organisationGuidance,
            organisationLesionsLimit,
            organisationName,
            organisationPathway,
            organisationType,
            organisationEmail,
            organisationCodeName,
            organisationNonSkinCancerAllowed,
            organisation,
            organisationMfaEnabled,
        } = this.state;

        const errors: any[] = [];

        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "guidance",
            organisation.guidance,
            organisationGuidance,
            response,
            errors
        );
        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "lesionsLimit",
            organisation.lesionsLimit,
            organisationLesionsLimit,
            response,
            errors
        );
        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "name",
            organisation.name,
            organisationName,
            response,
            errors
        );
        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "pathwayConsent",
            organisation.pathwayConsent,
            organisationPathway,
            response,
            errors
        );
        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "organisationType",
            organisation.type,
            organisationType,
            response,
            errors
        );
        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "organisationEmail",
            organisation.organisationEmail,
            organisationEmail,
            response,
            errors
        );
        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "codeName",
            organisation.codeName,
            organisationCodeName,
            response,
            errors
        );
        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "nonSkinCancerAllowed",
            organisation.nonSkinCancerAllowed,
            organisationNonSkinCancerAllowed,
            response,
            errors
        );
        EditDetailsComponent.checkPropertySubmitConfirmationInBackendResponse(
            "mfaEnabled",
            organisation.mfaEnabled,
            organisationMfaEnabled,
            response,
            errors
        );
        return errors.length ? errors : false;
    };

    private setInitialState(prevProps: IEditDetailsComponentProps) {
        const { organisation } = this.props;
        const {
            uuid,
            dermCredentials,
            lesionsLimit,
            minimumAge,
            name,
            parentName,
            parentUuid,
            type,
            logo,
            codeName,
            organisationEmail,
            mfaEnabled,
        } = organisation;

        if (prevProps.organisation.uuid !== uuid) {
            this.setState({
                dermHost: dermCredentials ? dermCredentials.host : "-",
                dermLogin: dermCredentials ? dermCredentials.user : "-",
                organisation,
                organisationLesionsLimit: lesionsLimit,
                organisationMinimumAge: minimumAge,
                organisationName: name,
                organisationParentName: parentName,
                organisationParentUuid: parentUuid,
                organisationType: type,
                organisationCodeName: codeName,
                organisationEmail,
                organisationMfaEnabled: mfaEnabled,
            });

            if (logo && !logo.removed) {
                this.fetchLogo();
            }
        }
    }

    public render() {
        const { organisation } = this.props;
        const {
            mode,
            changeCheckboxPropertyName,
            changeCheckboxDialogVisible,
            openDermModal,
            [changeCheckboxPropertyName]: doesCheckboxDialogOrgPropertyExist,
        } = this.state;
        const isEditMode = mode === ViewEditMode.VIEW;
        const isSuborganisation = !!organisation.parentUuid;
        const popupFieldName =
            changeCheckboxPropertyName === ALLOW_HOME_INITIATED_CASES_NAME
                ? OrganisationPropertyLabels.ALLOW_HOME_INITIATED_CASES_LABEL
                : OrganisationPropertyLabels.NON_SKIN_CANCER_ALLOWED_LABEL;

        return (
            <Segment>
                <Form>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                <Button floated="right" onClick={isEditMode ? this.onModeChangeClick : this.cancel}>
                                    {isEditMode ? "Edit" : "Cancel"}
                                </Button>
                                {!isSuborganisation && (
                                    <Button floated="right" onClick={this.openSetDermUserModal}>
                                        Set Derm user
                                    </Button>
                                )}
                                <Button floated="right" onClick={this.createSuborganisation}>
                                    Create suborganisation
                                </Button>
                                <Button floated="right" onClick={this.handleCreateNewUserClicked}>
                                    Create new user
                                </Button>
                                <Button floated="right" onClick={this.toggleOrganisationWorkingDaysDialog}>
                                    Working days
                                </Button>
                                <Popup open={openDermModal} modal closeOnDocumentClick={false}>
                                    {this.getSetDermUser()}
                                </Popup>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getLogoField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getNameField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getParentOrganisationField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getTypeField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getLesionsLimitField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getAboutPageField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getPathwayConsentField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getMinimumAgeField()}</Grid.Column>
                        </Grid.Row>
                        <Divider />
                        <Grid.Row>
                            <Grid.Column>{this.getDermUserField()}</Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column>{this.getDermHostField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getOrganisationEmailField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getMFAField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getAllowHomeInitiatedCasesSwitch()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getCodeNameField()}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>{this.getNonSkinCancerSwitch()}</Grid.Column>
                        </Grid.Row>
                        {this.getErrorMessages()}
                        {this.getSubmitButton()}
                        {this.getOrganisationWorkingDaysDialog()}
                    </Grid>
                </Form>
                <SkinConditionsPathwayPopup
                    fieldName={popupFieldName}
                    showDialog={changeCheckboxDialogVisible}
                    isPropertyEnabling={!doesCheckboxDialogOrgPropertyExist}
                    callback={this.onEnableDisableNonSkinCancer}
                />
            </Segment>
        );
    }
}

export default EditDetailsComponent;
