export interface IRequestStatus {
    isPending: boolean;
    isSuccess: boolean;
    error: string;
}

export const INITIAL_REQUEST_STATUS = { isPending: false, isSuccess: false, error: "" };

export enum ESEmpty {
    IS_EMPTY = "is:empty",
    NOT_EMPTY = "not:empty",
    LIST_IS_EMPTY = "list:empty",
    LIST_NOT_EMPTY = "listnot:empty",
}

export type ESExistenceCheck = ESEmpty.IS_EMPTY | ESEmpty.NOT_EMPTY;
export type ESArrayExistenceCheck = ESEmpty.LIST_IS_EMPTY | ESEmpty.LIST_NOT_EMPTY;

export const DATA_IS_NOT_AVAILABLE_TEXT = "N/A";

export const SUPPORT_EMAIL = "support@skinanalytics.co.uk";

export const PHONE_NUMBER = "020 8064 1967";
