import React, { FC, useState } from "react";
import { useSelector } from "react-redux";

import CustomButton from "components/templates/Button";
import ErrorMessage from "components/ErrorMessage";

import { formatTimeDOB } from "helpers/datetime";
import {
    ADDRESS_LINE_1_INPUT_TEXT,
    ADDRESS_LINE_2_INPUT_TEXT,
    ADDRESS_LINE_3_INPUT_TEXT,
    CITY_INPUT_TEXT,
    COUNTRY_INPUT_TEXT,
    DOB_INPUT_TEXT,
    GENDER_INPUT_TEXT,
    NAME_INPUT_TEXT,
    PHONE_INPUT_TEXT,
    POSTCODE_INPUT_TEXT,
    SURNAME_INPUT_TEXT,
} from "helpers/definedPersonalData";

import { ReviewFields } from "model/registration";
import { IPatientEdit } from "model/patientEdit";

import getValidateError from "redux/selectors/validateError";
import getFormErrors from "redux/selectors/formError";

interface IReviewList {
    form: IPatientEdit;
    onEdit: () => void;
    onSave: () => void;
}

const reviewFields = [
    NAME_INPUT_TEXT,
    SURNAME_INPUT_TEXT,
    ADDRESS_LINE_1_INPUT_TEXT,
    ADDRESS_LINE_2_INPUT_TEXT,
    ADDRESS_LINE_3_INPUT_TEXT,
    CITY_INPUT_TEXT,
    COUNTRY_INPUT_TEXT,
    POSTCODE_INPUT_TEXT,
    GENDER_INPUT_TEXT,
    DOB_INPUT_TEXT,
    PHONE_INPUT_TEXT,
];

const blockingSubmitErrors = ["Case has been assigned to another patient"];

const ReviewList: FC<IReviewList> = ({ form, onEdit, onSave }) => {
    const validateErrors = useSelector(getValidateError);
    const formErrors = useSelector(getFormErrors);

    const isFormContainBlockingError = formErrors?.errors?.some((error) =>
        blockingSubmitErrors.includes(error.message)
    );

    const [canSubmitPatient, setCanSubmitPatient] = useState<boolean>(!isFormContainBlockingError);

    const handleSave = () => {
        setCanSubmitPatient(false);
        onSave();
    };

    return (
        <>
            {reviewFields
                .filter((item: string) => !!form[item])
                .map((item: string) => (
                    <div className="remote-model__review-row" key={item}>
                        <p>
                            <b>{ReviewFields[item]}</b>
                        </p>
                        <p>{item === DOB_INPUT_TEXT ? formatTimeDOB(form[item] as string) : form[item]}</p>
                    </div>
                ))}
            <div className="buttons-wrapper">
                <CustomButton type="button" variant="empty-dark" action={onEdit} text="Edit" />
                <CustomButton
                    type="button"
                    variant="filled"
                    action={handleSave}
                    text="Save"
                    disabled={!canSubmitPatient}
                />
            </div>

            {isFormContainBlockingError && (
                <ErrorMessage errors="An error occurred. Please click on the Skin Analytics logo in the top left corner of your screen, then find your case in the list and click on Continue to retry." />
            )}

            {!isFormContainBlockingError && (validateErrors.load || formErrors.errors?.length) ? (
                <ErrorMessage errors={validateErrors?.load?.message || formErrors.errors[0]?.message} />
            ) : null}
        </>
    );
};

export default ReviewList;
