import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogContent } from "@material-ui/core";

import { history } from "App";

import { IOrganisation, IOrganisationData } from "model/organisation";

import * as ROUTES from "navigation/routes";

import { getOrganisation } from "redux/selectors/data";

import * as authorisationService from "services/authorizationService";

import ModalDialog from "./dialog/ModalDialog";

interface IOrganisationSwitchModalProps {
    newOrganisation: IOrganisationData;
    showModal: boolean;
    handleModalClose: () => void;
}

const OrganisationSwitchModal: FC<IOrganisationSwitchModalProps> = ({
    newOrganisation,
    showModal,
    handleModalClose,
}) => {
    const currentOrganisation: IOrganisation = useSelector(getOrganisation);
    const dispatch = useDispatch();

    const handleContinue = async (): Promise<void> => {
        const { organisationUuid } = newOrganisation;

        try {
            history.push(ROUTES.HOME);
            dispatch(authorisationService.switchUserOrganisation(organisationUuid));
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <ModalDialog
            title="Are you sure you want to switch to a different organisation?"
            open={showModal}
            iconSrc="/images/icons/icon-home-in-circle.svg"
            onClose={handleModalClose}
            maxWidth="sm"
            disableBackdropClick
            buttons={[
                {
                    onClick: handleContinue,
                    text: "Continue",
                    colour: "primary",
                    disabled: !currentOrganisation || !newOrganisation,
                },
                {
                    onClick: handleModalClose,
                    text: "Back",
                },
            ]}
        >
            <DialogContent className="organisation-switch-wrapper">
                <p>
                    From
                    <br />
                    <span className="organisation-name">{currentOrganisation ? currentOrganisation.name : ""}</span>
                    <br />
                    To
                    <br />
                    <span className="organisation-name">{newOrganisation ? newOrganisation.organisationName : ""}</span>
                </p>
            </DialogContent>
        </ModalDialog>
    );
};

export default OrganisationSwitchModal;
