import { Component } from "react";

import FitzpatrickQuestionsSection from "components/CaseDescription/Questions/FitzpatrickQuestionSection";
import QuestionsSection from "components/CaseDescription/Questions/QuestionsSection";

import { getCaseQuestionDefinition } from "helpers/questionDefinition";
import { formatTimeWithHours } from "helpers/datetime";

import { ICase, ISkinToneAnswer } from "model/case";
import CaseQuestionDefinitionType from "model/caseQuestionDefinitionType";
import { CaseViewMode } from "model/caseViewMode";
import { IMedicalHistoryQuestion } from "model/organisation";

import { mapFitzpatrickQuestionAnswer } from "helpers/fitzpatrick";

interface ICaseQuestionsProps {
    mode: CaseViewMode;
    caseObject: ICase;
    updateSkinToneClassification: (result: ISkinToneAnswer[]) => void;
}

interface ICaseQuestionsState {
    medicalQuestionDefinition: IMedicalHistoryQuestion[];
    biopsyQuestionDefinition: IMedicalHistoryQuestion[];
}

class CaseQuestions extends Component<ICaseQuestionsProps, ICaseQuestionsState> {
    constructor(props: any) {
        super(props);
        this.state = { medicalQuestionDefinition: [], biopsyQuestionDefinition: [] };
    }

    public componentDidMount() {
        const { caseObject } = this.props;
        const { nonSkinCancer: isNonSkinCancerCase, remote: isRemoteFlow, organisationUuid } = caseObject;

        const caseQuestionType = isNonSkinCancerCase
            ? CaseQuestionDefinitionType.NSC_MEDICAL
            : CaseQuestionDefinitionType.MEDICAL;

        getCaseQuestionDefinition(caseQuestionType, organisationUuid).then((response) => {
            this.setState({ medicalQuestionDefinition: response });
        });

        if (!isNonSkinCancerCase && !isRemoteFlow) {
            getCaseQuestionDefinition(CaseQuestionDefinitionType.BIOPSY, organisationUuid).then((response) => {
                this.setState({ biopsyQuestionDefinition: response });
            });
        }
    }

    private getQuestions() {
        const { caseObject, updateSkinToneClassification } = this.props;
        const { patient } = caseObject;
        const { medicalQuestionDefinition, biopsyQuestionDefinition } = this.state;
        const { createdByName, creationDate, medicalHistory } = patient;
        const caseQuestionsCreateDetails = `Captured by ${createdByName} from ${
            caseObject.organisationName
        } on ${formatTimeWithHours(creationDate)}`;
        const isNonSkinCancerCase = Boolean(caseObject.nonSkinCancer);
        const showBiopsyQuestions = Boolean(biopsyQuestionDefinition?.length);

        const fitzpatrickQuestionAnswer = mapFitzpatrickQuestionAnswer({
            medicalHistoryQuestions: medicalQuestionDefinition,
            medicalHistoryAnswers: medicalHistory,
        })?.questionAnswer;

        if (isNonSkinCancerCase) {
            return (
                <QuestionsSection
                    title="Medical History"
                    questionsDefinition={medicalQuestionDefinition}
                    questions={medicalHistory}
                    caseQuestionsCreateDetails={caseQuestionsCreateDetails}
                />
            );
        }

        if (!isNonSkinCancerCase) {
            return (
                <>
                    {fitzpatrickQuestionAnswer ? (
                        <FitzpatrickQuestionsSection
                            title="Fitzpatrick Skin Type (FST)"
                            caseObject={caseObject}
                            questionDefinitions={medicalQuestionDefinition}
                            questions={medicalHistory}
                            updateSkinToneClassification={updateSkinToneClassification}
                        />
                    ) : null}
                    <QuestionsSection
                        title="Medical History"
                        questionsDefinition={medicalQuestionDefinition}
                        questions={medicalHistory}
                        caseQuestionsCreateDetails={caseQuestionsCreateDetails}
                    />
                    {showBiopsyQuestions && (
                        <QuestionsSection
                            title="Biopsy Questions"
                            questionsDefinition={biopsyQuestionDefinition}
                            questions={medicalHistory}
                            caseQuestionsCreateDetails={caseQuestionsCreateDetails}
                        />
                    )}
                </>
            );
        }

        return null;
    }

    public render() {
        const { caseObject } = this.props;
        const { patient } = caseObject;

        if (!patient) {
            return null;
        }

        const isCaseHistory = caseObject.patient.medicalHistory?.length;

        return (isCaseHistory && this.getQuestions()) || <></>;
    }
}

export default CaseQuestions;
