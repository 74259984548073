import { IMedicalHistoryAnswers } from "model/assessment";
import { IHistoryAnswerType, IHistoryQuestion, IHistoryQuestionType } from "model/organisation";
import { IQuestionaryError } from "model/questionaryError";

interface IExclusionQuestions {
    answerType: IHistoryAnswerType;
    options: any[];
    question: string;
    removed: boolean;
    required: boolean;
    uuid: string;
}

const generateCheckboxAnswer = (answers: any) => {
    const result = {};
    answers.forEach((answer: any) => {
        result[answer] = true;
    });

    return result;
};

export const generateAnswers: any = (data: any[], questions: any[], exclusions?: any[]) => {
    const answers = {};
    if (data) {
        data.forEach((historyQuestion: any) => {
            const { uuid, answers: questionAnswers, additionalInformation } = historyQuestion;
            const currentQuestion = questions.find((question) => question.uuid === uuid);
            if (currentQuestion) {
                const { answerType } = currentQuestion;
                const value =
                    answerType === IHistoryAnswerType.CHECKBOX || answerType === IHistoryAnswerType.OPTIONS
                        ? generateCheckboxAnswer(questionAnswers)
                        : questionAnswers[0];
                answers[uuid] = {
                    ...(additionalInformation ? { additionalInformation } : {}),
                    type: answerType,
                    value,
                };
            }
        });
        if (exclusions) {
            const notRemovedExclusions = exclusions.filter((exclusion) => !exclusion.removed);
            notRemovedExclusions.forEach((exclusion) => {
                answers[exclusion.uuid] = {
                    exclusion: exclusion.exclusion,
                    value: true,
                };
            });
        }
    }

    return answers;
};

export const mapStateToAnswers = (answers: any) => {
    const answerTab: IMedicalHistoryAnswers[] = [];
    const extraTab: IMedicalHistoryAnswers[] = [];
    if (answers) {
        Object.keys(answers).forEach((key) => {
            const currentAnswer = answers[key];
            const isExclusion = !!currentAnswer.exclusion;
            if (isExclusion) {
                const { value, exclusion } = currentAnswer;
                if (value === true) {
                    extraTab.push({
                        exclusion,
                        exclusionUuid: key,
                        removed: false,
                        answerType: IHistoryAnswerType.CHECKBOX,
                        uuid: key,
                    });
                }
            } else {
                if (
                    currentAnswer.type === IHistoryAnswerType.TEXT ||
                    currentAnswer.type === IHistoryAnswerType.OPTION
                ) {
                    const { value, additionalInformation, type } = currentAnswer;
                    answerTab.push({
                        answers: [value],
                        questionUuid: key,
                        ...(additionalInformation ? { additionalInformation } : {}),
                        removed: false,
                        answerType: type,
                        uuid: key,
                    });
                }
                if (
                    currentAnswer.type === IHistoryAnswerType.CHECKBOX ||
                    currentAnswer.type === IHistoryAnswerType.OPTIONS
                ) {
                    const { value, additionalInformation, type } = currentAnswer;
                    const selectedAnswers: string[] = [];
                    Object.keys(value).forEach((answer) => {
                        if (value[answer]) {
                            selectedAnswers.push(answer);
                        }
                    });

                    answerTab.push({
                        answers: selectedAnswers,
                        questionUuid: key,
                        ...(additionalInformation ? { additionalInformation } : {}),
                        removed: false,
                        answerType: type,
                        uuid: key,
                    });
                }
            }
        });
        return { mainData: answerTab, extraData: extraTab };
    }
    return undefined;
};

export const validateAnswersCommon = (
    answers: any,
    questionSets: {
        questions: any[];
        label?: string;
    }[],
    errors: IQuestionaryError,
    updateErrors: (errors: IQuestionaryError, question?: IHistoryQuestion) => void,
    showError?: boolean,
    currentQuestion?: IHistoryQuestion
): boolean => {
    const validAnswers = Object.keys(answers);
    const currentAnswers = validAnswers.map((key: string) => ({
        questionUuid: key,
        value: answers[key].value,
    }));
    const questions: any[] = [];
    questionSets.forEach((questionSet) => questions.push(...questionSet.questions));
    const requiredIds = questions
        .filter(({ required }) => required)
        .map(({ uuid, questionType, answerType }) => ({
            answerType,
            questionType,
            uuid,
        }));
    const questionsWithoutAnswers = requiredIds
        .filter(({ uuid, questionType, answerType }) => {
            if (questionType === IHistoryQuestionType.EXCLUSION) {
                const exclusionQuestion: IExclusionQuestions = questions.find(
                    (question: IExclusionQuestions) => question.uuid === uuid
                );
                const optionsUuid = exclusionQuestion.options.map((option) => option.uuid);
                const found = optionsUuid.some((optionUuid) => {
                    const currentAnswer = currentAnswers.find((answer) => answer.questionUuid === optionUuid);
                    return currentAnswer?.value;
                });

                return !found;
            }
            return !currentAnswers.find((answer: any) => {
                let hasValue = true;
                const isCheckbox =
                    answerType === IHistoryAnswerType.CHECKBOX || answerType === IHistoryAnswerType.OPTIONS;
                if (isCheckbox) {
                    const values = answer.value ? Object.values(answer.value) : [];
                    hasValue = values.some((value) => value);
                }
                return answer.questionUuid === uuid && answer.value && hasValue;
            });
        })
        .map(({ uuid }) => uuid);
    updateErrors(
        {
            ...errors,
            isInitial: false,
            list: questionsWithoutAnswers,
            ...(showError ? { showError } : {}),
        },
        currentQuestion
    );
    const isValid = questionsWithoutAnswers.length === 0;

    return isValid;
};
