import { FC } from "react";
import { Icon, Popup } from "semantic-ui-react";

import { IModifiedInheritedProperties, IOwnProperties } from "model/organisation";

interface IOrganisationInheritedWarning {
    organisationInheritedProperties: IModifiedInheritedProperties | IOwnProperties;
    isProperties?: boolean;
}

const OrganisationInheritedWarning: FC<IOrganisationInheritedWarning> = ({
    organisationInheritedProperties,
    isProperties,
}) => {
    let inheritedInfo;
    const warningIcon = <Icon className="inherited-warning icon popup" name="exclamation triangle" />;

    const getText = (actionText: string, properties: string[]) => (
        <div>
            <p>{actionText} properties by Administrator:</p>
            <div>
                <ul>
                    {properties.map((item: string) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );

    if (isProperties) {
        const { added, removed, edited = [] } = organisationInheritedProperties as IOwnProperties;
        const areAddedProperties = Boolean(added.length);
        const areRemovedProperties = Boolean(removed.length);
        const areEditedProperties = Boolean(edited.length);
        const isCopiedNotEdited = !areAddedProperties && !areRemovedProperties && !areEditedProperties;

        inheritedInfo = (
            <>
                {areRemovedProperties && getText("Removed", removed)}
                {areAddedProperties && getText("Added", added)}
                {areEditedProperties && getText("Edited", edited)}
                {isCopiedNotEdited && (
                    <div>
                        <p>The settings set copied from parent organisation but not edited yet</p>
                    </div>
                )}
            </>
        );
    } else {
        inheritedInfo = (
            <>
                <p>Data inherited from parent organisation has been edited by Administrator</p>
                <div>
                    Data in:
                    <ul>
                        {Object.entries(organisationInheritedProperties).map(([key, value]: [string, unknown]) => (
                            <li key={key}>{value as string}</li>
                        ))}
                    </ul>
                </div>
            </>
        );
    }

    return (
        <Popup
            content={inheritedInfo}
            trigger={Object.entries(organisationInheritedProperties).length ? warningIcon : null}
        />
    );
};

OrganisationInheritedWarning.defaultProps = {
    isProperties: false,
};

export default OrganisationInheritedWarning;
