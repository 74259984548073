import { Grid } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";

import "scss/Dialog.scss";

interface IImportUsersDialog {
    showDialog: boolean;
    callback: (isOpenPopup: boolean) => void;
    onClose: () => void;
    errors?: string;
}

const ImportUsersDialog = (props: IImportUsersDialog) => {
    const { showDialog, callback, onClose, errors } = props;

    const title = "Import user list";

    const onConfirm = () => {
        onClose();
        window.location.reload();
    };

    const popupBody = (
        <Grid className="grid-dialog-body">
            <Grid.Row>
                <Grid.Column>
                    {errors ? (
                        errors.split(";").map((errorMsg) => <p>{errorMsg}</p>)
                    ) : (
                        <p>All users imported successfully</p>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        <ModalDialog
            title={title}
            open={showDialog}
            iconSrc={errors ? "/images/icons/icon-warning-triangle.svg" : "/images/icons/icon-check-large.svg"}
            onClose={callback}
            buttons={[
                {
                    colour: "primary",
                    onClick: onConfirm,
                    text: "Close",
                },
            ]}
        >
            {popupBody}
        </ModalDialog>
    );
};

ImportUsersDialog.defaultProps = {
    errors: "",
};

export default ImportUsersDialog;
