import { FC, useContext } from "react";
import { Grid } from "semantic-ui-react";

import AllocationConfigurationContext from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigProvider";
import ModalDialog from "components/templates/dialog/ModalDialog";

import "scss/Dialog.scss";

const UrgentReferModal: FC = () => {
    const {
        allocationState: { showUrgentReferModal },
        allocationDispatchers: { toggleUrgentReferModal, toggleUrgentRefer },
    } = useContext(AllocationConfigurationContext);

    const handleModalClose = () => {
        toggleUrgentReferModal();
    };

    const handleUrgentReferToggle = () => {
        toggleUrgentRefer();
    };

    if (!showUrgentReferModal) {
        return null;
    }

    return (
        <ModalDialog
            title="Stop urgent refers"
            open={showUrgentReferModal}
            iconSrc="/images/icons/icon-warning-red-bg.svg"
            onClose={handleModalClose}
            maxWidth="sm"
            disableBackdropClick
            buttons={[
                {
                    onClick: handleUrgentReferToggle,
                    text: "Continue",
                },
                {
                    onClick: handleModalClose,
                    text: "Cancel",
                },
            ]}
        >
            <Grid className="grid-dialog-body">
                <Grid.Row>
                    <Grid.Column>
                        Switching off the &ldquo;Urgent Refer&ldquo; will restore the case allocation configuration to
                        the default settings, do you still want to continue?
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </ModalDialog>
    );
};

export default UrgentReferModal;
