import { FixedQuestion } from "model/fixedQuestion";

export class Config {
    public static getCasesMsUrl() {
        return process.env.REACT_APP_CASES_MS;
    }

    public static getReaderMsUrl() {
        return process.env.REACT_APP_READER_MS;
    }

    public static getNotificationMsUrl() {
        return process.env.REACT_APP_NOTIFICATIONS_MS;
    }

    public static getRegistrationMsUrl() {
        return process.env.REACT_APP_REGISTRATION_MS;
    }

    public static getReportsMsUrl() {
        return process.env.REACT_APP_REPORTS_MS;
    }

    public static getUsersMsUrl() {
        return process.env.REACT_APP_USERS_MS;
    }

    public static getStudyMsUrl() {
        return process.env.REACT_APP_STUDY_MS;
    }

    public static getLesionsMsUrl() {
        return process.env.REACT_APP_LESIONS_MS;
    }

    public static getPatientsMsUrl() {
        return process.env.REACT_APP_PATIENTS_MS;
    }

    public static getImagesMsUrl() {
        return process.env.REACT_APP_IMAGES_MS;
    }

    public static getOrganisationMs() {
        return process.env.REACT_APP_ORGANISATIONS_MS;
    }

    public static getReviewMs() {
        return process.env.REACT_APP_REVIEW_MS;
    }

    public static getDermMs() {
        return process.env.REACT_APP_DERM_MS;
    }

    public static getServicesUrl() {
        return process.env.REACT_APP_SERVICES_URL;
    }

    public static getFeatureFlags() {
        return {
            [FixedQuestion.FITZPATRICK_SKIN_TYPE]:
                process.env.REACT_APP_DISABLE_EDIT_FITZPATRICK_SKIN_TYPE_AUDIT === "true",
        };
    }
}

export default new Config();
