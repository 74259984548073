import { connect } from "react-redux";

import ImageCapture from "components/ImageCapture/ImageCapture";

import * as caseService from "services/caseService";

const mapStateToProps = (state: any) => ({
    assessment: state.assessment,
    authToken: state.auth.authToken,
    currentLesion: state.assessment.currentLesion,
    organisation: state.data.organisation,
    refreshToken: state.auth.refreshToken,
    wizard: state.assessment.captureImageWizard,
});

const mapDispatchToProps = {
    getCase: caseService.getCaseForAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCapture);
