import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Container, Divider } from "semantic-ui-react";
import ReactHtmlParser from "html-react-parser";

import useAutoLogout from "hooks/useAutoLogout";

import { getOrganisation } from "redux/selectors/data";

const GuidancePage: FC = () => {
    useAutoLogout();
    const organisation = useSelector(getOrganisation);

    const { name, guidance } = organisation;
    const guidanceText = ReactHtmlParser(guidance || "");

    return (
        <Container className="ui segment wizzard-container">
            <h1>Guidance</h1>
            <Divider />
            <div className="guidance-page-wrapper">
                <h3>Skin Analytics for {name}</h3>
                <p>
                    In this page you will be able to find more information on the pathway defined for your Organisation
                </p>
                <Divider />
                <div>{guidanceText}</div>
            </div>
        </Container>
    );
};

export default GuidancePage;
