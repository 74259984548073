import { FC } from "react";

import CustomButton from "components/templates/Button";
import SupportEmailLink from "components/templates/SupportEmailLink";

const ServicesBlockedMessage: FC<any> = () => (
    <div className="services_blocked_message">
        <h2>This site can&apos;t be reached</h2>
        <p>
            This might be because your computer or network is protected by a firewall or proxy.
            <br /> Try using a personal (non-work) device and connecting to a home network.
            <br /> If you are a member of the NHS staff, please contact your local IT team.
            <br /> If the problem persists after following the steps above, please contact&nbsp;
            <SupportEmailLink />
        </p>
        <CustomButton
            type="button"
            variant="filled"
            action={() => window.location.reload()}
            text="Try again"
            dataTestId="refresh-try-again-button"
        />
    </div>
);

export default ServicesBlockedMessage;
