import { MFAVerificationChannel } from "model/mfa";

import types from "redux/actions/types";

const INITIAL_STATE = {
    organisation: null,
    organisationError: null,
    organisationLoading: false,
    showOrganisationSelection: false,
    displayCaseListSnackbar: false,
    pendingRequest: false,
    user: null,
    userError: null,
    userLoading: false,
};

const dataReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case types.GET_USER_ME_REQUEST:
            return {
                ...state,
                user: null,
                userError: null,
                userLoading: true,
            };
        case types.GET_USER_ME_SUCCESS:
            return {
                ...state,
                user: action.payload,
                userError: null,
                userLoading: false,
            };
        case types.GET_USER_ME_FAIL:
            return {
                ...state,
                userError: action.payload,
                userLoading: false,
            };
        case types.SET_USER_DATA: {
            return {
                ...state,
                user: { ...(state.user || {}), ...action.payload },
            };
        }
        case types.SHOW_ORGANISATION_SELECTION:
            return {
                ...state,
                showOrganisationSelection: true,
                displayCaseListSnackbar: action.payload,
            };
        case types.HIDE_ORGANISATION_SELECTION:
            return {
                ...state,
                showOrganisationSelection: false,
            };
        case types.GET_MY_ORGANISATION_REQUEST:
            return {
                ...state,
                organisation: null,
                organisationError: null,
                organisationLoading: true,
            };
        case types.GET_MY_ORGANISATION_SUCCESS:
            return {
                ...state,
                organisation: action.payload,
                organisationError: null,
                organisationLoading: false,
            };
        case types.GET_MY_ORGANISATION_FAIL:
            return {
                ...state,
                organisationError: action.payload,
                organisationLoading: false,
            };
        case types.HIDE_CASE_LIST_SNACKBAR:
            return {
                ...state,
                displayCaseListSnackbar: false,
            };
        case types.CLEAR_DATA:
            return {
                ...state,
                organisation: null,
                organisationError: null,
                organisationLoading: false,
                showOrganisationSelection: false,
                displayCaseListSnackbar: false,
                pendingRequest: false,
                user: null,
                userError: null,
                userLoading: false,
            };
        case types.SET_PENDING_REQUEST:
            return {
                ...state,
                pendingRequest: action.payload,
            };
        case types.UPDATE_USER_PREFERENCES: {
            const { user = {} } = state;
            const { preferences = {} } = { ...user };
            const { displayedColumns = [] } = { ...preferences };
            const currentIndex = displayedColumns.findIndex(
                (column: any) => column.viewName === action.payload.viewName
            );
            let updatedDisplayColumns: any[] = [...displayedColumns];
            if (currentIndex === -1) {
                updatedDisplayColumns = [...updatedDisplayColumns, action.payload];
            } else {
                updatedDisplayColumns.splice(currentIndex, 1, action.payload);
            }

            return {
                ...state,
                user: {
                    ...user,
                    preferences: {
                        ...preferences,
                        displayedColumns: updatedDisplayColumns,
                    },
                },
            };
        }
        case types.UPDATE_HIDE_GTE_POPUP: {
            const { user = {} } = state;
            const { preferences = {} } = { ...user };
            return {
                ...state,
                user: {
                    ...user,
                    preferences: {
                        ...preferences,
                        hideGeneralDermatologyExceptions: true,
                    },
                },
            };
        }

        case types.HIDE_USER_MODAL_PREFERENCE: {
            const { user = {} } = state;
            const { preferences = {} } = { ...user };
            const { modalPreferences } = preferences;
            const modalIdentifier = action.payload;
            const foundPreference = modalPreferences.find(
                (preference) => preference.modalIdentifier === modalIdentifier
            );
            foundPreference.hide = true;
            return {
                ...state,
                user: {
                    ...user,
                    preferences: {
                        ...preferences,
                        modalPreferences: [...modalPreferences, foundPreference],
                    },
                },
            };
        }

        case types.MFA_VERIFICATION_SENT: {
            if (action.payload.channel === MFAVerificationChannel.EMAIL) {
                return state;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    mobileNumber: action.payload.mobileNumber,
                },
            };
        }

        default:
            return state;
    }
};

export default dataReducer;
