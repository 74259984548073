import React, { Component } from "react";
import { Divider, Form, Grid, Label, Menu, Pagination, PaginationProps, Table } from "semantic-ui-react";

import LoadingSpinner from "components/templates/LoadingSpinner";
import OrganisationInheritedWarning from "components/templates/OrganisationInheritedWarning";
import TableRow from "components/templates/TableRow";

import { getModifiedInheritedProperties } from "helpers/organisation";

import {
    AdministrationPages,
    OrganisationManagementSubpages,
    ReviewManagementSubpages,
} from "model/administrationPages";
import { IModifiedInheritedProperties, IOrganisation } from "model/organisation";
import { OrganisationTypeFilter } from "model/organisationType";

import { ADMINISTRATION } from "navigation/routes";

import organisationService from "services/organizationService";

export enum IOrganisationTableMode {
    ORGANISATION_LIST = "organisation",
    REVIEW_LIST = "review-options",
}

interface IOrganisationTableProps {
    mode: IOrganisationTableMode;
}

interface IOrganisationTableState {
    activePage: number;
    nameFilter: string;
    organisationList: any[];
    typeFilter: string;
    totalOrganisations: number;
    totalPages: number;
}

class OrganisationTable extends Component<IOrganisationTableProps, IOrganisationTableState> {
    constructor(props: any) {
        super(props);
        this.state = {
            activePage: 0,
            nameFilter: "",
            organisationList: [],
            totalOrganisations: 0,
            totalPages: 0,
            typeFilter: "",
        };
    }

    public async componentDidMount() {
        await this.loadPage(0, undefined, undefined);
    }

    private getResults() {
        const { organisationList, totalOrganisations, activePage, totalPages } = this.state;

        if (organisationList) {
            return (
                <div>
                    <Divider />
                    <Table selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Organisation name</Table.HeaderCell>
                                <Table.HeaderCell>Parent organisation</Table.HeaderCell>
                                <Table.HeaderCell>Creation date</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{this.getOrganisationListRows(organisationList)}</Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={7} textAlign="left">
                                    <Label className="organisation-table-label">
                                        <Label.Detail>Total Organisations: {totalOrganisations}</Label.Detail>
                                    </Label>
                                    <Menu floated="right" pagination>
                                        <Pagination
                                            activePage={activePage + 1}
                                            boundaryRange={1}
                                            onPageChange={this.handlePaginationChange}
                                            size="small"
                                            siblingRange={1}
                                            totalPages={totalPages}
                                            firstItem={null}
                                            lastItem={null}
                                        />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
            );
        }
        return <LoadingSpinner />;
    }

    private handlePaginationChange = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        { activePage }: PaginationProps
    ) => {
        const { nameFilter, typeFilter } = this.state;
        this.loadPage(Number(activePage) - 1, nameFilter, typeFilter);
    };

    private getOrganisationListRows(organisationList: IOrganisation[]) {
        return organisationList.map((organisation: IOrganisation) => {
            const { uuid } = organisation;
            const organisationInheritedProperties: IModifiedInheritedProperties = getModifiedInheritedProperties(
                organisation,
                false
            );

            return (
                <TableRow key={uuid} id={uuid} link={this.getOrganisationLink(organisation.uuid)}>
                    <Table.Cell>
                        {organisation.name}
                        <OrganisationInheritedWarning
                            organisationInheritedProperties={organisationInheritedProperties}
                        />
                    </Table.Cell>
                    <Table.Cell>{organisation.parentName}</Table.Cell>
                    <Table.Cell>{new Date(organisation.creationDate).toLocaleString()}</Table.Cell>
                    <Table.Cell>{organisation.type}</Table.Cell>
                </TableRow>
            );
        });
    }

    private getOrganisationLink(uuid: string) {
        const { mode } = this.props;

        if (mode === IOrganisationTableMode.ORGANISATION_LIST) {
            return `${ADMINISTRATION}/${AdministrationPages.ORGANISATION_MANAGEMENT}/${OrganisationManagementSubpages.ORGANISATION_DETAILS}/${uuid}`;
        }

        if (mode === IOrganisationTableMode.REVIEW_LIST) {
            return `${ADMINISTRATION}/${AdministrationPages.REVIEW_MANAGEMENT}/${ReviewManagementSubpages.REVIEV_DETAILS}/${uuid}`;
        }

        return undefined;
    }

    private updateOrganisationNameFilter = (event: any, obj: any) => {
        const { typeFilter } = this.state;

        this.setState({ nameFilter: obj.value });
        this.loadPage(0, obj.value, typeFilter);
    };

    private updateOrganisationTypeFilter = (event: any, obj: any) => {
        const { nameFilter } = this.state;

        const value = obj.value === "ALL" ? undefined : obj.value;

        this.setState({ typeFilter: value });
        this.loadPage(0, nameFilter, value);
    };

    private async loadPage(
        actualPage: number,
        organisationNameFilter: string | undefined,
        organisationTypeFilter: string | undefined
    ): Promise<void> {
        try {
            const filters: string[] = [];

            if (organisationTypeFilter) {
                filters.push(`type:${organisationTypeFilter}`);
            }

            const result = await organisationService.getAllOrganisations(
                actualPage * 10,
                filters,
                organisationNameFilter
            );

            this.setState({
                activePage: actualPage,
                organisationList: result.data,
                totalOrganisations: result.total,
                totalPages: Math.ceil(result.total / 10),
            });
        } catch (e) {
            console.error(e);
        }
    }

    public render() {
        const { mode } = this.props;
        const { nameFilter } = this.state;

        return (
            <Form>
                <Grid columns={4}>
                    <Grid.Row>
                        {mode === IOrganisationTableMode.ORGANISATION_LIST ? (
                            <Grid.Column width={4}>
                                <Form.Input
                                    type="text"
                                    value={nameFilter}
                                    label="Organisation Name"
                                    id="organisationNameFilter"
                                    placeholder="Search by organisation name..."
                                    onChange={this.updateOrganisationNameFilter}
                                />
                            </Grid.Column>
                        ) : (
                            <></>
                        )}
                        <Grid.Column width={5}>
                            <Form.Select
                                label="Organisation Type"
                                id="organisationTypeFilter"
                                placeholder="Search by organisation type..."
                                onChange={this.updateOrganisationTypeFilter}
                                options={Object.keys(OrganisationTypeFilter).map((key) => ({
                                    key,
                                    text: OrganisationTypeFilter[key],
                                    value: key,
                                }))}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.getResults()}
            </Form>
        );
    }
}

export default OrganisationTable;
