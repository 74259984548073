import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";

import AssessmentFlowConfirmModal from "components/templates/AssessmentFlowConfirmModal";
import AssessmentFlowHeader from "components/templates/AssessmentFlowHeader";
import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import PatientBannerHeader from "components/templates/PatientBannerHeader";
import PatientDetailsIntegrationGrid from "components/templates/PatientDetailsIntegrationGrid";

import PatientDetailsEdit from "components/PatientDetails/PatientDetailsEdit";
import PatientDetailsValidation from "components/PatientDetails/PatientDetailsValidation";

import { getIsMergedRecordField } from "helpers/definedPersonalData";
import { getInitialPatientData } from "helpers/patientUtils";
import isPASIntegration from "helpers/PASintegration";

import { IAssessment } from "model/assessment";
import { ICase } from "model/case";
import { IDefinedPersonalData, IOrganisation } from "model/organisation";
import PatientDetailsIntegration from "model/integrations";
import { UserRole } from "model/userRole";
import { IUser } from "model/user";

import logoutTimeoutService from "services/logoutTimeoutService";

import { LESION_LOCATOR, MEDICAL_HISTORY, NEW_ASSESSMENT, PATIENT_LOOK_UP, PATIENT_SEARCH } from "navigation/routes";

import "scss/InputField.scss";

interface IPatientDetails {
    assessment: IAssessment;
    case: ICase;
    organisation: IOrganisation;
    pendingRequest: boolean;
    createPatient: (uuid: string, patientData: any) => void;
    updatePatient: (patientUuid: string, patientData: any) => void;
    location?: any;
    user: IUser;
}

interface IPatientDetailsState {
    editMode: boolean;
    edited: boolean;
    stateErrors: any;
    errors: {
        list: any[];
        show: false;
    };
    next: boolean;
    patient: any;
    personalDataFields: any[];
    showModal: boolean;
    formValidated: boolean;
}

class PatientDetails extends Component<IPatientDetails, IPatientDetailsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            editMode: false,
            edited: false,
            errors: {
                list: [],
                show: false,
            },
            stateErrors: {},
            next: false,
            patient: {},
            personalDataFields: [],
            showModal: false,
            formValidated: false,
        };
    }

    public componentDidMount() {
        logoutTimeoutService.stopCount();
        const {
            organisation,
            assessment: { patient },
        } = this.props;
        const { definedPersonalData = [], patientDetailsIntegrationType } = organisation;
        const isIntegrationPatient = Boolean(patient?.integrationInfo);

        let personalDataFields: IDefinedPersonalData[] = definedPersonalData
            .filter(
                ({ removed, isRequestedAtManualCreation }) =>
                    !removed &&
                    (patientDetailsIntegrationType && !isIntegrationPatient ? isRequestedAtManualCreation : true)
            )
            .sort((obj1: IDefinedPersonalData, obj2: IDefinedPersonalData) => obj1.displayOrder - obj2.displayOrder);

        if (patientDetailsIntegrationType === PatientDetailsIntegration.PDS) {
            personalDataFields = personalDataFields.filter((field) => !!field.isRequestedAtManualCreation);
        }
        let initialPatientData = getInitialPatientData(personalDataFields, patient);
        const { state: PDSSearchData } = this.props.location;
        if (PDSSearchData && Object.keys(PDSSearchData.values).length) {
            initialPatientData = { ...initialPatientData, ...PDSSearchData.values };
        }

        this.setState({ personalDataFields, patient: initialPatientData });
    }

    public changeFieldValue = (fieldName: string, fieldValue: string | number | Date) => {
        const { organisation } = this.props;
        const { patient, personalDataFields, formValidated, stateErrors } = this.state;
        patient[fieldName] = fieldValue;
        if (formValidated) {
            const validationResult = PatientDetailsValidation.validate(organisation, personalDataFields, patient);
            if (validationResult.isValid) {
                this.setState({
                    patient,
                    edited: true,
                    errors: {
                        ...stateErrors,
                        list: validationResult.errorsList,
                        showModal: false,
                        show: true,
                    },
                });
            } else {
                this.setState({
                    errors: {
                        ...stateErrors,
                        list: validationResult.errorsList,
                        showModal: false,
                        show: true,
                    },
                });
            }
        } else {
            this.setState({ patient, edited: true });
        }
    };

    public submit = async () => {
        const { assessment, organisation } = this.props;
        const { editMode, edited, stateErrors, personalDataFields, patient } = this.state;

        if (assessment.patient === undefined) {
            const validationResult = PatientDetailsValidation.validate(organisation, personalDataFields, patient);

            if (validationResult.isValid) {
                const { pendingRequest } = this.props;

                if (!pendingRequest) {
                    const { createPatient, case: stateCase } = this.props;

                    createPatient(stateCase.uuid, patient);
                }
            } else {
                this.setState({
                    formValidated: true,
                    errors: {
                        ...stateErrors,
                        list: validationResult.errorsList,
                        showModal: false,
                        show: true,
                    },
                });
            }
        }
        if (editMode && edited) {
            this.setState({ showModal: true });
        } else {
            this.moveForward();
        }
    };

    private editPatientInfo = () => {
        const { organisation } = this.props;
        const { editMode, showModal, edited, personalDataFields, patient, stateErrors } = this.state;

        if (!editMode) {
            this.setInitialPatientData();
        } else {
            const validationResult = PatientDetailsValidation.validate(organisation, personalDataFields, patient);

            if (validationResult.isValid) {
                const { pendingRequest, updatePatient } = this.props;

                if (!pendingRequest) {
                    const { assessment } = this.props;

                    if (edited) {
                        updatePatient(assessment.patient.uuid, patient);
                    }

                    if (showModal) {
                        this.moveForward();
                    }

                    this.setState({ editMode: false, edited: false });
                }
            } else {
                this.setState({
                    formValidated: true,
                    errors: {
                        ...stateErrors,
                        list: validationResult.errorsList,
                        showModal: false,
                        show: true,
                    },
                });
            }
        }
    };

    private closeModal = () => {
        this.setState({ showModal: false });
    };

    private moveForward = () => {
        this.setState({
            next: true,
        });
    };

    private resetEdit = () => {
        this.setInitialPatientData();
        this.setState({ editMode: false });
    };

    private setInitialPatientData = () => {
        const { personalDataFields } = this.state;
        const {
            assessment: { patient },
        } = this.props;
        const initialPatientData = getInitialPatientData(personalDataFields, patient);

        this.setState({ editMode: true, patient: initialPatientData, edited: false });
    };

    public render() {
        const { organisation, pendingRequest, assessment, user } = this.props;
        const { next, editMode, personalDataFields, errors, showModal, patient: patientState } = this.state;
        const { patient } = assessment;
        const { integrationInfo, patientData } = { ...patient };
        const { medicalHistoryQuestions, patientDetailsIntegrationType } = organisation;
        const isPatientCreated = Boolean(patient);
        const isPASIntegratedOrganisation = patientDetailsIntegrationType === PatientDetailsIntegration.PAS;
        const isPatientCreatedWithIntegration = Boolean(integrationInfo);
        const isPatientCreatedWithPAS =
            isPatientCreatedWithIntegration && isPASIntegration(integrationInfo?.integrationName);
        const isPatientCreatedWithPDS =
            isPatientCreatedWithIntegration && integrationInfo?.integrationName === PatientDetailsIntegration.PDS;
        const isPDSOrg = organisation?.patientDetailsIntegrationType === PatientDetailsIntegration.PDS;
        const isAdminUserOrGreater = user.role === UserRole.ADMIN || user.role === UserRole.SUPERADMIN;
        const isHomeInitiatedCase = assessment?.case?.homeInitiated;
        const backButtonLink =
            !isPASIntegratedOrganisation || isPatientCreatedWithIntegration || isHomeInitiatedCase
                ? NEW_ASSESSMENT
                : PATIENT_SEARCH;
        const editFunction = isPatientCreatedWithPAS ? null : this.editPatientInfo;
        const isMergedRecord = getIsMergedRecordField(patientData);
        const showVerifyText = isPDSOrg && isHomeInitiatedCase && !isPatientCreatedWithPDS;
        const nextButtonText = showVerifyText ? "Verify >" : "Next >";

        if (patient && next) {
            if (assessment?.case?.homeInitiated && isPDSOrg) {
                return <Redirect to={PATIENT_LOOK_UP} />;
            }
            if (medicalHistoryQuestions && medicalHistoryQuestions.filter((question) => !question.removed).length > 0) {
                return <Redirect to={MEDICAL_HISTORY} />;
            }
            return <Redirect to={LESION_LOCATOR} />;
        }

        const isNonZeroNhsNumber = patientState?.nhs !== "000 000 0000";
        const disableContinueButton =
            pendingRequest || (!isNonZeroNhsNumber && assessment?.case?.homeInitiated && isPDSOrg);
        return (
            <Container className="ui segment wizzard-container">
                {isPatientCreatedWithPAS ||
                    (isPatientCreatedWithPDS && isAdminUserOrGreater && (
                        <PatientBannerHeader integrationData={integrationInfo} isMergedRecord={isMergedRecord} />
                    ))}
                <AssessmentFlowHeader
                    title="Patient Details"
                    isEditMode={editMode}
                    disabled={!isPatientCreated}
                    edit={editFunction}
                    reset={this.resetEdit}
                />
                <Divider />

                {isPatientCreatedWithPAS ? (
                    <PatientDetailsIntegrationGrid patient={patientState} />
                ) : (
                    <PatientDetailsEdit
                        patient={patientState}
                        editMode={editMode}
                        personalDataFields={personalDataFields}
                        errors={errors}
                        organisation={organisation}
                        changeFieldValue={this.changeFieldValue}
                        patientCreated={Boolean(patient?.uuid)}
                        isAtHomeFlow={assessment?.case?.homeInitiated}
                        userRole={user?.role}
                    />
                )}

                <div className="vertical-space">
                    <Divider />
                    <ButtonContainer
                        button1={
                            <CustomButton
                                variant="empty"
                                type="link"
                                to={backButtonLink}
                                text="< Back"
                                size="small"
                                disabled={pendingRequest || editMode}
                            />
                        }
                        button2={
                            <CustomButton
                                variant="filled"
                                disabled={disableContinueButton}
                                loading={pendingRequest}
                                type="submit"
                                action={this.submit}
                                text={nextButtonText}
                            />
                        }
                    />
                </div>
                <AssessmentFlowConfirmModal
                    show={showModal}
                    close={this.closeModal}
                    goAhead={this.moveForward}
                    save={this.editPatientInfo}
                />
            </Container>
        );
    }
}

export default PatientDetails;
