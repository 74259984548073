import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Divider, Form, Grid, Message, Segment } from "semantic-ui-react";

import { AdministrationPages, StudyManagmentSubpages } from "model/administrationPages";
import { UserRole } from "model/userRole";

import { ADMINISTRATION } from "navigation/routes";

import studyService from "services/studyService";
import userService from "services/userService";

import "scss/Container.scss";

interface ICreateStudyComponentState {
    errorDetails: any[];
    isError: boolean;
    submitting: boolean;
    studyUuid: string;
    newDevice: any;
}

interface ICreateStudyComponentProps {
    params: {
        uuid: string;
    };
}

class CreateDeviceScreen extends Component<ICreateStudyComponentProps, ICreateStudyComponentState> {
    constructor(props: any) {
        super(props);

        const { params } = this.props;

        this.state = {
            errorDetails: [],
            isError: false,
            newDevice: {
                studyUuid: params.uuid,
                useForAi: false,
            },
            studyUuid: params.uuid,
            submitting: false,
        };
    }

    private getErrorMessages() {
        const { errorDetails } = this.state;

        return errorDetails.map((detail: any) => (
            <Message key={detail.message} negative>
                <p>{detail.message}</p>
            </Message>
        ));
    }

    private onFieldChange = (event: any, obj: any) => {
        const { newDevice } = this.state;

        const fieldName = obj.id;
        const fieldValue = obj.type === "checkbox" ? obj.checked : obj.value;

        newDevice[fieldName] = fieldValue;

        this.setState({ newDevice });
    };

    private submit = () => {
        const { newDevice, studyUuid } = this.state;

        this.setState({ submitting: true });

        if (newDevice) {
            studyService
                .createDevice(newDevice)
                .then(() => {
                    this.setState({ submitting: false });
                    const newDeviceUrl = `${ADMINISTRATION}/${AdministrationPages.STUDY_MANAGEMENT}/${StudyManagmentSubpages.STUDY_DETAILS}/${studyUuid}`;
                    window.location.href = newDeviceUrl;
                })
                .catch((err) => {
                    if (err.response) {
                        this.setState({
                            submitting: false,
                            isError: false,
                            errorDetails: err.response.data.errors,
                        });
                    } else {
                        this.setState({
                            submitting: false,
                            isError: false,
                            errorDetails: [{ message: err.message }],
                        });
                    }
                });
        }
        this.setState({ submitting: false });
    };

    public render() {
        const { isError, newDevice, submitting, studyUuid } = this.state;

        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        return (
            <Segment>
                <h2>Create new device</h2>
                <Divider />
                <div>
                    <h5 hidden={!isError} className="error-message">
                        <p>Please fill all required fields</p>
                    </h5>
                    <div className={isError ? "container error" : "contaner"}>
                        <Form>
                            <Segment>
                                <Grid columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label="Manufacturer name"
                                                type="text"
                                                id="manufacturer"
                                                required
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label="Model name"
                                                type="text"
                                                id="model"
                                                required
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label="Model version"
                                                type="text"
                                                id="version"
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label="OS version"
                                                type="text"
                                                id="osVersion"
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Checkbox
                                                label="Used for AI"
                                                id="useForAi"
                                                checked={newDevice.useForAi}
                                                required
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label="Info text"
                                                type="text"
                                                id="infoText"
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label="Serial number"
                                                type="text"
                                                id="serialNumber"
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form.Input
                                                label="Label"
                                                type="text"
                                                id="label"
                                                onChange={this.onFieldChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>{this.getErrorMessages()}</Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={9}>
                                            <Button loading={submitting} floated="right" onClick={this.submit}>
                                                {" "}
                                                Create{" "}
                                            </Button>
                                            <Link
                                                to={`${ADMINISTRATION}/${AdministrationPages.STUDY_MANAGEMENT}/${StudyManagmentSubpages.STUDY_DETAILS}/${studyUuid}`}
                                            >
                                                <Button floated="right">Back</Button>
                                            </Link>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form>
                    </div>
                </div>
            </Segment>
        );
    }
}

export default CreateDeviceScreen;
