import { FC, useEffect, SyntheticEvent } from "react";
import { Form, Grid, DropdownProps, TextAreaProps } from "semantic-ui-react";

import { onChangeWhitespaceRemover, onPasteWhitespaceRemover } from "helpers/field";

import {
    LesionLocation as LesionLocationModel,
    LesionLocationNsc as LesionLocationModelNsc,
} from "model/lesionLocation";
import PresentingComplaint from "model/presentingComplaint";
import { IQuestionaryError } from "model/questionaryError";

import CustomSelect from "components/SelectDropdown/Select";

interface ILesionLocation {
    disabled: boolean;
    onChange: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps | TextAreaProps) => void;
    values: any;
    errors: IQuestionaryError;
    updateErrors: (errors: IQuestionaryError) => void;
    isNonSkinCancerFlow?: boolean;
    isRemoteModel?: boolean;
}

interface IErrorObject {
    content: string;
    pointing: string;
}

const LesionLocation: FC<ILesionLocation> = ({
    disabled,
    onChange,
    values,
    errors,
    updateErrors,
    isNonSkinCancerFlow,
    isRemoteModel,
}) => {
    const { location } = values;

    useEffect(() => {
        updateErrors({ ...errors, formError: !location });
    }, [location]);

    const showError = errors.formError && errors.showError;

    const errorObject: IErrorObject = {
        content: "Please select location on body",
        pointing: isRemoteModel ? "left" : "above",
    };

    const error = showError ? errorObject : false;

    const lesionDictionary = isNonSkinCancerFlow ? LesionLocationModelNsc : LesionLocationModel;
    const locationOptions = Object.keys(lesionDictionary).map((key) => ({
        key,
        text: lesionDictionary[key],
        value: key,
    }));

    const presentingComplaintOptions = Object.keys(PresentingComplaint).map((key) => ({
        key,
        text: PresentingComplaint[key],
        value: key,
    }));

    const locationLabel = isRemoteModel ? "Where on the body is the lesion?" : "Location on body";
    const locationFreeTextLabel = isRemoteModel
        ? "Is there anything you would like to mention about the lesion location?"
        : "";

    return (
        <Grid columns={2}>
            {isNonSkinCancerFlow && (
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Form>
                            <Form.Select
                                error={error}
                                className="lesion-select"
                                label="Presenting complaint"
                                disabled={disabled}
                                type="options"
                                id="presentingComplaint"
                                placeholder="Select presenting complaint"
                                onChange={onChange}
                                name="presentingComplaint"
                                value={values.presentingComplaint}
                                options={presentingComplaintOptions}
                                required
                                selectOnBlur={false}
                            />
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form>
                            <Form.TextArea
                                className="short-note-lesion-nsc-complaint"
                                disabled={disabled}
                                type="text"
                                id="complaintDescription"
                                name="complaintDescription"
                                placeholder="Complaint description"
                                onChange={onChangeWhitespaceRemover(onChange, true)}
                                value={values.complaintDescription}
                            />
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row className={isRemoteModel ? "remote-model-location-questions" : ""}>
                <Grid.Column width={4}>
                    <Form>
                        <CustomSelect
                            id="location"
                            labelText={locationLabel}
                            placeholder="Select location on body"
                            options={locationOptions}
                            onChange={(e) => onChange(e, { id: "location", value: e.target.value })}
                            value={values.location}
                            required
                            disabled={disabled}
                            dropdownMaxHeight={400}
                            inputError={(error as IErrorObject)?.content}
                        />
                    </Form>
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form>
                        <Form.TextArea
                            className="short-note-lesion-nsc-location"
                            disabled={disabled}
                            type="text"
                            id="locationNotes"
                            name="locationNotes"
                            placeholder="Location notes"
                            onChange={onChange}
                            onPaste={onPasteWhitespaceRemover(onChange, true, { type: "text", id: "locationNotes" })}
                            value={values.locationNotes}
                            label={locationFreeTextLabel}
                        />
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

LesionLocation.defaultProps = {
    isNonSkinCancerFlow: false,
    isRemoteModel: false,
};

export default LesionLocation;
