import React, { Component, ChangeEvent } from "react";
import { Container, Divider, Form, Grid, Input, InputOnChangeData, Message, Segment } from "semantic-ui-react";

import PasswordMatching from "components/PasswordMatching";
import CustomButton from "components/templates/Button";

import { isErrorOfTypePasswordOnDenyList } from "helpers/error";
import { blacklistErrorMessage } from "helpers/password";

import HttpStatus from "model/httpStatus";
import { UserRole } from "model/userRole";

import logoutTimeoutService from "services/logoutTimeoutService";
import userService from "services/userService";

interface IChangePasswordProps {
    changePassword: (oldpassword: string, newpassword: string, newpasswordconf: string) => Promise<void>;
    changePasswordCompleted?: any;
    formErrors?: any;
    changePasswordReset: () => void;
    clearFormErrors: () => void;
}

interface IChangePasswordState {
    confirmNewPassword: string;
    error: any;
    isNewPasswordError: boolean;
    newPassword: string;
    oldPassword: string;
    visible: boolean;
    isPending: boolean;
    currentUserRole: UserRole;
}

class ChangePassword extends Component<IChangePasswordProps, IChangePasswordState> {
    constructor(props: any) {
        super(props);

        const { formErrors } = this.props;

        this.state = {
            confirmNewPassword: "",
            error: formErrors,
            isNewPasswordError: true,
            isPending: false,
            newPassword: "",
            oldPassword: "",
            visible: true,
            currentUserRole: undefined,
        };
    }

    public componentDidMount() {
        logoutTimeoutService.startCount();
        const { changePasswordReset, clearFormErrors } = this.props;
        changePasswordReset();
        clearFormErrors();
        this.getUserRole();
    }

    private getUserRole() {
        const userRole = userService.getLoggedUserRole();
        this.setState({ currentUserRole: userRole });
    }

    private renderMessage = () => {
        const { changePasswordCompleted, formErrors } = this.props;
        const { visible } = this.state;

        if (changePasswordCompleted) {
            if (changePasswordCompleted.status === HttpStatus.OK) {
                return <Message positive>Password has been changed successfully!</Message>;
            }
        }

        if (visible && formErrors.errors) {
            const isPasswordDenied = isErrorOfTypePasswordOnDenyList(formErrors.errors);

            const messageText = isPasswordDenied
                ? blacklistErrorMessage
                : "There was a problem resetting your password. Please check that you have entered your details correctly";

            return <Message negative>{messageText}</Message>;
        }

        return <></>;
    };

    private handleClick = () => {
        logoutTimeoutService.startCount();
    };

    private makeButtonDisabled = (state: boolean) => {
        const { isNewPasswordError } = this.state;

        if (isNewPasswordError !== state) {
            this.setState({ isNewPasswordError: state });
        }
    };

    private changePassword = async () => {
        this.setState({ isPending: true });
        const { oldPassword, newPassword, confirmNewPassword } = this.state;
        const { changePassword } = this.props;
        await changePassword(oldPassword, newPassword, confirmNewPassword);
        this.setState({ isPending: false });
    };

    private setNewValue = (event: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const { name } = data;
        this.setState((prevState) => ({ ...prevState, [name]: data.value }));
    };

    public render() {
        const { oldPassword, newPassword, confirmNewPassword, isNewPasswordError, isPending, currentUserRole } =
            this.state;

        const isResetButtonDisabled = isNewPasswordError || !oldPassword;

        return (
            <Container onClick={this.handleClick}>
                <Segment>
                    <h1>Change Password </h1>
                    <Divider />
                    {this.renderMessage()}
                    <Form>
                        <Form.Field>
                            <h5> Current Password</h5>
                            <Input
                                className="field login"
                                type="password"
                                placeholder="Enter your Current Password"
                                name="oldPassword"
                                value={oldPassword}
                                onChange={this.setNewValue}
                            />
                        </Form.Field>
                        <Grid>
                            <Grid.Row columns={2} className="reset-password-grid">
                                <Grid.Column>
                                    <h5> Enter your new password </h5>
                                    <Form.Field>
                                        <Input
                                            className="field login"
                                            type="password"
                                            placeholder="Enter your New password"
                                            name="newPassword"
                                            value={newPassword}
                                            onChange={this.setNewValue}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Input
                                            className="field login"
                                            type="password"
                                            placeholder="Confirm your New password"
                                            name="confirmNewPassword"
                                            value={confirmNewPassword}
                                            onChange={this.setNewValue}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <PasswordMatching
                                        newPassword={newPassword}
                                        confirmNewPassword={confirmNewPassword}
                                        makeButtonDisabled={this.makeButtonDisabled}
                                        userRole={currentUserRole}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <CustomButton
                            type="submit"
                            variant="filled"
                            text="Change Password"
                            disabled={isResetButtonDisabled}
                            loading={isPending}
                            action={this.changePassword}
                        />
                    </Form>
                    <CustomButton type="link" to="/home" variant="empty" text="Home" />
                </Segment>
            </Container>
        );
    }
}

export default ChangePassword;
