import { IReviewDiagnosis } from "model/organisation";

const isManagementOutcomeUsed = (reviewDiagnoses: IReviewDiagnosis[], outcomeUuid: string): boolean => {
    if (!reviewDiagnoses.length) {
        return false;
    }

    const activeReviewDiagnoses = reviewDiagnoses.filter((diagnosis) => !diagnosis.removed);

    if (!activeReviewDiagnoses.length) {
        return false;
    }

    let outcomeIsUsed = false;

    for (let i = 0; i < activeReviewDiagnoses.length; i += 1) {
        const diagnosis = activeReviewDiagnoses[i];
        outcomeIsUsed = diagnosis.managementOptions.some(
            (option) => !option.removed && option.managementUuid === outcomeUuid
        );
        if (outcomeIsUsed) {
            break;
        }
    }

    return outcomeIsUsed;
};

export default isManagementOutcomeUsed;
