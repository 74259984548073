import { FC, useState, useContext, useEffect, ChangeEvent, SyntheticEvent, KeyboardEvent } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
    DropdownItemProps,
    Form,
    Grid,
    Header,
    InputProps,
    SelectProps,
    DropdownProps,
    Message,
} from "semantic-ui-react";

import { CaseListContext } from "contextProviders/CaseListProvider";

import InputDateField from "components/InputDateField";

import preventWheelEvent from "helpers/input";
import createDropdownOptions from "helpers/semanticUi";
import { formatDateForForm, formatMomentDateForForm } from "helpers/datetime";

import { CaseListColumns, CaseListFilters as CaseListFiltersType, CaseListTabs } from "model/caseList";
import { RemoteTimelineDictionary, RemoteTimelineFilterOptions } from "model/remoteCaseTimeline";
import { CaseStatusDictionary, CaseStatusFilter } from "model/caseStatus";
import { IDictionaryItem } from "model/dictionaryItem";
import { ReviewType, ReviewTypePrefixText } from "model/reviewType";
import { UserRole } from "model/userRole";
import { UserStatus } from "model/userStatus";
import { ALL_ITEM } from "model/filters";
import { pathwayFilters, PathwayValues } from "model/assessmentPathway";

import { getOrganisation } from "redux/selectors/data";

import organizationService from "services/organizationService";
import userService from "services/userService";

import "scss/CaseList.scss";

interface ICaseListFilters {
    filters: string[];
}

interface ILocation {
    removed: boolean;
    name: string;
    uuid: string;
}

interface IManagementOptions {
    removed: boolean;
    management: string;
    uuid: string;
    reviewType: string;
}

export interface IDermatologist {
    uuid: string;
    name: string;
    surname: string;
    organisationName: string;
    organisationuuid?: string;
    additionalorganisationuuids?: string[];
    linkedUserUuid?: string;
}

const TODAY = moment();

const CaseListFilters: FC<ICaseListFilters> = ({ filters }) => {
    const [organisationsList, setOrganisationsList] = useState<IDictionaryItem[]>([]);
    const [locationList, setLocationList] = useState<DropdownItemProps[]>([]);
    const [managementOutcomesList, setManagementOutcomesList] = useState<DropdownItemProps[]>([]);
    const [pathwayList, setPathwayList] = useState<DropdownItemProps[]>([]);
    const [dermatologistsList, setDermatologistsList] = useState<DropdownItemProps[]>([]);
    const { currentTabCases, setFilter, activeTab, resetFilters, isKitDeliveryStatusTab } = useContext(CaseListContext);
    const currentOrganisation = useSelector(getOrganisation);
    const { filters: filterValues } = currentTabCases;
    const {
        dateFrom,
        dateTo,
        dermatologist,
        hospitalId,
        nhs,
        id,
        organisation,
        remoteTimeline,
        status,
        locationUuid,
        location = "",
        birth,
        managementOutcome,
        recommendation,
        pathway = "",
    } = {
        ...filterValues,
    };
    const isDataLoaded = currentTabCases?.data && !currentTabCases?.isPending;
    const isCurrentUserSuperAdmin = userService.checkUserHasRole([UserRole.SUPERADMIN, UserRole.SA_ADMIN]);
    const canCurrentUserSeeCaseIdFilter = userService.checkUserHasRole([
        UserRole.SUPERADMIN,
        UserRole.SA_ADMIN,
        UserRole.ADMIN,
        UserRole.DERMATOLOGIST,
        UserRole.CLINICAL_VIEWER,
        UserRole.CLINICIAN,
    ]);
    const isCurrentUserAdmin = userService.checkUserHasRole([UserRole.ADMIN]);
    const isCurrentUserDermatologist = userService.checkUserHasRole([UserRole.DERMATOLOGIST]);

    useEffect(() => {
        const generateManagementOptions = (managementOutcomes: IManagementOptions[]) => {
            const outcomes = managementOutcomes
                ?.filter((item: IManagementOptions) => !item.removed)
                .map((managementOutcomeItem: IManagementOptions) => {
                    const { uuid, management, reviewType } = managementOutcomeItem;
                    const isSafetyNet = reviewType === ReviewType.SAFETY_NET;
                    const optionText = `${
                        isSafetyNet ? ReviewTypePrefixText.SAFETY_NET : ReviewTypePrefixText.CLIENT
                    } ${management}`;
                    return {
                        key: uuid,
                        text: optionText,
                        value: optionText,
                    };
                })
                .sort((a, b) => {
                    if (a.text < b.text) {
                        return -1;
                    }
                    if (a.text > b.text) {
                        return 1;
                    }
                    return 0;
                });
            return outcomes ? [ALL_ITEM, ...outcomes] : [ALL_ITEM];
        };

        const generateDermatologistsOptions = (dermatologists: IDermatologist[]) => {
            const optionsDerm = dermatologists.map((dermatologistItem: IDermatologist) => {
                const { uuid, name, surname, organisationName } = dermatologistItem;
                return {
                    key: uuid,
                    text: `${surname.toUpperCase()}, ${name} (${organisationName})`,
                    value: uuid,
                };
            });
            return [ALL_ITEM, ...optionsDerm];
        };

        if (filters.includes(CaseListFiltersType.ORGANISATION)) {
            organizationService.getAllOrganisationsDictionary().then((result) => {
                setOrganisationsList(result);
            });
        }
        if (filters.includes(CaseListFiltersType.MANAGEMENT_OUTCOME)) {
            if (!isCurrentUserSuperAdmin) {
                if (isCurrentUserAdmin) {
                    organizationService.getManagementOutcomes().then((result) => {
                        const options = generateManagementOptions(result);
                        setManagementOutcomesList(options);
                    });
                } else {
                    const { reviewManagementOptions } = currentOrganisation;
                    const options = generateManagementOptions(reviewManagementOptions);
                    setManagementOutcomesList(options);
                }
            }
        }
        if (filters.includes(CaseListFiltersType.PATHWAY)) {
            if (isCurrentUserSuperAdmin || isCurrentUserDermatologist) {
                setPathwayList(pathwayFilters);
            } else {
                const pathwayFiltersWithoutRemoteModel = pathwayFilters.filter(
                    (pathwayFilter) => pathwayFilter.key !== PathwayValues.REMOTE_MODEL
                );
                setPathwayList(pathwayFiltersWithoutRemoteModel);
            }
        }
        if (isCurrentUserSuperAdmin && filters.includes(CaseListFiltersType.DERMATOLOGIST)) {
            userService
                .getAllUsers({
                    offset: 0,
                    filters: [`role:${UserRole.DERMATOLOGIST};status:${UserStatus.ACTIVE}`],
                    limit: 10000,
                })
                .then((result) => {
                    const options = generateDermatologistsOptions(result.data);
                    setDermatologistsList(options);
                });
        } else {
            if (filters.includes(CaseListFiltersType.DERMATOLOGIST)) {
                userService
                    .getOrganisationUsers([`role:${UserRole.DERMATOLOGIST};status:${UserStatus.ACTIVE}`])
                    .then((result) => {
                        const options = generateDermatologistsOptions(result.data);
                        setDermatologistsList(options);
                    });
            }
            if (filters.includes(CaseListFiltersType.LOCATION)) {
                const { locations } = currentOrganisation;
                const options = locations
                    ?.filter((item: ILocation) => !item.removed)
                    .map((dermatologistItem: ILocation) => {
                        const { uuid, name } = dermatologistItem;
                        return {
                            key: uuid,
                            text: name,
                            value: uuid,
                        };
                    });
                setLocationList(options ? [ALL_ITEM, ...options] : [ALL_ITEM]);
            }
        }
    }, []);

    const updateTextFilter = (event: ChangeEvent<HTMLInputElement>, data: InputProps) => {
        const { value, id: name } = data;
        setFilter({ [name]: value });
    };

    const updateSelectFilter = (event: SyntheticEvent<HTMLElement>, data: SelectProps | DropdownProps) => {
        const { value, id: filterId } = data;
        const currentValue = value || undefined;

        setFilter({ [filterId]: currentValue });
    };

    const handleClearFiltersClicked = () => {
        resetFilters();
    };

    const withCaseId = filters.includes(CaseListFiltersType.CASE_ID);
    const withDates = filters.includes(CaseListFiltersType.DATES);
    const withHospitalId = filters.includes(CaseListFiltersType.HOSPITAL_ID);
    const withNhs = filters.includes(CaseListFiltersType.NHS);
    const withLocation = filters.includes(CaseListFiltersType.LOCATION);
    const withOrganisation = filters.includes(CaseListFiltersType.ORGANISATION);
    const withManagementOutcome = filters.includes(CaseListFiltersType.MANAGEMENT_OUTCOME);
    const withDermatologist = filters.includes(CaseListFiltersType.DERMATOLOGIST);
    const withBirth = filters.includes(CaseListFiltersType.BIRTH);

    const withRemoteTimeline = filters.includes(CaseListFiltersType.REMOTE_TIMELINE) && !isKitDeliveryStatusTab();

    const withCaseStatus =
        filters.includes(CaseListFiltersType.CASE_STATUS) &&
        [CaseListTabs.ALL, CaseListTabs.OVERDUE].includes(activeTab as CaseListTabs);

    const withPathway =
        filters.includes(CaseListFiltersType.PATHWAY) &&
        !isKitDeliveryStatusTab() &&
        ((currentOrganisation && currentOrganisation.nonSkinCancerAllowed) ||
            isCurrentUserSuperAdmin ||
            isCurrentUserDermatologist);

    const isDateFromInFuture = formatDateForForm(dateFrom) > formatMomentDateForForm(TODAY);
    const isDateToInFuture = formatDateForForm(dateTo) > formatMomentDateForForm(TODAY);
    const isDateFromAfterDateTo = formatDateForForm(dateFrom) > formatDateForForm(dateTo);

    const ErrorMessage = ({ condition, message }) => (condition ? <Message negative>{message}</Message> : null);

    return (
        <Form>
            <Grid className="search-row">
                {withCaseId && canCurrentUserSeeCaseIdFilter && (
                    <Grid.Column tablet={4} computer={2}>
                        <Form.Input
                            type="number"
                            value={id}
                            label="CASE ID"
                            id="id"
                            placeholder="ID ..."
                            onChange={updateTextFilter}
                            onFocus={preventWheelEvent}
                            disabled={!isDataLoaded}
                        />
                    </Grid.Column>
                )}
                {withDates && (
                    <>
                        <Grid.Column tablet={4} computer={3}>
                            <InputDateField
                                value={dateFrom}
                                maxDate={TODAY}
                                label="DATE FROM"
                                boldLabel
                                onChange={(value) => {
                                    if (!value || value.isValid()) {
                                        updateTextFilter(undefined, {
                                            value: formatMomentDateForForm(value),
                                            id: "dateFrom",
                                        });
                                    }
                                }}
                                disabled={!isDataLoaded}
                            />
                            <ErrorMessage
                                condition={dateFrom && isDateFromInFuture}
                                message="Date can not be in the future"
                            />
                            <ErrorMessage
                                condition={dateFrom && !isDateFromInFuture && isDateFromAfterDateTo}
                                message="Date from must be before Date to"
                            />
                        </Grid.Column>
                        <Grid.Column tablet={4} computer={3}>
                            <InputDateField
                                value={dateTo}
                                maxDate={TODAY}
                                label="DATE TO"
                                boldLabel
                                onChange={(value) => {
                                    if (!value || value.isValid()) {
                                        updateTextFilter(undefined, {
                                            value: formatMomentDateForForm(value),
                                            id: "dateTo",
                                        });
                                    }
                                }}
                                disabled={!isDataLoaded}
                            />
                            <ErrorMessage
                                condition={dateTo && isDateToInFuture}
                                message="Date can not be in the future"
                            />
                        </Grid.Column>
                    </>
                )}
                {withHospitalId && (
                    <Grid.Column tablet={4} computer={3}>
                        <Form.Input
                            type="text"
                            value={hospitalId}
                            label={CaseListColumns.HOSPITAL_NUMBER}
                            id="hospitalId"
                            placeholder="Hospital Number..."
                            onChange={updateTextFilter}
                            disabled={!isDataLoaded}
                        />
                    </Grid.Column>
                )}
                {withNhs && (
                    <Grid.Column tablet={4} computer={3}>
                        <Form.Input
                            type="text"
                            value={nhs}
                            label="NHS #"
                            id="nhs"
                            placeholder="NHS #"
                            onChange={updateTextFilter}
                            disabled={!isDataLoaded}
                        />
                    </Grid.Column>
                )}
                {withLocation && (
                    <Grid.Column tablet={8} computer={4} className="case-list-filters-select">
                        {isCurrentUserSuperAdmin ? (
                            <Form.Input
                                type="text"
                                value={location}
                                label="LOCATION"
                                id="location"
                                placeholder="Location"
                                onChange={updateTextFilter}
                                disabled={!isDataLoaded}
                            />
                        ) : (
                            <Form.Select
                                label="LOCATION"
                                value={locationUuid}
                                id="locationUuid"
                                placeholder="Location"
                                onChange={updateSelectFilter}
                                options={locationList}
                                disabled={!isDataLoaded}
                                selectOnBlur={false}
                            />
                        )}
                    </Grid.Column>
                )}
                {withOrganisation && (
                    <Grid.Column tablet={8} computer={4} className="case-list-filters-select">
                        <Form.Dropdown
                            label="ORGANISATION"
                            value={organisation}
                            id="organisation"
                            placeholder="Organisation"
                            onChange={updateSelectFilter}
                            options={organisationsList}
                            search
                            selection
                            disabled={!isDataLoaded}
                            selectOnBlur={false}
                        />
                    </Grid.Column>
                )}
                {withDermatologist && (
                    <Grid.Column tablet={8} computer={4} className="case-list-filters-select">
                        <Form.Select
                            label="DERMATOLOGIST"
                            value={dermatologist}
                            id="dermatologist"
                            placeholder="Dermatologist"
                            onChange={updateSelectFilter}
                            options={dermatologistsList}
                            disabled={!isDataLoaded}
                            selectOnBlur={false}
                        />
                    </Grid.Column>
                )}
                {withBirth && (
                    <>
                        <Grid.Column tablet={4} computer={3}>
                            <InputDateField
                                value={birth}
                                label="DATE OF BIRTH"
                                onChange={(value) => {
                                    if (!value || value.isValid()) {
                                        updateTextFilter(undefined, {
                                            value: formatMomentDateForForm(value),
                                            id: "birth",
                                        });
                                    }
                                }}
                                disabled={!isDataLoaded}
                            />
                        </Grid.Column>
                    </>
                )}
                {withRemoteTimeline && (
                    <Grid.Column tablet={8} computer={4} className="case-list-filters-select">
                        <Form.Select
                            label="REMOTE TIMELINE"
                            id="remoteTimeline"
                            value={remoteTimeline}
                            placeholder="Remote timeline"
                            onChange={updateSelectFilter}
                            options={createDropdownOptions(RemoteTimelineFilterOptions, RemoteTimelineDictionary)}
                            disabled={!isDataLoaded}
                            selectOnBlur={false}
                        />
                    </Grid.Column>
                )}
                {withCaseStatus && (
                    <Grid.Column tablet={8} computer={4} className="case-list-filters-select">
                        <Form.Select
                            label="CASE STATUS"
                            id="status"
                            value={status}
                            placeholder="Case Status"
                            onChange={updateSelectFilter}
                            options={createDropdownOptions(CaseStatusFilter, CaseStatusDictionary)}
                            disabled={!isDataLoaded}
                            selectOnBlur={false}
                        />
                    </Grid.Column>
                )}
                {withManagementOutcome && (
                    <Grid.Column tablet={8} computer={4} className="case-list-filters-select">
                        {isCurrentUserSuperAdmin ? (
                            <Form.Input
                                type="text"
                                value={recommendation}
                                label="M-NT OUTCOME"
                                id="recommendation"
                                placeholder="Management Outcome"
                                onChange={updateTextFilter}
                                disabled={!isDataLoaded}
                            />
                        ) : (
                            <Form.Select
                                label="M-NT OUTCOME"
                                id="managementOutcome"
                                value={managementOutcome}
                                placeholder="Case Status"
                                onChange={updateSelectFilter}
                                options={managementOutcomesList}
                                disabled={!isDataLoaded}
                                selectOnBlur={false}
                            />
                        )}
                    </Grid.Column>
                )}
                {withPathway && (
                    <Grid.Column tablet={8} computer={4} className="case-list-filters-select">
                        <Form.Select
                            label="PATHWAY"
                            id="pathway"
                            value={pathway}
                            placeholder="Pathway"
                            onChange={updateSelectFilter}
                            options={pathwayList}
                            disabled={!isDataLoaded}
                            selectOnBlur={false}
                        />
                    </Grid.Column>
                )}
                <Grid.Column tablet={4} computer={2} className="case-list-filters-reset">
                    <Header
                        as="h3"
                        tabIndex={0}
                        onClick={handleClearFiltersClicked}
                        onKeyPress={(e: KeyboardEvent): void => {
                            if (e.key === "Enter") {
                                handleClearFiltersClicked();
                            }
                        }}
                    >
                        Clear
                    </Header>
                </Grid.Column>
            </Grid>
        </Form>
    );
};

export default CaseListFilters;
