import { FC } from "react";
import { DialogContent } from "@material-ui/core";
import { Message } from "semantic-ui-react";

import ModalDialog from "components/templates/dialog/ModalDialog";
import { IFormError } from "model/formError";

interface IActivateModalProps {
    activeUser: boolean;
    errorDetails: Pick<IFormError, "path" | "message">[];
    showModal: boolean;

    closeModal: () => void;
    toggleUser: () => void;
}

const ActivateModal: FC<IActivateModalProps> = ({ activeUser, errorDetails, showModal, closeModal, toggleUser }) => {
    if (!showModal) {
        return null;
    }

    return (
        <ModalDialog
            title={`Confirm to ${activeUser ? "deactivate" : "activate"} user`}
            open={showModal}
            iconName="warning sign"
            onClose={closeModal}
            buttons={[
                { text: "Yes", onClick: () => toggleUser() },
                { text: "No", onClick: () => closeModal() },
            ]}
            maxWidth="sm"
        >
            <DialogContent>
                <p>
                    {activeUser
                        ? "Are you sure you want to deactivate this user? The user will not be able to login. The account can be reactivated at a later stage if required."
                        : ""}
                </p>
                {errorDetails?.length
                    ? errorDetails.map((detail: Pick<IFormError, "path" | "message">) => (
                          <Message key={detail.message} negative>
                              <p>{detail.message}</p>
                          </Message>
                      ))
                    : null}
            </DialogContent>
        </ModalDialog>
    );
};

export default ActivateModal;
