import { useContext, useEffect, useState } from "react";
import { Message } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { history } from "App";

import CustomButton from "components/templates/Button";
import PatientDetailsIntegration from "components/templates/PatientDetailsIntegrationGrid";

import { PatientSearchContext } from "contextProviders/PatientSearchProvider";
import { PatientSearchActions } from "contextProviders/modules/patientSearch/actions";

import { PatientSearchExceptionModalTypes } from "model/patientSearch";
import { IPatientEdit } from "model/patientEdit";

import { MEDICAL_HISTORY } from "navigation/routes";

import { getCurrentCase } from "redux/selectors/assessment";
import { assessmentActions } from "redux/actions";
import { getOrganisation } from "redux/selectors/data";

import * as patientService from "services/patientService";

const PatientSearchExpandedView = () => {
    const {
        state: { expandedViewPatient, searchFormData },
        dispatch,
    } = useContext(PatientSearchContext);
    const [isPendingRequest, setIsPendingRequest] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const { uuid: caseUuid } = useSelector(getCurrentCase);
    const organisation = useSelector(getOrganisation);
    const dispatchRedux = useDispatch();

    const requiredFields = organisation.definedPersonalData
        .filter((field) => !field.removed && field.required)
        .map((field) => field.integrationSourcePropertyName);
    const isEmptyRequiredField = Boolean(
        requiredFields.find((propertyName) => expandedViewPatient[propertyName].trim() === "")
    );

    useEffect(() => {
        if (isEmptyRequiredField) {
            dispatch({
                type: PatientSearchActions.SET_MODAL_VISIBILITY,
                modalType: PatientSearchExceptionModalTypes.NOT_ENOUGH_DATA,
                show: true,
            });
        }
    }, [isEmptyRequiredField]);

    const {
        mergedRecord,
        dateOfBirth,
        patientIdentifier,
        title,
        forename1,
        surname,
        sex,
        nhsNumber,
        ethnicGroup,
        occupation,
        minorPatientIdentifier,
    } = expandedViewPatient;
    const patientData: IPatientEdit = {
        birth: dateOfBirth,
        ethnicGroup,
        gender: sex,
        hospitalNo: patientIdentifier,
        name: forename1,
        occupation,
        surname,
        title,
        nhs: nhsNumber,
        mergedRecord,
        minorPatientIdentifier,
    };

    const handleBackButtonClicked = () => {
        dispatch({ type: PatientSearchActions.RESET_EXPANDED_VIEW_PATIENT });
    };

    const handleCreatePatientClicked = async (): Promise<void> => {
        try {
            setIsError(false);
            setIsPendingRequest(true);
            const patient = await patientService.createPatientFromIntegration(caseUuid, patientIdentifier);
            dispatchRedux(assessmentActions.createPatientSuccess({ ...patient }));
            history.push(MEDICAL_HISTORY);
        } catch (e) {
            setIsPendingRequest(false);
            setIsError(true);
        }
    };

    return (
        <>
            <PatientDetailsIntegration patient={patientData} searchHospitalNumber={searchFormData.hospitalNumber} />
            {isError && (
                <div className="patient-search-expanded-view__error-wrapper">
                    <Message negative>Error occurred when creating the Patient</Message>
                </div>
            )}
            <div className="patient-search-expanded-view__buttons-wrapper">
                <CustomButton
                    variant="empty"
                    type="submit"
                    action={handleBackButtonClicked}
                    text="< Back"
                    size="small"
                    disabled={isPendingRequest}
                />
                {!isEmptyRequiredField && (
                    <CustomButton
                        variant="filled"
                        type="submit"
                        action={handleCreatePatientClicked}
                        text="Create case"
                        size="small"
                        loading={isPendingRequest}
                        disabled={isPendingRequest}
                    />
                )}
            </div>
        </>
    );
};

export default PatientSearchExpandedView;
