import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Divider } from "semantic-ui-react";

import CreateUserScreen from "components/Administration/UserManagement/CreateUserScreen";
import UserDetailsScreen from "components/Administration/UserManagement/UserDetailsScreen";
import UserList from "components/Administration/UserManagement/UserList";

import { UserManagementSubpages } from "model/administrationPages";
import { UserRole } from "model/userRole";

import userService from "services/userService";

import "scss/UserManagement.scss";

interface IUserManagmentScreenProps {
    params: any;
}

class UserManagmentScreen extends Component<IUserManagmentScreenProps, any> {
    private getContext() {
        const { params } = this.props;
        const { subpage = null } = { ...params };
        if (subpage) {
            if (subpage === UserManagementSubpages.CREATE_USER) {
                return <CreateUserScreen params={params} />;
            }
            if (subpage === UserManagementSubpages.USER_DETAILS) {
                return <UserDetailsScreen params={params} />;
            }
        }
        return <UserList />;
    }

    public render() {
        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to="/home" />;
        }

        return (
            <div>
                <h1>User Management</h1>
                <Divider />
                {this.getContext()}
            </div>
        );
    }
}

export default UserManagmentScreen;
